import React, { useEffect, useState} from 'react';
import Router from './components/router'
import { GlobalContext } from './common/context';
import { Provider } from 'react-redux';
import {store} from './redux/store';
import { getLocalStorage } from './common/helper/utility'
import { initGA } from '../src/common/googleAnalytics'

const { REACT_APP_GA_TRACKING_ID } = process.env;

function App() {
  const fkv2_session = getLocalStorage('fkv2_session')

  useEffect(() => { 
    // setTimeout(() => {
    //   window.adsbygoogle = window.adsbygoogle || []
    //   window.adsbygoogle.push({})
    // }, 1500)
    initGA(REACT_APP_GA_TRACKING_ID); 
  }, []);

  const [isShowAd, setIsShowAd] = useState(true)
  const [isShowPromo, setIsShowPromo] = useState(true)

  return (
    <GlobalContext.Provider value={{
      isShowAd, setIsShowAd, isShowPromo, setIsShowPromo
    }}>
      <Provider store={store}>
        <Router fkv2_session={fkv2_session}/>
      </Provider>
    </GlobalContext.Provider>
  );
}

export default App;

