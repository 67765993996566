import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import { emailVerifyLoad, emailVerifyClearMessage, resendEmailVerificationLoad, resendEmailVerificationReset } from '../../../redux/action'
import { SignupVerifiedSVG } from '../../../assets/svg'
import Loader from '../../../common/component/loader'
import Message from '../../../common/message'
import { theme } from '../../../common/theme'

const { REACT_APP_WEB_SEC } = process.env

const Email = (props) => {
  const fkv2_session = props.session.fkv2_session ? props.session.fkv2_session : false
  const { verifyIsLoading, error, message, resendEmailVerificationLoading, resendEmailVerificationMessage, resendEmailVerificationError } = props.auth
  const currentUrl = window.location.href
  const [notValid, setNotValid] = useState(null)
  const [parsedEmail, setParsedEmail] = useState('')

  useEffect(() => {
    if (!fkv2_session) {
      linkValid()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //hooks to clear message
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        props.emailVerifyClearMessage()
      }, 2000)
      setTimeout(() => {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}login`);
      }, 5000)
    }
    // eslint-disable-next-line
  }, [message])

  //hooks to clear resend
  useEffect(() => {
    if (resendEmailVerificationMessage || resendEmailVerificationError) {
      setTimeout(() => {
        props.resendEmailVerificationReset()
        window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
      }, 2000)
    }
    // eslint-disable-next-line
  }, [resendEmailVerificationMessage, resendEmailVerificationError])

  const checkPageStatus = () => {
    if (fkv2_session) {
      return <Redirect to='/' />
    }
  }

  const linkValid = () => {
    const split = currentUrl.split(REACT_APP_WEB_SEC)
    const hashedEmail = split[1]
    const registeredDate = split[2] ? window.atob(split[2]) : false
    const hoursOld = moment().diff(moment(registeredDate), 'hours');

      if (hoursOld  >= 12) {
        setNotValid(true)
        setParsedEmail(hashedEmail)
      }
      else {
        setNotValid(null)
        props.emailVerifyLoad(hashedEmail)
      }

  }

  

  const handleResendVerification = (e) => {
    e.preventDefault()
    props.resendEmailVerificationLoad(parsedEmail)
  }
  return (
    <div style={style.container}>
      {checkPageStatus()}
      {
        notValid ?
          <div className="mt-50">
            <Message message={theme.text.message.linkExpired} style="figmaDesign" />
            <form onSubmit={(e) =>handleResendVerification(e)}>
              { 
                resendEmailVerificationLoading ? <p className='mt-50'>Sending...</p>: 
                resendEmailVerificationMessage ? <p className='item-new-text-green mt-50'>{resendEmailVerificationMessage}</p> :
                resendEmailVerificationError ? <p className='item-new-text-red mt-50'>{resendEmailVerificationError}</p> :
                <input className="resend-verification-btn mt-50" type="submit" value={theme.text.message.resendEmailVerification} />  
              }
            </form>
          </div>  
        : verifyIsLoading ?
          <div className="signup-main-container">
          <div className={`signup-container ${verifyIsLoading ? `flex` : ``}`}>
            <Loader /> </div></div>
        : error ?
          <div>
            <h2 style={{ color: 'red' }}>{error}</h2>
          </div>
        : 
          <div className="signup-main-container">
            <div className={`signup-container ${message ? `flex` : ``}`}>
              {
                message ?
                  <Loader /> :
                  <div>
                    <Message message={theme.text.message.welcomeMessage} style="figmaDesign" />
                    <SignupVerifiedSVG />
                    <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '21px', color: '#232A34', display: 'flex', textAlign: 'center' }}>{theme.text.message.verifiedEmail}</p>
                  </div>
              }

            </div>
          </div>
      }
    </div>
  );
}

const mapStateToProps = store => {
  return {
    auth: store.authReducer
  };
};

const mapActionToProps = {
  emailVerifyLoad,
  emailVerifyClearMessage,
  resendEmailVerificationLoad,
  resendEmailVerificationReset
};

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 350
  },
}

export default connect(mapStateToProps, mapActionToProps)(Email);