import React, {useContext} from 'react'
import { HomeContext } from '../../../../../common/context'
import ItemCard from '../ItemCard'

const TopSearches = () => {
    const HomeValue = useContext(HomeContext)
    const {likeDislike, session, likeDislikeLoading, loading, TopSearchesArr} = HomeValue
    const sessionId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id : 0
    // const filterOtherUser = TopSearchesArr.filter(item => parseInt(item.oc_t_user.pk_i_id.xid) !== parseInt(sessionId))
    const finalData = TopSearchesArr && TopSearchesArr.slice(0, 4)

    return (
        TopSearchesArr.length === 0 ? null :
        <div className="w-100">
            <p className="home-title-label-p">{`Top Searches`}</p>
            <div className="recommended-item-container">
                {
                    finalData.map((item, i) => {
                        const hl_expiration = item && item.hl_expiration ? item.hl_expiration : null
                        const ta_expiration = item && item.ta_expiration ? item.ta_expiration : null
                        const ab_expiration = item && item.ab_expiration ? item.ab_expiration : null
                        return (
                            <React.Fragment key={i}>
                                <ItemCard key={i} item={item} ab_expiration={ab_expiration} hl_expiration={hl_expiration} ta_expiration={ta_expiration} session={session} likeDislike={likeDislike} likeDislikeLoading={likeDislikeLoading}/>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TopSearches