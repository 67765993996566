import { DBS_TOKEN_REQUEST } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { dbsTokenReuqestSuccess, dbsTokenReuqestFailed } from '../action';
// import { Api } from '../../external/dbs/api'
import user from '../../graphql/user'

function* dbsTokenRequestaga(action) {
    const { payload } = action
    try {
      const result = yield user.fkPurchase(payload)
      if(result === 403) {
        yield put(dbsTokenReuqestFailed('Something went wrong try again!'))
      }
      else {
        const data = result ? result : []
        yield put(dbsTokenReuqestSuccess(data));
        }
      } catch (error) {
        console.log('error', JSON.stringify(error))
        yield put(dbsTokenReuqestFailed(error))
      }
}    

export function* watchDbsTokenRequestSaga() {
  yield takeLatest(DBS_TOKEN_REQUEST.LOAD, dbsTokenRequestaga);
}