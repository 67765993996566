import React, { useState, useEffect, useRef, useContext} from 'react';
import { connect } from 'react-redux'
import { GlobalContext } from '../../context';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { logoutLoad, chatLoad, setChatMobileIsThread, itemReset, itemLoad, userSuccess, collectPromoLoad, collectPromoReset } from '../../../redux/action'
import PrivacyAndTerms from '../../../components/web/privacyAndTerms'
import Logo from '../logo'
import Navbar from '../navbar';
import Search from '../search'
import { theme } from '../../theme'
import BgImg from '../../../assets/images/fk_bg.jpg'
import {HeaderSideBarContainer} from './customComponents'
import { DownloadTheAppAd } from '../ad';
import { LegalIcon, BurgerIcon, SearchIcon, FKPrimeMobileIcon, CategoryReelsIcon, CategoryRodsIcon, CategoryLuresIcon, CategoryLineIcon, CategoryTerminalIcon, CategoryAccessoriesIcon, CategoryApparelIcon, CategoryWatercraftIcon, CategoryChartersIcon, PrivacyAndPolicyIcon, TermsOfUseIcon, ContactPhoneIcon, AboutIcon} from '../../../assets/svg'
import { CollectPromoModal } from '../customComponents'

const Header = (props) => {
    const globalValue = useContext(GlobalContext)
    const {isShowAd, setIsShowAd, isShowPromo, setIsShowPromo} = globalValue
    const [isShow, setIsShow] = useState(false)
    const [show, setShow] = useState(false)
    const [legal, setLegal] = useState(false)
    const [isSearchClick, setIsSearchClick] = useState(false)
    const [totalUnread, setTotalUnread] = useState(0)
    const [isBurgerClick, setIsBurgerClick] = useState(false)
    const data = props.chat && props.chat.data ? props.chat.data : []
    const mobileProfile = useRef(null)
    const mobileLinks = useRef(null)
    const mobileBurger = useRef(null)
    const mobileSearch = useRef(null)
    const type = props && props.session && props.session.fkv2_session && props.session.fkv2_session.type ? props.session.fkv2_session.type : false
    const id = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : false
    const text = theme.text
    const thread = props.chat && props.chat.mobileIsThread ? props.chat.mobileIsThread : false

    const sidebarArr = {
        category: [
            {label: 'Reels', icon: <CategoryReelsIcon />, path: '/reels'},
            {label: 'Rods', icon: <CategoryRodsIcon />, path: '/rods'},
            {label: 'Lures', icon: <CategoryLuresIcon />, path: '/lures'},
            {label: 'Line', icon: <CategoryLineIcon />, path: '/line'},
            {label: 'Terminal', icon: <CategoryTerminalIcon />, path: '/terminal'},
            {label: 'Accessories ', icon: <CategoryAccessoriesIcon />, path: '/accessories'},
            {label: 'Apparel', icon: <CategoryApparelIcon />, path: '/apparel'},
            {label: 'Watercraft ', icon: <CategoryWatercraftIcon />, path: '/watercraft'},
            {label: 'Charters', icon: <CategoryChartersIcon />, path: '/charters'},
        ],
        classifieds: [
            {label: 'Privacy Policy ', icon: <PrivacyAndPolicyIcon />, path: '/privacy-policy'},
            {label: 'Terms of Use', icon: <TermsOfUseIcon />, path: '/terms-of-use'},
            {label: 'Contact', icon: <ContactPhoneIcon />, path: '/contact-us'},
            {label: 'About ', icon: <AboutIcon />, path: '/about-us'},
        ]
    }


    const handleClickLegal = () => {
        setLegal(!legal)
    }

    const handleSearchClick = () => {
        setIsSearchClick(!isSearchClick)
    }

    useEffect(() => {
        let total = 0
        // eslint-disable-next-line 
        data.map((item) => {
            if (item && item.chat_messages.length > 0) {
                // eslint-disable-next-line 
                item.chat_messages.map(chat => {
                    if (!chat.read_at && chat.sender_id !== parseInt(id)) {
                        total++
                    }
                })
            }
        })
        setTotalUnread(total)
        // eslint-disable-next-line    
    }, [props.chat])

    const handleLogout = () => {
        const alert = window.confirm("Are you sure you want to logout"); if (alert === true) {
            props.logoutLoad()
        }
    }
    const handleClickOutside = event => {
        if (mobileProfile.current && !mobileProfile.current.contains(event.target)) {
            if (show) {
                setShow(false)
            }
        }
    };

    const handleClickOutsideMobile = event => {
        if (mobileLinks.current && !mobileLinks.current.contains(event.target)) {
            if (isShow) {
                setIsShow(false)
            }

        }
    }

    const handleClickBurgerOutside = event => {
        if(mobileBurger.current && !mobileBurger.current.contains(event.target)){
            if(isBurgerClick) {
                setIsBurgerClick(false)
            }
        }
    }

    const handleMobileSearchOutside = event => {
        if(mobileSearch.current && !mobileSearch.current.contains(event.target)){
            if(isSearchClick){
                setIsSearchClick(false)
            }
        }
    }

    const toggleShow = () => {
        setIsShow(!isShow)
    }

    const showToggle = () => {
        setShow(!show)
    }

    useEffect(() => {
        if (window.innerWidth < 600) {
            //  document.addEventListener("keydown", handleHideDropdown, true);
            document.addEventListener("click", handleClickBurgerOutside);
            document.addEventListener("click", handleClickOutsideMobile);
            document.addEventListener("click", handleMobileSearchOutside);
            return () => {
                //    document.removeEventListener("keydown", handleHideDropdown, true);
                document.removeEventListener("click", handleClickBurgerOutside);
                document.removeEventListener("click", handleClickOutsideMobile);
                document.removeEventListener("click", handleMobileSearchOutside);
            };
        } else {
            // document.addEventListener("keydown", handleHideDropdown, true);
            document.addEventListener("click", handleClickOutside);
            return () => {
                //   document.removeEventListener("keydown", handleHideDropdown, true);
                document.removeEventListener("click", handleClickOutside);
            };
        }

    });

    const clickChatHandler = () => {
        if (thread) {
            props.setChatMobileIsThread(false)
        }
    }

    const location = useLocation()
    const path = location.pathname

    const clickLogoHandler = () => {
        if (path === '/') {
            props.itemReset()
            props.itemLoad({ first: 40, page: 1 })
        }
    }

    const closeAdHandler = () => {
        setIsShowAd(false)
    }

    const uncollectedPromo = props.me && props.me.sessionData && props.me.sessionData.uncollected_promo ? props.me.sessionData.uncollected_promo : null
    const {isLoadingCollectPromo, collectPromoMessage, errorCollectPromo} = props.purchase

    const closePromoHandler = () => {
        setIsShowPromo(false)
    }

    const collectPromoHandler = (promo_id) => {
        props.collectPromoLoad({promo_id, id})
    }

    useEffect(() => {
        if (collectPromoMessage) {
            setTimeout(() => {
                props.collectPromoReset()
                setIsShowPromo(false)
            }, 3000)
        }
        if (errorCollectPromo) {
            setTimeout(() => {
                props.collectPromoReset()
                setIsShowPromo(false)
            }, 2000)
        }
        // eslint-disable-next-line    
    }, [collectPromoMessage, errorCollectPromo])

    return (
        path.includes('paypal') ? null :
        <>
        {uncollectedPromo && isShowPromo ? <CollectPromoModal sharedProps={{closePromoHandler, uncollectedPromo, collectPromoHandler, isLoadingCollectPromo, collectPromoMessage, errorCollectPromo, congratulations: text.congratulations, youReceived: text.youReceived, promo: text.promo, collect: text.collect, claimSuccess: text.claimSuccess, checkYourProfile: text.checkYourProfile}}/> : null}
        <div className={`mainHeader ${path === '/user/item/new' ? `` : ``} bg-white ${isShowAd ? ` flex-d-column` : ``}`} ref={mobileLinks}>
            {
                isShowAd ?
                <div className='mobile-icons w-100'>
                    <DownloadTheAppAd removeFunc={closeAdHandler}/>
                </div> : null
            }
            <div className={window.innerWidth < 600 ?  `flex flex-d-row flex-align-center flex-justify-between w-100 p-header` : `styleHeader`} ref={mobileProfile}>
                {
                    window.innerWidth < 600 ? path.includes('inbox') || path.includes('new') ? null :
                    <>  
                        <div className="flex flex-d-row flex-align-center">
                            <div className={`mr-13`} onClick={() => setIsBurgerClick(!isBurgerClick)}>
                                <BurgerIcon />
                            </div>
                            {
                                !isSearchClick ?
                                <Logo clickLogoHandler={clickLogoHandler} /> : null
                            }
                        </div>
                        <div className="flex flex-d-row flex-align-center">
                            {
                                isSearchClick ?
                                <div ref={mobileSearch}>
                                <Search id={id} />
                                </div> : null
                            }
                            {
                                !isSearchClick ?
                                <div onClick={() => handleSearchClick()}>
                                    <SearchIcon />
                                </div> : null
                            }
                            <Link to='/fk-prime' className="ml-17">
                                <FKPrimeMobileIcon />
                            </Link>
                        </div>
                        {isBurgerClick ? <HeaderSideBarContainer arr={sidebarArr} mobileBurger={mobileBurger} handleLogout={handleLogout} session={props.session}/> : null}
                    </> : 
                    <>
                        <Logo clickLogoHandler={clickLogoHandler} />
                        <Search id={id} />
                        <Navbar type={type} id={id} toggleShow={toggleShow} showToggle={showToggle} show={show} isShowing={isShow} session={props.session} />
                    </>
                }
            </div>

            <div className="responsiveBoard" >
                {isShow ?
                    type ?
                        <div className="linkBoard" onClick={() => setIsShow(false)}>

                            {
                                text.header.map((header, index) => {
                                    return <div key={index} className="headerLinks">
                                        {header.label === "messages" ?
                                            totalUnread > 0 ? <span style={style.unread}><span style={style.unreadText}>{`${totalUnread}`}</span></span> : null
                                            : null
                                        }
                                        {
                                           header.label === 'Settings' ? <Link to={{pathname: header.path, fromDrawerClick: true}} style={style.default}><i className={header.icon}></i> </Link> : <NavLink exact to={header.path} activeStyle={style.active} style={style.default} onClick={clickChatHandler}><i className={header.icon}></i></NavLink>
                                        }
                                        
                                    </div>
                                })
                            }
                            <div className="headerLinks">
                                <Link onClick={handleLogout} to='/' style={style.default}><i className="fas fa-power-off" /></Link>
                            </div>
                        </div>
                        :
                        <div className="linkBoard">
                            {
                                text.headerNoSession.map((header, index) => {
                                    return <div key={index} className="headerLinks">
                                        <NavLink exact to={header.path} activeStyle={style.active} style={style.default}><i className={header.icon}></i></NavLink>
                                    </div>
                                })

                            }
                            <div onClick={() => setLegal(true)} style={{ padding: '10px 0px', paddingRight: '5px' }}><LegalIcon /></div>
                        </div>

                    : <div className="extra"></div>}
            </div>
            {legal ? <PrivacyAndTerms click={handleClickLegal} id={id} /> : null}
        </div>
        </>
    );
};

const style = {
    display: 'flex',
    // backgroundColor:  theme.color.primaryBg, 
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px',
    default: {
        color: theme.color.white,
        textDecoration: 'none',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 25,
        paddingRight: 25,
        fontFamily: theme.font.primary,
        fontSize: 18
    },
    defaultHeader: {
        background: '#FFFFFF'
    },
    bgHeader: {
        backgroundImage: `url(${BgImg})`,
        backgroundSize: 'cover',
        backdropFilter: 'contrast(80%)',
        height: '300px'
    },
    active: {
        color: theme.color.orange,

    },
    menuBtn: {
        padding: '12px 18px',

    },
    unread: {
        padding: 0,
        margin: 0,
        position: 'absolute',
        top: -2,
        right: 19,
        backgroundColor: '#F77022',
        width: 18,
        height: 18,
        borderRadius: 9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    unreadText: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: 10
    },
    board: {
        borderBottom: '1px solid #95a5a6',
        borderTop: '1px solid #95a5a6 ',
        padding: '0px 2px',
        backgroundColor: theme.color.primaryBg,
    }
}
const mapStateToProps = store => {
    return {
        chat: store.chatReducer,
        me: store.userReducer,
        purchase: store.purchaseReducer
    };
};

const mapActionToProps = {
    chatLoad,
    logoutLoad,
    setChatMobileIsThread,
    itemReset,
    itemLoad,
    collectPromoLoad,
    collectPromoReset
};

export default connect(mapStateToProps, mapActionToProps)(Header);