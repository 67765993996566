import React from 'react'

export const WTT = () => {
    return (
        <div className="wtt-label">
            <p>{`WTT`}</p>
        </div>
    )
}

export const WTB = () => {
    return (
        <div className="wtb-label">
            <p>{`WTB`}</p>
        </div>
    )
}