import { REVIEW , REVIEW_VIEW } from '../constant'

const initialState = {
    isLoading: false,
    data: null,
    error: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case REVIEW.LOAD:
        return {...state, isLoading:true, error: null}

        case REVIEW.SUCCESS:
        return {...state, isLoading:false, data: action.payload, error: null}

        case REVIEW.FAIL:
        return {...state, isLoading: false, error:action.payload}

        case REVIEW_VIEW.LOAD:
        return {...state, isLoading:true, error:null}

        case REVIEW_VIEW.SUCCESS:
        return {...state, isLoading:false, data: action.payload, error: null}

        case REVIEW_VIEW.FAIL:
        return {...state, isLoading: false, error: action.payload}

        default:
        return state;
    }
}