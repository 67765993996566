import React, {useEffect} from 'react'
// import MobileTOU from './mobile'
// import {theme} from './theme'

const PageNotFound = (props) => {
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])
    // const data = theme.TOS
    // const lastUpdate = theme.lastUpdate
    // const lister = (l) => {
    //     let listing
    //     listing = l.map((list) => <li>{list.text}</li>)
    //     return listing
    // }
    // const termsBody = (p, s) => {
    //     let body
    //     body = <p className="content-sentence"><span className="content-section">{s}</span> {p}</p>
    //     return body
    // }
    return(
        <div style={{minHeight: 400}}>
        <h1 style={{marginTop: 200, textAlign: 'center'}}>Page not found!</h1>
        {/* {
             window.innerWidth < 600 ? <MobileTOU data={theme} query={props.query}/> :
             <div className="content-main-container">
             <div className="content-sub-container">
                 <h1 className="content-main-title">{`TERMS OF USE`}</h1>
             </div>
             <div className="content-body">
                 <h1 className="content-last-update">{`Last updated: ${lastUpdate}`}</h1>
                 { data.map((terms, index) => {
                     const bodyContent = terms.body 
                     const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, data.section) : <div><ul className="content-list">{lister(data.body)}</ul></div>)
                     return <div key={index}>
                         <h1 className="content-title">{terms.title}</h1>
                         <div>
                             {bodyCheck}
                         </div>
                     </div>
                 })}
             </div>
         </div>
        } */}
        </div>
    )
}

export default PageNotFound