export const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 300
    },
    title: {
        fontWeight: 'bold'
    },
    bundleContainer: {
        width: '100%',
        // margin: 20,
    },
    bundleItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
    },
    coinButton: {
        backgroundColor: '#d35400',
        border: '1px solid #FB770D',
        borderRadius: '3px',
        padding: '5px 10px',
        marginLeft: 'auto'
    },
    changeButton: {
        width: '100%',
        padding: 17,
        borderBottom: '0.5px solid #C4C4C4',
        fontSize: 14,
        display: 'flex',
        alignContent: 'space-between'
    },
    prcoeedButton: {
        backgroundColor: 'green',
        color: 'white',
        width: '100%',
        padding: 17,
        fontSize: 14,
        display: 'flex',
        alignContent: 'space-between'
    },
    methodContainer: {
        margin: '20px 0px'
    },
    radioCont: {
        marginTop: 20,
        marginBottom: 20,
    },
    stripeHeaderContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center',

    },
    stripePayButton: {
        color: '#fff',
        backgroundColor: '#337ab7',
        borderColor: '#2e6da4',
        width: '100%',
        padding: '10px 16px',
        fontSize: 18,
        lineHeight: 1.3333333,
        bordeRadius: 6,
        marginTop: 15,
        marginBottom: 15,
        borderRadius: 3
    }

}