
import { TOGGLE_LIVE, USE_NOW_PROMOTE, CANCEL_SUBSCRIPTION, UPDATE_SUBSCRIPTION} from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { toggleLiveSuccess, toggleLiveFailed, servicePromoteFailed, servicePromoteSuccess, meSuccess, cancelSubscriptionSuccess, cancelSubscriptionFailed, updateSubscriptionSuccess, updateSubscriptionFailed  } from '../action';
import  micro_service from '../../graphql/micro_service'
import { theme } from '../../common/theme'

function* toggleLiveSaga(action) {
    const { payload } = action
    try {
      const result = yield micro_service.toggleLive(payload)   
    if(result === 403) {
      yield put(toggleLiveFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.toggleLive  ? result.data.toggleLive : {}
      yield put(meSuccess(data));
      yield put(toggleLiveSuccess('Item updated successfully'))
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      console.log('error', errorMessage)
      yield put(toggleLiveFailed(errorMessage))
    }
  }    
  
  export function* watchToggleLiveSaga() {
    yield takeLatest(TOGGLE_LIVE.LOAD, toggleLiveSaga)
  }

  function* useNowPromoteSaga(action) {
    const { payload } = action
    try {
      const result = yield micro_service.useNowPromote(payload)   
    if(result === 403) {
      yield put(servicePromoteFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.useNowPromote  ? result.data.useNowPromote : {}
      yield put(meSuccess(data));
      yield put(servicePromoteSuccess('Item updated successfully'))
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      console.log('error', JSON.stringify(e))
      yield put(servicePromoteFailed(errorMessage))
    }
  }    
  
  export function* watchUseNowPromoteSaga() {
    yield takeLatest(USE_NOW_PROMOTE.LOAD, useNowPromoteSaga)
  }

  function* cancelSubscriptionSaga(action) {
    const { payload } = action
    try {
      const result = yield micro_service.cancelSubscription(payload)   
    if(result === 403) {
      yield put(cancelSubscriptionFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.cancelSubscription  ? result.data.cancelSubscription : {}
      yield put(meSuccess(data));
      yield put(cancelSubscriptionSuccess('Subscription cancelled.'))
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      console.log('error', JSON.stringify(e))
      yield put(cancelSubscriptionFailed(errorMessage))
    }
  }    
  
  export function* watchCancelSubscriptionSaga() {
    yield takeLatest(CANCEL_SUBSCRIPTION.LOAD, cancelSubscriptionSaga)
  }

  function* updateSubscriptionSaga(action) {
    const { payload } = action
    try {
      const result = yield micro_service.updateSubscription(payload)   
    if(result === 403) {
      yield put(updateSubscriptionFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.updateSubscription  ? result.data.updateSubscription : {}
      yield put(meSuccess(data));
      yield put(updateSubscriptionSuccess('Subscription updated.'))
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      console.log('error', JSON.stringify(e))
      yield put(updateSubscriptionFailed(errorMessage))
    }
  }    
  
  export function* watchUpdateSubscriptionSaga() {
    yield takeLatest(UPDATE_SUBSCRIPTION.LOAD, updateSubscriptionSaga)
  }