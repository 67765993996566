import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import {FkPrimeContext} from '../../../../common/context'
import {resetPaypal} from '../../../../common/helper/utility'

const PaypalButton = () => {
    const value = useContext(FkPrimeContext);
    const {userBoost, session, purchaseCoin, resetState, pillSelect} = value
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const boostDetails = userBoost && userBoost.buyCoins && userBoost.buyCoins.coinsArr ? userBoost.buyCoins.coinsArr : [] 
    const total = userBoost && userBoost.buyCoins && userBoost.buyCoins.total ? userBoost.buyCoins.total : 0

    const [lifetimeItem, setLifetimeItem] = useState([])

    const processPurchaseUnits = () => {
        let arr = []
        boostDetails.map(data => {
            const price = data && data.sg_price ? data.sg_price : 1
            const quantity = data && data.quantity ? data.quantity : 1

            const obj = {
                name: data.name,
                description: data.description,
                unit_amount: {
                    currency_code: "SGD",
                    value: price.toFixed(2)
                },
                quantity: quantity
            }
            arr.push(obj)
        })
        setLifetimeItem(arr)
    }

    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()
    const [paypalError, setPaypalError] = useState(false)
    const service_name = `${boostDetails.name}${boostDetails.subname}`.trim()

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
         processPurchaseUnits()
    }, [boostDetails])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [boostDetails])

    // console.log('userBoost', userBoost)

    const handleForcePurchase = () => {
        const line_items_data = parsedLineItems(boostDetails, 'paypal')
        const boostData = {credit: null, payment_method: 'paypal', type: 'coin_bundle', total_amount: total.toFixed(2), line_items_detail: line_items_data }
        purchaseCoin(boostData, { order_id: 'order.id' })
    }

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
            window.paypal.Buttons({
                style: {
                    label: 'paypal',
                    width: '50%'
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                description: 'Fk Coin',
                                amount: {
                                    currency_code: "SGD",
                                    value: total.toFixed(2),
                                    breakdown: {
                                        item_total: {
                                            currency_code: "SGD",
                                            value: total.toFixed(2)
                                        }
                                    }
                                },
                                items: lifetimeItem
                            }
                        ]
                    })
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture()
                    if (order.status === 'COMPLETED') {
                        const line_items_data = parsedLineItems(boostDetails, 'paypal')
                        const boostData = {credit: null, payment_method: 'paypal', type: 'coin_bundle', total_amount: total.toFixed(2), line_items_detail: line_items_data }
                        purchaseCoin(boostData, { order_id: order.id })

                    } else {
                        setPaypalError("Error processing payment, try again.")
                    }
                },
                onError: async (e) => {
                    const error = await e
                    console.log('error', error)
                    setPaypalError(JSON.stringify(error))
                    setTimeout(() => {
                        setPaypalError(false)
                        resetState()
                    }, 3000)
                }
            }).render(paypalRef);         
            })
        }
    }
 
    return (
        <div>
            <div>
                    { paypalError ? 
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ color: 'red' }}>{paypalError}</p>
                        </div> 
                    : 
                        <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                            <div ref={v => (paypalRef = v)}></div>
                            {/* <button onClick={() => handleForcePurchase()}>Force Purchased</button> */}
                        </div>
                    }
            </div>
        </div>
    );
}

export default PaypalButton;