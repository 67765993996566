
import React, { useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { AdminLoginContext } from "../index";
import { style } from '../style'
import { theme } from '../../../../common/theme'

export const LoginForm = () => {
    const value = useContext(AdminLoginContext);
    const { isLoading, error, userInput, handleChange, handleSubmit, parseError, captchaChange } = value

    return (
        <React.Fragment>
          <div className='admin-forgot-password-container '>{isLoading ? null : <Link to='/'>Back to Home</Link>}</div>
          <div className='text-align-center'><h2>{`Admin Login`}</h2></div>
            {isLoading ? <p>Loading...</p>
              : <div>
                  <form onSubmit={handleSubmit}>
                    <div className='admin-form-group'>
                      <label className='admin-login-label'>{`Email`}</label>
                      <input className='admin-login-form-input' type="email" name="userEmail"  value={userInput.userEmail} onChange={handleChange} required />
                    </div>
                    <div className='admin-form-group'>
                      <label className='admin-login-label'>{`Password`}</label>
                      <input className='admin-login-form-input' minLength={8} type="password" name="userPassword" value={userInput.userPassword} onChange={handleChange} required />
                    </div>
                    <div className="login-recaptcha direction-column">
                        <ReCAPTCHA sitekey={theme.text.sitekey} onChange={() =>captchaChange()} />
                        {userInput.inputError ? <div className="flex"><p className='captcha-error'>{userInput.inputError}</p></div> : null}
                    </div>
                    { error ? <div> {parseError(error)} </div> : null}
                    <input className='admin-blue-button pointer' type="submit" value="Login" />
                  </form>
                </div>
            }
        </React.Fragment>
    );
}

export const ForgotPasswordForm = () => {
    const value = useContext(AdminLoginContext);
    const { forgotPasswordIsLoading, forgotPasswordData, forgotPasswordError, emailInput, handleEmailChange, handleForgotSubmit, parseError, toggleForgot, forgotCaptchaChange } = value
    return (
        <React.Fragment>
          <div className='display-flex justify-content-flex-start'>
            {forgotPasswordIsLoading ? null : <Link onClick={() => toggleForgot()} to='/admin'><i className="fas fa-arrow-left" style={{fontSize: 20}}></i></Link> }
          </div>
          <div className='text-align-center'><h2>{`Admin Forgot Password`}</h2></div>
            {forgotPasswordIsLoading ? <p>Loading...</p>
              : <div>
                    <form onSubmit={handleForgotSubmit}>
                    <div className='admin-form-group'>
                        <label className='admin-login-label'>{`Email`}</label>
                        <input className='admin-login-form-input' type="email" name="email"  value={emailInput.email} onChange={handleEmailChange} required />
                    </div>
                    <div className="login-recaptcha direction-column">
                        <ReCAPTCHA sitekey={theme.text.sitekey} onChange={(e) =>forgotCaptchaChange(e)} />
                        { emailInput.inputError ? <div className="flex"><p className='captcha-error'>{emailInput.inputError}</p></div> : null}

                    </div>
                    { forgotPasswordError ? <div> {parseError(forgotPasswordError)} </div> : null}
                    { forgotPasswordData ? <p className='color-green'>{forgotPasswordData}</p> : <input className='admin-blue-button pointer' type="submit" value="Send" />}
                    </form>
                </div>
            }
        </React.Fragment>
    );
}

