import React, { useContext, useReducer, useEffect } from 'react';
import { AdminCategoryContext } from '../../../../../../common/context';
import { labelSwitch, renderStatusOptions, renderCategoryOptions } from '../../../../../../common/helper/utility'

export const CategoryList = () => {
    const value = useContext(AdminCategoryContext);
    const { adminCategoryData, fetchCategoryDetail, indexToShow, setIndexToShow, showAll } = value

    const renderChild = (data, parentIndex) => {
        let arrHolder = []
        // eslint-disable-next-line
        data.map((item2, index2) => {
            const isIndex = indexToShow === parentIndex ? true : false
            const name = item2.oc_t_category_descriptions && item2.oc_t_category_descriptions.s_name ? item2.oc_t_category_descriptions.s_name : ''
            const id = item2.oc_t_category_descriptions && item2.oc_t_category_descriptions.fk_i_category_id ? item2.oc_t_category_descriptions.fk_i_category_id : 0
            arrHolder.push(
                <tr onClick={() => fetchCategoryDetail(item2)} className='tr-child-data-container pointer' key={index2} style={{display: showAll  ? 'flex' : isIndex ? 'flex' : 'none', marginLeft: 50, marginRight: 50}}>
                    <td style={{flex: 1}}>{id}</td>
                    <td style={{flex: 10}}>{name}</td>
                    <td style={{flex: 1, color: item2.b_enabled ? 'green' : 'red'}}>{item2.b_enabled ? 'Active' : 'Not Active'}</td>   
                </tr>
            )
            
        })

        return arrHolder
    }

    return (
        <div className='main-container'>
            <div></div>
                {adminCategoryData === 0 ? <p style={{textAlign: 'center'}}>No sale found</p> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr style={{backgroundColor: '#505050', color: 'white'}}>
                                <th>Name</th>
                            </tr>
                            {adminCategoryData.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr className='tr-data-container' key={index} onClick={() => setIndexToShow(index)} >
                                            <td><b>{item.parent}</b></td>
                                        </tr>
                                        {renderChild(item.data, index)}
                                    </React.Fragment>

                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const CategoryModal = () => {
    const value = useContext(AdminCategoryContext);

    const { 
        isShow, closeItemModal, categoryDetailData, updateItem, adminUpsertCategoryIsLoading, adminUpsertCategoryError, adminUpsertCategorySuccess, adminCategorySelection
    } = value

    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    useEffect(() => {
        if (categoryDetailData) {
            if (categoryDetailData.pk_i_id) {
                const data = categoryDetailData
                let input = {}
                for (var k in data) {
                    if (data.hasOwnProperty(k)) {
                        if (k === 'oc_t_parent_category_descriptions') {
                            input[k] = data[k] && data[k].s_name ?  data[k].s_name : ''
                        } 
                        else if(k === 'oc_t_category_descriptions'){
                            input['oc_t_category_descriptions'] = data[k] && data[k].s_name ?  data[k].s_name : ''
                        }
                        else {
                            input[k] = data[k]
                        }
                    }
                }
                setItemInput(input)
            } else {
                setItemInput({
                    b_enabled: 1,
                    fk_i_parent_id: 0,
                    oc_t_parent_category_descriptions: '',
                    oc_t_category_descriptions: ''
                })
            }
        }
    },[])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue =  evt.target.value === 'true' ? true : evt.target.value === 'false' ? false :  evt.target.value;

        setItemInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                errorArray.push(<p key={key} style={{color: 'red'}}>{object[key].toString().replace('data.', '')}</p>)
            }
        }
        return errorArray
    }

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (k === 'oc_t_parent_category_descriptions') {
                    arr.push(
                        <div key={k} style={{marginBottom: 10, fontSize: 16}}>
                            <div><label><b>{labelSwitch(k)}</b></label></div>
                            <select name={'fk_i_parent_id'} value={data.fk_i_parent_id} onChange={handleChange}>
                                {renderCategoryOptions(adminCategorySelection, data.fk_i_parent_id)}
                            </select>
                        </div>
                    )
                }
                else if(k === 'oc_t_category_descriptions'){
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} required/>
                        </span>
                    )              
                } 
                else if(k === 'b_enabled'){
                    arr.push(
                        <div key={k} style={{marginBottom: 10, fontSize: 16}}>
                            <div><label><b>{labelSwitch(k)}</b></label></div>
                            <select name={k} value={data[k]} onChange={handleChange}>
                                {renderStatusOptions(data[k])}
                            </select>
                        </div>
                    )                
                }
                else if((k === '__typename') || (k === 'fk_i_parent_id')){
                }
                // else if(k === 'i_position'){
                //     arr.push(
                //         <span key={k}>
                //             <label ><b>{labelSwitch(k)}</b></label>
                //             <input type="text" onChange={handleChange} name={k} value={data[k]}/>
                //         </span>
                //     )
                // }
                else {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} disabled={true}/>
                        </span>
                    )
                }
            }
        }
        return arr
    }
    
    const handleSubmit = (event, itemInput) => {
        event.preventDefault()
        setItemInput({})
        updateItem(event, itemInput)
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeItemModal()}>&times;</span>
                {            
                    adminUpsertCategoryIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                        adminUpsertCategorySuccess ? <div className="message-container"><p className='success-message'>{adminUpsertCategorySuccess}</p></div> :
                            <div className="container">
                                <form onSubmit={(event) => handleSubmit(event, itemInput)}>
                                {renderInputs()}
                                { adminUpsertCategoryError ? <div>{parseError(adminUpsertCategoryError)}</div>  : null }
                                <input type="submit" value={categoryDetailData.pk_i_id ? "Update Category" : "Add Category" } className="sellButton pointer" style={{margin: '10px 0', alignSelf: 'flex-start'}}/>
                                </form>
                            </div>
                } 
                </div>
        </div>
    )
}