import React from 'react';
import { Switch, BrowserRouter,Route, Redirect} from 'react-router-dom';
import { publicRoutes, privateRoutes, protectedRoutes } from './routes'
import PublicRoute from './public-routes'
import PrivateRoute from './private-routes'
import ProtectedRoute from './protected-routes'
import Header from '../../common/component/header'
import Footer from '../../common/component/footer'
import MobileNavbar from '../../common/component/header/mobile'

import PusherComponent from '../../common/component/pusher'
import AntiScamAlert from '../../common/component/alert/AntiScamAlert'
import PaypalScreen from '../app/paypal'

const home_alert = localStorage.getItem('home-alert');

const Router = (session) => (
    <BrowserRouter>
      {/* {home_alert ? null : <AntiScamAlert type='home'/>} */}
      {
        !session.fkv2_session ? <Header session={session}/> : 
          session.fkv2_session && session.fkv2_session.type !== 'admin' ? <Header session={session}/> : null
      }
      <PusherComponent session={session}/>
      <Switch>
        <Route path="/apple-app-site-association" onEnter={() => window.location.reload()} />
        <Route path="/ads.txt" onEnter={() => window.location.reload()} />
        {publicRoutes.map(({
          key, exact, path, component, layout, allowedRoles,
        }) => (
          <PublicRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            layout={layout}
            allowedRoles={allowedRoles}
            session={session}
          />
        ))}
  
        {privateRoutes.map(({
          key, exact, path, component, layout, allowedRoles,
        }) => (
          <PrivateRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            layout={layout}
            allowedRoles={allowedRoles}
            session={session}
        />
        ))}

        {protectedRoutes.map(({
          key, exact, path, component, layout, allowedRoles,
        }) => (
          <ProtectedRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            layout={layout}
            allowedRoles={allowedRoles}
            session={session}
          />
        ))}
        <Route path="/health">
          <h3 className='health-style'>The App is Healthy</h3>
        </Route>
        <Route path="/paypal/yrFW4AyuI6AUHdby*" component={PaypalScreen} />
        {/* <Route path="/apple-app-site-association" onEnter={reload} />
        <Route path="/ads.text" onEnter={reload} /> */}
        <Route key='404' path='*' exact={true}>
          {/* <Redirect to='/page-not-found'/> */}
          <Redirect to='/login'/>
        </Route>
      </Switch>  
      {
        !session.fkv2_session ? <Footer session={session}/> : 
          session.fkv2_session && session.fkv2_session.type !== 'admin' ? <Footer session={session}/> : null
      }
      {/* <MobileNavbar session={session}/> */}
      {
        !session.fkv2_session ? <MobileNavbar session={session}/> : 
          session.fkv2_session && session.fkv2_session.type !== 'admin' ? <MobileNavbar session={session}/> : null
      }
    </BrowserRouter>
  );
  
  export default Router;