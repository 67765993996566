import { USER_ITEM } from '../constant'

export const itemUserLoad = (payload) =>{
    return {
        type: USER_ITEM.LOAD,
        payload
    }
}    

export const itemUserSuccess = (payload) =>{
    return {
        type: USER_ITEM.SUCCESS,
        payload,
    }
}

export const itemUserLoadMore = (payload) => {
    return {
        type: USER_ITEM.LOAD_MORE,
        payload
    }
}

export const itemUserLoadMoreSuccess = (payload) => {
    return {
        type: USER_ITEM.LOAD_MORE_SUCCESS,
        payload
    }
}


export const itemUserFailed = (payload) =>{
    return {
        type: USER_ITEM.FAILED,
        payload,
    }
}

export const itemUserRealtimeUpdate = (payload) =>{
    return {
        type: USER_ITEM.REALTIME_FIELD_UPDATE,
        payload,
    }
}

export const itemUserReset = () =>{
    return {
        type: USER_ITEM.RESET,
    }
}




