import { FILTER } from '../constant'

export const setRecentPriceFilter = (payload) => {
    return {
        type: FILTER.SET_RECENT_PRICE,
        payload
    }
}

export const resetPriceFilter = (payload) => {
    return {
        type: FILTER.RESET,
        payload
    }
}