import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const LayoutNoCategory = ( { component: Component, type, session, ...rest } ) => (
    <React.Fragment>
      {/* <BannerAd /> */}
      <Component {...rest} session={session}/>
    </React.Fragment>
);

LayoutNoCategory.propTypes = {
    component: PropTypes.any
};

export default LayoutNoCategory