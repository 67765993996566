import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getLogs = (payload) => {
    const { first, page } = payload
    try {
        const query = gql `query getLogs($first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            getLogs(first: $first, page: $page,
              orderField: $orderField, orderType: $orderType
            ){
                count
                logs {
                  dt_date
                  s_section
                  s_action
                  fk_i_id
                  s_data
                  s_ip
                  s_who
                  fk_i_who_id
                  doer
                }
            }
          }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderField: 'dt_date', orderType: 'DESC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }

//   export const subscriptionSearch = (data) => {
//     const {search, orderField, orderType, first } = data 
//     try {
//         const query = gql`query subscriptionSearch($search: String, $first: Int, $page: Int, $orderField: String,
//           $orderType: SortOrder
//           ){
//             subscriptionSearch(first: $first, page: $page,
//               orderField: $orderField, orderType: $orderType, search: $search
//             ){
//               count
//               subscription {
//                 transaction_id
//                 id
//                 type
//                 name
//                 user_id
//                 oc_t_user {
//                     pk_i_id
//                     s_username
//                 }
//                 subscription_status
//                 purchase_date
//                 payment_method
//                 due_date
//               }
//             }
//           }`;
  
//         const inputs = {
//           search: search,
//           orderField: orderField,
//           orderType: orderType,
//           first: parseInt(first)
//         }
          
//         const response = client.query({
//             query: query,
//             variables: inputs
//         });
  
//         const data = response ? response : null;
//         return data;
//     } catch (e) {   
//         const error = e && e.graphQLErrors ? e.graphQLErrors : e
//         throw error
//     }
//   }
  
export default {
    getLogs,
    // getSubscriptionDetail,
    // updateSubscription,
    // subscriptionSearch
}