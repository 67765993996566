import React, {useContext, useCallback} from 'react'
import {Text, IconContainerClickable, Divider, PriceTextWithSymbol, LinkText, MobileHeader, MobileFooter, PaymentSuccess, PaymentUnsuccessful} from './customComponent'
import { FkPrimeContext } from '../../../common/context'
import {CustomLoader} from '../../../common/component/loader'
import { theme } from '../../../common/theme'
import {CloseIcon, BackButton, CoinsIcon, BackIcon} from '../../../assets/svg'

export const IndividualAdsModal = () => {
    const {userBoost, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, handleShowIndividualAdsModal, session, purchaseCoin, sessionData} = useContext(FkPrimeContext)
    const { individualAdsArr, individualAdsTotal } = userBoost
    const {fkPrimePurchaseDisclaimer} = theme.text
    const totalAmount = individualAdsTotal ? individualAdsTotal.toFixed(2) : 0
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const wallet = sessionData && sessionData.wallet ? sessionData.wallet : 0
    // console.log('userBoost', userBoost)

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [individualAdsArr])

    const handleIndividualAdsPurchase = () => {
        const line_items_data = parsedLineItems(individualAdsArr, 'coin')
        const boostData = {payment_method: 'coin', type: 'individual_ad', total_amount: totalAmount, line_items_detail: line_items_data }
        // console.log("individualAdsArr", line_items_data)
        purchaseCoin(boostData, { order_id: null})
    }

    return (
        <div className={isLoadingPurchaseCoin ? `` : `purchase-main-body-modal-container ${purchaseSuccessMessage || errorPurchaseCoin ? `flex flex-justify-center flex-align-center` : `pt-30`}`}>
            <div className={isLoadingPurchaseCoin ? `` : `purchase-main-modal-body-container ${purchaseSuccessMessage || errorPurchaseCoin ? `h-px-462` : `mt-50`}`}>
                {
                    isLoadingPurchaseCoin ? null :
                    purchaseSuccessMessage ? <div className="w-100 h-100 flex flex-justify-center text-align-center"><PaymentSuccess /></div> :
                    errorPurchaseCoin ?  <div className="w-100 h-100 flex flex-justify-center flex-align-center text-align-center"><PaymentUnsuccessful text={errorPurchaseCoin} /></div> : 

                    <>
                         <div className="flex flex-justify-between w-100 p-5">
                            <div className="">
                                <IconContainerClickable 
                                    styleClass="cursor-pointer"
                                    clickFunc={() => handleShowIndividualAdsModal(false)}
                                    Icon={<BackButton/>}
                            />
                            </div>
                            <div className="">
                                <IconContainerClickable 
                                    styleClass="cursor-pointer"
                                    clickFunc={() => handleShowIndividualAdsModal(false)}
                                    Icon={<CloseIcon/>}
                                />
                            </div>
                        </div>
                        <div className="pl-12 pr-12">
                            <Text
                                styleClass="fk-prime-purchase-modal-title-container mt-34 mb-30"
                                text="Current Coins"
                                textClass="fk-prime-purchase-modal-title-p"
                            />
                            <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                                <div className="flex flex-align-center ">
                                    <CoinsIcon/> <p className='ml-4'>{wallet}</p>
                                </div>
                                <div>
                                    <p className='add-coin-text'>
                                        Add Coins
                                    </p>
                                </div>
                            </div>
                            <div className="mt-30">
                                <Divider />
                            </div>
                            <div className=" mt-30 mb-20">
                                <Text 
                                    styleClass="w-100 flex flex-justify-center flex-align-center"
                                    text="Purchase"
                                    textClass="fk-prime-purchase-modal-title-p"
                                />
                            </div>
                            {
                                individualAdsArr.map((b, i) => {
                                    var options = { day: 'numeric', month: 'short', year: 'numeric' };
                                    const currentDate = new Date().toLocaleString("en-AU", options)
                                    return (
                                        <div key={i} className="flex flex-d-row flex-justify-between mt-20">
                                            <div className="flex flex-d-column">
                                                <Text
                                                    styleClass=""
                                                    text={`${b.name}`}
                                                    textClass="fk-prime-purchase-title-p"
                                                />
                                                <Text
                                                    styleClass=""
                                                    text={`${b.description}`}
                                                    textClass="fk-prime-purchase-subtitle-p"
                                                />
                                            </div>
                                            <div className="flex flex-d-column">
                                                <PriceTextWithSymbol
                                                    styleClass="flex-justify-end fk-prime-purchase-price-container"
                                                    symbol={<CoinsIcon/>}
                                                    symbolClass="fk-prime-purchase-symbol-p"
                                                    priceClass="fk-prime-purchase-price-p ml-4"
                                                    price={b.credit.toFixed(2)}
                                                />
                                                <Text
                                                    styleClass="flex flex-justify-end"
                                                    text={currentDate}
                                                    textClass="fk-prime-purchase-due-date-p"
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                                <Text
                                    styleClass=""
                                    text="Total"
                                    textClass="fk-prime-purchase-total-label-p"
                                />
                            <PriceTextWithSymbol
                                    styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                                    symbol={<CoinsIcon/>}
                                    symbolClass="fk-prime-purchase-total-symbol-p"
                                    priceClass="fk-prime-purchase-total-price-p ml-4"
                                    price={totalAmount}
                                />

                            </div>
                            <div className='mt-20'>
                                <p className='ads-disclaimer'>
                                    {`*Note: - ${fkPrimePurchaseDisclaimer[3].text}`}
                                </p>
                            </div>
                            <div className={`mt-30 mb-30 flex flex-justify-center flex-align-center`}>
                            {
                            // isLoadingPurchaseCoin ? 'Processing...' :
                            //     purchaseSuccessMessage ? <p style={{color: 'green'}}>{purchaseSuccessMessage}</p> :
                            //         errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> : 
                                    <div className={`fk-prime-purchase-btn cursor-pointer`} onClick={() => handleIndividualAdsPurchase()} >
                                        <p className="fk-prime-purchase-p">{`Purchase`}</p>
                                    </div>
                            }
                            </div>
                            <div className=" fk-prime-purchase-modal-footer-container">
                                <Text
                                    styleClass=""
                                    text="By purchasing any of the above, you accept our"
                                    textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                                />
                                <LinkText
                                    path="/terms-of-use"
                                    text="Terms of Services."
                                    textClass="fk-prime-purchase-modal-footer-link-p"
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export const IndividualAdsPurchaseModalMobile = () => {
    const {userBoost, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, handleShowIndividualAdsModal, session, purchaseCoin, sessionData, handleBuyCoinsModal} = useContext(FkPrimeContext)
    const { individualAdsArr, individualAdsTotal } = userBoost
    const {fkPrimePurchaseDisclaimer} = theme.text
    const totalAmount = individualAdsTotal ? individualAdsTotal : 0
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const wallet = sessionData && sessionData.wallet ? sessionData.wallet : 0
    const canPurchase = totalAmount <= wallet ? true : false

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [individualAdsArr])

    const handleIndividualAdsPurchase = () => {
        const line_items_data = parsedLineItems(individualAdsArr, 'coin')
        const boostData = {payment_method: 'coin', type: 'individual_ad', total_amount: totalAmount, line_items_detail: line_items_data }
        purchaseCoin(boostData, { order_id: null})
    }

    return (
        <div className="purchase-main-body-mobile-modal-container">
             <MobileHeader 
                styleClass="w-100 fk-prime-mobile-header-container"
                leftStyleClass="flex flex-d-row flex-align-center"
                leftTextClass="fk-prime-left-header-text-p"
                leftFunc={handleShowIndividualAdsModal}
                leftIcon={isLoadingPurchaseCoin || purchaseSuccessMessage ? null : <BackIcon />}
                leftText={ isLoadingPurchaseCoin || purchaseSuccessMessage ? `` : `Back`}
                centerStyleClass="fk-prime-center-header-container"
                centerTextClass="fk-prime-center-header-text-p"
                centerText="Subscriptions"
                rightStyleClass="fk-prime-right-header-container"
                />
                <Divider />
            {
                isLoadingPurchaseCoin ? null:
                purchaseSuccessMessage ? <div className="w-100 h-100 flex flex-justify-center text-align-center"><PaymentSuccess /> </div> :
                errorPurchaseCoin ?  <div className="w-100 h-100 flex flex-justify-center text-align-center"><PaymentUnsuccessful text={errorPurchaseCoin}/> </div> : 
                <>
                    <div className="purchase-mobile-individual-ads-main-body-container">
                        <div className="purchase-mobile-individual-ads-current-coins-container">
                            <p className="individual-plans-current-coins-label-p">{`Current Coins`}</p>
                            <div className="flex flex-d-row flex-align-center">
                                <CoinsIcon/>
                                <p className="individual-plans-current-coins-int-p">{wallet}</p>
                            </div>
                            <p className="individual-plans-current-coins-error-p">{canPurchase ? `` : ` Oh no! Insufficient Funds. Buying coins will help.`}</p>
                        </div>
                        <Divider />
                        <div className="w-100 flex flex-d-column">
                            <div className="w-100 flex flex-align-center flex-justify-center mt-30">
                                <p className="individual-plans-purchase-label-p">{`Purchase`}</p>
                            </div>
                            {
                                individualAdsArr.map((b, i) => {
                                    var options = { day: 'numeric', month: 'short', year: 'numeric' };
                                    const currentDate = new Date().toLocaleString("en-AU", options)
                                    return (
                                        <div key={i} className="flex flex-d-row flex-justify-between mt-20">
                                            <div className="flex flex-d-column">
                                                <Text
                                                    styleClass=""
                                                    text={`${b.name}`}
                                                    textClass="fk-prime-purchase-title-p"
                                                />
                                                <Text
                                                    styleClass="fk-prime-purchase-title-container"
                                                    text={`${b.description}`}
                                                    textClass="fk-prime-purchase-subtitle-p"
                                                />
                                            </div>
                                            <div className="flex flex-d-column">
                                                <PriceTextWithSymbol
                                                    styleClass="flex-justify-end fk-prime-purchase-price-container"
                                                    symbol={<CoinsIcon/>}
                                                    symbolClass="fk-prime-purchase-symbol-p"
                                                    priceClass="fk-prime-purchase-price-p ml-4"
                                                    price={b.credit}
                                                />
                                                <Text
                                                    styleClass="flex flex-justify-end"
                                                    text={currentDate}
                                                    textClass="fk-prime-purchase-due-date-p"
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                                <Text
                                    styleClass=""
                                    text="Total"
                                    textClass="fk-prime-purchase-total-label-p"
                                />
                            <PriceTextWithSymbol
                                    styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                                    symbol={<CoinsIcon/>}
                                    symbolClass="fk-prime-purchase-total-symbol-p"
                                    priceClass="fk-prime-purchase-total-price-p ml-4"
                                    price={totalAmount}
                                />

                            </div>
                            <div className='mt-20'>
                                <p className='ads-disclaimer'>
                                    {`*Note:`}
                                </p>
                                <p className='ads-disclaimer'>{`- ${fkPrimePurchaseDisclaimer[3].text}`}</p>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    {/* <div className=" fk-prime-purchase-modal-footer-container"> */}
                    <div className="w-100 flex flex-d-column flex-justify-center flex-align-center mt-20">
                        <Text
                            styleClass=""
                            text="By purchasing any of the above, you accept our"
                            textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                        />
                        <LinkText
                            path="/terms-of-use"
                            text="Terms of Services."
                            textClass="fk-prime-purchase-modal-footer-link-p"
                        />
                    </div>
                    {/* </div> */}
                    <MobileFooter boost={individualAdsTotal} isCoinPurchase={true} handleFooter={canPurchase ? handleIndividualAdsPurchase : null} buyCoinsFunc={handleBuyCoinsModal}/>
                </>
            }
            
        </div>
    )
}