import React from 'react'
import { useHistory } from 'react-router-dom'

const MobileNavbar = (props) => {

    let history = useHistory()
    const rightLabel = props && props.details && props.details.right && props.details.right.title ? props.details.right.title : ''
    const centerLabel = props && props.details && props.details.center && props.details.center.title ? props.details.center.title : ''
    const leftLabel = props && props.details && props.details.left && props.details.left.title ? props.details.left.title : ''
    const rightAction = props && props.details && props.details.right && props.details.right.action ? props.details.right.action : props && props.details && props.details.rightAction ? props.details.rightAction : null 
    const leftAction = props && props.details && props.details.left && props.details.left.action ? props.details.left.action : ''

    const isLoading = props && props.loading ? props.loading : false
    const isRightNotReady = props && props.rightReady ? props.rightReady : false
    const isLeftNotReady = props && props.leftReady ? props.leftReady : false
    return (
            isLoading ? null :
            <div className="w-100 flex flex-d-row mobile-navbar-main-body-container flex-justify-center flex-align-center">
            <div onClick={(e) => isLeftNotReady ? history.push('/') : leftAction(e)}>
                <p className={`mobile-navbar-left-title-p `}>{leftLabel}</p>
            </div>
            <div className="w-100 flex flex-align-center flex-justify-center">
                <p className="mobile-navbar-center-title-p">{centerLabel}</p>
            </div>
            <div onClick={(e) => isRightNotReady ? null : rightAction(e)}>
                <p className={`mobile-navbar-right-title-p ${isRightNotReady ? `opacity-p-6` : ``}`}>{rightLabel}</p>
            </div>
        </div>
        
    )
}

export default MobileNavbar