import React, { useReducer, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom'
import { cPasswordLoad , cPasswordClearMessage, logoutLoad} from '../../../../redux/action'
import { theme  } from '../../../../common/theme'
import {CustomLoader} from '../../../../common/component/loader'
import {ChangePasswordLabel, CancelBtn, SuccessfulUI} from './customComponents'
import MobileNavbar from '../../../../common/component/mobileNavbar'
import {EyeIcon, CrossEyeIcon} from '../../../../assets/svg'

const ChangePassword = props => {
  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null
  const { cPasswordIsLoading, cPasswordError, cPasswordData} = props.user
  let history = useHistory()
  const [isNotReady, setIsNotReady] = useState(true)
  const [isCurrentPeak, setIsCurrentPeak] = useState(false)
  const [isNewPeak, setIsNewPeak] = useState(false)
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      id: parseInt(userId),
      currentPassword: '',
      password: '',
      // rePassword: '',
      type: 'user',
      error: ''
    }
  );

  const handleChange = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({[name]: newValue});
    if(userInput.currentPassword && name === 'password' && newValue.length >= 4){
      setIsNotReady(false)
    }
    if(userInput.currentPassword && name === 'password' && newValue.length <= 3){
      setIsNotReady(true)
    }
  }

  const handleSubmit = evt => {
    evt.preventDefault();
    if(userInput.currentPassword && userInput.password){
    props.cPasswordLoad(userInput)
    }else{
      setUserInput({['error']: 'Current password and new password must be filled'})
    }
  }

  const parseError = (error) => {
    let errorArray = []
    if (typeof error === 'string') {
      errorArray.push(<p key={1} style={{color: 'red'}}>{error}</p>)
    } else {
      for (var key in error) {
        if (error.hasOwnProperty(key)) {
            errorArray.push(<p key={key} style={{color: 'red'}}>{error[key].toString().replace('input.', '')}</p>)
        }
      }
    }
    return errorArray
  }

  //hooks to clear error / message
  // useEffect(() => {
   
  //   if (cPasswordData) {
  //     setTimeout(() => {
  //     props.cPasswordClearMessage()
  //   }, 3000)
  // }
  // eslint-disable-next-line
  // }, [props.user.cPasswordError, props.user.cPasswordData])

  useEffect(() => {
     if (cPasswordError) {
      setTimeout(() => {
      props.cPasswordClearMessage()
      }, 3000)
    }
    if(cPasswordData){
      setTimeout(() => {
        props.logoutLoad()
        history.push('/login')
      }, 5000)
    }
  }, [cPasswordData])

  const WebContainer = () => {
    return (
      cPasswordIsLoading ? <div className="p-absolute w-100 success-n-load-main-body-container"><CustomLoader /> </div> :
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> : cPasswordData ? <div className="success-and-loads-main-body-container" onClick={() => {props.logoutLoad(); history.push('/login')}}><div className="p-absolute w-100 success-n-load-main-body-container"><SuccessfulUI /></div></div> :
        <div className="w-100 change-password-main-body-container">
          <div className="w-100 change-password-sub-body-container">
            {
               
               <>
               <ChangePasswordLabel />
               <div className="flex flex-d-column flex-align-center flex-justify-center">
                   <div className={`change-password-form-input ${cPasswordError ? `border-error` : `mb-42`}`}>
                     <label className="change-password-label">{`Current Password`}</label>
                     <input name="currentPassword" type={isCurrentPeak ? `text` : `password`} placeholder="Enter your current password" className="change-password-input-field" value={userInput.currentPassword} onChange={(e) => handleChange(e)} required/>
                     <div className="cursor-pointer" onClick={() => setIsCurrentPeak(!isCurrentPeak)}>
                      {isCurrentPeak ? <CrossEyeIcon /> :  <EyeIcon />}
                     </div>
                   </div>
                   { cPasswordError ? <div className="c-password-error-container"> {parseError(cPasswordError)} </div> : null}
                   <div className="change-password-form-input">
                   <label className="change-password-label">{`New Password`}</label>
                   <input name="password" type={isNewPeak ? `text` : `password`} placeholder="Enter your new password" className="change-password-input-field" value={userInput.password} onChange={(e) => handleChange(e)} required/>
                   <div className="cursor-pointer" onClick={() => setIsNewPeak(!isNewPeak)}>
                      {isNewPeak ? <CrossEyeIcon /> :  <EyeIcon />}
                     </div>
                     {userInput.password && userInput.password.length <= 3 ? <div className="w-100 flex flex-justify-start p-absolute t-100percent left-0"><p className="password-below-4-p">{`Must be at least 4 characters.`}</p></div> : null}
                 </div>
               </div>
               </>
            }
          </div>
          {
            cPasswordIsLoading || cPasswordData ? null :
            <div className="flex flex-d-row w-100 flex-justify-end mt-20">
            <CancelBtn />
            <div className={`change-password-save-btn ml-30 cursor-pointer ${isNotReady ? `opacity-p-6` : ``}`} onClick={(e) => !isNotReady? handleSubmit(e) : null}>
              <p className="change-password-save-p">{`Save`}</p>
            </div>
        </div>
          }
         
        </div>

    )
  }

  const MobileDetails = {
    right: {
      title: 'Save'
    },
    center: {
      title: 'Change Password'
    },
    left: {
      title: 'Cancel'
    }
  }

  const MobileContainer = () => {
    return (
      <div className="w-100 h-100">
        <MobileNavbar details={{...MobileDetails, rightAction: handleSubmit}} rightReady={isNotReady} leftReady={true}/>
        {
          cPasswordData ? <div className="mt-60"> <SuccessfulUI /> </div> : 
          <div className="change-password-sub-body-container">
          <ChangePasswordLabel />
          <div className="flex flex-d-column flex-align-center flex-justify-center">
            <div className={`change-password-form-input ${cPasswordError ? `border-error` : `mb-42`}`}>
              <label className="change-password-label">{`Current Password`}</label>
              <input name="currentPassword" type={isCurrentPeak ? `text` : `password`} placeholder="Enter your current password" className="change-password-input-field" value={userInput.currentPassword}  onChange={(e) => handleChange(e)} required/>
              <div className="cursor-pointer" onClick={() => setIsCurrentPeak(!isCurrentPeak)}>
              {isCurrentPeak ? <CrossEyeIcon /> :  <EyeIcon />}
              </div>
            </div>
                { cPasswordError ? <div className="c-password-error-container"> {parseError(cPasswordError)} </div> : null}
                <div className="change-password-form-input">
                <label className="change-password-label">{`New Password`}</label>
                <input name="password" type={isNewPeak ? `text` : `password`} placeholder="Enter your new password" className="change-password-input-field" value={userInput.password}  onChange={(e) => handleChange(e)} required/>
                <div className="cursor-pointer" onClick={() => setIsNewPeak(!isNewPeak)}>
                {isNewPeak ? <CrossEyeIcon /> :  <EyeIcon />}
                </div>
              </div>
            </div>
        </div>
        }
      </div>
    )
  }

  return (
    window.innerWidth < 600 ? MobileContainer() : WebContainer()
  );
}

const mapStateToProps = store => {
  return {
    user: store.userReducer
  };
};

const mapActionToProps = {
  cPasswordLoad,
  cPasswordClearMessage,
  logoutLoad
}

export default connect(mapStateToProps, mapActionToProps)(ChangePassword);
