import { TOGGLE_LIVE, USE_NOW_PROMOTE, CANCEL_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../constant'    
    const initialState = {
        isLoadingToggleLive: false,
        toggleLiveMessage: false,
        toggleLiveError: false,

        isLoadingUseNowPromote: false,
        useNowPromoteMessage: false,
        useNowPromoteError: false,

        isLoadingCancelSubscription: false,
        cancelSubscriptionMessage: false,
        cancelSubscriptionError: false,

        isLoadingUpdateSubscription: false,
        updateSubscriptionMessage: false,
        updateSubscriptionError: false,
    }
    
export default (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_LIVE.LOAD:
            return {...state, isLoadingToggleLive: true, toggleLiveError: null}

        case TOGGLE_LIVE.SUCCESS:
            return {...state, isLoadingToggleLive: false, toggleLiveError: null, toggleLiveMessage: action.payload}

        case TOGGLE_LIVE.FAIL:
            return {...state, isLoadingToggleLive: false, toggleLiveError: action.payload, toggleLiveMessage: null}

        case TOGGLE_LIVE.RESET:
            return {...state, isLoadingToggleLive: false, toggleLiveError: null, toggleLiveMessage: null}

        case USE_NOW_PROMOTE.LOAD:
            return {...state, isLoadingUseNowPromote: true, useNowPromoteError: null}

        case USE_NOW_PROMOTE.SUCCESS:
            return {...state, isLoadingUseNowPromote: false, useNowPromoteError: null, useNowPromoteMessage: action.payload}

        case USE_NOW_PROMOTE.FAIL:
            return {...state, isLoadingUseNowPromote: false, useNowPromoteError: action.payload, useNowPromoteMessage: null}

        case USE_NOW_PROMOTE.RESET:
            return {...state, isLoadingUseNowPromote: false, useNowPromoteError: null, useNowPromoteMessage: null}

        case CANCEL_SUBSCRIPTION.LOAD:
            return {...state, isLoadingCancelSubscription: true, cancelSubscriptionError: null}

        case CANCEL_SUBSCRIPTION.SUCCESS:
            return {...state, isLoadingCancelSubscription: false, cancelSubscriptionError: null, cancelSubscriptionMessage: action.payload}

        case CANCEL_SUBSCRIPTION.FAIL:
            return {...state, isLoadingCancelSubscription: false, cancelSubscriptionError: action.payload, cancelSubscriptionMessage: null}

        case CANCEL_SUBSCRIPTION.RESET:
            return {...state, isLoadingCancelSubscription: false, cancelSubscriptionError: null, cancelSubscriptionMessage: null}

        case UPDATE_SUBSCRIPTION.LOAD:
            return {...state, isLoadingUpdateSubscription: true, updateSubscriptionError: null}

        case UPDATE_SUBSCRIPTION.SUCCESS:
            return {...state, isLoadingUpdateSubscription: false, updateSubscriptionError: null, updateSubscriptionMessage: action.payload}

        case UPDATE_SUBSCRIPTION.FAIL:
            return {...state, isLoadingUpdateSubscription: false, updateSubscriptionError: action.payload, updateSubscriptionMessage: null}

        case UPDATE_SUBSCRIPTION.RESET:
            return {...state, isLoadingUpdateSubscription: false, updateSubscriptionError: null, updateSubscriptionMessage: null}

        default:
        return state;  
    }
}