import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from 'react-router-dom'
import { categorySearchLoad, categorySearchMoreLoad, resetPriceFilter, categorySearchReset, setRecentPriceFilter } from '../../../redux/action'
import CategoryItemCard from './component'
import ErrorMessage from './component/error'
import Filter from './filter'
import DefaultMeta from '../../../common/component/defaultMeta'

const Category = (props) => {
    const location = useLocation()
    const path = location.pathname
    const categoryName = path.replace(/\//g, '')
    const categoryData = props.categoryData && props.categoryData.data ? props.categoryData.data : []
    const {isLoading, isLoadingMore, total} = props.category
    const data = props && props.category && props.category.data ? props.category.data : []
    const filter = props.filter && props.filter.recentPriceFilter ? props.filter.recentPriceFilter : null
    const minPrice = props.filter && props.filter.price && props.filter.price.minPrice ? props.filter.price.minPrice : 0
    const maxPrice = props.filter && props.filter.price && props.filter.price.maxPrice ? props.filter.price.maxPrice : 1000000
    const pages = props.category && props.category.pages ? props.category.pages : 1
    const session_id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const hasMore = data.length < total - 1 ? true : false
    const getCount = 20
    const [id, setId] = useState(null)

    useEffect(() => {
        return () => cleanUp()
    }, []);

    useEffect(() => {
        categoryData.map((item) => {
            const catName = item && item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name.toLowerCase() : null
            if (catName && catName.toLowerCase() === categoryName) {
                setId(item.pk_i_id)
            }
        })

    },[categoryData])

    useEffect(() => {
        cleanUp()
        if(id){
            props.categorySearchLoad({first: getCount, page: 1, id: id, filter: filter, minPrice: minPrice, maxPrice:maxPrice})
        }
    // eslint-disable-next-line
    },[path, id])

    const cleanUp = () => {
        props.resetPriceFilter()
        props.categorySearchReset()
    }

    useEffect(() => {
        props.categorySearchReset()
        props.categorySearchLoad({first: getCount, page: 1, id: id, filter: filter, minPrice: minPrice, maxPrice:maxPrice})
    // eslint-disable-next-line    
    },[props.filter])

    const handleLoadMore = () => {
        props.categorySearchMoreLoad({first: getCount, page: pages, id: id, filter: filter, minPrice: minPrice, maxPrice:maxPrice})
    }

    return (
        <>
            {/* <DefaultMeta />  */}
            <Filter />
            <div className="category-result-main-container">
                <div className="category-result-container">
            {
                isLoading 
                ? <p style={{marginTop: 15, textAlign: 'center'}}>Loading...</p> : parseInt(minPrice) > parseInt(maxPrice) ? <div>
                    <ErrorMessage action={'wrong_input'}/>
                    </div>
                    :
                    <>
                        <div className="category-results">
                            <CategoryItemCard items={data} session={session_id} />
                        </div>
                            { isLoadingMore ? <p style={{marginTop: 15, textAlign: 'center'}}>Loading...</p> :
                            window.outerWidth < 600 ? 
                                <InfiniteScroll dataLength={data.length} next={handleLoadMore} hasMore={hasMore} loader={<h4>Loading...</h4>} endMessage={
                                    <p style={{textAlign: 'center'}}>
                                        <b>End of result</b>
                                    </p>
                                }/> :
                                !isLoading && data.length === 0 ? <p style={{marginTop: 15, textAlign: 'center'}}></p> :
                                data.length < total - 1 ? 
                                    <div className="paginator-main-container"><div onClick={handleLoadMore} className="loadMoreBtn">{`Load More`}</div></div> : 
                                    <p style={{marginTop: 15, textAlign: 'center'}}><b></b></p>                                    
                            }
                     </>       
                }
            </div>
            </div>
        </>
    );
}

const mapStateToProps = store => {
    return {
        category: store.categorySearchReducer,
        categoryData: store.categoryReducer,
        filter: store.filterReducer
    }
}

const mapActionToProps = {
    categorySearchLoad,
    categorySearchMoreLoad,
    resetPriceFilter,
    categorySearchReset,
    setRecentPriceFilter,
}

export default connect(mapStateToProps, mapActionToProps)(Category)