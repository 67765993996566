import React, { useReducer, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { setRecentPriceFilter } from '../../../../redux/action'
import { theme } from '../../../../common/theme'
import { FilterIcon } from '../../../../assets/svg'
import MobileFilter from './mobile'

const Filter = (props) => {
    const data = theme.text.searchOption
    const { recentPriceFilter, price } = props.filter

    const [minMaxFilter, setMinMaxFilter] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice,
            class: recentPriceFilter
        }
    )

    const handleChange = evt => {
        // evt.preventDefault();
        const name = evt.target.name;
        const newValue = evt.target.value;
        setMinMaxFilter({ [name]: newValue });
    }

    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(!show)
    }
    const filterMobile = useRef(null)

    const handleClickOutside = event => {
        if (filterMobile.current && !filterMobile.current.contains(event.target)) {
            if (show) {
                setShow(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    const handleSubmit = evt => {
        evt.preventDefault();
        props.setRecentPriceFilter(minMaxFilter)
    }

    return (
        <div className="filter-container">
            <div className="filter-flex">
                <div className="filter-head">
                    <p style={{ fontWeight: '600', color: '#232A34' }}>{`Filter`}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="filter-input">
                        <p>{`Sort`}</p>
                        <div className="sort-container">
                            {
                                data.map((option, index) => {
                                    return <div key={index} className="sort-class">
                                        <span style={{ color: '#232A34' }}>{option.label}</span>
                                        <input type="radio" name="class" className="input-filter-radio" value={option.value} onChange={handleChange} defaultChecked={option.value === minMaxFilter.class ? true : null} />
                                    </div>
                                })
                            }
                        </div>

                    </div>
                    <div className="filter-price">
                        <p>Price</p>
                        <div className="price-filter">
                            <input name="minPrice" type="text" placeholder="Minimum" onChange={handleChange} />
                        </div>
                        <div className="price-filter">
                            <input name="maxPrice" type="text" placeholder="Maximum" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="filter-submit">
                        <input type="submit" value="Go" />
                    </div>
                </form>
            </div>
            <div className="mobile-filter-container" onClick={handleClick}>
                <FilterIcon />
            </div>
            <div className={`modal-mobile-bg ${show ? `flex` : `hide`}`} >
                <MobileFilter data={data} show={show} filter={props.filter} click={handleClick} filterM={filterMobile} />
            </div>

        </div>
    );
}

const mapStateToProps = store => {
    return {
        filter: store.filterReducer
    }
}

const mapActionToProps = {
    setRecentPriceFilter
}

export default connect(mapStateToProps, mapActionToProps)(Filter)