import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, Link} from "react-router-dom";
import moment from 'moment'
import MetaTags from 'react-meta-tags';
// import { OverlayLoader } from '../../../../common/component/loader'
import {WholePageLoader, WholePageActivated} from '../../../../common/component/customComponents'
import { useLocation } from 'react-router-dom'
import { itemViewLoad, itemImageEditLoad, itemImageEditReset, itemViewReset, reportLoad, reportReset, sendChatLoad, sendChatReset, itemUpdateByFieldLoad} from '../../../../redux/action'
import { connect } from 'react-redux'
import ViewItem from './component/main'
import {BackMobileButton, EllipsisV, EditIconBtn, TrashIconBtn, ItemFlagIcon, CycleIcon, InfoSubsIcon} from '../../../../assets/svg'
import { getItemIdFromUrl } from '../../../../common/helper/utility'
import Seller from './seller'
import { GApageView } from '../../../../common/googleAnalytics'
import { ReportModal, } from './component/CustomComponent'
import {ContactMobileModal, ItemSoldModal} from './seller/component/CustomComponent'
import { ItemViewContext } from '../../../../common/context'
import {ShareModal} from '../../../../common/component/customComponents'
import Breadcrumb from '../../../../common/component/breadcrumb'

const { REACT_APP_IMGX, REACT_APP_BASE_URL, REACT_APP_S3_CLASSIFIED_FOLDER, REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL } = process.env;

const Views = (props) => {
    const location = useLocation()
    const path = `${location.pathname}${location.search}`
    const trimmedPath = path.split('&description')
    const id = getItemIdFromUrl(path)
    let history = useHistory();
    const loading = props && props.itemView && props.itemView.isLoading ? props.itemView.isLoading : false
    const itemMessage = props && props.itemView && props.itemView.itemMessage ? props.itemView.itemMessage : false
    const itemData = props && props.itemView && props.itemView.data ? props.itemView.data : {}
    const session = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const {imageEditIsLoading, imageEditError, imageEditMessage } = props.itemView
    const item_title = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_title ? itemData.oc_t_item_description.s_title : ''
    const item_desc = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_description ? itemData.oc_t_item_description.s_description : ''
    const itemId = itemData && itemData.pk_i_id ? itemData.pk_i_id : 0
    const ownerId = itemData && itemData.oc_t_user && itemData.oc_t_user.pk_i_id ? itemData.oc_t_user.pk_i_id : 0
    const sessionId = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0

    const itemResourceId = itemData && itemData.oc_t_item_resource && itemData.oc_t_item_resource[0] && itemData.oc_t_item_resource[0].pk_i_id ? itemData.oc_t_item_resource[0].pk_i_id : null
    const itemResourceExtension = itemData && itemData.oc_t_item_resource && itemData.oc_t_item_resource[0] && itemData.oc_t_item_resource[0].s_extension ? itemData.oc_t_item_resource[0].s_extension : 'jpeg'
    const itemResourceSrc = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}${REACT_APP_S3_CLASSIFIED_FOLDER}/uploads/${itemResourceId}.${itemResourceExtension}` : `${REACT_APP_IMGX}${itemResourceId}.${itemResourceExtension}?w=290&min-w=40&h=140&min-h=50&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress`

    const sendMessageLoading = props && props.chat && props.chat.sendIsLoading ? props.chat.sendIsLoading : false
    const sendMessageErrror = props && props.chat && props.chat.sendError ? props.chat.sendError : false
    const sendMessageSuccess = props && props.chat && props.chat.sendMessage ? props.chat.sendMessage : ''
    const { updateIsLoading, updateMessage, updateError } = props && props.itemView
    const sold = itemData && itemData.b_mark_as_sold ? itemData.b_mark_as_sold : itemData.dt_sold ? true : false

    const sellerUserId = itemData && itemData.oc_t_user && itemData.oc_t_user.pk_i_id ? itemData.oc_t_user.pk_i_id : 0
    const [isEditClick, setIsEditClick] = useState(false)
    const [isDeleteClick, setIsDeleteClick] = useState(false)
    const [isContactClicked, setIsContactClicked] = useState(false)
    const [isShareClick, setIsShareClick] = useState(false)
    const categoryName = itemData && itemData.oc_t_category && itemData.oc_t_category.oc_t_category_descriptions && itemData.oc_t_category.oc_t_category_descriptions.s_name ? itemData.oc_t_category.oc_t_category_descriptions.s_name : ''
    const urlifiedItemName = item_title ? item_title.replace(/ /g, '-') : ''

    useEffect(() => {
        if(typeof id === 'number' && id){  
          props.itemViewLoad({id: id, pageviewData: {
            fk_i_item_id: id,
            fk_i_user_id: null,
            fk_i_viewer_id: session,
            pageviewable_type: "ITEM" 
          }})
        }
    },[path])

    const reload = () => {
        if(typeof id === 'number' && id){  
            props.itemViewLoad({id: id})
          }
    }

    useEffect(() => {
        if (Object.keys(itemData).length > 0 && itemData.constructor === Object) {
            GApageView("item_view")
        }
    }, [itemData])

    useEffect(() => {
        if(sendMessageSuccess){
            setTimeout(() => {
                props.sendChatReset()
                window.location.reload(false)
            },3000)
        }
    },[sendMessageSuccess])

    useEffect(() => {
        return () => cleanUp()
    }, [])

    const cleanUp = () => {
        props.itemViewReset()
    }

    const itemImageReset = () => {
        props.itemImageEditReset()
    }

    const handleImageEditSubmit= (file) => {
        props.itemImageEditLoad({id: id, file: file})
    }

    const itemDelete = (id) => {
        props.itemDeleteLoad({pk_i_id: id})
    }

    //report handler
    const { isLoadingReport, reportMessage, reportError } = props.item
    const [reportShow, setReportShow] = useState(false)
    const [ellipsisClick, setEllipsisClick] = useState(false)
    const [reportInput, setReportInput] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        reporter_id: session ? session : null,
        reported_id: null,
        reported_user_id: null,
        report_reason: null,
        report_type: 'Item'
      }
    );

    const [message, setMessage] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            owner_id: 0,
            sender_id: 0,
            item_id: 0,
            body: '',
            chat_room_id: 0,
            contact: '',
            error: ''
        }
    )

    const handleMessageChange = (e) => {
        if(e.target.name === 'contact'){
            setMessage({['contact']: e.target.value})
        }
        if(e.target.name === 'contact_message'){
            setMessage({['body']: e.target.value})
        }
    }
    const datas = {
        owner_id: ownerId,
        sender_id: sessionId,
        item_id: itemId,
        chat_room_id: 0,
        body: message.contact ? message.body+`\n Contact: ${message.contact}` : message.body
    }

    const sendChat = (data) => {
        if(message.contact && message.contact.replace(/\s/g, '').length === 8){
            props.sendChatLoad({data: datas})
            history.replace();
        }else{
            if(message.contact){
            setMessage({['error']: 'Number should be 8 digits long'})
            }else{
                props.sendChatLoad({data: datas})
                history.replace();
            }
        }
    }

    const handleReportSubmit = () => {
      props.reportLoad(reportInput)
    }

    const breadcrumbLinks = [
        {link: '/', label: 'Home'},
        {link: `/${categoryName.toLowerCase()}`, label: `${categoryName}`},
        {link: `/${categoryName.toLowerCase()}/${urlifiedItemName}_i${id}`, label: item_title}
    ]
    useEffect(() => {
        if(reportShow){
            setEllipsisClick(false)
        }
    }, [reportShow])

    const handleReportClick = (id, user_id) => {
        setReportShow(true)
        setReportInput({
            reported_id: id,
            reported_user_id: user_id
        })
    }
    
    useEffect(() => {
        if(message.error){
            setTimeout(() => {
                setMessage({['error']: ''})
            },3000)
        }
    },[message.error])
  
    useEffect(() => {
      if (reportMessage || reportError) {
        setTimeout(() => {
          setReportInput({
            reporter_id: session ? session : null,
            reported_id: null,
            reported_user_id: null,
            report_reason: null,
            report_type: 'Item'
          })
          setReportShow(false)
          props.reportReset()
        }, 2000)
      }
    // eslint-disable-next-line
    }, [reportMessage, reportError])
    const [hasCurrentBoost, setHasCurrentBoost] = useState(false)
    let purchasesArr = []
    const getPurchasesArr = itemData && itemData.oc_t_user && itemData.oc_t_user.purchases ? itemData.oc_t_user.purchases : []
    getPurchasesArr.map((p) => {
       return p.item_id === id ? purchasesArr.push(p) : false
    })

    const ArrayCheckerDate = (arr) => {
        let check = []
        arr.map((item) => {
            const created_dt = item.created_at ? item.created_at : null
            const valid_days = item.service && item.service.days ? item.service.days : null
            const exp_dt = moment(created_dt).add(valid_days, 'days')

            return moment().isSameOrBefore(exp_dt) ? check.push("1") : null
        })
        return check
    }

    useEffect(() => {
        if(purchasesArr.length > 0){
           const checker = ArrayCheckerDate(purchasesArr)
           if(checker.length !== 0){
            setHasCurrentBoost(true)
           }
            
        }
    }, [purchasesArr])

    useEffect(() => {
        if(updateError || updateMessage){
            window.location.reload(false)
        }
    },[updateError, updateMessage])

    const submitItemUpdateByFieldLoad = (data) => {
        props.itemUpdateByFieldLoad(data)
      }

      //mark as sold
    const [isShowMarkAsSold, setIsShowMarkAsSold] = useState(false)
    const [isSold, setIsSold] = useState(true)
    const [soldData, setSoldData] = useState(null)
    const markAsSoldUnsold = (data, type) => {
        setIsSold(type)
        setSoldData(data)
        setEllipsisClick(false)
        setIsShowMarkAsSold(true)
    }

    const closeSoldModal = () => {
        setIsSold(true)
        setSoldData(null)
        setEllipsisClick(false)
        setIsShowMarkAsSold(false)
    }

    const submitSoldUnsold = () => {
        if (soldData) {
        props.itemUpdateByFieldLoad(soldData)
        }
        closeSoldModal()
    }

    return (
        itemMessage ? <h2 style={{width: '100%', textAlign: 'center'}}>No item found!</h2> :
         <ItemViewContext.Provider value={{
            reportShow, setReportShow, handleReportSubmit, setReportInput, reportInput,
            isLoadingReport, reportMessage, reportError, setIsEditClick, isEditClick, setEllipsisClick, setIsDeleteClick, isDeleteClick, setIsContactClicked, sendChat,
            setMessage, message, handleMessageChange, sendMessageLoading, sendMessageErrror, itemId, ownerId, sendMessageSuccess, isShareClick, setIsShareClick, submitItemUpdateByFieldLoad,
            markAsSoldUnsold, isShowMarkAsSold, isSold, closeSoldModal, submitSoldUnsold
        }}>
       
      {window.innerWidth < 600 ? null : <Breadcrumb links={breadcrumbLinks} loading={loading}/>}  
      {updateIsLoading || loading ? <WholePageLoader /> : null}
      {updateError ? <WholePageActivated text={updateError} /> : null}
      {updateMessage ? <WholePageActivated text={updateMessage} /> : null}
        <div className={`${loading ? `display-none` : `item-view-container`}`}>
        {window.innerWidth < 600 ? 
        loading ? null :
        <div className="w-100 item-view-topnav-container flex flex-d-row flex-align-center flex-justify-evenly">
            <div className="flex flex-d-row flex-align-center" onClick={() => history.goBack()}>
                <BackMobileButton/>
                <p className="item-view-topnav-text">{`Back`}</p>
            </div>
            <div className="w-100 flex flex-align-center flex-justify-center">
                <p className="item-view-topnav-text ml-none">{item_title}</p>
            </div>
            <div className="ellipsis-v-container">
                <div onClick={() => setEllipsisClick(!ellipsisClick)}>
                    <EllipsisV />
                </div>
                {
                    ellipsisClick ?
                    session === sellerUserId ?
                    <div className="p-absolute more-action-container">
                        <div className="flex flex-d-row flex-align-center item-view-edit-mobile">
                            <EditIconBtn />
                         <Link to={{pathname :`/user/item/new`, query: {itemData}, id: {session}}} > <p className="item-view-mobile-edit-actions-p">{`Edit Listing`}</p> </Link> 
                        </div>
                        <div className="flex flex-d-row flex-align-center item-view-edit-mobile" onClick={()=> setIsDeleteClick(true)}>
                            <TrashIconBtn />
                            <p className="item-view-mobile-delete-actions-p">{`Delete Listing`}</p>
                        </div>
                        <div className="flex flex-d-row flex-align-center " 
                                onClick={() => markAsSoldUnsold({item_id: parseInt(itemId), field_name: 'b_mark_as_sold', field_value: sold ? false : true }, sold ? false : true)}
                                >
                                    <div className="h-px-12 w-px-11"/>
                                    <p className="item-view-mobile-edit-actions-p ">{`Mark as ${sold ? `Unsold` : `Sold`}`}</p>
                                </div>
                    </div> : 
                    <div className="p-absolute more-action-container hpx-26">
                       <div className="flex flex-d-row flex-align-center item-view-report-mobile" onClick={() => handleReportClick(id, sellerUserId)}>
                            <ItemFlagIcon />
                            <p className="item-view-mobile-edit-actions-p">{`Report Listing`}</p>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div> : null}
            <div>
                <div className={` w-100 item-view-image-container bg-black`}>
                    {/* <MetaTags>
                        <title>{`Fishingkaki - ${item_title}`}</title>
                        <meta id="mt_description" name="description" content={item_desc} />
                        <link id="mt_canocical" rel="canonical" href={`${REACT_APP_BASE_URL}${path.slice("1")}`} />
                        <meta id="mt_og_url" property="og:url" content={`${REACT_APP_BASE_URL}${path.slice("1")}`} />
                        <meta id="mt_og_title" property="og:title" content={`Fishingkaki - ${item_title}`}/>
                        <meta id="mt_og_description" property="og:description" content={item_desc}/> 
                        <meta id="mt_og_image" property="og:image" content={itemResourceSrc} />
                    </MetaTags> */}
                    {Object.keys(itemData).length === 0 && itemData.constructor === Object ? null:
                        loading ? null :
                            <ViewItem 
                                data={itemData} session={session} handleImageEditSubmit={handleImageEditSubmit}
                                imageEditIsLoading={imageEditIsLoading} imageEditError={imageEditError}  imageEditMessage={imageEditMessage}
                                itemImageReset={itemImageReset} itemDelete={itemDelete}
                                path={path}
                            />
                    }
                </div>

                {window.innerWidth < 600 || parseInt(itemData.fk_i_user_id) !== parseInt(session) ? null : 
                <>
                <div className="w-100 flex flex-d-column">
                 {
                     hasCurrentBoost ?
                     <p className="item-view-current-ads-title">{`Current Ads`}</p> : null
                 }   
                {
                    purchasesArr.map((purchases, index) => {
                        const created_date = purchases.created_at ? moment(purchases.created_at).format('DD MMM - HH:mm a') : null
                        const exp_date = created_date ? moment(purchases.created_at).add(purchases.service.days, 'd') : null
                        const isNotExpired = exp_date ? moment().isSameOrBefore(exp_date) : null
                        const boost_title = purchases.service.name ? purchases.service.name.includes('Top Ad') ? purchases.service.name.replace('Top Ad', '- Premium') : purchases.service.name.replace(' ', ' - ') : null
                        
                        return <div key={index}> {
                            isNotExpired ?
                            <div className="item-view-current-ads-container mb-6">
                                <div className="item-view-current-ads-header">
                                    <p>{boost_title}</p>
                                </div>
                            <div className="item-view-current-ads-body">
                                <div className="flex flex-d-row w-100 flex-justify-between ">
                                    <div className="flex flex-d-column flex-justify-start w-100 mt-6 ml-6">
                                        <p className="your-ads-activate-label">{`Activate: `}</p>
                                        <p className="your-ads-activate-date">{created_date}</p>
                                    </div>
                                    <div className="flex flex-d-column flex-justify-end w-100 mt-6 mr-6">
                                        <p className="your-ads-expired-label text-align-end">{`Expiry: `}</p>
                                        <p className="your-ads-expired-date text-align-end">{moment(exp_date).format('DD MMM - HH:mm a')}</p>
                                    </div>
                                </div>
                                {/* <div className="flex flex-d-row flex-justify-end w-100 mt-12">
                                   
                                    <div className="flex flex-d-row flex-justify-center flex-align-center your-ads-renew-btn mr-12">
                                        <CycleIcon /> 
                                        <p className="your-ads-renew-p">{`Extend`}</p>
                                    </div>
                                </div> */}
                            </div>
                            </div> : null
                        }
                        {
                            isNotExpired ? 
                            <div className="flex flex-d-row w-100 flex-justify-between">
                                <p className="your-ads-below-text">{`Only you will be able to see this.`}</p>
                                <div className="flex flex-d-row">
                                    <p className="your-ads-cancel-subs-p">{`Cancel Subscription`}</p>
                                    <InfoSubsIcon />
                                </div>
                            </div> : null
                        }
                        </div>
                    })
                    
                }
                
                    
                </div>
                </>
                }
            </div>
                <Seller session={session} itemId={id} reload={reload}/>
                { reportShow ? <ReportModal /> : null}
                { isContactClicked ? <ContactMobileModal/> : null}
                {isShareClick ? <ShareModal link={trimmedPath[0]} closeModalFunc={setIsShareClick}/> : null}
                { isShowMarkAsSold ? <ItemSoldModal/> : null}
            </div>
        </ItemViewContext.Provider>
        
    );
}
const mapStateToProps = store => {
    return {
        chat: store.chatReducer,
        itemView: store.itemViewReducer,
        item: store.itemReducer,
    }
}

const mapActionToProps = {
    itemViewLoad,
    itemImageEditLoad,
    itemImageEditReset,
    itemViewReset,
    reportLoad, 
    reportReset,
    sendChatLoad,
    sendChatReset,
    itemUpdateByFieldLoad
}

export default connect(mapStateToProps,mapActionToProps)(Views)