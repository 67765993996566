import React from "react";

const HomeItemTag = (props) => {
    const {sold, reserved, topAd} = props
    return (
        <div className={`home-item-tag-container ${sold ? 'background-gray' : reserved ? 'background-blue' : topAd ? 'background-orange' : 'background-transparent'}`}>
            <p className="home-item-tag-text">{sold ? 'Sold' : reserved ? 'Reserved' : topAd ? 'Premium Top Ad' : ''}</p>
        </div>
    )
}

export default React.memo(HomeItemTag)