import React from 'react'
import {useHistory} from 'react-router-dom'
import {SuccessSVGIcon} from '../../../../../assets/svg'

export const ChangePasswordLabel = () => {
    return (
        <div className="flex flex-d-column w-100 mb-42">
          <p className="change-password-title-label">{`Change Password`}</p>
          <p className="change-password-sub-title-label">{`To  proceed please provide your current password. `}</p>
        </div>
    )
}

export const CancelBtn = () => {
    let history = useHistory()
    return (
        <div className="change-password-cancel-btn cursor-pointer" onClick={() => history.push('/user/listing')}>
            <p className="change-password-cancel-p">{`Cancel`}</p>
        </div>
    )
}

export const SuccessfulUI = () => {
    return (
        <div className="w-100 flex flex-d-column flex-justify-center flex-align-center">
            <div className="password-successful-label-container">
                <p>{`Password has been successfully changed`}</p>
            </div>
            <SuccessSVGIcon />
            <div className="password-successful-label-p">
                <p>{`You have successfully change your password. Please login using your new password.`}</p>
                <p className="success-sub-title-label">{`You will be redirected to Login page in`} <span className="text-b">{`5 seconds.`}</span></p>
            </div>
            <div className="back-to-login-btn">
                <p className="back-to-login-p">{`Back to login`}</p>
            </div>
        </div>
    )
}

export default {ChangePasswordLabel, CancelBtn, SuccessfulUI}