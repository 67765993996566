export const theme = {
    privacyPolicy: [
        {
            title: '1. Acceptance of terms',
            body: [
                {type: 'sentence', text: 'FishingKaki.com or FishingKaki (Global) Pte. Ltd., its parent company; and/or its subsidiaries, as the case may be, own and operate websites (“Site”), as well as provide various services and social networks, including collecting payment for credits (the “Classifieds”). We may also offer other features, contents, contests, and/or distribution services from time to time (collectively, “Additional Features” and together with the Site and the Classifieds, the “Services”). Any reference to “we”, “us” or “our” shall refer to FishingKaki (Global) Pte. Ltd., its parent company, subsidiaries and/or affiliates as the case may be. Any reference to “parties” shall refer to you and us.'},
                {type: 'sentence', text: 'By using the Services, or by providing personal data to us, you agree to the collection, use and disclosure of your personal data in the manner described in this policy. As of 2022, personal data is restricted to login credentials and we do not share your information with a 3rd party.'},
                {type: 'sentence', text: 'In addition, if you have registered your telephone number with the national Do Not Call registry, we will not send you promotional and marketing messages via your telephone number unless you have provided us with the consent to do so. As of 2022, the Site does not ask for your telephone number. or have any use for it.'}
            ]
        },
        {
            title: '2. Collection, use and storage of personal data',
            body:[
                {type: 'sentence', text: 'The type of personal data that we may collect from individuals includes but is not limited to the following:'},
                {type: 'list', body:[
                    // {text: 'name, title, address;'},
                    // {text: 'personal identification and/or passport number;'},
                    // {text: 'telephone number;'},
                    {text: 'email address and online passwords;'},
                    {text: 'records of communications with us (including but not limited to voice and text messages);'},
                    // {text: 'credit card or debit card information;'},
                    {text: 'technical information;'},
                    {text: 'purchase and/or transaction history, including history on the usage of our Services; and'},
                    {text: 'website usage information. We will obtain personal data about you when you visit our websites. When you visit us, we may monitor your use of our websites through the use of cookies and similar tracking devices. For example, we may monitor the number of times you visit our websites or which pages you go to. This information helps us to build a profile of our users. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually.'}
                ]},
                {type: 'sentence', text: 'We only collect, use and disclose personal data with an individual’s consent or deemed consent or as otherwise permitted under the Personal Data Protection Act 2012 (“PDPA”) or other applicable laws and regulations.'},
                {type: 'sentence', text: 'Personal Data will, generally, only be collected and used for the primary purposes of:'},
                {type: 'list', body: [
                    {text: 'conducting, improving, maintaining and developing a business relationship;'},
                    {text: 'providing the Services to you;'},
                    {text: 'processing, servicing or enforcing transactions and sending related communications;'},
                    {text: 'identification and verification purposes in connection with any of the services or products that may be supplied to individuals;'},
                    {text: 'responding to individual’s queries;'},
                    {text: 'marketing (such as providing individuals with information about our products and promotional notices and offers and sending regular newsletters, promotions, events or contact you about products and services we think may be of interest to you);'},
                    {text: 'improving the Services;'},
                    {text: 'contacting you for product, service or customer satisfaction surveys; and'},
                    {text: 'safety, security and legal compliance.'}
                ]},
                {type: 'sentence', text: 'We will retain the information for as long as it is necessary to fulfil the purpose for which it was collected, our legal or business purposes, or as required by relevant laws.'},
                {type: 'sentence', text: 'We take reasonable steps to ensure the individual is aware of:'},
                {type: 'list', body: [
                    {text: 'the likely use of the information;'},
                    {text: 'the means of requesting us on the provision of personal data that is in our possession;'},
                    {text: 'the means of withdrawing any consent in respect of the collection, use or disclosure of the personal data that is in our possession;'},
                    {text: 'the identity and contact details of the organisation;'},
                    {text: 'any law requiring collection of the information; and'},
                    {text: 'the main consequences of failure to provide the information.'}
                ]}
            ]
        },
        {
            title: '3. How will we disclose your personal data?',
            body: [
                {type:'sentence', text: 'We disclose personal data:'},
                {type: 'list', body: [
                    {text: 'for the primary purpose for which it was collected;'},
                    {text: 'to improve and to provide better service;'},
                    {text: 'for purposes that a reasonable person will consider appropriate in the circumstances;'},
                    {text: 'where the individual has consented;'},
                    {text: 'for direct marketing by us where consent has been given but giving individuals the opportunity to opt out of such direct marketing. We include our contact details in any direct marketing; or'},
                    {text: 'where permitted under the PDPA or other applicable law or if required by law.'}
                ]},
                {type: 'sentence', text: 'We do not disclose your personal data for any secondary purposes unless your consent has been given or if so permitted or required under the PDPA or other applicable laws and regulations.'},
                {type: 'sentence', text: 'There may be other situations where we may collect and use personal data, which are outside a person’s reasonable expectation. When this occurs, we are committed to providing the individual with an explanation for the possible use of the personal data when asking for the individual’s consent.'},
                {type: 'sentence', text: 'We do not sell, trade, or otherwise transfer to third parties your personally identifiable information. This does not include third parties engaged to fulfil the purposes listed above, or who assist us in operating our Services, conducting our business, or servicing you, as long as these parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect our and others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses. We may also use and disclose your Personal Data with and to our subsidiaries or affiliates.'},
                {type: 'sentence', text: 'In the event we are sold or integrated with another business your details may be disclosed to our advisers and any prospective purchasers and their advisers and will be passed on to the new owners of the business.'},
            ]
        },
        {
            title: '4. Accuracy and correction of personal data',
            body: [
                {type: 'sentence', text: 'We take steps to ensure information is accurate and up-to-date by updating our records whenever changes to information come to our attention. We disregard information which seems, on a reasonable basis, to be inaccurate or out of date by reason of the time which has elapsed since it was collected or by reason of any other information in our possession.If an individual has submitted personal data to us electronically or otherwise, and would like it corrected, then an email request should be sent to support@fishingkaki.com .We will comply with an individual’s request to correct their personal data as soon as practicable. We reserve the right to disagree with any request to correct an individual’s personal data on reasonable grounds.'}
            ]
        },
        {
            title: '5. DELETING OF YOUR DATA AND WITHDRAWAL OF CONSENT',
            body: [
                {type: 'sentence', text:'An individual may withdraw, cancel or revoke their consent for the collection, use or disclosure of personal data by deleting their account which immediately deletes all data of the user from our database; the user will cease to exist.  and so will their information. This means, all traces of the user’s stored data such as email address, username, chat and posting history will no longer be accessible or searched on both the site and database.. Furthermore, the login credentials in this case are email address, password and/or username will be blocked. Henceforth, the site will no longer use your data for any purposes amongst any parties and will no longer retain any of your information. The user can also contact us from the website to keep their login credentials but request to cease to collect, use or disclose the personal data upon such withdrawal unless otherwise permitted or required by the PDPA or other applicable laws and regulations.'}
            ]
        },
        {
            title: '6. Using our services',
            body: [
                {type: 'sentence', text: 'When you use our Services, including visiting our websites or using an application on our websites, we may record anonymous information such as IP address (where not used to identify a specific individual), time, date, referring URL, pages accessed and documents downloaded, type of browser and operating system.'},
                {type: 'sentence', text: 'We also use “cookies”. Cookies are small data files placed on your computer to collect standard Internet log information and visitor behaviour information. The information is used to track visitor use of a website and to compile statistical reports on website activity. For further information about cookies, visit www.aboutcookies.org or www.allaboutcookies.org. You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browser. However, in a few cases some of our website features may not function if you remove cookies from your browser.'},
                {type: 'sentence', text: 'Our websites and Services may contain links to or from other websites. We are not responsible for the privacy practices of other websites. This privacy policy applies only to the information we collect on our websites and Services.'}
            ]
        },
        {
            title: '7. Security of your personal data',
            body: [
                {type: 'sentence', text: 'We will take reasonable steps to ensure that personal data is securely stored. We protect personal data from misuse or loss by restricting access to the information in electronic format and by appropriate physical and communications security. Any data destroyed is disposed of in a manner that protects the privacy of personal data in an appropriate manner.'},
                {type: 'sentence', text: 'However, we will not be liable for any loss or damage of any kind arising from consequential misuse and/or fraud if you do not take reasonable care to ensure the continued confidentiality and accuracy of your Personal Data.'}
            ]
        },
        {
            title: '8. Dealing with unsolicited information',
            body: [
                {type: 'sentence', text: 'We take all reasonable steps to ensure that all unsolicited information is destroyed immediately.'}
            ]
        },
        {
            title: '9. Access to personal data',
            body: [
                {type: 'sentence', text: 'Subject to the PDPA, if an individual wishes to be provided with information regarding their Personal Data that is in our possession or under our control, or the ways in which such Personal Data has been used or disclosed by us in the year preceding such request, then that person needs to send a written request by email to support@fishingkaki.com . We will handle such requests as soon as reasonably possible in accordance with the PDPA.'}
            ]
        },
        {
            title: '10. Anonymity when dealing with us',
            body: [
                {type: 'sentence', text: 'We allow individuals the option not to identify themselves when dealing with us, where practicable, without affecting the level of service that we are able to provide to the individuals.'}
            ]
        },
        {
            title: '11. Cross border disclosure',
            body: [
                {type: 'sentence', text:'Any personal data provided to us may be transferred to, stored by or disclosed to an overseas recipient. For example, we may use a server hosted overseas to store data, which may include your personal data. Your personal data may also be processed by our employees operating outside of Singapore.'},
                {type: 'sentence', text: 'We will take reasonable steps, in the circumstances, before your personal data is disclosed to an overseas recipient, to ensure that the overseas recipient provides a standard of protection to personal data so transferred that is comparable to the protection under the PDPA.'}
            ]
        },
        {
            title: '12. Sensitive information',
            body: [
                {type: 'sentence', text: 'We do not collect sensitive information without the individual’s consent and unless it is specifically relevant and necessary for our primary purposes of conducting, improving, maintaining and developing a business relationship. All sensitive information is collected and used in accordance with this privacy policy. We do not use government identifiers (e.g. tax file numbers) to identify individuals.'}
            ]
        },
        {
            title: '13. Further information',
            body: [
                {type: 'sentence', text: 'We reserve the right to modify this privacy policy at any time. A current version of this policy will be published on our website. If you have any queries or feedback about the privacy policy, please contact support@fishingkaki.com . For more information about PDPA generally, please visit the Personal Data Protection Commission’s website at http://www.pdpc.gov.sg.'}
            ]
        }
    ],
    lastUpdate: 'July 2022'
}