import React, { useContext, useEffect, useState, useReducer, memo} from 'react';
import { PromoteContext } from '../../../../../common/context';
import {CustomLoader} from '../../../../../common/component/loader'
import {PaymentSuccess, WholePageError} from '../../../../../common/component/customComponents'
import First from './steps/first'
import Second from './steps/second'
import Third from './steps/third'
import {SellerToolsProductContainer} from './steps/customComponent'
import { theme } from '../../../../../common/theme'

const PromoteModal = () => {
    const [tosModal, setTosModal] = useState(false)
    const value = useContext(PromoteContext);
    const { itemForPromote, promoteShow, getServices, step, resetModal, subscription, isLoadingPromote, promoteSuccessMessage, errorPromote, handleNext, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError, setPaymentData} = value
    const individualArr = subscription && subscription.individual && subscription.individual.useNow ? subscription.individual.useNow : []
    const [indAdsArr, setIndAdsArr] = useState([])
    const[show, setShow] = useState(false)
    const id = itemForPromote && itemForPromote.pk_i_id ? itemForPromote.pk_i_id : 0

    const sorter = (arr) => {
        let inArr = []
        arr.map((b) => {
            return b.name.includes('Prime') || b.name.includes('Pack') ? null : inArr.push(b)
        })

        setIndAdsArr(inArr)
    }

    const [adsTotal, setAdsTotal] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            base_ads: 0,
            base_ads_id: 0,
            combo_ads: 0,
            combo_ads_id: 0,
            auto_bump_ads: 0,
            auto_bump_ads_id: 0
        }
       )

       const handleItemSelect = (data) => {
        data.item_id = itemForPromote.pk_i_id
        // setPaymentData(data)
        // if(isEmpty(paymentData)){
            setPaymentData(data)
        if(data.type === 'top_ad' || data.type === 'highlight'){
            if(adsTotal.combo_ads_id === 0 && adsTotal.auto_bump_ads_id === 0){
                setAdsTotal({['base_ads']: data.credit, ['base_ads_id']: data.id})
            }else if(adsTotal.combo_ads_id === 0 && adsTotal.auto_bump_ads_id){
                setAdsTotal({['auto_bump_ads_id']: 0, ['auto_bump_ads']: 0, ['base_ads']: data.credit, ['base_ads_id']: data.id})
            }else {
                setAdsTotal({['combo_ads']: 0, ['combo_ads_id']: 0, ['base_ads']: data.credit, ['base_ads_id']: data.id})
            }
        }
        if(data.type === 'combo'){
            if(adsTotal.base_ads_id === 0 && adsTotal.auto_bump_ads_id === 0){
                setAdsTotal({['combo_ads']: data.credit, ['combo_ads_id']: data.id})
            }else if(adsTotal.auto_bump_ads_id === 0 && adsTotal.base_ads_id){
                setAdsTotal({['base_ads']: 0, ['base_ads_id']: 0, ['combo_ads']: data.credit, ['combo_ads_id']: data.id})
            }else{
                setAdsTotal({['auto_bump_ads_id']: 0, ['auto_bump']: 0, ['combo_ads']: data.credit, ['combo_ads_id']: data.id})
            }
        }
        if(data.type === 'auto_bump'){
            if(adsTotal.combo_ads_id === 0 && adsTotal.base_ads_id === 0){
                setAdsTotal({['auto_bump_ads']: data.credit, ['auto_bump_ads_id']: data.id})
            }else if(adsTotal.combo_ads_id === 0 && adsTotal.base_ads_id){
                setAdsTotal({['base_ads']: 0, ['base_ads_id']: 0, ['auto_bump_ads']: data.credit, ['auto_bump_ads_id']: data.id})
            }else {
                setAdsTotal({['combo_ads_id']: 0, ['combo_ads']: 0, ['auto_bump_ads']: data.credit, ['auto_bump_ads_id']: data.id})
            }
        }
        // }
        handleNext()
    }

    const handleClick = () => {
        setShow(!show)
    }

    useEffect(() => {
        if(individualArr.length !== 0){
            sorter(individualArr)
        }
    },[individualArr])

    useEffect(() => {
        getServices(true)
        // eslint-disable-next-line
    }, [])

    const handleModalClick = () => {
        if(tosModal){
            setTosModal(false)
        }
        else{
            setTosModal(true)
        }
    }

    const stepSwitch = () => {
        let stepHolder = []
        switch (step) {
            case 1:
                stepHolder = <First />
            break;

            case 2:
                stepHolder = <Second />
            break;

            case 3:
                stepHolder = <Third />
            break;

            default:
            break;
        }
        return stepHolder
    }

    return (
        <PromoteContext.Provider value={{
            ...value, handleModalClick, tosModal, handleItemSelect
        }}>
        <div className={promoteShow ? `flex modal-index-container` : `hide`}>
            {/* <div className={step === 1 ? "modal-main-index-container" : "modal-second-index-container"}> */}
                <div className={ `modal-main-index-container ${step === 1 ? `h-100` : `h-auto`}`}>
                    <div className='modal-promote-main-header'>
                        <div className="flex flex-d-row flex-align-center ">
                            <p className="seller-tools-label-p m-0-auto">{`Seller Tools`}</p>
                            <div className="modalBtnCloseContainer modal-promote-close-btn-container">
                                <span className="promoteModalCloseBtn" onClick={() => resetModal()}>&times;</span>
                            </div>
                        </div>
                        {step === 1 ? <SellerToolsProductContainer /> : null}
                    </div>
                    
                    <div className={`modal-boost-main-container`}>
                    {
                        // isLoadingUseNowPromote ? null :
                        // useNowPromoteMessage ? null :
                        // indAdsArr.length !== 0 ?
                        // <div className="current-ind-paid-ads-main-container">
                        //     <p className="your-ind-ads-label-p">{`Your Individual Ads`}</p>
                        //     <div className="ind-ads-main-container">
                        //         {
                        //             indAdsArr.map((ads, index) => {
                        //                 return (
                        //                     ads.name.includes('Prime') ? null :
                        //                     ads.name.includes('Pack') ? null :
                        //                     <div className="ind-ads-options-container" key={index}>
                        //                         <p className="ind-ads-name-p">{ads.name}</p>
                        //                         <div className="ind-ads-use-now-btn-container" onClick={() => handleUseNowBtn(ads, id)}>
                        //                             <p>{`Use Now`}</p>
                        //                         </div>
                        //                     </div>
                        //                 )
                        //             })
                        //         }
                        //     </div>
                        // </div> : null
                    }
                    
                    {isLoadingPromote || isLoadingUseNowPromote ? <div className="h-px-462"><CustomLoader /></div> :
                        promoteSuccessMessage || useNowPromoteMessage ? <div className="h-px-462"><PaymentSuccess text={promoteSuccessMessage ? promoteSuccessMessage : useNowPromoteMessage} /></div> :
                        errorPromote || useNowPromoteError ? <WholePageError text={errorPromote} /> :
                        stepSwitch()}
                </div>
                <div className={`fk-prime-purchase-modal-footer-container `}>
                    <div onClick={ handleModalClick}>
                        <p className='fk-prime-purchase-modal-footer-disclaimer-p' >{theme.text.purchaseTOS}<span style={{textDecoration: 'underline', cursor: 'pointer'}}>{`Terms of Use`}</span> </p>
                    </div>
                </div>
            </div>
        </div>
        </PromoteContext.Provider>
    );
}

export default memo(PromoteModal);