export const theme = {
    TOS: [
        {title: '1. acceptance of terms', body: [
            {section: '1.1', type:'sentence', text: 'FishingKaki.com or FishingKaki (Global) Pte. Ltd., its parent company; and/or its subsidiaries, as the case may be, own and operate websites (“Site”), as well as provide various services and social networks, including collecting payment for credits (the “Classifieds”). We may also offer other features, content, contests, and/or distribution services from time to time (collectively, “Additional Features” and together with the Site and the Classifieds, the “Services”). Any reference to “we”, “us” or “our” shall refer to FishingKaki (Global) Pte. Ltd., its parent company, subsidiaries and/or affiliates as the case may be. Any reference to “parties” shall refer to you and us.'},
            {section: '1.2', type:'sentence', text: 'This terms of use (“Terms of Use”) includes our policy for acceptable use of any of the Services and governs your rights, obligations and restrictions regarding your use of the Services. Your use of any of the Services is subject to these Terms of Use and you are only authorised to use the Services if you agree to abide by all applicable laws and these Terms of Use. By using the Services, you are deemed to have accepted and agree to be bound by these Terms of Use.'},
            {section: '1.3', type:'sentence', text: 'We may make changes to these Terms of Use from time to time. We may at our discretion notify you of such changes by any reasonable means, including by posting the revised version of these Terms of Use on the Services and/or through the Services. Your further use of any of the Services following changes to these Terms of Use will constitute your automatic acceptance of those changes and the revised Terms of Use. It is therefore important that you review these Terms of Use regularly to ensure you are updated as to any changes. If you do not agree with the changes, please discontinue use of the Services immediately.'},
            {section: '1.4', type:'sentence', text: 'In order to participate in certain Services, you may be notified that you are required to agree to additional terms and conditions, and such additional terms are hereby incorporated into these Terms of Use by this reference, but such additional terms and conditions shall where applicable, pertain solely to the applicable Service.'}
        ]},
        {title: '2. ability to accept terms of use', body: [
            {section: '2.1', type:'sentence', text: 'You affirm that you are either more than 13 years of age, or possess legal parental or guardian consent approving your use of the Services, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Use, and to abide by and comply with these Terms of Use.'},
        ]},
        {title: '3. services access', body: [
            {section: '3.1', type:'sentence', text: 'You are responsible for all access to the Services using your internet connection, even if the access is by another person whether with or without your knowledge and/or approval. You shall supply your own device to use the Services, and you shall bear the cost of any data services incurred in using the Services.'},
            {section: '3.2', type:'sentence', text: 'We will use reasonable efforts to ensure that the Services are available at all times. However, we cannot guarantee that the Services or any individual function or feature of the Services will always be available and/or error free. In addition, the Services may be unavailable during periods when we are implementing upgrades or carrying our essential maintenance on the Services.'},
            {section: '3.3', type:'sentence', text: 'To use the Services, you may be required to sign up for an account (e.g. ID login and password). When creating or updating an account, you are required to provide us with certain personal information, which may include your name, birth date, e-mail address, and, in some cases, payment information. You shall provide genuine and accurate sign up information as requested by the Service. We shall not be responsible for any misuse of information by any individual during or after the process of signing up for an account.'},
            {section: '3.4', type:'sentence', text: 'You are responsible for (i) keeping confidential any password that you created to use any aspect of the Services requiring registration, and (ii) restricting access to your computer or mobile device. You agree to accept full responsibility for all activities that occur within your account. You must notify us in writing immediately of any breach of security or unauthorised use of your account. Users are not allowed to transfer or lend their accounts to any third party at all times.'},
            {section: '3.5', type:'sentence', text: 'Without limiting anything in this Agreement, we reserve the right, in our sole discretion, to restrict, suspend, or terminate your account and/or your access to any or all parts of the Services at any time, for any or no reason, with or without prior notice, and without liability, including in the event we stop offering any aspect of the Services or where the account has been inactive for six (6) months. We expressly reserve the right to restrict, suspend and/or terminate your access to any part of the Services if we determine, in our sole discretion, that you have violated any part of these Terms of Agreement. Users whose account has been deleted for any reason whatsoever shall no longer be authorised to use the Services.'},
            {section: '3.6', type:'sentence', text: 'Although we will not be liable for your losses caused by any unauthorised use of your account, whether with or without your knowledge, you may be liable for our losses or the losses of our third-party licensors, content providers, merchants, advertisers, sponsors, and service providers due to any use of your account, whether authorised or unauthorised or with or without your knowledge.'},
        ]},
        {title: '4. access to services in your location', body: [
            {section: '4.1', type:'sentence', text: 'We make no promise that the Services and/or the contents and materials of the Services are legal, appropriate or available for use in your location. You are subject to all laws of the state(s) and countries in which you reside and from which you access the Services and are solely responsible for obeying those laws. You agree we cannot be held liable if laws applicable to you restrict or prohibit you from using or accessing the Services. If you choose to use the Services from elsewhere, you do so on your own initiative and are responsible for compliance with local laws and we shall not be liable for any claims, infringement, charges, penalties, fines and or losses of any nature whatsoever.'}
        ]},
        {title: '5. your use of the services', body: [
            {section: '5.1', type:'sentence', text: 'Your permission to use the Services is personal to you and non-transferable, and you may not transfer or lend your account to any other person or use the Services for commercial purposes. Your use of the Services is conditional on your compliance with the rules of conduct set forth in these Terms of Use and you agree that you will not:'},
            {type: 'list', body: [
                {text: '5.1.1 Use the Services for any fraudulent and/or unlawful purpose;'},
                {text: '5.1.1 Use the Services for any fraudulent and/or unlawful purpose;'},
                {text: '5.1.3 Use the Services in any way that is harmful to us, the Services or our reputation and/or to our affiliates or the reputation of any of our affiliates;'}
            ]},
            {section: '5.2', type:'sentence', text: 'We reserve the right to require you to immediately remove any link or reference to the Services at any time and you shall immediately comply with any request by us to remove any such link or reference.'}
        ]},
        {title: '6. purchases / payments / refunds', body: [
            {section: '6.1', type:'sentence', text: 'You may use the Services to make payment and purchases subject to these Terms of Use and the following conditions:'},
            {type: 'list', body: [
                {text: '6.1.1 We may, at our sole discretion, accept or reject your order, transaction and/or offer to purchase.'},
                {text: '6.1.2 You may not cancel an order, transaction and/or offer to purchase once it has been submitted, even if our acceptance or rejection is still pending.'},
                {text: '6.1.3 If, for any reason, we reject your order, transaction and/or offer to purchase, neither you nor us will be placed under any further liability to each other arising from your original order, transaction and/or offer or the non-acceptance by us. We are not obligated to provide reasons for rejecting your order, transaction and/or offer to purchase.'},
                {text: '6.1.4 All purchases are strictly non-refundable.'}
            ]},
            {section: '6.2', type:'sentence', text: 'You agree to pay all fees and applicable taxes incurred by you or anyone using an account registered to you. You acknowledge that we are not required to provide a refund for any reason and that you will not receive money back when the account is closed, whether such closure was voluntary or involuntary, unless otherwise required by law. Where we utilise any third party payment systems (e.g. PayPal), any refunds will where applicable be subject to the terms and conditions of such third party payment systems and will be at their discretion.'},

        ]},
        {title: '7. intellectual property', body: [
            {section: '7.1', type:'sentence', text: 'The intellectual property rights in the Services and all of the text, pictures, videos, graphics, user interfaces, visual interfaces, trademarks, logos, applications, programs, computer code and other content made available on it are owned by us and/or our licensors. You may not print, make copies, reverse engineer or otherwise infringe on any of our or our licensors’ intellectual property rights of any such content without our express prior written permission.'}
        ]},
        {title: '8. limitation of liability', body: [
            {section: '8.1', type:'sentence', text: 'We provide the Services on an “as is” basis and make no representations as to the quality, completeness or accuracy of any content made available on the Services. To the maximum extent permitted by law, we expressly exclude:'},
            {type: 'list', body: [
                {text: '8.1.1 All conditions, warranties and other terms that might otherwise by implied by law into these Terms of Use; and'},
                {text: '8.1.2 Any and all liability to you (whether direct or indirect and of any nature whatsoever), whether arising under these Terms of Use or otherwise in connection with your use of the Services.'}
            ]},
            {section: '8.2', type: 'sentence', text: 'Neither FishingKaki.com nor its parent company FishingKaki (Global) Pte. Ltd., subsidiaries or affiliates shall be responsible in any way for loss or damage of any kind arising out of your use of the Services or any part thereof, or your inability to use the Services or any part thereof, including without limitation direct damages, indirect damages, damages for loss of profits, loss of data, loss of goodwill, work stoppage, computer failure or malfunction, any unauthorised access to, or the modification, use, interception, or obstruction of use of, computer material, or any and all other damages or losses, whether direct or indirect. Further, neither FishingKaki.com nor its parent, subsidiaries or affiliates shall be liable in any way for any loss or damage to any account or in-game information such as characters, virtual goods or currency, accounts, statistics, or user standings, ranks or profile information stored by the games and/or the Services. We shall not be responsible for any interruptions of service, including without limitation ISP disruptions, software or hardware failures, or any other event which may result in a loss of data or disruption of service. In no case shall we be liable for any special, incidental, indirect, punitive, exemplary or consequential damages arising from your use of the Services. In no case shall our liability exceed the amount, if any, that you paid to us during the six (6) months prior to the time your cause of action arose.'},
            {section: '8.3', type: 'sentence', text: 'The foregoing is a comprehensive limitation of liability that applies to all damages of any kind, including (without limitation) compensatory, direct, indirect or consequential damages, loss of data, income or profit, loss of or damage to property and claims of third parties. Notwithstanding the foregoing, nothing in these Terms of Use is intended to exclude or limit any liability that may not by law be excluded or limited, nor in any way to exclude or limit liability to you for death or personal injury resulting from our negligence or that of our employees or agents.'}
        ]},
        {title: '9. collection of personal information', body: [
            {section: '9.1', type: 'sentence', text: 'We may collect and use information about you in accordance with our privacy policy. You can view a copy of this policy by clicking here http://www.fishingkaki.com/privacy-policy and our privacy policy is incorporated by reference in these Terms of Use'}
            ]
        },
        {title: '10. duration of terms and termination', body: [
            {section: '10.1', type: 'sentence', text: 'These Terms of Use are effective until terminated. We may, at any time and for any reason, terminate your access to or use of the Services. If we terminate your access to the Services you will not have the right to bring claims against us with respect to such termination regardless of the reason for termination. We shall not be liable for any termination of your access to the Services.'},
            {section: '10.2', type: 'sentence', text: 'The termination of these Terms of Use shall not affect or discharge any rights obligations and liabilities accrued or incurred prior to or upon termination of these Terms of Use, and shall not relieve the parties of their respective obligations hereunder that by their nature should survive such expiration or termination.'}
        ]},
        {title: '11. governing law', body: [
            {section: '11.1', type:'sentence', text: 'These Terms of Use and the use of the Services will be governed by and construed in accordance with the laws of Singapore, and the courts of Singapore will have exclusive jurisdiction over any claim or dispute arising under or in connection with these Terms of Use.'}
        ]},
        {title: '12. sole remedy', body: [
            {section: '12.1', type: 'sentence', text: 'You acknowledge and agree that your sole and exclusive remedy for any dispute with respect to your use of the Services is to discontinue your use of the Services, and to cancel all accounts registered to you.'}
        ]},
        {title: '13. indemnity', body: [
            {section: '13.1', type: 'sentence', text: 'You agree to irrevocably indemnify and hold us, and our respective officers, agents, partners and employees, harmless from any loss, liability, claim, or demand, including reasonable legal fees, made by any third party due to or arising out of your use of the Services and/or your breach of these Terms of Use.'}
        ]},
        {title: '14. severability', body: [
            {section: '14.1', type: 'sentence', text: 'The provisions of these Terms of Use are intended to be severable. If for any reason any provision of these Terms of Use shall be held invalid or unenforceable in whole or in part in any jurisdiction, such provision shall, as to such jurisdiction, be ineffective to the extent of such invalidity or unenforceability without in any manner affecting the validity or enforceability thereof in any other jurisdiction or the remaining provisions hereof in any jurisdiction.'}
        ]},
        {title: '15. others', body: [
            {section: '15.1', type: 'sentence', text: 'These Terms of Use are deemed accepted upon any use of any of the Services. These Terms of Use constitutes the entire agreement between you and us regarding the use of the Services and all previous written or oral understanding, discussions, representations, correspondence and/or communications between the parties relating to the matters covered by these Terms of Use are superseded.'},
            {section: '15.2', type: 'sentence', text: 'Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect.'},
            {section: '15.3', type: 'sentence', text: 'No person who is not a party to these Terms of Use, shall have any right under the Contracts (Rights of Third Parties) Act, Chapter 53B of Singapore to enforce any of the terms of these Terms of Use.'}
        ]}
    ],
    lastUpdate: 'January 2020'
}