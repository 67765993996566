import { ITEM_CREATE } from '../constant'    
    const initialState = {
        itemData: [],
        isLoading: false,
        data: false,
        error: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case ITEM_CREATE.LOAD:
            return {...state, isLoading: true, error: null}

            case ITEM_CREATE.SUCCESS:
            return {...state, isLoading: false,itemData: action.payload.data, data:  action.payload.message, error: null}

            case ITEM_CREATE.FAIL:
            return {...state, isLoading: false, error: action.payload, data: false, itemData: []}

            case ITEM_CREATE.RESET:
            return {...state, isLoading: false, error: false, data: false, itemData: []}

            default:
            return state;  
        }
    }
    
    