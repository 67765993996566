import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const getChatRoomsByUser = (id) => {
    const user_id = id ? id : null

    try {
        if (user_id) {
            const query = gql `query getChatRoomsByUser($user_id: Int!, $orderField: String!, $orderType: String!){
              getChatRoomsByUser(
                  id: $user_id,
                  orderField: $orderField,
                  orderType: $orderType
                ){
                  deleted_by
                  owner_id
                  guest_id
        					offer_amount
    							buyer_offer_status
    							seller_offer_status
                  buyer_rating_status
                  buyer_rating_expiration
                  seller_rating_status
                  seller_rating_expiration
                  last_message
                  user{
                    pk_i_id
                    s_name
                    s_username
                    review {
                      total_rate
                    }
                    oc_t_user_resource{
                      pk_i_id
                      s_path
                      s_extension
                      s_content_type
                    }
                    social {
                      data
                    }
                  }
                  unreadMessage {
                    id
                  }
                  created_at
                  updated_at
                  id
                  sender{
                    pk_i_id
                    s_name
                    s_username
                    review {
                      total_rate
                    }
                    oc_t_user_resource{
                      pk_i_id
                      s_path
                      s_extension
                      s_content_type
                    }
                    social {
                      data
                    }
                  }
                  item{
                    pk_i_id
                    i_price
                    get_i_price
                    status
                    b_reserved
                    b_mark_as_sold
                    dt_sold
                    oc_t_item_description{
                      s_title
                      s_description
                    }
                    oc_t_user{
                      pk_i_id
                      s_username
                      oc_t_user_resource{
                        pk_i_id
                        fk_i_user_id
                        s_extension
                        s_name
                        s_content_type
                        s_path
                      }
                      social {
                        data
                      }
                    }
                    oc_t_item_resource{
                      pk_i_id
                      s_path
                      s_extension
                      s_content_type
                    }
                    oc_t_category{
                      pk_i_id
                      fk_i_parent_id
                      oc_t_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                    }
                  }
                  chat_messages{
                    deleted_by
                    id
                    chat_room_id
                    sender_id
                    created_at
                    read_at
                    updated_at
                    body
                    sender{
                      pk_i_id
                      oc_t_user_resource{
                        pk_i_id
                        fk_i_user_id
                        s_extension
                        s_name
                        s_content_type
                        s_path
                      }
                      social {
                        data
                      }
                    }
                  }
                }
              }`;
            const response = client.query({
                query: query,
                variables: {user_id: user_id, orderField: "updated_at", orderType: "DESC"}
            });
            const data = response ? response : null;
            return data;
        } else {
            return {}
        }
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

// export const getChatRoomsByUserFiltered = (id) => {
//   const user_id = id ? id : null
  
//   try {
//       if (user_id) {
//           const query = gql `query getChatRoomsByUser($user_id: Int!, 
//             $filter: ChatFilter!, 
//             $first: Int!,
//             $page: Int!,
//             $orderField: String!, 
//             $orderType: String!){
//             getChatRoomsByUser(
//                 id: $user_id,
//                 filter: $filter,
//                 first: $first,
//                 page: $page,
//                 orderField: $orderField,
//                 orderType: $orderType
//               ){
//                 count
//                 data {
//                 owner_id
//                 guest_id
//                 last_message
//                 user{
//                   pk_i_id
//                   s_name
//                 }
//                 unreadMessage {
//                   id
//                 }
//                 created_at
//                 updated_at
//                 id
//                 sender{
//                   pk_i_id
//                   s_name
//                   s_username
//                   oc_t_user_resource{
//                     pk_i_id
//                     s_path
//                     s_extension
//                     s_content_type
//                   }
//                   social {
//                     data
//                   }
//                 }
//                 item{
//                   pk_i_id
//                   i_price
//                   oc_t_item_description{
//                     s_title
//                     s_description
//                   }
//                   oc_t_user{
//                     pk_i_id
//                     s_username
//                     oc_t_user_resource{
//                       pk_i_id
//                       fk_i_user_id
//                       s_extension
//                       s_name
//                       s_content_type
//                       s_path
//                     }
//                     social {
//                       data
//                     }
//                   }
//                   oc_t_item_resource{
//                     pk_i_id
//                     s_path
//                     s_extension
//                     s_content_type
//                   }
//                 }
//                 chat_messages{
//                   id
//                   chat_room_id
//                   sender_id
//                   created_at
//                   read_at
//                   updated_at
//                   body
//                   sender{
//                     pk_i_id
//                     oc_t_user_resource{
//                       pk_i_id
//                       fk_i_user_id
//                       s_extension
//                       s_name
//                       s_content_type
//                       s_path
//                     }
//                     social {
//                       data
//                     }
//                   }
//                 }
//               }
//             }
//           }`;
//           const response = client.query({
//               query: query,
//               // variables: {user_id: user_id, orderField: "created_at", orderType: "DESC"}
//               variables: {user_id: user_id, filter: filter, first: first, page: page, orderField: "created_at", orderType: "DESC"}

//           });
//           const data = response ? response : null;
//           return data;
//       } else {
//           return {}
//       }
//   } catch (e) {
//       console.log('e', e)
//       throw e
//   }
// }

export const sendChat = (data) => {
  const { owner_id, sender_id, item_id, body, chat_message_id } = data

  let input
  if (data.chat_room_id) {
    input = {
      input: 
        { owner_id: owner_id,
          sender_id: sender_id,
          item_id: item_id,
          body: body,
          chat_room_id: data.chat_room_id,
          chat_message_id: parseInt(chat_message_id) 
        }
      }
  } else {
    input = {
      input: 
        { owner_id: owner_id,
          sender_id: sender_id,
          item_id: item_id,
          body: body,
          chat_message_id: parseInt(chat_message_id)
        }
    }
  }

  try {
  const query = gql `mutation createChatMessage($input: CreateChatMessageInput!){
    createChatMessage(input: $input){
      id
      owner_id
      offer_amount
      buyer_offer_status
      seller_offer_status
      user {
        s_name
      }
      item {
        status
        b_reserved
        b_mark_as_sold
        oc_t_item_description {
          s_title
        }
      }
      chat_messages{
        id
        sender_id
        body
      }
    }
  } 
  `;
    const response = client.mutate({
      mutation: query,
      variables: input
    });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const updateChatUnread = (payload) => {
  const unread = payload && payload.unreadArr ? payload.unreadArr : []
  const user_id = payload && payload.userId ? parseInt(payload.userId) : ''
  const chat_room_id = payload && payload.chat_room_id ? parseInt(payload.chat_room_id) : ''
  try {
  const query = gql`mutation updateChatMessage($unread_arr: [Int!], $user_id: Int!, $chat_room_id: Int!){
    updateChatMessage(unread_arr:$unread_arr, user_id: $user_id, chat_room_id: $chat_room_id ){
      id
      unreadMessage{
        id
        sender_id
        read_at
      }
      chat_messages{
        chat_room_id
      }
    }
  } 
  `;
    const response = client.mutate({
      mutation: query,
      variables: {"user_id": user_id, "unread_arr": unread, "chat_room_id": chat_room_id}
    });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const getChatRoomById = (id, user_id) => {
  try {
  const query = gql`query getChatRoomById($id: Int!, $user_id: Int!){
    getChatRoomById(id: $id, user_id: $user_id){
      deleted_by
      id
      owner_id
      guest_id
      offer_amount
      buyer_offer_status
      seller_offer_status

      buyer_rating_status
      buyer_rating_expiration
      seller_rating_status
      seller_rating_expiration

      last_message
      user{
        pk_i_id
        s_name
        s_username
        review {
          total_rate
        }
        oc_t_user_resource{
          pk_i_id
          s_path
          s_extension
          s_content_type
        }
        social {
          data
        }
      }
      unreadMessage {
        id
      }
      created_at
      updated_at
      sender{
        pk_i_id
        s_name
        s_username
        review {
          total_rate
        }
        oc_t_user_resource{
          pk_i_id
          s_path
          s_extension
          s_content_type
        }
        social {
          data
        }
      }
      item{
        pk_i_id
        i_price
        get_i_price
        status
        b_reserved
        b_mark_as_sold
        dt_sold
        oc_t_item_description{
          s_title
          s_description
        }
        oc_t_user{
          pk_i_id
          s_username
          oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
          }
          social {
            data
          }
        }
        oc_t_item_resource{
          pk_i_id
          s_path
          s_extension
          s_content_type
        }
      }
      chat_messages{
        deleted_by
        id
        chat_room_id
        sender_id
        created_at
        read_at
        updated_at
        body
        sender{
          pk_i_id
          oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
          }
          social {
            data
          }
        }
      }
    }
  } 
  `;
    const response = client.mutate({
      mutation: query,
      variables: {id: parseInt(id), user_id: parseInt(user_id)}
    });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const sendReview = (payload) => {
  try {
  const query = gql`mutation createReview($input: CreateChatReviewInput!){
    createReview(input: $input){
  		review {
        id
        reviewer{
          pk_i_id
          s_username
        }
      }
    	message
  	}
  }`;
    const response = client.mutate({
      mutation: query,
      variables: {input: payload}
    });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const deleteChat = (data) => {
  const { user_id, chat_room_id } = data

  try {
  const query = gql `mutation deleteChatMessage($user_id: Int!, $chat_room_id: Int!){
    deleteChatMessage(user_id: $user_id, chat_room_id: $chat_room_id){
      chat_room {
        id
      }
    	message
    }
  } 
  `;
    const response = client.mutate({
      mutation: query,
      variables:  {user_id: parseInt(user_id), chat_room_id: parseInt(chat_room_id)}
    });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export default {
    getChatRoomsByUser,
    sendChat,
    updateChatUnread,
    getChatRoomById,
    sendReview,
    deleteChat
};