import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getServicesByType = (payload) => {
    const { first, page, type } = payload
    try {
        const query = gql `query getServicesByType(
            $type: String, 
            $first: Int!,
            $page: Int!,
            $orderField: String!, 
            $orderType: SortOrder!
        )
        {
            getServicesByType(
                type: $type,
                first: $first,
                page: $page,
                orderField: $orderField,
                orderType: $orderType
            ){
                count
                service {
                id
                days
                status
                sg_price
                credit
                name
                description
                type
                discount
                live_list_count
                top_ad_count
                highlight_count
                cover_photo
                youtube_link,
                auto_bump_count
                }
            }               
        }`;
        const response = client.query({
            query: query,
            variables: {type: type, first: first, page: page, orderField: 'id', orderType: 'ASC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      throw e
    }
  }

  export const serviceUpsert = (payload) => {
    const { id, name, description, status, sg_price, credit, type, days, discount,  live_list_count, top_ad_count, highlight_count, auto_bump_count, cover_photo, youtube_link} = payload
    const boolStatus = status === true || status === 'active' || status === 'true' ? true : false
    const bool_cover_photo = cover_photo === true || cover_photo === 'active' || cover_photo === 'true' ? true : false
    const bool_youtube_link = youtube_link === true || youtube_link === 'active' || youtube_link === 'true' ? true : false

    const insertVariables = {data: {
        id: null,
        name: name,
        description: description,
        status: boolStatus,
        sg_price: parseFloat(sg_price),
        credit: parseInt(credit),
        type: type,
        days: days ? parseInt(days) : 0,
        discount: parseFloat(discount),
        live_list_count: parseInt(live_list_count),
        top_ad_count: parseInt(top_ad_count),
        highlight_count: parseInt(highlight_count),
        auto_bump_count: parseInt(auto_bump_count),
        cover_photo: bool_cover_photo,
        youtube_link: bool_youtube_link
        }
    }

    const updateVariables = {data: {
        id: id ? parseInt(id) : null,
        name: name,
        description: description,
        status: boolStatus,
        sg_price: parseFloat(sg_price) ,
        credit: parseInt(credit),
        type: type,
        days: days ? parseInt(days) : 0,
        discount: parseFloat(discount),
        live_list_count: parseInt(live_list_count),
        top_ad_count: parseInt(top_ad_count),
        highlight_count: parseInt(highlight_count),
        auto_bump_count: parseInt(auto_bump_count),
        cover_photo: bool_cover_photo,
        youtube_link: bool_youtube_link
        }
    }

    const gql_query = gql`mutation serviceUpsert($data: ServiceUpsertInput)
    {
        serviceUpsert(data: $data)
        {
        message
        }
    }`;
    
    try{
          const response = client.mutate({
              mutation: gql_query,
              variables: id ? updateVariables : insertVariables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
}

export const deleteService = (payload) => {
    const { id  } = payload
  
    const  gql_query = gql`mutation serviceDelete($id: ID!){
        serviceDelete(id: $id){
        message
      }
    }`
   
    const variables = {
        id: id ? parseInt(id) : null
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  export default {
    getServicesByType,
    serviceUpsert,
    deleteService
}