import React, { useCallback, useEffect, useContext, useState } from 'react';
import {Link} from 'react-router-dom'
import { theme } from '../../../../../../common/theme'
import { ReviewModal, RequestReviewAlert} from '../child/CustomComponents'
import { getSocialUrl, slugify, categoryParser } from '../../../../../../common/helper/utility'
import { ChatContext } from '../../../../../../common/context'
import FKImage from '../../../../../../common/component/image'
import DefaultPhoto from '../../../../../../assets/images/defaultAvatar.png'
import { BackButton, EllipsisIcon, TrashIconBtn} from '../../../../../../assets/svg'
import { StarRatingPlain } from '../../../../../../common/component/rating'
import moment from 'moment'
import {ChatDeleteModal} from '../../web/child/CustomComponent'

// const { REACT_APP_WEB_SEC, REACT_APP_IMGX } = process.env;

// const MobileFilter = () => {
//     return (
//             <div className="w-100 flex-container flex-space-even">
//                 <div>
//                     <p className="f-s-normal f-w-500 f-16 l-20 c-white">{`Inbox`}</p>
//                 </div>
//             </div> 
//     )
// }

const MobileHeader = (props) => {
    const { toggleThread, thread, data } = props
    const value = useContext(ChatContext)
    const {chatRoomData, session, showRequestReviewAlert, requestReviewAlertProceed, toggleShowRequestReviewAlert, sendChat, sendIsLoading, sendMessage, resetSendChat, sendError, sendReview, resetSendReview, sendReviewIsLoading, sendReviewMessage, sendReviewError, isLoading,
        setActiveIndex, activeIndex, me, fromMessage, messageItem, addTempChatRoom,  showDelete, toggleShowDelete, setShowDeleteAlert, toggleDeleteAlert, showDeleteAlert, deleteChat, showReviewModal, toggleShowReviewModal
    } = value
    const userId = session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id : null
    const chatOwnerId = chatRoomData && chatRoomData.owner_id ? chatRoomData.owner_id : null
    const chatGuestId = chatRoomData && chatRoomData.guest_id ? chatRoomData.guest_id : null
    const itemOwnerId = chatRoomData && chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.pk_i_id ? chatRoomData.item.oc_t_user.pk_i_id : null
    const loginIsOwner = parseInt(userId) === parseInt(chatOwnerId) ? true : false
    const loginIsGuest = parseInt(userId) === parseInt(chatGuestId) ? true : false
    const loginIsItemOwner = parseInt(userId) === parseInt(itemOwnerId) ? true : false

    const buyerUsername = chatRoomData.sender && chatRoomData.sender.s_username ? chatRoomData.sender.s_username : ''
    const sellerUsername = chatRoomData.user && chatRoomData.user.s_username ? chatRoomData.user.s_username : ''
    const headerName = loginIsOwner ? buyerUsername :  sellerUsername
    
    const itemOwnerUserResource = chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.oc_t_user_resource ? chatRoomData.item.oc_t_user.oc_t_user_resource : null
    const itemSocialData = chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.social ? chatRoomData.item.oc_t_user.social : []
    const itemOwnerSocialResource = getSocialUrl(itemSocialData)

    const senderOwnerUserResource = chatRoomData.sender && chatRoomData.sender.oc_t_user_resource && chatRoomData.sender.oc_t_user_resource.pk_i_id ? chatRoomData.sender.oc_t_user_resource : null
    const senderOwnerSocialData = chatRoomData.sender && chatRoomData.sender.social ? chatRoomData.sender.social : []
    const senderOwnerSocialResource = getSocialUrl(senderOwnerSocialData)

    const itemImgResource = chatRoomData.item && chatRoomData.item.oc_t_item_resource && chatRoomData.item.oc_t_item_resource[0] ? chatRoomData.item.oc_t_item_resource[0] : []
    const itemTitle = chatRoomData.item && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description.s_title ? chatRoomData.item.oc_t_item_description.s_title : ''
    const itemPrice = chatRoomData.item && chatRoomData.item.get_i_price ? chatRoomData.item.get_i_price : ''
    const itemSold = chatRoomData.item && chatRoomData.item.b_mark_as_sold ? chatRoomData.item.b_mark_as_sold : chatRoomData.item && chatRoomData.item.dt_sold ? true : null

    const chatItemId = chatRoomData.item && chatRoomData.item.pk_i_id ? chatRoomData.item.pk_i_id : ''
    const chatRoomId = chatRoomData.id ? chatRoomData.id : ''
    const offer_amount = chatRoomData.offer_amount ? chatRoomData.offer_amount : 0
    const chat_messages = chatRoomData.chat_messages ? chatRoomData.chat_messages.reverse() : []

    const ownerTotalRate = chatRoomData.user && chatRoomData.user.review ? chatRoomData.user.review : []
    const senderTotalRate = chatRoomData.sender && chatRoomData.sender.review ? chatRoomData.sender.review : []

    const [showOfferModal, setShowOfferModal] = useState(false)
    const [useEditOffer, setUserEditOffer] = useState(false)
    const [madeOfferId, setMadeOfferId] = useState()

    // useEffect(() => {
    //     setActiveIndex(activeIndex)
    // }, [])

    const insertChatFromMessage = (item) => {
        const loginUserName = me && me.s_username ? me.s_username : ''
        const loginUserId = me && me.pk_i_id ? me.pk_i_id : 0
        const updatedDate = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
        const tempRoom = {
          buyer_offer_status: 'temporary_waiting_to_make_offer',
          chat_messages: [],
          created_at: updatedDate,
          updated_at: updatedDate,
          guest_id: userId,
          item: item,
          owner_id: item && item.fk_i_user_id ? item.fk_i_user_id : null,
          sender: {pk_i_id: loginUserId, s_username: loginUserName},
          unreadMessage: [],
          user: item && item.oc_t_user ? item.oc_t_user : {}
        }
        addTempChatRoom(tempRoom)
    }

    useEffect(() => {
        // const item = chatProps && chatProps[0] ? chatProps[0] : []

        if (fromMessage) {
            //check if item is on inbox
            const itemId = messageItem && messageItem.pk_i_id || null
            if(itemId) {
                //check if exist
                let tempItem
                let tempIndex
                let tempIsExist = false
                data.some(function (item, index) { 
                    if (!tempIsExist && item && item.item && parseInt(item.item.pk_i_id) === parseInt(itemId)) {
                        tempIsExist = true
                        tempItem = item
                        tempIndex = index
                        return false;
                    }
                });

                if (tempIsExist) {
                    // setActiveIndex(tempIndex)
                    // setMessagesData(tempItem)
                    // setUnread(tempItem)
                }
                else {
                    // setActiveIndex(0)
                    // setMessagesData(tempItem)
                    insertChatFromMessage(messageItem)
                }
                setActiveIndex(tempIndex)
            }
        } 
        else {
            setActiveIndex(activeIndex)
            // setMessagesData(item)
        }
    // eslint-disable-next-line 
    }, [fromMessage])

    const avatarSwitch = () => {
        let element = []
        if (loginIsOwner) {
            if (senderOwnerUserResource) {
                element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="seller_avatar"/>)
            } else if(senderOwnerSocialResource){
                element.push(<img key={1} src={senderOwnerSocialResource} alt="fb-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            } else{
                element.push(<img key={1} src={DefaultPhoto} alt="default-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            }
        } else if(loginIsGuest){
            if (itemOwnerUserResource) {
                element.push(<FKImage key={1} images={[itemOwnerUserResource]} type="seller_avatar" />)
            } else if(itemOwnerSocialResource) {
                element.push(<img key={1} src={itemOwnerSocialResource} alt="fb-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            } else{
                element.push(<img key={1} src={DefaultPhoto} alt="default-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            }
        }
        else if(loginIsItemOwner){
            if (senderOwnerUserResource) {
                element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="seller_avatar" />)
            } else if(senderOwnerSocialResource) {
                element.push(<img key={1} src={senderOwnerSocialResource} alt="fb-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            } else{
                element.push(<img key={1} src={DefaultPhoto} alt="default-avatar" width={40} height={40} style={{borderRadius: "50%"}}/>)
            }
        }
        return element
    }

    const parseRate =(arr) => {
        let total = 0
        arr.length > 0 && arr.map(item => 
            total += item.total_rate
        )
        return total === 0 ? 0 : total / arr.length
    }
   
    const ownerAverageRate = parseRate(ownerTotalRate)
    const senderAverageRate = parseRate(senderTotalRate)
    const ratingAverage = loginIsOwner ? senderAverageRate.toFixed(1) : ownerAverageRate.toFixed(1)

    const handleEditOffer = () => {
        setUserEditOffer(true)
        getMadeOfferId()
        toggleModal(!showOfferModal)
    }

    const toggleModal = () => {
        if (showOfferModal) {
            setUserEditOffer(false)
            setMadeOfferId(null)
        }
        setShowOfferModal(!showOfferModal)
    }

    const getMadeOfferId = () => {
        const chat_message_made_offer = chat_messages.find(item => item.body.includes('made_offer'))
        const chat_message_edit_offer = chat_messages.find(item => item.body.includes('edit_offer'))
        let id;
        if (chat_message_made_offer) {
            id = chat_message_made_offer.id
        }
        else if(chat_message_edit_offer){
            id = chat_message_edit_offer.id        }
        else {
            console.log('getMadeOfferId else')
        }
        setMadeOfferId(id)
        return chat_message_made_offer ? chat_message_made_offer : chat_message_edit_offer
    }

    // const handleDeclineOffer = (e, status) => {
    //     e.preventDefault()
    //     const offerData = getMadeOfferId()
    //     const id = offerData.id ? offerData.id : null
    //     const data = {
    //         owner_id: chatOwnerId,
    //         sender_id: userId,
    //         item_id: chatItemId,
    //         body: `${status}${REACT_APP_WEB_SEC}${offer_amount}`,
    //         chat_room_id: chatRoomId,
    //         chat_message_id: id
    //     }
    //     sendChat({data})
    // }

    const { itemAlreadySold } = theme.text

    // const buyerOfferStatusSwitch = () => {
    //     let status
    //     switch (chatRoomData.buyer_offer_status) {
    //         case theme.text.buyerOfferStatus.temporaryWaitingToMakeOffer.codeValue:
    //         case '':
    //             status = itemSold ? itemAlreadySold : ''
    //             break;
    //         case theme.text.buyerOfferStatus.madeOffer.codeValue:
    //         case theme.text.buyerOfferStatus.waitingOfferApproval.codeValue:    
    //             status = itemSold ? itemAlreadySold : `${theme.text.buyerOfferStatus.madeOffer.displayValue} ${offer_amount}`
    //             break;
    //         case theme.text.buyerOfferStatus.leaveSellerReview.codeValue:   
    //         case theme.text.buyerOfferStatus.leaveSellerReviewDone.codeValue:  
    //             status = `Accept an offer of SGD ${offer_amount}`
    //         break;

    //         default:
    //             break;
    //     }
    //     return status
    // }
    const buyerRatingOptionsSwitch = () => {
        let element
        if (chatRoomId == '') {
            element = ''
        } else {
            switch (chatRoomData.buyer_rating_status) {
                case null:
                case undefined:
                case '':
                    element = 
                    <div key={1} className='flex flex-d-column align-self-end '>
                        <div style={{alignSelf: 'flex-end'}}>
                            <button key={1} onClick={() => toggleShowRequestReviewAlert()}><p className="rating-button offer-button-text">{theme.text.buyerRatingStatus.buyerRequestReview.displayValue}</p></button>
                        </div>
                        <p className='rating-text-description'>{theme.text.buyerRatingStatus.buyerRequestReview.description}</p>
                    </div>
                break;

                case 'buyer_request_review':
                    const linkExpired = chatRoomData && chatRoomData.buyer_rating_expiration ? moment(new Date()).isAfter(chatRoomData.buyer_rating_expiration) : false
                    element = 
                    <div key={1} className='flex flex-d-column align-self-end '>
                        <button style={{pointerEvents: linkExpired ? 'visible' : 'none'}} key={1} onClick={() => linkExpired ? toggleShowRequestReviewAlert() : null} disabled={linkExpired ? false : true}><p className={linkExpired ? "rating-button offer-button-text" : "rating-button-disable offer-button-text"}>{theme.text.buyerRatingStatus.buyerRequestReview.displayValue}</p></button>
                        <p className='rating-text-description'>{theme.text.buyerRatingStatus.buyerRequestReview.description}</p>
                    </div>
                break; 

                case 'buyer_review_submitted_by_seller':
                    element = 
                    <div key={1} className='flex flex-d-column align-self-end '>
                        <Link to='/user/reviews'>
                            <div style={{alignSelf: 'flex-end'}}>
                            <button key={1}><p className="rating-button offer-button-text">{`View Review`}</p></button>
                            </div>
                        </Link>
                    </div>
                break;

                default:
                break;
            }
        }
        return element
    }

    // const sellerOfferStatusSwitch = () => {
    //     let status
    //     switch (chatRoomData.seller_offer_status) {
    //         case '':
    //         case theme.text.sellerOfferStatus.waitingForOffer.codeValue:
    //             status = itemSold ? itemAlreadySold : theme.text.sellerOfferStatus.waitingForOffer.displayValue
    //             break;
    //         case theme.text.sellerOfferStatus.offerApproval.codeValue:
    //             status = itemSold ? itemAlreadySold : `${theme.text.sellerOfferStatus.offerApproval.displayValue} ${offer_amount}`
    //             break;
    //         case theme.text.sellerOfferStatus.leaveBuyerReview.codeValue:
    //         case theme.text.sellerOfferStatus.leaveBuyerReviewDone.codeValue:
    //             status = `Accept an offer of SGD ${offer_amount}`
    //             break;
    //         default:
    //             break;
    //     }
    //     return status
    // }

    const sellerRatingOptionsSwitch = () => {
        let element
        switch (chatRoomData.seller_rating_status) {
            case null:
            case undefined:
            case '':
                element = 
                <div key={1} style={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-end'}}>
                    <div style={{alignSelf: 'flex-end'}}>
                        <button key={1} onClick={() => toggleShowRequestReviewAlert()}><p className="rating-button offer-button-text">{theme.text.sellerRatingStatus.sellerRequestReview.displayValue}</p></button>
                    </div>
                    <p className='rating-text-description'>{theme.text.sellerRatingStatus.sellerRequestReview.description}</p>
                </div>
            break;
            case 'seller_request_review':
                const linkExpired = chatRoomData && chatRoomData.seller_rating_expiration ? moment(new Date()).isAfter(chatRoomData.seller_rating_expiration) : false

                element = 
                <div key={1} style={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-end'}}>
                    <button style={{pointerEvents: linkExpired ? 'visible' : 'none'}} key={1} onClick={() => linkExpired ? toggleShowRequestReviewAlert() : null} disabled={linkExpired ? false : true}><p className={linkExpired ? "rating-button offer-button-text" : "rating-button-disable offer-button-text"}>{theme.text.sellerRatingStatus.sellerRequestReview.displayValue}</p></button>
                    <p className='rating-text-description'>{theme.text.sellerRatingStatus.sellerRequestReview.description}</p>                    
                </div>
            break;

            case 'seller_review_submitted_by_buyer':
                element = 
                <div key={1} style={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-end'}}>
                    <Link to='/user/reviews'>
                        <div style={{alignSelf: 'flex-end'}}>
                            <button key={1}><p className="rating-button offer-button-text">{`View Review`}</p></button>
                        </div>
                    </Link>
                </div>
            break;

            
            default:
            break;
        }
        return element
    }

    const sellerRatingOption = sellerRatingOptionsSwitch()
    const buyerRatingOption = buyerRatingOptionsSwitch()
    const ratingOption = loginIsOwner ? sellerRatingOption : buyerRatingOption


    // const handleOfferSend = (e, status) => {
    //     e.preventDefault()
    //     const offerData = getMadeOfferId()
    //     const id = offerData.id ? offerData.id : null
    //     const data = {
    //         owner_id: chatOwnerId,
    //         sender_id: userId,
    //         item_id: chatItemId,
    //         body: `${status}${REACT_APP_WEB_SEC}${offer_amount}`,
    //         chat_room_id: chatRoomId,
    //         chat_message_id: id
    //     }
    //     if (useEditOffer) {
    //         setUserEditOffer(false)
    //     }
    //     sendChat({data})
    // }


    const ratingElement = () => {
        return ratingAverage > 0 ? <StarRatingPlain totalStar={5} average= {ratingAverage} size={12}/> : null
    }

    const itemStatusSwitch = (itemSold) => {
        let el
        if (itemSold) {
            el = <div className="item-status-cont sold-cont"><p className="item-status-text sold-text">Sold</p></div>
        }
        else {
            el = null
        }
        return el
    }

    const resetSendChatCallback = useCallback(() => {
        resetSendChat()
    }, [resetSendChat])

    useEffect(() => {
        if (sendMessage) {
            setTimeout(() => {
                resetSendChatCallback()
            }, 2000)
        }
        if (sendError) {
            setTimeout(() => {
                resetSendChatCallback()
            }, 2000)
        }
    }, [sendMessage, sendError, resetSendChatCallback])

    const [showEllipsisAction, setShowEllipsisAction] = useState(false)

    // const offerModalDetail = {
    //     headerName: headerName, 
    //     itemPrice: itemPrice, 
    //     avatarSwitch: avatarSwitch, 
    //     showOfferModal: showOfferModal, 
    //     toggleModal: toggleModal,
    //     chatOwnerId: chatOwnerId,
    //     userId: userId,
    //     chatItemId: chatItemId,
    //     chatRoomId: chatRoomId,
    //     sendChat: sendChat,
    //     offer_amount: offer_amount,
    //     sendIsLoading: sendIsLoading,
    //     sendMessage: sendMessage, 
    //     resetSendChat: resetSendChat,
    //     sendError: sendError,
    //     useEditOffer: useEditOffer,
    //     madeOfferId: madeOfferId,
    // }

    const reviewModalDetail = {
        showReviewModal: showReviewModal,
        toggleReviewModal: toggleShowReviewModal,
        loginIsOwner: loginIsOwner,
        userId: userId,
        chatOwnerId: chatOwnerId,
        chatGuestId: chatGuestId,
        chatItemId: chatItemId,
        sendReview: sendReview, 
        resetSendReview: resetSendReview,
        sendReviewIsLoading: sendReviewIsLoading, 
        sendReviewMessage: sendReviewMessage, 
        sendReviewError: sendReviewError,
        chatRoomId: chatRoomId,
        buyerUsername: buyerUsername,
        sellerUsername: sellerUsername
    }

    const category = chatRoomData.item && chatRoomData.item.oc_t_category && chatRoomData.item.oc_t_category.oc_t_category_descriptions && chatRoomData.item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(chatRoomData.item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
    const itemSLug = slugify(itemTitle)
    const item_description = chatRoomData.item && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description.s_description ? chatRoomData.item.oc_t_item_description.s_description.substring(0, 100) : ''
    const cover_photo = chatRoomData.item && chatRoomData.item.oc_t_item_resource && chatRoomData.item.oc_t_item_resource[0] && chatRoomData.item.oc_t_item_resource[0].s_extension ? `${chatRoomData.item.oc_t_item_resource[0].pk_i_id}.${chatRoomData.item.oc_t_item_resource[0].s_extension}` : null
    const finalSLug = `/${category}/${itemSLug}_i${chatItemId}?title=${itemTitle}&cover_photo=${cover_photo}&description=${item_description}`

    const SubHeader = () => {
        return (
            <div className='mobile-option-container'>
                <div className='chat-header-two'>
                <div className='item-image-container-sm'>
                    <Link to={finalSLug} > 
                        <FKImage images={[itemImgResource]} type="item-message-inbox" />
                    </Link>
                    {itemStatusSwitch(itemSold)}
                </div>
                <div className="chat-header-details-container">
                    <Link to={finalSLug} className="chat-header-item-details"> 
                        <p className="chat-header-name">{itemTitle}</p>
                        <p className="chat-header-item-price">{`$${itemPrice}`}</p>
                    </Link>
                </div>
                </div>
                <div className="chat-header-offer-status mt-10">
                    {ratingOption}
                </div>
                {showReviewModal ? <ReviewModal detail={reviewModalDetail}/> : null}
            </div>
        )
    }

    return (
        <>
        <div className="pb-12 b-b-light-gray" style={{paddingTop: 20, position: 'fixed', width: '100%', zIndex: '2', background: 'white'}}>
            
            <div className="flex-container flex-direction-row flex-space-even mb-10 pr-16">
                <div className="pl-16">
                { thread ? <div onClick={toggleThread}><BackButton /></div> :<Link to="/"> <BackButton /> </Link> }    
                </div>
                {!thread ? <div className="flex-container w-100 flex-justify-center flex-direction-row ml-5 mr-5"><p className={'simple-inbox'}>Inbox</p></div>
                    :  isLoading ? null :
                        data.length > 0 ? 
                        <div className="flex-container w-100 flex-justify-center flex-direction-row ml-5 mr-5">
                           <Link to={loginIsItemOwner ? `/user/${chatGuestId}/listing` : `/user/${itemOwnerId}/listing`}>  { avatarSwitch() }</Link>
                            <div className="pl-12">
                                <div>
                                <Link to={loginIsItemOwner ? `/user/${chatGuestId}/listing` : `/user/${itemOwnerId}/listing`}> <p className="chat-header-name">{headerName}</p> </Link>
                                </div>
                                <div className="chat-header-rating">{ratingElement()}
                                    {<span style={{visibility: ratingAverage > 0 ? 'visible' : 'hidden' }} className="rating-count">{`(${ratingAverage})`}</span> }                        
                                </div>
                            </div>
                        </div>
                        : <div className="flex-container w-100 flex-justify-center flex-direction-row ml-5 mr-5"><p className="no-message">No message available</p></div>
                }               
                 {thread ? 
                <div className="p-relative" onClick={() => setShowEllipsisAction(!showEllipsisAction)}>
                    <EllipsisIcon />
                    {showEllipsisAction ? 
                        <div className="mobile-ellipsis-action-delete-container" onClick={() => setShowDeleteAlert(true)}> 
                            <TrashIconBtn />
                            <p>{`Delete Message`}</p>
                        </div> : null}
                </div> : null}
            </div>
            { data.length > 0 ? thread ? SubHeader() : null : null }
        </div>

        {showDeleteAlert ? <ChatDeleteModal sharedProps={{showDeleteAlert, setShowDeleteAlert, chatRoomData, userId, deleteChat}}/> : null}
        {showReviewModal ? <ReviewModal detail={reviewModalDetail}/> : null}
        {showRequestReviewAlert ? <RequestReviewAlert sharedProps={{showRequestReviewAlert, toggleShowRequestReviewAlert, requestReviewAlertProceed, chatRoomData, session}}/> : null}
        </>
    )
}

export default MobileHeader