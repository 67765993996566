import { SEARCH_WORD, SEARCH_WORD_MORE, ADD_TOP_SEARCH } from '../constant'
import { takeLatest, put } from 'redux-saga/effects'
import search from '../../graphql/search'
import { searchWordFail, searchWordSuccess, searchWordMoreSuccess } from '../action/searchAction'
import { theme } from '../../common/theme'

function* searchWordSaga(action) {      
    const word = action && action.payload && action.payload.key ? action.payload.key : null
    const type = action && action.payload && action.payload.type ? action.payload.type : null
    const filter = action && action.payload && action.payload.filter ? action.payload.filter : 'recent'
    const minPrice = action && action.payload && action.payload.minPrice ? action.payload.minPrice : 0
    const maxPrice = action && action.payload && action.payload.maxPrice ? action.payload.maxPrice : 1000000
    const orderField = filter ==='recent' ? 'dt_pub_date' : 'i_price'
    const orderType = filter !== 'lowToHigh' ? 'DESC' : 'ASC'
    const first = action && action.payload && action.payload.first ? action.payload.first : 20
    const page = action && action.payload && action.payload.page ? action.payload.page : 1

    try {
        const result = yield search.getSearch(first, page, word, type, orderType, orderField, minPrice, maxPrice)
        if(result === 403) {
            yield put(searchWordFail('Something went wrong try again!!!'))
        }
        else{
            let data
            if (type === 'user') {
                data = result && result.data && result.data.publicUserSearch && result.data.publicUserSearch ? result.data.publicUserSearch : {}
            } else {
                data = result && result.data && result.data.publicItemSearch && result.data.publicItemSearch ? result.data.publicItemSearch : {}
            }
            yield put(searchWordSuccess(data))
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(searchWordFail(errorMessage))
    }
}

export function* watchSearchWordSaga(){
    yield takeLatest(SEARCH_WORD.LOAD,searchWordSaga)
}

function* searchWordMoreSaga(action) {      
    const word = action && action.payload && action.payload.key ? action.payload.key : null
    const type = action && action.payload && action.payload.type ? action.payload.type : null
    const filter = action && action.payload && action.payload.filter ? action.payload.filter : 'recent'
    const minPrice = action && action.payload && action.payload.minPrice ? action.payload.minPrice : 0
    const maxPrice = action && action.payload && action.payload.maxPrice ? action.payload.maxPrice : 1000000
    const orderField = filter ==='recent' ? 'dt_pub_date' : 'i_price'
    const orderType = filter !== 'lowToHigh' ? 'DESC' : 'ASC'
    const first = action && action.payload && action.payload.first ? action.payload.first : 20
    const page = action && action.payload && action.payload.page ? action.payload.page : 1

    try {
        const result = yield search.getSearch(first, page, word, type, orderType, orderField, minPrice, maxPrice)
        if(result === 403) {
            yield put(searchWordFail('Something went wrong try again!!!'))
        }
        else{
            let data
            if (type === 'user') {
                data = result && result.data && result.data.publicUserSearch && result.data.publicUserSearch ? result.data.publicUserSearch : {}
            } else {
                data = result && result.data && result.data.publicItemSearch && result.data.publicItemSearch ? result.data.publicItemSearch : {}
            }
            yield put(searchWordMoreSuccess(data))
        }
    } catch (error) {
        console.log('error', error)
    }
}

export function* watchSearchWordMoreSaga(){
    yield takeLatest(SEARCH_WORD_MORE.LOAD,searchWordMoreSaga)
}

function* addTopSearchSaga(action) {   
    const id = action && action.payload ? action.payload : null
    try {
        const result = yield search.addTopSearch(id)
        //response not necessary
    } catch (error) {
        console.log('error', error)
    }
}

export function* watchAddTopSearchSaga(){
    yield takeLatest(ADD_TOP_SEARCH.LOAD,addTopSearchSaga)
}