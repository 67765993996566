import React, { useState, useEffect, useReducer }  from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ServiceContext } from '../../../../../../common/context'
import ReactPaginate from 'react-paginate'
import { adminServiceLoad, adminServiceReset, adminUpsertServiceLoad, adminUpsertServiceReset, adminDeleteServiceLoad, adminDeleteServiceReset } from '../../../../../../redux/action'
import Loader from '../../../../../../common/component/loader'
import { CoinServiceList, CoinServiceModal } from './component/CustomComponent'

const CoinServiceSection = (props) => {          
    const { adminServiceIsLoading, adminServiceData, adminServiceError, adminServicePage, adminServiceTotal,
    adminServiceUpsertIsLoading, adminServiceUpsertError, adminServiceUpsertMessage,
    adminServiceDeleteIsLoading, adminServiceDeleteError, adminServiceDeleteMessage
    } = props.admin
    const pageCount = 10
    const [isShow, setIsShow] = useState(false)
    const [textInput, setTextInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            id: '',
            name: '',
            description: '',
            status: "true",
            sg_price: 0,
            credit: '',
            type: 'coin_bundle',
            discount: 0,
            live_list_count: 0,
            top_ad_count: 0,
            highlight_count: 0,
            auto_bump_count: 0,
            cover_photo: false,
            youtube_link: false
        }
    );

    useEffect(() => {
        if (adminServiceData && adminServiceData.length === 0) {
            props.adminServiceLoad({first: 20, page: adminServicePage, type: 'coin_bundle'})
        }
    // eslint-disable-next-line     
    },[])

    const location = useLocation()
    const path = location.pathname

    useEffect(() => {
        props.adminServiceReset()
        props.adminServiceLoad({first: 20, page: 1, type: 'coin_bundle'})
    },[path])

    useEffect(()=>{
        if (adminServiceUpsertMessage || adminServiceUpsertError) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertServiceReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/service/coin`)
            }, 2000)
        }
        if (adminServiceDeleteError, adminServiceDeleteMessage) {
            setTimeout(() => {
                setIsShow(false)
                props.adminDeleteServiceReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/service/coin`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminServiceUpsertMessage, adminServiceUpsertError, adminServiceDeleteError, adminServiceDeleteMessage])

    const handleEdit = (item) => {
        setTextInput({
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
            sg_price: item.sg_price,
            credit: item.credit ? item.credit : '',
            type: item.type,
            discount: item.discount,
            live_list_count: item && item.live_list_count ? item.live_list_count : 0,
            top_ad_count: item && item.top_ad_count ? item.top_ad_count : 0,
            highlight_count: item && item.highlight_count ? item.highlight_count : 0,
            auto_bump_count: item && item.auto_bump_count ? item.auto_bump_count : 0,
            cover_photo: item && item.cover_photo ? item.cover_photo : false,
            youtube_link: item && item.youtube_link ? item.youtube_link : false
        });
        setIsShow(true)
    }

    const closeModal = () => {
        setTextInput({
            id: '',
            name: '',
            description: '',
            status: true,
            sg_price: 0,
            credit: '',
            type: 'coin_bundle',
            discount: 0,
            live_list_count: 0,
            top_ad_count: 0,
            highlight_count: 0,
            auto_bump_count: 0,
            cover_photo: false,
            youtube_link: false

        });
        setIsShow(false)
    }


    const handleAdd = () => {
        setIsShow(true)
    }

    const handleDelete = (id) => {
        props.adminDeleteServiceLoad({id: id})
    }

    const upsertService = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertServiceLoad(data)
    }

    const handlePageClick = data => {
        props.adminServiceLoad({first: 20, page: data.selected + 1, type: 'coin_bundle'})
    };

    return (
        <ServiceContext.Provider value={{
            adminServiceData, adminServiceIsLoading, adminServiceError, handleEdit, handleDelete,
            isShow, closeModal, upsertService,  
            adminServiceUpsertIsLoading, adminServiceUpsertError, adminServiceUpsertMessage,
            textInput, setTextInput,
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Subscription, Otp and Coin Services</h3>
            <section>
            {
                adminServiceIsLoading || adminServiceDeleteIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminServiceError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminServiceError}</p></div></article> :
                adminServiceDeleteMessage ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 200 }}><div><p style={{color: 'green'}}>{adminServiceDeleteMessage}</p></div></article> :
                adminServiceDeleteError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminServiceDeleteError}</p></div></article> :
                <article style={{flexDirection: 'column'}}>
                    <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={() => handleAdd(true)} className="sellButton pointer" style={{margin: 10, alignSelf: 'flex-start'}}>
                            <span>Add Coin Service</span>
                        </div>
                    </div>
                    <CoinServiceList type='load'/>
                </article>
            }
            <div></div> 
            {adminServiceError || adminServiceDeleteError || adminServiceDeleteMessage ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminServiceTotal}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }           
            </section> 
        </section> 
        {isShow ? <CoinServiceModal /> : null }
        </ServiceContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminServiceLoad,
    adminServiceReset,
    adminUpsertServiceLoad,
    adminUpsertServiceReset,
    adminDeleteServiceLoad, 
    adminDeleteServiceReset
}
  
export default connect(mapStateToProps, mapActionToProps)(CoinServiceSection);
