import React, {useEffect, useReducer, useState} from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { numberWithCommas, slugify, categoryParser, saveLocalStorage} from '../../../../common/helper/utility'
import SocialAvatar from '../../../../common/component/socialAvatar'
import FKImage from '../../../../common/component/image'
import HomeItemTag from '../../../../common/component/tags/HomeItemTag'
import DefaultItemPhoto from '../../../../assets/images/commerce-and-shopping.png'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import {WTT, WTB} from '../../../../common/component/label'

const itemReducer = (state, action) => {
    switch (action.type) {
      case 'topAd':
        return {...state, isTopAd: true}
      case 'highlight':
        return {...state, isHighlight: true}
      case 'toggleShow':
        return {...state, isShow: !state.isShow}    
      default:
        break;
    }
  }

  
const SearchItemCard = (props) => {
    const {item, _addTopSearch} = props
    const { watchlist_count, watchlist } = item
    const id = item && item.pk_i_id ? item.pk_i_id : ''
    const date_published = item && item.dt_pub_date ? item.dt_pub_date : null
    const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null
    const session_id = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? parseInt(props.session.fkv2_session.id) : null
    const user_resource = props.item && props.item.oc_t_user && props.item.oc_t_user.oc_t_user_resource ? props.item.oc_t_user.oc_t_user_resource : null
    const sellerUsername = props.item && props.item.oc_t_user && props.item.oc_t_user.s_username ? props.item.oc_t_user.s_username : null
    const user_social_id = props.item.oc_t_user && props.item.oc_t_user.social && props.item.oc_t_user.social[0] && props.item.oc_t_user.social[0].data
    && props.item.oc_t_user.social[0].data.id ? props.item.oc_t_user.social[0].data.id : ''
    const socialImageSrc = props.item.oc_t_user && props.item.oc_t_user.social && props.item.oc_t_user.social[0] && props.item.oc_t_user.social[0].data
      && props.item.oc_t_user.social[0].data.picture && props.item.oc_t_user.social[0].data.picture.data && props.item.oc_t_user.social[0].data.picture.data.url ? props.item.oc_t_user.social[0].data.picture.data.url : null
    const item_title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
    const [state, dispatch] = useReducer(itemReducer, {isTopAd: false, isHighlight: false, isShow: false})
    const item_resource = item && item.oc_t_item_resource ? item.oc_t_item_resource : []
    const item_price = item && item.i_price ? numberWithCommas((item.get_i_price).toFixed(2)) : ''
    const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
    const reserved = item && item.b_reserved ? item.b_reserved : false
    const ownerId = props.item && props.item.oc_t_user && props.item.oc_t_user.pk_i_id ? props.item.oc_t_user.pk_i_id : 0
    const searchword = props.searchword ? props.searchword : ''
    const categoryId = props.item && props.item.oc_t_category && props.item.oc_t_category.pk_i_id ? props.item.oc_t_category.pk_i_id : 0

    const [isLiked, setIsLiked] = useState(false)
    const isNew = item.is_new ? item.is_new : false

    const searchSaveToLocal = () => {
      if(session_id){
          const localStorageArr = {key: searchword, sessionUserId: session_id, itemId: id, categoryId: categoryId}
          saveLocalStorage("fkv2_search_key", localStorageArr)
      }
      _addTopSearch(id)
  }

  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (item && item.ta_expiration) {
      const isTopAd = item && item.ta_expiration ? moment(item.ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({type: 'topAd'})
      }
    }
    if (item && item.hl_expiration) {
      const isHighlight = item && item.hl_expiration ? moment(item.hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({type: 'highlight'})
      }
    }
  // eslint-disable-next-line  
  }, [])

  useEffect(() => {
    if (watchlist_count > 0) {
        watchlist.map(item => {
          if (session_id === item.fk_i_user_id) {
            setIsLiked(true)
          }
          return null
        })
    }
  // eslint-disable-next-line  
  }, [watchlist])

  // const toggleLiked = (item_id) => {
  //   if (!session_id) {
  //     alert(theme.text.loginToLike)
  //   } else {
  //     setIsLiked(!isLiked)
  //     likeDislike(item_id)
  //   }

    const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
    const itemSLug = slugify(item_title)
    const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
    const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
    const finalSLug = `/${category}/${itemSLug}_i${id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`
    const post_type = item && item.post_type ? item.post_type : ''

    return (
        <div className={`home-item-card regular`}>     
            <div style={{display: 'flex',width: '100%', justifyContent: 'flex-start', whiteSpace: 'nowrap', padding: '0px 5px', paddingTop: '8px'}}>
              <div style={{position: 'relative', left:'-5px'}}>
                {
                  state.isTopAd ? sold ? null :
                  <div id="ribbon-container">
                  <p >{state.isTopAd ? sold ? null :`Premium` : null}</p>
                  </div> : null
                }
              </div>
            <div style={{display: 'flex', marginBottom: '10px'}}>
              <Link to={parseInt(ownerId) === session_id ? `/user/listing` : `/user/${ownerId}/listing`} style={{width: '100%', display: 'flex'}}>
                {socialImageSrc ? <SocialAvatar id={user_social_id} url={socialImageSrc} /> : user_resource ? <FKImage images={[user_resource]} type="seller_avatar" alt="seller avatar"/> : <img src={DefaultPhoto} width={40} height={40} style={{borderRadius: 20}} alt="Default photo avatar"/>}
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: window.innerWidth < 600 ? '125px': '200px'}}>
                <p style={{paddingLeft: '8px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: 16}}>{sellerUsername}</p>
                <p style={{paddingLeft: '8px', fontSize: window.innerWidth < 600 ? '9.5px' : 12, fontStyle: 'normal', letterSpacing: '0.44px', color: '#c4c4c4'}}>{`${formatted_date}`}</p>
                </div>
              </Link>
            </div>
            </div>
            <div style={{border: state.isTopAd ? sold ? 'none' : '0.5px solid rgb(230, 126, 34)' : 'none', width: '100%', height: '100%', borderRadius: '6px', background: state.isHighlight ? sold ? '#FFFFFF' : '#FFF1E5': '#FFFFFF', paddingBottom: '5px'}}>
            <Link to={finalSLug} style={{width: '100%'}} onClick={() => searchSaveToLocal()}>
              <div style={{flex: 1, minHeight: 130, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', width: '100%', overflow: 'hidden', position: 'relative', borderRadius: '6px'}} id="item_image">
                {
                item_resource.length !== 0 ? 
                  <FKImage images={item_resource} type="thumbnail" isNew={isNew}/> : 
                  <div style={{
                    background: "linear-gradient(to right, rgb(189, 195, 199), rgb(44, 62, 80))", 
                    width: "100%", 
                    height: "140px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img src={DefaultItemPhoto} width={100} height={100} alt="item default photo" />
                  </div>
                }
                <HomeItemTag sold={sold} reserved={reserved}/>
              </div>
              <div className="flex w-100 p-0-8 mt-5 flex-justify-between flex-align-center" >
                <p className="itemcard-item-price">{`${item_price} $`}</p>
                {
                  post_type ?
                  post_type === 'WTS' ? null : 
                  <div className="mt-7">{
                  post_type === 'WTB' ? <WTB /> : <WTT /> }</div>: null
                }
              </div>
              <div style={{overflow: 'hidden', height: 32}}>
              <p className="itemcard-item-title">{item_title}</p>
              </div>
            </Link>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
            {
              session_id ? parseInt(ownerId) === parseInt(session_id) ? null :
              <div style={{borderTop: '1px solid rgb(196, 196, 196)', width: '100%',display: 'flex', justifyContent: 'center', padding: '6px'}}>
              <Link to={{
                pathname: !session_id ? `/login` : `/user/messages/inbox`,
                state: {fromMessage: true, item: item}
                }} 
                className={`btn-modal-options ${window.innerWidth < 600 ? `message-btn-mobile` : null}`}>Message
              </Link> </div> :  <div style={{borderTop: '1px solid rgb(196, 196, 196)', width: '100%',display: 'flex', justifyContent: 'center', padding: '6px'}}>
              <Link to={{
                pathname: !session_id ? `/login` : `/user/messages/inbox`,
                state: {fromMessage: true, item: item}
                }} 
                className={`btn-modal-options ${window.innerWidth < 600 ? `message-btn-mobile` : null}`}>Message
              </Link> </div>
            }    </div>
            </div>
           
        </div>
    );
}

export default SearchItemCard