import { DBS_TOKEN_REQUEST } from '../constant'    
    const initialState = {
        isLoading: false,
        toKenData: null,
        error: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case DBS_TOKEN_REQUEST.LOAD:
            return {...state, isLoading: true, error: null}

            case DBS_TOKEN_REQUEST.SUCCESS:
            return {...state, isLoading: false, toKenData:  action.payload, error: null}

            case DBS_TOKEN_REQUEST.FAIL:
            return {...state, isLoading: false, error: action.payload.message}

            default:
            return state;  
        }
    }
    
    