import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const getUserItems = (first, page, user_id, orderField, orderType) => {
  console.log("gql")
    try {
        const query = gql`query publicGetItemsByUser($first: Int, $page: Int, $fk_i_user_id: Int!, $orderField: String!, $orderType: SortOrder!){
          publicGetItemsByUser(fk_i_user_id: $fk_i_user_id, first: $first, page: $page,
              orderBy: [
             {
              field: $orderField,
              order: $orderType
             }
            ]
          ){
            paginatorInfo {
              total
            }
            data {
              b_active
              is_new
              pk_i_id
              fk_i_user_id
              s_contact_name
              dt_pub_date
              dt_sold
              i_price
              b_reserved
              b_mark_as_sold
              hl_expiration
              ta_expiration
              ab_expiration
              watchlist_count
              s_dm_meetup_add1
              s_dm_meetup_add2
              s_dm_meetup_add1_notes
              s_dm_meetup_add2_notes
              s_item_condition
              youtube_embed_link
              post_type
              watchlist {
                fk_i_user_id
                fk_i_item_id
              }
              watchlist_count
              pageview_count
              get_i_price
              live
              oc_t_category {
                pk_i_id
                fk_i_parent_id
                oc_t_category_descriptions{
                  fk_i_category_id
                  s_name
                }
                oc_t_custom_field {
                  name
                  label
                  placeholder
                  fk_i_category_id
                  type
                  custom_index
                }
              }
              oc_t_item_description {
                s_title
                s_description
              }
              oc_t_user{
                pk_i_id
                oc_t_user_resource{
                  pk_i_id
                  fk_i_user_id
                  s_extension
                  s_name
                  s_content_type
                  s_path
                }
              }
              oc_t_item_resource {
                pk_i_id
                fk_i_item_id
                s_name
                s_extension
                s_content_type
                s_path
              }
              custom_fields
            }
          }
        }`;
        const response = client.query({
            query: query,
            variables: { first: first, page: page, fk_i_user_id: user_id, orderField: orderField, orderType: orderType},
            // fetchPolicy: 'no-cache'
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('gql e', JSON.stringify(e))
        throw e
    }
}

export default {
    getUserItems
}