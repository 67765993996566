import React from 'react';
import PropTypes from 'prop-types';

const LayoutMessage = ( { component: Component, type, session, ...rest } ) => (
    <React.Fragment>
      <Component {...rest} session={session}/>
    </React.Fragment>
);

LayoutMessage.propTypes = {
    component: PropTypes.any
};

export default LayoutMessage