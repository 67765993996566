import React from 'react'

export const PremiumTag = () => {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="91"
      height="30"
      fill="none"
      viewBox="0 0 91 30"
    >
      <path fill="#F57223" d="M0 0H72V23H0z"></path>
      <path
        fill="#F57223"
        d="M91 0H71v23h20l-7-11 7-12zM0 23l4.5-5.63v11.26L0 23z"
      ></path>
      <path
        fill="#fff"
        d="M14.55 16h1.414v-3.346h2.632c2.03 0 3.57-1.302 3.57-3.136s-1.54-3.136-3.57-3.136H14.55V16zm1.414-4.662v-3.64H18.4c1.386 0 2.324.686 2.324 1.82s-.938 1.82-2.324 1.82h-2.436zm9.092-1.19V8.762H23.67V16h1.386v-4.116c.126-1.26 1.19-2.086 2.688-2.086V8.594c-1.246 0-2.226.602-2.688 1.554zm7.358 4.788c-1.246 0-2.128-.868-2.31-2.072h5.698c0-2.59-1.274-4.27-3.486-4.27-2.002 0-3.612 1.596-3.612 3.766 0 2.226 1.708 3.808 3.724 3.808 1.022 0 2.282-.434 2.926-1.12l-.868-.896c-.434.448-1.316.784-2.072.784zm-.042-5.11c1.246 0 1.932.812 2.086 1.932h-4.34c.224-1.176 1.134-1.932 2.254-1.932zM45.5 8.594c-1.092 0-2.016.56-2.352 1.456-.42-.896-1.274-1.456-2.324-1.456-.924 0-1.68.462-2.044 1.176V8.762h-1.386V16h1.386v-4.522c0-.994.728-1.68 1.764-1.68.91 0 1.54.742 1.54 1.806V16h1.358v-4.522c0-.994.728-1.68 1.764-1.68.91 0 1.54.742 1.54 1.806V16h1.358v-4.606c0-1.638-1.092-2.8-2.604-2.8zm5.282-1.036c.448 0 .826-.392.826-.84a.846.846 0 00-.826-.826.84.84 0 00-.84.826c0 .448.364.84.84.84zm-.7 8.442h1.386V8.762h-1.386V16zm8.375-7.238v4.69c-.112.854-.98 1.512-1.988 1.512-.98 0-1.666-.756-1.666-1.834V8.762h-1.358v4.606c0 1.638 1.12 2.8 2.716 2.8.98 0 1.862-.504 2.296-1.218V16h1.386V8.762h-1.386zm11.489-.168c-1.092 0-2.016.56-2.352 1.456-.42-.896-1.274-1.456-2.324-1.456-.924 0-1.68.462-2.044 1.176V8.762H61.84V16h1.386v-4.522c0-.994.728-1.68 1.764-1.68.91 0 1.54.742 1.54 1.806V16h1.358v-4.522c0-.994.728-1.68 1.764-1.68.91 0 1.54.742 1.54 1.806V16h1.358v-4.606c0-1.638-1.092-2.8-2.604-2.8z"
      ></path>
    </svg>
    )
}

export const NewTag = () => {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="30"
      fill="none"
      viewBox="0 0 74 30"
    >
      <path
        fill="#FFE600"
        d="M0 0H63V23H0z"
        transform="matrix(-1 0 0 1 73.5 0)"
      ></path>
      <path
        fill="#FFE600"
        d="M.5 0h20v23H.5l7-11-7-12zM73.5 23L69 17.37v11.26L73.5 23z"
      ></path>
      <path
        fill="#FF1616"
        d="M23.047 16h1.415V8.706L29.992 16h1.413V6.382h-1.414v7.294l-5.53-7.294h-1.413V16zm14.35-1.064c-1.247 0-2.129-.868-2.31-2.072h5.697c0-2.59-1.274-4.27-3.486-4.27-2.002 0-3.612 1.596-3.612 3.766 0 2.226 1.708 3.808 3.724 3.808 1.022 0 2.282-.434 2.926-1.12l-.868-.896c-.434.448-1.316.784-2.072.784zm-.043-5.11c1.246 0 1.932.812 2.086 1.932H35.1c.224-1.176 1.134-1.932 2.254-1.932zM44.31 16h1.274l1.75-5.376L49.07 16h1.274l2.436-7.238h-1.414l-1.652 5.39-1.792-5.39h-1.176l-1.792 5.39-1.666-5.39h-1.414L44.31 16z"
      ></path>
    </svg>
    )
}

export const SoldTag = () => {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="30"
      fill="none"
      viewBox="0 0 74 30"
    >
      <path
        fill="#979797"
        d="M0 0H63V23H0z"
        transform="matrix(-1 0 0 1 73.5 0)"
      ></path>
      <path
        fill="#979797"
        d="M.5 0h20v23H.5l7-11-7-12zM73.5 23L69 17.37v11.26L73.5 23z"
      ></path>
      <path
        fill="#fff"
        d="M26.057 16.154c2.268 0 3.5-1.232 3.5-2.856 0-1.722-1.33-2.366-3.248-2.814-1.582-.378-2.408-.686-2.408-1.582 0-.77.756-1.456 1.834-1.456.98 0 1.946.434 2.758 1.176l.812-1.064C28.38 6.746 27.275 6.2 25.79 6.2c-1.932 0-3.332 1.218-3.332 2.828 0 1.736 1.302 2.324 3.262 2.786 1.61.364 2.408.714 2.408 1.624 0 .77-.686 1.47-2.016 1.47-1.302 0-2.31-.532-3.164-1.344l-.854 1.05c1.022.98 2.352 1.54 3.962 1.54zm10.297.014c2.702 0 4.97-2.282 4.97-4.984s-2.268-4.97-4.97-4.97-4.998 2.268-4.998 4.97 2.296 4.984 4.998 4.984zm0-1.302c-1.918 0-3.542-1.68-3.542-3.682 0-1.988 1.624-3.668 3.542-3.668 1.918 0 3.528 1.68 3.528 3.668 0 2.002-1.61 3.682-3.528 3.682zm8.443-.182V6.382h-1.428V16h6.524v-1.316h-5.096zM51.99 16h3.318c3.108 0 5.32-2.002 5.32-4.816s-2.212-4.802-5.32-4.802h-3.318V16zm1.414-1.288V7.67h1.988c2.226 0 3.78 1.456 3.78 3.514 0 2.072-1.554 3.528-3.78 3.528h-1.988z"
      ></path>
    </svg>
    )
}