import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminCustomFieldSuccess, adminCustomFieldFailed, adminUpsertCustomFieldSuccess, adminUpsertCustomFieldFailed, adminDeleteCustomFieldSuccess, adminDeleteCustomFieldFailed } from "../../action";
import admin from '../../../graphql/admin/customField'
import { theme } from '../../../common/theme'

//fetch preference
function* adminCustomField(action) {
    const { payload } = action
    try{
        const result = yield admin.getCustomField(payload)

        if(result === 403) {
            yield put(adminCustomFieldFailed('Something went wrong try again!'))
        }
        else {
            const data = result && result.data && result.data.getDashboardDataCustomField ? result.data.getDashboardDataCustomField : {}
            yield put(adminCustomFieldSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminCustomFieldFailed(errorMessage))
    }
}

export function* watchAdminCustomField() {
    yield takeLatest(ADMIN.CUSTOM_FIELD.LOAD, adminCustomField);
}

//upsert custom field
function* adminUpsertCustomField(action) {
    const { payload } = action
    const queryType = payload.queryType

    try{
        const result = yield admin.upsertCustomField(payload)
        if(result === 403) {
            yield put(adminUpsertCustomFieldFailed('Something went wrong try again!'))
        }
        else {
            let data 
            if (queryType === 'UPDATE') {
                data = result.data && result.data.customFieldUpdate && result.data.customFieldUpdate ? result.data.customFieldUpdate : {}
            } else {
                data = result.data && result.data.customFieldInsert && result.data.customFieldInsert ? result.data.customFieldInsert : {}
            }
            yield put(adminUpsertCustomFieldSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertCustomFieldFailed(errorMessage))
    }
}

export function* watchAdminUpsertCustomField() {
    yield takeLatest(ADMIN.CUSTOM_FIELD_UPSERT.LOAD, adminUpsertCustomField);
}

//delete customField
function* adminDeleteCustom(action) {
    const { payload } = action
    try{
        const result = yield admin.deleteCustomField(payload)

        if(result === 403) {
            yield put(adminDeleteCustomFieldFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.customFieldDelete && result.data.customFieldDelete ? result.data.customFieldDelete : {}
            yield put(adminDeleteCustomFieldSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeleteCustomFieldFailed(errorMessage))
    }
}

export function* watchAdminDeleteCustomField() {
    yield takeLatest(ADMIN.CUSTOM_FIELD_DELETE.LOAD, adminDeleteCustom);
}


