import { ADMIN } from '../constant'

export const adminLoginLoad = (payload) =>{
    return {
        type: ADMIN.LOGIN.LOAD,
        payload
    }
}    

export const adminLoginSuccess = (payload) =>{
    return {
        type: ADMIN.LOGIN.SUCCESS,
        payload,
    }
}

export const adminLoginFailed = (payload) =>{
    return {
        type: ADMIN.LOGIN.FAIL,
        payload,
    }
}

export const adminLoginReset = () =>{
    return {
        type: ADMIN.LOGIN.RESET,
    }
}

//get admin session
export const adminSessionLoad = () =>{
    return {
        type: ADMIN.SESSION_ADMIN.LOAD,
    }
}

export const adminSessionSuccess = (payload) => {
    return {
        type: ADMIN.SESSION_ADMIN.SUCCESS,
        payload,
    }
}

export const adminSessionFail = (payload) => {
    return {
        type: ADMIN.SESSION_ADMIN.FAIL,
        payload,
    }
}

//send forgot password email
export const adminForgotPasswordLoad = (payload) =>{
    return {
        type: ADMIN.SEND_FORGOT_PASSWORD.LOAD,
        payload
    }
}   

export const adminForgotPasswordSuccess = (payload) =>{
    return {
        type: ADMIN.SEND_FORGOT_PASSWORD.SUCCESS,
        payload,
    }
}

export const adminForgotPasswordFailed = (payload) =>{
    return {
        type: ADMIN.SEND_FORGOT_PASSWORD.FAIL,
        payload,
    }
}

export const adminForgotPasswordClearMessage = () =>{
    return {
        type: ADMIN.SEND_FORGOT_PASSWORD.CLEAR_MESSAGE,
    }
}

//change password
export const adminChangePasswordLoad = (payload) =>{
    return {
        type: ADMIN.CHANGE_PASSWORD.LOAD,
        payload
    }
}   

export const adminChangePasswordSuccess = (payload) =>{
    return {
        type: ADMIN.CHANGE_PASSWORD.SUCCESS,
        payload,
    }
}

export const adminChangePasswordFailed = (payload) =>{
    return {
        type: ADMIN.CHANGE_PASSWORD.FAIL,
        payload,
    }
}

export const adminChangePasswordClearMessage = () =>{
    return {
        type: ADMIN.CHANGE_PASSWORD.CLEAR_MESSAGE,
    }
}

//fetch users
export const adminUserLoad = (payload) =>{
    return {
        type: ADMIN.USER.LOAD,
        payload
    }
}    

export const adminUserSuccess = (payload) =>{
    return {
        type: ADMIN.USER.SUCCESS,
        payload,
    }
}

export const adminUserFailed = (payload) =>{
    return {
        type: ADMIN.USER.FAIL,
        payload,
    }
}

export const adminUserReset = () =>{
    return {
        type: ADMIN.USER.RESET,
    }
}

//fetch user profile
export const adminUserProfileLoad = (payload) =>{
    return {
        type: ADMIN.USER_PROFILE.LOAD,
        payload
    }
}    

export const adminUserProfileSuccess = (payload) =>{
    return {
        type: ADMIN.USER_PROFILE.SUCCESS,
        payload,
    }
}

export const adminUserProfileFailed = (payload) =>{
    return {
        type: ADMIN.USER_PROFILE.FAIL,
        payload,
    }
}

export const adminUserProfileReset = () =>{
    return {
        type: ADMIN.USER_PROFILE.RESET,
    }
}

//upsert user
export const adminUpsertUserLoad = (payload) =>{
    return {
        type: ADMIN.USER_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertUserSuccess = (payload) =>{
    return {
        type: ADMIN.USER_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertUserFailed = (payload) =>{
    return {
        type: ADMIN.USER_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertUserReset = () =>{
    return {
        type: ADMIN.USER_UPSERT.RESET,
    }
}

//suggest user
export const adminUserSuggestLoad = (payload) =>{
    return {
        type: ADMIN.USER_SUGGEST.LOAD,
        payload
    }
}    

export const adminUserSuggestSuccess = (payload) =>{
    return {
        type: ADMIN.USER_SUGGEST.SUCCESS,
        payload,
    }
}

export const adminUserSuggestFailed = (payload) =>{
    return {
        type: ADMIN.USER_SUGGEST.FAIL,
        payload,
    }
}

export const adminUserSuggestReset = () =>{
    return {
        type: ADMIN.USER_SUGGEST.RESET,
    }
}

//fetch items
export const adminItemLoad = (payload) =>{
    return {
        type: ADMIN.ITEM.LOAD,
        payload
    }
}    

export const adminItemSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM.SUCCESS,
        payload,
    }
}

export const adminItemFailed = (payload) =>{
    return {
        type: ADMIN.ITEM.FAIL,
        payload,
    }
}

export const adminItemReset = () =>{
    return {
        type: ADMIN.ITEM.RESET,
    }
}

//fetch item detail
export const adminItemDetailLoad = (payload) =>{
    return {
        type: ADMIN.ITEM_DETAIL.LOAD,
        payload
    }
}    

export const adminItemDetailSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM_DETAIL.SUCCESS,
        payload,
    }
}

export const adminItemDetailFailed = (payload) =>{
    return {
        type: ADMIN.ITEM_DETAIL.FAIL,
        payload,
    }
}

export const adminItemDetailReset = () =>{
    return {
        type: ADMIN.ITEM_DETAIL.RESET,
    }
}

//upsert item
export const adminUpdateItemLoad = (payload) =>{
    return {
        type: ADMIN.ITEM_UPDATE.LOAD,
        payload
    }
}    

export const adminUpdateItemSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM_UPDATE.SUCCESS,
        payload,
    }
}

export const adminUpdateItemFailed = (payload) =>{
    return {
        type: ADMIN.ITEM_UPDATE.FAIL,
        payload,
    }
}

export const adminUpdateItemReset = () =>{
    return {
        type: ADMIN.ITEM_UPDATE.RESET
    }
}

//suggest item
export const adminItemSuggestLoad = (payload) =>{
    return {
        type: ADMIN.ITEM_SUGGEST.LOAD,
        payload
    }
}    

export const adminItemSuggestSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM_SUGGEST.SUCCESS,
        payload,
    }
}

export const adminItemSuggestFailed = (payload) =>{
    return {
        type: ADMIN.ITEM_SUGGEST.FAIL,
        payload,
    }
}

export const adminItemSuggestReset = () =>{
    return {
        type: ADMIN.ITEM_SUGGEST.RESET,
    }
}

//items by user
export const adminItemByUserLoad = (payload) =>{
    return {
        type: ADMIN.ITEM_BY_USER.LOAD,
        payload
    }
}    

export const adminItemByUserSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM_BY_USER.SUCCESS,
        payload,
    }
}

export const adminItemByUserFailed = (payload) =>{
    return {
        type: ADMIN.ITEM_BY_USER.FAIL,
        payload,
    }
}

export const adminItemByUserReset = () =>{
    return {
        type: ADMIN.ITEM_BY_USER.RESET,
    }
}

//fetch sales
export const adminSaleLoad = (payload) =>{
    return {
        type: ADMIN.SALE.LOAD,
        payload
    }
}    

export const adminSaleSuccess = (payload) =>{
    return {
        type: ADMIN.SALE.SUCCESS,
        payload,
    }
}

export const adminSaleFailed = (payload) =>{
    return {
        type: ADMIN.SALE.FAIL,
        payload,
    }
}

export const adminSaleReset = () =>{
    return {
        type: ADMIN.SALE.RESET,
    }
}

//fetch sale detail
export const adminSaleDetailLoad = (payload) =>{
    return {
        type: ADMIN.SALE_DETAIL.LOAD,
        payload
    }
}    

export const adminSaleDetailSuccess = (payload) =>{
    return {
        type: ADMIN.SALE_DETAIL.SUCCESS,
        payload,
    }
}

export const adminSaleDetailFailed = (payload) =>{
    return {
        type: ADMIN.SALE_DETAIL.FAIL,
        payload,
    }
}

export const adminSaleDetailReset = () =>{
    return {
        type: ADMIN.SALE_DETAIL.RESET,
    }
}

//suggest sale
export const adminSaleSuggestLoad = (payload) =>{
    return {
        type: ADMIN.SALE_SUGGEST.LOAD,
        payload
    }
}    

export const adminSaleSuggestSuccess = (payload) =>{
    return {
        type: ADMIN.SALE_SUGGEST.SUCCESS,
        payload,
    }
}

export const adminSaleSuggestFailed = (payload) =>{
    return {
        type: ADMIN.SALE_SUGGEST.FAIL,
        payload,
    }
}

export const adminSaleSuggestReset = () =>{
    return {
        type: ADMIN.SALE_SUGGEST.RESET,
    }
}


//fetch preference
export const adminPreferenceLoad = (payload) =>{
    return {
        type: ADMIN.PREFERENCE.LOAD,
        payload
    }
}    

export const adminPreferenceSuccess = (payload) =>{
    return {
        type: ADMIN.PREFERENCE.SUCCESS,
        payload,
    }
}

export const adminPreferenceFailed = (payload) =>{
    return {
        type: ADMIN.PREFERENCE.FAIL,
        payload,
    }
}

export const adminPreferenceReset = () =>{
    return {
        type: ADMIN.PREFERENCE.RESET,
    }
}

//fetch subscription
export const adminSubscriptionLoad = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION.LOAD,
        payload
    }
}    

export const adminSubscriptionSuccess = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION.SUCCESS,
        payload,
    }
}

export const adminSubscriptionFailed = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION.FAIL,
        payload,
    }
}

export const adminSubscriptionReset = () =>{
    return {
        type: ADMIN.SUBSCRIPTION.RESET,
    }
}

//fetch subscription detail
export const adminSubscriptionDetailLoad = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_DETAIL.LOAD,
        payload
    }
}    

export const adminSubscriptionDetailSuccess = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_DETAIL.SUCCESS,
        payload,
    }
}

export const adminSubscriptionDetailFailed = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_DETAIL.FAIL,
        payload,
    }
}

export const adminSubscriptionDetailReset = () =>{
    return {
        type: ADMIN.SUBSCRIPTION_DETAIL.RESET,
    }
}

export const adminUpdateSubscriptionLoad = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_UPDATE.LOAD,
        payload
    }
}    

export const adminUpdateSubscriptionSuccess = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_UPDATE.SUCCESS,
        payload,
    }
}

export const adminUpdateSubscriptionFailed = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_UPDATE.FAIL,
        payload,
    }
}

export const adminUpdateSubscriptionReset = () =>{
    return {
        type: ADMIN.SUBSCRIPTION_UPDATE.RESET
    }
}

//subscription search
export const adminSubscriptionSearchLoad = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_SEARCH.LOAD,
        payload
    }
}    

export const adminSubscriptionSearchSuccess = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_SEARCH.SUCCESS,
        payload,
    }
}

export const adminSubscriptionSearchFailed = (payload) =>{
    return {
        type: ADMIN.SUBSCRIPTION_SEARCH.FAIL,
        payload,
    }
}

export const adminSubscriptionSearchReset = () =>{
    return {
        type: ADMIN.SUBSCRIPTION_SEARCH.RESET,
    }
}

//fetch logs
export const adminLogsLoad = (payload) =>{
    return {
        type: ADMIN.LOGS.LOAD,
        payload
    }
}    

export const adminLogsSuccess = (payload) =>{
    return {
        type: ADMIN.LOGS.SUCCESS,
        payload,
    }
}

export const adminLogsFailed = (payload) =>{
    return {
        type: ADMIN.LOGS.FAIL,
        payload,
    }
}

export const adminLogsReset = () =>{
    return {
        type: ADMIN.LOGS.RESET,
    }
}


//upsert preference
export const adminUpsertPreferenceLoad = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertPreferenceSuccess = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertPreferenceFailed = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertPreferenceReset = () =>{
    return {
        type: ADMIN.PREFERENCE_UPSERT.RESET
    }
}

//delete preference
export const adminDeletePreferenceLoad = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_DELETE.LOAD,
        payload
    }
}    

export const adminDeletePreferenceSuccess = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeletePreferenceFailed = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_DELETE.FAIL,
        payload,
    }
}

//suggest preference
export const adminPreferenceSuggestLoad = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_SUGGEST.LOAD,
        payload
    }
}    

export const adminPreferenceSuggestSuccess = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_SUGGEST.SUCCESS,
        payload,
    }
}

export const adminPreferenceSuggestFailed = (payload) =>{
    return {
        type: ADMIN.PREFERENCE_SUGGEST.FAIL,
        payload,
    }
}

export const adminPreferenceSuggestReset = () =>{
    return {
        type: ADMIN.PREFERENCE_SUGGEST.RESET,
    }
}

//fetch category
export const adminCategoryLoad = (payload) =>{
    return {
        type: ADMIN.CATEGORY.LOAD,
        payload
    }
}    

export const adminCategorySuccess = (payload) =>{

    return {
        type: ADMIN.CATEGORY.SUCCESS,
        payload,
    }
}

export const adminCategoryFailed = (payload) =>{
    return {
        type: ADMIN.CATEGORY.FAIL,
        payload,
    }
}

export const adminCategoryReset = () =>{
    return {
        type: ADMIN.CATEGORY.RESET,
    }
}

//upsert category
export const adminUpsertCategoryLoad = (payload) =>{
    return {
        type: ADMIN.CATEGORY_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertCategorySuccess = (payload) =>{
    return {
        type: ADMIN.CATEGORY_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertCategoryFailed = (payload) =>{
    return {
        type: ADMIN.CATEGORY_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertPCategoryReset = () =>{
    return {
        type: ADMIN.CATEGORY_UPSERT.RESET
    }
}

//fetch reported item
export const adminReportedItemLoad = (payload) =>{
    return {
        type: ADMIN.REPORT.ITEM.LOAD,
        payload
    }
}    

export const adminReportedItemSuccess = (payload) =>{
    return {
        type: ADMIN.REPORT.ITEM.SUCCESS,
        payload,
    }
}

export const adminReportedItemFailed = (payload) =>{
    return {
        type: ADMIN.REPORT.ITEM.FAIL,
        payload,
    }
}

export const adminReportedItemReset = () =>{
    return {
        type: ADMIN.REPORT.ITEM.RESET
    }
}

//fetch administrator
export const administratorLoad = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR.LOAD,
        payload
    }
}    

export const administratorSuccess = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR.SUCCESS,
        payload,
    }
}

export const administratorFailed = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR.FAIL,
        payload,
    }
}

export const administratorReset = () =>{
    return {
        type: ADMIN.ADMINISTRATOR.RESET,
    }
}

//fetch administrator profile
export const administratorProfileLoad = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_PROFILE.LOAD,
        payload
    }
}    

export const administratorProfileSuccess = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_PROFILE.SUCCESS,
        payload,
    }
}

export const administratorProfileFailed = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_PROFILE.FAIL,
        payload,
    }
}

export const administratorProfileReset = () =>{
    return {
        type: ADMIN.ADMINISTRATOR_PROFILE.RESET,
    }
}

//upsert administrator
export const adminUpsertAdministratorLoad = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertAdministratorSuccess = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertAdministratorFailed = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertAdministratorReset = () =>{
    return {
        type: ADMIN.ADMINISTRATOR_UPSERT.RESET,
    }
}

//delete administrator
export const administratorDeleteLoad = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_DELETE.LOAD,
        payload
    }
}    

export const administratorDeleteSuccess = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_DELETE.SUCCESS,
        payload,
    }
}

export const administratorDeleteFailed = (payload) =>{
    return {
        type: ADMIN.ADMINISTRATOR_DELETE.FAIL,
        payload,
    }
}

export const administratorDeleteReset = () =>{
    return {
        type: ADMIN.ADMINISTRATOR_DELETE.RESET,
    }
}

// logout action
export const administratorLogoutLoad = () => {
    return {
        type: ADMIN.LOGOUT.LOAD,
    }
}

export const administratorLogoutSuccess = (payload) => {
    return {
        type: ADMIN.LOGOUT.SUCCESS,
        payload,
    }
}

export const administratorLogoutFail = (payload) => {
    return {
        type: ADMIN.LOGOUT.FAIL,
        payload,
    }
}

//fetch dashboard data
export const adminDashboardLoad = (payload) =>{
    return {
        type: ADMIN.DASHBOARD.LOAD,
        payload
    }
}    

export const adminDashboardSuccess = (payload) =>{
    return {
        type: ADMIN.DASHBOARD.SUCCESS,
        payload,
    }
}

export const adminDashboardFailed = (payload) =>{
    return {
        type: ADMIN.DASHBOARD.FAIL,
        payload,
    }
}

export const adminDashboardReset = () =>{
    return {
        type: ADMIN.DASHBOARD.RESET
    }
}

//delete item
export const adminDeleteItemLoad = (payload) =>{
    return {
        type: ADMIN.ITEM_DELETE.LOAD,
        payload
    }
}    

export const adminDeleteItemSuccess = (payload) =>{
    return {
        type: ADMIN.ITEM_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeleteItemFailed = (payload) =>{
    return {
        type: ADMIN.ITEM_DELETE.FAIL,
        payload,
    }
}

export const adminDeleteItemReset = () =>{
    return {
        type: ADMIN.ITEM_DELETE.RESET
    }
}

// login as user action
export const loginAsUserLoad = (payload) => {
    return {
        type: ADMIN.LOGIN_AS_USER.LOAD,
        payload,
    }
}

export const loginAsUserSuccess = (payload) => {
    return {
        type: ADMIN.LOGIN_AS_USER.SUCCESS,
        payload,
    }
}

export const loginAsUserFail = (payload) => {
    return {
        type: ADMIN.LOGIN_AS_USER.FAIL,
        payload,
    }
}

export const loginAsUserReset = () => {
    return {
        type: ADMIN.LOGIN_AS_USER.RESET
    }
}

//fetch custom field
export const adminCustomFieldLoad = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD.LOAD,
        payload
    }
}    

export const adminCustomFieldSuccess = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD.SUCCESS,
        payload,
    }
}

export const adminCustomFieldFailed = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD.FAIL,
        payload,
    }
}

export const adminCustomFieldReset = () =>{
    return {
        type: ADMIN.CUSTOM_FIELD.RESET,
    }
}


//upsert custom field
export const adminUpsertCustomFieldLoad = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertCustomFieldSuccess = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertCustomFieldFailed = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertCustomFieldReset = () =>{
    return {
        type: ADMIN.CUSTOM_FIELD_UPSERT.RESET
    }
}

//delete custom field
export const adminDeleteCustomFieldLoad = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_DELETE.LOAD,
        payload
    }
}    

export const adminDeleteCustomFieldSuccess = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeleteCustomFieldFailed = (payload) =>{
    return {
        type: ADMIN.CUSTOM_FIELD_DELETE.FAIL,
        payload,
    }
}

//fetch ban text
export const adminBantTextLoad = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT.LOAD,
        payload
    }
}    

export const adminBantTextSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT.SUCCESS,
        payload,
    }
}

export const adminBantTextFailed = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT.FAIL,
        payload,
    }
}

export const adminBantTextReset = () =>{
    return {
        type: ADMIN.BAN_TEXT.RESET,
    }
}

//upsert ban text
export const adminUpsertBantTextLoad = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertBantTextSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertBantTextFailed = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertBantTextReset = () =>{
    return {
        type: ADMIN.BAN_TEXT_UPSERT.RESET
    }
}

//delete ban text
export const adminDeleteBanTextLoad = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_DELETE.LOAD,
        payload
    }
}    

export const adminDeleteBanTextSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeleteBanTextFailed = (payload) =>{
    return {
        type: ADMIN.BAN_TEXT_DELETE.FAIL,
        payload,
    }
}

export const adminDeleteBanTextReset = () =>{
    return {
        type: ADMIN.BAN_TEXT_DELETE.RESET
    }
}

//fetch ban email
export const adminBanEmailLoad = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL.LOAD,
        payload
    }
}    

export const adminBanEmailSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL.SUCCESS,
        payload,
    }
}

export const adminBanEmailFailed = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL.FAIL,
        payload,
    }
}

export const adminBanEmailReset = () =>{
    return {
        type: ADMIN.BAN_EMAIL.RESET,
    }
}

//upsert ban email
export const adminUpsertBanEmailLoad = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertBanEmailSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertBanEmailFailed = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertBanEmailReset = () =>{
    return {
        type: ADMIN.BAN_EMAIL_UPSERT.RESET
    }
}

//delete ban email
export const adminDeleteBanEmailLoad = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_DELETE.LOAD,
        payload
    }
}    

export const adminDeleteBanEmailSuccess = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeleteBanEmailFailed = (payload) =>{
    return {
        type: ADMIN.BAN_EMAIL_DELETE.FAIL,
        payload,
    }
}

export const adminDeleteBanEmailReset = () =>{
    return {
        type: ADMIN.BAN_EMAIL_DELETE.RESET
    }
}

//change user email
export const adminChangeUserEmailLoad = (payload) =>{
    return {
        type: ADMIN.CHANGE_USER_EMAIL.LOAD,
        payload
    }
}   

export const adminChangeUserEmailSuccess = (payload) =>{
    return {
        type: ADMIN.CHANGE_USER_EMAIL.SUCCESS,
        payload,
    }
}

export const adminChangeUserEmailFailed = (payload) =>{
    return {
        type: ADMIN.CHANGE_USER_EMAIL.FAIL,
        payload,
    }
}

export const adminChangeUserEmailClearMessage = () =>{
    return {
        type: ADMIN.CHANGE_USER_EMAIL.CLEAR_MESSAGE,
    }
}

//fetch service
export const adminServiceLoad = (payload) =>{
    return {
        type: ADMIN.SERVICE.LOAD,
        payload
    }
}    

export const adminServiceSuccess = (payload) =>{
    return {
        type: ADMIN.SERVICE.SUCCESS,
        payload,
    }
}

export const adminServiceFailed = (payload) =>{
    return {
        type: ADMIN.SERVICE.FAIL,
        payload,
    }
}

export const adminServiceReset = () =>{
    return {
        type: ADMIN.SERVICE.RESET,
    }
}

//upsert service
export const adminUpsertServiceLoad = (payload) =>{
    return {
        type: ADMIN.SERVICE_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertServiceSuccess = (payload) =>{
    return {
        type: ADMIN.SERVICE_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertServiceFailed = (payload) =>{
    return {
        type: ADMIN.SERVICE_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertServiceReset = () =>{
    return {
        type: ADMIN.SERVICE_UPSERT.RESET
    }
}

//delete service
export const adminDeleteServiceLoad = (payload) =>{
    return {
        type: ADMIN.SERVICE_DELETE.LOAD,
        payload
    }
}    

export const adminDeleteServiceSuccess = (payload) =>{
    return {
        type: ADMIN.SERVICE_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeleteServiceFailed = (payload) =>{
    return {
        type: ADMIN.SERVICE_DELETE.FAIL,
        payload,
    }
}

export const adminDeleteServiceReset = () =>{
    return {
        type: ADMIN.SERVICE_DELETE.RESET
    }
}

//fetch promo
export const adminPromoLoad = (payload) =>{
    return {
        type: ADMIN.PROMO.LOAD,
        payload
    }
}    

export const adminPromoSuccess = (payload) =>{
    return {
        type: ADMIN.PROMO.SUCCESS,
        payload,
    }
}

export const adminPromoFailed = (payload) =>{
    return {
        type: ADMIN.PROMO.FAIL,
        payload,
    }
}

export const adminPromoReset = () =>{
    return {
        type: ADMIN.PROMO.RESET,
    }
}

//upsert promo
export const adminUpsertPromoLoad = (payload) =>{
    return {
        type: ADMIN.PROMO_UPSERT.LOAD,
        payload
    }
}    

export const adminUpsertPromoSuccess = (payload) =>{
    return {
        type: ADMIN.PROMO_UPSERT.SUCCESS,
        payload,
    }
}

export const adminUpsertPromoFailed = (payload) =>{
    return {
        type: ADMIN.PROMO_UPSERT.FAIL,
        payload,
    }
}

export const adminUpsertPromoReset = () =>{
    return {
        type: ADMIN.PROMO_UPSERT.RESET
    }
}

//delete promo
export const adminDeletePromoLoad = (payload) =>{
    return {
        type: ADMIN.PROMO_DELETE.LOAD,
        payload
    }
}    

export const adminDeletePromoSuccess = (payload) =>{
    return {
        type: ADMIN.PROMO_DELETE.SUCCESS,
        payload,
    }
}

export const adminDeletePromoFailed = (payload) =>{
    return {
        type: ADMIN.PROMO_DELETE.FAIL,
        payload,
    }
}

export const adminDeletePromoReset = () =>{
    return {
        type: ADMIN.PROMO_DELETE.RESET
    }
}



