import React, {useEffect, useState, useContext} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import RECAPTCHA from 'react-google-recaptcha'
import {CancelModal, YoutubeThumbnail, PostTypeFields, NoListingAvailableContainer, UsedAllSlots} from './customComponents'
import { meLoad, createItemLoad, createItemReset, servicesLoad, promoteLoad, promoteReset } from '../../../../redux/action'
import {WholePageLoader, WholePageError} from '../../../../common/component/customComponents'
import {ImageUploader, BoostContainer, ItemNewFields} from './customComponents2'
import 'react-dropzone-uploader/dist/styles.css'
import {ItemNewContext} from '../../../../common/context'
import { parsedError } from '../../../../common/helper/utility';
import WebItemSubmitted from './webSubmitted'
import { AddImageIcon, PremiumBoostIcon, MagnifyingGlassIcon, HighlightBoostIllustrateIcon, CoinsGrayIcon, ItemNewCoinsIcon, LocationIcon, CloseIconWithGrayBG, AddImageBtnGrayBg , MoneyIcon, InfoIcon, AddCircleIcon,CloseCircleIcon, CheckCircleIcon, TrashIconRedBG} from '../../../../assets/svg'

const {REACT_APP_GOOGLE_API_KEY, REACT_APP_ENV} = process.env

const ItemNewWeb = (props) => {
    const itemNewContext = useContext(ItemNewContext)
    const {isLoading, publishLoading, publishError, imageData, userInput, handleSubmit, setModalPops, handleAnotherListItem, postSubmitted, submitLoading} = itemNewContext

    return (
        <>
            <div className={`item-new-container ${postSubmitted ? `item-new-no-border` : ``}`}>
                {
                    isLoading || publishLoading || submitLoading ? <WholePageLoader /> : 
                    publishError ? <WholePageError text={parsedError(publishError)} /> :
                    postSubmitted ? <WebItemSubmitted data={userInput.submitItemDetails} iLink={userInput.itemLink} another={handleAnotherListItem} /> :
                    <div className="item-form-container">
                        <div className='item-main-container'>
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ fontFamily: 'Metropolis-Medium', fontStyle: 'normal', fontSize: '26px', lineHeight: '26px', letterSpacing: '0.44px', fontWeight: '500', textAlign: 'left', whiteSpace: 'nowrap', color: '#232A34' }}>{`What are you listing today?`}</p>
                            </div>
                            <div className={imageData.length === 0 ? `upload-only` : `form-container`}>
                                <div className='h-100 flex flex-d-column'>
                                    <ImageUploader />
                                    {/* {imageData.length === 0 ? null : <BoostContainer />} */}
                                </div>
                                <ItemNewFields />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = store => {
    return {
      me: store.userReducer,
      create: store.createItemReducer,
      purchase: store.purchaseReducer
    };
  };
  const mapActionToProps = {
    meLoad,
    createItemLoad,
    createItemReset,
    servicesLoad,
    promoteLoad,
    promoteReset
  }

export default withRouter(connect(mapStateToProps, mapActionToProps)(ItemNewWeb));