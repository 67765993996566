import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {SuccessIcon} from '../../../../assets/svg'
import {numberWithCommas} from '../../../../common/helper/utility'
import FKImage from '../../../../common/component/image'

const SuccessCounterCointainer = (data) => {
    // console.log("NoLiveListingReminderContainer", data)
    let a = []
    let b = []
    let free_not_used = []
    let purchase_not_used = []
    const fkPurchasedPaid = data && data.fkPurchase && data.fkPurchase.paid ? data.fkPurchase.paid : []
    const fkFreeSlots = data && data.fkPurchase && data.fkPurchase.free ? data.fkPurchase.free : []

    if(fkPurchasedPaid.length !== 0){
        fkPurchasedPaid.map((x) => {
            // console.log("x", x)
            return x.item_id === null ? purchase_not_used.push(x) : a.push(x)
        })
    }
    if(fkFreeSlots.length !== 0){
        fkFreeSlots.map((y) => {
            // console.log("y", y)
            return y.item_id === null ? free_not_used.push(y) : b.push(y)
        })
    }
    
    const isFreeFkPrimeClaimed = data && data.isFreeClaim ? data.isFreeClaim : false
    const unusedListingCount = data && data.unusedLiveListing ? data.unusedLiveListing : 0
    const live_status = data && data.live ? data.live : `not_live`
    const live_items_length = data && data.live_items ? data.live_items.length : 0
    const unusedTotal = unusedListingCount ? parseInt(unusedListingCount) - 1 : 0
    const live_items_total = live_items_length + 1
    const total_slot_listing = parseInt(unusedTotal) + parseInt(live_items_total)

    // console.log("data", data, a, b, free_not_used, purchase_not_used)
    return (
        <div className="flex flex-d-column mt-30">
            <div>
                <p className="item-create-you-have-label-p">{`You have:`}</p>
                {
                    live_status === 'live' ? 
                    <div> 
                        <p className="total-live-item-create-message-p">
                            <span>
                                {
                                    free_not_used.length === 0 ?
                                    `${fkFreeSlots.length}/${fkFreeSlots.length}` : `${parseInt(free_not_used.length) - 1}/${fkFreeSlots.length}`
                                }
                            </span>{` Free listing slots remaining`}</p>
                    </div> : null
                }
                {
                    fkPurchasedPaid.length !== 0 ?
                    <div> 
                        <p className="total-live-item-create-message-p">
                        <span>
                            {
                                free_not_used.length === 0 ? purchase_not_used.length === 0 ? `${a.length}/${fkPurchasedPaid.length}` :
                                `${parseInt(purchase_not_used.length) - 1}/${fkPurchasedPaid.length}` : `${fkPurchasedPaid.length}/${fkPurchasedPaid.length}`
                            }
                        </span>{` FK Prime listing slots remaining`}</p>
                    </div> : null
                }
                {
                    // isFreeFkPrimeClaimed ? 
                    // null :
                    // <div className="h-px-42 flex flex-d-column flex-justify-between">
                    //     <p className="five-free-listing-label-p"><span>{`5`}</span> &nbsp;{`Free listing slots`}</p>
                    //     <div className="flex flex-d-row">
                    //     <p className="five-free-listing-label-p"><Link to={`/fk-prime`}>
                    //         <p className="direct-to-fk-prime-claim-p">{`CLAIM`}</p></Link>&nbsp; {` it now.`}</p>
                    //     </div>
                    // </div>
                }
            </div>
            <div className="mt-20">
                {
                    // unusedListingCount === 0 ? 
                    // <div>
                    //     <p className="item-create-fk-prime-listing-ps"><span>{unusedListingCount}</span>&nbsp;{`FK Prime listing slot available`}</p>
                    //     <Link to={`/fk-prime`}>
                    //        <p className="direct-to-fk-prime-claim-p">
                    //            {
                    //                isFreeFkPrimeClaimed ? `BUY SLOT` : `FK PRIME`
                    //            }
                    //        </p>
                    //     </Link>
                    // </div> : null
                }
            </div>
            {
                // live_status === 'not_live' ? 
                // <p className="item-not-live-item-create-p">{`Please know that this listing is not live. Users will not be able to see this.`}</p> : null
            }
        </div>
    )
}

const WebItemSubmitted =    ({data, iLink, another}) => {
    const item_title = data && data.oc_t_item_description && data.oc_t_item_description.s_title ? data.oc_t_item_description.s_title : ''
    const price = data && data.i_price ? data.i_price : 0
    const item_resource = data && data.oc_t_item_resource && data.oc_t_item_resource[0] ? data.oc_t_item_resource[0] : []
    const unusedListingCount = data && data.unusedLiveListing ? data.unusedLiveListing : 0
    const liveListingLength = data && data.live_items ? parseInt(data.live_items.length) + 1 : 0
    const listingUnusedTotal = parseInt(unusedListingCount) - 1
    const hasSlot = listingUnusedTotal > 0 ? true : false

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="submitted-web-main-container">
            <div className="submitted-web-sub-container">
                <div className="submitted-container">
                    <div className="submitted-item-details-container">
                        <div className="submitted-item-img-container">
                            <FKImage images={[item_resource]} type="submit-thumbnail" />
                        </div>
                        <div className="submitted-item-price-container">
                            <p className="web-submitted-price">{`SGD ${numberWithCommas((price / 1000000).toFixed(2))}`}</p>
                        </div>
                        <div className="submitted-item-details-container">
                            <p className="web-submitted-title">{`${item_title}`}</p>
                        </div>
                    </div>
                </div>
                <div className="seperator-main-container">
                <div className="seperator-container">

</div>
                </div>
               
                <div className="submitted-succes-container">
                    <div className="submitted-success-listed">
                        <p className="submitted-success-title">{`Successfully listed`}</p>
                    </div>
                    <div className="submitted-success-icon-container">
                        <SuccessIcon />
                    </div>
                    <div className="submitted-group-actions">
                        <div className="viewListingBtn-container">
                            <Link to={iLink}>{`View listing`}</Link>
                        </div>
                        <div className="list-another-btn-container" onClick={another}>
                            <p>{`List another item`}</p>
                        </div>
                    </div>
                    {/* <div className="flex flex-d-column">
                       {
                           SuccessCounterCointainer(data)
                       }
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default WebItemSubmitted