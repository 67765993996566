import { ME, CHANGE_PASSWORD, SEND_FORGOT_PASSWORD, SESSION_USER, CLEAR_UNCOLLECTED_PROMO, REALTIME_USER_WALLET_UPDATE } from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        updateMessage: false,

        cPasswordIsLoading: false,
        cPasswordData: null,
        cPasswordError: false,

        sessionLoading: false,
        sessionData: [],
        sessionError: false,

        forgotPasswordIsLoading: false,
        forgotPasswordData: null,
        forgotPasswordError: false,

        stripeSessionLoading: false,
        stripeSessionData: [],
        stripeSessionError: false,


    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case ME.LOAD:
            return {...state, isLoading: true, error: null}

            case ME.UPDATE:
            return {...state, isLoading: true, error: null}

            case ME.SUCCESS:
            const userData = action.payload && action.payload.oc_t_user ?  action.payload.oc_t_user : action.payload
            const updateMessage = action.payload && action.payload.message || false
            return {...state, isLoading: false, data: userData, error: null, updateMessage: updateMessage}

            case ME.FAILED:
            return {...state, isLoading: false, error: action.payload}

            case ME.RESET_MESSAGE:
            return {...state, error: null, updateMessage: false}

            case SESSION_USER.LOAD:
            return {...state, sessionLoading: true, sessionError: null}

            case SESSION_USER.SUCCESS:
            return {...state, sessionLoading: false, sessionData: action.payload, sessionError: null}

            case SESSION_USER.FAIL:
            return {...state, sessionLoading: false, sessionError: action.payload}

            case CHANGE_PASSWORD.LOAD:
            return {...state, cPasswordIsLoading: true, cPasswordError: null}

            case CHANGE_PASSWORD.SUCCESS:
            return {...state, cPasswordIsLoading: false, cPasswordData:  action.payload, cPasswordError: null}

            case CHANGE_PASSWORD.FAIL:
            return {...state, cPasswordIsLoading: false, cPasswordError: action.payload}

            case CHANGE_PASSWORD.CLEAR_MESSAGE:
            return {...state, cPasswordIsLoading: false, cPasswordError: false, cPasswordData: null}

            case SEND_FORGOT_PASSWORD.LOAD:
            return {...state, forgotPasswordIsLoading: true, cPasswordError: null}

            case SEND_FORGOT_PASSWORD.SUCCESS:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordData:  action.payload, forgotPasswordError: null}

            case SEND_FORGOT_PASSWORD.FAIL:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordError: action.payload}

            case SEND_FORGOT_PASSWORD.CLEAR_MESSAGE:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordError: false, forgotPasswordData: null}

            // case STRIPE_SESSION.LOAD:
            //     console.log("STRIPE_SESSION.LOAD")
            // return {...state, stripeSessionLoading: true, stripeSessionError: null}

            // case STRIPE_SESSION.SUCCESS:
            //     console.log("STRIPE_SESSION.SUCCESS", action)
            // return {...state, stripeSessionLoading: false, stripeSessionData: action.payload, stripeSessionError: null}

            // case STRIPE_SESSION.FAIL:
            // return {...state, stripeSessionLoading: false, stripeSessionError: action.payload}

            case CLEAR_UNCOLLECTED_PROMO:
            return {...state, sessionData: {...state.sessionData, uncollected_promo: null}}

            case REALTIME_USER_WALLET_UPDATE:
            return {...state, sessionData: {...state.sessionData, wallet: action.payload.updatedUserWallet}, data: {...state.data, wallet: action.payload.updatedUserWallet}}

            default:
            return state;  
        }
    }
    
    