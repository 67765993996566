import { USER_DATA, NEW_USER, UPDATE_USER, EMAIL_VERIFY, LOGOUT, RESEND_EMAIL_VERIFICATION } from '../constant';

// login action
export const userLoad = (payload) => {
    return {
        type: USER_DATA.LOAD,
        payload,
    }
}

export const userSuccess = (payload) => {
    return {
        type: USER_DATA.SUCCESS,
        payload,
    }
}

export const userFail = (payload) => {
    return {
        type: USER_DATA.FAIL,
        payload,
    }
}

export const userReset = () => {
    return {
        type: USER_DATA.RESET
    }
}

// register action
export const newUserLoad = (payload) => {
    return {
        type: NEW_USER.LOAD,
        payload,
    }
}

export const newUserSuccess = (payload) => {
    return {
        type: NEW_USER.SUCCESS,
        payload,
    }
}

export const newUserFail = (payload) => {
    return {
        type: NEW_USER.FAIL,
        payload,
    }
}

// update action
export const updateUserLoad = (payload) => {
    
    return {
        type: UPDATE_USER.LOAD,
        payload,
    }
}

export const updateUserSuccess = (payload) => {
    return {
        type: UPDATE_USER.SUCCESS,
        payload,
    }
}

export const updateUserFail = (payload) => {
    return {
        type: UPDATE_USER.FAIL,
        payload,
    }
}

// email verify
export const emailVerifyLoad = (payload) => {
    return {
        type: EMAIL_VERIFY.LOAD,
        payload,
    }
}

export const emailVerifySuccess = (payload) => {
    return {
        type: EMAIL_VERIFY.SUCCESS,
        payload,
    }
}

export const emailVerifyFail = (payload) => {
    return {
        type: EMAIL_VERIFY.FAIL,
        payload,
    }
}

export const emailVerifyClearMessage = () => {
    return {
        type: EMAIL_VERIFY.CLEAR_MESSAGE,
    }
}

//resend email verify
export const resendEmailVerificationLoad = (payload) => {
    return {
        type: RESEND_EMAIL_VERIFICATION.LOAD,
        payload,
    }
}

export const resendEmailVerificationSuccess = (payload) => {
    return {
        type: RESEND_EMAIL_VERIFICATION.SUCCESS,
        payload,
    }
}

export const resendEmailVerificationFail = (payload) => {
    return {
        type: RESEND_EMAIL_VERIFICATION.FAIL,
        payload,
    }
}

export const resendEmailVerificationReset = () => {
    return {
        type: RESEND_EMAIL_VERIFICATION.RESET,
    }
}

// logout action
export const logoutLoad = () => {
    return {
        type: LOGOUT.LOAD,
    }
}

export const logoutSuccess = (payload) => {
    return {
        type: LOGOUT.SUCCESS,
        payload,
    }
}

export const logoutFail = (payload) => {
    return {
        type: LOGOUT.FAIL,
        payload,
    }
}

export const logoutReset = () => {
    return {
        type: LOGOUT.RESET
    }
}