import React, {useEffect, useState, useReducer, useCallback} from 'react'
import { connect } from 'react-redux';
import {useLocation } from 'react-router-dom'
import {servicesLoad, purchaseCoinLoad, purchaseCoinReset, updateSubscriptionLoad, updateSubscriptionReset, meLoad, sessionLoad} from '../../../redux/action'
import CoverPhotoSVG from '../../../assets/images/fkprimecoverweb.png'
import {CartIconWhite, BackIcon} from '../../../assets/svg'
import {theme} from '../../../common/theme'
import FKPrime from './fkPrime'
import IndividualAds from './individualAds'
import LifetimePlans from './lifetimePlans'
import {BoostCards, FKPrimeText, PartPillGroupBtn, ButtonWithIcon, ManyNotes, MobileHeader, Modal, MobileModalBuyCoins} from './customComponent'
import {FkPrimeContext} from '../../../common/context'
import { WholePageSuccessMessage, WholePageLoader} from '../../../common/component/customComponents'

const CoverContainer = () => {
    return (
        <img src={CoverPhotoSVG} alt="React Logo" className="fk-prime-cover-img"/>
    )
}

const MobileCoverContainer = () => {
    const backAction = () => {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
    }
    return (
        <>
        <MobileHeader 
            styleClass="w-100 fk-prime-mobile-header-container"
            leftStyleClass="flex flex-d-row flex-align-center"
            leftFunc={backAction}
            leftTextClass="fk-prime-left-header-text-p"
            leftIcon={<BackIcon />}
            leftText="Back"
            centerStyleClass="fk-prime-center-header-container"
            centerTextClass="fk-prime-center-header-text-p"
            centerText="Subscriptions"
            rightStyleClass="fk-prime-right-header-container"
        />
        <img src={CoverPhotoSVG} alt="React Logo" className="fk-prime-cover-img"/>
        </>
    )
}

const CartIcon = () => {
    return <CartIconWhite />
}

const TopText = ({text, styleClass}) => {
    return (
        <FKPrimeText text={text} styleClass={styleClass} />
    )
}

const GradientButton = (buyCoins) => {
    return (
        <ButtonWithIcon LeftIcon={CartIcon} text="Buy Coins" styleClass="flex flex-d-row flex-align-center gradient-button-main-btn" textClass="gradient-button-p" onClickAction={buyCoins.action}/>
    )
}

// const ManyNotesComponent = ({notesList}) => {
//     return (
//         <ManyNotes notes={notesList} textClass="notes-list-p" />
//     )
// }

const PillGroupBtn = ({left, center, right, buyCoins}) => {
    const leftText = left && left.text 
    const leftTextClass = left && left.textClass
    const leftStyleClass = left && left.styleClass
    const leftClickAction = left && left.leftPillAction

    const centerText = center && center.text
    const centerTextClass = center && center.textClass
    const centerStyleClass = center && center.styleClass
    const centerClickAction = center && center.centerPillAction

    const rightText = right && right.text
    const rightTextClass = right && right.textClass
    const rightStyleClass = right && right.styleClass
    const rightClickAction = right && right.rightPillAction

    return (
        <div className="flex flex-d-row flex-justify-center flex-align-center ">
            <div className="flex flex-d-row pill-group-main-group p-relative">
                <PartPillGroupBtn text={leftText} styleClass={leftStyleClass} textClass={leftTextClass} onClickAction={leftClickAction}/>
                {/* <div className="flex flex-justify-center flex-align-center bg-black-op5"><div className="divider-pill"/></div>
                <PartPillGroupBtn text={centerText} styleClass={centerStyleClass} textClass={centerTextClass} onClickAction={centerClickAction}/>
                <div className="flex flex-justify-center flex-align-center bg-black-op5"><div className="divider-pill"/></div>
                <PartPillGroupBtn text={rightText} styleClass={rightStyleClass} textClass={rightTextClass} onClickAction={rightClickAction}/> */}
                {
                    window.innerWidth < 600 ? null :
                    <div className="p-absolute left-100percent ml-30">
                        {GradientButton(buyCoins)}
                    </div>
                } 
            </div>
        </div>
    )
}

const FKPrimeComponent = (props) => {

    const [pillSelect, setPillSelect] = useState(1)
    const [boostOffer, setBoostOffer] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
           fkPrimeBoost: [],
           otpBoost: [],
           individualAds: {
               baseAds: {
                   premium: [],
                   highlight: []
               },
               comboAds: [],
               autoBump: []
           },
           buyCoins: []
        }
    )
    const {fkPrimeDummyEntries, oneTimePayDummyEntries} = theme.text

    const { servicesData, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin } = props.purchase
    const { data, sessionLoading, sessionData} = props.me
    const {has_active_subscription, is_free_subscription_claimed} = data
    const session = props.session
    const location = useLocation()
    const path = location.pathname
    const urlParams = new URLSearchParams(window.location.search);
    const stripeSessionId = urlParams.get('session_id');
    const userId = session.fkv2_session.id ? session.fkv2_session.id : null
    const [buyCoinsModal, setBuyCoinsModal] = useState(false)
    const [stripeError, setStripeError] = useState(null)
    const [buyCoinsStep, setBuyCoinsStep] = useState(1)
    const [initialLoading, setInitialLoading] = useState(false)
    const isBuyingSlot = props.history && props.history.location && props.history.location.isBuySlot ? props.history.location.isBuySlot : false
    const sub_stat = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null

    useEffect(() => {
        if(isBuyingSlot){
            setPillSelect(2)
        }
    },[isBuyingSlot])

    useEffect(() => {
        if(props.me.data.length === 0){
            props.meLoad({user: userId})
        }
        if(sessionData.length === 0){
            props.sessionLoad({id: userId})
        }
    }, [props.me.data.length, sessionData])
    
    const purchaseCoin = (data, input) => {
        props.purchaseCoinLoad({ fkv2_purchased_data: { ...data, ...input }, userId: userId })
    }

    const coinReset = () => {
        props.purchaseCoinReset()
    }

    const resetState = () => {
        coinReset()
        setPurchaseModal(false)
        setPurchaseLifeTimeModal(false)
        setStripeError(null)
        // window.history.pushState({}, document.title, "/" + "fk-prime" );
        window.location.replace(`${process.env.REACT_APP_BASE_URL}fk-prime`);
    }

    useEffect(() => {
        if (stripeSessionId && stripeSessionId === 'CANCELLED') {
            setPurchaseModal(true)
            setPurchaseSelected('stripe')
            setStripeError('Transaction cancelled')
            setTimeout(() => {
                resetState()
            }, 3000)
        }
        else if (stripeSessionId ) {
            setPurchaseModal(true)
            setPurchaseSelected('stripe')
            let data = {payment_method: 'stripe', type: 'fk_prime'}
            purchaseCoin(data, {order_id: stripeSessionId})
        }
    // eslint-disable-next-line
    }, [stripeSessionId])

    useEffect(() => {
        if (servicesData.length === 0) {
            props.servicesLoad(true)
        }
        else{
            let boost = []
            let otpBoost = []
            let premium = []
            let combo_ads = []
            let auto_bump = []
            let highlight = []
            let buyCoins_Offer = []
            const subscriptionPrice = has_active_subscription && has_active_subscription.service ? has_active_subscription.service : null
            servicesData.map((b) => {
                const isAvailable = !has_active_subscription ? false : !subscriptionPrice ? false : b.sg_price > subscriptionPrice ? true : false

                return b.type === 'fk_prime' ? boost.push({...b, is_available: isAvailable}) : b.type === 'otp' ? otpBoost.push(b) : b.type === 'auto_bump' ? auto_bump.push(b) : b.type === 'combo' ? combo_ads.push(b) : b.type === 'top_ad' ? premium.push(b) : b.type === 'highlight' ? highlight.push(b) : b.type === 'coin_bundle' ? buyCoins_Offer.push(b) : null
            })
            return setBoostOffer({['fkPrimeBoost']: boost, ['otpBoost']: otpBoost, ['buyCoins']: buyCoins_Offer, individualAds: {['comboAds']: combo_ads, ['autoBump']: auto_bump, baseAds: {['premium']: premium, ['highlight']: highlight}}})
        }
    // eslint-disable-next-line
    }, [path, servicesData, has_active_subscription])

    useEffect(() => {
        if (purchaseSuccessMessage || errorPurchaseCoin) {
        // if (purchaseSuccessMessage){
            setPurchaseModal(true)
            setPurchaseLifeTimeModal(true)
            setTimeout(() => {
                setInitialLoading(true)
                resetState()
            }, 3000)
        }
    }, [purchaseSuccessMessage, errorPurchaseCoin])

    useEffect(() => {
        if(initialLoading){
            setTimeout(() => {
                setInitialLoading(false)
            }, 3000)
        }
    },[initialLoading])

    const handleBuyCoinsModal = () =>{
        setBuyCoinsModal(!buyCoinsModal)
    }

    const IntroText = () => {
        const handleLeftPill = () => {
            setPillSelect(1)
        }
    
        // const left = {
        //     text: 'FK PRIME',
        //     textClass: 'pill-group-left-p',
        //     styleClass: pillSelect === 1 ? 'active-pill pill-group-left-btn' : 'pill-group-left-btn',
        //     leftPillAction: handleLeftPill
        // }

        const left = {
            text: 'LIFETIME PLANS',
            textClass: 'pill-group-center-p',
            styleClass: pillSelect === 1 ? 'active-pill pill-group-left-btn' : 'pill-group-left-btn',
            leftPillAction: handleLeftPill
        }

        const handleCenterPill = () => {
            setPillSelect(2)
        }

        // const center = {
        //     text: 'LIFETIME PLANS',
        //     textClass: 'pill-group-center-p',
        //     styleClass: pillSelect === 2 ? 'active-pill pill-group-center-btn' : 'pill-group-center-btn',
        //     centerPillAction: handleCenterPill
        // }
    
        const handleRightPill = () => {
            setPillSelect(3)
        }
    
        const right = {
            text: 'INDIVIDUAL ADS',
            textClass: 'pill-group-right-p',
            styleClass: pillSelect === 3 ? 'active-pill pill-group-right-btn' : 'pill-group-right-btn',
            rightPillAction: handleRightPill
        }

        const buyCoins = {
           action: handleBuyCoinsModal
        }
        return (

            <div className={`fk-prime-intro-main-container ${window.innerWidth < 600 ? `mb-30` : `mb-60` }`}>
                <div className="fk-prime-intro-subtitle-p-container">
                    {/* {TopText({text: 'Get 1-Month FREE trial of FK Prime on the first month of your first subscription.'})} */}
                    {TopText({text: ''})}
                </div>
                {/* <div className={`${window.innerWidth < 600 ? `` : `mb-30`}`}>
                    {TopText({text: 'You will be charged after free trial end.', styleClass: 'fk-prime-sub-sub-subtitle-p'})}
                </div> */}
                { PillGroupBtn({left, right, buyCoins})}
            </div>
        )
    }

    const notesList = [
        {text: "- Subscription plan ads credit will be replenish every month from the date of purchased."},
        {text: "- Ads will auto expire once the item is marked as sold and credits will not be refunded. Best of luck on your sale!"}
    ]

    //migrated state
    const defaultUserBoost = {
        boostID: '',
        boostOtpId: [],
        boostOtpDetails: [],
        boostOtpTotal: 0,
        otpMultiQuantity: 1,
        boostDetails: [],
        multiSelectionID: '',
        boostTotal: [],
        combo_adsTotal: 0,
        base_adsTotal: 0,
        auto_bumpTotal: 0,
        individualAdsTotal: 0,
        individualAdsArr: [],
        individualAdsIds: [],
        mobileIsPeek: [],
        buyCoins: {
            coinsArr: [],
            userId: session.fkv2_session.id,
            payment_method: '',
            total: 0,
            total_coin: 0,
            type: ''
        }
    }
    const [userBoost, setUserBoost] = useReducer(
        (state, newState) => ({...state, ...newState}), defaultUserBoost
    )

    const handleBuyCoins = (arr) => {
        let buyCoinsArr = userBoost.buyCoins.coinsArr
        let buy_total = userBoost.buyCoins.total
        let total_coin = userBoost.buyCoins.total_coin

        const found = userBoost.buyCoins.coinsArr.some(el => el.id === arr.id)
        const newArr = userBoost.buyCoins.coinsArr.filter(item => item.id !== arr.id)
        if(found){
            buyCoinsArr = newArr
            buy_total = parseInt(userBoost.buyCoins.total) - parseInt(arr.sg_price)
            total_coin = parseInt(userBoost.buyCoins.total_coin) - parseInt(arr.credit)
        }else{
            buyCoinsArr.push(arr)
            buy_total = parseInt(userBoost.buyCoins.total) + parseInt(arr.sg_price)
            total_coin = parseInt(userBoost.buyCoins.total_coin) + parseInt(arr.credit)
        }
        setUserBoost({buyCoins: {['coinsArr']: buyCoinsArr, ['userId']: userBoost.buyCoins.userId, ['total']: buy_total, ['type']: arr.type, ['total_coin']: total_coin, ['payment_method']: userBoost.buyCoins.payment_method}})
    }

    const handleBuyCoinsStepsAction = (action) => {
        if(action === '+'){
            setBuyCoinsStep(buyCoinsStep + 1)
        }
        if(action === '-'){
            setBuyCoinsStep(buyCoinsStep - 1)
        }
    }

    const handleBuyCoinsPay = (method) => {
        setUserBoost({buyCoins: {...userBoost.buyCoins, ['payment_method']: method}})
    }

    const [purchaseModal, setPurchaseModal] = useState(false)

    // const [isFreeClaimed, setIsFreeClaimed] = useState(false)
   
    const [freeClaim, setFreeClaim] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
           claimFreeClicked: false,
           isFreeClaimed: false
        }
    )
    const [purchaseSelected, setPurchaseSelected] = useState('')

    const handlePurchaseModal = () => {
        if(has_active_subscription && sub_stat && !sub_stat.toLowerCase().includes('cancel')){
            alert('You already have an active subscription')
        }
        else {
            if(userBoost.boostID || userBoost.boostOtpId){
                setPurchaseModal(!purchaseModal)
            }
        }
    }

    const peekFkPrimeBoost = (id) => {
        let ids = userBoost.mobileIsPeek
        if(userBoost.mobileIsPeek.length !== 0){
            const found = userBoost.mobileIsPeek.some(el => el.id === id);
            const new_ids = userBoost.mobileIsPeek.filter(item => item.id !== id)
            if(found){
                ids = new_ids
            }else{
                ids.push({id})
            }
        }else{
            ids.push({id})
        }
        return setUserBoost({['mobileIsPeek']: ids})
    }

    const handlePurchase = useCallback(e => {
        setPurchaseSelected(e.target.value)
    },[path])

    const handleIncrementDecrement = (action, id, price, data) => {
        let arr = userBoost.boostOtpDetails
        let multiQuantity = userBoost.otpMultiQuantity
        let total = userBoost.boostOtpTotal
        let deact = false
        let idsArr = userBoost.boostOtpId
        let priceA = 0
        let otp_ids = userBoost.boostOtpId
        const newIndex = arr.filter(item => item.id !== id)
        const newIds = idsArr.filter(item => item.id !== id)
        const found = userBoost.boostOtpId.some(el => el.id === id); 

        if(action === 'add'){
            const objIndex = arr.findIndex((obj => obj.id === id))
            const quantity = arr[objIndex] && arr[objIndex].quantity ? arr[objIndex].quantity : null
            if(quantity){
                arr[objIndex].quantity =  parseInt(multiQuantity) + 1 
            }else{
                arr[objIndex] = {...arr[objIndex], quantity: 1}
            }
            if(!found){
                otp_ids.push(data)
            }
            multiQuantity = parseInt(userBoost.otpMultiQuantity) + 1
            // total = userBoost.boostOtpTotal === 0 ? parseFloat(price * multiQuantity) : parseFloat(userBoost.boostOtpTotal) + parseFloat(price)
            total = found ? userBoost.boostOtpTotal === 0 ? parseFloat(price * multiQuantity) : parseFloat(userBoost.boostOtpTotal) + parseFloat(price) : parseFloat(price * multiQuantity) + parseFloat(total)
            setUserBoost({
                ['boostOtpId']: otp_ids,
                ['boostOtpDetails']: arr, 
                ['otpMultiQuantity']: multiQuantity, 
                ['boostOtpTotal']: total
            })
        }
        if(action === 'subtract' && multiQuantity !== 1){
            const objIndex = arr.findIndex((obj => obj.id === id))
            const quantity = arr[objIndex] && arr[objIndex].quantity ? arr[objIndex].quantity : null
            const objPrice = userBoost.boostOtpId.filter(obj => obj.id === id)
            const objPriceFromDetails = userBoost.boostOtpDetails.filter(obj => obj.id === id)

            if(found){
                if(quantity){
                    arr[objIndex].quantity =  parseInt(multiQuantity) - 1 
                }else{
                    arr[objIndex] = {...arr[objIndex], quantity: 1}
                }
                priceA = objPrice && objPrice[0] && objPrice[0].price ? objPrice[0].price : objPriceFromDetails && objPriceFromDetails[0] && objPriceFromDetails[0].price ? objPriceFromDetails[0].price : 0
                multiQuantity = userBoost.otpMultiQuantity <= 1 ? 0 : parseInt(multiQuantity) - 1
                total = parseFloat(userBoost.boostOtpTotal) - parseFloat(priceA)
                deact = userBoost.otpMultiQuantity <= 1 ? true : false
                setUserBoost({
                    ['boostOtpDetails']: deact ? newIndex : arr, 
                    ['otpMultiQuantity']: multiQuantity, 
                    ['boostOtpTotal']: total, ['boostOtpId']: deact ? newIds : userBoost.boostOtpId})
            }
        }
    }
 
    const handleBoostSelection = (type, id, data, action) => {
        const found = userBoost.boostOtpId.some(el => el.id === id);
        const name = data && data.name ? data.name.includes('Listing') : false
        const act = action ? action : null
        let price = 0
        let otp_ids = userBoost.boostOtpId
        let otp_details = userBoost.boostOtpDetails
        const quantity = data.name.includes('Listing') ? userBoost.otpMultiQuantity : 1

        if(type === 'otp'){
            if(found){
                const new_ids = userBoost.boostOtpId.filter(item => item.id !== id)
                const new_detailsArr = userBoost.boostOtpDetails.filter(item => item.id !== id)
                if(!name){
                    setUserBoost({['boostOtpId']: new_ids, ['boostOtpDetails']: new_detailsArr, ['boostOtpTotal']: otp_ids.length === 1 ? 0 : parseFloat(userBoost.boostOtpTotal) - parseFloat(data.price)})
                }
                if(name) {
                    const new_ids = userBoost.boostOtpId.filter(item => item.id !== id)
                    const new_detailsArr = userBoost.boostOtpDetails.filter(item => item.id !== id)
                    setUserBoost({
                        ['otpMultiQuantity']: 1,
                        ['boostOtpId']: new_ids, 
                        ['boostOtpDetails']: new_detailsArr, 
                        ['boostOtpTotal']: otp_ids.length === 1 ? 0 : parseFloat(userBoost.boostOtpTotal) - parseFloat(data.price)
                    })
                }
            }else{
                otp_ids.push({id})
                price = data && data.price
                otp_details.push({...data, quantity: quantity})
                if(act === 'add'){
                setUserBoost({['boostOtpTotal']: parseFloat(userBoost.boostOtpTotal) + parseFloat(price), ['boostOtpDetails']: otp_details})
                }
            }
        }        
    }

    const handleClaimFree = () => {
        if(freeClaim.isFreeClaimed === false){
            setFreeClaim({['claimFreeClicked']: true})
        }
    }

    const handleIndividualAds = (data, price, type) => {
        let boost_id = userBoost.individualAdsIds
        let total = userBoost.individualAdsTotal
        let dataArr = userBoost.individualAdsArr
        let autoBumpTotal = userBoost.auto_bumpTotal
        let comboAdsTotal = userBoost.combo_adsTotal
        let baseAdsTotal = userBoost.base_adsTotal
        const id = data.id
        // const index = userBoost.individualAdsIds(data.id)
        const found = userBoost.individualAdsIds.some(el => el.id === id);
        if(found){
            const new_detailsArr = userBoost.individualAdsIds.filter(item => item.id !== id)
            boost_id = new_detailsArr
        }else{
            boost_id.push({id})
            total = parseInt(userBoost.individualAdsTotal) + parseInt(price)
            dataArr.push(data)
        }
        return setUserBoost({
            ['individualAdsIds']: boost_id, 
            ['individualAdsTotal']: found ? parseInt(total) - parseInt(price) : total, 
            ['individualAdsArr']: dataArr,
            ['auto_bumpTotal']: type === 'auto_bump' && !found ? parseInt(autoBumpTotal) + parseInt(price) : found && type === 'auto_bump' ? parseInt(autoBumpTotal) - parseInt(price) : autoBumpTotal,
            ['combo_adsTotal']: type === 'combo' && !found ? parseInt(comboAdsTotal) + parseInt(price) : found && type === 'combo' ? parseInt(comboAdsTotal) - parseInt(price) : comboAdsTotal,
            ['base_adsTotal']: type === 'top_ad' && !found || type === 'highlight' && !found ? parseInt(baseAdsTotal) + parseInt(price) : found && type === 'top_ad' || type === 'highlight' && found ? parseInt(baseAdsTotal) - parseInt(price) : baseAdsTotal
        })
    }

    //lifeTime
    const [purchaseLifeTimeModal, setPurchaseLifeTimeModal] = useState(false)

    const handleSetPurchaseLifeTimeModal = (value)=> {
        if(value) {
            setPurchaseLifeTimeModal(true)
        }
        else {
            setPurchaseLifeTimeModal(false)
            setBoostOffer(defaultUserBoost)
        }
    }

    //individual ads
    const [showIndividualAdsModal, setShowIndividualAdsModal] = useState(false)

    const handleShowIndividualAdsModal = (value)=> {
        if(value) {
            setShowIndividualAdsModal(true)
        }
        else {
            setShowIndividualAdsModal(false)
            setBoostOffer(defaultUserBoost)
        }
    }

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [boostOffer])

    const s_email = sessionData && sessionData.s_email ? sessionData.s_email : null

    const handleClaim = () => {
        if(is_free_subscription_claimed){
            alert('Free subscription already claimed.')
        }
        else {
            const boostDetails = userBoost.boostDetails
            const service_name = `Fk Prime ${boostDetails.name}${boostDetails.subname}`.trim()
    
            const boostDetailsObj = {
                "type": boostDetails.type,
                "user_id": parseInt(userId),
                "coin": boostDetails.credit ? parseInt(boostDetails.credit) : 0,
                "id": boostDetails.id ? parseInt(boostDetails.id) : 0,
                "sg_price": 0,
                "description": boostDetails.description,
                "method": "free",
                "currency": "sgd",
                "s_email": s_email,
                "name": service_name,
                "customer": "",
            }
            const line_items_data = parsedLineItems([boostDetailsObj], 'free')
            const boostData = {payment_method: 'free', type: 'fk_prime', total_amount: 0, line_items_detail: line_items_data }
            purchaseCoin(boostData, { order_id: null })
        }
    }

    const [showSubscriptionUpgrade, setShowSubscriptionUpgrade] = useState(false)
    const [subscriptionUpgradeData, setSubscriptionUpgradeData] = useState(null)
    const toggleSubscriptionData = (data, show) => {
        setShowSubscriptionUpgrade(show)
        setSubscriptionUpgradeData(data)
    }

    const handleUpdateSubscription = (data) => {
        props.updateSubscriptionLoad(data)
    }

    const {isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError} = props.microService

    useEffect(() => {
        if(updateSubscriptionMessage || updateSubscriptionError){
          setTimeout(() =>{
            window.location.replace(`${process.env.REACT_APP_BASE_URL}fk-prime`);
          }, 2000)
        }
    }, [updateSubscriptionMessage, updateSubscriptionError])

    const handleUpgradeError = () => {
        props.updateSubscriptionFailed('Error upgrading, try again.')
    }

    const tabSwitcher = (i) => {
        if(i){
            switch(i){
                case 1:
                return <LifetimePlans />;

                case 2:
                return <LifetimePlans />;

                case 3: 
                return <IndividualAds />;

                default:
                return <LifetimePlans />
            }
        }
    }

    return (
        <FkPrimeContext.Provider value={{
            boostOffer, sessionData, session, userBoost, setUserBoost, purchaseModal, setPurchaseModal, handlePurchaseModal, handlePurchase, purchaseSelected, setPurchaseSelected,
            isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, stripeError, purchaseCoin,  resetState, handleBoostSelection,
            handleIndividualAds, peekFkPrimeBoost, purchaseLifeTimeModal, handleSetPurchaseLifeTimeModal, handleIncrementDecrement, pillSelect, showIndividualAdsModal, handleShowIndividualAdsModal, handleClaim,
            freeClaim, handleClaimFree, has_active_subscription, is_free_subscription_claimed, handleBuyCoinsPay, handleBuyCoinsModal, buyCoinsModal,
            showSubscriptionUpgrade, toggleSubscriptionData, subscriptionUpgradeData, handleUpdateSubscription,
            isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError, handleUpgradeError
        }}>

        {
            window.innerWidth < 600 ? 
                <div className="w-100 fk-prime-mobile-main-body-container p-relative">
                    {MobileCoverContainer()}
                    <div className="fk-subs-main-container p-absolute">
                    {IntroText()}
                    {/* {pillSelect === 1 ? <FKPrime/> : pillSelect === 2 ? <LifetimePlans /> : <IndividualAds />} */}
                    {tabSwitcher(pillSelect)}
                </div>
                </div> : 
                <div className="w-100 flex flex-justify-center p-relative top-p-131 h-100 h-mn-150">
                {CoverContainer()}
                <div className="fk-subs-main-container p-absolute">
                    {IntroText()}
                    {/* {pillSelect === 1 ? <FKPrime/> : pillSelect === 2 ? <LifetimePlans /> : <IndividualAds />} */}
                    {tabSwitcher(pillSelect)}
                </div>
                </div>
        }
        {
            buyCoinsModal ? window.innerWidth < 600 ? <MobileModalBuyCoins offer={boostOffer.buyCoins} handleBuyCoins={handleBuyCoins} offerSelected={userBoost.buyCoins} handleBuyCoinsStepsAction={handleBuyCoinsStepsAction} buyCoinsStep={buyCoinsStep} wallet={sessionData.wallet} total={userBoost.buyCoins.total} setBuyCoinsModal={setBuyCoinsModal} handleBuyCoinsPay={handleBuyCoinsPay}/> :
            <div className="fk-prime-buy-coins-modal-main-container">
                <Modal offer={boostOffer.buyCoins} handleBuyCoins={handleBuyCoins} offerSelected={userBoost.buyCoins} handleBuyCoinsStepsAction={handleBuyCoinsStepsAction} buyCoinsStep={buyCoinsStep} wallet={sessionData.wallet} total={userBoost.buyCoins.total} setBuyCoinsModal={setBuyCoinsModal} handleBuyCoinsPay={handleBuyCoinsPay}/>
            </div> : null
        }
        {
            isLoadingPurchaseCoin || sessionLoading || initialLoading ? 
            <WholePageLoader /> : null
        }
        {
            purchaseSuccessMessage ?
            <WholePageSuccessMessage text={purchaseSuccessMessage} /> :
            null
        }
        </FkPrimeContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
      purchase: store.purchaseReducer,
      me: store.userReducer,
      microService: store.microServiceReducer
    };
  };
  const mapActionToProps = {
    servicesLoad,
    meLoad,
    sessionLoad,
    purchaseCoinLoad,
    purchaseCoinReset,
    updateSubscriptionLoad,
    updateSubscriptionReset
  }

export default connect(mapStateToProps, mapActionToProps)(FKPrimeComponent)