import React from 'react'
import {AddImageBtnGrayBg} from '../../../../../../../../assets/svg'

export const SubmitBtn = () => {
    return (
    <div key={'dp'} className="attached-upload-container">
      <AddImageBtnGrayBg />
    </div>
    )
}

export default {SubmitBtn}