import { ITEM_CREATE } from '../constant'

export const createItemLoad = (payload) =>{

    return {
        type: ITEM_CREATE.LOAD,
        payload
    }
}    

export const createItemsuccess = (payload) =>{
    return {
        type: ITEM_CREATE.SUCCESS,
        payload,
    }
}

export const createItemFailed = (payload) =>{
    return {
        type: ITEM_CREATE.FAIL,
        payload,
    }
}

export const createItemReset = () =>{
    return {
        type: ITEM_CREATE.RESET,
    }
}




