import React, { useContext } from 'react';
import { theme } from '../../../../../common/theme'
import { ItemViewContext } from '../../../../../common/context';

export const ReportingSelect = () => {
    const value = useContext(ItemViewContext);
    const { setReportInput } = value
    const data = theme.text.reportListingOptions

    const handleOnchange = (event) => {
        const report_type = event.target.value === 'Suspicious Account' ? 'User' : event.target.value === 'Selling counterfiet items' ? 'User' : 'Item'
        setReportInput({
            report_reason: event.target.value,
            report_type: report_type
        })
    } 

    return (
      <div style={{background: 'rgba(236, 240, 241,1.0)', border: '1px solid rgba(127, 140, 141,0.7)', borderRadius: '5px', padding: '5px 0px'}}>
        <select name={'reportOptions'} style={style.input.formOption} onChange={(event) => handleOnchange(event)} required>
        <option selected disabled>Please select an option</option>
        {
          data.map((item, index) => {
            let arr = []
                arr.push(
                  <option key={index} value={item.label}>{`${item.label }`}</option>
                )
            return arr
          })
        }                    
    </select>
    </div>
    )
}

const style = {
    label: {
      padding: '4px 50px 0px 0px',
      display: 'inline-block',
      marginBottom: '3px',
      fontWeight: '700',
      color: '#333'
    },
    div: {
      headTitle: {
        textAlign: 'center',
      },
      formGroup: {
        marginBottom: '1rem',
      }
    },
    input: {
      formInput: {
        padding: '10px',
        border: '1px solid rgba(230, 126, 34,0.7)',
        borderRadius: '5px',
        width: '100%',
      },
      formOption: {
        padding: '5px 10px',
        border: 'none',
        background: 'rgba(236, 240, 241,1.0)',
        width: '98%',
        fontSize: 17
      },
    }
}