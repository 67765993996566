import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { categoryLoad } from '../../../../redux/action'

const MobileCategoryBar = (props) => {
    const data = props.category.data
    useEffect(() => {
        props.categoryLoad()
        // eslint-disable-next-line    
    }, [])

    return (
        <div className="mobile-category-container">
            {
                data.map((category, index) => {
                    const categoryName = category && category.oc_t_category_descriptions && category.oc_t_category_descriptions.s_name ? category.oc_t_category_descriptions.s_name : ''
                    const slug = category.oc_t_category_descriptions && category.oc_t_category_descriptions.s_name ? category.oc_t_category_descriptions.s_name.toLowerCase() : false
                    const categoryPath = `/${slug}`
                    return <div key={index} className="p-2 z-ndx-4">
                        <div className="p-0-10 br-5 border-gray-op-7">
                            <Link to={categoryPath} ><p className="category-mobile-label-p">{categoryName}</p></Link>
                        </div>
                    </div>

                })
            }
        </div>
    )
}

const mapStateToProps = store => {
    return {
        category: store.categoryReducer,
    };
};

const mapActionToProps = {
    categoryLoad
}

export default connect(mapStateToProps, mapActionToProps)(MobileCategoryBar)