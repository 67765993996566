import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment'
import { ChatContext } from '../../../../../../common/context'
import { groupDateParser } from '../../../../../../common/helper/utility'
import { RenderUserChat, RenderLoginUserChat } from './CustomComponent'

const { REACT_APP_WEB_SEC } = process.env;

const WebChatHistory = () => {
    const value = useContext(ChatContext)
    const { session, chatRoomData, height, tempIsExist, toggleShowReviewModal } = value
    const userId = session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id : null
    const data = chatRoomData && chatRoomData.chat_messages ? chatRoomData.chat_messages : []
    const [finalData, setFinalData] = useState([])
    const callbackSetFinal = useCallback((data) => {
        if (data && data.length > 0 && !tempIsExist) {
            const insertCreated = insertCreatedDay(data)
            const dedupData = removeDuplicate(insertCreated)
            const updatedData = parseData(dedupData)
            setFinalData(updatedData)
        }
        else {
            setFinalData([])
        }
    }, [data])

    useEffect(() => {
        callbackSetFinal(data)
    }, [data])

    const chatRoomElement = useRef(null);

    useEffect(() => {
        scrollToBottom()
    })

    const scrollToBottom = () => {
        chatRoomElement.current.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
    }

    const insertCreatedDay = (res) => {
        let updatedRes = []
        updatedRes = res && res.length > 0 && res.map((item) => {
            return { ...item, created_day: moment(item.created_at).format('DD-MM-YYYY') }
        })
        return updatedRes
    }

    const removeDuplicate = (insertCreated) => {
        const arr = insertCreated.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
        )

        let finalArr = []

        arr.map((message_item, index) => {
            const deletedBy = message_item && message_item.deleted_by ? message_item.deleted_by.toString() : ''
            if (deletedBy.includes(userId) === false) {
                finalArr.push(message_item)
            }
        })
                
        return finalArr
    }

    const parseData = (arr) => {
        const res = arr && arr.length > 0 && arr.sort((a, b) => {
            return moment.utc(a.created_at).diff(moment.utc(b.created_at))
        })

        let groups = {};
        for (let i = 0; i < res.length; i++) {
            let groupName = res && res[i] && res[i].created_day ? res[i].created_day : "Unknown";

            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push(res[i]);
        }
        return groups
    }

    const sentDateFormat = {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true}

    const renderChatHistoryElement = obj => {
        const buyer_rating_expiration = chatRoomData && chatRoomData.buyer_rating_expiration ? chatRoomData.buyer_rating_expiration : null
        const parsedBuyerRatingExpiration = buyer_rating_expiration ? new Date(buyer_rating_expiration).toLocaleString('en-US', sentDateFormat) : null
        const seller_rating_expiration = chatRoomData && chatRoomData.seller_rating_expiration ? chatRoomData.seller_rating_expiration : null
        const parsedSellerRatingExpiration = seller_rating_expiration ? new Date(seller_rating_expiration).toLocaleString('en-US', sentDateFormat) : null
        const sellerRatingStatus = chatRoomData && chatRoomData.seller_rating_status ? chatRoomData.seller_rating_status : null
        const buyerRatingState = chatRoomData && chatRoomData.buyer_rating_status ? chatRoomData.buyer_rating_status : null

        const chatOwnerId = chatRoomData && chatRoomData.owner_id ? chatRoomData.owner_id : null
        const loginIsOwner = parseInt(userId) === parseInt(chatOwnerId) ? true : false
        const buyerUsername = chatRoomData.sender && chatRoomData.sender.s_username ? chatRoomData.sender.s_username : ''
        const sellerUsername = chatRoomData.user && chatRoomData.user.s_username ? chatRoomData.user.s_username : ''
        const ratingLinkToName = loginIsOwner ? buyerUsername :  sellerUsername
        const itemOwnerId = chatRoomData && chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.pk_i_id ? chatRoomData.item.oc_t_user.pk_i_id : null
        const loginIsSeller = parseInt(userId) === parseInt(itemOwnerId) ? true : false

        let element = []
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                const groupParsedDate = groupDateParser(key)
                const parsedDate = groupParsedDate === 'Today' ? 'Today' :
                    groupParsedDate === 'Yesterday' ? 'Yesterday' : new Date(groupParsedDate).toLocaleString('en-US', {month: 'short', day: '2-digit', year: 'numeric'})

                element.push(<div key={key} className="chat-created-date-container"><span className="chat-created-date">{parsedDate}</span></div>)
                obj[key] && obj[key].length > 0 && obj[key].map((item, index) => {
                    let messages = []
                    const sentDate = item.created_at ? item.created_at : ''
                    const message = item.body ? item.body : ''
                    const isUser = userId === item.sender_id.toString() ? true : false
                    const splitMessage = message.split(REACT_APP_WEB_SEC)
                    const text = splitMessage && splitMessage[0] ? splitMessage[0] : ''

                    if (text === 'buyer_request_review') {
                        messages.push(
                            <span key={index}>
                                {isUser ? 
                                    <RenderLoginUserChat sharedProps={{message, sentDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, loginIsSeller, sellerRatingStatus, buyerRatingState, parsedSellerRatingExpiration, toggleShowReviewModal}}/> : 
                                    <RenderUserChat sharedProps={{message, sentDate, item, parsedBuyerRatingExpiration, ratingLinkToName, isUser, loginIsSeller, sellerRatingStatus, buyerRatingState, parsedSellerRatingExpiration, toggleShowReviewModal}}/>
                                }
                            </span>
                        )
                    }
                    else if (text === 'seller_request_review') {
                        messages.push(
                            <span key={index}>
                                {isUser ? 
                                    <RenderLoginUserChat sharedProps={{message, sentDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, loginIsSeller, sellerRatingStatus, buyerRatingState, parsedSellerRatingExpiration, toggleShowReviewModal}}/> :
                                    <RenderUserChat sharedProps={{message, sentDate, item, parsedBuyerRatingExpiration, ratingLinkToName, isUser, loginIsSeller, sellerRatingStatus, buyerRatingState, parsedSellerRatingExpiration, toggleShowReviewModal}}/>
                                }
                            </span>
                        )
                    }
                    else {
                        messages.push(
                            <span key={index}>
                                {isUser ? 
                                    <RenderLoginUserChat sharedProps={{message, sentDate}}/> :
                                    <RenderUserChat sharedProps={{message, sentDate, item}}/>
                                }
                            </span>
                        )
                    }
                    element.push(messages)
                })
            }
        }
        return element
    }

    return (
        <div className="chat-history" style={{ height: height - 335 }}>
            {renderChatHistoryElement(finalData)}
            <div ref={chatRoomElement} />
        </div>
    )
}

export default WebChatHistory