import { AVATAR } from '../constant'

const initialState = {
    avatarIsLoading: true,
    avatarData: [],
    avatarError: false,
}

export default (state = initialState, action) => {
    switch(action.type){

        case AVATAR.LOAD:
        return {...state, avatarIsLoading: true, avatarError: null}

        case AVATAR.SUCCESS:
        return {...state, avatarIsLoading: false, avatarData: action.payload, avatarError: null}

        case AVATAR.FAIL:
        return {...state, avatarIsLoading: false, avatarError: action.payload}

        default:
        return state;  
    
    }
}