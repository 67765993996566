import React from 'react'
import {Link} from 'react-router-dom'
import {SettingsIcon, SignupIcon, LogoutIcon, GetItOnAppStore, GetItOnPlayStore} from '../../../../assets/svg'

export const HeaderSideBarContainer = ({arr, mobileBurger, handleLogout, session}) => {
    const categories = arr && arr.category ? arr.category : []
    const classifieds = arr && arr.classifieds ? arr.classifieds : []
    const isSession = session && session.fkv2_session && session.fkv2_session.id ? true : false
    let isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) ||(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

    return (
        <div className="mobile-sidebar-main-container">
            <div className="mobile-sidebar-container" ref={mobileBurger}>
                <div className="mobile-sidebar-sub-container">
                    <div className="category-sidebar-title-label-container">
                        <p>{`Category`}</p>
                    </div>
                    <div className="category-list-main-container">
                        {
                            categories.map((c, i) => {
                                const label = c && c.label ? c.label : ''
                                const icon = c && c.icon ? c.icon : null
                                const path = c && c.path ? c.path : '/'
                                return (
                                    <Link to={path} key={i} className="category-sidebar-container">
                                        <div className="sidebar-icon-container">{icon}</div>
                                        <p className="category-sidebar-label-p">{label}</p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                    <div className='mt-30'>
                        <div className="category-sidebar-title-label-container">
                            <p>{`DOWNLOAD THE APP`}</p>
                        </div>
                        <div className='category-list-main-container ph-6'>
                            <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row pl-17 mb-6 flex-align-center" target="_blank">
                                {/* <img src={GooglePlay} width={150}/> */}
                                <GetItOnPlayStore />
                            </a>
                            <a href="https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549" className="flex flex-d-row flex-align-center pl-17" target="_blank">
                                <GetItOnAppStore />
                            </a>
                        </div>
                    </div>
                    <div className="mt-30">
                        <div className="category-sidebar-title-label-container">
                            <p>{`Fishingkaki Classifieds`}</p>
                        </div>
                        <div className={`category-list-main-container ${isIOS ? `mb-30` : ``}`}>
                            {
                                classifieds.map((c, i) => {
                                    const label = c && c.label ? c.label : ''
                                    const icon = c && c.icon ? c.icon : null
                                    const path = c && c.path ? c.path : '/'
                                    return (
                                        <Link to={path} key={i} className="category-sidebar-container">
                                            <div className="sidebar-icon-container">{icon}</div>
                                            <p className="category-sidebar-label-p">{label}</p>
                                        </Link>
                                    )
                                })
                            }
                            {
                                isSession ? 
                                <>
                                    <Link to="/user/settings" className="category-sidebar-container">
                                        <div className="sidebar-icon-container"><SettingsIcon /></div>
                                        <p className="category-sidebar-label-p">{`Settings`}</p>
                                    </Link>
                                    <div className="category-sidebar-container" onClick={() => handleLogout()}>
                                        <div className="sidebar-icon-container"><LogoutIcon /></div>
                                        <p className="category-sidebar-label-p">{`Log out`}</p>
                                    </div>
                                </> : 
                                <>
                                    <Link to="/signup" className="category-sidebar-container">
                                        <div className="sidebar-icon-container"><SignupIcon /></div>
                                        <p className="category-sidebar-label-p">{`Signup`}</p>
                                    </Link>
                                    <Link to="/login" className="category-sidebar-container category-sidebar-container-login">
                                        <div className="sidebar-icon-container"><LogoutIcon /></div>
                                        <p className="category-sidebar-label-p">{`Log in`}</p>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}