import { SERVICES, PROMOTE, PURCHASE_COIN, COLLECT_PROMO } from '../constant'

export const servicesLoad = (payload) =>{
    return {
        type: SERVICES.LOAD,
        payload,
    }
}    

export const servicesSuccess = (payload) =>{
    return {
        type: SERVICES.SUCCESS,
        payload,
    }
}

export const servicesFailed = (payload) =>{
    return {
        type: SERVICES.FAIL,
        payload,
    }
}

export const promoteLoad = (payload) =>{
    return {
        type: PROMOTE.LOAD,
        payload,
    }
}    

export const promoteSuccess = (payload) =>{
    return {
        type: PROMOTE.SUCCESS,
        payload,
    }
}

export const promoteFailed = (payload) =>{
    return {
        type: PROMOTE.FAIL,
        payload,
    }
}

export const promoteReset = () =>{
    return {
        type: PROMOTE.RESET,
    }
}

export const purchaseCoinLoad = (payload) =>{
    return {
        type: PURCHASE_COIN.LOAD,
        payload,
    }
}    

export const purchaseCoinSuccess = (payload) =>{
    return {
        type: PURCHASE_COIN.SUCCESS,
        payload,
    }
}

export const purchaseCoinFailed = (payload) =>{
    return {
        type: PURCHASE_COIN.FAIL,
        payload,
    }
}

export const purchaseCoinReset = () =>{
    return {
        type: PURCHASE_COIN.RESET,
    }
}

//collect promo
export const collectPromoLoad = (payload) =>{
    return {
        type: COLLECT_PROMO.LOAD,
        payload,
    }
}    

export const collectPromoSuccess = (payload) =>{
    return {
        type: COLLECT_PROMO.SUCCESS,
        payload,
    }
}

export const collectPromoFailed = (payload) =>{
    return {
        type: COLLECT_PROMO.FAIL,
        payload,
    }
}

export const collectPromoReset = () =>{
    return {
        type: COLLECT_PROMO.RESET,
    }
}
