import React from 'react';
import { theme } from '../../../../common/theme'

const BoosterStatus = props => {
  const data = theme.text.sampleBooster;
  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null
  const dateNow = new Date(Date.now())

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
       <div style={style.full}>
         <div style={style.head}>
          <h1>Booster Status</h1>
         </div>
         <div>
          <div style={style.body}>
          {data.map((item, key) => {
            return <div key={key} style={style.body.card}>
             <div style={style.body.boosterTitle}> 
               {item.title}
               </div>
              <div>
                <p>Expiration date: {item.exp_date}</p>
              </div>
              <div>
                <i className="fas fa-rocket" />
                {item.exp_date > dateNow ? ' Ongoing' : ' Expired'}
              </div>
            </div>
            })}
          </div>
         </div>
       </div>
  );
}

export const style = {
  full: {
    width: '100%',
    border: '1px solid #E1E1E1'
  },
  head: {
    textAlign: 'center',
    borderBottom: '1px solid #E1E1E1'
  },
  body: {
    padding: '20px',
    card: {
      border: '1px solid #2c3e50',
      marginBottom: '20px',
      padding: '5px 10px'
    },
    boosterTitle: {
      paddingTop: '30px',
      fontWeight: '800'
    }
  }
}

export default BoosterStatus;