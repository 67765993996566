import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate'
import { adminBantTextLoad, adminUpsertBantTextLoad, adminUpsertBantTextReset, adminDeleteBanTextLoad, adminDeleteBanTextReset } from '../../../../../../redux/action'
import { BanTextList, BanTextModal } from './component/CustomComponent'
import {  BanTextContext } from '../../../../../../common/context'
import Loader from '../../../../../../common/component/loader'

const BanTextSection = (props) => {
    const { 
        adminBanTextData, adminBanTextIsLoading, adminBanTextError, adminBanTextPage, adminBanTextTotal,
        adminBanTextUpsertIsLoading, adminBanTextUpsertMessage, adminBanTextUpsertError,
        adminBanTextDeleteIsLoading, adminBanTextDeleteMessage, adminBanTextDeleteError
    } = props.admin

    //variables
    const [isShow, setIsShow] = useState(false)
    const [textInput, setTextInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            pk_i_id: '',
            s_name: '',
            type: 'text'
        }
    );

    useEffect(() => {
        if (adminBanTextData && adminBanTextData.length === 0) {
            props.adminBantTextLoad({first: 20, page: adminBanTextPage, type: 's_name'})
        }
    // eslint-disable-next-line     
    },[])

    useEffect(()=>{
        if (adminBanTextUpsertMessage) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertBantTextReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/text`)
            }, 2000)
        }
        if (adminBanTextUpsertError) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertBantTextReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/text`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminBanTextUpsertMessage, adminBanTextUpsertError])

    useEffect(()=>{
        if (adminBanTextDeleteMessage) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/text`)
            }, 2000)
        }
        if (adminBanTextDeleteError) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/text`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminBanTextDeleteMessage, adminBanTextDeleteError])

    const handleEdit = (item) => {
        setTextInput({
            pk_i_id: item.pk_i_id,
            s_name: item.s_name,
            type: 'text'
        });
        setIsShow(true)
    }

    const handleAdd = () => {
        setTextInput({
            pk_i_id: '',
            s_name: '',
            type: 'text'
        });
        setIsShow(true)
    }

    const handleDelete = (id) => {
        props.adminDeleteBanTextLoad({pk_i_id: id})
    }

    const closeModal = () => {
        setIsShow(false)
    }

    const upsertBan = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertBantTextLoad(data)
    }

    const pageCount = 10
    const handlePageClick = data => {
        props.adminBantTextLoad({first: 20, page: data.selected + 1, type: 's_name'})
    };

    return (
    <BanTextContext.Provider value = {{
        adminBanTextData, adminBanTextIsLoading, adminBanTextError, isShow, closeModal, upsertBan,
        adminBanTextUpsertIsLoading, adminBanTextUpsertMessage, adminBanTextUpsertError, textInput, setTextInput, handleEdit, handleDelete
    }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Ban Keyword</h3>
            <section>
            {
                adminBanTextIsLoading || adminBanTextDeleteIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminBanTextError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminBanTextError}</p></div></article> :
                adminBanTextDeleteMessage ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'green'}}>{adminBanTextDeleteMessage}</p></div></article> :
                adminBanTextDeleteError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminBanTextDeleteError}</p></div></article> :
                <article style={{flexDirection: 'column'}}>
                    <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={() => handleAdd(true)} className="sellButton pointer" style={{margin: 10, alignSelf: 'flex-start'}}>
                            <span>Add Ban Keyword</span>
                        </div>
                    </div>
                    <BanTextList type='load'/>
                </article>
            }
            <div></div> 
            {adminBanTextError ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminBanTextTotal}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }           
            </section> 
        </section> 
        {isShow ? <BanTextModal /> : null }
    </BanTextContext.Provider>    
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminBantTextLoad,
    adminUpsertBantTextLoad,
    adminUpsertBantTextReset,
    adminDeleteBanTextLoad, 
    adminDeleteBanTextReset
}
  
export default connect(mapStateToProps, mapActionToProps)(BanTextSection);
