import React, { useEffect, useState, useRef} from 'react';
import {getPaypalSubscriptionIdByAmount, resetPaypal} from '../../../common/helper/utility'

const UpgradeButton = (props) => {
    const {location} = props
    const type = new URLSearchParams(location.search).get("type")
    const userId = new URLSearchParams(location.search).get("userId")
    const data = new URLSearchParams(location.search).get("data")
    const s_email = new URLSearchParams(location.search).get("s_email")
    const has_active_subscription_params = new URLSearchParams(location.search).get("has_active_subscription")

    const upgradeData = data ? [JSON.parse(data)] : null 
    const sg_price = upgradeData && upgradeData[0] && upgradeData[0].sg_price ? upgradeData[0].sg_price : 0
    const plan_id = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)

    let paypalRef = useRef()

    const has_active_subscription = has_active_subscription_params ? JSON.parse(has_active_subscription_params) : null
    const subscription_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.id ? has_active_subscription.purchase_micro_service.id : null
    const transaction_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const payment_method = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.payment_method ? has_active_subscription.purchase_micro_service.payment_method : null

    const [paypalError, setPaypalError] = useState(false)

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                window.paypal.Buttons({
                    style: {
                        label: 'paypal',
                        width: '50%'
                    },
                    createSubscription: function(data, actions) {
                        return actions.subscription.revise(subscription_id, {
                            plan_id: plan_id
                        });
                    },
                    onApprove: async (data, actions) => {
                        const service_id = upgradeData && upgradeData[0] && upgradeData[0].id ? parseInt(upgradeData[0].id) : null
                        const successData = {id: id, transaction_id: transaction_id, payment_method: payment_method, plan_id: plan_id, service_id: service_id, order_status: 'success'}
                        window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(JSON.stringify(successData));
                    },
                    onError: async (e) => {
                        const error = await e
                        const errorData = {order_status: error}
                        window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(JSON.stringify(errorData));
                        setPaypalError(JSON.stringify(error))
                        setTimeout(() => {
                            setPaypalError(false)
                        }, 3000)
                    }
                }).render(paypalRef);
            }, 1000)
        }
    }
 
    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', background: 'transparent', height: '100%'}}>
            { paypalError ? 
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: 'red' }}>{paypalError}</p>
                </div> 
            : 
                <div>
                    <div ref={v => (paypalRef = v)}></div>
                </div>
            }
        </div>
    );
}

export default UpgradeButton;