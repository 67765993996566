import { SAMPLE_DATA, LIST_DATA } from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        page: 0,
        listData: []
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case SAMPLE_DATA.LOAD:
            return {...state, isLoading: true, error: null}

            case SAMPLE_DATA.SUCCESS:
            return {...state, isLoading: false, data:  action.payload, error: null}

            case SAMPLE_DATA.FAILED:
            return {...state, isLoading: false, error: action.payload}

            case LIST_DATA.LOAD:
            return {...state, listData: state.listData.concat(action.payload)  }

            case LIST_DATA.SUCCESS:
            return {...state}

            default:
            return state;  
        }
    }
    
    