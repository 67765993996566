import React from 'react';
import { labelSwitch } from '../../helper/utility'
import { theme } from '../../theme'

const Type = (props) => {
    const { name, onChange, selectedType, isDisabled } = props
    const { fieldType } = theme.text
    return (
            <span key={name}>
                <label ><b>{labelSwitch(name)}</b></label>
                <select id={name} name={name} className="category-select-container form-option" onChange={(event) => onChange(event)} required disabled={isDisabled} >
                    <option disabled selected>{`Select type`}</option>
                    {fieldType.map((item, index) => {
                        let arr = []
                        const typeName = item.label ? item.label : false
                        const isSelected = selectedType === item.label ? true : false
                        if (typeName) {
                            arr.push(
                            <option key={index} value={typeName} data-id={index} selected={isSelected} data-cat-name={typeName}>{`${typeName}`}</option>
                            )
                        }
                        return arr
                    })
                    }
                </select>
            </span>
    );
};

export default Type;
