import React, { useContext, useReducer, useEffect } from 'react';
import { AdminPromoContext } from '../../../../../../common/context';
import { labelSwitch, isExpired } from '../../../../../../common/helper/utility'

export const PromoList = () => {
    const value = useContext(AdminPromoContext);
    const { adminPromoData, fetchPreferenceDetail, deleteItem } = value
    
    return (
        <div className='main-container'>
            <div></div>
                {adminPromoData.length === 0 ? <div style={{display: 'flex', minHeight: 200, justifyContent: 'center', alignItems: 'center'}}><p style={{textAlign: 'center'}}>No item found</p></div> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Name</th><th>Description</th><th>Type</th><th>Amount</th><th>Duration</th><th>Expiry</th><th>Delete</th>
                            </tr>
                            {adminPromoData.map((item, index) => {
                                const expired = isExpired(item.expiry) ? true : false
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.name}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}><p className={'max-lines'}>{item.description}</p></td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.type}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.amount}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.duration}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}><p className={expired ? 'error-message' : ''}>{item.expiry}</p></td>
                                        <td className='text-align-center pointer' onClick={()=> deleteItem(item)}><i className="fas fa-trash-alt"></i></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const PromoModal = () => {
    const value = useContext(AdminPromoContext);

    const { 
        isShow, closeItemModal, promoDetailData, upsertItem, adminPromoUpsertIsLoading, adminPromoUpsertError, adminPromoUpsertMessage, isInsert
    } = value

    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    useEffect(() => {
        if (promoDetailData) {
            const data = promoDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    if (k !== 'updated_at' && k !== '__typename') {
                        input[k] = data[k]
                    } 
                }
            }
            setItemInput(input)
        }
    },[promoDetailData])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue =  evt.target.value === 'true' ? true : evt.target.value === 'false' ? false :  evt.target.value;

        setItemInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
            }
        }
        return errorArray
    }

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if ((k === 'name') || (k === 'description') || (k === 'type')) {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} required/>
                        </span>
                    )
                }
                else if ((k === 'amount')) {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="number" onChange={handleChange} name={k} value={data[k]} required/>
                        </span>
                    )
                }
                else if ((k === 'duration')) {
                    arr.push(
                        <span key={k}>
                            <label ><b>{`${labelSwitch(k)} in days`}</b></label>
                            <input type="number" onChange={handleChange} name={k} value={data[k]} required/>
                        </span>
                    )
                }
                else {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} disabled={ isInsert === 'Insert' ? false : true} required/>
                        </span>
                    )
                }
            }
        }
        return arr
    }
    
    const handleSubmit = (event, itemInput, type) => {
        event.preventDefault()
        upsertItem(itemInput, type)
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeItemModal()}>&times;</span>
                    {adminPromoUpsertIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                        adminPromoUpsertMessage ? <div className="message-container"><p className='success-message'>{adminPromoUpsertMessage}</p></div> :
                        <div className="container">
                            <form onSubmit={(event) => handleSubmit(event, itemInput, isInsert === 'Insert' ? 'INSERT' : 'UPDATE')}>
                            {renderInputs()}
                            { adminPromoUpsertError ? <div>{parseError(adminPromoUpsertError)}</div>  : null }
                            <input type="submit" value={`${isInsert === 'Insert' ? 'Add' : 'Update'} Promo`} className="sellButton pointer" style={{margin: '10px 0', alignSelf: 'flex-start'}}/>
                            </form>
                        </div>
                    }
                </div>
        </div>
    )
}

// export const PromoSuggestData = () => {
//     const value = useContext(AdminPromoContext);
//     const { adminPreferenceSuggestIsLoading, adminPreferenceSuggestData, fetchPreferenceDetail } = value

//     const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

//     let arrHolder = []

//     adminPreferenceSuggestData.length === 0 ? 
//         arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
//                         <p style={{padding: 10}}>No results</p>
//                     </div>
//         ) :
//         adminPreferenceSuggestData.map((item, index) => {
//         arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
//                             marginLeft: 10,
//                             marginRight: 10,
//                             borderLeft: '1px solid silver',
//                             borderRight: '1px solid silver',
//                             borderBottom:  (item.length -1) === index ? 0 : '1px solid silver',
//                             backgroundColor: 'white',
//                             height: 40
//                         }}>
//                             <button onClick={() => fetchPreferenceDetail(item)} style={{border: 'none'}}>{item.s_name}</button>
//                         </div>
//         )
//         return null
//     })
//     return adminPreferenceSuggestIsLoading ? arrLoader : arrHolder
// }

// export const PromoSearchResult = () => {
//     const value = useContext(AdminPromoContext);
//     const { setIsShowSearchResult, isShowSearchResult, adminPreferenceSuggestData, fetchPreferenceDetail, deleteItem } = value

//     const itemSelect = (item) => {
//         setIsShowSearchResult(false)
//         fetchPreferenceDetail(item)
//     }

//     const itemDelete = (item) => {
//         setIsShowSearchResult(false)
//         deleteItem(item)
//     }

//     return (
//         <div className='data-modal-container' style={{display: isShowSearchResult ? 'block' : 'none'}}>
//             <div className='modal-content'>
//                 <span className='modal-close' onClick={() => setIsShowSearchResult(false)}>&times;</span>
//                     <div className="modal-purchase-container">
//                         <h2>Search Results</h2>
//                         <table>
//                             <tbody>
//                                 <tr>
//                                     <th>Section</th><th>Name</th><th>Value</th><th>Type</th><th>Delete</th>
//                                 </tr>
//                                 {adminPreferenceSuggestData.map((item, index) => {
//                                     return (
//                                         <tr className='tr-data-container' key={index}>
//                                             <td onClick={()=> itemSelect(item)}>{item.s_section}</td>
//                                             <td onClick={()=> itemSelect(item)}>{item.s_name}</td>
//                                             <td onClick={()=> itemSelect(item)}><p className={'max-lines'}>{item.s_value}</p></td>
//                                             <td onClick={()=> itemSelect(item)}>{item.e_type}</td>
//                                             <td onClick={()=> itemDelete(item)} style={{textAlign: 'center'}}><i className="fas fa-trash-alt"></i></td>
//                                         </tr>
//                                     )                        
//                                 })}
//                             </tbody>    
//                         </table>
//                     </div>
//             </div>
//         </div>        
//     )
// }