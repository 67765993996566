import { ITEM, CATEGORY, ITEM_CREATE, REPORT } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { itemSuccess, itemFailed, itemLoadMoreSuccess, categorySuccess, categoryFailed, createItemsuccess, createItemFailed, reportSuccess, reportFailed } from '../action';
import  item from '../../graphql/item'
import { categoryIndexer } from '../../common/helper/utility'
import { theme } from '../../common/theme'

function* itemSaga(action) {
  const { payload } = action
  const page = payload.page ? payload.page : 1
  const first = payload && payload.first ? payload.first : 20
  const searchKey = payload.key ? payload.key : null
  const categoryId = payload.categoryId ?  payload.categoryId : 0
  const user_id = payload.user_id ? payload.user_id : null

  try {
    const bumps = yield item.getBumps()
    const result = yield item.getItems(page, first, searchKey, categoryId, user_id)
    if(result === 403) {
      yield put(itemFailed('Something went wrong try again!'))
    }
    else {
      const bumpData = bumps && bumps.data && bumps.data.publicGetBumpByStatus ? bumps.data.publicGetBumpByStatus : []
      const data = result && result.data && result.data.publicGetItemsByStatus ? result.data.publicGetItemsByStatus : {data: [], paginatorInfo: {} }
      yield put(itemSuccess({data, bumpData}));
      }
    } catch (error) {
      console.log('error', JSON.stringify(error))
      yield put(itemFailed(error))
    }
}  

export function* watchItemSaga() {
  yield takeLatest(ITEM.LOAD, itemSaga);
}

function* itemMoreSaga(action) {
  const { payload } = action
  const first = payload && payload.first ? payload.first : 20
  const page = payload.page ? payload.page : 1
  try {
    const result = yield item.getItems(first, page)

    if(result === 403) {
      yield put(itemFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.publicGetItemsByStatus ? result.data.publicGetItemsByStatus : {data: [], paginatorInfo: {} }
      yield put(itemLoadMoreSuccess(data));
      }
    } catch (error) {
      console.log('error', error)
      yield put(itemFailed(error))
    }
}    

export function* watchItemMoreSaga() {
yield takeLatest(ITEM.LOAD_MORE, itemMoreSaga);
}

function* itemGetSaga(action) {
  const { payload } = action
  const first = payload && payload.first ? payload.first : 10
  const page = payload.page ? payload.page : 1
  try {
    const result = yield item.getItems(first, page)

    if(result === 403) {
      yield put(itemFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.oc_t_items && result.data.oc_t_items.data ? result.data.oc_t_items.data : []
      yield put(itemSuccess(data));
      }
    } catch (error) {
      console.log('error', error)
      yield put(itemFailed(error))
    }
}   

export function* watchItemGetSaga() {
  yield takeLatest(ITEM.GET, itemGetSaga);
}

  function* itemCategorySaga() {
  try {
    const result = yield item.getItemCategory()
    if(result === 403) {
      yield put(categoryFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.publicGetItemCategoryByStatus && result.data.publicGetItemCategoryByStatus.data ? result.data.publicGetItemCategoryByStatus.data : []
      const indexedData = addIndex(data)
      const sortedData = sortByIndex(indexedData)
      yield put(categorySuccess(sortedData));

      }
    } catch (error) {
      console.log('error', JSON.stringify(error))
      yield put(categoryFailed(error))
    }

    function addIndex(data) {
      let categoryData = [];
      data.map(item => {
        const category = item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name : '' 
        const catIndex = categoryIndexer(category)
        categoryData.push({ ...item, categoryIndex:  catIndex});
      })
      return categoryData
    }

    function sortByIndex(arr){
      return arr.sort(function(a, b){return a.categoryIndex - b.categoryIndex});
    }
}   

export function* watchItemCategorySaga() {
  yield takeLatest(CATEGORY.LOAD, itemCategorySaga);
}

function* createItemSaga(action) {
  const { payload } = action

  try {
    const result = yield item.itemCreate(payload)
    if(result === 403) {
      yield put(createItemFailed('Something went wrong try again!'))
    }
    else {
      const message = result.data && result.data.itemCreate && result.data.itemCreate.message ? result.data.itemCreate.message : ''
      const data = result.data && result.data.itemCreate && result.data.itemCreate.response ? result.data.itemCreate.response : ''
      yield put(createItemsuccess({message: message, data: data}));
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
          ? error[0].extensions.validation : 
          error && error[0] && error[0].message ? error[0].message : theme.text.error.default
      console.log('error', JSON.stringify(e))
      yield put(createItemFailed(errorMessage))
    }
}    

export function* watchCreateItemSaga() {
yield takeLatest(ITEM_CREATE.LOAD, createItemSaga);
}

//Reports
function* reportSaga(action) {
  const { payload } = action
  try {
    const result = yield item.reportCreate(payload)

    if(result === 403) {
      yield put(reportFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.reportCreate && result.data.reportCreate.message ? result.data.reportCreate.message : ''
      yield put(reportSuccess(data));
      }
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
          ? error[0].extensions.validation : 
          error && error[0] && error[0].message ? error[0].message : theme.text.error.default
      console.log('error', JSON.stringify(e))
      yield put(reportFailed(errorMessage))
    }
}    

export function* watchReportSaga() {
yield takeLatest(REPORT.LOAD, reportSaga);
}
