import { SEARCH_SUGGEST } from '../constant'
import suggest from '../../graphql/suggest'
import { takeLatest, put } from 'redux-saga/effects'

import {suggestWordFail, suggestWordSuccess} from '../action/suggestAction'

function* suggestWordSaga(action) {
    const word = action && action.payload && action.payload.text ? action.payload.text : null
    const type = action && action.payload && action.payload.searchType ? action.payload.searchType : null
    const limit = action && action.payload && action.payload.limit ? action.payload.limit : null
    const order = type === 'user' ? 's_username' : 'dt_pub_date'

    try{
        const result = word ? yield suggest.getSuggestSearch(type, word, limit, order) : null
        if(result){
            if(result === 403){
                yield put(suggestWordFail('Something went wrong try again'))
            }else{
                let data
                if (type === 'user') {
                    data = result && result.data && result.data.publicUserSearch && result.data.publicUserSearch.data ? result.data.publicUserSearch.data : []
                } else {
                    data = result && result.data && result.data.publicItemSearch && result.data.publicItemSearch.data ? result.data.publicItemSearch.data : []
                }
                yield put(suggestWordSuccess(data))
            }
        }
       
    }catch(error){
        console.log('Error: ', JSON.stringify(error))
        throw error
    }
}

export function* watchSuggestWordSaga(){
    yield takeLatest(SEARCH_SUGGEST.LOAD, suggestWordSaga)
}