import React, {useContext, useState, useRef, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom'
import { ItemContext } from '../../../../../../../common/context';
import { numberWithCommas } from '../../../../../../../common/helper/utility'
import SocialAvatar from '../../../../../../../common/component/socialAvatar'
// import {TosModal} from './customComponent'
// import { style } from './style'
import { theme } from '../../../../../../../common/theme'
import DefaultPhoto from '../../../../../../../assets/images/defaultAvatar.png'
import {CoinsIcon, CartIconWhite} from '../../../../../../../assets/svg'
import FkImage from '../../../../../../../common/component/image'
import {TosModal, BaseAdsContainer, ComboAdsContainer, AutoBumpContainer, PremiumAdsContainer} from './customComponent'

const First = () => {
    const [tosModal, setTosModal] = useState(false)

    let taFirstIndex = 0  
    let hlFirstIndex = 0  
    let cbFirstIndex = 0  
    let abFirstIndex = 0  

    const [readMore, setReadMore] = useState(false)
    const [descHeight, setDescHeight] = useState()
    const promoteRect = useRef()

    let height
    useEffect(() => {
         height = promoteRect.current ? promoteRect.current.offsetHeight : 0;
        setDescHeight({height: height, current: promoteRect.current})
      }, []);
      const handleModalClick = () => {
        // if(tosModal){
        //     setTosModal(false)
        // }
        // else{
        //     setTosModal(true)
        // }
        setTosModal(false)
    }

    const handleReadMoreClick = () => {
        setReadMore(!readMore)
    }

    return (
        <>
        <div className="modalPromote-first-container">
            {/* {window.innerWidth < 900 ? null : <p className="modalPromote-other-choices-label-p">{`Other Choices`}</p>} */}
        <div >  
                <PremiumAdsContainer boostKey={'top_ad'} />
                <PremiumAdsContainer boostKey={'highlight'}/>
                <PremiumAdsContainer boostKey={'combo'}/>
                <PremiumAdsContainer boostKey={'auto_bump'}/>
            {/* <div>
                <BaseAdsContainer servicesData={servicesData} handleItemSelect={handleItemSelect} adsTotal={adsTotal}/>
                <div className={`flex ${window.innerWidth < 900 ? `flex-d-column flex-justify-center` : `flex-d-row`} mt-20 mb-30`}>
                    <ComboAdsContainer servicesData={servicesData} handleItemSelect={handleItemSelect} adsTotal={adsTotal}/>
                    <AutoBumpContainer servicesData={servicesData} handleItemSelect={handleItemSelect} adsTotal={adsTotal}/>
                </div>
            </div> */}
           
             
            {
            //    window.innerWidth < 900 ? null :
            //    <div className="flex flex-justify-center flex-align-center ml-20 mr-20">
            //         <div className="listing-promote-divider-main-container"/>
            //     </div>
           }
            
            <div className="modalPromote-item-details-container">
                {/* <div className='flex flex-d-row flex-justify-between flex-align-center'>
                    <div className="flex flex-d-column ">
                        <p className="curr-coins-ind-ads-label-p">{`Current Coins`}</p>
                        <div className='flex flex-d-row flex-align-center'>
                            <CoinsIcon />
                            <p className="curr-coins-value-p">{currentCoin}</p>
                        </div>
                    </div>
                    <Link to={{pathname: `/user/wallet`, isBuyCoinsClick: true}} className="buy-coins-ind-ads-btn">
                        <CartIconWhite />
                        <p>{`Buy Coins`}</p>
                    </Link>
                </div>
                <div className="promote-listing-ind-ads-total-container">
                    <p className="total-ind-ads-label-p">{`Total`}</p>
                    <div className="flex flex-d-row flex-align-center">
                        <CoinsIcon />
                        <p className="total-ind-ads-chose-p">{adsTotal.base_ads ? adsTotal.base_ads : adsTotal.combo_ads ? adsTotal.combo_ads : adsTotal.auto_bump_ads ? adsTotal.auto_bump_ads : 0}</p>
                    </div>
                </div>
                <div className={`ind-ads-purchase-btn ${window.innerWidth < 900 ? `mb-20` : ``} ${adsTotal.base_ads === 0 && adsTotal.combo_ads === 0 && adsTotal.auto_bump_ads === 0 ? `ind-ads-purchase-btn-disable` : ``}`} onClick={() => adsTotal.base_ads === 0 && adsTotal.combo_ads === 0 && adsTotal.auto_bump_ads === 0 ? null :  handleNext()}>
                    <p>{`Purchase`}</p>
                </div> */}
                {
                    // window.innerWidth < 900 ? null :
                    // <div className="flex flex-justify-end flex-align-center mt-20">
                    //     <div className="clck-for-preview-btn">
                    //         <p>{`Click for Preview`}</p>
                    //     </div>
                    // </div>
                }
                {/* <div className="modalPromote-desktop">
                    <div className="ind-ads-item-mini-card">
                        <div className="flex flex-d-row flex-align-center flex-justify-center">
                            {
                                userImage ? <FkImage images={[userImage]} type="seller_avatar" alt="avatar" /> :
                                user_social_resource ? 
                                <SocialAvatar id={user_social_id}/>:
                                <img src={DefaultPhoto} width={40} height={40} alt="default seller avatar" />
                            }
                            <div className='user-pub-container'>
                                <p className='seller-name'>{sellerName}</p>
                                <p className='pub-date'>{pubDate}</p>
                            </div>
                        </div>
                        <div className='item-mini-image'>
                            <FkImage images={itemImage} type="itemMiniImage" alt='image'/>
                        </div>
                        <p className='promote-price'>{`$${price}`}</p>
                        <p className="promote-category">{`in ${category_name}`}</p>
                        <div className="flex flex-justify-start ">
                            <p className='promote-item-title'>{title}</p>
                        </div>
                        <div>
                            <p ref={promoteRect} className={readMore ? 'promote-item-description expand' : 'promote-item-description promote-shrink'}>{description}</p>
                            {descHeight ? 
                                descHeight.height >= 160 ? 
                                    readMore ? 
                                    <span className="promote-read-more-btn" onClick={() =>handleReadMoreClick()}>{`Read Less`}</span>  : <span className="promote-read-more-btn" onClick={() =>handleReadMoreClick()}>{`Read More`}</span> 
                                : null 
                            : null}
                        </div>
                    </div>
                </div> */}
                {/* <div className="modalPromote-mobile">
                    <div className={`item-details-container ${show ? ``: `no-border`}`}>
                        <div>
                            <p>{`Item Details`}</p>
                        </div>
                        <div onClick={handleClick}>
                            <i className={ show ? `fas fa-chevron-up` : `fas fa-chevron-down`} />
                        </div> 
                    </div>
                    {
                        show ?
                        <div style={{marginTop: 12}}>
                            <div >
                                {userImage ? 
                                <FkImage images={[userImage]} type="seller_avatar" alt="avatar" />: user_social_resource ?
                                <SocialAvatar id={user_social_id} />
                                : <img src={DefaultPhoto} width={40} height={40} style={{borderRadius: "50%"}} />
                            }
                                <div className='user-pub-container'>
                                    <p className='seller-name'>{sellerName}</p>
                                    <p className='pub-date'>{pubDate}</p>
                                </div>
                            </div>
                            <div className='item-mini-image'>
                                <FkImage images={itemImage} type="itemMiniImage" alt='image'/>
                            </div>
                            <p className='promote-price'>{`$${price}`}</p>
                            <p className='promote-item-title'>{title}</p>
                            <p className='promote-item-description'>{description}</p>
                        </div>
                        : null
                    } 
                </div> */}
            </div>
        </div>
        {/* <div className={`fk-prime-purchase-modal-footer-container ${window.innerWidth < 900 ? `p-20-10` : `p-0 p-t-20`}`}>
            <div onClick={ handleModalClick}>
                <p className='fk-prime-purchase-modal-footer-disclaimer-p' >{theme.text.purchaseTOS}<span style={{textDecoration: 'underline', cursor: 'pointer'}}>{`Terms of Use`}</span> </p>
            </div>
        </div> */}
    </div>
    {tosModal ? 
            <div style={{width: '100%', height: '100%', position: 'fixed', zIndex: '3', top: '0', left: '0', background: 'rgba(44, 62, 80,0.3)', display: 'flex', justifyContent: 'center', paddingTop: '10px', overflow: 'auto', paddingBottom: '100px'}}>
              <TosModal click={handleModalClick}/>
            </div> : null}
    </>
    );
}

export default First;