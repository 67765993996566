import React, {useContext} from 'react';
import { PromoteContext } from '../../../../../../common/context';
// import Stripe from '../method/stripe'
// import PayPal from '../method/paypal'
import { styles } from './style'

const Third = () => {
    const value = useContext(PromoteContext);
    const {purchaseData } = value

    // const paymentSwitch = () => {
    //     let paymentHolder = []
    //     switch (purchaseData.payment_method) {
    //         case 'stripe':
    //             paymentHolder = <Stripe />
    //         break;

    //         case 'paypal':
    //             paymentHolder = <PayPal />
    //         break;

    //         // case 'paynow':
    //         //     paymentHolder = <Third />
    //         // break;

    //         default:
    //         break;
    //     }
    //     return paymentHolder
    // }

    return (
        <React.Fragment>
            {/* {paymentSwitch()} */}
        </React.Fragment>
    );
}

export default Third;