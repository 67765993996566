import React, {useContext} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import PaypalButton from './buyCoinsPaymentMethod/PaypalButton'
import StripeButton from './buyCoinsPaymentMethod/StripeButton'
import {FishIcon, InfoIcon, CheckGreenBGIcon, PlusIcon, MinusIcon, MobileGreaterThanIcon, IndividualBoostCoinIcon, FKPrimeFishIcon, CoinsIcon, CloseIcon,  CoinsBundleLess, CoinsBundleMore, CoinsBundleMostWithBestDealLabel, FkPrimeGrayFishIcon, CartIconWhite, BackMobileButton, PaypalIcon, CreditCardsIcon, ChevronUp, SuccessSVGIcon, BackIcon, ErrorIcon} from '../../../assets/svg'
import { FkPrimeContext } from '../../../common/context'
// import {PaypalUpgradeButton} from './paymentMethod/PaypalButton'
import {PaypalUpgradeButton} from './paymentMethod/PaypalUpgradeButton'
import {StripeUpgradeButton} from './paymentMethod/StripeUpgradeButton'

export const BoostCards = ({data, styleClass, titleTextClass, subtitleClass, priceClass, priceTextClass, isSelected, boostClass, boostTextClass, id, handleBoostSelection, userBoost, mainClass, type, titleContainerClass, haveIncremation, setUserBoost, handleIncrementDecrement, handleClaimFree, freeClaim, is_free_subscription_claimed, toggleSubscriptionData, has_active_subscription}) => {
    const {is_available} = data
    const current_subs_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.service_id ? has_active_subscription.purchase_micro_service.service_id : 0
    const sub_stat = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null
    // console.log("is_available", current_subs_id === parseInt(data.id) ? true : false, "current_subs_id", has_active_subscription)
    // console.log("sub_stat", has_active_subscription, type, id, data)
    return (
        <div className={mainClass}>
            <div className={`${styleClass} ${type === 'otp' ? `mr-19 p-30-12` : ``}`}>
                <div className="w-100 flex flex-direction-column flex-justify-center flex-align-center h-100" onClick={(e) => 
                    data.name.includes('Free') && is_free_subscription_claimed ? 
                    null : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? handleBoostSelection(type, id, data) : is_available ? toggleSubscriptionData(data, true) : 
                     null : handleBoostSelection(type, id, data)}>
                    <div className={titleContainerClass} >
                        <p className={`${titleTextClass} 
                        ${!has_active_subscription ? '' : data.name.includes('Free') && is_free_subscription_claimed ? 'text-gray' : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? 'text-gray' : '' : ''}`}>{data.name}</p>
                        { type === 'fkPrime' ? <p className={subtitleClass}>{data.subname}</p> : null}
                    </div>
                    <div className={priceClass}>
                        <p className="fk-prime-price-symbol-p">{`S$`}</p>
                        <p className={`${priceTextClass} 
                        ${!has_active_subscription ? '' : data.name.includes('Free') && is_free_subscription_claimed ? 'text-gray' : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? 'text-gray' : '' : ''}`}>{data.price.toFixed(2)}</p>
                    </div>
                    {haveIncremation ?
                    <div className="w-100 flex flex-d-row boost-incremation-container flex-justify-center flex-align-center">
                        <div className="minusIconBoostIncrementContainer" onClick={() => handleIncrementDecrement('subtract', data.id, data.sg_price, data)}>
                            <MinusIcon />
                        </div>
                        <p className="w-100 flex flex-justify-center flex-align-center boost-increment-quantity-p">{userBoost.otpMultiQuantity}</p>
                        <div className="plusIconBoostIncrementContainer" onClick={() => handleIncrementDecrement('add', data.id, data.sg_price, data)}>
                            <PlusIcon />
                        </div>
                    </div>
                        : null}
                        {/* {console.log("is_available", is_available)} */}
                    <div className={boostClass}>
                        <div className={`flex flex-align-center ${type === 'fkPrime' ? 'fk-prime-list-main-container' : 'lifetime-plans-list-main-container'}`} >
                            {
                                type === 'fkPrime' ?
                                <div className="flex flex-d-row flex-align-start">
                                    <div className="pt-5 mr-14">
                                       {data.name.includes('Free') && is_free_subscription_claimed ? <FkPrimeGrayFishIcon /> : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? <FKPrimeFishIcon />  :  !is_available ? <FkPrimeGrayFishIcon /> :  <FKPrimeFishIcon /> : <FKPrimeFishIcon />}
                                    </div>
                                    <div>
                                        <p className={`${boostTextClass} 
                                        ${!has_active_subscription ? '' : data.name.includes('Free') && is_free_subscription_claimed ? 'text-gray' : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? 'text-gray' : '' : ''}`}>{data.description}</p>
                                    </div>
                                </div> : 
                                <p className={`${boostTextClass} ${!has_active_subscription ? '' : data.name.includes('Free') && is_free_subscription_claimed ? 'text-gray' : has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? 'text-gray' : '' : ''}`}>{data.description}</p>
                            }
                            
                            {
                                data.name.includes('Free') ? 
                                <p className={`fk-prime-listing-free-label-p ${is_free_subscription_claimed ? `text-gray` : ``}`}>{`Listing has no expiration`}</p> : null
                            }
                            {
                                data.name.includes('Free') ? 
                                <div className={is_free_subscription_claimed ? `free-claimed-boost-container-btn` : `free-claim-boost-container-btn`} onClick={() => is_free_subscription_claimed ? null : handleClaimFree()}>
                                    <p>{is_free_subscription_claimed ? `Claimed` : `Claim Free`}</p>
                                </div> : null
                            }
                            {
                                // type !== 'fkPrime' && !data.name.includes('Listing') ?
                                // <div className="lifetime-plans-fkprime-discount-label-container">
                                //     <p className="lifetime-plans-fkprime-discount-label-p">{`FK Prime member get`}</p>
                                //     <p className="lifetime-plans-fkprime-discount-int-p">{`50% off`}</p>
                                // </div> : null
                            }
                            {
                                has_active_subscription && !data.name.includes('Free') ? sub_stat.toLowerCase().includes('cancel') ? null :
                                <div className={!is_available ? current_subs_id === parseInt(data.id) ? `free-claimed-boost-container-btn` : `` : `upgrade-current-boost-container-btn`}>
                                    <p>{is_available ? `Upgrade` : current_subs_id === parseInt(data.id) ? `Current Plan` : ``}</p>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="w-100 flex flex-justify-end">
                    <InfoIcon />
                </div> */}
                {
                    data.discount ? 
                    <>
                    <div className={`p-absolute right-0 top-0 ${type === 'fkPrime' ? `fk-prime-discount-border-color-red` : ``} fk-prime-discount-container`} />
                    <div className="fk-prime-discount-sub-container">
                        <p className="fk-prime-discount-text-p">{data.discount}</p>
                        <p className="fk-prime-off-text-p">{'%OFF'}</p>
                    </div>
                    </>
                    : null
                }
            </div>
            {
                isSelected ? 
                <div className={`p-absolute ${type === 'otp' ? `fk-prime-selected-check-container-small` : `fk-prime-selected-check-container`}`}>
                    <CheckGreenBGIcon />
                </div> : null
            }
        </div>
    )
}

export const FKPrimeText = ({text, styleClass}) => {
    return (
        <p className={styleClass}>{text}</p>
    )
}

export const PartPillGroupBtn = ({text, styleClass, textClass, onClickAction}) => {
    return (
        <div className={styleClass} onClick={() => onClickAction()}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const ButtonWithIcon = ({text, LeftIcon, styleClass, RightIcon, textClass, onClickAction}) => {
    return (
        <div className={styleClass} onClick={() => onClickAction()}>
            {LeftIcon ? <LeftIcon /> : null}
            <p className={textClass}>{text}</p>
            {RightIcon ? <RightIcon /> : null}
        </div>
    )
}

export const ManyNotes = ({notes, textClass}) => {
    return (
        <div>
            <p className={textClass}>{`*Notes:`}</p>
            {
                notes.map((note, index) => {
                    return <p className={textClass} key={index}>{note.text}</p>
                })
            }
        </div>
        
    )
}

export const OtpBoostDetails = ({data}) => {
    return (
        <div className="flex flex-d-row">
            <div className="otp-boost-price-option-container">
                <p className="otp-boost-price-symbol-option">{`S$`}</p>
                <p className="otp-boost-price-p">{data.price}</p>
            </div>
            <div className="otp-boost-title-main-container">
                <p className="otp-boost-title-p">{data.name}</p>
                <p className="otp-boost-desc-p">{data.description}</p>
            </div>
        </div>
    )
}

export const Text = ({styleClass, textClass, text}) => {
    return (
        <div className={styleClass}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const RadioWithText = ({styleClass, textClass, text, value, onChangeFunc, radioClass, purchaseSelected}) => {
    return (
        <div className={styleClass}>
            <input type="radio" value={value} checked={purchaseSelected === value ? true : false} onChange={onChangeFunc} className={radioClass}/>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const IconContainerClickable = ({styleClass, Icon, clickFunc}) => {
    return (
        <div className={styleClass} onClick={() => clickFunc()}>
           {Icon}
        </div>
    )
}

export const Divider = () => {
    return (
        <div className="w-100 divider-border"/>
    )
}

export const PriceTextWithSymbol = ({styleClass, priceClass, price, symbol, symbolClass}) => {
    return (
        <div className={`flex flex-d-row flex-align-center ${styleClass}`}>
            <p className={symbolClass}>{symbol}</p>
            <p className={priceClass}>{price}</p>
        </div>
    )
}

export const Button = ({styleClass, textClass, text, clickFunc}) => {
    return (
        <div className={styleClass} onClick={() => clickFunc()}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const LinkText = ({path, textClass, text}) => {
    return (
        <Link to={path} className={textClass} >
            <p>{text}</p>
        </Link>
    )
}

export const MobileHeader = ({styleClass, leftFunc, leftIcon, leftStyleClass, leftText, leftTextClass, centerStyleClass, centerText, centerTextClass, rightStyleClass, rightText, rightTextClass}) => {
    return (
        <div className={styleClass}>
            <div className={leftStyleClass} onClick={() => leftFunc ? leftFunc() : null}>
                {leftIcon}
                <p className={leftTextClass}>{leftText}</p>
            </div>
            <div className={centerStyleClass}>
                <p className={centerTextClass}>{centerText}</p>
            </div>
            <div className={rightStyleClass}>
                <p className={rightTextClass}>{rightText}</p>
            </div>
        </div>
    )
}

export const ActionText = ({styleClass, text, textClass, textAction}) => {
    return (
        <div className={styleClass} onClick={() => textAction()}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const MobileCard = ({boost, titleTextClass, subtitleTextClass, textContainerClass, type, id, handleBoostSelection, setUserBoost, styleClass, isSelected, peekFkPrimeBoost, isPeek, toggleSubscriptionData, is_free_subscription_claimed, has_active_subscription}) => {
    const {is_available} = boost
    const sub_stat = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null
    return (
        <div className={styleClass}>
            {
                isSelected ? 
                <div className={`p-absolute fk-prime-selected-check-container`}>
                    <CheckGreenBGIcon />
                </div> : null
            }
            <div className="flex flex-d-row flex-justify-between flex-align-center w-100 hpx-98">
                <div className="flex flex-d-row flex-justify-between flex-align-center w-100" 
                onClick={() => boost && boost.service &&
                boost.service_name.includes('Free') && is_free_subscription_claimed ? null : 
                has_active_subscription ? sub_stat.toLowerCase().includes('cancel') ? handleBoostSelection(type, id, boost) : is_available ? toggleSubscriptionData(boost, true) :null : handleBoostSelection(type, id, boost) }>
                    <div className={textContainerClass}>
                        <p className={`${titleTextClass} ${ boost && boost.service && boost.service_name.includes('Free') && is_free_subscription_claimed ? `text-gray` : !has_active_subscription ? '' : has_active_subscription ? sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? ' text-gray' : '' : ''}`}>{boost.name}</p>
                        <p className={`${subtitleTextClass} ${boost && boost.service && boost.service_name.includes('Free') && is_free_subscription_claimed ? `text-gray` : !has_active_subscription ? '' : has_active_subscription ? sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? ' text-gray' : '' : ''}`}>{ boost.subname  }</p>
                    </div>
                    <div className="flex flex-d-row">
                        <p className="fk-prime-mobile-symbol-price-p">{`S$`}</p>
                        <p className={`${boost.price === 0 ? `fk-prime-mobile-int-inactive-price-p` : `fk-prime-mobile-int-price-p`} ${!has_active_subscription ? '' : sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? ' text-gray' : '' }`}>{boost.price === 0 ? `0` : boost.price.toFixed(2)}</p>
                    </div>
                </div>
                {
                    type === 'fk_prime' ?
                    <div className={`ml-28 ${ boost && boost.service && boost.service_name.includes('Free') && is_free_subscription_claimed ? 'make-next-icon-to-gray-color' : sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? ` make-next-icon-to-gray-color` : ``}`} onClick={() => peekFkPrimeBoost(id)}>
                       { isPeek ? <ChevronUp /> : <MobileGreaterThanIcon />}
                    </div> : null
                }
            </div>
            {
                isPeek ?
                <div className="fk-prime-mobile-boost-peek-container">
                    <div className={`pt-6 ${boost && boost.service && boost.service_name.includes('Free') && is_free_subscription_claimed ? `make-next-svg-gray-color` : !has_active_subscription ? '' : sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? 'make-next-svg-gray-color' : '' }`}>
                        <FKPrimeFishIcon />
                    </div>
                    <div>
                        <p className={`fk-prime-mobile-boost-description-p ${boost && boost.service && boost.service_name.includes('Free') && is_free_subscription_claimed ? `text-gray` : !has_active_subscription ? '' : sub_stat && sub_stat.toLowerCase().includes('cancel') ? '' : !is_available ? ' text-gray' : '' }`}>{boost.description}</p>
                    </div>
                </div> : null
            }
        </div>
    )
}

export const IndividualAdsTitleSubtitleContainer = (title, subtitle) => {
    return (
        <div>
            <p className="individual-ads-boost-title-p">{title}</p>
            <p className="individual-ads-boost-subtitle-p">{subtitle}</p>
        </div>
    )
}

export const MobileMiniStandBorder = () => {
    return (
        <div className="mobile-mini-stand-border"/>
    )
}

export const BoostDetailContainer = (boost, clickHandler, ids) => {
    const idList = ids ? ids : []
    return (
        <div className={window.innerWidth < 600 ? `boost-details-mobile-main-container` : `mt-30`}>
        {
            boost.map((b, i) => {
                const name = b && b.name ? b.name : ''
                const price = b && b.credit ? b.credit : ''
                const days = b && b.days ? b.days : ''
                const discount = b && b.discount ? b.discount : 0
                const type = b && b.type ? b.type : ''
                const found = idList.some(el => el.id === b.id);
                return (
                    <div key={i} className="flex flex-d-row flex-justify-between mb-30">
                        <div className="flex flex-d-row w-100">
                            <div className={discount ? `` : `flex flex-justify-center flex-align-center`}>
                                <IndividualBoostCoinIcon />
                            </div>
                            <div className={`ml-4 ${window.innerWidth < 600 ? `mr-16` : `mr-6`} flex flex-d-column flex-justify-center flex-align-center`}>
                                <p className="individual-ads-original-price-p">{price}</p>
                               { discount ? <p className="individual-ads-price-discount-p">{discount}</p> : null}
                            </div>
                            {
                                window.innerWidth < 600 ?
                                <div className="flex flex-justify-center flex-align-center mr-16">
                                    {MobileMiniStandBorder()}
                                </div> : null
                            }
                            <div className="flex flex-d-column h-100 flex-justify-between">
                                <p className="individual-ads-boost-name-p">{name}</p>
                                <p className="individual-ads-boost-due-days-p">{`${days} Days`}</p>
                            </div>
                        </div>
                        <div className="flex flex-align-center flex-justify-end">
                            <input type="radio" onClick={() => clickHandler(b, price, type)} className="item-condition-radios radio custom-radio-ui " checked={found ? true : false}/>
                        </div>
                    </div>
                )
            })
        }
        </div>
    )
}

export const MobileIndividualBoostOfferCard = ({boost, type, multiBoost, boost1, boost2, title, subtitle, boost1Title, boost1Subtitle, boost2Title, boost2Subtitle, clickHandler, total, ids}) => {
    return (
        <div className="individual-ads-main-card-container">
            <div className="flex flex-d-row flex-align-center flex-justify-between">
                <p className="individual-ads-card-title-p">{type.replace('_', ' ')}</p>
                <div>
                    <div className="flex flex-d-row flex-align-start flex-justify-end">
                        <IndividualBoostCoinIcon />
                        <div className="individual-ads-card-total-container">
                            <p>{total}</p>
                        </div>
                    </div>
                    <p className="individual-ads-card-total-below-label-p">{`Please select an Ad`}</p>
                </div>
                
            </div>
            <div className="individual-ads-card-boost-offer-container">
                {
                    multiBoost ? 
                    <div className="flex flex-d-column">
                        <div >
                            {IndividualAdsTitleSubtitleContainer(boost1Title, boost1Subtitle)}
                            {BoostDetailContainer(boost1, clickHandler, ids)}
                        </div>
                        <div>
                            {IndividualAdsTitleSubtitleContainer(boost2Title, boost2Subtitle)}
                            {BoostDetailContainer(boost2, clickHandler, ids)}
                        </div>
                    </div> :
                    <div>
                        {IndividualAdsTitleSubtitleContainer(title, subtitle)}   
                        {BoostDetailContainer(boost, clickHandler, ids)}
                    </div>
                }
            </div>
            {/* {multiBoost ?} */}
        </div>
    )
}

export const IndividualBoostOfferCard = ({boost, type, multiBoost, boost1, boost2, title, subtitle, boost1Title, boost1Subtitle, boost2Title, boost2Subtitle, clickHandler, total, ids}) => {

    return (
        <div className="individual-ads-main-card-container">
            <div className="flex flex-d-column flex-justify-center flex-align-center">
                <div className="flex flex-justify-center flex-align-center">
                    <p className="individual-ads-card-title-p">{type.replace('_', ' ')}</p>
                </div>
                <div className="flex flex-d-row flex-align-start">
                    <IndividualBoostCoinIcon />
                    <div className="individual-ads-card-total-container">
                        <p className={total !== 0 ? `text-orange` : ``}>{total}</p>
                    </div>
                </div>
                <p className="individual-ads-card-total-below-label-p">{`Please select an Ad`}</p>
                <div className="individual-ads-card-boost-offer-container">
                    {
                        multiBoost ?
                        <div className="flex flex-d-row">
                            <div >
                                {IndividualAdsTitleSubtitleContainer(boost1Title, boost1Subtitle)}
                                {BoostDetailContainer(boost1, clickHandler, ids)}
                            </div>
                            <div>
                                {IndividualAdsTitleSubtitleContainer(boost2Title, boost2Subtitle)}
                                {BoostDetailContainer(boost2, clickHandler, ids)}
                            </div>
                        </div> : 
                        <div>
                            {IndividualAdsTitleSubtitleContainer(title, subtitle)}   
                            {BoostDetailContainer(boost, clickHandler, ids)}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const MobileFooter = ({boost, isCoinPurchase, handleFooter, text, customButton, buyCoinsFunc, removeBuyCoinsBtn}) => {
    return (
        <>
        {
            removeBuyCoinsBtn ? null :
            <div className="buy-coins-mobile-main-body-container" onClick={() => buyCoinsFunc ? buyCoinsFunc() : null}>
                <CartIconWhite />
                <p>{`Buy Coins`}</p>
            </div>
        }
        <div className="fk-prime-mobile-footer-container">
            <div className="fk-prime-mobile-footer-total-container">
                {
                    isCoinPurchase ? 
                    <div className="flex flex-d-row flex-align-center">
                         <CoinsIcon />
                         <p className="fk-prime-coins-total-int-p">{boost}</p>
                    </div> :
                    <p className="fk-prime-mobile-footer-total-p">{`S$ ${boost ? boost.toFixed(2) : 0}`}</p>
                }
            </div>
            <div className=" flex flex-align-center flex-justify-center">
               { customButton ? customButton : <div className={boost ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={(e) => boost !== 0 && handleFooter ?  handleFooter(e) : null}>
                    <p className="fk-prime-purchase-p">{text ? text : `Purchase`}</p>
                </div>}
            </div>
        </div>
        </>
    )
}

export const SmallCoinsContainer = ({icon, data, handleBuyCoins, offerSelected, wallet, total}) => {
    const credit = data && data.credit ? data.credit : 0
    const price = data && data.sg_price ? data.sg_price : 0
    const boostId = data && data.id ? data.id : 0
    const found = offerSelected.some(el => el.id === boostId)
    return (
        <div className={ found ? `active-buy-coins-border buy-coins-option-small-container` : `buy-coins-option-small-container`} onClick={() =>  handleBuyCoins(data)}>
            {icon}
            <p className="buy-coins-credit-coins-p mt-10">{`${credit} coins`}</p>
            <p className="buy-coins-price-coins-p mt-16">{`${price}S$`}</p>
            {found ?  <div className={`p-absolute buy-coins-select-check-main-container`}>
                    <CheckGreenBGIcon />
                </div> : null}
        </div>
    )
}

export const LargeCoinsContainer = ({icon, data, handleBuyCoins, offerSelected, wallet, total}) => {
    const credit = data && data.credit ? data.credit : 0
    const price = data && data.sg_price ? data.sg_price : 0
    const boostId = data && data.id ? data.id : 0
    const found = offerSelected.some(el => el.id === boostId)
    return (
        <div className={ found ? `active-buy-coins-border buy-coins-option-large-container` : `buy-coins-option-large-container`} onClick={() => handleBuyCoins(data)}>
            {icon}
            <div className="flex flex-d-column flex-align-center flex-justify-center">
                <p className="buy-coins-credit-coins-p">{`${credit} coins`}</p>
                <p className="buy-coins-price-coins-p mt-16">{`${price}S$`}</p>
            </div>
            {found ?  <div className={`p-absolute buy-coins-select-check-main-container`}>
                <CheckGreenBGIcon />
            </div> : null}
        </div>
    )
}

export const ModalFooter = () => {
    return (
        <div className="buy-coins-modal-footer-container">
            <p className="buy-purchasing-label-p">{`By purchasing any of the above, you accept our `}</p>
            <p className="buy-purchasing-terms-link-p">{`Terms of Services.`}</p>
        </div>
    )
}
export const PaymentSuccess = () => {
    return (
        <div className="flex flex-d-column mt-113 flex-align-center">
            <p className="payment-successful-label-p">{`Payment Successful!`}</p>
            <SuccessSVGIcon />
        </div>
    )
}

export const PaymentUnsuccessful = ({text}) => {
    return (
        <div className="flex flex-d-column flex-align-center">
            <p className="payment-unsuccessful-label-p">{text}</p>
            {/* <ErrorIcon /> */}
        </div>
    )
}

export const PurchaseArrMapper = (arr) => {
    return (
        arr.map((offer, i) => {
            const coins = offer && offer.credit ? offer.credit : 0
            const price = offer && offer.sg_price ? offer.sg_price : 0
            const dt_today = moment().format('DD MMMM, YYYY')
            return (
                <div key={i} className="flex flex-d-row flex-justify-between flex-align-center mb-30">
                    <div>
                        <p className="int-buy-coins-per-total-p">{`${coins} Coins`}</p>
                    </div>
                    <div className="flex flex-d-column flex-justify-end ">
                        <div className="flex flex-d-row flex-justify-end text-align-end">
                            <p className="int-buy-coins-currency-symbol-p">{`S$`}</p> &nbsp;
                            <p className="int-buy-coins-currency-price-p">{price}</p>
                        </div>
                        <p className="int-buy-coins-dt-today">{dt_today}</p>
                    </div>
                </div>
            )
        })
    )
}

export const MobileModalBuyCoins = ({ offer, handleBuyCoins, offerSelected, handleBuyCoinsStepsAction, buyCoinsStep, wallet, total, setBuyCoinsModal, handleBuyCoinsPay}) => {
    const buyCoinsOffer = offer ? offer : []
    const selectedArr = offerSelected && offerSelected.coinsArr ? offerSelected.coinsArr : []
    const hasSelected = offerSelected.coinsArr.length !== 0 ? true : false
    const closeModalFunc = () => {
        setBuyCoinsModal(false)
    }
    const backOneStep = () => {
        handleBuyCoinsStepsAction('-')
    }
    return(
        <div className="purchase-main-body-mobile-modal-container">
            <MobileHeader 
                styleClass="w-100 fk-prime-mobile-header-container"
                leftStyleClass="flex flex-d-row flex-align-center"
                leftTextClass="fk-prime-left-header-text-p"
                leftFunc={buyCoinsStep === 1 ? closeModalFunc : backOneStep}
                leftIcon={ <BackIcon />}
                leftText={`Back`}
                centerStyleClass="fk-prime-center-header-container"
                centerTextClass="fk-prime-center-header-text-p"
                centerText="Subscriptions"
                rightStyleClass="fk-prime-right-header-container"
                />
                {Divider()}
                <div className="mobile-buy-coins-modal-body-container">
                    {
                        buyCoinsStep === 1 ?
                        <>
                            <div className="w-100 flex flex-d-column flex-justify-center flex-align-center">
                                <p className="get-more-coins-label-p">{`Get More Coins`}</p>
                                <p className="save-more-coins-label-p">{`Save more with bigger bundles`}</p>
                            </div>
                            <div className="flex flex-d-row flex-justify-between fk-prime-buy-current-coins-container">
                                <p className="buy-coins-current-coins-label-p">{`Current Coins`}</p>
                                <div className="flex flex-d-row flex-align-center">
                                    <CoinsIcon />
                                    <p className="buy-coins-current-coins-credit-p">{wallet}</p>
                                </div>
                            </div>
                            <div className="buy-coins-options-choices-main-container">
                                {
                                    buyCoinsOffer.map((offer, key) => {
                                        return (
                                            key+1 === buyCoinsOffer.length ?
                                            <LargeCoinsContainer icon={<CoinsBundleMostWithBestDealLabel />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/> :
                                            <SmallCoinsContainer icon={ key+1 === 2 ? <CoinsBundleMore /> : <CoinsBundleLess />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/>
                                        )
                                    })
                                }
                            </div> 
                        </> : 
                        <>
                            <div className="flex flex-d-column flex-align-center flex-justify-center mt-34">
                                <p className="get-more-coins-label-p">{`Select Payment Method`}</p>
                            </div>
                            <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-32">
                                <div className="flex flex-d-row flex-align-center">
                                    <input type="radio" value="paypal" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'paypal' ? true : false} onChange={() => handleBuyCoinsPay('paypal')}/>
                                    <p>{`Paypal`}</p>
                                </div>
                                <PaypalIcon />
                            </div>
                            <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-22 mb-30">
                                <div className="flex flex-d-row flex-align-center">
                                    <input type="radio" value="stripe" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'stripe' ? true : false} onChange={() => handleBuyCoinsPay('stripe')}/>
                                    <p>{`Stripe`}</p>
                                </div>
                                <CreditCardsIcon />
                            </div>
                            {Divider()}
                            <div className="flex flex-d-row w-100 flex-justify-center flex-align-center mt-30">
                                <p className="get-more-coins-label-p">{`Purchase`}</p>
                            </div>
                            {PurchaseArrMapper(selectedArr)}
                            <div className=" individual-ads-main-total-container">
                                <p className="individual-ads-main-total-label-p">{`Total`}</p>
                                <div className="flex flex-d-row flex-justify-end flex-align-center">
                                    <p className="buy-coins-main-currency-p">{`S$`}</p> &nbsp;
                                    <p className="buy-coins-main-total-int-p">{offerSelected.total}</p>
                                </div>
                            </div>
                        </>
                    }
                   
                </div>
                    {ModalFooter()}
                    <MobileFooter boost={offerSelected.total} removeBuyCoinsBtn={true} customButton={buyCoinsStep !== 1 && offerSelected.payment_method === 'paypal' ? <div className="mobile-fk-prime-purchase-paypal-container-btn"><PaypalButton /> </div> : buyCoinsStep !== 1 && offerSelected.payment_method === 'stripe' ?  <div className="mobile-fk-prime-purchase-paypal-container-btn"><StripeButton/> </div> : 
                                <div className={ !hasSelected || buyCoinsStep !== 1 ? `opacity-p-6 fk-prime-purchase-btn` : `fk-prime-purchase-btn`} onClick={() => hasSelected && buyCoinsStep === 1 ? handleBuyCoinsStepsAction('+') : null}>
                                    <p className="fk-prime-purchase-p">{`Pay`}</p>
                                </div>}/>
                {/* {
                    buyCoinsStep === 1 ? 
                } */}
        </div>
    )
}

export const Modal = ({ offer, handleBuyCoins, offerSelected, handleBuyCoinsStepsAction, buyCoinsStep, wallet, total, setBuyCoinsModal, handleBuyCoinsPay}) => {
    const buyCoinsOffer = offer ? offer : []
    const selectedArr = offerSelected && offerSelected.coinsArr ? offerSelected.coinsArr : []
    const hasSelected = offerSelected.coinsArr.length !== 0 ? true : false
    return (
        <div className={`fk-prime-buy-coins-modal-container`}>
            <div className="fk-prime-buy-coins-header-container">
                {
                    buyCoinsStep === 1 ?
                    <div className="w-100 flex flex-justify-end cursor-pointer" onClick={() => setBuyCoinsModal(false)}>
                        <CloseIcon />
                    </div> : 
                    <div className="w-100 flex flex-justify-between">
                        <div className="cursor-pointer" onClick={() => handleBuyCoinsStepsAction('-')}>
                            <BackMobileButton />
                        </div>
                        <div className="cursor-pointer" onClick={() => setBuyCoinsModal(false)}>
                        <CloseIcon />
                    </div>
                    </div>
                }
                {
                    buyCoinsStep === 1 ?
                    <>
                        <div className="flex flex-d-column flex-align-center flex-justify-center mt-34">
                            <p className="get-more-coins-label-p">{`Get More Coins`}</p>
                            <p className="save-more-coins-label-p">{`Save more with bigger bundles`}</p>
                        </div>
                        <div className="flex flex-d-row flex-justify-between fk-prime-buy-current-coins-container">
                            <p className="buy-coins-current-coins-label-p">{`Current Coins`}</p>
                            <div className="flex flex-d-row flex-align-center">
                                <CoinsIcon />
                                <p className="buy-coins-current-coins-credit-p">{wallet}</p>
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="flex flex-d-column flex-align-center flex-justify-center mt-34">
                            <p className="get-more-coins-label-p">{`Select Payment Method`}</p>
                        </div>
                        <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-32">
                            <div className="flex flex-d-row flex-align-center">
                                <input type="radio" value="paypal" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'paypal' ? true : false} onChange={() => handleBuyCoinsPay('paypal')}/>
                                <p>{`Paypal`}</p>
                            </div>
                            <PaypalIcon />
                        </div>
                        <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-22 mb-30">
                            <div className="flex flex-d-row flex-align-center">
                                <input type="radio" value="stripe" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'stripe' ? true : false} onChange={() => handleBuyCoinsPay('stripe')}/>
                                <p>{`Stripe`}</p>
                            </div>
                            <CreditCardsIcon />
                        </div>
                        {Divider()}
                        <div className="flex flex-d-row w-100 flex-justify-center flex-align-center mt-30">
                            <p className="get-more-coins-label-p">{`Purchase`}</p>
                        </div>
                        {PurchaseArrMapper(selectedArr)}
                        <div className=" individual-ads-main-total-container">
                            <p className="individual-ads-main-total-label-p">{`Total`}</p>
                            <div className="flex flex-d-row flex-justify-end flex-align-center">
                                <p className="buy-coins-main-currency-p">{`S$`}</p> &nbsp;
                                <p className="buy-coins-main-total-int-p">{offerSelected.total}</p>
                            </div>
                        </div>
                    </>
                }
                
            </div>
           {
               buyCoinsStep === 1 ?
               <div className="buy-coins-options-choices-main-container">
               {
                   buyCoinsOffer.map((offer, key) => {
                       return (
                           key+1 === buyCoinsOffer.length ?
                           <LargeCoinsContainer icon={<CoinsBundleMostWithBestDealLabel />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/> :
                           <SmallCoinsContainer icon={ key+1 === 2 ? <CoinsBundleMore /> : <CoinsBundleLess />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/>
                       )
                   })
               }
           </div> : null
           }
            {/* <div className={boostTotal !== 0 || isFree && freeClaim.claimFreeClicked ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={() =>  isFree ? handleClaim() : boostTotal !== 0 ? handlePurchaseModal() : null}> */}
            <div className={`flex flex-justify-center flex-align-center mt-30 pay-container-main-buy-coins`}>
                {
                    offerSelected.payment_method === 'paypal' && buyCoinsStep !== 1 ? <PaypalButton /> : offerSelected.payment_method === 'stripe' && buyCoinsStep !== 1 ? <StripeButton /> :
                    <div className={ buyCoinsStep === 1 ? hasSelected ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn` : offerSelected.payment_method ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn`} onClick={() => hasSelected && buyCoinsStep === 1 ? handleBuyCoinsStepsAction('+') : null}>
                        <p className="fk-prime-purchase-p">{buyCoinsStep === 1 ? `Purchase` : `Pay`}</p>
                    </div>
                }
            </div>
            {ModalFooter()}
        </div>
    )
}

export const SubscriptionUpgradeModal = () => {
    const {toggleSubscriptionData, has_active_subscription} = useContext(FkPrimeContext)
    const payment_method = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.payment_method ? has_active_subscription.purchase_micro_service.payment_method : null

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
        <div className="cancel-modal-body-container mt-30">
        {
            <>
                <div className="border-bottom">
                    <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                        <div />
                        <div>
                            <Text 
                                styleClass="w-100 flex flex-justify-center flex-align-center"
                                text="Click to upgrade."
                                textClass="fk-prime-purchase-modal-title-p"
                            />
                        </div>
                        <div className="">
                            <IconContainerClickable 
                                styleClass="cursor-pointer"
                                clickFunc={() => toggleSubscriptionData(null, false)}
                                Icon={<CloseIcon/>}
                            />
                        </div>
                    </div>
                </div>
                <div className='h-292 flex flex-d-row flex-justify-center cancel-sub-button-container'>
                    {payment_method === 'stripe' ? <StripeUpgradeButton /> : <PaypalUpgradeButton />}
                </div>
                <div className="cancel-subscription-footer-container ">
                    <Text
                        styleClass=""
                        text="I accept and agree to the"
                        textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                    />
                    <LinkText
                        path="/terms-of-use"
                        text="Terms of Services."
                        textClass="fk-prime-purchase-modal-footer-link-p"
                    />
                </div>
            </>
            }
        </div>
    </div>
    )
}

export default {
    BoostCards, 
    FKPrimeText, 
    PartPillGroupBtn, 
    ButtonWithIcon, 
    ManyNotes, 
    OtpBoostDetails, 
    Text, 
    RadioWithText, 
    IconContainerClickable, 
    Divider, 
    PriceTextWithSymbol, 
    Button, 
    LinkText, 
    MobileHeader,
    ActionText,
    MobileCard,
    IndividualBoostOfferCard,
    IndividualAdsTitleSubtitleContainer,
    MobileFooter,
    Modal,
    MobileIndividualBoostOfferCard,
    PaymentSuccess,
    MobileModalBuyCoins,
    PaymentUnsuccessful,
    SubscriptionUpgradeModal
}