import React from 'react'
import {useHistory} from 'react-router-dom'
import {SuccessSVGIcon, BackIcon, GreaterThanIcon} from '../../../../../assets/svg'

export const MobileHeader = (props) => {
    const {details} = props
    const leftLabel = details && details.leftLabel ? details.leftLabel : ''
    const centerLabel = details && details.centerLabel ? details.centerLabel : ''
    const rightLabel = details && details.rightLabel ? details.rightLabel : ''
    const rightAction = details && details.rightAction ? details.rightAction : ''
    const leftBackIcon = props && props.leftBackIcon ? true : false
    let history = useHistory()
    return (
        <div className="w-100 flex flex-d-row edit-user-mobile-header-container">
            <div onClick={() => history.push('/')} className="flex flex-d-row flex-align-center">
                {leftBackIcon ? <div className="mr-6"><BackIcon /></div> : null}
                <p className="edit-user-mobile-left-p">{leftLabel}</p>
            </div>
            <div className="w-100 flex flex-justify-center">
                <p className="edit-user-mobile-center-p">{centerLabel}</p>
            </div>
            <div onClick={(e) => rightAction(e)} className="w-px-40">
                <p className="edit-user-mobile-right-p">{rightLabel}</p>
            </div>
        </div>
    )
}

export const SuccessfulUpdatedUI = () => {
    return (
        <div className={`h-100 w-100 flex flex-d-column flex-align-center ${window.innerWidth < 600 ? 'pt-160' : 'pt-180'}`}>
            <p className="successful-ui-text-label-p mb-25">{`Your profile is now updated`}</p>
            <SuccessSVGIcon />
        </div>
    )
}

export const SettingsAndPrivacy = (props) => {
    let history = useHistory()
    const hideSettingsAndPrivacy = props && props.hideSettingsAndPrivacyFunc ? props.hideSettingsAndPrivacyFunc : null
    return (
        <div className="h-100 w-100">
            <div className="profile-settings-mobile-main-container">
                <div className="profile-settings-mobile-sub-header-container">
                    <p>{`Profile Settings`}</p>
                </div>
                <div className="profile-settings-links-main-container">
                    <div className="flex flex-d-row flex-align-center flex-justify-between profile-settings-links-container" onClick={(e) => hideSettingsAndPrivacy(e)}>
                        <div className="flex flex-d-column">
                            <p className="profile-settings-main-label-p">{`Edit Account`}</p>
                            <p className="profile-settings-sub-label-p">{`View or Change personal information`}</p>
                        </div>
                        <GreaterThanIcon />
                    </div>
                    <div className="flex flex-justify-between flex-align-center profile-settings-links-container" onClick={() => history.push('/user/change-password')}>
                        <p className="profile-settings-main-label-p">{`Change Password`}</p>
                        <GreaterThanIcon />
                    </div>
                </div>
                <div className="fk-links-main-container">
                    <div className="profile-settings-mobile-sub-header-container">
                        <p>{`Fishingkaki`}</p>
                    </div>
                    <div className="profile-settings-links-main-container">
                        <div className="flex flex-justify-between flex-align-center profile-settings-links-container" onClick={() => history.push('/terms-of-use')}>
                            <p className="profile-settings-main-label-p">{`Terms of Use`}</p>
                            <GreaterThanIcon />
                        </div>
                        <div className="flex flex-justify-between flex-align-center profile-settings-links-container" onClick={() => history.push('/privacy-policy')}>
                            <p className="profile-settings-main-label-p">{`Privacy Policy`}</p>
                            <GreaterThanIcon />
                        </div>
                        <div className="flex flex-justify-between flex-align-center profile-settings-links-container" onClick={() => history.push('/about-us')}>
                            <p className="profile-settings-main-label-p">{`About Fishingkaki`}</p>
                            <GreaterThanIcon />
                        </div>
                        <div className="flex flex-justify-between flex-align-center profile-settings-links-container" onClick={() => history.push('/contact-us')}>
                            <p className="profile-settings-main-label-p">{`Contact Us`}</p>
                            <GreaterThanIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default {MobileHeader, SuccessfulUpdatedUI, SettingsAndPrivacy}