import { FILTER } from '../constant'
    const initialState = {
        recentPriceFilter: 'recent',
        price: {minPrice: 0, maxPrice: 1000000}
    }

    export default (state = initialState, action) => {
        switch(action.type) {
            case FILTER.SET_RECENT_PRICE:
            return {...state, recentPriceFilter: action.payload.class, price:{minPrice: action.payload.minPrice, maxPrice: action.payload.maxPrice}}

            case FILTER.RESET:
            return initialState    

            default:
            return state;
        }
    } 