import React, { useReducer, useState, useEffect } from 'react';
import { userLoad, userReset, forgotPasswordLoad, forgotPasswordClearMessage, sessionLoad } from '../../../redux/action'
import { connect } from 'react-redux';
import { LoginContext } from '../../../common/context'
import { LoginForm, ForgotPasswordForm } from './component/CustomComponent'
import {ScrollToTop} from '../../../common/component/customComponents'
import { gapi } from 'gapi-script';

const { REACT_APP_ENV, REACT_APP_GOOGLE_CLIENT_ID} = process.env;

const Login = props => {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    { userEmail: '', userPassword: '', captcha: '', inputError: ''}
  );

  const isAuthenticated = props && props.session && props.session.fkv2_session ? props.session.fkv2_session : false
  const { isLoading, error,  } = props.userData
  const [peek, setPeek] = useState(false)
  // const [activeClass, setActiveClass] = useState('normal-input-border')
  const [classType, setClassType] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSetPeek = () => {
    setPeek(!peek)
  }

  const handleChange = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({[name]: newValue});
  }

  const captchaChange = value => {
    setUserInput({['captcha']: value})
    if(userInput.inputError !== ''){
      setUserInput({['inputError']: ''})
    }
  }

  const handleInputClick = (index) => {
    setClassType(index)
    // setActiveClass('active-input-border')
  }

  const handleSubmit = evt => {
    evt.preventDefault();
    if (REACT_APP_ENV === 'local') {
      props.userLoad(userInput)
    } else {
      if(userInput.captcha !== ''){
        props.userLoad(userInput)
      }else{
        alert("complete the input field")
        setUserInput({['inputError']: 'Recaptcha Error'})
      } 
    } 
  }

  //hooks to clear error
  useEffect(() => {
    if (error) {
        setTimeout(() => {
        props.userReset()
      }, 3000)
    }
  // eslint-disable-next-line
  }, [props.userData.error])

  useEffect(()=>{
    function start() {
      gapi.client.init({
        clientId: REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  },[])

  //toggle login & forgot
  const toggleForgot = () => {
    if (forgotShow) {
      props.forgotPasswordClearMessage()
      setEmailInput({['email']: ''});
    }
    setForgotShow(!forgotShow)
  }

  const parseError = (error) => {
    let errorArray = []
    if (typeof error === 'string') {
      errorArray.push(<p key={1} className="error">{error}</p>)
    } else {
      for (var key in error) {
        if (error.hasOwnProperty(key)) {
            errorArray.push(<p key={key} className="error">{error[key].toString().replace('input.', '')}</p>)
        }
      }
    }
    return errorArray
  }

  const responseFacebook = (response) => {
    if (response && response.email) {
      props.userLoad(response)
    } 
    else {
      console.log('fb login closed.');
    }
  }

  //forgot password state & function
  const {forgotPasswordIsLoading, forgotPasswordData, forgotPasswordError } = props.user
  const [forgotShow, setForgotShow] = useState(false)
  const [forgotClick, setForgotClick] = useState(false)
  const [emailInput, setEmailInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    { email: '' }
  );

  const handleEmailChange = event => {
    const name = event.target.name;
    const newValue = event.target.value;
    setEmailInput({[name]: newValue});
  }

  const handleForgotSubmit = evt => {
    evt.preventDefault();
    props.forgotPasswordLoad(emailInput)
  }

  //hooks to clear error / message
  useEffect(() => {
      if (forgotPasswordError) {
        setTimeout(() => {
        props.forgotPasswordClearMessage()
        setForgotShow(!forgotShow)
        }, 3000)
      }
      // if (forgotPasswordData) {
      //   setTimeout(() => {
      //   props.forgotPasswordClearMessage()
      //   // setForgotShow(!forgotShow)
      //   }, 3000)
      // }
  // eslint-disable-next-line
  }, [props.user.forgotPasswordError, props.user.forgotPasswordData])
  
  const handleFPClick = () => {
    setForgotClick(true)
  }
  //end forgot password state & function

  const responseGoogle = (response) => {
    if(response && response.profileObj && response.profileObj.email){
      let googleData = {}
      googleData.email = response.profileObj.email
      googleData.graphDomain = 'google'
      googleData.id = response.profileObj.googleId ? response.profileObj.googleId : 0
      googleData.name = response.profileObj.name ? response.profileObj.name : ''
      googleData.picture = {data: {height: 50, width: 50, is_silhouette: false, url: response.profileObj.imageUrl ? response.profileObj.imageUrl : ''}}
      props.userLoad(googleData)
    }
  }
  
  
  return (
    <ScrollToTop>
      <LoginContext.Provider value={{
        isAuthenticated, isLoading, handleSubmit, userInput, handleInputClick, peek, handleSetPeek, 
        captchaChange, error, parseError, toggleForgot, responseFacebook, handleChange,
        forgotPasswordIsLoading, handleForgotSubmit, forgotPasswordData, emailInput, 
        forgotPasswordError, forgotClick, handleFPClick, handleEmailChange, responseGoogle
      }}>
        {forgotShow ? <ForgotPasswordForm /> : <LoginForm />}
      </LoginContext.Provider>
    </ScrollToTop>
  );
};

const mapStateToProps = store => {
  return {
    userData: store.authReducer,
    user: store.userReducer,
  };
};

const mapActionToProps = {
   userLoad,
   userReset,
   forgotPasswordLoad,
   forgotPasswordClearMessage,
   sessionLoad
}

export default connect(mapStateToProps,mapActionToProps)(Login);
