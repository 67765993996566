import React, { useEffect } from 'react';
import { meLoad } from '../../../redux/action'
import { connect } from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'
import UserMain from './main'

const UserProfile = props => {

  const sessionId = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
  const { isLoading, data } = props.me
  const {location} = props
  const fromDrawerClick = location && location.fromDrawerClick ? location.fromDrawerClick : false

  const use_location = useLocation()
  const pathname = use_location.pathname
  const urlSliced = pathname.slice('6')
  const userId = urlSliced === 'listing' || urlSliced === 'reviews' || urlSliced === 'wallet' ? sessionId : urlSliced.replace(/(listing)|(reviews)|(wallet)/g, '')

  useEffect(() => {
      if(userId){
          props.meLoad({id: userId})
      }
  },[userId, pathname])

  return (
         !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
           <UserMain data={data} sessionId={sessionId} isLoading={isLoading} fromDrawerClick={fromDrawerClick}/>
  );
}

const mapStateToProps = store => {
  return {
    me: store.userReducer,
  };
};

const mapActionToProps = {
  meLoad
};

export default withRouter(connect(mapStateToProps,mapActionToProps)(UserProfile));