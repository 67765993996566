import React from 'react';

const AdminLayout = ( { component: Component, type, session, ...rest } ) => {
    return (
        <React.Fragment>
            <Component {...rest} session={session}/>
        </React.Fragment>
    )
};

export default AdminLayout;
