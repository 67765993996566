import React from 'react';
import { theme } from '../../../../common/theme'

const PurchaseHistory = props => {

  const data = theme.text.sampleTransaction
  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
       <div className="table">
         <div>
          <h1>Purchase History 1</h1>
         </div>
         <div className="desktop">
         {/* <div className="tableContent">
                <div>
                  <div className="tableHead">
                  <p>Transaction ID</p>
                  </div>
                  <div className="contents">
                {data.map((purchase, index) => {
                    return <div key={index}>
                      {purchase.transactionID === '' ? 'n/a' : purchase.transactionID}
                      </div>
                  })}
                </div>
                </div>
                  <div>
                    <div className="tableHead">
                    <p>Type</p>
                    </div>
                    <div className="contents">
                  {data.map((purchase, index) => {
                    return <div key={index}>
                      {purchase.type}
                      </div>
                  })}
                  </div>                 
                </div>
                <div>
                  <div className="tableHead">
                    <p>Status</p>
                  </div>
                  <div className="contents">
                  {data.map((purchase, index) => {
                    return <div key={index}>
                        {purchase.status}
                      </div>
                  })}
                  </div>
                </div>
                <div>
                  <div className="tableHead">
                    <p>Date</p>
                  </div>
                  <div className="contents">
                  {data.map((purchase, index) => {
                    return <div key={index}>
                      {purchase.date}
                    </div>
                  })}
                  </div>
                </div>
              </div>          */}
              <div>

              </div>
         </div>
              
       </div>
  );
}

export default PurchaseHistory;