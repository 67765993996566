import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  session, component, layout: Layout, ...rest
}) => (
  <Route
  {...rest}
  render={routeProps => 
  session && session.fkv2_session && session.fkv2_session.type && session.fkv2_session.type === 'admin' ? <Layout {...routeProps} component={component} session={session}/>
  : <Redirect to="/admin" />}
/>
);

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
};

export default ProtectedRoute;
