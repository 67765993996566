import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { AdminCustomFieldContext } from '../../../../../common/context'
import { adminCustomFieldLoad, adminUpsertCustomFieldLoad, adminUpsertCustomFieldReset, adminDeleteCustomFieldLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { CustomFieldList, UpsertModal } from './component/CustomComponent'


const CustomFieldSection = (props) => {
    const pageCount = 10
    const result_limit = 20
    const defaultInput = {id: "", name: "", fk_i_category_id: "", custom_index: "",  label: "", placeholder: "", type:"" }

    const { 
        adminCustomFieldIsLoading, adminCustomFieldData, adminCustomFieldError, adminCustomFieldPage, adminTotalCustomField,
        adminCustomFieldUpsertIsLoading, adminCustomFieldUpsertData, adminCustomFieldUpsertError, adminCustomFieldUpsertMessage,
        adminCustomFieldDeleteIsLoading, adminCustomFieldDeleteError, adminCustomFieldDeleteMessage
    } = props.admin

    useEffect(() => {
        if (adminCustomFieldData.length === 0) {
            props.adminCustomFieldLoad({first: 20, page: adminCustomFieldPage })
        }
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if (adminCustomFieldUpsertMessage) {
            setTimeout(() => {
                setIsShow(false)
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/custom_field`)
            }, 2000)
        }
        if (adminCustomFieldDeleteMessage) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/custom_field`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminCustomFieldUpsertMessage, adminCustomFieldDeleteMessage])

    const fetchCustomFieldDetail = (item) => {
        setIsShow(true)
        setIsInsert('Update')
        delete item['__typename']; 
        setCustomFieldDetailData(item)
    }

    const fetchCustomField = () => {
        setIsShow(true)
        setIsInsert('Insert')
        setCustomFieldDetailData(defaultInput)
    }

    const closeItemModal = () => {
        setIsShow(false)
    }

    //sale modal
    const [isShow, setIsShow] = useState(false)
    const [isInsert, setIsInsert] = useState('Insert')
    const [customFieldDetailData, setCustomFieldDetailData] = useState({})

    const handlePageClick = data => {
        props.adminCustomFieldLoad({first: 20, page: data.selected + 1})
    };

    const upsertItem = (data, queryType) => {
        props.adminUpsertCustomFieldLoad(data = {...data, queryType: queryType})
    }

    const deleteItem = (item) => {
        const alert = window.confirm(`Are you sure you want to delete ${item.name}`); if(alert === true){ 
            props.adminDeleteCustomFieldLoad(item)
        }
    }

    return (
        <AdminCustomFieldContext.Provider value={{
            adminCustomFieldIsLoading, adminCustomFieldData, adminCustomFieldError, defaultInput,
            isShow, closeItemModal, customFieldDetailData, isInsert, upsertItem, fetchCustomFieldDetail,
            adminCustomFieldUpsertIsLoading, adminCustomFieldUpsertData, adminCustomFieldUpsertError, adminCustomFieldUpsertMessage,
            // preferenceDetailData, upsertItem, adminPreferenceUpsertIsLoading, adminPreferenceUpsertError, adminPreferenceUpsertMessage, isInsert,
            deleteItem
        }}>
        <section className="page-content">
            <h3 className='text-align-center'>Custom Field</h3>
            <section>
            {adminCustomFieldIsLoading || adminCustomFieldDeleteIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminCustomFieldError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminCustomFieldError}</p></div></article> :
                    adminCustomFieldDeleteError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminCustomFieldDeleteError}</p></div></article> :
                    adminCustomFieldDeleteMessage ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'green'}}>{adminCustomFieldDeleteMessage}</p></div></article> :
                    <article className='direction-column'>
                        <div className='flex-button-container pointer'>
                            <div onClick={() => fetchCustomField()} className="sellButton admin-sell-button">
                                <span>Add Custom Field</span>
                            </div>
                        </div>
                        <CustomFieldList />
                    </article>
            }
            <div></div> 
            {adminCustomFieldError ? null :
                adminCustomFieldData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalCustomField}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {isShow ? <UpsertModal /> : null }
        </AdminCustomFieldContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminCustomFieldLoad,
    adminUpsertCustomFieldLoad, 
    adminUpsertCustomFieldReset,
    adminDeleteCustomFieldLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(CustomFieldSection);