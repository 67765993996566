import React from 'react'
import MobileFooter from './mobile'
import WebFooter from './web'

const CustomFooter = () => {
    return (
        <div className="w-100">
            {
                window.innerWidth < 600 ? 
                <MobileFooter /> : <WebFooter />
            }
        </div>
    )
}

export default CustomFooter