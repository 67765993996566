import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import FKImage from '../../../../common/component/image'
import Loader from '../../../../common/component/loader'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import SocialAvatar from '../../../../common/component/socialAvatar'
import {CoinsIcon, MiniCheckIcon, MiniItemIcon, WhatsappIcon, WorldIcon, LockMiniNotActive, CartIconWhite} from '../../../../assets/svg'
import {DividerLine} from './customComponent'

const MobileProfileContainer = (data) => {
  const session_id = data && data.session && data.session.fkv2_session && data.session.fkv2_session.id ? data.session.fkv2_session.id : 0
  const user_id = data && data.data && data.data.pk_i_id ? data.data.pk_i_id : 0
  const isThisTheLoggedUser = session_id === user_id ? true : false
  const username = data && data.data && data.data.s_username ? data.data.s_username : null
  const email = data && data.data && data.data.s_email ? data.data.s_email : null
  const mobile = data && data.data && data.data.s_phone_mobile ? data.data.s_phone_mobile : null
  const img_resource = data && data.data && data.data.oc_t_user_resource ? data.data.oc_t_user_resource : []
  const reg_date = data && data.data && data.data.dt_reg_date ? moment(data.data.dt_reg_date).format('MMMM DD, YYYY') : ''
  const bio = data && data.data && data.data.bio ? data.data.bio : null
  const user_social_id =  data && data.data && data.data.social && data.data.social[0] && data.data.social[0].data && data.data.social[0].data.id ? data.data.social[0].data.id : 0
  const items_count = data && data.data && data.data.items_count ? data.data.items_count : 0
  const sold_items = data && data.data && data.data.sold_items ? data.data.sold_items : []
  const social_avatar = data && data.data && data.data.social && data.data.social[0] && data.data.social[0].data && data.data.social[0].data.picture && data.data.social[0].data.picture.data && data.data.social[0].data.picture.data.url ? data.data.social[0].data.picture.data.url : null

  return (
    <div className="p-relative mobile-profile-user-details-main-container">
      <div className="flex flex-d-row ">
        <div className="flex flex-d-column">
          <p className="profile-member-since-dt-p">{`Member since:`}</p>
          <p className="profile-member-since-dt-p">{reg_date}</p>
        </div>
        <div className="flex flex-d-column p-relative">
          <div className="profile-avatar">
              { 
                user_social_id ? <SocialAvatar id={user_social_id} w={110} h={110} url={social_avatar} /> :
                img_resource.length !== 0 ?  <FKImage images={[img_resource]} type="avatar_preview" alt="seller avatar"/> : 
                 <img src={DefaultPhoto} width={110} height={110} alt="Default Photo Avatar" />
              }
            </div>
        </div>
      </div>
      <div className="mt-32 w-100 flex flex-d-column flex-align-center flex-justify-center">
            <p>{`@${username}`}</p>
          </div>
          {
            isThisTheLoggedUser ?
            <div className="w-100 flex flex-d-column flex-align-center flex-justify-center">
                <p>{email}</p>
                {
                  mobile ? 
                  <div className="flex flex-d-row ">
                    <div className="mt-5">
                      <WhatsappIcon />
                    </div>
                    <div className="flex flex-d-column">
                      <div className="flex flex-d-row flex-align-center">
                        <p className="ml-6 mr-10">{mobile}</p>
                        <LockMiniNotActive />
                      </div>
                      <p className="profile-whatsapp-disclaimer-p">{`Only you can see this.`}</p>
                    </div> 
                  </div> : null
                }
            </div> : null
          }
         { 
          bio ? <div className="mobile-profile-bio-main-container">
            <p className="profile-bio-main-label-p">{`Bio:`}</p>
            <p className="profile-bio-text-p">{bio}</p>
          </div> : null}
          <div className="profile-mobile-user-details-container">
            {/* <div className="flex flex-d-column text-align-center">
              <div className="flex flex-d-column ">
                <p className="mobile-profile-count-title-p">{`0`}</p>
                <p className="mobile-profile-under-label-p">{`Followers`}</p>
              </div>
              <div className="flex flex-d-column mt-16">
                <p className="mobile-profile-count-title-p">{`0`}</p>
                <p className="mobile-profile-under-label-p">{`Following`}</p>
              </div>
            </div> */}
            <div className="flex flex-d-column flex-justify-center flex-align-center">
                <p className="mobile-profile-count-title-p">{sold_items.length}</p>
                <p className="mobile-profile-under-label-p">{`Sold Items`}</p>
              </div>
            <div className="flex flex-justify-center flex-align-center m-0-auto">
              <DividerLine />
            </div>
            <div className="flex flex-d-column text-align-center">
              {/* <div className="flex flex-d-column mt-16">
                <p className="mobile-profile-count-title-p">{sold_items.length}</p>
                <p className="mobile-profile-under-label-p">{`Sold Items`}</p>
              </div> */}
               <div className="flex flex-d-column flex-justify-center flex-align-center">
                <p className="mobile-profile-count-title-p">{items_count}</p>
                <p className="mobile-profile-under-label-p">{`Listings`}</p>
              </div>
            </div>
          </div>
          {
            isThisTheLoggedUser ?
            <Link to={`/user/settings`} className="mt-20 mobile-edit-profile-btn">
            <p>{`Edit Profile`}</p>
          </Link> : null
          }
    </div>
  )
}

const ProfileBar = props => {
  const session_id = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
  const user_id = props && props.data && props.data.pk_i_id ? props.data.pk_i_id : 0
  const isThisTheLoggedUser = parseInt(session_id) === parseInt(user_id) ? true : false
  const avatarIsLoading = props && props.avatarIsLoading ? props.avatarIsLoading : false
  const userDetails = props && props.data ? props.data : null
  const username = userDetails && userDetails.s_username ? userDetails.s_username : null
  const email = userDetails && userDetails.s_email ? userDetails.s_email : null
  const profile_resource = userDetails && userDetails.oc_t_user_resource ? userDetails.oc_t_user_resource  : []
  const user_social_id = userDetails && userDetails.social && userDetails.social[0] && userDetails.social[0].data && userDetails.social[0].data.id ? userDetails.social[0].data.id : 0
  const wallet_count = props && props.data && props.data.wallet ? props.data.wallet : 0
  const item_count = props && props.data && props.data.items_count ? props.data.items_count : ''
  const reg_date = props && props.data && props.data.dt_reg_date ? moment(props.data.dt_reg_date).format('MMMM DD, YYYY') : ''
  const mobile_number = props && props.data && props.data.s_phone_mobile ? props.data.s_phone_mobile : ''
  const bio = props && props.data && props.data.bio ? props.data.bio : ''
  const isPhonePublic = props && props.data && props.data.is_phone_public ? props.data.is_phone_public : false
  const soldItemsArr = props && props.data && props.data.sold_items ? props.data.sold_items : []
  // console.log("profile bar props", props)

  const social_avatar = userDetails && userDetails.social && userDetails.social[0] && userDetails.social[0].data && userDetails.social[0].data.picture && userDetails.social[0].data.picture.data && userDetails.social[0].data.picture.data.url ? userDetails.social[0].data.picture.data.url : null


    return (
        <div className={`my-profile-container ${ window.innerWidth > 600 ? bio && mobile_number  ? ``:  !bio ? !mobile_number ? `h-px-130` : `h-px-175` : `` : ``}`}>
          {
            avatarIsLoading ?
            <Loader />
            :
            window.innerWidth < 600 ? MobileProfileContainer(props) :
            <div className="w-100 seller-profile-dashboard-main-container">
              <div className="seller-profile-dashboard-left-component-container">
                <div className="flex flex-justify-start flex-align-center mb-30 ">
                    <p className="profile-member-since-p">{reg_date ? `Member since: ${reg_date}` : ''}</p>
                  </div>
                  <div className="flex flex-d-row flex-align-center">
                    <div className="flex flex-d-column flex-align-center flex-justify-center mr-30">
                      <div className="flex flex-d-row flex-align-center mb-6">
                        <MiniCheckIcon />
                        <p className="ml-7">{soldItemsArr.length}</p>
                      </div>
                      <div className="flex flex-align-center flex-justify-center">
                        <p className="profile-user-details-label-p">{`Sold Items`}</p>
                      </div>
                    </div>
                    <DividerLine />
                    {
                      item_count ? 
                      <div className="flex flex-d-column flex-align-center flex-justify-center ml-30">
                      <div className="flex flex-d-row flex-align-center flex-justify-center mb-6">
                        <MiniItemIcon />
                        <p>{item_count}</p>
                      </div>
                      <div className="flex flex-align-center flex-justify-center">
                        <p className="profile-user-details-label-p">{`Listings`}</p>
                      </div>
                    </div> : null
                    }
                    
                  </div>

              </div>
               <div className="my-profile-info">
                <div className="profile-avatar">
                  { user_social_id ? <SocialAvatar id={user_social_id} w={110} h={110} url={social_avatar} /> :
                    profile_resource ?  <FKImage images={[profile_resource]} type="avatar_preview" alt="seller avatar"/> : 
                    <img src={DefaultPhoto} width={110} height={110} alt="Default Photo Avatar" />
                  }
                </div>
                <div className="profile-info">
                  <h2 style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{username}</h2>
                  {/* <p>{`Singapore`}</p> */}
                 {
                   isThisTheLoggedUser ? <p>{email}</p> : null
                 } 
                    
                    {
                      isThisTheLoggedUser ?
                      mobile_number ? 
                      <div className="flex flex-d-row ">
                        <div className="mt-5">
                        <WhatsappIcon />
                        </div>
                        <div className="flex flex-d-column">
                          <div className="flex flex-d-row flex-align-center">
                            <p className="ml-6 mr-10">{mobile_number}</p>
                           { isPhonePublic ? <WorldIcon /> : <LockMiniNotActive />} 
                          </div>
                          {
                            isPhonePublic ? null :
                            <p className="profile-whatsapp-disclaimer-p">{`Only you can see this.`}</p>
                          }
                        </div> 
                    </div> : null : isPhonePublic ? 
                    <div className="flex flex-d-row ">
                    <div className="mt-5">
                    <WhatsappIcon />
                    </div>
                    <div className="flex flex-d-column">
                      <div className="flex flex-d-row flex-align-center">
                        <p className="ml-6 mr-10">{mobile_number}</p>
                        <WorldIcon />
                      </div>
                      {/* <p className="profile-whatsapp-disclaimer-p">{`Only you can see this.`}</p> */}
                    </div> 
                </div>  : null
                    }
                </div>
              </div>
              <div className="flex flex-d-column seller-profile-dashboard-right-component-container">
                 
                  {
                 isThisTheLoggedUser ? <div className="flex flex-d-row flex-justify-end flex-align-center mb-25">
                  <CoinsIcon />
                 <p className="profile-coins-int-p ml-6"><span className="profile-coins-int-number-p">{`${wallet_count} `}</span> {`coins`}</p>
                </div> : null
                }
                {
                  isThisTheLoggedUser ?
                  <div className="flex flex-justify-end flex-align-center w-100">
                    <Link to={{pathname: `/user/wallet`, isBuyCoinsClick: true}} className="profile-main-buy-coins-btn">
                      <CartIconWhite />
                      <p>{`Buy Coins`}</p>
                    </Link> 
                  </div> : null
                }
                  {/* <div className="flex flex-d-row flex-justify-end flex-align-center">
                    <div className="flex flex-d-column flex-justify-center flex-align-center mr-30 mb-6">
                      <div>
                        <p>{`0`}</p>
                      </div>
                      <div>
                        <p className="profile-user-details-label-p">{`Followers`}</p>
                      </div>
                    </div>
                    <DividerLine />
                    <div className="flex flex-d-column flex-justify-center flex-align-center ml-30 mb-6">
                      <div>
                        <p>{`0`}</p>
                      </div>
                      <div>
                        <p className="profile-user-details-label-p">{`Following`}</p>
                      </div>
                    </div>
                  </div> */}
              </div>
              {bio ? <div className="w-100 profile-user-details-bio-container">
                <div className="flex flex-d-column flex-justify-center flex-align-center w-100">
                  <div className="profile-user-details-bio-sub-container">
                    <p className="profile-bio-main-label-p">Bio:</p>
                    <p className="profile-bio-text-p">{bio}</p>
                  </div>
                </div>
              </div> : null}
            </div>

          }
        </div>
    );
}

export default ProfileBar