import React, { useContext } from 'react';
import { ServiceContext } from '../../../../../../../common/context';
import Loader from '../../../../../../../common/component/loader'
import { labelSwitch, renderStatusOptions } from '../../../../../../../common/helper/utility'

export const ItemServiceList = () => {
    const value = useContext(ServiceContext);
    const { adminServiceData, adminServiceIsLoading, adminServiceError, handleEdit, handleDelete } = value

    const handleDeleteAlert = (item) => {
        const alert = window.confirm(`Are you sure you want to delete ${item.name}`);
         if(alert === true){ 
            handleDelete(item.id)
        }
    }

    return (
        <div className='main-container'>
            <div></div>
                {
                    adminServiceData === 0 ? <p style={{textAlign: 'center'}}>No item found</p> : 
                    adminServiceIsLoading ?  <div className="message-container"><Loader /></div> : 
                    adminServiceError ? <div className="message-container"><p className='error-message'>{adminServiceError}</p></div> :  
                    <div className='purchase-container'>
                        <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Id</th><th>Name</th><th>Description</th><th>Type</th><th>Credit</th><th>Discount</th><th colSpan={2} style={{textAlign: 'center'}}>Action</th>
                            </tr>
                            {adminServiceData.map((item, index) => {
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td>{item.type}</td>
                                        <td>{item.credit}</td>
                                        <td>{item.discount}</td>
                                        <td className='text-align-center pointer' onClick={()=> handleEdit(item)}><button className={'action-button'} ><i className="fas fa-edit"/></button></td>
                                        <td className='text-align-center pointer' onClick={() => handleDeleteAlert(item)}><button className={'action-button'} ><i className="fas fa-trash-alt"/></button></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const ItemServiceModal = () => {
    const value = useContext(ServiceContext);

    const { isShow, closeModal, upsertService, 
        adminServiceUpsertIsLoading, adminServiceUpsertError, adminServiceUpsertMessage,
        textInput, setTextInput } = value

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setTextInput({
            [name]: newValue
        });
    }

    const renderInputs = () => {
        const data = textInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (k === 'id') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input  type="number" name={k} value={data[k]} onChange={handleChange} disabled={k === 'id' ? true : false}/>
                        </span>
                    )
                }
                else if (k === 'sg_price') {
                    void(0)
                }
                else if (k === 'days') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input  type="number" name={k} value={data[k]} onChange={handleChange}/>
                        </span>
                    )
                }
                else if (k === 'description') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <textarea className = 'description_textarea' type="text" onChange={handleChange} name={k} value={data[k]} rows="2" cols="50"></textarea>
                        </span>
                    )
                }
                else if (k === 'status') {
                    arr.push(
                        <div key={k} style={{marginBottom: 10, fontSize: 16}}>
                            <div><label><b>{labelSwitch(k)}</b></label></div>
                            <select name={k} value={data[k]} onChange={handleChange}>
                                {renderStatusOptions(data[k])}
                            </select>
                        </div>
                    )
                } else {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input  type="text" onChange={handleChange} name={k} value={data[k]}/>
                        </span>
                    )
                }
            }
        }
        return arr
    }

    const parseError = (object) => {
        let errorArray = []
        if (object === 'Not authorized.') {
            errorArray.push(<p key={key} className='error-message'>{object}</p>)
        } else {
            for (var key in object) {
                if (object.hasOwnProperty(key)) {
                    errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
                }
            } 
        }
        return errorArray
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeModal()}>&times;</span>
                {
                    adminServiceUpsertIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                    adminServiceUpsertMessage ? <div className="message-container"><p className='success-message'>{adminServiceUpsertMessage}</p></div> :  
                        <div className="container">
                            <form onSubmit={(event) => upsertService(event, textInput)} autoComplete="off">
                            {renderInputs()}
                            { adminServiceUpsertError ? <div>{parseError(adminServiceUpsertError)}</div>  : null }
                            <input type="submit" value={textInput.id != "" ? 'Update Item Service' : 'Add Item Service'} className="sellButton pointer" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>
                            </form>
                        </div>
                }    
                </div>
        </div>
    )
}
