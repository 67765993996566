import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { AdminPromoContext } from '../../../../../common/context'
import { adminPromoLoad, adminUpsertPromoLoad, adminDeletePromoLoad } from '../../../../../redux/action'
import { PromoList, PromoModal } from './component/CustomComponent'

const PromoSection = (props) => {
    const pageCount = 10
    const result_limit = 20
    const defaultInput = {name: "", description: "", type: "", amount: "", duration: ""}

    const { 
        adminPromoData, adminPromoIsLoading, adminPromoError, adminPromoPage, adminTotalPromo,
        adminPromoUpsertIsLoading, adminPromoUpsertError, adminPromoUpsertMessage,
        adminPromoDeleteIsLoading, adminPromoDeleteError, adminPromoDeleteMessage
    } = props.admin

    useEffect(() => {
        if (adminPromoData.length === 0) {
            props.adminPromoLoad({first: 20, page: adminPromoPage})
        }
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if (adminPromoUpsertMessage) {
            setTimeout(() => {
                setIsShow(false)
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/promo`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminPromoUpsertMessage])

    useEffect(()=>{
        if (adminPromoDeleteMessage) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/promo`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminPromoDeleteMessage])

    const fetchPreferenceDetail = (item) => {
        setIsShow(true)
        setIsInsert('Update')
        setPromoDetailData(item)
    }

    const fetchPreferenceDefault = () => {
        setIsShow(true)
        setIsInsert('Insert')
        setPromoDetailData(defaultInput)
    }

    const closeItemModal = () => {
        setIsShow(false)
    }

    //promo modal
    const [isShow, setIsShow] = useState(false)
    const [isInsert, setIsInsert] = useState('Insert')
    const [promoDetailData, setPromoDetailData] = useState({})
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)


    //item search
    // const [isSearching, setIsSearching] = useState(false)

    // const searchOnchange = (event) => {
    //     const text = event.target.value
    //     const limit = 2
    //     if(text.length === 0){
    //         setIsSearching(false)
    //     }else if(text.length <= limit){
    //         setIsSearching(true)
    //     } else {
    //         setIsSearching(true)
    //         props.adminPreferenceSuggestLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "s_section", orderType: "ASC"})
    //     }
    // }

    // const searchSubmit = event => {
    //     event.preventDefault();
    //     setIsSearching(false)
    //     setIsShowSearchResult(true)
    // }

    const handlePageClick = data => {
        props.adminPreferenceLoad({first: 20, page: data.selected + 1})
    };

    const upsertItem = (data, type) => {
        props.adminUpsertPromoLoad(data = {...data, upsert_type: type})
    }

    const deleteItem = (item) => {
        const alert = window.confirm(`Are you sure you want to delete ${item.name}`); if(alert === true){
            if (item && item.id) {
                props.adminDeletePromoLoad(item.id)
            }
        }
    }

    return (
        <AdminPromoContext.Provider value={{
            adminPromoData, fetchPreferenceDetail, adminPromoIsLoading, 
            isShow, closeItemModal, promoDetailData, upsertItem, 
            adminPromoUpsertIsLoading, adminPromoUpsertError, adminPromoUpsertMessage,
            adminPromoDeleteIsLoading, adminPromoDeleteError, adminPromoDeleteMessage,
            isInsert,
            deleteItem, 
            // setIsShowSearchResult, isShowSearchResult
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Promo</h3>
            <section>
            {adminPromoIsLoading || adminPromoUpsertIsLoading || adminPromoDeleteIsLoading
            ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}>Loading...</article> : 
                adminPromoError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminPromoError}</p></div></article> :
                    adminPromoDeleteMessage ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'green'}}>{adminPromoDeleteMessage}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div onClick={() => fetchPreferenceDefault()} className="sellButton pointer" style={{margin: 10, height: 50, alignItems: 'center', alignSelf: 'flex-start'}}>
                                <span>Add Promo</span>
                            </div>
                            {/* <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><PromoSuggestData /></div>}
                            </div> */}
                        </div>
                        <PromoList />
                    </article>
            }
            <div></div> 
            {adminPromoError ? null :
                adminPromoData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalPromo}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {isShow ? <PromoModal /> : null }
        {/* {isShowSearchResult ? <PromoSearchResult /> : null} */}
        </AdminPromoContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminPromoLoad,
    adminUpsertPromoLoad,
    adminDeletePromoLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(PromoSection);