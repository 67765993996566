import { createContext } from 'react'

export const AdminUserContext = createContext()
export const AdminItemContext = createContext()
export const AdminSaleContext = createContext()
export const AdminPreferenceContext = createContext()
export const AdminCategoryContext = createContext()
export const PromoteContext = createContext()
export const ItemContext = createContext()
export const ItemImageContext = createContext()
export const ReportContext = createContext()
export const AdministratorContext = createContext()
export const AdminDashboardContext = createContext()
export const LoginContext = createContext()
export const ChatContext = createContext()
export const ItemViewContext = createContext()
export const AdminCustomFieldContext = createContext()
export const BanTextContext = createContext()
export const BanEmailContext = createContext()
export const ServiceContext = createContext()
export const FkPrimeContext = createContext()
export const ListingContext = createContext()
export const ProfileSubscriptionContext = createContext()
export const ItemNewContext = createContext()
export const AdminSubscriptionContext = createContext()
export const AdminLogContext = createContext()
export const GlobalContext = createContext()
export const HomeContext = createContext()
export const AdminPromoContext = createContext()
