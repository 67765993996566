import { useEffect } from 'react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js'
import { chatAddMessage, chatAddRoom, itemLoad, getChatRoomByIdLoad, homeItemSetReserve, homeItemSetSold, itemSetReserve, itemSetSold,
  itemSetHighlight, homeItemSetHighlight, homeItemSetTopAd, setChatItemReserved, setChatItemSold, itemSetWatcher, homeItemSetWatcher, itemUpdateRealtimeUpdate, itemUserRealtimeUpdate, clearUncollectedPromo, realtimeUserWalletUpdate
} from '../../../redux/action'

const { REACT_APP_PUSHER_KEY, REACT_APP_PUSHER_CLUSTER, REACT_APP_PUSHER_CHAT_CHANNEL, REACT_APP_WEB_SEC } = process.env;

const PusherComponent = (props) => {
  const user_id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? parseInt(props.session.fkv2_session.id) : null

  useEffect(() => {
    channelSubscribe()
  // eslint-disable-next-line      
  },[])

  const channelSubscribe = () => {
        const pusher = new Pusher(REACT_APP_PUSHER_KEY, {
          cluster: REACT_APP_PUSHER_CLUSTER,
          forceTLS: true,
          disableStats: true,
        });
        Pusher.logToConsole = false;
  
        const channel = pusher.subscribe(REACT_APP_PUSHER_CHAT_CHANNEL);
        channel.bind('chat', function(message) {
          const data = message && message.chat && message.chat.data ? message.chat.data : {}
          const owner_id = message && message.chat && message.chat.chat_room && message.chat.chat_room.owner_id ? parseInt(message.chat.chat_room.owner_id) : ''
          const guest_id = message && message.chat && message.chat.chat_room && message.chat.chat_room.guest_id ? parseInt(message.chat.chat_room.guest_id) : ''
          const type = message && message.chat && message.chat.type ? message.chat.type : ''
          const chat_room_id = message && message.chat && message.chat.chat_room && message.chat.chat_room.id || null

          const last_message = message && message.chat && message.chat.chat_room && message.chat.chat_room.last_message || null
          const item_id = message && message.chat && message.chat.chat_room && message.chat.chat_room.item_id || null
          const splitMessage = last_message.split(REACT_APP_WEB_SEC)
          const offer_type = splitMessage && splitMessage[0] ? splitMessage[0] : null

          //handle chat
          if (user_id === owner_id || user_id === guest_id) {
            chat_type_switch(type, data, chat_room_id, user_id )
          }

          //handle item update
          if (offer_type) {
            offer_type_switch(offer_type,item_id, chat_room_id)
          }       
        });

        channel.bind('promote', function(promote) {
          const promoteType = promote.promote && promote.promote.type ? promote.promote.type : null
          const promoteItemId = promote.promote && promote.promote.data && promote.promote.data.pk_i_id ? promote.promote.data.pk_i_id : null
          const promoteItemHighlightExpiry = promote.promote && promote.promote.data && promote.promote.data.hl_expiration ? promote.promote.data.hl_expiration : null
          const promoteItemTopAdExpiry = promote.promote && promote.promote.data && promote.promote.data.ta_expiration ? promote.promote.data.ta_expiration : null

          //handle promote
          if (promoteType) {
            promote_type_switch(promoteType,promoteItemId, promoteItemHighlightExpiry, promoteItemTopAdExpiry)
          }
        })

        channel.bind('like', function(item) {
          if (user_id) {
            const item_id = item && item.like && item.like.item_id ? item.like.item_id : null
            const watcher = item && item.like && item && item.like.watcher ? item.like.watcher : []
            props.itemSetWatcher({item_id: item_id, watcher: watcher})
            props.homeItemSetWatcher({item_id: item_id, watcher: watcher})
          }
        })

        channel.bind('item', function(item) {
          const item_id = item && item.item && item.item.item_id ? item.item.item_id : null
          const field_value = item && item.item && item.item.field_value ? item.item.field_value : null
          props.itemUpdateRealtimeUpdate(item)
          props.homeItemSetSold({b_mark_as_sold: field_value, soldItemId: item_id})
          props.itemUserRealtimeUpdate(item)
          if (user_id) {
            props.setChatItemSold({b_mark_as_sold: field_value, id: item_id, chat_room_id: null})
          } 
        })

        channel.bind('promo_collected', function(item) {
          const promoUserId = item && item.promo && item.promo.user_id ? parseInt(item.promo.user_id) : null
          const updatedUserWallet = item && item.promo && item.promo.updated_wallet && parseInt(item.promo.updated_wallet) !== 0 ? parseInt(item.promo.updated_wallet) : null

          if (user_id && user_id === promoUserId) {
            setTimeout(() => {
              props.clearUncollectedPromo(item)
              if (updatedUserWallet) {
                props.realtimeUserWalletUpdate({promoUserId, updatedUserWallet})
              }
            },3000)
          }
        })

        return () => pusher.unsubscribe
  }

  //update chat
  const chat_type_switch = (type, data, chat_room_id, user_id) => {
      switch (type) {
        case 'chatmessage':
          props.chatAddMessage(data)
          break;
        case 'chatroom':
          props.getChatRoomByIdLoad({id: chat_room_id, user_id: user_id})
          break;
        case 'chatoffer':
          props.getChatRoomByIdLoad({id: chat_room_id, user_id: user_id})
          break;
        case 'chatreview':
          props.getChatRoomByIdLoad({id: chat_room_id, user_id: user_id})
          break;
      
        default:
          break;
      }
  }

  //update items
  const offer_type_switch = (type, item_id, chat_room_id) => { 
    switch (type) {
        case 'accept_offer':
            props.homeItemSetSold({b_mark_as_sold: true, soldItemId: item_id})
            props.itemSetSold({b_mark_as_sold: true, id: item_id})
            if (user_id) {
              //handled item update to seller with multiple chats
              props.setChatItemSold({b_mark_as_sold: true, id: item_id, chat_room_id: chat_room_id})
            } 
            break 
        // case 'mark_as_sold':
        //     props.homeItemSetSold({b_mark_as_sold: true, soldItemId: item_id})
        //     props.itemSetSold({b_mark_as_sold: true, id: item_id})
        //     if (user_id) {
        //       //handled item update to seller with multiple chats
        //       props.setChatItemSold({b_mark_as_sold: true, id: item_id})
        //     }            
        // break
        // case 'unreserved':
        //       //handled item update to seller with multiple chats
        //     props.homeItemSetReserve({b_reserved: false, id: item_id})
        //     props.itemSetReserve({b_reserved: false, id: item_id})
        //     if (user_id) {
        //       props.setChatItemReserved({b_reserved: false, id: item_id})
        //     }
        //     break
        // case 'reserved':
        // case 'mark_as_unsold':
        //     props.homeItemSetSold({b_mark_as_sold: false, soldItemId: item_id})
        //     props.itemSetSold({b_mark_as_sold: false, id: item_id})
        //     if (user_id) {
        //       props.setChatItemSold({b_mark_as_sold: false, id: item_id})
        //     }            
        //     break
        default:
        break;
    }   
  }

  const promote_type_switch = (promoteType,promoteItemId, promoteItemHighlightExpiry, promoteItemTopAdExpiry) => { 
    switch (promoteType) {
        case 'top_ad':
            props.homeItemSetTopAd({ta_expiration: promoteItemTopAdExpiry, promoteItemTopAdId: promoteItemId})
            break
        case 'highlight':
            props.homeItemSetHighlight({hl_expiration: promoteItemHighlightExpiry, promoteItemId: promoteItemId})
            break
        default:
            break;
    }   
  }
  return null
}



const mapStateToProps = store => {
  return {
    chat: store.chatReducer,
  };
};

const mapActionToProps = {
  chatAddMessage,
  chatAddRoom,
  itemLoad,
  getChatRoomByIdLoad,
  homeItemSetReserve,
  homeItemSetSold,
  itemSetReserve, 
  itemSetSold,
  itemSetHighlight,
  homeItemSetHighlight,
  homeItemSetTopAd,
  setChatItemReserved, 
  setChatItemSold,
  itemSetWatcher,
  homeItemSetWatcher,
  itemUpdateRealtimeUpdate,
  itemUserRealtimeUpdate,
  clearUncollectedPromo,
  realtimeUserWalletUpdate
};

export default connect(mapStateToProps, mapActionToProps)(PusherComponent);
