import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { searchWordLoad, setRecentPriceFilter, servicesLoad, promoteLoad, promoteReset, watchlistLikeDislikeLoad, searchWordReset, resetPriceFilter, searchWordMoreLoad, addTopSearch } from '../../../redux/action'
import SearchItemCard from './item'
import Filter from './filter'
import DefaultMeta from '../../../common/component/defaultMeta'
import GoogleAds from '../../../common/component/googleAds'

const Search = (props) => {
    const location = useLocation()
    const getPath = location.pathname
    const keywords= getPath.split('/search/').join('')
    const keywordsType = 'item'
    const searchwords = keywords.slice(5)
    const searchword = searchwords.replace(/_/g, " ")
    const searchResult = props && props.search && props.search.data ? props.search.data : []
    const { isLoading, total, pages, isLoadingMore } = props.search
    const userId = props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const session = props.session
    const priceFilter = props.filter && props.filter.recentPriceFilter ? props.filter.recentPriceFilter : null
    const minPriceFilter = props.filter && props.filter.price && props.filter.price.minPrice ? props.filter.price.minPrice : 0
    const maxPriceFilter = props.filter && props.filter.price && props.filter.price.maxPrice ? props.filter.price.maxPrice : 1000000
    const likeDislikeLoading = props.watchlist && props.watchlist.isLoading ? props.watchlist.isLoading : false
    const hasMore = searchResult.length < total -1 ? true : false
    const getCount = 20
    
    useEffect(() => {
        return () => cleanUp()
    }, []);

    useEffect(() => {
            props.searchWordLoad({first: getCount, page: 1, key: searchword, type: keywordsType, filter: priceFilter, minPrice: minPriceFilter, maxPrice: maxPriceFilter})
    // eslint-disable-next-line  
    },[searchword])

    const cleanUp = () => {
        props.resetPriceFilter()
        props.searchWordReset()
    }

    useEffect(() => {
        if (pages > 1) {
            props.searchWordReset()
            props.searchWordLoad({first: getCount, page: 1, key: searchword, type: keywordsType, filter: priceFilter, minPrice: minPriceFilter, maxPrice: maxPriceFilter})
        }
        else {
            props.searchWordLoad({first: getCount, page: 1, key: searchword, type: keywordsType, filter: priceFilter, minPrice: minPriceFilter, maxPrice: maxPriceFilter})
        }
    // eslint-disable-next-line    
    },[props.filter])

    const handleLoadMore = () => {
        props.searchWordMoreLoad({first: getCount, page: pages, key: searchword, type: keywordsType, filter: priceFilter, minPrice: minPriceFilter, maxPrice: maxPriceFilter})
    }

    const getServices = (active) => {
        props.servicesLoad(active)
    }
  
    const submitPromote = (data) => {
      props.promoteLoad({fkv2_purchased_data: data, userId: userId})
    }
  
    const likeDislike = (item_id) => {
      props.watchlistLikeDislikeLoad({userId: userId, itemId: item_id})
    }

    const insertAds = (index) => {
        const actualIndex = index + 1
        const counter = window.innerWidth < 600 ? 10 : 20
        let element = null
        if (actualIndex % counter === 0) {
            element = 
            <div className={window.innerWidth < 600 ? 'grid-insert-1-3' : 'grid-insert-1-5'}>
              <GoogleAds />
            </div>
        }
        return element
    }

    

    const _addTopSearch = (id) => {
        props.addTopSearch(id)
    }

    return (
        <>
        {/* <DefaultMeta />  */}
        <Filter />
        <div className="search-result-main-container">
            <div className="search-result-container">
            {
                    <div className="search-word-container">
                        {searchResult.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <SearchItemCard key={index} searchword={searchword} item={item} session={session} 
                                        getServices={getServices} purchaseData={props.purchase} submitPromote={submitPromote}
                                        likeDislike={likeDislike}
                                        likeDislikeLoading={likeDislikeLoading} 
                                        _addTopSearch={_addTopSearch}
                                        />
                                        {insertAds(index)}
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
            }
            { isLoading || isLoadingMore ? <p style={{marginTop: 15, textAlign: 'center'}}>Loading...</p> :
                !isLoading && searchResult.length === 0 ? <p style={{marginTop: 15, textAlign: 'center'}}>No results</p> :
                window.outerWidth < 600 ? 
                <InfiniteScroll dataLength={searchResult.length} next={handleLoadMore} hasMore={hasMore} loader={<h4>Loading...</h4>} endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>End of result</b>
                    </p>
                }/> :
                searchResult.length < total -1 ? 
                    <div className="paginator-main-container"><div onClick={handleLoadMore} className="loadMoreBtn">{`Load More`}</div></div> : 
                    <p style={{marginTop: 15, textAlign: 'center'}}><b>End of result</b></p>                                    
            }
            </div>
        </div>
        </>
    );
}

const mapStateToProps = store => {
    return {
        search: store.searchReducer,
        filter: store.filterReducer,
        purchase: store.purchaseReducer,
        watchlist: store.watchlistReducer,
    }
}

const mapActionToProps = {
    searchWordLoad,
    setRecentPriceFilter,
    servicesLoad,
    promoteLoad,
    promoteReset,
    watchlistLikeDislikeLoad,
    searchWordReset,
    resetPriceFilter,
    searchWordMoreLoad,
    addTopSearch
}

export default connect(mapStateToProps,mapActionToProps)(Search)