import React from 'react';
import Loader from '../../../../common/component/loader'
import useWindowDimension from '../../../../common/helper/useWindowDimension'
import { theme } from '../../../../common/theme'

const PayNowInit = (props) => {
    const { height } = useWindowDimension();
    const state = props && props.history && props.history.location && props.history.location.state ? props.history.location.state : {}
    
    
    return (
        <div>
            <div style={style.itemContainer}>
            {
                true ? <Loader height={height}/> 
                : 
                    null
            }
            </div>
        </div>
    );
}

const style = {
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gridGap: '1rem',
      margin: '15px',
      fontFamily: theme.font.primary
    },
}

export default PayNowInit;