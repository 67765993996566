import React, { useContext, useReducer, useEffect } from 'react';
import { AdminLogContext } from '../../../../../../common/context';
import moment from 'moment';
import { labelSwitch, renderStatusOptions } from '../../../../../../common/helper/utility'

export const LogList = () => {
    const value = useContext(AdminLogContext);
    const { adminLogsData, fetchSaleDetail, gotoItem, gotoUser } = value

    return (
        <div className='main-container'>
            {adminLogsData === 0 ? <p style={{textAlign: 'center'}}>No sale found</p> : 
                <div className='purchase-container'>
                <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <th>Doer</th>
                                <th>Doer Role</th>
                                <th>Action</th>
                                <th>Affected Record</th>
                                <th>Data</th>
                            </tr>
                            {adminLogsData.map((item, index) => {
                                return (
                                    <tr className='tr-data-container' key={index} >
                                        <td>{item.dt_date}</td>
                                        <td>{item.doer}</td>
                                        <td>{item.s_who}</td>
                                        <td>{`${item.s_section} ${item.s_action}`}</td>
                                        <td>{item.fk_i_id}</td>
                                        <td>{item.s_data}</td>
                                        
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                </table>
                </div>
            }
        </div>
    );
}
