import React, { useContext, useReducer, useEffect } from 'react';
import { AdminPreferenceContext } from '../../../../../../common/context';
import { labelSwitch } from '../../../../../../common/helper/utility'
import { theme } from '../../../../../../common/theme'

export const PreferenceList = () => {
    const value = useContext(AdminPreferenceContext);
    const { adminPreferenceData, fetchPreferenceDetail, deleteItem } = value
    return (
        <div className='main-container'>
            <div></div>
                {adminPreferenceData.length === 0 ? <div style={{display: 'flex', minHeight: 200, justifyContent: 'center', alignItems: 'center'}}><p style={{textAlign: 'center'}}>No item found</p></div> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Section</th><th>Name</th><th>Value</th><th>Type</th><th>Delete</th>
                            </tr>
                            {adminPreferenceData.map((item, index) => {
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td >{item.s_section}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.s_name}</td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}><p className={'max-lines'}>{item.s_value}</p></td>
                                        <td className='pointer' onClick={()=> fetchPreferenceDetail(item)}>{item.e_type}</td>
                                        <td className='text-align-center pointer' onClick={()=> deleteItem(item)}><i className="fas fa-trash-alt"></i></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const PreferenceModal = () => {
    const value = useContext(AdminPreferenceContext);

    const { 
        isShow, closeItemModal, preferenceDetailData, upsertItem, adminPreferenceUpsertIsLoading, adminPreferenceUpsertError, adminPreferenceUpsertMessage, isInsert
    } = value
    const { text } = theme
    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    useEffect(() => {
        if (preferenceDetailData) {
            const data = preferenceDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    if (typeof data[k] === 'string' && k !== '__typename') {
                        input[k] = data[k]
                    } 
                    else if(typeof data[k] === 'boolean'){
                        input[k] = data[k]
                    }
                }
            }
            setItemInput(input)
        }
    },[preferenceDetailData])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue =  evt.target.value === 'true' ? true : evt.target.value === 'false' ? false :  evt.target.value;

        setItemInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
            }
        }
        return errorArray
    }

    const renderValueInput = (k, data) => {
        const type = itemInput && itemInput.e_type ? itemInput.e_type : null
        let element
        switch (type) {
            case 'STRING':
                element = <textarea className={ 'text-area'} onChange={handleChange} rows={15} type="text" name={k} value={data[k]} required/>
                break;
            case 'INTEGER':
                element = <input type="number" onChange={handleChange} name={k} value={data[k]} disabled={ isInsert ? false : true} required/>
                break;
        
            default:
                element = <input type="text" onChange={handleChange} name={k} value={data[k]} disabled={ isInsert === 'Insert' && k === 's_name' ? false : true} required/>
                break;
        }
        return element
    }

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if ((k === 's_value')) {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            {renderValueInput(k, data)}
                        </span>
                    )
                }
                else if(k === 'e_type') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <select id={k} name={k} className="category-select-container form-option" onChange={(event) => handleChange(event)} required disabled={ false } >
                                <option disabled selected>{`Select type`}</option>
                                {text.eType.map((item, index) => {
                                    let arr = []
                                    const typeName = item.label ? item.label : false
                                    const isSelected = itemInput.e_type === item.label ? true : false
                                    // const isSelected = false

                                    if (typeName) {
                                        arr.push(
                                        <option key={index} value={typeName} data-id={index} selected={isSelected} data-cat-name={typeName}>{`${typeName}`}</option>
                                        )
                                    }
                                    return arr
                                    })
                                }
                            </select>
                        </span>
                    )
                }
                else {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} disabled={ isInsert === 'Insert' && k === 's_name' ? false : true} required/>
                        </span>
                    )
                }
            }
        }
        return arr
    }
    
    const handleSubmit = (event, itemInput, type) => {
        event.preventDefault()
            upsertItem(itemInput, type)
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeItemModal()}>&times;</span>
                    {adminPreferenceUpsertIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                        adminPreferenceUpsertMessage ? <div className="message-container"><p className='success-message'>{adminPreferenceUpsertMessage}</p></div> :
                        <div className="container">
                            <form onSubmit={(event) => handleSubmit(event, itemInput, isInsert === 'Insert' ? 'INSERT' : 'UPDATE')}>
                            {renderInputs()}
                            { adminPreferenceUpsertError ? <div>{parseError(adminPreferenceUpsertError)}</div>  : null }
                            <input type="submit" value={`${isInsert === 'Insert' ? 'Add' : 'Update'} Preference`} className="sellButton pointer" style={{margin: '10px 0', alignSelf: 'flex-start'}}/>
                            </form>
                        </div>
                    }
                </div>
        </div>
    )
}

export const PreferenceSuggestData = () => {
    const value = useContext(AdminPreferenceContext);
    const { adminPreferenceSuggestIsLoading, adminPreferenceSuggestData, fetchPreferenceDetail } = value

    const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

    let arrHolder = []

    adminPreferenceSuggestData.length === 0 ? 
        arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
                        <p style={{padding: 10}}>No results</p>
                    </div>
        ) :
        adminPreferenceSuggestData.map((item, index) => {
        arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: '1px solid silver',
                            borderRight: '1px solid silver',
                            borderBottom:  (item.length -1) === index ? 0 : '1px solid silver',
                            backgroundColor: 'white',
                            height: 40
                        }}>
                            <button onClick={() => fetchPreferenceDetail(item)} style={{border: 'none'}}>{item.s_name}</button>
                        </div>
        )
        return null
    })
    return adminPreferenceSuggestIsLoading ? arrLoader : arrHolder
}

export const PreferenceSearchResult = () => {
    const value = useContext(AdminPreferenceContext);
    const { setIsShowSearchResult, isShowSearchResult, adminPreferenceSuggestData, fetchPreferenceDetail, deleteItem } = value

    const itemSelect = (item) => {
        setIsShowSearchResult(false)
        fetchPreferenceDetail(item)
    }

    const itemDelete = (item) => {
        setIsShowSearchResult(false)
        deleteItem(item)
    }

    return (
        <div className='data-modal-container' style={{display: isShowSearchResult ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close' onClick={() => setIsShowSearchResult(false)}>&times;</span>
                    <div className="modal-purchase-container">
                        <h2>Search Results</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Section</th><th>Name</th><th>Value</th><th>Type</th><th>Delete</th>
                                </tr>
                                {adminPreferenceSuggestData.map((item, index) => {
                                    return (
                                        <tr className='tr-data-container' key={index}>
                                            <td onClick={()=> itemSelect(item)}>{item.s_section}</td>
                                            <td onClick={()=> itemSelect(item)}>{item.s_name}</td>
                                            <td onClick={()=> itemSelect(item)}><p className={'max-lines'}>{item.s_value}</p></td>
                                            <td onClick={()=> itemSelect(item)}>{item.e_type}</td>
                                            <td onClick={()=> itemDelete(item)} style={{textAlign: 'center'}}><i className="fas fa-trash-alt"></i></td>
                                        </tr>
                                    )                        
                                })}
                            </tbody>    
                        </table>
                    </div>
            </div>
        </div>        
    )
}