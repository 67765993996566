import React, {useEffect} from 'react'
import MobileAbout from './mobile'
import {theme} from './theme'

const About = ({query}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const data = theme.about
   
    const termsBody = (p) => {
        let body
        body = <p className="content-sentence">{p}</p>
        return body
    }

    return (
        <>
        { window.innerWidth < 600 ? <MobileAbout data={theme} query={query}/> :
         <div className="content-main-container" style={{minHeight: '700px'}}>
         <div className="content-sub-container">
             <h1 className="content-main-title">{`ABOUT FISHINGKAKI`}</h1>
             <div className="content-body">
             <h1 className="content-last-update">{`ABOUT US`}</h1>
             {
                 data.map((terms, index) => {
                     const content = terms.text
                     return <div key={index}>
                         {termsBody(content)}
                         </div>
                 })
             }
         </div>
         </div>
         
        </div>
        }
       
        </>
    )
}

export default About