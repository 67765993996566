import React, {useContext} from 'react'
import { FkPrimeContext } from '../../../common/context'
import { IndividualBoostOfferCard, MobileIndividualBoostOfferCard, MobileFooter, Divider} from './customComponent'
import {GrayCoinsIcon, IndividualBoostCoinIcon, InfoIcon} from '../../../assets/svg'
import {IndividualAdsModal, IndividualAdsPurchaseModalMobile} from './individualAdsCustomComponent'
import CustomFooter from '../../../common/component/customComponents/footer'

const IndividualAds = () => {
    const {userBoost, boostOffer, handleIndividualAds, showIndividualAdsModal, handleShowIndividualAdsModal, handleBuyCoinsModal} = useContext(FkPrimeContext)
    const {individualAds} = boostOffer
    const {autoBump, baseAds, comboAds} = individualAds
    const {premium, highlight} = baseAds
    const {
        individualAdsTotal,
        combo_adsTotal,
        base_adsTotal,
        auto_bumpTotal,
        individualAdsIds
    } = userBoost

    
    return (
       <>
        {
            window.innerWidth < 600 ? 
            <>
            <div className="flex flex-d-column individual-ads-mobile-main-container">
                {
                    comboAds.length === 0 ? null :
                    <MobileIndividualBoostOfferCard boost={comboAds} total={combo_adsTotal} clickHandler={handleIndividualAds} title="Premium & Highlighted Ads" subtitle="Item is on top and highlighted." type="combo_ads" ids={individualAdsIds}/>
                }
                {
                    premium.length === 0 && highlight.length === 0 ? null :
                    <MobileIndividualBoostOfferCard boost={baseAds} total={base_adsTotal} clickHandler={handleIndividualAds} type="base_ads" multiBoost={true} boost1={premium} boost1Title="Premium" boost1Subtitle="Place item on top." boost2={highlight} boost2Title="Highlighted" boost2Subtitle="Highlight an item." ids={individualAdsIds}/>
                }
                {
                    autoBump.length === 0 ? null :
                    <MobileIndividualBoostOfferCard boost={autoBump} total={auto_bumpTotal} clickHandler={handleIndividualAds} title="Auto Bump Your Listing" subtitle="Bump when listing reach the 3rd page" type="auto_bump" ids={individualAdsIds}/>
                }
                {showIndividualAdsModal ? <IndividualAdsPurchaseModalMobile /> : null}
            </div>
            </> :
            <div className="w-100 flex flex-d-column flex-align-center">
                <div className="w-100 flex flex-d-row">
                    {
                        comboAds.length === 0 ? null :
                        <IndividualBoostOfferCard boost={comboAds} total={combo_adsTotal} clickHandler={handleIndividualAds} title="Premium & Highlighted Ads" subtitle="Item is on top and highlighted." type="combo_ads" ids={individualAdsIds}/>
                    }
                        <IndividualBoostOfferCard boost={baseAds} total={base_adsTotal} clickHandler={handleIndividualAds} type="base_ads" multiBoost={true} boost1={premium} boost1Title="Premium" boost1Subtitle="Place item on top." boost2={highlight} boost2Title="Highlighted" boost2Subtitle="Highlight an item." ids={individualAdsIds}/>
                    {
                        autoBump.length === 0 ? null :
                        <IndividualBoostOfferCard boost={autoBump} total={auto_bumpTotal} clickHandler={handleIndividualAds} title="Auto Bump Your Listing" subtitle="Bump when listing reach the 3rd page" type="auto_bump" ids={individualAdsIds}/>
                    }
                </div>
                <div className="mt-20 flex flex-d-row flex-align-center w-100">
                    <InfoIcon/>
                    <p className="to-see-all-purchases-label-p">{`*To see all purchases you may go to Profile > Subscription & Purchase`}</p>
                </div>
                <div className="mt-50 individual-ads-main-total-container">
                    <p className="individual-ads-main-total-label-p">{`Total`}</p>
                    <div className="flex flex-d-row flex-align-center">
                        {individualAdsTotal !== 0 ? <IndividualBoostCoinIcon /> : <GrayCoinsIcon />} &nbsp;
                        <p className={`individual-ads-total-number-p ${individualAdsTotal !== 0 ? `text-orange` : ``}`}>{individualAdsTotal}</p>
                    </div>
                </div>
                <div className="w-100 flex flex-align-center flex-justify-center mt-20">
                    <div className={individualAdsTotal !== 0 ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={() => handleShowIndividualAdsModal(true)} >
                        <p className="fk-prime-purchase-p">{`Purchase`}</p>
                    </div>
                </div>
            </div>
        }
        {window.innerWidth < 600 ?
        <> 
            <MobileFooter boost={individualAdsTotal} buyCoinsFunc={handleBuyCoinsModal} isCoinPurchase={true} handleFooter={handleShowIndividualAdsModal}/> 
            <Divider />
            <CustomFooter />
        </>: null}
        {showIndividualAdsModal ? <IndividualAdsModal/> : null}
       </>
    )
}

export default IndividualAds