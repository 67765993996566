import React, {useEffect} from 'react'
import {theme} from './theme'
import MobilePrivacyPolicy from './mobile'

const PrivacyPolicy = ({query}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const privacyBodyData = theme.privacyPolicy
    const privacyLastUpdate = theme.lastUpdate
    const lister = (l) => {
        let listing
        listing = l.map((list, index) => <li key={index}>{list.text}</li>)
        return listing
    }
    const termsBody = (p, index) => {
        let body
        body = <p className="content-sentence" key={`${index}`}>{p}</p>
        return body
    }
    return (
        <>
        {
            window.innerWidth < 600 ? <MobilePrivacyPolicy data={theme} query={query}/> :
            <div className="content-main-container">
            <div className="content-sub-container">
                <h1 className="content-main-title">{`PRIVACY POLICY`}</h1>
                <div className="content-body">
                <h1 className="content-last-update">{`Last updated: ${privacyLastUpdate}`}</h1>
                    {
                        privacyBodyData.map((terms, index) => {
                            const bodyContent = terms.body
                            const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, index) : <div><ul className="content-list">{lister(data.body)}</ul></div>)
                            return <div key={index}>
                                    <h1 className="content-title">{terms.title}</h1>
                                    <div>
                                    {bodyCheck}
                                    </div>
                                </div>
                        })
                    }
                </div>
            </div> 
            </div>
        }
       
    </>
    )
}

export default PrivacyPolicy