import React from 'react'
import { Link } from 'react-router-dom'
import SocialAvatar from '../../socialAvatar'
import FKImage from '../../../../common/component/image'
import DefaultAvatar from '../../../../assets/images/defaultAvatar.png'
import { slugify, categoryParser } from '../../../helper/utility'


const SearchData = (props) => {
    const { isLoading } = props.results ? props.results : false
    const results = props.results && props.results.data ? props.results.data : []
    const type = props.type ? props.type : null
    const searchSaveToLocal = props.searchSaveToLocal ? props.searchSaveToLocal : null
    
    return (
       <div className="bg-gray">
           {
                isLoading ? <p className="p-10-20 b-bottom-solid-gray-op5 ">{`Loading...`}</p> :
                results.length === 0 ? <div className="w-s-nowrap over-hide text-over-ellipsis">
                    <p className="p-10-20 over-hide text-over-ellipsis b-bottom-solid-gray-op5 ">{`No results found`}</p>
                </div> :
                type === 'item' ? 
                results.map((data, index) => {
                    const categoryId = data && data.oc_t_category && data.oc_t_category.pk_i_id ? data.oc_t_category.pk_i_id : 0
                    const category = data && data.oc_t_category && data.oc_t_category.oc_t_category_descriptions && data.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(data.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
                    const title = data && data.oc_t_item_description && data.oc_t_item_description.s_title ? data.oc_t_item_description.s_title : ''  
                    const itemSLug = slugify(title)
                    const id = data && data.pk_i_id ? data.pk_i_id : ''
                    const finalSLug = `/${category}/${itemSLug}_i${id}` 

                        return title === '' ? null : <Link to={finalSLug} onClick={() => searchSaveToLocal(id, categoryId)} key={index} className="w-s-nowrap over-hide text-over-ellipsis" >
                        <p className="over-hide text-over-ellipsis p-10-20 b-bottom-solid-gray-op5 ">{title}</p>
                    </Link>
                })
                :
                results.map((data, index) => {
                    const social_avatar = data && data.social && data.social[0] && data.social[0].data && data.social[0].data.picture && data.social[0].data.picture.data && data.social[0].data.picture.data.url ? data.social[0].data.picture.data.url : null
                    const name = data && data.s_username ? data.s_username : ''
                    const social_id = data && data.social && data.social[0] && data.social[0].data && data.social[0].data.id ? data.social[0].data.id : 0 
                    return name === '' ? null : <Link to={data.pk_i_id !== props.id ? `/user/${data.pk_i_id}/listing` : `/user/listing`} className="flex flex-d-row flex-justify-start flex-align-center w-s-nowrap over-hide text-over-ellipsis p-10-20" key={index}>
                       {
                           social_id ? <SocialAvatar id={social_id} url={social_avatar}/> :
                           data.oc_t_user_resource ?
                           <FKImage images={[data.oc_t_user_resource]} type="seller_avatar" />
                           :
                           <div className="flex flex-justify-center flex-align-center br-50p w-px-40 hpx-40 bg-dark-gray">
                               <img src={DefaultAvatar} width={40} height={40} />
                           </div>
                       } <p className="over-hide text-over-ellipsis p-0-10 w-px-150">{data.s_username}</p>
                    </Link>
                })
           }
       </div>
    );
}

export default (SearchData)