import React from 'react'

const SocialAvatar = (props) => {
    const id = props && props.id ? props.id : 0
    const w = props && props.w ? props.w : 40
    const h = props && props.h ? props.h : 40
    const url = props && props.url ? props.url : `https://graph.facebook.com/${id}/picture?type=large`

    return (
        <img src={url} width={w} height={h} style={{ borderRadius: '50%', display: 'flex', alignSelf: 'center'}} />
    )
}

export default SocialAvatar