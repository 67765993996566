import React, { useReducer, useEffect, useState, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { getLocalStorage } from '../../../common/helper/utility'
import { userLoad, newUserLoad } from '../../../redux/action'
import { theme } from '../../../common/theme'
import Loader from '../../../common/component/loader'
import Message from '../../../common/message'
import { SignupVerifySVG, GetItOnAppStore} from '../../../assets/svg'
import GooglePlay from '../../../assets/images/playstore.svg'
// import { styles } from './styles'
import RECAPTCHA from 'react-google-recaptcha'
import { gapi } from 'gapi-script';

const Signup = props => {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      // name: '',
      username: '',
      password: '',
      // rePassword: '',
      email: '',
      captcha: '',
      inputError: ''
    }
  );

  const [s_ip, set_s_ip] = useState(null)

  const fetchIp = async () => {
    await fetch('https://api.ipify.org/?format=json')
      .then(results => results.json())
      .then(data => set_s_ip(data.ip));
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if(!s_ip) {
      fetchIp()
    }
  }, [])

  const error_message = () => {
    alert('Inputted password does not match')
  }

  const { REACT_APP_FB_APP_ID, REACT_APP__BASE_URL, REACT_APP_ENV, REACT_APP_GOOGLE_CLIENT_ID} = process.env;

  const isAuthenticated = props && props.session && props.session.fkv2_session ? props.session.fkv2_session : false
  const { isLoading, message, error } = props.newUserData
  const activeInputClass = useRef(null)
  const inputUsername = useRef(null)
  const [classType, setClassType] = useState('')
  const [peek, setPeek] = useState(false)
  const [rePeek, setRePeek] = useState(false)
  const [activeClass, setActiveClass] = useState('normal-input-border')
  const signupMessage = theme.text.signup
  const signupLimit = theme.text.signupLength

  const handleClickOutside = event => {
    if (activeInputClass.current) {
      setActiveClass('normal-input-border')
    }
  }

  const handleChange = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;

    setUserInput({ [name]: newValue });
  }
  const captchaChange = value => {
    setUserInput({ ['captcha']: value })
    if (userInput.inputError !== '') {
      setUserInput({ ['inputError']: '' })
    }
  }

  const responseFacebook = (response) => {
    if (response && response.email) {
      setUserInput({ ['username']: response.name, ['email']: response.email })
      props.userLoad(response)

    }
    else {
      console.log('fb login closed.');
    }
  }

  useEffect(()=>{
    function start() {
      gapi.client.init({
        clientId: REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  },[])

  const responseGoogle = (response) => {
    if(response && response.profileObj && response.profileObj.email){
      let googleData = {}
      googleData.email = response.profileObj.email
      googleData.graphDomain = 'google'
      googleData.id = response.profileObj.googleId ? response.profileObj.googleId : 0
      googleData.name = response.profileObj.name ? response.profileObj.name : ''
      googleData.picture = {data: {height: 50, width: 50, is_silhouette: false, url: response.profileObj.imageUrl ? response.profileObj.imageUrl : ''}}
      props.userLoad(googleData)
    }
  }

  useEffect(() => {
    if (Object.keys(props.newUserData.data).length !== 0 && props.newUserData.data.constructor === Object) {
      const session = getLocalStorage('fkv2_session')
      if (session) {
        window.location.replace(REACT_APP__BASE_URL);
      }
    }
  }, [props.newUserData.data])

  const handleSubmit = evt => {
    evt.preventDefault();
    if (REACT_APP_ENV === 'local') {
      const finalIp = s_ip ? s_ip : 'unknown'
      const fileForSubmit = {...userInput, s_ip: finalIp }
      props.newUserLoad(fileForSubmit)
    }
    else {
      if(userInput.captcha !== ''){
        const finalIp = s_ip ? s_ip : 'unknown'
        const fileForSubmit = {...userInput, s_ip: finalIp }
        props.newUserLoad(fileForSubmit)
      }else{
        alert("complete the input field")
        setUserInput({['inputError']: 'Recaptcha Error'})
      }
    }
  }

  const handleInputClick = (index) => {
    // const name = evt.target.name
    setClassType(index)
    setActiveClass('active-input-border')
    inputUsername.current.readOnly = false
  }

  const handlePeekClick = () => {
    setPeek(!peek)
  }

  const handleRePeekClick = () => {
    setRePeek(!rePeek)
  }

  useEffect(() => {
    // document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      //   document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const parseError = (object) => {
    let errorArray = []
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        errorArray.push(<p key={key} style={{ color: 'red' }}>{object[key].toString().replace('data.', '')}</p>)
      }
    }
    return errorArray
  }

  if (isAuthenticated) {
    return <Redirect to='/' />
  } else {
    return (
      <div className="signup-main-container">
        <div className={`signup-container ${isLoading ? `flex` : ''}`}>
          {isLoading ? <Loader /> :
            message ? <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}><Message message={theme.text.message.signup[0].title} style="figmaDesign" /><SignupVerifySVG /> <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '21px', color: '#232A34' }}>{theme.text.message.signup[0].info}</p></div> :
              <div className="signup-sub-container">
                <div className="head-title">
                  <h2 style={{ color: '#5E6366', fontSize: '34px', lineHeight: '44px', letterSpacing: '0.25px', fontWeight: 'normal' }}>{`Create an account`}</h2>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                  {/* <div className="signup-form-input-main-container" >
                    <div className={`signup-form-input-sub-container ${classType === 'name' ? activeClass : `normal-input-border`}`} ref={activeInputClass}>
                      <div className="signup-form-input-container">
                       {userInput.name ? <label className="signup-form-label">{`Name`}</label> : null}
                        <input className="signup-form-main-input" name="name"  onClick={() => handleInputClick('name')} placeholder={userInput.name ? `` : `Enter name`} pattern="^[a-zA-Z ]*$" title="You inputted number" type="text" value={userInput.name} autocomplete="off" onChange={handleChange} required/>
                      </div>
                    </div>
                  </div> */}
                  <div className="signup-form-input-main-container" >
                    <div className={`signup-form-input-sub-container ${classType === 'username' ? activeClass : `normal-input-border`}`} ref={activeInputClass}>
                      <div className="signup-form-input-container">
                        {userInput.username ? <label className="signup-form-label">{`Display Name`}</label> : null}
                        <input className="signup-form-main-input" ref={inputUsername} autoComplete='off' name="username" onClick={() => handleInputClick('username')} type="text" placeholder={userInput.username ? `` : `Enter Display Name`} value={userInput.username} onChange={handleChange} required readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="signup-form-input-main-container">
                    <div className={`signup-form-input-sub-container ${classType === 'email' ? activeClass : `normal-input-border`}`}>
                      <div className="signup-form-input-container">
                        {userInput.email ? <label className="signup-form-label">{`Email`}</label> : null}
                        <input className="signup-form-main-input" autoComplete="new-email" name="email" onClick={() => handleInputClick('email')} placeholder={userInput.email ? `` : `Enter email`} type="email" value={userInput.email} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="signup-form-input-main-container">
                    <div className={`signup-form-input-sub-container ${classType === 'password' ? activeClass : `normal-input-border`}`}>
                      <div className="signup-form-input-container">
                        {userInput.password ? <label className="signup-form-label">{`Password`}</label> : null}
                        <input className="signup-form-main-input" autocomplete="nope" name="password" onClick={() => handleInputClick('password')} minLength="8" placeholder={userInput.password ? `` : `Enter password`} type={peek ? `text` : `password`} value={userInput.password} onChange={handleChange} required />
                        <i className={`far ${peek ? `fa-eye-slash` : `fa-eye`} peekBtn`} onClick={handlePeekClick} />
                      </div>
                    </div>
                    {/* {userInput.password ?
                      // <p className="signup-password-strong-message">{userInput.password.match(/[a-z]/g) !== null ? <del>{`Must at least one lowercase character. `}</del> : <ins>{`Must at least one lowercase character. `}</ins>}{userInput.password.match(/[A-Z]/g) !== null ? <del>{`Must at least one uppercase character. `}</del> : <ins>{`Must at least one uppercase character. `}</ins>}{userInput.password.length < 8 ? <ins>{`Must at least 8 characters. `}</ins> : <del>{`Must at least 8 characters. `}</del>}</p>  
                      <div className="signup-pw-message-container">
                        <p className="signup-password-strong-message">
                          {
                            signupMessage.map((index, key) => {
                              return (
                                userInput.password.match(index.regexp) !== null ?

                                  <del key={`${key}1`}>
                                    {index.label}
                                  </del> : <ins key={`${key}2`}>{index.label}</ins>
                              )

                            })
                          }
                          {userInput.password.length < 8 ? <ins>{signupLimit}</ins> : <del>{signupLimit}</del>}
                        </p>

                      </div>
                      : null} */}
                  </div>
                  {/* <div className="signup-form-input-main-container">
                    <div className={`signup-form-input-sub-container ${classType === 'rePassword' ? activeClass : `normal-input-border`}`}>
                      <div className="signup-form-input-container">
                        {userInput.rePassword ? <label className="signup-form-label">{`Re-enter Password`}</label> : null}
                        <input className="signup-form-main-input" name="rePassword" onClick={() => handleInputClick('rePassword')} placeholder={userInput.rePassword ? `` : `Re-enter password`} minLength="8" type={rePeek ? `text` : `password`} placeholder="Re-enter password" autocomplete="new-repassword" value={userInput.rePassword} onChange={handleChange} required />
                        <i className={`far ${rePeek ? `fa-eye-slash` : `fa-eye`} peekBtn`} onClick={handleRePeekClick}/>
                      </div>
                    </div>
                  </div> */}
                  <div className="signup-recaptcha">
                    <RECAPTCHA sitekey={theme.text.sitekey} onChange={captchaChange} />
                    {userInput.inputError ? <div className="flex"><p style={{ color: 'rgba(192, 57, 43,1.0)' }}>{userInput.inputError}</p></div> : null}
                  </div>
                  {error ? <div>
                    {parseError(error)}
                  </div>
                    : null
                  }
                   <input type="submit" value={`${REACT_APP_ENV === 'local' ? "Create Local Account" : "Create Account"}`} className={`${REACT_APP_ENV === 'local' ? `signup-btn` : 
                   userInput.username === '' || userInput.password === '' || userInput.email === '' || userInput.captcha === '' ? `default-signup-btn` : `signup-btn`}`}
                  />

                  {/* <input type="submit" value="Create Account" className={`${userInput.username === '' || userInput.password === '' || userInput.email === '' || userInput.captcha === '' ? `default-signup-btn` : `signup-btn`}`} /> */}
                </form>
                <p style={{ fontSize: 13, lineHeight: '19px', textAlign: 'center', letterSpacing: '0.25px', marginBottom: '20px', color: '#232A34' }}>
                  {/* <input type="checkbox" required/> */}
                  {theme.text.message.agreeTOS}
                  <Link to={'/terms-of-use'} style={{ color: '#FB770D' }}>{` Terms of use`}</Link>
                  {/* <a href={`${theme.text.forumLinks}tos.php`} style={{color: '#FB770D'}} target="_blank"> {`  Terms of Use `}</a> */}
                  {` and `}<Link to={'/privacy-policy'} style={{ color: '#FB770D' }}>{`Privacy policy`}</Link>
                </p>
                <p className="fb-top-label">{`Or connect with:`}</p>
                <div className='fb-btn-container'>
                    <GoogleLogin
                        clientId={REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        render={renderProps => (
                        <button onClick={renderProps.onClick} type="button" style={{width: '100%', backgroundColor: 'rgb(255, 255, 255)', display: 'inline-flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.54)', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px', padding: '0px', borderRadius: '2px', border: '1px solid transparent', fontSize: '14px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', justifyContent: 'center'}}>
                            <div style={{marginRight: '10px', background: 'rgb(255, 255, 255)', padding: '10px', borderRadius: '2px'}}>
                              <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
                                <g fill="#000" fillRule="evenodd">
                                  <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335" />
                                  <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4" />
                                  <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05" />
                                  <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853" />
                                  <path fill="none" d="M0 0h18v18H0z" />
                                </g>
                              </svg>
                            </div>
                            <span>Sign in with Google</span>
                          </button>
                        )}
                    />
                </div>
                <div className="fb-btn-container">
                  <FacebookLogin
                    appId={`${REACT_APP_FB_APP_ID}`}
                    fields="id,name,email,picture.height(961)"
                    callback={responseFacebook}
                    disableMobileRedirect={true}
                    render={renderProps => (
                      <button onClick={renderProps.onClick} className="fb-btn">
                        <i style={{ fontSize: 24, marginRight: 10 }} className="fab fa-facebook"></i> Sign in with Facebook
                      </button>
                    )}
                  />
                </div>
                {/* <div className="socialLinks"> */}
                    <div className="flex flex-d-row flex-align-center flex-justify-center">
                        <a href="https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549" className="flex flex-d-row flex-align-center mb-11" target="_blank">
                            <GetItOnAppStore />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mb-11 ml-12" target="_blank">
                            <img src={GooglePlay} width={128}/>
                            {/* <GetItOnPlayStore /> */}
                        </a>
                    </div>
                {/* </div> */}

              </div>

          }
          {
            isLoading ? null : message ? null : <div className="signup-bottom-container">
              <p className="new-user-signup-label">{`Already have an account `}<Link to={`/login`}>{` Login.`}</Link></p>
            </div>
          }

        </div>

      </div>

    );
  }
};

const mapStateToProps = store => {
  return {
    newUserData: store.authReducer,
  };
};

const mapActionToProps = {
  newUserLoad,
  userLoad
}

export default connect(mapStateToProps, mapActionToProps)(Signup);
