import { WATCHLIST } from '../constant'

export const watchlistLoad = (payload) =>{
    return {
        type: WATCHLIST.LOAD,
        payload
    }
}    

export const watchlistSuccess = (payload) =>{
    return {
        type: WATCHLIST.SUCCESS,
        payload,
    }
}

export const watchlistFailed = (payload) =>{
    return {
        type: WATCHLIST.FAILED,
        payload,
    }
}

export const watchlistLikeDislikeLoad = (payload) =>{
    return {
        type: WATCHLIST.LIKE_DISLIKE_LOAD,
        payload
    }
}    

export const watchlistLikeDislikeSuccess = (payload) =>{
    return {
        type: WATCHLIST.LIKE_DISLIKE_SUCCESS,
        payload,
    }
}

export const watchlistLikeDislikeFailed = (payload) =>{
    return {
        type: WATCHLIST.LIKE_DISLIKE_FAIL,
        payload,
    }
}