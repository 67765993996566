import React, { useContext, useEffect, useReducer, useState} from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import FKImage from '../../../../common/component/image'
import {ListingContext} from '../../../../common/context'
import { numberWithCommas, slugify, categoryParser, itemSatus } from '../../../../common/helper/utility'
import DefaultItem from '../../../../assets/images/commerce-and-shopping.png'
import {ThunderIcon, ReportIcon, BulletIcon} from '../../../../assets/svg'
import HomeItemTag from '../../../../common/component/tags/HomeItemTag'
import LikeButton from '../../../../common/component/like'
import {GAevent} from '../../../../common/googleAnalytics'
import {PremiumTag, NewTag, SoldTag} from '../../../../common/tags'
import {WTT, WTB} from '../../../../common/component/label'

const REACT_APP_IMGX = process.env.REACT_APP_IMGX || '';

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'topAd':
      return { ...state, isTopAd: true }
    case 'highlight':
      return { ...state, isHighlight: true }
    case 'toggleShow':
      return { ...state, isShow: !state.isShow }
    default:
      break;
  }
}

const ProfileListingCard = ({ item, style}) => {
  const {isSessionUser, updateIsLoading, submitItemUpdateByFieldLoad, setSelected, handleSetSelectedItem, likeDislike, sessionId, likeArr, setLikeArr, user_id, setReportInput, setReportShow, likeDislikeLoading, watchlistArr} = useContext(ListingContext)
  const [state, dispatch] = useReducer(itemReducer, { isTopAd: false, isHighlight: false, isShow: false })
  const item_title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
  const price = item && item.get_i_price ? numberWithCommas((item.get_i_price).toFixed(2)) : ''
  const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
  const reserved = item && item.b_reserved ? item.b_reserved : false
  const date_published = item && item.dt_pub_date ? item.dt_pub_date : null
  const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null 
  const { ta_expiration, hl_expiration } = item

  const isNew = item.is_new ? item.is_new : false
  const itemStatus = itemSatus(item.b_active, date_published)

  const messageClick = () => {
    if (sessionId) {
      GAevent("user", "message")
    }
  }

  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (ta_expiration) {
      const isTopAd = ta_expiration ? moment(ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({ type: 'topAd' })
      }
    }

    if (hl_expiration) {
      const isHighlight = hl_expiration ? moment(hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({ type: 'highlight' })
      }
    }
    // eslint-disable-next-line  
  }, [item, hl_expiration])

  // console.log("item card", item)
  const item_id = item.pk_i_id ? item.pk_i_id : 0
  const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
  const itemSLug = slugify(item_title)
  const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
  const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
  const finalSLug = `/${category}/${itemSLug}_i${item_id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`
  const fromNow_dt = item && item.dt_pub_date ? moment(item.dt_pub_date).fromNow() : ''
  const isABNotExpired = item && item.ab_expiration ? moment().isSameOrBefore(item.ab_expiration) : false
  const watchList = item && item.watchlist ? item.watchlist : []
  const found = watchlistArr && watchlistArr.some(el => parseInt(el.fk_i_item_id) === parseInt(item_id));
  const watchlist_count = item && item.watchlist_count ? item.watchlist_count : 0
  const watchIndex = watchlistArr ? watchlistArr.findIndex(item => parseInt(item.fk_i_item_id) === parseInt(item_id)) : 0
  const initial_watchlist_count = watchlistArr && watchlistArr[watchIndex] && watchlistArr[watchIndex].items && watchlistArr[watchIndex].items.watchlist_count ? watchlistArr[watchIndex].items.watchlist_count : null
  const post_type = item && item.post_type ? item.post_type : ''
  const item_status = item && item.s_item_condition ? item.s_item_condition : ''
  const isTaNotExpired = item && item.ta_expiration ? moment().isSameOrBefore(item.ta_expiration) : false

  const toggleLiked = (itemId) => {
    if( sessionId ){
      // console.log("hjsdfbjhsdgfjhsdf", item_id, itemId)
      likeDislike(itemId)
      // console.log("like func", itemId)
    }
  }

  const handleReportClick = (id, report_user_id) => {
    // setClick(false)
    setReportShow(true)
    setReportInput({
      reported_id: id,
      reported_user_id: report_user_id
    })
  }

  return (
    <>
    <div className="flex flex-d-row flex-align-center">
      <p className="fromNow-dt-p">{fromNow_dt}</p>
      {isABNotExpired ? <ThunderIcon /> : null}
    </div>
      <div className={`w-100 p-relative ${state.isTopAd ? sold ? 'border-none' : 'border-orange' : 'border-none'} br-6`}>
          <div className={`profile-listing-item-card ${state.isHighlight ? sold ? `bg-white` : `bg-highlight` : `bg-white`}`} >
            <Link to={finalSLug}>
            <div className="p-relative">

              {
                state.isTopAd ? sold ? null :
                  <div id="ribbon-container">
                    <p >{state.isTopAd ? sold ? null : `Premium` : null}</p>
                  </div> : null
              }
            </div>
            <div className="p-0 text-align-center br-t-r-4 p-relative" >
              <div >
                <div className="itemcard-listing-image-container">
                  {
                    item.oc_t_item_resource.length === 0 ?
                      <div className="bg-dark-gray bg-webkit-linear-gray w-100 h-100 flex flex-justify-center flex-align-center">
                        {sold || reserved ? null : itemStatus === 'Active' ? null : <div className='no-image-is-pending-container'><p className='color-white'>{itemStatus}</p></div>}
                        <img src={DefaultItem} width={214} height={214} />
                      </div> :
                      <FKImage images={item.oc_t_item_resource} type="listing-thumbnail" />
                  }
                  {sold || reserved ? <HomeItemTag sold={sold} reserved={reserved} /> : null}
                </div>
              </div>
              {
                isNew ? sold ? null :
                <div className="p-absolute new-tag-container">
                  <NewTag />
                </div> : null
              }
            </div>
            <div className="item-action-main-container-listing">
              <div className="flex flex-justify-between flex-align-center mb-30 mt-6">
                <p className="itemcard-item-price">{`${price} $`}</p>
                {
                  post_type ? post_type === 'WTS' ? null :
                  <div className="mt-7">{
                  post_type === 'WTB' ? <WTB /> : <WTT /> }</div>: null
                }
              </div>

              <div>
                <p className="other-seller-itemcard-item-title">{item_title}</p>
                {/* <p className="profile-formatted-date-p">{`(${formatted_date ? formatted_date : null})`}</p> */}
                {
                  item_status ? 
                  <div className="flex flex-d-row mb-20 mt-12">
                    {
                      item_status.includes('new') ?
                      item_status.includes('use') ? 
                      <div className="flex flex-d-row"> 
                        <p className="profile-item-new-used-p">{`New`}</p> &nbsp; <BulletIcon /> <p className="profile-item-new-used-p">{`Used`}</p>
                      </div> : <p className="profile-item-new-used-p">{`New`}</p> : item_status.includes('use') ? <p className="profile-item-new-used-p">{`Used`}</p> : null
                    }
                  </div> : null
                }
              </div>
            </div>
            </Link>
            <div className="flex flex-d-row flex-justify-between w-100 item-action-main-container-listing">
                  <div className="flex flex-d-row flex-align-center">
                    {
                      sessionId ? 
                      <LikeButton toggleLiked={toggleLiked} likeDislikeLoading={likeDislikeLoading} isLiked={found} likeId={item_id}/> : 
                      <Link to="/login">
                        <LikeButton toggleLiked={toggleLiked} likeDislikeLoading={likeDislikeLoading} isLiked={found} likeId={item_id}/>
                      </Link>
                    }
                    <p className="watchlist-count-profile-listing-p">{found ? initial_watchlist_count : watchlist_count}</p>
                  </div>
                  <div>
                  {
                    sessionId !== user_id ? sessionId ?
                      <div style={{ color: '#232A34', cursor: 'pointer' }} className="report-icon cursor-pointer" onClick={() => handleReportClick(item_id, user_id)} ><ReportIcon /></div>
                      : null : null
                  }
                    {/* <ReportIcon /> */}
                  </div>
            </div>
            <Link to={{
                    pathname: !sessionId ? `/login` : `/user/messages/inbox`,
                    state: { fromMessage: true, item: item }
                  }}
                    onClick={messageClick} className="profile-listing-message-container">
                  <p>{`Message`}</p>
            </Link>
            { isSessionUser ? 
              <div className='item-button-container'>
                <button onClick={updateIsLoading ? null : () => submitItemUpdateByFieldLoad({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: true })} style={{display: sold ? 'none' : 'inline-block'}} className={'small-basic-button background-gray align-self' }>Sold</button>
                <button className='align-self fk-light-gray fk-font-18' onClick={() => handleSetSelectedItem(item)}><i className="far fa-edit"></i></button>
                <button className='align-self fk-light-gray fk-font-18' onClick={() => setSelected(parseInt(item_id), item_title)}><i className="fas fa-trash-alt"></i></button> 
              </div>  
                : null
            }

            </div>
      </div>
    </>
  )
}

export default ProfileListingCard