import React, {useContext} from 'react'
import { HomeContext } from '../../../../../common/context'
import ItemCard from '../ItemCard'

const Recommended = () => {
    const HomeValue = useContext(HomeContext)
    const {likeDislike, session, likeDislikeLoading, loading, recommendedForYouArr} = HomeValue
    const finalrecommendedForYouArr = recommendedForYouArr && recommendedForYouArr.length !== 0 ? recommendedForYouArr : []

    return (
        recommendedForYouArr.length === 0 || loading ? null :
        <div className="w-100">
            <p className="home-title-label-p">{`Recommended For You`}</p>
            <div className="recommended-item-container">
                {
                    finalrecommendedForYouArr.map((item, i) => {
                        const hl_expiration = item && item.hl_expiration ? item.hl_expiration : null
                        const ta_expiration = item && item.ta_expiration ? item.ta_expiration : null
                        const ab_expiration = item && item.ab_expiration ? item.ab_expiration : null
                        return (
                            <React.Fragment key={i}>
                                <ItemCard key={i} item={item} ab_expiration={ab_expiration} hl_expiration={hl_expiration} ta_expiration={ta_expiration} session={session} likeDislike={likeDislike} likeDislikeLoading={likeDislikeLoading}/>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Recommended