import { CATEGORY_SEARCH, CATEGORY_SEARCH_MORE } from '../constant'
import { takeLatest, put } from 'redux-saga/effects'
import category from '../../graphql/category'
import { categorySearchFail, categorySearchSuccess, categorySearchMoreSuccess } from '../action/categoryAction'
import { theme } from '../../common/theme'

function* categorySearchSaga(action) {
    const { payload } = action
    const id = payload.id ? payload.id : 0
    const filter = action && action.payload && action.payload.filter ? action.payload.filter : 'recent'
    const minPrice = action && action.payload && action.payload.minPrice ? action.payload.minPrice : 0
    const maxPrice = action && action.payload && action.payload.maxPrice ? action.payload.maxPrice : 1000000
    const orderField = filter ==='recent' ? 'dt_pub_date' : 'i_price'
    const orderType = filter !== 'lowToHigh' ? 'DESC' : 'ASC'

    const first = payload.first ? payload.first : 20
    const pages = payload.page ? payload.page : 1

    try {
        const result = yield category.getCategorySearch(id, orderField, orderType, minPrice, maxPrice, pages, first)

        if(result === 403) {
            yield put(categorySearchFail('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.publicGetItemsByCategory && result.data.publicGetItemsByCategory ? result.data.publicGetItemsByCategory : []
            yield put(categorySearchSuccess(data));
        }

    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(categorySearchFail(errorMessage))
    }
}

export function* watchCategorySearchSaga(){
    yield takeLatest(CATEGORY_SEARCH.LOAD,categorySearchSaga)
}

function* categoryMoreSaga(action){
    const { payload } = action
    const id = payload.id ? payload.id : 0
    const first = payload.first ? payload.first : 20
    const pages = payload.page ? payload.page : 1
    const filter = action && action.payload && action.payload.filter ? action.payload.filter : 'recent'
    const minPrice = action && action.payload && action.payload.minPrice ? action.payload.minPrice : 0
    const maxPrice = action && action.payload && action.payload.maxPrice ? action.payload.maxPrice : 1000000
    const orderField = filter ==='recent' ? 'dt_pub_date' : 'i_price'
    const orderType = filter !== 'lowToHigh' ? 'DESC' : 'ASC'

    try {
        const result = yield category.getCategorySearch(id, orderField, orderType, minPrice, maxPrice, pages, first)

        if(result === 403) {
            yield put(categorySearchFail('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.publicGetItemsByCategory && result.data.publicGetItemsByCategory ? result.data.publicGetItemsByCategory : []
            yield put(categorySearchMoreSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
      yield put(categorySearchFail(errorMessage))
    }
}

export function* watchCategorySearchMoreSaga(){
    yield takeLatest(CATEGORY_SEARCH_MORE.LOAD,categoryMoreSaga)
}