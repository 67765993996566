import { SERVICES, PROMOTE, PURCHASE_COIN, COLLECT_PROMO } from '../constant'    
    const initialState = {
        isLoading: false,
        servicesData: [],
        error: false,

        isLoadingPromote: false,
        promoteData: [],
        errorPromote: false,
        promoteSuccessMessage: false,

        isLoadingPurchaseCoin: false,
        purchaseCoinData: {},
        errorPurchaseCoin: false,
        purchaseSuccessMessage: false,

        isLoadingCollectPromo: false,
        collectPromoData: {},
        errorCollectPromo: false,
        collectPromoMessage: false

    }
    
export default (state = initialState, action) => {
    switch(action.type) {
        case SERVICES.LOAD:
        return {...state, isLoading: true, error: null}

        case SERVICES.SUCCESS:
        return {...state, isLoading: false, servicesData:  action.payload, error: null}

        case SERVICES.FAILED:
        return {...state, isLoading: false, error: action.payload}

        case PROMOTE.LOAD:
        return {...state, isLoadingPromote: true, errorPromote: null}

        case PROMOTE.SUCCESS:
            const promote_data = action.payload && action.payload.data ? action.payload.data : {}
            const promote_message = action.payload && action.payload.message ? action.payload.message : false
        return {...state, isLoadingPromote: false, promoteData: promote_data, errorPromote: null, promoteSuccessMessage: promote_message}

        case PROMOTE.FAIL:
        return {...state, isLoadingPromote: false, errorPromote: action.payload}

        case PROMOTE.RESET:
        return {...state, isLoadingPromote: false, promoteData: [] , errorPromote: null, promoteSuccessMessage: false}

        case PURCHASE_COIN.LOAD:
        return {...state, isLoadingPurchaseCoin: true, errorPurchaseCoin: null}

        case PURCHASE_COIN.SUCCESS:
            const data = action.payload && action.payload.data ? action.payload.data : {}
            const message = action.payload && action.payload.message ? action.payload.message : false
        return {...state, isLoadingPurchaseCoin: false, errorPurchaseCoin: null, purchaseCoinData: data, purchaseSuccessMessage: message }

        case PURCHASE_COIN.FAIL:
        return {...state, isLoadingPurchaseCoin: false, errorPurchaseCoin: action.payload}

        case PURCHASE_COIN.RESET:
        return {...state, isLoadingPurchaseCoin: false, errorPurchaseCoin: null, purchaseCoinData: {} , purchaseSuccessMessage: false }

        case COLLECT_PROMO.LOAD:
        return {...state, isLoadingCollectPromo: true, errorCollectPromo: null}

        case COLLECT_PROMO.SUCCESS:
            const collectPromodata = action.payload && action.payload.data ? action.payload.data : {}
            const collectPromoMessage = action.payload && action.payload.message ? action.payload.message : false
        return {...state, isLoadingCollectPromo: false, errorCollectPromo: null, collectPromoData: collectPromodata, collectPromoMessage: collectPromoMessage }

        case COLLECT_PROMO.FAIL:
        return {...state, isLoadingCollectPromo: false, errorCollectPromo: action.payload}

        case COLLECT_PROMO.RESET:
        return {...state, isLoadingCollectPromo: false, errorCollectPromo: null, collectPromoData: {} , collectPromoMessage: false }

        default:
        return state;  
    }
}