import {CONTACT_DATA} from '../constant'

export const contactDataLoad = (payload) => {
    return {
        type: CONTACT_DATA.LOAD,
        payload
    }
}

export const contactDataSuccess = (payload) => {
    return {
        type: CONTACT_DATA.SUCCESS,
        payload
    }
}

export const contactDataFail = (payload) => {
    return {
        type: CONTACT_DATA.FAIL,
        payload
    }
}

export const contactDataReset = (payload) => {
    return {
        type: CONTACT_DATA.RESET,
        payload
    }
}