import React from 'react'
import {theme} from './theme'
import {BackButton} from '../../../../../../assets/svg'

export const TosModal = ({click}) => {
    const data = theme.TOS
    const lastUpdate = theme.lastUpdate
    const lister = (l) => {
        let listing
        listing = l.map((list) => <li>{list.text}</li>)
        return listing
    }
    const termsBody = (p, s) => {
        let body
        body = <p className="content-sentence"><span className="content-section">{s}</span> {p}</p>
        return body
    }
    return (
        <div style={{width: window.innerWidth < 600 ? '90%' : '50%', background: 'rgba(255, 255, 255,1.0)', borderRadius: 12, height: 'fit-content'}}>
           <div style={{padding: window.innerWidth < 600 ? 10 : 30}}>
             <div className="content-sub-container" style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row'}}>
                <div onClick={click} style={{cursor: 'pointer'}}>
                    <BackButton />
                 </div>
                 <h1 className="content-main-title" style={{width: '100%', textAlign: 'center'}}>{`Terms of Use`}</h1>
                 <div style={{width: '10px'}}></div>
             </div>
             <div className="content-body">
                 <h1 className="content-last-update">{`Last updated: ${lastUpdate}`}</h1>
                 { data.map((terms, index) => {
                     const bodyContent = terms.body 
                     const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, data.section) : <div><ul className="content-list">{lister(data.body)}</ul></div>)
                     return <div key={index}>
                         <h1 className="content-title">{terms.title}</h1>
                         <div>
                             {bodyCheck}
                         </div>
                     </div>
                 })}
             </div>
         </div>
        </div>
    )
}