import React, { useState, useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import { adminChangePasswordLoad , adminChangePasswordClearMessage} from '../../../redux/action'
import Loader from '../../../common/component/loader'
import Message from '../../../common/message'
import { theme } from '../../../common/theme'
import { style } from './style'

const { REACT_APP_WEB_SEC } = process.env

const AdminForgot = (props) => {
    const fkv2_session = props.session.fkv2_session ? props.session.fkv2_session : false
    const { cPasswordIsLoading, cPasswordError, cPasswordData} = props.admin
    const currentUrl = window.location.href
    const [notValid, setNotValid] = useState(false)
    const [reDirect, setRedirect] = useState(false)

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            password: '',
            rePassword: '',
            email: '',
            type: 'admin'
        }
    );

    useEffect(() => {
      if (!fkv2_session) {
        linkValid()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const checkPageStatus = () => {
        if (fkv2_session) {
            return <Redirect to='/'/>
        }
    }

    const linkValid = () =>{
        const split = currentUrl.split(REACT_APP_WEB_SEC)
        const hashedEmail = split[1]
        const registeredDate = split[2] ? window.atob(split[2]) : false
        const hoursOld = moment().diff(moment(registeredDate), 'hours');
        setTimeout(() => {
            if(hoursOld >= 48) { 
                setNotValid(true)
            }
            else{
                // eslint-disable-next-line
                setUserInput({['email']: hashedEmail});
            }
        }, 2000)
    }

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserInput({[name]: newValue});
      }
    
      const handleSubmit = evt => {
        evt.preventDefault();
        props.adminChangePasswordLoad(userInput)
      }

      const parseError = (error) => {
        let errorArray = []
        if (typeof error === 'string') {
          errorArray.push(<p key={1} style={{color: 'red'}}>{error}</p>)
        } else {
          for (var key in error) {
            if (error.hasOwnProperty(key)) {
                errorArray.push(<p key={key} style={{color: 'red'}}>{error[key].toString().replace('input.', '')}</p>)
            }
          }
        }
        return errorArray
      }

      const renderRedirect = () => {
        if (reDirect) {
            return <Redirect to='/admin'/>}
      }
    
      //hooks to clear error / message
      useEffect(() => {
            if (cPasswordError) {
            setTimeout(() => {
            props.adminChangePasswordClearMessage()
            setRedirect(true)
            }, 3000)
            }
            if (cPasswordData) {
            setTimeout(() => {
            props.adminChangePasswordClearMessage()
            setRedirect(true)
            }, 3000)
        }
      // eslint-disable-next-line
      }, [props.admin.cPasswordError, props.admin.cPasswordData])

    return (
        <div style={style.boxContainer}>
            {checkPageStatus()}
            {renderRedirect()}
            {cPasswordIsLoading ? <Loader /> :
                notValid ? 
                    <Message message={theme.text.message.linkExpired}/>
                    : <div style={style.box}>
                        <div style={style.boxHead}>
                            <h1>Admin Reset Password</h1>
                        </div>
                        <div style={style.boxBody}>
                        <form onSubmit={handleSubmit}>
                            <div style={style.formGroup}>
                                <label style={style.formLabel}>New Password</label>
                                <input name="password" minLength={8} type="password" style={style.formInput} onChange={handleChange} required/>
                            </div>
                            <div style={style.formGroup}>
                                <label style={style.formLabel}>Re-type New Password</label>
                                <input name="rePassword" minLength={8} type="password" style={style.formInput} onChange={handleChange} required/>
                            </div>
                            { cPasswordError ? <div> {parseError(cPasswordError)} </div> : null}
                            { cPasswordData ? <p style={{color: 'green'}}>{cPasswordData}</p> : <input type="submit" value="Change Password" style={style.signupBtn}/>}
                            
                        </form>
                        </div>
                      </div>
            }
        </div>

    );
  }

  const mapStateToProps = store => {
    return {
      admin: store.adminReducer
    };
  };
  
  const mapActionToProps = {
    adminChangePasswordLoad,
    adminChangePasswordClearMessage
  }

export default connect(mapStateToProps,mapActionToProps)(AdminForgot);