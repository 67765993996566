import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { AdminDashboardContext } from '../../../../common/context';
import { Bar} from 'react-chartjs-2';

// export const ListingChart = () => {
//     const value = useContext(AdminDashboardContext);
//     const { adminDashboardItemData } = value
//     const total = adminDashboardItemData && adminDashboardItemData.sum ? adminDashboardItemData.sum : 0

//     const data = {
//         labels: adminDashboardItemData.labels,
//         datasets: [{
//             data: adminDashboardItemData.data,
//             backgroundColor: adminDashboardItemData.randomColor
//         }],
//     };
    
//     return (
//         <div className="chart-container">
//             {/* <h2>Posting Today: {total}</h2> */}
//             <Pie data={data} 
//                 width={5} height={5} 
//                 options={{
//                     legend: {display: true, position: 'bottom'},
//                     title: {display: true, fontSize: 20,text: `Posting this month: ${total}`
//                     },
//                 }} 
//             />
//         </div>
//     );
// }

// export const PurchaseChart = () => {
//     const value = useContext(AdminDashboardContext);
//     const { adminDashboardPurchaseData } = value
//     const total = adminDashboardPurchaseData && adminDashboardPurchaseData.sum ? adminDashboardPurchaseData.sum : 0

//     const data = {
//         labels: adminDashboardPurchaseData.labels,
//         datasets: [{
//             data: adminDashboardPurchaseData.data,
//             backgroundColor: adminDashboardPurchaseData.randomColor
//         }],
//     };
    
//     return (
//         <div className="chart-container">
//             <Pie data={data} 
//                 width={5} height={5} 
//                 options={{
//                     legend: {display: true, position: 'bottom'},
//                     title: {display: true, fontSize: 20,text: `Purchase this month: ${total}`
//                     },
//                 }} 
//             />
//         </div>
//     );
// }

// export const UserChart = () => {
//     const value = useContext(AdminDashboardContext);
//     const { adminDashboardUserData } = value
//     return (
//         <div className="chart-container" style={{justifyContent: 'normal'}}>
//             <p className="chart-title">Recent Signup</p>
//             <table className="signup-table">
//                 <thead>
//                     <tr>
//                         <th>Name</th>
//                         <th>Registration Date</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 { adminDashboardUserData.map((item, index) => {
//                     const name = item.s_username || ''
//                     const regDate = item.dt_reg_date || ''
//                     return (<tr key={index}>
//                                 <td>{name}</td>
//                                 <td>{moment(regDate).format('YYYY-MM-DD')}</td>
//                             </tr>  
//                     )  
//                 })  
//                 }
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// export const MonthlyPurchaseChart = () => {
//     const value = useContext(AdminDashboardContext);
//     const { adminDashboardMonthlyPurchaseData } = value
//     const data = {
//         labels: adminDashboardMonthlyPurchaseData.labels,
//         datasets: [
//           {
//             label: 'Monthly Purchase',
//             fill: true,
//             lineTension: 0.1,
//             backgroundColor: 'rgba(75,192,192,0.4)',
//             borderColor: 'rgba(75,192,192,1)',
//             borderCapStyle: 'butt',
//             borderDash: [],
//             borderDashOffset: 0.0,
//             borderJoinStyle: 'miter',
//             pointBorderColor: 'rgba(75,192,192,1)',
//             pointBackgroundColor: '#fff',
//             pointBorderWidth: 1,
//             pointHoverRadius: 5,
//             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//             pointHoverBorderColor: 'rgba(220,220,220,1)',
//             pointHoverBorderWidth: 2,
//             pointRadius: 1,
//             pointHitRadius: 10,
//             height: 300,
//             data: adminDashboardMonthlyPurchaseData.data
//           }
//         ]
//       };
    
//     return (
//         <div className="chart-container">
//             <Line 
//                 data={data}
//                 options={{
//                     legend: {display: false},
//                     title: {display: true, fontSize: 20,text: `Monthly Purchase`},
//                     maintainAspectRatio: false,
//                 }} 
//             />
//         </div>
//     );
// }

// export const MonthlyUserChart = () => {
//     const value = useContext(AdminDashboardContext);
//     const { adminDashboardMonthlyUserData } = value
//     const data = {
//         labels: adminDashboardMonthlyUserData.labels,
//         datasets: [
//           {
//             backgroundColor: 'rgba(255,99,132,0.2)',
//             borderColor: 'rgba(255,99,132,1)',
//             borderWidth: 1,
//             hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//             hoverBorderColor: 'rgba(255,99,132,1)',
//             data: adminDashboardMonthlyUserData.data
//           }
//         ]
//       };
    
//     return (
//         <div className="chart-container">
//             <HorizontalBar 
//                 data={data} 
//                 options={{
//                     title: {display: true, fontSize: 20,text: `Monthly Signup`},
//                     maintainAspectRatio: false,
//                     scales: {
//                         xAxes: [{
//                             ticks: {
//                                 beginAtZero: true,
//                                 userCallback: function(label) {
//                                     if (Math.floor(label) === label) {
//                                         return label;
//                                     }
//                                 },
//                             }
//                         }]
//                     },
//                     legend: {
//                         display: false,
//                     }
//                 }}
//             />
//         </div>
//     );
// }

//Updated
export const TodayListingChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalTodayPostingPerUserData } = value
    const totalItem = finalTodayPostingPerUserData && finalTodayPostingPerUserData.totalItem ? finalTodayPostingPerUserData.totalItem : ''
    const totalUser = finalTodayPostingPerUserData && finalTodayPostingPerUserData.totalUser ? finalTodayPostingPerUserData.totalUser : ''
    const totalAverage = finalTodayPostingPerUserData && finalTodayPostingPerUserData.totalAverage ? finalTodayPostingPerUserData.totalAverage : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalTodayPostingPerUserData && finalTodayPostingPerUserData.data && finalTodayPostingPerUserData.data.length > 0) {
            const data = {
                labels: finalTodayPostingPerUserData.labels,
                datasets: [
                  {
                    label: `Item`,
                    backgroundColor: 'green',
                    data: finalTodayPostingPerUserData.data,
                  },   
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalTodayPostingPerUserData.users,
                  }, 
                  {
                    label: `Average post per User`,
                    backgroundColor: 'red',
                    data: finalTodayPostingPerUserData.average,
                  }
                ],
            };
            setFinalData(data)
        }
    }, [finalTodayPostingPerUserData])
    return (
        <Link to={'/admin/dashboard/item'} >
        <div className="chart-container">
            <h3>Post Today</h3>
            <p>Total Item: {totalItem}</p>
            <p>Total User: {totalUser}</p>
            <p>Total Average post per User: {totalAverage}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const ThisWeekListingChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalWeekPostingPerUser } = value
    const totalItem = finalWeekPostingPerUser && finalWeekPostingPerUser.totalItem ? finalWeekPostingPerUser.totalItem : ''
    const totalUser = finalWeekPostingPerUser && finalWeekPostingPerUser.totalUser ? finalWeekPostingPerUser.totalUser : ''
    const totalAverage = finalWeekPostingPerUser && finalWeekPostingPerUser.totalAverage ? finalWeekPostingPerUser.totalAverage : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalWeekPostingPerUser && finalWeekPostingPerUser.data && finalWeekPostingPerUser.data.length > 0) {
            const data = {
                labels: finalWeekPostingPerUser.labels,
                datasets: [
                  {
                    label: `Item`,
                    backgroundColor: 'green',
                    data: finalWeekPostingPerUser.data,
                  },   
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalWeekPostingPerUser.users,
                  }, 
                  {
                    label: `Average post per User`,
                    backgroundColor: 'red',
                    data: finalWeekPostingPerUser.average,
                  }
                ],
            };
            setFinalData(data)
        }
    }, [finalWeekPostingPerUser])
    return (
        <Link to={'/admin/dashboard/item'} >
        <div className="chart-container">
            <h3>Post this Week</h3>
            <p>Total Item: {totalItem}</p>
            <p>Total User: {totalUser}</p>
            <p>Total Average post per User: {totalAverage}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const MonthlyListingChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalMonthPostPerUser } = value
    const totalItem = finalMonthPostPerUser && finalMonthPostPerUser.totalItem ? finalMonthPostPerUser.totalItem : ''
    const totalUser = finalMonthPostPerUser && finalMonthPostPerUser.totalUser ? finalMonthPostPerUser.totalUser : ''
    const totalAverage = finalMonthPostPerUser && finalMonthPostPerUser.totalAverage ? finalMonthPostPerUser.totalAverage : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalMonthPostPerUser && finalMonthPostPerUser.data && finalMonthPostPerUser.data.length > 0) {
            const finalLabels = finalMonthPostPerUser.labels.map(item => {
                return `Week ${item}`
            })
            const data = {
                labels: finalLabels,
                datasets: [
                  {
                    label: `Item`,
                    backgroundColor: 'green',
                    data: finalMonthPostPerUser.data,
                  },   
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalMonthPostPerUser.users,
                  }, 
                  {
                    label: `Average post per User`,
                    backgroundColor: 'red',
                    data: finalMonthPostPerUser.average,
                  }
                ],
            };
            setFinalData(data)
        }
    }, [finalMonthPostPerUser])
    return (
        <Link to={'/admin/dashboard/item'} >
        <div className="chart-container">
            <h3>Post this Month</h3>
            <p>Total Item: {totalItem}</p>
            <p>Total User: {totalUser}</p>
            <p>Total Average post per User: {totalAverage}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const YearlyListingChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalYearPostPerUser } = value
    const totalItem = finalYearPostPerUser && finalYearPostPerUser.totalItem ? finalYearPostPerUser.totalItem : ''
    const totalUser = finalYearPostPerUser && finalYearPostPerUser.totalUser ? finalYearPostPerUser.totalUser : ''
    const totalAverage = finalYearPostPerUser && finalYearPostPerUser.totalAverage ? finalYearPostPerUser.totalAverage : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalYearPostPerUser && finalYearPostPerUser.data && finalYearPostPerUser.data.length > 0) {
            const data = {
                labels: finalYearPostPerUser.labels,
                datasets: [
                  {
                    label: `Item`,
                    backgroundColor: 'green',
                    data: finalYearPostPerUser.data,
                  },   
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalYearPostPerUser.users,
                  }, 
                  {
                    label: `Average post per User`,
                    backgroundColor: 'red',
                    data: finalYearPostPerUser.average,
                  }
                ],
            };
            setFinalData(data)
        }
    }, [finalYearPostPerUser])
    return (
        <Link to={'/admin/dashboard/item'} >
        <div className="chart-container">
            <h3>Post this Year</h3>
            <p>Total Item: {totalItem}</p>
            <p>Total User: {totalUser}</p>
            <p>Total Average post per User: {totalAverage}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const TodaySignupChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalSignUpToday } = value
    const totalUser = finalSignUpToday && finalSignUpToday.totalUser ? finalSignUpToday.totalUser : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalSignUpToday && finalSignUpToday.data && finalSignUpToday.data.length > 0) {
            const data = {
                labels: finalSignUpToday.labels,
                datasets: [ 
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalSignUpToday.users,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalSignUpToday])
    return (
        <Link to={'/admin/dashboard/user'} >
        <div className="chart-container">
            <h3>Signup Today</h3>
            <p>Total User: {totalUser}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const ThisWeekSignupChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalSignUpThisWeek } = value
    const totalUser = finalSignUpThisWeek && finalSignUpThisWeek.totalUser ? finalSignUpThisWeek.totalUser : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalSignUpThisWeek && finalSignUpThisWeek.data && finalSignUpThisWeek.data.length > 0) {
            const data = {
                labels: finalSignUpThisWeek.labels,
                datasets: [ 
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalSignUpThisWeek.users,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalSignUpThisWeek])
    return (
        <Link to={'/admin/dashboard/user'} >
        <div className="chart-container">
            <h3>Signup this Week</h3>
            <p>Total User: {totalUser}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const ThisMonthSignupChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalSignUpThisMonth } = value
    const totalUser = finalSignUpThisMonth && finalSignUpThisMonth.totalUser ? finalSignUpThisMonth.totalUser : ''
    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalSignUpThisMonth && finalSignUpThisMonth.data && finalSignUpThisMonth.data.length > 0) {
            const finalLabels = finalSignUpThisMonth.labels.map(item => {
                return `Week ${item}`
            })
            const data = {
                labels: finalLabels,
                datasets: [ 
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalSignUpThisMonth.users,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalSignUpThisMonth])
    return (
        <Link to={'/admin/dashboard/user'} >
        <div className="chart-container">
            <h3>Signup this Month</h3>
            <p>Total User: {totalUser}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const ThisYearSignupChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalSignUpThisYear } = value
    const totalUser = finalSignUpThisYear && finalSignUpThisYear.totalUser ? finalSignUpThisYear.totalUser : ''
    const [finalData, setFinalData] = useState({})
    useEffect(() => {
        if (finalSignUpThisYear && finalSignUpThisYear.data && finalSignUpThisYear.data.length > 0) {
            const data = {
                labels: finalSignUpThisYear.labels,
                datasets: [ 
                  {
                    label: `User`,
                    backgroundColor: 'yellow',
                    data: finalSignUpThisYear.users,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalSignUpThisYear])
    return (
        <Link to={'/admin/dashboard/user'} >
        <div className="chart-container">
            <h3>Signup this Year</h3>
            <p>Total User: {totalUser}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const TodayCoinPurchaseChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalPurchaseToday } = value
    const totalPurchase = finalPurchaseToday && finalPurchaseToday.totalPurchase ? finalPurchaseToday.totalPurchase : ''
    const totalCount = finalPurchaseToday && finalPurchaseToday.totalCount ? finalPurchaseToday.totalCount : ''

    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalPurchaseToday && finalPurchaseToday.data && finalPurchaseToday.data.length > 0) {
            const data = {
                labels: finalPurchaseToday.labels,
                datasets: [ 
                  {
                    backgroundColor: '#aad77a',
                    data: finalPurchaseToday.data,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalPurchaseToday])
    return (
        <Link to={'/admin/dashboard/sale'} >
        <div className="chart-container">
            <h3>Purchase Today</h3>
            <p>Total Count: {totalCount}</p>
            <p>Total Purchase: {`${totalPurchase} SGD`}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    );
}

export const ThisWeekCoinPurchaseChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalPurchaseThisWeek } = value
    const totalPurchase = finalPurchaseThisWeek && finalPurchaseThisWeek.totalPurchase ? finalPurchaseThisWeek.totalPurchase : ''
    const totalCount = finalPurchaseThisWeek && finalPurchaseThisWeek.totalCount ? finalPurchaseThisWeek.totalCount : ''

    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalPurchaseThisWeek && finalPurchaseThisWeek.data && finalPurchaseThisWeek.data.length > 0) {
            const data = {
                labels: finalPurchaseThisWeek.labels,
                datasets: [ 
                  {
                    backgroundColor: '#aad77a',
                    data: finalPurchaseThisWeek.data,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalPurchaseThisWeek])
    return (
        <Link to={'/admin/dashboard/sale'}>
        <div className="chart-container">
            <h3>Purchase this Week</h3>
            <p>Total Count: {totalCount}</p>
            <p>Total Purchase: {`${totalPurchase} SGD`}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    ); 
}

export const ThisMonthCoinPurchaseChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalPurchaseThisMonth } = value
    const totalPurchase = finalPurchaseThisMonth && finalPurchaseThisMonth.totalPurchase ? finalPurchaseThisMonth.totalPurchase : ''
    const totalCount = finalPurchaseThisMonth && finalPurchaseThisMonth.totalCount ? finalPurchaseThisMonth.totalCount : ''

    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalPurchaseThisMonth && finalPurchaseThisMonth.data && finalPurchaseThisMonth.data.length > 0) {
            const finalLabels = finalPurchaseThisMonth.labels.map(item => {
                return `Week ${item}`
            })
            const data = {
                labels: finalLabels,
                datasets: [ 
                  {
                    backgroundColor: '#aad77a',
                    data: finalPurchaseThisMonth.data,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalPurchaseThisMonth])
    return (
        <Link to={'/admin/dashboard/sale'}>
        <div className="chart-container">
            <h3>Purchase this Month</h3>
            <p>Total Count: {totalCount}</p>
            <p>Total Purchase: {`${totalPurchase} SGD`}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    ); 
}

export const ThisYearCoinPurchaseChart = () => {
    const value = useContext(AdminDashboardContext);
    const { finalPurchaseThisYear } = value
    const totalPurchase = finalPurchaseThisYear && finalPurchaseThisYear.totalPurchase ? finalPurchaseThisYear.totalPurchase : ''
    const totalCount = finalPurchaseThisYear && finalPurchaseThisYear.totalCount ? finalPurchaseThisYear.totalCount : ''

    const [finalData, setFinalData] = useState({})

    useEffect(() => {
        if (finalPurchaseThisYear && finalPurchaseThisYear.data && finalPurchaseThisYear.data.length > 0) {
            const data = {
                labels: finalPurchaseThisYear.labels,
                datasets: [ 
                  {
                    backgroundColor: '#aad77a',
                    data: finalPurchaseThisYear.data,
                  }, 
                ],
            };
            setFinalData(data)
        }
    }, [finalPurchaseThisYear])
    return (
        <Link to={'/admin/dashboard/sale'}>
        <div className="chart-container">
            <h3>Purchase this Year</h3>
            <p>Total Count: {totalCount}</p>
            <p>Total Purchase: {`${totalPurchase} SGD`}</p>
            <Bar
                data={finalData} width={100} height={100}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: false
                    },
                }}
            />
        </div>
        </Link>
    ); 
}

