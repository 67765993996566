import React from 'react';
import { theme } from '../../../../common/theme';

const { REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER } = process.env;

const WatchList = props => {

  const data = theme.text.sampleItem
  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
      <div style={style.full}>
      <div>
      <h1>{`Watch List`}</h1>
      </div>
        <div style={style.container}>
        {data.map((item, key) => {
         return <div key={key} style={style.itembox}>
                   <div style={style.cardItem}>
                     {/* <Link to={item.path} style={style.sideButton}>{item.label}</Link> */}
                     <div style={style.cardInfo}>
                       <div style={style.row}>
                         <div>
                           <img src={`${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/uploads/62_thumbnail.jpg`} alt="items"/>
                         </div>
                         <div>
                           <p>${item.price}.00</p>
                         </div>
                         <div>
                           <p>{item.title}</p>
                         </div>
                         <div>
                           <p>{item.views} views</p>
                         </div>
                       </div>
                     </div>
                   </div>
               </div>
           })}
        </div>
    </div>
  );
}

export const style = {
  full: {
    width: '100%'
  },
  container: {
    display: 'flex',
    flex: '1 1 0%'
  },
  itembox: {
    display:'flex',
    overflow: 'hidden'
  },
  cardItem: {
    margin: '8px',
    border: '1px solid #E1E1E1',
    borderRadius: '5px',
    background: '#fff',
    minHeight: '317px'
  },
  cardInfo: {
    padding: '10px',
    borderRadius: '0 0 5px 5px',
    marginTop: '1px',
    textAlign: 'center',
    overflow: 'hidden'
  },
  row: {
    marginRight: '-15px',
    marginLeft: '-15px'
  }
};

export default WatchList;