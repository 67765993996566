import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import { AdminItemContext } from '../../../../../common/context'
import { adminItemLoad, adminItemDetailLoad, adminItemDetailReset, adminUpdateItemLoad, adminUpdateItemReset,
         adminItemSuggestLoad, adminItemByUserLoad, adminItemByUserReset, adminDeleteItemLoad, adminDeleteItemReset
} from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { ItemList, ItemModal, ItemSuggestData, ItemSearchResult, UserItemsResult } from './component/CustomComponent'

const ItemSection = (props) => {
    const pageCount = 10
    const result_limit = 20
    
    const { adminItemData, adminItemIsLoading, adminItemError, adminItemPage, adminTotalItem,
            adminItemDetailData, adminItemDetailIsLoading, adminItemDetailError,
            adminUpdateItemIsLoading, adminUpdateItemData, adminUpdateItemError, adminUpdateItemMessage,
            adminItemSuggestIsLoading, adminItemSuggestData, adminItemSuggestError,
            adminUserItemIsLoading, adminUserItemData, adminUserItemError, adminUserItemPage,
            adminDeleteItemIsLoading, adminDeleteItemData, adminDeleteItemError, adminDeleteItemMessage
    } = props.admin

    const historyUserId = props.history && props.history.location && props.history.location.state && props.history.location.state.user_id ? props.history.location.state.user_id : null
    const historyItemId = props.history && props.history.location && props.history.location.state && props.history.location.state.item_id ? props.history.location.state.item_id : null

    let history = useHistory();

    //item modal
    const [isShow, setIsShow] = useState(false)
    const [isShowEdit, setIsShowEdit] = useState(false)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)
    const [isShowUserItemResult, setIsShowUserItemResult] = useState(false)
    
    useEffect(() => {
        if (historyUserId) {
            fetchUserItems(historyUserId)
        } else if(historyItemId) {
            fetchItemDetail(historyItemId, true)
        }
        else {
            fetchItems()
        }
        return () => {
            closeAllModal()
            //history.replace()
        }
    // eslint-disable-next-line
    }, [historyUserId, historyItemId])

    useEffect(()=>{
        if (adminUpdateItemMessage) {
            setTimeout(() => {
                setIsShow(false)
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/item`)
            }, 2000)
        }
        if (adminDeleteItemMessage) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/item`)
            }, 1000)
        }
    // eslint-disable-next-line
    }, [adminUpdateItemMessage, adminDeleteItemMessage])

    // useEffect(() => {
    //     if (historyUserId) {
    //         fetchUserItems(historyUserId)
    //     }
    // // eslint-disable-next-line
    // }, [historyUserId])

    // useEffect(() => {
    //     if (historyItemId) {
    //         fetchItemDetail(historyItemId)
    //     }
    // // eslint-disable-next-line
    // }, [historyItemId])

    const fetchItems = () => {
        if (adminItemData.length === 0) {
            props.adminItemLoad({first: 20, page: adminItemPage})
        }
    }

    const fetchUserItems = (id) => {
        setIsShowUserItemResult(true)
        props.adminItemByUserLoad({fk_i_user_id: id, first: 20, page: adminUserItemPage, orderField: "dt_pub_date", orderType: "DESC"})
    }

    const closeUserItemModal = () => {
        setIsShowUserItemResult(false)
        props.adminItemByUserReset()
        if (historyUserId) {
            history.push("/admin/dashboard/user", {user_id: null});
        }
    }

    const fetchItemDetail = (id, isEdit) => {
        setIsShow(true)
        if (isEdit) {
            setIsShowEdit(true)
        }
        props.adminItemDetailLoad({pk_i_id: id})
    }

    const closeItemModal = () => {
        closeAllModal()
        if (historyItemId) {
            // props.history.replace('/admin/dashboard/report', null)
        }
        if (historyUserId) {
            // history.push("/admin/dashboard/user", {user_id: null});
        }
    }

    const closeAllModal = () => {
        setIsShowUserItemResult(false)
        setIsShowSearchResult(false)
        setIsShow(false)
        setIsShowEdit(false)
        // history.replace()
    }

    const handlePageClick = data => {
        props.adminItemLoad({first: 20, page: data.selected + 1})
    };

    const updateItem = (evt, data) => {
        evt.preventDefault();
        const editRequiredField = ['pk_i_id', 'b_active', 'b_spam', 'i_price', 'fk_i_category_id', 's_title', 's_description']
        let parseInput = {}

        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (editRequiredField.includes(k)) {
                    if (k === 'pk_i_id' || k === 'i_price') {
                        parseInput[k] = parseInt(data[k])
                    } else {
                        parseInput[k] = data[k]
                    }
                }
            }
        }
        props.adminUpdateItemLoad(parseInput)
    }

    //item search
    const [isSearching, setIsSearching] = useState(false)

    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            props.adminItemSuggestLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "dt_pub_date", orderType: "DESC",
            price_from: 0, price_to: 999999999})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setIsShowSearchResult(true)
    }
    
    const handleDeleteSubmit = (id, title) => {
        let r = window.confirm(`Are you sure you want to delete listing ${title}`)
        if (r == true) {
            closeAllModal()
            const userId = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
            props.adminDeleteItemLoad({id, userId})
        }
    }

    const gotoUser = (id) => {
        history.push("/admin/dashboard/user", {user_id: id});
    }

    const handleViewPublicItem = (id) => {
        const url = `/admin/dashboard/item/${id}`
        history.push(url);
    }

    return (
        <AdminItemContext.Provider value={{adminItemData, fetchItemDetail, closeItemModal, adminItemDetailData, adminItemDetailIsLoading, adminItemDetailError,
            updateItem, isShow, isShowEdit, adminUpdateItemIsLoading, adminUpdateItemData, adminUpdateItemError, adminUpdateItemMessage, 
            adminItemSuggestIsLoading, adminItemSuggestData, adminItemSuggestError, isShowSearchResult, setIsShowSearchResult,
            historyUserId, adminUserItemIsLoading, adminUserItemData, adminUserItemError, isShowUserItemResult, setIsShowUserItemResult, closeUserItemModal,
            handleDeleteSubmit, gotoUser, handleViewPublicItem
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Items</h3>
            <section>
            {
            adminItemIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminItemError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminItemError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div ></div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><ItemSuggestData /></div>}
                            </div>
                        </div>
                        {
                            adminDeleteItemIsLoading ? <p style={{textAlign: 'center'}}>Deleting...</p> :
                            adminDeleteItemError ? <p style={{color: 'red', textAlign: 'center'}}>{adminDeleteItemError}</p>:
                            adminDeleteItemMessage ? <p style={{textAlign: 'center', color: 'green'}}>{adminDeleteItemMessage}</p> : null
                        }
                        <ItemList type='load'/>
                    </article>
            }
            <div></div> 
                {adminItemError ? null :
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={adminTotalItem}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={pageCount}
                        onPageChange={(d) => handlePageClick(d)}
                        containerClassName={'pagination pointer'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                }
            </section> 
        </section> 
        {isShow ? <ItemModal /> : null }
        {isShowEdit ? <ItemModal /> : null }
        {isShowSearchResult ? <ItemSearchResult /> : null}
        {isShowUserItemResult ? <UserItemsResult /> : null}
        </AdminItemContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminItemLoad,
    adminItemDetailLoad,
    adminUpdateItemLoad,
    adminUpdateItemReset,
    adminItemDetailReset,
    adminItemSuggestLoad,
    adminItemByUserLoad,
    adminItemByUserReset,
    adminDeleteItemLoad, 
    adminDeleteItemReset
}
  
export default connect(mapStateToProps, mapActionToProps)(ItemSection);