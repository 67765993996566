import React, { useState, useEffect }  from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReportContext } from '../../../../../common/context'
import { ReportsList } from './component/CustomComponent'
import { adminReportedItemLoad } from '../../../../../redux/action'

const ReportSection = (props) => {
    const [activeTab, setActiveTab] = useState(0)
    const { adminReportedItemIsLoading, adminReportedItemError, adminReportedItemData } = props.admin
    let history = useHistory();

    const [reportedId, setReportedId ] = useState(null)

    useEffect(() => {
        props.adminReportedItemLoad({first: 20, page: 1, orderField: 'created_at', orderType: 'DESC', type: 'item'})
        const { pathname } = history && history.location
        if (pathname.includes('report/')) {
            const splitString = pathname.split('report/')
            if (splitString[1]) {
                setReportedId(splitString[1])
            }
        }
    // eslint-disable-next-line
    }, [history.location])

    //goto items
    const gotoItem = (id) => {
        history.push("/admin/dashboard/item", {item_id: id});
    }

    const gotoUser = (id) => {
        history.push("/admin/dashboard/user", {user_id: id});
    }

    return (
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Reports</h3>
            <section>
                <article>
                    <div style={{minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <ReportContext.Provider value={{activeTab, setActiveTab, adminReportedItemIsLoading, adminReportedItemError, adminReportedItemData, gotoItem, gotoUser, reportedId }} >
                            <ReportsList />
                        </ReportContext.Provider>
                        
                    </div>
                </article>
            </section> 
        </section> 
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminReportedItemLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(ReportSection);
