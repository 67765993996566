export const theme = {
    color: {
        white: '#ecf0f1',
        black: '#2f3640',
        blue: '#2980b9', 
        orange: '#FB770D',
        primaryBg: '#00aaff',
        secondaryBg: '#66ccff',
        primayColor: '#f2f2f2',
        secondaryColor: '#e6e6e6',
    },
    font: {
        primary: 'Arial',
    },
    text: {
        currencyCode: 'SGD',
        fkPrimePremiumTitle: 'PREMIUM',
        fkPrimePremiumDescription: 'Place your listing on top.',
        fkPrimeHighlightTitle: 'HIGHLIGHT',
        fkPrimeHighlightDescription: 'Highlight your listing',
        fkPrimeComboTitle: 'COMBO PREMIUM & HIGHLIGHT',
        fkPrimeComboDescription: 'Place your listing on top and highlighted ',
        fkPrimeAutoBumpTitle: 'AUTO BUMP',
        fkPrimeAutoBumpDescription: 'Bump when listing reach the 3rd page',
        daysLabel: 'days',
        chooseBtn: 'Choose',
        chooseComboBtn: 'Choose Combo',
        chooseBumpsBtn: 'Choose Bumps',
        brand: 'Fishingkaki',
        sitekey: '6Ldv39oUAAAAAAVqlkJBIxvyRLP1qjaorXX211Fq',
        forumLinks: 'https://forum.fishingkaki.com/',
        contactUs: [
            {label: 'Classifieds'},
            {label: 'Login Issues'},
            {label: 'Payment Issues'},
            {label: 'Change Email'},
            {label: 'Advertising'},
            {label: 'Forum'},
            {label: 'Delete Account'},
            {label: 'Others'}
        ],
        signup: [
            {label: 'Must have at least one lowercase character. ', regexp: /[a-z]/g},
            {label: 'Must have at least one uppercase character. ', regexp: /[A-Z]/g},
            {label: 'Must have at least one number. ', regexp: /[0-9]/g},
        ],
        signupLength: 'Must at least 8 character',
        loginCredentialsError: 'The email or password you entered is incorrect',
        sideBar: [
            // {label: 'Profile', path: 'profile', icon: 'fas fa-id-badge'},
            {label: 'Listings', path: 'listing', icon: 'fas fa-th-list'},
            {label: 'Change Password', path: 'change-password', icon: 'fas fa-key'},
            {label: 'WatchList', path: 'watchlist', icon: 'fas fa-list'},
            {label: 'Ratings', path: 'ratings', icon: 'fas fa-star-half-alt'},
            {label: 'Booster Status', path: 'booster-status', icon: 'fas fa-rocket'},
            {label: 'Purchase History', path: 'purchase-history', icon: 'fas fa-store-alt'},
            {label: 'Messages', path: 'messages/inbox', icon: 'fas fa-mail-bulk'},
            {label: 'Logout', path: '', icon: 'fas fa-power-off'},
        ],
        itemNew: [
            {label: 'New', value: 'new'},
            {label: 'Used', value: 'use'}
        ],
        header: [
            {label: 'Credits', icon: 'far fa-user', path: '/user/wallet'},
            {label: 'Top-up', icon: 'far fa-heart', path: '/user/likes'},
            {label: 'messages', icon: 'far fa-comment', path: '/user/messages/inbox'},
            {label: 'Settings', icon: 'fas fa-cog', path: '/user/settings'},
        ],
        headerNoSession: [
            {label: 'Login', icon: 'fas fa-sign-in-alt', path: '/login'},
            {label: 'Register', icon: 'fas fa-user-plus', path: '/signup'}
        ],
        profileOptions: [
            {label: 'Listings', path: 'listing', icon: 'fas fa-th-list'},
            {label: 'Purchase', path: 'subscription', icon: 'fas fa-user-cog'},
            {label: 'Coins', path: 'wallet', icon: 'fas fa-wallet'},
            {label: 'Reviews', path: 'reviews', icon:'fas fa-star-half-alt'}
            // {label: 'Edit Profile', path: 'settings', icon: 'fas fa-user-cog'},
        ],
        mobileProfileOptions: [
            {label: 'Listings', path: 'listing', icon: 'fas fa-th-list'},
            {label: 'Purchase', path: 'subscription', icon: 'fas fa-user-cog'},
            {label: 'Coins', path: 'wallet', icon: 'fas fa-wallet'}
        ],
        sampleCategory: [
            {title: 'Reels'},
            {title: 'Lures'},
            {title: 'Rods'},
            {title: 'Lines'},
            {title: 'Terminal Tackle'}
        ],
        searchOption: [
            {label: 'Recent', value: 'recent'},
            {label: 'Price - High to Low', value: 'highToLow'},
            {label: 'Price - Low to High', value: 'lowToHigh'},
        ],
        sy : '1997',
        sampleItem: [
            {title: 'sample #1', price: '599', views: '999'},
            {title: 'sample #2', price: '59', views: '999'},
        ],
        sampleBooster: [
            {title: 'sample boost #1', exp_date: '2019-08-14 10:37:24'},
            {title: 'sample boost #1', exp_date: '2019-08-06 10:37:24'},
            {title: 'sample boost #1', exp_date: '2019-07-14 10:37:24'},
        ],
        sampleTransaction: [
            {transactionID: '', type: 'wallet', status: 'completed', date: '2018-10-24'},
            {transactionID: '65AHGFHGF789GAHGF', type: 'paypal', status: 'completed', date: '2018-10-24'},
            {transactionID: '', type: 'wallet', status: 'completed', date: '2018-10-24'},
            {transactionID: '', type: 'wallet', status: 'completed', date: '2018-10-24'},
            {transactionID: '9085BFHGF789GAUIO', type: 'wallet', status: 'completed', date: '2018-10-24'}
        ],
        messageLink: [
            {label: 'Inbox', path: 'inbox', icon: 'fas fa-inbox'},
            // {label: 'Archive', path: 'archive', icon: 'fas fa-archive'},
            // {label: 'Trash', path: 'trash', icon: 'fas fa-trash'}
        ],
        sampleMessage: [
            {sender: 'fk user 01', message:'lorem ipsum...', date: '2018-10-24 12:30pm'},
            {sender: 'fk user 02', message:'lorem ipsum...', date: '2018-10-24 3:00pm'},
            {sender: 'fk user 03', message:'lorem ipsum...', date: '2018-10-24 12:04pm'}
        ],
        error: {
            default: 'Something went wrong try again!',
            wrongEmail: 'Incorrect Email, try again!',
            wrongPassword: 'Incorrect Password, try again!',
            emailNotVerified: 'Email not yet verified, check your inbox to verify',
            filterInputError: 'Input Error! Minimum field should be lower than maximum field',
            noData: 'No items yet in this category'
        },
        message: {
            signup: [
                {title: 'Verify your email address', info: 'Please check your email to verify account', svgComponent: 'SignupVerifySVG'}
            ],
            registerSuccess: 'Account registered, check your email to validate',
            // linkExpired: 'Verification expired. Click the button to resend verification',
            linkExpired: 'Email Verification Expired',
            verifyEmail: 'Verifying email...',
            verifiedEmail: 'Email is successfully verified, You can now explore more of the features',
            welcomeMessage: 'Welcome to fishingkaki!',
            agreeTOS: ' By creating an account, I agree to the Fishingkaki',
            resendEmailVerification: 'Resend Email Verification',
        },
       
        strict: {
            digitsOnly: '/d*',
            minpw: '8',
            maxMobile: '11',
            maxPhone: '8'
        },
        itemNewForm: [
            {id: 'item_condition', label: 'Item Condition', type: 'options', required: 'Yes', options: [{label: 'New', value: 'new', type: 'radio'}, {label: 'Used', value: 'value', type: 'radio'}]},
            {id: 'title', name: 's_title', value: '', error: '', label: 'Listing Title', type: 'text', placeholder: 'Enter title', required: 'Yes'},
            {id: 'price', name: 'i_price', value: '', error: '', label: 'Price', type: 'number', prefixText: 'SGD', placeholder: '0.00', required: 'Yes', altText: 'Tip: Putting “0” in the price will show “Check with seller” for buyer.'},
            {id: 'description', name: 's_description', value: '', error: '', label: 'Description', type: 'textarea', placeholder: 'Listing item details', required: 'Yes', rows: 3},
            {id: 'deal_method', label: 'Deal Method', type: 'choices', choices: [{id: 'meet_up',label: 'Meet-up', name: 'deal_method_meetup', value: 'meetup', type: 'radio', fields: [
                {id: 's_dm_meetup_add1', name: 's_dm_meetup_add_1', value: '', error: '', type: 'location', placeholder: 'Search a place or Postal code', notes: {
                    id: 'meetupAddnotesAddress1', name: 'meetupAddnotesAddress1', value: '', error: '', type: 'textarea'
                }}, {id: 's_dm_meetup_add2', name: 's_dm_meetup_add_2', value: '', error: '', type: 'location', placeholder: 'Search a place or Postal code', notes: {
                    id: 'meetupAddnotesAddress2', name: 'meetupAddnotesAddress2', value: '', error: '', type: 'textarea'
                }}
            ]}, {id: 'shipped',label: 'Shipped', value: 'shipped', name: 'deal_method_shipped', type: 'radio', fields: [
                {id: 's_dm_shipped_details', name: 's_dm_shipped_details', value: '', error: '', type: 'textarea', placeholder: 'Enter shipping details...'}
            ]}]},
            {id: 'youtube_link', label: 'Youtube Link', type: 'link', placeholder: 'https://www.youtube.com/'}
        ],
        uploadFile: ' (Up to 4 photos)',
        noUser:'No User found!',
        publishItem: 'Publish a listing',
        sellerTools: [
            {label: 'Good Deal',  data: [
                {name: 'Premium', price: 10},
                {name: 'Highlight', price: 5},
            ]},
            {label: 'Bumps',  data: [
                {name: 'Urgent Bump', price: 10},
                {name: '3 Days Bump', price: 10},
                {name: '1 Day Bump', price: 5},
            ]}
        ],
        coinBundle: [
            {name: '50 coins', label:'50 ', price: 5, coin: 50},
            {name: '100 coins', label:'100 ', price: 10, coin: 100},
            {name: '300 coins', label:'300 ', price: 30, coin: 300},
        ],
        reachMoreBuyer: 'Reach more buyers quickly & sell faster.',
        purchaseTOS: 'By purchasing any of the above, you accept our ',
        wallet: 'Wallet',
        payNow: 'Paynow',
        sellerToolsText: 'Seller Tools',
        paymentDetails: 'Payment Details',
        securePayment: 'Secure payment',
        dataCollectionDisclaimer: 'Any data collected is securely protected by our payment service partner',
        getMoreCoins: 'Get more Coins',
        saveMore: 'Save more with bigger bundles',
        topAd: 'Top Ad',
        highlight: 'Highlight',
        loginToLike: 'You must login to like an item.',
        reportListingHeader: 'Why are you reporting this listing?',
        reportListingOptions: [
            {label: 'Suspicious Account', disabled: false},
            {label: 'Items wrongly categorized', disabled: false},
            {label: 'Selling counterfiet items', disabled: false},
            {label: 'Duplicate post', disabled: false},
            {label: 'Irrelevant keywords', disabled: false},
            {label: 'Offensive behaviour/content', disabled: false},
            {label: 'Mispriced listing', disabled: false},
            {label: 'Selling prohibited item', disabled: false},
            {label: 'Scam', disabled: false}
        ],
        reportTabs: [
            {label: 'Item'},
            // {label: 'User'}
        ],
        adminMenu: [
            {label: 'ADMIN', menu: [
                {label: 'Dashboard', path: '/admin/dashboard'},
                {label: 'Users', menu: [
                    {label: 'User', path: '/admin/dashboard/user'},
                    {label: 'Admin', path: '/admin/dashboard/admin'},
                ]},
                {label: 'Items', path: '/admin/dashboard/item'},
                {label: 'Sales', path: '/admin/dashboard/sale'},
                {label: 'Category', path: '/admin/dashboard/category'},
                {label: 'Report', path: '/admin/dashboard/report'},
                {label: 'Services', menu: [
                    {label: 'Coin', path: '/admin/dashboard/service/coin'},
                    {label: 'Item', path: '/admin/dashboard/service/item'},
                ]},
                // {label: 'Comments', path: '/admin/dashboard/comment'},
                {label: 'Fk Prime', path: '/admin/dashboard/subscription'},
                {label: 'Logs', path: '/admin/dashboard/log'},
                {label: 'Promo', path: '/admin/dashboard/promo'},
            ]},
            {label: 'SETTING', menu: [
                {label: 'Preference', path: '/admin/dashboard/setting'},
                {label: 'Custom Field', path: '/admin/dashboard/custom_field'},
                {label: 'Ban', menu: [
                    {label: 'Keyword', path: '/admin/dashboard/ban/text'},
                    {label: 'Email', path: '/admin/dashboard/ban/email'},
                ]},
            ]}
        ],
        adminMobileMenu: [
                {label: 'Dashboard', path: '/admin/dashboard'},
                {label: 'User', path: '/admin/dashboard/user'},
                {label: 'Admin', path: '/admin/dashboard/admin'},
                {label: 'Items', path: '/admin/dashboard/item'},
                {label: 'Sales', path: '/admin/dashboard/sale'},
                {label: 'Category', path: '/admin/dashboard/category'},
                {label: 'Report', path: '/admin/dashboard/report'},
                {label: 'Coin Service', path: '/admin/dashboard/service/coin'},
                {label: 'Item Service', path: '/admin/dashboard/service/item'},
                {label: 'Fk Prime', path: '/admin/dashboard/subscription'},
                {label: 'Logs', path: '/admin/dashboard/log'},
                {label: 'Promo', path: '/admin/dashboard/promo'},
                {label: 'Preference', path: '/admin/dashboard/setting'},
                {label: 'Custom Field', path: '/admin/dashboard/custom_field'},
                {label: 'Ban Keyword', path: '/admin/dashboard/ban/text'},
                {label: 'Ban Email', path: '/admin/dashboard/ban/email'},
        ],
        insufficientFunds: 'Insufficient funds. Please add coins to proceed.',
        buyerOfferStatus: {
            temporaryWaitingToMakeOffer: {codeValue: 'temporary_waiting_to_make_offer', displayValue: ''},
            makeOffer: {codeValue: 'make_offer', displayValue: 'Make Offer'},
            madeOffer: {codeValue: 'made_offer', displayValue: 'Made an offer of SGD'},
            cancelOffer: {codeValue: 'cancel_offer', displayValue: 'Cancel Offer'},
            editOffer: {codeValue: 'edit_offer', displayValue: 'Edit Offer'},
            waitingOfferApproval: {codeValue: 'waiting_offer_approval', displayValue: 'Waiting Offer Approval'},
            waitingItemSold: {codeValue: 'waiting_item_sold', displayValue: 'Waiting Item Sold'},
            leaveSellerReview: {codeValue: 'leave_seller_review', displayValue: 'Leave review'},
            leaveSellerReviewDone: {codeValue: 'leave_seller_review_done', displayValue: 'Item is sold. See review'}

        },
        sellerOfferStatus: {
            waitingForOffer: {codeValue: 'waiting_for_offer', displayValue: 'You are waiting for an offer . . .'},
            offerApproval: {codeValue: 'offer_approval', displayValue: 'Offer has been made for SGD'},
            declineOffer: {codeValue: 'decline_offer', displayValue: 'Decline Offer'},
            acceptOffer: {codeValue: 'accept_offer', displayValue: 'Accept Offer'},
            reserved: {codeValue: 'reserved', displayValue: 'Reserved'},
            unReserved: {codeValue: 'unreserved', displayValue: 'Unreserved'},
            markAsSold: {codeValue: 'mark_as_sold', displayValue: 'Mark As Sold'},
            leaveBuyerReview: {codeValue: 'leave_buyer_review', displayValue: 'Leave review'},
            leaveBuyerReviewDone: {codeValue: 'leave_buyer_review_done', displayValue: 'Item is sold. See review'}
        },
        buyerRatingStatus: {
            buyerRequestReview: {codeValue: 'buyer_request_review', displayValue: 'Request Review', description: 'Send link to give permission to seller to leave you a review.'},
            buyer_review_submitted: {codeValue: 'buyer_review_submitted', displayValue: 'View Review', description: 'Send link to give permission to seller to leave you a review.'},
        },
        sellerRatingStatus: {
            sellerRequestReview: {codeValue: 'seller_request_review', displayValue: 'Request Review', description: 'Send link to give permission to buyer to leave you a review.'},
            seller_review_submitted: {codeValue: 'seller_review_submitted', displayValue: 'View Review', description: 'Send link to give permission to buyer to leave you a review.'},
        },
        rateBuyer: 'RATE BUYER',
        rateSeller: 'RATE SELLER',
        youSentReviewLink: 'You have sent a review link.',
        buyerSentReviewLink: 'Buyer have sent a review link.',
        sellerSentReviewLink: 'Seller have sent a review link.',
        buyerRespondedToReviewLink: 'Buyer has responded to a review link.',
        sellerRespondedToReviewLink: 'Seller has responded to a review link.',
        linkWillExpire: 'Link will expired:',
        shareDetailsOfOwnTransaction: 'Share details of your own transaction experience with',
        ratingDisclaimer: 'This review will appear publicly with your profile name and picture within the FishingKaki site.',

        chatCustomText: {
            madeAnOffer: 'Made an Offer',
            editOffer: 'Edit Offer',
            cancelledOffer: 'Cancelled Offer',
            acceptedAnOffer: 'Offer Accepted',
            offer: 'Offer',
            decline: 'Declined',
            itemHasBeenReserved: 'Item has been reserved.',
            senderSide: {
                madeAnOffer: '. . . has made an offer of',
                editOffer: '. . . has edited their offer to',
                acceptedAnOffer: '. . . has accepted your offer of',
                cancelledOffer: '. . . has cancelled your offer of'
            },
            receiverSide: {
                madeAnOffer: 'You have made an offer of . . .',
                editOffer: 'You have edited your offer to . . .',
                acceptedAnOffer: 'You accepted an offer of. . .',
                cancelledOffer: 'You cancelled your offer . . .'
            }
        }, 
        leaveAReview: 'Leave a review',
        ratingTitle: 'How was the experience with',
        ratingTitleTwo: 'You have 14 days to leave review after the listing item is sold.',
        productRateLabel: 'How was the item?',
        productRateDescription: 'The actual item matched exactly with the listing description and photos.',
        communicationRateLabel: 'How was the communication?',
        communicationRateDescription: 'Seller was polite and friendly, and the overall chat experience was pleasant.',
        meetupShippingRateLabel: 'How was the meet up / shipping?',
        meetupShippingRateDescription: 'The coordination was smooth. Seller was punction to the meetup, or shipping was on time',
        paymentRateLabel: 'How was the collection of payment?',
        paymentRateDescription: 'Buyer made the payment on time without having to be reminded multiple times.',
        reviewNote: 'This review will appear publicly with your profile name and picture within the FishingKaki site.',
        itemAlreadySold: 'Item is already sold',
        itemAlreadyReserved: 'Item is already reserved',
        markAsSoldDisclaimer: '*Premium subscriptions will auto expire once the item is marked as sold and credits will not be refunded. Best of luck on your sale!',
        eType: [
            {label: 'STRING'},
            {label: 'INTEGER'},
            // {label: 'BOOLEAN'}
        ],
        fieldType: [
            {label: 'STRING'},
            {label: 'INTEGER'},
            {label: 'BOOLEAN'},
            {label: 'LONGTEXT'}
        ],
        fkPrimeDummyEntries: [
            {
                name: 'Free', 
                subname: '',
                price: '0', 
                discount: false, 
                boost: 
                [
                    {name: 'x5 Live Listings'}
                ],
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                name: '1', 
                subname: 'Month',
                price: '9.90', 
                discount: false, 
                boost: 
                [
                    {name: 'x5 Live Listings'},
                    {name: 'x1 Top Ad'},
                    {name: 'x2 Highlighted Ad'},
                    {name: 'x3 Auto Bump'},
                    {name: 'Unlock Cover Photo'},
                    {name: 'Youtube Link'},
                    {name: 'Discount label'}
                ],
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                name: '3', 
                subname: 'Months',
                price: '28.22', 
                discount: '5%', 
                boost: 
                [
                    {name: 'x10 Live Listings'},
                    {name: 'x1 Top Ad'},
                    {name: 'x2 Highlighted Ad'},
                    {name: 'x3 Auto Bump'},
                    {name: 'Unlock Cover Photo'},
                    {name: 'Youtube Link'},
                    {name: 'Discount label'}
                ],
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                name: '6', 
                subname: 'Months',
                price: '44.55', 
                discount: '15%',
                boost: 
                [
                    {name: 'x15 Live Listings'},
                    {name: 'x1 Top Ad'},
                    {name: 'x2 Highlighted Ad'},
                    {name: 'x3 Auto Bump'},
                    {name: 'Unlock Cover Photo'},
                    {name: 'Youtube Link'},
                    {name: 'Discount label'}
                ],
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                name: '1', 
                subname: 'Year',
                price: '83.16', 
                discount: '30%',
                boost: 
                [
                    {name: 'x30 Live Listings'},
                    {name: 'x1 Top Ad'},
                    {name: 'x2 Highlighted Ad'},
                    {name: 'x3 Auto Bump'},
                    {name: 'Unlock Cover Photo'},
                    {name: 'Youtube Link'},
                    {name: 'Discount label'}
                ],
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
        ],
        oneTimePayDummyEntries: [
            {
                name: '1 Listing Slot',
                description: '1 Month Pay',
                price: '1.98'
            },
            {
                name: 'Cover Photo',
                description: '1 Month Pay',
                price: '6.90'
            },
            {
                name: 'Youtube',
                description: 'One Time Pay',
                price: '6.90'
            }
        ],
        individualAdsDummyEntries: [
            
        ],
        fkPrimePurchaseDisclaimer: [
            {text: 'Subscription Plan will start counting after 1 Month free trial.'},
            {text: 'Your subscription has auto-renew enabled. Your payment will be processed on the due date.'},
            {text: 'FK PRIME credit will replenish every month from the date of purchase. '},
            {text: 'Ads will auto expire once the item is marked as sold and credits will not be refunded. Best of luck on your sale!'}
        ],
        fkPrimeCoverDescription: 'Change the Cover Photo on your Profile Page.',
        fkPrimeYouTubeDescription: 'Enable YouTube to be previewed in your post as a video.',
        fkPrimeAutoRenewDisclaimer: {
            text: 'Your subscription has auto-renew enabled. Your payment will be processed on the due date.'
        },
        fkPrimeUnableToProcess: {
            text: 'Unable to process this payment, please try again or use alternative payment method.'
        },
        fkPrimeCancelDisclaimer: {
            text: 'Your will still have access to the subscription until the end of the period you have paid for.'
        },
        footerTexts: [
            {
                title: 'Fishingkaki', 
                links: [
                    {
                        title: 'Forum',
                        path: 'https://forum.fishingkaki.com/',
                        externalLink: true
                    },
                    {
                        title: 'Classifieds',
                        path: '/',
                        externalLink: false
                    }
                ]
            },
            {
                title: 'About Us',
                links: [
                    {
                        title: 'About Fishingkaki',
                        path: '/about-us',
                        externalLink: false
                    },
                    {
                        title: 'Privacy Policy',
                        path: '/privacy-policy',
                        externalLink: false
                    },
                    {
                        title: 'Terms of Use',
                        path: '/terms-of-use',
                        externalLink: false
                    }
                ]
            },
            {
                title: 'Learn',
                links: [
                    {
                        title: 'Coins',
                        path: '/',
                        externalLink: false
                    },
                    {
                        title: 'FK Prime',
                        path: '/',
                        externalLink: false
                    },
                    {
                        title: 'Individual Plans',
                        path: '/',
                        externalLink: false
                    }
                ]
            },
            {
                title: 'Contact Us',
                links: [
                    {
                        title: 'Contact',
                        path: '/contact-us',
                        externalLink: false
                    }
                ]
            }
        ],
        congratulations: 'Congratulations!',
        youReceived: "You've received",
        promo: 'Promo',
        collect: 'Collect',
        claimSuccess: 'Claim Success',
        checkYourProfile: 'Check your profile coins and enjoy your reward!'
    },

}