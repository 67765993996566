import React, {useState, useEffect, useReducer} from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import {numberWithCommas, slugify, categoryParser} from '../../../../../common/helper/utility'
import LikeButton from '../../../../../common/component/like'
import DefaultPhoto from '../../../../../assets/images/defaultAvatar.png'
import { ItemFlagIconLarge, PremiumLabelIcon, NewLabelIcon, SeenIcon} from '../../../../../assets/svg'
import {WTT, WTB} from '../../../../../common/component/label'
import FKImage from '../../../../../common/component/image'

const RelatedItemCard = ({related_item, likeDislikeLoading, watchList, session_id, likeDislike, sessionMobile}) => {
    const social_user = related_item && related_item.oc_t_user && related_item.oc_t_user.social && related_item.oc_t_user.social[0] && related_item.oc_t_user.social[0].data && related_item.oc_t_user.social[0].data.id ? `https://graph.facebook.com/${related_item.oc_t_user.social[0].data.id}/picture?type=large` : null
    const user_resource = related_item && related_item.oc_t_user && related_item.oc_t_user.oc_t_user_resource ? related_item.oc_t_user.oc_t_user_resource : []                  
    const id = related_item && related_item.pk_i_id ? related_item.pk_i_id : 0
    const watchlist_count = related_item && related_item.watchlist_count ? related_item.watchlist_count : 0
    const seller_id = related_item && related_item.oc_t_user && related_item.oc_t_user.pk_i_id ? related_item.oc_t_user.pk_i_id : 0
    const is_new = related_item && related_item.is_new ? related_item.is_new : false
    const categoryName = related_item && related_item.oc_t_category && related_item.oc_t_category.oc_t_category_descriptions && related_item.oc_t_category.oc_t_category_descriptions.s_name ? related_item.oc_t_category.oc_t_category_descriptions.s_name.toLowerCase() : ''
    const item_title = related_item && related_item.oc_t_item_description &&  related_item.oc_t_item_description.s_title ? related_item.oc_t_item_description.s_title : ''
    const urlTitleName = item_title ? item_title.toLowerCase().replace(/ /g, '-') : ''
    const items_count = related_item && related_item.oc_t_user && related_item.oc_t_user.items_count ? related_item.oc_t_user.items_count : 0
    // const watcher = related_item && related_item.watcher ? related_item.watcher : []
    const loggedUserId = session_id === 0 ? sessionMobile ? sessionMobile : 0 : session_id
    const data_price = related_item && related_item.i_price ? numberWithCommas((related_item.i_price / 1000000).toFixed(2)) : 0
    const data_currency_code = related_item && related_item.fk_c_currency_code ? related_item.fk_c_currency_code : `S$`
    const related_price = data_currency_code. data_price
    const [isLiked, setIsLiked] = useState(false)
    const item_slug = slugify(item_title)
    const category = related_item && related_item.oc_t_category && related_item.oc_t_category.oc_t_category_descriptions && related_item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(related_item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
    const item_description = related_item.oc_t_item_description && related_item.oc_t_item_description.s_description ? related_item.oc_t_item_description.s_description.substring(0, 100) : ''
    const cover_photo = related_item && related_item.oc_t_item_resource && related_item.oc_t_item_resource[0] && related_item.oc_t_item_resource[0].s_extension ? `${related_item.oc_t_item_resource[0].pk_i_id}.${related_item.oc_t_item_resource[0].s_extension}` : null
    const finalSLug = `/${category}/${item_slug}_i${id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`
    const post_type = related_item && related_item.post_type ? related_item.post_type : ''
    const seenCount = related_item && related_item.pageview_count ? related_item.pageview_count : 0

    const toggleLiked = (item_id) => {
        if (loggedUserId) {
          likeDislike(item_id)
        }
    
      }
useEffect(() => {
    if (watchlist_count > 0) {
      const watcher = related_item.watcher && related_item.watcher.filter(item => parseInt(item) === parseInt(loggedUserId))
      if (watcher.length !== 0) {
        setIsLiked(true)
      } else {
        setIsLiked(false)
      }
    }
    else {
      setIsLiked(false)
    }
    // eslint-disable-next-line  
  }, [related_item.watcher])

    return (
        <div className="related-item-card">
        <Link to={`/user/${seller_id}/listing`} >
        <div className="related-seller-avatar">
            {
                social_user ? 
                <img src={social_user} width={26} height={26} style={{borderRadius: '50%'}} /> :
                user_resource.length !== 0 ?
                <FKImage images={[user_resource]} type="related_seller_avatar" alt="avatar" /> :
                <img src={DefaultPhoto} width={26} height={26} alt="item owner avatar" />
                
            }
            <div className="flex flex-d-column ">
                <p className="item-related-seller-name">{related_item.oc_t_user.s_username}</p>
                <div className="flex flex-d-row flex-align-center related-seller-more-info-container">
                    {/* <p className="related-seller-followers-p">{`999 Followers`}</p> */}
                    {
                        window.innerWidth < 600 ? null : <>
                        {/* <p className="related-seller-followers-p">{`|`}</p> */}
                        <p className="related-seller-listings-p">{`${items_count} Listing${items_count === 1 ? `` : `s`}`}</p>
                        </>
                    }
                    
                </div>
            </div>
                
            </div>
        </Link>       
        <div className={`${related_item.hl_expiration && moment().isSameOrBefore(related_item.hl_expiration) ? `bg-highlight border-r-4` : ``} ${related_item.ta_expiration && moment().isSameOrBefore(related_item.ta_expiration) ? `border-premium border-r-4` : ``}`}>                
            <Link to={finalSLug} className="w-s-nowrap over-hide text-over-ellipsis">
                <div className="p-relative">
                    <FKImage images={[related_item.oc_t_item_resource[0]]} type="related-ads-thumbnail" />
                    {
                        related_item.ta_expiration && moment().isSameOrBefore(related_item.ta_expiration) ?
                        <div className="p-absolute related-ads-premium-label-container">
                            <PremiumLabelIcon />
                        </div> : null
                    }
                    {
                    is_new ?
                    <div className="p-absolute top-0 right-0">
                        <NewLabelIcon />
                    </div> : null
                    }
                </div>
                <div className="w-100 flex flex-justify-between flex-align-center">
                <p className="related-ads-item-price">{`${parseInt(related_item.i_price) === 0 ? `Check with seller` : `${related_item.fk_c_currency_code ? related_item.fk_c_currency_code : 'SGD'} ${numberWithCommas((related_item.i_price / 1000000).toFixed(2))}`}`}</p>
                {
                    post_type ?
                    post_type === 'WTS' ? null 
                    : post_type === 'WTT' ? <WTT /> : <WTB /> : null
                }
                </div>
                <p className="related-ads-item-title">{item_title}</p>
            </Link>
            <p className="related-item-condition-p">{related_item.s_item_condition ? related_item.s_item_condition === 'use' ?  related_item.s_item_condition.charAt(0).toUpperCase() + related_item.s_item_condition.slice(1) + 'd' : related_item.s_item_condition.charAt(0).toUpperCase() + related_item.s_item_condition.slice(1) : ''}</p>
            <div className="flex flex-d-row w-100 flex-align-center flex-justify-between mb-4 ps-6 ">
                <div className="flex flex-d-row flex-align-center" >
                    <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} style={'black-border-heart'}/>
                    <p className="related-ads-item-watch-count-p ml-4">{watchlist_count}</p>
                </div>
                {
                    // parseInt(seller_id) === parseInt(session_id) ? null :
                    // <div className="flex flex-align-center">
                    //     <ItemFlagIconLarge />
                    // </div>
                }
                 <div className="flex flex-d-row flex-align-center">
                    <SeenIcon color={'#232A34'} />
                    <p className="related-ads-item-watch-count-p ml-4">{seenCount}</p>
                </div>
                
            </div>
            {
                // parseInt(seller_id) === parseInt(session_id) ? 
                // <div className="related-ads-message-item-seller-container pt-8 ms-6 pb-8 cursor-pointer">
                //     <p>{`Promote`}</p>
                // </div> :
                // <div className="related-ads-message-item-seller-container pt-8 ms-6 pb-8 cursor-pointer">
                //     <p>{`Message`}</p>
                // </div>
            }
           
        </div>
        </div>
    )
}

export default RelatedItemCard