import React, { useContext, useEffect, useRef, useReducer } from 'react';
import { ChatContext } from '../../../../../../common/context'

const MobileInput = () => {
    const value = useContext(ChatContext)
    const { session, sendChat, sendIsLoading, chatRoomData } = value
    const userId = session.fkv2_session.id ? session.fkv2_session.id : null

    const chatOwnerId = chatRoomData && chatRoomData.owner_id ? chatRoomData.owner_id : null
    const chatItemId = chatRoomData && chatRoomData.item && chatRoomData.item.pk_i_id ? chatRoomData.item.pk_i_id : null
    const chatRoomId = chatRoomData && chatRoomData.id ? chatRoomData.id : null
    const [chatInput, setChatInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
        message: '',
        }
    );

    const chatInputButtomElement = useRef(null);

    useEffect(() => {
        scrollToBottom()
    }, [chatRoomData])

    const scrollToBottom = () => {
        chatInputButtomElement.current.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });

    }

    const handleChange = event => {
        const name = event.target.name;
        const newValue = event.target.value;
        setChatInput({[name]: newValue});
    }

    const handleSend = () => {
        const data = {
            owner_id: chatOwnerId,
            sender_id: userId,
            item_id: chatItemId,
            body: chatInput.message,
            chat_room_id: chatRoomId
        }
        sendChat({data})
        // eslint-disable-next-line    
        setChatInput({['message']: ''});
    }

    const keyPressed = (event) => {
        if (event.key === "Enter") {
            if (!sendIsLoading) {
                if (chatInput.message.length > 0) {
                    handleSend()
                }
            }
        }
    }

    return (
        <>
            <div className="mobile-chat-message clearfix">
                <input className="mobile-chat-input" autoComplete="off" type="text" name="message"  value={chatInput.message} placeholder={'Type a message'} onChange={(e) =>handleChange(e)} onKeyPress={keyPressed}/>
                <div className="bg-orange br-radius-50 flex flex-justify-center flex-align-center p-10">
                    <button onClick={sendIsLoading ? null : chatInput.message.length > 0 ? () => handleSend() : null}><i className="fas fa-paper-plane c-white"/></button>
                </div>
            </div> 
            <div ref={chatInputButtomElement} />
        </>
    )
}

export default MobileInput