import { DBS_TOKEN_REQUEST } from '../constant'

export const dbsTokenRequestLoad = (payload) =>{
    return {
        type: DBS_TOKEN_REQUEST.LOAD,
        payload
    }
}    

export const dbsTokenReuqestSuccess = (payload) =>{
    return {
        type: DBS_TOKEN_REQUEST.SUCCESS,
        payload,
    }
}

export const dbsTokenReuqestFailed = (payload) =>{
    return {
        type: DBS_TOKEN_REQUEST.FAIL,
        payload,
    }
}




