import React, {useReducer, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { meLoad, servicesLoad, promoteReset, purchaseCoinLoad, purchaseCoinReset, itemUserLoad, itemUpdateLoad, itemUserLoadMore, itemUserReset, servicePromoteLoad, servicePromoteReset, cancelSubscriptionLoad, cancelSubscriptionReset, updateSubscriptionLoad, updateSubscriptionReset, updateSubscriptionFailed, purchaseCoinFailed, sessionLoad} from '../../../../redux/action'
import {isEmpty, isDue} from '../../../../common/helper/utility'
import {SubscriptionHeader, ActiveSubscriptionLabel, FKPrimeDetails, DueDateContainer, Divider, FKPrimeBoostAvailability, FKPrimeSubscriptionHistory, MobileFKPrimeDetails, MobileDueDateContainer, MobileBoostContainer, MobileSubscriptionHistory, IndividualArrSubscriptionHistory, ChooseListingModalContainer, SubscriptionMainContainer, MobileSubscriptionMainContainer, CancelSubscriptionModal, SubscriptionActionModalContainer, SubscriptionRenewModalContainer, SubscriptionPaynowModalContainer, MobileFooter} from './customComponent'
import {OrangePlusIcon, OrangeMinusIcon} from '../../../../assets/svg'
import {OverlayLoader} from '../../../../common/component/loader'
import {ProfileSubscriptionContext} from '../../../../common/context'
import {SubsToFkPrimeFooter, PaymentSuccess, PaymentUnsuccessful, WholePageLoader, ScrollToTop} from '../../../../common/component/customComponents'

const FKPrimeContainer = (fkprimeArr, historyView, setHistoryView, has_lifetime_cover_photo, has_lifetime_youtube, setCancelModal, subscriptionActionModal) => {
    const status = fkprimeArr && fkprimeArr.fkPrime && fkprimeArr.fkPrime[0] && fkprimeArr.fkPrime[0].status ? fkprimeArr.fkPrime[0].status : null
    const type = fkprimeArr && fkprimeArr.fkPrime && fkprimeArr.fkPrime[0] && fkprimeArr.fkPrime[0].payment_method ? fkprimeArr.fkPrime[0].payment_method : null
    const free_listing = fkprimeArr && fkprimeArr.individual && fkprimeArr.individual.free_listing ? fkprimeArr.individual.free_listing : []
    return (
        <>
        {
            fkprimeArr.fkPrime.length || free_listing.length ? 
             <div className="subscription-details-main-container mb-30">
                 {
                     fkprimeArr.fkPrime.length === 0 ? null :
                     <div className="flex flex-d-row">
                        <div className="fk-prime-profile-active-container">
                                <ActiveSubscriptionLabel status={status} type={type}/>
                                <FKPrimeDetails fkPrime={fkprimeArr.fkPrime} />
                        </div>
                        <div className="subscription-due-date-container w-100">
                                <DueDateContainer fkPrime={fkprimeArr.fkPrime} subscriptionActionModal={subscriptionActionModal}/>
                        </div>
                    </div>
                 }
                <div className="subscription-body-container">
                    {
                        fkprimeArr.fkPrime.length === 0 ? null :
                        <>
                            <Divider />
                            <FKPrimeBoostAvailability 
                                fkPrime={fkprimeArr.individual}
                                has_lifetime_cover_photo={has_lifetime_cover_photo}
                                has_lifetime_youtube={has_lifetime_youtube}
                                status={status}
                            />
                        </>
                    }
                    {
                        historyView ?
                        <>
                        <Divider />
                        <FKPrimeSubscriptionHistory subs={fkprimeArr}/> 
                        </>:
                        null
                    }
                    <Divider />
                    <div className="w-100 flex flex-d-row flex-justify-between mt-30 mb-30">
                        <div onClick={() => setHistoryView(!historyView)} className="flex flex-d-row flex-align-center">
                            {
                                historyView ? <OrangeMinusIcon /> : <OrangePlusIcon />
                            }
                            <p className="purchase-history-btn-p no-select">{`Purchase history`}</p>
                        </div>
                        {
                            status && status.status && status.status.toLowerCase().includes('cancel') ? null :
                            <div className="flex flex-justify-end fk-prime-cancel-btn-profile" onClick={() => setCancelModal(true)}>
                                    <p className="no-select">{`Cancel Subscription`}</p>
                            </div>
                        }
                    </div>
                </div>
            </div> : null
        }
        </>
       
    )
}

const UseNowModalContainer = (useNow, setUseNow, itemsList, upto, setUpto, handleUseNowBtn, boostReset, handleInfiniteScroll, handlePromote, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError) => {
    return (
        <div className="choose-listing-main-container">
            <ChooseListingModalContainer itemList={itemsList} upto={upto} setUpto={setUpto} setUseNow={setUseNow} useNow={useNow} handleUseNowBtn={handleUseNowBtn} boostReset={boostReset} handleInfiniteScroll={handleInfiniteScroll} handlePromote={handlePromote}/>
        </div>
    )
}

const MobileFkPrimeContainer = (fkprimeArr, historyView, setHistoryView, has_lifetime_cover_photo, has_lifetime_youtube, setCancelModal, subscriptionActionModal, handleFooterFunc) => {
    const status = fkprimeArr && fkprimeArr.fkPrime && fkprimeArr.fkPrime[0] && fkprimeArr.fkPrime[0].status ? fkprimeArr.fkPrime[0].status : null
    const type = fkprimeArr && fkprimeArr.fkPrime && fkprimeArr.fkPrime[0] && fkprimeArr.fkPrime[0].payment_method ? fkprimeArr.fkPrime[0].payment_method : null
    return (
        <>
            {
                fkprimeArr.fkPrime.length === 0 ? null :
                <div className="p-relative">
                    <div>
                        <div className="flex flex-d-column mobile-profile-active-subs-container">
                            <ActiveSubscriptionLabel status={status} type={type}/>
                            <MobileFKPrimeDetails fkPrime={fkprimeArr.fkPrime} handleFooterFunc={handleFooterFunc} status={status}/>
                        </div>
                        <MobileDueDateContainer fkPrime={fkprimeArr.fkPrime}/>
                    </div>
                    <div className="mobile-profile-boost-offer-details-container">
                       <MobileBoostContainer boost={fkprimeArr.individual} />
                    </div>
                    {
                        historyView ?
                        <>
                            <Divider />
                            <MobileSubscriptionHistory boost={fkprimeArr}/>
                        </> : null
                    }
                    <Divider />
                    <div className="w-100 flex flex-d-row flex-justify-between purchase-history-btn-container">
                        <div onClick={() => setHistoryView(!historyView)} className="flex flex-d-row flex-align-center">
                            {
                                historyView ? <OrangeMinusIcon /> : <OrangePlusIcon />
                            }
                            <p className="purchase-history-btn-p">{`Purchase history`}</p>
                        </div>
                        {/* <div className="flex flex-justify-end fk-prime-cancel-btn-profile">
                            <p>{`Cancel Subscription`}</p>
                        </div> */}
                         {
                            status && status.status && status.status.toLowerCase().includes('cancel') ? null :
                            <div className="flex flex-justify-end fk-prime-cancel-btn-profile" onClick={() => setCancelModal(true)}>
                                    <p className="no-select">{`Cancel Subscription`}</p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )   
}

const IndividualPlansContainer = (individualArr, historyView, setHistoryView, handleUseNowBtn) => {
    const {useNow, used, active, notActive} = individualArr

    return (
        useNow.length === 0 && used.length === 0 && active.length === 0 && notActive.length === 0 ? null :
        
        <div className="subscription-details-main-container">
            <SubscriptionHeader
                title="Individual Plans"
                styleClass="flex flex-align-center subs-individual-plans-header-container"
                textClass="subs-individual-plans-text-p"
            />
            <div className="individual-ads-body-container">
              <SubscriptionMainContainer obj={useNow} handleUseNowBtn={handleUseNowBtn}/>
              <SubscriptionMainContainer obj={used} handleUseNowBtn={handleUseNowBtn}/>
              <SubscriptionMainContainer obj={active} handleUseNowBtn={handleUseNowBtn}/>
              <SubscriptionMainContainer obj={notActive} handleUseNowBtn={handleUseNowBtn}/>
                {
                    historyView ?
                    <>
                    {/* <Divider /> */}
                    <IndividualArrSubscriptionHistory individualArr={individualArr}/> 
                    </>:
                    null
                }
                <div className="w-100 flex flex-d-row flex-justify-between mt-30 mb-30">
                    <div onClick={() => setHistoryView(!historyView)} className="flex flex-d-row flex-align-center">
                        {
                            historyView ? <OrangeMinusIcon /> : <OrangePlusIcon />
                        }
                        <p className="purchase-history-btn-p">{`Purchase history`}</p>
                    </div>
                    {/* <div className="flex flex-justify-end fk-prime-cancel-btn-profile">
                        <p>{`Cancel`}</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const MobileIndividualPlansContainer = (individualArr, historyView, setHistoryView, handleUseNowBtn) => {
    
    const autoBumpArr = individualArr && individualArr.auto_bump ? individualArr.auto_bump : []
    const coverPhotoArr = individualArr && individualArr.cover_photo ? individualArr.cover_photo : []
    const highlightedAdArr = individualArr && individualArr.highlighted_ad ? individualArr.highlighted_ad : []
    const liveListingArr = individualArr && individualArr.live_listing ? individualArr.live_listing : []
    const topAdArr = individualArr && individualArr.top_ad ? individualArr.top_ad : []
    const youtubeLinkArr = individualArr && individualArr.youtube_link ? individualArr.youtube_link : []
    return (
        <div className="mobile-individual-plan-main-container">
            <SubscriptionHeader
                title="Individual Plans"
                styleClass="flex flex-align-center subs-individual-plans-header-container"
                textClass="subs-individual-plans-text-p"
            />
            <div className="mobile-individual-plan-body-container">
                <MobileSubscriptionMainContainer obj={autoBumpArr} handleUseNowBtn={handleUseNowBtn} />
                <MobileSubscriptionMainContainer obj={topAdArr} handleUseNowBtn={handleUseNowBtn} />
                <MobileSubscriptionMainContainer obj={highlightedAdArr} handleUseNowBtn={handleUseNowBtn} />
                {/* <MobileSubscriptionMainContainer obj={liveListingArr} handleUseNowBtn={handleUseNowBtn} /> */}
                <MobileSubscriptionMainContainer obj={coverPhotoArr} handleUseNowBtn={handleUseNowBtn} />
                <MobileSubscriptionMainContainer obj={youtubeLinkArr} handleUseNowBtn={handleUseNowBtn} />
            </div>

            <div className="w-100 flex flex-d-row flex-justify-between purchase-history-btn-container">
                        <div onClick={() => setHistoryView(!historyView)} className="flex flex-d-row flex-align-center">
                            {
                                historyView ? <OrangeMinusIcon /> : <OrangePlusIcon />
                            }
                            <p className="purchase-history-btn-p">{`Purchase history`}</p>
                        </div>
                        {/* <div className="flex flex-justify-end fk-prime-cancel-btn-profile">
                            <p>{`Unsubscribe`}</p>
                        </div> */}
                    </div>
        </div>
    )
}

const SubscriptionPage = (props) => {
    const {
        me, 
        purchase,
        item
    } = props

    const purchases = me && me.data && me.data.purchase_micro_service ? me.data.purchase_micro_service : []
    const sessionData = me && me.sessionData ? me.sessionData : []
    // const subscription_status = me && me.data && me.data.realtime_subscription_status ? me.data.realtime_subscription_status : []
    const subscription_status = me && me.data && me.data.database_subscription_status ? me.data.database_subscription_status : []
    const userId = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : false
    // const itemsList = item && item.data ? item.data : []
    const itemsList = me && me.data && me.data.active_items ? me.data.active_items : []
    const page = item && item.page ? item.page : 1
    const [historyView, setHistoryView] = useState(true)
    const [individualHistoryView, setIndividualHistoryView] = useState(true)
    const [useNowModal, setUseNowModal] = useState(false)
    const [upto, setUpto] = useState(8)
    const [cancelModal, setCancelModal] = useState(false)
    const [initialLoader, setInitialLoader] = useState(true)
    const [paymentMethod, setPaymentMethod] = useState('')
    const stripe_cus_id = me && me.data && me.data.stripe_cus_id ? me.data.stripe_cus_id : null
    const has_lifetime_cover_photo = me && me.data && me.data.has_lifetime_cover_photo ? me.data.has_lifetime_cover_photo : false
    const has_lifetime_youtube = me && me.data && me.data.has_lifetime_youtube ? me.data.has_lifetime_youtube : false
    const has_active_subscription = me && me.data && me.data.has_active_subscription ? me.data.has_active_subscription : false
    const is_free_subscription_claimed = me && me.data && me.data.is_free_subscription_claimed ? me.data.is_free_subscription_claimed : false
    const session = props.session        
    const {isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError,
        isLoadingCancelSubscription, cancelSubscriptionMessage, cancelSubscriptionError,
        isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError
    } = props.microService

    const {isLoadingPurchaseCoin, errorPurchaseCoin, purchaseSuccessMessage} = purchase

    const [subscription, setSubscription] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
        fkPrime: [],
        individual: []
    }
   )

   const [useNow, setUseNow] = useReducer(
       (state, newState) => ({...state, ...newState}),
       {
           micros_service_id: null,
           item_id: null,
           service_id: null
       }
   )

    const [subscriptionAction, setSubscriptionAction] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            isModalActive: false,
            fkPrimeArr: [],
        }
    )
    const [upgradeData, setUpgradeData] = useState([])
    const [upgradeStep, setUpgradeStep] = useState(1)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const handlePaymentMethod = (e) => {
        setPaymentMethod(e.target.value)
    }

    const subscriptionActionModal = (fkPrimeArr, showModal, stepCount) => {
        setSubscriptionAction({['isModalActive']: !subscriptionAction.isModalActive, ['fkPrimeArr']: fkPrimeArr})
        setShowUpgradeModal(showModal)
        if (!stepCount) {
            setUpgradeStep(2)
        }
        else {
            setUpgradeStep(stepCount)
        }
    }

    const handleSetUpgradeData = (fkPrimeArr, showModal, stepCount) => {
        setShowUpgradeModal(showModal)
        setUpgradeData([fkPrimeArr])
        if (!stepCount) {
        setUpgradeStep(2)
        }
        else {
        setUpgradeStep(stepCount)
        }
    }

    const handlePurchaseWhenCancel = (stepCount) => {
        if (!stepCount) {
            setUpgradeStep(2)
        }
        else {
            setUpgradeStep(stepCount)
        }
    }

   const boostReset = () => {
    setUseNow({['micros_service_id']: null, ['item_id']: null, ['service_id']: null })
    setUseNowModal(false)
   }

   const handleLoadMore = () => {
        props.itemUserLoadMore({user_id: userId, page: page})
    }

   const handleUseNowBtn = (boost) => {
       const micros_service_id = boost && boost.id ? parseInt(boost.id) : null
       const service_id = boost && boost.service_detail && boost.service_detail.id ? parseInt(boost.service_detail.id) : null
       setUseNow({['micros_service_id']: micros_service_id, ['service_id']: service_id})
       setUseNowModal(!useNowModal)
    }

    const handlePromote = (item_id) => {
        useNow.item_id = parseInt(item_id)
        boostReset()
        props.servicePromoteLoad(useNow)
    }

    useEffect(() => {
        if(sessionData.length === 0){
            props.sessionLoad({id: userId})
        }
    }, [sessionData])

    const urlParams = new URLSearchParams(window.location.search);
    const stripeSessionId = urlParams.get('session_id');

    useEffect(() => {
        if (stripeSessionId && stripeSessionId === 'CANCELLED') {
            setShowSubscriptionRenew(true)
            handleRenewFailed()
            setTimeout(() => {
                purchaseCoinReset()
                setShowSubscriptionRenew(false)
            }, 3000)
        }
        else if (stripeSessionId ) {
            setShowSubscriptionRenew(true)
            let data = {payment_method: 'stripe', type: 'fk_prime'}
            props.purchaseCoinLoad({ fkv2_purchased_data: { ...data, order_id: stripeSessionId }, userId: userId })
            // window.location.replace(`${process.env.REACT_APP_BASE_URL}user/subscription`);
            window.history.pushState({}, document.title, "/" + "user/subscription" );
        }
    // eslint-disable-next-line
    }, [stripeSessionId])

    useEffect(() => {
        if(useNowPromoteMessage || useNowPromoteError){
          setTimeout(() =>{
            props.servicePromoteReset()
          }, 2000)
        }
    }, [useNowPromoteMessage, useNowPromoteError])

   const statusLabelParser = (type, item_id, due_date) => {
        let status
        if (type === 'cover_photo' || type === 'youtube_link') {
            if (new Date(due_date) > new Date()) {
                status = 'Active'
            } else {
                status = 'Not Active'
            }
        } else {
            if (!item_id) {
                status = 'Use Now'
            } else {
                status = 'Used'
            }
        }
        return status
    }

   const sortArr = (arr) => {
    let fkPrimeArr = []
    let youtube = []
    let cover = []
    let listing = []
    let top_ad = []
    let highlight = []
    let auto_bump = []
    let individual_arr = []
    let useNow = []
    let used = []
    let active = []
    let notActive = []
    let free = []
    arr.map((p) => {
        if(p.type === 'fk_prime'){
            fkPrimeArr.push({...p, status: subscription_status})
        }
        else {
            const isActiveByDueDate = isDue(p.due_date)
            if (isActiveByDueDate) {
                individual_arr.push(p)
                if(p.type === 'youtube_link'){
                    youtube.push(p)
                }
                if(p.type === 'cover_photo'){
                    cover.push(p)
                }
                if(p.type === 'live_listing'){
                    listing.push(p)
                }
                if(p.type === 'top_ad'){
                    top_ad.push(p)
                }
                if(p.type === 'auto_bump'){
                    auto_bump.push(p)
                }
                if(p.type === 'highlight'){
                    highlight.push(p)
                } 
                if (p.type !== 'live_listing') {
                    const label = statusLabelParser(p.type, p.item_id, p.due_date)
                    if (label === 'Use Now') {
                        useNow.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                    } else if(label === 'Used') {
                        used.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                    } else if(label === 'Active') {
                        active.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                    } else if(label === 'Not Active') {
                        notActive.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                    } 
                }
                if(p.type === 'live_listing' && p.payment_method.includes('free')){
                    free.push(p)
                }
            }
        }
    })
    return setSubscription({['fkPrime']: fkPrimeArr.reverse(), individual: {
        ['useNow']: useNow, ['used']: used, ['active']: active, ['notActive']: notActive,
        ['youtube_link']: youtube, ['cover_photo']: cover, ['live_listing']: listing, ['top_ad']: top_ad, ['highlighted_ad']: highlight, ['auto_bump']: auto_bump,
        ['free_listing']: free
    }})
   }

   useEffect(() => {
        if(purchases.length !== 0){
            sortArr(purchases)
            // console.log("purchases", purchases)
        }
    }, [me])

    useEffect(() => {
        setTimeout(() => {
            setInitialLoader(false)
        }, 3000)
    },[])


   useEffect(() => {
    props.itemUserLoad({user_id: userId})
    return () => {
    props.itemUserReset()
    }
    }, [userId])

    const handleCancelSubscription = () => {
        const id = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].id ? subscription.fkPrime[0].id : null
        const transaction_id = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].transaction_id ? subscription.fkPrime[0].transaction_id : null
        const payment_method = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].payment_method ? subscription.fkPrime[0].payment_method : null

        props.cancelSubscriptionLoad({id, transaction_id, payment_method})
    }

    useEffect(() => {
        if(cancelSubscriptionMessage || cancelSubscriptionError){
          setTimeout(() =>{
            setCancelModal(false)
            props.cancelSubscriptionReset()
          }, 2000)
        }
    }, [cancelSubscriptionMessage, cancelSubscriptionError])

    const servicesData = purchase && purchase.servicesData ? purchase.servicesData : []
    const servicesLoading = purchase && purchase.isLoading ? purchase.isLoading : null
    const servicesError = purchase && purchase.error ? purchase.error : []

    const handleServicesLoad = () => {
        props.servicesLoad(true)
    }

    //migrated state and function from fk prime
    const defaultUserBoost = {
        boostID: '',
        boostOtpId: [],
        boostOtpDetails: [],
        boostOtpTotal: 0,
        otpMultiQuantity: 1,
        boostDetails: [],
        multiSelectionID: '',
        boostTotal: [],
        combo_adsTotal: 0,
        base_adsTotal: 0,
        auto_bumpTotal: 0,
        individualAdsTotal: 0,
        individualAdsArr: [],
        individualAdsIds: [],
        mobileIsPeek: [],
        buyCoins: {
            coinsArr: [],
            userId: session.fkv2_session.id,
            payment_method: '',
            total: 0,
            total_coin: 0,
            type: ''
        }
    }

    const [userBoost, setUserBoost] = useReducer(
        (state, newState) => ({...state, ...newState}), defaultUserBoost
    )

    const handleBoostSelection = (type, id, data, action) => {
        let boostTotals = userBoost.boostTotal;

        if(type === 'fk_prime' || type === 'fkPrime'){
            if(id === parseInt(userBoost.boostId)){
                setUserBoost({['boostID']: '', ['boostDetails']: []})
            }else{
               userBoost.boostTotal.length !== 0 ? boostTotals = [{price: data.price, id: data.id}] : boostTotals.push({price: data.price, id: data.id})
                setUserBoost(
                    {
                        ['boostID']: id, 
                        ['boostDetails']: data, 
                        ['boostTotal']: boostTotals
                    }
                    )
            }
        }
    }


    const handleUpdateSubscription = (data) => {
        props.updateSubscriptionLoad(data)
    }

    const resetUpgrade = () => {
        setUpgradeData([])
        setUpgradeStep(1)
        setShowUpgradeModal(false)
    }

    useEffect(() => {
        if(updateSubscriptionMessage || updateSubscriptionError){
          setTimeout(() =>{
            // window.location.replace(`${process.env.REACT_APP_BASE_URL}user/subscription`);
            window.location.reload(false)
          }, 2000)
        }
    }, [updateSubscriptionMessage, updateSubscriptionError])


    const handleUpgradeError = () => {
        props.updateSubscriptionFailed('Error upgrading, try again.')
    }


    //renew
    const [showSubscriptionRenew, setShowSubscriptionRenew] = useState(false)
    const [subscriptionRenewData, setSubscriptionRenewData] = useState(null)
    const toggleSubscriptionRenewData = (data, show) => {
        setShowSubscriptionRenew(show)
        setSubscriptionRenewData(data)
    }

    useEffect(() => {
        if(errorPurchaseCoin, purchaseSuccessMessage){
          setTimeout(() =>{
            // window.location.replace(`${process.env.REACT_APP_BASE_URL}user/subscription`);
            window.location.reload(false)
          }, 2000)
        }
    }, [errorPurchaseCoin, purchaseSuccessMessage])

    const handleRenewSubscription = (data, input) => {
        props.purchaseCoinLoad({ fkv2_purchased_data: { ...data, ...input }, userId: userId })
    }
    const s_email = me && me.data && me.data.s_email ? me.data.s_email : null

    const handleRenewFailed = () => {
        props.purchaseCoinFailed('Error renewing, try again.')
    }

    const handleCoinReset = () => {
        props.purchaseCoinReset()
    }

    //paynow
    const [showSubscriptionPayNow, setShowSubscriptionPayNow] = useState(false)
    const [subscriptionPayNowData, setSubscriptionPayNowData] = useState(null)
    const toggleSubscriptionPaynowData = (data, show) => {
        setShowSubscriptionPayNow(show)
        setSubscriptionPayNowData(data)
    }

    const NoSubscriptionContainer = () => {
        return (
            <div className="no-active-subscription-main-container">
                <p className="no-active-subs-label-p">{`You have no active subscription`}</p>
                <Link to={`/fk-prime`} className="no-subs-go-to-fk-btn">
                    <p>{`Go to FK Prime to subscribe`}</p>
                </Link>
            </div>
        )
    }

    const MobileContainer = () => {

       const status = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].status && subscription.fkPrime[0].status.status ? subscription.fkPrime[0].status.status : ''
       const fkPrime = subscription && subscription.fkPrime ? subscription.fkPrime : []
       const price = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].service_detail && subscription.fkPrime[0].service_detail.sg_price

       const handleFooterFunc = () => {
        return status && status.toLowerCase().includes('active') ? subscriptionActionModal(fkPrime, true, 1) : status && status.toLowerCase().includes('cancel') ? subscriptionActionModal(fkPrime, true, 1) : toggleSubscriptionPaynowData(fkPrime, true)
        }
       return (
           <>
            <div className={subscription.fkPrime.length === 0 ? `` : `p-relative`}>
                {
                    // subscription.fkPrime.length !== 0 ?
                    // MobileFkPrimeContainer(subscription, historyView, setHistoryView, has_lifetime_cover_photo, has_lifetime_youtube, setCancelModal, subscriptionActionModal, handleFooterFunc) : NoSubscriptionContainer()
                }
                {
                    isEmpty(subscription.individual) === false ?
                    MobileIndividualPlansContainer(subscription.individual, individualHistoryView, setIndividualHistoryView, handleUseNowBtn) : null
                }
                {
                    useNowModal ?
                    UseNowModalContainer(useNow, setUseNow, itemsList, upto, setUpto, handleUseNowBtn, boostReset, handleLoadMore, handlePromote, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError) : null
                }
                {
                    // subscription.fkPrime.length === 0 ?
                    // <SubsToFkPrimeFooter /> : 
                    // <MobileFooter handleFooter={handleFooterFunc} btnText={status === 'active' || status === 'ACTIVE' ? 'Upgrade' : status.includes('CANCELLED') || status.includes('canceled') ? `Purchase` : `Pay now`}/>
                }
            </div>
            { cancelModal ? <CancelSubscriptionModal sharedProps={{cancelModal, setCancelModal, subscription, handleCancelSubscription, isLoadingCancelSubscription, cancelSubscriptionMessage, cancelSubscriptionError}}/> : null}
            { showUpgradeModal ? <SubscriptionActionModalContainer/> : null}
            { showSubscriptionRenew ? <SubscriptionRenewModalContainer/> : null}
            { showSubscriptionPayNow ? <SubscriptionPaynowModalContainer/> : null}
           </>
       )
   }

   const DesktopContainer = () => {
       return (
        isLoadingUseNowPromote ? <OverlayLoader /> :
        useNowPromoteMessage ? <div className="p-fixed top-0 left-0 w-100 h-100 bg-black-op-3"> <div className="bg-white"><PaymentSuccess text={useNowPromoteMessage} /></div> </div> :
        useNowPromoteError ? <div className="p-fixed top-0 left-0 w-100 h-100 bg-black-op-3"> <div className="bg-white"><PaymentUnsuccessful text={useNowPromoteError} /></div> </div> : 
        <>
            {
                // subscription.fkPrime.length !== 0 ?
                // FKPrimeContainer(subscription, historyView, setHistoryView, has_lifetime_cover_photo, has_lifetime_youtube, setCancelModal, subscriptionActionModal) : NoSubscriptionContainer()
            }            
            {
                isEmpty(subscription.individual) === false ? 
                IndividualPlansContainer(subscription.individual, individualHistoryView, setIndividualHistoryView, handleUseNowBtn) : null
            }
            {
                useNowModal ?
                UseNowModalContainer(useNow, setUseNow, itemsList, upto, setUpto, handleUseNowBtn, boostReset, handleLoadMore, handlePromote, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError) : null
            }
            { cancelModal ? <CancelSubscriptionModal sharedProps={{cancelModal, setCancelModal, subscription, handleCancelSubscription, isLoadingCancelSubscription, cancelSubscriptionMessage, cancelSubscriptionError}}/> : null}
            { showUpgradeModal ? <SubscriptionActionModalContainer/> : null}
            { showSubscriptionRenew ? <SubscriptionRenewModalContainer/> : null}
            { showSubscriptionPayNow ? <SubscriptionPaynowModalContainer/> : null}
        </>
       )
   }

    return (
        <ScrollToTop>
            <ProfileSubscriptionContext.Provider value={{
                subscriptionAction,  subscriptionActionModal, stripe_cus_id, session, upgradeStep, setUpgradeStep, subscription, handleServicesLoad, servicesData, servicesLoading, servicesError, has_active_subscription,
                userBoost, setUserBoost, is_free_subscription_claimed, showUpgradeModal, upgradeData, handleSetUpgradeData, handlePurchaseWhenCancel,
                isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError, handleUpdateSubscription, handleUpgradeError, toggleSubscriptionRenewData, handleRenewSubscription, subscriptionRenewData, s_email,
                isLoadingPurchaseCoin, errorPurchaseCoin, purchaseSuccessMessage, handleRenewFailed, handleCoinReset, subscriptionPayNowData, toggleSubscriptionPaynowData, handleBoostSelection,
                sessionData, paymentMethod, setPaymentMethod,handlePaymentMethod
            }}>
                <div className={window.innerWidth < 600 ? `` : `bg-white`}>
                    {   
                        initialLoader ? <WholePageLoader /> :
                        window.innerWidth < 600 ? MobileContainer() :
                        DesktopContainer()
                    }
                </div>
            </ProfileSubscriptionContext.Provider>
        </ScrollToTop>
    )
}

const mapStateToProps = store => {
    return {
        item: store.itemUserReducer,
        me: store.userReducer,
        purchase: store.purchaseReducer,
        microService: store.microServiceReducer

    };
};

const mapActionToProps = {
    itemUserLoad,
    itemUpdateLoad, 
    itemUserLoadMore,
    itemUserReset,
    meLoad,
    servicesLoad,
    promoteReset,
    purchaseCoinLoad,
    purchaseCoinReset,
    servicePromoteLoad, 
    servicePromoteReset,
    cancelSubscriptionLoad, 
    cancelSubscriptionReset,
    updateSubscriptionLoad, 
    updateSubscriptionReset, 
    updateSubscriptionFailed,
    purchaseCoinFailed,
    sessionLoad,
}

export default connect(mapStateToProps, mapActionToProps)(SubscriptionPage)