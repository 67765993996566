import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getItems = (payload) => {
    const { first, page } = payload
    try {
        const query = gql `query getAllItems($first: Int, $page: Int, $orderBy: [OrderByClause!]!){
            getAllItems(first: $first, page: $page, orderBy: $orderBy){
            paginatorInfo{
              total
              hasMorePages
            }
            data{
              pk_i_id
              dt_pub_date
              dt_mod_date
              b_active
              oc_t_item_description {
                fk_i_item_id
                s_title
                s_description
              }
              oc_t_user{
                pk_i_id
                s_username
              }
              oc_t_category{
                pk_i_id
                fk_i_parent_id
                oc_t_category_descriptions{
                  fk_i_category_id
                  s_name
                }
                oc_t_parent_category_descriptions{
                  fk_i_category_id
                  s_name
                }
              }
             }
           }
        }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderBy: 
              [
                {field: "dt_pub_date", order: "DESC"}
              ] 
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
  }

  export const getItemDetail = (id) => {
    try{
      const query = gql `query adminGetItemById($id: ID){
        adminGetItemById(pk_i_id: $id){
          pk_i_id
          b_active
          b_spam
          oc_t_item_description{
            s_title
            s_description
          }
          fk_i_category_id
          oc_t_category{
            pk_i_id
            fk_i_parent_id
            oc_t_category_descriptions{
              fk_i_category_id
              s_name
            }
          }
              oc_t_user{
            pk_i_id
            s_name
            s_username
          }
          get_i_price
          s_contact_email
          ta_expiration
          hl_expiration
          oc_t_item_description{
            s_title
            s_description
          }
        }
      }`;
  
      const response = client.query({
        query: query,
        variables: {"id": id}
      });
  
      const data = response ? response : null;
      return data;
    } catch(e) {
      throw e
    }
  }

  export const updateItem = (payload) => {
    const gql_query = gql`mutation itemAdminUpdate($data: ItemCreateInput){
        itemAdminUpdate(data: $data){
        message
        response{
          b_active
          b_spam
          pk_i_id
          oc_t_item_description{
            s_title
            s_description
          }
          oc_t_category{
            pk_i_id
            fk_i_parent_id
            oc_t_category_descriptions{
              fk_i_category_id
              s_name
            }
          }
          oc_t_user{
            pk_i_id
            s_name
            s_username
          }
          i_price
          get_i_price
          s_contact_name
          s_contact_email
          ta_expiration
          hl_expiration
          oc_t_item_description{
            s_title
            s_description
          }
        }
      }
    }`
    const variables = {data: payload}        
    
  try{
        const query = gql_query;
        const response = client.mutate({
            mutation: query,
            variables: variables
        });
        const data = response ? response : null;
        return data;
  }catch(e){
      throw e
  }
}

export const searchItemsForSuggest = (data) => {
    const {type, first, page, search, orderField, orderType, price_from, price_to} = data 
    try {
        const query = gql`query itemAllSearchSuggest($type: String, $first: Int!, $page: Int!, 
            $search: String!, $orderField: String!, $orderType: String!,
            $price_from: Int, $price_to: Int
          ){
            itemAllSearchSuggest(
                      orderField: $orderField,
                      orderType: $orderType
                    ,
                  search: $search,
                  first: $first,
                  page: $page,
                  i_price: {
                      price_from: $price_from,
                      price_to: $price_to
                  }
                  type: $type
            ){
              count
              data{
                  pk_i_id
                  b_active
                  oc_t_item_description{
                    s_title
                  }
                  oc_t_category{
                    oc_t_category_descriptions{
                      s_name
                    }
                  }
                  oc_t_user{
                    pk_i_id
                    s_username
                  }
              }
            }
        }`;
  
        const inputs = {type: type, first: first, page: page, search: search, 
          orderField: orderField, orderType: orderType,
          price_from: price_from, price_to: price_to
        }
  
        const response = client.query({
            query: query,
            variables: inputs
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }

  export const getItemsByUser = (data) => {
    const {first, page, fk_i_user_id, orderField, orderType } = data 
    try {
        const query = gql`query getItemsByUser($first: Int, $page: Int, $fk_i_user_id: Int!, $orderField: String!, $orderType: SortOrder!){
          getItemsByUser(fk_i_user_id: $fk_i_user_id, first: $first, page: $page,
              orderBy: [
             {
              field: $orderField,
              order: $orderType
             }
            ]
          ){
            data {
                pk_i_id
              dt_pub_date
              dt_mod_date
              b_active
              oc_t_item_description {
                fk_i_item_id
                s_title
                s_description
              }
              oc_t_user{
                pk_i_id
                s_username
              }
              oc_t_category{
                pk_i_id
                fk_i_parent_id
                oc_t_category_descriptions{
                  fk_i_category_id
                  s_name
                }
                oc_t_parent_category_descriptions{
                  fk_i_category_id
                  s_name
                }
              }
            }
          }
        }`;
  
        const inputs = {first: first, page: page, fk_i_user_id: fk_i_user_id, 
          orderField: orderField, orderType: orderType
        }
  
        const response = client.query({
            query: query,
            variables: inputs
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }

  export const getReportedItems = (data) => {
    const {first, page, type, orderField, orderType } = data 
    try {
        const query = gql`query getReportedByType($type: String!, $first: Int, $page: Int,  $orderField: String, $orderType: SortOrder){
          getReportedByType(type: $type, first: $first, page: $page,
            orderField: $orderField, orderType: $orderType
          ){
            count
            reports {
              id
              reported_by {
                pk_i_id
                s_username
              }
              reported_item{
                pk_i_id
                oc_t_item_description{
                  s_title
                }
                oc_t_user {
                  pk_i_id
                  s_username
                  b_active
                }
                b_active
              }
              report_reason
              created_at
              report_type
            }
          }
        }`;
  
        const inputs = {first: first, page: page, type: type,
          orderField: orderField, orderType: orderType
        }
  
        const response = client.query({
            query: query,
            variables: inputs
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }

  export const deleteItem = (payload) => {
    const {id, userId} = payload
    const gql_query = gql`mutation adminItemDelete($pk_i_id: ID!, $fk_i_who_id: Int!, $s_section: String, $s_action: String, $s_who: String){
      adminItemDelete(pk_i_id: $pk_i_id, fk_i_who_id: $fk_i_who_id, s_section: $s_section,
      s_action: $s_action, s_who: $s_who
      ){
        message
      }
    }`
    const variables = {
      pk_i_id: parseInt(id),
      fk_i_who_id: parseInt(userId),
      s_section: 'item',
      s_action: 'delete',
      s_who: 'admin'
    }        
    
  try{
        const query = gql_query;
        const response = client.mutate({
            mutation: query,
            variables: variables
        });
        const data = response ? response : null;
        return data;
  }catch(e){
      throw e
  }
}

  export default {
    getItems,
    getItemDetail,
    updateItem,
    searchItemsForSuggest,
    getItemsByUser,
    getReportedItems,
    deleteItem
  }