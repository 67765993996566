import React from 'react'
import { theme } from '../../../../common/theme'

const ErrorMessage = (props) => {

    const message = props.action === 'wrong_input' ? theme.text.error.filterInputError : theme.text.error.noData
    return (
        <div>
            <p>{message}</p>
        </div>
    )
}

export default ErrorMessage