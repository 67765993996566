import React, {useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom';
import { avatarLoad, logoutLoad } from '../../redux/action'
import FKImage from '../component/image'
import Loader from '../component/loader'
import DefaultPhoto from '../../assets/images/defaultAvatar.png'
import {DropdownIcon} from '../../assets/svg'
import { getLocalStorage } from '../helper/utility'
import SocialAvatar from '../component/socialAvatar'

const Avatar = (props) => {
    const avatarProfile = useRef(null)

    // const handleClickOutside = event => {
    //     if(avatarProfile.current ){
    //         props.showToggle()
    //     }
    // }

    const handleLogout = () => {
        const alert = window.confirm("Are you sure you want to logout"); if(alert === true){ 
            props.logoutLoad()
        }
    }

    const {avatarIsLoading} = props.avatar
    const id = props.id
    const session = getLocalStorage('fkv2_session')
    const resource_avatar = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.oc_t_user_resource ? props.avatar.avatarData.oc_t_user_resource : null
    const social_avatar = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
    && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.picture && props.avatar.avatarData.social[0].data.picture.data
    && props.avatar.avatarData.social[0].data.picture.data.url
    ? `${props.avatar.avatarData.social[0].data.picture.data.url}` : null
    const social_id = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.id ? props.avatar.avatarData.social[0].data.id : 0 

    // let image;
    // if (session && session.social && session.social === 'facebook') {
    //     image = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
    //     && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.picture && props.avatar.avatarData.social[0].data.picture.data
    //     && props.avatar.avatarData.social[0].data.picture.data.url
    //     ? `${props.avatar.avatarData.social[0].data.picture.data.url}` : ''
    // } else {
    //     image = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.oc_t_user_resource ? props.avatar.avatarData.oc_t_user_resource : []
    // }
    
    useEffect(() => {
        props.avatarLoad({user_id: id})
    // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     // document.addEventListener("keydown", handleHideDropdown, true);
    //     document.addEventListener("click", handleClickOutside, true);
    //     return () => {
    //     //   document.removeEventListener("keydown", handleHideDropdown, true);
    //       document.removeEventListener("click", handleClickOutside, true);
    //     };
    //   });
    return (
        <div className="avatar-container" onClick={()=> props.showToggle()}>
            {
                avatarIsLoading ? null :
                <div className="user-avatar">
                    {/* {session && session.social && session.social === 'facebook' ? <img src={image}></img> : image.length !== 0 ? <FKImage images={[image]} type="avatar" /> : <img src={DefaultPhoto} width={40} height={40} alt="session user avatar" />} */}
                {social_avatar ? <SocialAvatar id={social_id} url={social_avatar}/> :
                resource_avatar ? <FKImage images={[resource_avatar]} type="avatar" /> : 
                <img src={DefaultPhoto} width={40} height={40} alt="session user avatar" />
                }
                </div>
            }
            <div className="navbarChevron" >
                {
                    props.show ?
                    <div>
                       <DropdownIcon />
                        <div className="account-container">
                            {props.type ?
                                <div className="accountLinkGroup">
                                    <NavLink exact to="/user/listing" ><i className="fas fa-user" />  Profile</NavLink>
                                    <Link to="/user/settings"><i className="fas fa-tools" /> Settings</Link>
                                    <Link onClick={handleLogout} to="/"><i className="fas fa-power-off" /> Logout</Link>
                                </div>
                            :
                            null
                            }
                        </div>
                    </div>
                    :
                    <DropdownIcon />
                }
            
            </div>
        </div>
    )
}   

const mapStateToProps = store => {
    return {
      avatar: store.avatarReducer,
    };
  };
  
const mapActionToProps = {
    avatarLoad,
    logoutLoad
};


export default connect(mapStateToProps,mapActionToProps)(Avatar);