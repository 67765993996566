import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const adminLogin = (data) => {
    const { userEmail, userPassword } = data
    try {
        const query = gql`mutation adminLogin($input: LoginInput){
          adminLogin(input: $input){
            access_token
            admin{
              pk_i_id
              s_name
              s_username
            }
          }
      }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: {"username": userEmail, "password": userPassword}},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const getSession = (id, type) => {
    try {
        const query = gql`query getAdminSession($id: Int!, $type: String!){
          getAdminSession(id: $id, type: $type){
            pk_i_id
            s_username
          }
        }`;
        const response = client.query({
            query: query,
            variables: { id: parseInt(id), type: type}
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('gql e', JSON.stringify(e))
        throw e
    }
}

//USERS
export const getUsers = (data) => {

    const { first, page } = data
    try {
        const query = gql`query getAllUsers($first: Int, $page: Int, $orderField: String!, $orderType: SortOrder!){
            getAllUsers(first: $first, page: $page,
                orderBy: [
             {
              field: $orderField,
              order: $orderType
             }
            ]
          ){
            paginatorInfo {
              total
            }
            data{
              pk_i_id
              s_name
              s_username
              s_email
              wallet
              b_active
              dt_reg_date
              dt_mod_date
              dt_deactivated_date
              items_count
            }
          }
        }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderField: "dt_reg_date", orderType: "DESC"},
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const getUser = (id) => {
    try {
        const query = gql`query getUserById($id: ID)
        {getUserById(pk_i_id: $id) {
            pk_i_id
            s_name
            s_username
            s_email
            s_phone_land
            s_phone_mobile
            s_country
            s_region
            s_city
            s_website
            wallet
            b_active
            dt_deactivated_date
            oc_t_user_resource {
              pk_i_id
              s_name
              s_content_type
              s_extension
            }
            purchases{
              id
              transaction_id
              total_purchase
              before_credit
              current_credit
              type
              status
              created_at
              detail
            }
            purchase_micro_service {
              id
              item_id
              type
              payment_method
              name
              due_date
            }
          }
        }`;
        const response = client.query({
            query: query,
            variables: { id: id},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
  }

  export const upsertUser = (payload) => {
    const { pk_i_id, s_name, s_username, s_email, s_phone_land, s_phone_mobile, wallet, s_password, confirm_password, b_active, dt_deactivated_date } = payload
    const is_active = b_active === 'active' ? true :  b_active === 'not_active' ? false : b_active === true ? true : false;  
  
    let variables
    let gql_query
    if (pk_i_id) {
      gql_query = gql`mutation userAdminUpdate($data: UserUpdateInput){
        userAdminUpdate(data: $data){
          message
          oc_t_user{
                pk_i_id
                s_name
                s_username
                s_email
                s_phone_land
                s_phone_mobile
                s_country
                s_region
                s_city
                s_website
                b_active
                wallet
                dt_deactivated_date
                oc_t_user_resource {
                    pk_i_id
                    s_extension
                }
          }
          }
      }`
      variables = {data: {"pk_i_id": parseInt(pk_i_id), "s_name": s_name, "s_username": s_username, "s_phone_land": parseInt(s_phone_land),
                          "s_phone_mobile": parseInt(s_phone_mobile), "wallet": parseInt(wallet), "resource_id": null, "imageData": [], "cover_photo": [],  "b_active": is_active, "dt_deactivated_date": dt_deactivated_date }
                  }        
    } else {
      gql_query = gql`mutation userAdminRegister($input: UserRegisterInput){
        userAdminRegister(data: $input){
               message
        }}`;
  
      variables = {input: {"name": s_name, "username": s_username, "email": s_email, "password": s_password, "password_confirmation":  confirm_password }}
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  export const searchUsersForSuggest = (data) => {
    const type = data.type ? data.type : null
    const limit = data.limit ? data.limit : 10
    const text = data.text ? data.text : 10
  
    let query

    try {
        if (type === 'active') {
            query = gql`query publicUsersForSuggest($text: String!, $type: String, $limit: Int!, $orderField: String!, $orderType: SortOrder!){
                publicUsersForSuggest(type: $type, limit: $limit,text: $text,
                    orderBy: [
                 {
                  field: $orderField,
                  order: $orderType
                 }
                ]
              ){
                pk_i_id
                s_username
                s_email
                s_name
                b_active
                wallet
                b_active
                dt_reg_date
                dt_mod_date
                dt_deactivated_date
              }
        }`
        } else {
            query = gql`query getUsersForSuggest($text: String!, $type: String, $limit: Int!, $orderField: String!, $orderType: SortOrder!){
                getUsersForSuggest(type: $type, limit: $limit,text: $text,
                    orderBy: [
                 {
                  field: $orderField,
                  order: $orderType
                 }
                ]
              ){
                pk_i_id
                s_username
                s_email
                s_name
                b_active
                wallet
                b_active
                dt_reg_date
                dt_mod_date
                dt_deactivated_date
                items_count
              }
        }`
    }
    const response = client.query({
            query: query,
            variables: {text: text, type: type, limit: limit, orderField: "s_username", orderType: "ASC"},
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }

  export const adminLogout = () => {
    try {
        const query = gql`mutation adminLogout{
          adminLogout{
              message
            }
          }`;
        const response = client.mutate({
            mutation: query,
        });
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const changeUserEmail = (payload) => {
  const { pk_i_id, s_email } = payload
    const query = gql`mutation changeUserEmail($data: ChangeUserEmailInput){
      changeUserEmail(data: $data){
          message
        }
    }`
    const variables = {data: {"pk_i_id": parseInt(pk_i_id), "s_email": s_email}}     

  try{
        const response = client.mutate({
            mutation: query,
            variables: variables
        });
        const data = response ? response : null;
        return data;
  }catch(e){
      throw e
  }
}

export default {
    adminLogin,
    getSession,
    getUsers,
    getUser,
    upsertUser,
    searchUsersForSuggest,
    adminLogout,
    changeUserEmail
}
  