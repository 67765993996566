import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const contactUs = (data) => {
    const { email, message, subject } = data
    try{
        const query = gql`mutation contactUs($data: ContactUsInput){
            contactUs(data: $data){
          		message
            }
        }`;

          const response = client.mutate({
            mutation: query,
            variables: {
                data: {
                  email: email,
                  subject: subject,
                  message: message
                }
              }
          });
          const result = response ? response : null;
          return result;
    } catch (e){
        console.log('error', e)
        throw e
    }
}


export default {
    contactUs
}