import React, { useState } from "react";

const Number = ({ selected = false, onClick = f => f, index}) => (
  <div className={`circle-container ${selected ? `selected-circle` : `unselected-circle`}`} onClick={() => onClick()}>
    <p>{index+1}</p>
  </div>
);

export const NumberRating = ({ totalStars, handleChange, inputName}) => {
  const [numberSelected, setNumberSelected] = useState(0)

  const onClick = (i) => {
    setNumberSelected(i)
    handleChange(inputName, i)
  }

  return (
    <div className="flex flex-d-row flex-justify-evenly mt-20">
        {[...Array(totalStars)].map((n, i) => (
        <Number
          key={i}
          selected={i < numberSelected}
          onClick={() => onClick(i + 1)}
          index={i}
        />
      ))}
    </div>
  )
}

const Star = ({ selected = false, onClick = f => f }) => (
    <i className={selected ? "fas fa-star star rating" : "far fa-star star"} onClick={onClick}/>
);

export const StarRating = ({ totalStars, handleChange, inputName }) => {
  const [starsSelected, selectStar] = useState(0);
  
  const onClick = (i) => {
    selectStar(i)
    handleChange(inputName, i)
  }

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((n, i) => (
        <Star
          key={i}
          selected={i < starsSelected}
          onClick={() => onClick(i + 1)}
        />
      ))}
    </div>
  );
};

const parseStar = (total, w, d, s ) => {
  return [...Array(total)].map((n, i) => (   
     d === 0 && i < w ?  <i key={i} className="fas fa-star star-colored" style={{fontSize: s}} /> :
     d === 0 && i >= w -1 ?  <i key={i} className="far fa-star star-colored" style={{fontSize: s}}/> :
     d > 0 &&  i <= w - 1 ?  <i key={i} className="fas fa-star star-colored" style={{fontSize: s}}/> :
     d > 0 && i === w ? <i key={i} className="fas fa-star-half-alt star-colored" style={{fontSize: s}}/> :
     d > 0 && i > w ? <i key={i} className="far fa-star star-colored" style={{fontSize: s}}/> : null
  ))
}

export const StarRatingPlain = ({ totalStar, average, size }) => {
  const split = average.toString().split('.')
  const whole = split[0] ? parseInt(split[0]) : 0
  const decimal = split[1] ? parseInt(split[1]) : 0

  return (
    <div className="star-rating-plain">
      {parseStar(totalStar, whole, decimal, size )}
    </div>
  );
};

