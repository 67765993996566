import React, { useState, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { LikesHeartIllustrate } from '../../../../assets/svg'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import { numberWithCommas, slugify, categoryParser } from '../../../../common/helper/utility'
import HomeItemTag from '../../../../common/component/tags/HomeItemTag'
import SocialAvatar from '../../../../common/component/socialAvatar'
import {WTT, WTB} from '../../../../common/component/label'
import { theme } from '../../../../common/theme'
import { HeartIcon } from '../../../../assets/svg'
import FkImage from '../../../../common/component/image'

export const ZeroList = () => {
  return (
    <>
      <div style={{ border: '0.5px solid #C4C4C4', borderRadius: 8, boxSizing: 'border-box', background: '#FFFFFF', margin: 16, padding: '30px 4px' }}>
        <div style={{ textAlign: 'center', width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: 20, lineHeight: '20px', color: '#232A34' }}>{`You don't appear to like anything, hugs.`}</p>
          <div style={{ padding: '30px 0px' }}><LikesHeartIllustrate /></div>
          <div>
            <p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: 20, lineHeight: '30px', color: '#232A34' }}>{`Tap or Click on a listing's Heart Shape to add to your Likes`}</p>
            <p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: 20, lineHeight: '30px', color: '#232A34' }}>{`so you can watch that listing`}</p>
          </div>
        </div>
      </div>

    </>
  )
}

export const LikeItemCard = ({ item, likeDislike, isLoading }) => {

  const itemReducer = (state, action) => {
    switch (action.type) {
      case 'topAd':
        return { ...state, isTopAd: true }
      case 'highlight':
        return { ...state, isHighlight: true }
      case 'toggleShow':
        return { ...state, isShow: !state.isShow }
      default:
        break;
    }
  }

  const pk_i_id = item && item.pk_i_id ? item.pk_i_id : null
  const get_i_price = item && item.get_i_price ? item.get_i_price : 0
  const oc_t_item_description = item && item.oc_t_item_description ? item.oc_t_item_description : null
  const oc_t_user = item && item.oc_t_user ? item.oc_t_user : null
  const oc_t_item_resource = item && item.oc_t_item_resource ? item.oc_t_item_resource : null
  const watchlist_count = item && item.watchlist_count ? item.watchlist_count : 0
  const ta_expiration = item && item.ta_expiration ? item.ta_expiration : null
  const hl_expiration = item && item.hl_expiration ? item.hl_expiration : null
  const post_type = item && item.post_type ? item.post_type : null
  const s_title = oc_t_item_description && oc_t_item_description.s_title ? oc_t_item_description.s_title : ''
  const s_description = oc_t_item_description && oc_t_item_description.s_description ? oc_t_item_description.s_description : ''
  const s_username = oc_t_user && oc_t_user.s_username ? oc_t_user.s_username : null
  const oc_t_user_resource = oc_t_user && oc_t_user.oc_t_user_resource ? oc_t_user.oc_t_user_resource : []
  const social = oc_t_user && oc_t_user.social ? oc_t_user.social : []

  const id = oc_t_user && oc_t_user.pk_i_id ? oc_t_user.pk_i_id : 0
  const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
  const reserved = item && item.b_reserved ? item.b_reserved : false
  const user_social_id = social && social[0] && social[0].data && social[0].data.id ? social[0].data.id : ''
  const imgSrc = social && social[0] && social[0].data && social[0].data.picture && social[0].data.picture.data && social[0].data.picture.data.url ? social[0].data.picture.data.url : null
  const [isLiked, setIsLiked] = useState(true)
  const price = get_i_price ? numberWithCommas((get_i_price).toFixed(2)) : 0
  const [state, dispatch] = useReducer(itemReducer, { isTopAd: false, isHighlight: false, isShow: false })
  const toggleLike = () => {
    setIsLiked(!isLiked)
    likeDislike(pk_i_id)
  }

  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (ta_expiration) {
      const isTopAd = ta_expiration ? moment(ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({ type: 'topAd' })
      }
    }

    if (hl_expiration) {
      const isHighlight = hl_expiration ? moment(hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({ type: 'highlight' })
      }
    }
    // eslint-disable-next-line  
  }, [item, hl_expiration])

  const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
  const itemSLug = s_title ? slugify(s_title) : ''
  const item_description = s_description ? s_description.substring(0, 100) : ''
  const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
  const finalSLug = `/${category}/${itemSLug}_i${pk_i_id}?title=${s_title}&cover_photo=${cover_photo}&description=${item_description}`
  console.log('imgSrc', imgSrc)
  console.log('user_social_id', user_social_id)

  return (
    <div className="like-item-card">
      <div className="like-item-sub-card" >
        <div style={{ position: 'relative' }}>
          <Link to={`/user/${id}/listing`} className="avatar-profile-link">
            {
              user_social_id ? <SocialAvatar id={user_social_id} url={imgSrc}/> 
                :  oc_t_user_resource ?<FkImage images={[oc_t_user_resource]} type="seller_avatar" alt="seller" /> 
                : <img src={DefaultPhoto} width="40" height="40" className="social-avatar" alt="seller default" />
            }
            <div style={{ flex: 1, padding: 5, backgroundColor: 'transparent', maxWidth: window.innerWidth < 600 ? '125px' : '200px' }}><p className="item-seller">{`${s_username}`} </p></div>
          </Link>
          {
            state.isTopAd ? sold ? null :
              <div id="ribbon-container">
                <p >Premium</p>
              </div>
              : null
          }</div>
        <div style={{ border: state.isTopAd ? sold ? 'none' : '0.5px solid #FB770D' : 'none', width: '100%', height: '100%', borderRadius: 6, background: state.isHighlight ? sold ? '#FFFFFF' : '#FFF1E5' : '#FFFFFF' }}>
          <Link to={finalSLug}>
            <div className="like-item-thumbnail">
              <div className="like-item-sub-thumbnail">
                <FkImage images={oc_t_item_resource} type="like-image" className="like-image" />
              </div>
              <HomeItemTag sold={sold} reserved={reserved} />
            </div>
            <div style={{ flex: 1, paddingLeft: 8, paddingRight: 8 }}>
              <div className="flex flex-justify-between flex-align-center w-100">
                <p className="itemcard-item-price">{`${price} $`}</p>
                {
                  post_type ? post_type === 'WTS' ? null : 
                  <div className="mt-7">{
                  post_type === 'WTB' ? <WTB /> : <WTT /> }</div>: null
                }
              </div>
              <p className='itemcard-item-title'>{s_title}</p>
            </div>
          </Link>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 8px' }} onClick={isLoading ? null : () => toggleLike()}>
            <div style={{ width: '100%', paddingBottom: 8, borderBottom: '1px solid rgb(196, 196, 196)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className={isLiked ? `like-button` : `default-like-button`}>
                <HeartIcon />
              </div>&nbsp;
              <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 14, lineHeight: '14px', letterSpacing: '0.0044em', color: '#232A34' }}>{watchlist_count === 0 ? null : watchlist_count}</p>
              {/* <p style={{ color: '#e74c3c' }}><i className={isLiked ? "fa fa-heart" : "far fa-heart"} /> {watchlist_count}</p> */}
            </div>

          </div>
          <div style={{ display: 'flex', padding: '6px', justifyContent: 'center', fontFamily: 'Metropolis-Regular' }}>
            <Link to={{
              pathname: `/user/messages/inbox`,
              state: { fromMessage: true, item: item }
            }} className={`btn-modal-options ${window.innerWidth < 600 ? `message-btn-mobile` : null}`}>Message
                  </Link>
          </div>

        </div>

      </div>
    </div>
  )
}

export const style = {
  itemHolder: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid silver',
    minHeight: '200px',
    margin: 5,
    padding: 10,
  },
  itemTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.color.black
  },
  itemDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '56px',
    textAlign: 'center',
    color: 'gray',
  },
  itemWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(189, 195, 199,0.4x)',
    margin: 5,
    padding: 5,
  },
  itemFooterContainer: { display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: 'transparent', width: '100%' },
}




