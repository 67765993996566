import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AdminLogContext } from '../../../../../common/context'
import { adminLogsLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
// import { SaleList, SaleModal, SaleSuggestData, SaleSearchResult } from './component/CustomComponent'
import { LogList } from './component/CustomComponent'

const LogSection = (props) => {
    const pageCount = 10
    const result_limit = 20

    const { 
        adminLogsData, adminLogsIsLoading, adminLogsError, adminLogsPage, adminTotalLogs,
        // adminSaleDetailIsLoading, adminSaleDetailData, adminSaleDetailError,
        // adminSaleSuggestIsLoading, adminSaleSuggestData, adminSaleSuggestError
    } = props.admin

    useEffect(() => {
        if (adminLogsData.length === 0) {
            props.adminLogsLoad({first: 20, page: adminLogsPage})
        }
    // eslint-disable-next-line
    }, [])

    const fetchSaleDetail = (id) => {
        setIsShow(true)
        // props.adminSaleDetailLoad({id: id})
    }

    const closeSaleModal = () => {
        setIsShow(false)
    }

    //sale modal
    const [isShow, setIsShow] = useState(false)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)


    //item search
    const [isSearching, setIsSearching] = useState(false)

    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            // props.adminSaleSuggestLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "created_at", orderType: "DESC"})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setIsShowSearchResult(true)
    }

    const handlePageClick = data => {
        props.adminLogsLoad({first: 20, page: data.selected + 1})
    };

    let history = useHistory();
    const gotoItem = (id) => {
        history.push("/admin/dashboard/item", {item_id: id});
    }

    const gotoUser = (id) => {
        history.push("/admin/dashboard/user", {user_id: id});
    }

    return (
        <AdminLogContext.Provider value={{
            adminLogsData, fetchSaleDetail, adminLogsIsLoading, adminLogsError, isShow, closeSaleModal, gotoUser, gotoItem
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Logs</h3>
            <section>
            {adminLogsIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminLogsError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminLogsError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div ></div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                {/* <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div> */}
                                {/* {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><SaleSuggestData /></div>} */}
                            </div>
                        </div>
                        <LogList />
                    </article>
            }
            <div></div> 
            {adminLogsError ? null :
                adminLogsData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalLogs}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {/* {isShow ? <SaleModal /> : null }
        {isShowSearchResult ? <SaleSearchResult /> : null} */}
        </AdminLogContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminLogsLoad,
    // adminSaleDetailLoad,
    // adminSaleSuggestLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(LogSection);