import React, {useContext, useEffect, useState} from 'react';
import {Button} from '../customComponent'
import {loadStripe} from '@stripe/stripe-js';
import {getStripeSubscriptionIdByAmount, getStripeOtpPriceIdByName} from '../../../../common/helper/utility'
import {FkPrimeContext} from '../../../../common/context'

const {REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_STRIPE_KEY} = process.env

const StripeButton = () => {
    const value = useContext(FkPrimeContext);
    const {sessionData, userBoost, session, pillSelect} = value
    const token = session && session.fkv2_session && session.fkv2_session.access_token ? session.fkv2_session.access_token  : null
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const s_email = sessionData && sessionData.s_email ? sessionData.s_email : null
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
    const stripeCusId = sessionData && sessionData.stripe_cus_id ? sessionData.stripe_cus_id : null
    const boostDetails = userBoost && userBoost.boostDetails ? userBoost.boostDetails : [] 
    const sg_price = userBoost && userBoost.boostDetails && userBoost.boostDetails.sg_price ? userBoost.boostDetails.sg_price : 0
    const stripeProductId = getStripeSubscriptionIdByAmount(sg_price)
    const otpDetails = userBoost && userBoost.boostOtpDetails ? userBoost.boostOtpDetails : [] 
    const otpTotal = userBoost && userBoost.boostOtpTotal ? userBoost.boostOtpTotal.toFixed(2) : 0

    const [otpLineItem, setOtpLineItem] = useState([])

    const collectOtpPriceId = (data) => {
        let res = []
        if (data && data.length > 0) {
            data.map(otp => {
                const id = getStripeOtpPriceIdByName(otp.name)
                res.push({price: id, quantity: 1})
            })
        }
        setOtpLineItem(res)
    }

    useEffect(() => {
        if (otpDetails && otpDetails.length > 0) {
            collectOtpPriceId(otpDetails)
        }
    },[otpDetails])

    const handleCheckout = async () => {
        // setStripeLoading(true)
        const GET_SESSION_URL = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql`
        const stripe = await stripePromise;
        const input = generateStripeInput(boostDetails,otpDetails)

        console.log("input handleCheckout", input)

        const query = `mutation getStripeSession($input: PurchaseInput){
          getStripeSession(input: $input){
            session
          }
        }`;

        const response = await fetch(GET_SESSION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query,
                variables: {input}
            })
        });
        const subscriptionSession = await response.json();  
        console.log('subscriptionSession', subscriptionSession)

        if (subscriptionSession.error) {
            console.log('subscriptionSession.error', subscriptionSession.error)
            // setStripeError(result.error.message)
            // setStripeLoading(false)
        } else {
            const result = await stripe.redirectToCheckout({
            sessionId: subscriptionSession && subscriptionSession.data && subscriptionSession.data.getStripeSession && subscriptionSession.data.getStripeSession.session ? JSON.parse(subscriptionSession.data.getStripeSession.session) : null
            }); 
            if (result.error) {
                console.log('result.error', result.error)
                // setStripeError(result.error.message)
                // setStripeLoading(false)
            }
        }
    };

    const generateStripeInput = (boostDetails, otpDetails) => {
        let line_item_details = []
        let line_items = []


        // if (pillSelect === 1) {
        //     line_items.unshift({price: stripeProductId, quantity: 1})
        //     const service_name = boostDetails.service_name

        //     const boostDetailsObj = {
        //         "type": boostDetails.type,
        //         "user_id": parseInt(userId),
        //         "coin": boostDetails.credit ? parseInt(boostDetails.credit) : 0,
        //         "id": boostDetails.id,
        //         "amount": boostDetails.sg_price,
        //         "description": boostDetails.description,
        //         "method": "stripe",
        //         "currency": "sgd",
        //         "s_email": s_email,
        //         "name": service_name,
        //         "customer": stripeCusId,
        //     }
        //     line_item_details.push(boostDetailsObj)

        // } else {
            line_items = otpLineItem
            otpDetails && otpDetails.length > 0 && otpDetails.map(data => {
                const boostOtpDetailsObj = {
                    "type": data.type,
                    "user_id": parseInt(userId),
                    "coin": data.credit ? data.credit : 0,
                    "id": data.id,
                    "amount": data.sg_price,
                    "description": data.description,
                    "method": "stripe",
                    "currency": "sgd",
                    "s_email": s_email,
                    "name": data.name,
                    "customer": stripeCusId,
                }
                line_item_details.push(boostOtpDetailsObj)
            })
        // }

         const input = {
        //   "type": pillSelect === 1 ? 'fk_prime' : 'otp',
          "type": 'otp',
          "user_id": parseInt(userId),
          "coin": 0,
        //   "amount": pillSelect === 1 ? sg_price : otpTotal,
          "amount": otpTotal,
          "method": "stripe",
          "currency": "sgd",
          "s_email": s_email,
        //   "payment_mode": pillSelect === 1 ? "subscription" : "payment",
          "payment_mode": "payment",
          "return_url": "fk-prime",
          "customer": stripeCusId, 
          "line_items": line_items,
          "line_items_detail": line_item_details
        }
        return input
    }

    return (
            <Button
                styleClass={`flex flex-justify-center flex-align-center fk-prime-purchase-pay-button-container pointer`}
                text="Checkout"
                textClass="fk-prime-purchase-pay-button-p"
                clickFunc={handleCheckout}
            />
    )
    

}

export default StripeButton;