import React from 'react'
import PropTypes from 'prop-types';
import CategoryBar from '../../common/component/categoryBar'
import Related from '../../components/web/item/view/component/related-old' 
import OtherItemsFromSeller from '../../components/web/item/view/component/otherItemsFromSeller'
import GoogleAds from '../../common/component/googleAds'

const layoutWithRightSidebar = ( { component: Component, type, session, ...rest } ) => (
    <React.Fragment>
    <CategoryBar session={session}/>
    <div className="item-view-main-container">
        { window.innerWidth < 600 ? null :  <GoogleAds position='top'/> }
        <div className={window.innerWidth < 600 ? `w-100` : `item-details-main-body-container`}>
            <Component {...rest} session={session}/>
        </div>
        {/* comment the related ads */}
         { window.innerWidth < 600 ? null : <GoogleAds /> }
         {
             window.innerWidth < 600 ? null :  
             <div className="item-view-related-container">
                 <OtherItemsFromSeller {...rest} session={session}/> 
            </div>
         }
        {window.innerWidth < 600 ? null : 
        <div className="item-view-related-container">
            <Related session={session} />
        </div>
        }
        
        
    </div>
  </React.Fragment>
);

layoutWithRightSidebar.propTypes = {
    component: PropTypes.any
  };

  export default layoutWithRightSidebar
