import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import RelatedItemCard from './RelatedCustomComponent'
import { Link, useLocation } from 'react-router-dom'
import { categorySearchLoad, itemViewLoad, watchlistLikeDislikeLoad, categorySearchMoreLoad} from '../../../../../redux/action'

const Related = (props) => {
    const item = props && props.itemView && props.itemView.data ? props.itemView.data : []
    const id = item && item.oc_t_category && item.oc_t_category.pk_i_id ? item.oc_t_category.pk_i_id : null
    const page = props.category && props.category.pages ? props.category.pages : 1
    const categoryTotal = props.category && props.category.total ? props.category.total : 0
    const userId = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const location = useLocation()
    const itemViewingId = props && props.itemView && props.itemView.data && props.itemView.data.pk_i_id ? props.itemView.data.pk_i_id : 0
    const data = props && props.category && props.category.data ? props.category.data : []
    const initialUpto = window.innerWidth < 600 ? 6 : 5
    const [upto, setUpto] = useState(initialUpto)
    const seller_id = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : 0
    const relatedAdsArr = data.filter(item => item.pk_i_id !== itemViewingId && item.oc_t_user.pk_i_id !== seller_id)
    const sliceRelatedData = relatedAdsArr ? relatedAdsArr.slice(0, upto).map((item)=> item) : 0
    const likeDislikeLoading = props.watchList && props.watchList.isLoading ? props.watchList.isLoading : false
    const itemLoading = props && props.itemView && props.itemView.isLoading ? props.itemView.isLoading : false
    const watchlistArr = props && props.watchList && props.watchList.data ? props.watchList.data : []
    const [forceRender, setForceRender] = useState(false)
    const likeDislike = (item_id) => {
        props.watchlistLikeDislikeLoad({userId: sessionMobile ? sessionMobile : userId, itemId: item_id})
      }
    const sessionMobile = props && props.sessionMobile ? parseInt(props.sessionMobile) : 0

    useEffect(() => {
        if(id){
            props.categorySearchLoad({id: id})
        }
    // eslint-disable-next-line    
    },[id, forceRender])

    const handleSetUpto = () => {
        const total = parseInt(upto) + 10

        if(total === data.length){
            props.categorySearchMoreLoad({id: id, page:page})
            setUpto(parseInt(upto) + 5)
        }else {
            setUpto(parseInt(upto) + 5)
        }
    }
    // const handleLoadMore = () => {
    //    props.categorySearchLoadMore({id: id})
    // }

    const toggleLiked = (itemId) => {
        props.watchlistLikeDislikeLoad({ userId: sessionMobile ? sessionMobile : userId, itemId: itemId })
    }

    return (
        <>
        {
            itemLoading ? null :
            <div className="item-view-related-sub-container mt-30">
                <div className="related-label">
                    <p>{`Related Ads`}</p>
                    {
                        parseInt(upto) === parseInt(categoryTotal) ? null :
                        <div onClick={() => handleSetUpto()}>
                            <span className="see-more-span">{`View more`}</span>
                        </div>
                    }
                </div>
                <div className="flex flex-justify-center flex-align-center">
                    <div className="related-ads-container">
                    {
                sliceRelatedData.map((related_item, index) => {
                    const social_user = related_item && related_item.oc_t_user && related_item.oc_t_user.social && related_item.oc_t_user.social[0] && related_item.oc_t_user.social[0].data && related_item.oc_t_user.social[0].data.picture && related_item.oc_t_user.social[0].data.picture.data && related_item.oc_t_user.social[0].data.picture.data.url ? related_item.oc_t_user.social[0].data.picture.data.url : null
                    const user_resource = related_item && related_item.oc_t_user && related_item.oc_t_user.oc_t_user_resource ? related_item.oc_t_user.oc_t_user_resource : []                  
                    const id = related_item && related_item.pk_i_id ? related_item.pk_i_id : 0

                    return <div key={index} className='w-100'>
                        <RelatedItemCard related_item={related_item} setForceRender={setForceRender} likeDislike={likeDislike} seller_id={seller_id} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} watchList={watchlistArr} session_id={userId} sessionMobile = {sessionMobile}/>
                            </div>
                })
                    }
                    </div>
                </div>
            </div>
        }
        </>
    );
}

const mapStateToProps = store => {
    return {
        category: store.categorySearchReducer,
        itemView: store.itemViewReducer,
        watchList: store.watchlistReducer
    }
}

const mapActionToProps = {
    categorySearchLoad,
    itemViewLoad,
    watchlistLikeDislikeLoad,
    categorySearchMoreLoad
}

export default connect(mapStateToProps,mapActionToProps)(Related)