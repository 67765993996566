import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import FKImage from '../../../../../../common/component/image'
import { numberWithCommas, slugify, categoryParser, itemSatus } from '../../../../../../common/helper/utility'
import DefaultItem from '../../../../../../assets/images/commerce-and-shopping.png'
import HomeItemTag from '../../../../../../common/component/tags/HomeItemTag'

const REACT_APP_IMGX = process.env.REACT_APP_IMGX || '';

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'topAd':
      return { ...state, isTopAd: true }
    case 'highlight':
      return { ...state, isHighlight: true }
    case 'toggleShow':
      return { ...state, isShow: !state.isShow }
    default:
      break;
  }
}

const ProfileListingCard = ({ item, style, isSessionUser, updateIsLoading, submitItemUpdateByFieldLoad, setSelected, handleSetSelectedItem }) => {
  const [state, dispatch] = useReducer(itemReducer, { isTopAd: false, isHighlight: false, isShow: false })
  const item_title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
  const price = numberWithCommas((item.get_i_price).toFixed(2))
  const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
  const reserved = item && item.b_reserved ? item.b_reserved : false
  const date_published = item && item.dt_pub_date ? item.dt_pub_date : null
  const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null 
  const { ta_expiration, hl_expiration } = item

  const isNew = item.is_new ? item.is_new : false
  const itemStatus = itemSatus(item.b_active, date_published)

  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (ta_expiration) {
      const isTopAd = ta_expiration ? moment(ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({ type: 'topAd' })
      }
    }

    if (hl_expiration) {
      const isHighlight = hl_expiration ? moment(hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({ type: 'highlight' })
      }
    }
    // eslint-disable-next-line  
  }, [item, hl_expiration])

  const item_id = item.pk_i_id ? item.pk_i_id : 0
  const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
  const itemSLug = slugify(item_title)
  const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
  const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
  const finalSLug = `/${category}/${itemSLug}_i${item_id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`

  return (
    <>
      <div style={{ width: '100%', position: 'relative', border: state.isTopAd ? sold ? 'none' : '0.5px solid #FB770D' : 'none', borderRadius: '6px' }}>
          <div className="profile-listing-item-card" style={{ background: state.isHighlight ? sold ? '#FFFFFF' : '#FFF1E5' : '#FFFFFF' }}>
            <Link to={finalSLug}>
            <div style={{ position: 'relative' }}>

              {
                state.isTopAd ? sold ? null :
                  <div id="ribbon-container">
                    <p >{state.isTopAd ? sold ? null : `Premium` : null}</p>
                  </div> : null
              }
            </div>
            <div style={style.cardInfo}>
              <div style={style.row}>
                <div className="itemcard-image-container">
                  {
                    item.oc_t_item_resource.length === 0 ?
                      <div style={{ background: '#bdc3c7', background: '-webkit-linear-gradient(to right, #bdc3c7, #2c3e50)', background: 'linear-gradient(to right, #bdc3c7, #2c3e50)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {sold || reserved ? null : itemStatus === 'Active' ? null : <div className='no-image-is-pending-container'><p className='color-white'>{itemStatus}</p></div>}
                        <img src={DefaultItem} width={100} height={100} />
                      </div> :
                      <FKImage images={item.oc_t_item_resource} type="thumbnail" isNew={isNew} itemStatus={itemStatus} sold={sold}/>
                  }
                  {sold || reserved ? <HomeItemTag sold={sold} reserved={reserved} /> : null}
                </div>
              </div>
            </div>
            <div style={{ padding: '0px 8px 8px' }}>
              <div >
                <p className="itemcard-item-price">{`${price} $`}</p>
              </div>

              <div>
                <p className="itemcard-item-title">{item_title}</p>
                <p style={{fontSize: window.innerWidth < 600 ? '9.5px' : 12, fontStyle: 'normal', letterSpacing: '0.44px', color: '#c4c4c4'}}>{`(${formatted_date ? formatted_date : null})`}</p>
              </div>
            </div>
            </Link>
            { isSessionUser ? 
              <div className='item-button-container'>
                <button onClick={updateIsLoading ? null : () => submitItemUpdateByFieldLoad({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: true })} style={{display: sold ? 'none' : 'inline-block'}} className={'small-basic-button background-gray align-self' }>Sold</button>
                <button className='align-self fk-light-gray fk-font-18' onClick={() => handleSetSelectedItem(item)}><i className="far fa-edit"></i></button>
                <button className='align-self fk-light-gray fk-font-18' onClick={() => setSelected(parseInt(item_id), item_title)}><i className="fas fa-trash-alt"></i></button> 
              </div>  
                : null
            }

            </div>
      </div>
    </>
  )
}

export default ProfileListingCard