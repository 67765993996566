import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { 
    adminPreferenceSuccess, adminPreferenceFailed, adminUpsertPreferenceSuccess, adminUpsertPreferenceFailed, adminDeletePreferenceSuccess, adminDeletePreferenceFailed,
    adminPreferenceSuggestSuccess, adminPreferenceSuggestFailed
} from "../../action";
import admin from '../../../graphql/admin/preference'
import { theme } from '../../../common/theme'

//fetch preference
function* adminPreferences(action) {
    const { payload } = action
    try{
        const result = yield admin.getPreferences(payload)

        if(result === 403) {
            yield put(adminPreferenceFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getAllPreference ? result.data.getAllPreference : {}
            yield put(adminPreferenceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminPreferenceFailed(errorMessage))
    }
}

export function* watchAdminPreferences() {
    yield takeLatest(ADMIN.PREFERENCE.LOAD, adminPreferences);
}

//upsert preference
function* adminUpsertPreference(action) {
    const { payload } = action
    const type = payload.type
    try{
        const result = yield admin.upsertPreference(payload)
        if(result === 403) {
            yield put(adminUpsertPreferenceFailed('Something went wrong try again!'))
        }
        else {
            let data 
            if (type === 'UPDATE') {
                data = result.data && result.data.preferenceUpdate && result.data.preferenceUpdate ? result.data.preferenceUpdate : {}
            } else {
                data = result.data && result.data.preferenceInsert && result.data.preferenceInsert ? result.data.preferenceInsert : {}
            }
            yield put(adminUpsertPreferenceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertPreferenceFailed(errorMessage))
    }
}

export function* watchAdminUpsertPreference() {
    yield takeLatest(ADMIN.PREFERENCE_UPSERT.LOAD, adminUpsertPreference);
}

//delete preference
function* adminDeletePreference(action) {
    const { payload } = action
    try{
        const result = yield admin.deletePreference(payload)

        if(result === 403) {
            yield put(adminDeletePreferenceFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.preferenceDelete && result.data.preferenceDelete ? result.data.preferenceDelete : {}
            yield put(adminDeletePreferenceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeletePreferenceFailed(errorMessage))
    }
}

export function* watchAdminDeletePreference() {
    yield takeLatest(ADMIN.PREFERENCE_DELETE.LOAD, adminDeletePreference);
}

//suggest preference
function* adminSuggestPreference(action) {
    const { payload } = action
    try{
        const result = yield admin.searchPreferenceForSuggest(payload)
        if(result === 403) {
            yield put(adminPreferenceSuggestFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.searchPreference ? result.data.searchPreference : {}
            yield put(adminPreferenceSuggestSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(adminPreferenceSuggestFailed(errorMessage))
    }
}

export function* watchAdminSuggestPreference() {
    yield takeLatest(ADMIN.PREFERENCE_SUGGEST.LOAD, adminSuggestPreference);
}
