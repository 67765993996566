import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { theme } from '../../../../common/theme'

const SideBar = () => {
    const location = useLocation()
    const path = location.pathname
    const [showSubMenu, setShowSubMenu] = useState(null)

    const menuHover = (label) => {
        if (label === 'Users') return setShowSubMenu('Users')
        if (label === 'Ban') return setShowSubMenu('Ban')
        if (label === 'Services') return setShowSubMenu('Services')
    }

    useEffect(() => {
        if ((path === '/admin/dashboard/user') || (path === '/admin/dashboard/admin')) return setShowSubMenu('Users')
        if ((path === '/admin/dashboard/ban/text') || (path === '/admin/dashboard/ban/email')) return setShowSubMenu('Ban')
        if ((path === '/admin/dashboard/service/coin') || (path === '/admin/dashboard/service/item')) return setShowSubMenu('Services')
    }, [path])

    const renderMenu = () => {
        const menu = theme.text.adminMenu.map((item, index) => {
            return  (
                <React.Fragment key={index} >
                    <li className="menu-heading">
                        <h3>{item.label}</h3>
                    </li>
                    {item.menu.map((subItem, subIndex) =>{
                        return (
                            <React.Fragment key={subIndex}>
                                <li  className={path === subItem.path ? 'admin-menu-active': null} onMouseOver={() => menuHover(subItem.label)}>
                                    {
                                        subItem && subItem.menu ? <div><span style={{paddingLeft: 10}} >{subItem.label}</span></div> :
                                        <Link exact="true" to={subItem.path ? subItem.path : '/'}>
                                            <span style={{paddingLeft: 10}} >{subItem.label}</span>
                                        </Link>
                                    }
                                </li>
                                { showSubMenu === 'Users' &&
                                    subItem && subItem.label === 'Users' && subItem.menu ? subItem.menu.map((subItemMenu, subItemMenuIndex) => {
                                    return(
                                        <li key={subItemMenuIndex} className={path === subItemMenu.path ? 'admin-menu-active': null}>
                                        <Link exact="true" to={subItemMenu.path}>
                                            <span style={{paddingLeft: 30}} >{subItemMenu.label}</span>
                                        </Link>
                                    </li>
                                    )
                                    }) : null
                                }
                                { showSubMenu === 'Ban' &&
                                    subItem && subItem.label === 'Ban' && subItem.menu ? subItem.menu.map((subItemMenu, subItemMenuIndex) => {
                                    return(
                                        <li key={subItemMenuIndex} className={path === subItemMenu.path ? 'admin-menu-active': null}>
                                        <Link exact="true" to={subItemMenu.path}>
                                            <span style={{paddingLeft: 30}} >{subItemMenu.label}</span>
                                        </Link>
                                    </li>
                                    )
                                    }) : null
                                }
                                { showSubMenu === 'Services' &&
                                    subItem && subItem.label === 'Services' && subItem.menu ? subItem.menu.map((subItemMenu, subItemMenuIndex) => {
                                    return(
                                        <li key={subItemMenuIndex} className={path === subItemMenu.path ? 'admin-menu-active': null}>
                                        <Link exact="true" to={subItemMenu.path}>
                                            <span style={{paddingLeft: 30}} >{subItemMenu.label}</span>
                                        </Link>
                                    </li>
                                    )
                                    }) : null
                                }
                            </React.Fragment>
                        )
                      })
                    }
                </React.Fragment>
            )
        })
        return menu
    }

    return (
        <header className="page-header">
            <nav>
                <ul className="admin-menu">
                    {renderMenu()}
                </ul>
            </nav>
        </header>
    )
}

export default SideBar