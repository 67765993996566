import { ITEM, REPORT, ITEM_HOME_LIKE_UPDATE, TOGGLE_LIVE} from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        bumpData: [],
        error: false,
        page: 1,
        recommendedForYou: [],
        topSearches: [],

        isLoadingMore: false,
        paginatorInfo: {},
        reset: false,

        isLoadingReport: false,
        reportMessage: false,
        reportError: false,

        isLoadingToggleLive: false,
        toggleLiveMessage: false,
        toggleLiveError: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case ITEM.LOAD:
            return {...state, isLoading: true, error: null}

            case ITEM.SUCCESS:
            const itemData =   action.payload && action.payload.data && action.payload.data.data ? action.payload.data.data : []
            const paginatorInfo = action.payload && action.payload.data && action.payload.data.paginatorInfo ? action.payload.data.paginatorInfo : {}
            const bumpData = action.payload && action.payload.bumpData ? action.payload.bumpData : []
            const counter = 16 - bumpData.length
            const recommended = action.payload && action.payload.data && action.payload.data.recommended_for_you ? action.payload.data.recommended_for_you : []
            const top_searches = action.payload && action.payload.data && action.payload.data.top_searches ? action.payload.data.top_searches : []

            let arr = []
            itemData.map((item, index) => {
                const actualIndex = index + 1
                if (bumpData && bumpData.length > 0) {
                        if (index === 0) {
                            bumpData.map((bump) => {
                                arr.push(bump)
                            })
                        } else if(index !== 0 && index % counter === 0) {
                            bumpData.map((bump) => {
                                arr.push(bump)
                            })
                        }
                        else {

                        }
                    arr.push(item)
                }
                else {
                    arr.push(item)
                }
            })
            paginatorInfo.total = arr.length

            return {...state, 
                isLoading: false, 
                isLoadingMore: false, 
                data: state.data.concat(arr), 
                paginatorInfo: paginatorInfo,
                error: null, 
                page: state.page + 2,
                bumpData: bumpData,
                recommendedForYou: recommended,
                topSearches: top_searches
            }
            case ITEM.LOAD_MORE:
            return {...state, isLoadingMore: true, error: null}

            case ITEM.LOAD_MORE_SUCCESS:
                const moreData =   action.payload && action.payload.data ? action.payload.data : []
                const morePaginatorInfo = action.payload && action.payload.paginatorInfo ? action.payload.paginatorInfo : {}

                let moreArr = []
                moreData.map((item, index) => {
                    if (state.bumpData && state.bumpData.length > 0) {
                            if (index === 0) {
                                state.bumpData.map((bump) => {
                                    moreArr.push(bump)
                                })
                            } 
                        moreArr.push(item)
                    }
                    else {
                        moreArr.push(item)
                    }
                })
                morePaginatorInfo.total = moreArr.length

                return {...state, 
                    isLoading: false, 
                    isLoadingMore: false, 
                    data:  state.data.concat(moreArr), 
                    paginatorInfo: morePaginatorInfo,
                    error: null, 
                    page: state.page + 1
            }

            case ITEM.FAILED:
            return {...state, isLoading: false,isLoadingMore: false, error: action.payload}

            case ITEM.RESET:
            return {...state, reset: !state.reset, page: 1,  data: [] }    

            case REPORT.LOAD:
            return {...state, isLoadingReport: true, reportError: null}

            case REPORT.SUCCESS:
            return {...state, isLoadingReport: false, reportError: null, reportMessage: action.payload}

            case REPORT.FAIL:
            return {...state, isLoadingReport: false, reportError: action.payload, reportMessage: null}

            case REPORT.RESET:
            return {...state, data: state.data, isLoadingReport: false, reportError: null, reportMessage: null}

            case ITEM.SET_RESERVE:
                const {b_reserved, id} = action.payload
                const dataArr = state.data.map(item => {
                    if (parseInt(item.pk_i_id) === id) {
                        return {...item, b_reserved: b_reserved }
                    } else {
                        return item
                    }
                })
            return {...state, 
                data:  dataArr, 
            }

            case ITEM.SET_SOLD:
                const {b_mark_as_sold, soldItemId} = action.payload
                const soldDataArr = state.data.map(item => {
                    if (parseInt(item.pk_i_id) === parseInt(soldItemId)) {
                        return {...item, b_mark_as_sold: b_mark_as_sold }
                    } else {
                        return item
                    }
                })
            return {...state, 
                data:  soldDataArr, 
            }

            case ITEM.SET_HIGHLIGHT:
                const {hl_expiration, promoteItemId} = action.payload
                const highlightDataArr = state.data.map(item => {
                    if (parseInt(item.pk_i_id) === promoteItemId) {
                        return {...item, hl_expiration: hl_expiration }
                    } else {
                        return item
                    }
                })
            return {...state, 
                data:  highlightDataArr, 
            }

            case ITEM.SET_TOP_AD:
                const {ta_expiration, promoteItemTopAdId} = action.payload
                const topAdDataArr = state.data.map(item => {
                    if (parseInt(item.pk_i_id) === promoteItemTopAdId) {
                        return {...item, ta_expiration: ta_expiration }
                    } else {
                        return item
                    }
                })
            return {...state, 
                data:  topAdDataArr
            }

            case ITEM_HOME_LIKE_UPDATE.SUCCESS:
                const likeId = action && action.payload && action.payload.pk_i_id
                const likeItem = action && action.payload
                const likeUpdateArr = state.data.map(item => {
                    if (item.pk_i_id === likeId) {
                        return likeItem
                    } else {
                        return item
                    }
                })
                const likeUpdatedRecommended = state.recommendedForYou.map(item => {
                    if(item.pk_i_id === likeId){
                        return likeItem
                    }else {
                        return item
                    }
                })

                const likeUpdateTopSearches = state.topSearches.map(item => {
                    if(item.pk_i_id === likeId){
                        return likeItem
                    }else {
                        return item
                    }
                })

            return {...state, data:  likeUpdateArr, recommendedForYou: likeUpdatedRecommended, topSearches: likeUpdateTopSearches}

            case ITEM.SET_WATCHER:
                const liked_item_id = action.payload && action.payload.item_id ? action.payload.item_id : null
                const watcher = action.payload && action.payload.watcher ? action.payload.watcher : []

                if (state.data && state.data.length > 0) {
                    const updateDataItemWatcher = state.data.map(item => {
                        if (parseInt(item.pk_i_id) === parseInt(liked_item_id)) {
                            return {...item, watcher: watcher }
                        } else {
                            return item
                        }
                    })
                    return {...state, data: updateDataItemWatcher}
                } 
                
                else {
                    return {...state}
                }

            default:
            return state;  
        }
    }
    
    