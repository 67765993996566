import React, { useState, useEffect, useReducer } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import { cPasswordLoad , cPasswordClearMessage} from '../../../redux/action'
import Loader from '../../../common/component/loader'
import Message from '../../../common/message'
import { theme } from '../../../common/theme'
import { style } from './style'

const { REACT_APP_WEB_SEC } = process.env

const Forgot = (props) => {
    const fkv2_session = props.session.fkv2_session ? props.session.fkv2_session : false
    const { cPasswordIsLoading, cPasswordError, cPasswordData} = props.user
    const currentUrl = window.location.href
    const [notValid, setNotValid] = useState(false)
    const [reDirect, setRedirect] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            password: '',
            rePassword: '',
            email: '',
            type: 'user'
        }
    );

    useEffect(() => {
      if (!fkv2_session) {
        linkValid()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const checkPageStatus = () => {
        if (fkv2_session) {
            return <Redirect to='/'/>
        }
    }

    const linkValid = () =>{
        const split = currentUrl.split(REACT_APP_WEB_SEC)
        const hashedEmail = split[1]
        const registeredDate = split[2] ? window.atob(split[2]) : false
        const hoursOld = moment().diff(moment(registeredDate), 'hours');
        split && split[3] ? setUserEmail(split[3]) : console.log('no email')

        setTimeout(() => {
            if(hoursOld >= 48) { 
                setNotValid(true)
            }
            else{
                // eslint-disable-next-line
                setUserInput({['email']: hashedEmail});
            }
        }, 2000)
    }

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserInput({[name]: newValue});
      }
    
      const handleSubmit = evt => {
        evt.preventDefault();
        props.cPasswordLoad(userInput)
      }

      const parseError = (error) => {
        let errorArray = []
        if (typeof error === 'string') {
          errorArray.push(<p key={1} className="error">{error}</p>)
        } else {
          for (var key in error) {
            if (error.hasOwnProperty(key)) {
                errorArray.push(<p key={key} className="error">{error[key].toString().replace('input.', '')}</p>)
            }
          }
        }
        return errorArray
      }

      const renderRedirect = () => {
        if (reDirect) {
            return <Redirect to='/login'/>
        }
      }
    
      //hooks to clear error / message
      useEffect(() => {
        // if (cPasswordError) {
        //   setTimeout(() => {
        //   props.cPasswordClearMessage()
        //   setRedirect(true)
        //   }, 3000)
        // }
        if (cPasswordData) {
          setTimeout(() => {
          props.cPasswordClearMessage()
          setRedirect(true)
          }, 5000)
        }
      // eslint-disable-next-line
      }, [props.user.cPasswordError, props.user.cPasswordData])

      const [peek, setPeek] = useState(false)
      const handleSetPeek = () => {
        setPeek(!peek)
      }

    const resetSuccessfull = () => {
      return (
        <React.Fragment>
          <div className="forgot-pw-x-icon">
              <Link to='/login'><i className="fas fa-times" style={{fontSize: 20}}></i></Link>
          </div>
          <p className="head-title">{`Password Reset Successfully`}</p>
          <div className="round-check-container">
            <svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6104 39.177L30.786 51.4277L59.1957 22.8428" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28.1224 70.2685C48.0111 75.3605 68.0954 62.9011 72.1364 44.0559C76.0818 25.6562 63.6347 7.38372 45.6734 2.9154C27.4645 -1.61467 7.81938 9.13109 2.87349 27.3516C-4.68868 55.8762 21.1164 68.5546 22.4111 68.5522" stroke="#219653" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <p style={{textAlign: 'center', marginBottom: 16}}>{`You have successfully reset the password with the account with the email`}
            <p style={{color: '#FB770D'}}>{userEmail}</p>
            <p>{`You will be redirected to Login page in 5 seconds`}</p>
          </p>
          <input className="login-btn" type="submit" value={"Back to login"} />
        </React.Fragment>
      )
    }

    return (
        <div style={style.boxContainer}>
            {checkPageStatus()}
            {renderRedirect()}
            {
            // cPasswordIsLoading ? <Loader /> :
                notValid ? 
                    <Message message={theme.text.message.linkExpired}/> : 
                    <div className="forgot-pw-main-container">
                    <div className="forgot-pw-container">
                        <div className="forgot-pw-sub-container">
                          { cPasswordIsLoading ?  <div style={{minWidth: 304}}><Loader /></div> :
                            cPasswordData ? resetSuccessfull() :
                            <React.Fragment>
                                <div className="forgot-pw-x-icon">
                                    <Link to='/login'><i className="fas fa-times" style={{fontSize: 20}}></i></Link>
                                </div>
                                <div className="head-title">
                                    <p>{`Set Your Password`}</p>
                                </div>
                                <p style={{textAlign: 'center', marginBottom: 16}}>{`Create new password for `}
                                  <p style={{color: '#FB770D'}}>{userEmail}</p>
                                </p>
                                <div>
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="login-form-input-main-container">
                                        <div className="login-form-input-sub-container normal-input-border">
                                            <div className="login-form-input-container">
                                            {userInput.password ? <label className="login-form-label">{`Password`}</label> : null}
                                                <input className="login-form-main-input" placeholder="Enter password" minLength={8} type={peek ? `text` : `password`} name="password" autocomplete="off" value={userInput.password} onChange={(e) =>handleChange(e)} required />
                                                <i className={` far ${ peek ? `fa-eye-slash`  : `fa-eye`}`} onClick={ () => handleSetPeek()} />
                                            </div>
                                        </div>
                                        { cPasswordError ? <div> {parseError(cPasswordError)} </div> : null}
                                    </div>
                                    <input className="login-btn" type="submit" value={"Continue"} />
                                    </form>
                                </div>
                            </React.Fragment>
                          }
                        </div>
                    </div>
                </div>
            }
        </div>

    );
  }

  const mapStateToProps = store => {
    return {
      user: store.userReducer
    };
  };
  
  const mapActionToProps = {
    cPasswordLoad,
    cPasswordClearMessage
  }

export default connect(mapStateToProps,mapActionToProps)(Forgot);