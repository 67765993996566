import { USER_ITEM } from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        page: 1,
        isLoadingMore: false,
        paginatorInfo: {},
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case USER_ITEM.LOAD:
            return {...state, isLoading: true, error: null}

            case USER_ITEM.SUCCESS:
            const initialUserItemData = action.payload && action.payload.data ? action.payload.data : []
            const initialUserItemPageInfo = action.payload && action.payload.paginatorInfo ? action.payload.paginatorInfo : {}
            return {...state, isLoading: false, isLoadingMore: false, data: initialUserItemData, error: null, page: state.page +1, paginatorInfo:  initialUserItemPageInfo}

            case USER_ITEM.LOAD_MORE:
            return {...state, isLoadingMore: true, error: null}

            case USER_ITEM.LOAD_MORE_SUCCESS:
            const userItemData = action.payload && action.payload.data ? action.payload.data : []
            const userItemPageInfo = action.payload && action.payload.paginatorInfo ? action.payload.paginatorInfo : {}
            return {...state, isLoading: false, isLoadingMore: false, data: state.data.concat(userItemData), error: null, page: state.page +1, paginatorInfo:  userItemPageInfo}
    
            case USER_ITEM.FAILED:
            return {...state, isLoading: false,isLoadingMore: false, error: action.payload}

            case USER_ITEM.REALTIME_FIELD_UPDATE:
                const realTimeItemId = action.payload && action.payload.item && action.payload.item.item_id ? action.payload.item.item_id : null
                const realTimeFieldName = action.payload && action.payload.item && action.payload.item.field_name ? action.payload.item.field_name : null
                const realTimeFieldValue = action.payload && action.payload.item && action.payload.item.field_value ? action.payload.item.field_value : null
                const userItemArr = state.data.map(item => {
                if (parseInt(item.pk_i_id) === parseInt(realTimeItemId)) {
                    return {...item, [realTimeFieldName]: realTimeFieldValue }
                } else {
                    return item
                }
            })
            return {...state, 
                data:  userItemArr, 
            }

            case USER_ITEM.RESET:
            return initialState;

            default:
            return state;  
        }
    }
    
    