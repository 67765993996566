import React from 'react';
import PropTypes from 'prop-types';
import CategoryBar from '../../common/component/categoryBar'
import SettingsLink from '../web/profile/settings/settingsLink'
import PrivacyAndTerms from '../web/privacyAndTerms'

const LayoutWithTwoSidebar = ( { component: Component, type, session, ...rest } ) => (

    <React.Fragment>
        <CategoryBar session={session}/>
            <div className='settings-container'>
            <div className="settings-main-container">
                {
                    window.innerWidth < 600 ? null :
                    <div className="settings-link-container">
                        <SettingsLink session={session}/>
                    </div>  
                }
                <Component {...rest} session={session}/>
            </div>
        </div>
        
    </React.Fragment>

);

LayoutWithTwoSidebar.propTypes = {
    component: PropTypes.any
  };

  export default LayoutWithTwoSidebar;
