import React, { useContext } from 'react';
import { theme } from '../../theme'
import { PromoteContext } from '../../context';

export const ReportingSelect = () => {
    const value = useContext(PromoteContext);
    const { setReportInput } = value
    const data = theme.text.reportListingOptions

    const handleOnchange = (event) => {
        const report_type = event.target.value === 'Suspicious Account' ? 'User' : event.target.value === 'Selling counterfiet items' ? 'User' : 'Item'
        setReportInput({
            report_reason: event.target.value,
            report_type: report_type
        })
    } 

    return (
      <div className="report-select-main-selection-container">
        <select name={'reportOptions'} className="report-select-input-form-option " onChange={(event) => handleOnchange(event)} required>
        <option selected disabled>Please select an option</option>
        {
          data.map((item, index) => {
            let arr = []
                arr.push(
                  <option key={index} value={item.label}>{`${item.label }`}</option>
                )
            return arr
          })
        }                    
    </select>
    </div>
    )
}