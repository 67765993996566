import React, { useContext, useReducer, useEffect, useState} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom'
import FKImage from '../../../../../common/component/image'
import { ItemContext, ListingContext} from '../../../../../common/context';
import {ReportingSelect} from './reportSelect'
import Category from '../../../../../common/component/category'
import Loader from '../../../../../common/component/loader'
import DefaultItem from '../../../../../assets/images/commerce-and-shopping.png'
import { CustomFieldList } from '../../../item/new/component/CustomComponent'
import { isEmpty, numberWithCommas, slugify, categoryParser, fromNow} from '../../../../../common/helper/utility'
import { GreenOnlineIcon, OrangeCircleIcon, GrayCircleIcon, RedCircleIcon, DefaultHeartIcon, ProfileHeartIcon, ProfileEyeIcon, EllipsisIcon, EditIconBtn, TrashIconBtn} from '../../../../../assets/svg'
import {PremiumTag, NewTag, SoldTag} from '../../../../../common/tags'
import {WTT, WTB} from '../../../../../common/component/label'
import LikeButton from '../../../../../common/component/like'

export const ItemEditModal = () => {
    const value = useContext(ItemContext);
    const { 
        handleEditSubmit, selectedItem, isShowEdit, closeEditModal, updateIsLoading, updateMessage, updateError
    } = value

    const { oc_t_category, oc_t_item_description, pk_i_id, get_i_price, custom_fields } = selectedItem
    const categoryId = oc_t_category && oc_t_category.pk_i_id ? oc_t_category.pk_i_id : null
    const item_title = oc_t_item_description && oc_t_item_description.s_title ? oc_t_item_description.s_title : null
    const item_desc = oc_t_item_description && oc_t_item_description.s_description ? oc_t_item_description.s_description : null
    const raw_price = get_i_price

    const item_custom_field = custom_fields ? custom_fields : {}
    const category_custom_field = oc_t_category && oc_t_category.oc_t_custom_field ? oc_t_category.oc_t_custom_field : []
    // item_custom_field, category_custom_field

    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }), {}
    );

    const customFieldParser = () => {
        const customFieldParse = category_custom_field ? category_custom_field : []

        let cfObject = {}
        if (customFieldParse.length > 0) {
          customFieldParse.map((item) => {
            const parsedCustomField = isEmpty(item_custom_field) ? {} : JSON.parse(item_custom_field)
            const value = parsedCustomField && parsedCustomField[item.name] ? parsedCustomField[item.name] : ''
            cfObject[item.name] = {...item, value: value}
          })
        }
        return cfObject
    }
    const handleCustomFieldChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        const cfObject = itemInput.custom_fields
        let finalObj = {}
        for (var k in cfObject) {
            if (cfObject[k].name === name) {
            finalObj[name] = {...cfObject[k], value: newValue}
            }
            else {
            finalObj[cfObject[k].name] = {...cfObject[k]}
            }
        }
        setItemInput({['custom_fields']: finalObj});
    }

    const editSubmitHandler = evt => {
        evt.preventDefault();
          if(
            //comment next line to bypass recaptcha
            itemInput.captcha === '' ||
            itemInput.item_title === '' || itemInput.item_desc === '' || itemInput.item_desc.length < 9 || itemInput.raw_price === '' || itemInput.categoryId === ''){
            setItemInput(
              {
              error: 
              {['name']: itemInput.item_title === '' ? 'Enter title' : null, 
              ['description']: itemInput.item_desc === '' ? 'Enter description' :  null, 
              ['price']: itemInput.raw_price === '' ? 'Enter Price' : itemInput.raw_price.includes("-") ? 'Invalid Price' : itemInput.raw_price === 0 ? 'Price should not be equal to 0' : null, 
              ['category']: itemInput.categoryId === ''  ? 'Enter Category' : null} 
            })
            setItemInput({['inputError']: 'Recaptcha Error'})
          }else{
              //parse custom field
              let finalObj = {}
              for (var k in itemInput) {
                if (k === 'custom_fields') {
                  let finalObj2 = {}
                  for(var l in itemInput[k]){
                    if (itemInput[k].hasOwnProperty(l)) {
                      const value = itemInput[k] && itemInput[k][l] && itemInput[k][l].value ? itemInput[k][l].value : ''
                      const name = itemInput[k] && itemInput[k][l] && itemInput[k][l].name ? itemInput[k][l].name : ''
                      finalObj2[name] = value
                    }
                  }
                  finalObj[k] = JSON.stringify(finalObj2)
                }
              }
              const fileForSubmit = {...itemInput, custom_fields : finalObj.custom_fields }
              handleEditSubmit(fileForSubmit) 
          }
        // setUserInput({[name]: ''})
      }

    useEffect(() => {
        const customFieldParsed = customFieldParser()
        setItemInput({
            pk_i_id: pk_i_id,
            raw_price: raw_price,
            item_title: item_title,
            item_desc: item_desc,
            categoryId: categoryId,
            custom_fields: customFieldParsed,
        })
    }, [])

    const handleChange = (evt) => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : evt.target.value === 'false' ? false : evt.target.value;
        setItemInput({
            [name]: newValue
        });
    }

    const parsedError = (error) => {
        let errorMessage
        if (error.includes('file')) {
            errorMessage = "Item posted with no image, due to error processing your picture."
        } else {
            errorMessage = error
        }
        return errorMessage
    }

    return (
        <div className={isShowEdit ? `flex contact-modal` : `hide`}>
            <div className="desktop-edit-item-modal">
                <span style={{ cursor: 'pointer', float: 'right', fontSize: '16px' }} onClick={() => closeEditModal()}>&times;</span>
                <div>
                    <div style={{ marginBottom: 10 }}>
                        <h4>Edit Item</h4>
                    </div>
                    <div className="contact-form-container">
                        <div className="input-container">
                            <Category name={'categoryId'} selectedId={itemInput.categoryId ? itemInput.categoryId : 0} onChange={event => handleChange(event)} />
                        </div>
                        <div className="input-container">
                            <div className="edit-input-container">
                                <div className="edit-input-sub-container">
                                    <div className="edit-input-main-container">
                                        <label className="edit-input-label">Title </label>
                                        <input type="text" name={'item_title'} value={itemInput.item_title ? itemInput.item_title : ''} onChange={event => handleChange(event)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomFieldList data={itemInput.custom_fields} handleCustomFieldChange={handleCustomFieldChange} />
                        <div className="input-container">
                            <div className="input-textarea-container">
                                <div className="input-textarea-sub-container">
                                    <label className="edit-input-label edit-textarea-label">Description </label>
                                    <textarea className="input-textarea" name={'item_desc'} value={itemInput.item_desc ? itemInput.item_desc : ''} onChange={event => handleChange(event)} />
                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="edit-input-container">
                                <div className="edit-input-sub-container">
                                    <div className="edit-input-main-container">
                                        <label className="edit-input-label">Price </label>
                                        <input type="number" name={'raw_price'} value={itemInput.raw_price ? itemInput.raw_price : 0} onChange={event => handleChange(event)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {updateError ? <p style={{ color: 'red', marginBottom: 10 }}>{parsedError(updateError)}</p> : null}
                        {updateMessage ? <p style={{ color: 'green', marginBottom: 10 }}>{updateMessage}</p> : null}
                        {updateIsLoading ? <p>Processing...</p> : <button className="btn primary" onClick={(e) => editSubmitHandler(e)}>Update</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ItemDeleteModal = () => {
    const value = useContext(ItemContext);
    const { isShowDelete, closeDeleteModal, selectedId, selectedTitle, handleDeleteSubmit, deleteIsloading, deleteMessage, deleteError } = value
    return (
        <div className={isShowDelete ? `flex contact-modal` : `hide`}>
            <div className="delete-item-modal">
                {deleteIsloading ? <Loader /> :
                    deleteMessage ? <p>Item successfully deleted.</p> :
                        deleteError ? <p style={{ color: 'red' }}>{deleteError}</p> :
                            <>
                                <p className="delete-close" onClick={() => closeDeleteModal()}>&times;</p>
                                <p className="delete-title">Delete listing?</p>
                                <p className="delete-confirm-text">Are you sure you want to delete your listing item?</p>
                                <p className="delete-item-title">{selectedTitle}</p>
                                <div className="delete-button-cont">
                                    <button className="delete-button delete-cancel" onClick={() => closeDeleteModal()}>Cancel</button>
                                    <button className="delete-button delete-ok" onClick={() => handleDeleteSubmit(selectedId)}>Delete</button>
                                </div>
                            </>
                }
            </div>
        </div>
    )
}

export const ProfileListingsContainer = ({data}) => {
    const value = useContext(ListingContext);
    const {toggleLive, updateIsLoading, submitItemUpdateByFieldLoad, handleEllipsisClick, isEllipsisClick, sessionId, togglePromoteShow, setSelected, likeDislike, likeDislikeLoading, watchlistArr, markAsSoldUnsold} = value
    console.log("ProfileListingsContainer data", data)
    const activeArr = data ? data.filter(item => !item.b_mark_as_sold) : []
    const soldArr = data ? data.filter(item => item.b_mark_as_sold) : []
    const finalData = [...activeArr, ...soldArr]
    const toggleLiked = (itemId) => {
        if( sessionId ){
          // console.log("hjsdfbjhsdgfjhsdf", item_id, itemId)
          likeDislike(itemId)
          // console.log("like func", itemId)
        }
      }
    return (
        <div className="profile-listings-in-container">
            {
                finalData && finalData.map((item, i) => {
                    const item_id = item && item.pk_i_id ? item.pk_i_id : 0
                    const date = item && item.dt_pub_date ? fromNow(item.dt_pub_date) : ''
                    const img_src = item && item.oc_t_item_resource ? item.oc_t_item_resource : null
                    const isNew = item && item.is_new ? item.is_new : null
                    const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : null
                    const price =  item && item.get_i_price ? item.get_i_price : 0
                    const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
                    const view_count = item && item.pageview_count ? item.pageview_count : 0
                    const watchlist_count = item && item.watchlist_count ? item.watchlist_count : 0
                    const isLive = item && item.live && item.live === 'live' ? true : false
                    const isNotLive = item && item.live === null ? false : item.live === 'not_live' ? true : false
                    const top_ad_exp = item && item.ta_expiration ? moment().isSameOrBefore(item.ta_expiration) : false
                    const item_slug = slugify(title)
                    const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
                    const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
                    const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
                    const finalSLug = `/${category}/${item_slug}_i${item_id}?title=${title}&cover_photo=${cover_photo}&description=${item_description}`
                    const post_type = item && item.post_type ? item.post_type : ''
                    const isOldActive = item && item.b_active ? item.b_active && item.live === null ? true : false :false
                    const found = watchlistArr && watchlistArr.some(el => parseInt(el.fk_i_item_id) === parseInt(item_id));
                    const watchIndex = watchlistArr ? watchlistArr.findIndex(item => parseInt(item.fk_i_item_id) === parseInt(item_id)) : 0
                    const initial_watchlist_count = watchlistArr && watchlistArr[watchIndex] && watchlistArr[watchIndex].items && watchlistArr[watchIndex].items.watchlist_count ? watchlistArr[watchIndex].items.watchlist_count : null
                    const hl_exp = item && item.hl_expiration ? moment().isSameOrBefore(item.hl_expiration) : false

                    return (
                        // <div className="fk-profile-listings-card" key={i}>
                        <div className={`fk-profile-listings-card ${hl_exp ? `bg-highlight p-2` : ``}`} key={i}>
                            <Link to={finalSLug} className="cursor-pointer">
                            <p className="profile-item-date-p">{date}</p>
                            <div className="p-relative listing-image-profile-container">
                                {
                                    img_src ? 
                                    <FKImage images={img_src} type="listing-thumbnail" /> :
                                    <img src={DefaultItem} width={214} height={214} loading="lazy"/>                                
                                }
                                {/* {
                                isNotLive && !sold ?
                                <div className="w-100 h-100 listing-not-active-label-container">
                                    <p>{`Listing Not Active`}</p>
                                </div> : null
                                } */}
                                {
                                sold ?
                                <div className="p-absolute sold-tag-container">
                                    <SoldTag />
                                </div> : null
                                }
                                {
                                    isNew ?
                                    <div className="p-absolute new-tag-container">
                                        <NewTag />
                                    </div> : null
                                }
                                {
                                    top_ad_exp ?
                                    <div className="p-absolute premium-tag-container">
                                        <PremiumTag />
                                    </div> : null
                                }
                            </div>
                            <div className="flex flex-justify-between flex-align-center w-100">
                                <p className="profile-item-price-p">{`S$ ${numberWithCommas((price).toFixed(2))}`}</p>
                                {
                                    post_type ? post_type === 'WTS' ? null :
                                    post_type === 'WTB' ? <WTB /> : <WTT /> : null
                                }
                            </div>
                                <p className="profile-title-p">{title}</p>
                                <div className="flex flex-d-row">
                                    {item.is_new ? <p className="profile-item-new-used-p">{`New`}</p> : null}
                                    {!item.is_new ? <p className="profile-item-new-used-p">{`Used`}</p> : null}
                                </div>
                                </Link>
                                <div className="flex flex-d-row flex-align-center border-bottom pb-9 mt-20">
                                    <div className="flex flex-d-row flex-align-center">
                                    <LikeButton toggleLiked={toggleLiked} likeDislikeLoading={likeDislikeLoading} isLiked={found} likeId={item_id} style="black-border-heart"/>
                                        <p className="ml-4 profile-watchlist-count-p">{found ? initial_watchlist_count : watchlist_count}</p>
                                    </div>
                                    <div className="flex flex-d-row flex-align-center w-100 flex-justify-center">
                                        <ProfileEyeIcon />
                                        <p className="ml-4 profile-view-count-p">{view_count}</p>
                                    </div>
                                    <div className="p-relative cursor-pointer" onClick={() => handleEllipsisClick(item_id)}>
                                        <EllipsisIcon />
                                        {
                                            isEllipsisClick === item_id ?
                                            <div className="p-absolute more-action-container">
                                            <div className="flex flex-d-row flex-align-center edit-listing-container cursor-pointer">
                                                <EditIconBtn />
                                            <Link to={{pathname :`/user/item/new`, query: {item}, id: {sessionId}}} > <p className="edit-listing-p">{`Edit Listing`}</p> </Link> 
                                            </div>
                                            <div className="flex flex-d-row flex-align-center cursor-pointer" 
                                             onClick={()=> setSelected(item_id, title)}
                                            // onClick={()=> setIsDeleteClick(true)}
                                            >
                                                <TrashIconBtn />
                                                <p className="edit-listing-p color-delete-p">{`Delete Listing`}</p>
                                            </div>
                                        </div> : null
                                        }
                                    </div>
                            </div>
                            {/* {
                                isLive && sold === null  ?
                                <div className="active-profile-item-container">
                                    <p>{`Active`}</p>
                                </div> : null
                            } */}
                            {
                                sold ?
                                <div className="mt-20 profile-unsold-btn-container" onClick={() => markAsSoldUnsold({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: false })}>
                                    <p>{`Repost`}</p>
                                </div> : null
                            }
                            {/* {
                                item.is_live === false && !sold ?
                                <div className="buy-slot-btn-container">
                                    <p>{`Buy Slot`}</p>
                                </div> : null
                            } */}
                            {
                                sold === null && item.is_live || sold === null &&  item.is_live === null || sold === null && item.is_live === undefined ?
                                <div className="sold-item-profile-btn-container" onClick={() => markAsSoldUnsold({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: true }, true)}>
                                    <p>{`Mark as Sold`}</p>
                                </div> : null
                            }
                            {
                                // sold || isNotLive || isOldActive ? null :
                                sold ? null :
                                <div className="profile-promote-listing-btn-container" onClick={() => togglePromoteShow(item)}>
                                    <p>{`Promote`}</p>
                                </div>
                            }
                            
                            {/* {
                                isLive && sold === null ?
                                <div className="deactivate-listing-btn-container" onClick={() => toggleLive(item.pk_i_id, 'not_live')}>
                                    <p>{`Deactivate Listing`}</p> 
                                </div> : null
                            }
                            {
                                isNotLive && sold === null ?
                                <div className="activate-listing-btn-container" onClick={() => toggleLive(item.pk_i_id, 'live')}>
                                    <p>{`Activate Listing`}</p>
                                </div> : null
                            } */}
                        </div>
                    )

                })
            }
        </div>
    )
}

export const LiveListingContainer = () => {

    const value = useContext(ListingContext);
    const {listings, unusedListingCount, isFreeClaimed} = value
    const {live} = listings
    return (
        <div className="w-100 live-listing-container">
            {/* <div className="flex flex-d-row flex-justify-between">
                <div className="flex flex-d-row">
                    <GreenOnlineIcon />
                    <div className="flex flex-d-column ml-9">
                        <p className="profile-live-listings-title-p">{`Live Listings`}</p>
                        <p className="profile-live-listing-sub-title-p">{`Sellers and buyers will see these listings`}</p>
                    </div>
                </div>
                <div className="flex flex-justify-end flex-align-end">
                    <p className="profile-live-listings-see-all-p">{`See all`}</p>
                </div>
            </div>  */}
            <div className="flex flex-d-row flex-justify-between mb-12">
                <div className={`flex flex-d-row ${window.innerWidth < 600 ? `flex-align-center` : ``}`}>
                    <GreenOnlineIcon />
                    <div className="flex flex-d-column ml-9">
                        <p className="profile-live-listings-title-p">{`${live.length} Live Listing${live.length < 2 ? `` : `s` }`}</p>
                    </div>
                </div>
                <div className="flex flex-justify-end flex-align-end">
                    <p className="profile-live-listings-see-all-p">{`See all`}</p>
                </div>
            </div>
            <div className={`w-100 flex flex-align-center ml-23 ${window.innerWidth< 600 ? `mx-w-231` : ``}`}>
                    <p className="profile-live-listing-sub-title-p">{`Sellers and buyers will see these listings`}</p>
            </div>
            <div className={`w-100 flex flex-d-row ${isFreeClaimed ? `flex-justify-end flex-align-end` : window.innerWidth < 600 ? `pt-20 flex-align-start flex-justify-between flex-align-start` : `flex-justify-between flex-align-end`} ${window.innerWidth < 600 ? `mt-30` : ``}`}>
                {
                    isFreeClaimed ? null :
                    <div className={`flex ${window.innerWidth < 600 ? `p-relative flex-d-column h-px-54 w-px-97` : `flex-d-row flex-align-center`}  ml-23`}>
                        <p className="user-listing-unclaimed-disc-p">{`You have 5 free slots`}</p> &nbsp; &nbsp;
                        <Link to={`/fk-prime`} className={`${window.innerWidth < 600 ? `p-absolute bottom-n-7 ` : ``}`}>
                            <p className="user-listing-unclaimed-claim-link-p">{`CLAIM`}</p>
                        </Link>
                    </div>
                }
                {
                    !isFreeClaimed && window.innerWidth < 600 ?
                    
                <div className="h-px-60 flex flex-justify-center flex-align-center "><div className="custom-divider-listing-profile" /></div> : null
                    
                }
                <div className={`flex flex-align-end  ${isFreeClaimed ? `mt-30` : window.innerWidth < 600 ? `text-align-end flex-d-column flex-justify-end mx-w-139` : ` flex-d-row`}`}>
                    <p className={`slots-remaining-p ${isFreeClaimed ? `` : window.innerWidth < 600 ? `` : `mr-0`}`}>{`You only have `} <span>{unusedListingCount}</span> {` Slot/s remaining`}</p>
                    <Link to={{pathname :`/fk-prime`, isBuySlot: true}} className="user-listing-buy-slot-btn">
                        <GreenOnlineIcon />
                        <p className="user-listing-buy-slot-btn-p">{`Buy Slot`}</p>
                    </Link>
                </div>
            </div>
            <div className="profile-listings-in-container">
                {live && live.length === 0 ? 
                // <div className="w-100 h-100 flex flex-justify-center flex-align-center mt-50"><p className="no-listings-available-p">{`No Listings Available`}</p></div> 
                null
                : 
                <LiveListingCard data={live} /> }
                {unusedListingCount > 0 ? LiveListingNullCard(unusedListingCount): null}
            </div>
        </div>
    )
}

export const ActiveListingsContainer = () => {

    const value = useContext(ListingContext);
    const {listings, seeAll, seeAllHandler} = value
    const {active} = listings
    const {active_length} = seeAll
    // let size = 10
    const items = active && active.slice(0, active_length).map(i => {
        return i
    })

    return (
        <div className="w-100 live-listing-container mt-30">
            <div className="flex flex-d-row flex-justify-between">
                <div className="flex flex-d-row">
                    <OrangeCircleIcon />
                    <div className="flex flex-d-column ml-9">
                        <p className="profile-live-listings-title-p">{`${active.length} Active Listing${active.length < 2 ? `` : `s`}`}</p>
                        <p className="profile-live-listing-sub-title-p">{`These listings are from previous version. Seller and buyers will still be able to see these.`}</p>
                    </div>
                </div>
                    {
                        active.length >= active_length ?
                        <div className="flex flex-justify-end flex-align-end" onClick={() => seeAllHandler('active_length',active.length === active_length ? 10 : active.length)}>
                            <p className="profile-live-listings-see-all-p">{active.length === active_length ? `See less` : `See all`}</p>
                        </div> : null
                    }         
            </div>
            {active && active.length === 0 ? 
            <div className="w-100 h-100 flex flex-justify-center flex-align-center mt-50">
                <p className="no-listings-available-p">{`No Listings Available`}</p> 
            </div> : 
            <ProfileListingsContainer data={items} /> }
        </div>
    )
}

export const NotLiveListingsContainer = () => {
    const value = useContext(ListingContext);
    const {listings, seeAll, seeAllHandler} = value
    const {not_live_length} = seeAll
    const {not_live} =listings
    const items = not_live && not_live.slice(0, not_live_length).map(i => {
        return i
    })
    return (
        <div className="w-100 live-listing-container mt-30">
            <div className="flex flex-d-row flex-justify-between">
                <div className="flex flex-d-row">
                    <GrayCircleIcon />
                    <div className="flex flex-d-column ml-9">
                        <p className="profile-live-listings-title-p">{`${not_live.length} Not live listing${not_live.length < 2 ? `` : `s`}`}</p>
                        <p className="profile-live-listing-sub-title-p">{`Sellers and buyers will not see these listings`}</p>
                    </div>
                </div>
                {
                    not_live.length >= not_live_length ?
                    <div className="flex flex-justify-end flex-align-end" onClick={() => seeAllHandler('not_live_length', not_live.length === not_live_length ? 5 : not_live.length)}>
                        <p className="profile-live-listings-see-all-p">{not_live.length === not_live_length ? `See less` : `See all`}</p>
                    </div> : null
                }
            </div>
            {not_live && not_live.length === 0 ? 
            <div className="w-100 h-100 flex flex-justify-center flex-align-center mt-50">
                <p className="no-listings-available-p">{`No Listings Available`}</p> 
            </div> : <ProfileListingsContainer data={items} />}
        </div>
    )
}

export const SoldListingsContainer = () => {
    const value = useContext(ListingContext);
    const {listings, seeAll, seeAllHandler} = value
    const {sold} = listings
    const {sold_length} = seeAll
    const items = sold && sold.slice(0, sold_length).map(i => {
        return i
    })

    return (
        <div className="w-100 live-listing-container mt-30">
            <div className="flex flex-d-row flex-justify-between">
                <div className="flex flex-d-row">
                    <RedCircleIcon />
                    <div className="flex flex-d-column ml-9">
                        <p className="profile-live-listings-title-p">{`${sold.length} Sold listing${sold.length < 2 ? `` : `s`}`}</p>
                        <p className="profile-live-listing-sub-title-p">{`Promoting item make listing to re-appear again and automatically mark item as unsold.`}</p>
                    </div>
                </div>
                {
                    sold.length >= sold_length ? 
                    <div className="flex flex-justify-end flex-align-end" onClick={() => seeAllHandler('sold_length', sold.length === sold_length ? 5 : sold.length) }>
                        <p className="profile-live-listings-see-all-p">{sold.length === sold_length ? `See less` : `See all`}</p>
                    </div> : null
                }
            </div>
            {sold && sold.length === 0 ? 
            <div className="w-100 h-100 flex flex-justify-center flex-align-center mt-50">
                <p className="no-listings-available-p">{`No Listings Available`}</p> 
            </div> : <ProfileListingsContainer data={items}/>}
        </div>
    )
}

export const LiveListingNullCard = count => {
    let el = []

    for (var i = 0; i < count; i++) {
        el.push(
            <div className="null-listings-card" key={i}>
                <p className='null-listing-text'>Available Slot</p>
                <p className='null-listing-text2'>Activate listing<br />or<br />Post new item</p>
            </div>
        )
    }
    return el
}

export const LiveListingCard = ({data}) => {
    const value = useContext(ListingContext);
    const {toggleLive, updateIsLoading, submitItemUpdateByFieldLoad, handleEllipsisClick, isEllipsisClick, sessionId, setSelected, togglePromoteShow, likeDislike, likeDislikeLoading, watchlistArr, markAsSoldUnsold} = value
    let el = []
    const toggleLiked = (itemId) => {
        if( sessionId ){
          // console.log("hjsdfbjhsdgfjhsdf", item_id, itemId)
          likeDislike(itemId)
          // console.log("like func", itemId)
        }
      }
    data && data.map((item, i) => {
        const item_id = item && item.pk_i_id ? item.pk_i_id : 0
        const date = item && item.dt_pub_date ? fromNow(item.dt_pub_date) : ''
        const img_src = item && item.oc_t_item_resource ? item.oc_t_item_resource : null
        const isNew = item && item.is_new ? item.is_new : null
        const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : null
        const price =  item && item.get_i_price ? item.get_i_price : 0
        const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
        const view_count = item && item.pageview_count ? item.pageview_count : 0
        const watchlist_count = item && item.watchlist_count ? item.watchlist_count : 0
        const isLive = item && item.live && item.live === 'live' ? true : false
        const isNotLive = item && item.live === null ? false : item.live === 'not_live' ? true : false

        const item_slug = slugify(title)
        const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
        const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
        const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
        const finalSLug = `/${category}/${item_slug}_i${item_id}?title=${title}&cover_photo=${cover_photo}&description=${item_description}`

        const top_ad_exp = item && item.ta_expiration ? moment().isSameOrBefore(item.ta_expiration) : false
        const hl_exp = item && item.hl_expiration ? moment().isSameOrBefore(item.hl_expiration) : false
        const post_type = item && item.post_type ? item.post_type : ''

        const found = watchlistArr && watchlistArr.some(el => parseInt(el.fk_i_item_id) === parseInt(item_id));
        const watchIndex = watchlistArr ? watchlistArr.findIndex(item => parseInt(item.fk_i_item_id) === parseInt(item_id)) : 0
        const initial_watchlist_count = watchlistArr && watchlistArr[watchIndex] && watchlistArr[watchIndex].items && watchlistArr[watchIndex].items.watchlist_count ? watchlistArr[watchIndex].items.watchlist_count : null
        if(sold === null ){
            el.push (
                <div className={`fk-profile-listings-card ${hl_exp ? `bg-highlight p-2` : ``}`} key={i}>
                    <Link to={finalSLug} className="cursor-pointer">
                    <p className="profile-item-date-p">{date}</p>
                    <div className="p-relative listing-image-profile-container">
                        {
                            img_src ? 
                            <FKImage images={img_src} type="listing-thumbnail"/> :
                            <img src={DefaultItem} width={214} height={214} loading="lazy"/>                                
                        }
                        {
                        item.is_live === false && !sold ?
                        <div className="w-100 h-100 listing-not-active-label-container">
                            <p>{`Listing Not Active`}</p>
                        </div> : null
                        }
                        {
                        sold ?
                        <div className="p-absolute sold-tag-container">
                            <SoldTag />
                        </div> : null
                        }
                        {
                            isNew ?
                            <div className="p-absolute new-tag-container">
                                <NewTag />
                            </div> : null
                        }
                        {
                            top_ad_exp ?
                            <div className="p-absolute premium-tag-container">
                                <PremiumTag />
                            </div> : null
                        }
                    </div>
                    <div className="flex flex-justify-between flex-align-center w-100">
                        <p className="profile-item-price-p">{`S$ ${numberWithCommas((price).toFixed(2))}`}</p>
                        {
                            post_type ? post_type === 'WTS' ? null :
                            post_type === 'WTB' ? <WTB /> : <WTT /> : null
                        }
                    </div>
                        <p className="profile-title-p">{title}</p>
                        <div className="flex flex-d-row">
                            {item.is_new ? <p className="profile-item-new-used-p">{`New`}</p> : null}
                            {!item.is_new ? <p className="profile-item-new-used-p">{`Used`}</p> : null}
                        </div>
                        </Link>
                        <div className="flex flex-d-row flex-align-center border-bottom pb-9 mt-20">
                            <div className="flex flex-d-row flex-align-center">
                            <LikeButton toggleLiked={toggleLiked} likeDislikeLoading={likeDislikeLoading} isLiked={found} likeId={item_id} style="black-border-heart"/>
                                <p className="ml-4 profile-watchlist-count-p">{found ? initial_watchlist_count : watchlist_count}</p>
                            </div>
                            <div className="flex flex-d-row flex-align-center w-100 flex-justify-center">
                                <ProfileEyeIcon />
                                <p className="ml-4 profile-view-count-p">{view_count}</p>
                            </div>
                            <div className="p-relative cursor-pointer" onClick={() => handleEllipsisClick(item_id)}>
                                <EllipsisIcon />
                                {
                                    isEllipsisClick === item_id ?
                                    <div className="p-absolute more-action-container">
                                    <div className="flex flex-d-row flex-align-center edit-listing-container cursor-pointer">
                                        <EditIconBtn />
                                     <Link to={{pathname :`/user/item/new`, query: {item}, id: {sessionId}}} > <p className="edit-listing-p">{`Edit Listing`}</p> </Link> 
                                    </div>
                                    <div className="flex flex-d-row flex-align-center cursor-pointer" 
                                    onClick={()=> setSelected(item_id, title)}
                                    >
                                        <TrashIconBtn />
                                        <p className="edit-listing-p color-delete-p">{`Delete Listing`}</p>
                                    </div>
                                </div> : null
                                }
                            </div>
                        </div>
                    {/* {
                        isLive ?
                        <div className="active-profile-item-container">
                            <p>{`Active`}</p>
                        </div> : null
                    } */}
                    {
                        sold ?
                        <div className="mt-20 profile-unsold-btn-container" onClick={updateIsLoading ? null : () => markAsSoldUnsold({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: false })}>
                            <p>{`Mark as Unsold`}</p>
                        </div> : null
                    }
                    {
                        item.is_live === false && !sold ?
                        <div className="buy-slot-btn-container">
                            <p>{`Buy Slot`}</p>
                        </div> : null
                    }
                    {
                        sold === null && item.is_live || sold === null && item.is_live === null || sold === null && item.is_live === undefined ?
                        <div className="sold-item-profile-btn-container" onClick={updateIsLoading ? null : () => markAsSoldUnsold({item_id: parseInt(item_id), field_name: 'b_mark_as_sold', field_value: true }, true)}>
                            <p>{`Mark as Sold`}</p>
                        </div> : null
                    }
                    <div className="profile-promote-listing-btn-container" onClick={() => togglePromoteShow(item)}>
                        <p>{`Promote`}</p>
                    </div>
                    {/* {
                        isLive ?
                        <div className="deactivate-listing-btn-container" onClick={() => toggleLive(item.pk_i_id, 'not_live')}>
                            <p>{`Deactivate Listing`}</p> 
                        </div> : null
                    } */}
                    {
                        isNotLive || sold ?
                        <div className="activate-listing-btn-container" onClick={() => toggleLive(item.pk_i_id, 'live')}>
                            <p>{`Activate Listing`}</p>
                        </div> : null
                    }
                </div>
            )
        }
    }) 
    return el
}

export const ReportModal = () => {
    const { reportShow, setReportShow, handleReportSubmit, reportInput, isLoadingReport, reportMessage, reportError } = useContext(ListingContext)
    return(
        <div className={reportShow ? `flex modal-index-container` : `hide`}>
            <div className="modal-report-main-container">
                {
                    isLoadingReport ? <div className="modal-index-head"></div> :
                    reportMessage ? <div className="modal-index-head"></div> :
                    reportError ? <div className="modal-index-head"></div> :
                        <div className="modal-index-head">
           
                        <div style={{padding: '0px 15px'}}>
                            <p className="report-listing-label-p">{`Report Listing`}</p>
                        </div>
                        <div >
                            <span onClick={() => setReportShow(false)}>&times;</span>
                        </div>
                    </div>
                }

                <div style={{flex: 1, marginTop: '10px'}}>
                    {   
                        isLoadingReport ? <p className="text-align-center">Submitting...</p> :
                        reportMessage ? <p className="text-success text-align-center">{reportMessage}</p> :
                        reportError ? <p className="text-red text-align-center">{reportError}</p> :
                        <React.Fragment>
                            <ReportingSelect />
                            {
                            reportInput && reportInput.report_reason ? 
                            <div className="modal-report-btn-container">
                                <button type="button" className="modal-report-btn" disabled={reportInput && reportInput.report_reason ? false : true } onClick={() => handleReportSubmit()}><p className="modal-report-btn-p">{`Submit`}</p></button>
                            </div>
                                    : null
                            }
                        </React.Fragment>
                    }

                </div>
            </div>
        </div>
    )
}

export const ItemSoldModal = () => {
    const value = useContext(ItemContext);
    const { isShowDelete, closeDeleteModal, selectedId, selectedTitle, handleDeleteSubmit, deleteIsloading, deleteMessage, deleteError, 
    isShowMarkAsSold, isSold, closeSoldModal, submitSoldUnsold } = value
    const title = isSold ? 'Mark as Sold' : 'Repost?'
    const description = isSold ? 'This will Mark your item as Sold, do you want to continue?' : 'Do you want to continue?'

    return (
        <div className={isShowMarkAsSold ? `flex contact-modal` : `hide`}>
            <div className="delete-item-modal">
                <>
                    <p className="delete-close" onClick={() => closeSoldModal()}>&times;</p>
                    <p className="delete-title">{title}</p>
                    <p className="delete-confirm-text">{description}</p>
                    <div className="delete-button-cont">
                        <button className="delete-button delete-cancel" onClick={() => closeSoldModal()}>No</button>
                        <button className="delete-button delete-ok" onClick={() => submitSoldUnsold()}>Yes</button>
                    </div>
                </>
            </div>
        </div>
    )
}
