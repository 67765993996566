import React, {useContext, useEffect, useState} from 'react';
import {Button} from '../customComponents'
import {loadStripe} from '@stripe/stripe-js';
import {getStripePriceIdByAmount} from '../../../../../common/helper/utility'
import {ItemNewContext} from '../../../../../common/context'

const {REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_STRIPE_KEY} = process.env

const StripeButton = () => {
    const value = useContext(ItemNewContext);
    const {sessionData, userBoost, session} = value
    const token = session && session.fkv2_session && session.fkv2_session.access_token ? session.fkv2_session.access_token  : null
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const s_email = sessionData && sessionData.s_email ? sessionData.s_email : null
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
    const stripeCusId = sessionData && sessionData.stripe_cus_id ? sessionData.stripe_cus_id : null
    const otpDetails = userBoost && userBoost.buyCoins && userBoost.buyCoins.coinsArr ? userBoost.buyCoins.coinsArr : [] 
    const otpTotal = userBoost && userBoost.buyCoins && userBoost.buyCoins.total ? userBoost.buyCoins.total : 0
    const total_coin = userBoost && userBoost.buyCoins && userBoost.buyCoins.total_coin ? userBoost.buyCoins.total_coin : 0
    
    const [otpLineItem, setOtpLineItem] = useState([])

    const collectPriceId = (data) => {
        let res = []
        if (data && data.length > 0) {
            data.map(otp => {
                const id = getStripePriceIdByAmount(otp.sg_price)
                res.push({price: id, quantity: 1})
            })
        }
        setOtpLineItem(res)
    }

    useEffect(() => {
        if (otpDetails && otpDetails.length > 0) {
            collectPriceId(otpDetails)
        }
    },[otpDetails])

    const handleCheckout = async () => {
        // setStripeLoading(true)
        const GET_SESSION_URL = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql`
        const stripe = await stripePromise;
    
        const query = `mutation getStripeSession($input: PurchaseInput){
          getStripeSession(input: $input){
            session
          }
        }`;

        const input = generateStripeInput(otpDetails)

        console.log('handleCheckout input', input)

        const response = await fetch(GET_SESSION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query,
                variables: {input}
            })
          });
          const subscriptionSession = await response.json();  
  
          if (subscriptionSession.error) {
            console.log('subscriptionSession.error', subscriptionSession.error)
            // setStripeError(result.error.message)
            // setStripeLoading(false)
          } else {
            const result = await stripe.redirectToCheckout({
                sessionId: subscriptionSession && subscriptionSession.data && subscriptionSession.data.getStripeSession && subscriptionSession.data.getStripeSession.session ? JSON.parse(subscriptionSession.data.getStripeSession.session) : null
              }); 
            if (result.error) {
                console.log('result.error', result.error)
                // setStripeError(result.error.message)
                // setStripeLoading(false)
            }
          }
    };

    const generateStripeInput = (otpDetails) => {
        let line_item_details = []
        let line_items = []
            line_items = otpLineItem
            otpDetails && otpDetails.length > 0 && otpDetails.map(data => {
                const boostOtpDetailsObj = {
                    "type": data.type,
                    "user_id": parseInt(userId),
                    "coin": data.credit ? data.credit : 0,
                    "id": data.id,
                    "amount": data.sg_price,
                    "description": data.description,
                    "method": "stripe",
                    "currency": "sgd",
                    "s_email": s_email,
                    "service_name": data.name,
                    "customer": stripeCusId,
                }
                line_item_details.push(boostOtpDetailsObj)
            })

         const input = {
          "type": 'coin_bundle',
          "user_id": parseInt(userId),
          "coin": total_coin,
          "amount": otpTotal,
          "method": "stripe",
          "currency": "sgd",
          "s_email": s_email,
          "payment_mode": "payment",
          "return_url": "fk-prime",
          "customer": stripeCusId, 
          "line_items": line_items,
          "line_items_detail": line_item_details
        }
        return input
    }

    return (
            <Button
                styleClass={`flex flex-justify-center flex-align-center fk-prime-purchase-pay-button-container pointer`}
                text="Checkout"
                textClass="fk-prime-purchase-pay-button-p"
                clickFunc={handleCheckout}
            />
    )
    

}

export default StripeButton;