import gql from 'graphql-tag'
import client from '../apollo/apollo-client'

export const getSuggestSearch = (type, text, limit, order) => {
    if(type === 'user'){
        try{
            const query = gql`query publicUserSearch($first: Int, $page: Int, $search: String!,$b_active: Boolean!, $orderField: String!, $orderType: String!){
                publicUserSearch(
                  first: $first,
                  page: $page,
                  search: $search,
                  b_active: $b_active,
                  orderField: $orderField,
                  orderType: $orderType,
                ){
                    count 
                    data{
                        pk_i_id
                        b_active
                        s_name
                        s_username
                        social {
                            data
                        }
                        oc_t_user_resource{
                            pk_i_id
                            fk_i_user_id
                            s_extension
                            s_name
                            s_content_type
                            s_path
                            }
                        }
                  }
        }`;

        const response = client.query({
            query: query,
            variables: {first: limit, page: 1, search: text, b_active: true, orderField: order, orderType:"ASC"}
        });
        const data = response ? response : null;
        return data

        }catch(error){
            console.log("Error: ", error)
            throw error
        }
        // eslint-disable-next-line  
    }else {
        try{
            const query = gql`query publicItemSearch($b_active: Boolean, $first: Int!, $page: Int!, $search: String!, $orderField: String!, $orderType: String!, $price_from: Int, $price_to: Int){
                publicItemSearch(
                            b_active: $b_active,
                            first: $first,
                            page: $page,
                            search: $search,
                            orderField: $orderField,
                            orderType: $orderType,
                            i_price: {
                                price_from: $price_from,
                                price_to: $price_to
                            }
                            
                            ){
                                count
                                data {
                            pk_i_id
                            b_active
                            dt_pub_date
                            oc_t_item_description{
                                s_title
                                s_description
                            }
                            oc_t_category{
                                pk_i_id
                                oc_t_category_descriptions{
                                    s_name
                                }
                            }
                        }
                        }                
                    }`;
                    const response = client.query({
                        query: query,
                        variables: {first: limit, page: 1, b_active: true, search: text, orderField: order, orderType: 'ASC', price_from: 0, price_to: 1000000}
                    });

                    const data = response ? response : null
                    return data
        }catch (error){
            console.log('gql error', JSON.stringify(error))
            throw error

        }
        // eslint-disable-next-line  
    }
}

export default{
    getSuggestSearch
} 