import React, { useContext, useReducer, useEffect } from 'react';
import { AdministratorContext } from '../../../../../../common/context';
import Loader from '../../../../../../common/component/loader'

export const AdminList = () => {
    const value = useContext(AdministratorContext);
    const { administratorData, fetchUserProfile,
        adminDeleteAdministratorIsLoading, adminDeleteAdministratorError, adminDeleteAdministratorMessage, deleteAdministrator
    } = value

    const handleDelete = (user) => {
        const alert = window.confirm(`Are you sure you want to delete ${user.s_name}`);
         if(alert === true){ 
            deleteAdministrator(user.pk_i_id)
        }
    }

    return (
        <div className='main-container'>
            <div></div>
                {administratorData === 0 ? <p style={{textAlign: 'center'}}>No user found</p> : 
                    adminDeleteAdministratorIsLoading ?  <div className="message-container"><Loader /></div> : 
                        adminDeleteAdministratorError ? <div className="message-container"><p className='error-message'>{adminDeleteAdministratorError}</p></div> :  
                    <div className='purchase-container'>
                        <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Name</th><th>Username</th><th>Email</th><th>Moderator</th><th colSpan={2} style={{textAlign: 'center'}}>Action</th>
                            </tr>
                            {administratorData.map((user, index) => {
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td>{user.s_name}</td>
                                        <td>{user.s_username}</td>
                                        <td>{user.s_email}</td>
                                        <td className={user.b_moderator ? 'success-message' : 'error-message'}>{user.b_moderator ? 'Yes' : 'No'}</td>
                                        <td style={{textAlign: 'center'}}><button className={'action-button'} onClick={()=> fetchUserProfile(user.pk_i_id)}><i className="fas fa-edit"/></button></td>
                                        <td style={{textAlign: 'center'}}><button className={'action-button'} onClick={() => handleDelete(user)}><i className="fas fa-trash-alt"/></button></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const AdministratorModal = () => {
    const value = useContext(AdministratorContext);

    const { isShow, administratorProfileIsLoading, administratorProfileData, administratorProfileError, closeUserModal, upsertUser,
        adminUpsertAdministratorIsLoading, adminUpsertAdministratorError, adminUpsertAdministratorMessage
    } = value

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          pk_i_id: !administratorProfileData.pk_i_id ? null : administratorProfileData.pk_i_id,
          s_name: !administratorProfileData.s_name ? '' : administratorProfileData.s_name,
          s_username: !administratorProfileData.s_username ? '' : administratorProfileData.s_username,
          s_email: !administratorProfileData.s_email ? '' : administratorProfileData.s_email,
          b_moderator: administratorProfileData && !administratorProfileData.b_moderator ? false : administratorProfileData.b_moderator,
          s_password: '',
          s_password_confirmation: '',
        }
    );

    useEffect(() => {
            setUserInput({
                pk_i_id: !administratorProfileData.pk_i_id ? null : administratorProfileData.pk_i_id,
                s_name: !administratorProfileData.s_name ? '' : administratorProfileData.s_name,
                s_username: !administratorProfileData.s_username ? '' : administratorProfileData.s_username,
                s_email: !administratorProfileData.s_email ? '' : administratorProfileData.s_email,
                b_moderator: administratorProfileData && !administratorProfileData.b_moderator ? false : administratorProfileData.b_moderator
            });
    }, [administratorProfileData])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;

        setUserInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        if (object === 'Not authorized.') {
            errorArray.push(<p key={key} className='error-message'>{object}</p>)
        } else {
            for (var key in object) {
                if (object.hasOwnProperty(key)) {
                    errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
                }
            } 
        }
        return errorArray
    }

    const renderStatusOptions = () => {
        const b_moderator = administratorProfileData && administratorProfileData.b_moderator ? administratorProfileData.b_moderator : false
        const statusOptions = b_moderator ?
            <React.Fragment> 
                <option value={'true'}>Yes</option>
                <option value={'false'}>No</option>
            </React.Fragment> :
            <React.Fragment>
                <option value={'false'}>No</option>
                <option value={'true'}>Yes</option>
            </React.Fragment>
        return statusOptions
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close' onClick={() => closeUserModal()}>&times;</span>
                {administratorProfileIsLoading || adminUpsertAdministratorIsLoading ?  <div className="message-container"><p>Loading...</p></div> : 
                    administratorProfileError ? <div className="message-container"><p className='error-message'>{administratorProfileError}</p></div> :  
                        adminUpsertAdministratorMessage ? <div className="message-container"><p className='success-message'>{adminUpsertAdministratorMessage}</p></div> :  
                        <div className="container">
                            <form onSubmit={(event) => upsertUser(event, userInput)}>
                            <div style={{marginBottom: 10, fontSize: 16}}>
                                <div><label><b>Moderator</b></label></div>
                                    <select id="b_moderator" name='b_moderator' value={userInput.b_moderator} onChange={handleChange}>
                                        {renderStatusOptions()}
                                    </select>
                            </div>
                            <label ><b>Name</b></label>
                            <input type="text" onChange={handleChange} name={'s_name'} value={userInput.s_name} pattern="^[a-zA-Z ]*$" required/>
                            <label ><b>Username</b></label>
                            <input type="text" onChange={handleChange} name={'s_username'} value={userInput.s_username} required/>
                            <label ><b>Email</b></label>
                            <input type="email" onChange={handleChange} name={'s_email'} value={userInput.s_email} disabled={userInput.pk_i_id ? true : false} required/>
                            { userInput.pk_i_id ? null :
                                <React.Fragment>
                                    <label ><b>Password</b></label>
                                    <input type="password" name="s_password" value={userInput.s_password} onChange={handleChange} required />
                                    <label ><b>Confirm Password</b></label>
                                    <input minLength="8" type="password" name="s_password_confirmation" value={userInput.s_password_confirmation} onChange={handleChange} required />
                                </React.Fragment>
                            }

                            { adminUpsertAdministratorError ? <div>{parseError(adminUpsertAdministratorError)}</div>  : null }

                            {userInput.pk_i_id ? <input type="submit" value="Update user" className="sellButton" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>
                                               : <input type="submit" value="Add user" className="sellButton" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>

                            }
                            </form>
                        </div>
                }    
                </div>
        </div>
    )
}
