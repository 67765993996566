import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FKImage from '../../../../../../common/component/image'
import DefaultPhoto from '../../../../../../assets/images/defaultAvatar.png'
import { ChatContext } from '../../../../../../common/context'
import { getSocialUrl, getSocialID, slugify, categoryParser } from '../../../../../../common/helper/utility'
import { ReviewModal, ChatDeleteModal, RequestReviewAlert } from './CustomComponent'
import { theme } from '../../../../../../common/theme'
import { StarRatingPlain } from '../../../../../../common/component/rating'
import SocialAvatar from '../../../../../../common/component/socialAvatar';
import {TrashIconBtn} from '../../../../../../assets/svg'

const WebChatHeader = () => {
    const value = useContext(ChatContext)
    const {chatRoomData, session, sendChat, sendIsLoading, sendMessage, resetSendChat, sendError, sendReview, resetSendReview, sendReviewIsLoading, sendReviewMessage, sendReviewError,
        showDelete, toggleShowDelete, showDeleteAlert, setShowDeleteAlert, toggleDeleteAlert, deleteChat,
        showRequestReviewAlert, toggleShowRequestReviewAlert, showRequestReviewModal, toggleShowRequestReviewModal, requestReviewAlertProceed, showReviewModal, setShowReviewModal, toggleShowReviewModal
    } = value
    const userId = session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id : null
    const chatOwnerId = chatRoomData && chatRoomData.owner_id ? chatRoomData.owner_id : null
    const chatGuestId = chatRoomData && chatRoomData.guest_id ? chatRoomData.guest_id : null
    const itemOwnerId = chatRoomData && chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.pk_i_id ? chatRoomData.item.oc_t_user.pk_i_id : null
    const loginIsOwner = parseInt(userId) === parseInt(chatOwnerId) ? true : false
    const loginIsGuest = parseInt(userId) === parseInt(chatGuestId) ? true : false
    const loginIsItemOwner = parseInt(userId) === parseInt(itemOwnerId) ? true : false
    const buyerUsername = chatRoomData.sender && chatRoomData.sender.s_username ? chatRoomData.sender.s_username : ''
    const sellerUsername = chatRoomData.user && chatRoomData.user.s_username ? chatRoomData.user.s_username : ''
    const headerName = loginIsOwner ? buyerUsername :  sellerUsername
    
    const itemOwnerUserResource = chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.oc_t_user_resource ? chatRoomData.item.oc_t_user.oc_t_user_resource : null
    const itemSocialData = chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.social ? chatRoomData.item.oc_t_user.social : []
    const itemOwnerSocialID = getSocialID(itemSocialData)
    const itemOwnerSocialResource = getSocialUrl(itemSocialData)

    const senderOwnerUserResource = chatRoomData.sender && chatRoomData.sender.oc_t_user_resource && chatRoomData.sender.oc_t_user_resource.pk_i_id ? chatRoomData.sender.oc_t_user_resource : null
    const senderOwnerSocialData = chatRoomData.sender && chatRoomData.sender.social ? chatRoomData.sender.social : []
    const senderOwnerSocialId = getSocialID(senderOwnerSocialData)
    const senderOwnerSocialResource = getSocialUrl(senderOwnerSocialData)

    const itemImgResource = chatRoomData.item && chatRoomData.item.oc_t_item_resource && chatRoomData.item.oc_t_item_resource[0] ? chatRoomData.item.oc_t_item_resource[0] : []
    const itemTitle = chatRoomData.item && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description.s_title ? chatRoomData.item.oc_t_item_description.s_title : ''
    const itemPrice = chatRoomData.item && chatRoomData.item.get_i_price ? chatRoomData.item.get_i_price : ''
    const itemSold = chatRoomData.item && chatRoomData.item.b_mark_as_sold ? chatRoomData.item.b_mark_as_sold : chatRoomData.item && chatRoomData.item.dt_sold ? true : null

    const chatItemId = chatRoomData.item && chatRoomData.item.pk_i_id ? chatRoomData.item.pk_i_id : ''
    const chatRoomId = chatRoomData.id ? chatRoomData.id : ''
    const offer_amount = chatRoomData.offer_amount ? chatRoomData.offer_amount : 0
    // const chat_messages = chatRoomData.chat_messages ? chatRoomData.chat_messages.reverse() : []

    const ownerTotalRate = chatRoomData.user && chatRoomData.user.review ? chatRoomData.user.review : []
    const senderTotalRate = chatRoomData.sender && chatRoomData.sender.review ? chatRoomData.sender.review : []

    const [showOfferModal, setShowOfferModal] = useState(false)
    const [useEditOffer, setUserEditOffer] = useState(false)
    const [madeOfferId, setMadeOfferId] = useState()

    const parseRate =(arr) => {
        let total = 0
        arr.length > 0 && arr.map(item => 
            total += item.total_rate
        )
        return total === 0 ? 0 : total / arr.length
    }
    const ownerAverageRate = parseRate(ownerTotalRate)
    const senderAverageRate = parseRate(senderTotalRate)
    const ratingAverage = loginIsOwner ? senderAverageRate.toFixed(1) : ownerAverageRate.toFixed(1)

    // const handleEditOffer = () => {
    //     setUserEditOffer(true)
    //     getMadeOfferId()
    //     toggleModal(!showOfferModal)
    // }

    const toggleModal = () => {
        if (showOfferModal) {
            setUserEditOffer(false)
            setMadeOfferId(null)
        }
        setShowOfferModal(!showOfferModal)
    }

    const sellerRatingOptionsSwitch = () => {
        let element

        switch (chatRoomData.seller_rating_status) {
            case null:
            case undefined:
            case '':
                element = 
                <div key={1} className="flex flex-d-column flex-self-end">
                    <div style={{alignSelf: 'flex-end'}}>
                        <button key={1} onClick={() => toggleShowRequestReviewAlert()}><p className="rating-button offer-button-text">{theme.text.sellerRatingStatus.sellerRequestReview.displayValue}</p></button>
                    </div>
                    <p className='rating-text-description'>{theme.text.sellerRatingStatus.sellerRequestReview.description}</p>
                </div>
            break;
            case 'seller_request_review':
                const linkExpired = chatRoomData && chatRoomData.seller_rating_expiration ? moment(new Date()).isAfter(chatRoomData.seller_rating_expiration) : false
                element = 
                <div key={1} className="flex flex-d-column flex-self-end">
                    <button style={{pointerEvents: linkExpired ? 'visible' : 'none'}} key={1} onClick={() => linkExpired ? toggleShowRequestReviewAlert() : null} disabled={linkExpired ? false : true}><p className={linkExpired ? "rating-button offer-button-text" : "rating-button-disable offer-button-text"}>{theme.text.sellerRatingStatus.sellerRequestReview.displayValue}</p></button>
                    <p className='rating-text-description'>{theme.text.sellerRatingStatus.sellerRequestReview.description}</p>                    
                </div>
            break;

            case 'seller_review_submitted_by_buyer':
                element = 
                <div key={1} className="flex flex-d-column flex-self-end">
                    <Link to='/user/reviews'>
                        <div style={{alignSelf: 'flex-end'}}>
                            <button key={1}><p className="rating-button offer-button-text">{`View Review`}</p></button>
                        </div>
                    </Link>
                </div>
            break;

            
            default:
            break;
        }
        return element
    }

    const buyerRatingOptionsSwitch = () => {
        let element
        if (chatRoomId == '') {
            element = ''
        } else {
            switch (chatRoomData.buyer_rating_status) {
                case null:
                case undefined:
                case '':
                    element = 
                    <div key={1} className="flex flex-d-column flex-self-end">
                        <div style={{alignSelf: 'flex-end'}}>
                            <button key={1} onClick={() => toggleShowRequestReviewAlert()}><p className="rating-button offer-button-text">{theme.text.buyerRatingStatus.buyerRequestReview.displayValue}</p></button>
                        </div>
                        <p className='rating-text-description'>{theme.text.buyerRatingStatus.buyerRequestReview.description}</p>
                    </div>
                break;

                case 'buyer_request_review':
                    const linkExpired = chatRoomData && chatRoomData.buyer_rating_expiration ? moment(new Date()).isAfter(chatRoomData.buyer_rating_expiration) : false
                    element = 
                    <div key={1} className="flex flex-d-column flex-self-end">
                        <button style={{pointerEvents: linkExpired ? 'visible' : 'none'}} key={1} onClick={() => linkExpired ? toggleShowRequestReviewAlert() : null} disabled={linkExpired ? false : true}><p className={linkExpired ? "rating-button offer-button-text" : "rating-button-disable offer-button-text"}>{theme.text.buyerRatingStatus.buyerRequestReview.displayValue}</p></button>
                        <p className='rating-text-description'>{theme.text.buyerRatingStatus.buyerRequestReview.description}</p>
                    </div>
                break; 

                case 'buyer_review_submitted_by_seller':
                    element = 
                    <div key={1} className="flex flex-d-column flex-self-end">
                        <Link to='/user/reviews'>
                            <div style={{alignSelf: 'flex-end'}}>
                            <button key={1}><p className="rating-button offer-button-text">{`View Review`}</p></button>
                            </div>
                        </Link>
                    </div>
                break;

                default:
                break;
            }
        }
        return element
    }

    const ratingOption = loginIsOwner ? sellerRatingOptionsSwitch() : buyerRatingOptionsSwitch()

    const ratingElement = () => {
        return ratingAverage > 0 ? <StarRatingPlain totalStar={5} average= {ratingAverage} size={12}/> : null
    }

    const avatarSwitch = () => {
        let element = []
        if (loginIsOwner) {
            if (senderOwnerUserResource) {
                element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="chat-header-seller_avatar"/>)
            } else if(senderOwnerSocialId){
                element.push(<SocialAvatar key={1} id={senderOwnerSocialId} url={senderOwnerSocialResource}/>)
            } else{
                element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 48, maxHeight: 48}} alt='profile-default-avatar'/>)
            }
        } else if(loginIsGuest){
            if (itemOwnerUserResource) {
                element.push(<FKImage key={1} images={[itemOwnerUserResource]} type="chat-header-seller_avatar" />)
            } else if(itemOwnerSocialID) {
                element.push(<SocialAvatar id={itemOwnerSocialID} url={itemOwnerSocialResource} />)
            } else{
                element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 48, maxHeight: 48}} alt='profile-default-avatar'/>)
            }
        }
        else if(loginIsItemOwner){
            if (senderOwnerUserResource) {
                element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="chat-header-seller_avatar" />)
            } else if(senderOwnerSocialId) {
                element.push(<SocialAvatar id={senderOwnerSocialId} url={senderOwnerSocialResource}/>)
            } else{
                element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 48, maxHeight: 48}} alt='profile-default-avatar'/>)
            }
        }
        return element
    }

    const itemStatusSwitch = (itemSold) => {
        let el
        if (itemSold) {
            el = <div className="item-status-cont sold-cont"><p className="item-status-text sold-text">Sold</p></div>
        }
        else {
            el = null
        }
        return el
    }

    const resetSendChatCallback = useCallback(() => {
        resetSendChat()
    }, [resetSendChat])

    useEffect(() => {
        if (sendMessage) {
            setTimeout(() => {
                resetSendChatCallback()
            }, 2000)
        }
        if (sendError) {
            setTimeout(() => {
                resetSendChatCallback()
            }, 2000)
        }
    }, [sendMessage, sendError, resetSendChatCallback])

    const toggleReviewModal = () => {
        setShowReviewModal(!showReviewModal)
    }

    const offerModalDetail = {
        headerName: headerName, 
        itemPrice: itemPrice, 
        avatarSwitch: avatarSwitch, 
        showOfferModal: showOfferModal, 
        toggleModal: toggleModal,
        chatOwnerId: chatOwnerId,
        userId: userId,
        chatItemId: chatItemId,
        chatRoomId: chatRoomId,
        sendChat: sendChat,
        offer_amount: offer_amount,
        sendIsLoading: sendIsLoading,
        sendMessage: sendMessage, 
        resetSendChat: resetSendChat,
        sendError: sendError,
        useEditOffer: useEditOffer,
        madeOfferId: madeOfferId,
    }

    const reviewModalDetail = {
        showReviewModal: showReviewModal,
        toggleReviewModal: toggleReviewModal,
        loginIsOwner: loginIsOwner,
        userId: userId,
        chatOwnerId: chatOwnerId,
        chatGuestId: chatGuestId,
        chatItemId: chatItemId,
        sendReview: sendReview, 
        resetSendReview: resetSendReview,
        sendReviewIsLoading: sendReviewIsLoading, 
        sendReviewMessage: sendReviewMessage, 
        sendReviewError: sendReviewError,
        chatRoomId: chatRoomId,
        buyerUsername: buyerUsername,
        sellerUsername: sellerUsername
    }

    const category = chatRoomData.item && chatRoomData.item.oc_t_category && chatRoomData.item.oc_t_category.oc_t_category_descriptions && chatRoomData.item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(chatRoomData.item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
    const itemSLug = slugify(itemTitle)
    const item_description = chatRoomData.item && chatRoomData.item.oc_t_item_description && chatRoomData.item.oc_t_item_description.s_description ? chatRoomData.item.oc_t_item_description.s_description.substring(0, 100) : ''
    const cover_photo = chatRoomData.item && chatRoomData.item.oc_t_item_resource && chatRoomData.item.oc_t_item_resource[0] && chatRoomData.item.oc_t_item_resource[0].s_extension ? `${chatRoomData.item.oc_t_item_resource[0].pk_i_id}.${chatRoomData.item.oc_t_item_resource[0].s_extension}` : null
    const finalSLug = `/${category}/${itemSLug}_i${chatItemId}?title=${itemTitle}&cover_photo=${cover_photo}&description=${item_description}`

    return (     
        <>
            <div className="chat-header clearfix">
                <div className='chat-header-one'> 
                    <div style={{width: 48, height: 48, borderRadius: '50%', overflow: 'hidden'}}>
                    <Link to={loginIsItemOwner ? `/user/${chatGuestId}/listing` : `/user/${itemOwnerId}/listing`}>  { avatarSwitch() }</Link>
                    </div>
                    <div className="chat-header-one-details-cont">
                        <div style={{flex: 1, padding: '0 12px'}}>
                        <Link to={loginIsItemOwner ? `/user/${chatGuestId}/listing` : `/user/${itemOwnerId}/listing`}> <p className="chat-header-name">{headerName}</p> </Link>
                            <div className="chat-header-rating">{ratingElement()}
                                {ratingAverage > 0 ? <span className="rating-count">{`(${ratingAverage})`}</span> : null }
                            </div>
                        </div>
                        <div className="chat-header-ellipsis">
                            <i className="fas fa-ellipsis-v" onClick={() => toggleShowDelete()}/>
                            <div style={{position: 'relative'}}>
                            {showDelete ? 
                                <div className="delete-chat-button-container">
                                    <div className="flex flex-d-row flex-align-center" onClick={() => toggleDeleteAlert()}>
                                        <TrashIconBtn />
                                        <p className="delete-listing-p pointer">{`Delete Message`}</p>
                                    </div>
                                </div> : null
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chat-header-two'>
                    <div className='item-image-container-sm'>
                        <Link to={finalSLug} > 
                            <FKImage images={[itemImgResource]} type="item-message-inbox" />
                        </Link>
                        {itemStatusSwitch(itemSold)}
                    </div>
                    <div className="chat-header-details-container">
                        <Link to={finalSLug} className="chat-header-item-details"> 
                            <p className="chat-header-name">{itemTitle}</p>
                            <p className="chat-header-item-price">{`$${itemPrice}`}</p>
                        </Link>
                    </div>
                </div>
                <div className="chat-header-offer-status">
                    {ratingOption}
                </div>
            </div>
            {showReviewModal ? <ReviewModal detail={reviewModalDetail}/> : null}
            {showDeleteAlert ? <ChatDeleteModal sharedProps={{showDeleteAlert, setShowDeleteAlert, chatRoomData, userId, deleteChat}}/> : null}
            {showRequestReviewAlert ? <RequestReviewAlert sharedProps={{showRequestReviewAlert, toggleShowRequestReviewAlert, requestReviewAlertProceed, chatRoomData, session}}/> : null}
        </>
    )
}

export default WebChatHeader