import React, {useEffect} from 'react'
import RECAPTCHA from 'react-google-recaptcha'
import Category from '../../../../../../../common/component/category'
import {theme} from '../../../../../../../common/theme'
import { isEmpty } from '../../../../../../../common/helper/utility'
import {YoutubeThumbnail, PostTypeFields} from '../../../customComponents'
import { LocationIcon, CloseIconWithGrayBG, TrashIconRedBG, MagnifyingGlassIcon} from '../../../../../../../assets/svg'
import { CustomFieldList } from '../../../component/CustomComponent'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const { REACT_APP_ENV, REACT_APP_GOOGLE_API_KEY} = process.env;

const FormContainer = ({setModalPops, userUpdateInput, setUserUpdateInput, data, handleChange, onKeyDownInput, removeAddress, convertYoutubeLink, captchaChange, handleUpdate, isTyping, setIsTyping, handleChangeMap, saveLocationFunc, locationSuggest, handleCustomFieldChange, has_active_subscription}) => {
    const {itemNew} = theme.text
    const category_id = data && data.oc_t_category && data.oc_t_category.oc_t_category_descriptions && data.oc_t_category.oc_t_category_descriptions.fk_i_category_id ? data.oc_t_category.oc_t_category_descriptions.fk_i_category_id : 0
    const item_price = data && data.get_i_price ? data.get_i_price : 0
    const item_condition = data && data.s_item_condition ? data.s_item_condition : null
    const item_id = data && data.pk_i_id ? data.pk_i_id : 0
    const item_title = data && data.oc_t_item_description && data.oc_t_item_description.s_title ? data.oc_t_item_description.s_title : ''
    const item_description = data && data.oc_t_item_description && data.oc_t_item_description.s_description ? data.oc_t_item_description.s_description : ''
    const item_meetup_1 = data && data.s_dm_meetup_add1 ? data.s_dm_meetup_add1 : ''
    const item_meetup_notes = data && data.s_dm_meetup_add1_notes ? data.s_dm_meetup_add1_notes : ''
    const item_meetup_2 = data && data.s_dm_meetup_add2 ? data.s_dm_meetup_add2 : ''
    const item_meetup_notes_2 = data && data.s_dm_meetup_add2_notes ? data.s_dm_meetup_add2_notes : ''
    const item_meetup = item_meetup_1 ? true : false
    const item_shipped_details = data && data.s_dm_shipped_details ? data.s_dm_shipped_details : ''
    const item_shipped = item_shipped_details ? true : false
    const item_yt_embed = data && data.youtube_embed_link ? data.youtube_embed_link : ''
    const item_youtube_link = data && data.s_youtube_link ? data.s_youtube_link : ''
    const item_custom_fields = data && data.custom_fields ? data.custom_fields : {}
    const category_custom_fields = data && data.oc_t_category && data.oc_t_category.oc_t_custom_field ? data.oc_t_category.oc_t_custom_field : []
    const item_YT_link = item_yt_embed ? item_yt_embed : convertYoutubeLink(item_youtube_link)
    const post_type = data && data.post_type ? data.post_type : ''

    const customFieldParser = () => {
        const customFieldParse = category_custom_fields ? category_custom_fields : []

        let cfObject = {}
        if (customFieldParse.length > 0) {
          customFieldParse.map((item) => {
            const parsedCustomField = isEmpty(item_custom_fields) ? {} : JSON.parse(item_custom_fields)
            const value = parsedCustomField && parsedCustomField[item.name] ? parsedCustomField[item.name] : ''
            cfObject[item.name] = {...item, value: value}
          })
        }
        return cfObject
    }

    const postTypeArr = [
        {label: 'WTS', value: 'WTS', sublabel: 'Want to Sell'},
        {label: 'WTB', value: 'WTB', sublabel: 'Want to Buy'},
        {label: 'WTT', value: 'WTT', sublabel: 'Want to Trade'}
      ]

    const handleMeetupClick = () => {
        if(userUpdateInput.deal_method_meetup){
            setUserUpdateInput({['deal_method_meetup']: '',['s_dm_meetup_add1']: '', ['s_dm_meetup_add1_notes']: '', ['s_dm_meetup_add2']: '', ['s_dm_meetup_add2_notes']: ''})
        }else{
            setUserUpdateInput({['deal_method_meetup']: 'meetup'})
        }
    }

    const handleShippedClick = () => {
        if(userUpdateInput.deal_method_shipped){
            setUserUpdateInput({['deal_method_shipped']: '', ['s_dm_shipped_details']: ''})
        }else{
            setUserUpdateInput({['deal_method_shipped']: 'shipped'})
        }
    }

    useEffect(()=> {
        if(!userUpdateInput.item_id){
            const custom_fields_parser = customFieldParser(category_custom_fields, item_custom_fields)
            setUserUpdateInput({
                    ['fk_i_category_id']: category_id,
                    ['i_price']: item_price,
                    ['item_condition']: item_condition,
                    ['item_id']: item_id,
                    ['s_category']: category_id,
                    ['s_title']: item_title,
                    ['s_description']: item_description,
                    ['deal_method_meetup']: item_meetup,
                    ['deal_method_shipped']: item_shipped,
                    ['s_dm_meetup_add1']: item_meetup_1,
                    ['s_dm_meetup_add1_notes']: item_meetup_notes,
                    ['s_dm_meetup_add2']: item_meetup_2,
                    ['s_dm_meetup_add2_notes']: item_meetup_notes_2,
                    ['s_dm_shipped_details']: item_shipped_details,
                    ['youtube_link']: item_YT_link,
                    ['custom_fields']: custom_fields_parser,
                    ['post_type']: post_type
                })
        }
    },[userUpdateInput])

    return (
        <>
        <div className="item-new-form-container">
            <div className={`flex flex-d-row mb-30 ${window.innerWidth < 600 ? `flex-justify-center` : ``}`}>
                {
                    postTypeArr.map((post, i) => {
                    return (
                        <PostTypeFields radioname="post_type" name={post.label} nameClass="item-new-post-type-label-p" subname={post.sublabel} subnameClass="item-new-post-type-sub-label-p" value={post.value} radioClass="item-condition-radios radio w-custom-radio-ui" key={i} onChangeFunc={handleChange} selectedOptions={userUpdateInput.post_type}/>
                    )
                    })
                }
            </div>
            <Category value={userUpdateInput.s_category} name={'s_category'} onChange={handleChange}/>
            <div className={` ${window.innerWidth < 600 ? `mt-30` : `item-new-formGroup`}`}>
                <label className={`item-new-label ${window.innerWidth < 600 ? `ps-16`: ``}`}>{`Item Condition`}</label>
                <div className="item-condition-container">
                {
                    itemNew.map((choices, index) => {
                    return <div key={index} className={`options-${choices.value}`}>
                        <input type="radio" name={userUpdateInput.post_type === 'WTS' ? `item_condition` : `item_condition_${choices.value}`} className={`item-condition-radios radio custom-radio-ui`} 
                        value={choices.value} onClick={(e) => handleChange(e)} checked={userUpdateInput && userUpdateInput.item_condition && userUpdateInput.item_condition.includes(choices.value)  ? true : null} />
                        <span className="condition-label">{choices.label}</span>
                    </div>
                    })
                }
                </div>
                <div>
                    <div className="item-new-formGroup">
                        <label className="item-new-label">{`Listing title`}</label>
                        <input className="item-new-input" autoComplete="off" placeholder="Enter title" type="text" name={'s_title'} value={userUpdateInput.s_title} onChange={handleChange} required />
                    </div>
                    <CustomFieldList data={userUpdateInput.custom_fields} handleCustomFieldChange={handleCustomFieldChange} />
                    <div className="item-new-formGroup">
                        <label className="item-new-label">{`Price`}</label>
                        <div className={`item-new-price`}>
                            <span className="item-new-currency">SGD</span>
                            <input className="item-new-priceInput" type="number" name="i_price" placeholder="0.00" pattern="/^[0-9\b]+$/" value={userUpdateInput.i_price} onKeyDown={onKeyDownInput} onKeyPress={handleChange} onChange={handleChange} required></input>
                        </div>
                        <p className="tip-p-label">Tip: Putting “<span>0</span>” in the price will show “<span>Check with seller</span>” for buyer.</p>
                    </div>
                    <div className="item-new-formGroup">
                        <label className="item-new-label">{`Description`}</label>
                        <div className={`item-new-textarea-container `} >
                            <div className={`item-textarea-container ${ userUpdateInput.s_description.length < 9 ? `border-red` : userUpdateInput.s_description.length > 9 && userUpdateInput.s_description.length < 15 ? 'border-yellow' : userUpdateInput.s_description.length > 15 ? 'border-green' : 'border-default'}`} >
                            <textarea autoComplete="nope" rows="3" placeholder="Listing item details" name={'s_description'} className="item-textarea" value={userUpdateInput.s_description} onChange={handleChange} required />
                            </div>
                        {
                            userUpdateInput.s_description.length < 9 ?  <p className="item-new-text item-new-text-red">{`Description should at least be 10 characters.`}</p> : 
                            userUpdateInput.s_description.length > 9 && userUpdateInput.s_description.length < 15 ? <p className="item-new-text item-new-text-yellow">{`Describe more to make people find you.`}</p> :
                            userUpdateInput.s_description.length > 15 ? <p className="item-new-text item-new-text-green">{`Good to go!`}</p> : null
                        }
                        </div>
                    </div>
                    <div className="item-new-formGroup">
                        <label className="item-new-label">{`Deal method`}</label>
                        <div className="item-new-deal-main-container">
                            <div className="item-new-meetup-container mb-12" >
                                <input type="radio" name="deal_method_meetup" onClick={() => handleMeetupClick()} checked={userUpdateInput.deal_method_meetup ? true : false} value="meetup" className="item-condition-radios radio custom-radio-ui " onChange={handleChange} />
                                <span className="item-new-meetup-label">{`Meet - up`}</span>
                            </div>
                            {
                            userUpdateInput.deal_method_meetup ?
                            <div className="g-map-container">
                                <div className="meetup-main-container">
                                    {
                                        userUpdateInput.s_dm_meetup_add1 ?
                                        <>
                                        <div className="meetup-address-container">
                                            <div className="meetup-icon-container">
                                                <LocationIcon />
                                            </div>
                                            <div className="meetup-p-container">
                                                <p className="meetup-address-p">{userUpdateInput.s_dm_meetup_add1}</p>
                                            </div>
                                            <div onClick={() => removeAddress('address1')} className="meetup-close-container">
                                                <CloseIconWithGrayBG />
                                            </div>
                                        </div> 
                                        </> : null
                                    }
                                    {
                                        userUpdateInput.s_dm_meetup_add1 ? 
                                        <> 
                                         <div className="flex flex-d-column add-notes-container-with-textarea">
                                            <p className="w-100">{`Notes:`}</p>
                                            <div className="add-notes-textarea-container w-100">
                                            <textarea autoComplete="nope" id={'meetupNotes1'} placeholder="Details make life easier, Tell some more ... " name="s_dm_meetup_add1_notes" maxLength={200} value={userUpdateInput.s_dm_meetup_add1_notes} onChange={handleChange} rows="3"/>
                                            <span className="textarea-counter">{`${userUpdateInput.s_dm_meetup_add1_notes.length}/200`}</span>
                                            </div>
                                            <div className="w-100 flex flex-d-row add-notes-button-group">
                                            {
                                                <div onClick={() => setUserUpdateInput({['s_dm_meetup_add1_notes']: ''})}>
                                                    <TrashIconRedBG />
                                                </div>
                                            }
                                            
                                            </div>
                                        </div>
                                        </> : null
                                    }
                                    {
                                        userUpdateInput.s_dm_meetup_add2 ? 
                                        <> 
                                        <div className="meetup-address-container">
                                            <div className="meetup-icon-container">
                                                <LocationIcon />
                                            </div>
                                            <div className="meetup-p-container">
                                                <p className="meetup-address-p">{userUpdateInput.s_dm_meetup_add2}</p>
                                            </div>
                                            <div onClick={() => removeAddress('address2')} className="meetup-close-container">
                                                <CloseIconWithGrayBG />
                                            </div>
                                        </div> 
                                        </> : null
                                    }
                                    {
                                         userUpdateInput.s_dm_meetup_add2 ? 
                                         <> 
                                          <div className="flex flex-d-column add-notes-container-with-textarea">
                                             <p className="w-100">{`Notes:`}</p>
                                             <div className="add-notes-textarea-container w-100">
                                             <textarea autoComplete="nope" id={'meetupNotes1'} placeholder="Details make life easier, Tell some more ... " name="s_dm_meetup_add2_notes" maxLength={200} value={userUpdateInput.s_dm_meetup_add2_notes} onChange={handleChange} rows="3"/>
                                             <span className="textarea-counter">{`${userUpdateInput.s_dm_meetup_add2_notes.length}/200`}</span>
                                             </div>
                                             <div className="w-100 flex flex-d-row add-notes-button-group">
                                             {
                                                 <div onClick={() => setUserUpdateInput({['s_dm_meetup_add2_notes']: ''})}>
                                                     <TrashIconRedBG />
                                                 </div>
                                             }
                                             
                                             </div>
                                         </div>
                                         </> : null
                                    }
                                    {
                                              userUpdateInput.s_dm_meetup_add1 && !userUpdateInput.s_dm_meetup_add2 ?
                                              <div className="add-notes-container-with-textarea mb-6">
                                                <p className="mb-0">{`Alternative venue:`}</p>
                                              </div> : null
                                            }
                                    {
                                                !userUpdateInput.s_dm_meetup_add1 || !userUpdateInput.s_dm_meetup_add2 ?

                                                <div className="g-map-search-bar-main-container">
                                                  <GooglePlacesAutocomplete
                                                    apiKey={REACT_APP_GOOGLE_API_KEY}
                                                    className={`g-map-search-bar-container w-100 `}
                                                    selectProps={{
                                                      onChange: handleChangeMap,
                                                      className: `g-map-search-input w-100 ${userUpdateInput.s_dm_meetup_add1 ? `ml-36 mx-w-438` : ``}`,
                                                      isClearable: false,
                                                      placeholder: "Search a place or Postal code",
                                                      components: {
                                                        DropdownIndicator: () =>  <div className="g-map-search-icon-container"> <MagnifyingGlassIcon /> </div>,
                                                        IndicatorSeparator:() => null
                                                      }
                                                    }}
                                                    autocompletionRequest={{
                                                      componentRestrictions: {
                                                      country: ['sg'],
                                                      }
                                                    }}
                                                  />
                                                  </div> : null
                                              }

                                </div>
                            </div> : null
                        }
                            <div>
                                <div className={`item-new-shipped-container `} onClick={() => handleShippedClick()}>
                                    <input type="radio" name="deal_method_shipped" checked={userUpdateInput.deal_method_shipped ? true : false} value="shipped" className="item-condition-radios radio custom-radio-ui " onChange={handleChange} />
                                    <span className="tem-new-shipped-label">{`Shipped`}</span>
                                </div>
                                {
                                    userUpdateInput.deal_method_shipped ?
                                    <div className="item-new-ship-container">
                                        <div className="item-new-ship-sub-container">
                                        <textarea autoComplete="nope" name="s_dm_shipped_details" value={userUpdateInput.s_dm_shipped_details} maxLength={200} onChange={handleChange} rows="3" placeholder="Enter shipping details..." />
                                        <div className="flex w-100 flex-justify-end">
                                            <p className="textarea-counter">{`${userUpdateInput.s_dm_shipped_details.length}/200`}</p>
                                        </div>
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        has_active_subscription ? 
                        <>
                            <div className="item-new-formGroup">
                                <label className="item-new-label">{`Youtube link`}&nbsp;<i className="optional-text">{`(optional)`}</i></label>
                                <input type="text" name="youtube_link" value={userUpdateInput.youtube_link} placeholder="https://www.youtube.com" className="item-new-input" onChange={handleChange} />
                            </div>
                            <YoutubeThumbnail loading={false} url={userUpdateInput.youtube_link ? convertYoutubeLink(userUpdateInput.youtube_link) : ''} />
                        </> : null
                    }
                    
                    {<div className={`mt-12 ${window.innerWidth < 600 ? `ps-16` : ``}`}> <RECAPTCHA sitekey={theme.text.sitekey} onChange={captchaChange} /> </div>}
                </div>
            </div>
        </div>
        {
            window.innerWidth < 600 ? null :
            <div className="item-new-buttons-group">
            <div className="cancel-btn" onClick={() => setModalPops(true)}>
                <p>{`Cancel`}</p>
            </div>
            { REACT_APP_ENV === 'local' ?
                <button className={`item-new-btn ${userUpdateInput.error === 'Invalid' && userUpdateInput.s_category && userUpdateInput.i_price && userUpdateInput.s_title && userUpdateInput.s_description.length > 9
                ? `btn-inc` : !userUpdateInput.captcha ? `btn-inc` : `btn-passed`}`} onClick={userUpdateInput.captcha && userUpdateInput.s_category && userUpdateInput.i_price && userUpdateInput.s_title && userUpdateInput.s_description > 9 ? handleUpdate :null} > <p>Update Now</p> </button> :
                <button className={`item-new-btn ${userUpdateInput.error === 'Invalid' && userUpdateInput.captcha && userUpdateInput.s_category && userUpdateInput.i_price && userUpdateInput.s_title && userUpdateInput.s_description.length > 9
                ? `btn-inc` : !userUpdateInput.captcha ? `btn-inc` : `btn-passed`}`} onClick={userUpdateInput.captcha && userUpdateInput.s_category && userUpdateInput.i_price && userUpdateInput.s_title && userUpdateInput.s_description.length > 9  ? handleUpdate : null} > <p>Update Now</p> </button>
            }
            </div>
        }
        
        </>
    )
}

export default FormContainer