import { ITEM_VIEW, ITEM_UPDATE, ITEM_IMAGE_EDIT, ITEM_DELETE, ITEM_HOME_LIKE_UPDATE, ITEM_VIEW_LIKE_UPDATE, ITEM_VIEW_UPDATE_BY_FIELD } from '../constant'

export const itemViewLoad = (payload) => {
    return {
        type: ITEM_VIEW.LOAD,
        payload
    }
}

export const itemViewSuccess = (payload) => {
    return {
        type: ITEM_VIEW.SUCCESS,
        payload
    }
}

export const itemViewFail = (payload) => {
    return {
        type: ITEM_VIEW.FAIL,
        payload
    }
}

export const itemViewReset = () => {
    return {
        type: ITEM_VIEW.RESET,
    }
}

export const itemUpdateLoad = (payload) =>{
    return {
        type: ITEM_UPDATE.LOAD,
        payload
    }
}    

export const itemUpdateSuccess = (payload) =>{
    return {
        type: ITEM_UPDATE.SUCCESS,
        payload,
    }
}

export const itemUpdateFailed = (payload) =>{
    return {
        type: ITEM_UPDATE.FAIL,
        payload,
    }
}

export const itemUpdateReset = () =>{
    return {
        type: ITEM_UPDATE.RESET,
    }
}

export const itemImageEditLoad = (payload) =>{
    return {
        type: ITEM_IMAGE_EDIT.LOAD,
        payload
    }
}    

export const itemImageEditSuccess = (payload) =>{
    return {
        type: ITEM_IMAGE_EDIT.SUCCESS,
        payload,
    }
}

export const itemImageEditFailed = (payload) =>{
    return {
        type: ITEM_IMAGE_EDIT.FAIL,
        payload,
    }
}

export const itemImageEditReset = () =>{
    return {
        type: ITEM_IMAGE_EDIT.RESET,
    }
}

export const itemDeleteLoad = (payload) =>{
    return {
        type: ITEM_DELETE.LOAD,
        payload
    }
}    

export const itemDeleteSuccess = (payload) =>{
    return {
        type: ITEM_DELETE.SUCCESS,
        payload,
    }
}

export const itemDeleteFailed = (payload) =>{
    return {
        type: ITEM_DELETE.FAIL,
        payload,
    }
}

export const itemDeleteReset = () =>{
    return {
        type: ITEM_DELETE.RESET,
    }
}

export const itemSetReserve = (payload) =>{
    return {
        type: ITEM_VIEW.SET_RESERVE,
        payload,
    }
}

export const itemSetSold = (payload) =>{
    return {
        type: ITEM_VIEW.SET_SOLD,
        payload,
    }
} 

export const itemSetHighlight = (payload) =>{
    return {
        type: ITEM_VIEW.SET_HIGHLIGHT,
        payload,
    }
}

export const itemHomeLikeUpdate = (payload) => {
    return {
        type: ITEM_HOME_LIKE_UPDATE.LOAD,
        payload
    }
}

export const itemHomeLikeUpdateSuccess = (payload) => {
    return {
        type: ITEM_HOME_LIKE_UPDATE.SUCCESS,
        payload
    }
}

export const itemHomeLikeUpdateFail = (payload) => {
    return {
        type: ITEM_HOME_LIKE_UPDATE.FAIL,
        payload
    }
}

export const itemViewLikeUpdate = (payload) => {
    return {
        type: ITEM_VIEW_LIKE_UPDATE.LOAD,
        payload
    }
}

export const itemViewLikeUpdateSuccess = (payload) => {
    return {
        type: ITEM_VIEW_LIKE_UPDATE.SUCCESS,
        payload
    }
}

export const itemViewLikeUpdateFail = (payload) => {
    return {
        type: ITEM_VIEW_LIKE_UPDATE.FAIL,
        payload
    }
}

export const itemSetWatcher = (payload) =>{
    return {
        type: ITEM_VIEW.SET_WATCHER,
        payload,
    }
} 

export const itemUpdateByFieldLoad = (payload) =>{
    return {
        type: ITEM_VIEW_UPDATE_BY_FIELD.LOAD,
        payload
    }
}    

export const itemUpdateByFieldSuccess = (payload) =>{
    return {
        type: ITEM_VIEW_UPDATE_BY_FIELD.SUCCESS,
        payload,
    }
}

export const itemUpdateByFieldFailed = (payload) =>{
    return {
        type: ITEM_VIEW_UPDATE_BY_FIELD.FAIL,
        payload,
    }
}

export const itemUpdateRealtimeUpdate = (payload) =>{
    return {
        type: ITEM_VIEW.REALTIME_FIELD_UPDATE,
        payload,
    }
}

