import React, { useContext, useState} from 'react'
import { useHistory} from 'react-router-dom'
import { PromoteContext } from '../../../../../../common/context';
import { TosModal } from './customComponent' 
import { theme } from '../../../../../../common/theme'
import { Verifying, Success} from '../../../../../../common/message'
import {CoinsIcon} from '../../../../../../assets/svg'

const Second = () => {
    const [tosModal, setTosModal] = useState(false)
    const value = useContext(PromoteContext);
    const { paymentData, isLoadingPromote, promoteSuccessMessage, errorPromote, submitPromote, handleBack, currentCoin } = value
    let history = useHistory();

    const getCoins = () => {
        history.push({
            pathname: '/user/wallet',
            state: { redirect: 'promote' }
        })
    }

    const handleModalClick = () => {
        if(tosModal){
            setTosModal(false)
        }
        else{
            setTosModal(true)
        }
    }

    return (
        <>
        <div className="modalPromote-second-modalContainer">
        { 
            isLoadingPromote ? null  :
                errorPromote ? null :
                    promoteSuccessMessage ? null :
                    <React.Fragment>
                            <p className='promote-title'>{`Purchase`}</p>
                            <div className="promote-second-part-info-container">
                                <div className='flex'>
                                    <div className='flex-one'><p className="ind-ads-option-boost-title-p flex flex-d-row flex-align-center">{`${paymentData.name} `}&nbsp; <CoinsIcon />&nbsp;{`${paymentData.credit}`}</p></div>
                                    <div className='flex-one-end flex-align-center' onClick={() => handleBack()}><p className='promote-orange-text cursor-pointer'>{`Change`}</p></div>
                                </div>
                                <div className='flex'>
                                    <div className='flex-one' style={{flexDirection: 'column'}}>
                                        <p className='ind-ads-option-boost-sub-p'>{`${paymentData.description}`}</p>
                                        <p className='promote-service-description' style={{marginTop: 14, fontStyle: 'italic', fontSize: 12}}>{`${theme.text.markAsSoldDisclaimer}`}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modalPromote-second-container pl-16">
                                { currentCoin < paymentData.credit  ?
                                    <React.Fragment>
                                        <div className="promote-second-part-info-container" >
                                            <div className="flex flex-d-row flex-justify-between flex-align-center">
                                                <div className="modalPromote-promote-info">
                                                    <p className="ind-ads-option-boost-title-p">Current coins</p>
                                                    <p className="ind-ads-option-boost-title-p flex flex-d-row flex-align-center"><CoinsIcon />&nbsp;{` ${currentCoin ? currentCoin : 0}`}</p>
                                                </div>
                                                <div>
                                                    <button className="modalPromote-promote-btn" onClick={() => getCoins()}><p>Add</p></button>
                                                </div>
                                            </div>
                                        </div>
                                        <p className={`text-red promote-service-description mt-10 mb-6 ${window.innerWidth > 600 ? `pl-20` : ``}`}>{theme.text.insufficientFunds}</p>
                                    </React.Fragment>
                                    : null
                                }
                                <div className="w-100 flex flex-justify-center flex-align-center">
                                    <button onClick={() => submitPromote(paymentData)} className={currentCoin < paymentData.credit ? "ind-ads-use-now-btn-container modalPromote-coin-btn-disabled" : "ind-ads-use-now-btn-container" } disabled={currentCoin < paymentData.credit ? true : false}>
                                        <p>{`Pay now `}</p>
                                    </button>
                                </div>
                            </div>
                            {/* <div className='flex-one-center' style={{margin: '6px auto'}} onClick={ handleModalClick}>
                                <p className='promote-text promote-text-4' style={{width:'90%'}}>{theme.text.purchaseTOS}<span style={{textDecoration: 'underline', cursor: 'pointer'}}>{`Terms of Use`}</span> </p>
                            </div> */}
                        </React.Fragment>
            }    
        </div>
        {tosModal ? 
            <div style={{width: '100%', height: '100%', position: 'fixed', zIndex: '1', top: '0', left: '0', background: 'rgba(44, 62, 80,0.3)', display: 'flex', justifyContent: 'center', paddingTop: '10px', overflow: 'auto', paddingBottom: '100px'}}>
              <TosModal click={handleModalClick}/>
            </div> : null}
        </>
    );
}

export default Second;