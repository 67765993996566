import React from 'react';
import PropTypes from 'prop-types';
import CategoryBar from '../../common/component/categoryBar'
import GoogleAds from '../../common/component/googleAds'


const LayoutSearch = ( { component: Component, type, session, ...rest } ) => (
    <React.Fragment>
      <CategoryBar session={session}/>
      <div className='layout-search-ads-container'>
        <GoogleAds position='top'/>
      </div>
      <div className="search-main-container">
        <div className="searched-container">
          <Component {...rest} session={session}/>
        </div>
      </div>
    </React.Fragment>
);

LayoutSearch.propTypes = {
    component: PropTypes.any
};

export default LayoutSearch