export const me = `pk_i_id
b_active
s_name
s_username
s_email
s_phone_land
s_phone_mobile
s_country
s_region
s_city
s_website
wallet
sold_items{
    pk_i_id
    dt_pub_date
    status
    i_price
    dt_sold
    watchlist_count
    get_i_price
    oc_t_item_description {
        fk_i_item_id
        s_title
        s_description
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
}
is_email_public
is_phone_public
dt_deactivated_date
is_free_subscription_claimed
stripe_cus_id
items_count
dt_reg_date
oc_t_items {
    pk_i_id
    dt_pub_date
    status
    i_price
    dt_sold
    watchlist_count
    get_i_price
    s_item_condition
    is_new
    post_type
    oc_t_item_description {
        fk_i_item_id
        s_title
        s_description
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
}
cover_photo{
    pk_i_id
    fk_i_user_id
    s_extension
    s_name
    s_content_type
    s_path
}
bio
responsive
social {
    data
}
oc_t_user_resource {
    pk_i_id
    s_name
    s_content_type
    s_extension
}
purchases{
    id
    transaction_id
    total_purchase
    before_credit
    current_credit
    type
    status
    created_at
    detail
    service_name
    oc_t_item {
    oc_t_item_description{
        s_title
    }
    }
    payment_method
    service {
        id
        days
        name
        credit
        discount
        sg_price
    }
  }  
is_free_subscription_claimed
has_active_subscription
has_lifetime_cover_photo
has_lifetime_youtube
realtime_subscription_status
database_subscription_status
purchase_micro_service {
  id
  user_id
  item_id
  name
  type
  payment_method
  purchase_date
  due_date
  cancel_date
  transaction_id
  subscription_status
  service_detail {
    id
    name
    sg_price
    credit
    description
    }
  oc_t_item {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
        fk_i_category_id
        s_name
        }
        oc_t_custom_field {
        name
        label
        placeholder
        fk_i_category_id
        type
        custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
        pk_i_id
        fk_i_user_id
        s_extension
        s_name
        s_content_type
        s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
    }
  }

  live_items {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
            fk_i_category_id
            s_name
        }
        oc_t_custom_field {
            name
            label
            placeholder
            fk_i_category_id
            type
            custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
  }
  active_items {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
            fk_i_category_id
            s_name
        }
        oc_t_custom_field {
            name
            label
            placeholder
            fk_i_category_id
            type
            custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
  }`

export const meSession = `pk_i_id
s_name
s_username
s_email
s_phone_land
s_phone_mobile
s_country
s_region
s_city
s_website
wallet
is_email_public
is_phone_public
dt_deactivated_date
stripe_cus_id
social {
  data
}
oc_t_user_resource {
      pk_i_id
      s_name
      s_content_type
      s_extension
  }
  purchases{
      id
      transaction_id
      total_purchase
      before_credit
      current_credit
      type
      status
      created_at
      detail
      payment_method
  } 
  is_free_subscription_claimed
  has_active_subscription
  has_lifetime_cover_photo
  has_lifetime_youtube 
  uncollected_promo
  purchase_micro_service {
    id
    user_id
    item_id
    name
    type
    payment_method
    purchase_date
    due_date
    transaction_id
    service_detail 
        {
        id
        name
        sg_price
        credit
        description
        }
    }   
`

export const meWithSubscription = `pk_i_id
s_name
s_username
s_email
s_phone_land
s_phone_mobile
s_country
s_region
s_city
s_website
wallet
is_email_public
is_phone_public
dt_deactivated_date
stripe_cus_id
items_count
oc_t_items {
    pk_i_id
    dt_pub_date
    status
    i_price
    dt_sold
    watchlist_count
    get_i_price
    s_item_condition
    is_new
    post_type
    oc_t_item_description {
        fk_i_item_id
        s_title
        s_description
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
}
cover_photo{
    pk_i_id
    fk_i_user_id
    s_extension
    s_name
    s_content_type
    s_path
}
bio
responsive
social {
    data
}
oc_t_user_resource {
    pk_i_id
    s_name
    s_content_type
    s_extension
}
purchases{
    id
    transaction_id
    total_purchase
    before_credit
    current_credit
    type
    status
    created_at
    detail
    service_name
    oc_t_item {
    oc_t_item_description{
        s_title
    }
    }
    payment_method
    service {
        id
        days
        name
        credit
        discount
        sg_price
    }
  }  
is_free_subscription_claimed
has_active_subscription
has_lifetime_cover_photo
has_lifetime_youtube
realtime_subscription_status
purchase_micro_service {
  id
  user_id
  item_id
  name
  type
  payment_method
  purchase_date
  due_date
  transaction_id
  subscription_status
  service_detail {
    id
    name
    sg_price
    credit
    description
    }
  oc_t_item {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
        fk_i_category_id
        s_name
        }
        oc_t_custom_field {
        name
        label
        placeholder
        fk_i_category_id
        type
        custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
        pk_i_id
        fk_i_user_id
        s_extension
        s_name
        s_content_type
        s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
    }
  }

  live_items {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
            fk_i_category_id
            s_name
        }
        oc_t_custom_field {
            name
            label
            placeholder
            fk_i_category_id
            type
            custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
  }

  active_items {
    b_active
    is_new
    pk_i_id
    s_contact_name
    dt_pub_date
    dt_sold
    i_price
    b_reserved
    b_mark_as_sold
    hl_expiration
    ta_expiration
    ab_expiration
    watchlist_count
    pageview_count
    get_i_price
    live
    s_dm_meetup_add1
    s_dm_meetup_add2
    s_dm_meetup_add1_notes
    s_dm_meetup_add2_notes
    s_dm_shipped_details
    youtube_embed_link
    custom_fields
    is_new
    post_type
    oc_t_category {
        pk_i_id
        fk_i_parent_id
        oc_t_category_descriptions{
            fk_i_category_id
            s_name
        }
        oc_t_custom_field {
            name
            label
            placeholder
            fk_i_category_id
            type
            custom_index
        }
    }
    oc_t_item_description {
        s_title
        s_description
    }
    oc_t_user{
        oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
        }
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
    custom_fields
  }`

  export const user = `pk_i_id
  b_active
  s_name
  s_username
  s_email
  s_phone_land
  s_phone_mobile
  s_country
  s_region
  s_city
  s_website
  wallet
  is_free_subscription_claimed
  is_email_public
  is_phone_public
  dt_deactivated_date
  stripe_cus_id
  items_count
  dt_reg_date
  sold_items{
    pk_i_id
    dt_pub_date
    status
    i_price
    dt_sold
    watchlist_count
    get_i_price
    oc_t_item_description {
        fk_i_item_id
        s_title
        s_description
    }
    oc_t_item_resource {
        pk_i_id
        fk_i_item_id
        s_name
        s_extension
        s_content_type
        s_path
    }
}
  oc_t_items {
      pk_i_id
      dt_pub_date
      status
      i_price
      dt_sold
      watchlist_count
      get_i_price
      s_item_condition
      is_new
      post_type
      oc_t_item_description {
          fk_i_item_id
          s_title
          s_description
      }
      oc_t_item_resource {
          pk_i_id
          fk_i_item_id
          s_name
          s_extension
          s_content_type
          s_path
      }
      s_dm_meetup_add1
      s_dm_meetup_add2
      s_dm_meetup_add1_notes
      s_dm_meetup_add2_notes
      s_dm_shipped_details
      youtube_embed_link
      custom_fields
      is_new
  }
  cover_photo{
      pk_i_id
      fk_i_user_id
      s_extension
      s_name
      s_content_type
      s_path
  }
  bio
  responsive
  social {
      data
  }
  oc_t_user_resource {
      pk_i_id
      s_name
      s_content_type
      s_extension
  }

    live_items {
      b_active
      is_new
      pk_i_id
      s_contact_name
      dt_pub_date
      dt_sold
      i_price
      b_reserved
      b_mark_as_sold
      hl_expiration
      ta_expiration
      ab_expiration
      watchlist_count
      pageview_count
      get_i_price
      live
      s_dm_meetup_add1
      s_dm_meetup_add2
      s_dm_meetup_add1_notes
      s_dm_meetup_add2_notes
      s_dm_shipped_details
      youtube_embed_link
      custom_fields
      is_new
      post_type
      oc_t_category {
          pk_i_id
          fk_i_parent_id
          oc_t_category_descriptions{
              fk_i_category_id
              s_name
          }
          oc_t_custom_field {
              name
              label
              placeholder
              fk_i_category_id
              type
              custom_index
          }
      }
      oc_t_item_description {
          s_title
          s_description
      }
      oc_t_user{
          oc_t_user_resource{
              pk_i_id
              fk_i_user_id
              s_extension
              s_name
              s_content_type
              s_path
          }
      }
      oc_t_item_resource {
          pk_i_id
          fk_i_item_id
          s_name
          s_extension
          s_content_type
          s_path
      }
      custom_fields
    }
  
    active_items {
      b_active
      is_new
      pk_i_id
      s_contact_name
      dt_pub_date
      dt_sold
      i_price
      b_reserved
      b_mark_as_sold
      hl_expiration
      ta_expiration
      ab_expiration
      watchlist_count
      pageview_count
      get_i_price
      live
      s_dm_meetup_add1
      s_dm_meetup_add2
      s_dm_meetup_add1_notes
      s_dm_meetup_add2_notes
      s_dm_shipped_details
      youtube_embed_link
      custom_fields
      is_new
      post_type
      oc_t_category {
          pk_i_id
          fk_i_parent_id
          oc_t_category_descriptions{
              fk_i_category_id
              s_name
          }
          oc_t_custom_field {
              name
              label
              placeholder
              fk_i_category_id
              type
              custom_index
          }
      }
      oc_t_item_description {
          s_title
          s_description
      }
      oc_t_user{
          oc_t_user_resource{
              pk_i_id
              fk_i_user_id
              s_extension
              s_name
              s_content_type
              s_path
          }
      }
      oc_t_item_resource {
          pk_i_id
          fk_i_item_id
          s_name
          s_extension
          s_content_type
          s_path
      }
      custom_fields
    }`