import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getCustomField = (payload) => {
    const { first, page } = payload
    try {
        const query = gql`query getDashboardDataCustomField($first: Int, $page: Int, $orderField: String!, $orderType: SortOrder!){
            getDashboardDataCustomField(first: $first, page: $page,
                orderBy: [{field: $orderField, order: $orderType}]
            ){
    	        paginatorInfo {
                    total
                }
                data {
                    id
                    fk_i_category_id
                    name
                    label
                    placeholder
                    type
                    custom_index
                    oc_t_category {
                        pk_i_id
                        oc_t_category_descriptions{
                            fk_i_category_id
                            s_name
                          }
                    }
                }    
            }
        }`;
        const response = client.query({
            query: query,
            variables: { first: first,
            page: page,
            orderField: "custom_index",
            orderType: "ASC"
          }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }
  
export const upsertCustomField = (payload) => {
    const { fk_i_category_id, custom_index, id, label, name, placeholder, type, queryType } = payload
    let gql_query
    let variables
    if (queryType === 'UPDATE') {
        variables = {
            data: 
            { 
              id: id,
              fk_i_category_id: parseInt(fk_i_category_id),
              custom_index: parseInt(custom_index),
              label: label,
              name: name,
              placeholder: placeholder,
              type: type
            }
        }
        gql_query = gql`mutation customFieldUpdate($data: UpdateCustomFieldInput){
            customFieldUpdate(data: $data){
            message
            custom_field{
                id
            }
            }
        }`
    } else {
        variables = {
            data: 
            { 
              fk_i_category_id: parseInt(fk_i_category_id),
              custom_index: parseInt(custom_index),
              label: label,
              name: name,
              placeholder: placeholder,
              type: type
            }
        }

        gql_query = gql`mutation customFieldInsert($data: InsertCustomFieldInput){
            customFieldInsert(data: $data){
            message
            custom_field{
                id
            }
            }
        }`;
    }
    
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
}

export const deleteCustomField = (payload) => {
    const { id  } = payload
  
    const  gql_query = gql`mutation customFieldDelete($data: CustomFieldDeleteInput){
        customFieldDelete(data: $data){
        message
      }
    }`
   
    const variables = {
      data: {
        id: id,
      }
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  export default {
    getCustomField,
    upsertCustomField,
    deleteCustomField,
  }