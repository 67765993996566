import React, { useContext } from 'react';
import { BanTextContext } from '../../../../../../../common/context';
import Loader from '../../../../../../../common/component/loader'

export const BanEmailList = () => {
    const value = useContext(BanTextContext);
    const { adminBanEmailData, adminBanEmailIsLoading, adminBanEmailError, handleEdit, handleDelete } = value

    const handleDeleteAlert = (item) => {
        const alert = window.confirm(`Are you sure you want to delete ${item.s_email}`);
         if(alert === true){ 
            handleDelete(item.pk_i_id)
        }
    }

    return (
        <div className='main-container'>
            <div></div>
                {
                    adminBanEmailData === 0 ? <p style={{textAlign: 'center'}}>No item found</p> : 
                    adminBanEmailIsLoading ?  <div className="message-container"><Loader /></div> : 
                    adminBanEmailError ? <div className="message-container"><p className='error-message'>{adminBanEmailError}</p></div> :  
                    <div className='purchase-container'>
                        <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Email</th><th colSpan={2} style={{textAlign: 'center'}}>Action</th>
                            </tr>
                            {adminBanEmailData.map((item, index) => {
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td>{item.s_email}</td>
                                        <td style={{textAlign: 'center'}} onClick={()=> handleEdit(item)}><button className={'action-button'} ><i className="fas fa-edit"/></button></td>
                                        <td style={{textAlign: 'center'}} onClick={() => handleDeleteAlert(item)}><button className={'action-button'} ><i className="fas fa-trash-alt"/></button></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const BanEmailModal = () => {
    const value = useContext(BanTextContext);
    const { isShow, closeModal, upsertBan, adminBanEmailUpsertIsLoading, adminBanEmailUpsertMessage, adminBanEmailUpsertError, textInput, setTextInput } = value

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setTextInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        if (object === 'Not authorized.') {
            errorArray.push(<p key={key} className='error-message'>{object}</p>)
        } else {
            for (var key in object) {
                if (object.hasOwnProperty(key)) {
                    errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
                }
            } 
        }
        return errorArray
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeModal()}>&times;</span>
                {
                    adminBanEmailUpsertIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                    adminBanEmailUpsertMessage ? <div className="message-container"><p className='success-message'>{adminBanEmailUpsertMessage}</p></div> :  
                        <div className="container">
                            <form onSubmit={(event) => upsertBan(event, textInput)}>
                            <label ><b>Email</b></label>
                            <input type="email" minLength="5" autoComplete="off" onChange={handleChange} name={'s_email'} value={textInput.s_email} required/>
                            { adminBanEmailUpsertError ? <div>{parseError(adminBanEmailUpsertError)}</div>  : null }
                            <input type="submit" value={textInput.pk_i_id != "" ? 'Update Keyword' : 'Add Keyword'} className="sellButton pointer" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>
                            </form>
                        </div>
                }    
                </div>
        </div>
    )
}
