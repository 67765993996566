import { all, fork, spawn } from 'redux-saga/effects';
import { watchItemSaga, watchItemMoreSaga, watchItemCategorySaga, watchCreateItemSaga, watchReportSaga } from './itemSaga'
import { watchauthUser, watchNewUser, watchMeSaga, watchUpdateUser, watchVerifyEmail, watchChangePassword, watchSendForgotEmail, watchSessionUserSaga, watchLogoutUser, watchLoginAsUser, watchResendEmailVerification} from './userSaga' 
import { watchAvatarSaga } from './avatarSaga'
import { watchItemUserSaga, watchItemMoreUserSaga } from './itemGetByUser'
import { watchItemViewSaga, watchItemUpdateSaga, watchItemImageEditSaga, watchItemDeleteSaga, watchItemUpdateByFieldSaga } from './itemViewSaga'
import { watchDbsTokenRequestSaga } from './dbsSaga'
import { watchCategorySearchSaga, watchCategorySearchMoreSaga} from './categorySaga' 
import { watchSearchWordSaga, watchSearchWordMoreSaga, watchAddTopSearchSaga } from './searchSaga'
import { watchChatSaga, watchSendChatSaga, watchUpdateUnreadSaga, watchGetChatRoomByIdSaga, watchSendReviewSaga, watchDeleteChatSaga } from './chatSaga'
import { watchServicesSaga, watchPromoteSaga, watchPurchaseCoinSaga, watchCollectPromoSaga } from './purchaseSaga'
import { watchWatchListSaga, watchLikeDislikeSaga } from './watchlistSaga'
import { 
        //  watchAdminLogin, watchAdminSendForgotEmail, watchAdminChangePassword, 
         watchAdminLogin, 
         watchAdminUser, watchAdminGetUserProfile, watchAdminUpsertUser, watchAdminSuggestUser, watchAdminSessionSaga, watchAdminLogout, watchAdminChangeUserEmail} from './admin/userSaga'
import { watchAdminItems, watchAdminGetItemDetail, watchAdminUpdateItem, watchAdminSuggestItem, watchAdminItemsByUser, watchAdminReportedItems, watchAdminDeleteItem } from './admin/itemSaga'
import { watchAdminSales, watchAdminGetSaleDetail, watchAdminSuggestSale } from './admin/saleSaga'
import { watchAdminPreferences, watchAdminUpsertPreference, watchAdminDeletePreference, watchAdminSuggestPreference } from './admin/preferenceSaga'
import { watchAdminCategory, watchAdminUpsertCategory } from './admin/categorySaga'
import { watchReviewSaga, watchReviewViewSaga } from './reviewSaga'
import { watchSuggestWordSaga } from './suggestSaga'
import { watchAdministratorLoad, watchAdministratorProfile, watchAdminUpsertAdministrator, watchAdministratorDeleteLoad, watchAdminSendForgotEmail, watchAdminChangePassword, watchDashboardLoad } from './admin/administratorSaga'
import { watchContactUsSaga } from './contactSaga'
import { watchAdminCustomField, watchAdminUpsertCustomField, watchAdminDeleteCustomField } from './admin/customFieldSaga'
import { watchAdminBanText, watchAdminUpsertBanText, watchAdminDeleteBanText, watchAdminBanEmail, watchAdminUpsertBanEmail, watchAdminDeleteBanEmail } from './admin/banSaga'
import { watchAdminService, watchAdminUpsertService, watchAdminDeleteService} from './admin/serviceSaga'
import { watchToggleLiveSaga, watchUseNowPromoteSaga, watchCancelSubscriptionSaga, watchUpdateSubscriptionSaga } from './microServiceSaga'
import { watchAdminSubscription, watchAdminSubscriptionDetail, watchAdminUpdateSubscription, watchAdminSubscriptionSearch } from './admin/subscriptionSaga'
import { watchAdminLogs } from './admin/logSaga'
import { watchAdminPromos, watchAdminUpsertPromo, watchAdminDeletePromo } from './admin/promoSaga'

export default function* rootSaga(){
    yield all([
        fork(watchItemSaga),
        fork(watchItemMoreSaga),
        fork(watchauthUser),
        fork(watchNewUser),
        fork(watchMeSaga),
        fork(watchSessionUserSaga),
        fork(watchUpdateUser),
        fork(watchVerifyEmail),
        fork(watchItemUserSaga),
        fork(watchItemCategorySaga),
        fork(watchCreateItemSaga),
        fork(watchItemViewSaga),
        fork(watchChangePassword),
        fork(watchSendForgotEmail),
        fork(watchAvatarSaga),
        fork(watchDbsTokenRequestSaga),
        fork(watchCategorySearchSaga),
        fork(watchCategorySearchMoreSaga),
        fork(watchSearchWordSaga),
        fork(watchChatSaga),
        fork(watchSendChatSaga),
        fork(watchUpdateUnreadSaga),
        fork(watchServicesSaga),
        fork(watchPromoteSaga),
        fork(watchWatchListSaga),
        fork(watchLikeDislikeSaga),
        fork(watchAdminLogin),
        fork(watchReviewSaga),
        fork(watchReviewViewSaga),
        fork(watchAdminSendForgotEmail),
        fork(watchAdminChangePassword),
        fork(watchAdminUser),
        fork(watchAdminGetUserProfile),
        fork(watchAdminUpsertUser),
        fork(watchPurchaseCoinSaga),
        fork(watchAdminSuggestUser),
        fork(watchAdminItems),
        fork(watchAdminGetItemDetail),
        fork(watchAdminUpdateItem),
        fork(watchAdminSuggestItem),
        fork(watchAdminSales),
        fork(watchAdminGetSaleDetail),
        fork(watchAdminSuggestSale),
        fork(watchAdminPreferences),
        fork(watchAdminUpsertPreference),
        fork(watchAdminDeletePreference),
        fork(watchAdminSuggestPreference),
        fork(watchAdminCategory),
        fork(watchAdminUpsertCategory),
        fork(watchAdminSessionSaga),
        fork(watchItemUpdateSaga),
        fork(watchAdminItemsByUser),
        fork(watchItemImageEditSaga),
        fork(watchSuggestWordSaga),
        fork(watchReportSaga),
        fork(watchAdminReportedItems),
        fork(watchAdministratorLoad),
        fork(watchAdministratorProfile),
        fork(watchAdminUpsertAdministrator),
        fork(watchAdministratorDeleteLoad),
        fork(watchLogoutUser),
        fork(watchAdminLogout),
        fork(watchDashboardLoad),
        fork(watchItemDeleteSaga),
        fork(watchGetChatRoomByIdSaga),
        fork(watchSendReviewSaga),
        fork(watchSearchWordMoreSaga),
        fork(watchItemUpdateByFieldSaga),
        fork(watchContactUsSaga),
        fork(watchAdminDeleteItem),
        fork(watchLoginAsUser),
        fork(watchAdminCustomField),
        fork(watchAdminUpsertCustomField),
        fork(watchAdminDeleteCustomField),
        fork(watchAdminBanText),
        fork(watchAdminUpsertBanText),
        fork(watchAdminDeleteBanText),
        fork(watchAdminChangeUserEmail),
        fork(watchItemMoreUserSaga),
        fork(watchAdminBanEmail),
        fork(watchAdminUpsertBanEmail),
        fork(watchAdminDeleteBanEmail),
        fork(watchAdminService),
        fork(watchAdminUpsertService),
        fork(watchAdminDeleteService),
        fork(watchUseNowPromoteSaga),
        fork(watchToggleLiveSaga),
        fork(watchCancelSubscriptionSaga),
        fork(watchUpdateSubscriptionSaga),
        fork(watchDeleteChatSaga),
        fork(watchAdminSubscription),
        fork(watchAdminSubscriptionDetail),
        fork(watchAdminUpdateSubscription),
        fork(watchAdminSubscriptionSearch),
        fork(watchAdminLogs),
        fork(watchResendEmailVerification),
        fork(watchAdminPromos),
        fork(watchAdminUpsertPromo),
        fork(watchAdminDeletePromo),
        fork(watchCollectPromoSaga)
    ])
}