import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Authenticated from '../auth'

const PrivateRoute = ({
  session, component, layout, ...rest
}) => (
  <Route
    {...rest}
    render={routeProps => 
    session && session.fkv2_session && session.fkv2_session.type && session.fkv2_session.type === 'admin' ? <Redirect to="/admin/dashboard" />
    : <Authenticated {...routeProps} component={component} layout={layout} session={session} />}
  />
);

export default PrivateRoute;
