import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import PaypalButton from './buyCoinsPaymentMethod/PaypalButton'
import StripeButton from './buyCoinsPaymentMethod/StripeButton'
import {YoutubeIcon, LocationIcon, CloseIconWithGrayBG, TrashIconRedBG, CoinsIcon, CloseIcon, BackMobileButton, PaypalIcon, CreditCardsIcon, CheckGreenBGIcon, CoinsBundleMostWithBestDealLabel, CoinsBundleMore, CoinsBundleLess, SuccessSVGIcon, ErrorIcon, FiveFishIcon} from '../../../../assets/svg'
import {YTLoader} from '../../../../common/component/loader'
import { CustomLoader } from '../../../../common/component/loader'
import {ItemNewContext} from '../../../../common/context'

export const CancelModal = ({alertDetails}) => {
    const title = alertDetails && alertDetails.title ? alertDetails.title : ''
    const body = alertDetails && alertDetails.body ? alertDetails.body : ''
    const leftBtn = alertDetails && alertDetails.leftBtn ? alertDetails.leftBtn : null
    const rightBtn = alertDetails && alertDetails.rightBtn ? alertDetails.rightBtn : null
    // const {leftBtn, rightBtn} = alertDetails
    const lBtnTitle = leftBtn && leftBtn.title ? leftBtn.title : ''
    const rBtnTitle = rightBtn && rightBtn.title ? rightBtn.title : ''
    const lBtnFunc = leftBtn && leftBtn.action ? leftBtn.action : ''
    const rBtnFunc = rightBtn && rightBtn.action ? rightBtn.action : ''

    return (
        <div className="cancel-modal-main-container">
            <div className="cancel-modal-container">
                <div className="cancel-modal-title-container">
                    <p className="cancel-modal-title">{title}</p>
                </div>
                <div className="cancel-modal-p-container">
                    <p className="cancel-modal-p">{body}</p>
                </div>
                <div className="cancel-modal-actions">
                    <div className="cancel-modal-discard" onClick={() => lBtnFunc()}>
                        <p>{lBtnTitle}</p>
                    </div>
                    <div className="cancel-modal-edit" onClick={() => rBtnFunc()}>
                        <p>{rBtnTitle}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const YoutubeThumbnail = ({loading, url}) => {
    return (
        <div className="flex flex-justify-center flex-align-center">
            {
                loading ? <div className="item-create-youtube-preview"> <YTLoader />  </div>:
                url ? 
                <div className="mt-12 flex flex-justify-center flex-align-center">
                    <iframe width={window.innerWidth < 600 ? 300 : 523} height={window.innerWidth < 600 ? 250 : 340} src={url} frameBorder="0" allowFullScreen />
                    </div> : 
                <div className="item-create-youtube-preview">                                 
                    <YoutubeIcon />  
                </div>
            }
        </div>
    )
}


export const Button = ({styleClass, textClass, text, clickFunc}) => {
    return (
        <div className={styleClass} onClick={() => clickFunc()}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const SelectedLocation = ({location, removeFunc}) => {
    return (
        <>
       <div className="meetup-address-container">
            <div className="meetup-icon-container">
                <LocationIcon />
            </div>
            <div className="meetup-p-container">
                <p className="meetup-address-p">{location}</p>
            </div>
            <div onClick={() => removeFunc()} className="meetup-close-container">
                <CloseIconWithGrayBG />
            </div>
        </div> 
        </>
    )
}

export const ItemConditionField = ({ change, datas, savedData, error}) => {
    return (
        <>
         <div className="item-new-formGroup">
            <label className="item-new-label">{`Item Condition`}</label>
            <div className="item-condition-container">
            {
                datas.map((choices, index) => {
                return <div key={index} className={`options-${choices.value}`}>
                    <input type="radio" name="item_condition" className={`item-condition-radios radio custom-radio-ui ${choices.value === savedData ? `item-new-radio-checked` : ``}`} value={choices.value} onChange={change} checked={choices.value === savedData ? true : false} />
                    <span className="condition-label">{choices.label}</span>
                </div>
                })
            }
            <p style={{ color: '#c0392b' }}>{error ? error : null}</p>
            </div>
        </div> 
        </>
    )
}

export const AutoSuggestionContainer = ({locationSuggestList, saveLocationFunc}) => {
    return (
        <div className="g-place-suggest-container">
        <div className="g-main-suggest-container">
          {
            locationSuggestList.map((predict, index) => {
              return index !== 0 ? locationSuggestList[index-1].ADDRESS === predict.ADDRESS ? null :<div className="g-sub-suggest-container"> <div key={index} className="g-suggest-container" onClick={() => saveLocationFunc(predict.ADDRESS)}>
            <p className="g-location-postal">{predict.POSTAL}</p>
            <p className="g-location-p">{predict.ADDRESS}</p>
          </div> </div> : <div className="g-sub-suggest-container"> <div key={index} className="g-suggest-container" onClick={() => saveLocationFunc(predict.ADDRESS)}>
            <p className="g-location-postal">{predict.POSTAL}</p>
            <p className="g-location-p">{predict.ADDRESS}</p>
          </div> </div>
            })
          }
        </div>
      </div> 
    )
}

export const DeliveryTextArea = ({data, change, name}) => {
    return (
        <div className="item-new-ship-container">
        <div className="item-new-ship-sub-container">
          <textarea autoComplete="nope" name={name} maxLength={200} onChange={change} value={data} rows="3" placeholder="Enter shipping details..." />
          <div className="flex w-100 flex-justify-end">
            <p className="textarea-counter">{`${data.length}/200`}</p>
          </div>
        </div>
      </div> 
    )
}

export const UploadComponent = () => {
    return (
      <div key={'dp'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{ paddingLeft: '17px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className="select-photos-btn">{`Select Photos`}</div>
          <p className="desktop-label">{`or drag photos here`}</p>
          <p className="desktop-label">{`Up to 4 images`}</p>
        </div>
      </div>
    )
  }

  export const PostTypeFields = ({radioname, name, subname, value, radioClass, nameClass, subnameClass, onChangeFunc, selectedOptions}) => {
      return (
          <div className={`flex ${window.innerWidth < 600 ? `flex-d-column` : `flex-align-center flex-d-row mr-33`}`}>
              <input type="radio" name={radioname} value={value} className={radioClass} onChange={(e) => onChangeFunc(e)} checked={selectedOptions === value ? true : false}/>
              <div className={`flex flex-d-column `}>
                <p className={nameClass}>{name}</p>
                <p className={subnameClass}>{subname}</p>
              </div>
          </div>
      )
  }

  export const PurchaseArrMapper = (arr) => {
    return (
        arr.map((offer, i) => {
            const coins = offer && offer.credit ? offer.credit : 0
            const price = offer && offer.sg_price ? offer.sg_price : 0
            const dt_today = moment().format('DD MMMM, YYYY')
            return (
                <div key={i} className="flex flex-d-row flex-justify-between flex-align-center mb-30">
                    <div>
                        <p className="int-buy-coins-per-total-p">{`${coins} Coins`}</p>
                    </div>
                    <div className="flex flex-d-column flex-justify-end ">
                        <div className="flex flex-d-row flex-justify-end text-align-end">
                            <p className="int-buy-coins-currency-symbol-p">{`S$`}</p> &nbsp;
                            <p className="int-buy-coins-currency-price-p">{price}</p>
                        </div>
                        <p className="int-buy-coins-dt-today">{dt_today}</p>
                    </div>
                </div>
            )
        })
    )
}
export const SmallCoinsContainer = ({icon, data, handleBuyCoins, offerSelected, wallet, total}) => {
    const credit = data && data.credit ? data.credit : 0
    const price = data && data.sg_price ? data.sg_price : 0
    const boostId = data && data.id ? data.id : 0
    const found = offerSelected.some(el => el.id === boostId)
    return (
        <div className={ found ? `active-buy-coins-border buy-coins-option-small-container` : `buy-coins-option-small-container`} onClick={() =>  handleBuyCoins(data)}>
            {icon}
            <p className="buy-coins-credit-coins-p mt-10">{`${credit} coins`}</p>
            <p className="buy-coins-price-coins-p mt-16">{`${price}S$`}</p>
            {found ?  <div className={`p-absolute buy-coins-select-check-main-container`}>
                    <CheckGreenBGIcon />
                </div> : null}
        </div>
    )
}

export const LargeCoinsContainer = ({icon, data, handleBuyCoins, offerSelected, wallet, total}) => {
    const credit = data && data.credit ? data.credit : 0
    const price = data && data.sg_price ? data.sg_price : 0
    const boostId = data && data.id ? data.id : 0
    const found = offerSelected.some(el => el.id === boostId)

    return (
        <div className={ found ? `active-buy-coins-border buy-coins-option-large-container` : `buy-coins-option-large-container`} onClick={() => handleBuyCoins(data)}>
            {icon}
            <div className="flex flex-d-column flex-align-center flex-justify-center">
                <p className="buy-coins-credit-coins-p">{`${credit} coins`}</p>
                <p className="buy-coins-price-coins-p mt-16">{`${price}S$`}</p>
            </div>
            {found ?  <div className={`p-absolute buy-coins-select-check-main-container`}>
                <CheckGreenBGIcon />
            </div> : null}
        </div>
    )
}

  export const Divider = () => {
    return (
        <div className="w-100 divider-border"/>
    )
    }

    export const ModalFooter = () => {
        return (
            <div className="buy-coins-modal-footer-container">
                <p className="buy-purchasing-label-p">{`By purchasing any of the above, you accept our `}</p>
                <p className="buy-purchasing-terms-link-p">{`Terms of Services.`}</p>
            </div>
        )
    }

    export const PaymentSuccess = () => {
        return (
            <div className="flex flex-d-column mt-113 flex-align-center">
                <p className="payment-successful-label-p">{`Payment Successful!`}</p>
                <SuccessSVGIcon />
            </div>
        )
    }
    
    export const PaymentUnsuccessful = () => {
        return (
            <div className="flex flex-d-column mt-113 flex-align-center">
                <p className="payment-unsuccessful-label-p">{`Payment Successful!`}</p>
                <ErrorIcon />
            </div>
        )
    }

  export const Modal = () => {
    const value = useContext(ItemNewContext)
    const {buyCoinsOffer, setIsCoinsClick, handleBuyCoinsStepsAction, offerSelected, buyCoinStep, wallet, handleBuyCoinsPay, handleBuyCoins, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, initialLoader, setInitialLoader} = value
    const selectedArr = offerSelected && offerSelected.coinsArr ? offerSelected.coinsArr : []
    const hasSelected = offerSelected.coinsArr.length !== 0 ? true : false
    const total = offerSelected && offerSelected.total ? offerSelected.total : 0

    return (
        <div className={`fk-prime-buy-coins-modal-container`}>
            {
                initialLoader ? <CustomLoader />: 
                isLoadingPurchaseCoin ? <CustomLoader />: 
                purchaseSuccessMessage ? PaymentSuccess() :
                errorPurchaseCoin ? PaymentUnsuccessful() :
            
            <div className="fk-prime-buy-coins-header-container">
                {
                    buyCoinStep === 1 ?
                    <div className="w-100 flex flex-justify-end cursor-pointer" onClick={() => setIsCoinsClick(false)}>
                        <CloseIcon />
                    </div> : 
                    <div className="w-100 flex flex-justify-between">
                        <div className="cursor-pointer" onClick={() => handleBuyCoinsStepsAction('-')}>
                            <BackMobileButton />
                        </div>
                        <div className="cursor-pointer" onClick={() => setIsCoinsClick(false)}>
                        <CloseIcon />
                    </div>
                    </div>
                }
                {
                    buyCoinStep === 1 ?
                    <>
                        <div className="flex flex-d-column flex-align-center flex-justify-center mt-34">
                            <p className="get-more-coins-label-p">{`Get More Coins`}</p>
                            <p className="save-more-coins-label-p">{`Save more with bigger bundles`}</p>
                        </div>
                        <div className="flex flex-d-row flex-justify-between fk-prime-buy-current-coins-container">
                            <p className="buy-coins-current-coins-label-p">{`Current Coins`}</p>
                            <div className="flex flex-d-row flex-align-center">
                                <CoinsIcon />
                                <p className="buy-coins-current-coins-credit-p">{wallet}</p>
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="flex flex-d-column flex-align-center flex-justify-center mt-34">
                            <p className="get-more-coins-label-p">{`Select Payment Method`}</p>
                        </div>
                        <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-32">
                            <div className="flex flex-d-row flex-align-center">
                                <input type="radio" value="paypal" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'paypal' ? true : false} onChange={() => handleBuyCoinsPay('paypal')}/>
                                <p>{`Paypal`}</p>
                            </div>
                            <PaypalIcon />
                        </div>
                        <div className="w-100 flex flex-d-row flex-justify-between flex-align-center mt-22 mb-30">
                            <div className="flex flex-d-row flex-align-center">
                                <input type="radio" value="stripe" className="item-condition-radios radio custom-radio-ui " checked={offerSelected.payment_method === 'stripe' ? true : false} onChange={() => handleBuyCoinsPay('stripe')}/>
                                <p>{`Stripe`}</p>
                            </div>
                            <CreditCardsIcon />
                        </div>
                        {Divider()}
                        <div className="flex flex-d-row w-100 flex-justify-center flex-align-center mt-30">
                            <p className="get-more-coins-label-p">{`Purchase`}</p>
                        </div>
                        {PurchaseArrMapper(selectedArr)}
                        <div className=" individual-ads-main-total-container">
                            <p className="individual-ads-main-total-label-p">{`Total`}</p>
                            <div className="flex flex-d-row flex-justify-end flex-align-center">
                                <p className="buy-coins-main-currency-p">{`S$`}</p> &nbsp;
                                <p className="buy-coins-main-total-int-p">{offerSelected.total}</p>
                            </div>
                        </div>
                    </>
                }
                
            </div>
            }
           {
             buyCoinStep === 1 ?
               <div className="buy-coins-options-choices-main-container">
               {
                   buyCoinsOffer.map((offer, key) => {
                       return (
                           key+1 === buyCoinsOffer.length ?
                           <LargeCoinsContainer icon={<CoinsBundleMostWithBestDealLabel />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/> :
                           <SmallCoinsContainer icon={ key+1 === 2 ? <CoinsBundleMore /> : <CoinsBundleLess />} data={offer} handleBuyCoins={handleBuyCoins} offerSelected={selectedArr} wallet={wallet} total={total} key={key}/>
                       )
                   })
               }
           </div> : null
           }
            { isLoadingPurchaseCoin || purchaseSuccessMessage || errorPurchaseCoin || initialLoader ? null :
            <>
                <div className={`flex flex-justify-center flex-align-center mt-30 pay-container-main-buy-coins`} >
                    {
                        offerSelected.payment_method === 'paypal' && buyCoinStep !== 1 ? <div> <PaypalButton /> </div>: offerSelected.payment_method === 'stripe' && buyCoinStep !== 1 ? <div> <StripeButton />  </div>:
                        <div className={ buyCoinStep === 1 ? hasSelected ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn` : offerSelected.payment_method ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn`} onClick={() => hasSelected && buyCoinStep === 1 ? handleBuyCoinsStepsAction('+') : null}>
                            <p className="fk-prime-purchase-p">{buyCoinStep === 1 ? `Purchase` : `Pay`}</p>
                        </div>
                    }
                </div>
            {ModalFooter()}
            </>
            }
        </div>
    )
}

export const NoListingAvailableContainer = () => {
    const value = useContext(ItemNewContext)
    const {handleClaim} = value
    return (
        <div className="unclaimed-free-listing-body-container">
            <p className="unclaimed-free-listing-slots-top-label-p">{`You have no listing slots available`}</p>
            <div className="unclaimed-free-item-new-container">
                <div className="flex flex-d-column flex-justify-center flex-align-center mt-50">
                        <p className="unclaimed-five-free-listing-label-p">{`You have 5 free listing slots,`}</p>
                        <p className="unclaimed-five-free-listing-label-p">{`No expiration!`}</p>
                </div>
                <div className="flex flex-justify-center mt-30">
                    <div className="unclaimed-free-listing-claim-btn" onClick={() => handleClaim()}>
                        <p>{`CLAIM`}</p>
                    </div>
                </div>
                <div className="mt-30 w-100 flex flex-justify-center">
                    <p className="unclaimed-activate-disclaimer-label-p">{`Activate and make different listings go live`}</p>
                </div>
            </div>
        </div>
    )
}

export const UsedAllSlots = () => {
    return (
        <div className="unclaimed-free-listing-body-container">
            <p className="unclaimed-free-listing-slots-top-label-p">{`You have no listing slots available`}</p>
            <div className="no-available-slots-main-body-container">
                <div className="flex flex-d-column flex-justify-center flex-align-center mt-50">
                    <p className="no-available-slots-top-alert-p">{`You have used all listing slots, you may deactivate a listing to free a slot`}</p>
                    <Link to={`/user/listing`}>
                        <p className="no-available-link-to-list-p">{`LISTING PAGE`}</p>
                    </Link>
                    <div className="mt-30 mb-30">
                        <FiveFishIcon />
                    </div>
                    <p>{`or`}</p>
                </div>
                <div className="upgrade-your-plan-main-container">
                    <p className="upgrade-plan-no-avail-p">{`UPGRADE YOUR PLAN`}</p>
                    <p className="add-slots-label-p">{`Add Slots`}</p>
                    <Link to={`/fk-prime`} className="unclaimed-free-listing-claim-btn" >
                        <p>{`FK PRIME`}</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default {CancelModal, YoutubeThumbnail, SelectedLocation, ItemConditionField, AutoSuggestionContainer, DeliveryTextArea, UploadComponent, PostTypeFields, NoListingAvailableContainer, UsedAllSlots}