import React from 'react';
import { useHistory } from 'react-router-dom'
import ProfileBar from './ProfileBar'
import ProfileNavLink from '../../../../../web/profile/profileNavLink'
import DefaultCover from '../../../../../web/profile/cover'
import Listing from '../listing'

const PublicProfile = () => {
    let history = useHistory();
    const session = {}

    const handleBack = () => {
        const url = `/admin/dashboard/user`
        history.push(url);
    }
    return (

        <div className="profile-container">
            <div className='profile-container-overlay' />
            <div className='back-button-container'>
                <span className='back-button pointer' onClick={() => handleBack()}><i class="fas fa-arrow-left"></i></span>
            </div>
            <div className="profile-sub-main-container w-100">
                <h2 className='align-center'>User Public View</h2>
                <DefaultCover />
                <div className="profile-main-container">
                    <ProfileBar session={session} />
                    <div>
                    <ProfileNavLink session={session}/>
                    <Listing session={session}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicProfile;
