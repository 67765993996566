import { SEARCH_SUGGEST } from '../constant'

const initialState = {
    isLoading: false,
    data: [],
    error: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case SEARCH_SUGGEST.LOAD:
            return { ...state, isLoading: true, error: null }

        case SEARCH_SUGGEST.SUCCESS:
            return { ...state, isLoading: false, data: action.payload, error: null }

        case SEARCH_SUGGEST.FAIL:
            return { ...state, isLoading: false, error: action.payload }

        default:
            return state;
    }
}