import { SERVICES, PROMOTE, PURCHASE_COIN, COLLECT_PROMO } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { servicesSuccess, servicesFailed, promoteSuccess, promoteFailed, purchaseCoinSuccess, purchaseCoinFailed, meSuccess, sessionSuccess, itemUpdateSuccess,
collectPromoSuccess, collectPromoFailed } from '../action';
import  user from '../../graphql/user'
import { theme } from '../../common/theme'

function* servicesSaga(action) {
    try {
      const result = yield user.getServices()
      if(result === 403) {
        yield put(servicesFailed('Something went wrong try again!'))
      }
      else {
        const data = result && result.data && result.data.getActiveServices ? result.data.getActiveServices : []
        yield put(servicesSuccess(data));
        }
    } catch (e) {
    console.log('error', e)
    const errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].debugMessage ? e.graphQLErrors[0].debugMessage : theme.text.error.default 
    yield put(servicesFailed(errorMessage))
    }
}    

export function* watchServicesSaga() {
  yield takeLatest(SERVICES.LOAD, servicesSaga);
}

function* promoteSaga(action) {
  const { payload } = action
  try {
    const result = yield user.fkPurchase(payload)
    if(result === 403) {
      yield put(promoteFailed('Something went wrong try again!'))
    }
    else {
      const purchase_data = result && result.data &&  result.data.fkPaymentProcess ? result.data.fkPaymentProcess : {}
      const data= result && result.data &&  result.data.fkPaymentProcess && result.data.fkPaymentProcess.data ? result.data.fkPaymentProcess.data : null
        if (data) {
          yield put(meSuccess(data));
          yield put(sessionSuccess(data));
        }
        if (purchase_data) {
          yield put(purchaseCoinSuccess(purchase_data));
          yield put(itemUpdateSuccess(purchase_data))
          yield put(promoteSuccess(purchase_data));
        }
      }
    } catch (e) {
      console.log('error', JSON.stringify(e))
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      yield put(promoteFailed(errorMessage))
    }
}    

export function* watchPromoteSaga() {
  yield takeLatest(PROMOTE.LOAD, promoteSaga);
}

function* purchaseCoinSaga(action) {
  const { payload } = action

  try {
    const result = yield user.fkPurchase(payload)
    if(result === 403) {
      yield put(purchaseCoinFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data &&  result.data.fkPaymentProcess ? result.data.fkPaymentProcess : null
      const meData = result && result.data &&  result.data.fkPaymentProcess && result.data.fkPaymentProcess.data ? result.data.fkPaymentProcess.data : null
        if (meData) {
          yield put(meSuccess(meData));
          yield put(sessionSuccess(meData));
        }
        if (data) {
          yield put(purchaseCoinSuccess(data));

        }
      }
    } catch (e) {
      console.log('error', JSON.stringify(e))
      // const errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].debugMessage ? e.graphQLErrors[0].debugMessage : theme.text.error.default 
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      // console.log('error', errorMessage)
      yield put(purchaseCoinFailed(errorMessage))
    }
}    

export function* watchPurchaseCoinSaga() {
  yield takeLatest(PURCHASE_COIN.LOAD, purchaseCoinSaga)
}

function* collectPromoSaga(action) {
  const { payload } = action
  try {
    const result = yield user.collectPromo(payload)
    if(result === 403) {
      yield put(collectPromoFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data &&  result.data.collectPromo ? result.data.collectPromo : null
      yield put(collectPromoSuccess(data));
      }
    } catch (e) {
      console.log('error', JSON.stringify(e))
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
          error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
            error && error[0] && error[0].message ? error[0].message : 
              theme.text.error.default
      yield put(collectPromoFailed(errorMessage))
    }
}    

export function* watchCollectPromoSaga() {
  yield takeLatest(COLLECT_PROMO.LOAD, collectPromoSaga)
}