import React from 'react'

export const CustomFieldList = (props) => {
    const { data, handleCustomFieldChange } = props

    const renderInputs = () => {
        const userData = data
        let arr = []
        for (var k in userData) {
            const value = userData[k].value;

            switch (userData[k].type) {
                case 'STRING':
                    arr.push(
                        <div className="item-new-formGroup">
                        <label className="item-new-label">{userData[k].label}</label>
                        <input className="item-new-input" type="text" name={userData[k].name} value={value} onChange={handleCustomFieldChange} placeholder={userData[k].placeholder}></input>
                        </div>
                    )
                    break;
                case 'INTEGER':
                    arr.push(
                        <div className="item-new-formGroup">
                        <label className="item-new-label">{userData[k].label}</label>
                        <input className="item-new-input" type="number" name={userData[k].name} value={value} onChange={handleCustomFieldChange} placeholder={userData[k].placeholder}></input>
                        </div>
                    )
                    break;
            
                default:
                    arr.push(
                        <div className="item-new-formGroup">
                        <label className="item-new-label">{userData[k].label}</label>
                        <input className="item-new-input" type="text" name={userData[k].name} value={value} onChange={handleCustomFieldChange} placeholder={userData[k].placeholder}></input>
                        </div>
                    )
                    break;
            }
        }
        return arr
    }

    return(
        renderInputs()
    )
}