import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminBantTextSuccess, adminBantTextFailed, adminUpsertBantTextSuccess, adminUpsertBantTextFailed, adminDeleteBanTextSuccess, adminDeleteBanTextFailed,
         adminBanEmailSuccess, adminBanEmailFailed, adminUpsertBanEmailSuccess, adminUpsertBanEmailFailed, adminDeleteBanEmailSuccess, adminDeleteBanEmailFailed
} from "../../action";
import admin from '../../../graphql/admin/ban'
import { theme } from '../../../common/theme'

//fetch ban text
function* adminBanText(action) {
    const { payload } = action
    try{
        const result = yield admin.getBan(payload)
        if(result === 403) {
            yield put(adminBantTextFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getBanRuleByType ? result.data.getBanRuleByType : {}
            yield put(adminBantTextSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminBantTextFailed(errorMessage))
    }
}

export function* watchAdminBanText() {
    yield takeLatest(ADMIN.BAN_TEXT.LOAD, adminBanText);
}

//upsert ban text
function* adminUpsertBanText(action) {
    const { payload } = action
    try{
        const result = yield admin.upsertBan(payload)
        if(result === 403) {
            yield put(adminUpsertBantTextFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.banRuleUpsert && result.data.banRuleUpsert ? result.data.banRuleUpsert : ''
            yield put(adminUpsertBantTextSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertBantTextFailed(errorMessage))
    }
}

export function* watchAdminUpsertBanText() {
    yield takeLatest(ADMIN.BAN_TEXT_UPSERT.LOAD, adminUpsertBanText);
}

//delete ban text
function* adminDeleteBanText(action) {
    const { payload } = action
    try{
        const result = yield admin.deleteBanText(payload)

        if(result === 403) {
            yield put(adminDeleteBanTextFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.banRuleDelete && result.data.banRuleDelete ? result.data.banRuleDelete : ''
            yield put(adminDeleteBanTextSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeleteBanTextFailed(errorMessage))
    }
}

export function* watchAdminDeleteBanText() {
    yield takeLatest(ADMIN.BAN_TEXT_DELETE.LOAD, adminDeleteBanText);
}

//fetch ban email
function* adminBanEmail(action) {
    const { payload } = action
    try{
        const result = yield admin.getBan(payload)
        if(result === 403) {
            yield put(adminBanEmailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getBanRuleByType ? result.data.getBanRuleByType : {}
            yield put(adminBanEmailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminBanEmailFailed(errorMessage))
    }
}

export function* watchAdminBanEmail() {
    yield takeLatest(ADMIN.BAN_EMAIL.LOAD, adminBanEmail);
}

//upsert ban email
function* adminUpsertBanEmail(action) {
    const { payload } = action
    try{
        const result = yield admin.upsertBan(payload)
        if(result === 403) {
            yield put(adminUpsertBanEmailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.banRuleUpsert && result.data.banRuleUpsert ? result.data.banRuleUpsert : ''
            yield put(adminUpsertBanEmailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertBanEmailFailed(errorMessage))
    }
}

export function* watchAdminUpsertBanEmail() {
    yield takeLatest(ADMIN.BAN_EMAIL_UPSERT.LOAD, adminUpsertBanEmail);
}

//delete ban email
function* adminDeleteBanEmail(action) {
    const { payload } = action
    try{
        const result = yield admin.deleteBanText(payload)

        if(result === 403) {
            yield put(adminDeleteBanEmailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.banRuleDelete && result.data.banRuleDelete ? result.data.banRuleDelete : ''
            yield put(adminDeleteBanEmailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeleteBanEmailFailed(errorMessage))
    }
}

export function* watchAdminDeleteBanEmail() {
    yield takeLatest(ADMIN.BAN_EMAIL_DELETE.LOAD, adminDeleteBanEmail);
}