import React, { useState } from 'react';
import {TrashCanIcon} from '../../../../assets/svg'

const DragableLayoutMobile = (props) => {
  const { REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX, REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER } = process.env;

  const useForceUpdate = () => useState()[1]
  const forceUpdate = useForceUpdate()
  //imageData is array of image files
  //setImageData function that updates imageData
  //import this file and add the function below to your Dropzone component 

  // const renderPreview = () => {
  //   return <DragableLayout imageData={imageData} setImageData={setImageData}/>
  // }

  //Use the function as LayoutComponent on your Dropzone, sample below
  // LayoutComponent={imageData.length > 0 ? () => renderPreview() : null}

  
  const {imageData, setImageData, handleChangeStatus, isFromEdit, editRemoveImage} = props
  const [forceRender, toggleForceRender] = useState(false)
  
  const togRender = () => {
      toggleForceRender(!forceRender)
  }

  const goToFirst = (i, e) => {
    e.preventDefault()
    let newImageData = imageData
    newImageData.forEach((item, index) => {
      if(item.meta ? item.meta.id === i : item.id === i){
          newImageData.splice(index, 1)
          newImageData.unshift(item)
      }
    })
    setImageData(newImageData)
    togRender()
  }

  const removeImage = (item, func) => {
    handleChangeStatus(item, 'removed')
    func()
  }

  const changeAction = (index, item, func) => {
    return isFromEdit ? editRemoveImage(imageData, setImageData, index) : removeImage(item, func)
  } 

  return (<>
            {imageData.map((item, index) =>{
                    const source = item && item.meta && item.meta.previewUrl ? item.meta.previewUrl : ''
                    const name = item && item.meta && item.meta.name ? item.meta.name : ''
                    const metaId = item && item.meta && item.meta.id ? item.meta.id : null
                    const id = item.id ? item.id : 0
                    const itemId = item.id ? item.id : ''
                    const ext = item.s_extension ? item.s_extension : ''
                    const path = 'uploads'
                    const imgSrc = item.original ? item.original : ''
                    return (
                        <>
                            <div 
                              className="droptarget" 
                              id={`${metaId}:${index}`} >

                              <img draggable="true" src={isFromEdit ? id ? imgSrc : source : source} width={80} height={91} name={isFromEdit ? itemId : name} id={ isFromEdit ? `${itemId}:${index}` :`${metaId}:${index}`} />
                              <div className="dzu-previewStatusContainer" onClick={() => changeAction(index, item, item.remove) }>
                              <span className="remove-trash-icon-container"><TrashCanIcon /></span>
                              </div>
                              {index === 0 ? null : <div onClick={(e) => isFromEdit ? id ? goToFirst(id, e) : goToFirst(metaId, e) : goToFirst(metaId, e)} className="setAsCover-btn">Set as Cover</div>}
                            </div> 
                           
                        </>
                    )
                  })
            }
          </>
  )

}

export default DragableLayoutMobile
