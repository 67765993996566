import React from 'react';

const Ratings = props => {

  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
       <div>
           <h1>Ratings</h1>
       </div>
  );
}

export default Ratings;