import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom'
import { adminUserLoad, adminUserReset, adminUserProfileLoad, adminUserProfileReset, adminUpsertUserLoad, adminUpsertUserReset, adminUserSuggestLoad, loginAsUserLoad, loginAsUserReset, adminChangeUserEmailLoad, adminChangeUserEmailClearMessage } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { UserList, UserModal, UserSuggestData, UserSearchResult, ChangeEmailModal } from './component/CustomComponent'
import {  AdminUserContext } from '../../../../../common/context'


const UserSection = (props) => {
    const pageCount = 10
    const result_limit = 20

    const { adminUserData, adminUserIsLoading, adminUserError, adminUserPage, adminTotalUser,
            adminUserProfileData, adminUserProfileIsLoading, adminUserProfileError, adminUpsertUserError, adminUpsertUserSuccess,
            changeUserEmailIsLoading, changeUserEmailData, changeUserEmailError
    } = props.admin

    const { loginAsUserIsloading, loginAsUserMessage,loginAsUserError } = props.auth

    useEffect(() => {
        if (adminUserData && adminUserData.length === 0) {
            props.adminUserLoad({first: 20, page: adminUserPage})
        }
    // eslint-disable-next-line     
    },[adminUserData])

    useEffect(()=>{
        if (adminUpsertUserSuccess) {
            setTimeout(() => {
                setModalToShow(null)
                props.adminUserProfileReset()
            }, 2000)
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/user`)
            }, 3000)
        }
        if (changeUserEmailData) {
            setTimeout(() => {
                setModalToShow(null)
            }, 2000)
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/user`)
            }, 3000)
        }
    // eslint-disable-next-line
    }, [adminUpsertUserSuccess, changeUserEmailData])

    useEffect(()=>{
        if (loginAsUserMessage) {
            setTimeout(() => {
                props.loginAsUserReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}`)
            }, 2000)
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}`)
            }, 3000)
        }
        if (loginAsUserError) {
            setTimeout(() => {
                props.loginAsUserReset()
            }, 2000)
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/user`)
            }, 3000)
        }
    // eslint-disable-next-line
    }, [loginAsUserMessage, loginAsUserError])

    const handlePageClick = data => {
        props.adminUserLoad({first: 20, page: data.selected + 1})
    };

    //user modal
    const [isShow, setIsShow] = useState(false)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)
    const [modalToShow, setModalToShow] = useState(null)

    const fetchUserProfile = (id) => {
        setIsSearching(false)
        setModalToShow('user')
        props.adminUserProfileLoad({pk_i_id: id})
    }

    const closeUserModal = () => {
        props.adminUserProfileReset()
        setModalToShow(null)
    }

    const upsertUser = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertUserLoad(data)
    }

    const { userSuggestData, userSuggestIsLoading  } = props.admin
    const [isSearching, setIsSearching] = useState(false)

    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            props.adminUserSuggestLoad({type: 'all', limit: result_limit, text: text})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setModalToShow('search')

    }

    //goto items
    let history = useHistory();
    const gotoItems = (id) => {
        history.push("/admin/dashboard/item", {user_id: id});
    }

    const closeAllModal = () => {
        setIsSearching(false)
        setModalToShow(null)
    }

    const handleLoginAsUser = (id, email, username) => {
        let r = window.confirm(`You are about to login as user ${username}. Be reminded that this feature is for issue troubleshooting purpose only.`)
        if (r == true) {
            closeAllModal()
            props.loginAsUserLoad({id: id,email: email, username: username})
        }
    }

    const historyUserId = props.history && props.history.location && props.history.location.state && props.history.location.state.user_id ? props.history.location.state.user_id : null
    
    useEffect(() => {
        if (historyUserId) {
            fetchUserProfile(historyUserId)
        }
        return () => {
            closeAllModal()
            // history.replace()
        }
    // eslint-disable-next-line
    }, [historyUserId])

    const renderShowModal = () => {
        let element
        switch (modalToShow) {
            case 'user':
                element = <UserModal />
                break;
            case 'search':
                element = <UserSearchResult />
                break; 
            case 'change_email':
                element = <ChangeEmailModal />
                break; 
                
            default:
                break;
        }
        return element
    }

    const changeEmail = (evt, data) => {
        evt.preventDefault();
        props.adminChangeUserEmailLoad(data)
    }

    const handleViewPublicProfile = (id) => {
        const url = `/admin/dashboard/user/${id}`
        history.push(url);
    }

    return (
        <AdminUserContext.Provider value={{adminUserData, isShow, setIsShow, fetchUserProfile, closeUserModal, 
            adminUserProfileData, adminUserProfileIsLoading, adminUserProfileError, upsertUser,
            userSuggestData, userSuggestIsLoading, adminUpsertUserError, setIsShowSearchResult, isShowSearchResult,
            gotoItems, handleLoginAsUser, setModalToShow, modalToShow, changeEmail, changeUserEmailIsLoading, changeUserEmailData, changeUserEmailError,
            handleViewPublicProfile
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Users</h3>
            <section>
            {adminUserIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminUserError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminUserError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div onClick={() => setIsShow(true)} className="sellButton pointer" style={{margin: 10, alignSelf: 'flex-start'}}>
                                <span>Add user</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {userSuggestIsLoading ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><UserSuggestData /></div>}
                            </div>
                        </div>
                        {
                            loginAsUserIsloading ? <p style={{textAlign: 'center'}}>Loggin in as user...</p> :
                            loginAsUserError ? <p style={{color: 'red', textAlign: 'center'}}>{loginAsUserError}</p>:
                            loginAsUserMessage ? <p style={{textAlign: 'center', color: 'green'}}>{loginAsUserMessage}</p> : null
                        }
                        <UserList type='load'/>
                    </article>
            }
                    {adminUserError ? null :
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={adminTotalUser}
                            marginPagesDisplayed={0}
                            pageRangeDisplayed={pageCount}
                            onPageChange={(d) => handlePageClick(d)}
                            containerClassName={'pagination pointer'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    }  
            </section> 
        </section> 
        {renderShowModal()}
        </AdminUserContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
        auth: store.authReducer
    };
  };
  
const mapActionToProps = {
    adminUserLoad,
    adminUserProfileLoad,
    adminUserProfileReset,
    adminUpsertUserLoad, 
    adminUpsertUserReset,
    adminUserReset,
    adminUserSuggestLoad,
    loginAsUserLoad,
    loginAsUserReset,
    adminChangeUserEmailLoad, 
    adminChangeUserEmailClearMessage
}
  
export default connect(mapStateToProps, mapActionToProps)(UserSection);
