import { ITEM_VIEW, ITEM_UPDATE, ITEM_IMAGE_EDIT, ITEM_DELETE, ITEM_VIEW_LIKE_UPDATE, ITEM_VIEW_UPDATE_BY_FIELD } from '../constant'

const initialState = {
    isLoading: false,
    data: {},
    error: false,
    itemMessage: false,

    updateIsLoading: false,
    updateMessage: false,
    updateData: {},
    updateError: false,

    imageEditIsLoading: false,
    imageEditMessage: false,
    imageEditData: [],
    imageEditError: false,

    deleteIsloading: false,
    deleteMessage: false,
    deleteError: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case ITEM_VIEW.LOAD:
        return {...state, isLoading: true, error: null, itemMessage: false}

        case ITEM_VIEW.SUCCESS:
        const noResultMessage =  Object.keys(action.payload).length === 0 ? 'No item found' : false
        return {...state, isLoading: false, data: action.payload, error: null, itemMessage: noResultMessage }

        case ITEM_VIEW.FAIL:
        return {...state, isLoading: false, error: action.payload, itemMessage: false}

        case ITEM_UPDATE.LOAD:
        return {...state, updateIsLoading: true, updateError: false} 

        case ITEM_UPDATE.SUCCESS:
            const updateMessage = action.payload && action.payload.message ? action.payload.message : ''
            const updatedData = action.payload && action.payload.response ? action.payload.response : {}
        return {...state, updateIsLoading: false, updateError: false, data: updatedData, updateMessage: updateMessage} 

        case ITEM_UPDATE.FAIL:
        return {...state, updateIsLoading: false, updateError: action.payload, updateData: {}} 

        case ITEM_UPDATE.RESET:
        return {...state, updateIsLoading: false, updateError: false, updateData: {}, updateMessage: false} 

        case ITEM_IMAGE_EDIT.LOAD:
        return {...state, imageEditIsLoading: true, imageEditError: false} 

        case ITEM_IMAGE_EDIT.SUCCESS:
            const imageEditMessage = action.payload && action.payload.message ? action.payload.message : ''
            const updatedImageEditData = action.payload && action.payload.response ? action.payload.response : {}
        return {...state, imageEditIsLoading: false, imageEditError: false, data: updatedImageEditData, imageEditMessage: imageEditMessage} 

        case ITEM_IMAGE_EDIT.FAIL:
        return {...state, imageEditIsLoading: false, imageEditError: action.payload, imageEditData: {}} 

        case ITEM_IMAGE_EDIT.RESET:
        return {...state, imageEditIsLoading: false, imageEditError: false, imageEditMessage: false} 

        case ITEM_DELETE.LOAD:
        return {...state, deleteIsloading: true, deleteError: false} 

        case ITEM_DELETE.SUCCESS:
            const deleteMessage = action.payload && action.payload.message ? action.payload.message : ''
        return {...state, deleteIsloading: false, deleteError: false, deleteMessage: deleteMessage } 

        case ITEM_DELETE.FAIL:
        return {...state, deleteIsloading: false, deleteError: action.payload, deleteMessage: false}

        case ITEM_DELETE.RESET:
        return {...state, data: state.data, deleteIsloading: false, deleteError: false, deleteMessage: false }

        case ITEM_VIEW.SET_RESERVE:
        if (state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(action.payload.id)) {
            return {...state, data: {...state.data, b_reserved: action.payload.b_reserved }}
        }  

        case ITEM_VIEW.SET_SOLD:
        if (state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(action.payload.id)) {
            return {...state, data: {...state.data, b_mark_as_sold: action.payload.b_mark_as_sold }}
        }

        case ITEM_VIEW.SET_HIGHLIGHT:
        if (state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(action.payload.promoteItemId)) {
            return {...state, data: {...state.data, hl_expiration: action.payload.hl_expiration }}
        }

        case ITEM_VIEW_LIKE_UPDATE.SUCCESS:
            const likeItemId = action.payload && action.payload.pk_i_id 
            const payloadActionArr = action.payload && action.payload.oc_t_user && action.payload.oc_t_user ? action.payload.oc_t_user : []
            const payloadUserId = payloadActionArr && payloadActionArr.pk_i_id ? parseInt(payloadActionArr.pk_i_id) : 0
            const stateUserId = state.data && state.data.oc_t_user && state.data.oc_t_user.pk_i_id ? parseInt(state.data.oc_t_user.pk_i_id) : 0
            console.log("reducer action", action.payload, "state data", state.data)
            const likeUpdateArr =  state.data && state.data.item_from_same_seller.map(item => {
                if (item.pk_i_id === likeItemId) {
                    return action.payload
                } else {
                    return item
                }
            })

        if (state.data && state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(likeItemId)) {
            return {...state, data: action.payload }
        }else {
            if(payloadUserId === stateUserId){
                return {...state, data: {...state.data, ['oc_t_user']: payloadActionArr, ['item_from_same_seller']: likeUpdateArr}}
            }else {
                return {...state}
            }
        }

        case ITEM_VIEW.SET_WATCHER:
            const liked_item_id = action.payload && action.payload.item_id ? action.payload.item_id : null
            const watcher = action.payload && action.payload.watcher ? action.payload.watcher : []

        if (liked_item_id && state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(liked_item_id)) {
            return {...state, data: {...state.data, watcher: watcher }}
        }

        case ITEM_VIEW_UPDATE_BY_FIELD.LOAD:
            return {...state, updateIsLoading: true, updateError: false} 

        case ITEM_VIEW_UPDATE_BY_FIELD.SUCCESS:
            const updateByFieldMessage = action.payload && action.payload.message ? action.payload.message : ''
            const updatedByFieldData = action.payload && action.payload.response ? action.payload.response : {}
            return {...state, updateIsLoading: false, updateError: false, data: updatedByFieldData, updateMessage: updateByFieldMessage} 

        case ITEM_VIEW_UPDATE_BY_FIELD.FAIL:
            return {...state, updateIsLoading: false, updateError: action.payload, updateData: {}} 

        case ITEM_VIEW.REALTIME_FIELD_UPDATE:
            const realTimeItemId = action.payload && action.payload.item && action.payload.item.item_id ? action.payload.item.item_id : null
            const realTimeFieldName = action.payload && action.payload.item && action.payload.item.field_name ? action.payload.item.field_name : null
            const realTimeFieldValue = action.payload && action.payload.item && action.payload.item.field_value ? action.payload.item.field_value : null
        if (state.data.pk_i_id && parseInt(state.data.pk_i_id) === parseInt(realTimeItemId)) {
            return {...state, data: {...state.data, [realTimeFieldName]: realTimeFieldValue }}
        }

        case ITEM_VIEW.RESET:
            return initialState

        default:
        return state;  
    }
}