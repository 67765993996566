import React, {useState, useEffect,  useRef} from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import MobileCategoryBar from '../../categoryBar/mobile'
import { connect } from 'react-redux'
import { itemReset, itemLoad, chatLoad} from '../../../../redux/action'
import {MobileBottomBarContent} from './customComponent'
import {HomeIcon, HeartIconBottomBar, SellIcon, ChatIcon, ProfileIcon, CategoryListIcon} from '../../../../assets/svg'

const { REACT_APP__BASE_URL } = process.env;

const BottomBarContent = (icon, label, goto, count) => {
  return (
    <MobileBottomBarContent icon={icon} hasAlert={label === 'Chat' ? true : false} count={count} label={label} goto={goto} textClass={`${label === 'Sell' ? `mobile-bottom-bar-sell-text-p mt-5` : `mobile-bottom-bar-text-p mt-8`} `}/>
  )
}

const MobileNavbar = (props) => {
    const session_id = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const data = props.chat && props.chat.data ? props.chat.data : []
    const [totalUnread, setTotalUnread] = useState(0)

    const [isComponentVisible, setIsComponentVisible] = useState(
        false
      );
      const ref = useRef(null);

    //   const handleHideDropdown = (event: KeyboardEvent) => {
    //     if (event.key === "Escape") {
    //       setIsComponentVisible(false);
    //     }
    //   };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };


  useEffect(() => {
    // document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
    //   document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (session_id) {
        if (data.length === 0) {
            props.chatLoad({ user_id: session_id })
        }
    }
    // eslint-disable-next-line
}, [session_id])

  useEffect(() => {
    let total = 0
    // eslint-disable-next-line 
    data.map((item) => {
        if (item && item.chat_messages.length > 0) {
            // eslint-disable-next-line 
            item.chat_messages.map(chat => {
                if (!chat.read_at && chat.sender_id !== parseInt(session_id)) {
                    total++
                }
            })
        }
    })
    setTotalUnread(total)
    // eslint-disable-next-line    
}, [props.chat])

  const location = useLocation()
  const path = location.pathname

  const clickLogoHandler = () => {
    if (path === '/') {
      props.itemReset()
      props.itemLoad({first: 40, page: 1})
    }
  }

  const IconSorter = (type) => {
    if(type === 'Home'){
      return <HomeIcon />
    }
    if(type === 'Favorites'){
      return <HeartIconBottomBar />
    }
    if(type === 'Sell'){
      return <SellIcon />
    }
    if(type === 'Chat'){
      return <ChatIcon />
    }
    if(type === 'Profile'){
      return <ProfileIcon />
    }
    if(type === 'Category'){
      return <CategoryListIcon />
    }
  }

  return (
    path.includes('paypal') ? null :
    <div className={`${path.includes('admin') || path.includes('inbox') || path.includes('new') ? `hide` : session_id ? `mobileNavbarContainer flex-align-center ` : `mobileNavbarContainer flex flex-align-center flex-justify-evenly`} `} ref={ref} >
      {
        session_id ?
        <div className="flex flex-d-row flex-align-center flex-justify-evenly w-100">
        {BottomBarContent(IconSorter('Home'), 'Home', '/user/profile')}
        {BottomBarContent(IconSorter('Favorites'), 'Fave', '/user/likes')}
        {BottomBarContent(IconSorter('Sell'), 'Sell', '/user/item/new')}
        {BottomBarContent(IconSorter('Chat'), 'Chat', '/user/messages/inbox', totalUnread)}
        {BottomBarContent(IconSorter('Profile'), 'Profile', '/user/listing')}
        </div>
        :
        <div className="flex flex-d-row flex-align-center flex-justify-evenly w-100">
         {BottomBarContent(IconSorter('Home'), 'Home', '/')}
         {BottomBarContent(IconSorter('Sell'), 'Sell', '/login')}
         {BottomBarContent(IconSorter('Category'), 'Category')}
        </div>
      }
    </div>
    );
}

const mapStateToProps = store => {
  return {
      chat: store.chatReducer,
  };
};

const mapActionToProps = {
  itemReset, 
  itemLoad,
  chatLoad
};

export default connect(mapStateToProps,mapActionToProps)(MobileNavbar);