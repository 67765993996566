import React, { useState } from 'react'
import MobileTOU from '../../TOS'
import MobilePrivacyPolicy from '../../privacypolicy'
import MobileAbout from '../../about'
import { Link } from 'react-router-dom'

const CustomComponents = ({ b, id }) => {
    const [tou, setTou] = useState(false)
    const [policy, setPolicy] = useState(false)
    const [about, setAbout] = useState(false)

    const handleTou = () => {
        setTou(!tou)
    }
    const handlePolicy = () => {
        setPolicy(!policy)
    }
    const handleAbout = () => {
        setAbout(!about)
    }
    return (
        <>
            <div style={{ paddingTop: 15 }}>
                <div style={{ borderBottom: '0.5px solid #CACACA', padding: 12 }}>
                    <p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: '20px', lineHeight: '22px', color: '#232A34' }}>About Fishingkaki</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }} >
                    {
                        id ?
                            <>
                                <div onClick={handleTou} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>Terms of use</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={handlePolicy} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>Privacy policy</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={handleAbout} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>About</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={b} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <Link to="/contact-us">Contact</Link> <i className="fa fa-chevron-right" /></div>
                            </> :
                            <>
                                <div onClick={handleTou} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>Terms of use</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={handlePolicy} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>Privacy policy</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={handleAbout} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <p>About</p> <i className="fa fa-chevron-right" /></div>
                                <div onClick={b} style={{ padding: '20px 0px', paddingRight: '18px', display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #CACACA', justifyContent: 'space-between' }}> <Link to="/contact-us">Contact</Link> <i className="fa fa-chevron-right" /></div>
                            </>
                    }

                </div>

            </div>
            {tou ? <MobileTOU query={handleTou} /> : null}
            {policy ? <MobilePrivacyPolicy query={handlePolicy} /> : null}
            {about ? <MobileAbout query={handleAbout} /> : null}
        </>
    )
}

export default CustomComponents