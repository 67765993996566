import React, {useContext} from 'react';
import { CoinContext } from "../../../index";
import { styles } from './style'
import {MoneyIcon} from '../../../../../../../assets/svg'

const First = (props) => {
    const value = useContext(CoinContext);
    const {theme, gotoSecond, services} = value
    const { isLoading, servicesData, error } = services
    return (
        <div >
            <div >
                {isLoading ? <p style={{textAlign: 'center', minHeight: 150}}>Loading...</p> : 
                  error ? <p style={{textAlign: 'center', minHeight: 150, color: 'red'}}>{error}</p> :
                  servicesData.map((item, index) => {
                        if (item.type === 'coin_bundle') {
                            return (
                                <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30}}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <MoneyIcon />
                                        <p style={{marginLeft: '8.6px', padding: 0}}>{`${item.credit} `}</p>
                                    </div>
                                    <button style={{minWidth: 87}} onClick={() => gotoSecond(item)} className="getCoins-price default-btn">
                                        <p style={{fontStyle: 'normal', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#fff'}}>{`${item.sg_price}$`}</p>
                                    </button>
                                </div>
                            )
                        }
                        else {
                            return null
                        }
                    })
                }
            </div>
        </div>
    );
}

export default First;