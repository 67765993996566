import { CATEGORY_SEARCH, CATEGORY_SEARCH_MORE, ITEM_VIEW_LIKE_UPDATE   } from '../constant'
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        pages: 1,
        total: null,  
        isLoadingMore: false,
        sameId: true,
        id: 0,
        filter : {
            sortBy: "recent",
            priceHigh: 1000000,
            priceLow: 0
        },
        sameFilter: true
    }

    export default (state = initialState, action) => {
        switch(action.type) {
            case CATEGORY_SEARCH.LOAD:
            const loadedId = action.payload.id ? action.payload.id : 0
            const { filter } = state
            return {
                ...state, 
                isLoading: true, 
                error: null, 
                sameId : state.id === 0 ? true : state.id === loadedId ? true : false,
                id: loadedId,
            }

            case CATEGORY_SEARCH.SUCCESS:
            const { payload } = action
            const data = payload && payload.data  ? payload.data : []
            const total = payload && payload.count  ? payload.count : null

            return {
                ...state, 
                isLoading: false, 
                isLoadingMore: false, 
                total: total,
                data: data, 
                error: null, 
                pages: state.pages+1

            }

            case CATEGORY_SEARCH.FAIL:
                return {...state, isLoading:false, isLoadingMore: false, error: action.payload}

            case CATEGORY_SEARCH_MORE.LOAD:
                return {
                    ...state, 
                    isLoadingMore: true
                }

            case CATEGORY_SEARCH_MORE.SUCCESS:
                const moreData = action.payload && action.payload.data  ? action.payload.data : []
                const moreTotal = action.payload && action.payload.count  ? action.payload.count : null  
                return {
                    ...state, 
                    isLoading: false, 
                    isLoadingMore: false, 
                    total: moreTotal,
                    data: state.data.concat(moreData), 
                    error: null, 
                    pages: state.pages+1
                }
            
            case ITEM_VIEW_LIKE_UPDATE.SUCCESS:
            const updatedItemId = action.payload && action.payload.pk_i_id ? action.payload.pk_i_id : 0
            const findIdOnData = state.data && state.data.length !== 0 ? state.data.filter(item => parseInt(item.pk_i_id) === parseInt(updatedItemId)) : []
            const indexLike = state.data && state.data.length !== 0 ? state.data.findIndex(item => parseInt(item.pk_i_id) === parseInt(updatedItemId) ) : 0

            if(findIdOnData.length !== 0){
                state.data[indexLike] = action.payload
                return {...state}
            }else {
                return {...state}
            }

            case CATEGORY_SEARCH.RESET:
                return initialState
            
            default:
            return state;
        }
    }