import React, { useReducer } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { reviewLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'

const LeaveReview = (props) => {
    const { isLoading } = props.review
    const reviewer_id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const location = useLocation()
    const id = location.pathname.replace('/user/', '').replace('/reviews/leave', '')
    const [userReview, setUserReview] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            review: '',
            seller_id: id,
            reviewer: reviewer_id
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserReview({ [name]: newValue })
    }
    const handleSubmit = evt => {
        evt.preventDefault();
        props.reviewLoad(userReview)
    }
    return (
        <div style={{ display: 'flex', padding: '0px 18rem' }}>
            {
                isLoading ?
                    <Loader />
                    :
                    <form onSubmit={handleSubmit} >
                        <div style={{ width: '100%' }}>
                            <textarea style={{ fontFamily: 'Arial', width: '500px', height: '150px', padding: '10px', fontSize: '15px' }} name={'review'} value={userReview.review} onChange={handleChange} required />
                        </div>
                        <input type="submit" value="Submit Review" style={{ padding: '5px 10px', background: '#FB770D', color: '#ecf0f1', height: '50px', fontSize: '15px' }} />
                    </form>
            }
        </div>
    );
}

const mapStateToProps = store => {
    return {
        review: store.reviewReducer
    }
}

const mapActionToProps = {
    reviewLoad
}

export default connect(mapStateToProps, mapActionToProps)(LeaveReview)