import React, { useContext, useEffect, useState, useRef } from 'react';
import {FkPrimeContext} from '../../../../common/context'
import {getPaypalSubscriptionIdByAmount, resetPaypal} from '../../../../common/helper/utility'
import {CustomLoader} from '../../../../common/component/loader'
import {PaymentSuccess} from '../../../../common/component/customComponents'

export const PaypalUpgradeButton = () => {
    const value = useContext(FkPrimeContext);
    const {subscriptionUpgradeData, has_active_subscription, handleUpdateSubscription, handleUpgradeError, isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError} = value
    const sg_price = subscriptionUpgradeData && subscriptionUpgradeData.sg_price ? subscriptionUpgradeData.sg_price : 0
    const plan_id = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()
    const subscription_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.id ? has_active_subscription.purchase_micro_service.id : null
    const transaction_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const payment_method = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.payment_method ? has_active_subscription.purchase_micro_service.payment_method : null

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                window.paypal.Buttons({
                    style: {
                        label: 'paypal',
                        width: '50%'
                    },
                    createSubscription: function(data, actions) {
                        return actions.subscription.revise(subscription_id, {
                            plan_id: plan_id
                        });
                    },
                    onApprove: async (data, actions) => {
                        console.log('onApprove data', data)
                        const service_id = subscriptionUpgradeData && subscriptionUpgradeData.id ? parseInt(subscriptionUpgradeData.id) : null
                        handleUpdateSubscription({id, transaction_id, payment_method, plan_id, service_id})
                    },
                    onError: async (e) => {
                        console.log('onError e', e)
                        handleUpgradeError()
                    }
                }).render(paypalRef);
            }, 1000)
        }
    }
 
    return (
        isLoadingUpdateSubscription ? <CustomLoader /> :
        updateSubscriptionMessage ? <PaymentSuccess text={updateSubscriptionMessage} /> :
        updateSubscriptionError ? <p className='color-danger text-align-center'>{updateSubscriptionError}</p> : 
        <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
            <div ref={v => (paypalRef = v)}></div>
        </div>

    );
}