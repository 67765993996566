import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getCategories = (payload) => {
    const { page } = payload
    try {
        const query = gql `query getAllCategory($first: Int, $page: Int, $orderField: String, $orderType: SortOrder
          ){
            getAllCategory(
              first: $first, page: $page, orderField: $orderField,
              orderType: $orderType
            ){
              count
              category{
                  pk_i_id
                  i_position
                  b_enabled
                  fk_i_parent_id
                  oc_t_parent_category_descriptions{
                    s_name
                    fk_i_category_id
                  }
                  oc_t_category_descriptions{
                    s_name
                    fk_i_category_id
                  }
              }
      
            }
      }`;
        const response = client.query({
            query: query,
            variables: {
              first: 500,
              page: page,
              orderField: "i_position",
              orderType: "ASC"
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
  }

  export const upsertCategory = (payload) => {
    const { pk_i_id, b_enabled, fk_i_parent_id, oc_t_category_descriptions } = payload
  
    let variables
    let gql_query
    
    //if pk_i_id update else insert
    if (pk_i_id) {
      gql_query = gql`mutation categoryUpdate($data: CategoryUpdateInput
        ){
          categoryUpdate(data: $data) {
            message
            category{
              pk_i_id
              fk_i_parent_id
              oc_t_parent_category_descriptions{
                fk_i_category_id
                s_name
              }
              b_enabled
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
          }
        }`
      variables = {data: {"pk_i_id": parseInt(pk_i_id), "b_enabled": b_enabled, "fk_i_parent_id": parseInt(fk_i_parent_id), 
        category_description: oc_t_category_descriptions, type: 'UPDATE'}}       
    } 
    else {
      gql_query = gql`mutation categoryInsert($data: CategoryInsertInput
        ){
          categoryInsert(data: $data) {
            message
            category{
              pk_i_id
              i_position
              fk_i_parent_id
              oc_t_parent_category_descriptions{
                fk_i_category_id
                s_name
              }
              b_enabled
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
          }
        }`;
  
      variables = {data: { b_enabled: true, fk_i_parent_id: parseInt(fk_i_parent_id), category_description: oc_t_category_descriptions, type: "INSERT"}}
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  export default {
    getCategories,
    upsertCategory
  }