import React from 'react'
import{ NavLink, useLocation, Link} from 'react-router-dom'
import {theme} from '../../../../common/theme'

const DesktopContainer = (data, user, props, user_id) => {
    const test = parseInt(user)
    return (
            isNaN(test) === true ?
            <div className="w-100 flex flex-d-row flex-align-end flex-justify-between">
                <div className="flex flex-d-row flex-align-center">
                    {
                        data.map((item, index) => {
                            const path = item.path ? item.path : ''
                        return (
                            <div className={`links-container`} key={index}>
                                <NavLink to={path} className={`profile-links`} activeStyle={style.active} props={props}>{item.label}</NavLink>
                            </div>
                        )
                        })
                    }
                </div>
                    <Link to={`/user/settings`} className="edit-profile-btn">
                        <p>{`Edit Profile`}</p>
                    </Link>
            </div>
            
            :
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                <NavLink to={`/user/${user_id}/listing`} style={style.default} activeStyle={style.active}>Listings</NavLink>
                <NavLink to={`/user/${user_id}/reviews`} style={style.default} activeStyle={style.active}>Reviews</NavLink>
            </div>
    )
}

const MobileContainer = (data, user, props, user_id) => {
    const test = parseInt(user)
    return (
        isNaN(test) === true ?
        data.map((item, index) => {
            const path = item.path ? item.path : ''
           return (
            <div className={`links-container`} key={index}>
        <NavLink to={path} className={`profile-links`} activeStyle={style.active} props={props}>{item.label}</NavLink>
                </div>
           )
        })
        :
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
            <NavLink to={`/user/${user_id}/listing`} style={style.default} activeStyle={style.active}>Listings</NavLink>
            <NavLink to={`/user/${user_id}/reviews`} style={style.default} activeStyle={style.active}>Reviews</NavLink>
        </div>
    )
}

const ProfileNavLink = props => {
    const data = theme.text.profileOptions
    const mobileData = theme.text.mobileProfileOptions
    const location = useLocation()
    const pathname =  location.pathname
    const user = pathname.slice('6')
    const test = parseInt(user)
    const user_id = user.replace('/listing', '').replace('/reviews', '').replace('/leave', '')
    return (
        <div className="profileLink-container">
            <div className="profile-sub-container">
                {
                    window.innerWidth < 600 ? MobileContainer(mobileData, user, props, user_id) :
                    DesktopContainer(data, user, props, user_id)
                }
            </div>
        </div>
    )
}

const style = {
    links: {
      margin: 'auto'
    },
    default: {
        color: "#bdc3c7",
        textDecoration: 'none',
        padding: 5,
        fontFamily: theme.font.primary,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'left',
        whiteSpace: 'nowrap',
        alignItems: 'center'
    },
    active: {
        color: '#FB770D',
        border: '4px solid transparent',
        borderBottom: '4px solid #FB770D',
    }
    
}

export default ProfileNavLink