import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getSubscription = (payload) => {
    const { first, page, type } = payload
    try {
        const query = gql `query getSubscriptions($first: Int, $page: Int, $type: String, $orderField: String,
          $orderType: SortOrder
          ){
            getSubscriptions(first: $first, page: $page, type: $type,
              orderField: $orderField, orderType: $orderType
            ){
                count
                subscription {
                  transaction_id
                  id
                  type
                  name
                  user_id
                  oc_t_user {
                      pk_i_id
                      s_username
                  }
                  subscription_status
                  purchase_date
                  payment_method
                  due_date
                }
            }
          }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, type: type, orderField: 'purchase_date', orderType: 'DESC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }

  export const getSubscriptionDetail = (id) => {
    try{
      const query = gql`query getSubscriptionDetail($id: ID!)
      {getSubscriptionDetail(id: $id){
            transaction_id
            id
            type
            name
            user_id
            oc_t_user {
                pk_i_id
                s_username
            }
            subscription_status
            purchase_date
            payment_method
            due_date
          }
        }`;
  
      const response = client.query({
        query: query,
        variables: {
          id: id
        }
      });
  
      const data = response ? response : null;
      return data;
    } catch(e) {
      throw e
    }
  }

  export const updateSubscription = (data) => {
    const {id, due_date} = data
    try{
      const query = gql`mutation adminSubscriptionUpdate($id: ID!, $due_date: String)
      {adminSubscriptionUpdate(id: $id, due_date: $due_date){
            message
          }
      }`;
  
      const response = client.mutate({
        mutation: query,
        variables: {id: parseInt(id), due_date: due_date}
      });
  
      const data = response ? response : null;
      return data;
    } catch(e) {
      throw e
    }
  }

  export const subscriptionSearch = (data) => {
    const {search, orderField, orderType, first } = data 
    try {
        const query = gql`query subscriptionSearch($search: String, $first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            subscriptionSearch(first: $first, page: $page,
              orderField: $orderField, orderType: $orderType, search: $search
            ){
              count
              subscription {
                transaction_id
                id
                type
                name
                user_id
                oc_t_user {
                    pk_i_id
                    s_username
                }
                subscription_status
                purchase_date
                payment_method
                due_date
              }
            }
          }`;
  
        const inputs = {
          search: search,
          orderField: orderField,
          orderType: orderType,
          first: parseInt(first)
        }
          
        const response = client.query({
            query: query,
            variables: inputs
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }
  
export default {
    getSubscription,
    getSubscriptionDetail,
    updateSubscription,
    subscriptionSearch
}