import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Gate from './Gate';

const Authenticated = (props) => {
  const {session, layout, component, location: { pathname }, ...rest } = props;
  const isAuthenticated = props && props.session && props.session.fkv2_session && props.session.fkv2_session.type && props.session.fkv2_session.type === 'user' ? true : false

  if (isAuthenticated) {
    return ( 
          <Gate
              {...rest}
              component={component}
              Layout={layout}
              pathname={pathname}
              session={session}
          />
    );
  } else {
  return  <Redirect to='/' />
  }
}

Authenticated.propTypes = {
  layout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default Authenticated;
