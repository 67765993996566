import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const getInput = (email, password) => {
    try {
        const query = gql`mutation userLogin($input: LoginInput){
            userLogin(input: $input){
              access_token
              oc_t_user{
                s_name
                s_username
                s_email
                b_enabled
                b_active
                wallet
                pk_i_id
              }
            }
          }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: {"username": email, "password": password}},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const newUserInput = async (name, username, password, email, repassword, s_ip) => {
    try {
        const query = gql`mutation userRegister($input: UserRegisterInput){
            userRegister(data: $input){
                   message
            }}`;
        return await client.mutate({
            mutation: query,
            variables: {"input": {"name": name, "username": username, "email": email, "password": password, "password_confirmation":  repassword, "s_access_ip": s_ip }},
        });
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }

}

export const verifyEmail = (email) => {
    try {
        const query = gql`mutation verifyUserEmail($data: VerifyEmailInput){
            verifyUserEmail(data: $data){
              message
        }}`;
        const response = client.mutate({
            mutation: query,
            variables: {"data": { "email": email } }
        });

        const data = response ? response : null;
        return data;
        
    } catch (e) {
        console.log('e',JSON.stringify(e))
        throw e
    }
}

export const resendEmailVerification = (email) => {
    try {
        const query = gql`mutation resendEmailVerification($data: VerifyEmailInput){
            resendEmailVerification(data: $data){
              message
        }}`;
        const response = client.mutate({
            mutation: query,
            variables: {"data": { "email": email } }
        });

        const data = response ? response : null;
        return data;
        
    } catch (e) {
        console.log('e',JSON.stringify(e))
        throw e
    }
}

export const socialLogin = (data) => {
    const name = data && data.name || ''
    const email = data && data.email || ''
    const id = data && data.id ? data.id.substring(0, 15) : 0
    const type = data && data.graphDomain || ''
 
    try {
        const query = gql`mutation userSocialLogin($input: FkSocialLoginInput){
            userSocialLogin(input: $input) {
              access_token
              oc_t_user{
                pk_i_id
                s_email
                social{
                  provider
                  provider_name
                  provider_email
                  data
                }
              }
            }
        }`;
          
        const response = client.mutate({
            mutation: query,
            variables: {
                "input": {
                    "name": name,
                    "email": email,
                    "id": parseInt(id),
                    "type": type,
                    "data": data
                  }
            }
        });
        const result = response ? response : null;
        return result;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const userLogout = () => {
    try {
        const query = gql`mutation userLogout{
            userLogout{
              message
            }
          }`;
        const response = client.mutate({
            mutation: query,
        });
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const getLoginAsUserToken = (payload) => {
    console.log('getLoginAsUserToken gql payload', payload)
    const { email } = payload
    try {
        const query = gql`mutation getLoginAsUserToken($input: LoginAsUserInput!){
            getLoginAsUserToken(input: $input){
               access_token
               oc_t_user{
                s_name
                s_username
                s_email
                b_enabled
                b_active
                wallet
                pk_i_id
               }
              
            }
          }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: {"s_email": email}},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export default {
    getInput, 
    newUserInput,
    verifyEmail,
    socialLogin,
    userLogout,
    getLoginAsUserToken,
    resendEmailVerification
};