import React from 'react';
import { theme } from '../../../theme'

const mainFoot = () => {
    return (
        <div className="mainFooter">
            <div className="sinceYear">
                <p className="since-year-label-p no-select">{`Copyright ${(new Date().getFullYear())} ${theme.text.brand}.com`}</p>
            </div>
        </div>
    );
};

// export const style = {
//     display: 'flex',
//     backgroundColor:  theme.color.primaryBg, 
//     justifyContent: 'space-between', 
//     alignItems: 'center',
//     bottom: '0'
// }

// const styles = {
//     link: {
//         color: theme.color.secondaryColor,
//         textDecoration: 'none',
//         paddingRight: '20px',
//         fontFamily: theme.font.primary,
//         fontSize: '15px'
//     },
//     brand: {
//         fontSize: '17px',
//         color: theme.color.secondaryColor,
//         fontFamily: theme.font.primary
//     },


// }

export default mainFoot