import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

const MobileAbout = ({data, query}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const datas = data.about
    const termsBody = (p) => {
        let body
        body = <p>{p}</p>
        return body
    }
    return (
        <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex: '4', background: '#FFFFFF', overflow: 'auto'}}>
         <div className="content-sub-container">
             <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                 <div ></div>
             <h1 className="content-main-title">{`About Fishingkaki`}</h1>
            { query ? <div onClick={query}>
                <p>{`Close`}</p>
            </div> :
                <Link to={'/'}> <p>{`Close`}</p>
                </Link>    
            }
             </div>
             
         </div>
         <div className="content-body">
             <h1 className="content-last-update">{`ABOUT US`}</h1>
             {
                 datas.map((terms, index) => {
                     const content = terms.text
                     return <div key={index}>
                         {termsBody(content)}
                         </div>
                 })
             }
         </div>
        </div>
    )
}

export default MobileAbout