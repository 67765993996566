import { ITEM, REPORT } from '../constant'

export const itemLoad = (payload) =>{
    return {
        type: ITEM.LOAD,
        payload
    }
}    

export const itemSuccess = (payload) =>{
    return {
        type: ITEM.SUCCESS,
        payload,
    }
}

export const itemLoadMore = (payload) => {
    return {
        type: ITEM.LOAD_MORE,
        payload
    }
}

export const itemLoadMoreSuccess = (payload) => {
    return {
        type: ITEM.LOAD_MORE_SUCCESS,
        payload
    }
}


export const itemFailed = (payload) =>{
    return {
        type: ITEM.FAILED,
        payload,
    }
}

export const itemReset = () =>{
    return {
        type: ITEM.RESET,
    }
}

export const homeItemSetReserve = (payload) =>{
    return {
        type: ITEM.SET_RESERVE,
        payload,
    }
}

export const homeItemSetSold = (payload) =>{
    return {
        type: ITEM.SET_SOLD,
        payload,
    }
} 

export const homeItemSetHighlight = (payload) =>{
    return {
        type: ITEM.SET_HIGHLIGHT,
        payload,
    }
} 

export const homeItemSetTopAd = (payload) =>{
    return {
        type: ITEM.SET_TOP_AD,
        payload,
    }
} 

export const homeItemSetWatcher = (payload) =>{
    return {
        type: ITEM.SET_WATCHER,
        payload,
    }
} 

export const reportLoad = (payload) =>{
    return {
        type: REPORT.LOAD,
        payload
    }
}    

export const reportSuccess = (payload) =>{
    return {
        type: REPORT.SUCCESS,
        payload,
    }
}

export const reportFailed = (payload) =>{
    return {
        type: REPORT.FAIL,
        payload,
    }
}

export const reportReset = () =>{
    return {
        type: REPORT.RESET,
    }
}







