import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getSales = (payload) => {
    const { first, page } = payload
    try {
        const query = gql `query getAllPayment($first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            getAllPayment(first: $first, page: $page,
              orderField: $orderField, orderType: $orderType
            ){
                count
                sales {
                  id
                  transaction_id
                  oc_t_user{
                    pk_i_id
                    s_username
                    s_email
                  }
                  payment_method
                  type
                  total_purchase
                  before_credit
                  current_credit
                  created_at
                  service{
                    description
                  }
                  status
                  oc_t_item{
                    pk_i_id
                    oc_t_item_description{
                      s_title
                    }
                  }
                }
            }
          }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderField: 'created_at', orderType: 'DESC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }
  
  export const getSaleDetail = (id) => {
    try{
      const query = gql`query getPayMentDetail($payment_id: Int!)
      {getPayMentDetail(payment_id: $payment_id){
                transaction_id
                oc_t_user{
                  s_username
                }
                payment_method
                type
                total_purchase
                before_credit
                current_credit
                created_at
                service{
                  description
                }
                status
                oc_t_item{
                  oc_t_item_description{
                    s_title
                  }
                }
                id
          }
        }`;
  
      const response = client.query({
        query: query,
        variables: {
          payment_id: id
        }
      });
  
      const data = response ? response : null;
      return data;
    } catch(e) {
      throw e
    }
  }
  
  export const searchSaleForSuggest = (data) => {
    const {search, orderField, orderType } = data 
    try {
        const query = gql`query searchSale($search: String, $first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            searchSale(first: $first, page: $page,
              orderField: $orderField, orderType: $orderType, search: $search
            ){
                count
                sales {
                  transaction_id
                  oc_t_user{
                    s_username
                  }
                  payment_method
                  type
                  total_purchase
                  before_credit
                  current_credit
                  created_at
                  service{
                    description
                  }
                  status
                  oc_t_item{
                    oc_t_item_description{
                      s_title
                    }
                  }
                  id
                }
            }
          }`;
  
        const inputs = {
          search: search,
          orderField: orderField,
          orderType: orderType
        }
          
        const response = client.query({
            query: query,
            variables: inputs
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }

export default {
  getSales,
  getSaleDetail,
  searchSaleForSuggest
}