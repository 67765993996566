import React from 'react';
import {useLocation} from 'react-router-dom'
import SubFooter from './sub';
import Foot from './main';

const Footer = (props) => {
    const type = props && props.session && props.session.fkv2_session && props.session.fkv2_session.type ? props.session.fkv2_session.type : false
    const location = useLocation()
    const pathname = location.pathname

    return (
        pathname.includes("messages") || pathname.includes("paypal") ? null :
        <div className="foot">
            <SubFooter type={type}/>
            <Foot />
        </div>
    );
};

export default Footer;



