import React from 'react';

const Loader = (props) => {
    return null
    // const loaderHW = props.hw ? props.hw : {}

    // return (
    //     <div className="flex flex-justify-center flex-align-center w-100 align-self-center h-px-300">
    //         <div className="loader" style={loaderHW}></div>
    //     </div>
    // );
};

export default Loader;

export const OverlayLoader = (props) => {
    return null
    // const loaderHW = props.hw ? props.hw : {}
    // return (
    //     <div className='loader-overlay'>
    //         <div className="loader-container">
    //             <div className="loader" style={loaderHW}></div>
    //         </div>
    //     </div>
    // );
};

export const CustomLoader = () => {
    return null
    // return (
    //     <div className="w-100 h-100 flex flex-justify-center flex-align-center">
    //         <div className="cssload-loader">classifieds.fishingkaki.com</div>
    //     </div>
       
    // )
}

export const YTLoader = () => {
    return (
        <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
        </div>
    )
}

export const RippleLoader = () => {
    return (
        <div class="lds-ripple"><div></div><div></div></div>
    )
}

export const RollerLoader = () => {
    return (
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}