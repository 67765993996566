import React, { useEffect, useState, useReducer, useContext, memo} from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import { theme } from '../../../common/theme'
import { itemLoad, itemLoadMore, servicesLoad, promoteLoad, promoteReset, watchlistLikeDislikeLoad, itemReset, adminUserSuggestLoad, reportLoad, reportReset, homeItemSetReserve, homeItemSetSold, servicePromoteLoad, servicePromoteReset, sessionLoad, searchWordLoad, categorySearchLoad} from '../../../redux/action'
import Loader from '../../../common/component/loader'
// import { OverlayLoader } from '../../../common/component/loader'
import {isDue, isEmpty, getLocalStorage} from '../../../common/helper/utility'
import {WholePageLoader} from '../../../common/component/customComponents'
import {RouteToDownloadPageBtn} from '../../../common/component/buttons'
import ItemCard from './component/ItemCard'
import { PromoteContext, GlobalContext, HomeContext} from '../../../common/context'
import PromoteModal from './component/promote'
import { ReportModal } from './component/CustomComponent'
import Modal from '../../../common/component/modal'
import DefaultMeta from '../../../common/component/defaultMeta'
import Logo from '../../../assets/images/fk_logo.png'
import { GApageView } from '../../../common/googleAnalytics'
import GoogleAds from '../../../common/component/googleAds'
import { useLocation } from 'react-router-dom';
import {DownloadTheAppAd} from '../../../common/component/ad'
import Recommended from './component/recommended';
import TopSearches from './component/topSearches';

const Home = (props) => {
  const globalValue = useContext(GlobalContext)
  const {isShowAd} = globalValue
  const data = props.item && props.item.data  ? props.item.data : []
  const loading = props.item && props.item.isLoading
  const loadingMore = props.item && props.item.isLoadingMore
  const session = props.session
  const userId = props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
  const hasMore = props.item && props.item.paginatorInfo && props.item.paginatorInfo.hasMorePages  ? props.item.paginatorInfo.hasMorePages : false
  const likeDislikeLoading = props.watchlist && props.watchlist.isLoading ? props.watchlist.isLoading : false
  const {  isLoading, servicesData, error,isLoadingPromote, promoteSuccessMessage, errorPromote } = props.purchase
  const { sessionData } = props.me
  const bumpData = props.item && props.item.bumpData  ? props.item.bumpData : []
  const recommendedForYouArr = props.item && props.item.recommendedForYou ? props.item.recommendedForYou : []
  const saveLocalSearchKey = getLocalStorage('fkv2_search_key')
  const getKey = saveLocalSearchKey && saveLocalSearchKey.key ? saveLocalSearchKey.key : ''
  const categoryId = saveLocalSearchKey && saveLocalSearchKey.categoryId ? saveLocalSearchKey.categoryId : 0
  // const meData = props.me && props.me.sessionData ? props.me.sessionData : []
  const subscription_status = sessionData && sessionData.database_subscription_status ? sessionData.database_subscription_status : []
  const TopSearchesArr = props.item && props.item.topSearches ? props.item.topSearches : []
  const {isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError,
    isLoadingCancelSubscription, cancelSubscriptionMessage, cancelSubscriptionError,
    isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError
} = props.microService

  //promote context
  const [promoteShow, setPromoteShow] = useState(false)
  const [soldPromoteModal, setSoldPromoteModal] = useState(false)
  const stepLength = 2
  const [step, setStep] = useState(1)
  const [itemForPromote, setItemForPromote] = useState({})
  const [paymentData, setPaymentData] = useState({})
  const [currentCoin, setCurrentCoin] = useState(0)
  const [subscription, setSubscription] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
        fkPrime: [],
        individual: []
    }
   )
 
   const handleUseNowBtn = (boost, item_id) => {
    const micros_service_id = boost && boost.id ? parseInt(boost.id) : null
    const service_id = boost && boost.service_detail && boost.service_detail.id ? parseInt(boost.service_detail.id) : null
    props.servicePromoteLoad({micros_service_id: micros_service_id, item_id: parseInt(item_id), service_id: service_id})

 }

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      GApageView("home"); 
    }, 1000)
  }, [])

  useEffect(() => {
    if(useNowPromoteMessage || useNowPromoteError){
      setTimeout(() =>{
        props.servicePromoteReset()
        window.location.reload(false)
      }, 2000)
    }
}, [useNowPromoteMessage, useNowPromoteError])

  useEffect(() => {
    if(isEmpty(sessionData)){
      if(userId){
        props.sessionLoad({id: userId})
      }
    }
  }, [sessionData])

  const togglePromoteShow = (item) => {
      setItemForPromote(item)
      setPromoteShow(!promoteShow)
  }

  const toggleSoldPromoteModal = () => {
    setSoldPromoteModal(!soldPromoteModal)
  }

  const resetModal = () => {
    setStep(1)
    setItemForPromote({})
    setPaymentData({})
    setPromoteShow(false)
  }

  const handleNext = () => {
      if (step !== stepLength || step < stepLength) {
          setStep(step + 1)
      }
  }

  const handleBack = () => {
      if (step > 1) {
          setStep(step - 1)
      }
  }

  const getServices = (active) => {
    if (servicesData.length === 0) {
      props.servicesLoad(active)
    }
  }

  const submitPromote = (data) => {
    props.promoteLoad({fkv2_purchased_data: data, userId: userId})
  }
  //end promote context

  useEffect(() => {
    if(errorPromote){
        setTimeout(() => {
            props.promoteReset()
            window.location.reload(false)
        }, 3000)
    }
    if(promoteSuccessMessage){
        setTimeout(() => {
            props.promoteReset()
            props.itemReset()
            togglePromoteShow()
            resetModal()
            window.location.reload(false)
        }, 3000)
    }
// eslint-disable-next-line
}, [errorPromote, promoteSuccessMessage])

const statusLabelParser = (type, item_id, due_date) => {
  let status
  if (type === 'cover_photo' || type === 'youtube_link') {
      if (new Date(due_date) > new Date()) {
          status = 'Active'
      } else {
          status = 'Not Active'
      }
  } else {
      if (!item_id) {
          status = 'Use Now'
      } else {
          status = 'Used'
      }
  }
  return status
}

const sortArr = (arr) => {
  let fkPrimeArr = []
  let youtube = []
  let cover = []
  let listing = []
  let top_ad = []
  let highlight = []
  let auto_bump = []
  let individual_arr = []
  let useNow = []
  let used = []
  let active = []
  let notActive = []
  let free = []
  let combo = []
  arr.map((p) => {
      if(p.type === 'fk_prime'){
          fkPrimeArr.push({...p, status: subscription_status})
      }
      else {
          const isActiveByDueDate = isDue(p.due_date)
          if (isActiveByDueDate) {
              individual_arr.push(p)
              if(p.type === 'youtube_link'){
                  youtube.push(p)
              }
              if(p.type === 'cover_photo'){
                  cover.push(p)
              }
              if(p.type === 'live_listing'){
                  listing.push(p)
              }
              if(p.type === 'top_ad'){
                  top_ad.push(p)
              }
              if(p.type === 'combo'){
                combo.push(p)
              }
              if(p.type === 'auto_bump'){
                  auto_bump.push(p)
              }
              if(p.type === 'highlight'){
                  highlight.push(p)
              } 
              if (p.type !== 'live_listing') {
                  const label = statusLabelParser(p.type, p.item_id, p.due_date)
                  if (label === 'Use Now') {
                      useNow.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Used') {
                      used.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Active') {
                      active.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Not Active') {
                      notActive.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } 
              }
              if(p.type === 'live_listing' && p.payment_method.includes('free')){
                  free.push(p)
              }
          }
      }
  })
  return setSubscription({['fkPrime']: fkPrimeArr.reverse(), individual: {
      ['useNow']: useNow, ['used']: used, ['active']: active, ['notActive']: notActive,
      ['youtube_link']: youtube, ['cover_photo']: cover, ['live_listing']: listing, ['top_ad']: top_ad, ['highlighted_ad']: highlight, ['auto_bump']: auto_bump,
      ['free_listing']: free, ['combo']: combo
  }})
 }

  useEffect(() => {
    if(props.me.sessionData && props.me.sessionData.purchase_micro_service){
      sortArr(sessionData.purchase_micro_service)
    }
  }, [props.me.sessionData.purchase_micro_service])

  useEffect(() => {
    if(data && data.length === 0){
      props.itemLoad({first: 40, page: props.item.page, key: getKey, categoryId: categoryId, user_id: userId})
    }
    // else if(getKey){
    //   props.itemLoad({key: getKey, categoryId: categoryId, user_id: userId})
    // }

  }, [props.item.reset])

  useEffect(() => {
    if(sessionData.wallet !== 0){
      setCurrentCoin(sessionData.wallet)
    }
    return () => setCurrentCoin(0)
  // eslint-disable-next-line
  }, [sessionData])

  const showMore = () => {
    props.itemLoadMore({first: 20, page: props.item.page})
  }

  const likeDislike = (item_id) => {
    props.watchlistLikeDislikeLoad({userId: userId, itemId: item_id})
  }

  //report handler
  const { isLoadingReport, reportMessage, reportError } = props.item
  const [reportShow, setReportShow] = useState(false)
  const [reportInput, setReportInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      reporter_id: userId ? userId : null,
      reported_id: null,
      reported_user_id: null,
      report_reason: null,
      report_type: 'Item'
    }
  );

  const handleReportSubmit = () => {
    props.reportLoad(reportInput)
  }

  useEffect(() => {
    if (reportMessage || reportError) {
      setTimeout(() => {
        setReportInput({
          reporter_id: userId ? userId : null,
          reported_id: null,
          reported_user_id: null,
          report_reason: null,
          report_type: 'Item'
        })
        setReportShow(false)
        props.reportReset()
      }, 2000)
    }
  // eslint-disable-next-line
  }, [reportMessage, reportError])

  const insertAds = (index) => {
    const actualIndex = index + 1
    const counter = window.innerWidth < 600 ? 10 : 20
    let element = null
    if (actualIndex % counter === 0) {
        element = 
        <div className={window.innerWidth < 600 ? 'grid-insert-1-3' : 'grid-insert-1-5'}>
          <GoogleAds />
        </div>
    }
    return element
  }

  const location = useLocation()
  const path = location.pathname

  const clickLogoHandler = () => {
    if (path === '/') {
        props.itemReset()
        props.itemLoad({first: 40, page: 1})
    }
  }

  return (
    <PromoteContext.Provider value={{
      promoteShow, togglePromoteShow, getServices, step, handleNext, handleBack, toggleSoldPromoteModal,
      isLoading, servicesData, error,
      itemForPromote, setItemForPromote,
      paymentData, setPaymentData,
      isLoadingPromote, promoteSuccessMessage, errorPromote, submitPromote,
      resetModal, currentCoin,
      reportShow, setReportShow, handleReportSubmit, setReportInput, reportInput,
      isLoadingReport, reportMessage, reportError, sessionData, subscription, handleUseNowBtn, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError
    }}>
      <HomeContext.Provider value={{
         likeDislike, session, likeDislikeLoading, recommendedForYouArr, loading, TopSearchesArr

      }}>
      {/* <DefaultMeta />     */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', position: 'relative', top: window.innerWidth < 600 ? '85px' : '130px'}}>
      <div className='desktop-icons w-100'>
          <DownloadTheAppAd />
        </div>
      <div className={`body-container`}>
      {/* <RouteToDownloadPageBtn /> */}
      {
      // window.innerWidth < 600 ? 
      //     <div style={{background: 'rgb(35, 42, 52)', width: '100%', marginBottom: 10}} className="mobile-logo-container" onClick={() => clickLogoHandler()}>
      //       <img src={Logo} alt="fk logo" width={200}/>
      //     </div>: null
      }
      {
        TopSearchesArr.length === 0 ? null : 
        <>
          {/* <div className={`w-100 flex flex-d-column flex-justify-center flex-align-center ${isShowAd ? `mt-30` : ``}`}>
            <GoogleAds position='top'/>
          </div> */}
          <div className={`w-100 flex flex-d-column flex-justify-center flex-align-center ${isShowAd ? window.innerWidth < 600 ? `mt-95` : `` : ``}`}>
            <TopSearches/>
          </div>
        </> 
      }
      {
        userId && !isEmpty(saveLocalSearchKey) ?
        parseInt(saveLocalSearchKey.sessionUserId) === parseInt(userId) ?
        <>
          <div className={`w-100 flex flex-d-column flex-justify-center flex-align-center ${isShowAd ? `mt-30` : ``}`}>
            <GoogleAds position='top'/>
          </div>
          <Recommended/>
        </> : null : null
      }
      <div className={`w-100 flex flex-d-column flex-justify-center flex-align-center ${isShowAd ? `mt-30` : ``}`}>
        <GoogleAds position='top'/>
      </div>
        <div style={{maxWidth: 990, display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%'}}>
       {/* { */}
        {loading ? null : <p className="home-title-label-p">{`Latest ads`}</p>}   
          {
            <div style={style.itemContainer} className="home-item-container">
              {
                  data.map((item, index) => {
                    const hl_expiration = item.hl_expiration
                    const ta_expiration = item.ta_expiration
                    const ab_expiration = item.ab_expiration
                    return (
                      <React.Fragment key={index}>
                        <ItemCard key={index} item={item} ab_expiration={ab_expiration} hl_expiration={hl_expiration} ta_expiration={ta_expiration} session={session} likeDislike={likeDislike} likeDislikeLoading={likeDislikeLoading}/>
                        {insertAds(index)}
                      </React.Fragment>
                    ) 
                  })
              }
            </div>
          }
          <div className='more-btn-division'>
            { window.outerWidth > 600 ?
                        loadingMore ? <Loader />
                        : hasMore ? 
                        loading ? null :
                        <button onClick={showMore} className="showMoreBtn">Load More </button>
                        : null :
                      loading ? null :
                <InfiniteScroll dataLength={data.length} next={showMore} hasMore={true} loader={<h4>Loading...</h4>} endMessage={
                <p style={{textAlign: 'center'}}>
                  <b>End of result</b>
                </p>
              }/>
            }
          </div>
          </div>
      </div>
      </div>
      
      { promoteShow ? <PromoteModal /> : null}
      { reportShow ? <ReportModal /> : null}
      { soldPromoteModal ? <Modal close={toggleSoldPromoteModal} /> : null}
      { loading ? <WholePageLoader /> : null }
      </HomeContext.Provider>
    </PromoteContext.Provider>
  );
};

const style = {
  itemContainer: {
    display: 'grid',
    // fontFamily: theme.font.primary,
    width: '100%'
  },
}

const mapStateToProps = store => {
  return {
    item: store.itemReducer,
    user: store.authReducer,
    purchase: store.purchaseReducer,
    watchlist: store.watchlistReducer,
    admin: store.adminReducer,
    microService: store.microServiceReducer,
    me: store.userReducer,
    search: store.searchReducer,
    category: store.categorySearchReducer
  };
};

const mapActionToProps = {
  itemLoad,
  itemLoadMore,
  servicesLoad,
  promoteLoad,
  promoteReset,
  watchlistLikeDislikeLoad,
  itemReset,
  adminUserSuggestLoad,
  reportLoad,
  reportReset,
  homeItemSetReserve,
  homeItemSetSold,
  servicePromoteLoad,
  servicePromoteReset,
  sessionLoad,
  searchWordLoad,
  categorySearchLoad
}

export default connect(mapStateToProps, mapActionToProps)(memo(Home));
