import React from 'react'
import { Link } from 'react-router-dom'
import { BackButton } from '../../../assets/svg'
import CustomComponents from './customComponents'

const PrivacyAndTerms = (props) => {
    const id = props.id ? props.id : null
    const back = props.click

    return (
        <>
            <div style={{ width: '100%', height: '100%', zIndex: '4', background: '#FFFFFF', position: 'fixed', top: '0', left: '0' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '12px 0px' }}>
                    <div style={{ borderBottom: '0.5px solid #C4C4C4', padding: '10.5px 5px', width: '100%' }}>
                        {
                            id ? <>
                                <div style={{ width: '100%', borderBottom: '0.5px solid #CACACA', padding: '10px 0px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div onClick={back}> <Link to={'/'}><BackButton /></Link></div>
                                        <h1 className="legalTermsLabel">{`Settings and Privacy`}</h1>
                                        <div></div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ borderBottom: '0.5px solid #CACACA', padding: '20px 12px' }}><p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: '20px', lineHeight: '22px', color: '#232A34' }}>{`Account Settings`}</p></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '5px 12px' }} onClick={back}><div style={{ display: 'flex', flexDirection: 'column' }}><p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '17px', lineHeight: '22px', color: '#232A34' }}>Edit Account</p> <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '15px', lineHeight: '15px', color: '#696969' }}>View or change personal information</p></div><div><i className="fa fa-chevron-right" /></div></div>
                                </div> </> :
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                        <div onClick={back}><BackButton /></div>
                                        <h1 className="legalTermsLabel">{`Legal Terms`}</h1>
                                        <div></div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <CustomComponents b={back} id={id ? id : false} />
            </div>
        </>
    )
}

export default PrivacyAndTerms