import React from "react";

const ItemTag = (props) => {
    const {sold, reserved} = props
    return (
        <div className={`item-tag-container ${sold ? 'background-gray' : reserved ? 'background-blue' : 'background-transparent'}`}>
            <p className="item-tag-text">{sold ? 'Sold' : reserved ? 'Reserved' : ''}</p>
        </div>
    )
}

export default React.memo(ItemTag)