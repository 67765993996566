import React from 'react'
import moment from 'moment';

const {REACT_APP_STRIPE_COIN_50_ID, REACT_APP_STRIPE_COIN_100_ID, REACT_APP_STRIPE_COIN_300_ID, 
  REACT_APP_STRIPE_FKPRIME_1_ID, REACT_APP_STRIPE_FKPRIME_3_ID, REACT_APP_STRIPE_FKPRIME_6_ID, REACT_APP_STRIPE_FKPRIME_12_ID,
  REACT_APP_STRIPE_LISTING_SLOT_ID, REACT_APP_STRIPE_COVER_PHOTO_ID, REACT_APP_STRIPE_YOUTUBE_LINK_ID,
  REACT_APP_PAYPAL_FKPRIME_1_ID, REACT_APP_PAYPAL_FKPRIME_3_ID, REACT_APP_PAYPAL_FKPRIME_6_ID, REACT_APP_PAYPAL_FKPRIME_12_ID
} = process.env

// export all shared functions
export const getLocalStorage = (key) => {
  const storage = localStorage.getItem(key);

  try {
    if (storage && storage !== '') {
      return JSON.parse(storage);
    }

    return null;
  } catch (e) {
    return storage;
  }
};

export const saveLocalStorage = (key, value) => {
  try {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }

    return null;
  } catch ( e ) {
    return null;
  }
};

export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);

    return key;
  } catch ( e ) {
    return null;
  }
};

export const getFileExtension = (filename) => {
  try {
    return filename.match(/\.[0-9a-z]+$/i)[0];
  } catch (ex) {
    return '';
  }
};

/**
 * Convert Bytes to Size value in sizes
 */
export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  let result = bytes;
  let i = 0;

  if (bytes === 0) {
    return '0 Byte';
  }

  while (result > 900) {
    result /= 1024;
    i += 1;
  }

  const exactSize = Math.round(result * 100) / 100;

  return `${exactSize} ${sizes[i]}`;
};

/**
 * determine if it is odd or not
 */
export const isOdd = number => number % 2;

/**
 * Humanize date
 * @param  {String} date: String date in ISO format
 * @return {String} Humanize date based on certain parameters
 */
// export const calendarDate = (date) => {
//   if (isEmpty(date)) {
//     return '';
//   }

//   return moment(date).calendar(null, {
//     sameDay: '[Today]',
//     lastDay: '[Yesterday]',
//     lastWeek: 'MMMM DD, YYYY',
//     sameElse: 'MMMM DD, YYYY'
//   });
// };

/**
 * [description]
 * @param  {String} prevDate    :previous date
 * @param  {String} currentDate :current date
 * @param  {String} precision   :precision criterio, day, month, year
 * @return {Bool}             :if it is same date or not
 */
// export const isSameDate = (prevDate, nextDate, precision = 'day') => {
//   if (isEmpty(prevDate) || isEmpty(nextDate)) {
//     return false;
//   }

//   return moment(prevDate).isSame(nextDate, precision);
// };

/**
 * Add comma for thousand's in integer
 * @param  {Integer} x    :price
 */
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const labelSwitch = (text) => {

  switch (text) {
    case 'pk_i_id':
    return 'Id'
    case 'id':
    return 'Id'
    case 'i_price':
    case 'get_i_price':
    case 'sg_price':
    return 'Price'
    case 's_contact_name':
    return 'Contact Name'
    case 's_contact_email':
    return 'Contact Email'
    case 'ta_expiration':
    return 'Top Add Expiry'
    case 'hl_expiration':
    return 'Highlight Expiry'
    case 'owner':
    return 'Owner'
    case 'title':
    case 's_title':
    return 'Title'
    case 'description':
    case 's_description':
    return 'Description'
    case 'category':
    case 'fk_i_category_id':
    return 'Category'
    case 'b_active':
    return 'Status'
    case 'b_spam':
    return 'Spam'

    case 'transaction_id':
    return 'Transaction Id'   
    case 'payment_method':
    return 'Payment Method'  
    case 'type':
    return 'Type'  
    case 'total_purchase':
    return 'Total Purchase'   
    case 'before_credit':
    return 'Previous Credit'  
    case 'current_credit':
    return 'Current Credit'  
    case 'created_at':
    return 'Created Date'   
    case 'status':
    return 'Status'  
    case 'service':
    return 'Service Description'  
    case 'item':
    return 'Item'
    case 's_name':
    case 'name':
    return 'Name'
    case 's_section':
    return 'Section'
    case 's_value':
    return 'Value'
    case 'e_type':
    return 'Type'

    case 'b_enabled':
    return 'Status'  
    case 'oc_t_parent_category_descriptions':
    return 'Parent Category'  
    case 'oc_t_category_descriptions':
    return 'Category'
    case 'i_position':
    case 'custom_index':
    return 'Position'
    case 'label':
    return 'Label'
    case 'placeholder':
    return 'Placeholder'
    case 'placeholder':

    return 'brand_and_model'
    case 'Brand & Model':
    return 'Placeholder'
    case 'placeholder':
    return 'Placeholder'
    case 'placeholder':
    return 'Placeholder'
    case 'days':
    return 'Days'
    case 'credit':
    return 'Credit'
    case 'live_list_count':
    return 'Live List Count'
    case 'top_ad_count':
    return 'Top Add Count'
    case 'highlight_count':
    return 'Highlight Count'
    case 'discount':
    return 'Discount'
    case 'cover_photo':
    return 'Cover Photo'
    case 'youtube_link':
    return 'Youtube Link'
    case 'auto_bump_count':
    return 'Auto Bump Count'
    case 'purchase_date':
    return 'Purchase Date'

    case 'amount':
    return 'Amount'
    case 'duration':
    return 'Duration'
    case 'expiry':
    return 'Expiration Date'
    
    default:
    return text.toString()
  }
}

export const renderStatusOptions = (isTrue) => {
  const statusOptions = isTrue ?
      <React.Fragment> 
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
      </React.Fragment> :
      <React.Fragment>
          <option value={false}>Not Active</option>
          <option value={true}>Active</option>
      </React.Fragment>
  return statusOptions
}

export const renderCategoryOptions = (data) => {
    let arr = []
    arr.push(<option key={0} value={0} disabled>{'Select Parent Category'}</option>)

    data.map((item) => {
        arr.push(<option key={item.pk_i_id} value={item.fk_i_category_id}>{item.s_name}</option>)
        return true
    })
    return arr
}

export const categoryIndexer = (text) => {
  switch (text) {
    case 'Rods':
    return 2
    case 'Reels':
    return 1
    case 'Lures':
    return 3
    case 'Line':
    return 4
    case 'Terminal':
    return 5
    case 'Accessories':
    return 6
    case 'Apparel':
    return 7
    case 'Watercraft':
    return 8
    case 'Charters':
    return 9
    case 'Boats':
    return 10
    default:
    return 100
  }
}

export const setCookie = (cname,cvalue,exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// export const checkCookie = () => {
//   var user=getCookie("fkv2_cookie");
//   if (user != "") {
//     alert("Welcome again " + user);
//   } else {
//      user = prompt("Please enter your name:","");
//      if (user != "" && user != null) {
//        setCookie("fkv2_cookie", user, 30);
//      }
//   }
// }

export const dateParser = (date) => {
  const sentDate = moment(date)
  const today = moment(new Date).format("YYYY-MM-DD");
  const yesterday = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD");

  let dateLabel;
  if (sentDate.isSame(today, 'd')) {
    dateLabel = 'Today'
  } else if(sentDate.isSame(yesterday, 'd')) {
    dateLabel ='Yesterday'
  }
  else {
    dateLabel = moment(date).format('MMM DD, YYYY')
  }  
  return dateLabel
}

export const groupDateParser = (date) => {
  const sentDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
  const today = moment(new Date).format("YYYY-MM-DD")
  const yesterday = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")

  let dateLabel;
  if (moment(sentDate).isSame(today, 'd')) {
    dateLabel = 'Today'
  } else if(moment(sentDate).isSame(yesterday, 'd')) {
    dateLabel ='Yesterday'
  }
  else {
    dateLabel = sentDate
  }  
  return dateLabel

}

//social params is data from facebook response
export const getSocialUrl = (social) => {
         const url = social[0] && social[0].data && social[0].data.picture && social[0].data.picture.data && social[0].data.picture.data.url ? social[0].data.picture.data.url : null
  return url;
}

export const getSocialID = (social) => {
  const id = social[0] && social[0].data && social[0].data.id ? social[0].data.id : null
  return id
}

export const slugify = ( text ) => {
  return text
  .toString()
  .normalize( 'NFD' )                   // split an accented letter in the base letter and the acent
  .replace( /[\u0300-\u036f]/g, '' )    // remove all previously split accents
  .toLowerCase()
  .trim()
  .replace(/\_/g, '-')  //replace unscore with dash, underscore is reserve with i for url item id
  .replace(/\s+/g, '-')
  .replace(/[^\w\-]+/g, '')
  .replace(/\-\-+/g, '-'); 
}

export const getItemIdFromUrl = ( url ) => {
  const split = url.split('_i')
  const id = split && split[1] ? parseInt(split[1]) : null
  return id;
}

export const categoryParser = (text) => {
  const categories = ['Rods', 'Reels','Lures', 'Line', 'Terminal','Accessories', 'Apparel', 'Watercraft', 'Charters' ];
  let category = 'others';
  for (let i = 0; i < categories.length; i++) {
    if (text.indexOf(categories[i]) > -1) {
      category = categories[i].toLowerCase()
    }
  }
  return category;
}

export const hourChecker = (item_date_pub, hour) => {
  const hours = moment().diff(moment(item_date_pub), 'hours');
  if(hours < hour) {     
    return true
  } 
  else {
    return false
  }
}

export const isEmpty = (obj) => {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }
  return true;
}

export const itemSatus = (active, date) => {
  let status;
  const lastMonth = moment(new Date).subtract(30, 'day').format("YYYY-MM-DD");

  if (active) {
    status = 'Active'
  } else if(active === false && date > lastMonth) {
    status = 'Pending'
  }
  else {
    status = 'Not Active'
  }
  return status
}

export const getStripePriceIdByAmount = (amount) => {
  let id
  if (amount === 5) {
    id = REACT_APP_STRIPE_COIN_50_ID
  }
  else if(amount === 10) {
    id = REACT_APP_STRIPE_COIN_100_ID
  }
  else if(amount === 30) {
    id = REACT_APP_STRIPE_COIN_300_ID
  }
  else {
    id = REACT_APP_STRIPE_COIN_50_ID
  }
  return id
}

export const getStripeSubscriptionIdByAmount = (amount) => {
  let id
  if (amount === 9.9) {
    id = REACT_APP_STRIPE_FKPRIME_1_ID
  }
  else if(amount === 28.22) {
    id = REACT_APP_STRIPE_FKPRIME_3_ID
  }
  else if(amount === 44.55) {
    id = REACT_APP_STRIPE_FKPRIME_6_ID
  }
  else if(amount === 83.16) {
    id = REACT_APP_STRIPE_FKPRIME_12_ID
  }
  else {
    id = REACT_APP_STRIPE_FKPRIME_1_ID
  }
  return id
}

export const getStripeOtpPriceIdByName = (name) => {
  let id
  if (name === 'Listing Slot') {
    id = REACT_APP_STRIPE_LISTING_SLOT_ID
  }
  else if(name === 'Cover Photo') {
    id = REACT_APP_STRIPE_COVER_PHOTO_ID
  }
  else if(name === 'Youtube') {
    id = REACT_APP_STRIPE_YOUTUBE_LINK_ID
  }
  else {
    id = null
  }
  return id
}

export const getPaypalSubscriptionIdByAmount = (amount) => {
  let id
  if (amount === 9.9) {
    id = REACT_APP_PAYPAL_FKPRIME_1_ID
  }
  else if(amount === 28.22) {
    id = REACT_APP_PAYPAL_FKPRIME_3_ID
  }
  else if(amount === 44.55) {
    id = REACT_APP_PAYPAL_FKPRIME_6_ID
  }
  else if(amount === 83.16) {
    id = REACT_APP_PAYPAL_FKPRIME_12_ID
  }
  else {
    id = REACT_APP_PAYPAL_FKPRIME_1_ID
  }
  return id
}


export const currentDate = () => {
  return moment().format('DD MMMM, YYYY - HH:mm')
}

export const addDate = (d, y) => {
  if(y.includes("Year"|| "Years")){
      return moment().add(d, 'y').format('DD MMMM, YYYY')
  }else{
      return moment().add(d, 'M').format('DD MMMM, YYYY')
  }
}

export const checkPaypalLoaded = () => {
  let isPaypalLoaded = false
  Object.keys(window).some((key) => {
      if (/paypal|zoid|post_robot/.test(key)) {
          // eslint-disable-next-line
          isPaypalLoaded = true
      }
  });
  return isPaypalLoaded
}

export const resetPaypal = () => {
  Object.keys(window).forEach(key => {
    if (/paypal|zoid|post_robot/.test(key)) {
      delete window[key];
    }
  });
  document.querySelectorAll('script[src*="www.paypal.com/sdk"]').forEach(node => node.remove());
}

export const fromNow = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var years = Math.floor(seconds / 31536000);
  var months = Math.floor(seconds / 2592000);
  var days = Math.floor(seconds / 86400);

  if (days > 548) {
    return years + ' years ago';
  }
  if (days >= 320 && days <= 547) {
    return 'a year ago';
  }
  if (days >= 45 && days <= 319) {
    return months + ' months ago';
  }
  if (days >= 26 && days <= 45) {
    return 'a month ago';
  }

  var hours = Math.floor(seconds / 3600);

  if (hours >= 36 && days <= 25) {
    return days + ' days ago';
  }
  if (hours >= 22 && hours <= 35) {
    return 'a day ago';
  }

  var minutes = Math.floor(seconds / 60);

  if (minutes >= 90 && hours <= 21) {
    return hours + ' hours ago';
  }
  if (minutes >= 45 && minutes <= 89) {
    return 'an hour ago';
  }
  if (seconds >= 90 && minutes <= 44) {
    return minutes + ' minutes ago';
  }
  if (seconds >= 45 && seconds <= 89) {
    return 'a minute ago';
  }
  if (seconds >= 0 && seconds <= 45) {
    return 'a few seconds ago';
  }
};

export const isDue = (date) => {
  const dueDate = moment(date).format("YYYY-MM-DD");
  const today = moment(new Date).format("YYYY-MM-DD");
  if (dueDate >= today) {
    return true
  } else {
    return false
  }
}

export const parsedError = (error) => {
  let errorMessage
  if (error.includes('file')) {
    errorMessage = "Item posted with no image, due to error processing your picture."
  } else {
    errorMessage = error
  }
  return errorMessage
}

export const remove_duplicates = (arr) => {
  const unique = arr.filter((v,i,a)=>a.findIndex(t=>(t.pk_i_id===v.pk_i_id))===i)
  return unique
}

export const isExpired = (date) => {
  const passDate = new Date(date)
  const today = new Date()
  if (passDate) {
    if (passDate > today) {
      return true
    } else {
      return false
    }
  }
}
