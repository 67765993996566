import React, { useEffect, useState, useReducer, useCallback, useRef, memo} from 'react';
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import ProfileListingCard from './itemCard'
// import FKImage from '../../../../common/component/image'
import {NoListingIcon} from '../../../../assets/svg'
import {isDue, isEmpty} from '../../../../common/helper/utility'
// import { theme } from '../../../../common/theme'
import { itemUserLoad, itemUserLoadMore, itemUpdateByFieldLoad, itemDeleteReset, itemDeleteLoad, itemUpdateLoad, itemUpdateReset, itemUserReset, toggleLiveLoad, toggleLiveReset, watchlistLikeDislikeLoad, reportLoad, servicesLoad, promoteLoad, promoteReset, servicePromoteLoad, servicePromoteReset, sessionLoad} from '../../../../redux/action'
import { ItemContext, ListingContext} from '../../../../common/context'
import { ItemDeleteModal, ItemEditModal, LiveListingContainer, ActiveListingsContainer, NotLiveListingsContainer, SoldListingsContainer, ProfileListingsContainer, ReportModal, ItemSoldModal} from './component/CustomComponent'
import { data } from './component/dummyData'
import {OverlayLoader} from '../../../../common/component/loader'
import PromoteModal from './component/promote'
import {PaymentSuccess, PaymentUnsuccessful, WholePageLoader, WholePageActivate, WholePageActivated} from '../../../../common/component/customComponents'

const { REACT_APP_BASE_URL } = process.env;

const Listing = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // get item data
  // const data = theme.text.sampleItem
  const {isLoading, error, paginatorInfo, isLoadingMore } = props.item
  const {reportError, reportMessage} = props.itemReport
  const location = useLocation()
  const pathname = location.pathname
  const userView = pathname.slice('6')
  const test = parseInt(userView)
  const user_id = userView.replace('/listing', '')
  const itemCard = useRef(null)
  const page = props && props.item && props.item.page ? props.item.page : 1
  const items_data = props.item && props.item.data  ? props.item.data : []
  const userId = isNaN(test) === true ? props.session.fkv2_session.id : user_id
  const sessionId = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : false
  const isSessionUser = parseInt(userId) === parseInt(sessionId) ? true : false
  // const [upto, setUpto] = useState('10')
  // const renderItem = items_data.slice(0, upto).map((item) => <div>{item}</div>)
  const purchase_micro_service = props && props.me && props.me.data && props.me.data.purchase_micro_service ? props.me.data.purchase_micro_service : []
  const not_live_items = props && props.me && props.me.data && props.me.data.not_live_items ? props.me.data.not_live_items : []
  const live_items = props && props.me && props.me.data && props.me.data.live_items ? props.me.data.live_items : []
  const active_items = props && props.me && props.me.data && props.me.data.active_items ? props.me.data.active_items : []
  const sold_items = props && props.me && props.me.data && props.me.data.sold_items ? props.me.data.sold_items : []
  const isFreeClaimed = props && props.me && props.me.data && props.me.data.is_free_subscription_claimed ? props.me.data.is_free_subscription_claimed : false
  const likeDislikeLoading = props.watchlist && props.watchlist.isLoading ? props.watchlist.isLoading : false
  const currentCoin = props && props.me && props.me.sessionData && props.me.sessionData.wallet ? props.me.sessionData.wallet : 0
  const watchlistArr = props && props.watchlist && props.watchlist.data ? props.watchlist.data : []
  const meData = props.me && props.me.sessionData ? props.me.sessionData : []
  const subscription_status = meData && meData.database_subscription_status ? meData.database_subscription_status : []
  const [actDeact, setActDeact] = useState(false)
  const [isDeactMessage, setIsDeactMessage] = useState('')
  const [isDeactPath, setIsDeactPath] = useState('')
  const [subscription, setSubscription] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
        fkPrime: [],
        individual: []
    }
   )
 
   const handleUseNowBtn = (boost, item_id) => {
    const micros_service_id = boost && boost.id ? parseInt(boost.id) : null
    const service_id = boost && boost.service_detail && boost.service_detail.id ? parseInt(boost.service_detail.id) : null
    props.servicePromoteLoad({micros_service_id: micros_service_id, item_id: parseInt(item_id), service_id: service_id})

 }

  const likeDislike = (item_id) => {
    props.watchlistLikeDislikeLoad({userId: sessionId, itemId: item_id})
  }

  const [listings, setListings] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
        live: [],
        active: [],
        not_live: [],
        sold: [],
        other_seller_listings: []
    }
);

  const {isLoadingToggleLive, toggleLiveMessage, toggleLiveError, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError} = props.items

  const [reportLoading, setReportLoading] = useState(false)

  const handleLoadMore = () => {
    props.itemUserLoadMore({user_id: userId, page: page})
  }
  const total = paginatorInfo && paginatorInfo.total ? paginatorInfo.total : null
  // const { updateIsLoading } = props.itemView

  useEffect(() => {
      props.itemUserLoad({user_id: userId})
  // eslint-disable-next-line 
      return () => {
        props.itemUserReset()
      }
  }, [userId])

  useEffect(() => {
    if(reportError || reportMessage){
      setReportLoading(false)
      setTimeout(() => {
        setReportShow(false)
        window.location.reload(false)
      }, 3000)
    }
  },[reportError, reportMessage])
  
  useEffect(() => {
    if(isEmpty(meData)){
      if(sessionId){
        props.sessionLoad({id: sessionId})
      }
    }
  },[meData])

  const submitItemUpdateByFieldLoad = (data) => {
    props.itemUpdateByFieldLoad(data)
  }

  //mark as sold
  const [isShowMarkAsSold, setIsShowMarkAsSold] = useState(false)
  const [isSold, setIsSold] = useState(true)
  const [soldData, setSoldData] = useState(null)
  const markAsSoldUnsold = (data, type) => {
    setIsSold(type)
    setSoldData(data)
    setIsShowMarkAsSold(true)
  }

  const closeSoldModal = () => {
    setIsSold(true)
    setSoldData(null)
    setIsShowMarkAsSold(false)
  }

  const submitSoldUnsold = () => {
    if (soldData) {
      props.itemUpdateByFieldLoad(soldData)
    }
      closeSoldModal()
  }

  //delete
  const { deleteIsloading, deleteMessage, deleteError } = props.itemView
  const [isShowDelete, setIsShowDelete] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [selectedTitle, setSelectedTitle] = useState(null)

  const setSelected = (id, title) => {
    setSelectedId(id)
    setSelectedTitle(title)
    setIsShowDelete(true)
  }

  const closeDeleteModal = () => {
    setIsShowDelete(false)
    setSelectedId(null)
    setSelectedTitle(null)
  }

  const handleDeleteSubmit = (id) => {
    props.itemDeleteLoad({id, userId})
  }

  useEffect(() => {
    if(deleteError){
        setTimeout(() => {
            props.itemDeleteReset()
            setIsShowDelete(false)
        }, 2000)
    }
    if(deleteMessage){
        setTimeout(() => {
            props.itemDeleteReset()
            setIsShowDelete(false)
            window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
        }, 2000)
    }
// eslint-disable-next-line
}, [deleteError, deleteMessage])

//edit
const { updateIsLoading, updateMessage, updateError } = props && props.itemView
const [isShowEdit, setIsShowEdit] = useState(false)
const [selectedItem, setSelectedItem] = useState(null)
const [likeArr, setLikeArr] = useState([])
const [isDeleteClick, setIsDeleteClick] = useState(false)
const [isEllipsisClick, setIsEllipsisClick] = useState(0)
const [reportShow, setReportShow] = useState(false)
  const [reportInput, setReportInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      reporter_id: sessionId ? sessionId : null,
      reported_id: null,
      reported_user_id: null,
      report_reason: null,
      report_type: 'Item'
    }
  );


const handleEditSubmit = (item) => {
  props.itemUpdateLoad(item)
}

const closeEditModal = () => {
  setIsShowEdit(false)
  setSelectedItem(null)
}

const handleSetSelectedItem = (item) => {
  setSelectedItem(item)
  setIsShowEdit(true)
}

const handleReportSubmit = () => {
  setReportLoading(true)
  props.reportLoad(reportInput)
}

const filterItemsData = (active_items, sold_items) => {
  let active = []
  let sold = []

  if(active_items.length > 0) {
    active_items.map((item) => {
      active.push(item)
    })
  }
  if(sold_items.length > 0) {
    sold_items.map((item) => {
      sold.push(item)
    })
  }
  return setListings({['active']: active, ['sold']: sold})
}

const [unusedListingCount, setUnusedListingCount] = useState(0)

const filterMicroserviceData = useCallback((micro_service_data, live_items, not_live_data) => {
  let live = []
  let not_live = []
  let unused = []
  // const unused_live_counter = micro_service_data.filter(
  //   service => service.type === 'live_listing' && service.item_id === null && service && service.subscription_status ? service.subscription_status.toLowerCase().includes('active') : null)
  micro_service_data.filter(
    service => service.type === 'live_listing' && service.item_id === null || service.type === 'live_listing' && service.item_id === null && service && service.subscription_status  ? unused.push(service) : null
    )

  if(live_items.length > 0) {
    live_items.map((item) => {
      if(item.b_mark_as_sold === false){
        live.push(item)
      }
      //TODO add due date greater than now
      // if(item.item_id){
      //   live.push(item.oc_t_item)
      // }
      // else {
      //   unused_live_counter++
      // }
    })
  }

  if(not_live_data.length > 0) {
    not_live_data.map((item) => {
      if(item.b_mark_as_sold === false){
        not_live.push(item)
      }
    })
  }

  // setUnusedListingCount(unused_live_counter.length)
  setUnusedListingCount(unused.length)
  setListings({['live']: live, ['not_live']: not_live})
},[])

useEffect(() => {
    filterMicroserviceData(purchase_micro_service, live_items, not_live_items)
}, [props.me])


useEffect(()=>{
  if (updateMessage) {
      setTimeout(() => {
          props.itemUpdateReset()
          setIsShowEdit(false)
          window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
      }, 2000)
  }
  if (updateError) {
      setTimeout(() => {
          props.itemUpdateReset()
          setIsShowEdit(false)
      }, 2000)
  }
// eslint-disable-next-line
}, [updateMessage, updateError])

useEffect(() => {
  if(props.me.data.length !== 0){
    filterItemsData(active_items, sold_items)
  }
}, [props.me])

useEffect(() => {
  if(toggleLiveMessage || toggleLiveError){
    setActDeact(toggleLiveMessage ? true : false)
    setTimeout(() =>{
      props.toggleLiveReset()
      setActDeact(false)
    }, 4000)
  }
}, [toggleLiveMessage, toggleLiveError])

const items_data_filterizer = useCallback((arr) => {
  // const {itemsData} = arr
  // const {live_items, active_items} = itemsData


  // arr.map((item) => {
  //   return item.b_active && item.live === null ? active.push(item) : item.live === 'live' ? live.push(item) : null 
  // })

  setListings({['other_seller_listings']: [...arr]})
})

const getServices = (active) => {
  if (servicesData.length === 0) {
    props.servicesLoad(active)
  }
}

useEffect(() => {
  if(items_data.length !== 0){
    items_data_filterizer(items_data)
  }
}, [items_data, props.me])

useEffect(() => {
  if (isSessionUser) {
    getServices(true)
  }
  // eslint-disable-next-line
}, [])

const toggleLive = (item_id, live) => {
  setIsDeactMessage(live === 'not_live' ? `Listing Successfully Deactivated` : `Listing Successfully Activated`)
  setIsDeactPath(live === 'not_live' ? `Page > Listings > Not Live Listing` : `Page > Listings > Live Listing`)
  props.toggleLiveLoad({item_id, live})
}

const handleEllipsisClick = (item_id) => {
  if(isEllipsisClick === 0){
    setIsEllipsisClick(item_id)
  }
  if(item_id !== isEllipsisClick){
    setIsEllipsisClick(item_id)
  }else{
    setIsEllipsisClick(0)
  }
}

//promote context
const { servicesData, isLoadingPromote, promoteSuccessMessage, errorPromote } = props.purchase
const [promoteShow, setPromoteShow] = useState(false)
const stepLength = 2
const [step, setStep] = useState(1)
const [itemForPromote, setItemForPromote] = useState({})
const [paymentData, setPaymentData] = useState({})

const statusLabelParser = (type, item_id, due_date) => {
  let status
  if (type === 'cover_photo' || type === 'youtube_link') {
      if (new Date(due_date) > new Date()) {
          status = 'Active'
      } else {
          status = 'Not Active'
      }
  } else {
      if (!item_id) {
          status = 'Use Now'
      } else {
          status = 'Used'
      }
  }
  return status
}

const sortArr = (arr) => {
  let fkPrimeArr = []
  let youtube = []
  let cover = []
  let listing = []
  let top_ad = []
  let highlight = []
  let auto_bump = []
  let individual_arr = []
  let useNow = []
  let used = []
  let active = []
  let notActive = []
  let free = []
  let combo = []
  arr.map((p) => {
      if(p.type === 'fk_prime'){
          fkPrimeArr.push({...p, status: subscription_status})
      }
      else {
          const isActiveByDueDate = isDue(p.due_date)
          if (isActiveByDueDate) {
              individual_arr.push(p)
              if(p.type === 'youtube_link'){
                  youtube.push(p)
              }
              if(p.type === 'cover_photo'){
                  cover.push(p)
              }
              if(p.type === 'live_listing'){
                  listing.push(p)
              }
              if(p.type === 'top_ad'){
                  top_ad.push(p)
              }
              if(p.type === 'combo'){
                combo.push(p)
              }
              if(p.type === 'auto_bump'){
                  auto_bump.push(p)
              }
              if(p.type === 'highlight'){
                  highlight.push(p)
              } 
              if (p.type !== 'live_listing') {
                  const label = statusLabelParser(p.type, p.item_id, p.due_date)
                  if (label === 'Use Now') {
                      useNow.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Used') {
                      used.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Active') {
                      active.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } else if(label === 'Not Active') {
                      notActive.push({...p, status: statusLabelParser(p.type, p.item_id, p.due_date)})
                  } 
              }
              if(p.type === 'live_listing' && p.payment_method.includes('free')){
                  free.push(p)
              }
          }
      }
  })
  return setSubscription({['fkPrime']: fkPrimeArr.reverse(), individual: {
      ['useNow']: useNow, ['used']: used, ['active']: active, ['notActive']: notActive,
      ['youtube_link']: youtube, ['cover_photo']: cover, ['live_listing']: listing, ['top_ad']: top_ad, ['highlighted_ad']: highlight, ['auto_bump']: auto_bump,
      ['free_listing']: free, ['combo']: combo
  }})
 }

  useEffect(() => {
    if(props.me.sessionData && props.me.sessionData.purchase_micro_service){
      sortArr(meData.purchase_micro_service)
    }
  }, [props.me.sessionData.purchase_micro_service])


const togglePromoteShow = (item) => {
    setItemForPromote(item)
    setPromoteShow(!promoteShow)
}

const resetModal = () => {
    setStep(1)
    setItemForPromote({})
    setPaymentData({})
    setPromoteShow(false)
}

useEffect(() => {
    if (errorPromote) {
        setTimeout(() => {
            props.promoteReset()
            window.location.reload(false)
        }, 3000)
    }
    if (promoteSuccessMessage) {
        setTimeout(() => {
            props.promoteReset()
            togglePromoteShow()
            setPromoteShow(false)
            resetModal()
            window.location.reload(false)
        }, 3000)
    }
    // eslint-disable-next-line
}, [errorPromote, promoteSuccessMessage])

useEffect(() => {
  if(useNowPromoteMessage || useNowPromoteError){
    setTimeout(() =>{
      props.servicePromoteReset()
      window.location.reload(false)
    }, 2000)
  }
}, [useNowPromoteMessage, useNowPromoteError])

const handleNext = () => {
    if (step !== stepLength || step < stepLength) {
        setStep(step + 1)
    }
}

const handleBack = () => {
    if (step > 1) {
        setStep(step - 1)
    }
}

const submitPromote = (data) => {
    props.promoteLoad({ fkv2_purchased_data: data, userId: sessionId })
}
//end promote context

const [seeAll, setSeeAll] = useReducer(
  (state, newState) => ({...state, ...newState}),
  {
    live_length: 5,
    active_length: 10,
    not_live_length: 5,
    sold_length: 5,
    other_seller_listings: 10
  }
);

const seeAllHandler = (type, length) => {
  setSeeAll({[type]: length})
}

const other_items = listings && listings.other_seller_listings && listings.other_seller_listings.slice(0, seeAll.other_seller_listings).map(i => {
  return i
})

  return (
      !userId ? <p className='text-align-center'>No User found!</p> : 
       <div className={`w-100 mx-w-1240 ${window.innerWidth < 600 ? 'p-0' : 'mh-20 ph-15'} ${isSessionUser ? `` : `viewOtherSellerMainContainer`}`}>
         <ListingContext.Provider value={{
             updateIsLoading, submitItemUpdateByFieldLoad, setSelected, handleSetSelectedItem, likeDislike, sessionId, reportShow, setReportShow,
             reportInput, setReportInput, likeArr, setLikeArr, user_id, handleReportSubmit, seeAll, seeAllHandler, unusedListingCount, toggleLive, toggleLive,
             submitItemUpdateByFieldLoad, setSelected, handleSetSelectedItem, handleEllipsisClick, isEllipsisClick, sessionId, setSelected, togglePromoteShow, listings,
             isFreeClaimed, likeDislikeLoading, watchlistArr, meData, subscription, handleUseNowBtn, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError,
             markAsSoldUnsold
           }}>
          {
            reportLoading || 
            isLoadingPromote ||
            //  isSessionUser && updateIsLoading || 
            isLoadingToggleLive ? <WholePageLoader/> :
            updateMessage ? <WholePageActivated text={updateMessage} /> : 
            //  toggleLiveMessage ? <WholePageSuccessMessage text={toggleLiveMessage} />:
            reportError ? <WholePageActivated text={reportError} /> : 
            reportMessage ? <WholePageActivated text={reportMessage} /> : 
            actDeact ? <WholePageActivate text={isDeactMessage} path={isDeactPath}/> :
            toggleLiveError ? <PaymentUnsuccessful text={toggleLiveError} />:
            errorPromote ? <WholePageActivated text={errorPromote} /> : 
            isSessionUser ? 
            props.me.data.length !== 0  ?
           <>
              {/* <LiveListingContainer /> */}
              {/* <NotLiveListingsContainer />   */}
              <ActiveListingsContainer />
              {/* <SoldListingsContainer /> */}
           </> : 
           <WholePageLoader/> : 
           <>
            {
              
              listings.other_seller_listings.length === 0 ? <div className="flex flex-justify-center flex-align-center" ><NoListingIcon /></div> : 
              <div className="flex flex-d-column w-100  ">
                {/* <div className="w-100 flex flex-justify-end flex-align-center mb-30">
                    <div onClick={() => seeAllHandler("other_seller_listings", parseInt(listings.other_seller_listings.length))}>
                      <p className="profile-live-listings-see-all-p">{seeAll.other_seller_listings > 10 ? `See Less` : `See All`}</p>
                    </div>
                </div> */}
                <div className="profile-listing-item-container">
                  {
                  listings.other_seller_listings.length !==0 ? listings.other_seller_listings.map((item, key) => {
                    return <div key={key} className="profile-listing-seller-item-card">       
                            <ProfileListingCard item={item}/>
                          </div>
                      }) : null
                    }
                    {
                    // listings.other_seller_listings.active && listings.other_seller_listings.active.length !==0 ? 
                    //   listings.other_seller_listings.active.map((item, key) => {
                    //     return <div key={key} className="profile-listing-seller-item-card">       
                    //             <ProfileListingCard item={item}/>
                    //           </div>
                    //       }) : null
                    }
                  </div>
                </div>
                }
                {reportShow ? <ReportModal /> : null}
              </>
            }
            </ListingContext.Provider>


          <ItemContext.Provider value={{
              isShowDelete, closeDeleteModal, selectedId, selectedTitle, handleDeleteSubmit, deleteIsloading, deleteMessage, deleteError,
              handleEditSubmit, selectedItem, isShowEdit, closeEditModal, updateIsLoading, updateMessage, updateError, servicesData, itemForPromote, paymentData,
              handleNext, handleBack, submitPromote, step, promoteShow, resetModal, currentCoin, setPaymentData,  meData, subscription, handleUseNowBtn, isLoadingUseNowPromote, useNowPromoteMessage, useNowPromoteError, isShowMarkAsSold, setIsShowMarkAsSold,
              isSold, closeSoldModal, submitSoldUnsold, isLoadingPromote
          }}>
            {isShowDelete ? <ItemDeleteModal /> : null}
            {isShowEdit ? <ItemEditModal /> : null}
            {promoteShow ? <PromoteModal /> : null}
            {isShowMarkAsSold ? <ItemSoldModal /> : null}
          </ItemContext.Provider>
          
       </div>
  );
}

const mapStateToProps = store => {
  return {
    item: store.itemUserReducer,
    user: store.authReducer,
    itemView: store.itemViewReducer,
    items: store.microServiceReducer,
    watchlist: store.watchlistReducer,
    purchase: store.purchaseReducer,
    itemReport: store.itemReducer,
  };
};

const mapActionToProps = {
  itemUserLoad,
  itemUpdateByFieldLoad,
  itemDeleteReset, 
  itemDeleteLoad ,
  itemUpdateLoad, 
  itemUpdateReset,
  itemUserLoadMore,
  itemUserReset,
  toggleLiveLoad,
  toggleLiveReset,
  watchlistLikeDislikeLoad,
  reportLoad,
  servicesLoad,
  promoteLoad,
  promoteReset,
  servicePromoteLoad,
  servicePromoteReset,
  sessionLoad
}


export default connect(mapStateToProps, mapActionToProps)(memo(Listing));