import React, { useEffect } from 'react'
import { Link , useLocation} from 'react-router-dom'
import { connect } from 'react-redux';
import { categoryLoad } from '../../../redux/action'

const CategoryBar = (props) => {
    const data = props.category.data
    const location = useLocation()
    const pathname = location.pathname
    const isItemView = pathname.includes('_i') ? true : false
    const { itemView } = props
    const itemID = itemView && itemView.data && itemView.data.oc_t_category && itemView.data.oc_t_category.pk_i_id ? itemView.data.oc_t_category.pk_i_id : 0

    useEffect(() => {
        props.categoryLoad()
        //if(isItemView){
            // props.itemViewLoad({id: id})
        //}
    // eslint-disable-next-line    
    }, [pathname])


    return (
        <div>
            <div className="category-main-container">
            <div className="category-sub-container">
              {
                data.map((item, index) => {
                    let arr = []
                    const itemCategory = isItemView ? item.pk_i_id === itemID ? true : false : false
                    const name = item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name : false
                    const slug = item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name.toLowerCase() : false
                    const categoryPath = `/${slug}`
                    if (name) {
                        arr.push(
                            <div className="parent" key={index}>
                                {<Link to={categoryPath} className={`child ${pathname === categoryPath || itemCategory  ? `categoryActive` : null}`}> &nbsp;&nbsp;&nbsp;{`${name}`}</Link>} 
                            </div>
                        )
                    }
                    return arr;
                })
                    //Will be used for phase 2 sub category
                    // let subArr = []
                    // let childrenArr = []
                    // if (item.parent !== 'Orphan') {
                    //     item.data.map((item2, index2) => {
                    //         subArr.push(<Link to={`/category/${item2.pk_i_id}`} key={index2} className="child">&nbsp;&nbsp;&nbsp;{`${item2.oc_t_category_descriptions.s_name}`}</Link>)
                    //             return null
                    //         })
                    //         childrenArr.push(<div key={`2${index}`} className="categoryChildren">{subArr}</div>)

                    //     arr.push(<div className="parent" key={index}>{item.parent}
                    //     {childrenArr}
                        
                    //     </div>) 
                    // }
              }
            </div>
            </div>
        </div>
       

    )
}

const mapStateToProps = store => {
    return {
      category: store.categoryReducer,
      itemView: store.itemViewReducer,
    };
  };
  
const mapActionToProps = {
    categoryLoad,
}

export default connect(mapStateToProps, mapActionToProps)(CategoryBar)