import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AdminSaleContext } from '../../../../../common/context'
import { adminSaleLoad, adminSaleDetailLoad, adminSaleSuggestLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { SaleList, SaleModal, SaleSuggestData, SaleSearchResult } from './component/CustomComponent'


const SalesSection = (props) => {
    const pageCount = 10
    const result_limit = 20

    const { 
        adminSaleData, adminSaleIsLoading, adminSaleError, adminSalePage, adminTotalSale,
        adminSaleDetailIsLoading, adminSaleDetailData, adminSaleDetailError,
        adminSaleSuggestIsLoading, adminSaleSuggestData, adminSaleSuggestError
    } = props.admin

    useEffect(() => {
        if (adminSaleData.length === 0) {
            props.adminSaleLoad({first: 20, page: adminSalePage})
        }
    // eslint-disable-next-line
    }, [])

    const fetchSaleDetail = (id) => {
        setIsShow(true)
        props.adminSaleDetailLoad({id: id})
    }

    const closeSaleModal = () => {
        setIsShow(false)
    }

    //sale modal
    const [isShow, setIsShow] = useState(false)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)


    //item search
    const [isSearching, setIsSearching] = useState(false)

    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            props.adminSaleSuggestLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "created_at", orderType: "DESC"})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setIsShowSearchResult(true)
    }

    const handlePageClick = data => {
        props.adminSaleLoad({first: 20, page: data.selected + 1})
    };

    let history = useHistory();
    const gotoItem = (id) => {
        history.push("/admin/dashboard/item", {item_id: id});
    }

    const gotoUser = (id) => {
        history.push("/admin/dashboard/user", {user_id: id});
    }

    return (
        <AdminSaleContext.Provider value={{
            adminSaleData, fetchSaleDetail, adminSaleDetailIsLoading, adminSaleDetailData, adminSaleDetailError, isShow, closeSaleModal, adminSaleSuggestIsLoading, adminSaleSuggestData, adminSaleSuggestError, setIsShowSearchResult, isShowSearchResult, gotoItem, gotoUser
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Sales</h3>
            <section>
            {adminSaleIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminSaleError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminSaleError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div ></div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><SaleSuggestData /></div>}
                            </div>
                        </div>
                        <SaleList />
                    </article>
            }
            <div></div> 
            {adminSaleError ? null :
                adminSaleData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalSale}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {isShow ? <SaleModal /> : null }
        {isShowSearchResult ? <SaleSearchResult /> : null}
        </AdminSaleContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminSaleLoad,
    adminSaleDetailLoad,
    adminSaleSuggestLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(SalesSection);