import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../../theme';
import {InstagramLogoCircleGrayBG, FacebookLogoCircleGrayBG, FacebookLikeLogoCircleGrayBG, GetItOnAppStore, GetItOnPlayStore} from '../../../../assets/svg'
import GooglePlay from '../../../../assets/images/playstore.svg'
// import { removeLocalStorage } from '../../../helper/utility'

// const { REACT_APP__BASE_URL } = process.env;


const subFoot = (session) => {
    // const type = session && session.type ? session.type : false

    // const handleLogout = () => {
    //     const alert = window.confirm("Are you sure you want to logout"); if(alert === true){ 
    //         removeLocalStorage('fkv2_session')
    //         window.location.replace(REACT_APP__BASE_URL);
    //     }
    // }

    return (
        <div className="sub-container">
                    <div>
                        <p className="footer-sub-container-label-p">Fishingkaki</p>
                        <div className="flex flex-d-column">
                            <a href={`${theme.text.forumLinks}`} className="footer-sub-container-sub-label-p" target="_blank">Forums</a>
                            <Link to="/" className="footer-sub-container-sub-label-p" target="_blank">Classifieds</Link>
                        </div>
                    </div>
                    <div>
                    <p className="footer-sub-container-label-p">About Us</p>
                        <div className="flex flex-d-column">
                            <Link to={`/about-us`} className="footer-sub-container-sub-label-p">About Fishingkaki</Link>
                            <Link to={`/privacy-policy`} className="footer-sub-container-sub-label-p">Privacy Policy</Link>
                            <Link to={`/terms-of-use`} className="footer-sub-container-sub-label-p">Terms of Use</Link>
                        </div>
                    </div>
                    <div>
                    <p className="footer-sub-container-label-p">Contact Us</p>
                        <div className="flex flex-d-column">
                            <Link to={'/contact-us'} className="footer-sub-container-sub-label-p" >Contact</Link>
                            <Link to={'/contact-us'} className="footer-sub-container-sub-label-p" >Support</Link>
                        </div>
                </div>
                <div className="socialLinks">
                <p className="footer-sub-container-label-p">Follow Us</p>
                    <div className="flex flex-d-column ">
                        <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center mb-11" target="_blank">
                            <FacebookLogoCircleGrayBG />
                            <p className="social-links-footer-label-p">{`Group`}</p>
                        </a>
                        <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center mb-11" target="_blank">
                            <FacebookLikeLogoCircleGrayBG />
                            <p className="social-links-footer-label-p">{`Like!`}</p>
                        </a>
                       <a href="https://www.instagram.com/fishingkakicommunity/" className="flex flex-d-row flex-align-center mb-24" target="_blank">
                            <InstagramLogoCircleGrayBG />
                            <p className="social-links-footer-label-p">{`Instagram`}</p>
                        </a>
                    </div>
                </div>
                <div className="socialLinks">
                <p className="footer-sub-container-label-p">Download the App now!</p>
                    <div className="flex flex-d-row ">
                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mb-11" target="_blank">
                            <img src={GooglePlay} width={128}/>
                            {/* <GetItOnPlayStore /> */}
                        </a>
                        <a href="https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549" className="flex flex-d-row flex-align-center mb-11 ml-12" target="_blank">
                            <GetItOnAppStore />
                        </a>
                    </div>
                </div>
        </div>
    );
};

export const style = {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: '20px',
    bottom: '0',
    borderTop: '1px solid #bdc3c7',
    marginTop: '50px'
}

// const styles = {
//     row: {
//         content: '',
//         display: 'table',
//         clear: 'both',
//         width: '100%'
//     },  
//     column: {
//         float: 'left',
//         width: '20%',
//         marginLeft: '5%'
//     },
//     socialButton: {
//         color: theme.color.black,
//         marginRight: '20px',
//         fontFamily: theme.font.primary,
//         fontSize: '14px',
//         textDecoration: 'none',
//     },
//     socialGroup: {
//         margin: '10px 5px',
//     },
//     fbwidget: {
//         width: '340px',
//         height: '327px',
//         border: 'none',
//         overflow: 'hidden',
//         scrolling: 'no',
//         frameborder: '0',
//         allowTransparency:      'true'
//     },
//     instawidget: {
//         textDecoration: 'none',
//         fontSize: '14px',
//     }
// }



export default subFoot