import { SEARCH_WORD, SEARCH_WORD_MORE, WATCHLIST} from '../constant'

    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        pages: 1,
        isLoadingMore: false,
        total: null
    }

    export default (state = initialState, action) => {
        switch(action.type) {
            case SEARCH_WORD.LOAD:
                return { ...state, isLoading: true, error: null }

            case SEARCH_WORD.SUCCESS:
                const searchData = action.payload && action.payload.data ? action.payload.data : []
                const searchTotal = action.payload && action.payload.count ? action.payload.count : null
                const likeId = action.payload && action.payload.pk_i_id ? action.payload.pk_i_id : 0
                const indexLike = state.data && state.data.length !== 0 ? state.data.findIndex(item => parseInt(item.pk_i_id) === parseInt(likeId) ) : 0
                
                if(likeId !== 0){
                    state.data[indexLike] = action.payload
                    return {...state}
                }else {
                    return { ...state, isLoading: false, data: searchData, total: searchTotal, error: null, pages: state.pages + 1 
                    }
                }

            case SEARCH_WORD.FAIL:
                return { ...state, isLoading: false, error: action.payload }

            case SEARCH_WORD_MORE.LOAD:
                return { ...state, isLoadingMore: true }

            case SEARCH_WORD_MORE.SUCCESS:
                const searchMoreData = action.payload && action.payload.data ? action.payload.data : []
                const searchMoreTotal = action.payload && action.payload.count ? action.payload.count : null
                return { ...state, isLoadingMore: false, 
                    data: state.data.concat(searchMoreData), 
                    total: searchMoreTotal, error: null, pages: state.pages + 1 
                }

            case SEARCH_WORD.RESET:
                return initialState

            default:
                return state;
        }
    }