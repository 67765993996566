import { REVIEW , REVIEW_VIEW } from '../constant'

export const reviewLoad = (payload) => {
    return {
        type: REVIEW.LOAD,
        payload
    }
}

export const reviewSuccess = (payload) => {
    return {
        type: REVIEW.SUCCESS,
        payload
    }
}

export const reviewFail = (payload) => {
    return {
        type: REVIEW.FAIL,
        payload
    }
}

export const reviewViewLoad = (payload) => {
    return {
        type: REVIEW_VIEW.LOAD,
        payload
    }
}

export const reviewViewSuccess = (payload) => {
    return {
        type: REVIEW_VIEW.SUCCESS,
        payload
    }
}

export const reviewViewFail = (payload) => {
    return {
        type: REVIEW_VIEW.FAIL,
        payload
    }
}
