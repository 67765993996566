import { WATCHLIST } from '../constant'
import { takeLatest, put } from 'redux-saga/effects'
import user from '../../graphql/user'
import { watchlistSuccess, watchlistFailed, watchlistLikeDislikeSuccess, watchlistLikeDislikeFailed, itemHomeLikeUpdateSuccess, itemViewLikeUpdateSuccess, searchWordSuccess} from '../action'
import { theme } from '../../common/theme'

function* watchlistSaga(action) {
    try {
        const result = yield user.getWatchlistByUser(action.payload)

        if(result === 403) {
            yield put(watchlistFailed('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.getWatchlistByUser && result.data.getWatchlistByUser.watchlist ? result.data.getWatchlistByUser.watchlist : []
            yield put(watchlistSuccess(data));
        }

    } catch (e) {
        console.log('error', JSON.stringify(e))
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        yield put(watchlistFailed(errorMessage))
    }
}

export function* watchWatchListSaga(){
    yield takeLatest(WATCHLIST.LOAD,watchlistSaga)
}



function* likeDislikeSaga(action) {

    try {
        const result = yield user.likeDislikeByUser(action.payload)

        if(result === 403) {
            yield put(watchlistLikeDislikeFailed('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.likeDislikeItem && result.data.likeDislikeItem.user_data && result.data.likeDislikeItem.user_data.watchlist ? result.data.likeDislikeItem.user_data.watchlist : []
            const item_data = result && result.data && result.data.likeDislikeItem && result.data.likeDislikeItem.item_data ? result.data.likeDislikeItem.item_data : {}
            yield put(itemHomeLikeUpdateSuccess(item_data))
            yield put(itemViewLikeUpdateSuccess(item_data))
            yield put(searchWordSuccess(item_data))
            yield put(watchlistLikeDislikeSuccess(data));
        }

    } catch (error) {
        console.log('error', error)
        yield put(watchlistLikeDislikeFailed(error))
    }
}

export function* watchLikeDislikeSaga(){
    yield takeLatest(WATCHLIST.LIKE_DISLIKE_LOAD,likeDislikeSaga)
}
