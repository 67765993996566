import React from 'react'
import {SuccessBannerMessage} from '../../../assets/svg'

const SuccessBanner = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', padding: '40px 0px', height: window.innerWidth < 800 ? '100%' : '451px'}}>
            <SuccessBannerMessage />
        </div>
    )
}

export default SuccessBanner