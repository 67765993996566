import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getAllPromo = (payload) => {
    const { first, page } = payload
    try {
        const query = gql `query getAllPromo($first: Int, $page: Int, $orderField: String, $orderType: SortOrder){
            getAllPromo(first: $first, page: $page, orderField: $orderField, orderType: $orderType){
              count
              promo {
                id
                name
                description
                type
                amount
                duration
                expiry
                created_at
                updated_at
              }
            }
        }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderField: 'created_at', orderType: 'DESC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }

  export const upsertPromo = (payload) => {
    const { id, name, description, duration, amount, type } = payload

    const variables = {data: {
        id: id ? parseInt(id) : null,
        name: name ? name : null,
        description: description ? description : null,
        type: type ? type : null,
        amount: amount ? parseInt(amount) : null,
        duration: duration ? parseInt(duration) : null
      }
    }

    const gql_query = gql`mutation promoUpsert($data: PromoUpsertInput)
    {
      promoUpsert(data: $data)
        {
          message
          data {
            id
          }
        }
    }`;
    
    try{
      const response = client.mutate({
          mutation: gql_query,
          variables: variables
      });
      const data = response ? response : null;
      return data;
    }catch(e){
        throw e
    }
}

export const deletePromo = (payload) => {
  const  gql_query = gql`mutation promoDelete($id: ID!){
    promoDelete(id: $id){
      message
    }
  }`
 
  const variables = {
    id: payload ? parseInt(payload) : null
  }

  try{
    const query = gql_query;
    const response = client.mutate({
        mutation: query,
        variables: variables
    });
    const data = response ? response : null;
    return data;
  }catch(e){
      throw e
  }
}

export default {
    getAllPromo,
    upsertPromo,
    deletePromo
}