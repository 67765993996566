import React, {useState, useEffect} from 'react'
import Dropzone from 'react-dropzone-uploader'
import {SubmitBtn} from './customComponents'
import {AddImageIcon} from '../../../../../../../assets/svg'
import DragableLayout from '../../../DragableLayout'
import DragableLayoutMobile from '../../../DragableLayoutMobile'

const ImageContainer = (props) => {

    const {imageData, setImageData, handleChangeStatus, setImageFiles, imageFiles} = props
    const [forceRender, toggleForceRender] = useState(false)
    let imageArr = []

    const togRender = () => {
        toggleForceRender(!forceRender)
      }

    const editRemoveImage = (arr, setArr, index) => {
        arr.splice(index, 1)
        setArr(arr)
        togRender()
    }

    const handleInitialImage = async () => {
        await imageData.map( item => {
            fetch(item.raw).then(res => {
                res.arrayBuffer().then(buf => {
                const file = new File([buf], `${item.id ? `${item.id}.${item.ext}` : `${item.name}`}`, { type: 'image/*' })
                imageArr.push(file)
                if(imageData.length === imageArr.length){
                        setImageFiles(imageArr)
                    }
                })
            })
        })
    }
    useEffect(() => {
        if (imageData.length > 0) {
            async function loadContent(){
                await handleInitialImage()
            }
        loadContent()
        }
    }, [imageData])

    const renderPreview = (e) => {
        const { input, files } = e
        const maxFiles = 4
        return <div className={`dropzone-main-body-container ${imageData.length === 4 ? `custom-mb-30` : ``}`}>
            {
                window.innerWidth < 600 ? <DragableLayoutMobile imageData={imageData} setImageData={setImageData} handleChangeStatus={handleChangeStatus} editRemoveImage={editRemoveImage} isFromEdit={true}/> : 
                <DragableLayout imageData={imageData} setImageData={setImageData} handleChangeStatus={handleChangeStatus} editRemoveImage={editRemoveImage} isFromEdit={true}/>
            }
            <div>
            {imageData.length < maxFiles && input}
            </div>
        </div>
    }
    return (
        <div className={`item-sub-container-with-images`}>
            <div >
                <div className={`upload-image-label-with-images`}>
                    <div className="tips-posting-container">
                    <p>{`Tips : Re-arrange photos to change cover`}</p>
                    </div>
                <Dropzone
                    onChangeStatus={handleChangeStatus}
                    accept="image/*"
                    maxFiles={imageData.length === 0 ? 4 : 2^53 - 1}
                    chunking={true}
                    inputContent={<SubmitBtn />}
                    initialFiles={[imageFiles]}
                    inputWithFilesContent={<SubmitBtn />}
                    LayoutComponent={imageData.length > 0 ? (e) => renderPreview(e) : null}
                    canRemove={true}
                    classNames={{ inputLabelWithFiles: 'select-photos-with-files', preview: 'previewCont' }}
                    required />
                {
                    imageData.length === 0 || imageData.length === 4 ? null :
                    
                    <div style={{ textAlign: 'center', marginTop: '30.25px', marginBottom: 20 }}>
                        <p className="desktop-label">{`or drag photos here`}</p>
                        <p className="desktop-label">{`Up to 4 images`}</p>
                    </div>
                }
                </div>
            </div>
            </div>
    )
}

export default ImageContainer