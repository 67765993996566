import { USER_ITEM } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { itemUserSuccess, itemUserFailed, itemUserLoadMoreSuccess } from '../action';
import  userItems from '../../graphql/userItems'

function* itemUserSaga(action) {
    const { payload } = action
    const first = payload && payload.first ? payload.first : 15
    const page = payload.page ? payload.page : 1
    const user_id = payload.user_id ? payload.user_id : null
    const orderField = payload.orderField ? payload.orderField : "dt_pub_date"
    const orderType = payload.orderType ? payload.orderType : "DESC"

    try {
      const result = yield userItems.getUserItems(first, page, user_id, orderField, orderType)
      if(result === 403) {
        yield put(itemUserFailed('Something went wrong try again!'))
      }
      else {
        const data = result && result.data && result.data.publicGetItemsByUser ? result.data.publicGetItemsByUser : {}
        yield put(itemUserSuccess(data));
        }
      } catch (error) {
        yield put(itemUserFailed(error))
    }
    
}    

export function* watchItemUserSaga() {
  yield takeLatest(USER_ITEM.LOAD, itemUserSaga);
}

function* itemMoreUserSaga(action){
  const { payload } = action
  const first = payload && payload.first ? payload.first : 15
  const page = payload.page ? payload.page : 1
  const user_id = payload.user_id ? payload.user_id : null
  const orderField = payload.orderField ? payload.orderField : "dt_pub_date"
  const orderType = payload.orderType ? payload.orderType : "DESC"
  try {
    const result = yield userItems.getUserItems(first, page, user_id, orderField, orderType)
    if(result === 403) {
      yield put(itemUserFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.publicGetItemsByUser ? result.data.publicGetItemsByUser : {}
      yield put(itemUserLoadMoreSuccess(data));
      }
    } catch (error) {
      yield put(itemUserFailed(error))
  }
}

export function* watchItemMoreUserSaga() {
  yield takeLatest(USER_ITEM.LOAD_MORE, itemMoreUserSaga);
}