import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { CoinContext } from '../../../../index';
import { styles } from '../../steps/style'
import Loader from '../../../../../../../../common/component/loader'
import { MoneyIcon } from '../../../../../../../../assets/svg'
import paypalLogo from '../../../../../../../../assets/images/Credit-Card-Logo/paypal-md.png'
import {resetPaypal} from '../../../../../../../../common/helper/utility'

const PayPal = () => {
    const value = useContext(CoinContext);
    const { purchaseData, purchaseCoin, isLoadingPurchaseCoin, handleBack, errorPurchaseCoin, purchaseSuccessMessage, purchaseCoinData, purchaseCoinForceLoad, session } = value
    const userId = session && session.id ? parseInt(session.id)  : null
    const [loaded, setLoaded] = useState(false)
    const [paypalError, setPaypalError] = useState(false)
    const [lifetimeItem, setLifetimeItem] = useState([])

    let paypalRef = useRef()

    const processPurchaseUnits = () => {
        let arr = []
        const purchaseDataArr = [purchaseData]
        purchaseDataArr.map(data => {
            const price = data && data.sg_price ? data.sg_price : 1
            const quantity = data && data.quantity ? data.quantity : 1

            const obj = {
                name: data.name,
                description: data.description,
                unit_amount: {
                    currency_code: "SGD",
                    value: price.toFixed(2)
                },
                quantity: quantity
            }
            arr.push(obj)
        })
        setLifetimeItem(arr)
    }

    useEffect(() => {
        processPurchaseUnits()
    }, [purchaseData])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: userId, method: method}
            data.push(obj)
        })
        return data
    }, [purchaseData])

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    const handleForcePurchase = () => {
        const line_items_data = parsedLineItems([purchaseData], 'paypal')
        const boostData = {credit: purchaseData.credit, payment_method: 'paypal', type: 'coin_bundle', total_amount: purchaseData.sg_price, line_items_detail: line_items_data }
        purchaseCoin(boostData, { order_id: 'order.id' })
    }

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() =>{
            window.paypal.Buttons({
                style: {
                    label: 'paypal',
                    width: '50%'
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                description: 'Fk Coin',
                                amount: {
                                    currency_code: "SGD",
                                    value: purchaseData.sg_price.toFixed(2),
                                    breakdown: {
                                        item_total: {
                                            currency_code: "SGD",
                                            value: purchaseData.sg_price.toFixed(2)
                                        }
                                    }
                                },
                                items: lifetimeItem
                            }
                        ]
                    })
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture()
                    if (order.status === 'COMPLETED') {
                        const line_items_data = parsedLineItems([purchaseData], 'paypal')
                        const boostData = {credit: purchaseData.credit, payment_method: 'paypal', type: 'coin_bundle', total_amount: purchaseData.sg_price, line_items_detail: line_items_data }
                        purchaseCoin(boostData, { order_id: order.id })

                    } else {
                        setPaypalError("Error processing payment, try again.")
                    }
                },
                onError: async (e) => {
                    const error = await e
                    console.log('error', error)
                    setPaypalError(JSON.stringify(error))
                    setTimeout(() => {
                        setPaypalError(false)
                    }, 3000)
                }
            }).render(paypalRef);            
            })
        }
    }

    return (
        <div>
            <div style={{ width: '100%', borderBottom: '0.5px solid #C4C4C4', marginBottom: '31.5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p style={{ marginRight: 4 }}>{`Purchase`}</p><MoneyIcon /><p style={{ color: '#FB770D', marginLeft: '3.6px' }}>{purchaseData.credit}</p>
                    </div>
                    <img src={paypalLogo} alt="paypal logo" width="60px" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#232A34' }}>{`Paypal`}</p>
                    </div>
                    <div onClick={() => handleBack()}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#FB770D', cursor: 'pointer' }}>{`Change`}</p>
                    </div>
                </div>
            </div>
            <div style={styles.bundleContainer}>
                {
                    paypalError ? (
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ color: 'red' }}>{paypalError}</p>
                        </div>
                    ) :
                        isLoadingPurchaseCoin ? (
                            <Loader />
                        ) :
                            errorPurchaseCoin ? (
                                <div style={{ textAlign: 'center' }}>
                                    <p style={{ color: 'red' }}>{errorPurchaseCoin}</p>
                                </div>
                            ) :
                                purchaseSuccessMessage ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'green' }}>{purchaseSuccessMessage}</p>
                                    </div>
                                ) :
                                    (
                                        <div >
                                            <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                                                <div ref={v => (paypalRef = v)}></div>
                                                {/* <button onClick={() => handleForcePurchase()}>Force Purchased</button> */}
                                            </div>
                                        </div>
                                    )
                }
            </div>
        </div>
    );
}

export default PayPal;