import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { administratorLoad, administratorProfileLoad, administratorProfileReset, adminUpsertAdministratorLoad, adminUpsertAdministratorReset,
    administratorDeleteLoad, administratorDeleteReset
} from '../../../../../redux/action'
import { AdminList, AdministratorModal } from './component/CustomComponent'
import {  AdministratorContext } from '../../../../../common/context'
import Loader from '../../../../../common/component/loader'

const AdminSection = (props) => {
    const { 
        administratorData, administratorIsLoading, administratorError, administratorPage,
        administratorProfileIsLoading, administratorProfileData, administratorProfileError,
        adminUpsertAdministratorIsLoading, adminUpsertAdministratorError,adminUpsertAdministratorMessage,
        adminDeleteAdministratorIsLoading, adminDeleteAdministratorError, adminDeleteAdministratorMessage
    } = props.admin


    useEffect(() => {
        if (administratorData && administratorData.length === 0) {
            props.administratorLoad({first: 20, page: administratorPage})
        }
    // eslint-disable-next-line     
    },[])

    useEffect(()=>{
        if (adminUpsertAdministratorMessage) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertAdministratorReset()
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminUpsertAdministratorMessage])

    //user modal
    const [isShow, setIsShow] = useState(false)

    const fetchUserProfile = (id) => {
        setIsShow(true)
        props.administratorProfileLoad({pk_i_id: id})
    }

    const closeUserModal = () => {
        props.administratorProfileReset()
        setIsShow(false)
    }

    const upsertUser = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertAdministratorLoad(data)
    }

    const deleteAdministrator = (id) => {
        props.administratorDeleteLoad(id)
    }

    return (
    <AdministratorContext.Provider value = {{
        administratorData, fetchUserProfile, setIsShow, isShow, 
        administratorProfileIsLoading, administratorProfileData, administratorProfileError, closeUserModal, upsertUser,
        adminUpsertAdministratorIsLoading, adminUpsertAdministratorError,adminUpsertAdministratorMessage,
        adminDeleteAdministratorIsLoading, adminDeleteAdministratorError, adminDeleteAdministratorMessage, deleteAdministrator

    }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Administrator</h3>
            <section>
            {administratorIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                administratorError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{administratorError}</p></div></article> :
                <article style={{flexDirection: 'column'}}>
                    <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={() => setIsShow(true)} className="sellButton" style={{margin: 10, alignSelf: 'flex-start'}}>
                            <span>Add administrator</span>
                        </div>
                    </div>
                    <AdminList type='load'/>
                </article>
            }           
            </section> 
        </section> 
        {isShow ? <AdministratorModal /> : null }
    </AdministratorContext.Provider>    
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    administratorLoad,
    administratorProfileLoad,
    administratorProfileReset,
    adminUpsertAdministratorLoad, 
    adminUpsertAdministratorReset,
    administratorDeleteLoad, 
    administratorDeleteReset
}
  
export default connect(mapStateToProps, mapActionToProps)(AdminSection);
