import React, { useEffect, useState, useRef, useCallback } from 'react';
import {resetPaypal} from '../../../common/helper/utility'

const LifetimePlanButton = (props) => {
    const {location} = props
    const type = new URLSearchParams(location.search).get("type")
    const userId = new URLSearchParams(location.search).get("userId")
    const webViewData = new URLSearchParams(location.search).get("data")
    const total = new URLSearchParams(location.search).get("total")

    const purchaseData = webViewData ? JSON.parse(webViewData) : null
    const [loaded, setLoaded] = useState(false)
    const [paypalError, setPaypalError] = useState(false)
    const [orderItem, setOrderItem] = useState([])

    let paypalRef = useRef()

    const [totalValue, setTotalValue] = useState(0)

    const processPurchaseUnits = () => {
        let arr = []
        let tempTotal = 0
        purchaseData && purchaseData.map(data => {
            const price = data && data.sg_price ? data.sg_price : 1
            const quantity = data && data.quantity ? data.quantity : 1

            const obj = {
                name: data.name,
                description: data.description,
                unit_amount: {
                    currency_code: "SGD",
                    value: price.toFixed(2)
                },
                quantity: quantity
            }
            arr.push(obj)
            tempTotal = +tempTotal + +price
        })
        setTotalValue(tempTotal.toFixed(2))
        setOrderItem(arr)
    }

    useEffect(() => {
        processPurchaseUnits()
    }, [webViewData])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: userId, method: method}
            data.push(obj)
        })
        return data
    }, [webViewData])

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() =>{
            window.paypal.Buttons({
                style: {
                    label: 'paypal',
                    width: '50%'
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                description: 'Fk Lifetime Plan',
                                amount: {
                                    currency_code: "SGD",
                                    value: totalValue,
                                    breakdown: {
                                        item_total: {
                                            currency_code: "SGD",
                                            value: totalValue
                                        }
                                    }
                                },
                                items: orderItem
                            }
                        ]
                    })
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture()
                    if (order.status === 'COMPLETED') {
                        const line_items_data = parsedLineItems(purchaseData, 'paypal')
                        const orderData = {credit: null, payment_method: 'paypal', type: 'otp', total_amount: totalValue, line_items_detail: line_items_data, order_status: 'success' }
                        window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(JSON.stringify(orderData, { order_id: order.id }));
                    } else {
                        setPaypalError("Error processing payment, try again.")
                        setTimeout(() => {
                            setPaypalError(false)
                        }, 3000)
                    }
                },
                onError: async (e) => {
                    const error = await e
                    const orderData = {order_status: error}
                    window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage(JSON.stringify(orderData));
                    setPaypalError(JSON.stringify(error))
                    setTimeout(() => {
                        setPaypalError(false)
                    }, 3000)
                }
                }).render(paypalRef);            
            })
        }
    }

    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', background: 'transparent', height: '100%'}}>
            { paypalError ? 
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: 'red' }}>{paypalError}</p>
                </div> 
            : 
                <div>
                    <div ref={v => (paypalRef = v)}></div>
                </div>
            }
        </div>
    );
}

export default LifetimePlanButton;