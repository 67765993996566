import React, {useContext, useEffect } from 'react';
import WebChatFilter from './child/WebChatFilter'
import WebChatInbox from './child/WebChatInbox'
import WebChatHeader from './child/WebChatHeader'
import WebChatHistory from './child/WebChatHistory'
import WebChatInput from './child/WebChatInput'
import { ChatContext } from '../../../../../common/context'

const WebChat = () => {
  const value = useContext(ChatContext)
  const { chatProps, activeIndex, setMessagesData, chatRoomData } = value

  useEffect(() => {
      const data = chatProps && chatProps.data && chatProps.data[activeIndex] ? chatProps.data[activeIndex] : []
      setMessagesData(data)
      window.focus({preventScroll: true})
  // eslint-disable-next-line
  }, [chatProps])

  return (
    <>
      <div className="people-list" id="people-list">
        <WebChatFilter/>
        <WebChatInbox/>
      </div>
      <div className="chat">
        {chatRoomData && chatRoomData.length === 0 ? null :
          <>
            <WebChatHeader/>
            <WebChatHistory/>
            <WebChatInput/>
          </> 
        }
      </div> 
    </>
  )
}

export default WebChat;