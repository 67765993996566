import { CATEGORY, CATEGORY_SEARCH, CATEGORY_SEARCH_MORE } from '../constant'

export const categoryLoad = () =>{
    return {
        type: CATEGORY.LOAD,
    }
}    

export const categorySuccess = (payload) =>{
    return {
        type: CATEGORY.SUCCESS,
        payload,
    }
}

export const categoryFailed = (payload) =>{
    return {
        type: CATEGORY.FAILED,
        payload,
    }
}

export const categorySearchLoad = (payload) => {
    return {
        type: CATEGORY_SEARCH.LOAD,
        payload
    }
}

export const categorySearchSuccess = (payload) => {
    return {
        type: CATEGORY_SEARCH.SUCCESS,
        payload
    }
}

export const categorySearchFail = (payload) => {
    return {
        type: CATEGORY_SEARCH.FAIL,
        payload
    }
}

export const categorySearchReset = () => {
    return {
        type: CATEGORY_SEARCH.RESET,
    }
}

export const categorySearchMoreLoad = (payload) => {
    return {
        type: CATEGORY_SEARCH_MORE.LOAD,
        payload
    }
}

export const categorySearchMoreSuccess = (payload) => {
    return {
        type: CATEGORY_SEARCH_MORE.SUCCESS,
        payload
    }
}