import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { theme } from '../../theme'
import {CoinsIcon, NavbarHeartIcon, MessageIcon, AlertRedIcon} from '../../../assets/svg'
import Avatar from '../../avatar'
import MenuButton from '../../buttons/menu'
import { connect } from 'react-redux'
import { chatLoad, watchlistLoad, sessionLoad } from '../../../redux/action'

const Navbar = (props) => {
    const type = props && props.type ? props.type : false
    const data = props.chat && props.chat.data ? props.chat.data : []
    const [totalUnread, setTotalUnread] = useState(0)
    const watchlistData = props.watchlist && props.watchlist.data ? props.watchlist.data : []
    const wallet = props.me && props.me.sessionData && props.me.sessionData.wallet ? props.me.sessionData.wallet : 0
    const pk_i_id = props.me && props.me.sessionData && props.me.sessionData.pk_i_id ? props.me.sessionData.pk_i_id : false
    const loginAsUser = props.session && props.session.fkv2_session && props.session.fkv2_session.login_as_admin ? props.session.fkv2_session.login_as_admin : false

    useEffect(() => {
        if (props.id) {
            props.sessionLoad({ id: props.id })
            if (data.length === 0) {
                props.chatLoad({ user_id: props.id })
            }
            if (watchlistData.length === 0) {
                props.watchlistLoad({ user_id: props.id })
            }
        }
        // eslint-disable-next-line
    }, [props.id])

    useEffect(() => {
        let total = 0
        // eslint-disable-next-line 
        data.map((item) => {
            if (item && item.chat_messages.length > 0) {
                // eslint-disable-next-line 
                item.chat_messages.map(chat => {
                    if (!chat.read_at && chat.sender_id !== parseInt(props.id)) {
                        total++
                    }
                })
            }
        })
        setTotalUnread(total)
        // eslint-disable-next-line    
    }, [props.chat])

    return (
        type && pk_i_id ?
            <div className="linkGroup" id="linkGroup">
                <div className="desktop">
                    <div className="topupContainer">
                        <NavLink exact to={"/fk-prime"} className="fk-prime-navbar-text-default-p">FK Prime</NavLink>
                    </div>
                    <div className="creditsContainer">
                        <NavLink exact to={"/user/wallet"} activeStyle={style.active} style={style.default}><CoinsIcon /> &nbsp;<span className="navbar-coins-amount-label-p" >{wallet}</span></NavLink>
                    </div>
                    <div className="userMessages">
                        <NavLink exact to={"/user/likes"} activeStyle={style.active} style={style.like}><NavbarHeartIcon /></NavLink>
                    </div>
                    <div className="userMessages">
                        {totalUnread > 0 ? <div style={style.unread}><AlertRedIcon /></div> : null}
                        <NavLink exact to={"/user/messages/inbox"} activeStyle={style.active} style={style.messageBtn}><MessageIcon /></NavLink>
                    </div>
                    {loginAsUser ? 
                        <div className="creditsContainer">
                            <span style={style.default}>{`Login as User`}</span>
                        </div> : null
                    }
                    <div className="userAvatar">
                        <Avatar showToggle={props.showToggle} show={props.show} id={props.id} type={props.type} />
                    </div>
                    <div className="sellBtn">
                        <Link to="/user/item/new" style={{ ...style.sellButton}}>Sell</Link>
                    </div>
                </div>
                <div className="mobile">
                    <MenuButton showToggle={props.showToggle} toggleShow={props.toggleShow} id={props.id} unread={totalUnread} />
                </div>
            </div> :

            type && !pk_i_id ? null :
                <div className="linkGroup w-30" id="linkGroup" >
                    <div className="desktop_unsession">
                        <div className="topupContainer">
                            <NavLink exact to={"/login"} className="fk-prime-navbar-text-default-p">FK Prime</NavLink>
                        </div>
                        <div>
                            <NavLink exact to="/signup" activeStyle={style.active} style={style.textBtn}>Signup</NavLink>
                        </div>
                        <div className="navbarLoginBtn">
                            <NavLink exact to="/login" activeStyle={style.active} style={style.textBtn}>Login</NavLink>
                        </div>
                         <div className="sellBtn">
                            {/* <NavLink exact to="/login" activeStyle={style.active} style={style.default}><i className="fas fa-plus" /> Sell</NavLink> */}
                            <Link to="/login" style={style.sellButton}> Sell</Link>
                        </div>
                    </div>
                    <div className="mobile">
                        <MenuButton showToggle={props.showToggle} toggleShow={props.toggleShow} />
                    </div>
                </div>

    );
};

export const style = {
    default: {
        border: '1px solid #232A34',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 32,
        width: '72.83px'
    },
    textBtn: {
        fontFamily: 'Metropolis-Regular',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 17,
        lineHeight: '24px',
        color: '#232A34',
        letterSpacing: '0.44px'
    },
    like: {
        fontSize: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontFamily: theme.font.primary,
        padding: 5,
        color: '#CED6E3',
    },
    sellButton: {
        color: '#FFFFFF',
        textDecoration: 'none',
        padding: 5,
        // fontFamily: theme.font.primary,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        // fontSize: 16,
        // background: '#FB770D',
    },
    sellUnSessionBtn: {
        color: '#FFFFFF',
        textDecoration: 'none',
        padding: 5,
        fontFamily: theme.font.primary,
        fontWeight: '700',
        fontSize: 15,
    },
    messageBtn: {
        fontSize: 20,
        color: theme.color.white,
        textDecoration: 'none',
        padding: 5,
        fontFamily: theme.font.primary,
    },
    active: {
        color: theme.color.orange,
    },
    fkPrimeActive: {
        color: theme.color.orange,
        textDecoration: 'underline'
    },
    activeGradient: {
        backgroundColor: 'radial-gradient(circle, rgba(180,58,58,1) 0%, rgba(253,123,29,1) 50%, rgba(229,252,69,1) 100%)'
    },
    unread: {
        padding: 0,
        margin: 0,
        position: 'absolute',
        top: 10,
        right: '10%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    unreadText: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: 10
    },
    sell: {
        display: 'flex',
        textAlign: 'center',
        background: '#FB770D',
        padding: '13px 20px',
        borderRadius: '3px'
    },
    fkPrimeDefault: {
        marginRight: '20px',
        fontFamily: 'Metropolis-Regular'
    }
};

const mapStateToProps = store => {
    return {
        chat: store.chatReducer,
        watchlist: store.watchlistReducer,
        me: store.userReducer,
    };
};

const mapActionToProps = {
    chatLoad,
    watchlistLoad,
    sessionLoad
};

export default connect(mapStateToProps, mapActionToProps)(Navbar);

