export const theme = {
    about: [
        {text: 'FishingKaki.com is the largest fishing community conglomerate dedicated to catering to anglers worldwide. What initially began as a hobby website in 1997 has since evolved into the largest internet-based messaging boards for anglers in the ASEAN region and beyond.'},
        {text: 'The messaging board grew into an organized forum with circa half a million members currently from all over the world.'},
        {text: 'The introduction of the online classifieds in 2011 provided a stable platform for anglers to trade their pre-loved fishing equipment.'},
        {text: 'FishingKaki translates to “Fishing Buddy”. The word “Kaki” means “leg” in Malay language and is spoken in countries like Singapore, Malaysia and Indonesia; but as a slang, it means “buddy” or “companion”, as legs tend to be a pair or viewed as two. One could even say someone is your holiday kaki which says that person is your travel partner.'},
        {text: 'The site and all its properties are well known around the world as a source of discussion, with a focus in the area of Buying and Selling tackle.'},
        {text: 'When viewed as a Marketing Channel, it is important to look at FishingKaki as a sum of all its parts.'},
        {text: 'With an ever increasing demand for reliable fishing services, FishingKaki is endeavouring to provide our members with a one-stop fishing portal encompassing product sales and charter services worldwide in addition to the well established Forum and Classifieds.'}
    ]
}