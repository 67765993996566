import { SEARCH_SUGGEST } from '../constant'

export const suggestWordLoad = (payload) =>{
    return {
        type: SEARCH_SUGGEST.LOAD,
        payload
    }
}

export const suggestWordSuccess = (payload) => {
    return {
        type: SEARCH_SUGGEST.SUCCESS,
        payload
    }
}

export const suggestWordFail = (payload) => {
    return {
        type: SEARCH_SUGGEST.FAIL,
        payload
    }
}