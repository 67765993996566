export const SAMPLE_DATA = {
    LOAD: 'SAMPLE_DATA_LOAD',
    SUCCESS: 'SAMPLE_DATA_SUCCESS',
    FAIL: 'SAMPLE_DATA_FAIL',
}

export const LIST_DATA = {
    LOAD: 'LIST_DATA_LOAD',
    SUCCESS: 'LIST_DATA_SUCCESS'
}

export const CONTACT_DATA = {
    LOAD: 'CONTACT_DATA_LOAD',
    SUCCESS: 'CONTACT_DATA_SUCCESS',
    FAIL: 'CONTACT_DATA_FAIL',
    RESET: 'CONTACT_DATA_RESET'
}

export const ITEM = {
    LOAD: 'ITEM_LOAD',
    SUCCESS: 'ITEM_SUCCESS',
    FAIL: 'ITEM_FAIL',
    LOAD_MORE: 'ITEM_LOAD_MORE',
    LOAD_MORE_SUCCESS: 'ITEM_LOAD_MORE_SUCCESS',
    RESET: 'ITEM_RESET',
    SET_RESERVE: 'ITEM_SET_RESERVE',
    SET_SOLD: 'ITEM_SET_SOLD',
    SET_HIGHLIGHT: 'ITEM_SET_HIGHLIGHT',
    SET_TOP_AD: 'ITEM_SET_TOP_AD',
    SET_WATCHER: 'ITEM_SET_WATCHER',
}

export const ITEM_UPDATE = {
    LOAD: 'ITEM_UPDATE_LOAD',
    SUCCESS: 'ITEM_UPDATE_SUCCESS',
    FAIL: 'ITEM_UPDATE_FAIL',
    RESET: 'ITEM_UPDATE_RESET'
}

export const ITEM_DELETE = {
    LOAD: 'ITEM_DELETE_LOAD',
    SUCCESS: 'ITEM_DELETE_SUCCESS',
    FAIL: 'ITEM_DELETE_FAIL',
    RESET: 'ITEM_DELETE_RESET'
}

export const REVIEW = {
    LOAD: 'REVIEW_LOAD',
    SUCCESS: 'REVIEW_SUCCESS',
    FAIL: 'REVIEW_FAIL'
}

export const REVIEW_VIEW = {
    LOAD: 'REVIEW_VIEW_LOAD',
    SUCCESS: 'REVIEW_VIEW_SUCCESS',
    FAIL: 'REVIEW_VIEW_FAIL'
}

export const FILTER = {
    SET_RECENT_PRICE: 'FILTER_SET_RECENT_PRICE',
    RESET: 'FILTER_RESET',
}

export const USER_ITEM = {
    LOAD: 'USER_ITEM_LOAD',
    SUCCESS: 'USER_ITEM_SUCCESS',
    FAIL: 'USER_ITEM_FAIL',
    LOAD_MORE: 'USER_ITEM_LOAD_MORE',
    LOAD_MORE_SUCCESS: 'USER_ITEM_LOAD_MORE_SUCCESS',
    REALTIME_FIELD_UPDATE: 'USER_ITEM_REALTIME_FIELD_UPDATE',
    RESET: 'USER_ITEM_RESET'
}

export const USER_DATA = {
    LOAD: 'USER_DATA_LOAD',
    SUCCESS: 'USER_DATA_SUCCESS',
    FAIL: 'USER_DATA_FAIL',
    RESET: 'USER_DATA_RESET'
}

export const SESSION_USER = { 
    LOAD: 'SESSION_USER_LOAD',
    SUCCESS : 'SESSION_USER_SUCCESS',
    FAIL: 'SESSION_USER_FAIL'
}

export const NEW_USER = {
    LOAD: 'NEW_USER_LOAD',
    SUCCESS: 'NEW_USER_SUCCESS',
    FAIL: 'NEW_USER_FAIL'
}

export const UPDATE_USER = {
    LOAD: 'UPDATE_USER_LOAD',
    SUCCESS: 'UPDATE_USER_SUCCESS',
    FAIL: 'UPDATE_USER_FAIL'
}

export const ME = {
    LOAD: 'ME_LOAD',
    UPDATE: 'ME_UPDATE',
    SUCCESS: 'ME_SUCCESS',
    FAIL: 'ME_FAIL',
    RESET_MESSAGE: 'ME_RESET_MESSAGE'
}

export const ITEM_VIEW = {
    LOAD: 'ITEM_VIEW_LOAD',
    SUCCESS: 'ITEM_VIEW_SUCCESS',
    FAIL: 'ITEM_VIEW_FAIL',
    RESET: 'ITEM_VIEW_RESET',
    SET_RESERVE: 'ITEM_VIEW_SET_RESERVE',
    SET_SOLD: 'ITEM_VIEW_SET_SOLD',
    SET_TOP_AD: 'ITEM_VIEW_SET_TOP_AD',
    SET_HIGHLIGHT: 'ITEM_VIEW_SET_HIGHLIGHT',
    SET_WATCHER: 'ITEM_VIEW_SSET_WATCHER',
    REALTIME_FIELD_UPDATE: 'ITEM_VIEW_REALTIME_FIELD_UPDATE'
}

export const ITEM_VIEW_UPDATE_BY_FIELD = {
    LOAD: 'ITEM_VIEW_UPDATE_BY_FIELD_LOAD',
    SUCCESS: 'ITEM_VIEW_UPDATE_BY_FIELD_SUCCESS',
    FAIL: 'ITEM_VIEW_UPDATE_BY_FIELD_FAIL',
}

export const ITEM_IMAGE_EDIT = {
    LOAD: 'ITEM_IMAGE_EDIT_LOAD',
    SUCCESS: 'ITEM_IMAGE_EDIT_SUCCESS',
    FAIL: 'ITEM_IMAGE_EDIT_FAIL',
    RESET: 'ITEM_IMAGE_EDIT_RESET',
}


export const EMAIL_VERIFY = {
    LOAD: 'EMAIL_VERIFY_LOAD',
    UPDATE: 'EMAIL_VERIFY_UPDATE',
    SUCCESS: 'EMAIL_VERIFY_SUCCESS',
    FAIL: 'EMAIL_VERIFY_FAIL',
    CLEAR_MESSAGE: 'EMAIL_VERIFY_CLEAR_MESSAGE'
}

export const RESEND_EMAIL_VERIFICATION = {
    LOAD: 'RESEND_EMAIL_VERIFICATION_LOAD',
    SUCCESS: 'RESEND_EMAIL_VERIFICATION_SUCCESS',
    FAIL: 'RESEND_EMAIL_VERIFICATION_FAIL',
    RESET: 'RESEND_EMAIL_VERIFICATION_RESET'
}

export const CATEGORY = {
    LOAD: 'CATEGORY_LOAD',
    SUCCESS: 'CATEGORY_SUCCESS',
    FAIL: 'CATEGORY_FAIL'
}

export const CATEGORY_SEARCH = {
    LOAD: 'CATEGORY_SEARCH_LOAD',
    SUCCESS: 'CATEGORY_SEARCH_SUCCESS',
    FAIL: 'CATEGORY_SEARCH_FAIL',
    RESET: 'CATEGORY_SEARCH_RESET',
}

export const CATEGORY_SEARCH_MORE = {
    LOAD: 'CATEGORY_SEARCH_MORE_LOAD',
    SUCCESS: 'CATEGORY_SEARCH_MORE_SUCCESS',
    FAIL: 'CCATEGORY_SEARCH_MORE_FAIL',
}

export const ITEM_CREATE = {
    LOAD: 'ITEM_CREATE_LOAD',
    SUCCESS: 'ITEM_CREATE_SUCCESS',
    FAIL: 'ITEM_CREATE_FAIL',
    RESET: 'ITEM_CREATE_RESET'
}

export const CHANGE_PASSWORD = {
    LOAD: 'CHANGE_PASSWORD_LOAD',
    SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    FAIL: 'CHANGE_PASSWORD_FAIL',
    CLEAR_MESSAGE: 'CHANGE_PASSWORD_CLEAR_MESSAGE'
}

export const SEND_FORGOT_PASSWORD = {
    LOAD: 'SEND_FORGOT_PASSWORD_LOAD',
    SUCCESS: 'SEND_FORGOT_PASSWORD_SUCCESS',
    FAIL: 'SEND_FORGOT_PASSWORD_FAIL',
    CLEAR_MESSAGE: 'SEND_FORGOT_PASSWORD_CLEAR_MESSAGE'
}

export const AVATAR = {
    LOAD: 'AVATAR_LOAD',
    SUCCESS: 'AVATAR_SUCCESS',
    FAIL: 'AVATAR_FAIL',
    UPDATE: 'AVATAR_UPDATE'
}

export const DBS_TOKEN_REQUEST = {
    LOAD: 'DBS_TOKEN_REQUEST_LOAD',
    SUCCESS: 'DBS_TOKEN_REQUEST_SUCCESS',
    FAIL: 'DBS_TOKEN_REQUEST_FAIL',
}

export const CHAT = {
    LOAD: 'CHAT_LOAD',
    SUCCESS: 'CHAT_SUCCESS',
    FAIL: 'CHAT_FAIL',
    ADD_MESSAGE: 'CHAT_ADD_MESSAGE',
    ADD_ROOM: 'CHAT_ADD_ROOM',
    UPDATE_UNREAD: 'CHAT_UPDATE_UNREAD',
    SET_ACTIVE_INDEX: 'CHAT_SET_ACTIVE_INDEX',
    SET_ISTHREAD: 'CHAT_SET_ISTHREAD'
}

export const ADD_ROOM = {
    LOAD: 'CHAT_ADD_ROOM_LOAD',
    SUCCESS: 'CHAT_ADD_ROOM_SUCCESS',
    FAIL: 'CHAT_ADD_ROOM_FAIL',
    RESET: 'CHAT_ADD_ROOM_RESET'
}

export const SEND_CHAT = {
    LOAD: 'SEND_CHAT_LOAD',
    SUCCESS: 'SEND_CHAT_SUCCESS',
    FAIL: 'SEND_CHAT_FAIL',
    RESET: 'SEND_CHAT_RESET'
}

export const SET_CHAT_ITEM = {
    RESERVE: 'SET_CHAT_ITEM_RESERVE',
    SOLD: 'SET_CHAT_ITEM_SOLD'
}

export const DELETE_CHAT = {
    LOAD: 'DELETE_CHAT_LOAD',
    SUCCESS: 'DELETE_CHAT_SUCCESS',
    FAIL: 'DELETE_CHAT_FAIL',
    RESET: 'DELETE_CHAT_RESET'
}

export const SEND_REVIEW = {
    LOAD: 'SEND_REVIEW_LOAD',
    SUCCESS: 'SEND_REVIEW_SUCCESS',
    FAIL: 'SEND_REVIEW_FAIL',
    RESET: 'SEND_REVIEW_RESET'
}

export const SEARCH_WORD = {
    LOAD: 'SEARCH_WORD_LOAD',
    SUCCESS: 'SEARCH_WORD_SUCCESS',
    FAIL: 'SEARCH_WORD_FAIL',
    RESET: 'SEARCH_WORD_RESET'
}

export const SEARCH_WORD_MORE = {
    LOAD: 'SEARCH_WORD_MORE_LOAD',
    SUCCESS: 'SEARCH_WORD_MORE_SUCCESS',
    FAIL: 'SEARCH_WORD_MORE_FAIL',
}

export const SEARCH_SUGGEST = {
    LOAD: 'SEARCH_SUGGEST_LOAD',
    SUCCESS: 'SEARCH_SUGGEST_SUCCESS',
    FAIL: 'SEARCH_SUGGEST_FAIL'
}

export const ADD_TOP_SEARCH = {
    LOAD: 'ADD_TOP_SEARCH_LOAD',
}

export const SERVICES = {
    LOAD: 'SERVICES_LOAD',
    SUCCESS: 'SERVICES_SUCCESS',
    FAIL: 'SERVICES_FAIL'
}

export const PROMOTE = {
    LOAD: 'PROMOTE_LOAD',
    SUCCESS: 'PROMOTE_SUCCESS',
    FAIL: 'PROMOTE_FAIL',
    RESET: 'PROMOTE_RESET',
}

export const WATCHLIST = {
    LOAD: 'WATCHLIST_LOAD',
    SUCCESS: 'WATCHLIST_SUCCESS',
    FAIL: 'WATCHLIST_FAIL',
    LIKE_DISLIKE_LOAD: 'LIKE_DISLIKE_LOAD',
    LIKE_DISLIKE_SUCCESS: 'LIKE_DISLIKE_SUCCESS',
    LIKE_DISLIKE_FAIL: 'LIKE_DISLIKE_FAIL'
}

export const PURCHASE_COIN = {
    LOAD: 'PURCHASE_COIN_LOAD',
    SUCCESS: 'PURCHASE_COIN_SUCCESS',
    FAIL: 'PURCHASE_COIN_FAIL',
    RESET: 'PURCHASE_COIN_RESET',
}

export const COLLECT_PROMO = {
    LOAD: 'COLLECT_PROMO_LOAD',
    SUCCESS: 'COLLECT_PROMO_SUCCESS',
    FAIL: 'COLLECT_PROMO_FAIL',
    RESET: 'COLLECT_PROMO_RESET',
}

export const REPORT = {
    LOAD: 'REPORT_LOAD',
    SUCCESS: 'REPORT_SUCCESS',
    FAIL: 'REPORT_FAIL',
    RESET: 'REPORT__RESET',
}

export const LOGOUT = {
    LOAD: 'LOGOUT_LOAD',
    SUCCESS: 'LOGOUT_SUCCESS',
    FAIL: 'LOGOUT_FAIL',
    RESET: 'LOGOUT_RESET',
}

export const ADMIN = {
    LOGIN: {
        LOAD: 'ADMIN_LOGIN_LOAD',
        SUCCESS: 'ADMIN_LOGIN_SUCCESS',
        FAIL: 'ADMIN_LOGIN_FAIL',
        RESET: 'ADMIN_LOGIN_RESET'
    },
    SEND_FORGOT_PASSWORD: {
        LOAD: 'SEND_ADMIN_FORGOT_PASSWORD_LOAD',
        SUCCESS: 'SEND_ADMIN_FORGOT_PASSWORD_SUCCESS',
        FAIL: 'SEND_ADMIN_FORGOT_PASSWORD_FAIL',
        CLEAR_MESSAGE: 'SEND_ADMIN_FORGOT_PASSWORD_CLEAR_MESSAGE'
    },
    CHANGE_PASSWORD: {
        LOAD: 'ADMIN_CHANGE_PASSWORD_LOAD',
        SUCCESS: 'ADMIN_CHANGE_PASSWORD_SUCCESS',
        FAIL: 'ADMIN_CHANGE_PASSWORD_FAIL',
        CLEAR_MESSAGE: 'ADMIN_CHANGE_PASSWORD_CLEAR_MESSAGE'
    },
    USER: {
        LOAD: 'ADMIN_USER_LOAD',
        SUCCESS: 'ADMIN_USER_SUCCESS',
        FAIL: 'ADMIN_USER_FAIL',
        RESET: 'ADMIN_USER_RESET'
    },
    USER_PROFILE: {
        LOAD: 'ADMIN_USER_PROFILE_LOAD',
        SUCCESS: 'ADMIN_USER_PROFILE_SUCCESS',
        FAIL: 'ADMIN_USER_PROFILE_FAIL',
        RESET: 'ADMIN_USER_PROFILE_RESET'
    },
    USER_UPSERT: {
        LOAD: 'ADMIN_USER_UPSERT_LOAD',
        SUCCESS: 'ADMIN_USER_UPSERT_SUCCESS',
        FAIL: 'ADMIN_USER_UPSERT_FAIL',
        RESET: 'ADMIN_USER_UPSERT_RESET'
    },
    USER_SUGGEST: {
        LOAD: 'ADMIN_USER_SUGGEST_LOAD',
        SUCCESS: 'ADMIN_USER_SUGGEST_SUCCESS',
        FAIL: 'ADMIN_USER_SUGGEST_FAIL',
        RESET: 'ADMIN_USER_SUGGEST_RESET'
    },
    ITEM: {
        LOAD: 'ADMIN_ITEM_LOAD',
        SUCCESS: 'ADMIN_ITEM_SUCCESS',
        FAIL: 'ADMIN_ITEM_FAIL',
        RESET: 'ADMIN_ITEM_RESET'
    },
    ITEM_DETAIL: {
        LOAD: 'ADMIN_ITEM_DETAIL_LOAD',
        SUCCESS: 'ADMIN_ITEM_DETAIL_SUCCESS',
        FAIL: 'ADMIN_ITEM_DETAIL_FAIL',
        RESET: 'ADMIN_ITEM_DETAIL_RESET'
    },
    ITEM_UPDATE: {
        LOAD: 'ADMIN_ITEM_UPDATE_LOAD',
        SUCCESS: 'ADMIN_ITEM_UPDATE_SUCCESS',
        FAIL: 'ADMIN_ITEM_UPDATE_FAIL',
        RESET: 'ADMIN_ITEM_UPDATE_RESET',
    },
    ITEM_DELETE: {
        LOAD: 'ADMIN_ITEM_DELETE_LOAD',
        SUCCESS: 'ADMIN_ITEM_DELETE_SUCCESS',
        FAIL: 'ADMIN_ITEM_DELETE_FAIL',
        RESET: 'ADMIN_ITEM_DELETE_RESET',
    },
    ITEM_SUGGEST: {
        LOAD: 'ADMIN_ITEM_SUGGEST_LOAD',
        SUCCESS: 'ADMIN_ITEM_SUGGEST_SUCCESS',
        FAIL: 'ADMIN_ITEM_SUGGEST_FAIL',
        RESET: 'ADMIN_ITEM_SUGGEST_RESET'
    },
    ITEM_BY_USER: {
        LOAD: 'ADMIN_ITEM_BY_USER_LOAD',
        SUCCESS: 'ADMIN_ITEM_BY_USER_SUCCESS',
        FAIL: 'ADMIN_ITEM_BY_USER_FAIL',
        RESET: 'ADMIN_ITEM_BY_USER_RESET'
    },
    SALE: {
        LOAD: 'ADMIN_SALE_LOAD',
        SUCCESS: 'ADMIN_SALE_SUCCESS',
        FAIL: 'ADMIN_SALE_FAIL',
        RESET: 'ADMIN_SALE_RESET'
    },
    SALE_DETAIL: {
        LOAD: 'ADMIN_SALE_DETAIL_LOAD',
        SUCCESS: 'ADMIN_SALE_DETAIL_SUCCESS',
        FAIL: 'ADMIN_SALE_DETAIL_FAIL',
        RESET: 'ADMIN_SALE_DETAIL_RESET'
    },
    SALE_SUGGEST: {
        LOAD: 'ADMIN_SALE_SUGGEST_LOAD',
        SUCCESS: 'ADMIN_SALE_SUGGEST_SUCCESS',
        FAIL: 'ADMIN_SALE_SUGGEST_FAIL',
        RESET: 'ADMIN_SALE_SUGGEST_RESET'
    },
    PREFERENCE: {
        LOAD: 'ADMIN_PREFERENCE_LOAD',
        SUCCESS: 'ADMIN_PREFERENCE_SUCCESS',
        FAIL: 'ADMIN_PREFERENCE_FAIL',
        RESET: 'ADMIN_PREFERENCE_RESET'
    },
    PREFERENCE_UPSERT: {
        LOAD: 'ADMIN_PREFERENCE_UPSERT_LOAD',
        SUCCESS: 'ADMIN_PREFERENCE_UPSERT_SUCCESS',
        FAIL: 'ADMIN_PREFERENCE_UPSERT_FAIL',
        RESET: 'ADMIN_PREFERENCE_UPSERT_RESET'
    },
    PREFERENCE_DELETE: {
        LOAD: 'ADMIN_PREFERENCE_DELETE_LOAD',
        SUCCESS: 'ADMIN_PREFERENCE_DELETE_SUCCESS',
        FAIL: 'ADMIN_PREFERENCE_DELETE_FAIL',
    },
    PREFERENCE_SUGGEST: {
        LOAD: 'ADMIN_PREFERENCE_SUGGEST_LOAD',
        SUCCESS: 'ADMIN_PREFERENCE_SUGGEST_SUCCESS',
        FAIL: 'ADMIN_PREFERENCE_SUGGEST_FAIL',
        RESET: 'ADMIN_PREFERENCE_SUGGEST_RESET'
    },
    CATEGORY: {
        LOAD: 'ADMIN_CATEGORY_LOAD',
        SUCCESS: 'ADMIN_CATEGORY_SUCCESS',
        FAIL: 'ADMIN_CATEGORY_FAIL',
        RESET: 'ADMIN_CATEGORY_RESET'
    },
    CATEGORY_UPSERT: {
        LOAD: 'ADMIN_CATEGORY_UPSERT_LOAD',
        SUCCESS: 'ADMIN_CATEGORY_UPSERT_SUCCESS',
        FAIL: 'ADMIN_CATEGORY_UPSERT_FAIL',
        RESET: 'ADMIN_CATEGORY_UPSERT_RESET'
    },
    SESSION_ADMIN: {
        LOAD: 'SESSION_ADMIN_LOAD',
        SUCCESS : 'SESSION_ADMIN_SUCCESS',
        FAIL: 'SESSION_ADMIN_FAIL'
    },
    REPORT: {
        ITEM: {
            LOAD: 'ADMIN_REPORT_LOAD',
            SUCCESS: 'ADMIN_REPORT_SUCCESS',
            FAIL: 'ADMIN_REPORT_FAIL',
            RESET: 'ADMIN_REPORT_RESET'
        },
        USER: {
            LOAD: 'ADMIN_REPORT_LOAD',
            SUCCESS: 'ADMIN_REPORT_SUCCESS',
            FAIL: 'ADMIN_REPORT_FAIL',
            RESET: 'ADMIN_REPORT_RESET'
        }
    },
    CUSTOM_FIELD: {
        LOAD: 'ADMIN_CUSTOM_FIELD_LOAD',
        SUCCESS: 'ADMIN_CUSTOM_FIELD_SUCCESS',
        FAIL: 'ADMIN_CUSTOM_FIELD_FAIL',
        RESET: 'ADMIN_CUSTOM_FIELD_RESET'
    },
    CUSTOM_FIELD_UPSERT: {
        LOAD: 'ADMIN_CUSTOM_FIELD_UPSERT_LOAD',
        SUCCESS: 'ADMIN_CUSTOM_FIELD_UPSERT_SUCCESS',
        FAIL: 'ADMIN_CUSTOM_FIELD_UPSERT_FAIL',
        RESET: 'ADMIN_CUSTOM_FIELD_UPSERT_RESET'
    },
    CUSTOM_FIELD_DELETE: {
        LOAD: 'ADMIN_CUSTOM_FIELD_DELETE_LOAD',
        SUCCESS: 'ADMIN_CUSTOM_FIELD_DELETE_SUCCESS',
        FAIL: 'ADMIN_CUSTOM_FIELD_DELETE_FAIL',
    },
    DASHBOARD: {
        LOAD: 'ADMIN_DASHBOARD_LOAD',
        SUCCESS: 'ADMIN_DASHBOARD_SUCCESS',
        FAIL: 'ADMIN_DASHBOARD_FAIL',
        RESET: 'ADMIN_DASHBOARD_RESET'
    },

    ADMINISTRATOR: {
        LOAD: 'ADMIN_ADMINISTRATOR_LOAD',
        SUCCESS: 'ADMIN_ADMINISTRATOR_SUCCESS',
        FAIL: 'ADMIN_ADMINISTRATOR_FAIL',
        RESET: 'ADMIN_ADMINISTRATOR_RESET'
    },
    ADMINISTRATOR_PROFILE: {
        LOAD: 'ADMIN_ADMINISTRATOR_PROFILE_LOAD',
        SUCCESS: 'ADMIN_ADMINISTRATOR_PROFILE_SUCCESS',
        FAIL: 'ADMIN_ADMINISTRATOR_PROFILE_FAIL',
        RESET: 'ADMIN_ADMINISTRATOR_PROFILE_RESET'
    },
    ADMINISTRATOR_UPSERT: {
        LOAD: 'ADMIN_ADMINISTRATOR_UPSERT_LOAD',
        SUCCESS: 'ADMIN_ADMINISTRATOR_UPSERT_SUCCESS',
        FAIL: 'ADMIN_ADMINISTRATOR_UPSERT_FAIL',
        RESET: 'ADMIN_ADMINISTRATOR_UPSERT_RESET'
    },
    ADMINISTRATOR_DELETE: {
        LOAD: 'ADMIN_ADMINISTRATOR_DELETE_LOAD',
        SUCCESS: 'ADMIN_ADMINISTRATOR_DELETE_SUCCESS',
        FAIL: 'ADMIN_ADMINISTRATOR_DELETE_FAIL',
        RESET: 'ADMIN_ADMINISTRATOR_DELETE_RESET'
    },
    LOGOUT: {
        LOAD: 'ADMIN_LOGOUT_LOAD',
        SUCCESS: 'ADMIN_LOGOUT_SUCCESS',
        FAIL: 'ADMIN_LOGOUT_FAIL',
    },
    LOGIN_AS_USER: {
        LOAD: 'ADMIN_LOGIN_AS_USER_LOAD',
        SUCCESS: 'ADMIN_LOGIN_AS_USER_SUCCESS',
        FAIL: 'ADMIN_LOGIN_AS_USER_FAIL',
        RESET: 'ADMIN_LOGIN_AS_USER_RESET'
    },
    BAN_TEXT: {
        LOAD: 'ADMIN_BAN_TEXT_LOAD',
        SUCCESS: 'ADMIN_BAN_TEXT_SUCCESS',
        FAIL: 'ADMIN_BAN_TEXT_FAIL',
        RESET: 'ADMIN_BAN_TEXT_RESET'
    },
    BAN_TEXT_UPSERT: {
        LOAD: 'ADMIN_BAN_TEXT_UPSERT_LOAD',
        SUCCESS: 'ADMIN_BAN_TEXT_UPSERT_SUCCESS',
        FAIL: 'ADMIN_BAN_TEXT_UPSERT_FAIL',
        RESET: 'ADMIN_BAN_TEXT_UPSERT_RESET'
    },
    BAN_TEXT_DELETE: {
        LOAD: 'ADMIN_BAN_TEXT_DELETE_LOAD',
        SUCCESS: 'ADMIN_BAN_TEXT_DELETE_SUCCESS',
        FAIL: 'ADMIN_BAN_TEXT_DELETE_FAIL',
        RESET: 'ADMIN_BAN_TEXT_DELETE_RESET'
    },
    BAN_EMAIL: {
        LOAD: 'ADMIN_BAN_EMAIL_LOAD',
        SUCCESS: 'ADMIN_BAN_EMAIL_SUCCESS',
        FAIL: 'ADMIN_BAN_EMAIL_FAIL',
        RESET: 'ADMIN_BAN_EMAIL_RESET'
    },
    BAN_EMAIL_UPSERT: {
        LOAD: 'ADMIN_BAN_EMAIL_UPSERT_LOAD',
        SUCCESS: 'ADMIN_BAN_EMAIL_UPSERT_SUCCESS',
        FAIL: 'ADMIN_BAN_EMAIL_UPSERT_FAIL',
        RESET: 'ADMIN_BAN_EMAIL_UPSERT_RESET'
    },
    BAN_EMAIL_DELETE: {
        LOAD: 'ADMIN_BAN_EMAIL_DELETE_LOAD',
        SUCCESS: 'ADMIN_BAN_EMAIL_DELETE_SUCCESS',
        FAIL: 'ADMIN_BAN_EMAIL_DELETE_FAIL',
        RESET: 'ADMIN_BAN_EMAIL_DELETE_RESET'
    },
    CHANGE_USER_EMAIL: {
        LOAD: 'ADMIN_CHANGE_USER_EMAIL_LOAD',
        SUCCESS: 'ADMIN_CHANGE_USER_EMAIL_SUCCESS',
        FAIL: 'ADMIN_CHANGE_USER_EMAIL_FAIL',
        CLEAR_MESSAGE: 'ADMIN_CHANGE_USER_EMAIL_CLEAR_MESSAGE'
    },
    SERVICE: {
        LOAD: 'ADMIN_SERVICE_LOAD',
        SUCCESS: 'ADMIN_SERVICE_SUCCESS',
        FAIL: 'ADMIN_SERVICE_FAIL',
        RESET: 'ADMIN_SERVICE_RESET'
    },
    SERVICE_UPSERT: {
        LOAD: 'ADMIN_SERVICE_UPSERT_LOAD',
        SUCCESS: 'ADMIN_SERVICE_UPSERT_SUCCESS',
        FAIL: 'ADMIN_SERVICE_UPSERT_FAIL',
        RESET: 'ADMIN_SERVICE_UPSERT_RESET'
    },
    SERVICE_DELETE: {
        LOAD: 'ADMIN_SERVICE_DELETE_LOAD',
        SUCCESS: 'ADMIN_SERVICE_DELETE_SUCCESS',
        FAIL: 'ADMIN_SERVICE_DELETE_FAIL',
        RESET: 'ADMIN_SERVICE_DELETE_RESET'
    },
    SUBSCRIPTION: {
        LOAD: 'ADMIN_SUBSCRIPTION_LOAD',
        SUCCESS: 'ADMIN_SUBSCRIPTION_SUCCESS',
        FAIL: 'ADMIN_SUBSCRIPTION_FAIL',
        RESET: 'ADMIN_SUBSCRIPTION_RESET'
    },
    SUBSCRIPTION_DETAIL: {
        LOAD: 'ADMIN_SUBSCRIPTION_DETAIL_LOAD',
        SUCCESS: 'ADMIN_SUBSCRIPTION_DETAIL_SUCCESS',
        FAIL: 'ADMIN_SUBSCRIPTION_DETAIL_FAIL',
        RESET: 'ADMIN_SUBSCRIPTION_DETAIL_RESET'
    },
    SUBSCRIPTION_UPDATE: {
        LOAD: 'ADMIN_SUBSCRIPTION_UPDATE_LOAD',
        SUCCESS: 'ADMIN_SUBSCRIPTION_UPDATE_SUCCESS',
        FAIL: 'ADMIN_SUBSCRIPTION_UPDATE_FAIL',
        RESET: 'ADMIN_SUBSCRIPTION_UPDATE_RESET',
    },
    SUBSCRIPTION_SEARCH: {
        LOAD: 'ADMIN_SUBSCRIPTION_SEARCH_LOAD',
        SUCCESS: 'ADMIN_SUBSCRIPTION_SEARCH_SUCCESS',
        FAIL: 'ADMIN_SUBSCRIPTION_SEARCH_FAIL',
        RESET: 'ADMIN_SUBSCRIPTION_SEARCH_RESET'
    },
    LOGS: {
        LOAD: 'ADMIN_LOGS_LOAD',
        SUCCESS: 'ADMIN_LOGS_SUCCESS',
        FAIL: 'ADMIN_LOGS_FAIL',
        RESET: 'ADMIN_LOGS_RESET'
    },
    PROMO: {
        LOAD: 'ADMIN_PROMO_LOAD',
        SUCCESS: 'ADMIN_PROMO_SUCCESS',
        FAIL: 'ADMIN_PROMO_FAIL',
        RESET: 'ADMIN_PROMO_RESET'
    },
    PROMO_UPSERT: {
        LOAD: 'ADMIN_PROMO_UPSERT_LOAD',
        SUCCESS: 'ADMIN_PROMO_UPSERT_SUCCESS',
        FAIL: 'ADMIN_PROMO_UPSERT_FAIL',
        RESET: 'ADMIN_PROMO_UPSERT_RESET'
    },
    PROMO_DELETE: {
        LOAD: 'ADMIN_PROMO_DELETE_LOAD',
        SUCCESS: 'ADMIN_PROMO_DELETE_SUCCESS',
        FAIL: 'ADMIN_PROMO_DELETE_FAIL',
    },
    PROMO_SUGGEST: {
        LOAD: 'ADMIN_PROMO_SUGGEST_LOAD',
        SUCCESS: 'ADMIN_PROMO_SUGGEST_SUCCESS',
        FAIL: 'ADMIN_PROMO_SUGGEST_FAIL',
        RESET: 'ADMIN_PROMO_SUGGEST_RESET'
    },
    
}

export const ITEM_HOME_LIKE_UPDATE = {
    LOAD: 'ITEM_HOME_LIKE_UPDATE_LOAD',
    SUCCESS: 'ITEM_HOME_LIKE_UPDATE_SUCCESS',
    FAIL: 'ITEM_HOME_LIKE_UPDATE_FAIL',
}

export const ITEM_VIEW_LIKE_UPDATE = {
    LOAD: 'ITEM_VIEW_LIKE_UPDATE_LOAD',
    SUCCESS: 'ITEM_VIEW_LIKE_UPDATE_SUCCESS',
    FAIL: 'ITEM_VIEW_LIKE_UPDATE_FAIL'
}

// export const STRIPE_SESSION = { 
//     LOAD: 'STRIPE_SESSION_LOAD',
//     SUCCESS : 'STRIPE_SESSION_SUCCESS',
//     FAIL: 'STRIPE_SESSION_FAIL'
// }

export const TOGGLE_LIVE = {
    LOAD: 'TOGGLE_LIVE_LOAD',
    SUCCESS: 'TOGGLE_LIVE_SUCCESS',
    FAIL: 'TOGGLE_LIVE_FAIL',
    RESET: 'TOGGLE_LIVE_RESET'
}

export const USE_NOW_PROMOTE = {
    LOAD: 'USE_NOW_PROMOTE_LOAD',
    SUCCESS: 'USE_NOW_PROMOTE_SUCCESS',
    FAIL: 'USE_NOW_PROMOTE_FAIL',
    RESET: 'USE_NOW_PROMOTE_RESET'
}

export const CANCEL_SUBSCRIPTION = {
    LOAD: 'CANCEL_SUBSCRIPTION_LOAD',
    SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
    FAIL: 'CANCEL_SUBSCRIPTION_FAIL',
    RESET: 'CANCEL_SUBSCRIPTION_RESET'
}

export const UPDATE_SUBSCRIPTION = {
    LOAD: 'UPDATE_SUBSCRIPTION_LOAD',
    SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    FAIL: 'UPDATE_SUBSCRIPTION_FAIL',
    RESET: 'UPDATE_SUBSCRIPTION_RESET'
}

export const CLEAR_UNCOLLECTED_PROMO = 'CLEAR_UNCOLLECTED_PROMO'
export const REALTIME_USER_WALLET_UPDATE = 'REALTIME_USER_WALLET_UPDATE'
