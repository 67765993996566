import { ITEM_VIEW, ITEM_UPDATE, ITEM_IMAGE_EDIT, ITEM_DELETE, ITEM_VIEW_UPDATE_BY_FIELD } from '../constant'
import { takeLatest, put, delay } from "redux-saga/effects";
import { itemViewSuccess, itemViewFail, itemUpdateSuccess, itemUpdateFailed, itemImageEditSuccess, itemImageEditFailed, itemDeleteSuccess, itemDeleteFailed, itemUpdateByFieldSuccess, itemUpdateByFieldFailed } from '../action'
import item from '../../graphql/item'
import { theme } from '../../common/theme'

function* itemViewSaga(action) {
    const { payload } = action
    const id = payload && payload.id ? payload.id : null
    const pageviewData = payload && payload.pageviewData ? payload.pageviewData : {}
    const isSess = payload && payload.pageviewData && payload.pageviewData.fk_i_viewer_id ? payload.pageviewData.fk_i_viewer_id : null

    if (isSess && isSess !== 0) {
        yield item.pageview(pageviewData)
        yield delay(500)
    }
    
    try {
        const result = yield item.getItemView(id)
        if(result === 403){
            yield put(itemViewFail('Something went wrong try again'))
        }
        else{
            console.log(result)
            const data = result && result.data && result.data.publicGetItemById ? result.data.publicGetItemById : {}
            yield put(itemViewSuccess(data))
        }
    }catch (e) {
        console.log('e', JSON.stringify(e))
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(itemViewFail(error))
    }
}

export function* watchItemViewSaga(){
    yield takeLatest(ITEM_VIEW.LOAD, itemViewSaga)
}

function* itemUpdateSaga(action) {
    const { payload } = action

    try {
    const result = yield item.itemUpdate(payload)
        if(result === 403){
            yield put(itemUpdateFailed('Something went wrong try again'))
        }
        else{
            const data = result && result.data && result.data.itemUpdate ? result.data.itemUpdate : {}
            yield put(itemUpdateSuccess(data))
        }

    }catch (e) {
        console.log('e', JSON.stringify(e))
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(itemUpdateFailed(errorMessage))
    }
}
export function* watchItemUpdateSaga(){
    yield takeLatest(ITEM_UPDATE.LOAD, itemUpdateSaga)
}

function* itemImageEditSaga(action) {
    const { payload } = action
    try {
        const result = yield item.itemImageEdit(payload)
        if(result === 403){
            yield put(itemImageEditFailed('Something went wrong try again'))
        }
        else{
            const data = result && result.data && result.data.itemImageUpdate ? result.data.itemImageUpdate : {}
            yield put(itemImageEditSuccess(data))
        }

    }catch (e) {
        console.log('error', JSON.stringify(e))
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(itemImageEditFailed(errorMessage))
    }
}
export function* watchItemImageEditSaga(){
    yield takeLatest(ITEM_IMAGE_EDIT.LOAD, itemImageEditSaga)
}

function* itemDeleteSaga(action) {
    const { payload } = action
    try {
    const result = yield item.itemDelete(payload)
        if(result === 403){
            yield put(itemDeleteFailed('Something went wrong try again'))
        }
        else{
            const data = result && result.data && result.data.itemDelete ? result.data.itemDelete : {}
            yield put(itemDeleteSuccess(data))
        }
    }catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(itemDeleteFailed('Internal server error, try again'))
    }
}
export function* watchItemDeleteSaga(){
    yield takeLatest(ITEM_DELETE.LOAD, itemDeleteSaga)
}

function* itemUpdateByFieldSaga(action) {
    const { payload } = action
    try {
    const result = yield item.itemUpdateByField(payload)
        if(result === 403){
            yield put(itemUpdateByFieldFailed('Something went wrong try again'))
        }
        else{
            const data = result && result.data && result.data.itemUpdateByField ? result.data.itemUpdateByField : {}
            yield put(itemUpdateByFieldSuccess(data))
        }

    }catch (e) {
        console.log('e', JSON.stringify(e))
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(itemUpdateByFieldFailed(errorMessage))
    }
}
export function* watchItemUpdateByFieldSaga(){
    yield takeLatest(ITEM_VIEW_UPDATE_BY_FIELD.LOAD, itemUpdateByFieldSaga)
}
