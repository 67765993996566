import React from 'react';
import { connect } from 'react-redux';
import { watchlistLikeDislikeLoad } from '../../../redux/action'
import { LikeItemCard, ZeroList} from './component/CustomComponent'
import {WholePageLoader} from '../../../common/component/customComponents'
import { theme }  from '../../../common/theme'

const Likes = (props) => {
    const userId = props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const { data, isLoading } = props.watchlist
    const likeDislike = (itemId) => {
        props.watchlistLikeDislikeLoad({userId: userId, itemId: itemId})
    }

    return (
        <div className="likes-main-container">
            <div className="likes-container">
            <h1 className="likes-label-container">My Likes</h1>
           {data.length === 0 && !isLoading ? <ZeroList /> : 
             isLoading ? <WholePageLoader /> : 
                <div className="likes-item-main-container">
                        <div className="likes-item-sub-container">
                            {
                                data.map((item, index) => {
                                  if (item.items) {
                                      
                                    return (
                                        <LikeItemCard key={index} item={item.items} likeDislike={likeDislike} isLoading={isLoading}/>
                                        )
                                  }  
                                })
                            }
                        </div>
                    </div>
            }
        </div>
        </div>
         
    );

}

export const style = {
    container: {
      display: 'flex',
      flex: 1,
      margin: '20px 50px',
      minHeight: 200,
      flexDirection: 'column',
      maxWidth: '1240px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 100px'
    },
    itemContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 240px))',
        gridGap: '1rem',
        padding: '30px',
        fontFamily: theme.font.primary,
        maxWidth: '1240px'
      },
  };

const mapStateToProps = store => {
    return {
        watchlist: store.watchlistReducer,
    };
};

const mapActionToProps = {
    watchlistLikeDislikeLoad
}

export default connect(mapStateToProps,mapActionToProps)(Likes);
