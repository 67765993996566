import React, {useContext} from 'react';
import {ProfileSubscriptionContext} from '../../../../../common/context'
import {getStripeSubscriptionIdByAmount} from '../../../../../common/helper/utility'
import {CustomLoader} from '../../../../../common/component/loader'
import {PaymentSuccess} from '../../../../../common/component/customComponents'
import {loadStripe} from '@stripe/stripe-js';
import {Button} from '../../../fkPrime/customComponent'

const {REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_STRIPE_KEY} = process.env

export const StripeUpgradeButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {upgradeData, has_active_subscription, isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError, handleUpdateSubscription} = value
    const sg_price = upgradeData && upgradeData[0] && upgradeData[0].sg_price ? upgradeData[0].sg_price : 0
    const transaction_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const payment_method = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.payment_method ? has_active_subscription.purchase_micro_service.payment_method : null
    const id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.id ? has_active_subscription.purchase_micro_service.id : null
    const plan_id = getStripeSubscriptionIdByAmount(sg_price)

    const handleCheckout = () => {
        const service_id = upgradeData && upgradeData[0] && upgradeData[0].id ? parseInt(upgradeData[0].id) : null
        handleUpdateSubscription({id, transaction_id, payment_method, plan_id, service_id})
    };

    return (
      isLoadingUpdateSubscription ? <CustomLoader /> :
      updateSubscriptionMessage ? <PaymentSuccess text={updateSubscriptionMessage} /> :
      updateSubscriptionError ? <p className='color-danger text-align-center'>{updateSubscriptionError}</p> : 
            <div className="use-now-btn-individual-ads" onClick={() => handleCheckout()}>
                <p className="no-select">{`Upgrade`}</p>
            </div> 
    )
  }

  export const StripeRenewButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {subscriptionRenewData, session, s_email, stripe_cus_id, isLoadingPurchaseCoin, errorPurchaseCoin, purchaseSuccessMessage, handleRenewFailed, setShowSubscriptionRenew, handleCoinReset} = value
    const token = session && session.fkv2_session && session.fkv2_session.access_token ? session.fkv2_session.access_token  : null
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
    const sg_price = subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.sg_price ? parseInt(subscriptionRenewData[0].service_detail.sg_price) : null
    const stripeProductId = getStripeSubscriptionIdByAmount(sg_price)

    const handleCheckout = async () => {
        const GET_SESSION_URL = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql`
        const stripe = await stripePromise;
        const input = generateStripeInput(subscriptionRenewData)

        const query = `mutation getStripeSession($input: PurchaseInput){
          getStripeSession(input: $input){
            session
          }
        }`;

        const response = await fetch(GET_SESSION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query,
                variables: {input}
            })
        });

        const subscriptionSession = await response.json();  

        if (subscriptionSession.error) {
            console.log('subscriptionSession.error', subscriptionSession.error)
            handleRenewFailed()
            setTimeout(() => {
                handleCoinReset()
                setShowSubscriptionRenew(false)
            }, 3000)
        } else {
            const result = await stripe.redirectToCheckout({
            sessionId: subscriptionSession && subscriptionSession.data && subscriptionSession.data.getStripeSession && subscriptionSession.data.getStripeSession.session ? JSON.parse(subscriptionSession.data.getStripeSession.session) : null
            }); 
            if (result.error) {
                handleRenewFailed()
                setTimeout(() => {
                    handleCoinReset()
                    setShowSubscriptionRenew(false)
                }, 3000)
            }
        }
    };

    const generateStripeInput = (subscriptionRenewData) => {
        let line_item_details = []
        let line_items = []

        line_items.unshift({price: stripeProductId, quantity: 1})

        const boostDetailsObj = {
            "type": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].type ? subscriptionRenewData[0].type : null,
            "user_id": parseInt(userId),
            "coin": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.credit ? parseInt(subscriptionRenewData[0].service_detail.credit) : null,
            "id": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.id ? subscriptionRenewData[0].service_detail.id : null,
            "amount": sg_price,
            "description":  subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.description ? subscriptionRenewData[0].service_detail.description : null,
            "method": "stripe",
            "currency": "sgd",
            "s_email": s_email,
            "name": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.name ? subscriptionRenewData[0].service_detail.name : null,
            "customer": stripe_cus_id,
        }

        line_item_details.push(boostDetailsObj)

        const input = {
            "type": 'fk_prime',
            "user_id": parseInt(userId),
            "coin": 0,
            "amount": sg_price,
            "method": "stripe",
            "currency": "sgd",
            "s_email": s_email,
            "payment_mode": "subscription",
            "return_url": "user/subscription",
            "customer": stripe_cus_id, 
            "line_items": line_items,
            "line_items_detail": line_item_details
        }
        return input
    }

    return (
            isLoadingPurchaseCoin ? <CustomLoader /> :
            purchaseSuccessMessage ? <PaymentSuccess text={purchaseSuccessMessage} /> :
            errorPurchaseCoin ? <p className='color-danger text-align-center'>{errorPurchaseCoin}</p> : 
            <Button
                styleClass={`flex flex-justify-center flex-align-center fk-prime-purchase-pay-button-container pointer p-8-13`}
                text="Renew"
                textClass="fk-prime-purchase-pay-button-p"
                clickFunc={handleCheckout}
            />
    )
  }

  export const StripePaynowButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {session, stripe_cus_id} = value
    const token = session && session.fkv2_session && session.fkv2_session.access_token ? session.fkv2_session.access_token  : null
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null

    const handleRedirectToPortal = async () => {
        const GET_SESSION_URL = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql`
        const query = `mutation getStripePortalSession($user_id: Int!, $customer: String!, $return_url: String!){
            getStripePortalSession(user_id: $user_id, customer: $customer, return_url: $return_url ){
              session
            }
        }`;

        const response = await fetch(GET_SESSION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query,
                variables: {user_id: userId, customer: stripe_cus_id, return_url: 'http://fkv2web-local.fishingkaki.com.ph:3000/user/subscription'}
            })
        });
            
        const portal_session = await response.json();

        if (portal_session && portal_session.data && portal_session.data.getStripePortalSession && portal_session.data.getStripePortalSession.session && portal_session.data.getStripePortalSession.session) {
            const raw_session = JSON.parse(portal_session.data.getStripePortalSession.session)
            const url = raw_session.url
            window.location.replace(url);
        }
        if (portal_session.error) {
            console.log('create-billing-session error', portal_session.error);
        }
    };

    return (
            <Button
                styleClass={`flex flex-justify-center flex-align-center fk-prime-purchase-pay-button-container pointer p-8-13`}
                text="PayNow"
                textClass="fk-prime-purchase-pay-button-p"
                clickFunc={handleRedirectToPortal}
            />
    )
  }