import React, {useReducer, useRef} from 'react'
import { connect } from 'react-redux'
import { setRecentPriceFilter } from '../../../../../redux/action'
import {FilterSortIcon} from '../../../../../assets/svg'

const MobileFilter = (props) => {
    const data = props.data
    // const show = props.show
    const { recentPriceFilter, price } = props.filter

    const click = props.click
    
    const [filterValues, setFilterValues] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice,
            class: recentPriceFilter
        }
    )
        

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFilterValues({[name]: newValue})
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        props.setRecentPriceFilter(filterValues)
    }
    return (
        
             <div className="filter-modal-container" ref={props.filterM}>
                  <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}} onClick={click}>
                     X
                 </div>
            <div className="modal-sub-container">
                <div className="filter-modal-head">
                    <p>{`Filters`}</p>
                </div>
                <div className="filter-label-head">
                    <p>{`Sort`}</p>
                </div>
                <form onSubmit={handleSubmit}>
                <div className="filter-input-container" >
                    <div className="filter-input-sub-container">
                        <div className="filter-input-main-container">
                            <div style={{paddingRight: 6}}>
                            <FilterSortIcon />
                            </div>
                            
                            <select className="filter-select-container" name="class" onChange={handleChange} >
                                {
                                    data.map((option, index) => {
                                    return <option value={option.value}  key={index}>{option.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            // data.map((option, index) => {
                            //     return <div key={index} >
                            //             <button value={option.value} name="class" className={`filter ${filterValues.class === option.value ? `highlighted` : `regular`}`} onClick={handleClick}>{option.label}</button>
                            //         </div>
                            // })
                        }
                       
                    </div>
                </div>
                <div className="filter-label-head">
                    <p>{`Price`}</p>
                </div>
                <div >
                    <div className="filter-input-form-container">
                        <p className="filter-input-currency">S$</p>
                        <input type="text" className="filter-text-input" placeholder="Minimum" min="1" name="minPrice" value={filterValues.minPrice} onChange={handleChange} />
                    </div>
                    <div className="filter-input-form-container">
                    <p className="filter-input-currency">S$</p>
                        <input type="text" className="filter-text-input" placeholder="Maximum" max="999999" value={filterValues.maxPrice} name="maxPrice" onChange={handleChange} />
                    </div>
                </div>
                <div className="flex filter-apply">
                    <div className="submit-filter" onClick={click}>
                        <input type="submit" value="Apply Filters" />
                    </div>
                </div>
                </form>
               
            </div>
        </div>
       
        
        
    );
}

const mapStateToProps = store => {
    return {
        filter: store.filterReducer
    }
}

const mapActionToProps = {
    setRecentPriceFilter
}

export default connect(mapStateToProps, mapActionToProps)(MobileFilter)