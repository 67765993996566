import React, { useContext } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Link, Redirect } from 'react-router-dom';
import Loader from '../../../../common/component/loader'
import { theme } from '../../../../common/theme'
import { LoginContext } from '../../../../common/context'
import GooglePlay from '../../../../assets/images/playstore.svg'
import {GetItOnAppStore} from '../../../../assets/svg'
import { GoogleLogin } from 'react-google-login';


export const LoginForm = () => {
    const { REACT_APP_FB_APP_ID, REACT_APP_GOOGLE_CLIENT_ID} = process.env;
    const value = useContext(LoginContext)
    const { isAuthenticated, isLoading, handleSubmit, userInput, handleInputClick, peek, handleSetPeek, 
        captchaChange, error, parseError, toggleForgot, responseFacebook, handleChange, responseGoogle
    } = value
    const {loginCredentialsError} = theme.text

    if (isAuthenticated) {
        return <Redirect to='/' />
    } else {
        return (
        <div className="login-main-container">
            <div className="login-container">
                <div className="login-sub-container" >
                    { isLoading ?  <div style={{marginTop: '50px',minWidth: 304}}><Loader /></div> :
                        <React.Fragment>
                            <div>
                                <h1 className="head-title">{`Login`}</h1>
                            </div>
                            <div className="login-inputs-container">
                                <form onSubmit={(e) =>handleSubmit(e)}>
                                    <div className="login-form-input-main-container">
                                        <div className={`login-form-input-sub-container normal-input-border`}>
                                            <div className="login-form-input-container">
                                                {userInput.userEmail ? <label className="login-form-label">{`Email`}</label> : null}
                                                <input className="login-form-main-input" placeholder="Enter email" type="email" name="userEmail" onClick={() => handleInputClick('email')} autocomplete="off" value={userInput.userEmail} onChange={(e) =>handleChange(e)} required />
                                            </div>

                                        </div>
                                        {/* { error && error.includes('Email') ? <div> {parseError(error)} </div> : null} */}
                                    </div>
                                    <div className="login-form-input-main-container">
                                        <div className="login-form-input-sub-container normal-input-border">
                                            <div className="login-form-input-container">
                                            {userInput.userPassword ? <label className="login-form-label">{`Password`}</label> : null}
                                                <input className="login-form-main-input" placeholder="Enter password" minLength={4} type={peek ? `text` : `password`} name="userPassword" onClick={() => handleInputClick('password')} autocomplete="off" value={userInput.userPassword} onChange={(e) =>handleChange(e)} required />
                                                <i className={` far ${ peek ? `fa-eye-slash`  : `fa-eye`}`} onClick={ () => handleSetPeek()} />
                                            </div>
                                        </div>
                                        { error && error.includes('Password') || error && error.includes('Email') ? <p className='error'>{loginCredentialsError}</p> : null}
                                    </div>
                                    <div className="login-recaptcha">
                                        <ReCAPTCHA 
                                        sitekey={theme.text.sitekey} onChange={() =>captchaChange()} />
                                        {userInput.inputError ? <div className="flex"><p style={{color: 'rgba(192, 57, 43,1.0)'}}>{userInput.inputError}</p></div> : null}
                                    </div>
                                        { error && error.includes('deactivated') ? <div> {parseError(error)} </div> : null}
                                    <input className="login-btn" type="submit" value="Login" />
                                    <div className="login-forgot-password-container">
                                        <Link onClick={() => toggleForgot()} to='/login'>Forgot Password</Link>
                                    </div>
                                </form>
                                <p className="fb-top-label">Or connect with</p>
                                <div className='fb-btn-container'>
                                    <GoogleLogin
                                        clientId={REACT_APP_GOOGLE_CLIENT_ID}
                                        buttonText="Sign in with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        render={renderProps => (
                                        <button onClick={renderProps.onClick} type="button" style={{width: '100%', backgroundColor: 'rgb(255, 255, 255)', display: 'inline-flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.54)', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px', padding: '0px', borderRadius: '2px', border: '1px solid transparent', fontSize: '14px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', justifyContent: 'center'}}>
                                            <div style={{marginRight: '10px', background: 'rgb(255, 255, 255)', padding: '10px', borderRadius: '2px'}}>
                                              <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
                                                <g fill="#000" fillRule="evenodd">
                                                  <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335" />
                                                  <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4" />
                                                  <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05" />
                                                  <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853" />
                                                  <path fill="none" d="M0 0h18v18H0z" />
                                                </g>
                                              </svg>
                                            </div>
                                            <span style={{padding: '10px 10px 10px 0px', fontWeight: 500}}>Sign in with Google</span>
                                          </button>
                                        )}
                                    />
                                </div>
                                <div className="fb-btn-container">
                                    <FacebookLogin
                                        appId={`${REACT_APP_FB_APP_ID}`}
                                        fields="id,name,email,picture.height(961)"
                                        callback={responseFacebook}
                                        disableMobileRedirect={true}
                                        render={renderProps => (
                                        <button onClick={renderProps.onClick} className="fb-btn">
                                            <i style={{fontSize: 24, marginRight: 10}} className="fab fa-facebook"></i> Sign in with Facebook
                                        </button>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="login-bottom-container">
                                <p className="new-user-signup-label">{`Dont have an account yet? `}<Link to={`/signup`}>{` Sign up`}</Link>{` or`}<Link to={'/contact-us'}>{` Contact Us.`}</Link></p>
                                    
                            </div>
                            {/* <div className="socialLinks"> */}
                                    <div className="flex flex-d-row flex-align-center flex-justify-center">
                                        <a href="https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549" className="flex flex-d-row flex-align-center mb-11" target="_blank">
                                            <GetItOnAppStore />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mb-11 ml-12" target="_blank">
                                            <img src={GooglePlay} width={128}/>
                                            {/* <GetItOnPlayStore /> */}
                                        </a>
                                    </div>
                                {/* </div> */}
                        </React.Fragment>
                        
                    }
                </div>
            </div>
        </div>
        )
    }
  }

  export const ForgotPasswordForm = () => {
    const value = useContext(LoginContext)
    const height = window.innerHeight
    const { forgotPasswordIsLoading, toggleForgot, handleForgotSubmit, forgotPasswordData, emailInput, 
            forgotPasswordError, forgotClick, handleFPClick, handleEmailChange, parseError
    } = value

    return (
        <div className={`forgot-pw-main-container ${height > 560 ? `highScreen`: ``}`}>
            <div className="forgot-pw-container">
                <div className="forgot-pw-sub-container">
                    { forgotPasswordIsLoading ?  <div style={{minWidth: 304}}><Loader /></div> :
                    <React.Fragment>
                        <div className="forgot-pw-x-icon">
                            <Link onClick={() => toggleForgot()} to='/login'><i className="fas fa-times" style={{fontSize: 20}}></i></Link>
                        </div>
                        <div className="head-title">
                            <p>{`Reset Password`}</p>
                        </div>
                        <div>
                            <form onSubmit={(e) => handleForgotSubmit(e)}>
                            <div className="forgot-pw-input-main-container">
                            { forgotPasswordData ? <p style={{textAlign: 'center', marginBottom: 30}}>{'We’ve sent an email to '}
                            <span style={{color: '#FB770D'}}>{emailInput.email}</span> {'Check your email and click the link to reset your password.'}</p> : null }
                                <div className={`forgot-pw-input-sub-container ${forgotPasswordError ? `forgot-pw-input-error-border` : `forgot-pw-input-border`}`}>
                                { forgotClick ? <label className="forgot-pw-input-label">{`Email`}</label> : null }
                                <input className="forgot-pw-input" onClick={() =>handleFPClick()} placeholder={forgotClick ? `` : `Enter email address`} type="email" name="email"  value={emailInput.email} onChange={(e) =>handleEmailChange(e)} required />
                                </div>
                                { forgotPasswordError ? <div> {parseError(forgotPasswordError)} </div> : null}
                            </div>
                            <input className="login-btn" type="submit" value={forgotPasswordData ? "Resend link" : "Send link"} />
                            </form>
                        </div>
                        <div className="fp-login-container">
    <p>{`Try again? `}<span onClick={() => toggleForgot()} className="fp-login-label">{` Login `}</span> {`or `}<Link to={`/contact-us`}>{`Contact Us.`}</Link></p>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
  }