import React, { useEffect, useReducer, useState, useRef} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import NumberFormat from 'react-number-format';
import CoverPhoto from '../cover'
import { meUpdate, meResetMessage } from '../../../../redux/action/userAction';
import { connect } from 'react-redux'; 
import { getLocalStorage } from '../../../../common/helper/utility'
import FKImage from '../../../../common/component/image'
import {CustomLoader} from '../../../../common/component/loader'
import {CameraIcon, LockIcon, ActiveLockIcon, WorldIcon} from '../../../../assets/svg'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import {CameraEditImageIcon, VeryResponsiveIcon, NotSoResponsiveIcon, ArrowDropdownIcon} from '../../../../assets/svg'
import {MobileHeader, SuccessfulUpdatedUI, SettingsAndPrivacy} from './customComponents'

const { REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER, REACT_APP_DEFAULT_AVATAR_ID, REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX_COVER} = process.env

const UserMain = props => {
    const {error, updateMessage} = props.me
    const {fromDrawerClick} = props
    useEffect(() => {
      if (updateMessage) {
        setTimeout(() => {
          props.meResetMessage()
        }, 3000)
      }
    // eslint-disable-next-line
    },[updateMessage])
    const [showAvatar, toggleShowAvatar] = useState(true)
    const [lockClick, setLockClick] = useState('')
    const [fromDrawer, setFromDrawer] = useState(false)
    const [submitClick, setSubmitClick] = useState(false)
    const [userUpdateSuccess, setUserUpdateSuccess] = useState(false)
    const [updateError, setUpdateError] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        isError: false,
        errorMessage: ''
      }
    );
    const data = props && props.me && props.me.data ? props.me.data : []
    const {s_name, s_username, s_email, s_phone_land, s_phone_mobile, s_country, s_region, s_city, s_website, wallet , bio, cover_photo, responsive, is_email_public, is_phone_public} = data
    // const s_name = props && props.data && props.data.s_name ? props.data.s_name : ''
    // const s_username = props && props.data && props.data.s_username ? props.data.s_username : ''
    const userId = props.data.pk_i_id
    const path = 'profile'
    const isLoading = props && props.isLoading ? props.isLoading : false
    const updateSuccess = props && props.data && props.data.coverResult && props.data.coverResult.message ? props.data.coverResult.message : props && props.data && props.data.data && props.data.data.message ? props.data.data.message : false
    // const resourceId = props.data && props.data.oc_t_user_resource && props.data.oc_t_user_resource.pk_i_id ? props.data.oc_t_user_resource.pk_i_id : REACT_APP_DEFAULT_AVATAR_ID
    const extension = props.data && props.data.oc_t_user_resource && props.data.oc_t_user_resource.s_extension ? props.data.oc_t_user_resource.s_extension : 'jpg'
    const resourceId = props.data && props.data.oc_t_user_resource && props.data.oc_t_user_resource.pk_i_id ? props.data.oc_t_user_resource.pk_i_id : null

    const session = getLocalStorage('fkv2_session')

    const  fbImageSrc = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
        && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.picture && props.avatar.avatarData.social[0].data.picture.data
        && props.avatar.avatarData.social[0].data.picture.data.url
        ? `${props.avatar.avatarData.social[0].data.picture.data.url}` : null

    // const imgSrc = session && session.social && session.social === 'facebook' ? fbImageSrc : 
    //   `${REACT_APP_ENV === 'local' ? REACT_APP_GRAPHQL_SERVER_BASE_URL : REACT_APP_S3_CLASSIFIED }${REACT_APP_S3_CLASSIFIED_FOLDER}/${path}/${resourceId}.${extension}`
    const imgSrc = resourceId ? `${REACT_APP_ENV === 'local' ? REACT_APP_GRAPHQL_SERVER_BASE_URL : REACT_APP_S3_CLASSIFIED }${REACT_APP_S3_CLASSIFIED_FOLDER}/${path}/${resourceId}.${extension}` :
    fbImageSrc ? fbImageSrc : null
    const profile_img_resource = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.oc_t_user_resource ? props.avatar.avatarData.oc_t_user_resource : ''

    const userWallet = wallet ? wallet : 0
    const [updateInput, setUpdateInput] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        profileID: '',
        profileResourceId: '',
        profileCoverPhoto: '',
        profileResponsiveness: '',
        profileName: '',
        profileUserName: '',
        // profilePhone: s_phone_land ? s_phone_land : '',
        profileBio: '',
        profileMobile: '',
        // profileCountry: s_country ? s_country : '',
        // profileRegion: s_region ? s_region : '',
        // profileCity: s_city ? s_city : '',
        // profileWebsite: s_website ? s_website : '',
        profileEmail: '',
        isEmailPublic: false,
        profileWhatsApp: '',
        isMobilePublic: false
      }
    );

    const PrivateChoices = [
      {icon: <WorldIcon/>, label: 'Public', value: 'public'},
      {icon: <LockIcon />, label: 'Only me', value: 'private'}
    ]

    useEffect(() => {
      if(fromDrawerClick){
        setFromDrawer(true)
      }
    },[fromDrawerClick])

    useEffect(() => {
      if(data){
        setUpdateInput({
          ['profileID']: userId,
          ['profileResourceId']: resourceId,
          ['profileResponsiveness']: responsive,
          ['profileName']: s_name,
          ['profileUserName']: s_username,
          ['profileBio']: bio,
          // ['profileMobile']: s_phone_mobile,
          ['profileWhatsApp']: s_phone_mobile,
          // ['profileEmail']: s_email
          ['profileCoverPhoto']: cover_photo ? cover_photo : [],
          ['profileEmail']: s_email,
          ['isEmailPublic']: is_email_public,
          ['isMobilePublic']: is_phone_public
        })
      }
    },[data])

    useEffect(() => {
      if(updateSuccess){
        setSubmitClick(false)
        setUserUpdateSuccess(true)
        setTimeout(() => {
          // setUserUpdateSuccess(false)
          window.location.reload(false)
        }, 1500)
      }
    }, [updateSuccess])

    const handleChange = evt => {
      const name = evt.target.name;
      const newValue = evt.target.value;

      if(evt.target.name === 'profileWhatsApp'){
        setUpdateInput({['profileWhatsApp']: newValue})
      }else{
        setUpdateInput({[name]: newValue});
      }

    }

    const [imageData, setImageData] = useState([])
    // const [imageCover, setImageCover] = useState([])

    const finalDataArr = {
      profileBio: updateInput.profileBio,
      profileEmail: updateInput.profileEmail,
      profileID: updateInput.profileID,
      profileName: updateInput.profileName,
      profileResourceId: updateInput.profileResourceId,
      profileResponsiveness: updateInput.profileResponsiveness,
      profileUserName: updateInput.profileUserName,
      profileMobile: updateInput.profileWhatsApp,
      profileBio: updateInput.profileBio,
      profileResponsiveness: updateInput.profileResponsiveness,
      profileCoverPhoto: updateInput.profileCoverPhoto && updateInput.profileCoverPhoto[0] ? updateInput.profileCoverPhoto[0] : updateInput.profileCoverPhoto && updateInput.profileCoverPhoto.file,
      profileIsEmailPublic: updateInput.isEmailPublic,
      profileIsMobilePublic: updateInput.isMobilePublic
    }

    const handleSubmit = evt => {
      evt.preventDefault();
      setSubmitClick(true)
      // const imageCoverFile = imageCover && imageCover[0] ? imageCover[0] : updateInput.profileCoverPhoto
      const mergeData = { ...finalDataArr, imageData}
      if(updateInput.profileWhatsApp && updateInput.profileWhatsApp.replace(/\s/g, '').length === 8 || !updateInput.profileWhatsApp){
        props.meUpdate(mergeData)
      }else {
        setUpdateError({['isError']: true, ['errorMessage']: 'Number should be 8 digits long'})
      }
    }

    const handleChangeStatus = ({ meta, file }, status) => { 
      if (status === 'done') {
        const tempData = imageData.concat(file)
        setImageData(tempData)
        if (showAvatar) {
          toggleShowAvatar(false)
        }
      }
      if (status === 'removed') {
        const tempData = imageData.filter((obj) => {
          return obj.name !== file.name
        })
        setImageData(tempData)
        if (!showAvatar) {
          toggleShowAvatar(true)
        }
      }
    }
    const mobileDetails = {
      leftLabel: isLoading || userUpdateSuccess ? '' : 'Cancel',
      centerLabel: 'Edit Profile',
      rightLabel: isLoading || userUpdateSuccess ? '' : 'Save',
      rightAction: handleSubmit
    }
    const handleChangeCover = ({ meta, file, remove}, status) => {
      if(status === 'done'){
        if(updateInput.profileCoverPhoto.length === 0){
          const tempData = updateInput.profileCoverPhoto.concat(file, meta, {r:remove})
          setUpdateInput({['profileCoverPhoto']: tempData})
        }else{
          setUpdateInput({['profileCoverPhoto']: {file, meta, r:remove}})
        }
        
      }
      if(status === 'removed'){
        const tempData = updateInput.profileCoverPhoto.filter((obj) => {
          return obj.name !== file.name
        })
        setUpdateInput({['profileCoverPhoto']: []})
      }
    }

    const uploadComponent = () => {
      return (
        <div key={'dp'}>
          <CameraEditImageIcon />
        </div>
      )
    }

    const handleLockClick = (c) => {
      if(c === 'email'){
        setLockClick('email')
      }
      if(c === 'mobile'){
        setLockClick(lockClick ? '' : 'mobile')
      }
    }

    const handlePrivateFields = (f, option) => {
      if(f === 'email'){
        if(option === 'Public'){
          setUpdateInput({['isEmailPublic']: true})
        }else{
          setUpdateInput({['isEmailPublic']: false})
        }
      }
      if(f === 'mobile'){
        if(option === 'Public'){
          setUpdateInput({['isMobilePublic']: true})      
        }else{
          setUpdateInput({['isMobilePublic']: false})
        }
      }
    }

    const onKeyDownInput = (event) => {
      const regex = /^[a-zA-Z\x08\?]$/
      if (regex.test(event.key) || event.key === '+' || event.key === '-') {
        event.preventDefault();
      }
    }

    useEffect(()=> {
      setLockClick('')
    }, [updateInput.isEmailPublic, updateInput.isMobilePublic])

    useEffect(()=> {
      if(updateError.isError){
        setTimeout(() => {
          setUpdateError({['isError']: false})
        }, 3000)
      }
    },[updateError.isError])

    const bioRef = useRef(null);
    const emailRef = useRef(null)
    const mobileRef = useRef(null)

    const scrollToTopFunc = (ref) => {
      if(window.innerWidth < 600) {
        ref.current.scrollIntoView();
      }
    }

    const profileForm = () => {
      return (
        <div className={`w-100 flex flex-justify-center flex-align-center `}>
        <div className="edit-user-details-main-fields">
           <form onSubmit={handleSubmit} className="flex flex-d-column flex-justify-center flex-align-center">
             <div className="m-w-460">
             {/* <div className="flex flex-d-row flex-justify-between flex-align-center w-100 mb-42">
              <div className="flex flex-d-row w-100 flex-align-center">
                <input type="radio" name="profileResponsiveness" checked={updateInput.profileResponsiveness === 'very_responsive' ? true : false} value="very_responsive" onChange={handleChange} className="custom-radio-ui radio custom-radio-ui z-ndx-1"/>
                <div className="ml-12 mr-5">
                  <VeryResponsiveIcon />
                </div>
                <p className="edit-profile-responsiveness-p">{`Very responsive`}</p>
              </div>
              <div className="flex flex-d-row w-100 flex-justify-end flex-align-center">
                  <input type="radio" name="profileResponsiveness" checked={updateInput.profileResponsiveness === 'not_responsive' ? true : false} value="not_responsive" onChange={handleChange} className="custom-radio-ui radio custom-radio-ui z-ndx-1"/>
                  <div className="ml-12 mr-5">
                    <NotSoResponsiveIcon />
                  </div>
                  <p className="edit-profile-responsiveness-p">{`Not so responsive`}</p>
                </div>
             </div> */}
             <div className="edit-input-container-text">
              <label className="edit-input-label-text">{`Username`}</label>
              <input type="text" name="profileUserName" className="edit-text-field-username" value={updateInput.profileUserName} onChange={handleChange}/>
             </div>
             <div className="mb-30" ref={bioRef}>
                <label className="edit-bio-label">{`Bio`}</label>
                <div className="edit-textarea-container">
                  <textarea value={updateInput.profileBio} onClick={() => scrollToTopFunc(bioRef)} maxLength={300} className="edit-textarea-field" name="profileBio" placeholder="Add a short bio to tell people more about yourself. Facebook, Instagram, Youtube, etc . . ." onChange={handleChange}></textarea>
                  <div className="edit-profile-bio-length-label">
                    <p>{`${updateInput.profileBio && updateInput.profileBio.length ? updateInput.profileBio.length : 0}/300`}</p>
                  </div>
                </div>
             </div>
             <div className="mb-42">
                <label className="edit-bio-label">{`Private Information`}</label>
                <p className="mt-5 pb-5 edit-private-info-p">{`We do not share your private information with other users or third parties.`}</p>
             </div>
             <div className={`flex flex-d-row flex-align-center w-100 p-relative ${window.innerWidth < 600 ? `big-mobile-input-container` : ``}`}>
               <div className="edit-input-container-text mb-42 w-100"ref={emailRef} > 
                <label className="edit-input-label-text">{`Email`}</label>
                <input type="email" onClick={() => scrollToTopFunc(emailRef)} name="profileEmail" className="edit-text-field-username w-100" value={updateInput.profileEmail} onChange={handleChange}/>
               </div>
               <div className={`p-absolute private-lock-icon-container ${lockClick === 'email' ? `right-n-33`: ``}`}>
                <div className="p-relative">
                {lockClick === 'email' ? <div className="isPublic-main-container">
                {updateInput.isEmailPublic ? <WorldIcon/> : <LockIcon />} <ArrowDropdownIcon />
                  </div> : updateInput.isEmailPublic ? <WorldIcon/> : <LockIcon />}
                  {
                    lockClick === 'email' ?
                    <div className="p-absolute private-lock-icon-sub-container">
                      {
                          PrivateChoices.map((choice, index)=> {
                            const icon = choice && choice.icon ? choice.icon : null
                            const label = choice && choice.label ? choice.label : null
                            return <div key={index} className="flex flex-d-row flex-align-center private-lock-choice-container pl-12" onClick={(e) => handlePrivateFields('email', label)}>
                              <div className="mr-7">{icon}</div>
                              <p className="private-lock-choice-p">{label}</p>
                            </div>
                          })
                        }
                    </div> : null
                  }
                </div>
               </div>
             </div>
             <div className={`flex flex-d-column ${window.innerWidth < 600 ? `flex-align-start` : `flex-align-center`} w-100 p-relative ${window.innerWidth < 600 ? `big-mobile-input-container` : ``}`}>
               <div className={`edit-input-container-text ${window.innerWidth < 600 ? `edit-input-container-text-lock` : ``} mb-6 w-100`} ref={mobileRef}> 
                <label className="edit-input-label-whatsapp-text">{`Whatsapp Number`}</label>
                <span className="contact-number-code">{`+65 `}</span> &nbsp;
                <NumberFormat onClick={() => scrollToTopFunc(mobileRef)} format="#### ####" placeholder="(E.g. 1234 5678)" name="profileWhatsApp" className="edit-text-field-username w-100" value={updateInput.profileWhatsApp} onChange={handleChange}/>
                {/* <input type="text" name="profileWhatsApp" maxLength="9" placeholder="(E.g. 65123456789)" className="edit-text-field-username w-100" pattern="/^[0-9\b]+$/" onKeyDown={onKeyDownInput} value={updateInput.profileWhatsApp} onChange={handleChange}/> */}
               </div>
               <div className={`p-absolute private-lock-icon-container ${lockClick === 'mobile' ? `right-n-33`: ``}`}>
               <div className="p-relative cursor-pointer" onClick={() => handleLockClick('mobile')}>
                  {lockClick === 'mobile' ? <div className="isPublic-main-container">
                {updateInput.isMobilePublic ? <WorldIcon/> : <LockIcon />} <ArrowDropdownIcon />
                  </div> : updateInput.isMobilePublic ? <WorldIcon/> : <LockIcon />}
                    {
                      lockClick === 'mobile' ?
                      <div className="p-absolute private-lock-icon-sub-container">
                        {
                          PrivateChoices.map((choice, index)=> {
                            const icon = choice && choice.icon ? choice.icon : null
                            const label = choice && choice.label ? choice.label : null
                            return <div key={index} className="flex flex-d-row flex-align-center private-lock-choice-container pl-12" onClick={(e) => handlePrivateFields('mobile', label)}>
                              <div className="mr-7">{icon}</div>
                              <p className="private-lock-choice-p">{label}</p>
                            </div>
                          })
                        }
                      </div> : null
                    }
                </div>
               </div>
               {updateError.isError ? <div className="w-100 flex flex-justify-start"> <p className="edit-whatsapp-p-label text-red">{updateError.errorMessage}</p> </div>: null}
               {updateInput.isMobilePublic ? null : <p className="edit-whatsapp-p-label">{`This is private until you make it public. Making contact numbers public will only appear in listing details and chat conversation.`}</p>}
             </div>
             </div>
        
      </form> 
        </div>
        </div>
      )
    }

    const avatarContainer = () => {
      return showAvatar ?
        <div className="p-absolute edit-profile-image-edit">
          {
             fbImageSrc ?
            <img src={imgSrc} alt={'Avatar'} width={100} height={100} style={{borderRadius: '50%'}}/> :
            resourceId ?
            <FKImage images={[profile_img_resource]} type="avatar_preview_edit" alt="seller avatar" /> :
            <img src={DefaultPhoto} width={100} height={100} style={{borderRadius: '50%'}} alt={'Default Avatar'} />
          }
          
        </div>
        : null
    }

    const inputCover = () => {
      return (
        <div className="flex flex-d-row change-cover-btn p-absolute">
           <CameraIcon />
          <p className="change-cover-p">{`Change Cover`}</p>
        </div>
      )
    }

    const PreviewComponent = (imageCover) => {
      const filePreviewObj = imageCover && imageCover[1] ? imageCover[1] : imageCover && imageCover.meta ? imageCover.meta : []
      const coverSrc = filePreviewObj && filePreviewObj.previewUrl ? filePreviewObj.previewUrl : `${REACT_APP_ENV === 'local' ? REACT_APP_GRAPHQL_SERVER_BASE_URL+`classifieds-local/cover/` : REACT_APP_IMGX_COVER }${imageCover.pk_i_id}.${imageCover.s_extension}` 
      const removeFunc = imageCover && imageCover[2] && imageCover[2].r ? imageCover[2].r : imageCover && imageCover.r ? imageCover.r :null

     return (
         <div className="w-100 p-relative">
          <img src={coverSrc} width={ window.innerWidth < 410 ? 343 : window.innerWidth >= 411 && window.innerWidth < 600 ? 379 : 698} height={window.innerWidth < 600 ? 125 : 206} className="edit-cover-img-elm"/>
          {/* <div className="p-absolute cover-dropzone-container">
            <div className="flex flex-d-row change-cover-btn p-absolute" onClick={() => {removeFunc(); setUpdateInput({['profileCoverPhoto']: []})}}>
              <p className="change-cover-p">{`Remove`}</p>
            </div>
          </div> */}
       </div>
     )
    }

    const WebContainer = () => {
      return (
        <div className="edit-component">
        {
          userUpdateSuccess ? <SuccessfulUpdatedUI /> :
          isLoading ? <CustomLoader /> : 
          <div className="w-100">
          <div className="edit-main-body-container">
          {/* {editButton()} */}
            <p className="edit-public-info-p">{`Public Information`}</p>
          </div>
          <div>
            <div className="w-100 mt-20">
              <div className="p-relative mb-20">
                <div className="w-100 p-relative">
                  {
                    updateInput.profileCoverPhoto.length === 0 ? <CoverPhoto /> : 
                    PreviewComponent(updateInput.profileCoverPhoto)
                  }
                  <div className="p-absolute cover-dropzone-container">
                  <Dropzone
                    inputContent={inputCover}
                    maxFiles={1}
                    onChangeStatus={handleChangeCover}
                    PreviewComponent={null}
                  />
                  </div>
                </div>
              {avatarContainer()}   
              {session && session.social && session.social === 'facebook' ? null :
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  accept="image/*"
                  maxFiles={1}
                  inputContent={uploadComponent()}
                  classNames={{
                    inputLabelWithFiles: 'addFiles', 
                    preview: 'previewContentImage',
                    dropzone: 'customDropzoneProfilePic'
                  }}
                /> 
              }    
              </div>
            </div>
            {profileForm()}
          </div>
        </div>
        
        }
        {
          userUpdateSuccess ? null :
          <div className="w-100 flex flex-d-row flex-align-center flex-justify-end p-absolute t-100percent mt-20">
            <div className="update-discard-btn cursor-pointer">
              <p className="update-discard-p">{`Discard Changes`}</p>
            </div>
            <div className="update-save-btn ml-20 cursor-pointer" onClick={(e) => handleSubmit(e)}>
              <p className="update-save-p">{`Save Changes`}</p>
            </div>
          </div>
        }  
          </div>
      )
    }

    const MobileContainer = () => {
      return (
        <div className="w-100 h-100">
          <MobileHeader details={mobileDetails}/>
          {
            userUpdateSuccess ? <SuccessfulUpdatedUI /> :
            isLoading ? <div className="loader-main-body-container"> <CustomLoader /> </div> : 
            <div className="edit-profile-mobile-body-container">
          <div className="edit-main-body-container">
            <p className="edit-public-info-p">{`Public Information`}</p>
          </div>
          <div className="w-100 mt-20">
              <div className="p-relative mb-20">
                <div className="w-100 p-relative">
                  {
                    updateInput.profileCoverPhoto.length === 0 ? <CoverPhoto /> : 
                    PreviewComponent(updateInput.profileCoverPhoto)
                  }
                  <div className="p-absolute cover-dropzone-container">
                  <Dropzone
                    inputContent={inputCover}
                    maxFiles={1}
                    onChangeStatus={handleChangeCover}
                    PreviewComponent={null}
                  />
                  </div>
                </div>
              {avatarContainer()}   
              {session && session.social && session.social === 'facebook' ? null :
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  accept="image/*"
                  maxFiles={1}
                  inputContent={uploadComponent()}
                  classNames={{
                    inputLabelWithFiles: 'addFiles', 
                    preview: 'previewCont',
                    dropzone: 'customDropzone'
                  }}
                /> 
              }    
              </div>
              </div>
              {profileForm()}
         </div>
          }
         
        </div>
      )
    }

    const mobileSettingsPrivacyHeader = {
      leftLabel: 'Back',
      centerLabel: 'Settings & Privacy'
    }

    const hideSettingsAndPrivacyFunc = () => {
      setFromDrawer(!fromDrawer)
    }

    const settingsAndPrivacyMobileContainer = () => {
      return(
        <>
          <MobileHeader details={mobileSettingsPrivacyHeader} leftBackIcon={true}/>
          <SettingsAndPrivacy hideSettingsAndPrivacyFunc={hideSettingsAndPrivacyFunc}/>
        </>
      )
    }
    return (
      <>
        {
          window.innerWidth < 600 ? fromDrawer ? settingsAndPrivacyMobileContainer() : MobileContainer() : 
          WebContainer() 
        }
      </>
    );
  }

  const mapActionToProps = {
    meUpdate,
    meResetMessage
  }
  const mapStateToProps = store => {
    return {
      me: store.userReducer,
      avatar: store.avatarReducer,
    };
  };

export default connect(mapStateToProps ,mapActionToProps)(UserMain);