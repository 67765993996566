import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const getCategorySearch = (id, orderField, orderType, priceFrom, priceTo, pages, first) => {
    try {
        const query = gql`query publicGetItemsByCategory($b_active: Boolean, $fk_i_category_id: Int, $first: Int, $page: Int, $orderField: String!, $orderType: String!, $price_from: Int, $price_to: Int){
          publicGetItemsByCategory(first: $first, page: $page, b_active: $b_active,
            fk_i_category_id: $fk_i_category_id,
            orderField: $orderField,
            orderType: $orderType,
            i_price: {
              price_from: $price_from,
              price_to: $price_to
            }
          ){
    				count
            data {
              ab_expiration
              is_new
              pk_i_id
              b_active
              fk_i_user_id
              s_contact_name
              dt_pub_date
              dt_sold
              fk_c_currency_code
              i_price
              b_reserved
              b_mark_as_sold
              ta_expiration
              hl_expiration
              watchlist_count
              get_i_price
              dt_p_colorized
              s_item_condition
              watcher
              pageview_count
              post_type
              oc_t_item_description {
                s_title
                s_description
              }
              oc_t_item_resource{
                pk_i_id
                fk_i_item_id  
                s_name
                s_extension
                s_path
                s_content_type
              }
              oc_t_category{
                oc_t_category_descriptions{
                  s_name
                }
                oc_t_custom_field {
                  name
                  label
                  placeholder
                  fk_i_category_id
                  type
                  custom_index
                } 
              }
              oc_t_user{
                oc_t_user_resource{
                  pk_i_id
                  fk_i_user_id
                  s_extension
                  s_name
                  s_content_type
                  s_path
                }
                s_username
                pk_i_id
                items_count
                social {
                  data
                }
              }
              custom_fields
            }
          }
        }`;

          const response = client.query({
              query: query,
              variables: { b_active: true, fk_i_category_id: id, first: first, page: pages, orderField: orderField, orderType: orderType, price_from: priceFrom, price_to: priceTo }
          });
          const data = response ? response : null;
          return data

    } catch (e) {
        console.log('gql category e', JSON.stringify(e))
        throw e
    }
}

export default {
    getCategorySearch
}