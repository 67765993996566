import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom'
import { StarRatingPlain} from '../../../../../common/component/rating'
import { SortIcon } from '../../../../../assets/svg'
import DefaultPhoto from '../../../../../assets/images/defaultAvatar.png'
import FKImage from '../../../../../common/component/image'
import { getSocialUrl, slugify, categoryParser } from '../../../../../common/helper/utility'

export const ReviewContainerTop = (props) => {
    const {overallAverage, reviewCount, averageCommunicationRate, averageMeetupShippingRate, averageProductRate, averagePaymentRate} = props.sharedProps
    return (
        <div className="review-container-top">
            <div className='overall-container'>
                <div>
                    <div className='flex flex-d-row flex-align-center'>
                        <p className='overall-avg-txt-main-p'><span className='overall-average-text'>{overallAverage}</span><span className='overall-average-text2'>{`/5 `}</span></p>
                        <StarRatingPlain totalStar={5} average= {overallAverage} size={15}/>
                    </div>
                </div>
                <p className='review-count'>{`${reviewCount} ${reviewCount < 2 ? `Review` : `Reviews`}`}</p>
            </div>
            
            {/* <div className='rate-container'>
                <div>
                    <p className='review-rate'><span>{`Communication`}</span><span className='average-text'>{averageCommunicationRate ? averageCommunicationRate : 'N/A'}</span></p>
                    <p className='review-rate'><span>{`Meet up / Shipping`}</span><span className='average-text'>{averageMeetupShippingRate ? averageMeetupShippingRate : 'N/A'}</span></p>
                </div>
                <div>
                    <p className='review-rate'><span>{`Product`}</span><span className='average-text'>{averageProductRate ? averageProductRate : 'N/A'}</span></p>
                    <p className='review-rate'><span>{`Payment`}</span><span className='average-text'>{averagePaymentRate ? averagePaymentRate : 'N/A'}</span></p>
                </div>
            </div> */}
        </div>
    )
}
export const ReviewFilterModal = (type) => {
    
    return (
        <div>

        </div>
    )
}

export const ReviewFilter = () => {
    return (
        <div className="review-filter-container">
            <div className="review-filter-content" onClick={() => ReviewFilterModal('user')}>
                <div className="review-icon">
                    <i className="fas fa-user" />
                </div>
                <div>
                    <p className="text-light-black text-normal f-11 l-11">{`All reviews`}</p>
                </div>
            </div>
            <div className="review-filter-content">
                <div className="review-icon">
                    <SortIcon />
                </div>
                <div>
                    <p className="text-light-black text-normal f-11 l-11">{`Price: lowest to high`}</p>
                </div>
            </div>
            <div className="review-filter-content">
                <div>
                    <i className="fas fa-image" />
                </div>
            </div>
        </div>
    )
}

export const ReviewDataElement = (props) => {
    const {reviewData } = props.sharedProps
    return reviewData.map((item, index) => {
        const reviewName = item.reviewer && item.reviewer.s_username ? item.reviewer.s_username : ''
        const reviewerId = item.reviewer_id ? item.reviewer_id : null
        const reviewerIsBuyer = item.review_ratingable_id && item.review_ratingable_id === 1 ? 'buyer': 'seller'
        // const totalRate = item.total_rate ? item.total_rate : ''
        const totalRate = item.communication_rate ? item.communication_rate : ''

        const createAt = item.created_at ? moment(item.created_at).format("DD MMM YYYY") : ''
        const body = item.body ? item.body : ''
        const communicationRate = item.communication_rate ? item.communication_rate.toFixed(1) : ''
        const productRate = item.product_rate ? item.product_rate.toFixed(1) : ''
        const paymentRate = item.payment_rate ? item.payment_rate.toFixed(1) : ''
        const meetupShippingRate = item.meetup_shipping_rate ? item.meetup_shipping_rate.toFixed(1) : ''
        const itemImgResource = item.item && item.item.oc_t_item_resource && item.item.oc_t_item_resource[0] ? item.item.oc_t_item_resource[0] : []
        const itemId = item.item && item.item.pk_i_id ? item.item.pk_i_id : null

        const reviewerResource = item.reviewer && item.reviewer.oc_t_user_resource ? item.reviewer.oc_t_user_resource : null
        const reviewerSocialData = item.reviewer  && item.reviewer.social ? item.reviewer.social : []
        const reviewerSocialResource = getSocialUrl(reviewerSocialData)
        const avatarSwitch = () => {
            let element = []
                if (reviewerResource) {
                    element.push(<FKImage key={1} images={[reviewerResource]} type="chat-history-avatar"/>)
                } else if(reviewerSocialResource){
                    element.push(<img key={1} src={reviewerSocialResource} style={{borderRadius: '50%', maxWidth: 36, maxHeight: 36}} alt='profile-avatar'/>)
                } else{
                    element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 36, maxHeight: 36}} alt='profile-avatar'/>)
                }
            return element
        }

        const item_title = item.item && item.item.oc_t_item_description && item.item.oc_t_item_description.s_title ? item.item.oc_t_item_description.s_title  : ''
        const category = item.item && item.item.oc_t_category && item.item.oc_t_category.oc_t_category_descriptions && item.item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
        const itemSLug = slugify(item_title)
        const finalSLug = `/${category}/${itemSLug}_i${itemId}`
        const review_src = item && item.review_resource && item.review_resource.length !== 0 ? item.review_resource : []

        return (
            <div key={index} className='review-item-container'>
                <div className='flex flex-d-row'>
                    <div>
                        <div className='review-item-image-container'>
                            {avatarSwitch()}
                        </div>
                    </div>
                    <div className='review-item-details'>
                        <Link to={`/user/${reviewerId}/listing`}> <p className='reviewer-name'>{`${reviewName}`}</p></Link>
                        <div className='flex flex-justify-between flex-align-start'>
                            <div className="flex flex-align-center flex-justify-start"> <p className='item-average-container'><span className='item-average-text'>{totalRate}</span><span className='item-average-text2'>/5</span></p>
                                <StarRatingPlain totalStar={5} average= {totalRate} size={10}/> 
                            </div>
                            <div>
                                <p className='review-date'>{createAt}</p>
                            </div>
                        </div>
                        <div className='mt-10'>
                            <p className='item-review-body'>{body}</p>
                        </div>
                        {
                           review_src && review_src.length !== 0 ?
                            <div className='review-attach-image-container'>
                                <FKImage images={review_src} type="review-attach-item" />
                            </div> : null
                        }
                        <Link to={finalSLug}>
                            <div className="review-item-main-container mt-10">
                                <FKImage images={[itemImgResource]} type="review-item" />
                                <div className="review-item-title-container">
                                    <span className="review-item-title">{item_title}</span>
                                    {/* <span class="Kg5RKE">Variation: 4PC</span> */}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <div className='review-item-container-top'>
                    <div>
                        <div className='review-item-image-container'>
                        {avatarSwitch()}
                        </div>
                        <div className='review-item-details'>
                        <Link to={`/user/${reviewerId}/listing`}> <p className='reviewer-name'>{`${reviewName}`}</p></Link>
                        <div className="flex flex-align-center flex-justify-start"> <p className='item-average-container'><span className='item-average-text'>{totalRate}</span><span className='item-average-text2'>/5</span></p>
                            <StarRatingPlain totalStar={5} average= {totalRate} size={10}/> </div>
                        </div>
                        <p className='review-date'>{createAt}</p>
                    </div>
                </div> */}
                {/* <p className='item-review-body'>{body}</p> */}
                {/* <div className='review-item-container-top'> */}
                    {/* <div style={{flex: 1}}>
                        <Link to={finalSLug}>
                            <div className='flex flex-d-row flex-align-center'>
                                <FKImage images={[itemImgResource]} type="review-item" />
                                <div>
                                    <p>{item_title}</p>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                    {/* <div className='review-item-rate-container'>
                        <p className='item-review-rate'><span>{`Communication `}</span><span className='average-text'>{communicationRate}</span></p>
                        {reviewerIsBuyer === 'buyer' ? <p className='item-review-rate'><span>{`Product `}</span><span className='average-text'>{productRate}</span></p> :
                            <p className='item-review-rate'><span>{`Payment `}</span><span className='average-text'>{paymentRate}</span></p>
                        }
                        <p className='item-review-rate'><span>{`Meet up /Shipping `}</span><span className='average-text'>{meetupShippingRate}</span></p>
                    </div> */}

                {/* </div> */}
        </div>
        )
    })
}