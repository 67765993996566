import React, {useReducer, useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { suggestWordLoad, addTopSearch } from '../../../redux/action'
import SearchData from './customComponent'
import { saveLocalStorage, getLocalStorage} from '../../helper/utility'
import {MagnifyIcon, MiniMagnifyIcon} from '../../../assets/svg'

const Search = (props) => {
    const location = useLocation()
    const getPath = location.pathname
    const sessionUserId = props.me && props.me.sessionData && props.me.sessionData.pk_i_id ? props.me.sessionData.pk_i_id : 0 

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            suggestInputType: 'item',
            suggestInputWord: '',
        }
    )

    useEffect(() => {
        if (!getPath.includes('search')) {
            setUserInput({['suggestInputWord']: ''})
            setIsSearching(false)
        }
    }, [getPath])

    const [isSearching, setIsSearching] = useState(false)
   
    const userData = props && props.suggest && props.suggest.data ? props.suggest : []
    const session_id = props && props.id ? props.id : 0
    const clickOutside = useRef(null)
    const limit = 2

    const handleClickOutside = event => {
        if(clickOutside.current && !clickOutside.current.contains(event.target)){
            setIsSearching(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
          document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const handleOptionChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserInput({[name]: newValue, ['suggestInputWord']: ''})
    }

    const searchSaveToLocal = (itemId, categoryId) => {
        if(sessionUserId !== 0){
            const localStorageArr = {key: userInput.suggestInputWord, sessionUserId: sessionUserId, itemId: itemId, categoryId: categoryId}
            saveLocalStorage("fkv2_search_key", localStorageArr)
        }
        props.addTopSearch(itemId)
    }

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserInput({[name]: newValue})

        if(newValue.length <= limit ){
            setIsSearching(false)
        } else {
            setIsSearching(true)
            props.suggestWordLoad({searchType: userInput.suggestInputType, limit: '5', text: newValue})
        }
    }

    const handleSearchSubmit = evt => {
        evt.preventDefault();
        if (isSearching) {
            const word = encodeURI(userInput.suggestInputWord)
            const test = `/search/${userInput.suggestInputType}/${word}`
            setIsSearching(false)
            props.history.push(test)
        }
    }

    return (
        <div className="search-container" ref={clickOutside}>
            <form onSubmit={handleSearchSubmit}>
                <div className="searchGroup">
                    <div className="options">
                        <div>
                        <select name="suggestInputType" className="searchSelector" onChange={handleOptionChange}>
                            <option value="item">Items</option>
                            <option value="user">Users </option>
                        </select>
                        </div>
                    </div> 
                    <div className="search-word-k-container">
                        <div className="searchWord">
                            <input type="text" minLength={3} placeholder={`${userInput.suggestInputType === 'item' ? 'Search the best items in town...' : 'Search for sellers name...'}`} value={userInput.suggestInputWord} className="input-search" name="suggestInputWord" onChange={handleChange} autoComplete="off"/>
                            {isSearching ? <div className="autoSuggest-container"><SearchData searchSaveToLocal={searchSaveToLocal} results={userData} id={session_id} type={userInput.suggestInputType} /></div> : null}
                        </div>
                        <div className="submitButton">
                            <button type="submit">
                                {
                                    window.innerWidth < 600 ?
                                    <MiniMagnifyIcon /> : <MagnifyIcon />
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        suggest: store.suggestReducer,
        me: store.userReducer
    }
}

const mapActionToProps = {
    suggestWordLoad,
    addTopSearch
}

export default withRouter(connect(mapStateToProps, mapActionToProps)(Search))