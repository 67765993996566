import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

const MobileTOU = ({data, query}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const hide = query ? query : null
    const touBodyData = data.TOS
    const touLastUpdate = data.lastUpdate

    const lister = (l) => {
        let listing
        listing = l.map((list) => <li>{list.text}</li>)
        return listing
    }
    const termsBody = (p, s) => {
        let body
        body = <p ><span className="content-section">{s}</span> {p}</p>
        return body
    }
    return (
        <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex: '4', background: '#FFFFFF', overflow: 'auto'}}>
             <div className="content-sub-container">
             <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                    <div></div>
                    <h1 className="content-main-title">{`Terms of Use`}</h1>
                    <>{
                        hide ? 
                        <div onClick={hide}>
                            <p>{`Close`}</p>
                        </div> :
                        <Link to={'/'}> <p>{`Close`}</p></Link>
                        }</>
                 </div>
             </div>
             <div className="content-body">
                 <h1 className="content-last-update">{`Last updated: ${touLastUpdate}`}</h1>
                 { touBodyData.map((terms, index) => {
                     const bodyContent = terms.body 
                     const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, data.section) : <div><ul className="content-list">{lister(data.body)}</ul></div>)
                     return <div key={index}>
                         <h1 className="content-title">{terms.title}</h1>
                         <div>
                             {bodyCheck}
                         </div>
                     </div>
                 })}
             </div>
         </div>
    )
}

export default MobileTOU