import React from 'react'
import { useLocation } from 'react-router-dom'
import GooglePlay from '../../../assets/images/playstore.svg'
import GooglePlayLG from '../../../assets/images/playstoreLG.svg'
import QRCode from '../../../assets/images/qrCode.jpeg'
import { GetItOnAppStoreLG, GetItOnPlayStoreLG, GetItOnPlayStore, GetItOnAppStore, CloseCircleIcon} from '../../../assets/svg'

export const CustomAd = (props) => {
    const title = props.title ? props.title : ''
    const description = props.description ? props.description : ''
    // const component = props.component ? props.component : null
    return (
        <div>
            <p>{title}</p>
            <p>{description}</p>
            {/* {component()} */}
        </div>
    )
}

export const DownloadTheAppAd = (props) => {
    const removeAd = props.removeFunc ? props.removeFunc : null
    const location = useLocation()
    const path = location.pathname
    return (
        path === '/user/item/new' ? null :
        <div className='download-the-app-ad-container'>
            <div className='desktop-icons'>
                <p className='download-label-text-p'>{`Try FishingKaki on-App`}</p>
                <p>{`Unlock exclusive features when you download the FishingKaki app today!`}</p>
                <div className='flex flex-d-row flex-align-center mt-30 desktop-icons'>
                    <div className='flex flex-d-column'>
                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center" target="_blank">
                            <img src={GooglePlayLG} />
                            {/* <GooglePlayLG /> */}
                        </a>
                        <div className='mt-5'>
                            <a href="https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549" className="flex flex-d-row flex-align-center" target="_blank">
                                {/* <img src={AppStore} width={150}/> */}
                                <GetItOnAppStoreLG />
                            </a>
                        </div>
                    </div>
                    <div className='qr-code-container'>
                        <img src={QRCode} />
                        {/* <p className='qr-label-p'>{`Android users only`}</p> */}
                    </div>
                </div>
            </div>
            <div className='mobile-icons w-100'>
                <div className='flex flex-d-row flex-align-center'>
                    <div onClick={() => removeAd()}>
                        <p className='close-icon-x ml-6'>{`x`}</p>
                    </div>
                    <div>
                        <p className='download-label-text-p'>{`FishingKaki on-App`}</p>
                        <p className='download-sublabel-text-p'>{`Classifieds (Buy & Sell)`}</p>
                    </div>
                    <div className='flex flex-d-column ml-36 '>
                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className='flex flex-d-row flex-align-center '>
                            <img src={GooglePlay} />
                        </a>
                        <a href='https://apps.apple.com/us/app/fishingkaki-classifieds/id1602722549' className='flex flex-d-row flex-align-center mt-10'>
                            <GetItOnAppStore />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}