import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

const MobilePrivacyPolicy = ({data, query}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const hide = query ? query : null
    const privacyBodyData = data.privacyPolicy
    const privacyLastUpdate = data.lastUpdate
    const lister = (l, i) => {
        let listing
        listing = l.map((list, index) => <li >{list.text}</li>)
        return listing
    }
    const termsBody = (p, index) => {
        let body
        body = <p >{p}</p>
        return body
    }
    return (
        <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex: '4', background: '#FFFFFF', overflow: 'auto'}}>
            <div className="content-sub-container">
                <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                <div ></div>
                    <h1 className="content-main-title">{`Privacy Policy`}</h1>
                    {query ? <div onClick={hide}><p>{`Close`}</p></div> : <Link to={'/'}> <p>{`Close`}</p></Link>}
                </div>
                </div>
                <div className="content-body">
                <h1 className="content-last-update">{`Last updated: ${privacyLastUpdate}`}</h1>
                    {
                        privacyBodyData.map((terms, index) => {
                            const bodyContent = terms.body
                            const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, index) : <div><ul className="content-list">{lister(data.body, index)}</ul></div>)
                            return <div key={`${index*10}Main`}>
                                    <h1 className="content-title">{terms.title}</h1>
                                    <div>
                                    {bodyCheck}
                                    </div>
                                </div>
                        })
                    }
             
            </div> 
            </div>
    )
}

export default MobilePrivacyPolicy