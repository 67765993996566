import React, {useReducer, useEffect, useRef} from 'react'
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { contactDataLoad, contactDataReset } from '../../../redux/action'
import {theme} from '../../../common/theme'
import SuccessBanner from './customComponent'

const ContactUs = (props) => {
    const { isLoading, data, error } = props.contact
    const {contactUs, sitekey} = theme.text
    let captcha;
    const [contactUserInput, setContactUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          email: '',
          subject: '',
          message: '',
          captcha: '',
          errorAlert: {
              errorEmail: '',
              errorSubject: '',
              errorMessage: '',
              errorCaptcha: ''
          }
        }
      );

      useEffect(() => {
        if (data) {
            setTimeout(() => {
                props.contactDataReset()
                reset()
            }, 2000)
        }
      }, [data])

      const setCaptchaRef = (ref) => {
        if (ref) {
          return captcha = ref;
        }
     };

      const captchaChange = value => {
        setContactUserInput({['captcha']: value})
        // if(contactUserInput.captcha !== ''){
        //     setContactUserInput({errorAlert:{['errorCaptcha']: ''}})
        // }

      }

      const resetCaptcha = () => {
        captcha.reset();
      }

    const reset = () => {
        setContactUserInput({
            ['email']: '', 
            ['subject']: '', 
            ['message']: '', 
            errorAlert: {
                ['errorEmail']: '',
                ['errorSubject']: '',
                ['errorMessage']: ''
            }
            })
            // resetCaptcha();
    }

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setContactUserInput({[name]: newValue});
    }

    const finalData = {
        email: contactUserInput.email,
        subject: contactUserInput.subject,
        message: contactUserInput.message
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        if(contactUserInput.email === '' || contactUserInput.subject === '' || contactUserInput.message === '' || contactUserInput.captcha === ''){
            setContactUserInput({errorAlert: {['errorEmail']: contactUserInput.email === '' ? 'Enter Email' : null, ['errorSubject']: contactUserInput.subject === '' ? 'Enter Subject' : null, ['errorMessage']: contactUserInput.message === '' ? 'Describe your concern' : '', ['errorCaptcha']: contactUserInput.captcha === '' ? 'Please verify with recaptcha' : null}})
        }else {
            props.contactDataLoad(finalData)
            //reset()
        }
      }

    return (
        <div className="content-main-container">
            <div className="content-sub-container contact-parent-main-container">
                <div className="contact-wrap-container">
                    <h1 className="content-main-title contact-text-title">{'CONTACT US'}</h1>
                    <div className="contact-body">
                        {
                            data ? <SuccessBanner />:
                            <div className="contact-container">
                            <div className="contact-sub-container">
                                <form onSubmit={handleSubmit}>
                                    <div className="contact-input-container">
                                        <p className="contact-label">{`Email Address`}</p>
                                        <input type="email" name="email" placeholder="Enter email address" value={contactUserInput.email} onChange={handleChange} required/>
                                        {contactUserInput.errorAlert.errorEmail ? <p className="item-new-text item-new-text-red">{contactUserInput.errorAlert.errorEmail}</p> : null}
                                    </div>
                                    <div className="contact-input-container">
                                        <p className="contact-label">{`Subject`}</p>
                                        <div className="contact-select-container">
                                            <div className="contact-input-sub-container">
                                                <div className="contact-input-main-container">
                                                    <select name="subject" onChange={handleChange} >
                                                        <option disabled selected className="contact-text-gray">{`Select issue`}</option>
                                                        {
                                                            contactUs.map((options, index) => {
                                                            return <option key={index}>{options.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {contactUserInput.errorAlert.errorSubject ? <p className="item-new-text item-new-text-red">{contactUserInput.errorAlert.errorSubject}</p> : null}
                                    </div>
                                    <div className="contact-input-container no-margin">
                                        <p className="contact-label">{`Message`}</p>
                                        <textarea autoComplete="nope" rows="3" name="message" value={contactUserInput.message} onChange={handleChange} placeholder="Tell us more about it...." />
                                        {contactUserInput.errorAlert.errorMessage ? <p className="item-new-text item-new-text-red">{contactUserInput.errorAlert.errorMessage}</p> : null}
                                    </div>
                                    <div className="contact-input-container">
                                        <ReCAPTCHA 
                                        ref={(r) => setCaptchaRef(r)}
                                        sitekey={sitekey} 
                                        onChange={() =>captchaChange() } />
                                        {contactUserInput.errorAlert.errorCaptcha ? <p className="item-new-text item-new-text-red">{contactUserInput.errorAlert.errorCaptcha}</p> : null}
                                    </div>
                                    <div className="contact-submit-container">
                                        { isLoading ? <p>Processing...</p> :
                                            error ? <p style={{color: 'red'}}></p> :
                                                // data ? <p style={{color: 'green'}}>{data}</p> :
                                                    <input type="submit" name="submit" className={ contactUserInput.email === '' || contactUserInput.subject === '' || contactUserInput.message === '' || contactUserInput.captcha === '' ? `contact-btn-default` : `contact-btn-active`}/>
                                        }

                                    </div>
                                </form>
                            </div>
                        </div>
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = store => {
    return {
    contact: store.contactReducer
    };
  };
  
  const mapActionToProps = {
    contactDataLoad,
    contactDataReset
  }

export default connect(mapStateToProps,mapActionToProps)(ContactUs)