import React, { useState, useEffect } from 'react';
import { chatLoad, sendChatLoad, chatUpdateUnread, chatAddRoom, chatSetActiveIndex, sendChatReset, sendReviewLoad, sendReviewReset, setChatMobileIsThread, deleteChatLoad } from '../../../redux/action'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {ReviewSuccessIllustration} from '../../../assets/svg'
import { WholePageLoader, WholePageActivated} from '../../../common/component/customComponents'
import useWindowDimensions from '../../../common/helper/useWindowDimension'
import WebChat from './component/web'
import MobileChat2 from './component/mobile2'
import { ChatContext} from '../../../common/context'
import AntiScamAlert from '../../../common/component/alert/AntiScamAlert'

const chat_alert = localStorage.getItem('chat-alert');

const Messages = props => {

  let history = useHistory();
  const session = props.session
  const chatProps = props.chat
  const me = props.me && props.me.sessionData ? props.me.sessionData : []
  const isLoading = props.chat && props.chat.isLoading ? props.chat.isLoading : false
  const sendIsLoading = props.chat && props.chat.sendIsLoading ? props.chat.sendIsLoading : false
  const sendMessage = props.chat && props.chat.sendMessage ? props.chat.sendMessage : false
  const sendError = props.chat && props.chat.sendError ? props.chat.sendError : false
  const thread = props.chat && props.chat.mobileIsThread ? props.chat.mobileIsThread : false
  const chatUpdateUnread = props.chatUpdateUnread
  const chatArrFromItemView = props.history && props.history.location && props.history.location.chatR ? props.history.location.chatR : []
  const activeIndexFromItemView = chatArrFromItemView && chatArrFromItemView[0] && chatArrFromItemView[0].index ? chatArrFromItemView[0].index : 0
  const isFromItemView = props.history && props.history.location && props.history.location.isFromItemView ? props.history.location.isFromItemView : false
  const { height, width } = useWindowDimensions();

  const fromMessage = props.history && props.history.location && props.history.location.state && props.history.location.state.fromMessage ? props.history.location.state.fromMessage : false
  const messageItem = props.history && props.history.location && props.history.location.state && props.history.location.state.item ? props.history.location.state.item : false

  const [tempIsExist, setTempIsExist] = useState(false)

  const addTempChatRoom = (data) => {
    props.chatAddRoom(data)
    history.replace();
  }
  
  const sendChat = (data) => {
    props.sendChatLoad(data)
    history.replace();
  }

  const resetSendChat = (data) => {
    props.sendChatReset(data)
  }

  // const chatMarginTop = window.getComputedStyle(document.documentElement).getPropertyValue("--chat-margin-top").slice(0, -2);
  // const chatAdsHeight = window.getComputedStyle(document.documentElement).getPropertyValue("--chat-ads-height").slice(0, -2);
  // useEffect(() =>{
  //   document.documentElement.style.setProperty(
  //     "--chat-container-height", `${height - (parseInt(chatMarginTop) + parseInt(chatAdsHeight))}px`,
  //     "--chat-container-width", `${width}px`,
  //   );
  //   return () => history.replace();
  // // eslint-disable-next-line   
  // }, [height])

  //var & func from chat items
  const [chatRoomData, setChatRoomData] = useState([chatProps.data[0]])
  const chatPropsData = chatProps && chatProps.data ? chatProps.data : []
  const activeIndex = chatProps && chatProps.activeIndex ? chatProps.activeIndex : 0

  const setActiveIndex = (index) => {
    props.chatSetActiveIndex(index)
  }

  const setMessagesData = (data) => {
    setChatRoomData(data)
  }

  const sendReviewIsLoading = chatProps && chatProps.sendReviewIsLoading ? chatProps.sendReviewIsLoading : false
  const sendReviewMessage = chatProps && chatProps.sendReviewMessage ? chatProps.sendReviewMessage : false
  const sendReviewError = chatProps && chatProps.sendReviewError ? chatProps.sendReviewError : false


  const sendReview = (data) => {
    props.sendReviewLoad(data)
  }

  const resetSendReview = (data) => {
    props.sendReviewReset(data)
  }

  const setChatMobileIsThread = (status) => {
      props.setChatMobileIsThread(status)
  }

  //delete
  const [showDelete, setShowDelete] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const {deleteChatIsLoading, deleteChatData, deleteChatMessage, deleteChatError} = chatProps

  const toggleShowDelete = () => {
      setShowDelete(!showDelete)
  }

  const toggleDeleteAlert = () => {
    if (showDelete) {
      setShowDelete(false)
    }
    setShowDeleteAlert(!showDeleteAlert)
  }

  const deleteChat = (user_id, chat_room_id) => {
    setShowDeleteAlert(false)
    props.deleteChatLoad({user_id, chat_room_id})
  }

  useEffect(() => {
    if(deleteChatMessage || deleteChatError){
      setTimeout(() =>{
        window.location.replace(`${process.env.REACT_APP_BASE_URL}user/messages/inbox`);
      }, 2000)
    }
  }, [deleteChatMessage, deleteChatError])

  useEffect(() => {
    if(isFromItemView){
      setActiveIndex(activeIndexFromItemView)
    }
  },[isFromItemView])

  //rating
  const [showRequestReviewAlert, setShowRequestReviewAlert] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)

  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false)

  const toggleShowRequestReviewAlert = () => {
    setShowRequestReviewAlert(!showRequestReviewAlert)
  }

  const toggleShowRequestReviewModal = () => {
    // alert('test')
    setShowRequestReviewModal(!showRequestReviewModal)
  }

  const toggleShowReviewModal = () => {
    // alert('test')
    setShowReviewModal(!showReviewModal)
  }

  const requestReviewAlertProceed = (data) => {
    setShowRequestReviewAlert(false)
    //send chat with rating status
    sendChat({data})
    //setShowReviewModal(true)
  }

  useEffect(() => {
    if(sendReviewMessage || sendReviewError){
      toggleShowReviewModal()
      setTimeout(() => {
        props.sendReviewReset()
      },2000)
    }
  },[sendReviewMessage, sendReviewError])

  const ReviewSuccessMessage = () => {
    return (
      <div className='p-fixed w-100 h-100 bg-black-op-p3 top-0 z-ndx-5 flex flex-align-center flex-justify-center'>
        <div className='flex flex-d-column flex-justify-center flex-align-center bg-white review-modal-container'>
          <p className='review-modal-title-p'>{`Terima Kasih!`}</p>
          <p className='review-modal-subtitle-p'>{`Review is now published, stay awesome!`}</p>
          <ReviewSuccessIllustration />
          <div className='review-modal-btn-container' onClick={() => props.sendReviewReset()}>
            <p>{`OK LAH!`}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="chat-container">
      {/* {chat_alert ? null : <AntiScamAlert type='chat'/>} */}
      {width > 800 ? <div style={{height: 15}}/> : null}
      <div className={width > 800 ? "chat-main-container" : "chat-main-container-mobile"}>
        <ChatContext.Provider 
          value={{
            session, chatProps, sendChat, chatUpdateUnread,
            setMessagesData, activeIndex, setActiveIndex, chatPropsData, chatRoomData, 
            me, height, 
            fromMessage, messageItem, addTempChatRoom, tempIsExist, setTempIsExist, 
            sendIsLoading, sendMessage, resetSendChat, sendError,
            sendReview, resetSendReview, sendReviewIsLoading, sendReviewMessage, sendReviewError,
            isLoading,
            thread, setChatMobileIsThread,
            showDelete, toggleShowDelete, setShowDeleteAlert, toggleDeleteAlert, showDeleteAlert, deleteChat,
            showRequestReviewAlert, toggleShowRequestReviewAlert, 
            requestReviewAlertProceed,
            showReviewModal, setShowReviewModal,
            toggleShowReviewModal
            // toggleShowRequestReviewModal,

          }}
        >
        {width < 800 ? <MobileChat2/> : <WebChat/>}  
        </ChatContext.Provider>
      </div>
      { isLoading || sendReviewIsLoading ? <WholePageLoader /> : null }
      {sendReviewMessage ? ReviewSuccessMessage()  : null}
      { 
        deleteChatIsLoading ? <WholePageLoader /> :
        deleteChatMessage ? <WholePageActivated text={deleteChatMessage}/> :
        deleteChatError ? <WholePageActivated text={deleteChatError}/>  : null
      }
    </div>
  );
}

const mapStateToProps = store => {
  return {
    chat: store.chatReducer,
    me: store.userReducer,
  };
};

const mapActionToProps = {
  chatLoad,
  sendChatLoad,
  chatUpdateUnread,
  chatAddRoom,
  chatSetActiveIndex,
  sendChatReset,
  sendReviewLoad, 
  sendReviewReset,
  setChatMobileIsThread,
  deleteChatLoad
};

export default connect(mapStateToProps,mapActionToProps)(Messages);