import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminLogsSuccess, adminLogsFailed } from "../../action";
import admin from '../../../graphql/admin/logs'
import { theme } from '../../../common/theme'

//fetch sale
function* adminLogs(action) {
    const { payload } = action
    console.log('adminLogs saga payload',payload )
    try{
        const result = yield admin.getLogs(payload)
        if(result === 403) {
            yield put(adminLogsFailed('Something went wrong try again!'))
        }
        else {
            console.log('adminLogs saga result', result )
            const data = result.data && result.data.getLogs ? result.data.getLogs : {}
            yield put(adminLogsSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminLogsFailed(errorMessage))
    }
}

export function* watchAdminLogs() {
    yield takeLatest(ADMIN.LOGS.LOAD, adminLogs);
}

// //fetch sale Detail
// function* adminGetSaleDetail(action) {
//     const { payload } = action

//     try{
//         const result = yield admin.getSaleDetail(parseInt( payload.id))
//         if(result === 403) {
//             yield put(adminSaleDetailFailed('Something went wrong try again!'))
//         }
//         else {
//             const data = result.data && result.data.getPayMentDetail && result.data.getPayMentDetail ? result.data.getPayMentDetail : {}
//             yield put(adminSaleDetailSuccess(data));
//         }
//     } catch (e) {
//         const error = e && e.graphQLErrors ? e.graphQLErrors : e
//         const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
//             ? error[0].extensions.validation : 
//               error && error[0] && error[0].message ? error[0].message : theme.text.error.default
//         console.log('e', JSON.stringify(e))
//         yield put(adminSaleDetailFailed(errorMessage))
//     }
// }

// export function* watchAdminGetSaleDetail() {
//     yield takeLatest(ADMIN.SALE_DETAIL.LOAD, adminGetSaleDetail);
// }

// //suggest sale
// function* adminSuggestSale(action) {
//     const { payload } = action
//     try{
//         const result = yield admin.searchSaleForSuggest(payload)
//         if(result === 403) {
//             yield put(adminSaleSuggestFailed('Something went wrong try again!'))
//         }
//         else {
//             const data = result.data && result.data.searchSale ? result.data.searchSale : []
//             yield put(adminSaleSuggestSuccess(data));
//         }
//     } catch (e) {
//         const error = e && e.graphQLErrors ? e.graphQLErrors : e
//         const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
//             ? error[0].extensions.validation : 
//               error && error[0] && error[0].message ? error[0].message : theme.text.error.default
//         console.log('e', JSON.stringify(e))
//         yield put(adminSaleSuggestFailed(errorMessage))
//     }
// }

// export function* watchAdminSuggestSale() {
//     yield takeLatest(ADMIN.SALE_SUGGEST.LOAD, adminSuggestSale);
// }