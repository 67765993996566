import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { dbsTokenRequestLoad } from '../../../../redux/action'
import Loader from '../../../../common/component/loader'
import useWindowDimension from '../../../../common/helper/useWindowDimension'
import { theme } from '../../../../common/theme'
import { getLocalStorage, removeLocalStorage } from '../../../../common/helper/utility'

const PayNowRedirect = (props) => {
    const { height } = useWindowDimension();
    const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const fkv2_purchased_data = getLocalStorage("purchase_data")
    const [directoProfile, setDirectoProfile] = useState(false)
    const [directoHome, setDirectoHome] = useState(false)
    const { isLoading, error, toKenData } = props.dbs
    
    //effect to parse url params, post to server & to get token using authorization code
    useEffect(() => {
        const search = props && props.history && props.history.location && props.history.location.search ? props.history.location.search : ''
        const parsedString = search.substr(1)
        const searchParams = new URLSearchParams(parsedString)
        if (fkv2_purchased_data) {
            props.dbsTokenRequestLoad({code: searchParams.get('code'), state: searchParams.get('state'), fkv2_purchased_data, userId: userId})
        }
        else {
            setDirectoHome(true)
        }
     // eslint-disable-next-line    
    },[])

    useEffect(() => {
      if (toKenData) {
          setDirectoProfile(true)
      }
     // eslint-disable-next-line    
    },[toKenData])

    const gotoProfile = () => {
        removeLocalStorage("purchase_data")
        return directoProfile ? <Redirect to='/user/wallet' /> : null;
    }

    const gotoHome = () => {
        return directoHome ? <Redirect to='/' /> : null;
    }

    return (
        <div>
            {gotoHome()}
            {gotoProfile()}
            <div style={style.itemContainer}>
                { isLoading ? <Loader height={height * .9}/> : null }
                { error ? <p style={{color: 'red', textAlign: 'center', minHeight: height * .5 }}>{error}</p> : null }
            </div>
        </div>
    );
}

const style = {
    itemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gridGap: '1rem',
      margin: '15px',
      fontFamily: theme.font.primary
    },
}

const mapStateToProps = store => {
    return {
        dbs: store.dbsReducer
    };
};
  
const mapActionToProps = {
    dbsTokenRequestLoad
}

  export default connect(mapStateToProps,mapActionToProps)(PayNowRedirect);