import React, {useContext, useEffect } from 'react';
import MobileHeader from './child/MobileHeader'
import MobileInbox from './child/MobileInbox'
import MobileHistory from './child/MobileHistory'
import MobileInput from './child/MobileInput'
import { ChatContext } from '../../../../../common/context'

const MobileChat2 = () => {
  const value = useContext(ChatContext)
  const { chatProps, activeIndex, setMessagesData, fromMessage, thread, setChatMobileIsThread } = value
  const data = chatProps && chatProps.data ? chatProps.data : []

  const toggleThread = () => {
    setChatMobileIsThread(!thread)
  }

  useEffect(() => {
      const data = chatProps && chatProps.data && chatProps.data[activeIndex] ? chatProps.data[activeIndex] : []
      setMessagesData(data)
      window.focus({preventScroll: true})
  // eslint-disable-next-line
  }, [chatProps])

  useEffect(() => {
    if (fromMessage) {
        setChatMobileIsThread(true)
    }
  }, [fromMessage])

  return (
      
    <div className="h-100">
       <MobileHeader toggleThread={toggleThread} thread={thread} data={data}/>
        {   
            thread ?
            <div className="h-100">
                <MobileHistory />
                <MobileInput />
            </div> :
            <div> 
                <MobileInbox toggleThread={toggleThread}/>
            </div> 
        }
    </div>
  )
}

export default MobileChat2;