import { theme } from '../../../common/theme'

export const style = {
    boxContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: 20,
    },
    box: {
      border: '1px solid #E1E1E1',
      padding: '10px',
      width: '50%',
      minWidth: 500,
      minHeight: 500,
    },
    boxHead: {
      textAlign: 'center',
      borderBottom: '1px solid #E1E1E1'
    },
    boxBody: {
      margin: '50px',
    },
    formGroup: {
      marginBottom: '1rem',
      display: 'flex', 
      flexDirection: 'column'
    },
    formLabel: {
      padding: '4px 50px 0px 0px',
      display: 'inline-block',
      marginBottom: '1rem',
      fontWeight: '700',
      color: '#333',
      flex: 1
    },
    formInput: {
      padding: '10px',
      border: '2px solid #bdc3c7',
      borderRadius: '5px',
      flex: 1
    },
    signupBtn: {
      textAlign: 'center',
      padding: '10px 40px',
      margin: '40px 0',
      border: '2px solid #00aaff',
      borderRadius: '5px',
      width: '100%',
      fontSize: '18px',
      color: theme.color.secondaryColor,
      backgroundColor: theme.color.primaryBg,
     }
  }