import React, { useReducer, useEffect, useCallback } from 'react';
import moment from 'moment';
import { theme } from '../../../../../../common/theme'
import Dropzone from 'react-dropzone-uploader'
import { InfoUnfilledIcon, CloseIcon, AddImageBtnGrayXLarge} from '../../../../../../assets/svg'
import { NumberRating} from '../../../../../../common/component/rating'
import { GAevent } from '../../../../../../common/googleAnalytics'
import FKImage from '../../../../../../common/component/image'

const { REACT_APP_WEB_SEC } = process.env;

export const OfferModal = (props) => {
    const headerName = props.detail && props.detail.headerName ? props.detail.headerName : ''
    const itemPrice = props.detail && props.detail.itemPrice ? props.detail.itemPrice : 0
    const avatarSwitch = props.detail && props.detail.avatarSwitch ? props.detail.avatarSwitch : null
    const showOfferModal = props.detail && props.detail.showOfferModal ? props.detail.showOfferModal : null
    const chatOwnerId = props.detail && props.detail.chatOwnerId ? props.detail.chatOwnerId : 0
    const userId = props.detail && props.detail.userId ? props.detail.userId : 0
    const chatItemId = props.detail && props.detail.chatItemId ? props.detail.chatItemId : 0
    const chatRoomId = props.detail && props.detail.chatRoomId ? props.detail.chatRoomId : 0
    const sendChat = props.detail && props.detail.sendChat ? props.detail.sendChat : null
    const offer_amount = props.detail && props.detail.offer_amount ? props.detail.offer_amount : 0
    const sendIsLoading = props.detail && props.detail.sendIsLoading ? props.detail.sendIsLoading : null
    const sendMessage = props.detail && props.detail.sendMessage ? props.detail.sendMessage : null
    const resetSendChat = props.detail && props.detail.resetSendChat ? props.detail.resetSendChat : null
    const sendError = props.detail && props.detail.sendError ? props.detail.sendError : null
    const useEditOffer = props.detail && props.detail.useEditOffer ? props.detail.useEditOffer : null
    const toggleModal = props.detail && props.detail.toggleModal ? props.detail.toggleModal : null
    const madeOfferId = props.detail && props.detail.madeOfferId ? props.detail.madeOfferId : 0

    const [amountInput, setAmountInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
        message: offer_amount,
        }
    );

    const handleChange = event => {
        const name = event.target.name;
        const newValue = event.target.value;
        setAmountInput({[name]: newValue});
    }

    const handleSend = (e) => {
        GAevent("user", "make_offer")
        e.preventDefault()
        const data = {
            owner_id: chatOwnerId,
            sender_id: userId,
            item_id: chatItemId,
            body: `${useEditOffer ? theme.text.buyerOfferStatus.editOffer.codeValue : theme.text.buyerOfferStatus.madeOffer.codeValue}${REACT_APP_WEB_SEC}${amountInput.message}`,
            chat_room_id: chatRoomId,
            chat_message_id: madeOfferId ? madeOfferId : null
        }
        sendChat({data})
    }

    //message and error handling & modal closing
    useEffect(() => {
        if (sendMessage) {
            setTimeout(() => {
                setAmountInput({['message']: 0});
                resetSendChat()
                toggleModal()
            }, 2000)
        }
        if (sendError) {
            setTimeout(() => {
                resetSendChat()
            }, 2000)
        }
    }, [sendMessage, sendError])

    return (
        <div className={showOfferModal ? `flex modal-index-container` : `hide`}>
            <div className={"modal-offer-container"}>
                <div className="offer-header">
                    <div className="offer-header-one">
                        {avatarSwitch()}
                        <div className="offer-details">
                            <p>{`${headerName} is selling it for`}</p>
                            <p>{`SGD ${itemPrice}`}</p>
                        </div>
                    </div>
                    <div style={{width: 16}}>
                        <span className="offer-close" onClick={() => toggleModal()}>&times;</span>
                    </div>
                </div>
                <form onSubmit={(e) =>handleSend(e)}>
                    <div className="offer-form">
                        <p className="offer-form-text1">You are offering</p>
                        <div className="offer-form-container">
                            <div className="edit-profile-form-sub">
                                <label className="offer-input-label">SGD </label>
                                <input type="number" inputMode="numeric" className="offer-input" autoComplete="off" name="message"  placeholder={0} value={amountInput.message === 0 ? '' : amountInput.message} onChange={(e) =>handleChange(e)} required/>
                            </div>
                        </div>
                        {sendIsLoading ? <p>Processing...</p> : 
                            sendMessage ? <p style={{color: 'green'}}>{sendMessage}</p> :
                                sendError ? <p style={{color: 'red'}}>{sendError}</p> :
                        <button type="submit" className="modal-offer-button"><p className="offer-button-text">{useEditOffer ? 'Edit Offer' : 'Make offer'}</p></button>}
                    </div>
                </form>
            </div>
        </div>
    );
}

// export const AcceptOfferElement = ({isID}) => {
//     return (
//         <div className="accept-text-container">
//             <p className="accept-text big">Yey! A deal has been made!</p>
//             <HandShakeIcon/>
//             {/* <p className="accept-text small"><span style={{fontWeight: '600'}}>{isID ? `Seller Tip: ` : `Buyer Tip: `}</span> &nbsp; {isID ? ` Mark the item as sold only after the item has been traded` : ` Faster go take the thing lah!`}</p> */}
//         </div>
//     )
// }

// export const SoldElement = () => {
//     return (
//         <div className="accept-text-container" style={{marginBottom: 16}}>
//             {/* <p className="sold-text-message">Congratulations! The item has been marked as sold!</p> */}
//             <p className="sold-text-message">You can leave a review now!</p>
//             {/* <SoldIcon/> */}
//             <p className="sold-text-message">Keep rockin’</p>
//         </div>
//     )
// }

// export const DeclineElement = () => {
//     return ( <DeclineIcon/>)
// }

export const SubmitBtn = () => {
    return (
      <div key={'dp'} className="attached-upload-container">
        <AddImageBtnGrayXLarge />
      </div>
    )
  }

export const ReviewModal = (props) => {
    const showReviewModal = props.detail && props.detail.showReviewModal ? props.detail.showReviewModal : null
    const toggleReviewModal = props.detail && props.detail.toggleReviewModal ? props.detail.toggleReviewModal : null
    const loginIsOwner = props.detail && props.detail.loginIsOwner ? props.detail.loginIsOwner : null
    const userId = props.detail && props.detail.userId ? props.detail.userId : 0
    const chatOwnerId = props.detail && props.detail.chatOwnerId ? props.detail.chatOwnerId : 0
    const chatGuestId = props.detail && props.detail.chatGuestId ? props.detail.chatGuestId : 0
    const chatItemId = props.detail && props.detail.chatItemId ? props.detail.chatItemId : 0
    const sendReview = props.detail && props.detail.sendReview ? props.detail.sendReview : null
    const resetSendReview = props.detail && props.detail.resetSendReview ? props.detail.resetSendReview : null
    const sendReviewIsLoading = props.detail && props.detail.sendReviewIsLoading ? props.detail.sendReviewIsLoading : null
    const sendReviewMessage = props.detail && props.detail.sendReviewMessage ? props.detail.sendReviewMessage : null
    const sendReviewError = props.detail && props.detail.sendReviewError ? props.detail.sendReviewError : null
    const chatRoomId = props.detail && props.detail.chatRoomId ? props.detail.chatRoomId : null
    const sellerUsername = props.detail && props.detail.sellerUsername ? props.detail.sellerUsername : null
    const buyerUsername = props.detail && props.detail.buyerUsername ? props.detail.buyerUsername : null

    const [ratingInput, setRatingInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            reviewer_id: parseInt(userId), 
            reviewee_id: loginIsOwner ? chatGuestId : chatOwnerId,
            product_rate: null,
            //communication_rate as user rate (seller or buyer)
            communication_rate: null,
            payment_rate: null,
            meetup_shipping_rate: null,
            body: ' ',
            review_ratingable_id: loginIsOwner ? 2 : 1,
            review_item_id: parseInt(chatItemId),
            chat_room_id: parseInt(chatRoomId),
            rating_image: []
        }
    );

    const handleChangeStatus = (imageContext, status) => {
        if (status === 'done') {
            const tempData = imageContext && imageContext.file && ratingInput && ratingInput.rating_image.concat(imageContext)
          if(ratingInput && ratingInput.rating_image && ratingInput.rating_image.length < 4 ){
            setRatingInput({['rating_image']: tempData})
          }
        }
        if (status === 'removed') {
          const tempData = ratingInput.rating_image.filter((obj) => {
            return obj.file.name !== imageContext.file.name
          })
          setRatingInput({['rating_image']: tempData})
        }
      }

    const handleChange = (name, value) => {
        const inputName = name;
        const newValue = value;
        setRatingInput({[inputName]: newValue});
    }

    const handleSend = (e) => {
        let finalImageDataArr = []
        ratingInput.rating_image.map((item) => {
            return finalImageDataArr.push(item.file)
        })
        const finalRatingInput = {...ratingInput, rating_image: finalImageDataArr }

        e.preventDefault()
        sendReview({ratingInput: finalRatingInput})
    }


    //message & error handling & modal closing
    useEffect(() => {
        if (sendReviewMessage) {
            setTimeout(() => {
                resetSendReview()
                toggleReviewModal()
            }, 2000)
        }
        if (sendReviewError) {
            setTimeout(() => {
                resetSendReview()
                toggleReviewModal()
            }, 2000)
        }
    }, [sendReviewMessage, sendReviewError])

    const renderRatingLabel = useCallback(() => {
        switch(ratingInput.communication_rate){
            case 1:
            return <p className='ratings-desc-p'>{`Jialat!`}</p>

            case 2:
            return <p className='ratings-desc-p'>{`Aiyoh!`}</p>

            case 3:
            return <p className='ratings-desc-p'>{`Boleh!`}</p>

            case 4:
            return <p className='ratings-desc-p'>{`Shiok!`}</p>

            case 5:
            return <p className='ratings-desc-p'>{`Best Lah!`}</p>

            default:
            return <p>{``}</p>
        }
    },[ratingInput.communication_rate])

    return (
        <div className={showReviewModal ? `flex modal-index-container` : `hide`}>
            <div className={"modal-review-container"}>
                {
                    sendReviewIsLoading ? <p>Processing...</p> :
                    <>
                        <div className="review-header">
                            <div style={{width: 16}}>
                                <span className="offer-close" onClick={() => toggleReviewModal()}>&times;</span>
                            </div>
                        </div>
                        <p className='review-title'>{theme.text.leaveAReview}</p>                
                        <form onSubmit={(e) =>handleSend(e)}>
                            <div className="rating-input">
                                <p className="rating-input-label">{`${theme.text.ratingTitle} ${loginIsOwner ? buyerUsername : sellerUsername}?`}</p>
                                <NumberRating totalStars={5} handleChange={handleChange} inputName={'communication_rate'} />
                                <div className='w-100 flex flex-justify-center flex-align-center'>
                                    {renderRatingLabel()}
                                </div>
                            </div>
                            <div className="review-input">
                                <p className="rating-input-review">{`Please write a review for the ${loginIsOwner ? 'buyer' : 'seller'}.`}</p>
                                <textarea className="review-text-area" rows={5} placeholder='Tell us more...(optional)' maxLength={200} value={ratingInput.body} onChange={(e) => handleChange('body', e.target.value)}/>
                            </div>
                            <div className='flex flex-align-center flex-justify-end mb-20'>
                                <div className='ratings-add-photo-container flex flex-d-column flex-align-end'>
                                    <Dropzone 
                                        onChangeStatus={handleChangeStatus}
                                        accept="image/*"
                                        maxFiles={1}
                                        chunking={true}
                                        inputContent={<SubmitBtn />}
                                    />
                                    {ratingInput.rating_image && ratingInput.rating_image.length === 0 ? <p className='review-add-photo-p'>{`Add photo`}<span>{`(Optional)`}</span></p> : null }
                                </div>
                            </div>
                            {sendReviewIsLoading ? <p>Processing...</p>:
                                sendReviewMessage ? <p className='green'>{sendReviewMessage}</p>:
                                    sendReviewError ? <p className='red'>{sendReviewError}</p>:
                                    <button type="submit" className="modal-rating-button"><p className="offer-button-text">{'Submit'}</p></button>
                            }
                            <div className='flex flex-d-row flex-align-center mt-20'>
                                <div className='flex flex-align-center'>
                                    <InfoUnfilledIcon />
                                </div><p className='review-note'>{theme.text.reviewNote}</p></div>
                        </form>
                     </>
                }
            </div>
        </div>
    );
}

export const ChatDeleteModal = (props) => {
    const showDeleteAlert = props.sharedProps && props.sharedProps.showDeleteAlert ? props.sharedProps.showDeleteAlert : null
    const setShowDeleteAlert = props.sharedProps && props.sharedProps.setShowDeleteAlert ? props.sharedProps.setShowDeleteAlert : null
    const chatRoomData = props.sharedProps && props.sharedProps.chatRoomData ? props.sharedProps.chatRoomData : []
    const userId = props.sharedProps && props.sharedProps.userId ? props.sharedProps.userId : 0
    const deleteChat = props.sharedProps && props.sharedProps.deleteChat ? props.sharedProps.deleteChat : null
    const chatRoomId = chatRoomData && chatRoomData.id ? chatRoomData.id : null
    return (
        <div className={showDeleteAlert ? `flex delete-modal-main-container ${window.innerWidth < 600 ? `flex-align-center` : ``}` : `hide`}>
            <div className="delete-message-alert-modal">
                {
                    <>
                    {
                        window.innerWidth < 600 ? 
                        <div className="flex flex-d-column flex-justify-center"> 
                            <div className="delete-message-close" onClick={() => setShowDeleteAlert(false)}>
                                <CloseIcon />
                            </div>
                            <p className="delete-message-confirm-text">Are you sure you want to delete this conversation?</p>
                        </div> :
                        <div className="flex flex-d-row flex-justify-center">
                            <p className="delete-message-confirm-text">Are you sure you want to delete this conversation?</p>
                            <div className="delete-message-close" onClick={() => setShowDeleteAlert(false)}>
                                <CloseIcon />
                            </div>
                        </div>
                    }
                    
                        <p className="delete-text-message-title2 ">This message  will be deleted forever.</p>
                        <div className="delete-chat-button-cont">
                            <button className="delete-button delete-ok" onClick={() => deleteChat(userId, chatRoomId)}>Delete</button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export const RequestReviewAlert = (props) => {
    const showRequestReviewAlert = props.sharedProps && props.sharedProps.showRequestReviewAlert ? props.sharedProps.showRequestReviewAlert : null
    const toggleShowRequestReviewAlert = props.sharedProps && props.sharedProps.toggleShowRequestReviewAlert ? props.sharedProps.toggleShowRequestReviewAlert : null
    const requestReviewAlertProceed = props.sharedProps && props.sharedProps.requestReviewAlertProceed ? props.sharedProps.requestReviewAlertProceed : null
    const chatRoomData = props.sharedProps && props.sharedProps.chatRoomData ? props.sharedProps.chatRoomData : []
    const session = props.sharedProps && props.sharedProps.session ? props.sharedProps.session : []
    
    const handleSendProceed = () => {
        const userId = session.fkv2_session.id ? session.fkv2_session.id : null

        const chatOwnerId = chatRoomData && chatRoomData.owner_id ? chatRoomData.owner_id : null
        const chatItemId = chatRoomData && chatRoomData.item && chatRoomData.item.pk_i_id ? chatRoomData.item.pk_i_id : null
        const chatRoomId = chatRoomData && chatRoomData.id ? chatRoomData.id : null
        const itemOwnerId = chatRoomData && chatRoomData.item && chatRoomData.item.oc_t_user && chatRoomData.item.oc_t_user.pk_i_id ? chatRoomData.item.oc_t_user.pk_i_id : null
        const loginIsItemOwner = userId && parseInt(userId) === parseInt(itemOwnerId) ? true : false

        const ratingMessage = loginIsItemOwner ? 'seller_request_review' : 'buyer_request_review'

        const data = {
            owner_id: chatOwnerId,
            sender_id: userId,
            item_id: chatItemId,
            body: ratingMessage + REACT_APP_WEB_SEC,
            chat_room_id: chatRoomId
        }
        requestReviewAlertProceed(data)
    }

    return (
        <div className={showRequestReviewAlert ? `flex delete-modal-main-container ${window.innerWidth < 600 ? `flex-align-start` : ``}` : `hide`}>
            <div className="rating-alert-modal">
                {
                    <>
                    {
                        window.innerWidth < 600 ? 
                        <div className="flex flex-d-column flex-justify-start"> 
                            <p className="rating-alert-title">SEND LINK?</p>
                        </div> :
                        <div className="flex flex-d-row flex-justify-start">
                            <p className="rating-alert-title">SEND LINK?</p>
                        </div>
                    }
                    
                        <p className="rating-alert-text-1">This link will give access to leave you a reviews.</p>
                        <div className='flex flex-d-row flex-align-center mt-12'>
                            <InfoUnfilledIcon />
                            <p className="rating-alert-text-2 ml-4">Link request for review will expire after 1 week. </p>
                        </div>
                        <div className="rating-alert-button-container">
                            <button className="rating-alert-cancel" onClick={() => toggleShowRequestReviewAlert()}>CANCEL</button>
                            <button className="rating-alert-proceed" onClick={() => handleSendProceed()}>PROCEED</button>
                        </div>

                    </>
                }
            </div>
        </div>
    )
}

export const LoginUserRatingElement = (props) => {
    const ratingLinkToName = props.sharedProps && props.sharedProps.ratingLinkToName ? props.sharedProps.ratingLinkToName : null
    const isUser = props.sharedProps && props.sharedProps.isUser ? props.sharedProps.isUser : null
    const rateUser = props.sharedProps && props.sharedProps.rateUser ? props.sharedProps.rateUser : null
    const ratingText = props.sharedProps && props.sharedProps.ratingText ? props.sharedProps.ratingText : null
    const toggleShowReviewModal = props.sharedProps && props.sharedProps.toggleShowReviewModal ? props.sharedProps.toggleShowReviewModal : null
    const text = props.sharedProps && props.sharedProps.text ? props.sharedProps.text : null
    const textDate = props.sharedProps && props.sharedProps.textDate ? props.sharedProps.textDate : null

    const sellerRatingStatus = props.sharedProps && props.sharedProps.sellerRatingStatus ? props.sharedProps.sellerRatingStatus : null
    const buyerRatingState = props.sharedProps && props.sharedProps.buyerRatingState ? props.sharedProps.buyerRatingState : null
    const {linkWillExpire, shareDetailsOfOwnTransaction, ratingDisclaimer} = theme.text
    const ratingLinkText1 = linkWillExpire
    const ratingLinkText2 = shareDetailsOfOwnTransaction
    const ratingLinkText3 = ratingDisclaimer

    const sellerRateStatus = sellerRatingStatus ? sellerRatingStatus : null
    const buyerRateStatus = buyerRatingState ? buyerRatingState : null

    const isReviewDoneFunc = (role) => {

        let result;
        if(!isUser){
            if(role === 'buyer' && buyerRateStatus){
                result = buyerRateStatus && buyerRateStatus.includes('submitted') ? true : false
            }else if(role === 'seller' && sellerRateStatus){
                result = sellerRateStatus && sellerRateStatus.includes('submitted') ? true : false
            }else {
                result = true
            }
    
        }else {
            result = true
        }
        return result
    }

    const isReviewDone = isReviewDoneFunc(text && text.includes('seller') ? 'seller' : 'buyer')
    const linkExpired = textDate ? moment(new Date()).isAfter(textDate) : true
    const isButtonDisabled = isUser ? true : isReviewDone ? true : linkExpired ? true : false

    return (
        <>
            <p className="login-user-chat-text"><span>{ratingText}</span></p>
            <div className='rating-link-container'>
                <div className='rating-link-text-1-container'>
                    <p className='rating-link-text-1'>{textDate ? ratingLinkText1 : ''}</p>
                    <p className='rating-link-text-1'>{textDate ? new Date(textDate).toLocaleString('en-US', expirationDateFormat) : ''}</p>
                </div>
                <div className='rating-link-text-2-container'>
                    <p className='rating-link-text-2'>{`${ratingLinkText2} ${ratingLinkToName}.`}</p>
                    <button disabled={isButtonDisabled ? true : false} onClick={() => toggleShowReviewModal()} style={{pointerEvents: isButtonDisabled ? 'none' : 'visible'}}><p className={isButtonDisabled ? "rating-button-disable offer-button-text" : "rating-button offer-button-text"}>{rateUser}</p></button>

                    <p className='rating-link-text-3'>{ratingLinkText3}</p>
                </div>
            </div>
        </>
    )
}

export const UserRatingElement = (props) => {
    const ratingLinkToName = props.sharedProps && props.sharedProps.ratingLinkToName ? props.sharedProps.ratingLinkToName : null
    const isUser = props.sharedProps && props.sharedProps.isUser ? props.sharedProps.isUser : null
    const rateUser = props.sharedProps && props.sharedProps.rateUser ? props.sharedProps.rateUser : null
    const ratingText = props.sharedProps && props.sharedProps.ratingText ? props.sharedProps.ratingText : null
    const toggleShowReviewModal = props.sharedProps && props.sharedProps.toggleShowReviewModal ? props.sharedProps.toggleShowReviewModal : null
    const sellerRatingStatus = props.sharedProps && props.sharedProps.sellerRatingStatus ? props.sharedProps.sellerRatingStatus : null
    const buyerRatingState = props.sharedProps && props.sharedProps.buyerRatingState ? props.sharedProps.buyerRatingState : null
    const text = props.sharedProps && props.sharedProps.text ? props.sharedProps.text : null
    const textDate = props.sharedProps && props.sharedProps.textDate ? props.sharedProps.textDate : null
        const {linkWillExpire, shareDetailsOfOwnTransaction, ratingDisclaimer} = theme.text
    const sellerRateStatus = sellerRatingStatus ? sellerRatingStatus : null
    const buyerRateStatus = buyerRatingState ? buyerRatingState : null

    const ratingLinkText1 = linkWillExpire
    const ratingLinkText2 = shareDetailsOfOwnTransaction
    const ratingLinkText3 = ratingDisclaimer

    const isReviewDoneFunc = (role) => {

        let result;
        if(!isUser){
            if(role === 'buyer' && buyerRateStatus){
                result = buyerRateStatus && buyerRateStatus.includes('submitted') ? true : false
            }else if(role === 'seller' && sellerRateStatus){
                result = sellerRateStatus && sellerRateStatus.includes('submitted') ? true : false
            }else {
                result = true
            }
    
        }else {
            result = true
        }
        return result
    }

    const isReviewDone = isReviewDoneFunc(text && text.includes('seller') ? 'seller' : 'buyer')
    const linkExpired = textDate ? moment(new Date()).isAfter(textDate) : true
    const isButtonDisabled = isUser ? true : isReviewDone ? true : linkExpired ? true : false

    return (
        <>
            <p className="user-chat-text"><span>{ratingText}</span></p>
            <div className='rating-link-container'>
                <div className='rating-link-text-1-container'>
                    <p className='rating-link-text-1'>{textDate ? ratingLinkText1 : ''}</p>
                    <p className='rating-link-text-1'>{textDate ? new Date(textDate).toLocaleString('en-US', expirationDateFormat) : ''}</p>
                </div> 
                <div className='rating-link-text-2-container'>
                    <p className='rating-link-text-2'>{`${ratingLinkText2} ${ratingLinkToName}.`}</p>
                    <button disabled={isButtonDisabled} onClick={() => toggleShowReviewModal()} style={{pointerEvents: isButtonDisabled ? 'none' : 'visible'}}>
                        <p className={isButtonDisabled ? "rating-button-disable offer-button-text" : "rating-button offer-button-text"}>{rateUser}</p>
                    </button>
                    <p className='rating-link-text-3'>{ratingLinkText3}</p>
                </div>
            </div>
        </>
    )
}

const expirationDateFormat = {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true}
const sentDateFormat = {hour: '2-digit', minute: '2-digit', hour12: true}

export const RenderUserChat = (props) => {
    const {message, sentDate, item, parsedBuyerRatingExpiration, ratingLinkToName, isUser, sellerRatingStatus, 
        buyerRatingState, parsedSellerRatingExpiration, toggleShowReviewModal} = props.sharedProps
    const imgSrc = item && item.sender && item.sender.oc_t_user_resource ? item.sender.oc_t_user_resource : null
    const socialImgSrc = item && item.sender && item.sender.social && item.sender.social[0] && item.sender.social[0].data && item.sender.social[0].data.picture && item.sender.social[0].data.picture.data && item.sender.social[0].data.picture.data.url ? item.sender.social[0].data.picture.data && item.sender.social[0].data.picture.data.url : null
    const splitMessage = message.split(REACT_APP_WEB_SEC)
    const text = splitMessage && splitMessage[0] ? splitMessage[0] : ''
    const textDate = splitMessage && splitMessage[1] ? splitMessage[1] : null

    const {youSentReviewLink, buyerSentReviewLink, sellerSentReviewLink, rateBuyer, rateSeller} = theme.text
    const ratingText = isUser ? youSentReviewLink : text == 'buyer_request_review' ? buyerSentReviewLink : sellerSentReviewLink
    const rateUser = text == 'buyer_request_review' ? rateBuyer : rateSeller

    let messageElement
    switch (text) {
        case 'buyer_request_review':
        messageElement = <UserRatingElement sharedProps={{textDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, rateUser, ratingText, toggleShowReviewModal, sellerRatingStatus, buyerRatingState, text, parsedSellerRatingExpiration}} />
        break;
        case 'seller_request_review':
        messageElement = <UserRatingElement sharedProps={{textDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, rateUser, ratingText, toggleShowReviewModal, sellerRatingStatus, buyerRatingState, text, parsedSellerRatingExpiration}} />
        break;
        case 'seller_review_submitted_by_buyer':
        case 'buyer_review_submitted_by_seller':
        messageElement = <p className="user-chat-text">{'Review submitted'}</p>
        break;
        default:
        messageElement = <p className="user-chat-text">{message}</p>
        break;
    }

    const finalElement =
        <div className="user-chat-message-sent-cont">
            <div style={{ width: 36, height: 36, borderRadius: '50%', marginRight: 5, overflow: 'hidden' }}>
                {imgSrc ? <FKImage images={[imgSrc]} type="chat-history-avatar" /> :
                    <img src={socialImgSrc} style={{ borderRadius: '50%', maxWidth: 36, maxHeight: 36 }} />
                }
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {messageElement}
                <p className="user-chat-date">{`Sent ${new Date(sentDate).toLocaleString('en-US', sentDateFormat).toLowerCase()}`}</p>
            </div>
        </div>

    return finalElement
}

export const RenderLoginUserChat = (props) => {
    const {message, sentDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, sellerRatingStatus, buyerRatingState, 
        parsedSellerRatingExpiration, toggleShowReviewModal} = props.sharedProps
    const splitMessage = message.split(REACT_APP_WEB_SEC)
    const text = splitMessage && splitMessage[0] ? splitMessage[0] : ''
    const textDate = splitMessage && splitMessage[1] ? splitMessage[1] : null
    const {youSentReviewLink, buyerSentReviewLink, sellerSentReviewLink, rateBuyer, rateSeller} = theme.text
    const ratingText = isUser ? youSentReviewLink : text == 'buyer_request_review' ? buyerSentReviewLink : sellerSentReviewLink
    const rateUser = text == 'buyer_request_review' ? rateBuyer : rateSeller

    let messageText
    switch (text) {
        case 'buyer_request_review':
        messageText = <LoginUserRatingElement sharedProps={{textDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, rateUser, ratingText, toggleShowReviewModal, parsedSellerRatingExpiration, text, sellerRatingStatus, buyerRatingState}} />
        break;
        case 'seller_request_review':
        messageText = <LoginUserRatingElement sharedProps={{textDate, parsedBuyerRatingExpiration, ratingLinkToName, isUser, rateUser, ratingText, toggleShowReviewModal, parsedSellerRatingExpiration, text, sellerRatingStatus, buyerRatingState}} />
        break;
        case 'seller_review_submitted_by_buyer':
        case 'buyer_review_submitted_by_seller':
        messageText = <p className="login-user-chat-text">{'Review submitted'}</p>
        break;
        default:
        messageText = <p className="login-user-chat-text">{message}</p>
        break;
    }

    const finalElement =
        <div className="login-user-chat-message-sent-cont">
            {messageText}
            <p className="login-user-chat-date">{`Sent ${new Date(sentDate).toLocaleString('en-US', sentDateFormat).toLowerCase()}`}</p>
        </div>
    return finalElement
}


