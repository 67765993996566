import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import FKImage from '../../../../../../common/component/image'
import {slugify, categoryParser} from '../../../../../../common/helper/utility'
import {SuccessIcon} from '../../../../../../assets/svg'
import {YTLoader} from '../../../../../../common/component/loader'

const { REACT_APP_BASE_URL } = process.env;

export const CancelModal = ({alertDetails}) => {
    let history = useHistory()
    const title = alertDetails && alertDetails.title ? alertDetails.title : ''
    const body = alertDetails && alertDetails.body ? alertDetails.body : ''
    const {leftBtn, rightBtn} = alertDetails
    const lBtnTitle = leftBtn && leftBtn.title ? leftBtn.title : ''
    const lBtnFunc = leftBtn && leftBtn.action ? leftBtn.action : ''
    const rBtnTitle = rightBtn && rightBtn.title ? rightBtn.title : ''
    const rBtnFunc = rightBtn && rightBtn.action ? rightBtn.action : ''
    return (
        <> 
            <div className="cancel-modal-main-container">
                <div className="cancel-modal-container">
                    <div className="cancel-modal-title-container">
                        <p className="cancel-modal-title">{title}</p>
                    </div>
                    <div className="cancel-modal-p-container">
                        <p className="cancel-modal-p">{body}</p>
                    </div>
                    <div className="cancel-modal-actions">
                        <div className="cancel-modal-discard" onClick={() => lBtnFunc ? lBtnFunc() : history.push('/')}>
                            <p>{lBtnTitle}</p>
                        </div>
                        <div className="cancel-modal-edit" onClick={() => rBtnFunc ? rBtnFunc() : history.push('/')}>
                            <p>{rBtnTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const MobileTopNav = (props) => {
    let history = useHistory()
    const {left, center,right} = props.details
    const rightAction = props && props.rightAction ? props.rightAction : ''
    const leftDAction = props && props.leftAction ? props.leftAction : ''
    const leftTitle = left && left.title ? left.title : ''
    const leftAction = left && left.action ? left.action : ''
    const centerTitle = center && center.title ? center.title : ''
    const rightTitle = right && right.title ? right.title : ''
    const isSuccess = props.isSuccess ? props.isSuccess : false
    const rightDAction = right && right.action ? right.action : ''
    const userUpdateInput = props && props.userUpdateInput ? props.userUpdateInput : []
    const captcha  = userUpdateInput && userUpdateInput.captcha ? userUpdateInput.captcha : false

    return (
        <div className="w-100 flex flex-align-center topNavMobile-container">
            <div onClick={(e) => props.leftAction ? props.leftAction(e) : history.push('/')}>
                <p className="topNavMobile-left-p">{leftTitle}</p>
            </div>
            <div className="w-100 flex flex-justify-center flex-align-center">
                <p className="topNavMobile-center-p">{centerTitle}</p>
            </div>
            <div onClick={(e) => isSuccess ? window.location.reload() : rightAction ? captcha ? props.rightAction(e) : null : history.push('/')}>
                <p className={`topNavMobile-right-p ${captcha ? `` : `native-not-active-link`}`}>{rightTitle}</p>
            </div>
        </div>
    )
}

export const SuccessfullyListed = (props) => {
    let history = useHistory()
    const data = props.data
    const {oc_t_item_resource, get_i_price, oc_t_item_description, oc_t_category, pk_i_id} = data
    const {s_title} = oc_t_item_description
    const {oc_t_category_descriptions} = oc_t_category
    const categoryName = oc_t_category_descriptions && oc_t_category_descriptions.s_name ? categoryParser(oc_t_category_descriptions.s_name) : ''
    const titleUrlified = slugify(s_title)
    const link = `${categoryName}/${titleUrlified}-_i${pk_i_id}`

    const WebContainer = () => {
        return (
        <div className=" success-sub-container flex flex-d-row flex-justify-center flex-align-center">
            <div className="flex flex-d-column success-item-details-container align-self-start">
                <div className="success-img-container">
                    <FKImage images={[oc_t_item_resource[0]]} type="success-thumbnail" />
                </div>
                <p className="success-price-p">{get_i_price}</p>
                <p className="success-title-p">{s_title}</p>
            </div>
            <div className="success-border-center"></div>
            <div className="flex flex-d-column flex-align-center align-self-start">
                <p className="successful-label-p">{`Successfully Updated`}</p>
                <SuccessIcon />
                <div className="flex flex-d-row successful-btn-group">
                    <div className="view-listing-btn" onClick={() => window.location.replace(`${REACT_APP_BASE_URL}${link}`)}>
                        <p className="view-listing-p">{`View listing`}</p>
                    </div>
                    <div className="list-another-listing-btn" onClick={() => window.location.reload(false)}>
                        <p className="list-another-listing-p">{`List another item`}</p>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const MobileContainer = () => {
        return (
        <div className=" success-sub-container flex flex-d-column flex-align-center">
            <p className="successful-label-p mt-20">{`Successfully Updated`}</p>
            <div className="mb-16">
                <SuccessIcon />
            </div>
            <div className="success-img-container">
                <FKImage images={[oc_t_item_resource[0]]} type="success-thumbnail-mobile" />
            </div>
            <p className="success-price-p">{get_i_price}</p>
            <p className="success-title-p">{s_title}</p>

            <div className="w-100 flex flex-d-column flex-align-center align-self-start">
                
                <div className="flex flex-d-row successful-btn-group">
                    <Link to={'/'} className="view-listing-btn">
                        <p className="view-listing-p">{`Home`}</p>
                    </Link>
                    <div className="list-another-listing-btn" onClick={() => window.location.replace(`${REACT_APP_BASE_URL}${link}`)}>
                        <p className="list-another-listing-p">{`View Listing`}</p>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    return (
        <div className={`w-100 h-100 flex flex-justify-center ${window.innerWidth < 600 ? `` : `flex-align-center top-p-80`} p-relative mt-12`}>
            <div className="w-100 successful-main-container flex flex-justify-center flex-align-center">
               {
                   window.innerWidth < 600 ?
                    <MobileContainer /> : <WebContainer />
               }
            </div>
        </div>
    )
}

export default {CancelModal, MobileTopNav, SuccessfullyListed}