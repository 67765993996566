import React, {useContext, useEffect, useState} from 'react';
import {IconContainerClickable, Text, LinkText} from '../../../../../common/component/customComponents'
import {CloseIcon} from '../../../../../assets/svg'
import {ProfileSubscriptionContext} from '../../../../../common/context'
import {BoostCardComponent, MobileBoostCard} from '../../../fkPrime/fkPrimeCustomComponent'

const First = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {subscriptionActionModal, servicesData, has_active_subscription, userBoost, setUserBoost, is_free_subscription_claimed, showUpgradeModal, handleSetUpgradeData, handleBoostSelection, handlePurchaseWhenCancel} = value
    const subscriptionPrice = has_active_subscription && has_active_subscription.service ? has_active_subscription.service : null
    const [fkPrimeService, setFkPrimeService] = useState([])
    const isCancel = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null
    const boostID = userBoost && userBoost.boostID ? userBoost.boostID : ''

    useEffect(() => {
        if (servicesData.length > 0 && subscriptionPrice) {
            parsedFkPrimeService()
        }
    }, [servicesData, subscriptionPrice])

    const parsedFkPrimeService = () => {
        let arr = []
            servicesData.map(item => {
                if (item.type === 'fk_prime' && item.sg_price !== 0) {
                    const isAvailable = subscriptionPrice && subscriptionPrice >= item.sg_price ? false : true
                    // const isAvailable = true
                    arr.push({...item, is_available: isAvailable})
                }
            })
        setFkPrimeService(arr)
    }

    return (
        <>
            <div className="border-bottom">
                <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                    <div />
                    <div>
                        <Text 
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text={isCancel && isCancel.toLowerCase().includes('cancel') ? `Choose your plan` : `Choose Your Upgrade`}
                            textClass="fk-prime-purchase-modal-title-p"
                        />
                    </div>
                    <div className="">
                        <IconContainerClickable 
                            styleClass="cursor-pointer"
                            clickFunc={() => subscriptionActionModal([], false, 1)}
                            Icon={<CloseIcon/>}
                        />
                    </div>
                </div>
            </div>

            <div className={`${window.innerWidth < 600 ? `` : `h-px-462`} flex flex-align-center flex-justify-center`}>
                <div className={`flex ${window.innerWidth < 600 ? `flex-d-column` : `flex-d-row`} flex-justify-center flex-align-center cancel-sub-button-container`}>
                    {
                        fkPrimeService.map((boosts, i) => {
                            const price = boosts && boosts.sg_price ? boosts.sg_price : 0
                            const name = boosts && boosts.name ? boosts.name.replace(/(Fk Prime|month|Month|months|Months|Year|year|Years|years|s|fk prime)+/g, '') : ''
                            const subname = boosts && boosts.name ? boosts.name.replace(/(Fk Prime|FK Prime|FK Prime|Free|free|\d)+/g, '') : ''
                            const service_name = boosts && boosts.name ? boosts.name : ''
                            const boost = {...boosts, price: price, name: name, subname: subname, service_name: service_name}
                            return window.innerWidth < 600 ? MobileBoostCard({boost, i, setUserBoost, userBoost, handleBoostSelection: isCancel ? handleBoostSelection : null, handleClaimFree: null, freeClaim: null, is_free_subscription_claimed, showUpgradeModal, toggleSubscriptionData: handleSetUpgradeData, has_active_subscription}) : BoostCardComponent({boost, i, setUserBoost, userBoost, handleBoostSelection: isCancel ? handleBoostSelection : null, handleClaimFree: null, freeClaim: null, is_free_subscription_claimed, showUpgradeModal, toggleSubscriptionData: handleSetUpgradeData, has_active_subscription})
                        })
                    }
                </div>
            </div>
            {
            isCancel && isCancel.toLowerCase().includes('cancel') ? 
                <div className="w-100 flex flex-justify-center flex-align-center">
                    <div class={`fk-prime-pay-now-btn fk-prime-pay-now-btn-pay-pending ${boostID ? `` : `opacity-p-6`}`} onClick={() => boostID ? handlePurchaseWhenCancel(2) : null}><p>Purchase</p></div>
                </div> : null
            }

            <div className="cancel-subscription-footer-container ">
                <Text
                    styleClass=""
                    text="I accept and agree to the"
                    textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                />
                <LinkText
                    path="/terms-of-use"
                    text="Terms of Services."
                    textClass="fk-prime-purchase-modal-footer-link-p"
                />
            </div>
        </>
    )
}

export default First