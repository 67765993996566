import React, { useContext, useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import FKImage from '../../../../../../common/component/image'
import DefaultPhoto from '../../../../../../assets/images/defaultAvatar.png'
import { ChatContext } from '../../../../../../common/context'
import { dateParser, getSocialUrl } from '../../../../../../common/helper/utility'
import { theme } from '../../../../../../common/theme';

const { REACT_APP_WEB_SEC } = process.env;

const MobileInbox = (props) => {
    const { toggleThread } = props
    const value = useContext(ChatContext)
    const { session, chatProps, setMessagesData, setActiveIndex, activeIndex, chatUpdateUnread, fromMessage, messageItem, addTempChatRoom, me, height, 
    } = value

    const userId = session.fkv2_session.id ? session.fkv2_session.id : null
    const [hold, setHold] = useState(false)
    const data = chatProps && chatProps.data ? chatProps.data : []

    useEffect(() => {
        const item = chatProps && chatProps[0] ? chatProps[0] : []

        if (fromMessage) {
            //check if item is on inbox
            const itemId = messageItem && messageItem.pk_i_id || null
            if(itemId) {
                //check if exist
                let tempItem
                let tempIndex
                let tempIsExist
                data.some(function (item, index) { 
                    if (item && item.item && item.item.pk_i_id === itemId) {
                        tempIsExist = true
                        tempItem = item
                        tempIndex = index
                        return false;
                    }
                });

                if (tempIsExist) {
                    // setActiveIndex(tempIndex)
                    setMessagesData(tempItem)
                    setUnread(tempItem)
                }
                else {
                    // setActiveIndex(0)
                    // insertChatFromMessage(messageItem)
                }
                setActiveIndex(tempIndex)
            }
        } 
        else {
            // setActiveIndex(0)
            setActiveIndex(activeIndex)
            setMessagesData(item)
            setUnread(item)
        }
    // eslint-disable-next-line 
    }, [fromMessage])

    const inboxClick = (item, index) => {
        setActiveIndex(index)
        setMessagesData(item)
        setUnread(item)
        toggleThread(true)
    }

    const setUnread = (item) => {
        let unreadArr = []
        const chat_room_id = item.id
        if (item.chat_messages && item.chat_messages.length > 0) {
            // eslint-disable-next-line 
            item.chat_messages.map((message) => {
                if (!message.read_at) {
                    unreadArr.push(parseInt(message.id))
                }
            })
            if (unreadArr.length > 0) {
                setHold(true)
                chatUpdateUnread({unreadArr, userId, chat_room_id})
                setTimeout(() => {
                setHold(false)
                }, 1500)    
            }

        }
    }

    const userUnreadCount = (arr) => {
        let unreadCount = 0
            arr.map((item) => {
                if (!item.read_at && (item.sender_id !== parseInt(userId))) {
                    unreadCount++
                }
            })
        return unreadCount
    }

    const lastMessageParser = useCallback((message) => {
        const splitMessage = message.split(REACT_APP_WEB_SEC)
        const text = splitMessage && splitMessage[0] ? splitMessage[0] : ''
        let string
        switch (text) {
            case 'made_offer':
                // string = theme.text.chatCustomText.madeAnOffer
                string = '. . . has made an offer'
                break;
            case 'edit_offer':
                string = theme.text.chatCustomText.editOffer
                break;
            case 'cancel_offer':
                string = theme.text.chatCustomText.cancelledOffer
                break;
            case 'decline_offer':
                string = 'Offer declined'
                break;
            case 'accept_offer':
                string = theme.text.chatCustomText.acceptedAnOffer
                break;
            case 'buyer_request_review':
                string = theme.text.buyerSentReviewLink
                break;
            case 'seller_request_review':
                string = theme.text.sellerSentReviewLink
                break;
            case 'buyer_review_submitted_by_seller':
                string = theme.text.buyerRespondedToReviewLink
                break;
            case 'seller_review_submitted_by_buyer':
                string = theme.text.sellerRespondedToReviewLink
                break;
            default:
                string = text
                break;
        }
        return string
    },[data])

    const offerStatusSwitch = (itemSold) => {
        let text
        switch (itemSold) {
            case true:
                text = 'Accepted'
                break;
            case null:
            case false:
            case undefined:
                text = ''
                break;                      
            default:
                break;
        }
        return text
    }  

    const itemStatusSwitch = (itemSold, itemReserved) => {
        let el
        if (itemSold) {
            el = <div className="item-status-cont-big sold-cont"><p className="item-status-text-big sold-text">Sold</p></div>
        }
        else if(itemReserved) {
            el = <div className="item-status-cont-big reserved-cont"><p className="item-status-text-big reserved-text">Reserved</p></div>
        }
        else {
            el = null
        }
        return el
    }


    return (
        <ul className="mobile-inbox-list">
        {data.length > 0 ? 
            data.map((item, index) => {
                const senderName = userId === item.sender.pk_i_id ? item.item.oc_t_user.s_username : item.sender.s_username
                const itemName = item.item && item.item.oc_t_item_description && item.item.oc_t_item_description.s_title ? item.item.oc_t_item_description.s_title : ''
                const lastMessage = item.last_message ? item.last_message : ''
                const updatedDate = item.updated_at ? item.updated_at : ''
                const unreadCount = userUnreadCount(item.chat_messages)
                const itemImgResource = item.item && item.item.oc_t_item_resource && item.item.oc_t_item_resource[0] ?item.item.oc_t_item_resource[0] : []
                const itemReserved = item.item && item.item.b_reserved ? item.item.b_reserved : null
                const itemSold = item.item && item.item.b_mark_as_sold ? item.item.b_mark_as_sold : item.item.dt_sold ? true : false
                
                //avatarSwitch vars
                const chatOwnerId = item && item.owner_id || null
                const chatGuestId = item && item.guest_id || null
                const itemOwnerId = item && item.item && item.item.oc_t_user && item.item.oc_t_user.pk_i_id || null
            
                const loginIsOwner = parseInt(userId) === parseInt(chatOwnerId) ? true : false
                const loginIsGuest = parseInt(userId) === parseInt(chatGuestId) ? true : false
                const loginIsItemOwner = parseInt(userId) === parseInt(itemOwnerId) ? true : false

                const itemOwnerUserResource = item.item && item.item.oc_t_user && item.item.oc_t_user.oc_t_user_resource ? item.item.oc_t_user.oc_t_user_resource : null
                const itemSocialData = item && item.item && item.item.oc_t_user && item.item.oc_t_user.social || []
                const itemOwnerSocialResource = getSocialUrl(itemSocialData)
        
                const senderOwnerUserResource = item.sender && item.sender.oc_t_user_resource && item.sender.oc_t_user_resource.pk_i_id ? item.sender.oc_t_user_resource : null
                const senderSocialData = item && item.sender && item.sender.social || []
                const senderOwnerSocialResource = getSocialUrl(senderSocialData)

                const parsedDate = dateParser(updatedDate) === 'Today' ? moment(updatedDate).format("hh:mm a") : 
                        dateParser(updatedDate) === 'Yesterday' ? 'Yesterday' :   
                            moment(updatedDate).format("MMM DD, YYYY")

                const offerAmount = item && item.offer_amount && item.offer_amount > 0 ? item.offer_amount : null

                const avatarSwitch = () => {
                    let element = []
                    if (loginIsOwner) {
                        if (senderOwnerUserResource) {
                            element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="seller_avatar"/> )
                        } else if(senderOwnerSocialResource){
                            element.push(<img key={1} src={senderOwnerSocialResource} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}} alt="profile-social"/>)
                        } else{
                            element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}} alt="profile-default"/>)
                        }
                    } else if(loginIsGuest){
                        if (itemOwnerUserResource) {
                            element.push(<FKImage key={1} images={[itemOwnerUserResource]} type="seller_avatar" /> )
                        } else if(itemOwnerSocialResource) {
                            element.push(<img key={1} src={itemOwnerSocialResource} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}} alt="profile-social"/>)
                        } 
                        else{
                            element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}}/>)
                        }
                    }
                    else if(loginIsItemOwner){
                        if (senderOwnerUserResource) {
                            element.push(<FKImage key={1} images={[senderOwnerUserResource]} type="seller_avatar" />)
                        } else if(senderOwnerSocialResource) {
                            element.push(<img key={1} src={senderOwnerSocialResource} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}} alt="profile-social"/>)
                        } 
                        else{
                            element.push(<img key={1} src={DefaultPhoto} style={{borderRadius: '50%', maxWidth: 40, maxHeight: 40}} alt="profile-default"/>)
                        }
                    }
                    return element
                }

                return (
                    <li key={index} style={{backgroundColor: activeIndex === index ? '#F9F9F9' : 'transparent', borderRight: 0}} onClick={() => hold ? null : inboxClick(item, index)}>
                        <div className="mobile-inbox-list-container">
                            <div className="mobile-inbox-left-container" style={{fontWeight: unreadCount > 0 ? 'bold' : 'normal'}}>
                                    <div className="mobile-inbox-avatar-details-container">
                                        <div className="inbox-avatar">
                                            { avatarSwitch() }
                                        </div>
                                        <div className="inbox-details-container" >
                                            <div className="mobile-user-sent-container">
                                                <p className="user-sent-username">{senderName}</p>
                                                <p className="user-sent-time">{parsedDate}</p>
                                            </div>
                                            <div  className="item-title-unread-container">
                                                <p className="inbox-item-title">{itemName}</p>
                                                    <div className="unread-container" style={{visibility: unreadCount > 0 ? "visible" : "hidden"}}>
                                                        <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.99 21.39L13.02 21.43L16.17 25.78C16.2727 25.9147 16.6129 25.9059 16.71 25.76L19.88 21.44L19.92 21.39H31.72C32.36 21.39 32.88 20.87 32.88 20.23V1.16C32.88 0.52 32.36 0 31.72 0H1.16C0.52 0 0 0.52 0 1.16V20.23C0 20.87 0.52 21.39 1.16 21.39H12.99Z" fill="#FB770D"/>
                                                        </svg>
                                                    <p className="unread-count">{unreadCount}</p>
                                                    </div> 
                                            </div>
                                            <div style={{backgroundColor: 'transparent', flex: 1}}>
                                                <p className="inbox-last-message">{lastMessageParser(lastMessage)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={{flex: 1}}>
                                        <p>
                                            <span className="inbox-accepted">{offerAmount ? offerStatusSwitch(itemSold) : null}</span>
                                            {offerAmount ? <span className="inbox-offered">Offered: SGD {offerAmount}</span> : null}
                                        </p>
                                    </div> */}
                            </div>
                            <div className="inbox-item-container">
                                <FKImage images={[itemImgResource]} type="chat-item-message-inbox" />
                                {itemStatusSwitch(itemSold, itemReserved)}
                            </div>
                        </div>
                    </li>
                )
            }) : <p className="no-message">No message available</p>
        }
        {data.length > 0 ? <li><p className='chat-inbox-footer'>All chats are displayed</p></li> : null}    
        </ul>
    )
}

export default MobileInbox