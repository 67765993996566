import React, { useContext  } from 'react';
import { CoinContext } from '../../../../index';
import Loader from '../../../../../../../../common/component/loader'
import { MoneyIcon } from '../../../../../../../../assets/svg'
import visaLogo from '../../../../../../../../assets/images/Credit-Card-Logo/visa.png'
import moneyCard from '../../../../../../../../assets/images/Credit-Card-Logo/money-card.png'
import masterCardLogo from '../../../../../../../../assets/images/Credit-Card-Logo/mastercard.png'
import amex from '../../../../../../../../assets/images/Credit-Card-Logo/american-express.png'
import {loadStripe} from '@stripe/stripe-js';
import {getStripePriceIdByAmount} from '../../../../../../../../common/helper/utility'

const {REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_STRIPE_KEY} = process.env

const Stripe = (props) => {
    const value = useContext(CoinContext);
    const { purchaseData, isLoadingPurchaseCoin, errorPurchaseCoin, purchaseSuccessMessage, session, data, 
        setStripeError, setStripeLoading, handleBack, stripeLoading, stripeError, stripeSuccess
    } = value
    
    const token = session && session.access_token ? session.access_token : null
    const userId = session && session.id ? session.id : null
    const s_email = data && data.s_email ? data.s_email : null
    const {sg_price, description, credit, id, name} = purchaseData
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
    const stripeProductId = getStripePriceIdByAmount(sg_price)
    const stripeCusId = data && data.stripe_cus_id ? data.stripe_cus_id : null

    const handleCheckout = async () => {
        setStripeLoading(true)
        const GET_SESSION_URL = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql`
        const stripe = await stripePromise;
    
        const query = `mutation getStripeSession($input: PurchaseInput){
          getStripeSession(input: $input){
            session
          }
        }`;

        //line_items_detail
        const line_items_detail = [
            {
                "type": "coin_bundle",
                "user_id": parseInt(userId),
                "coin": parseInt(credit),
                "id": id,
                "amount": parseInt(sg_price),
                "description": description,
                "method": "stripe",
                "currency": "sgd",
                "s_email": s_email,
                "customer": stripeCusId,
                // "service_name": name,
                "name": name

            },
        ]
    
        const input = {
          "type": "coin_bundle",
          "user_id": parseInt(userId),
          "coin": parseInt(credit),
          "id": id,
          "amount": parseInt(sg_price),
          "description": description,
          "method": "stripe",
          "currency": "sgd",
          "s_email": s_email,
          "payment_mode": "payment",
          "return_url": "user/wallet",
          "customer": stripeCusId,
          "line_items": [
            {price: stripeProductId, quantity: 1},
          ],
          "line_items_detail": line_items_detail
        }

        console.log('input', input)
        
        const response = await fetch(GET_SESSION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query,
                variables: {input}
            })
          });
          const session = await response.json();    

          if (session.error) {
            console.log('session.error', session.error)
            // setStripeError(result.error.message)
            // setStripeLoading(false)
          } else {
            const result = await stripe.redirectToCheckout({
                sessionId: session && session.data && session.data.getStripeSession && session.data.getStripeSession.session ? JSON.parse(session.data.getStripeSession.session) : null
              }); 
            if (result.error) {
                console.log('result.error', result.error)
                // setStripeError(result.error.message)
                // setStripeLoading(false)
            }
          }
      };

    const renderStripeButton = () => {
        return (
            <div className="default-btn pay-btn" style={{marginBottom: 20}} onClick={() => handleCheckout()}>
                <div style={{flex: 1, textAlign: 'center'}}><p className="text-white">{`Proceed to Checkout`}</p></div>
            </div>
        )
    }
    
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 3,
            // padding: 10,
        }}>
            <div style={{ width: '100%', borderBottom: '0.5px solid #C4C4C4', marginBottom: '31.5px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    {/* <p>{`Purchase ${purchaseData.sg_price}`}</p> */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p style={{ marginRight: 4 }}>{`Purchase`}</p><MoneyIcon /><p style={{ color: '#FB770D', marginLeft: '3.6px' }}>{purchaseData.credit}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                    <img src={visaLogo} alt="visa" style={{ margin: '0px 3px' }} />
                    <img src={masterCardLogo} alt="mastercard" style={{ margin: '0px 3px' }} />
                    <img src={moneyCard} alt="discover" style={{ margin: '0px 3px' }} />
                    <img src={amex} alt="amex" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#232A34' }}>{`Credit/Debit Card`}</p>
                    </div>
                    <div onClick={() => handleBack()}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#FB770D', cursor: 'pointer' }}>{`Change`}</p>
                    </div>
                </div>
            </div>

            {isLoadingPurchaseCoin || stripeLoading ? <Loader /> :
                errorPurchaseCoin ? <div style={{ height: 200 }}><p style={{ color: 'red' }}>{errorPurchaseCoin}</p></div> :
                stripeError ? <div style={{ height: 200 }}><p style={{ color: 'red' }}>{stripeError}</p></div> :
                    purchaseSuccessMessage ? <div style={{ height: 200 }}><p style={{ color: 'green' }}>{purchaseSuccessMessage}</p></div> :
                    stripeSuccess ? <div style={{ height: 200 }}><p style={{ color: 'green' }}>{stripeSuccess}</p></div> :
                    renderStripeButton()
            }
        </div>
    );
}

export default Stripe;