import { WATCHLIST, ITEM_HOME_LIKE_UPDATE} from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
        page: 1,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case WATCHLIST.LOAD:
            return {...state, isLoading: true, error: null}

            case WATCHLIST.SUCCESS:
                console.log("waatchlist redu", state)
            return {...state, isLoading: false,
                data:  action.payload,
                error: null, page: state.page +1}

            case WATCHLIST.FAILED:
            return {...state, isLoading: false,error: action.payload}

            case WATCHLIST.LIKE_DISLIKE_LOAD:
            return {...state, isLoading: true, error: null}

            case WATCHLIST.LIKE_DISLIKE_SUCCESS:
            return {...state, isLoading: false,
                data:  action.payload,
                error: null, page: state.page +1}

            case WATCHLIST.LIKE_DISLIKE_FAIL:
            return {...state, isLoading: false, error: action.payload}

            case ITEM_HOME_LIKE_UPDATE.SUCCESS:
                const likeFromHomeId = action.payload && action.payload.pk_i_id ? action.payload.pk_i_id : 0
                const filter = state && state.data && state.data.length !== 0 ? state.data.filter(item => item && item.items && parseInt(item.items.pk_i_id) === parseInt(likeFromHomeId)) : []
                const notLikeItemFromHomeId = state && state.data && state.data.length !== 0 ? state.data.filter(item => item && item.items && parseInt(item.items.pk_i_id) !== parseInt(likeFromHomeId)) : []
                const getIdFromLastArr = state && state.data && state.data.length !== 0 ? state.data[state.data.length -1].id : 0
                const likeFromHomeArr = likeFromHomeId && likeFromHomeId !== 0 ? {id: parseInt(getIdFromLastArr) + 1, fk_i_item_id: likeFromHomeId, items: action.payload} : []
                const mergeData = filter  && filter.length === 0 ? [...state.data, likeFromHomeArr] : notLikeItemFromHomeId
            return{
                ...state,
                isLoading: false,
                data: mergeData
            }

            default:
            return state;  
        }
    }
    
    