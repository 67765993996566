import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getAdministrator = (data) => {
    const { first, page } = data
    try {
        const query = gql`query getAdministrator($first: Int, $page: Int, $orderField: String!, $orderType: SortOrder!){
            getAdministrator(
                first: $first, 
                page: $page,
                orderBy: [
                       {
                        field: $orderField,
                        order: $orderType
                       }
                  ]
            ) {
              paginatorInfo{
                total
              }
              data {
                pk_i_id
                s_name
                s_username
                s_email
                b_moderator
              }
            }
          }`;
        const response = client.query({
            query: query,
            variables: {first: first, page: page, orderField: "pk_i_id", orderType: "ASC"},
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const getAdministratorById = (id) => {
    try {
        const query = gql`query getAdministratorById($id: ID)
        { getAdministratorById(pk_i_id: $id) {
        pk_i_id
        s_name
        s_username
        s_email
        b_moderator
            }
    }`;
        const response = client.query({
            query: query,
            variables: { id: id},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
  }

  export const upsertAdministrator = (payload) => {
    const { pk_i_id, s_name, s_username, s_email, s_password, s_password_confirmation, b_moderator} = payload
    const is_moderator = b_moderator === 'true' ? true : false   

    let variables
    let gql_query
    if (pk_i_id) {
      gql_query = gql`mutation administratorAdminUpdate($data: AdministratorRegisterInput){
        administratorAdminUpdate(data: $data){
          message
          data{
            pk_i_id
            s_name
            s_username
            s_email
            b_moderator
          }
        }
      }`
      variables = {data: 
        {"pk_i_id": parseInt(pk_i_id), "s_name": s_name, "s_username": s_username, "b_moderator": is_moderator }}        
    } else {
      gql_query = gql`mutation administratorAdminRegister($data: AdministratorRegisterInput){
        administratorAdminRegister(data: $data){
          message
          data{
            pk_i_id
            s_name
            s_username
            s_email
            b_moderator
          }
        }
      }`;
      variables = {data: {"s_name": s_name, "s_username": s_username, "s_email": s_email, "b_moderator": is_moderator, "s_password": s_password, "s_password_confirmation":  s_password_confirmation }}
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  export const deleteAdministrator = (id) => {
    try {
        const query = gql`mutation administratorDelete($pk_i_id: Int!){
            administratorDelete(pk_i_id: $pk_i_id){
              message
              id
            }
          }`;
        const response = client.mutate({
            mutation: query,
            variables: { pk_i_id: parseInt(id)},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
  }

export const adminForgotPassword = (data) => {
  const { email, type} = data
    try{
      const query = gql`mutation userForgotPassword($input: FkForgotPasswordInput){
        userForgotPassword(input: $input){
            message
          }
        }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: { "email": email, "type": type}}
          });
        const data = response ? response : null;
        return data;
    }catch(e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const adminChangePassword = (id, currentPassword, password, rePassword, email, type) => {
  let inputData = {}
  if (email) {
      inputData = {
          "email" : email,
          "password": password,
          "password_confirmation": rePassword,
            "type": type
      }
  } else {
      inputData = {
          "id" : id,
          "current_password": currentPassword,
          "password": password,
          "password_confirmation": rePassword,
          "type": type
      }
  }
  try{
      const query = gql`mutation adminChangePassword($input: ChangePasswordInput){
        adminChangePassword(input: $input){
          message
        }
      }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: inputData}

          });
        const data = response ? response : null;
        return data;
  }catch(e){
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
  }
}

export const getDashboardData = (payload) => {
  try {
    const query = gql`query getDashboardDataByCreation($type: DateType){
      getDashboardDataByCreation(type: $type){
        today_posting_per_user
        this_week_posting_per_user
        this_month_posting_per_user
        this_year_posting_per_user
        sign_up_today
        sign_up_this_week
        sign_up_this_month
        sign_up_this_year
        purchase_today
        purchase_this_week
        purchase_this_month
        purchase_this_year
      }
    }`;
      const response = client.query({
          query: query,
          variables: { type: payload.type},
      });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export default {
    getAdministrator,
    getAdministratorById,
    upsertAdministrator,
    deleteAdministrator,
    adminForgotPassword,
    adminChangePassword,
    getDashboardData
}