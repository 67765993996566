import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
// import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { getLocalStorage } from '../common/helper/utility'

const {
  REACT_APP_GRAPHQL_SERVER_BASE_URL,
} = process.env;

// let exception

// const clientHolder =  new ApolloClient({
//     link: ApolloLink.from([
//         onError(({ graphQLErrors, networkError }) => {
//           if (graphQLErrors){
//             exception = graphQLErrors
//               // graphQLErrors.forEach(({ message, locations, path }) =>
//               // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)  ,
//               // );
//           }
//           if (networkError){
//             console.log(`[Network error]: ${networkError}`);
//           }
//         }),
//         new HttpLink({
//           uri: `${REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql`,
//         //   headers: {
//         //     "Access-Control-Allow-Origin": "*",
//         //     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
//         //     'Access-Control-Allow-Headers': '*'
//         //     },
//         //   fetchOptions: {
//         //     mode: "no-cors",
//         //   },
//             // fetchPolicy: 'network-only'
//         })
//       ]),
//       cache: new InMemoryCache()
// });
const fkv2_session = getLocalStorage('fkv2_session')
const access_token = fkv2_session && fkv2_session.access_token ? fkv2_session.access_token : ''
const getAuthToken = () => `Bearer ${access_token}`;

const uploadLink = createUploadLink({ uri: `${REACT_APP_GRAPHQL_SERVER_BASE_URL}graphql` || '' });

const authLink = setContext((operation, { headers }) => {
  const token = getAuthToken();

  if (token) {
    return {
      headers: { ...headers, Authorization: token }
    };
  }

  return { headers };
});

const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

// const client = exception ? exception : clientHolder

export default client;
