import gql from 'graphql-tag';
import client from '../apollo/apollo-client';
import {me} from './string_data'

export const toggleLive = (data) => {
    const { item_id, live } = data 
    try {
        const query = gql`mutation toggleLive($item_id: Int!, $live: String!){
          toggleLive(item_id: $item_id,live: $live ){${me}}}`;
        const response = client.mutate({
            mutation: query,
            variables: {
              item_id: parseInt(item_id),
              live: live
            }
        });
        const result = response ? response : null;
        return result;
    } catch (e) {
        throw e
    }
  }


export const useNowPromote = (data) => {
    const { item_id, micros_service_id, service_id } = data 
    try {
        const query = gql`mutation useNowPromote($item_id: Int!, $micros_service_id: Int!, $service_id: Int!){
            useNowPromote(item_id: $item_id, micros_service_id: $micros_service_id, service_id: $service_id ){${me}}}`;

        const response = client.mutate({
            mutation: query,
            variables: {
              item_id: item_id,
              micros_service_id: micros_service_id,
              service_id: service_id
            }
        });
        const result = response ? response : null;
        return result;
    } catch (e) {
        throw e
    }
}

export const cancelSubscription = (data) => {
  const { id, payment_method, transaction_id } = data 
  // return null
  try {
  const query = gql`mutation cancelSubscription($id: Int!, $transaction_id: String!, $payment_method: String!){
         cancelSubscription(id: $id, transaction_id: $transaction_id, payment_method: $payment_method ){${me}}}`;

      const response = client.mutate({
          mutation: query,
          variables: {
            id: parseInt(id),
            transaction_id: transaction_id,
            payment_method: payment_method
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      throw e
  }
}

export const updateSubscription = (data) => {
  const { id, payment_method, transaction_id, plan_id, service_id } = data 
  // return null
  try {
    const query = gql`mutation updateSubscription($id: Int!, $transaction_id: String!, $payment_method: String!, $plan_id: String!, $service_id: Int!){
      updateSubscription(id: $id, transaction_id: $transaction_id, payment_method: $payment_method, plan_id: $plan_id, service_id: $service_id){
                pk_i_id
              realtime_subscription_status
      }
    }`;

    const response = client.mutate({
        mutation: query,
        variables: {
          id: id,
          transaction_id: transaction_id,
          payment_method: payment_method,
          plan_id: plan_id,
          service_id: service_id
        }
    });
    const result = response ? response : null;
    return result;
  } catch (e) {
      throw e
  }
}

export default {
    toggleLive,
    useNowPromote,
    cancelSubscription,
    updateSubscription
};