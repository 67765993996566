import {CONTACT_DATA} from '../constant'
    const initialState = {
        isLoading: false,
        data: null,
        error: false,
    }

    export default (state = initialState, action) => {
        switch(action.type) {
            case CONTACT_DATA.LOAD:
            return {...state, isLoading: true, error: null}
    
            case CONTACT_DATA.SUCCESS:
            return {...state, isLoading: false, isLoadingMore: false, data:  action.payload, error: null}

            case CONTACT_DATA.FAIL:
            return {...state, isLoading: false,isLoadingMore: false, error: action.payload}

            case CONTACT_DATA.RESET:
            return initialState

            default:
            return state;  
        }
    }
