import React, { useEffect, useState, useRef, useCallback } from 'react';
import {getPaypalSubscriptionIdByAmount, resetPaypal} from '../../../common/helper/utility'

const SubscribeButton = (props) => {
    const {location} = props
    const type = new URLSearchParams(location.search).get("type")
    const userId = new URLSearchParams(location.search).get("userId")
    const subscriptionData = new URLSearchParams(location.search).get("data")
    const s_email = new URLSearchParams(location.search).get("s_email")

    const boostDetails = subscriptionData ? JSON.parse(subscriptionData) : [] 
    const sg_price = boostDetails && boostDetails.sg_price ? boostDetails.sg_price : 0

    let paypalRef = useRef()

    const paypalPlanId = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    const [paypalError, setPaypalError] = useState(false)

    const boostDetailsObj = {
        "type": boostDetails.type,
        "user_id": parseInt(userId),
        "coin": boostDetails.credit ? parseInt(boostDetails.credit) : 0,
        "id": boostDetails.id ? parseInt(boostDetails.id) : 0,
        "sg_price": sg_price,
        "description": boostDetails.description,
        "method": "paypal",
        "currency": "sgd",
        "s_email": s_email,
        "name": boostDetails.name,
        "customer": "",
    }

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [boostDetailsObj])

    const _onLoad = () => {
        setLoaded(true)
    }

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  type === 'subscription' ? `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription` : `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`

        script.src = paypalSrc
        script.addEventListener('load', () => _onLoad())
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                    window.paypal.Buttons({
                        label: 'paypal',
                        style: {
                            label: 'paypal',
                            width: '50%'
                        },
                        createSubscription: (data, actions) => {
                            return actions.subscription.create({
                              'plan_id': paypalPlanId
                            });
                        },
                        onApprove: async (data, actions) => {
                            const responseData = await data
                            const order_id = responseData && responseData.subscriptionID ? responseData.subscriptionID : ''
                            const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
                            const boostData = {credit: null, payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data, 
                            order_id: order_id, order_status: 'success', 
                        }
                            window.ReactNativeWebView &&
                            window.ReactNativeWebView.postMessage(JSON.stringify(boostData));
                        },
                        onError: async (e) => {
                            const error = await e
                            const boostData = {order_status: error}
                            window.ReactNativeWebView &&
                            window.ReactNativeWebView.postMessage(JSON.stringify(boostData));
                            setPaypalError(JSON.stringify(error))
                            setTimeout(() => {
                                setPaypalError(false)
                            }, 3000)
                        }
                    }).render(paypalRef);
            }, 1000)
        }
    }

    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', background: 'transparent', height: '100%'}}>
            { paypalError ? 
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: 'red' }}>{paypalError}</p>
                </div> 
            : 
                <div>
                    <div ref={v => (paypalRef = v)}></div>
                </div>
            }
        </div>
    );
}

export default SubscribeButton;