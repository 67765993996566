import { CHAT, SEND_CHAT, ADD_ROOM, SEND_REVIEW, DELETE_CHAT } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { chatSuccess, chatFailed, sendChatSuccess, sendChatFailed, getChatRoomByIdSuccess, getChatRoomByIdFailed, sendReviewSuccess, sendReviewFailed, deleteChatSuccess, deleteChatFailed} from '../action';
import  chat from '../../graphql/chat'
import { theme } from '../../common/theme'

function* chatSaga(action) {
    const id = action && action.payload  && action.payload.user_id ? action.payload.user_id : null
    try {
      const result = yield chat.getChatRoomsByUser(id)
      if(result === 403) {
        yield put(chatFailed('Something went wrong try again!'))
      }
      else {
        const data = result && result.data && result.data.getChatRoomsByUser ? result.data.getChatRoomsByUser : []
        yield put(chatSuccess(data));
        }
    } catch (e) {
    console.log('chatSaga error', e)
    const error = e && e.graphQLErrors ? e.graphQLErrors : e
    const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
        error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
          error && error[0] && error[0].message ? error[0].message : 
            theme.text.error.default
    yield put(chatFailed(errorMessage))
    }
}    

export function* watchChatSaga() {
  yield takeLatest(CHAT.LOAD, chatSaga);
}

function* sendChatSaga(action) {
  const { data } = action.payload
  console.log("action.payload", action)
  try {
    const result = yield chat.sendChat(data)
    if(result === 403) {
      yield put(sendChatFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.chatRoomsByUser ? result.data.chatRoomsByUser : []
      yield put(sendChatSuccess([data]));
      }
  } catch (e) {
  console.log('error', JSON.stringify(e))
  const error = e && e.graphQLErrors ? e.graphQLErrors : e
  const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
      error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
        error && error[0] && error[0].message ? error[0].message : 
          theme.text.error.default
  yield put(sendChatFailed(errorMessage))
  }
}    

export function* watchSendChatSaga() {
yield takeLatest(SEND_CHAT.LOAD, sendChatSaga);
}

function* updateUnreadSaga(action) {
  try {
    const result = yield chat.updateChatUnread(action.payload)
    if(result === 403) {
      yield console.log('Something went wrong try again!')
    }
    else {
      // yield console.log('result', result)
    }
  } catch (error) {
    yield console.log('error')
  }
}    

export function* watchUpdateUnreadSaga() {
yield takeLatest(CHAT.UPDATE_UNREAD, updateUnreadSaga);
}

function* getChatRoomByIdSaga(action) {
  const id  = action.payload && action.payload.id || null
  const user_id  = action.payload && action.payload.user_id || null

  try {
    const result = yield chat.getChatRoomById(id, user_id)
    if(result === 403) {
      yield put(getChatRoomByIdFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.getChatRoomById ? result.data.getChatRoomById : {}
      yield put(getChatRoomByIdSuccess(data));
      }
  } catch (error) {
  console.log('error', JSON.stringify(error))
  yield put(getChatRoomByIdFailed(error))
  }
}    

export function* watchGetChatRoomByIdSaga() {
yield takeLatest(ADD_ROOM.LOAD, getChatRoomByIdSaga);
}

function* sendReviewSaga(action) {
  const { ratingInput } = action.payload
  try {
    const result = yield chat.sendReview(ratingInput)
    if(result === 403) {
      yield put(sendReviewFailed('Something went wrong try again!'))
    }
    else {
      const data = result && result.data && result.data.createReview ? result.data.createReview : {}
      yield put(sendReviewSuccess(data));
      }
  } catch (e) {
  console.log('error', JSON.stringify(e))
  const error = e && e.graphQLErrors ? e.graphQLErrors : e
  const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
      error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
        error && error[0] && error[0].message ? error[0].message : 
          theme.text.error.default
  yield put(sendReviewFailed(errorMessage))
  }
}    

export function* watchSendReviewSaga() {
yield takeLatest(SEND_REVIEW.LOAD, sendReviewSaga);
}

function* deleteChatSaga(action) {
  const { payload } = action
  try {
    const result = yield chat.deleteChat(payload)
    if(result === 403) {
      yield put(deleteChatFailed('Something went wrong try again!'))
    }
    else {
      const message = result && result.data && result.data.deleteChatMessage && result.data.deleteChatMessage.message ? result.data.deleteChatMessage.message : ''
      yield put(deleteChatSuccess(message));
    }
  } catch (e) {
  console.log('error', JSON.stringify(e))
  const error = e && e.graphQLErrors ? e.graphQLErrors : e
  const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
      error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
        error && error[0] && error[0].message ? error[0].message : 
          theme.text.error.default
  yield put(deleteChatFailed(errorMessage))
  }
}    

export function* watchDeleteChatSaga() {
yield takeLatest(DELETE_CHAT.LOAD, deleteChatSaga);
}