import React, { useContext, useReducer, useEffect } from 'react';
import { AdminCustomFieldContext } from '../../../../../../common/context';
import { labelSwitch } from '../../../../../../common/helper/utility'
import { theme } from '../../../../../../common/theme'
import Category from '../../../components/Category'
import Type from '../../../../../../common/component/type'

export const CustomFieldList = () => {
    const value = useContext(AdminCustomFieldContext);
    const { adminCustomFieldData, defaultInput, fetchCustomFieldDetail, deleteItem } = value

    const renderHeader = () => {
        let element = []
        const data = defaultInput
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                element.push(<th key={k}>{labelSwitch(k)}</th>)
            }
        }
        element.push(<th key={'edit'}>{'Edit'}</th>)
        element.push(<th key={'delete'}>{'Delete'}</th>)
        return element
    }

    return (
        <div className='main-container'>
            <div></div>
                {adminCustomFieldData.length === 0 ? <div className='no-record-container'><p className='text-align-center'>No item found</p></div> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                {renderHeader()}
                            </tr>
                            {adminCustomFieldData.map((item, index) => {
                                const cagetoryName = item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? item.oc_t_category.oc_t_category_descriptions.s_name : ''
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td >{item.id}</td>
                                        <td >{item.name}</td>
                                        <td >{cagetoryName}</td>
                                        <td >{item.custom_index}</td>
                                        <td >{item.label}</td>
                                        <td >{item.placeholder}</td>
                                        <td >{item.type}</td>
                                        <td onClick={()=> fetchCustomFieldDetail(item)} className='text-align-center pointer'><i className="far fa-edit"/></td>
                                        <td onClick={()=> deleteItem(item)} className='text-align-center pointer'><i className="fas fa-trash-alt"></i></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const UpsertModal = () => {
    //TODO EDIT API
    const value = useContext(AdminCustomFieldContext);
    const { 
        isShow, closeItemModal, customFieldDetailData, isInsert, upsertItem,
        adminCustomFieldUpsertIsLoading, adminCustomFieldUpsertError, adminCustomFieldUpsertMessage
        // isShow, closeItemModal, preferenceDetailData, upsertItem, adminPreferenceUpsertIsLoading, adminPreferenceUpsertError, adminPreferenceUpsertMessage, isInsert
    } = value
    const { text } = theme
    
    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    useEffect(() => {
        if (customFieldDetailData) {
            const data = customFieldDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    input[k] = data[k]
                }
            }
            setItemInput(input)
        }
    },[customFieldDetailData])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue =  evt.target && evt.target.value ? evt.target.value : null;
        setItemInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
            }
        }
        return errorArray
    }

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (k === 'fk_i_category_id') {
                    arr.push(
                        <div key={k} style={{margin: '30px auto'}}>
                            <Category name={'fk_i_category_id'} selectedId={data[k] ? data[k].toString() : ''} onChange={handleChange} isShowEdit={isInsert === 'Insert' ? true : false} required/>
                        </div>
                    )
                } 
                else if(k === 'custom_index') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="number" onChange={handleChange} name={k} value={data[k]} autocomplete="off" required/>
                        </span>
                    )  
                }
                else if(k === 'type') {
                    arr.push(
                        <div key={k} style={{margin: '30px auto'}}>
                            <Type name={'type'} onChange={handleChange} isShowEdit={true} required selectedType={data[k]} isDisabled={isInsert === 'Insert' ? false : true}/>
                        </div>
                    )  
                }
                else if(k === 'name') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} autocomplete="off" required pattern="^\S+$" title="Spaces is not allowed" disabled={isInsert === 'Insert' ? false : true}/>
                        </span>
                    )                    
                }
                else if(k === 'oc_t_category') {
                    console.log('oc_t_category')                    
                }
                else {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} autocomplete="off" required disabled={k === 'id' ? true : false} />
                        </span>
                    )                    
                }
            }
        }
        return arr
    }
    
    const handleSubmit = (event, itemInput, type) => {
        event.preventDefault()
        if (itemInput.fk_i_category_id === "") {
            alert('Please select category')
        } 
        else if(itemInput.type === "") {
            alert('Please select type')
        }
        else {
            upsertItem(itemInput, type)
        }
    }

    return (
        <div className={`data-modal-container ${isShow ? 'display-block' : 'display-none'}`}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeItemModal()}>&times;</span>
                    {adminCustomFieldUpsertIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                        adminCustomFieldUpsertMessage ? <div className="message-container"><p className='success-message'>{adminCustomFieldUpsertMessage}</p></div>:
                        <div className="container">
                            <form onSubmit={(event) => handleSubmit(event, itemInput, isInsert === 'Insert' ? 'INSERT' : 'UPDATE')}>
                            {renderInputs()}
                            { adminCustomFieldUpsertError ? <div className='flex flex-d-row'>{parseError(adminCustomFieldUpsertError)}</div>  : null }
                            <input type="submit" value={`${isInsert === 'Insert' ? 'Add' : 'Update'} Custom Field`} className="sellButton admin-sell-button pointer"/>
                            </form>
                        </div>
                    }
                </div>
        </div>
    )
}


