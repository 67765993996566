import React, { useContext, useReducer, useEffect } from 'react';
import { AdminSaleContext } from '../../../../../../common/context';
import moment from 'moment';
import { labelSwitch, renderStatusOptions } from '../../../../../../common/helper/utility'

export const SaleList = () => {
    const value = useContext(AdminSaleContext);
    const { adminSaleData, fetchSaleDetail, gotoItem, gotoUser } = value
    return (
        <div className='main-container'>
            {adminSaleData === 0 ? <p style={{textAlign: 'center'}}>No sale found</p> : 
                <div className='purchase-container'>
                <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Id</th><th>Transaction Id</th><th>Owner</th><th>Email</th><th>Item</th><th>Payment Method</th><th>Type</th><th>Total purchase</th><th>Status</th><th>Created Date</th>
                            </tr>
                            {adminSaleData.map((item, index) => {
                                const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                const ownerId = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : null
                                const email = item && item.oc_t_user && item.oc_t_user.s_email ? item.oc_t_user.s_email : ''
                                const itemTitle = item && item.oc_t_item && item.oc_t_item.oc_t_item_description && item.oc_t_item.oc_t_item_description.s_title ? item.oc_t_item.oc_t_item_description.s_title : 'NA'
                                const itemId = item && item.oc_t_item && item.oc_t_item.pk_i_id ? item.oc_t_item.pk_i_id : null

                                const total_purchase_currency = item.type === 'coin_bundle' ? 'SGD' : 'Coin'
                                return (
                                    <tr className='tr-data-container' key={index} >
                                        <td>{item.id}</td>
                                        <td className='pointer' onClick={()=> fetchSaleDetail(item.id)}>{item.transaction_id}</td>
                                        <td className='pointer' onClick={()=> gotoUser(ownerId)}>{owner}</td>
                                        <td className='pointer' onClick={()=> gotoUser(ownerId)}>{email}</td>
                                        <td className={itemTitle === 'NA' ? '' : 'pointer'} onClick={itemTitle === 'NA' ? ()=> console.log('') : ()=> gotoItem(itemId)}>{itemTitle}</td>
                                        <td>{item.payment_method}</td>
                                        <td>{item.type}</td>
                                        <td>{`${item.total_purchase} ${total_purchase_currency}`}</td>
                                        <td className={item.status === 'Completed' ? 'success-message' : 'error-message'}>{item.status}</td>   
                                        <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
            }
        </div>
    );
}

export const SaleModal = () => {
    const value = useContext(AdminSaleContext);

    const { 
        isShow, closeSaleModal, adminSaleDetailIsLoading, adminSaleDetailData, adminSaleDetailError
    } = value

    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    useEffect(() => {
        if (adminSaleDetailData) {
            const data = adminSaleDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    if(k === 'oc_t_user'){
                        input['owner'] = data[k]  && data[k].s_username ? data[k].s_username : ''
                    }
                    else if (k === 'oc_t_item') {
                        input['item'] = data[k] && data[k].oc_t_item_description && data[k].oc_t_item_description.s_title ? data[k].oc_t_item_description.s_title : null
                    } 
                    else if (k === 'service') {
                        input[k] = data[k]  && data[k].description ? data[k].description : ''
                    } 
                    else if (typeof data[k] === 'string' && k !== '__typename') {
                        input[k] = data[k]
                    } 
                    else if (typeof data[k] === 'number') {
                        input[k] = data[k]
                    } 
                    else if(typeof data[k] === 'boolean'){
                        input[k] = data[k]
                    }

                }
            }
            setItemInput(input)
        }
    },[adminSaleDetailData])

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (typeof data[k] === 'string') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" name={k} value={data[k]} disabled={true}/>
                        </span>
                    )
                }
                else if (typeof data[k] === 'number') {
                   let num_value = 0
                   switch (k) {
                       case 'before_credit':
                           num_value = parseInt(data[k])
                           break;
                        case 'current_credit':
                            num_value = parseInt(data[k])
                            break;                   
                       default:
                            num_value = parseInt(data[k])
                           break;
                   }
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="number" name={k} value={num_value} disabled={true}/>
                        </span>
                    )
                }
                else if(typeof data[k] === 'boolean'){
                    arr.push(
                        <div key={k} style={{marginBottom: 10, fontSize: 16}}>
                            <div><label><b>{labelSwitch(k)}</b></label></div>
                            <select name={k} value={data[k]} >
                                {renderStatusOptions(data[k])}
                            </select>
                        </div>
                    )
                } 
            }
        }
        return arr
    }

    const close = () => {
        setItemInput({})
        closeSaleModal()
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => close()}>&times;</span>
                { adminSaleDetailIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                        adminSaleDetailError ? <div className="message-container"><p className='error-message'>{adminSaleDetailError}</p></div> :  
                                <div className="container">
                                    {renderInputs()}
                                </div>
                } 
                </div>
        </div>
    )
}

export const SaleSuggestData = () => {
    const value = useContext(AdminSaleContext);
    const { adminSaleSuggestIsLoading, adminSaleSuggestData, fetchSaleDetail } = value

    const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

    let arrHolder = []
    adminSaleSuggestData.length === 0 ? 
        arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
                        <p style={{padding: 10}}>No results</p>
                    </div>
        ) :
    adminSaleSuggestData.map((item, index) => {
        const transaction_id = item && item.transaction_id && item.transaction_id ? item.transaction_id : ''
        const owner = item && item.oc_t_user && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''

        arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: '1px solid silver',
                            borderRight: '1px solid silver',
                            borderBottom:  (item.length -1) === index ? 0 : '1px solid silver',
                            backgroundColor: 'white',
                            height: 40
                        }}>
                            <button onClick={() => fetchSaleDetail(item.id)} style={{border: 'none'}}>{`${transaction_id} - ${owner}`}</button>
                        </div>
        )
        return null
    })
    return adminSaleSuggestIsLoading ? arrLoader : arrHolder
}

export const SaleSearchResult = () => {
    const value = useContext(AdminSaleContext);
    const { setIsShowSearchResult, isShowSearchResult, adminSaleSuggestData, fetchSaleDetail } = value

    const saleSelect = (id) => {
        setIsShowSearchResult(false)
        fetchSaleDetail(id)
    }

    return (
        <div className='data-modal-container' style={{display: isShowSearchResult ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close' onClick={() => setIsShowSearchResult(false)}>&times;</span>
                    {adminSaleSuggestData.length > 0 ?
                        <div className="modal-purchase-container">
                            <h2>Search Results</h2>
                            <table>
                                <tbody>
                                    <tr>
                                    <th>Id</th><th>Transaction Id</th><th>Owner</th><th>Payment Method</th><th>Type</th><th>Total purchase</th><th>Status</th><th>Created Date</th>
                                    </tr>
                                    {adminSaleSuggestData.map((item, index) => {
                                    const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                    return (
                                        <tr className='tr-data-container' key={index} onClick={()=> saleSelect(item.id)}>
                                            <td>{item.id}</td>
                                            <td>{item.transaction_id}</td>
                                            <td>{owner}</td>
                                            <td>{item.payment_method}</td>
                                            <td>{item.type}</td>
                                            <td>{item.total_purchase}</td>
                                            <td className={item.status === 'Completed' ? 'success-message' : 'error-message'}>{item.status}</td>   
                                            <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    )                        
                                })}
                                </tbody>    
                            </table>
                        </div> :
                        <div className="container">
                            <h2>No Results</h2>
                        </div>
                    }
            </div>
        </div>        
    )
}