import React, { useContext, useReducer, useEffect} from 'react';
import { AdminSubscriptionContext } from '../../../../../../common/context';
import moment from 'moment';
import { labelSwitch } from '../../../../../../common/helper/utility'

export const SubscriptionList = () => {
    const value = useContext(AdminSubscriptionContext);
    const { adminSubscriptionData, gotoUser, fetchSubscriptionDetail, type, handleTypeChange } = value

    return (
        <div className='main-container'>
            {adminSubscriptionData === 0 ? <p style={{textAlign: 'center'}}>No subscription found</p> : 
                <div className='purchase-container'>
                <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Transaction Id</th>
                                <th>
                                     <select id='type' name={'type'} value={type} onChange={handleTypeChange}>
                                        <option value={'subscription'}>Subscription</option>
                                        <option value={'live_listing'}>Live Listing</option>
                                        <option value={'others'}>Others</option>
                                        <option value={'all'}>All</option>
                                    </select>
                                </th>


                                <th>Name</th>
                                <th>User</th>
                                <th>Purchase Date</th>
                                <th>Status</th>
                                <th>Due Date</th>
                                <th>Payment Method</th>
                            </tr>
                            {adminSubscriptionData.map((item, index) => {
                                const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                const ownerId = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : null
                                const status = item.subscription_status === null ? 'ACTIVE' : item.subscription_status
                                return (
                                    <tr className='tr-data-container' key={index} >
                                        <td className='pointer' onClick={()=> fetchSubscriptionDetail(item.id, true)}>{item.transaction_id}</td>
                                        <td>{item.type}</td>
                                        <td>{item.name}</td>
                                        <td className='pointer' onClick={()=> gotoUser(ownerId)}>{owner}</td>
                                        <td>{moment(item.purchase_date).format('YYYY-MM-DD')}</td>
                                        <td className={status === null ? '' : status === 'active' || status === 'ACTIVE' ? 'success-message' : 'error-message'}>{status}</td>   
                                        <td>{item.due_date === '8888-01-01 12:00:00' ? 'No expiry' : moment(item.due_date).format('YYYY-MM-DD hh:mm a')}</td>
                                        <td>{item.payment_method}</td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
            }
        </div>
    );
}

export const SubscriptionModal = () => {
    const value = useContext(AdminSubscriptionContext);
    const { 
        isShow, isShowEdit, adminSubscriptionDetailData, closeAllModal, gotoUser,
        adminSubscriptionDetailIsLoading, adminSubscriptionDetailError, handleUpdate,
        adminUpdateSubscriptionLoading, adminUpdateSubscriptionError, adminUpdateSubscriptionSuccess
    } = value

    const [subscriptionInput, setSubscriptionInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );

    const ownerId = adminSubscriptionDetailData && adminSubscriptionDetailData.oc_t_user && adminSubscriptionDetailData.oc_t_user.pk_i_id ? adminSubscriptionDetailData.oc_t_user.pk_i_id : null

    useEffect(() => {
        if (adminSubscriptionDetailData) {
            const data = adminSubscriptionDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    if(k === 'oc_t_user'){
                        input['owner'] = data[k]  && data[k].s_username ? data[k].s_username : ''
                    } 
                    else {
                        input[k] = data[k]
                    }
                }
            }
            setSubscriptionInput(input)
        }
    },[adminSubscriptionDetailData])

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setSubscriptionInput({
            [name]: value
        });
    }

    const parseError = (error) => {
        let errorArray = []
        if (typeof error === 'string') {
          errorArray.push(<p key={1} style={{color: 'red'}}>{error}</p>)
        } else {
          for (var key in error) {
            if (error.hasOwnProperty(key)) {
                errorArray.push(<p key={key} style={{color: 'red'}}>{error[key].toString().replace('input.', '')}</p>)
            }
          }
        }
        return errorArray
      }

    const renderInputs = () => {
        const data = subscriptionInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if ((k !== 'id') && (k !== '__typename') && (k !== 'user_id')) {
                    if (k === 'subscription_status') {
                        const status = data[k] === null ? 'ACTIVE' : data[k]

                        arr.push(
                            <span key={k}>
                                <label ><b>Subscription Status</b></label>
                                <input 
                                className={status === null ? '' : status === 'active' || status === 'ACTIVE' ? 'success-message' : 'error-message'}
                                type="text" name={k} value={status} disabled={true}/>
                            </span>
                        )
                    } 
                    else if(k === 'due_date'){
                        const dueDate = data[k] === '8888-01-01 12:00:00' ? 'No expiry' :   moment(data[k]).format('YYYY-MM-DD')
                        arr.push(
                            <div className='date-input-container' key={k}>
                                <label ><b>Due Date: </b></label>
                                {data[k] === '8888-01-01 12:00:00' ? 'No expiry' : 
                                    <input className='date-input' type="date" onChange={handleChange} name={k} value={dueDate} disabled={false}/>
                                }
                            </div>
                        )
                    } 
                    else if(k === 'owner'){
                        arr.push(
                            <span key={k}>
                                <label ><b>{labelSwitch(k)}</b></label>
                                <span onClick={()=> gotoUser(ownerId)} >
                                    <input className='pointer' type="text" name={k} value={data[k]} disabled={true}/>
                                </span>
                            </span>
                        )
                    } 
                    else {
                        arr.push(
                            <span key={k}>
                                <label ><b>{labelSwitch(k)}</b></label>
                                <input type="text" name={k} value={data[k]} disabled={true}/>
                            </span>
                        )
                    }
                } 
  
            }
        }
        return arr
    }
    
    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeAllModal()}>&times;</span>
                { 
                adminSubscriptionDetailIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                    adminSubscriptionDetailError ? <div className="message-container"><p className='error-message'>{parseError(adminSubscriptionDetailError)}</p></div> :  
                        adminUpdateSubscriptionLoading ?  <div className="message-container"><p>Loading...</p></div> :
                            adminUpdateSubscriptionSuccess ? <div className="message-container"><p className='success-message'>{adminUpdateSubscriptionSuccess}</p></div> :
                            adminUpdateSubscriptionError ? <div className="message-container"><p className='error-message'>{parseError(adminUpdateSubscriptionError)}</p></div> :  
                            <div className="container">
                                {isShowEdit ?
                                    <div>
                                        {renderInputs()}
                                        <div className="sellButton" style={{margin: 10, alignSelf: 'flex-start'}}>
                                            <span className='pointer' onClick={() => handleUpdate(subscriptionInput)}>Update Due Date</span>
                                        </div>
                                    </div>
                                    : 
                                    <>{renderInputs()}</>
                                }
                            </div>
                } 
                </div>
        </div>
    )
}

export const SubscriptionSuggestData = () => {
    const value = useContext(AdminSubscriptionContext);
    const { adminSubscriptionSearchIsLoading, adminSubscriptionSearchData, fetchSubscriptionDetail } = value

    const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

    let arrHolder = []
    adminSubscriptionSearchData.length === 0 ? 
        arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
                        <p style={{padding: 10}}>No results</p>
                    </div>
        ) :
        adminSubscriptionSearchData.map((item, index) => {
        const transaction_id = item && item.transaction_id && item.transaction_id ? item.transaction_id : ''
        const owner = item && item.oc_t_user && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
        const type = item && item.type && item.type ? item.type : ''

        arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: '1px solid silver',
                            borderRight: '1px solid silver',
                            borderBottom:  (item.length -1) === index ? 0 : '1px solid silver',
                            backgroundColor: 'white',
                            height: 40
                        }}>
                            <button onClick={() => fetchSubscriptionDetail(item.id, true)} style={{border: 'none'}}>{`${transaction_id} - ${owner} - ${type}`}</button>
                        </div>
        )
        return null
    })
    return adminSubscriptionSearchIsLoading ? arrLoader : arrHolder
}

export const SubscriptionSearchResult = () => {
    const value = useContext(AdminSubscriptionContext);
    const { adminSubscriptionSearchIsLoading, adminSubscriptionSearchData, fetchSubscriptionDetail, setIsShowSearchResult, isShowSearchResult, gotoUser } = value

    const subscriptionSelect = (id) => {
        fetchSubscriptionDetail(id, true)
    }

    return (
        <div className='data-modal-container' style={{display: isShowSearchResult ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => setIsShowSearchResult(false)}>&times;</span>
                    {
                    adminSubscriptionSearchIsLoading ?  <div className="message-container"><p>Loading...</p></div> : 
                    adminSubscriptionSearchData.length > 0 ?
                        <div className="modal-purchase-container">
                            <h2>Search Results</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Transaction Id</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>User</th>
                                        <th>Status</th>
                                        <th>Purchase Date</th>
                                        <th>Due Date</th>
                                        <th>Payment Method</th>
                                    </tr>
                                    {adminSubscriptionSearchData.map((item, index) => {
                                        const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                        const ownerId = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : null
                                        const status = item.subscription_status === null ? 'ACTIVE' : item.subscription_status
                                        return (
                                            <tr className='tr-data-container' key={index} >
                                                <td className='pointer' onClick={() => subscriptionSelect(item.id)}>{item.transaction_id}</td>
                                                <td>{item.type}</td>
                                                <td>{item.name}</td>
                                                <td className='pointer' onClick={()=> gotoUser(ownerId)}>{owner}</td>
                                                <td className={status === null ? '' : status === 'active' || status === 'ACTIVE' ? 'success-message' : 'error-message'}>{status}</td>   
                                                <td>{moment(item.purchase_date).format('YYYY-MM-DD')}</td>
                                                <td>{item.due_date === '8888-01-01 12:00:00' ? 'No expiry' : moment(item.due_date).format('YYYY-MM-DD hh:mm a')}</td>
                                                <td>{item.payment_method}</td>
                                            </tr>
                                        )                        
                                    })}
                                </tbody>    
                            </table>
                        </div> :
                        <div className="container">
                            <h2>No Results</h2>
                        </div>
                    }
            </div>
        </div>        
    )
}