import React from 'react';
import Loader from '../component/loader'
const Message = (props) => {
    return (
        <div style={props.style === 'figmaDesign' ? style.figmaContainer : style.errorCont}>
            {
                props.style === 'figmaDesign' ? <p style={style.figmaDesign}>{props.message}</p> : <h2>{props.message}</h2>
            }

        </div>
    );
};

const style = {
    errorCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50,
        minHeight: 350
    },
    figmaContainer: {
        minHeight: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50
    },
    figmaDesign: {
        // minHeight: 'auto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        color: '#232A34'
    }
}

export default Message;

export const Verifying = () => {
    return (
        <div className="modalPromote-second-modalContainer" style={{ height: 350, padding: 16, position: 'relative' }}>
            <p className='promote-title'>{`Payment process`}</p>
            <p className='verifying'>Verifying...</p>
            <div style={{ position: 'absolute', top: 33, left: 175 }}>
                <Loader />
            </div>
        </div>
    )
}

export const Success = () => {
    return (
        <div className="modalPromote-second-modalContainer" style={{ height: 350, padding: 16, position: 'relative' }}>
            <p className='promote-title'>{`Payment process`}</p>
            <p className='verifying' style={{ color: 'green' }}>Successful!</p>
            <p style={{ color: 'green', fontSize: 40, textAlign: 'center' }}><i className="fas fa-check" /></p>
        </div>
    )
}