import React, {useContext, useReducer, useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux';
import MobileItemSubmitted from './mobileSubmitted'
import { withRouter, useHistory} from 'react-router-dom'
import RECAPTCHA from 'react-google-recaptcha'
import 'react-dropzone-uploader/dist/styles.css'
import {CancelModal, PostTypeFields, NoListingAvailableContainer, UsedAllSlots} from './customComponents'
import Dropzone from 'react-dropzone-uploader'
import {AddImageIcon, PromoteLabelMobile, PremiumBoostIcon, CoinsGrayIcon, HighlightBoostIllustrateIcon, ItemNewCoinsIcon, MagnifyingGlassIcon, LocationIcon, CloseIconWithGrayBG, AddImageBtnGrayBg, MoneyIcon, InfoIcon,  AddCircleIcon,CloseCircleIcon, CheckCircleIcon, TrashIconRedBG} from '../../../../assets/svg'
import { theme } from '../../../../common/theme'
import Category from '../../../../common/component/category'
import {WholePageLoader, WholePageError} from '../../../../common/component/customComponents'
import { meLoad, createItemLoad, createItemReset, servicesLoad, promoteLoad, promoteReset} from '../../../../redux/action'
import { slugify, categoryParser, isEmpty} from '../../../../common/helper/utility'
import NativeHeaderMobile from '../../../../common/component/nativeHeaderMobile';
import DragableLayoutMobile from './DragableLayoutMobile'
import { CustomFieldList } from './component/CustomComponent'
import {ItemNewContext} from '../../../../common/context'
// import LocationData from '../../../../assets/data/sg.json'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const {REACT_APP_GOOGLE_API_KEY} = process.env

const SubmitBtn = () => {
  return (
    <div key={'dp'} className="attached-upload-container">
      <AddImageBtnGrayBg />
    </div>
  )
}

const UploadComponent = () => {
  return (
    <div key={'dp'} className="item-new-mobile-next-form">
      {/* <i className="fas fa-images" style={{fontSize: 50, color: '#FB770D', marginRight: 10}}></i> */}
      {/* <AddImageIcon /> */}
      <div className="item-new-mobile-next-form pl-17">
        <div className="select-photos-btn">{`Select Photos`}</div>
        <p className="mobile-label">{`or drag photos here`}</p>
        <p className="mobile-label">{`Up to 4 images`}</p>
      </div>
    </div>
  )
}


const MobileNew = (props) => {

  const userId = props.props.session.fkv2_session.id ? props.props.session.fkv2_session.id : null
  const {isFreeClaimed, freeNotClaim, fkPurchased, premiumAccount} = useContext(ItemNewContext)
  const { isLoading, data } = props.props.me
  const publishLoading = props.create.isLoading
  const publishData = props.create.data
  const publishError = props.create.error
  const publishItemData = props.create.itemData
  const { itemNew } = theme.text
  const [inputError, setInputError] = useState([])
  const [desc, setDesc] = useState(false)
  const [color, setColor] = useState('red')
  const [submitted, setSubmitted] = useState(false)
  const [addNewClick, setAddNewClick] = useState(false)
  const [imageName, setImageName] = useState([])
  const [cancelClick, setCancelClick] = useState(false)
  const [modalPops, setModalPops] = useState(false)
  const [discardBtnClick, setDiscardBtnClick] = useState(false)
  const { servicesData } = props.purchase
  const [submitLoading, setSubmitLoading] = useState(false)
  const [postSubmitted, setPostIsSubmitted] = useState(false)
  const [highlightClick, setHighlightClick] = useState(false)
  const [premiumClick, setPremiumClick] = useState(false)
  const [isLinkActive, setIsLinkActive] = useState(false)
  const [fetchResponse, setFetchResponse] = useState({})
  const [isTyping, setIsTyping] = useState(false)
  const [isMeetupAddNotes, setIsMeetupAddNotes] = useState(false)
  const [anotherListing, setAnotherListing] = useState(false)
  const [isMeetupAddNotes2, setIsMeetupAddNotes2] = useState(false)
  const [hideSearchBox, setHideSearchBox] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const live_items = props.me && props.me.data && props.me.data.live_items ? props.me.data.live_items : []
  let dPremiumId = []
  let dHighlightId = []
  const defaultPremiumId = servicesData.map((boost) => { return boost.type === 'top_ad' ? dPremiumId.push(boost) : null })
  const defaultHighlightId = servicesData.map((boost) => { return boost.type === 'highlight' ? dHighlightId.push(boost) : null })

  const [s_ip, set_s_ip] = useState(null)

  const fetchIp = async () => {
      await fetch('https://api.ipify.org/?format=json')
        .then(results => results.json())
        .then(data => set_s_ip(data.ip));
  }

  useEffect(() => {
    if(userId){
      if(!s_ip) {
        fetchIp()
      }
    }
  // eslint-disable-next-line
  }, [])

  let captcha;
  let history = useHistory();
  const getCoins = () => {
    history.push({
      pathname: '/user/wallet',
      state: { redirect: 'promote' }
    })
  }
  const submitPromote = (datas) => {
    props.promoteLoad({ fkv2_purchased_data: datas, userId: userId })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getServices(true)
  }, [])

  const [headerText, setHeaderText] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rightLinkText: 'List Now',
      leftLinkText: 'Cancel'
    }
  );

  const getServices = (active) => {
    if (servicesData.length === 0) {
      props.servicesLoad(active)
    }
  }

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      s_category: '',
      fk_i_category_id: '',
      i_price: '',
      s_title: '',
      item_condition: '',
      formIsComplete: false,
      deal_method: '',
      s_description: '',
      submitItemDetails: [],
      deal_method_shipped: '',
      s_dm_shipped_details: '',
      s_dm_meetup_add1: '',
      s_dm_meetup_add2: '',
      deal_method_meetup: '',
      totalLocation: 0,
      itemLink: '',
      youtube_link: '',
      raw_address: '',
      boost: '',
      taDetails: [],
      hlDetails: [],
      item_id: '',
      subBoost: 0,
      boostTotal: 0,
      shippingDetails: '',
      formStart: false,
      boostAppliedTotal: 0,
      highlight: '',
      subHighlight: 0,
      highlightTotal: 0,
      meetupAddnotesAddress1: '',
      meetupAddnotesAddress2: '',
      error: {
        name: '',
        price: '',
        description: '',
        image: '',
        item_condition: '',
        category: ''
      },
      captcha: '',
      inputError: '',
      custom_fields: {},
      post_type: 'WTS'
    }
  );

  const postTypeArr = [
    {label: 'WTS', value: 'WTS', sublabel: 'Want to Sell'},
    {label: 'WTB', value: 'WTB', sublabel: 'Want to Buy'},
    {label: 'WTT', value: 'WTT', sublabel: 'Want to Trade'}
  ]

  const handleCustomFieldChange = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    const cfObject = userInput.custom_fields
    let finalObj = {}
    for (var k in cfObject) {
      if (cfObject[k].name === name) {
        finalObj[name] = {...cfObject[k], value: newValue}
      }
      else {
        finalObj[cfObject[k].name] = {...cfObject[k]}
      }
    }
    setUserInput({['custom_fields']: finalObj});
  }



  useEffect(() => {
    if (publishData) {
      const item_id = publishItemData.pk_i_id ? publishItemData.pk_i_id : 0
      if (userInput.taDetails.length !== 0) {
        const mergeTaPromoteData = { ...userInput.taDetails, item_id: item_id }
        submitPromote(mergeTaPromoteData)
      }
      if (userInput.hlDetails.length !== 0) {
        const mergeHlPromoteData = { ...userInput.hlDetails, item_id: item_id }
        submitPromote(mergeHlPromoteData)
      }
      setTimeout(() => {
        reset()
        props.createItemReset()
        const category = publishItemData && publishItemData.oc_t_category && publishItemData.oc_t_category.oc_t_category_descriptions && publishItemData.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(publishItemData.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
        const item_title = publishItemData && publishItemData.oc_t_item_description && publishItemData.oc_t_item_description.s_title ? publishItemData.oc_t_item_description.s_title : ''
        const itemSLug = slugify(item_title)
        const finalSLug = `/${category}/${itemSLug}_i${item_id}`
        const postData = {...publishItemData, live_items: live_items, isFreeClaim: isFreeClaimed, fkPurchase: fkPurchased}
        setPostIsSubmitted(true)
        if (publishData.includes("moderation")) {
          window.location.replace(`${process.env.REACT_APP_BASE_URL}user/listing`);
        } else {
          setIsLinkActive(true)
          setUserInput({ ['itemLink']: finalSLug, ['submitItemDetails']: postData, ['captcha']: '' })
          setSubmitLoading(false)
        }
        // props.history.push(finalSLug)

      }, 2000)
    }
    if (publishError) {
      setTimeout(() => {
        // reset()
        // props.createItemReset()
        window.location.replace(`${process.env.REACT_APP_BASE_URL}user/item/new`)
      }, 3000)
    }
    // eslint-disable-next-line
  }, [publishData, publishError])


  useEffect(() => {
    if (discardBtnClick) {
      reset()
      window.location.reload(false)
      setTimeout(() => {
        setCancelClick(false)
        setModalPops(false)
        setDiscardBtnClick(false)
        props.createItemReset()

      }, 1000)
    }
  }, [discardBtnClick])

  const userData = {
    fk_i_user_id: data.pk_i_id,
    s_contact_name: data.s_username,
    fk_c_currency_code: 'SGD',
    s_contact_email: data.s_email
  }

  const [imageData, setImageData] = useState([])

  const captchaChange = value => {
    setUserInput({ ['captcha']: value })
    if (userInput.s_title || userInput.s_description || userInput.s_description.length < 9 || userInput.i_price || userInput.s_category) {
      setUserInput({ ['formIsComplete']: true })
      setIsLinkActive(true)
    }
  }

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
  };

  const resetCaptcha = () => {
    captcha.reset();
  }


  const handleChange = evt => {
    if (evt.target.type === 'select-one') {
      // eslint-disable-next-line
      setUserInput({ ['fk_i_category_id']: evt.target.options[evt.target.selectedIndex].dataset.id });
    }

    const name = evt.target.name;
    const newValue = evt.target.value;
    let item_cond
    if (evt.target.name === 'i_price') {
      if (evt.target.value.includes("-")) {
        setUserInput({ error: { ['name']: userInput.s_title === '' ? 'Enter title' : null, ['price']: 'Price should not be negative', ['category']: userInput.s_category === '' ? 'Enter Category' : null } })
      } else {
        setUserInput({ error: { ['name']: userInput.s_title === '' ? 'Enter title' : null, ['price']: newValue === '0' ? 'Price is too low' : null, ['category']: userInput.s_category === '' ? 'Enter Category' : null } })
      }
    }
    if(evt.target.name === 'post_type'){
      setUserInput({['post_type']: evt.target.value})
    }

    if(evt.target.name === 'item_condition_use' || evt.target.name === 'item_condition_new'){
      if(userInput.item_condition && userInput.item_condition.includes(evt.target.value)){
        item_cond = userInput.item_condition.replaceAll(evt.target.value, '')
        setUserInput({['item_condition']: item_cond})
      }
      if(userInput.item_condition && !userInput.item_condition.includes(evt.target.value)) {
        item_cond = `${userInput.item_condition} ${evt.target.value}`
        setUserInput({['item_condition']: item_cond})
      }
      if(!userInput.item_condition) {
        setUserInput({['item_condition']: evt.target.value})
      }
    }

    if(evt.target.name === 'youtube_link'){
      // if(evt.target.value.includes('www.youtube.com/')){
      if(evt.target.value.includes('https://youtu.be/') || evt.target.value.includes('https://www.youtube.com/watch')){
        setUserInput({[name]: newValue})
        if(userInput.error.yt_link){
          setUserInput({error: {['yt_link']: ''}})
        }
      }else{
        setUserInput({error: {['yt_link']: 'Please enter a valid youtube share link'}})
      }
    }
    if(evt.target.name === 's_category'){
      const customFieldParse = evt.target.options[evt.target.selectedIndex].dataset.cf ? JSON.parse(evt.target.options[evt.target.selectedIndex].dataset.cf) : []

      let cfObject = {}
      if (customFieldParse.length > 0) {
        customFieldParse.map((item) => {
           cfObject[item.name] = {...item, value: ''}
        })
      }
      setUserInput({['custom_fields']: cfObject});
    }
    if (evt.target.name === 's_description') {
      if (newValue.length < 9) {
        setColor('red')
      } else if (newValue.length > 9 && newValue.length < 15) {
        setColor('yellow')
      } else if (newValue.length > 15) {
        setColor('green')

      }
    }
    if (
      //comment next line to bypass recaptcha
      userInput.captcha === '' ||
      userInput.s_title === '' || userInput.s_description === '' || userInput.s_description.length < 9 || userInput.i_price === '' || userInput.s_category === '') {
      setUserInput({ ['formIsComplete']: false })
    } else {
      setUserInput({ ['formIsComplete']: true })

    }
    if (submitted === true) {
      setUserInput({ error: { ['name']: userInput.s_title === '' ? 'Enter title' : null, ['description']: userInput.s_description === '' ? 'Enter description' : null, ['price']: userInput.i_price === '' ? 'Enter Price' : userInput.i_price.includes("-") ? 'Invalid Price' : userInput.i_price === 0 ? 'Price should not be equal to 0' : null, ['category']: userInput.s_category === '' ? 'Enter Category' : null } })
    }
    setUserInput({ [name]: newValue });
  }

  useEffect(() => {
    if (addNewClick) {
      setUserInput({ ['formIsComplete']: false, ['itemLink']: '', ['submitItemDetails']: '' })
      setPostIsSubmitted(false)
      setHeaderText({ ['leftLinkText']: 'Cancel', ['rightLinkText']: 'List Now' })
      setSubmitted(false)
      setColor('red')
      setIsLinkActive(false)
      setAddNewClick(false)
      // resetCaptcha()
    }
    if (cancelClick) {
      if (imageData && imageData.length === 0) {
        props.history.push('/')
      } else {
        setModalPops(true)
      }
    }
    if (anotherListing) {
      setSubmitted(false)
      reset()
      window.location.reload(false)
    }
  }, [addNewClick, cancelClick, anotherListing])

  const clearData = () => {
    setUserInput(
      {
        ['i_price']: '',
        ['s_title']: '',
        ['s_description']: '',
        ['s_category']: '',
        ['fk_i_category_id']: 0,
        ['itemLink']: '',
        ['item_condition']: '',
        ['captcha']: '',
        ['taDetails']: [],
        ['hlDetails']: [],
        ['boost']: '',
        ['boostTotal']: 0,
        ['subBoost']: 0,
        ['s_dm_meetup_add1']: '',
        ['s_dm_meetup_add2']: '',
        ['s_dm_shipped_details']: ''
      }
    )
  }

  const reset = () => {
    setImageData([])
    clearData()
  }

  useEffect(() => {
    if (imageData.length === 0 && userInput.formStart) {
      clearData()
      setPremiumClick(false)
      setHighlightClick(false)
      setUserInput({ ['formStart']: false })
    }
  }, [imageData])

  const handleDesc = () => {
    if (!desc) {
      setDesc(true)
    }
  }

  const handleSubmit = evt => {
    evt.preventDefault();
    if(userInput.meetupAddnotesAddress1 && hideSearchBox){

    }
    setSubmitted(true)

    let finalImageDataArr = []
    imageData.map((item) => {
      return finalImageDataArr.push(item.file)
    })

    const mergeData = { ...userData, ...userInput, finalImageDataArr }

    if (
      //comment next line to bypass recaptcha
      userInput.captcha === '' ||
      userInput.s_title === '' || userInput.s_description === '' || userInput.s_description.length < 9 || userInput.i_price === '' || mergeData.finalImageDataArr.length === 0 || userInput.s_category === '') {
      setUserInput(
        {
          error:
          {
            ['name']: userInput.s_title === '' ? 'Enter title' : null,
            ['description']: userInput.s_description === '' ? 'Enter description' : null,
            ['price']: userInput.i_price === '' ? 'Enter Price' : userInput.i_price.includes("-") ? 'Invalid Price' : userInput.i_price === 0 ? 'Price should not be equal to 0' : null,
            ['image']: mergeData.finalImageDataArr.length === 0 ? 'Add image' : null,
            ['category']: userInput.s_category === '' ? 'Enter Category' : null
          }
        })
      setUserInput({ ['inputError']: 'Recaptcha Error' })
    } else {
      let finalObj = {}
    for (var k in mergeData) {
      if (k === 'custom_fields') {
        let finalObj2 = {}
        for(var l in mergeData[k]){
          if (mergeData[k].hasOwnProperty(l)) {
            const value = mergeData[k] && mergeData[k][l] && mergeData[k][l].value ? mergeData[k][l].value : ''
            const name = mergeData[k] && mergeData[k][l] && mergeData[k][l].name ? mergeData[k][l].name : ''
            finalObj2[name] = value
          }
        }
        finalObj[k] = JSON.stringify(finalObj2)
      }
    }
    const finalIp = s_ip ? s_ip : 'unknown'
    const fileForSubmit = {...mergeData, custom_fields : finalObj.custom_fields, s_ip: finalIp }
    props.createItemLoad(fileForSubmit) 
    setIsLinkActive(false)
    setHeaderText({ ['rightLinkText']: 'Add new', ['leftLinkText']: '' })
    setSubmitLoading(true)
    }
    // setUserInput({[name]: ''})
  }

  //dropzone 
  const handleChangeStatus = (imageContext, status) => {
    if (status === 'done') {
      const tempData = imageData.concat(imageContext)
      if(imageData.length < 4){
      setImageData(tempData)
      }
    }
    if (status === 'removed') {
      const tempData = imageData.filter((obj) => {
        return obj.file.name !== imageContext.file.name
      })
      setImageData(tempData)
    }
  }

  const renderPreview = (e) => {
    const { input, files } = e
    const maxFiles = 4
    return <div className="dropzone-main-body-container">
      <DragableLayoutMobile imageData={imageData} setImageData={setImageData} handleChangeStatus={handleChangeStatus} files={files}/>

      <div>
        {imageData.length < maxFiles && input}
      </div>
    </div>
  }

  const handleKeepEditBtn = () => {
    setCancelClick(false)
  }

  const handleDiscardPostBtn = () => {
    setDiscardBtnClick(true)
  }

  const parsedError = (error) => {
    let errorMessage
    if (error.includes('file')) {
      errorMessage = "Item posted with no image, due to error processing your picture."
    } else {
      errorMessage = error
    }
    return errorMessage
  }

  useEffect(() => {
    if (!highlightClick) {
      setUserInput({ ['highlight']: '', ['subHighlight']: 0, ['highlightTotal']: 0 })
    } if (!premiumClick) {
      setUserInput({ ['boost']: '', ['subBoost']: 0, ['boostTotal']: 0 })
    }
  }, [highlightClick, premiumClick])

  useEffect(() => {
    let total
    total = userInput.highlightTotal + userInput.boostTotal
    setUserInput({ ['boostAppliedTotal']: total })
  }, [userInput.highlightTotal, userInput.boostTotal])

  const handleHighlightClick = () => {
    if (!highlightClick) {
      setUserInput({ ['subHighlight']: dHighlightId[0].id, ['hlDetails']: dHighlightId[0], ['highlightTotal']: parseInt(dHighlightId[0].credit)})
      setHighlightClick(true)
    } else {
      setUserInput({ ['subHighlight']: 0, ['hlDetails']: [], ['highlightTotal']: 0})
      setHighlightClick(false)
    }

  }

  const handlePremiumClick = () => {
    if (!premiumClick) {
      setUserInput({ ['subBoost']: dPremiumId[0].id, ['taDetails']: dPremiumId[0], ['boostTotal']: parseInt(dPremiumId[0].credit)})
      setPremiumClick(true)
    } else {
      setUserInput({ ['subBoost']: 0, ['taDetails']: [] })
      setPremiumClick(false)
    }

  }


  const handleNewPost = () => {
    setAddNewClick(true)
  }

  const handleCancelClick = () => {
    setCancelClick(true)
  }

  const headerDetails = {
    leftLink: headerText.leftLinkText,
    leftLinkFunc: handleCancelClick,
    // isLeftFuncReady: cancelClick,
    mainTitle: 'Add Listing',
    rightLink: headerText.rightLinkText,
    submitFunc: submitLoading ? null : postSubmitted ? handleNewPost : handleSubmit,
    isFuncReady: isLinkActive
  }

  const handleTotal = (type, total, boost) => {
    if (type === 'premium') {
      setUserInput({
        ['boostTotal']: total, ['taDetails']: boost
      })
    }
    if (type === 'highlight') {
      setUserInput({
        ['highlightTotal']: total,
        ['hlDetails']: boost
      })
    }
  }
  const [locationSuggest, setLocationSuggest] = useState([])
  // const object = Object.values(LocationData)

  const handleChangeMap = (evt) => {
    setIsTyping(true)
    const newValue = evt && evt.label ? evt.label : '';
    const name = 'raw_address'
    // const convertToUpperCase = newValue.toUpperCase()
    if(!userInput.s_dm_meetup_add1) {
      setUserInput({['s_dm_meetup_add1']: newValue.toUpperCase()});
    }
    if(userInput.s_dm_meetup_add1) {
      setUserInput({['s_dm_meetup_add2']: newValue.toUpperCase()});
    }
    // setUserInput({ [name]: newValue })
    if(newValue){
      evt.label = null;
    }
    if(newValue){
      // object.map((place) => {
      //   const address = place.ADDRESS
      //   // return address.match(convertToUpperCase) ? locationSuggest.length === 3 ? setLocationSuggest([place]) : setLocationSuggest(locationSuggest.concat(place)) : null
      // })
    }else{
      setLocationSuggest([])
    }
  }

  const saveAddressEnter = (description) => {
    setIsTyping(false)
    setUserInput({ ['raw_address']: '' })
    if (!userInput.s_dm_meetup_add1 && !userInput.s_dm_meetup_add2) {
      // setUserInput({ meetup_address: { ['address1']: description }, ['totalLocation']: 1 })
      setUserInput({ ['s_dm_meetup_add1']: description, ['totalLocation']: userInput.totalLocation + 1 })
    }
    if (!userInput.s_dm_meetup_add1 && userInput.s_dm_meetup_add2 && userInput.s_dm_meetup_add2 !== description) {
      setUserInput({ ['s_dm_meetup_add1']: userInput.s_dm_meetup_add2, ['s_dm_meetup_add2']: description, ['totalLocation']: userInput.totalLocation + 1, ['meetupAddnotesAddress1']: userInput.meetupAddnotesAddress2 ? userInput.meetupAddnotesAddress2 : '', ['meetupAddnotesAddress2']: '' , error: {['meetup']: ''}})
    }
    if (userInput.s_dm_meetup_add1 && !userInput.s_dm_meetup_add2 && userInput.s_dm_meetup_add1 !== description) {
      setUserInput({ ['s_dm_meetup_add1']: userInput.s_dm_meetup_add1, ['s_dm_meetup_add2']: description, ['totalLocation']: userInput.totalLocation + 1, error: {['meetup']: ''}})
    }
    if(userInput.s_dm_meetup_add1 === description || userInput.s_dm_meetup_add2 === description){
      setUserInput({ error: {['meetup']: 'You can`t select same address, try to choose another location'}})
    }
  }

  const removeAddress = (address) => {
    if (address === 'address1') {
      setUserInput(
        {
          ['s_dm_meetup_add1']: '',
          ['s_dm_meetup_add2']: userInput.s_dm_meetup_add2,
          ['totalLocation']: userInput.s_dm_meetup_add2 || userInput.s_dm_meetup_add1 ? userInput.totalLocation - 1  : 0
        })
    } else {
      setUserInput(
        {
          ['s_dm_meetup_add1']: userInput.s_dm_meetup_add1,
          ['s_dm_meetup_add2']: '',

          ['totalLocation']: userInput.s_dm_meetup_add2 || userInput.s_dm_meetup_add1 ? userInput.totalLocation - 1  : 0
        })
    }
  }

  const handleAnotherListItem = () => {
    setAnotherListing(true)
  }

  const [meetupClicked, setMeetupClicked] = useState(false)
  const [shippedClicked, setShippedClicked] = useState(false)

  const onKeyDownInput = (event) => {
    if (event.key === 'e' || event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  }

  const handleShippedClick = (type) => {
    if (type === 'meet_up') {
      if (meetupClicked) {
        setMeetupClicked(false)
        setUserInput({ ['s_dm_meetup_add1']: '', ['s_dm_meetup_add2']: '', ['deal_method_meetup']: '' })
      } else {
        setMeetupClicked(true)
      }
    } else {
      if (shippedClicked) {
        setShippedClicked(false)
        setUserInput({ ['s_dm_shipped_details']: '', ['deal_method_shipped']: '' })
      } else {
        setShippedClicked(true)
      }
    }
  }

  const handleSetAddNote = (type) => {
    if(type === 'ad1'){
      setIsMeetupAddNotes(true)
      setHideSearchBox(true)
    }else{
      setIsMeetupAddNotes2(true)
      setIsConfirm(true)
    }
  }

  const handleMeetupAddNote = (type) => {
    if(type === 'ad1'){
      setIsMeetupAddNotes(false)
      setUserInput({['meetupAddnotesAddress1']: ''})
      if(hideSearchBox){
        setHideSearchBox(false)
      }
    }else{
      setIsMeetupAddNotes2(false)
      setUserInput({['meetupAddnotesAddress2']: ''})
      if(!isConfirm){
        setIsConfirm(true)
      }
    }
  }

  const handleMeetupAddnotesConfirm = (type) => {
    if(type === 'ad1'){
      setHideSearchBox(false)
      document.getElementById('meetupNotes1').readOnly = true
    }else{
      setIsConfirm(false)
      document.getElementById('meetupNotes2').readOnly = true
    }
  }

  const raw_address = userInput.raw_address

  const alertDetails = {
    // leftBtn: handleDiscardPostBtn,
    leftBtn: {
      title: 'Discard Post',
      action: handleDiscardPostBtn
    },
    rightBtn: {
      title: 'Keep Editing',
      action: handleKeepEditBtn},
    title: 'Are you sure you want to cancel?',
    body: 'If you cancel now, your post will be discarded.'
  }


  return (
    <>
      <div className="item-new-mobile-main-container">
        <NativeHeaderMobile details={headerDetails} />
        {
          isLoading || publishLoading ? <WholePageLoader /> :
          publishError ? <WholePageError text={parsedError(publishError)} /> : 
          freeNotClaim ? <NoListingAvailableContainer /> : fkPurchased.free.length !== 0 && fkPurchased.noAvailableSlot ? <UsedAllSlots /> :
          submitLoading ?  <WholePageLoader /> :
            postSubmitted ? <MobileItemSubmitted data={userInput.submitItemDetails} iLink={userInput.itemLink} another={handleAnotherListItem}/> :

              <form>
                <div className={`mobile-post-main-container ${imageData.length === 0 ? `ps-16` : ``}`}>
                  <div className={`item-new-listing-mobile-label-container ${imageData.length === 0 ? `` : `ps-16 mb-17`}`}>
                    <p className="mobile-post-title">{`What are you listing today?`}</p>
                    <p className="mobile-post-sub-title">{imageData.length === 0 ? `You may upload up to 4 images` : ``}</p>
                  </div>
                  <div className={`${imageData.length === 0 ? `upload-pic-container upload-component-center` : `dropzone-with-images-container`}`}>
                    <div className={`upload-image-label-container`}>
                      {imageData.length === 0 ? <AddImageIcon /> : null}
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        // onDrop={testOnDrop}
                        accept="image/*"
                        maxFiles={imageData.length === 0 ? 4 : 2^53 - 1}
                        chunking={true}
                        // maxFilesize= {2} // MB
                        inputWithFilesContent={<SubmitBtn />}
                        inputContent={<UploadComponent key={'dropzone-key'} />}
                        // preview={<Preview />}
                        LayoutComponent={imageData.length > 0 ? (e) => renderPreview(e) : null}
                        // disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
                        classNames={{ inputLabelWithFiles: 'select-photos-with-files', preview: 'previewCont' }}
                        required />
                    </div>
                  </div>
                  {
                        imageData.length !== 0 ?
                        <div className="flex flex-d-row mb-30 w-100 p-0-16">
                        {
                          postTypeArr.map((post, i) => {
                            return (
                              <PostTypeFields radioname="post_type" name={post.label} nameClass="item-new-post-type-label-p" subname={post.sublabel} subnameClass="item-new-post-type-sub-label-p" value={post.value} radioClass="item-condition-radios radio custom-radio-ui" key={i} onChangeFunc={handleChange} selectedOptions={userInput.post_type}/>
                            )
                          })
                        }
                        </div> : null
                      }
                  {
                    imageData.length === 0 ? null :
                      <>
                        <Category value={userInput.category} name={'s_category'} onChange={handleChange} />
                        {
                          userInput.s_category ?
                            <div className="item-new-formGroup">
                              <label className="item-new-label">{`Item Condition`}<span className="text-red">*</span></label>
                              <div className="item-condition-container">
                              {
                                  itemNew.map((choices, index) => {
                                    return (
                                      <div key={index} className={`options-${choices.value}`}>
                                        <input type="radio" name={userInput.post_type === 'WTS' ? `item_condition` : `item_condition_${choices.value}`} className={`item-condition-radios radio custom-radio-ui 
                                      `} value={choices.value} onClick={(e) => handleChange(e)} checked={userInput.item_condition.includes(choices.value) ? true : false}
                                       />
                                      <span className="condition-label">{choices.label}</span>
                                    </div>
                                    )
                                  })
                                }
                              </div>
                              <p className="color-danger">{userInput.error.item_condition ? userInput.error.item_condition : null}</p>
                            </div> : null
                        }
                        {
                          userInput.item_condition ?

                            <div className="item-new-mobile-next-form w-100">

                              <div className="item-new-formGroup">
                                <label className="item-new-label">{`Listing Title`}<span className="text-red">*</span></label>
                                <input className="item-new-input" autoComplete="off" placeholder="Enter title" type="text" name={'s_title'} value={userInput.s_title} onChange={handleChange} required></input>
                                <p className="color-danger">{userInput.error.name ? userInput.error.name : null}</p>
                              </div>
                              <CustomFieldList data={userInput.custom_fields} handleCustomFieldChange={handleCustomFieldChange} />
                              <div className="item-new-formGroup">
                                <label className="item-new-label">{`Price`}<span className="text-red">*</span></label>
                                <div className={userInput.error !== 'Invalid' ? `item-new-price` : `item-new-error`}>
                                  <span className="item-new-currency">SGD</span>
                                  <input className="item-new-priceInput" type="number" name="i_price" placeholder="0.00" inputMode="numeric" value={userInput.i_price} onKeyDown={onKeyDownInput} onChange={handleChange} required></input>
                                </div>
                                <p className="color-danger">{userInput.error.price ? userInput.error.price : null}</p>
                                <p className="tip-p-label">Tip: Putting “<span>0</span>” in the price will show “<span>Check with seller</span>” for buyer.</p>
                              </div>

                              <div className="item-new-formGroup">
                                <label className="item-new-label">{`Description`}<span className="text-red">*</span></label>
                                <div className={`item-new-textarea-container `} onClick={handleDesc}>
                                  <div className={`item-textarea-container ${desc ? color === 'red' ? submitted ? `border-red` : null : color === 'yellow' ? 'border-yellow' : color === 'green' ? 'border-green' : 'border-default' : 'border-default'}`} >
                                    <textarea autoComplete="nope" rows="3" placeholder="Listing item details" maxLength={200} name={'s_description'} className="item-textarea" value={userInput.s_description} onChange={handleChange} required />
                                    <span className="textarea-counter">{`${userInput.s_description.length}/200`}</span>
                                  </div>
                                  {desc ?
                                    color === 'red' ? submitted ? <p className="item-new-text item-new-text-red">{`Description should at least be 10 characters.`}</p> : null :
                                      color === 'yellow' ? <p className="item-new-text item-new-text-yellow">{`Describe more to make people find you.`}</p> :
                                        color === 'green' ? <p className="item-new-text item-new-text-green">{`Good to go!`}</p> : null : null
                                  }
                                  <p className="color-danger">{userInput.error.description ? userInput.error.description : null}</p>
                                </div>
                              </div>

                              <div className="item-new-formGroup">
                                <label className="item-new-label">{`Deal Method`}</label>
                                <div className="item-new-deal-main-container">
                                  <div >
                                    <div className={`item-new-meetup-container p-relative ${meetupClicked ? `mb-12` : ``} ${userInput.raw_address ? ` flex-align-start` : ``}`} onClick={() => handleShippedClick('meet_up')}>
                                      <input type="radio" name="deal_method_meetup" checked={meetupClicked ? true : false} value="meetup" className="item-condition-radios radio custom-radio-ui " onChange={handleChange} />
                                      {
                                        
                                       meetupClicked && userInput.raw_address && !userInput.s_dm_meetup_add1 ?
                                        <div className="flex flex-d-column">
                                            <span className="item-new-meetup-label">{`Meet - up`}</span>
                                            <span className="deal-method-label-locations">{`You can add up to 2 locations`}</span>
                                        </div> : <span className="item-new-meetup-label">{`Meet - up`}</span>
                                       
                                        
                                      }
                                       <div className={`p-absolute top-0 right-0`}>              
                                      { meetupClicked ?
                                        userInput.raw_address && !userInput.s_dm_meetup_add1 ?
                                        <span className="location-counter">{`${userInput.totalLocation}/2`} </span> :
                                        userInput.s_dm_meetup_add1 || userInput.s_dm_meetup_add2 ?
                                        <span className="location-counter">{`${userInput.totalLocation}/2`} </span> : null
                                        : null
                                      }
                                    </div>
                                    </div>
                                   
                                    {
                                      userInput.deal_method_meetup ?
                                        <>
                                          <div className="flex flex-d-column">
                                            {
                                              userInput.s_dm_meetup_add1 ?
                                              <>
                                                <div className="meetup-address-container">
                                                  <div className="meetup-icon-container">
                                                    <LocationIcon />
                                                  </div>
                                                  <div className="meetup-p-container">
                                                    <p className="meetup-address-p">{userInput.s_dm_meetup_add1}</p>
                                                  </div>
                                                  <div onClick={() => removeAddress('address1')} className="meetup-close-container">
                                                    <CloseIconWithGrayBG />
                                                  </div>
                                                </div> 
                                                {
                                                  !isMeetupAddNotes ?
                                                  <div className="flex flex-d-column add-notes-container-with-textarea">
                                                    <p className="w-100">{`Notes:`}</p>
                                                    <div className="add-notes-textarea-container w-100">
                                                      <textarea autoComplete="nope" id={'meetupNotes1'} placeholder="Details make life easier, don't be shy, tell some more" name="meetupAddnotesAddress1" maxLength={200} value={userInput.meetupAddnotesAddress1} onChange={handleChange} rows="3"/>
                                                      <span className="textarea-counter">{`${userInput.meetupAddnotesAddress1.length}/200`}</span>
                                                    </div>
                                                    <div className="w-100 flex flex-d-row add-notes-button-group">
                                                      {
                                                        userInput.meetupAddnotesAddress1.length === 0 ? null :
                                                        <div onClick={() => handleMeetupAddNote('ad1')}>
                                                            <TrashIconRedBG />
                                                        </div>
                                                      }
                                                      
                                                    </div>
                                                  </div> : null
                                                }
                                                </>: null
                                            }
                                            {
                                              userInput.s_dm_meetup_add2 ?
                                              <>
                                                <div className="meetup-address-container">
                                                  <div className="meetup-icon-container">
                                                    <LocationIcon />
                                                  </div>
                                                  <div className="meetup-p-container">
                                                    <p className="meetup-address-p">{userInput.s_dm_meetup_add2}</p>
                                                  </div>
                                                  <div onClick={() => removeAddress('address2')} className="meetup-close-container">
                                                    <CloseIconWithGrayBG />
                                                  </div>
                                                </div>
                                                {
                                                  !isMeetupAddNotes2 ?
                                                  <div className="flex flex-d-column add-notes-container-with-textarea">
                                                    <p className="w-100">{`Notes:`}</p>
                                                    <div className="add-notes-textarea-container w-100">
                                                      <textarea autoComplete="nope" id={'meetupNotes2'} placeholder="Details make life easier, don't be shy, tell some more" name="meetupAddnotesAddress2" maxLength={200} value={userInput.meetupAddnotesAddress2} onChange={handleChange}/>
                                                      <span className="textarea-counter">{`${userInput.meetupAddnotesAddress2.length}/200`}</span>
                                                    </div>
                                                    <div className="w-100 flex flex-d-row add-notes-button-group">
                                                      {
                                                        userInput.meetupAddnotesAddress2.length === 0 ? null :
                                                        <div onClick={() => handleMeetupAddNote('ad2')}>
                                                              <TrashIconRedBG />
                                                          </div>
                                                      }
                                                      
                                                    </div>
                                                  </div> : null
                                                }
                                                </> : null
                                            }
                                          </div>
                                          <div className="g-map-container">
                                            {/* google map api here */}
                                            {/* {
                                              !userInput.s_dm_meetup_add1 || !userInput.s_dm_meetup_add2 ?
                                              hideSearchBox ? null :
                                              <>
                                               {userInput.s_dm_meetup_add1 && !userInput.s_dm_meetup_add2 ? <div className="add-notes-container-with-textarea mb-6"><p className="mb-0">{`Alternative venue:`}</p></div> : null }
                                                <div className="g-map-search-bar">
                                                  <input type="text" name="raw_address" value={userInput.raw_address} autoComplete="off" onChange={handleChangeMap} placeholder="Search a place or Postal code" className="g-map-search-input" />
                                                 <div className="g-map-search-icon-container"> <MagnifyingGlassIcon /> </div>
                                                </div> </> : null
                                            } */}
                                            {
                                              userInput.s_dm_meetup_add1 && !userInput.s_dm_meetup_add2 ?
                                              <div className="add-notes-container-with-textarea mb-6">
                                                <p className="mb-0">{`Alternative venue:`}</p>
                                              </div> : null
                                            }
                                            {
                                                !userInput.s_dm_meetup_add1 || !userInput.s_dm_meetup_add2 ?
                                                hideSearchBox ? null :

                                                <div className="g-map-search-bar-main-container">
                                                  <GooglePlacesAutocomplete
                                                    apiKey={REACT_APP_GOOGLE_API_KEY}
                                                    className={`g-map-search-bar-container w-100 `}
                                                    selectProps={{
                                                      raw_address,
                                                      onChange: handleChangeMap,
                                                      className: `g-map-search-input w-100 ${userInput.s_dm_meetup_add1 ? `ml-36 mx-w-438` : ``}`,
                                                      isClearable: false,
                                                      placeholder: "Search a place or Postal code",
                                                      components: {
                                                        DropdownIndicator: () =>  <div className="g-map-search-icon-container"> <MagnifyingGlassIcon /> </div>,
                                                        IndicatorSeparator:() => null
                                                      }
                                                    }}
                                                    autocompletionRequest={{
                                                      componentRestrictions: {
                                                      country: ['sg'],
                                                      }
                                                    }}
                                                  />
                                                  </div> : null
                                              }
                                               {userInput.error.meetup ? <span className="text-red flex flex-justify-end meetup-error-text">{userInput.error.meetup}</span> : null}
                                            {
                                              
                                              isTyping ?
                                              locationSuggest.length !== 0  ?
                                                  <div className="g-place-suggest-container">
                                                    <div className="meetup-header-container">
                                                      <p className="meetup-label-p">{`Results`}</p>
                                                      {/* <p className="meetup-count-p">{`${}/2`}</p> */}
                                                    </div>
                                                    <div className="g-main-suggest-container">
                                                    {
                                                      locationSuggest.map((predict, index) => {
                                                        return index !== 0 ? locationSuggest[index-1].ADDRESS === predict.ADDRESS ? null : <div key={index} className="g-suggest-container" onClick={() => saveAddressEnter(predict.ADDRESS)}>
                                                          {/* <LocationIcon /> */}
                                                          <p className="g-location-postal">{predict.POSTAL}</p>
                                                          <p className="g-location-p">{predict.ADDRESS}</p>
                                                        </div> : <div key={index} className="g-suggest-container" onClick={() => saveAddressEnter(predict.ADDRESS)}>
                                                          {/* <LocationIcon /> */}
                                                          <p className="g-location-postal">{predict.POSTAL}</p>
                                                          <p className="g-location-p">{predict.ADDRESS}</p>
                                                        </div>
                                                      })
                                                    }
                                                </div>
                                              </div> : null : null
                                            }
                                          </div> </> : null
                                    }
                                  </div>
                                  <div>
                                    <div className={`item-new-shipped-container ${userInput.deal_method_shipped ? `mb-12` : ``}`} onClick={() => handleShippedClick('shipped')}>
                                      <input type="radio" name="deal_method_shipped" checked={shippedClicked ? true : false} value="shipped" className="item-condition-radios radio custom-radio-ui " onChange={handleChange} />
                                      <span className="tem-new-shipped-label">{`Delivery`}</span>
                                    </div>
                                    {
                                      userInput.deal_method_shipped ?
                                        <div className="item-new-ship-container">
                                          <div className="item-new-ship-sub-container">
                                            <textarea autoComplete="nope" name="s_dm_shipped_details" maxLength={200} onChange={handleChange} rows="3" placeholder="Enter shipping details..." />
                                            <div className="flex w-100 flex-justify-end">
                                              <p className="textarea-counter">{`${userInput.s_dm_shipped_details.length}/200`}</p>
                                            </div>
                                          </div>
                                        </div> : null
                                    }
                                  </div>
                                </div>
                              </div>
                              {
                                premiumAccount ? 
                                <>
                                   <div className="item-new-formGroup">
                                    <label className="item-new-label">{`Youtube Link`}&nbsp;<i className="optional-text">{`(optional)`}</i></label>
                                    <input type="text" autoComplete="off" placeholder="https://www.youtube.com" name="youtube_link" className="item-new-input" onChange={handleChange} />
                                  </div>
                                </> : null
                              }

                              <div className="item-new-formGroup">
                                <RECAPTCHA sitekey={theme.text.sitekey} onChange={captchaChange} ref={(r) => setCaptchaRef(r)} />
                                {userInput.inputError ? <div className="flex"><p className="text-red">{userInput.inputError}</p></div> : null}
                                {publishData ? <div><p className="text-green">{publishData}</p></div> : null}
                                {publishError ? <div><p className="text-red">{parsedError(publishError)}</p></div> : null}
                                {inputError ? <div><p className="text-red">{inputError}</p></div> : null}
                              </div>
                            </div> : null
                        }
                        {/* <div className="w-100 ps-16"> */}
                          {/* <div className={`item-new-coins-balance-container ${data.wallet < dHighlightId[0].credit || data.wallet < dPremiumId[0].credit ? `border-red` : ``}`}> */}
                            {/* {console.log("data.wallet", props)} */}
                            {/* <p className="item-new-coins-label"><MoneyIcon /> &nbsp;{data.wallet ? data.wallet : 0}</p> */}
                            {/* <div className="item-new-user-coin" onClick={() => getCoins()}> */}
                              {/* <ItemNewCoinsIcon /> &nbsp; */}
                              {/* <p className="item-new-promote-coin-text">{`${ data.wallet < dHighlightId[0].credit || data.wallet < dPremiumId[0].credit ? `Get` : `Add`} Coins`}</p>                      */}
                          {/* </div> */}
                          {/* </div> */}
                        {/* </div> */}
                        {/* {data.wallet < dHighlightId[0].credit || data.wallet < dPremiumId[0].credit ? <p className="w-100 flex mt-6 ps-16 item-label-insufficient">{`Insufficient funds`}</p>: null} */}
                        {
                          // imageData.length === 0 ? null :
                            // <div className={`item-new-promote-container ${data.wallet < dHighlightId[0].credit || data.wallet < dPremiumId[0].credit ? `opacity-p-5` : ``}`}>
                            //   {/* <PromoteLabelMobile /> */}
                            //   <div className="item-new-promote-label-container">
                            //     <p>{`Base Ads`}</p>
                            //   </div>
                            //   <div className="item-new-promote-sub-container">
                                
                            //     <div>
                            //       <div className={`boost-main-container ${userInput.boost !== 'premium' ? `boost-premium` : `boost-premium-active`} `}>
                            //         <div className="boost-container">
                            //         <input type="radio" name="boost" checked={premiumClick} onClick={() => 
                            //         // clickable logic 

                            //           userInput.highlightTotal === 0 ? 
                            //           data.wallet < dPremiumId[0].credit ? 
                            //           null : handlePremiumClick() : 
                            //           parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < data.wallet ? 
                            //           handlePremiumClick() : null

                            //           } value="premium" 
                            //           className={`item-condition-radios radio custom-radio-ui 
                            //           ${userInput.highlightTotal === 0 ? 
                            //             data.wallet < dPremiumId[0].credit ? `custom-radio-ui-inactive` : ``:
                            //             parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < data.wallet ? `` : `custom-radio-ui-inactive`
                            //             }`} onChange={ data.wallet < dHighlightId[0].credit || data.wallet < dPremiumId[0].credit ? null : handleChange} />
                            //         <div className="boost-details">
                            //           <div className={`boost-title 
                            //           ${ userInput.highlightTotal === 0 ? 
                            //             data.wallet < dPremiumId[0].credit ? `text-gray` : `` :
                            //             parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < data.wallet ? `` : `text-gray`
                            //             }`}><span>{`Premium`}</span>
                            //             <div className="info-container">
                            //               <span className="boost-title-info-icon"><InfoIcon /></span>
                            //               <div className="tooltip-container">
                            //                 <p>{`Note: Refunds will not be provided for any services that have been purchased.`}</p>
                            //               </div>
                            //               </div>
                            //             </div>
                            //           <span className={`boost-desc 
                            //           ${userInput.highlightTotal === 0 ?
                            //             data.wallet < dPremiumId[0].credit ? `text-gray` : `` : 
                            //             parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < data.wallet ? `` : `text-gray`
                            //             }`}>{`Placed item on top`}</span>
                            //         </div>
                            //       </div>
                            //       <div className={`boost-illustrate-icon ${
                            //         userInput.highlightTotal === 0 ? data.wallet < dPremiumId[0].credit ? `opacity-p-5` : `` : 
                            //         parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < data.wallet ? `` : `opacity-p-5`
                            //       }`}>
                            //           <PremiumBoostIcon />
                            //         </div>
                            //       </div>
                            //       <div>
                            //         {
                            //           premiumClick ?
                            //             <>
                            //               {
                            //                 servicesData.map((boost, index) => {
                            //                   const credit = boost.credit ? boost.credit : 0
                            //                   return (
                            //                     <div key={index}>
                            //                       {
                            //                         boost.type !== 'top_ad' ? null :
                            //                           <div className="subBoost-container">
                            //                             <div className="boost-container ">
                            //                             <input type="radio" 
                            //                             name="subBoost" 
                            //                             value={boost.id} 
                            //                             checked={userInput.subBoost !== 0 && userInput.subBoost === boost.id ? true : false} 
                            //                             onClick={() => 
                            //                               userInput.highlightTotal === 0 ?
                            //                               credit < data.wallet ? handleTotal('premium', credit, boost) : null : 
                            //                               parseInt(userInput.highlightTotal) + parseInt(credit) < data.wallet ?
                            //                               handleTotal('premium', credit, boost) : null
                            //                             } 
                            //                             className={`item-condition-radios radio custom-radio-ui 
                            //                             ${userInput.highlightTotal === 0 ? 
                            //                               userInput.subBoost !== boost.id ? 
                            //                               `custom-radio-border-gray 
                            //                               ${credit > data.wallet ? `custom-radio-border-gray cursor-disabled` : `custom-radio-border-gray`}` : credit > data.wallet ? `custom-radio-border-gray cursor-disabled` : `` :parseInt(userInput.highlightTotal) + parseInt(credit) < data.wallet ? userInput.subBoost !== boost.id ? `custom-radio-border-gray` : `` : `custom-radio-border-gray cursor-disabled`}`} 
                            //                             onChange={credit < data.wallet ? handleChange : null} />
                            //                             <div className="boost-details">
                            //                               <span className={`subBoost-title 
                            //                               ${userInput.highlightTotal === 0 ? 
                            //                                 credit > data.wallet ? `subBoost-text-gray` : 
                            //                                 userInput.subBoost !== 0 ? userInput.subBoost === boost.id ? `text-black` : `subBoost-text-gray` : `text-black` : parseInt(userInput.highlightTotal)+parseInt(credit) < data.wallet ? userInput.subBoost !== boost.id ? `subBoost-text-gray` : `text-black` : `subBoost-text-gray`}`}>{boost.name ? boost.name : ''}</span>
                            //                               <span className={`subBoost-desc ${credit > data.wallet ? `subBoost-text-gray` : userInput.subBoost !== 0 ? userInput.subBoost === boost.id ? `text-black` : `subBoost-text-gray` : `text-black`}`}>{boost.description ? boost.description : ''}</span>
                            //                             </div> </div>
                            //                             <div className="boost-credit-container">
                            //                               {credit > data.wallet ? <CoinsGrayIcon /> :
                            //                                 userInput.subBoost !== 0 ? userInput.subBoost === boost.id ? <ItemNewCoinsIcon /> : <CoinsGrayIcon /> : <ItemNewCoinsIcon />
                            //                               } &nbsp;
                            //                       <p className={`boost-credit-int ${credit > data.wallet ? `subBoost-text-gray` : userInput.subBoost !== 0 ? userInput.subBoost === boost.id ? `boost-text-orange` : `subBoost-text-gray` : `boost-text-orange`}`}>{credit}</p>
                            //                             </div>
                            //                           </div>
                            //                       }
                            //                     </div>
                            //                   )
                            //                 })
                            //               }
                            //             </> : null
                            //         }</div>
                            //       <div className={`boost-main-container ${highlightClick ? `boost-highlight-active` : ``}`}>
                            //         <div className="boost-container">
                            //         <input type="radio" name="highlight" checked={highlightClick} value="highlight" 
                            //       onClick={() => 
                            //         userInput.boostTotal === 0 ? 
                            //         dHighlightId[0].credit < data.wallet ? 
                            //         handleHighlightClick() : null : 
                            //         parseInt(userInput.boostTotal) + parseInt(dHighlightId[0].credit) < data.wallet ? handleHighlightClick() : null } 
                            //       className={`item-condition-radios radio custom-radio-ui 
                            //       ${userInput.boostTotal === 0 ?
                            //         dHighlightId[0] > data.wallet ? `custom-radio-ui-inactive` : `` :
                            //         parseInt(userInput.boostTotal) + parseInt(dHighlightId[0].credit) < data.wallet ? `` : `custom-radio-ui-inactive`
                            //         }`} onChange={userInput.boostAppliedTotal < data.wallet ? null : handleChange} />
                            //       <div className="boost-details">
                            //         <div className={`boost-title ${
                            //           userInput.boostTotal === 0 ?
                            //           dHighlightId[0].credit > data.wallet ? `text-gray` : `` :
                            //           parseInt(userInput.boostTotal) + parseInt(dHighlightId[0].credit) < data.wallet ? `` : `text-gray`
                            //           }`}><span>{`Highlighted`}</span>
                            //            <div className="info-container">
                            //               <span className="boost-title-info-icon"><InfoIcon /></span>
                            //               <div className="tooltip-container">
                            //                 <p>{`Note: Refunds will not be provided for any services that have been purchased.`}</p>
                            //               </div>
                            //               </div>
                            //           </div>
                            //         <span className={`boost-desc ${
                            //           userInput.boostTotal === 0 ?
                            //           dHighlightId[0].credit > data.wallet ? `text-gray` : `` :
                            //           parseInt(userInput.boostTotal) + parseInt(dHighlightId[0].credit) < data.wallet ? `` : `text-gray`
                            //           }`}>{`Highlight an item`}</span>
                            //           </div>
                            //         </div>
                            //         <div>
                            //           <HighlightBoostIllustrateIcon />
                            //         </div>
                            //       </div>
                            //       <div>
                            //         {
                            //           highlightClick ?
                            //             <>
                            //               {
                            //                 servicesData.map((boost, index) => {
                            //                   const credit = boost.credit ? boost.credit : 0
                            //                   return (
                            //                     <div key={index}>
                            //                       {
                            //                     boost.type !== 'highlight' ? null :
                            //                       <div className="subBoost-container">
                            //                         <div className="boost-container ">
                            //                           <input type="radio"
                            //                             name="subHighlight"
                            //                             value={boost.id}
                            //                             checked={
                            //                               userInput.subHighlight !== 0 &&
                            //                                 userInput.subHighlight === boost.id ? true : false
                            //                             } onClick={() => userInput.boostTotal === 0 ? credit < data.wallet ? handleTotal('highlight', credit, boost) : null : parseInt(userInput.boostTotal)+ parseInt(credit) < data.wallet ? handleTotal('highlight', credit, boost) : null} className={`boost-radio-container radio custom-radio-ui ${credit > data.wallet ? `custom-radio-border-gray cursor-disabled` : userInput.subHighlight !== 0 ? userInput.subHighlight === boost.id ? `` : `custom-radio-border-gray` : ``}`} onChange={userInput.boostTotal === 0 ? credit < data.wallet ? handleChange : null: parseInt(userInput.boostTotal) + parseInt(credit) < data.wallet ? handleChange : null} />
                            //                           <div className="boost-details">
                            //                             <span className={`subBoost-title ${credit > data.wallet ? `subBoost-text-gray` : userInput.subHighlight !== 0 ? userInput.subHighlight === boost.id ? `text-black` : `subBoost-text-gray` : `text-black`}`}>{boost.name ? boost.name : ''}</span>
                            //                             <span className={`subBoost-desc ${credit > data.wallet ? `subBoost-text-gray` : userInput.subHighlight !== 0 ? userInput.subHighlight === boost.id ? `text-black` : `subBoost-text-gray` : `text-black`}`}>{boost.description ? boost.description : ''}</span>
                            //                           </div> </div>
                            //                         <div className="boost-credit-container">
                            //                           {credit > data.wallet ? <CoinsGrayIcon /> :
                            //                             userInput.subHighlight !== 0 ? userInput.subHighlight === boost.id ? <ItemNewCoinsIcon /> : <CoinsGrayIcon /> : <ItemNewCoinsIcon />
                            //                           } &nbsp;
                            //                       <p className={`boost-credit-int ${credit > data.wallet ? `subBoost-text-gray` : userInput.subHighlight !== 0 ? userInput.subHighlight === boost.id ? `boost-text-orange` : `subBoost-text-gray` : `boost-text-orange`}`}>{credit}</p>
                            //                         </div>
                            //                       </div>
                            //                   }
                            //                     </div>
                            //                   )
                            //                 })
                            //               }
                            //             </> : null
                            //         }</div>
                            //     </div>

                            //   </div>
                            // </div>
                        }
                      </>
                  }

                </div>
              </form>
        }

      </div>
      {modalPops ? <CancelModal alertDetails={alertDetails}/> : null}
    </>
  )
}

const mapStateToProps = store => {
  return {
    // me: store.userReducer,
    create: store.createItemReducer,
    purchase: store.purchaseReducer
  };
};

const mapActionToProps = {
  // meLoad,
  createItemLoad,
  createItemReset,
  servicesLoad,
  promoteLoad,
  promoteReset
}

export default withRouter(connect(mapStateToProps, mapActionToProps)(MobileNew))
