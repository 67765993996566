import { ADMIN } from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,

        //session
        sessionLoading: false,
        sessionData: {},
        sessionError: false,

        cPasswordIsLoading: false,
        cPasswordData: null,
        cPasswordError: false,

        forgotPasswordIsLoading: false,
        forgotPasswordData: null,
        forgotPasswordError: false,

        //users
        adminUserIsLoading: false,
        adminUserData: [],
        adminUserError: false,
        adminUserPage: 1,
        adminTotalUser: 0,


        adminUserProfileIsLoading: false,
        adminUserProfileData: {},
        adminUserProfileError: false,

        adminUpsertUserIsLoading: false,
        //adminUpsertUserData: {},
        adminUpsertUserError: false,
        adminUpsertUserSuccess: false,

        userSuggestIsLoading: false,
        userSuggestData: [],
        userSuggestError: false,

        //items
        adminItemIsLoading: false,
        adminItemData: [],
        adminItemError: false,
        adminItemPage: 1,
        adminTotalItem: 0,

        adminItemDetailIsLoading: false,
        adminItemDetailData: {},
        adminItemDetailError: false,

        adminUpdateItemIsLoading: false,
        adminUpdateItemData: {},
        adminUpdateItemError: false,
        adminUpdateItemMessage: false,

        adminDeleteItemIsLoading: false,
        adminDeleteItemData: {},
        adminDeleteItemError: false,
        adminDeleteItemMessage: false,

        adminItemSuggestIsLoading: false,
        adminItemSuggestData: [],
        adminItemSuggestError: false,

        adminUserItemIsLoading: false,
        adminUserItemData: [],
        adminUserItemError: false,
        adminUserItemPage: 1,
        adminTotalUserItem: 0,

        //sales
        adminSaleIsLoading: false,
        adminSaleData: [],
        adminSaleError: false,
        adminSalePage: 1,
        adminTotalSale: 0,

        adminSaleDetailIsLoading: false,
        adminSaleDetailData: {},
        adminSaleDetailError: false,

        adminSaleSuggestIsLoading: false,
        adminSaleSuggestData: [],
        adminSaleSuggestError: false,

        //preference
        adminPreferenceIsLoading: false,
        adminPreferenceData: [],
        adminPreferenceError: false,
        adminPreferencePage: 1,
        adminTotalPreference: 0,

        adminPreferenceUpsertIsLoading: false,
        adminPreferenceUpsertData: {},
        adminPreferenceUpsertError: false,
        adminPreferenceUpsertMessage: false,

        adminPreferenceDeleteIsLoading: false,
        adminPreferenceDeleteError: false,

        adminPreferenceSuggestIsLoading: false,
        adminPreferenceSuggestData: [],
        adminPreferenceSuggestError: false,

        //category
        admincCategoryIsLoading: false,
        adminCategoryData: [],
        adminCategorySelection: [],
        adminCategoryError: false,
        adminCategoryPage: 1,
        adminTotalCategory: 0,

        adminUpsertCategoryIsLoading: false,
        //adminUpsertUserData: {},
        adminUpsertCategoryError: false,
        adminUpsertCategorySuccess: false,

        //reports
        adminReportedItemIsLoading: false,
        adminReportedItemData: [],
        adminReportedItemError: false,
        adminReportedItemPage: 1,
        adminReportedItemTotal: 0,

        //administrator
        administratorIsLoading: false,
        administratorData: [],
        administratorError: false,
        administratorPage: 1,
        adminTotalAdministrator: 0,

        administratorProfileIsLoading: false,
        administratorProfileData: {},
        administratorProfileError: false,

        adminUpsertAdministratorIsLoading: false,
        adminUpsertAdministratorData: {},
        adminUpsertAdministratorError: false,
        adminUpsertAdministratorMessage: false,

        adminDeleteAdministratorIsLoading: false,
        adminDeleteAdministratorData: {},
        adminDeleteAdministratorError: false,
        adminDeleteAdministratorMessage: false,

        logoutIsLoading: false,
        logoutSuccess: false,
        logoutError: false,

        //custom field
        adminCustomFieldIsLoading: false,
        adminCustomFieldData: [],
        adminCustomFieldError: false,
        adminCustomFieldPage: 1,
        adminTotalCustomField: 0,

        adminCustomFieldUpsertIsLoading: false,
        adminCustomFieldUpsertData: {},
        adminCustomFieldUpsertError: false,
        adminCustomFieldUpsertMessage: false,

        adminCustomFieldDeleteIsLoading: false,
        adminCustomFieldDeleteError: false,
        adminCustomFieldDeleteMessage: false,

        //ban text
        adminBanTextIsLoading: false,
        adminBanTextData: [],
        adminBanTextError: false,
        adminBanTextPage: 1,
        adminBanTextTotal: 0,

        adminBanTextUpsertIsLoading: false,
        adminBanTextUpsertError: false,
        adminBanTextUpsertMessage: false,

        adminBanTextDeleteIsLoading: false,
        adminBanTextDeleteError: false,
        adminBanTextDeleteMessage: false,

        //ban email
        adminBanEmailIsLoading: false,
        adminBanEmailData: [],
        adminBanEmailError: false,
        adminBanEmailPage: 1,
        adminBanEmailTotal: 0,

        adminBanEmailUpsertIsLoading: false,
        adminBanEmailUpsertError: false,
        adminBanEmailUpsertMessage: false,

        adminBanEmailDeleteIsLoading: false,
        adminBanEmailDeleteError: false,
        adminBanEmailDeleteMessage: false,

        //dashboard
        adminDashboardIsLoading: false,
        adminDashboardItemData: false,
        adminDashboardPurchaseData: false,
        adminDashboardUserData: [],
        adminDashboardMonthlyItemData: [],
        adminDashboardMonthlyPurchaseData: [],
        adminDashboardMonthlyUserData: [],
        adminDashboardError: false,

        //change user email
        changeUserEmailIsLoading: false,
        changeUserEmailData: null,
        changeUserEmailError: false,

        //service
        adminServiceIsLoading: false,
        adminServiceData: [],
        adminServiceError: false,
        adminServicePage: 1,
        adminServiceTotal: 0,

        adminServiceUpsertIsLoading: false,
        adminServiceUpsertError: false,
        adminServiceUpsertMessage: false,

        adminServiceDeleteIsLoading: false,
        adminServiceDeleteError: false,
        adminServiceDeleteMessage: false,

        //subscription
        adminSubscriptionIsLoading: false,
        adminSubscriptionData: [],
        adminSubscriptionError: false,
        adminSubscriptionPage: 1,
        adminTotalSubscription: 0,

        adminSubscriptionDetailIsLoading: false,
        adminSubscriptionDetailData: {},
        adminSubscriptionDetailError: false,

        adminUpdateSubscriptionLoading: false,
        adminUpdateSubscriptionError: false,
        adminUpdateSubscriptionSuccess: false,

        adminSubscriptionSearchIsLoading: false,
        adminSubscriptionSearchData: [],
        adminSubscriptionSearchError: false,

        //logs
        adminLogsIsLoading: false,
        adminLogsData: [],
        adminLogsError: false,
        adminLogsPage: 1,
        adminTotalLogs: 0,

        //promos
        adminPromoIsLoading: false,
        adminPromoData: [],
        adminPromoError: false,
        adminPromoPage: 1,
        adminTotalPromo: 0,

        //upsert promo
        adminPromoUpsertIsLoading: false,
        adminPromoUpsertData: {},
        adminPromoUpsertError: false,
        adminPromoUpsertMessage: false,

        //delete promo
        adminPromoDeleteIsLoading: false,
        adminPromoDeleteError: false,
        adminPromoDeleteMessage: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case ADMIN.LOGIN.LOAD:
            return {...state, isLoading: true, error: null}

            case ADMIN.LOGIN.SUCCESS:
            return {...state, isLoading: false, isLoadingMore: false, data:  action.payload, error: null}

            case ADMIN.LOGIN.FAIL:
            return {...state, isLoading: false,isLoadingMore: false, error: action.payload}

            //session
            case ADMIN.SESSION_ADMIN.LOAD:
            return {...state, sessionLoading: true, sessionError: null}

            case ADMIN.SESSION_ADMIN.SUCCESS:
            return {...state, sessionLoading: false, sessionData: action.payload, sessionError: null}

            case ADMIN.SESSION_ADMIN.FAIL:
            return {...state, sessionLoading: false, sessionError: action.payload}

            case ADMIN.SEND_FORGOT_PASSWORD.LOAD:
            return {...state, forgotPasswordIsLoading: true, forgotPasswordError: null}

            case ADMIN.SEND_FORGOT_PASSWORD.SUCCESS:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordData:  action.payload, forgotPasswordError: null}

            case ADMIN.SEND_FORGOT_PASSWORD.FAIL:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordError: action.payload}

            case ADMIN.SEND_FORGOT_PASSWORD.CLEAR_MESSAGE:
            return {...state, forgotPasswordIsLoading: false, forgotPasswordError: false, forgotPasswordData: null}

            case ADMIN.CHANGE_PASSWORD.LOAD:
            return {...state, cPasswordIsLoading: true, cPasswordError: null}
    
            case ADMIN.CHANGE_PASSWORD.SUCCESS:
            return {...state, cPasswordIsLoading: false, cPasswordData:  action.payload, cPasswordError: null}

            case ADMIN.CHANGE_PASSWORD.FAIL:
            return {...state, cPasswordIsLoading: false, cPasswordError: action.payload}

            case ADMIN.CHANGE_PASSWORD.CLEAR_MESSAGE:
            return {...state, cPasswordIsLoading: false, cPasswordError: false, cPasswordData: null}

            //users
            case ADMIN.USER.LOAD:
            return {...state, adminUserIsLoading: true, adminUserError: null}

            case ADMIN.USER.SUCCESS:
            const { data, paginatorInfo } = action.payload  


            return {...state, adminUserIsLoading: false, adminUserData:  data, adminUserPage: state.adminUserPage + 1, adminUserError: null, adminTotalUser: paginatorInfo.total}

            case ADMIN.USER.FAIL:
            return {...state, adminUserIsLoading: false, adminUserError: action.payload}

            case ADMIN.USER.RESET:
            return {...state, adminUserIsLoading: false, adminUserError: false, adminUserData:  [], adminUserPage: 1, adminTotalUser: 0}

            case ADMIN.USER_PROFILE.LOAD:
            return {...state, adminUserProfileIsLoading: true, adminUserProfileError: null}

            case ADMIN.USER_PROFILE.SUCCESS:
            return {...state, adminUserProfileIsLoading: false, adminUserProfileData:  action.payload, adminUserProfileError: null}

            case ADMIN.USER_PROFILE.FAIL:
            return {...state, adminUserProfileIsLoading: false, adminUserProfileError: action.payload}

            case ADMIN.USER_PROFILE.RESET:
            return {...state, adminUserProfileIsLoading: false, adminUserProfileError: null, adminUserProfileData: {}}
            
            case ADMIN.USER_UPSERT.LOAD:
            return {...state, adminUserProfileIsLoading: true, adminUpsertUserError: null}

            case ADMIN.USER_UPSERT.SUCCESS:
            const userUpsertData = action.payload && action.payload.oc_t_user ? action.payload.oc_t_user : {}
            const userUpsertMessage = action.payload && action.payload.message ? action.payload.message : ''

            //update existing data 
            let updatedUserData = []
                if (userUpsertMessage.includes('created')) {
                    updatedUserData = state.administratorData.concat(userUpsertData)
                } else {
                    const user_data = state.adminUserData.map(user2 => {
                        if (user2.pk_i_id === userUpsertData.pk_i_id) {
                            return userUpsertData
                        } else {
                            return user2
                        }
                    })
                    updatedUserData = user_data
                }
            return {...state, adminUserProfileIsLoading: false, adminUserProfileData:  action.payload, adminUpsertUserError: null, adminUpsertUserSuccess: true,
                adminUserData: updatedUserData
            }


            case ADMIN.USER_UPSERT.FAIL:
            return {...state, adminUserProfileIsLoading: false, adminUpsertUserError: action.payload}

            case ADMIN.USER_UPSERT.RESET:
            return {...state, adminUserProfileIsLoading: false, adminUserProfileError: null, adminUserProfileData: {}, adminUpsertUserSuccess: false}

            case ADMIN.USER_SUGGEST.LOAD:
            return {...state, userSuggestIsLoading: true, userSuggestError: null}

            case ADMIN.USER_SUGGEST.SUCCESS:
            return {...state, userSuggestIsLoading: false, userSuggestError: null,  userSuggestData:  action.payload }

            case ADMIN.USER_SUGGEST.FAIL:
            return {...state, userSuggestIsLoading: false, userSuggestError: action.payload}

            case ADMIN.USER_SUGGEST.RESET:
            return {...state, userSuggestIsLoading: false, userSuggestError: null, userSuggestData: []}

            //item
            case ADMIN.ITEM.LOAD:
            return {...state, adminItemIsLoading: true, adminItemError: null}

            case ADMIN.ITEM.SUCCESS:
            const itemData = action.payload && action.payload.data ? action.payload.data : []
            const totalItem = action.payload && action.payload.paginatorInfo && action.payload.paginatorInfo.total ? action.payload.paginatorInfo.total : 10
            return {...state, adminItemIsLoading: false, adminItemError: null, adminItemData:  itemData, adminItemPage: state.adminItemPage + 1, adminTotalItem: totalItem }

            case ADMIN.ITEM.FAIL:
            return {...state, adminItemIsLoading: false, adminItemError: action.payload}

            case ADMIN.ITEM.RESET:
            return {...state, adminItemIsLoading: false, adminItemError: false, adminItemData:  [], adminItemPage: 1, adminTotalUser: 0}


            case ADMIN.ITEM_DETAIL.LOAD:
            return {...state, adminItemDetailIsLoading: true, adminItemDetailError: null}

            case ADMIN.ITEM_DETAIL.SUCCESS:
            return {...state, adminItemDetailIsLoading: false, adminItemDetailError: null, adminItemDetailData:  action.payload, }

            case ADMIN.ITEM_DETAIL.FAIL:
            return {...state, adminItemDetailIsLoading: false, adminItemDetailError: action.payload}

            case ADMIN.ITEM_DETAIL.RESET:
            return {...state, adminItemDetailIsLoading: false, adminItemDetailError: null, adminItemDetailData: {}}

            case ADMIN.ITEM_UPDATE.LOAD:
            return {...state, adminUpdateItemIsLoading: true, adminUpdateItemError: null}

            case ADMIN.ITEM_UPDATE.SUCCESS:
            const updateData = action.payload && action.payload.response ? action.payload.response : {}
            const updateMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminUpdateItemIsLoading: false, adminUpdateItemError: null, adminUpdateItemData:  updateData, adminUpdateItemMessage: updateMessage
            }

            case ADMIN.ITEM_UPDATE.FAIL:
            return {...state, adminUpdateItemIsLoading: false, adminUpdateItemError: action.payload}

            case ADMIN.ITEM_UPDATE.RESET:
            return {...state, adminUpdateItemIsLoading: false, adminUpdateItemError: null, adminUpdateItemData: {}, adminUpdateItemMessage: false,
                adminItemDetailData: {}
            }
            //item delete
            case ADMIN.ITEM_DELETE.LOAD:
            return {...state, adminDeleteItemIsLoading: true, adminDeleteItemError: null}

            case ADMIN.ITEM_DELETE.SUCCESS:
            const deleteData = action.payload && action.payload.response ? action.payload.response : {}
            const deleteMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminDeleteItemIsLoading: false, adminDeleteItemError: null, adminDeleteItemData:  deleteData, adminDeleteItemMessage: deleteMessage
            }

            case ADMIN.ITEM_DELETE.FAIL:
            return {...state, adminDeleteItemIsLoading: false, adminDeleteItemError: action.payload}

            case ADMIN.ITEM_DELETE.RESET:
            return {...state, adminDeleteItemIsLoading: false, adminDeleteItemError: null, adminDeleteItemData: {}, adminDeleteItemMessage: false,
                adminItemDetailData: {}
            }

            case ADMIN.ITEM_SUGGEST.LOAD:
            return {...state, adminItemSuggestIsLoading: true, adminItemSuggestError: null}

            case ADMIN.ITEM_SUGGEST.SUCCESS:
            const  items  = action.payload && action.payload.data ? action.payload.data : [] 
            return {...state, adminItemSuggestIsLoading: false, adminItemSuggestError: null,  adminItemSuggestData:  items }

            case ADMIN.ITEM_SUGGEST.FAIL:
            return {...state, adminItemSuggestIsLoading: false, adminItemSuggestError: action.payload}

            case ADMIN.ITEM_SUGGEST.RESET:
            return {...state, adminItemSuggestIsLoading: false, adminItemSuggestError: null, adminItemSuggestData: []}


            case ADMIN.ITEM_BY_USER.LOAD:
            return {...state, adminUserItemIsLoading: true, adminUserItemError: null}

            case ADMIN.ITEM_BY_USER.SUCCESS:
            return {...state, adminUserItemIsLoading: false, adminUserItemError: null,  adminUserItemData:  action.payload, adminUserItemPage: state.adminUserItemPage + 1 }

            case ADMIN.ITEM_BY_USER.FAIL:
            return {...state, adminUserItemIsLoading: false, adminUserItemError: action.payload}

            case ADMIN.ITEM_BY_USER.RESET:
            return {...state, adminUserItemIsLoading: false, adminUserItemError: null, adminUserItemData: [], adminUserItemPage: 1}

            //sales
            case ADMIN.SALE.LOAD:
            return {...state, adminSaleIsLoading: true, adminSaleError: null}

            case ADMIN.SALE.SUCCESS:
            const saleData = action.payload && action.payload.sales ? action.payload.sales : []
            const adminTotalSale = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminSaleIsLoading: false, adminSaleError: null, adminSaleData:  saleData, adminSalePage: state.adminSalePage + 1, adminTotalSale: adminTotalSale }

            case ADMIN.SALE.FAIL:
            return {...state, adminSaleIsLoading: false, adminSaleError: action.payload}

            case ADMIN.SALE.RESET:
            return {...state, adminSaleIsLoading: false, adminSaleError: false, adminSaleData:  [], adminSalePage: 1, adminTotalSale: 0}

            case ADMIN.SALE_DETAIL.LOAD:
            return {...state, adminSaleDetailIsLoading: true, adminSaleDetailError: null}

            case ADMIN.SALE_DETAIL.SUCCESS:
            return {...state, adminSaleDetailIsLoading: false, adminSaleDetailError: null, adminSaleDetailData:  action.payload, }

            case ADMIN.SALE_DETAIL.FAIL:
            return {...state, adminSaleDetailIsLoading: false, adminSaleDetailError: action.payload}

            case ADMIN.SALE_DETAIL.RESET:
            return {...state, adminSaleDetailIsLoading: false, adminSaleDetailError: null, adminSaleDetailData: {}}

 
            case ADMIN.SALE_SUGGEST.LOAD:
            return {...state, adminSaleSuggestIsLoading: true, adminSaleSuggestError: null}

            case ADMIN.SALE_SUGGEST.SUCCESS:
            const { sales } = action.payload
            return {...state, adminSaleSuggestIsLoading: false, adminSaleSuggestError: null,  adminSaleSuggestData:  sales }

            case ADMIN.SALE_SUGGEST.FAIL:
            return {...state, adminSaleSuggestIsLoading: false, adminSaleSuggestError: action.payload}

            case ADMIN.SALE_SUGGEST.RESET:
            return {...state, adminSaleSuggestIsLoading: false, adminSaleSuggestError: null, adminSaleSuggestData: []}

            //preference
            case ADMIN.PREFERENCE.LOAD:
            return {...state, adminPreferenceIsLoading: true, adminPreferenceError: null}
            case ADMIN.PREFERENCE.SUCCESS:
            const preferenceData = action.payload && action.payload.preference ? action.payload.preference : []
            const adminTotalPreference = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminPreferenceIsLoading: false, adminPreferenceError: null, adminPreferenceData:  preferenceData, adminPreferencePage: state.adminPreferencePage + 1, adminTotalPreference: adminTotalPreference }

            case ADMIN.PREFERENCE.FAIL:
            return {...state, adminPreferenceIsLoading: false, adminPreferenceError: action.payload}

            case ADMIN.PREFERENCE.RESET:
            return {...state, adminPreferenceIsLoading: false, adminPreferenceError: false, adminPreferenceData:  [], adminPreferencePage: 1, adminTotalPreference: 0}

            case ADMIN.PREFERENCE_UPSERT.LOAD:
            return {...state, adminPreferenceUpsertIsLoading: true, adminPreferenceUpsertError: null}

            case ADMIN.PREFERENCE_UPSERT.SUCCESS:
            const preferenceUpsertData = action.payload && action.payload.preference ? action.payload.preference : {}
            const preferenceUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminPreferenceUpsertIsLoading: false, adminPreferenceUpsertError: null,  adminPreferenceUpsertData:  preferenceUpsertData, adminPreferenceUpsertMessage: preferenceUpsertMessage}

            case ADMIN.PREFERENCE_UPSERT.FAIL:
            return {...state, adminPreferenceUpsertIsLoading: false, adminPreferenceUpsertError: action.payload}

            case ADMIN.PREFERENCE_UPSERT.RESET:
            return {...state, adminPreferenceUpsertIsLoading: false, adminPreferenceUpsertError: null, adminUserProfileData: {}, adminPreferenceUpsertMessage: false}

            case ADMIN.PREFERENCE_DELETE.LOAD:
                return {...state, adminPreferenceDeleteIsLoading: true, adminPreferenceDeleteError: null}

            case ADMIN.PREFERENCE_DELETE.SUCCESS:
            const preferenceUpdatedData = action.payload && action.payload.preference ? action.payload.preference : {}
            return {...state, adminPreferenceDeleteIsLoading: false, adminPreferenceDeleteError: null,  adminPreferenceData:  preferenceUpdatedData}

            case ADMIN.PREFERENCE_DELETE.FAIL:
            return {...state, adminPreferenceDeleteIsLoading: false, adminPreferenceDeleteError: action.payload}

            case ADMIN.PREFERENCE_DELETE.RESET:
            return {...state, adminPreferenceDeleteIsLoading: false, adminPreferenceDeleteError: null, adminPreferenceData: []}

            case ADMIN.PREFERENCE_SUGGEST.LOAD:
            return {...state, adminPreferenceSuggestIsLoading: true, adminPreferenceSuggestError: null}

            case ADMIN.PREFERENCE_SUGGEST.SUCCESS:
            const adminPreferenceSuggestData = action.payload && action.payload.preference ? action.payload.preference : []
            return {...state, adminPreferenceSuggestIsLoading: false, adminPreferenceSuggestError: null,  adminPreferenceSuggestData:  adminPreferenceSuggestData }

            case ADMIN.PREFERENCE_SUGGEST.FAIL:
            return {...state, adminPreferenceSuggestIsLoading: false, adminPreferenceSuggestError: action.payload}

            case ADMIN.PREFERENCE_SUGGEST.RESET:
            return {...state, adminPreferenceSuggestIsLoading: false, adminPreferenceSuggestError: null, adminPreferenceSuggestData: []}

            //category
            case ADMIN.CATEGORY.LOAD:
            return {...state, admincCategoryIsLoading: true, adminCategoryError: null}

            case ADMIN.CATEGORY.SUCCESS:
            const categoryData = action.payload && action.payload.groupedData || []
            const categorySelection = action.payload && action.payload.categoryRaw || []
            const totalCategory = action.payload && action.payload.length
            return {...state, admincCategoryIsLoading: false, adminCategoryError: null, adminCategoryData:  categoryData, 
                    adminCategoryPage: state.adminCategoryPage + 1, adminTotalCategory: totalCategory, adminCategorySelection: categorySelection
                   }

            case ADMIN.CATEGORY.FAIL:
            return {...state, admincCategoryIsLoading: false, adminCategoryError: action.payload}

            case ADMIN.CATEGORY.RESET:
            return {...state, admincCategoryIsLoading: false, adminCategoryError: false, adminCategoryData:  [], adminCategoryPage: 1, adminTotalCategory: 0}

            case ADMIN.CATEGORY_UPSERT.LOAD:
            return {...state, adminUpsertCategoryIsLoading: true, adminUpsertCategoryError: null}

            case ADMIN.CATEGORY_UPSERT.SUCCESS:
            return {...state, adminUpsertCategoryIsLoading: false, adminUpsertCategoryError: null, adminUpsertCategorySuccess: action.payload.message}

            case ADMIN.CATEGORY_UPSERT.FAIL:
            return {...state, adminUpsertCategoryIsLoading: false, adminUpsertCategoryError: action.payload}

            case ADMIN.CATEGORY_UPSERT.RESET:
            return {...state, adminUpsertCategoryIsLoading: false, adminUpsertCategoryError: null, adminUserProfileData: {}}

            //reports
            case ADMIN.REPORT.ITEM.LOAD:
            return {...state, adminReportedItemIsLoading: true, adminReportedItemError: null}

            case ADMIN.REPORT.ITEM.SUCCESS:
                const reportedItemData = action.payload && action.payload.reports ? action.payload.reports : []
                const reportedItemTotal = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminReportedItemIsLoading: false, adminReportedItemError: null, adminReportedItemData:  reportedItemData, adminReportedItemPage: state.adminReportedItemPage + 1, adminReportedItemTotal: reportedItemTotal }

            case ADMIN.REPORT.ITEM.FAIL:
            return {...state, adminReportedItemIsLoading: false, adminReportedItemError: action.payload}

            case ADMIN.REPORT.ITEM.RESET:
            return {...state, adminReportedItemIsLoading: false, adminReportedItemError: false, adminReportedItemData:  [], adminReportedItemPage: 1, adminReportedItemTotal: 0}


            //administrator
            case ADMIN.ADMINISTRATOR.LOAD:
            return {...state, administratorIsLoading: true, administratorError: null}

            case ADMIN.ADMINISTRATOR.SUCCESS:
                const administrator_data = action.payload && action.payload.data ? action.payload.data : []
                const administrator_total = action.payload && action.payload.paginatorInfo && action.payload.paginatorInfo.total ? action.payload.paginatorInfo.total : 0           
            return {...state, administratorIsLoading: false, administratorError: null, administratorData:  administrator_data, administratorPage: state.administratorPage + 1, adminTotalAdministrator: administrator_total}

            case ADMIN.ADMINISTRATOR.FAIL:
            return {...state, administratorIsLoading: false, administratorError: action.payload}

            case ADMIN.ADMINISTRATOR.RESET:
            return {...state, administratorIsLoading: false, administratorError: false, administratorData:  [], administratorPage: 1, adminTotalAdministrator: 0}


            case ADMIN.ADMINISTRATOR_PROFILE.LOAD:
            return {...state, administratorProfileIsLoading: true, administratorProfileError: null}

            case ADMIN.ADMINISTRATOR_PROFILE.SUCCESS:
            return {...state, administratorProfileIsLoading: false, administratorProfileError: null, administratorProfileData:  action.payload }

            case ADMIN.ADMINISTRATOR_PROFILE.FAIL:
            return {...state, administratorProfileIsLoading: false, administratorProfileError: action.payload}

            case ADMIN.ADMINISTRATOR_PROFILE.RESET:
            return {...state, administratorProfileIsLoading: false, administratorProfileError: null, administratorProfileData: {}}
            
            case ADMIN.ADMINISTRATOR_UPSERT.LOAD:
            return {...state, adminUpsertAdministratorIsLoading: true, adminUpsertAdministratorError: null}

            case ADMIN.ADMINISTRATOR_UPSERT.SUCCESS:
                const adminUpsertData = action.payload && action.payload.data ? action.payload.data : {}
                const adminUpsertMessage = action.payload && action.payload.message ? action.payload.message : ''

                //update existing data 
                let updatedAdministratorData = []
                    if (adminUpsertMessage.includes('created')) {
                        updatedAdministratorData = state.administratorData.concat(adminUpsertData)
                    } else {
                        const data = state.administratorData.map(item => {
                            if (item.pk_i_id === adminUpsertData.pk_i_id) {
                                return adminUpsertData
                            } else {
                                return item
                            }
                        })
                        updatedAdministratorData = data
                        
                    }
            return {...state, adminUpsertAdministratorIsLoading: false, adminUpsertAdministratorError: null, adminUpsertAdministratorData:  adminUpsertData,  adminUpsertAdministratorMessage: adminUpsertMessage,
                administratorData: updatedAdministratorData
            }

            case ADMIN.ADMINISTRATOR_UPSERT.FAIL:
            return {...state, adminUpsertAdministratorIsLoading: false, adminUpsertAdministratorError: action.payload}

            case ADMIN.ADMINISTRATOR_UPSERT.RESET:
            return {...state, adminUpsertAdministratorIsLoading: false, adminUpsertAdministratorError: null, adminUpsertAdministratorData: {}, adminUpsertAdministratorMessage: false}

            case ADMIN.ADMINISTRATOR_DELETE.LOAD:
            return {...state, adminDeleteAdministratorIsLoading: true, adminDeleteAdministratorError: null}

            case ADMIN.ADMINISTRATOR_DELETE.SUCCESS:
                const adminDeleteId = action.payload && action.payload.id ? action.payload.id : {}
                const adminDeleteMessage = action.payload && action.payload.message ? action.payload.message : ''

                //update existing data 
                let deletedAdministratorData = []

                const deletedData = state.administratorData.filter(item => {
                    return item.pk_i_id !== adminDeleteId.toString()
                })
                deletedAdministratorData = deletedData

            return {...state, adminDeleteAdministratorIsLoading: false, adminDeleteAdministratorError: null,  adminUpsertAdministratorData:  adminDeleteId,  adminUpsertAdministratorMessage: adminDeleteMessage, administratorData: deletedAdministratorData }

            case ADMIN.ADMINISTRATOR_DELETE.FAIL:
            return {...state, adminDeleteAdministratorIsLoading: false, adminDeleteAdministratorError: action.payload}

            case ADMIN.ADMINISTRATOR_DELETE.RESET:
            return {...state, adminDeleteAdministratorIsLoading: false, adminDeleteAdministratorError: null, adminUpsertAdministratorData: false, adminUpsertAdministratorMessage: false}

            // logout action 
            case ADMIN.LOGOUT.LOAD:
            return {...state, logoutIsLoading: true, logoutError: false}
    
            case ADMIN.LOGOUT.SUCCESS:
            return {...state, logoutIsLoading: false, logoutError: false, logoutSuccess:  action.payload }
    
            case ADMIN.LOGOUT.FAIL:
            return {...state, logoutIsLoading: false, logoutError: action.payload}

            //custom field
            case ADMIN.CUSTOM_FIELD.LOAD:
            return {...state, adminCustomFieldIsLoading: true, adminCustomFieldError: null}

            case ADMIN.CUSTOM_FIELD.SUCCESS:
            const customFieldData = action.payload && action.payload.data ? action.payload.data : []
            const adminTotalCustomField = action.payload && action.payload.paginatorInfo && action.payload.paginatorInfo.total ? action.payload.paginatorInfo.total : null
            return {...state, adminCustomFieldIsLoading: false, adminCustomFieldError: null, adminCustomFieldData:  customFieldData, adminCustomFieldPage: state.adminPreferencePage + 1, adminTotalCustomField: adminTotalCustomField }

            case ADMIN.CUSTOM_FIELD.FAIL:
            return {...state, adminCustomFieldIsLoading: false, adminCustomFieldError: action.payload}

            case ADMIN.CUSTOM_FIELD.RESET:
            return {...state, adminCustomFieldIsLoading: false, adminCustomFieldError: false, adminCustomFieldData:  [], adminCustomFieldPage: 1, adminTotalCustomField: 0}



            case ADMIN.CUSTOM_FIELD_UPSERT.LOAD:
            return {...state, adminCustomFieldUpsertIsLoading: true, adminCustomFieldUpsertError: null}

            case ADMIN.CUSTOM_FIELD_UPSERT.SUCCESS:
            const customFieldUpsertData = action.payload && action.payload.customField ? action.payload.customField : {}
            const customFieldUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminCustomFieldUpsertIsLoading: false, adminCustomFieldUpsertError: null,  adminCustomFieldUpsertData:  customFieldUpsertData, adminCustomFieldUpsertMessage: customFieldUpsertMessage}

            case ADMIN.CUSTOM_FIELD_UPSERT.FAIL:
            return {...state, adminCustomFieldUpsertIsLoading: false, adminCustomFieldUpsertError: action.payload}

            case ADMIN.CUSTOM_FIELD_UPSERT.RESET:
            return {...state, adminCustomFieldUpsertIsLoading: false, adminCustomFieldUpsertError: null, adminCustomFieldUpsertData: {}, adminCustomFieldUpsertMessage: false}

            case ADMIN.CUSTOM_FIELD_DELETE.LOAD:
            return {...state, adminCustomFieldDeleteIsLoading: true, adminCustomFieldDeleteError: null}

            case ADMIN.CUSTOM_FIELD_DELETE.SUCCESS:
            const customFieldDelete = action.payload && action.payload.message ? action.payload.message : {}
            return {...state, adminCustomFieldDeleteIsLoading: false, adminCustomFieldDeleteError: null,  adminCustomFieldDeleteMessage:  customFieldDelete}

            case ADMIN.CUSTOM_FIELD_DELETE.FAIL:
            return {...state, adminCustomFieldDeleteIsLoading: false, adminCustomFieldDeleteError: action.payload}

            case ADMIN.CUSTOM_FIELD_DELETE.RESET:
            return {...state, adminCustomFieldDeleteIsLoading: false, adminCustomFieldDeleteError: null, adminCustomFieldDeleteMessage: false}

            //dashboard
            case ADMIN.DASHBOARD.LOAD:
            return {...state, adminDashboardIsLoading: true, adminDashboardError: null}
           
            case ADMIN.DASHBOARD.SUCCESS:
                const finalTodayPostingPerUserData = action.payload && action.payload.finalTodayPostingPerUserData || []
                const finalWeekPostingPerUser = action.payload && action.payload.finalWeekPostingPerUser || []
                const finalMonthPostPerUser = action.payload && action.payload.finalMonthPostPerUser || []
                const finalYearPostPerUser = action.payload && action.payload.finalYearPostPerUser || []  

                const finalSignUpToday = action.payload && action.payload.finalSignUpToday || []
                const finalSignUpThisWeek = action.payload && action.payload.finalSignUpThisWeek || []
                const finalSignUpThisMonth = action.payload && action.payload.finalSignUpThisMonth || []
                const finalSignUpThisYear = action.payload && action.payload.finalSignUpThisYear || []

                const finalPurchaseToday = action.payload && action.payload.finalPurchaseToday || []
                const finalPurchaseThisWeek = action.payload && action.payload.finalPurchaseThisWeek || []
                const finalPurchaseThisMonth = action.payload && action.payload.finalPurchaseThisMonth || []
                const finalPurchaseThisYear = action.payload && action.payload.finalPurchaseThisYear || []

                
                

            return {...state, adminDashboardIsLoading: false, adminDashboardError: null, 
                finalTodayPostingPerUserData: finalTodayPostingPerUserData,
                finalWeekPostingPerUser: finalWeekPostingPerUser,
                finalMonthPostPerUser: finalMonthPostPerUser,
                finalYearPostPerUser: finalYearPostPerUser,

                finalSignUpToday: finalSignUpToday,
                finalSignUpThisWeek: finalSignUpThisWeek,
                finalSignUpThisMonth: finalSignUpThisMonth,
                finalSignUpThisYear: finalSignUpThisYear,

                finalPurchaseToday: finalPurchaseToday,
                finalPurchaseThisWeek: finalPurchaseThisWeek,
                finalPurchaseThisMonth: finalPurchaseThisMonth,
                finalPurchaseThisYear: finalPurchaseThisYear
            }

            case ADMIN.DASHBOARD.FAIL:
            return {...state, adminDashboardIsLoading: false, adminDashboardError: action.payload, adminDashboardItemData: false}

            case ADMIN.DASHBOARD.RESET:
            return {...state, adminDashboardIsLoading: false, adminDashboardError: false, adminDashboardItemData:  false}

            //ban text load
            case ADMIN.BAN_TEXT.LOAD:
            return {...state, adminBanTextIsLoading: true, adminBanTextError: null}

            case ADMIN.BAN_TEXT.SUCCESS:
            const banTextData = action.payload && action.payload.keywords? action.payload.keywords : []
            const banTextTotal = action.payload && action.payload.count ? action.payload.count : 0
            return {...state, adminBanTextIsLoading: false, adminBanTextError: null, adminBanTextData:  banTextData, adminBanTextPage: state.adminBanTextPage + 1, adminBanTextTotal: banTextTotal }

            case ADMIN.BAN_TEXT.FAIL:
            return {...state, adminBanTextIsLoading: false, adminBanTextError: action.payload}

            //ban text upsert
            case ADMIN.BAN_TEXT_UPSERT.LOAD:
            return {...state, adminBanTextUpsertIsLoading: true, adminBanTextUpsertError: null}

            case ADMIN.BAN_TEXT_UPSERT.SUCCESS:
            const banTextUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminBanTextUpsertIsLoading: false, adminBanTextUpsertError: false,   adminBanTextUpsertMessage: banTextUpsertMessage}

            case ADMIN.BAN_TEXT_UPSERT.FAIL:
            return {...state, adminBanTextUpsertIsLoading: false, adminBanTextUpsertError: action.payload}

            case ADMIN.BAN_TEXT_UPSERT.RESET:
            return {...state, adminBanTextUpsertIsLoading: false, adminBanTextUpsertError: false, adminBanTextUpsertMessage: false}

            //ban text delete
            case ADMIN.BAN_TEXT_DELETE.LOAD:
            return {...state, adminBanTextDeleteIsLoading: true, adminBanTextDeleteError: null}

            case ADMIN.BAN_TEXT_DELETE.SUCCESS:
            const banTextDeleteMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminBanTextDeleteIsLoading: false, adminBanTextDeleteError: false,   adminBanTextDeleteMessage: banTextDeleteMessage}

            case ADMIN.BAN_TEXT_DELETE.FAIL:
            return {...state, adminBanTextDeleteIsLoading: false, adminBanTextDeleteError: action.payload}

            case ADMIN.BAN_TEXT_DELETE.RESET:
            return {...state, adminBanTextDeleteIsLoading: false, adminBanTextDeleteError: false, adminBanTextDeleteMessage: false}

            //ban email load
            case ADMIN.BAN_EMAIL.LOAD:
            return {...state, adminBanEmailIsLoading: true, adminBanEmailError: null}

            case ADMIN.BAN_EMAIL.SUCCESS:
            const banEmailData = action.payload && action.payload.keywords? action.payload.keywords : []
            const banEmailTotal = action.payload && action.payload.count ? action.payload.count : 0
            return {...state, adminBanEmailIsLoading: false, adminBanEmailError: null, adminBanEmailData:  banEmailData, adminBanEmailPage: state.adminBanEmailPage + 1, adminBanEmailTotal: banEmailTotal }

            case ADMIN.BAN_EMAIL.FAIL:
            return {...state, adminBanEmailIsLoading: false, adminBanEmailError: action.payload}

            //ban email upsert
            case ADMIN.BAN_EMAIL_UPSERT.LOAD:
            return {...state, adminBanEmailUpsertIsLoading: true, adminBanEmailUpsertError: null}

            case ADMIN.BAN_EMAIL_UPSERT.SUCCESS:
            const banEmailUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminBanEmailUpsertIsLoading: false, adminBanEmailUpsertError: false,   adminBanEmailUpsertMessage: banEmailUpsertMessage}

            case ADMIN.BAN_EMAIL_UPSERT.FAIL:
            return {...state, adminBanEmailUpsertIsLoading: false, adminBanEmailUpsertError: action.payload}

            case ADMIN.BAN_EMAIL_UPSERT.RESET:
            return {...state, adminBanEmailUpsertIsLoading: false, adminBanEmailUpsertError: false, adminBanEmailUpsertMessage: false}

            //ban email delete
            case ADMIN.BAN_EMAIL_DELETE.LOAD:
            return {...state, adminBanEmailDeleteIsLoading: true, adminBanEmailDeleteError: null}

            case ADMIN.BAN_EMAIL_DELETE.SUCCESS:
            const banEmailDeleteMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminBanEmailDeleteIsLoading: false, adminBanEmailDeleteError: false,   adminBanEmailDeleteMessage: banEmailDeleteMessage}

            case ADMIN.BAN_EMAIL_DELETE.FAIL:
            return {...state, adminBanEmailDeleteIsLoading: false, adminBanEmailDeleteError: action.payload}

            case ADMIN.BAN_EMAIL_DELETE.RESET:
            return {...state, adminBanEmailDeleteIsLoading: false, adminBanEmailDeleteError: false, adminBanEmailDeleteMessage: false}

            //change user email
            case ADMIN.CHANGE_USER_EMAIL.LOAD:
            return {...state, changeUserEmailIsLoading: true, changeUserEmailError: null}
    
            case ADMIN.CHANGE_USER_EMAIL.SUCCESS:
            return {...state, changeUserEmailIsLoading: false, changeUserEmailData:  action.payload, changeUserEmailError: null}

            case ADMIN.CHANGE_USER_EMAIL.FAIL:
            return {...state, changeUserEmailIsLoading: false, changeUserEmailError: action.payload}

            case ADMIN.CHANGE_USER_EMAIL.CLEAR_MESSAGE:
            return {...state, changeUserEmailIsLoading: false, changeUserEmailError: false, changeUserEmailData: null}

            //service load
            case ADMIN.SERVICE.LOAD:
            return {...state, adminServiceIsLoading: true, adminServiceError: null}

            case ADMIN.SERVICE.SUCCESS:
            const serviceData = action.payload && action.payload.service? action.payload.service : []
            const serviceTotal = action.payload && action.payload.count ? action.payload.count : 0
            return {...state, adminServiceIsLoading: false, adminServiceError: null, adminServiceData:  serviceData, adminServicePage: state.adminServicePage + 1, adminServiceTotal: serviceTotal }

            case ADMIN.SERVICE.FAIL:
            return {...state, adminServiceIsLoading: false, adminServiceError: action.payload}

            case ADMIN.SERVICE.RESET:
            return {...state, adminServiceIsLoading: false, adminServiceError: false, adminServiceData: [],  adminServicePage: 1, adminServiceTotal: 0}

            //service upsert
            case ADMIN.SERVICE_UPSERT.LOAD:
            return {...state, adminServiceUpsertIsLoading: true, adminServiceUpsertError: null}

            case ADMIN.SERVICE_UPSERT.SUCCESS:
            const serviceUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminServiceUpsertIsLoading: false, adminServiceUpsertError: false,   adminServiceUpsertMessage: serviceUpsertMessage}

            case ADMIN.SERVICE_UPSERT.FAIL:
            return {...state, adminServiceUpsertIsLoading: false, adminServiceUpsertError: action.payload}

            case ADMIN.SERVICE_UPSERT.RESET:
            return {...state, adminServiceUpsertIsLoading: false, adminServiceUpsertError: false, adminServiceUpsertMessage: false}

            //service delete
            case ADMIN.SERVICE_DELETE.LOAD:
            return {...state, adminServiceDeleteIsLoading: true, adminServiceDeleteError: null}

            case ADMIN.SERVICE_DELETE.SUCCESS:
            const serviceDeleteMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminServiceDeleteIsLoading: false, adminServiceDeleteError: false,   adminServiceDeleteMessage: serviceDeleteMessage}

            case ADMIN.SERVICE_DELETE.FAIL:
            return {...state, adminServiceDeleteIsLoading: false, adminServiceDeleteError: action.payload}

            case ADMIN.SERVICE_DELETE.RESET:
            return {...state, adminServiceDeleteIsLoading: false, adminServiceDeleteError: false, adminServiceDeleteMessage: false}

            //subscription
            case ADMIN.SUBSCRIPTION.LOAD:
            return {...state, adminSubscriptionIsLoading: true, adminSubscriptionError: null}

            case ADMIN.SUBSCRIPTION.SUCCESS:
            const subscriptionData = action.payload && action.payload.subscription ? action.payload.subscription : []
            const adminTotalSubscription = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminSubscriptionIsLoading: false, adminSubscriptionError: null, adminSubscriptionData:  subscriptionData, adminSubscriptionPage: state.adminSubscriptionPage + 1, adminTotalSubscription: adminTotalSubscription }

            case ADMIN.SUBSCRIPTION.FAIL:
            return {...state, adminSubscriptionIsLoading: false, adminSubscriptionError: action.payload}

            case ADMIN.SUBSCRIPTION.RESET:
            return {...state, adminSubscriptionIsLoading: false, adminSubscriptionError: false, adminSubscriptionData:  [], adminSubscriptionPage: 1, adminTotalSubscription: 0}

            case ADMIN.SUBSCRIPTION_DETAIL.LOAD:
            return {...state, adminSubscriptionDetailIsLoading: true, adminSubscriptionDetailError: null}

            case ADMIN.SUBSCRIPTION_DETAIL.SUCCESS:
            return {...state, adminSubscriptionDetailIsLoading: false, adminSubscriptionDetailError: null, adminSubscriptionDetailData:  action.payload, }

            case ADMIN.SUBSCRIPTION_DETAIL.FAIL:
            return {...state, adminSubscriptionDetailIsLoading: false, adminSubscriptionDetailError: action.payload}

            case ADMIN.SUBSCRIPTION_DETAIL.RESET:
            return {...state, adminSubscriptionDetailIsLoading: false, adminSubscriptionDetailError: null, adminSubscriptionDetailData: {}}

            case ADMIN.SUBSCRIPTION_UPDATE.LOAD:
            return {...state, adminUpdateSubscriptionLoading: true, adminUpdateSubscriptionError: null}

            case ADMIN.SUBSCRIPTION_UPDATE.SUCCESS:
            const subscriptionUpdateMessage = action.payload
            return {...state, adminUpdateSubscriptionLoading: false, adminUpdateSubscriptionError: null, adminUpdateSubscriptionSuccess: subscriptionUpdateMessage}

            case ADMIN.SUBSCRIPTION_UPDATE.FAIL:
            return {...state, adminUpdateSubscriptionLoading: false, adminUpdateSubscriptionError: action.payload}

            case ADMIN.SUBSCRIPTION_UPDATE.RESET:
            return {...state, adminUpdateSubscriptionLoading: false, adminUpdateSubscriptionError: false, adminUpdateSubscriptionSuccess: false}
            
            case ADMIN.SUBSCRIPTION_SEARCH.LOAD:
            return {...state, adminSubscriptionSearchIsLoading: true, adminSubscriptionSearchError: null}

            case ADMIN.SUBSCRIPTION_SEARCH.SUCCESS:
            const subscription_search = action.payload
            return {...state, adminSubscriptionSearchIsLoading: false, adminSubscriptionSearchError: null,  adminSubscriptionSearchData:  subscription_search }

            case ADMIN.SUBSCRIPTION_SEARCH.FAIL:
            return {...state, adminSubscriptionSearchIsLoading: false, adminSubscriptionSearchError: action.payload}

            case ADMIN.SUBSCRIPTION_SEARCH.RESET:
            return {...state, adminSubscriptionSearchIsLoading: false, adminSubscriptionSearchError: null, adminSubscriptionSearchData: []}

            //logs
            case ADMIN.LOGS.LOAD:
            return {...state, adminLogsIsLoading: true, adminLogsError: null}

            case ADMIN.LOGS.SUCCESS:
            const logsData = action.payload && action.payload.logs ? action.payload.logs : []
            const adminTotalLogs = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminLogsIsLoading: false, adminLogsError: null, adminLogsData:  logsData, adminLogsPage: state.adminLogsPage + 1, adminTotalLogs: adminTotalLogs }

            case ADMIN.LOGS.FAIL:
            return {...state, adminLogsIsLoading: false, adminLogsError: action.payload}

            case ADMIN.LOGS.RESET:
            return {...state, adminLogsIsLoading: false, adminLogsError: false, adminLogsData:  [], adminLogsPage: 1, adminTotalLogs: 0}
            

            //fetch promos
            case ADMIN.PROMO.LOAD:
            return {...state, adminPromoIsLoading: true, adminPromoError: null}

            case ADMIN.PROMO.SUCCESS:
            const promosData = action.payload && action.payload.promo ? action.payload.promo : []
            const adminTotalPromos = action.payload && action.payload.count && action.payload.count ? action.payload.count : 10
            return {...state, adminPromoIsLoading: false, adminPromoError: null, adminPromoData:  promosData, adminPromoPage: state.adminPromoPage + 1, adminTotalPromos: adminTotalPromos }

            case ADMIN.PROMO.FAIL:
            return {...state, adminPromoIsLoading: false, adminPromoError: action.payload}

            case ADMIN.PROMO.RESET:
            return {...state, adminPromoIsLoading: false, adminPromoError: false, adminPromoData:  [], adminPromoPage: 1, adminTotalPromos: 0}
            
            //upsert promo
            case ADMIN.PROMO_UPSERT.LOAD:
            return {...state, adminPromoUpsertIsLoading: true, adminPromoUpsertError: null}

            case ADMIN.PROMO_UPSERT.SUCCESS:
            const promoUpsertData = action.payload && action.payload.preference ? action.payload.preference : {}
            const promoUpsertMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminPromoUpsertIsLoading: false, adminPromoUpsertError: null,  adminPromoUpsertData:  promoUpsertData, adminPromoUpsertMessage: promoUpsertMessage}

            case ADMIN.PROMO_UPSERT.FAIL:
            return {...state, adminPromoUpsertIsLoading: false, adminPromoUpsertError: action.payload}

            case ADMIN.PROMO_UPSERT.RESET:
            return {...state, adminPromoUpsertIsLoading: false, adminPromoUpsertError: null, adminPromoUpsertData: {}, adminPromoUpsertMessage: false}

            //delete promo
            case ADMIN.PROMO_DELETE.LOAD:
            return {...state, adminPromoDeleteIsLoading: true, adminPromoDeleteError: null}

            case ADMIN.PROMO_DELETE.SUCCESS:
            const promoDeleteMessage = action.payload && action.payload.message ? action.payload.message : false
            return {...state, adminPromoDeleteIsLoading: false, adminPromoDeleteError: false,   adminPromoDeleteMessage: promoDeleteMessage}

            case ADMIN.PROMO_DELETE.FAIL:
            return {...state, adminPromoDeleteIsLoading: false, adminPromoDeleteError: action.payload}

            case ADMIN.PROMO_DELETE.RESET:
            return {...state, adminPromoDeleteIsLoading: false, adminPromoDeleteError: false, adminPromoDeleteMessage: false}
            


            default:
            return state;  
        }
    }
    
    