import React from 'react'
import { NavLink} from 'react-router-dom'
import { theme } from '../../../../common/theme'

export const Links = () => {
    
    const data = theme.text.messageLink

    return(
       <div className="links">
           {data.map((link, key) => {
            return <div key={key} className="margin ">
                      <div className="groupLinks">
                      <NavLink to={link.path} style={style.default} activeStyle={style.active}><i className={link.icon}/> {link.label}</NavLink>
                      </div>
                  </div>
              })}
       </div>
    );
}

const style = {
    links: {
      margin: 'auto'
    },
    default: {
        color: "#bdc3c7",
        textDecoration: 'none',
        padding: 5,
        fontFamily: theme.font.primary,
        fontSize: 18
    },
    active: {
        color: theme.color.black,
    }
    
}


export default Links