import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { itemViewLoad  } from '../../../../../../redux/action'
import ViewItem from '../view/component/main'
import Seller from '../view/seller'
import { OverlayLoader } from '../../../../../../common/component/loader'

const PublicItem = (props) => {
    const location = useLocation()
    const path = location.pathname
    const splitPath = path.split('/')
    const id = splitPath && splitPath[4] ? parseInt(splitPath[4]) : null 
    const loading = props && props.itemView && props.itemView.isLoading ? props.itemView.isLoading : false
    const itemMessage = props && props.itemView && props.itemView.itemMessage ? props.itemView.itemMessage : false
    const itemData = props && props.itemView && props.itemView.data ? props.itemView.data : {}
    const session = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0

    useEffect(() => {
        if(typeof id === 'number' && id){  
          props.itemViewLoad({id: id})
        }
    },[path])

    const reload = () => {
        if(typeof id === 'number' && id){  
            props.itemViewLoad({id: id})
          }
    }

    let history = useHistory();
    const handleBack = () => {
        const url = `/admin/dashboard/item`
        history.push(url);
    }

    return (
            <div className="profile-container">
                {
                    itemMessage ? <h2 style={{width: '100%', textAlign: 'center'}}>No item found!</h2> :
                    <>
                        <div className='profile-container-overlay' />
                        <div style={{height: '100%', width: '100%'}}>
                            <div className='back-button-container'>
                                <span className='back-button pointer' onClick={() => handleBack()}><i class="fas fa-arrow-left"></i></span>
                            </div>
                            <h2 className='align-center'>Item Public View</h2>
                            <div>
                            {Object.keys(itemData).length === 0 && itemData.constructor === Object ? null:
                                loading ? null : <ViewItem data={itemData} session={session} path={path}/>
                            }
                            </div>
                            {loading ? <OverlayLoader /> : null}
                        </div>
                        <Seller session={session} itemId={id} reload={reload}/>
                    </>
                }
            </div>
    );
}
const mapStateToProps = store => {
    return {
        itemView: store.itemViewReducer,
    }
}

const mapActionToProps = {
    itemViewLoad,
}

export default connect(mapStateToProps,mapActionToProps)(PublicItem)