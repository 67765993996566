import React, { useContext, useReducer, useEffect, useState } from 'react';
import { AdminUserContext } from '../../../../../../common/context';
import moment from 'moment';
import {isDue} from '../../../../../../common/helper/utility'

export const UserList = () => {
    const value = useContext(AdminUserContext);
    const { adminUserData, fetchUserProfile, gotoItems, handleLoginAsUser, handleViewPublicProfile } = value

    return (
        <div className='main-container'>
            <div></div>
                {adminUserData === 0 ? <p style={{textAlign: 'center'}}>No user found</p> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Id</th><th>Status</th><th>Deactivated date</th><th>Email</th><th>Username</th><th>Date</th><th>Coins</th><th>Items</th><th>View</th><th>Login as User</th>
                            </tr>
                            {adminUserData.map((user, index) => {
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td >{user.pk_i_id}</td>
                                        <td className={user.b_active ? 'success-message' : 'error-message'}>{user.b_active ? 'Active' : 'Not Active'}</td>   
                                        <td >{user.dt_deactivated_date ? moment(user.dt_deactivated_date).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                                        <td className='pointer' onClick={()=> fetchUserProfile(user.pk_i_id)}>{user.s_email}</td>
                                        <td className='pointer' onClick={()=> fetchUserProfile(user.pk_i_id)}>{user.s_username}</td>
                                        <td >{moment(user.dt_reg_date).format('YYYY-MM-DD')}</td>
                                        <td className='pointer' onClick={()=> fetchUserProfile(user.pk_i_id)}>{!user.wallet ? 0 : user.wallet}</td>
                                        <td className='pointer' onClick={()=> user.items_count === 0 ? alert('No item') : gotoItems(parseInt(user.pk_i_id))}>{user.items_count}</td>
                                        <td className='pointer light-blue' onClick={()=> handleViewPublicProfile(user.pk_i_id)}>Public Profile</td>
                                        <td className='pointer light-blue' onClick={()=> handleLoginAsUser(user.pk_i_id, user.s_email, user.s_username)}><i className='fas fa-sign-in-alt'/></td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const UserModal = () => {
    const value = useContext(AdminUserContext);
    const { closeUserModal, adminUserProfileData, adminUserProfileIsLoading, adminUserProfileError, upsertUser, adminUpsertUserError, handleLoginAsUser, modalToShow, setModalToShow, handleViewPublicProfile } = value
    const [addUserMessage, setAddUserMessage] = useState(false)

    const [liveListingFree, setLiveListingFree] = useState({remaining: 0, total: 0})
    const [liveListingSubscribe, setLiveListingSubscribe] = useState({remaining: 0, total: 0})

    const liveListingHandler = () => {
        if (adminUserProfileData && adminUserProfileData.purchase_micro_service && adminUserProfileData.purchase_micro_service.length > 0) {
            let freeCountTotal = 0
            let freeCountUsed = 0
            let subscribeCountTotal = 0
            let subscribeCountUsed = 0
            adminUserProfileData.purchase_micro_service.map((item, index) => {
                if (item.type === 'live_listing') {
                    if (item.payment_method === 'free') {
                        freeCountTotal++
                        if (item && item.item_id) {
                            freeCountUsed++
                        }
                    } else {
                        const isActiveByDueDate = isDue(item.due_date)
                        if (isActiveByDueDate) {
                            subscribeCountTotal++
                            if (item && item.item_id) {
                                subscribeCountUsed++
                            }
                        }
                    }
                }
            })
            setLiveListingFree({remaining: freeCountTotal - freeCountUsed,total: freeCountTotal})
            setLiveListingSubscribe({remaining: subscribeCountTotal - subscribeCountUsed,total: subscribeCountTotal})
        }
    }

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          pk_i_id: !adminUserProfileData.pk_i_id ? null : adminUserProfileData.pk_i_id,
          dt_deactivated_date: !adminUserProfileData.dt_deactivated_date ? null : adminUserProfileData.dt_deactivated_date,
          s_name: !adminUserProfileData.s_name ? '' : adminUserProfileData.s_name,
          s_username: !adminUserProfileData.s_username ? '' : adminUserProfileData.s_username,
          s_email: !adminUserProfileData.s_email ? '' : adminUserProfileData.s_email,
          s_phone_land: !adminUserProfileData.s_phone_land ? '' : adminUserProfileData.s_phone_land,
          s_phone_mobile: !adminUserProfileData.s_phone_mobile ? '' : adminUserProfileData.s_phone_mobile,
          wallet: !adminUserProfileData.wallet ? '' : adminUserProfileData.wallet,
          b_active: !adminUserProfileData.b_active ? false : adminUserProfileData.b_active,
          s_password: '',
          confirm_password: '',
        }
    );

    useEffect(() => {
        if (adminUserProfileData && adminUserProfileData.message) {
            setAddUserMessage(adminUserProfileData.message)
        } else {
            setUserInput({
                pk_i_id: !adminUserProfileData.pk_i_id ? null : adminUserProfileData.pk_i_id,
                dt_deactivated_date: !adminUserProfileData.dt_deactivated_date ? null : adminUserProfileData.dt_deactivated_date,
                s_name: !adminUserProfileData.s_name ? '' : adminUserProfileData.s_name,
                s_username: !adminUserProfileData.s_username ? '' : adminUserProfileData.s_username,
                s_email: !adminUserProfileData.s_email ? '' : adminUserProfileData.s_email,
                s_phone_land: !adminUserProfileData.s_phone_land ? '' : adminUserProfileData.s_phone_land,
                s_phone_mobile: !adminUserProfileData.s_phone_mobile ? '' : adminUserProfileData.s_phone_mobile,
                wallet: !adminUserProfileData.wallet ? 0 : adminUserProfileData.wallet,
                b_active: !adminUserProfileData.b_active ? false : adminUserProfileData.b_active,
            });
            liveListingHandler()
        }
    }, [adminUserProfileData])

    

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;

        setUserInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        if (typeof object === 'string') {
            errorArray.push(<p key={1} style={{color: 'red'}}>{object}</p>)
        }else {
            for (var key in object) {
                if (object.hasOwnProperty(key)) {
                    errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
                }
            }
        }
        return errorArray
    }

    const renderStatusOptions = () => {
        const b_active = !adminUserProfileData.b_active ? false : adminUserProfileData.b_active

        const statusOptions = b_active ?
            <React.Fragment> 
                <option value="active">Active</option>
                <option value="not_active">Not Active</option>
            </React.Fragment> :
            <React.Fragment>
                <option value="not_active">Not Active</option>
                <option value="active">Active</option>
            </React.Fragment>
        return statusOptions
    }

    return (
        <div className='data-modal-container' style={{display: modalToShow === 'user' ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeUserModal()}>&times;</span>
                {adminUserProfileIsLoading ?  <div className="message-container"><p>Loading...</p></div> : 
                    adminUserProfileError ? <div className="message-container"><p className='error-message'>{adminUserProfileError}</p></div> :  
                        addUserMessage ? <div className="message-container"><p className='success-message'>{addUserMessage}</p></div> :  
                        <div className="container">
                            <form onSubmit={(event) => upsertUser(event, userInput)}>
                            {/* {userInput.pk_i_id ?  <div className="imgcontainer">
                                                    <img src="img_avatar2.png" alt="Avatar" className="avatar"/>
                                                  </div>
                                : <div className="imgcontainer"></div>
                            } */}
                            <div style={{marginBottom: 10, fontSize: 16}}>
                                <div><label><b>Status</b></label></div>
                                    <select id="b_active" name='b_active' value={userInput.b_active} onChange={handleChange}>
                                        {renderStatusOptions()}
                                    </select>
                            </div>
                            <label ><b>Deactivated date</b></label>
                            <input type="text" onChange={handleChange} name={'dt_deactivated_date'} value={userInput.dt_deactivated_date}/>
                            <label ><b>Name</b></label>
                            <input type="text" onChange={handleChange} name={'s_name'} value={userInput.s_name} pattern="^[a-zA-Z0-9 ]*$"/>
                            <label ><b>Username</b></label>
                            <input type="text" onChange={handleChange} name={'s_username'} value={userInput.s_username} required/>
                            <label ><b>Email</b></label>
                            <input type="email" onChange={handleChange} name={'s_email'} value={userInput.s_email} disabled={userInput.pk_i_id ? true : false} required/>
                            {!userInput.pk_i_id ? 
                                <React.Fragment>
                                    <label ><b>Password</b></label>
                                    <input type="text" onChange={handleChange} name="s_password" value={userInput.s_password} required/>
                                    <label ><b>Confirm password</b></label>
                                    <input type="text" onChange={handleChange} name="confirm_password" value={userInput.confirm_password} required/>
                                </React.Fragment>
                            : null
                            }
                            <label ><b>Phone</b></label>
                            <input type="number" onChange={handleChange} name={'s_phone_land'} value={userInput.s_phone_land} />
                            <label ><b>Mobile</b></label>
                            <input type="number" onChange={handleChange} name={'s_phone_mobile'} value={userInput.s_phone_mobile} />
                            <label ><b>Wallet</b></label>
                            <input type="number" onChange={handleChange} name={'wallet'} value={userInput.wallet} />

                            <label ><b>Live Listing</b></label>
                            <p>Free: {`${liveListingFree.remaining}/${liveListingFree.total}`}</p>
                            <p>Subscribe: {`${liveListingSubscribe.remaining}/${liveListingSubscribe.total}`}</p>
                            { adminUpsertUserError ? <div>{parseError(adminUpsertUserError)}</div>  : null }

                            {userInput.pk_i_id ? <input type="submit" value="Update user" className="sellButton pointer" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>
                                               : <input type="submit" value="Add user" className="sellButton pointer" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>

                            }
                            </form>
                            <div className='user-buttons-contaiiner'>
                                <button className="login-as-user-button" onClick={()=> handleViewPublicProfile(userInput.pk_i_id)}>Public Profile</button>
                                <button className="login-as-user-button" onClick={()=> setModalToShow('change_email')}>Change Email</button>
                                <button className="login-as-user-button" onClick={()=> handleLoginAsUser(adminUserProfileData.pk_i_id, adminUserProfileData.s_email, adminUserProfileData.s_username)}>Login as User</button>
                            </div>
                        </div>
                }    
                </div>
        </div>
    )
}

export const UserSuggestData = () => {
    const value = useContext(AdminUserContext);
    const { userSuggestData, userSuggestIsLoading, fetchUserProfile } = value

    const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

    let arrHolder = []

    userSuggestData.length === 0 ? 
        arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
                        <p style={{padding: 10}}>No results</p>
                    </div>
        ) :
    userSuggestData.map((user, index) => {
        arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: '1px solid silver',
                            borderRight: '1px solid silver',
                            borderBottom:  (user.length -1) === index ? 0 : '1px solid silver',
                            backgroundColor: 'white',
                            height: 40
                        }}>
                            <button onClick={() => fetchUserProfile(user.pk_i_id)} style={{border: 'none'}}>{user.s_username}</button>
                        </div>
        )
        return null
    })
    return userSuggestIsLoading ? arrLoader : arrHolder
}

export const UserSearchResult = () => {
    const value = useContext(AdminUserContext);
    const { userSuggestData, fetchUserProfile, gotoItems, setModalToShow, modalToShow, handleViewPublicProfile } = value

    const userSelect = (id) => {
        setModalToShow(null)
        fetchUserProfile(id)
    }

    return (
        <div className='data-modal-container' style={{display: modalToShow === 'search' ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => setModalToShow(false)}>&times;</span>
                    <div className="modal-purchase-container">
                        <h2>Search Results</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Status</th><th>Email</th><th>Username</th><th>Name</th><th>Date</th><th>Coins</th><th>Items</th><th>Update date</th><th>View</th>
                                </tr>
                                {userSuggestData.map((user, index) => {
                                    return (
                                        <tr className='tr-data-container' key={index}>
                                            <td className={user.b_active ? 'success-message ' : 'error-message'}>{user.b_active ? 'Active' : 'Not Active'}</td>   
                                            <td className='pointer' onClick={()=> userSelect(user.pk_i_id)}>{user.s_email}</td>
                                            <td className='pointer' onClick={()=> userSelect(user.pk_i_id)}>{user.s_username}</td>
                                            <td className='pointer' onClick={()=> userSelect(user.pk_i_id)}>{user.s_name}</td>
                                            <td >{moment(user.dt_reg_date).format('YYYY-MM-DD')}</td>
                                            <td >{!user.wallet ? 0 : user.wallet}</td>
                                            <td className='pointer' onClick={()=> user.items_count === 0 ? alert('No item') : gotoItems(user.pk_i_id)}>{user.items_count}</td>
                                            <td >{moment(user.dt_mod_date).format('YYYY-MM-DD')}</td>
                                            <td className='pointer light-blue' onClick={()=> handleViewPublicProfile(user.pk_i_id)}>Public Profile</td>
                                        </tr>
                                    )                        
                                })}
                            </tbody>    
                        </table>
                    </div>
            </div>
        </div>        
    )
}

export const ChangeEmailModal = () => {
    const value = useContext(AdminUserContext);
    const { closeUserModal, adminUserProfileData, adminUserProfileIsLoading, adminUserProfileError, adminUpsertUserError,
        modalToShow, changeEmail, changeUserEmailIsLoading, changeUserEmailData, changeUserEmailError} = value
    const [addUserMessage, setAddUserMessage] = useState(false)

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          pk_i_id: !adminUserProfileData.pk_i_id ? null : adminUserProfileData.pk_i_id,
          s_username: !adminUserProfileData.s_username ? '' : adminUserProfileData.s_username,
          s_email: !adminUserProfileData.s_email ? '' : adminUserProfileData.s_email,
        }
    );

    useEffect(() => {
        if (adminUserProfileData && adminUserProfileData.message) {
            setAddUserMessage(adminUserProfileData.message)
        }
        else {
            setUserInput({
                pk_i_id: !adminUserProfileData.pk_i_id ? null : adminUserProfileData.pk_i_id,
                s_username: !adminUserProfileData.s_username ? '' : adminUserProfileData.s_username,
                s_email: !adminUserProfileData.s_email ? '' : adminUserProfileData.s_email,
            });
        }
    }, [adminUserProfileData])

    
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;

        setUserInput({
            [name]: newValue
        });
    }

    const parseError = (object) => {
        let errorArray = []
        if(typeof object === 'string'){
            errorArray = <p className='error-message'>{object}</p>
        }else {
            for (var key in object) {
                if (object.hasOwnProperty(key)) {
                    errorArray.push(<p key={key} className='error-message'>{object[key].toString().replace('data.', '')}</p>)
                }
            }
        }
        return errorArray
    }

    return (
        <div className='data-modal-container' style={{display: modalToShow === 'change_email' ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeUserModal()}>&times;</span>
                {adminUserProfileIsLoading || changeUserEmailIsLoading ?  <div className="message-container"><p>Loading...</p></div> : 
                    adminUserProfileError ? <div className="message-container"><p className='error-message'>{adminUserProfileError}</p></div> : 
                        addUserMessage ? <div className="message-container"><p className='success-message'>{addUserMessage}</p></div> :  
                        changeUserEmailData ? <div className="message-container"><p className='success-message'>{changeUserEmailData}</p></div> :  

                        <div className="container">
                            <form onSubmit={(event) => changeEmail(event, userInput)}>
                            <label ><b>Username</b></label>
                            <input type="text" onChange={handleChange} name={'s_username'} value={userInput.s_username} disabled={true}/>
                            <label ><b>Email</b></label>
                            <input type="email" onChange={handleChange} name={'s_email'} value={userInput.s_email} required/>

                            { adminUpsertUserError ? <div>{parseError(adminUpsertUserError)}</div>  : null }
                            { changeUserEmailError ? <div>{parseError(changeUserEmailError)}</div>  : null }

                            <input type="submit" value="Update Email" className="sellButton pointer" style={{margin: '10px 0px', alignSelf: 'flex-start'}}/>

                            </form>
                        </div>
                }    
                </div>
        </div>
    )
}


