import React, {useContext} from 'react';
import moment from 'moment';
import {CoinContext} from '../../wallet';
import {CoinsIcon} from '../../../../../assets/svg'

export const MobilePurchaseHistoryTable = ({history}) => {

    return (
        <div>
            {
                history.map((boost, i) => {
                    const credit = boost && boost.service && boost.service.credit ? boost.service.credit : 0
                    const amount = boost && boost.service && boost.service.sg_price ? boost.service.sg_price : 0
                    const transaction_id = boost && boost.transaction_id ? boost.transaction_id : null
                    const date = boost && boost.created_at ? moment(boost.created_at).format('MM/DD/YYYY HH:mm') : null
                    return (
                        <div key={i} className="flex flex-d-column purchase-wallet-mobile-container">
                            <div className="flex flex-d-row flex-justify-between mb-20">
                                <div className="flex flex-d-column">
                                    <p className="wallet-purchase-history-mobile-label-p">{`Coins`}</p>
                                    <p className="wallet-purchase-history-mobile-credit-p">{`${credit} coins`}</p>
                                </div>
                                <div className="flex flex-d-column text-align-end">
                                    <p className="wallet-purchase-history-mobile-label-p">{`Amount`}</p>
                                    <p className="wallet-purchase-history-mobile-amount-p">{`S$ ${amount}`}</p>
                                </div>
                            </div>
                            <div className="flex flex-d-row flex-justify-between">
                                <div className="flex flex-d-column">
                                    <p className="wallet-purchase-history-mobile-label-p">{`Transaction No.`}</p>
                                    <p className="wallet-purchase-history-transaction-mobile-p">{transaction_id}</p>
                                </div>
                                <div className="flex flex-d-column text-align-end">
                                    <p className="wallet-purchase-history-mobile-label-p">{`Date`}</p>
                                    <p className="wallet-purchase-mobile-history-date-p">{date}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const PurchaseTable = () => {
    const value = useContext(CoinContext)
    const {userPurchases} = value
    const historyData = userPurchases && userPurchases.data ? userPurchases.data : []

    const tableHeader = [
        {label: 'Coins'},
        {label: 'Amount'},
        {label: 'Date'},
        {label: 'Transaction No.'},
    ]

    return (
        <div className="wallet-purchase-history-table-container">
            {
                window.innerWidth < 600 ? <MobilePurchaseHistoryTable history={historyData} /> :
                <table className="wallet-purchase-table-main-container">
                <thead className="wallet-purchase-table-head-container">
                    <tr>
                        {
                            tableHeader.map((head, index) => {
                                return (
                                    <th key={index} className="wallet-th-table">
                                        <p>{head.label}</p>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody className="wallet-purchase-tbody-container">
                    {
                        historyData.map((data, index) => {
                            const coins = data && data.service && data.service.credit ? data.service.credit : null
                            const date = data && data.created_at ? moment(data.created_at).format('MM/DD/YYYY HH:mm') : null
                            const transaction_id = data && data.transaction_id ? data.transaction_id : null
                            const amount = data && data.service && data.service.sg_price ? data.service.sg_price : null
                            return (
                                <tr key={index} className="wallet-tr-table">
                                    <td className="wallet-td-table">{`${coins} coins`}</td>
                                    <td className="wallet-td-table">{`S$ ${amount}`}</td>
                                    <td className="wallet-td-table">{date}</td>
                                    <td className="wallet-td-table">{transaction_id}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            }
        </div>
    )
}

export const PurchaseCard = ({boost}) => {
    const value = useContext(CoinContext)
    const {getMoreCoinsInstantHandler} = value
    const credit = boost && boost.credit ? boost.credit : 0
    const price = boost && boost.sg_price ? boost.sg_price : 0
    return (
        <div className="wallet-purchase-container-card">
            <div className="flex flex-d-row flex-align-center">
                <CoinsIcon />
                <p className="wallet-purchase-credit-p">{credit}</p>
            </div>
            <div className="default-btn bg-orange mt-50 custom-buy-wallet-btn" onClick={() => getMoreCoinsInstantHandler(boost)}>
                <p className="text-white">{`S$ ${price}`}</p>
            </div>
        </div>
    )
}