import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminLoginSuccess, adminLoginFailed, 
    adminUserSuccess, adminUserFailed, adminUserProfileSuccess, adminUserProfileFailed, adminUpsertUserSuccess, adminUpsertUserFailed, 
    adminUserSuggestSuccess, adminUserSuggestFailed, adminSessionSuccess, adminSessionFail, administratorLogoutFail, adminChangeUserEmailSuccess, adminChangeUserEmailFailed
} from "../../action";
import admin from '../../../graphql/admin/user'

import { saveLocalStorage, getLocalStorage, removeLocalStorage } from '../../../common/helper/utility'
import { theme } from '../../../common/theme'

//login
function* adminLogin(action) {
    const { payload } = action
    try{
        const result = yield admin.adminLogin(payload)
        if(result === 403) {
            yield put(adminLoginFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.adminLogin.admin ? result.data.adminLogin.admin : null
            const id = result.data.adminLogin.admin.pk_i_id ? result.data.adminLogin.admin.pk_i_id : null
            const access_token = result.data.adminLogin.access_token ? result.data.adminLogin.access_token : null
            // const name = result.data.adminLogin.admin.s_username ? result.data.adminLogin.admin.s_username : null
            const fkv2_session = {"access_token": access_token, "type": "admin", 'id': id}

            yield saveLocalStorage("fkv2_session", fkv2_session)
            yield put(adminLoginSuccess(data));
            yield window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard`)
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', e)
        yield put(adminLoginFailed(errorMessage))
    }
}

export function* watchAdminLogin() {
    yield takeLatest(ADMIN.LOGIN.LOAD, adminLogin);
}

//admin session
function* sessionAdminSaga() {
    const session = getLocalStorage('fkv2_session')
    const { id, type} = session
    try {
            const result = yield admin.getSession(id, type)
            if(result === 403) {
                yield put(adminSessionFail('Something went wrong try again!!!'))
            }
            else{
                const data = result && result.data && result.data.getAdminSession ? result.data.getAdminSession : {}
                yield put(adminSessionSuccess(data))
            }
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        yield put(adminSessionFail(errorMessage))
        if (errorMessage === 'Unauthenticated.') {
                removeLocalStorage('fkv2_session')
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin`);  
        } else{
            yield put(adminSessionFail(errorMessage))
        }
    }
}

export function* watchAdminSessionSaga(){
    yield takeLatest(ADMIN.SESSION_ADMIN.LOAD, sessionAdminSaga)
}

//USERS
//fetch users
function* adminUser(action) {
    const { payload } = action
    try{
        const result = yield admin.getUsers(payload)
        if(result === 403) {
            yield put(adminUserFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.getAllUsers ? result.data.getAllUsers : null
            yield put(adminUserSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', e)
        yield put(adminUserFailed(errorMessage))
    }
}

export function* watchAdminUser() {
    yield takeLatest(ADMIN.USER.LOAD, adminUser);
}

//fetch user profile
function* adminGetUserProfile(action) {
    const { payload } = action
    try{
        const result = yield admin.getUser(parseInt( payload.pk_i_id))
        if(result === 403) {
            yield put(adminUserProfileFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getUserById ? result.data.getUserById : {}
            yield put(adminUserProfileSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('saga e', e)
        yield put(adminUserProfileFailed(errorMessage))
    }
}

export function* watchAdminGetUserProfile() {
    yield takeLatest(ADMIN.USER_PROFILE.LOAD, adminGetUserProfile);
}

//upsert user
function* adminUpsertUser(action) {
    const { payload } = action
    try{
        const result = yield admin.upsertUser(payload)
        if(result === 403) {
            yield put(adminUpsertUserFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.userAdminUpdate && result.data.userAdminUpdate ? result.data.userAdminUpdate : 
                result.data && result.data.userAdminRegister ? result.data.userAdminRegister : {}
            yield put(adminUpsertUserSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('adminUpsertUser e', JSON.stringify(e))
        yield put(adminUpsertUserFailed(errorMessage))
    }
}

export function* watchAdminUpsertUser() {
    yield takeLatest(ADMIN.USER_UPSERT.LOAD, adminUpsertUser);
}

//suggest user
function* adminSuggestUser(action) {
    const { payload } = action
    try{
        const result = yield admin.searchUsersForSuggest(payload)
        if(result === 403) {
            yield put(adminUserSuggestFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.publicUsersForSuggest ? result.data.publicUsersForSuggest : 
            result.data.getUsersForSuggest ? result.data.getUsersForSuggest : []
            yield put(adminUserSuggestSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(adminUserSuggestFailed(errorMessage))
    }
}

export function* watchAdminSuggestUser() {
    yield takeLatest(ADMIN.USER_SUGGEST.LOAD, adminSuggestUser);
}

//logout
function* adminLogout() {
    try {
        const result = yield admin.adminLogout()

        if(result === 403) {
            yield put(administratorLogoutFail('Something went wrong try again!!!'))
        }
        else{
            removeLocalStorage('fkv2_session')
            window.location.replace(`${process.env.REACT_APP_BASE_URL}admin`);  
        }
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        if (errorMessage === 'Unauthenticated.') {
                removeLocalStorage('fkv2_session')
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin`);  
        } else{
            yield put(administratorLogoutFail(errorMessage))
        }
    }
}

export function* watchAdminLogout() {
    yield takeLatest(ADMIN.LOGOUT.LOAD, adminLogout);
}

//change user email
function* adminChangeUserEmail(action) {
    const { payload } = action
    try{
        const result = yield admin.changeUserEmail(payload)
        if(result === 403) {
            yield put(adminChangeUserEmailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.changeUserEmail.message ? result.data.changeUserEmail.message : null
            yield put(adminChangeUserEmailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('adminChangeUserEmail saga error', JSON.stringify(e))
        yield put(adminChangeUserEmailFailed(errorMessage))
    }
}

export function* watchAdminChangeUserEmail() {
    yield takeLatest(ADMIN.CHANGE_USER_EMAIL.LOAD, adminChangeUserEmail);
}