import React, {useContext} from 'react'
import { ItemNewContext } from '../../../../common/context'
import Dropzone from 'react-dropzone-uploader'
import DragableLayout from './DragableLayout'
import RECAPTCHA from 'react-google-recaptcha'
import { numberWithCommas } from '../../../../common/helper/utility'
import Category from '../../../../common/component/category'
import { theme } from '../../../../common/theme'
import { AddImageIcon, PremiumBoostIcon, MagnifyingGlassIcon, HighlightBoostIllustrateIcon, CoinsGrayIcon, ItemNewCoinsIcon, LocationIcon, CloseIconWithGrayBG, AddImageBtnGrayBg , MoneyIcon, InfoIcon, AddCircleIcon,CloseCircleIcon, CheckCircleIcon, TrashIconRedBG} from '../../../../assets/svg'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const {REACT_APP_GOOGLE_API_KEY, REACT_APP_ENV} = process.env

export const SubmitBtn = () => {
    return (
      <div key={'dp'} className="attached-upload-container">
        <AddImageBtnGrayBg />
      </div>
    )
  }
 export const UploadComponent = () => {
    return (
      <div key={'dp'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{ paddingLeft: '17px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className="select-photos-btn">{`Select Photos`}</div>
          <p className="desktop-label">{`or drag photos here`}</p>
          <p className="desktop-label">{`Up to 4 images`}</p>
        </div>
      </div>
    )
  }

export const ImageUploader = () => {
    const itemNewContext = useContext(ItemNewContext)
    const {imageData, setImageData, handleChangeStatus} = itemNewContext
    const renderPreview = (e) => {
        const { input, files } = e
        const maxFiles = 4
        return <div className={`dropzone-main-body-container ${imageData.length === 4 ? `custom-mb-30` : ``}`}>
          <DragableLayout imageData={imageData} setImageData={setImageData} handleChangeStatus={handleChangeStatus}/>
          <div>
            {imageData.length < maxFiles && input}
          </div>
        </div>
      }
    return (
        <>
            <div style={{ height: '100%'}}>
                <div className={imageData.length === 0 ? `item-sub-container` : `item-sub-container-with-images`}>
                    <div className={`${imageData.length === 0 ? `upload-pic-container upload-component-center` : ``}`}>
                        <div className={imageData.length === 0 ? `upload-image-label-container` : `upload-image-label-with-images`}>
                        {imageData.length === 0 ? null :
                            <div className="tips-posting-container">
                            <p>{`Tips : Re-arrange photos to change cover`}</p>
                            </div>}
                        {imageData.length === 0 ? <AddImageIcon /> : null}
                        <Dropzone
                            onChangeStatus={handleChangeStatus}
                            accept="image/*"
                            maxFiles={imageData.length === 0 ? 4 : 2^53 - 1}
                            chunking={true}
                            inputWithFilesContent={<SubmitBtn />}
                            inputContent={<UploadComponent key={'dropzone-key'} />}
                            LayoutComponent={imageData.length > 0 ? (e) => renderPreview(e) : null}
                            canRemove={true}
                            classNames={{ inputLabelWithFiles: 'select-photos-with-files', preview: 'previewCont' }}
                            required />
                        {
                            imageData.length === 0 || imageData.length === 4 ? null :
                            
                            <div style={{ textAlign: 'center', marginTop: '30.25px', marginBottom: 20 }}>
                                <p className="desktop-label">{`or drag photos here`}</p>
                                <p className="desktop-label">{`Up to 4 images`}</p>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const TitleBoostContainer = ({boost}) => {
    const itemNewContext = useContext(ItemNewContext)
    const {sessionData, userInput, dPremiumId} = itemNewContext
    return (
        <>
            <div className="boost-details">
                <div className={`boost-title ${ userInput.highlightTotal === 0 ? sessionData.wallet < dPremiumId[0].credit ? `text-gray` : `` : parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < sessionData.wallet ? `` : `text-gray`}`}>
                    <span>{boost}</span>
                    <div className="info-container">
                        <span className="boost-title-info-icon"><InfoIcon /></span>
                        <div className="tooltip-container">
                            <p>{`Note: Refunds will not be provided for any services that have been purchased.`}</p>
                        </div>
                    </div>
                </div>
                <span className={`boost-desc ${userInput.highlightTotal === 0 ? sessionData.wallet < dPremiumId[0].credit ? `text-gray` : `` : parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < sessionData.wallet ? `` : `text-gray`}`}>{boost.includes('Premium') ? `Make item on premium` : `Make item on highlighted`}</span>
            </div>
            <div className={`boost-illustrate-icon ${userInput.highlightTotal === 0 ? sessionData.wallet < dPremiumId[0].credit ? `opacity-p-5` : `` : parseInt(userInput.highlightTotal) + parseInt(dPremiumId[0].credit) < sessionData.wallet ? `` : `opacity-p-5`}`}>
                {boost.includes('Premium') ? <PremiumBoostIcon /> : <HighlightBoostIllustrateIcon /> }
            </div>
        </>
    )
}

export const BoostContainer = () => {
    const itemNewContext = useContext(ItemNewContext)
    const {sessionData, servicesData, setIsCoinsClick, isCoinsClick, userInput, premiumClick, handlePremiumClick, dPremiumId, dHighlightId, highlightClick, handleHighlightClick} = itemNewContext

    return (
        <>
            <div className={`item-new-coins-balance-container ${sessionData.wallet < dHighlightId[0].credit || sessionData.wallet < dPremiumId[0].credit ? `border-red` : ``}`}>
                <p className="item-new-coins-label"><MoneyIcon /> &nbsp;{sessionData.wallet ? numberWithCommas((sessionData.wallet).toFixed(0)) : 0}</p>
                <div className="item-new-user-coin" onClick={() => setIsCoinsClick(!isCoinsClick)}>
                    {/* <ItemNewCoinsIcon /> &nbsp; */}
                    <p className="item-new-promote-coin-text">{`${ sessionData.wallet < dHighlightId[0].credit || sessionData.wallet < dPremiumId[0].credit ? `Get` : `Add`} Coins`}</p>
                </div>
            </div>
            <div className={`item-new-promote-container ${sessionData.wallet < dHighlightId[0].credit || sessionData.wallet < dPremiumId[0].credit ? `opacity-p-5` : ``}`}>
                <div className="item-new-promote-label-container">
                    <p>{`Base Ads`}</p>
                </div>
                <div className="item-new-promote-sub-container">
                    <div className={`boost-main-container ${userInput.boost !== 'premium' ? `boost-premium` : `boost-premium-active`} `}>
                        <div className="boost-container">
                            <input type="radio" name="boost" checked={premiumClick} onClick={() => 
                            // clickable logic 
                                userInput.boostTotal === 0 ? 
                                sessionData.wallet < dPremiumId[0].credit ? 
                                null : handlePremiumClick() : 
                                parseInt(userInput.boostTotal) + parseInt(dPremiumId[0].credit) < sessionData.wallet ? 
                                handlePremiumClick() : null
                                } value="premium" 
                                className={`item-condition-radios radio custom-radio-ui 
                                ${userInput.boostTotal === 0 ? 
                                sessionData.wallet < dPremiumId[0].credit ? `custom-radio-ui-inactive` : ``:
                                parseInt(userInput.boostTotal) + parseInt(dHighlightId[0].credit) < sessionData.wallet ? `` : `custom-radio-ui-inactive`
                            }`}  />
                            <TitleBoostContainer boost='Premium'/>
                        </div>
                        <PremiumBoostOptions b={'Premium'}/>
                    </div>
                    <div className={`boost-main-container ${highlightClick ? `boost-highlight-active` : ``} `}>
                        <div className="boost-container">
                            <input type="radio" name="highlight" checked={highlightClick} onClick={() => 
                            // clickable logic 
                                userInput.highlightTotal === 0 ? 
                                sessionData.wallet < dHighlightId[0].credit ? 
                                null : handleHighlightClick() : 
                                parseInt(userInput.highlightTotal) + parseInt(dHighlightId[0].credit) < sessionData.wallet ? 
                                handleHighlightClick() : null
                                } value="highlight" 
                                className={`item-condition-radios radio custom-radio-ui 
                                ${userInput.highlightTotal === 0 ? 
                                sessionData.wallet < dHighlightId[0].credit ? `custom-radio-ui-inactive` : ``:
                                parseInt(userInput.highlightTotal) + parseInt(dHighlightId[0].credit) < sessionData.wallet ? `` : `custom-radio-ui-inactive`
                            }`}  />
                            <TitleBoostContainer boost='Highlight'/>
                        </div>
                        <PremiumBoostOptions b={'Highlight'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export const PremiumBoostOptions = ({b}) => {
    const itemNewContext = useContext(ItemNewContext)
    const {userInput, sessionData, handleChange, servicesData, premiumClick, handlePremiumClick, dPremiumId, dHighlightId, handleTotal, highlightClick} = itemNewContext
    const selectedBoost = b.includes('Premium') ? 'top_ad' : 'highlight'
    const click = selectedBoost.includes('top_ad') ? premiumClick : highlightClick

    return (
        <div>
            {
                click ?
                servicesData.map((boost, index) => {
                    const credit = boost.credit ? boost.credit : 0
                    const isCheck = selectedBoost.includes('top_ad') ? userInput.subBoost !== 0 && userInput.subBoost === boost.id ? true : false : userInput.subHighlight !== 0 && userInput.subHighlight === boost.id ? true : false

                    return (
                        boost.type !== selectedBoost ? null :
                        <div className="subBoost-container" key={index}>
                            
                            <div className="boost-container ">
                            <input type="radio" name={selectedBoost.includes('top_ad') ? `subBoost` : `subHighlight`} value={boost.id} checked={isCheck} onClick={() => 
                                handleTotal(selectedBoost.includes('top_ad') ? 'premium' : 'highlight', credit, boost)
                            } 
                            className={`item-condition-radios radio custom-radio-ui 
                            ${selectedBoost.includes('top_ad') ? userInput.boostTotal === 0 ? 
                                userInput.subBoost !== boost.id ? 
                                `custom-radio-border-gray ${credit > sessionData.wallet ? `custom-radio-border-gray cursor-disabled` : `custom-radio-border-gray`}` : credit > sessionData.wallet ? `custom-radio-border-gray cursor-disabled` : `` :parseInt(userInput.boostTotal) + parseInt(credit) < sessionData.wallet ? userInput.subBoost !== boost.id ? `custom-radio-border-gray` : `` : `custom-radio-border-gray cursor-disabled` : 
                                userInput.highlightTotal === 0 ? 
                                userInput.subHighlight !== boost.id ?
                                `custom-radio-border-gray ${credit > sessionData.wallet ? `custom-radio-border-gray cursor-disabled` : `custom-radio-border-gray`}` : credit > sessionData.wallet ? `custom-radio-border-gray cursor-disabled` : `` :parseInt(userInput.highlightTotal) + parseInt(credit) < sessionData.wallet ? userInput.subHighlight !== boost.id ? `custom-radio-border-gray` : `` : `custom-radio-border-gray cursor-disabled`
                            }`} 
                            />
                            <div className="boost-details">
                                <span className={`subBoost-title 
                                ${selectedBoost.includes('top_ad') ? userInput.subBoost === boost.id ? `text-black` : `subBoost-text-gray` : userInput.subHighlight === boost.id ? `text-black` : `subBoost-text-gray`}`}>{boost.name ? boost.name : ''}</span>
                                <span className={`subBoost-desc 
                                ${selectedBoost.includes('top_ad') ? userInput.subBoost === boost.id ? `text-black` : `subBoost-text-gray` : userInput.subHighlight === boost.id ? `text-black` : `subBoost-text-gray`}`}>{boost.description ? boost.description : ''}</span>
                            </div> </div>
                            <div className="boost-credit-container">
                            {
                                selectedBoost.includes('top_ad') ?  userInput.subBoost === boost.id ? <ItemNewCoinsIcon /> : <CoinsGrayIcon /> : userInput.subHighlight === boost.id ? <ItemNewCoinsIcon /> : <CoinsGrayIcon />
                            } &nbsp;
                        <p className={`boost-credit-int 
                        ${selectedBoost.includes('top_ad') ?  userInput.subBoost === boost.id ? `boost-text-orange`: `subBoost-text-gray` : userInput.subHighlight === boost.id ? `boost-text-orange`: `subBoost-text-gray`}`}
                        >{credit}</p>
                            </div>
                        </div>
                    )
                }) : null
            }
        </div>
    )
}

export const PostTypeFields = ({radioname, name, subname, value, radioClass, nameClass, subnameClass, onChangeFunc, selectedOptions}) => {
    return (
        <div className={`flex ${window.innerWidth < 600 ? `flex-d-column` : `flex-align-center flex-d-row mr-33`}`}>
            <input type="radio" name={radioname} value={value} className={radioClass} onChange={(e) => onChangeFunc(e)} checked={selectedOptions === value ? true : false}/>
            <div className={`flex flex-d-column `}>
              <p className={nameClass}>{name}</p>
              <p className={subnameClass}>{subname}</p>
            </div>
        </div>
    )
}

export const PostTypeContainer = () => { 
    const itemNewContext = useContext(ItemNewContext)
    const {imageData, postTypeArr, handleChange, userInput} = itemNewContext
    return (

            imageData.length !== 0 ?
            <div className="flex flex-d-row mb-11p5">
            {
            postTypeArr.map((post, i) => {
                return (
                <PostTypeFields radioname="post_type" name={post.label} nameClass="item-new-post-type-label-p" subname={post.sublabel} subnameClass="item-new-post-type-sub-label-p" value={post.value} radioClass="item-condition-radios radio w-custom-radio-ui" key={i} onChangeFunc={handleChange} selectedOptions={userInput.post_type}/>
                )
            })
            }
            </div> : null
    )
}

export const Meetup = (props) => {
    const itemNewContext = useContext(ItemNewContext)
    const {handleChange, userInput, isMeetupAddNotes2, setIsMeetupAddNotes2, isMeetupAddNotes, removeAddress, handleMeetupAddNote} = itemNewContext
    const {arr, add, inputValue, inputNotesValue} = props
    const id = arr && arr.id ? arr.id : 's_dm_meetup_add1'
    const t = arr && arr.notes ? arr.notes : []

    return (
        inputValue ?
        <>
            <div className="meetup-address-container">
                <div className="meetup-icon-container">
                    <LocationIcon />
                </div>
                <div className="meetup-p-container">
                    <p className="meetup-address-p">{inputValue}</p>
                </div>
                <div onClick={() => removeAddress('address1')} className="meetup-close-container">
                    <CloseIconWithGrayBG />
                </div>
            </div> 
            {
            !isMeetupAddNotes ?
            <div className="flex flex-d-column add-notes-container-with-textarea">
            <p className="w-100">{`Notes:`}</p>
            <div className="add-notes-textarea-container w-100">
                <textarea autoComplete="nope" id={'meetupNotes1'} placeholder="Details make life easier, Tell some more ... " name={t.name} maxLength={200} value={inputNotesValue} onChange={handleChange} rows="3"/>
                <span className="textarea-counter">{`${inputNotesValue.length}/200`}</span>
            </div>
            <div className="w-100 flex flex-d-row add-notes-button-group">
                {
                inputNotesValue.length == 0 ? null :
                <div onClick={() => handleMeetupAddNote(add)} className='mb-11'>
                    <TrashIconRedBG />
                </div>
                }
                
            </div>
            </div> : null
            }
        </> : null
    )
}

export const InputFields = () => {
    const itemNewContext = useContext(ItemNewContext)
    const {handleChange, userInput, onKeyDownInput, handleDesc, submitted, color,desc, handleShippedClick, meetupClicked, handleChangeMap, imageData, setModalPops, handleSubmit, captchaChange} = itemNewContext
    const {itemNewForm} = theme.text
    const raw_address = userInput.raw_address

    return (
        itemNewForm.map((form,i) => {
            if(userInput.item_condition){
                if(form.type === 'text' && form.id === 'title' && userInput.item_condition){
                    return (
                        <div className="item-new-formGroup" key={i}>
                            <label className="item-new-label">{form.label}<span className="text-red">*</span></label>
                            <input className="item-new-input" autoComplete="off" placeholder={form.placeholder ? form.placeholder : ''} type={form.type} name={'s_title'} value={userInput.s_title} onChange={handleChange} required></input>
                            <p style={{ color: '#c0392b' }}>{userInput.error.name ? userInput.error.name : null}</p>
                        </div>
                    )
                }else if(form.type === 'number' && form.id === 'price' && userInput.item_condition){
                    return (
                        <div className="item-new-formGroup" key={i}>
                            <label className="item-new-label">{form.label}<span className="text-red">*</span></label>
                            <div className={userInput.error !== 'Invalid' ? `item-new-price` : `item-new-error`}>
                                <span className="item-new-currency">{form.prefixText}</span>
                                <input className="item-new-priceInput" type={form.type} name="i_price" placeholder={form.placeholder ? form.placeholder : ''} pattern="/^[0-9\b]+$/" value={userInput.i_price} onKeyDown={onKeyDownInput} onKeyPress={handleChange} onChange={handleChange} required></input>
                            </div>
                            <p style={{ color: '#c0392b' }}>{userInput.error.price ? userInput.error.price : null}</p>
                            <p className="tip-p-label">Tip: Putting “<span>0</span>” in the price will show “<span>Check with seller</span>” for buyer.</p>
                      </div>
                    )
                }else if(form.type === 'textarea' && form.id === 'description'){
                    return (
                        <div className="item-new-formGroup" key={i}>
                            <label className="item-new-label">{`Description`}<span className="text-red">*</span></label>
                            <div className={`item-new-textarea-container `} onClick={handleDesc}>
                                <div className={`item-textarea-container ${desc ? color === 'red' ? submitted ? `border-red` : null : color === 'yellow' ? 'border-yellow' : color === 'green' ? 'border-green' : 'border-default' : 'border-default'}`} >
                                <textarea autoComplete="nope" rows="3" placeholder="Listing item details" name={'s_description'} className="item-textarea" value={userInput.s_description} onChange={handleChange} required />
                                </div>
                                {desc ?
                                color === 'red' ? submitted ? <p className="item-new-text item-new-text-red">{`Description should at least be 10 characters.`}</p> : null :
                                    color === 'yellow' ? <p className="item-new-text item-new-text-yellow">{`Describe more to make people find you.`}</p> :
                                    color === 'green' ? <p className="item-new-text item-new-text-green">{`Good to go!`}</p> : null : null
                                }
                                <p style={{ color: '#c0392b' }}>{userInput.error.description ? userInput.error.description : null}</p>
                            </div>
                        </div>
                    )
                }else if(form.type === 'choices' && form.id === 'deal_method'){
                    return (
                        <div className="item-new-formGroup" key={i}>
                            <label className="item-new-label">{form.label}</label>
                            <div className="item-new-deal-main-container">
                                {
                                    form.choices && form.choices.map((deal, i) => {
                                        return (
                                            <div key={i}>
                                                <div className={`${deal.id === 'meet_up' ? 'item-new-meetup-container' : 'item-new-shipped-container'}  mb-12`} onClick={() => handleShippedClick(deal.id)}>
                                                    <input type="radio" name={deal.name} checked={meetupClicked && meetupClicked.includes(deal.id) ? true : false} value={deal.id} className="item-condition-radios radio custom-radio-ui "/>
                                                    <span className="item-new-meetup-label">{deal.label}</span>
                                                </div>
                                                {
                                                    meetupClicked && meetupClicked.includes(deal.id) ?
                                                        deal.id === 'meet_up' ?
                                                            <div className="g-map-container" key={i}>
                                                                <div className="meetup-main-container">
                                                                    {
                                                                        deal.fields.map((f, i) => {
                                                                            if(f.type === 'location'){
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <Meetup index={i} arr={f} add={`ad${i}`} inputValue={i === 1 ? userInput.s_dm_meetup_add2 : userInput.s_dm_meetup_add1} inputNotesValue={i === 1 ? userInput.meetupAddnotesAddress2 : userInput.meetupAddnotesAddress1}/>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    {
                                                                        userInput.s_dm_meetup_add1 && !userInput.s_dm_meetup_add2 || !userInput.s_dm_meetup_add1 && userInput.s_dm_meetup_add2 ?
                                                                        <div className="add-notes-container-with-textarea mb-6">
                                                                            <p className="mb-0">{`Alternative venue:`}</p>
                                                                        </div> : null
                                                                    }
                                                                    {
                                                                        userInput.s_dm_meetup_add1 && userInput.s_dm_meetup_add2 ? null :
                                                                        <div className="g-map-search-bar-main-container">
                                                                            <GooglePlacesAutocomplete
                                                                                apiKey={REACT_APP_GOOGLE_API_KEY}
                                                                                className={`g-map-search-bar-container w-100 `}
                                                                                selectProps={{
                                                                                raw_address,
                                                                                onChange: handleChangeMap,
                                                                                className: `g-map-search-input w-100 ${userInput.s_dm_meetup_add1 ? `ml-36 mx-w-438` : ``}`,
                                                                                isClearable: false,
                                                                                placeholder: "Search a place or Postal code",
                                                                                components: {
                                                                                    DropdownIndicator: () =>  <div className="g-map-search-icon-container"> <MagnifyingGlassIcon /> </div>,
                                                                                    IndicatorSeparator:() => null
                                                                                }
                                                                                }}
                                                                                autocompletionRequest={{
                                                                                componentRestrictions: {
                                                                                country: ['sg'],
                                                                                }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                            </div> : 
                                                            deal.fields.map((f, i) => {
                                                                return (
                                                                    <div className="item-new-ship-container" key={i}>
                                                                        <div className="item-new-ship-sub-container">
                                                                            <textarea autoComplete="nope" name={f.name} maxLength={200} onChange={handleChange} rows="3" placeholder={f.placeholder ? f.placeholder : ''} />
                                                                            <div className="flex w-100 flex-justify-end">
                                                                            <p className="textarea-counter">{`${userInput.s_dm_shipped_details.length}/200`}</p>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                )
                                                            })
                                                         : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                imageData.length === 0 ? null :
                                <div className="item-new-formGroup">
                                    <RECAPTCHA sitekey={theme.text.sitekey} onChange={captchaChange} />
                                    {userInput.inputError ? <div style={{ display: 'flex' }}><p style={{ color: 'rgba(192, 57, 43,1.0)' }}>{userInput.inputError}</p></div> : null}
                                    {/* {publishData ? <div><p style={{ color: 'green' }}>{publishData}</p></div> : null}
                                    {publishError ? <div><p style={{ color: 'red' }}>{parsedError(publishError)}</p></div> : null}
                                    {inputError ? <div><p style={{ color: 'rgba(192, 57, 43,1.0)' }}>{inputError}</p></div> : null} */}
                                </div>
                            }
                            {
                                    imageData.length === 0 ? null :
                                    <div className="item-new-buttons-group">
                                    <div className="cancel-btn" onClick={() => setModalPops(true)}>
                                        <p>{`Cancel`}</p>
                                    </div>
                                    {
                                        REACT_APP_ENV === 'local' ? <button className="item-new-btn btn-passed" onClick={handleSubmit}><p>List Local</p> </button> : 
                                        <button className={`item-new-btn ${userInput.error === 'Invalid' || !userInput.captcha || !userInput.s_category || !userInput.i_price || userInput.i_price === 0 || !userInput.s_title || !userInput.s_description || imageData.length === 0
                                        ? `btn-inc` : `btn-passed`}`} onClick={userInput.captcha ? handleSubmit : null} > <p>List Now</p> </button>
                                    }
                                    </div>
                                }
                        </div>
                    )
                }
            }else {
                return null
            }
            
        })
    )
}

export const ItemNewFields = () => {
    const itemNewContext = useContext(ItemNewContext)
    const {imageData, postTypeArr, handleChange, userInput, captchaChange} = itemNewContext
    const { itemNew, itemNewForm} = theme.text

    return (
        <div className='item-new-main-container'>
            <PostTypeContainer />
            <div className={imageData.length === 0 ? `hide` : `item-new-form-container`}>
                <Category value={userInput.s_category} name={'s_category'} onChange={handleChange} />
                <p style={{ color: '#c0392b' }}>{userInput.error.category ? userInput.error.category : null}</p>
                {
                    userInput.s_category ?
                    <div className="item-new-formGroup">
                        <label className="item-new-label">{`Item Condition`}<span className="text-red">*</span></label>
                        <div className="item-condition-container">
                        {
                            itemNew.map((choices, index) => {
                            return (
                                <div key={index} className={`options-${choices.value}`}>
                                <input type="radio" name={userInput.post_type === 'WTS' ? `item_condition` : `item_condition_${choices.value}`} className={`item-condition-radios radio custom-radio-ui 
                                `} value={choices.value} onClick={(e) => handleChange(e)} checked={userInput.item_condition.includes(choices.value) ? true : false}
                                />
                                <span className="condition-label">{choices.label}</span>
                            </div>
                            )
                            })
                        }
                        </div>
                        <p style={{ color: '#c0392b' }}>{userInput.error.item_condition ? userInput.error.item_condition : null}</p>
                    </div> : null
                }
                <InputFields />
            </div>
        </div>
    )
}