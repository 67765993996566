import React, {useState, useEffect, useReducer, useCallback} from 'react'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { meLoad, servicesLoad, itemUpdateLoad} from '../../../../../redux/action' 
import {CancelModal, MobileTopNav, SuccessfullyListed} from './customComponents'
import ImageContainer from './components/imageContainer'
import BoostContainer from './components/boostContainer'
import FormContainer from './components/formContainer'
import {CustomLoader} from '../../../../../common/component/loader'
// import LocationData from '../../../../../assets/data/sg.json'
import {WholePageLoader, WholePageError} from '../../../../../common/component/customComponents'

const { REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX, REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER } = process.env;

const EditIndex = (props) => {
    const history = useHistory()
    const data = props && props.data ? props.data : []
    const sessionUser = props.indexProps && props.indexProps.me ? props.indexProps.me : []
    const { oc_t_item_resource,  oc_t_user } = data
    const image = oc_t_item_resource
    const {purchases} = oc_t_user
    const {sessionData} = sessionUser
    const { servicesData } = props.purchase
    const itemUpdateMessage = props.item.updateMessage
    const itemUpdateIsLoading = props.item.updateIsLoading
    const itemUpdateError = props.item.updateError
    const itemUpdateData = props.item.data
    const has_active_subscription = sessionUser && sessionUser.data && sessionUser.data.has_active_subscription && sessionUser.data.has_active_subscription.purchase_micro_service && sessionUser.data.has_active_subscription.purchase_micro_service.subscription_status ? sessionUser.data.has_active_subscription.purchase_micro_service.subscription_status.includes('active') ? true : false : false
    // const object = Object.values(LocationData)

    const [imageData, setImageData] = useState([])
    const [modalPops, setModalPops] = useState(false)
    const [imageFiles, setImageFiles] = useState([])
    const [updateLoading, setUpdateLoading] = useState(false)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    const [locationSuggest, setLocationSuggest] = useState([])

    const [userUpdateInput, setUserUpdateInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
        item_id: '',
        i_price: '',
        s_title: '',
        s_description: '',
        fk_i_category_id: 0,
        s_category: '',
        custom_fields: {},
        youtube_link: '',
        item_condition: '',
        deal_method_meetup: '',
        deal_method_shipped: '',
        s_dm_meetup_add1: '',
        s_dm_meetup_add1_notes: '',
        s_dm_meetup_add2: '',
        s_dm_meetup_add2_notes: '',
        s_dm_shipped_details: '',
        post_type: 'WTS',
        totalLocation: 0,
          error: {
            name: '',
            price: '',
            description: '',
            image: '',
            item_condition: '',
            category: '',
            meetup: ''
          },
          raw_address: '',
          captcha: '',
          inputError: '',
        }
      );
   
    const handleChangeStatus = (imageContext, status) => {
      if (status === 'done') {
        const tempData = imageData.concat(imageContext)
        if(imageData.length < 4 ){
          setImageData(tempData)
        }
      }
      if (status === 'removed') {
          const tempData = imageData.filter((obj) => {
            if (imageContext.id) {
             return obj.id !== imageContext.id
            }  else if (imageContext.file && imageContext.file.name) {
               return obj.file.name !== imageContext.file.name
            }  
          })
          setImageData(tempData)
      }
    }

    const imageHandler = useCallback(
      (image) => {
      let arr = []

      if (image && image.length > 0) {
        image.map((img) => {
              const id = img.pk_i_id
              const ext = img.s_extension
              const path = 'uploads'
              const imgSrc = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}` : `${REACT_APP_IMGX}${id}.${ext}?w=450&h=455&fit=crop`
              const thumbImgSrc = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}?w=100&h=102` : `${REACT_APP_IMGX}${id}.${ext}?w=100&h=102&fit=crop&crop=focalpoint&fp-z=1&auto-true`
              const imgRaw = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}` : `${REACT_APP_IMGX}${id}.${ext}`

              arr.push({raw: imgRaw, original: imgSrc, thumbnail: thumbImgSrc, thumbnailClass: 'featured-thumb', id: id, ext: ext})
          })
          setImageData(arr)
      }
      return null
      }, [image]
  )

  useEffect(() => {
    imageHandler(image)
  }, [image])

  useEffect(() => {
    if(itemUpdateMessage){
      setTimeout(() => {
        setUpdateLoading(false)
        setDataSuccess(true)
      }, 2000)
    }
    if(itemUpdateError){
      setUpdateLoading(false)
      setTimeout(() => {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}user/listing`)
      }, 3000)
    }
  }, [itemUpdateMessage, itemUpdateError])

    const removeAddress = (add) => {
        if(add === 'address1'){
            setUserUpdateInput({['s_dm_meetup_add1']: '', ['s_dm_meetup_add1_notes']: '', ['totalLocation']: userUpdateInput.totalLocation - 1})
        }else{
            setUserUpdateInput({['s_dm_meetup_add2']: '', ['s_dm_meetup_add2_notes']: '', ['totalLocation']: userUpdateInput.totalLocation - 1})
        }
    } 

    const convertYoutubeLink = (url) => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      const embed = '//www.youtube.com/embed/'
      if (match && match[2].length == 11) {
      return embed + match[2];
      } else {
      return null;
      }
  }

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;

        let item_cond;

        if(evt.target.name === 'item_condition'){
          setUserUpdateInput({['item_condition']: evt.target.value});
        }
        if(evt.target.name === 'item_condition_use' || evt.target.name === 'item_condition_new'){
          if(userUpdateInput.item_condition && userUpdateInput.item_condition.includes(evt.target.value)){
            item_cond = userUpdateInput.item_condition.replaceAll(evt.target.value, '')
            setUserUpdateInput({['item_condition']: item_cond})
          }
          if(userUpdateInput.item_condition && !userUpdateInput.item_condition.includes(evt.target.value)) {
            item_cond = `${userUpdateInput.item_condition} ${evt.target.value}`
            setUserUpdateInput({['item_condition']: item_cond})
          }
          if(!userUpdateInput.item_condition) {
            setUserUpdateInput({['item_condition']: evt.target.value})
          }
        }

        if(evt.target.name === 's_category'){
            const customFieldParse = evt.target.options[evt.target.selectedIndex].dataset.cf ? JSON.parse(evt.target.options[evt.target.selectedIndex].dataset.cf) : []
      
            let cfObject = {}
            if (customFieldParse.length > 0) {
              customFieldParse.map((item) => {
                 cfObject[item.name] = {...item, value: ''}
              })
            }
            setUserUpdateInput({['custom_fields']: cfObject});
          }
        if(evt.target.name === 'youtube_link'){
          setUserUpdateInput({['youtube_link']: convertYoutubeLink(newValue)})
        }

          setUserUpdateInput({ [name]: newValue });
    }

    const handleCustomFieldChange = evt => {
      const name = evt.target.name;
      const newValue = evt.target.value;
      const cfObject = userUpdateInput.custom_fields
      let finalObj = {}
      for (var k in cfObject) {
        if (cfObject[k].name === name) {
          finalObj[name] = {...cfObject[k], value: newValue}
        }
        else {
          finalObj[cfObject[k].name] = {...cfObject[k]}
        }
      }
      setUserUpdateInput({['custom_fields']: finalObj});
    }


    const onKeyDownInput = (event) => {
        if (event.key === 'e' || event.key === '+' || event.key === '-') {
          event.preventDefault();
        }
    }

    const captchaChange = value => {
        setUserUpdateInput({ ['captcha']: value })
        if (userUpdateInput.inputError !== '') {
            setUserUpdateInput({ ['inputError']: '' })
        }
    }

    const finalDataArr = {
        pk_i_id: userUpdateInput.item_id,
        raw_price: userUpdateInput.i_price,
        item_title: userUpdateInput.s_title,
        item_desc: userUpdateInput.s_description,
        categoryId: userUpdateInput.fk_i_category_id,
        // custom_fields: userUpdateInput.custom_fields,
        s_youtube_link: userUpdateInput.youtube_link,
        // imageData: imageFiles,
        item_condition: userUpdateInput.item_condition,
        post_type: userUpdateInput.post_type,
        deal_method: {
          add1: {
            location: userUpdateInput.s_dm_meetup_add1,
            notes: userUpdateInput.s_dm_meetup_add1_notes
          },
          add2: {
            location: userUpdateInput.s_dm_meetup_add2,
            notes: userUpdateInput.s_dm_meetup_add2_notes
          },
          shipped: userUpdateInput.s_dm_shipped_details
        }
    }

    const keepEditBtn = () => {
      if(modalPops){
        setModalPops(false)
      }
    }

    const cancelDetails = {
      title: 'Cancel Changes?',
      body: 'If you cancel now, your changes will not be saved.',
      leftBtn: {
        title: 'Cancel',
        action: ''
      },
      rightBtn: {
        title: 'Keep Editing',
        action: keepEditBtn
      }
    }
    
    const handleUpdate = evt => {
        evt.preventDefault();
        // setMainState({['submitLoading']: true})

        if(imageData.length > 0){
          const parsedImageData = parseImageForSubmit()
          setUpdateLoading(true)
          let finalObj = {}
              for (var k in userUpdateInput) {
                if (k === 'custom_fields') {
                  let finalObj2 = {}
                  for(var l in userUpdateInput[k]){
                    if (userUpdateInput[k].hasOwnProperty(l)) {
                      const value = userUpdateInput[k] && userUpdateInput[k][l] && userUpdateInput[k][l].value ? userUpdateInput[k][l].value : ''
                      const name = userUpdateInput[k] && userUpdateInput[k][l] && userUpdateInput[k][l].name ? userUpdateInput[k][l].name : ''
                      finalObj2[name] = value
                    }
                  }
                  finalObj[k] = JSON.stringify(finalObj2)
                }
              }
          setTimeout(() => {
            const dataForSubmit = {...finalDataArr, imageData: parsedImageData, custom_fields : finalObj.custom_fields}
            props.itemUpdateLoad(dataForSubmit)
          }, 2000)
        }else{
          setUserUpdateInput({error: {['image']: 'Please attached atleast one image'}})
        }
    }

    const parseImageForSubmit = () => {
      let arr = []
      imageData.map((item, index) => {
        if(item && item.raw) {
          fetch(item.raw).then(res => {
            res.arrayBuffer().then(buf => {
            const file = new File([buf], `${item.id ? `${item.id}.${item.ext}` : `${item.name}`}`, { type: 'image/*' })
            arr[index] = file
            })
          })
        }
        if (item && item.file) {
          arr[index] = item.file
        }
      })
      return arr
    }

    const handleChangeMap = (evt) => {
      const newValue = evt && evt.label ? evt.label : '';
      const name = "raw_address"

      if(!userUpdateInput.s_dm_meetup_add1) {
        setUserUpdateInput({['s_dm_meetup_add1']: newValue.toUpperCase()});
      }
      if(userUpdateInput.s_dm_meetup_add1) {
        setUserUpdateInput({['s_dm_meetup_add2']: newValue.toUpperCase()});
      }
      // setUserInput({ [name]: newValue })
      if(newValue){
        evt.label = null;
      }
      if(newValue){
        // object.map((place) => {
        //   const address = place.ADDRESS
        //   // return address.match(convertToUpperCase) ? locationSuggest.length === 3 ? setLocationSuggest([place]) : setLocationSuggest(locationSuggest.concat(place)) : null
        // })
      }else{
        setLocationSuggest([])
      }
      // const convertToUpperCase = newValue.toUpperCase()
      // setUserUpdateInput({ [name]: newValue })

      // if(userUpdateInput.raw_address.length > 0){
      //   setIsTyping(true)
      // }
      // if(newValue){
      //     object.map((place) => {
      //       const address = place.ADDRESS
      //       return address.match(convertToUpperCase) ? locationSuggest.length === 3 ? setLocationSuggest([place]) : setLocationSuggest(locationSuggest.concat(place)) : null
      //     })
      //   }else{
      //     setLocationSuggest([])
      //   }      

  }

  const saveLocationFunc = (add) => {
    setIsTyping(false)
    setUserUpdateInput({['raw_address']: ''})
    setLocationSuggest([])

    if(!userUpdateInput.s_dm_meetup_add1 && !userUpdateInput.s_dm_meetup_add2){
      setUserUpdateInput({['s_dm_meetup_add1']: add, ['totalLocation']: userUpdateInput.totalLocation + 1})
    }
    if(userUpdateInput.s_dm_meetup_add1 && !userUpdateInput.s_dm_meetup_add2){
      setUserUpdateInput({['s_dm_meetup_add1']: userUpdateInput.s_dm_meetup_add1, ['s_dm_meetup_add2']: add, ['totalLocation']: userUpdateInput.totalLocation + 1})
    }
    if(!userUpdateInput.s_dm_meetup_add1 && userUpdateInput.s_dm_meetup_add2){
      setUserUpdateInput({['s_dm_meetup_add1']: userUpdateInput.s_dm_meetup_add2, ['s_dm_meetup_add1_notes']: userUpdateInput.s_dm_meetup_add2_notes, ['s_dm_meetup_add2']: add, ['s_dm_meetup_add2_notes']: '', ['totalLocation']: userUpdateInput.totalLocation + 1})
    }
}


    useEffect(() => {
        if(servicesData.length === 0){
            props.servicesLoad(true)
        }
    },[])

    const handleModalPops = () => {
      if(!modalPops){
        setModalPops(true)
      }else{
        keepEditBtn()
      }
    }

    const MobileTopNavDetails = {
      left: {
        title: updateLoading ? '' : dataSuccess ? '' : 'Cancel',
        action: ''
      },
      center: {
        title: 'Update Listing',
        action: ''
      },
      right: {
        title: updateLoading ? '' : dataSuccess ? 'Add New' : 'Update',
        action: ''
      }
    }

    const parsedError = (error) => {
      let errorMessage
      if (error.includes('file')) {
        errorMessage = "Item posted with no image, due to error processing your picture."
      } else {
        errorMessage = error
      }
      return errorMessage
    }
    
    return (
        <>
        { 
          window.innerWidth < 600 ? <div className="h-100 w-100 over-scroll p-fixed bg-white z-ndx-5">
            <MobileTopNav details={MobileTopNavDetails} userUpdateInput={userUpdateInput} rightAction={handleUpdate} leftAction={handleModalPops} isSuccess={dataSuccess}/>
            {
              updateLoading ? <WholePageLoader /> :
              itemUpdateError ? <WholePageError text={parsedError(itemUpdateError)} /> :
              dataSuccess ? <SuccessfullyListed data={itemUpdateData} /> : 
              <>
              <ImageContainer handleChangeStatus={handleChangeStatus} setImageFiles={setImageFiles} imageFiles={imageFiles} imageData={imageData} setImageData={setImageData} />
              <FormContainer setModalPops={setModalPops} userUpdateInput={userUpdateInput} setUserUpdateInput={setUserUpdateInput} data={data} handleChange={handleChange} onKeyDownInput={onKeyDownInput} handleCustomFieldChange={handleCustomFieldChange} removeAddress={removeAddress} convertYoutubeLink={convertYoutubeLink} captchaChange={captchaChange} handleUpdate={handleUpdate} setIsTyping={setIsTyping} isTyping={isTyping} handleChangeMap={handleChangeMap} saveLocationFunc={saveLocationFunc} locationSuggest={locationSuggest} has_active_subscription={has_active_subscription}/>
              {/* <BoostContainer servicesData={servicesData} purchases={purchases} sessionData={sessionData}/> */}
              </>
            }
          </div> : 
          <div className="item-new-container">
            {
              updateLoading ? <WholePageLoader /> :
              itemUpdateError ? <WholePageError text={parsedError(itemUpdateError)} /> :
              dataSuccess ? <SuccessfullyListed data={itemUpdateData} /> : 
              <div className="item-form-container">
                <div style={{ marginBottom: 20 }}>
                  <p style={{ fontFamily: 'Metropolis-Medium', fontStyle: 'normal', fontSize: '26px', lineHeight: '26px', letterSpacing: '0.44px', fontWeight: '500', textAlign: 'left', whiteSpace: 'nowrap', color: '#232A34' }}>{`What are you listing today?`}</p>
                  <div className={`w-100 flex flex-d-row mt-20`}>
                        <div>
                            <ImageContainer handleChangeStatus={handleChangeStatus} setImageFiles={setImageFiles} imageFiles={imageFiles} imageData={imageData} setImageData={setImageData}/>
                            {userUpdateInput.error.image ? <p className="text-red">{userUpdateInput.error.image }</p> : null}
                            {/* <BoostContainer servicesData={servicesData}  purchases={purchases} sessionData={sessionData}/> */}
                        </div>
                        <div className="item-new-main-container ml-20">
                            <FormContainer setModalPops={setModalPops} userUpdateInput={userUpdateInput} setUserUpdateInput={setUserUpdateInput} data={data} handleChange={handleChange} onKeyDownInput={onKeyDownInput} handleCustomFieldChange={handleCustomFieldChange} removeAddress={removeAddress} convertYoutubeLink={convertYoutubeLink} captchaChange={captchaChange} handleUpdate={handleUpdate} setIsTyping={setIsTyping} isTyping={isTyping} handleChangeMap={handleChangeMap} saveLocationFunc={saveLocationFunc} locationSuggest={locationSuggest} has_active_subscription={has_active_subscription}/>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        }
        {modalPops ? <div className="h-100 w-100"><CancelModal alertDetails={cancelDetails} /></div>  : null}
        </>
    )
}

const mapStateToProps = store => {
    return {
      // me: store.userReducer,
      purchase: store.purchaseReducer,
      item: store.itemViewReducer
    };
};

const mapActionToProps = {
    // meLoad,
    servicesLoad,
    itemUpdateLoad
}

export default connect(mapStateToProps, mapActionToProps)(EditIndex)