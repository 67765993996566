import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../../assets/images/fk_logo.png'
import { theme } from '../../../../common/theme'

import { adminSessionLoad, administratorLogoutLoad } from '../../../../redux/action'

const NavBar = (props) => {
    const id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const { sessionData } = props.admin
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const location = useLocation()
    const path = location.pathname

    useEffect(() => {
        if(id) {
            props.adminSessionLoad()
        }
        // eslint-disable-next-line
    }, [id])

    const logoutHandler = () => {
      const alert = window.confirm("Are you sure you want to logout"); if(alert === true){ 
        props.administratorLogoutLoad()
      }
    }

    const toggleMobileMenu = () => {
      setShowMobileMenu(!showMobileMenu)
    }

    const checkIfCurrentPage = (loc) => {
      if (path === loc) {
        setShowMobileMenu(false)
      }
    }

    const renderMenu = () => {
      const menu = theme.text.adminMobileMenu.map((item, index) => {
        return (
          <span key={index}>
            <Link exact="true" to={item.path ? item.path : '/'} onClick={() => checkIfCurrentPage(item.path)}>
                <span style={{paddingLeft: 10}} >{item.label}</span>
            </Link>
          </span>
        )
      })
      return menu
  }

    return (
      <React.Fragment>
        <div className="topnav">
          <a className="admin-menu-icon" onClick={() => toggleMobileMenu()}>
            {showMobileMenu ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
          </a>
          <Link to="/admin/dashboard" className="admin-logo"><img width={200} src={logo} alt="fk logo"/></Link>
          <span className="greeting pointer" onClick={() => logoutHandler()}><i className="fas fa-sign-out-alt"></i></span>
          <span className="greeting">{`Hi ${sessionData.s_username} `}</span>
        </div>
        {/* mobile menu */}
        <div className="overlay" style={{height: showMobileMenu ? '100%' : '0%'}}>
          <div className="overlay-content">
            {renderMenu()}
          </div>
        </div>
        {/* end mobile menu */}
      </React.Fragment>

    )
}

const mapStateToProps = store => {
    return {
      admin: store.adminReducer,
    };
};

const mapActionToProps = {
    adminSessionLoad,
    administratorLogoutLoad
  };
  
export default connect(mapStateToProps,mapActionToProps)(NavBar);