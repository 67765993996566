import React, {useState, useReducer, useContext, memo, useEffect} from 'react'
import {currentDate, addDate} from '../../../common/helper/utility'
import {BoostCards, Text, RadioWithText, IconContainerClickable, Divider, PriceTextWithSymbol, Button, LinkText, ActionText, MobileCard, MobileFooter, MobileHeader, PaymentSuccess, PaymentUnsuccessful} from './customComponent'
import {CloseIcon, PaypalIcon, CreditCardsIcon, MobileGreaterThanIcon, GrayCoinsIcon, BackIcon} from '../../../assets/svg'
import { theme } from '../../../common/theme'
import PaypalButton from './paymentMethod/PaypalButton'
import StripeButton from './paymentMethod/StripeButton'
import { FkPrimeContext } from '../../../common/context'
import {CustomLoader} from '../../../common/component/loader'
import {WholePageError} from '../../../common/component/customComponents'

export const ModalComponent = () => {
    const {handlePurchaseModal, userBoost, handlePurchase, purchaseSelected, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, stripeError} = useContext(FkPrimeContext)
    const {
        boostDetails,
    } = userBoost

    const {fkPrimePurchaseDisclaimer} = theme.text
    let choices = []

    userBoost.boostTotal.map((b) => { return choices.push(b.id === "28" ? b.price * userBoost.otpMultiQuantity : b.price)})

    const sum = choices && choices.length > 0 && choices.reduce((a, b) => {
        return a + b 
    })

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className="purchase-main-modal-body-container">
                {
                    isLoadingPurchaseCoin ? null :
                        purchaseSuccessMessage ? null :
                        errorPurchaseCoin ? null :
                        stripeError ? <WholePageError text={stripeError} /> :

                        <>
                             <div className="w-100 flex flex-justify-end p-8">
                                <IconContainerClickable 
                                    styleClass="cursor-pointer"
                                    clickFunc={handlePurchaseModal}
                                    Icon={<CloseIcon/>}
                                />
                            </div>
                            <div className="pl-12 pr-12">
                                <Text
                                    styleClass="fk-prime-purchase-modal-title-container mt-34 mb-30"
                                    text="Select Payment Method"
                                    textClass="fk-prime-purchase-modal-title-p"
                                />
                                <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                                    <RadioWithText 
                                        styleClass="flex flex-d-row flex-align-center"
                                        value="paypal"
                                        radioClass="item-condition-radios radio custom-radio-ui "
                                        onChangeFunc={handlePurchase}
                                        text="Paypal"
                                        textClass="payment-radio-text-p"
                                        purchaseSelected={purchaseSelected}
                                    />
                                    <PaypalIcon />
                                </div>
                                <div className="flex flex-d-row flex-justify-between flex-align-center">
                                    <RadioWithText 
                                        styleClass="flex flex-d-row flex-align-center"
                                        value="stripe"
                                        radioClass="item-condition-radios radio custom-radio-ui "
                                        onChangeFunc={handlePurchase}
                                        text="Credit/Debit Card"
                                        textClass="payment-radio-text-p"
                                        purchaseSelected={purchaseSelected}
                                    />
                                    <CreditCardsIcon />
                                </div>
                                <div className="mt-30">
                                    <Divider />
                                </div>
                                <div className=" mt-30 mb-20">
                                    <Text 
                                        styleClass="w-100 flex flex-justify-center flex-align-center"
                                        text="Purchase"
                                        textClass="fk-prime-purchase-modal-title-p"
                                    />
                                    <Text
                                        styleClass="w-100 flex flex-justify-center flex-align-center"
                                        text={currentDate()}
                                        textClass=""
                                    />
                                </div>
                                {
                                    boostDetails.length !== 0 ?
                                    <>
                                    <div className="flex flex-d-row w-100 flex-justify-between">
                                        <div className="flex flex-d-column">
                                            <Text
                                                styleClass=""
                                                text="Fk Prime Subscription"
                                                textClass="fk-prime-purchase-title-p"
                                            />
                                            <Text
                                                styleClass="mt-4"
                                                text={`${boostDetails.name} ${boostDetails.subname}`}
                                                textClass="fk-prime-purchase-subtitle-p"
                                            />
                                        </div>
                                        <div className="flex flex-d-column">
                                            <PriceTextWithSymbol
                                                styleClass="flex-justify-end fk-prime-purchase-price-container"
                                                symbol="S$"
                                                symbolClass="fk-prime-purchase-symbol-p"
                                                priceClass="fk-prime-purchase-price-p"
                                                price={boostDetails.price}
                                            />
                                            <Text
                                                styleClass="fk-prime-purchase-due-date-container"
                                                text={`${addDate(boostDetails.name, boostDetails.subname)}`}
                                                textClass="fk-prime-purchase-due-date-p"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-20 mb-20">
                                        {
                                            fkPrimePurchaseDisclaimer.map((disc, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Text
                                                            styleClass=""
                                                            text={`- ${disc.text}`}
                                                            textClass="fk-prime-purchase-disclaimer-p"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <Divider />
                                
                                    </> : null
                                }
                                <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                                    <Text
                                        styleClass=""
                                        text="Total"
                                        textClass="fk-prime-purchase-total-label-p"
                                    />
                                <PriceTextWithSymbol
                                        styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                                        symbol="S$"
                                        symbolClass="fk-prime-purchase-total-symbol-p"
                                        priceClass="fk-prime-purchase-total-price-p"
                                        price={sum ? sum.toFixed(2) : 0}
                                    />
                                </div>
                                <div className="mt-30 mb-30">
                                {
                                // isLoadingPurchaseCoin ? <div className="p-fixed w-100 h-100 bg-white top-0 left-0 flex flex-align-center flex-justify-center"><CustomLoader /></div>  :
                                //     purchaseSuccessMessage ? <div className="p-fixed w-100 h-100 bg-white top-0 left-0 flex flex-align-center flex-justify-center"><p style={{color: 'green'}}>{purchaseSuccessMessage}</p></div> :
                                //         errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> :
                                //             stripeError ? <p style={{color: 'red'}}>{stripeError}</p> :
                                            purchaseSelected === 'paypal' ? <PaypalButton /> : purchaseSelected === 'stripe' ? <StripeButton/> : 
                                            <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                                <p className="fk-prime-purchase-p">{`Pay`}</p>
                                            </div>
                                }
                                </div>
                                <div className=" fk-prime-purchase-modal-footer-container">
                                    <Text
                                        styleClass=""
                                        text="By purchasing any of the above, you accept our"
                                        textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                                    />
                                    <LinkText
                                        path="/terms-of-use"
                                        text="Terms of Services."
                                        textClass="fk-prime-purchase-modal-footer-link-p"
                                    />
                                </div>
                            </div>
                        </>
                }
                
            </div>
        </div>
    )
}

export const MobilePurchaseModal = () => {
    const {handlePurchaseModal, userBoost, handlePurchase, purchaseSelected, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, stripeError, handleClaim} = useContext(FkPrimeContext)
    const {
        boostDetails,
    } = userBoost

    const {fkPrimePurchaseDisclaimer} = theme.text
    let choices = []

    userBoost.boostTotal.map((b) => { return choices.push(b.id === "28" ? b.price * userBoost.otpMultiQuantity : b.price)})

    const sum = choices && choices.length > 0 && choices.reduce((a, b) => {
        return a + b 
    })
    const isFree = userBoost && userBoost.boostDetails && userBoost.boostDetails.name && userBoost.boostDetails.name.trim() === 'Free' ? true : false
    return (
        <div className="purchase-main-body-mobile-modal-container">
             <MobileHeader 
                styleClass="w-100 fk-prime-mobile-header-container"
                leftStyleClass="flex flex-d-row flex-align-center"
                leftTextClass="fk-prime-left-header-text-p"
                leftIcon={ <BackIcon />}
                leftText={`Back`}
                centerStyleClass="fk-prime-center-header-container"
                centerTextClass="fk-prime-center-header-text-p"
                centerText="Subscriptions"
                rightStyleClass="fk-prime-right-header-container"
                />
                <Divider />
                {
                    isLoadingPurchaseCoin ? <div className="w-100 h-100 flex flex-justify-center flex-align-center text-align-center"> <CustomLoader /> </div> : 
                    purchaseSuccessMessage ? <div className="w-100 h-100 flex flex-justify-center flex-align-center text-align-center"><PaymentSuccess /> </div> :
                    <>
                        <div>
                    <div className="mobile-purchase-fk-prime-container">
                        <Text
                        styleClass="fk-prime-purchase-modal-title-container mt-34 mb-30"
                        text="Select Payment Method"
                        textClass="fk-prime-purchase-modal-title-p"
                        />
                         <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                            <RadioWithText 
                                styleClass="flex flex-d-row flex-align-center"
                                value="paypal"
                                radioClass=""
                                onChangeFunc={handlePurchase}
                                text="Paypal"
                                textClass="payment-radio-text-p"
                                purchaseSelected={purchaseSelected}
                            />
                            <PaypalIcon />
                        </div>
                        <div className="flex flex-d-row flex-justify-between flex-align-center">
                            <RadioWithText 
                                styleClass="flex flex-d-row flex-align-center"
                                value="stripe"
                                radioClass=""
                                onChangeFunc={handlePurchase}
                                text="Credit/Debit Card"
                                textClass="payment-radio-text-p"
                                purchaseSelected={purchaseSelected}
                            />
                            <CreditCardsIcon />
                        </div>
                    </div>
                <div className="mt-30">
                    <Divider />
                </div>
                <div className="mobile-purchase-fk-prime-container">
                    <div className=" mt-30 mb-20">
                        <Text 
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text="Purchase"
                            textClass="fk-prime-purchase-modal-title-p"
                        />
                    </div>
                    {
                        boostDetails.length !== 0 ?
                        <>
                        <div className="flex flex-d-row w-100 flex-justify-between">
                            <div className="flex flex-d-column">
                                <Text
                                    styleClass=""
                                    text="Fk Prime Subscription"
                                    textClass="fk-prime-purchase-title-p"
                                />
                                <Text
                                    styleClass="mt-4"
                                    text={`${boostDetails.name} ${boostDetails.subname}`}
                                    textClass="fk-prime-purchase-subtitle-p"
                                />
                            </div>
                            <div className="flex flex-d-column">
                                <PriceTextWithSymbol
                                    styleClass="flex-justify-end fk-prime-purchase-price-container"
                                    symbol="S$"
                                    symbolClass="fk-prime-purchase-symbol-p"
                                    priceClass="fk-prime-purchase-price-p"
                                    price={boostDetails.price}
                                />
                                <Text
                                    styleClass="fk-prime-purchase-due-date-container"
                                    text={`${addDate(boostDetails.name, boostDetails.subname)}`}
                                    textClass="fk-prime-purchase-due-date-p"
                                />
                            </div>
                        </div>
                        <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                            <Text
                                styleClass=""
                                text="Total"
                                textClass="fk-prime-purchase-total-label-p"
                            />
                        <PriceTextWithSymbol
                                styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                                symbol="S$"
                                symbolClass="fk-prime-purchase-total-symbol-p"
                                priceClass="fk-prime-purchase-total-price-p"
                                price={sum ? sum.toFixed(2) : 0}
                            />
                        </div>
                        <div className="mt-20 mb-20">
                            {
                                fkPrimePurchaseDisclaimer.map((disc, i) => {
                                    return (
                                        <div key={i}>
                                            <Text
                                                styleClass=""
                                                text={`- ${disc.text}`}
                                                textClass="fk-prime-purchase-disclaimer-p"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        </> : null
                    }
                </div>
                <Divider />
                    {/* <div className="mt-30 mb-30">
                    {isLoadingPurchaseCoin ? 'Processing...' :
                        purchaseSuccessMessage ? <p style={{color: 'green'}}>{purchaseSuccessMessage}</p> :
                            errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> :
                                stripeError ? <p style={{color: 'red'}}>{stripeError}</p> :
                                purchaseSelected === 'paypal' ? <PaypalButton /> : purchaseSelected === 'stripe' ? <StripeButton/> : 
                                <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                    <p className="fk-prime-purchase-p">{`Pay`}</p>
                                </div>
                    }
                    </div> */}
                    {/* <div className=" fk-prime-purchase-modal-footer-container"> */}
                    <div className="mt-20 w-100 flex flex-d-column flex-justify-center flex-align-center text-align-center">
                        <Text
                                styleClass=""
                                text="By purchasing any of the above, you accept our"
                                textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                            />
                            <LinkText
                                path="/terms-of-use"
                                text="Terms of Services."
                                textClass="fk-prime-purchase-modal-footer-link-p"
                            />
                    </div>
                    {/* </div> */}
                </div>
                <MobileFooter boost={userBoost.boostDetails.sg_price} customButton={purchaseSelected === 'paypal' ? <div className="mobile-fk-prime-purchase-paypal-container-btn"><PaypalButton /> </div> : purchaseSelected === 'stripe' ?  <div className="mobile-fk-prime-purchase-paypal-container-btn"><StripeButton/> </div> : 
                                <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                    <p className="fk-prime-purchase-p">{`Pay`}</p>
                                </div>}/>
                    </>
                }
                
        </div>
    )
}

export const BoostCardComponent = ({boost, i, handleBoostSelection, userBoost, setUserBoost, handleClaimFree, freeClaim, is_free_subscription_claimed, showSubscriptionUpgrade, toggleSubscriptionData, has_active_subscription}) => {
    return (
        <BoostCards 
            id={boost.id}
            data={boost} 
            type="fkPrime"
            mainClass={`p-relative fk-prime-main-body-container`}
            styleClass={userBoost.boostID === boost.id && userBoost.boostID !== 0 ? `border-success fk-prime-boost-card` : `fk-prime-boost-card`}
            titleTextClass={`${is_free_subscription_claimed && boost.name.includes('Free') ? `fk-prime-title-text-p text-gray` : `fk-prime-title-text-p`}`} 
            titleContainerClass="fk-prime-title-container"
            subtitleClass="fk-prime-sub-title-text-p" 
            priceClass="fk-prime-price-container" 
            priceTextClass={
                is_free_subscription_claimed && boost.name.includes('Free') ? `text-gray fk-prime-price-text-p fk-prime-price-text-p-0` :
                parseInt(boost.price) === 0 ? 
                `fk-prime-price-text-p fk-prime-price-text-p-0` : 
                `fk-prime-price-text-p fk-prime-price-text-p-not-0`
            } 
            boostClass="fk-prime-boost-list-container"
            boostTextClass={is_free_subscription_claimed && boost.name.includes('Free') ? `fk-prime-boost-list-p text-gray` : `fk-prime-boost-list-p`}
            isSelected={userBoost.boostID === boost.id && userBoost.boostID !== 0 ? true : false}
            key={i}
            handleBoostSelection={handleBoostSelection}
            userBoost={userBoost}
            setUserBoost={setUserBoost}
            handleClaimFree={handleClaimFree}
            freeClaim={freeClaim}
            is_free_subscription_claimed={is_free_subscription_claimed}
            showSubscriptionUpgrade={showSubscriptionUpgrade} 
            toggleSubscriptionData={toggleSubscriptionData}
            has_active_subscription={has_active_subscription}
            
        />
    )
}

export const MobileBoostCard = ({boost, i, handleBoostSelection, userBoost, setUserBoost, is_free_subscription_claimed, showSubscriptionUpgrade, toggleSubscriptionData, has_active_subscription}) => {
    const peekFkPrimeBoost = (id) => {
        let ids = userBoost.mobileIsPeek
        if(userBoost.mobileIsPeek.length !== 0){
            const found = userBoost.mobileIsPeek.some(el => el.id === id);
            const new_ids = userBoost.mobileIsPeek.filter(item => item.id !== id)
            if(found){
                ids = new_ids
            }else{
                ids.push({id})
            }
        }else{
            ids.push({id})
        }
        return setUserBoost({['mobileIsPeek']: ids})
    }
    return (
        <MobileCard
            styleClass={userBoost.boostID === boost.id && userBoost.boostID !== 0 ? `border-success fk-prime-mobile-boost-card` : `fk-prime-mobile-boost-card`}
            textContainerClass="flex flex-d-column flex-align-center fk-prime-mobile-title-container"
            titleTextClass="fk-prime-boost-mobile-title-p"
            subtitleTextClass="fk-prime-boost-mobile-subtitle-p"
            boost={boost}
            id={boost.id}
            key={i}
            type={boost.type}
            handleBoostSelection={handleBoostSelection}
            setUserBoost={setUserBoost}
            isSelected={userBoost.boostID === boost.id && userBoost.boostID !== 0 ? true : false}
            peekFkPrimeBoost={peekFkPrimeBoost}
            isPeek={userBoost.mobileIsPeek.some(el => el.id === boost.id) ? true : false}
            is_free_subscription_claimed={is_free_subscription_claimed}
            showSubscriptionUpgrade={showSubscriptionUpgrade} 
            toggleSubscriptionData={toggleSubscriptionData}
            has_active_subscription={has_active_subscription}
        />
    )
}

export default {
    ModalComponent,
    MobilePurchaseModal,
    BoostCardComponent,
    MobileBoostCard
}