import React, { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { numberWithCommas, slugify, categoryParser } from '../../../../common/helper/utility'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import DefaultItem from '../../../../assets/images/commerce-and-shopping.png'
import HomeItemTag from '../../../../common/component/tags/HomeItemTag'
import SocialAvatar from '../../../../common/component/socialAvatar'
import FKImage from '../../../../common/component/image'
import {WTB, WTT} from '../../../../common/component/label'

const REACT_APP_IMGX = process.env.REACT_APP_IMGX || '';

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'topAd':
      return { ...state, isTopAd: true }
    case 'highlight':
      return { ...state, isHighlight: true }
    case 'toggleShow':
      return { ...state, isShow: !state.isShow }
    default:
      break;
  }
}


const CategoryItemCard = ({ item, index, id }) => {
  let seller_id
  seller_id = item.oc_t_user.pk_i_id
  const imageSrc = item.oc_t_user && item.oc_t_user.oc_t_user_resource ? item.oc_t_user.oc_t_user_resource : null
  const sellerUsername = item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : null
  const price = item && item.i_price ? numberWithCommas((item.get_i_price).toFixed(2)) : ''
  const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
  const reserved = item && item.b_reserved ? item.b_reserved : false
  const item_title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
  const item_resource = item && item.oc_t_item_resource ? item.oc_t_item_resource : []
  const ownerId = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : 0
  const user_social_id = item.oc_t_user && item.oc_t_user.social && item.oc_t_user.social[0] && item.oc_t_user.social[0].data
  && item.oc_t_user.social[0].data.id ? item.oc_t_user.social[0].data.id : ''
  const socialImageSrc = item.oc_t_user && item.oc_t_user.social && item.oc_t_user.social[0] && item.oc_t_user.social[0].data
    && item.oc_t_user.social[0].data.picture && item.oc_t_user.social[0].data.picture.data && item.oc_t_user.social[0].data.picture.data.url ? item.oc_t_user.social[0].data.picture.data.url : null
  const [state, dispatch] = useReducer(itemReducer, { isTopAd: false, isHighlight: false, isShow: false })
  const date_published = item && item.dt_pub_date ? item.dt_pub_date : null 
  const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null

  const isNew = item.is_new ? item.is_new : false

  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (item && item.ta_expiration) {
      const isTopAd = item && item.ta_expiration ? moment(item.ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({ type: 'topAd' })
      }
    }
    if (item && item.hl_expiration) {
      const isHighlight = item && item.hl_expiration ? moment(item.hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({ type: 'highlight' })
      }
    }
    // eslint-disable-next-line  
  }, [item, item.ta_expiration, item.hl_expiration])

  const item_id = item.pk_i_id ? item.pk_i_id : 0
  const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
  const itemSLug = slugify(item_title)
  const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
  const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null
  const finalSLug = `/${category}/${itemSLug}_i${item_id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`
  const post_type = item && item.post_type ? item.post_type : ''

  return (
    <div key={index} className="home-item-card regular">

      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', height: '100%' }}>


        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', padding: 5 }}>
          {socialImageSrc ? <SocialAvatar id={user_social_id} url={socialImageSrc}/> : imageSrc ? <FKImage images={[imageSrc]} type="seller_avatar" alt="seller results" /> : <img src={DefaultPhoto} width={40} height={40} style={{ borderRadius: 20, overflow: 'hidden' }} alt="default seller result" />}
          <div>
            <p className="sender-name">{sellerUsername}</p>
            <p style={{fontSize: window.innerWidth < 600 ? '9.5px' : 12, fontStyle: 'normal', letterSpacing: '0.44px', color: '#c4c4c4', marginLeft: 5}}>{`${formatted_date ? formatted_date : null}`}</p>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {
            state.isTopAd ? sold ? null :
              <div id="ribbon-container">
                <p >Premium</p>
              </div>
              : null
          }
        </div>
        <div style={{ border: state.isTopAd ? sold ? 'none' : '0.5px solid #FB770D' : 'none', width: '100%', borderRadius: '6px', background: state.isHighlight ? sold ? '#FFFFFF' : '#FFF1E5' : '#FFFFFF' }}>
          <Link to={finalSLug} style={{ width: '100%' }}>

            {/* <p>{item.dt_pub_date}</p> */}
            <div className="itemcard-image-container">
              {/* <FKImage images={item_resource} type="thumbnail" /> */}
              {
                item_resource.length !== 0 ?
                  <>
                    <FKImage images={item_resource} type="thumbnail" isNew={isNew}/>
                    <HomeItemTag sold={sold} reserved={reserved} />
                  </> :
                  <div style={{ background: '#bdc3c7', background: '-webkit-linear-gradient(to right, #bdc3c7, #2c3e50)', background: 'linear-gradient(to right, #bdc3c7, #2c3e50)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={DefaultItem} width={100} height={100} />
                    <HomeItemTag sold={sold} reserved={reserved} />
                  </div>
              }
            </div>
            <div className="flex flex-justify-between flex-align-center p-0-5 pt-3" >
              <p className="itemcard-item-price">{`${price} $`}</p>
              {
                post_type ? post_type === 'WTS' ? null :
                <div className="mt-7">
                  { post_type === 'WTT' ? <WTT /> : <WTB /> }</div> : null
              }
            </div>
            <div style={{ padding: '0px 5px', height: '30px', marginTop: '12px', maxHeight: '32px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <p className="itemcard-item-title">{item_title}</p>
            </div>
          </Link>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', padding: '0px 8px' }}>
            {
              id ? parseInt(ownerId) === parseInt(id) ? <div className="dummy-container"> </div> :
                <div style={{ borderTop: '1px solid rgb(196, 196, 196)', width: '100%', display: 'flex', justifyContent: 'center', padding: '6px' }}>
                  <Link to={{
                    pathname: !id ? `/login` : `/user/messages/inbox`,
                    state: { fromMessage: true, item: item }
                  }}
                    className={`btn-modal-options ${window.innerWidth < 600 ? `message-btn-mobile` : null}`}>Message
              </Link> </div> : <div style={{ borderTop: '1px solid rgb(196, 196, 196)', width: '100%', display: 'flex', justifyContent: 'center', padding: '6px' }}>
                  <Link to={{
                    pathname: !id ? `/login` : `/user/messages/inbox`,
                    state: { fromMessage: true, item: item }
                  }}
                    className={`btn-modal-options ${window.innerWidth < 600 ? `message-btn-mobile` : null}`}>Message
              </Link> </div>
            }    </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryItemCard