import React, {useRef, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton, WhatsappShareButton, WhatsappIcon, FacebookMessengerShareButton, FacebookMessengerIcon} from 'react-share'
import {CustomLoader} from '../loader'
import {SuccessSVGIcon, CartIconWhite, CoinsIcon, ErrorIcon, CloseIcon, TwitterShareIcon,
    WhatsappShareIcon,
    MessengerShareIcon,
    FacebookShareIcon, BalloonsParty, MobileBaloonsParty, FiveFishIcon, CheckCircleIcon} from '../../../assets/svg'
import {BottomWave, GreenCheck} from './svg'

export const Text = ({styleClass, textClass, text}) => {
    return (
        <div className={styleClass}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const IconContainerClickable = ({styleClass, Icon, clickFunc}) => {
    return (
        <div className={styleClass} onClick={() => clickFunc()}>
           {Icon}
        </div>
    )
}

export const Divider = () => {
    return (
        <div className="w-100 divider-border"/>
    )
}

export const LinkText = ({path, textClass, text}) => {
    return (
        <Link to={path} className={textClass} >
            <p>{text}</p>
        </Link>
    )
}

export const PaymentSuccess = (props) => {
    const {text} = props
    return (
        <div className='flex flex-justify-center flex-align-center h-100'>
            <div className="flex flex-d-column flex-align-center">
                <p className="payment-successful-label-p">{text}</p>
                <SuccessSVGIcon />
            </div>
        </div>
    )
}

export const Button = ({styleClass, textClass, text, clickFunc}) => {
    return (
        <div className={styleClass} onClick={() => clickFunc()}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const MobileFooter = ({boost, isCoinPurchase, handleFooter, text, customButton, buyCoinsFunc, removeBuyCoinsBtn}) => {
    return (
        <>
        {
            removeBuyCoinsBtn ? null :
            <div className="buy-coins-mobile-main-body-container" onClick={() => buyCoinsFunc ? buyCoinsFunc() : null}>
                <CartIconWhite />
                <p>{`Buy Coins`}</p>
            </div>
        }
        <div className="fk-prime-mobile-footer-container">
            <div className="fk-prime-mobile-footer-total-container">
                {
                    isCoinPurchase ? 
                    <div className="flex flex-d-row flex-align-center">
                         <CoinsIcon />
                         <p className="fk-prime-coins-total-int-p">{boost}</p>
                    </div> :
                    <p className="fk-prime-mobile-footer-total-p">{`S$ ${boost ? boost.toFixed(2) : 0}`}</p>
                }
            </div>
            <div className=" flex flex-align-center flex-justify-center">
               { customButton ? customButton : <div className={boost ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={(e) => boost !== 0 && handleFooter ?  handleFooter(e) : null}>
                    <p className="fk-prime-purchase-p">{text ? text : `Purchase`}</p>
                </div>}
            </div>
        </div>
        </>
    )
}

export const SubsToFkPrimeFooter = () => {
    return (
        <div className="subs-to-fk-prime-footer-main-container">
            <Link to={`/fk-prime`} className="subs-to-fk-prime-footer-btn-container">
                <p className="subs-to-fk-prime-footer-btn-p">{`Subscribe to FK Prime`}</p>
            </Link>
        </div>
    )
}

export const PaymentUnsuccessful = (props) => {
    const {text} = props
    return (
        <div className="flex flex-d-column mt-113 flex-align-center">
            <p className="payment-unsuccessful-label-p">{text}</p>
            <div className="error-icon-unsuccessful-container">
                <ErrorIcon />
            </div>
        </div>
    )
}

export const WholePageLoader = () => {
    return null
    // return (
    //     <div className="p-fixed top-0 left-0 w-100 h-100 h-mn-100 bg-black-op-p3 flex flex-justify-center flex-align-center z-ndx-7">
    //         <div className="custom-loader-custom-comp-container">
    //             <CustomLoader />
    //         </div>
    //     </div>
    // )
}

export const WholePageError = ({text}) => {
    return (
        <div className="p-fixed top-0 left-0 w-100 h-100 h-mn-100 bg-black-op-p3 flex flex-justify-center flex-align-center z-ndx-7">
            <div className="custom-loader-custom-comp-container">
                <p>{text}</p>
            </div>
        </div>
    )
}

export const WholePageActivated = ({text}) => {
    return (
        <div className="p-fixed top-0 left-0 w-100 h-100 h-mn-100 bg-black-op-p3 flex flex-justify-center flex-align-center z-ndx-7">
            <div className="custom-loader-custom-comp-container">
                <p>{text}</p>
            </div>
        </div>
    )
}

export const WholePageActivate = ({text, path}) => {
    return (
        <div className="p-fixed top-0 left-0 w-100 h-100 h-mn-100 bg-black-op-p3 flex flex-justify-center flex-align-center z-ndx-7">
            <div className="custom-whole-page-custom-comp-container">
                <p>{text}</p>
                {path ? <><p>{`To see deactivated listings you may go to`}</p><p>{path}</p> </>: null}
            </div>
        </div>
    )
}

export const WholePageSuccessMessage = ({text}) => {
    return (
        <div className="p-fixed top-0 left-0 w-100 h-100 h-mn-100 bg-black-op-p3 flex flex-justify-center flex-align-center z-ndx-7">
            <div className="custom-success-custom-comp-container">
                <p>{text}</p>
                <SuccessSVGIcon />
            </div>
        </div>
    )
}

export const SuccessMessage = ({successText}) => {
    return (
        <div className="flex flex-d-column flex-justify-center flex-align-center">
            <div>
                <p className="success-text-custom-component-p">{successText}</p>
            </div>
            <div>
                <SuccessSVGIcon />
            </div>
        </div>
    )
}

export const LinkCopiedAlert = () => {
    return (
        <div className="link-copied-main-container">
            <div className="link-copied-container">
                <p>{`Link copied!`}</p>
            </div>
        </div>
    )
}

export const ShareModal = ({link, closeModalFunc}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const appID = process.env.REACT_APP_FB_APP_ID
    const removeFirstSlash = link.substring(1);
    const textAreaRef = useRef(null);

    const [linkCopied, setLinkCopied] = useState(false)

    useEffect(() => {
        if(linkCopied){
            setTimeout(() => {
                setLinkCopied(false)
            }, 5000)
        }
    },[linkCopied])

    const copyHandler = () => {
        const el = document.createElement("input");
        //el.value = window.location.href;
        el.value = encodeURI(baseUrl+removeFirstSlash);
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setLinkCopied(true);
        // navigator.clipboard.writeText(`${baseUrl+removeFirstSlash}`)
    }

    const MobileContainer = () => {
        return (
            <div className="w-100 flex flex-d-column flex-justify-center">
                <div className="share-social-container">
                    <WhatsappShareButton
                        url={encodeURI(baseUrl+removeFirstSlash)} className="flex flex-d-row flex-align-center">
                        <WhatsappShareIcon />
                        <p className="whatsapp-share-label-p">{`Whatsapp`}</p>
                    </WhatsappShareButton>
                </div>
                <div className="share-social-container">
                    <TwitterShareButton
                        url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                        <TwitterShareIcon />
                        <p className="twitter-share-label-p">{`Twitter`}</p>
                    </TwitterShareButton>
                </div>
                <div className="share-social-container">
                    <FacebookShareButton
                        url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                        <FacebookIcon size={36} borderRadius={12}/>
                        <p className="facebook-share-label-p">{`Facebook`}</p>
                    </FacebookShareButton>
                </div>
                <div className="share-social-container">
                    <FacebookMessengerShareButton appId={appID} redirectUri={baseUrl+removeFirstSlash}
                        url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                        <MessengerShareIcon />
                        <p className="messenger-share-label-p">{`Messenger`}</p>
                    </FacebookMessengerShareButton>
                </div>
                <div className="share-socials-copy-link-container">
                    <div className="textarea-share-link-container">
                        <input
                            className="share-copy-link-textarea no-select"
                            ref={textAreaRef}
                            value={encodeURI(baseUrl+removeFirstSlash)}
                            disabled
                        />
                    </div>
                    <button className={`copy-to-clipboard-btn ${linkCopied ? `btn-dark-orange` : `btn-bg-orange`}`} onClick={() => copyHandler()}>Copy</button> 
                </div>
            </div>
        )
    }

    return (
        <div className="share-modal-main-container">
            <div className="share-modal-container">
                <div className="share-header-container" >
                    <div className="flex flex-d-row">
                        <p className="share-label-p">{`Share`}</p>
                        <div className={`cursor-pointer ${window.innerWidth < 600 ? `ml-36` : `ml-63`}`} onClick={() => closeModalFunc(false)}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className="share-body-container">
                        {
                            window.innerWidth < 600 ?
                            MobileContainer() :
                            <>
                                <div className="share-socials-container">
                                    <div className="share-social-container">
                                        <TwitterShareButton
                                            url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                                            <TwitterShareIcon />
                                            <p className="twitter-share-label-p">{`Twitter`}</p>
                                        </TwitterShareButton>
                                    </div>
                                    <div className="share-social-container">
                                        <FacebookShareButton
                                            url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                                            <FacebookIcon size={36} borderRadius={12}/>
                                            <p className="facebook-share-label-p">{`Facebook`}</p>
                                        </FacebookShareButton>
                                    </div>
                                </div>
                                <div className="share-socials-container">
                                    <div className="share-social-container">
                                        <WhatsappShareButton
                                            url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                                            <WhatsappShareIcon />
                                            <p className="whatsapp-share-label-p">{`Whatsapp`}</p>
                                        </WhatsappShareButton>
                                    </div>
                                    <div className="share-social-container">
                                        <FacebookMessengerShareButton appId={appID}
                                            url={baseUrl+removeFirstSlash} className="flex flex-d-row flex-align-center">
                                            <MessengerShareIcon />
                                            <p className="messenger-share-label-p">{`Messenger`}</p>
                                        </FacebookMessengerShareButton>
                                    </div>
                                </div>
                                <div className="share-socials-copy-link-container">
                                    <div className="textarea-share-link-container">
                                        <input
                                            className="share-copy-link-textarea no-select"
                                            ref={textAreaRef}
                                            value={baseUrl+removeFirstSlash}
                                            disabled
                                        />
                                    </div>
                                    <button className={`copy-to-clipboard-btn ${linkCopied ? `btn-dark-orange` : `btn-bg-orange`}`} onClick={() => copyHandler()}>Copy</button> 
                                </div>
                            </>
                        }
                </div>
            </div>
            {linkCopied ? <LinkCopiedAlert /> : null}
        </div>
    )
}

export const FreeSlotSuccessClaimModal = ({handleBtn}) => {
    return (
        <div className="free-slot-claim-modal-container">
            <div className="free-slot-f-container">
                <div className="free-announce-container">
                    <p>{`Yey! you have claimed 5 free listing slots,`}</p>
                    <p>{`No expiration!`}</p>
                </div>
                <div className="mb-30">
                    { window.innerWidth < 600 ? <MobileBaloonsParty /> : <BalloonsParty />  }
                </div>
                <p className="unclaimed-activate-disclaimer-label-p">{`Activate and make different listings go live`}</p>
                <div className="mt-30 mb-20">
                    <FiveFishIcon />
                </div>
                <p className="mb-30">{`or`}</p>
            </div>
            <div className="free-slot-s-container">
                <p className="be-an-fk-label-p">{`Be an FK PRIME`}</p>
                <div className="be-an-fk-disc-label-p">
                    <p>{`Get 1-Month FREE trial of FK Prime`}</p>
                    <p>{`on the first month of your first subscription.`}</p>
                    <p>{`We’ll remind you 7 days before your trial ends. Cancel anytime.`}</p>
                </div>
                <Link to={`/fk-prime`} className="unclaimed-free-listing-claim-btn mb-30">
                    <p>{`TRY IT FREE`}</p>
                </Link>
                <div className="maybe-later-container" onClick={() => handleBtn()}>
                    <p>{`MAYBE LATER, I WANT TO SELL SOMETHING`}</p>
                </div>
            </div>
        </div>
    )
}

export const ScrollToTop = ({children}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return children
}

export const CollectPromoModal = (props) => {
    const {closePromoHandler, collectPromoHandler, uncollectedPromo, isLoadingCollectPromo, collectPromoMessage, errorCollectPromo, congratulations, youReceived, promo, collect, claimSuccess, checkYourProfile } = props.sharedProps
    const promoTitle = uncollectedPromo && uncollectedPromo.name ? uncollectedPromo.name : promo
    const promoDescription = uncollectedPromo && uncollectedPromo.description ? uncollectedPromo.description : ''
    const promoAmount = uncollectedPromo && uncollectedPromo.amount ? uncollectedPromo.amount : ''
    const promoId = uncollectedPromo && uncollectedPromo.id ? uncollectedPromo.id : null

    return (
        <div className="share-modal-main-container">
            <div className={"promo-collect-modal-container"} style={{background: collectPromoMessage ? 'white' : '#FFF1E5'}}>
                {
                    isLoadingCollectPromo || collectPromoMessage ||  errorCollectPromo ? null :
                    <div className='promo-modal-close-container' onClick={() => closePromoHandler(false)}>
                        <CloseIcon />
                    </div>
                }
                {
                    collectPromoMessage ?   
                    <div className="promo-collect-modal-content collect-success-container">
                        <GreenCheck/>
                        <p className="claim-success-text">{claimSuccess}</p>
                        <p className="check-youre-profile-text">{checkYourProfile}</p>
                    </div> : 
                    <div className="promo-collect-modal-content">
                        <p className="promo-greeting-1">{congratulations}</p>
                        <p className="promo-greeting-2">{youReceived}</p>
                        <div className="promo-details-container">
                            <div className="promo-details-container-top">
                                <div style={{flex: 3}}>
                                    <p className='promo-title'>{promoTitle}</p>
                                    <p className='promo-description'>{promoDescription}</p>
                                </div>
                                <div className='promo-amount-container'>
                                    <p>
                                        <span className='promo-amount'>{`${promoAmount}`}</span>
                                        <span className='promo-amount-currency' dangerouslySetInnerHTML={{ "__html": "&copy;" }} />
                                    </p>
                                </div>
                            </div>
                            <div className="promo-details-container-bottom">
                                <BottomWave/>
                            </div>
                        </div>
                        <div className='collect-button-container'>
                            {
                                isLoadingCollectPromo ? <p>Loading...</p> : 
                                errorCollectPromo ? <p style={{color: 'red'}}>{errorCollectPromo}</p> : 
                                <div className={`collect-button btn-bg-orange`} onClick={() => collectPromoHandler(promoId)}>
                                    <p className='collect-text'>{collect}</p>
                                </div> 
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}