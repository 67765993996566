import React from 'react';
import PropTypes from 'prop-types';
import CategoryBar from '../../common/component/categoryBar'
// import ProfileBar from '../web/profile/profileBar'
// import ProfileNavLink from '../web/profile/profileNavLink'
// import DefaultCover from '../web/profile/cover'

const LayoutWithSidebar = ( { component: Component, type, session, ...rest } ) => (
  
  <React.Fragment>
    <CategoryBar session={session}/>
    {/* <div className="profile-container">
      <div className="profile-sub-main-container w-100">
        <DefaultCover />
        <div className="profile-main-container">
          <div>
            <ProfileBar session={session} />
          </div>
          <div>
            <div>
              <ProfileNavLink session={session}/>
            </div>
            <div style={{marginBottom: 50}}>
            <Component {...rest} session={session}/>
            </div>
          </div>
        </div>
      </div>
     
      
    </div> */}
    <Component {...rest} session={session}/>
  </React.Fragment>
);

LayoutWithSidebar.propTypes = {
  component: PropTypes.any
};

export default LayoutWithSidebar;
