import { CONTACT_DATA } from '../constant'
import { takeLatest, put } from 'redux-saga/effects'
import contact from '../../graphql/contact'
import { contactDataSuccess, contactDataFail } from '../action/contactAction'
import { theme } from '../../common/theme'

function* contactUsSaga(action) {
    const { payload } = action
    try {
        const result = yield contact.contactUs(payload)
        if(result === 403) {
            yield put(contactDataFail('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.contactUs && result.data.contactUs.message ? result.data.contactUs.message : ''
            yield put(contactDataSuccess(data));
        }

    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
            error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(contactDataFail(errorMessage))
    }
}

export function* watchContactUsSaga(){
    yield takeLatest(CONTACT_DATA.LOAD, contactUsSaga)
}