import React from 'react'

const WebFooter = () => {
    return (
        <div className="w-100">

        </div>
    )
}

export default WebFooter