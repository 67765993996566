import { REVIEW, REVIEW_VIEW } from '../constant'
import { takeLatest, put } from 'redux-saga/effects'
import { reviewSuccess, reviewFail, reviewViewSuccess, reviewViewFail } from '../action'
import review from '../../graphql/review'

function* reviewSaga(action) {
    const { payload } = action
    try {
        const result = yield review.createReview(payload)
        if(result === 403) {
            yield put(reviewFail('Something went wrong try again!'))
        }
        else{
            yield put(reviewSuccess(result))
        }
    } catch (error) {
        console.log('error', JSON.stringify(error))
        yield put(reviewFail(error))
    }
}

export function* watchReviewSaga(){
yield takeLatest(REVIEW.LOAD, reviewSaga)
}

function* reviewViewSaga(action){
    const { payload } = action
    try {
        const result = yield review.reviewView(payload)

        if(result === 403) {
            yield put(reviewViewFail('Something went wrong try again!'))
        }else{
            const data = result && result.data && result.data.getReviewById ? result.data.getReviewById : null
            yield put(reviewViewSuccess(data))
        }
    }catch (error) {
        console.log('error', JSON.stringify(error))
        yield put(reviewViewFail(error))
    }
}

export function* watchReviewViewSaga(){
yield takeLatest(REVIEW_VIEW.LOAD, reviewViewSaga)
}