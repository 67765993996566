import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import CategoryBar from '../../common/component/categoryBar'

const Layout = ( { component: Component, type, session, ...rest } ) => {
  const isAdmin = session && session.fkv2_session && session.fkv2_session.type && session.fkv2_session.type === 'admin' ? true : false
  if (isAdmin) {
    return <Redirect to='/admin/dashboard'/>
  } else {
    return (
      <React.Fragment>
        <CategoryBar session={session}/>
        <Component {...rest} session={session}/>
      </React.Fragment>
    )
  }
};

Layout.propTypes = {
  component: PropTypes.any
};

export default Layout;
