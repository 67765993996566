import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import {ProfileSubscriptionContext} from '../../../../../common/context'
import {getPaypalSubscriptionIdByAmount, resetPaypal} from '../../../../../common/helper/utility'
import {CustomLoader} from '../../../../../common/component/loader'
import {PaymentSuccess} from '../../../../../common/component/customComponents'
import {Button} from '../../../fkPrime/customComponent'

export const PaypalUpgradeButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {upgradeData, has_active_subscription, handleUpdateSubscription, handleUpgradeError, isLoadingUpdateSubscription, updateSubscriptionMessage, updateSubscriptionError} = value
    const sg_price = upgradeData && upgradeData[0] && upgradeData[0].sg_price ? upgradeData[0].sg_price : 0
    const plan_id = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()
    const subscription_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.id ? has_active_subscription.purchase_micro_service.id : null
    const transaction_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const payment_method = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.payment_method ? has_active_subscription.purchase_micro_service.payment_method : null

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    // const handleForcePurchase = () => {
    //         const line_items_data = parsedLineItems(boostOtpDetails, 'paypal')
    //         const boostData = {payment_method: 'paypal', type: 'otp', total_amount: boostOtpTotal.toFixed(2), line_items_detail: line_items_data }
    //         purchaseCoin(boostData, { order_id: 'order.id' })
    // }

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription`
        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                window.paypal.Buttons({
                    style: {
                        label: 'paypal',
                        width: '50%'
                    },
                    createSubscription: function(data, actions) {
                        return actions.subscription.revise(subscription_id, {
                            plan_id: plan_id
                        });
                    },
                    onApprove: async (data, actions) => {
                        const service_id = upgradeData && upgradeData[0] && upgradeData[0].id ? parseInt(upgradeData[0].id) : null
                        handleUpdateSubscription({id, transaction_id, payment_method, plan_id, service_id})
                    },
                    onError: async (e) => {
                        console.log('onError e', e)
                        handleUpgradeError()
                    }
                }).render(paypalRef);
            }, 1000)
        }
    }
 
    return (
        isLoadingUpdateSubscription ? <CustomLoader /> :
        updateSubscriptionMessage ? <PaymentSuccess text={updateSubscriptionMessage} /> :
        updateSubscriptionError ? <p className='color-danger text-align-center'>{updateSubscriptionError}</p> : 
            <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                <div ref={v => (paypalRef = v)}></div>
                {/* <button onClick={() => handleForcePurchase()}>Force Purchased</button> */}
            </div>

    );
}

export const PaypalRenewButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {subscriptionRenewData, session, handleRenewSubscription, s_email, isLoadingPurchaseCoin, errorPurchaseCoin, purchaseSuccessMessage, handleRenewFailed, handleCoinReset, setShowSubscriptionRenew} = value
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const sg_price = subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.sg_price ? parseInt(subscriptionRenewData[0].service_detail.sg_price) : null

    const paypalPlanId = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()

    const boostDetailsObj = {
        "type": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].type ? subscriptionRenewData[0].type : null,
        "user_id": parseInt(userId),
        "coin": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.credit ? parseInt(subscriptionRenewData[0].service_detail.credit) : null,
        "id": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.id ? subscriptionRenewData[0].service_detail.id : null,
        "sg_price": sg_price,
        "description":  subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.description ? subscriptionRenewData[0].service_detail.description : null,
        "method": "paypal",
        "currency": "sgd",
        "s_email": s_email,
        "name": subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].service_detail && subscriptionRenewData[0].service_detail.name ? subscriptionRenewData[0].service_detail.name : null,
        "customer": "",
    }

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [subscriptionRenewData])

    // const handleForcePurchase = () => {
    //     const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
    //     const boostData = {payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
    //     purchaseCoin(boostData, { order_id: 'data.subscriptionID' })
    // }

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription`

        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                window.paypal.Buttons({
                    style: {
                        label: 'paypal',
                        width: '50%'
                    },
                    createSubscription: (data, actions) => {
                        return actions.subscription.create({
                            'plan_id': paypalPlanId
                        });
                    },
                    onApprove: async (data, actions) => {
                        const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
                        const total_amount = sg_price ? sg_price.toFixed(2) : null
                        const boostData = {credit: null, payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
                        handleRenewSubscription(boostData, { order_id: data.subscriptionID })
                    },
                    onError: async (e) => {
                        const error = await e
                        console.log('error', error)
                        handleRenewFailed()
                        setTimeout(() => {
                            handleCoinReset()
                            setShowSubscriptionRenew(false)
                        }, 3000)
                    }
                }).render(paypalRef);
            })
        }
    }

    return (
        isLoadingPurchaseCoin ? <CustomLoader /> :
        purchaseSuccessMessage ? <PaymentSuccess text={purchaseSuccessMessage} /> :
        errorPurchaseCoin ? <p className='color-danger text-align-center'>{errorPurchaseCoin}</p> : 
        <div>
            <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                <div ref={v => (paypalRef = v)}></div>
                {/* <button onClick={() => handleForcePurchase()}>Force Purchased</button> */}
            </div>
        </div>
    );
}

export const PaypalPaynowButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {has_active_subscription} = value
    const subscription_id = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.transaction_id ? has_active_subscription.purchase_micro_service.transaction_id : null
    const url = process.env.REACT_APP_ENV === 'prod' ? `https://www.paypal.com/myaccount/autopay/connect/${subscription_id}` : `https://www.sandbox.paypal.com/myaccount/autopay/connect/${subscription_id}`

    const handleRedirectToPortal = () => {
        window.open(url);
    }

    return (
        <Button
            styleClass={`flex flex-justify-center flex-align-center fk-prime-purchase-pay-button-container pointer p-8-13`}
            text="PayNow"
            textClass="fk-prime-purchase-pay-button-p"
            clickFunc={handleRedirectToPortal}
        />
    )
}

export const PaypalPurchaseButton = () => {
    const value = useContext(ProfileSubscriptionContext);
    const {sessionData, userBoost, session, purchaseCoin, resetState, pillSelect} = value
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const s_email = sessionData && sessionData.s_email ? sessionData.s_email : null
    const boostDetails = userBoost && userBoost.boostDetails ? userBoost.boostDetails : [] 
    const sg_price = userBoost && userBoost.boostDetails && userBoost.boostDetails.sg_price ? userBoost.boostDetails.sg_price : 0
    const boostOtpDetails = userBoost && userBoost.boostOtpDetails ? userBoost.boostOtpDetails : [] 
    const boostOtpTotal = userBoost && userBoost.boostOtpTotal ? userBoost.boostOtpTotal : 0

    const [lifetimeItem, setLifetimeItem] = useState([])

    const processPurchaseUnits = () => {
        let arr = []
        boostOtpDetails.map(data => {
            const price = data && data.sg_price ? data.sg_price : 1
            const quantity = data && data.quantity ? data.quantity : 1

            const obj = {
                name: data.name,
                description: data.description,
                unit_amount: {
                    currency_code: "SGD",
                    value: price.toFixed(2)
                },
                quantity: quantity
            }
            arr.push(obj)
        })
        setLifetimeItem(arr)
    }

    const paypalPlanId = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()
    const [paypalError, setPaypalError] = useState(false)
    const service_name = boostDetails.service_name

    const boostDetailsObj = {
        "type": boostDetails.type,
        "user_id": parseInt(userId),
        "coin": boostDetails.credit ? parseInt(boostDetails.credit) : 0,
        "id": boostDetails.id ? parseInt(boostDetails.id) : 0,
        "sg_price": sg_price,
        "description": boostDetails.description,
        "method": "paypal",
        "currency": "sgd",
        "s_email": s_email,
        "name": service_name,
        "customer": "",
    }

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        if (pillSelect === 2) {
            processPurchaseUnits()
        }
    }, [pillSelect])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [boostOtpDetails, boostDetailsObj])

    const handleForcePurchase = () => {
        if (pillSelect === 1) {
            const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
            const boostData = {payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
            purchaseCoin(boostData, { order_id: 'data.subscriptionID' })
        }
        else {
            const line_items_data = parsedLineItems(boostOtpDetails, 'paypal')
            const boostData = {payment_method: 'paypal', type: 'otp', total_amount: boostOtpTotal.toFixed(2), line_items_detail: line_items_data }
            purchaseCoin(boostData, { order_id: 'order.id' })
        }
    }

    const processPaypal = () => {
        const script = document.createElement("script")
        const paypalSrc =  pillSelect === 1 ? `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription` : `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`

        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                if (pillSelect === 1) {
                    window.paypal.Buttons({
                        style: {
                            label: 'paypal',
                            width: '50%'
                        },
                        createSubscription: (data, actions) => {
                            return actions.subscription.create({
                              'plan_id': paypalPlanId
                            });
                        },
                        onApprove: async (data, actions) => {
                            const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
                            const boostData = {credit: null, payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
                            purchaseCoin(boostData, { order_id: data.subscriptionID })
                        },
                        onError: async (e) => {
                            const error = await e
                            console.log('error', error)
                            setPaypalError(JSON.stringify(error))
                            setTimeout(() => {
                                setPaypalError(false)
                                resetState()
                            }, 3000)
                        }
                    }).render(paypalRef);
                }
                else {
                    window.paypal.Buttons({
                        style: {
                            label: 'paypal',
                            width: '50%'
                        },
                        createOrder: (data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        description: 'Fk Lifetime Plan',
                                        amount: {
                                            currency_code: "SGD",
                                            value: boostOtpTotal.toFixed(2),
                                            breakdown: {
                                                item_total: {
                                                    currency_code: "SGD",
                                                    value: boostOtpTotal.toFixed(2)
                                                }
                                            }
                                        },
                                        items: lifetimeItem
                                    }
                                ]
                            })
                        },
                        onApprove: async (data, actions) => {
                            const order = await actions.order.capture()
                            if (order.status === 'COMPLETED') {
                                const line_items_data = parsedLineItems(boostOtpDetails, 'paypal')
                                const boostData = {credit: null, payment_method: 'paypal', type: 'otp', total_amount: boostOtpTotal.toFixed(2), line_items_detail: line_items_data }
                                purchaseCoin(boostData, { order_id: order.id })

                            } else {
                                setPaypalError("Error processing payment, try again.")
                            }
                        },
                        onError: async (e) => {
                            const error = await e
                            console.log('error', error)
                            setPaypalError(JSON.stringify(error))
                            setTimeout(() => {
                                setPaypalError(false)
                                resetState()
                            }, 3000)
                        }
                    }).render(paypalRef);
                }
            })
        }
    }
 
    return (
        <div>
            <div>
                    { paypalError ? 
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ color: 'red' }}>{paypalError}</p>
                        </div> 
                    : 
                        <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                            <div ref={v => (paypalRef = v)}></div>
                        </div>
                    }
            </div>
        </div>
        // <div onClick={() => handleForcePurchase()} style={{position: 'absolute', height: 200}}>Force Purchased</div>
    );
}


