import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { adminDashboardLoad } from '../../../redux/action'
import { TodayListingChart, ThisWeekListingChart, MonthlyListingChart, YearlyListingChart,
  TodaySignupChart, ThisWeekSignupChart, ThisMonthSignupChart, ThisYearSignupChart,
  TodayCoinPurchaseChart, ThisWeekCoinPurchaseChart, ThisMonthCoinPurchaseChart, ThisYearCoinPurchaseChart
} from './components/CustomComponent'
import Loader from '../../../common/component/loader'
import {  AdminDashboardContext } from '../../../common/context'
import './style.css'

const AdminDashboard = props => {
  const { adminDashboardIsLoading, adminDashboardError, 
    finalTodayPostingPerUserData, finalWeekPostingPerUser, finalMonthPostPerUser, finalYearPostPerUser,
    finalSignUpToday, finalSignUpThisWeek, finalSignUpThisMonth, finalSignUpThisYear,
    finalPurchaseToday, finalPurchaseThisWeek, finalPurchaseThisMonth, finalPurchaseThisYear
  } = props.admin

  useEffect(() => {
    //change type to TODAY / THIS_MONTH / THIS_YEAR as per use case
    props.adminDashboardLoad({type: 'THIS_YEAR'})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return ( 
    <section className="page-content">
      { adminDashboardIsLoading ? <Loader /> :
          adminDashboardError ? <span className='color-red'>{adminDashboardError}</span> :
                <AdminDashboardContext.Provider value={{ 
                  finalTodayPostingPerUserData, finalWeekPostingPerUser, finalMonthPostPerUser, finalYearPostPerUser,
                  finalSignUpToday, finalSignUpThisWeek, finalSignUpThisMonth, finalSignUpThisYear,
                  finalPurchaseToday, finalPurchaseThisWeek, finalPurchaseThisMonth, finalPurchaseThisYear
                  }}>
                  <h2 className='chart-type-title'>Item</h2>
                  <section className="charts-container">
                  <TodayListingChart />
                  <ThisWeekListingChart />
                  <MonthlyListingChart />
                  <YearlyListingChart />
                  </section>
                  <h2 className='chart-type-title'>User</h2>
                  <section className="charts-container">
                  <TodaySignupChart />
                  <ThisWeekSignupChart />
                  <ThisMonthSignupChart />
                  <ThisYearSignupChart />
                  </section>
                  <h2 className='chart-type-title'>Coin Purchase</h2>
                  <section className="charts-container">
                  <TodayCoinPurchaseChart />
                  <ThisWeekCoinPurchaseChart />
                  <ThisMonthCoinPurchaseChart />
                  <ThisYearCoinPurchaseChart />
                  </section>
                </AdminDashboardContext.Provider>  
      }

    </section>
  );
  }

const mapStateToProps = store => {
  return {
    admin: store.adminReducer,
  };
};

const mapActionToProps = {
  adminDashboardLoad
};

export default connect(mapStateToProps,mapActionToProps)(AdminDashboard);