import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { searchWordLoad, searchWordMoreLoad, searchWordReset } from '../../../../redux/action'
import FKImage from '../../../../common/component/image'
import Loader from '../../../../common/component/loader'
import SocialAvatar from '../../../../common/component/socialAvatar'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'

const UserSearch = (props) => {
    const location = useLocation()
    const path = location.pathname
    const search_words = path.slice('13')
    const search_word = decodeURIComponent(search_words)
    const keywordsType = 'user'
    const recent = 'recent'
    const { isLoading, pages, total, isLoadingMore } = props.search
    const session_id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : []
    const data = props.search && props.search.data ? props.search.data : []

    useEffect(() => {
        props.searchWordLoad({ first: 20, page: 1, key: search_word, type: keywordsType, filter: recent })
        // eslint-disable-next-line 
    }, [search_word])

    useEffect(() => {
        return () => cleanUp()
    }, []);

    const cleanUp = () => {
        props.searchWordReset()
    }
    const handleLoadMore = () => {
        props.searchWordMoreLoad({ first: 20, page: pages, key: search_word, type: keywordsType, filter: recent })
    }

    return (
        <>
            <div style={{ position: 'relative', height: 'auto' }}>
                <div className="userSearchContainer">
                    {
                        isLoading ? <Loader /> :
                            data.length === 0 ? <div><p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 20, lineHeight: '20px' }}>{`"`}<b>{search_word}</b>{`" ${keywordsType} didn't match your search. Try searching other usernames`}</p></div> :
                                <div className="userSearchResults">
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 180px))', gridGap: '10px' }}>

                                            {
                                                data.map((user, index) => {
                                                    const pk_i_id = user.pk_i_id
                                                    const name = user.s_name
                                                    const username = user.s_username
                                                    const image_resource = user.oc_t_user_resource ? user.oc_t_user_resource : null
                                                    const items_count = user.items_count ? user.items_count : 'No'
                                                    const user_social_id = user && user.social && user.social[0] && user.social[0].data && user.social[0].data.id ? user.social[0].data.id : ''
                                                    const socialImageSrc = user && user.social && user.social[0] && user.social[0].data
                                                        && user.social[0].data.picture && user.social[0].data.picture.data && user.social[0].data.picture.data.url ? user.social[0].data.picture.data.url : null

                                                    return <div key={index} style={{ height: '250px' }}>
                                                        <Link to={session_id === pk_i_id ? `/user/listing` : `/user/${pk_i_id}/listing`} className="userSearchGrid" data={[pk_i_id, name, username, image_resource]}>
                                                            <div className="userSearchAvatar">
                                                                {socialImageSrc ? <SocialAvatar id={user_social_id} w={70} h={70} url={socialImageSrc}/> : image_resource ? <FKImage images={[image_resource]} type="avatar" alt="avatar" /> : <div style={{ borderRadius: "50%", border: "0.5px solid rgba(189, 195, 199,0.4)", padding: "5px", display: 'flex', alignItems: 'center' }}><img src={DefaultPhoto} width={70} height={70} style={{ borderRadius: "50%" }} /> </div>}

                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px', justifyContent: 'center', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <h3>{`${username}`}</h3>
                                                                <p style={{ fontStyle: 'normal', fontWeight: '300', fontSize: 12, lineHeight: '12px', color: '#232A34' }}>{`${items_count} listings`}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                    }

                </div>
            </div>

            {isLoading || isLoadingMore ? <p style={{ marginTop: 130, textAlign: 'center' }}>Loading...</p> :
                !isLoading && data.length === 0 ? <p style={{ marginTop: 130, textAlign: 'center' }}></p> :
                    data.length < total - 1 ?
                        <div className="paginator-main-container" style={{ marginTop: 130, textAlign: 'center' }}><div onClick={handleLoadMore} className="loadMoreBtn">{`Load More`}</div></div> :
                        <p style={{ marginTop: 130, textAlign: 'center' }}><b>End of result</b></p>
            }

        </>
    );
}

const mapStateToProps = store => {
    return {
        search: store.searchReducer,
    }
}

const mapActionToProps = {
    searchWordLoad,
    searchWordMoreLoad,
    searchWordReset
}

export default connect(mapStateToProps, mapActionToProps)(UserSearch)