import React, {useContext} from 'react';
import { MoneyIcon } from '../../../../../../../assets/svg'
import { CoinContext } from "../../../index";
import { styles } from './style'
import paypalLogo from '../../../../../../../assets/images/Credit-Card-Logo/paypal.png'
import visaLogo from '../../../../../../../assets/images/Credit-Card-Logo/visa.png'
import moneyCard from '../../../../../../../assets/images/Credit-Card-Logo/money-card.png'
import masterCardLogo from '../../../../../../../assets/images/Credit-Card-Logo/mastercard.png'
import amex from '../../../../../../../assets/images/Credit-Card-Logo/american-express.png'

const Second = () => {
    const {purchaseData, handleBack, handleNext, handleMethodChange } = useContext(CoinContext);

    return (
        <div style={styles.container}>
           

            <div style={styles.bundleContainer}>
                <div style={{width: '100%', borderBottom: '0.5px solid #C4C4C4', marginBottom: '31.5px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 16}} >
                        <div style={{display: 'flex', flexDirection: 'row'}}> <MoneyIcon /> <p style={{marginLeft: '8.6px'}}>{`${purchaseData.credit}`}</p></div>
                        <div onClick={() => handleBack()}>
                            <p style={{fontStyle: 'normal', fontWeight: '600', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#FB770D', cursor: 'pointer'}}>{`Change`}</p>
                        </div>
                    </div>
                </div>
               

                <div style={styles.methodContainer} onChange={(event) => handleMethodChange(event.target.value)}> 
                    <p style={{fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px', letterSpacing: '0.44px'}} className="text-black">Payment Method</p>
                    <div style={styles.radioCont}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
    <input type="radio" value="paypal" name="payment_method" defaultChecked={purchaseData.payment_method === 'paypal' ? true : false}/> 
    <p style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '0.44px', marginLeft: '12px'}} className="text-black">{`Paypal`}</p>
                            </div>
                            <div style={{flex: 1, textAlign: 'right'}}>
                                <img src={paypalLogo} width="34px" height="34px"/>
                            </div>
                        </div>
                    </div>
                    <div style={styles.radioCont}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                <input type="radio" value="stripe" name="payment_method" defaultChecked={purchaseData.payment_method === 'stripe' ? true : false}/>
                                <p style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '0.44px', marginLeft: '12px'}} className="text-black">{`Credit/Debit Card`}</p>
                            </div>
                            <div style={{display: 'flex',flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignItems: 'center'}}>
                               <img src={visaLogo} alt="visa" style={{margin: '0px 3px'}}/>
                               <img src={masterCardLogo} alt="mastercard" style={{margin: '0px 3px'}}/>
                               <img src={moneyCard} alt="discover" style={{margin: '0px 3px'}}/>
                               <img src={amex} alt="amex" />
                            </div>
                        </div>
                    </div>   
                    {/* <div style={styles.radioCont}>
                        <input type="radio" value="paynow" name="payment_method" defaultChecked={purchaseData.payment_method === 'paynow' ? true : false} disabled/> 
                        <span style={{color: 'gray'}}> PayNow (Coming soon)</span>
                    </div> */}
                </div>

                <div className="default-btn pay-btn" style={{marginBottom: 20}} onClick={() => handleNext()}>
        <div style={{flex: 1, textAlign: 'center'}}><p className="text-white">{`Pay ${purchaseData.sg_price}$`}</p></div>
                </div>
            </div>
        </div>
    );
}

export default Second;