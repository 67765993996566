import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { categoryLoad } from '../../../../redux/action'

const Category = (props) => {
    const { name, onChange, selectedId, isShowEdit } = props
    const { isLoading, data, error } = props.category
    const location = useLocation()
    const pname = location.pathname

    useEffect(() => {
        props.categoryLoad()
    // eslint-disable-next-line    
    }, [])

    return (

        isLoading ? <h5 className='text-align-center'>Loading... </h5> :
            error ? <h5 className='text-align-center color-red'>Error... </h5> :
                <div className="category-input-container">
                  <div className="category-input-sub-container">
                    <div className="category-input-main-container">
                    <label className={`category-input-label ${pname.includes('new') ? `t-70percent` : ``}`}>{`Category`}</label>
                    <select id={name} name={name} style={style.input.formOption} className="category-select-container" onChange={(event) => onChange(event)} required disabled={!isShowEdit ? true : isShowEdit === true ? false : true} >
                        <option disabled selected>{`Select a category`}</option>
                        {
                          data.map((item, index) => {
                            let arr = []
                            const catName = item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name : false
                            const isSelected = selectedId === item.pk_i_id ? true : false
                            if (catName) {
                                arr.push(
                                  <option key={index} value={item.pk_i_id} data-id={item.oc_t_category_descriptions.fk_i_category_id} selected={isSelected} data-cat-name={catName}>{`${catName}`}</option>
                                )
                            }
                            return arr
                          })
                        }
                    </select>
                    </div>
                    </div>
                </div>
    );
};

const style = {
    label: {
      padding: '4px 50px 0px 0px',
      display: 'inline-block',
      marginBottom: '3px',
      fontWeight: '700',
      color: '#333'
    },
    div: {
      headTitle: {
        textAlign: 'center',
      },
      formGroup: {
        marginBottom: '1rem',
      }
    },
    input: {
      formInput: {
        padding: '10px',
        border: '1px solid rgba(230, 126, 34,0.7)',
        borderRadius: '5px',
        width: '100%',
      },
      formOption: {
        padding: '10px',
        // border: '1px solid rgba(127, 140, 141,0.7)',
        // background: 'rgba(236, 240, 241,1.0)',
        borderRadius: '5px',
        width: '100%',
        fontSize: 16
      },
    }
}

const mapStateToProps = store => {
    return {
      category: store.categoryReducer,
    };
  };
  
const mapActionToProps = {
    categoryLoad
}

export default connect(mapStateToProps, mapActionToProps)(Category);
