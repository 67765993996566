import React, { useState } from 'react';
import {TrashCanIcon} from '../../../../assets/svg'

const DragableLayout = (props) => {
  
  const { REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX, REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER } = process.env;
  
  //imageData is array of image files
  //setImageData function that updates imageData
  //import this file and add the function below to your Dropzone component 

  // const renderPreview = () => {
  //   return <DragableLayout imageData={imageData} setImageData={setImageData}/>
  // }

  //Use the function as LayoutComponent on your Dropzone, sample below
  // LayoutComponent={imageData.length > 0 ? () => renderPreview() : null}

  const {imageData, setImageData, handleChangeStatus, isFromEdit, editRemoveImage} = props
  const [forceRender, toggleForceRender] = useState(false)
  
  const togRender = () => {
      toggleForceRender(!forceRender)
  }

  const dragStart = (event) => {
    event.dataTransfer.setData("Image", event.target.id);
  }

  const handleTouchMove = (event) => {
    event.dataTransfer.setData("Image", event.target.id);
  }

  const handleTouchEnd = (event) => {
    event.preventDefault();
    const fromData = event.dataTransfer.getData("Image");
    const splitFromData = fromData.split(':')
    const fromIndex = splitFromData[1]

    const splitToData = event.target.id.split(':')
    const toIndex = splitToData[1]

    const updatedImageArray = arrangeArray(imageData, fromIndex, toIndex)
    setImageData(updatedImageArray)
    togRender()
  }

  const allowDrop = (event) =>{
    event.preventDefault();
  }

  // const removeImage = (item, func) => {
  //   handleChangeStatus(item, 'removed')
  //   func()
  // }

  // const changeAction = (index, item, func) => {
  //   return isFromEdit ? editRemoveImage(imageData, setImageData, index) : removeImage(item, func)
  // } 

  const removeImage = (item) => {
    handleChangeStatus(item, 'removed')
    if(item.remove) {
      item.remove()
    }
  }

  const changeAction = (index, item) => {
    removeImage(item)
  } 

  const onDrop = (event) => {
    event.preventDefault();
    const fromData = event.dataTransfer.getData("Image");
    const splitFromData = fromData.split(':')
    const fromIndex = splitFromData[1]

    const splitToData = event.target.id.split(':')
    const toIndex = splitToData[1]

    const updatedImageArray = arrangeArray(imageData, fromIndex, toIndex)
    setImageData(updatedImageArray)
    togRender()
  }

  const arrangeArray = (arr, old_index, new_index) =>{
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
    return arr;
  }

  return (<>
            {imageData.map((item, index) =>{
                    const source = item && item.meta && item.meta.previewUrl ? item.meta.previewUrl : ''
                    const name = item && item.meta && item.meta.name ? item.meta.name : ''
                    const metaId = item && item.meta && item.meta.id ? item.meta.id : null
                    const id = item.id ? item.id : 0
                    const itemId = item.id ? item.id : ''
                    const imgSrc = item.original ? item.original : ''
                    return (
                            <div 
                              draggable="true" 
                              className="droptarget" 
                              onDrop={onDrop} 
                              onTouchMove={(e) => handleTouchMove(e)} 
                              onTouchEnd={(e) => handleTouchEnd(e)}
                              id={`${metaId}:${index}`} 
                              onDragOver={allowDrop}>

                              <img draggable="true" src={isFromEdit ? id ? imgSrc : source : source} width={80} height={91} name={isFromEdit ? itemId : name} id={ isFromEdit ? `${itemId}:${index}` :`${metaId}:${index}`} onDragStart={dragStart}/>
                              <div className="dzu-previewStatusContainer" onClick={() => changeAction(index, item, item.remove) }>
                              <span className="remove-trash-icon-container"><TrashCanIcon /></span>
                              </div>
                            </div> 
                    )
                  })
            }
          </>
  )

}

export default DragableLayout
