import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { meLoad } from '../../../../../../redux/action'
import FKImage from '../../../../../../common/component/image'
import Loader from '../../../../../../common/component/loader'
import DefaultPhoto from '../../../../../../assets/images/defaultAvatar.png'
import SocialAvatar from '../../../../../../common/component/socialAvatar'

const ProfileBar = props => {
    const userDetails = props && props.me && props.me.data ? props.me.data : null
    const username = userDetails && userDetails.s_username ? userDetails.s_username : null
    const email = userDetails && userDetails.s_email ? userDetails.s_email : null
    const coin = userDetails && userDetails.wallet ? userDetails.wallet : null
    const b_active = userDetails && userDetails.b_active ? userDetails.b_active : false
    const location = useLocation()
    const pathname = location.pathname
    const splitPath = pathname.split('/')
    const userId = splitPath && splitPath[4] ? splitPath[4] : null 
    const session_id = props.session && props.session.fkv2_session &&  props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const {isLoading} = props.me
    const profile_resource = props.me && props.me.data && props.me.data.oc_t_user_resource ? props.me.data.oc_t_user_resource : null 
    let user_social_id;
    if ( 
        pathname.includes(session_id) &&
        props.session && props.session.fkv2_session && props.session.fkv2_session.social && props.session.fkv2_session.social === 'facebook') {
        user_social_id = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
        && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.id ? props.avatar.avatarData.social[0].data.id : ''
    } else {
      user_social_id = props && props.me && props.me.data && props.me.data.oc_t_user_resource ? props.me.data.oc_t_user_resource : 
          props && props.me && props.me.data && props.me.data.social && props.me.data.social[0]  && props.me.data.social[0].data && props.me.data.social[0].data.id ? props.me.data.social[0].data.id : ''
    }

    const user_social_resource = props && props.me && props.me.data && props.me.data.oc_t_user_resource ? props.me.data.oc_t_user_resource : 
    props && props.me && props.me.data && props.me.data.social && props.me.data.social[0]  && props.me.data.social[0].data && props.me.data.social[0].data.picture && props.me.data.social[0].data.picture.data && props.me.data.social[0].data.picture.data.url ? props.me.data.social[0].data.picture.data.url : null

    useEffect(() => {
        if(userId){
          props.meLoad({id: `${userId}/`})
        }
      // eslint-disable-next-line
      }, [userId])

    return (
        <div className="my-profile-container">
          {
            isLoading ?
            <Loader />
            :
            <div className="my-profile-info">
              <div className="profile-avatar">
                { 
                  user_social_id ? <SocialAvatar id={user_social_id} w={110} h={110} url={user_social_resource}/> : 
                  profile_resource ?  <FKImage images={[profile_resource]} type="avatar_preview" alt="seller avatar"/> : 
                  <img src={DefaultPhoto} width={110} height={110} alt="Default Photo Avatar" />
                }
              </div>
                <div className="profile-info">
                <h2 style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{username}</h2>
                    <div>
                    <p className={b_active ? 'green' : 'red'}>{b_active ? 'Active' : 'Not Active'}</p>
                    <p>{`Singapore`}</p>
                    <p>{email}</p>
                    <p>Coin: ${coin ? coin : 0}</p>
                    </div>
                </div>
            <div />
            </div>
          }
        </div>
    );
}

const mapStateToProps = store => {
    return {
      me: store.userReducer,
      avatar: store.avatarReducer,
    };
  };
  
  const mapActionToProps = {
    meLoad
  };

export default connect(mapStateToProps,mapActionToProps)(ProfileBar)