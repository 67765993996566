import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import {GreenOnlineIcon, OrangeDownloadIcon, FKCoinsIcon, CloseIcon, CoinsIcon, GrayOfflineIcon, ErrorPaymentIcon} from '../../../../../assets/svg'
import FKImage from '../../../../../common/component/image'
import {WTT, WTB} from '../../../../../common/component/label'
import {RollerLoader, CustomLoader} from '../../../../../common/component/loader'
import {numberWithCommas} from '../../../../../common/helper/utility'
import {theme} from '../../../../../common/theme'
import {IconContainerClickable, Text, LinkText, PaymentSuccess} from '../../../../../common/component/customComponents'
import {PaypalRenewButton, PaypalPaynowButton} from '../paymentMethod/PaypalButton'
import {StripeRenewButton, StripePaynowButton} from '../paymentMethod/StripeButton'
import First from '../steps/first'
import Second from '../steps/second'
import {ProfileSubscriptionContext} from '../../../../../common/context'

export const SubscriptionContainer = ({individualAds, handleUseNowBtn}) => {
    const {service_detail} = individualAds
    const individualAdsName = service_detail && service_detail.name ? service_detail.name : ''
    const dt_purchased = individualAds && individualAds.purchase_date ? moment(individualAds.purchase_date).format('MMMM DD, YYYY') : ''
    const payment_method = individualAds && individualAds.payment_method ? individualAds.payment_method.charAt(0).toUpperCase() + individualAds.payment_method.slice(1) : ''
    const purchase_amount = service_detail && service_detail.sg_price !== 0 ? service_detail.sg_price : service_detail.credit
    const isCoin = service_detail && service_detail.sg_price === 0 ? true: false
    const boostDetail = service_detail && service_detail.description ? service_detail.description : ''
    const boostStatus = individualAds ? individualAds.item_id === null ? 
    individualAds.type === 'cover_photo' || 
    individualAds.type === 'live_listing' || 
    individualAds.type === 'youtube_link' ? 'completed' : 'NotUsed' : 'used' : 'completed'

    return (
        <div className="flex flex-d-row w-100 mb-30 mt-30 ">
            <div className="flex flex-d-column individual-ads-profile-sub-container">
                {/* <p className="combo-ads-label-p">{`Combo Ads`}</p> */}
                <p className="individual-boost-name-p">{individualAdsName}</p>
                <div className="flex flex-d-row fk-prime-details-container flex-align-center">
                    <p className="fk-prime-label-p">{`Date purchased: `}</p> &nbsp;
                    <p>{dt_purchased}</p>
                </div>
                <div className="flex flex-d-row fk-prime-details-container flex-align-center">
                    <p className="fk-prime-label-p">{`Payment method: `}</p> &nbsp;
                    <p>{payment_method}</p>
                </div>
            </div>
            <div className="flex flex-justify-center flex-align-center mr-56">
                <div className="border-vertical-line"/>
            </div>
            <div className="w-100 flex flex-d-row flex-justify-between">
                <div className="flex flex-d-column flex-justify-center ">
                    <div className="flex flex-d-row">
                        {isCoin ? 
                            <div className='flex flex-align-center individual-ads-total-purchase-p'>
                                <CoinsIcon/> <p className='ml-7 mt-5'>{purchase_amount}</p>
                            </div> :
                            <p className="individual-ads-total-purchase-p">{`S$ ${purchase_amount}`}</p>
                        }
                        
                    </div>
                    <div className="flex flex-justify-end flex-align-end h-100">
                        <p className="individual-ads-detail-p">{boostDetail}</p>
                    </div>
                </div>
                {
                    boostStatus === 'NotUsed' ?
                    <div className="flex flex-justify-center flex-align-center">
                        <div className="use-now-btn-individual-ads" onClick={() => handleUseNowBtn(individualAds)}>
                            <p className="no-select">{`Use now`}</p>
                        </div>
                    </div> : null
                }
                {
                     boostStatus === 'used' ?
                     <div className="flex flex-justify-center flex-align-center">
                         <div className="used-btn-individual-ads">
                             <p className="no-select">{`Used`}</p>
                         </div>
                     </div> : null
                }
                {
                    boostStatus === 'paid' || boostStatus === 'completed' ?
                    <div className="flex flex-justify-center flex-align-center">
                        <div className="active-btn-individual-ads">
                            <p className="no-select">{`Active`}</p>
                        </div>
                    </div> : null
                }
               
            </div>
        </div>
    )
}

export const SubscriptionMainContainer = ({obj, handleUseNowBtn}) => {
    return ( 
        obj.map((i, key) => {
            return (
                <div key={key} className="individual-ads-card-container">
                    {SubscriptionContainer ({individualAds: i, handleUseNowBtn: handleUseNowBtn})}
                </div>
            )
        })
    )
}

export const IndividualPlansDivider = () => {
    return (
        <div className="mobile-individual-plans-divider-border"/>
    )
}

export const MobileSubscriptionContainer = ({individualAds, handleUseNowBtn}) => {
    const { service_detail } = individualAds
    const name = service_detail && service_detail.name ? service_detail.name : ''
    const date_purchased = individualAds.purchase_date ? moment(individualAds.purchase_date).format('MMMM DD, YYYY') : ''
    const payment_method = individualAds.payment_method ? individualAds.payment_method.charAt(0).toUpperCase() + individualAds.payment_method.slice(1) : ''
    const total_price = service_detail.sg_price ? service_detail.sg_price : 0
    const description = service_detail.description ? service_detail.description : ''
    const boostStatus = individualAds ? individualAds.item_id === null ? 
    individualAds.type === 'cover_photo' || 
    individualAds.type === 'live_listing' || 
    individualAds.type === 'youtube_link' ? 'completed' : 'NotUsed' : 'used' : 'completed'
    return (
        <div className="pt-30 pb-30">
            <div className="mb-20">
                <p className="individual-boost-name-p">{name}</p>
                <div className="flex flex-d-row flex-align-center">
                    <p className="fk-prime-label-p">{`Date purchased: `}</p> &nbsp;
                    <p>{date_purchased}</p>
                </div>
                <div className="flex flex-d-row flex-align-center mt-6">
                    <p className="fk-prime-label-p">{`Payment method: `}</p> &nbsp;
                    <p>{payment_method}</p>
                </div>
            </div>
            <div className="flex flex-justify-center flex-align-center mb-20">
                {IndividualPlansDivider()}
            </div>
           <div >
                <div className="flex flex-d-row">
                    {individualAds.payment_method === 'paypal' || individualAds.payment_method === 'stripe' ? <p className="individual-ads-total-purchase-p">{`S$`}</p> : <FKCoinsIcon />}
                    <p className="individual-ads-total-purchase-p">{total_price}</p>
                </div>
                <div>
                    <p className="individual-ads-detail-p">{description}</p>
                </div>
           </div>
           {
                boostStatus === 'NotUsed' ?
                <div className="flex flex-justify-center flex-align-center">
                    <div className="use-now-btn-individual-ads" onClick={() => handleUseNowBtn(individualAds)}>
                        <p className="no-select">{`Use now`}</p>
                    </div>
                </div> : null
            }
            {
                boostStatus === 'used' ?
                <div className="flex flex-justify-center flex-align-center">
                    <div className="used-btn-individual-ads">
                        <p className="no-select">{`Used`}</p>
                    </div>
                </div> : null
            }
            {
                boostStatus === 'paid' || boostStatus === 'completed' ?
                <div className="flex flex-justify-center flex-align-center">
                    <div className="active-btn-individual-ads">
                        <p className="no-select">{`Active`}</p>
                    </div>
                </div> : null
            }
        </div>
    )
}

export const MobileSubscriptionMainContainer = ({obj, handleUseNowBtn}) => {
    return (
        obj.map((i, key) => {
            return (
                <div key={key} className="individual-ads-card-container">
                    {MobileSubscriptionContainer ({individualAds: i, handleUseNowBtn: handleUseNowBtn})}
                </div>
            )
        })
    )
}

export const SubscriptionHeader = ({title, styleClass, textClass}) => {
    return (
        <div className={styleClass}>
            <p className={textClass}>{title}</p>
        </div>
    )
}

export const SubscriptionLabel = (status) => {
    let label
    if(status === 'SUSPENDED' || status === 'unpaid'){
        label = 'Subscription Not Active '
    }
    if(status === 'ACTIVE' || status === 'active'){
        label = 'Active Subscription'
    }
    if(status === 'CANCELLED' || status === 'canceled'){
        label = 'Last Subscription'
    }
    if(status === 'past_due' || status === 'EXPIRED'){
        label = 'Last Subscription'
    }
    return (
        <p className="ml-9 fk-prime-active-subs-label-p">{label}</p>
    )
}

export const ActiveSubscriptionLabel = ({status, type}) => {
    const boostStatus = status && status.status ? status.status : null
    return(
        <div className="flex flex-d-row flex-align-center mb-20">
            {boostStatus ? boostStatus.includes('active') || boostStatus.includes('ACTIVE') ? <GreenOnlineIcon /> : <GrayOfflineIcon /> : null}
            { boostStatus ? SubscriptionLabel(boostStatus) : null}
        </div>
    )
}

export const Divider = () => {
    return (
        <div className="divider-border-line"/>
    )
}

export const FKPrimeDetails = ({fkPrime}) => {
    const months = fkPrime && fkPrime[0] && fkPrime[0].name ? fkPrime[0].name.match(/\d+/)[0] : 0
    const purchased_date = fkPrime && fkPrime[0] && fkPrime[0].purchase_date ? moment(fkPrime[0].purchase_date).format('MMMM DD, YYYY') : ''
    const payment_method = fkPrime && fkPrime[0] && fkPrime[0].payment_method ? fkPrime[0].payment_method.charAt(0).toUpperCase() + fkPrime[0].payment_method.slice(1) : ''
    const service_detail_month = fkPrime && fkPrime[0] && fkPrime[0].service_detail && fkPrime[0].service_detail.name ? fkPrime[0].service_detail.name : ''

    return (
        <div className="flex flex-d-column flex-justify-end flex-align-end">
           <p className="w-100 fkprime-months-p">{`FK Prime - ${months} ${service_detail_month.toLowerCase().includes('month') ? 'month' : 'year'}${parseInt(months) < 2 ? '' : 's'}`}</p>
           <div className="flex flex-d-row w-100 fk-prime-details-container">
                <p className="fk-prime-label-p">{`Date purchased: `}</p>
                <p>{purchased_date}</p>
           </div>
           <div className="flex flex-d-row w-100 fk-prime-details-container">
                <p className="fk-prime-label-p">{`Payment method: `}</p>
                <p>{payment_method}</p>
           </div>
        </div>
    )
}

export const MobileFKPrimeDetails = ({fkPrime, handleFooterFunc, status}) => {
    const months = fkPrime && fkPrime[0] && fkPrime[0].name ? fkPrime[0].name.match(/\d+/)[0] : 0
    const purchased_date = fkPrime && fkPrime[0] && fkPrime[0].purchase_date ? moment(fkPrime[0].purchase_date).format('MMMM DD, YYYY') : ''
    const payment_method = fkPrime && fkPrime[0] && fkPrime[0].payment_method ? fkPrime[0].payment_method.charAt(0).toUpperCase() + fkPrime[0].payment_method.slice(1) : ''
    const purchaseStatus = status && status.status ? status.status : ''
    // console.log("status", status)
    return (
        <div className="flex flex-d-column">
            <div className="flex flex-d-row w-100 fk-prime-details-container">
                <p className="fk-prime-label-p">{`Date purchased: `}</p>
                <p className="fk-prime-label-p">{purchased_date}</p>
           </div>
           <div className="flex flex-d-row w-100 fk-prime-details-container">
                <p className="fk-prime-label-p">{`Payment method: `}</p>
                <p className="fk-prime-label-p">{payment_method}</p>
           </div>
           <div className="flex flex-justify-center flex-align-center mt-30">
                <div className="fk-prime-purchase-btn cursor-pointer" onClick={() => handleFooterFunc()}>
                    <p className="fk-prime-purchase-p">{purchaseStatus === 'active' || purchaseStatus === 'ACTIVE' ? 'Upgrade' : purchaseStatus.includes('CANCELLED') || purchaseStatus.includes('canceled') ? `Purchase` : `Pay now`}</p>
                </div>

           </div>
        </div>
    )
}

export const SubsLabelAndInfo = (fkPrime, themeText) => {
    const {fkPrimeAutoRenewDisclaimer, fkPrimeUnableToProcess, fkPrimeCancelDisclaimer} = themeText
    const due_date_format = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.next_billing_time ? moment(fkPrime[0].status.next_billing_time).format('MMMM DD, YYYY') : ''
    const cancel_date_format = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.cancel_date ? moment(fkPrime[0].status.cancel_date).format('MMMM DD, YYYY') : ''
    const status = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.status ? fkPrime[0].status.status : ''
    return (
        <div className="fk-prime-due-date-sub-container">
            <p className={status.toLowerCase().includes('cancel') ? `due-date-cancelled-label-p` : `due-date-label-p`}>{status.includes('CANCELLED') || status.includes('canceled') ? `Cancelled` : `Due date`}</p>
            <p className={status.toLowerCase().includes('cancel') ? `due-date-cancelled-dt-p` : `due-date-dt-p`}>{status.includes('CANCELLED') || status.includes('canceled') ? `${cancel_date_format}` : due_date_format}</p>
           
           {
               status.toLowerCase().includes('past_due') || status.toLowerCase().includes('unpaid') || status.toLowerCase().includes('expired') ?
               <div className="flex flex-d-row">
                   <ErrorPaymentIcon />
                   <p className="due-date-unpaid-disclaim-p">{fkPrimeUnableToProcess.text}</p>
               </div> : status.toLowerCase().includes('cancel') ? <p className="due-date-disclaimer-p">{fkPrimeCancelDisclaimer.text}</p> :
               <p className="due-date-disclaimer-p">{fkPrimeAutoRenewDisclaimer.text}</p>
           } 
        </div>
    )
}
export const DueDateContainer = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {subscription, subscriptionActionModal, toggleSubscriptionRenewData, toggleSubscriptionPaynowData} = value
    const fkPrime = subscription.fkPrime
    const total_purchase = fkPrime && fkPrime[0] && fkPrime[0].service_detail && fkPrime[0].service_detail.sg_price ? fkPrime[0].service_detail.sg_price : ''
    const status = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.status ? fkPrime[0].status.status : ''
    const activeBtn = status ? true : false 

    return (
        <div className="flex flex-d-row flex-justify-between w-100 fk-prime-main-body-due-dt-container">
           {SubsLabelAndInfo(fkPrime, theme.text)}
            <div className="flex flex-d-column flex-justify-center flex-align-center">
                <p className="due-date-total-purchase-p">{`$ ${total_purchase}`}</p>
                <div className={`fk-prime-pay-now-btn ${activeBtn ? `fk-prime-pay-now-btn-pay-pending` : `fk-prime-pay-now-btn-paid`}`} onClick={() => 
                    status.includes('ACTIVE') || status.includes('active') ? subscriptionActionModal(fkPrime, true, 1) :
                    // status.includes('CANCELLED') || status.includes('canceled') ? toggleSubscriptionRenewData(fkPrime, true) : 
                    status.includes('CANCELLED') || status.includes('canceled') ? subscriptionActionModal(fkPrime, true, 1) :
                    toggleSubscriptionPaynowData(fkPrime, true)
                    }>
                    <p>{ status.includes('ACTIVE') || status.includes('active') ? `Upgrade` : status.includes('CANCELLED') || status.includes('canceled') ? `Purchase` : `Pay now`}</p>
                </div>
            </div>
        </div>
    )
}

export const MobileDueDateContainer = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {subscription} = value
    const fkPrime = subscription.fkPrime
    const due_date_format = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.next_billing_time ? moment(fkPrime[0].status.next_billing_time).format('MMMM DD, YYYY') : ''
    const cancel_date_format = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.cancel_date ? moment(fkPrime[0].status.cancel_date).format('MMMM DD, YYYY') : ''
    const status = fkPrime && fkPrime[0] && fkPrime[0].status && fkPrime[0].status.status ? fkPrime[0].status.status : ''
    const {fkPrimeAutoRenewDisclaimer, fkPrimeUnableToProcess, fkPrimeCancelDisclaimer} = theme.text
    return (
        <div className="fk-prime-main-body-due-dt-container">
            <p className={status.toLowerCase().includes('cancel') ? `due-date-cancelled-label-p` : `due-date-label-p`}>{status.includes('CANCELLED') || status.includes('canceled') ? `Cancelled` : `Due date`}</p>
            <p className={status.toLowerCase().includes('cancel') ? `due-date-cancelled-dt-p` : `due-date-dt-p`}>{status.includes('CANCELLED') || status.includes('canceled') ? `${cancel_date_format}` : due_date_format}</p>
            {
               status.toLowerCase().includes('past_due') || status.toLowerCase().includes('unpaid') || status.toLowerCase().includes('expired') ?
               <div className="flex flex-d-row">
                   <ErrorPaymentIcon />
                   <p className="due-date-unpaid-disclaim-p">{fkPrimeUnableToProcess.text}</p>
               </div> : status.toLowerCase().includes('cancel') ? <p className="due-date-disclaimer-p text-gray">{fkPrimeCancelDisclaimer.text}</p> :
               <p className="due-date-disclaimer-p">{fkPrimeAutoRenewDisclaimer.text}</p>
           } 
        </div>
    )
}

const countRemain = (arr) => {
    let remain = 0

    arr.map((a) => {
        return remain = a.item_id === null ? remain + 1 : remain
    })

    return remain
}

export const BoostTitleAndSub = (title, subtitle) => {
    return (
        <div className="flex flex-d-column mb-30">
            <p className="fk-prime-boost-availability-p">{title}</p>
            <p className="fk-prime-boost-availability-sub-p">{subtitle}</p>
        </div>
    )
}

export const MobileBoostContainer = ({boost}) => {
    const {auto_bump, cover_photo, discount_label, highlighted_ad, live_listing, top_ad, youtube_link} = boost

    // const total = boost && boost.total ? boost.total : 0
    // const remaining = boost && boost.remaining ? boost.remaining : null
    // const status = boost && boost.status ? boost.status : null
    // const label = boost && boost.label ? boost.label : null
    return (
        <div className="flex flex-d-row flex-justify-between">
           <div className="flex flex-d-column">
               { BoostTitleAndSub(`x${live_listing.length} Live Listings`, `${countRemain(live_listing)} Remainings`) }
               {/* { BoostTitleAndSub(`${cover_photo.length}`, `${cover_photo.status}`) } */}
               { BoostTitleAndSub(`x${highlighted_ad.length} Highlighted Ad`, `${countRemain(highlighted_ad)} Remainings`) }
               {/* { BoostTitleAndSub(`${discount_label.label}`, `${discount_label.status}`) } */}
           </div>
           <div className="flex flex-d-column flex-justify-start">
               { BoostTitleAndSub(`x${top_ad.length} Top Ad`, `${countRemain(highlighted_ad)} Remainings`) }
               {/* { BoostTitleAndSub(`${youtube_link.label}`, `${youtube_link.status}`) } */}
               { BoostTitleAndSub(`x${auto_bump.length} Auto Bump`, `${countRemain(auto_bump)} Remainings`) }
           </div>
        </div>
    )
}

export const IndividualAdArrHistoryContainer = (arr) => {
    return (
        arr.map((individual, i) => {
            const {service_detail} = individual
            const name = service_detail && service_detail.name ? service_detail.name : ''
            const total_purchase = service_detail && service_detail.sg_price ? service_detail.sg_price : 0
            const transaction_id = individual && individual.transaction_id ? individual.transaction_id : ''
            const isFree = individual ? individual.name.includes('Free') ? true : false : false

            return (
                <tbody key={i} className="no-border pb-30">
                     <tr>
                         <td>{name}</td>
                         <td>{isFree ? `Free` : `$${total_purchase}`}</td>
                         <td>{`-`}</td>
                         <td>{transaction_id}</td>
                         {/* <td><OrangeDownloadIcon /></td> */}
                     </tr>
                </tbody>
            )
        })
    )
}

export const IndividualArrSubscriptionHistory = ({individualArr}) => {
    const autoBumpArr = individualArr && individualArr.auto_bump ? individualArr.auto_bump : []
    const coverPhotoArr = individualArr && individualArr.cover_photo ? individualArr.cover_photo : []
    const highlightedAdArr = individualArr && individualArr.highlighted_ad ? individualArr.highlighted_ad : []
    const liveListingArr = individualArr && individualArr.live_listing ? individualArr.live_listing : []
    const topAdArr = individualArr && individualArr.top_ad ? individualArr.top_ad : []
    const youtubeLinkArr = individualArr && individualArr.youtube_link ? individualArr.youtube_link : []
    // const free_listing = individualArr && individualArr.free_listing ? individualArr.free_listing : []
    return (
        <table className="no-border" cellSpacing="0" cellPadding="0">
             <thead className="divider-border-line">
                <tr className="no-border">
                    <th>{`Subscription`}</th>
                    <th>{`Amount`}</th>
                    <th>{`Billing Period`}</th>
                    <th>{`Transaction No.`}</th>
                    {/* <th>{`Receipt`}</th> */}
                </tr>
           </thead>
           {IndividualAdArrHistoryContainer(autoBumpArr)}
           {IndividualAdArrHistoryContainer(coverPhotoArr)}
           {IndividualAdArrHistoryContainer(highlightedAdArr)}
           {/* {IndividualAdArrHistoryContainer(liveListingArr)} */}
           {IndividualAdArrHistoryContainer(topAdArr)}
           {IndividualAdArrHistoryContainer(youtubeLinkArr)}
           {/* {IndividualAdArrHistoryContainer(free_listing)} */}
        </table>
    )
}
export const FKPrimeSubscriptionHistory = ({subs}) => {
    const fkPrime = subs && subs.fkPrime ? subs.fkPrime : []
    const free_listing = subs && subs.individual && subs.individual.free_listing ? subs.individual.free_listing : []
    const free_list_data = free_listing && free_listing[0] ? free_listing[0] : []

    return (
       <table className="no-border" cellSpacing="0" cellPadding="0">
           <thead className="divider-border-line">
                <tr className="no-border">
                    <th>{`Subscription`}</th>
                    <th>{`Amount`}</th>
                    <th>{`Purchased Date`}</th>
                    <th>{`Billing Period`}</th>
                    <th>{`Transaction No.`}</th>
                    {/* <th>{`Receipt`}</th> */}
                </tr>
           </thead>
           {
               fkPrime.map((prime, i) => {
                   const {service_detail} = prime
                   const months = prime && prime.name ? prime.name.match(/\d+/)[0] : 0
                    const bill_period_start = prime.purchase_date ? moment(prime.purchase_date).format('MM/DD/YYYY') : ''
                    const bill_period_end = prime.due_date ? moment(prime.due_date).format('MM/DD/YYYY') : ''
                    const transaction_no = prime.transaction_id ? prime.transaction_id : ''
                    const total_purchase = service_detail && service_detail.sg_price ? service_detail.sg_price : 0
                    const purchase_date = prime.purchase_date ? moment(prime.purchase_date).format('MM/DD/YYYY HH:mm') : ''
                    const service_detail_month = prime.service_detail && prime.service_detail.name ? prime.service_detail.name : ''
                    // console.log("prime", prime)
                    return (
                        <tbody key={i} className="no-border pb-30">
                            <tr className="row-1st">
                                <td>{`FK Prime - ${months} ${service_detail_month.toLowerCase().includes('month') ? 'Month' : 'Year'}${months > 1 ? 's' : ''}`}</td>
                                <td>{`$${total_purchase}`}</td>
                                <td>{purchase_date}</td>
                                <td>{`${bill_period_start} - ${bill_period_end}`}</td>
                                <td >{transaction_no}</td>
                                {/* <td><OrangeDownloadIcon /></td> */}
                            </tr>
                        </tbody>
                        
                    )
               })
           }
           {
               free_listing.length !== 0 ? 
               <>
               {
                   <tbody >
                       <tr className="last-row">
                           <td>{free_list_data && free_list_data.name ? free_list_data.name : ''}</td>
                           <td>{`Free`}</td>
                           <td>{free_list_data && free_list_data.purchase_date ? moment(free_list_data.purchase_date).format('MM/DD/YYYY HH:mm') : ''}</td>
                           <td>{``}</td>
                           <td>{free_list_data && free_list_data.transaction_id ? free_list_data.transaction_id : ''}</td>
                       </tr>
                   </tbody>
                //    free_listing.map((free, i) => {
                //     const name = free && free.name ? free.name : null
                //     const transaction_id = free && free.transaction_id ? free.transaction_id : null
                //     const purchase_date = free && free.purchase_date ? moment(free.purchase_date).format('MM/DD/YYYY') : ''
                //     return (
                //         <tbody key={i}>
                //             <tr>
                //                 <td>{name}</td>
                //                 <td>{`Free`}</td>
                //                 <td>{purchase_date}</td>
                //                 <td >{transaction_id}</td>
                //                 {/* <td><OrangeDownloadIcon /></td> */}
                //             </tr>
                //         </tbody>
                //     )
                //    })
               }
                </>: null
           }
       </table>
    )
}

export const FKPrimeBoostAvailability = ({fkPrime, has_lifetime_cover_photo, has_lifetime_youtube, status}) => {
    const {auto_bump, cover_photo, discount_label, highlighted_ad, live_listing, top_ad, youtube_link} = fkPrime
    const cover_photo_due_date = cover_photo && cover_photo[0] && cover_photo[0].due_date ? cover_photo[0].due_date : null
    const cover_photo_status = has_lifetime_cover_photo ? 'Active' : cover_photo_due_date ? moment(cover_photo_due_date).format('MMMM DD, YYYY') : 'Not Active'
    const youtube_due_date = youtube_link && youtube_link[0] && youtube_link[0].due_date ? youtube_link[0].due_date : null
    const youtube_status = has_lifetime_youtube ? 'Active' : youtube_due_date ? moment(youtube_due_date).format('MMMM DD, YYYY') : 'Not Active'
    const statusSubs = status && status.status ? status.status : ''
    return (
        <div className="flex flex-d-column pt-20 pb-30">
            <div className="flex flex-d-row flex-justify-evenly mb-30">
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`x${live_listing.length !== 0 ? live_listing.length : 0} Live Listings`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{`${live_listing.length !== 0 ? countRemain(live_listing) : 0} Remainings`}</p>
                </div>
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`x${top_ad.length !== 0 ? top_ad.length : 0} Top Ad`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{`${top_ad.length !== 0 ? countRemain(top_ad) : 0} Remainings`}</p>
                </div>
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`x${highlighted_ad.length !== 0 ? highlighted_ad.length : 0} Highlighted Ad`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{`${highlighted_ad.length !== 0 ? countRemain(highlighted_ad) : 0} Remainings`}</p>
                </div>
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`x${auto_bump.length !== 0 ? auto_bump.length : 0} Auto Bump`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{`${auto_bump.length !== 0 ? countRemain(auto_bump) : 0} Remainings`}</p>
                </div>
            </div>
            <div className="flex flex-d-row flex-justify-evenly">
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`Unlock Cover Photo`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{cover_photo_status}</p>

                </div>
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`Youtube Link`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{youtube_status}</p>
                </div>
                <div className="flex flex-d-column">
                    <p className={`${statusSubs.includes('ACTIVE') || statusSubs.includes('active') ? `` : `text-line-through text-gray`} fk-prime-boost-availability-p`}>{`Discount label`}</p>
                    <p className="fk-prime-boost-availability-sub-p">{`${discount_label && discount_label.status ? discount_label.status === 'not_used' ? 'Not Used' : 'Used' : 'N/A'}`}</p>
                </div>
                <div className="flex flex-d-column">
                    <p className="fk-prime-boost-availability-p">{``}</p>
                    <p className="fk-prime-boost-availability-sub-p">{``}</p>
                </div>
            </div>
        </div>
    )
}

export const SubscriptionDetailsContainer = (label, title, customStyle) => {
    return (
        <div className="flex flex-d-column">
            <p className="mobile-profile-history-label-p">{label}</p>
            <p className={`mobile-profile-history-title-p ${customStyle ? customStyle : ''}`}>{title}</p>
        </div>
    )
}

export const SubscriptionBoostTitleContainer = (label, title) => {
    return (
        <div className="flex flex-d-column">
            <p className="mobile-profile-history-label-p">{label}</p>
            <p className="mobile-profile-history-boost-title-p">{title}</p>
        </div>
    )
}

export const MobileSubscriptionHistory = ({boost}) => {
    const boosts = boost && boost.fkPrime ? boost.fkPrime : []
    const boostIndividual = boost && boost.individual ? boost.individual : []
    const free = boostIndividual && boostIndividual.free_listing ? boostIndividual.free_listing : []
    // console.log("boostusdfksd", boost)
    return (
       <div className="purchase-history-mobile-container">
        {
            boosts.map((item, i) => {
                const service_detail_month = item && item.service_detail && item.service_detail.name ? item.service_detail.name : ''
                const months = service_detail_month ? service_detail_month.match(/\d+/)[0] : 0
                const price = item && item.total_purchase ? item.total_purchase : 0
                const start_dt = item && item.purchase_date ? moment(item.purchase_date).format('MM/DD/YYYY') : ''
                const end_dt = item && item.due_date ? moment(item.due_date).format('MM/DD/YYYY') : ''
                const transaction_id = item && item.transaction_id ? item.transaction_id : ''
                // console.log("item", item)
                return (
                    <>
                        {i === 0 ? null : Divider()}
                        <div key={i}>
                            <div className="flex flex-d-column">
                                <div className="flex flex-d-row flex-align-center flex-justify-between">
                                     { SubscriptionBoostTitleContainer(`Subscription`, `FK Prime - ${months} ${parseInt(months) < 2 ? 'month' : 'months'}`) }
                                     { SubscriptionDetailsContainer(`Amount`, `${price}`, `text-align-end`) }
                                </div>
                                <div className="flex flex-d-row flex-align-center flex-justify-between">
                                     { SubscriptionDetailsContainer(`Billing Period`, `${start_dt} - ${end_dt}`) }
                                     { SubscriptionDetailsContainer(`Transaction No.`, `${transaction_id}`) }
                                </div>
                                <div className="w-100 flex flex-justify-end flex-align-end">
                                    <div className="flex flex-d-column flex-align-center">
                                        {/* <p>{`Receipt`}</p>
                                        <OrangeDownloadIcon /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })
        }
        {Divider()}
        <div>
            <div className="flex flex-d-column">
                <div className="flex flex-d-row flex-align-center flex-justify-between">
                    { SubscriptionBoostTitleContainer(`Subscription`, `${free && free[0].name}`) }
                    { SubscriptionDetailsContainer(`Amount`, `Free`, `text-align-end`) }
                </div>
                <div className="flex flex-d-row flex-align-center flex-justify-between">
                        { SubscriptionDetailsContainer(`Purchased Date`, `${free && free[0].purchase_date ? moment(free[0].purchase_date).format('MM/DD/YYYY'): ''}`) }
                        { SubscriptionDetailsContainer(`Transaction No.`, `${free && free[0].transaction_id}`) }
                </div>
            </div>
        </div>
       </div>
    )
}

export const ChooseListingItemCard = ({item, handleUseNowBtn, handlePromote}) => {
    const pub_dt = item && item.dt_pub_date ? moment(item.dt_pub_date).fromNow() : null
    const item_resource = item && item.oc_t_item_resource ? item.oc_t_item_resource : []
    const item_price = item && item.get_i_price ? numberWithCommas((item.get_i_price).toFixed(2)) : 0
    const item_title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : null
    const post_type = item && item.post_type ? item.post_type : ''
    return (
        <div className="choose-listing-item-body-card">
            <p className="choose-listing-pub-dt-p">{pub_dt}</p>
            <div>
                <FKImage images={item_resource} type="thumbnail"/> 
                <div>
                    <div className="flex flex-justify-between flex-align-center w-100">
                        <p className="choose-list-item-price-p">{item_price}</p>
                        {
                            post_type ? post_type === 'WTS' ? null :
                            post_type === 'WTT' ? <WTT /> : <WTB /> : null
                        }
                    </div>
                    <p className="choose-list-item-title-p">{item_title}</p>
                </div>
                <div className="w-100 choose-list-promote-btn-container" onClick={() => handlePromote(item.pk_i_id)}>
                    <p>{`Promote`}</p>
                </div>
            </div>
        </div>
    )
}

export const ChooseListingModalContainer = ({itemList, upto, setUpto, setUseNow, useNow, handleUseNowBtn, boostReset, handleInfiniteScroll, handlePromote}) => {
    const itemListLimit = itemList ? itemList.slice(0, upto) : []
    return (
        <div className="choose-listing-sub-container">
            <div className="choose-listing-header-container">
                <p className="m-0-auto">{`Choose Listing`}</p>
                <div className="mr-30" onClick={() => boostReset()}>
                    <CloseIcon />
                </div>
            </div>
            <div className="choose-listing-main-body-container">
                <div className="choose-listing-body-container">
                    {
                        itemList.length === 0 ? null :
                        itemList.map((item, i) => {
                            return (
                                <>
                                <div key={i}>
                                    <ChooseListingItemCard item={item} handleUseNowBtn={handleUseNowBtn} handlePromote={handlePromote}/>
                                </div>
                                </>
                            )
                        })
                    }
                </div>
                <div className="w-100 flex flex-justify-center flex-align-center mb-25 mt-30">
                    {
                        itemList.length !== 0 ? 
                        <InfiniteScroll dataLength={itemList.length} next={handleInfiniteScroll} hasMore={true} loader={<RollerLoader />} endMessage={
                            <p style={{textAlign: 'center'}}>
                                <b>End of result</b>
                            </p>
                        }/>  : null
                    }
                </div>
                <div className="choose-listing-footer-container">
                    <p className="agree-terms-choose-listing-p">{`I accept and agree to the`}</p> &nbsp;
                    <p className="terms-of-services-p-link">{`Terms of Services`}</p>
                </div>
            </div>
        </div>
    )
}

export const CancelSubscriptionModal = (props) => {
    const {setCancelModal, subscription, handleCancelSubscription, isLoadingCancelSubscription, cancelSubscriptionMessage, cancelSubscriptionError} = props.sharedProps
    const due_date = subscription && subscription.fkPrime && subscription.fkPrime[0] && subscription.fkPrime[0].due_date ? subscription.fkPrime[0].due_date : ''
    const cancelText1 = `Your monthly subscription is paid until ${due_date} You will still have access to the subscription until the end of the period you have paid for.`
    const cancelText2 = `If you would like  to proceed with canceling your subscription, please select “Cancel Subscription” below.`

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className="cancel-modal-body-container mt-30">
            {
                isLoadingCancelSubscription ? <CustomLoader/> :
                cancelSubscriptionMessage ? <PaymentSuccess text={'Successful!'}/> :
                cancelSubscriptionError ? 
                    <div className='flex flex-justify-center flex-align-center h-100'>
                        <p className='flex text-red'>{cancelSubscriptionError}</p> 
                    </div> :
                <>
                    <div className="border-bottom">
                        <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                            <div />
                            <div>
                                <Text 
                                    styleClass="w-100 flex flex-justify-center flex-align-center"
                                    text="Are you sure?"
                                    textClass="fk-prime-purchase-modal-title-p"
                                />
                            </div>
                            <div className="">
                                <IconContainerClickable 
                                    styleClass="cursor-pointer"
                                    clickFunc={() => setCancelModal(false)}
                                    Icon={<CloseIcon/>}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='cancel-text1-container'>
                            <p>{cancelText1}</p>
                        </div>
                        <div className='cancel-text2-container'>
                            <p className='cancel-text2'>{cancelText2}</p>
                        </div>
                        <div className='flex flex-d-row flex-justify-end cancel-sub-button-container'>
                            <div className='mr-30'><p>Cancel</p></div>
                            <div className="use-now-btn-individual-ads" onClick={() => handleCancelSubscription()}>
                                <p className="no-select">{`Cancel Subscription`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="cancel-subscription-footer-container ">
                        <Text
                            styleClass=""
                            text="I accept and agree to the "
                            textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                        /> &nbsp;
                        <LinkText
                            path="/terms-of-use"
                            text=" Terms of Services."
                            textClass="fk-prime-purchase-modal-footer-link-p"
                        />
                    </div>
                </>
                }
            </div>

        </div>
    )
}

export const SubscriptionActionModalContainer = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {upgradeStep, handleServicesLoad, servicesData, has_active_subscription} = value
    const isCancel = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null
    const [el, setEl] = useState([])
    
    useEffect(() => {
        if (servicesData.length === 0) {
        handleServicesLoad()
        } else {
            stepSwitch()
        }
    }, [servicesData, upgradeStep])

    const stepSwitch = () => {
        let stepHolder = []
        switch (upgradeStep) {
            case 1:
                stepHolder = <First />
                break;
            case 2:
                stepHolder = <Second />
                break;
            default:
                stepHolder = <First />
                break;
        }
        setEl(stepHolder)
    }

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className={`${isCancel && upgradeStep === 2 ? `purchase-main-modal-body-container p-relative` : `upgrade-modal-body-container`} mt-30`}>
                {el}
            </div>
        </div>
    )
}

export const SubscriptionRenewModalContainer = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {toggleSubscriptionRenewData, subscriptionRenewData} = value
    const payment_method = subscriptionRenewData && subscriptionRenewData[0] && subscriptionRenewData[0].payment_method ? subscriptionRenewData[0].payment_method : null

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className="upgrade-modal-body-container mt-30">
                <div className="border-bottom">
                    <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                        <div />
                        <div className="w-100">
                            <Text 
                                styleClass="w-100 flex flex-justify-center flex-align-center"
                                text={`Click the button to proceed`}
                                textClass="fk-prime-purchase-modal-title-p w-auto"
                            />
                        </div>
                        <div className="">
                            {
                                <IconContainerClickable 
                                styleClass="cursor-pointer"
                                clickFunc={() => toggleSubscriptionRenewData([], false)}
                                Icon={<CloseIcon/>}
                            />
                            }
                        </div>
                    </div>
                </div>
                <div className={`h-462`}>
                    <div className='h-100 flex flex-d-row flex-justify-center cancel-sub-button-container'>
                        {payment_method === 'stripe' ? <StripeRenewButton/> : <PaypalRenewButton />}
                    </div>
                </div>
                <div className="cancel-subscription-footer-container ">
                    <Text
                        styleClass=""
                        text="I accept and agree to the"
                        textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                    />
                    <LinkText
                        path="/terms-of-use"
                        text="Terms of Services."
                        textClass="fk-prime-purchase-modal-footer-link-p"
                    />
                </div>
            </div>
        </div>
    )
}

export const SubscriptionPaynowModalContainer = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {subscriptionPayNowData, toggleSubscriptionPaynowData} = value
    const payment_method = subscriptionPayNowData && subscriptionPayNowData[0] && subscriptionPayNowData[0].payment_method ? subscriptionPayNowData[0].payment_method : null

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className="upgrade-modal-body-container mt-30">
                <div className="border-bottom">
                    <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                        <div />
                        <div className="w-100">
                            <Text 
                                styleClass="w-100 flex flex-justify-center flex-align-center"
                                text={`Click the button pay now`}
                                textClass="fk-prime-purchase-modal-title-p w-auto"
                            />
                        </div>
                        <div className="">
                            {
                                <IconContainerClickable 
                                styleClass="cursor-pointer"
                                clickFunc={() => toggleSubscriptionPaynowData([], false)}
                                Icon={<CloseIcon/>}
                            />
                            }
                        </div>
                    </div>
                </div>
                <div className={`h-462`}>
                    <div className='h-100 flex flex-d-row flex-justify-center cancel-sub-button-container'>
                        {payment_method === 'stripe' ? <StripePaynowButton/> : <PaypalPaynowButton/>}
                    </div>
                </div>
                <div className="cancel-subscription-footer-container ">
                    <Text
                        styleClass=""
                        text="I accept and agree to the"
                        textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                    />
                    <LinkText
                        path="/terms-of-use"
                        text="Terms of Services."
                        textClass="fk-prime-purchase-modal-footer-link-p"
                    />
                </div>
            </div>
        </div>
    )
}

export const MobileFooter = ({handleFooter, btnText}) => {
    return (
        <>
        <div className="fk-prime-mobile-footer-container">
            <div className=" flex flex-align-center flex-justify-center">
               {/* { customButton ? customButton : <div className={boost ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={(e) => boost !== 0 && handleFooter ?  handleFooter(e) : null}>
                    <p className="fk-prime-purchase-p">{text ? text : `Purchase`}</p>
                </div>} */}
                <div className="fk-prime-purchase-btn cursor-pointer" onClick={() => handleFooter()}>
                    <p className="fk-prime-purchase-p">{btnText}</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default {
    SubscriptionContainer,
    SubscriptionHeader,
    ActiveSubscriptionLabel,
    FKPrimeDetails,
    DueDateContainer,
    FKPrimeSubscriptionHistory,
    Divider,
    FKPrimeBoostAvailability,
    MobileFKPrimeDetails,
    MobileDueDateContainer,
    MobileBoostContainer,
    BoostTitleAndSub,
    MobileSubscriptionHistory,
    SubscriptionDetailsContainer,
    IndividualArrSubscriptionHistory,
    ChooseListingModalContainer,
    SubscriptionMainContainer,
    MobileSubscriptionMainContainer,
    MobileSubscriptionContainer,
    CancelSubscriptionModal,
    SubscriptionActionModalContainer,
    SubscriptionRenewModalContainer,
    SubscriptionPaynowModalContainer,
    MobileFooter
}