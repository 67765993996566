import React, { useContext, useReducer, useEffect } from 'react';
import { AdminItemContext } from '../../../../../../common/context';
import moment from 'moment';
import { labelSwitch, renderStatusOptions } from '../../../../../../common/helper/utility'
import Category from '../../../components/Category'

export const ItemList = () => {
    const value = useContext(AdminItemContext);
    const { adminItemData, fetchItemDetail, handleDeleteSubmit, gotoUser, handleViewPublicItem } = value
    return (
        <div className='main-container'>
                {adminItemData === 0 ? <p style={{textAlign: 'center'}}>No user found</p> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Id</th><th>Status</th><th>Title</th><th>Owner</th><th>Category</th><th>Publish Date</th><th>Last Update Date</th><th>Edit</th><th>Delete</th><th>View</th>
                            </tr>
                            {adminItemData.map((item, index) => {
                                const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
                                const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? item.oc_t_category.oc_t_category_descriptions.s_name : ''
                                const owner_id = item && item.oc_t_user && item.oc_t_user.pk_i_id ?  parseInt(item.oc_t_user.pk_i_id) : ''
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td >{item.pk_i_id}</td>
                                        <td className={item.b_active ? 'success-message' : 'error-message'}>{item.b_active ? 'Active' : 'Not Active'}</td>   
                                        <td className='pointer' onClick={()=> fetchItemDetail(item.pk_i_id)}>{title}</td>
                                        <td className='pointer' onClick={()=> gotoUser(owner_id)}>{owner}</td>
                                        <td >{category}</td>
                                        <td >{moment(item.dt_pub_date).format('YYYY-MM-DD')}</td>
                                        <td >{item.dt_mod_date ? moment(item.dt_mod_date).format('YYYY-MM-DD') : null}</td>
                                        <td className='pointer' onClick={()=> fetchItemDetail(item.pk_i_id, true)}><i className="far fa-edit"/></td>
                                        <td className='pointer' onClick={()=> handleDeleteSubmit(item.pk_i_id, title)}><i className="fas fa-trash-alt"/></td>
                                        <td className='pointer light-blue' onClick={()=> handleViewPublicItem(item.pk_i_id)}>Public View</td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
                }
        </div>
    );
}

export const ItemModal = () => {
    const value = useContext(AdminItemContext);

    const { 
        isShow, isShowEdit, closeItemModal, adminItemDetailData, adminItemDetailIsLoading, adminItemDetailError, updateItem,
        adminUpdateItemIsLoading, adminUpdateItemError, adminUpdateItemMessage, handleDeleteSubmit, gotoUser, handleViewPublicItem
    } = value

    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({...state, ...newState}), {}
    );
    const owner_id = adminItemDetailData && adminItemDetailData.oc_t_user && adminItemDetailData.oc_t_user.pk_i_id ? parseInt(adminItemDetailData.oc_t_user.pk_i_id) : ''

    useEffect(() => {
        if (adminItemDetailData) {
            const data = adminItemDetailData
            let input = {}
            for (var k in data) {
                if (data.hasOwnProperty(k)) {
                    if(k === 'fk_i_category_id'){
                        input[k] = data[k]
                    }
                    else if (typeof data[k] === 'string' && k !== '__typename') {
                        input[k] = data[k]
                    } 
                    else if(typeof data[k] === 'number' && k !== '__typename'){
                        input['i_price'] = data[k]
                    }
                    else if(typeof data[k] === 'boolean'){
                        input[k] = data[k]
                    }
                    else if(k === 'oc_t_item_description'){
                        input['s_title'] = data[k] && data[k].s_title ? data[k].s_title : ''
                        input['s_description'] = data[k] && data[k].s_description ? data[k].s_description : ''
                    }
                    else if(k === 'oc_t_user'){
                        input['owner'] = data[k]  && data[k].s_username ? data[k].s_username : ''
                    }
                }
            }
            setItemInput(input)
        }
    },[adminItemDetailData])

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue =  evt.target.value === 'true' ? true : evt.target.value === 'false' ? false :  evt.target.value;
        setItemInput({
            [name]: newValue
        });
    }

    const parseError = (error) => {
        let errorArray = []
        if (typeof error === 'string') {
          errorArray.push(<p key={1} style={{color: 'red'}}>{error}</p>)
        } else {
          for (var key in error) {
            if (error.hasOwnProperty(key)) {
                errorArray.push(<p key={key} style={{color: 'red'}}>{error[key].toString().replace('input.', '')}</p>)
            }
          }
        }
        return errorArray
      }

    const isEditable = (field) => {
        let disabled = true
        if (isShowEdit) {
            switch (field) {
                case 'i_price':
                    disabled = false
                    break;
                case 's_title':
                case 's_description':
                    disabled = false
                    break;
                default:
                    disabled = true
                    break;
            }
        } 
        return disabled
    }

    const renderInputs = () => {
        const data = itemInput
        let arr = []
        for (var k in data) {
            if (data.hasOwnProperty(k)) {
                if (k === 'fk_i_category_id') {
                    arr.push(
                        <div key={k} style={{margin: '30px auto'}}>
                            <Category name={'fk_i_category_id'} selectedId={data[k] ? data[k] : ''} onChange={handleChange} isShowEdit={isShowEdit}/>
                        </div>
                    )
                }
                else if (k === 's_description') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <textarea className = 'description_textarea' type="text" onChange={handleChange} name={k} value={data[k]} disabled={isEditable(k)} rows="4" cols="50"></textarea>
                        </span>
                    )
                }
                else if (typeof data[k] === 'string' && k !== 'fk_i_category_id' || typeof data[k] === 'string' && k !== 's_description') {
                    arr.push(
                        <span key={k} onClick={ k === 'owner' && !isShowEdit ? () => gotoUser(owner_id) : null}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input  className={k === 'owner' && !isShowEdit ? 'pointer' : ''} type="text" onChange={handleChange} name={k} value={data[k]} disabled={isEditable(k)}/>
                        </span>
                    )
                }
                else if (typeof data[k] === 'number') {
                    arr.push(
                        <span key={k}>
                            <label ><b>{labelSwitch(k)}</b></label>
                            <input type="text" onChange={handleChange} name={k} value={data[k]} disabled={isEditable(k)}/>
                        </span>
                    )
                }
                else if(typeof data[k] === 'boolean'){
                    arr.push(
                        isShowEdit? <div key={k} style={{marginBottom: 10, fontSize: 16}}>
                                        <div><label><b>{labelSwitch(k)}</b></label></div>
                                        <select name={k} value={data[k]} onChange={handleChange}>
                                            {renderStatusOptions(data[k])}
                                        </select>
                                    </div> :
                                    <span key={k}>
                                        <label ><b>{labelSwitch(k)}</b></label>
                                        <input type="text" onChange={handleChange} name={k} value={data[k] ? 'Active' : 'Not Active'} disabled={true}/>
                                    </span>

                    )
                }
            }
        }
        return arr
    }
    
    const handleSubmit = (event, itemInput) => {
        event.preventDefault()
        setItemInput({})
        updateItem(event, itemInput)
    }

    return (
        <div className='data-modal-container' style={{display: isShow ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeItemModal()}>&times;</span>
                { adminItemDetailIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                    adminItemDetailError ? <div className="message-container"><p className='error-message'>{adminItemDetailError}</p></div> :  
                        adminUpdateItemIsLoading ?  <div className="message-container"><p>Loading...</p></div> :
                            adminUpdateItemMessage ? <div className="message-container"><p className='success-message'>{adminUpdateItemMessage}</p></div> :
                            <div className="container">
                                {isShowEdit ?
                                    <form onSubmit={(event) => handleSubmit(event, itemInput)}>
                                    { adminUpdateItemError ? <div>{parseError(adminUpdateItemError)}</div>  : null }
                                    {renderInputs()}
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
                                        <input type="submit" value="Update Item" className="sellButton pointer" style={{display: 'inline-block', margin: '10px 0', alignSelf: 'flex-start'}}/>
                                        <button className="public-view-button" onClick={()=> handleViewPublicItem(itemInput['pk_i_id'])}>Public View</button>
                                        <div className='pointer' onClick={()=> handleDeleteSubmit(itemInput['pk_i_id'], itemInput['s_title'])} style={{ padding: 10 }}><i className="fas fa-trash-alt" style={{fontSize: 24}}/></div>
                                    </div>
                                    </form> :
                                    <>
                                        {renderInputs()}
                                        <button className="public-view-button" onClick={()=> handleViewPublicItem(itemInput['pk_i_id'])}>Public View</button>
                                    </>
                                }
                            </div>
                } 
                </div>
        </div>
    )
}

export const ItemSuggestData = () => {
    const value = useContext(AdminItemContext);
    const { adminItemSuggestData, adminItemSuggestIsLoading, fetchItemDetail } = value

    const arrLoader = [<div key={0} className={'admin-suggest-popup-container'}><p style={{padding: 10}}>Loading...</p></div>]

    let arrHolder = []

    adminItemSuggestData.length === 0 ? 
        arrHolder.push( <div key={0} className={'admin-suggest-popup-container'}>
                        <p style={{padding: 10}}>No results</p>
                    </div>
        ) :
    adminItemSuggestData.map((item, index) => {
        const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
        arrHolder.push( <div key={index} className={'admin-suggest-popup-container'} style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: '1px solid silver',
                            borderRight: '1px solid silver',
                            borderBottom:  (item.length -1) === index ? 0 : '1px solid silver',
                            backgroundColor: 'white',
                            height: 40
                        }}>
                            <button onClick={() => fetchItemDetail(item.pk_i_id, true)} style={{border: 'none'}}>{title}</button>
                        </div>
        )
        return null
    })
    return adminItemSuggestIsLoading ? arrLoader : arrHolder
}

export const ItemSearchResult = () => {
    const value = useContext(AdminItemContext);
    const { setIsShowSearchResult, isShowSearchResult, adminItemSuggestData, fetchItemDetail, handleDeleteSubmit, gotoUser, handleViewPublicItem } = value

    const userSelect = (id, isEdit) => {
        setIsShowSearchResult(false)
        fetchItemDetail(id, isEdit)
    }

    return (
        <div className='data-modal-container' style={{display: isShowSearchResult ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => setIsShowSearchResult(false)}>&times;</span>
                    <div className="modal-purchase-container">
                        <h2>Search Results</h2>
                        <table>
                        <tbody>
                            <tr>
                                <th>Id</th><th>Status</th><th>Title</th><th>Owner</th><th>Category</th><th>Publish Date</th><th>Edit</th><th>Delete</th><th>View</th>
                            </tr>
                            {adminItemSuggestData.map((item, index) => {
                                const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
                                const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? item.oc_t_category.oc_t_category_descriptions.s_name : ''
                                const owner_id = item && item.oc_t_user && item.oc_t_user.pk_i_id ?  parseInt(item.oc_t_user.pk_i_id) : ''
                                return (
                                    <tr className='tr-data-container' key={index}>
                                        <td >{item.pk_i_id}</td>
                                        <td className={item.b_active ? 'success-message' : 'error-message'}>{item.b_active ? 'Active' : 'Not Active'}</td>   
                                        <td className='pointer' onClick={()=> userSelect(item.pk_i_id)}>{title}</td>
                                        <td className='pointer' onClick={()=> gotoUser(owner_id)}>{owner}</td>
                                        <td >{category}</td>
                                        <td >{moment(item.dt_pub_date).format('YYYY-MM-DD')}</td>
                                        <td className='pointer' onClick={()=> userSelect(item.pk_i_id, true)}><i className="far fa-edit"/></td>
                                        <td className='pointer' onClick={()=> handleDeleteSubmit(item.pk_i_id, title)}><i className="fas fa-trash-alt"/></td>
                                        <td className='pointer light-blue' onClick={()=> handleViewPublicItem(item.pk_i_id)}>Public View</td>
                                    </tr>
                                )                        
                            })}
                        </tbody>  
                        </table>
                    </div>
            </div>
        </div>        
    )
}

export const UserItemsResult = () => {
    const value = useContext(AdminItemContext);
    const { isShowUserItemResult, setIsShowUserItemResult, closeUserItemModal, adminUserItemIsLoading, adminUserItemData, adminUserItemError, fetchItemDetail, handleDeleteSubmit, gotoUser } = value

    const userSelect = (id, isEdit) => {
        setIsShowUserItemResult(false)
        fetchItemDetail(id, isEdit)
    }

    return (
        <div className='data-modal-container' style={{display: isShowUserItemResult ? 'block' : 'none'}}>
            <div className='modal-content'>
                <span className='modal-close pointer' onClick={() => closeUserItemModal()}>&times;</span>
                    <div className="container">
                        <h2>User Item Results</h2>
                        {  adminUserItemIsLoading ? <p>Loading...</p> :
                            adminUserItemError ? <p className='error-message'>{'adminUserItemError'}</p> :
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Id</th><th>Status</th><th>Title</th><th>Owner</th><th>Category</th><th>Publish Date</th><th>Edit</th><th>Delete</th>
                                        </tr>
                                        {adminUserItemData.map((item, index) => {
                                            const title = item && item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
                                            const owner = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
                                            const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? item.oc_t_category.oc_t_category_descriptions.s_name : ''
                                            const owner_id = item && item.oc_t_user && item.oc_t_user.pk_i_id ?  parseInt(item.oc_t_user.pk_i_id) : ''
                                            return (
                                                <tr className='tr-data-container' key={index}>
                                                    <td >{item.pk_i_id}</td>
                                                    <td className={item.b_active ? 'success-message' : 'error-message'}>{item.b_active ? 'Active' : 'Not Active'}</td>   
                                                    <td className='pointer' onClick={()=> userSelect(item.pk_i_id)}>{title}</td>
                                                    <td className='pointer' onClick={()=> gotoUser(owner_id)}>{owner}</td>
                                                    <td >{category}</td>
                                                    <td >{moment(item.dt_pub_date).format('YYYY-MM-DD')}</td>
                                                    <td className='pointer' onClick={()=> userSelect(item.pk_i_id, true)}><i className="far fa-edit"/></td>
                                                    <td className='pointer' onClick={()=> handleDeleteSubmit(item.pk_i_id, title)}><i className="fas fa-trash-alt"/></td>
                                                </tr>
                                            )                        
                                        })}
                                    </tbody>  
                                </table>
                        }
                    </div>
            </div>
        </div>        
    )
}