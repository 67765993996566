import React from 'react'
import MetaTags from 'react-meta-tags';

const { REACT_APP_BASE_URL } = process.env;

const DefaultMeta = () => {
    return(
        <MetaTags>
            <title>Fishingkaki</title>
            <meta id="mt_description" name="description" content="Massive Resource for Anglers" />
            <link id="mt_canocical" rel="canonical" href={REACT_APP_BASE_URL}/>
            <meta id="mt_og_url" property="og:url" content={REACT_APP_BASE_URL} />
            <meta id="mt_og_title" property="og:title" content="Fishingkaki" />
            <meta id="mt_og_description" property="og:description" content="Massive Resource for Anglers"/> 
            <meta id="mt_og_image" property="og:image" content="https://fishingkaki-prd-ase1-bucket.s3.ap-southeast-1.amazonaws.com/classifieds/fk_home_share.png" />
        </MetaTags>
    )
}   

export default DefaultMeta