import React from 'react'
import {Link} from 'react-router-dom'

export const Text = ({styleClass, textClass, text}) => {
    return (
        <div className={styleClass}>
            <p className={textClass}>{text}</p>
        </div>
    )
}

export const LinkText = ({path, styleClass, textClass, text}) => {
    return (
        <Link to={path} className={styleClass}>
        <p className={textClass}>{text}</p>
        </Link>
    )
}

export const GotoPageText = ({path, styleClass, textClass, text}) => {
    return (
        <a href={path} className={styleClass}>
            <p className={textClass}>{text}</p>
        </a>
    )
}

export const LinkTextWithIcon = ({path, styleClass, textClass, text, icon}) => {
    return (
        <a href={path} className={styleClass}>
            {icon}
            <p className={textClass}>{text}</p>
        </a>
    )
}

export const DashedDivider = () => {
    return (
        <div className="dashed-divider"/>
    )
}

export default {
    Text,
    LinkText,
    GotoPageText,
    LinkTextWithIcon,
    DashedDivider
}