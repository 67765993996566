import React from 'react'
import {useHistory} from 'react-router-dom'
import {RedAlertCircleIcon} from '../../../../assets/svg'

export const MobileBottomBarContent = ({icon, label, textClass, goto, hasAlert, count}) => {
    let history = useHistory()
    return (
        <div className={`flex flex-d-column flex-justify-center flex-align-center h-100 mobile-bottom-bar-container ${label.includes('Sell') ? goto.includes(`login`) ? `ml-25` : `` : ``}`} onClick={() => history.push(goto)}>
          <div className="p-relative">
            {
              hasAlert ? 
              <div className="p-absolute top-0 right-0">
                { count === 0 ? null : <RedAlertCircleIcon />}
              </div> :
                null
            }
            {icon}
          </div>
          <p className={textClass}>{label}</p>
        </div>
    )
}

export default {MobileBottomBarContent}