import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { reviewViewLoad } from '../../../../redux/action'
import {NoReviewsIllustrate} from '../../../../assets/svg'
import { ReviewContainerTop, ReviewFilter, ReviewDataElement } from './child/CustomComponent'
import { StarRatingPlain } from '../../../../common/component/rating'

const Review = (props) => {
    const location = useLocation()
    const sellerId = location.pathname.replace('/user/', '').replace('/reviews','')
    const session_id = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const seller_id = parseInt(sellerId) ? sellerId : session_id
    const { data, isLoading, error } = props.review
    useEffect(() => {
        if(seller_id) {
            props.reviewViewLoad({id: seller_id})
        }
          // eslint-disable-next-line
    }, [seller_id])
    // const overallAverage = data && data.average_total_rate ? data.average_total_rate : ''
    const overallAverage = data && data.average_communication_rate ? data.average_communication_rate : ''
    
    const reviewCount = data && data.review_count ? data.review_count : 0
    const averageCommunicationRate = data && data.average_communication_rate ? data.average_communication_rate.toFixed(1) : ''
    const averageMeetupShippingRate = data && data.average_meetup_shipping_rate ? data.average_meetup_shipping_rate.toFixed(1) : ''
    const averageProductRate = data && data.average_product_rate ? data.average_product_rate.toFixed(1) : ''
    const averagePaymentRate = data && data.average_payment_rate ? data.average_payment_rate.toFixed(1) : 'NA'
    const reviewData = data && data.data ? data.data : []

    return (
        <div className='review-container'>
            {reviewData && reviewData.length === 0 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 10px'}}> <NoReviewsIllustrate /> </div>: 
                <>
                    <ReviewContainerTop sharedProps={{overallAverage, reviewCount, averageCommunicationRate, averageMeetupShippingRate, averageProductRate, averagePaymentRate}}/>
                    <div className="review-container-main">
                        {/* <ReviewFilter/> */}
                        <ReviewDataElement sharedProps={{reviewData, session_id }}/>
                    </div>
                </>
            }
        </div>
    );
}

const mapStateToProps = store => {
    return {
        review: store.reviewReducer
    }
}

const mapActionToProps = {
    reviewViewLoad
}

export default connect(mapStateToProps, mapActionToProps)(Review)