import React from 'react'
import { Link } from 'react-router-dom'

const Modal = ({ close }) => {
    return (
        <div className="w-100 p-fixed top-0 left-0 z-ndx-7 bg-black-op-p3 h-100 flex flex-justify-center flex-align-center">
            <div className={`${window.innerWidth < 600 ? `w-90` : `w-30`} h-auto ${window.innerWidth < 600 ? `p-5` : `p-20`} mb-150 flex flex-justify-center flex-d-column flex-align-center bg-white br-4`} >
                <div className="w-100 flex flex-justify-end">
                    <div onClick={close} className="text-black">
                        <i className="fas fa-close" />
                    </div>
                </div>
                {
                    window.innerWidth < 600 ? <div className="text-align-center">
                        <div>
                            <p className="font-style-normal font-size-20">{`This item has been sold `}</p>
                            <p className="font-style-normal font-size-20">{`and cannot be promoted.`}</p>
                        </div>
                        <div className="mt-11">
                            <p className="font-style-normal font-size-20">{`You may have other `}</p>
                            <p className="font-style-normal font-size-20">{`listings to promote?`}</p>
                        </div>
                    </div> :
                        <div className="text-align-center">
                            <p className="font-style-normal font-size-20">{`This item has been sold and cannot be promoted.`}</p>
                            <p className="font-style-normal font-size-20">{`You may have other listings to promote?`}</p>
                        </div>
                }

                <div className="w-100 flex flex-justify-center flex-align-center p-20">
                    <div onClick={close} className="text-align-center cursor-pointer br-4 bg-orange p-8-13" >
                        <Link to={'/user/listing'}><p className="text-white font-weight-500">{`Got it!`}</p></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal