import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { categoryLoad } from '../../../redux/action'

const Category = (props) => {
    const { name, onChange, selectedId, value} = props
    const { data, error } = props.category
    const location = useLocation()
    const [selecting, setSelecting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        props.categoryLoad()
    // eslint-disable-next-line    
    }, [])

    useEffect(() => {
      if(data){
        setIsLoading(false)
      }
    }, [data])

    return (

        isLoading ? <h5 className="text-align-center">Loading... </h5> :
            error ? <h5 className="text-align-center text-red">Error... </h5> :
                <div className="category-input-container">
                  <div className="">
                    <div className="flex flex-d-column">
                    <label className={`category-input-label`}>{`Category`}<span className="text-red">*</span></label>
                    <select name={name} className="form-option" className={`category-select-container ${selecting ? `bg-chevron-up` : `bg-chevron-down`}`} defaultValue={''} value={value} onChange={(event) => onChange(event)} required>
                        <option disabled value="">{`Select category`}</option>
                        {
                          data.map((item, index) => {
                            let arr = []
                            const catName = item.oc_t_category_descriptions && item.oc_t_category_descriptions.s_name ? item.oc_t_category_descriptions.s_name : false
                            const isSelected = selectedId === item.pk_i_id ? true : false
                            if (catName) {
                                arr.push(
                                  <option key={index} value={item.pk_i_id} data-id={item.oc_t_category_descriptions.fk_i_category_id} data-cf={JSON.stringify(item.oc_t_custom_field)} selected={isSelected}>{`${catName}`}</option>
                                )
                            }
                            return arr
                          })
                        }
                    
                    </select>
                    </div>
                    </div>
                </div>
    );
};

const mapStateToProps = store => {
    return {
      category: store.categoryReducer,
    };
  };
  
const mapActionToProps = {
    categoryLoad
}

export default connect(mapStateToProps, mapActionToProps)(Category);
