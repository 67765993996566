import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AdminSubscriptionContext } from '../../../../../common/context'
import { adminSubscriptionLoad, adminSubscriptionDetailLoad, adminSubscriptionDetailReset, adminUpdateSubscriptionLoad, adminUpdateSubscriptionReset, adminSubscriptionSearchLoad, adminSubscriptionSearchReset } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { SubscriptionList, SubscriptionModal, SubscriptionSuggestData, SubscriptionSearchResult } from './component/CustomComponent'


const SubscriptionSection = (props) => {
    const pageCount = 20
    const result_limit = 20

    const { 
        adminSubscriptionData, adminSubscriptionIsLoading, adminSubscriptionError, adminSubscriptionPage, adminTotalSubscription,
        adminSubscriptionDetailIsLoading, adminSubscriptionDetailData, adminSubscriptionDetailError,
        adminUpdateSubscriptionLoading, adminUpdateSubscriptionError, adminUpdateSubscriptionSuccess,
        adminSubscriptionSearchIsLoading, adminSubscriptionSearchData, adminSubscriptionSearchError
    } = props.admin

    const [type, setType] = useState('subscription')

    const handleTypeChange = (evt) => {
        const value = evt.target.value;
        setType(value)
    }

    useEffect(() => {
        if (adminSubscriptionData.length === 0) {
            props.adminSubscriptionLoad({first: pageCount, page: adminSubscriptionPage, type: type})
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        props.adminSubscriptionLoad({first: pageCount, page: 1, type: type})
    // eslint-disable-next-line
    }, [type])

    useEffect(()=>{
        if (adminUpdateSubscriptionSuccess) {
            setTimeout(() => {
                closeAllModal()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/subscription`)
            }, 2000)
        }
        if (adminUpdateSubscriptionError) {
            setTimeout(() => {
                closeAllModal()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/subscription`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminUpdateSubscriptionSuccess, adminUpdateSubscriptionError])

    const handlePageClick = data => {
        props.adminSubscriptionLoad({first: pageCount, page: data.selected + 1,  type: type})
    };

    let history = useHistory();
    const gotoUser = (id) => {
        closeAllModal()
        history.push("/admin/dashboard/user", {user_id: id});
    }

    //subscription modal
    const [isShow, setIsShow] = useState(false)
    const [isShowEdit, setIsShowEdit] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)

    const fetchSubscriptionDetail = (id, isEdit) => {
        setIsSearching(false)
        setIsShowSearchResult(false)
        setIsShow(true)
        if (isEdit) {
            setIsShowEdit(true)
        }
        props.adminSubscriptionDetailLoad({id: id})
    }

    const closeAllModal = () => {
        setIsSearching(false)
        setIsShowSearchResult(false)
        setIsShow(false)
        setIsShowEdit(false)
    }

    const handleUpdate = (subscriptionData) => {
        props.adminUpdateSubscriptionLoad(subscriptionData)
    }

    //subscription search
    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            props.adminSubscriptionSearchLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "purchase_date", orderType: "DESC"})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setIsShowSearchResult(true)
    }

    return (
        <AdminSubscriptionContext.Provider value={{
            adminSubscriptionData, gotoUser,
            fetchSubscriptionDetail, isShow, setIsShow, closeAllModal,
            adminSubscriptionDetailIsLoading, adminSubscriptionDetailData, adminSubscriptionDetailError,
            type, handleTypeChange, isShowEdit, handleUpdate,
            adminUpdateSubscriptionLoading, adminUpdateSubscriptionError, adminUpdateSubscriptionSuccess,
            adminSubscriptionSearchIsLoading, adminSubscriptionSearchData, adminSubscriptionSearchError, isShowSearchResult, setIsShowSearchResult
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Subscription</h3>
            <section>
            {adminSubscriptionIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminSubscriptionError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminSubscriptionError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div ></div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative', width: 450}}>
                                <div className="admin-search-container">
                                        <form onSubmit={(e) =>searchSubmit(e)} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><SubscriptionSuggestData /></div>}
                            </div>
                        </div>
                        <SubscriptionList />
                    </article>
            }
            <div></div> 
            {adminSubscriptionError ? null :
                adminSubscriptionData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalSubscription}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {isShow || isShowEdit ? <SubscriptionModal /> : null }
        {isShowSearchResult ? <SubscriptionSearchResult /> : null}
        </AdminSubscriptionContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminSubscriptionLoad,
    adminSubscriptionDetailLoad,
    adminSubscriptionDetailReset,
    adminUpdateSubscriptionLoad, 
    adminUpdateSubscriptionReset,
    adminSubscriptionSearchLoad, 
    adminSubscriptionSearchReset
}
  
export default connect(mapStateToProps, mapActionToProps)(SubscriptionSection);