import React, { useReducer, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setRecentPriceFilter, resetPriceFilter, categorySearchReset } from '../../../../redux/action'
import { theme } from '../../../../common/theme'
import { FilterIcon } from '../../../../assets/svg'
import MobileFilter from './mobile'

const Filter = (props) => {
    const location = useLocation()
    const path = location.pathname

    const data = theme.text.searchOption
    const { recentPriceFilter, price } = props.filter

    useEffect(() => {
        setMinMaxFilter({
            minPrice: 0,
            maxPrice: 1000000,
            class: 'recent'
        })
        props.resetPriceFilter()
        props.categorySearchReset()
    }, [path])

    const [minMaxFilter, setMinMaxFilter] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice,
            class: recentPriceFilter
        }
    )

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setMinMaxFilter({ [name]: newValue });
    }

    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(!show)
    }
    const filterMobile = useRef(null)

    const handleClickOutside = event => {
        if (filterMobile.current && !filterMobile.current.contains(event.target)) {
            setShow(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const handleFocus = (evt) => {
        const name = evt.target.name;
        const newValue = '';
        if (evt.target.value === 'Minimum' || evt.target.value === 'Maximum') {
            setMinMaxFilter({ [name]: newValue });
        }
    }
    const handleSubmit = evt => {
        evt.preventDefault();
        props.setRecentPriceFilter(minMaxFilter)
    }

    return (
        <div className="filter-container">
            <div className="filter-flex">
                <div className="filter-head">
                    <p style={{ fontWeight: '600', color: '#232A34' }}>{`Filter`}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="filter-input">
                        <p>{`Sort`}</p>
                        <div className="sort-container">
                            {
                                data.map((option, index) => {
                                    return <div key={index} className="sort-class">
                                        <span style={{ color: '#232A34' }}>{option.label}</span>
                                        <input type="radio" name="class" value={option.value} onChange={handleChange} checked={option.value === minMaxFilter.class ? true : false} />
                                    </div>
                                })
                            }
                        </div>

                    </div>
                    <div className="filter-price">
                        <p>Price</p>
                        <div className="price-filter">
                            <input style={{ color: '#232A34' }} name="minPrice" type="text" placeholder="Minimum" value={minMaxFilter.minPrice === 0 ? 'Minimum' : minMaxFilter.minPrice} onChange={handleChange} onFocus={handleFocus} />
                        </div>
                        <div className="price-filter">
                            <input style={{ color: '#232A34' }} name="maxPrice" type="text" placeholder="Maximum" value={minMaxFilter.maxPrice === 1000000 ? 'Maximum' : minMaxFilter.maxPrice} onChange={handleChange} onFocus={handleFocus} />
                        </div>
                    </div>
                    <div className="filter-submit">
                        <input type="submit" value="Go" />
                    </div>
                </form>
            </div>
            <div className="mobile-filter-container" onClick={handleClick}>
                <FilterIcon />

            </div>
            <div className={`modal-mobile-bg ${show ? `flex` : `hide`}`} >
                <MobileFilter data={data} show={show} click={handleClick} filterM={filterMobile} />
            </div>

        </div>
    );
}

const mapStateToProps = store => {
    return {
        filter: store.filterReducer
    }
}

const mapActionToProps = {
    setRecentPriceFilter,
    resetPriceFilter,
    categorySearchReset
}

export default connect(mapStateToProps, mapActionToProps)(Filter)