import { combineReducers } from 'redux'
import sampleReducer from './sampleReducer'
import itemReducer from './itemReducer'
import authReducer from './authReducer'
import userReducer from './userReducer'
import itemUserReducer from './itemUserReducer'
import categoryReducer from './categoryReducer'
import createItemReducer from './createItemReducer'
import itemViewReducer from './itemViewReducer'
import avatarReducer from './avatarReducer'
import dbsReducer from './dbsReducer'
import categorySearchReducer from './categorySearchReducer'
import chatReducer from './chatReducer'
import filterReducer from './filterReducer'
import searchReducer from './searchReducer'
import purchaseReducer from './purchaseReducer'
import watchlistReducer from './watchlistReducer'
import adminReducer from './adminReducer'
import reviewReducer from './reviewReducer'
import suggestReducer from './suggestReducer'
import contactReducer from './contactReducer'
import microServiceReducer from './microServiceReducer'

export default combineReducers ({
    sampleReducer,
    itemReducer,
    authReducer,
    userReducer,
    itemUserReducer,
    categoryReducer,
    createItemReducer,
    itemViewReducer,
    avatarReducer,
    dbsReducer,
    categorySearchReducer,
    filterReducer,
    searchReducer,
    chatReducer,
    purchaseReducer,
    watchlistReducer,
    adminReducer,
    reviewReducer,
    suggestReducer,
    contactReducer,
    microServiceReducer
})
