import { USER_DATA, NEW_USER, ME, EMAIL_VERIFY, CHANGE_PASSWORD, SEND_FORGOT_PASSWORD, SESSION_USER, LOGOUT, ADMIN, RESEND_EMAIL_VERIFICATION } from '../constant';
import { takeLatest, put } from "redux-saga/effects";
import { userSuccess, userFail, newUserSuccess, newUserFail, emailVerifySuccess, emailVerifyFail, logoutFail, resendEmailVerificationSuccess, resendEmailVerificationFail } from "../action/authAction";
import { meSuccess, meFailed, cPasswordSuccess, cPasswordFailed, forgotPasswordSuccess, forgotPasswordFailed, sessionFail, sessionSuccess } from "../action/userAction";
import { loginAsUserSuccess, loginAsUserFail } from "../action/adminAction"
import auth from '../../graphql/auth'
import user from '../../graphql/user'
import admin from '../../graphql/admin'
import { saveLocalStorage, getLocalStorage, removeLocalStorage } from '../../common/helper/utility'
import { theme } from '../../common/theme'

const { REACT_APP_BASE_URL } = process.env;

//login
function* authUser(action) {
    const { payload } = action
    if (payload && payload.graphDomain && payload.graphDomain === 'facebook' || payload.graphDomain === 'google') {
        try{
            const result = yield auth.socialLogin(payload)
            if(result === 403) {
                yield put(userFail('Something went wrong try again!'))
            }
            else {
                //save token & type  & user id to localstorage
                const data = result.data.userSocialLogin.oc_t_user ? result.data.userSocialLogin.oc_t_user : null
                const id = result.data.userSocialLogin.oc_t_user.pk_i_id ? result.data.userSocialLogin.oc_t_user.pk_i_id : null
                const access_token = result.data.userSocialLogin.access_token ? result.data.userSocialLogin.access_token : null
                const fkv2_session = {"access_token": access_token, "type": "user", 'id': id, 'social': payload.graphDomain}
                yield saveLocalStorage("fkv2_session", fkv2_session)
                window.location.replace(REACT_APP_BASE_URL);
            }
        } catch (e) {
            const error = e && e.graphQLErrors ? e.graphQLErrors : e
            const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
                ? error[0].extensions.validation : 
                error && error[0] && error[0].message ? error[0].message : theme.text.error.default
            console.log('error', JSON.stringify(e))
            yield put(userFail(errorMessage))
        }
    } else {
        const email = payload.userEmail
        const password = payload.userPassword
        try{
            const result = yield auth.getInput(email, password)
            if(result === 403) {
                yield put(userFail('Something went wrong try again!'))
            }
            else {
                //save token & type  & user id to localstorage
                const data = result.data.userLogin.oc_t_user ? result.data.userLogin.oc_t_user : null
                const id = result.data.userLogin.oc_t_user.pk_i_id ? result.data.userLogin.oc_t_user.pk_i_id : null
                const access_token = result.data.userLogin.access_token ? result.data.userLogin.access_token : null
                const fkv2_session = {"access_token": access_token, "type": "user", 'id': id}
                yield saveLocalStorage("fkv2_session", fkv2_session)
                window.location.replace(REACT_APP_BASE_URL);

            }
        } catch (e) {
            const error = e && e.graphQLErrors ? e.graphQLErrors : e
            const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
                ? error[0].extensions.validation : 
                error && error[0] && error[0].message ? error[0].message : theme.text.error.default
            console.log('error')
            yield put(userFail(errorMessage))
        }
    }

}

export function* watchauthUser() {
    yield takeLatest(USER_DATA.LOAD, authUser);
}

//register
function* newUser(action) {
    const { payload } = action
    const email = payload.email
    const password = payload.password
    const repassword = payload.rePassword
    const name = payload.name
    const username = payload.username
    const s_ip = payload.s_ip

    try {
        const result = yield auth.newUserInput(name, username, password, email, repassword, s_ip)
        if (result) {
            if(result === 403){
                yield put(newUserFail('Something went wrong try again!'))
            }else{
                const data = result && result.data && result.data.userRegister && result.data.userRegister.message ? result.data.userRegister.message : null
                yield put(newUserSuccess(data))
            } 
        }
    } catch (e){
            const error = e && e.graphQLErrors ? e.graphQLErrors : e
            const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
                ? error[0].extensions.validation : 
                error && error[0] && error[0].message ? error[0].message : theme.text.error.default
            console.log('error', JSON.stringify(e))
        yield put(newUserFail(errorMessage))
    }

}

export function* watchNewUser() {
    yield takeLatest(NEW_USER.LOAD, newUser)
}

//fetch login user
function* meSaga(action) {
    const id = action && action.payload && action.payload.id ? action.payload.id : ''
    const sellerId = id.replace('/', '');   

    try {
        let result
        if (id.includes("/")) {
            result = yield user.getUser(sellerId)
        } else {
            result = yield user.getMe()
        }    
      if(result === 403) {
        yield put(meFailed('Something went wrong try again!'))
      }
      else {
        let data
        if (id.includes("/")) {
            data = result && result.data && result.data.publicGetUserById  ? result.data.publicGetUserById : {}
        } else {
            data = result && result.data && result.data.me  ? result.data.me : {}
        }   
        yield put(meSuccess(data));
        }
      } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(meFailed(errorMessage))
      }
}    

export function* watchMeSaga() {
  yield takeLatest(ME.LOAD, meSaga);
}

function* sessionUserSaga(action) {
    const session = getLocalStorage('fkv2_session')
    const { id, type} = session
    try {
        const result = yield user.getSession(id, type)
        if(result === 403) {
            yield put(sessionFail('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.getSession ? result.data.getSession : {}
            yield put(sessionSuccess(data))
        }
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        if (errorMessage === 'Unauthenticated.') {
                removeLocalStorage('fkv2_session')
                window.location.replace(process.env.REACT_APP__BASE_URL);  

        } else{
            yield put(sessionFail(errorMessage))
        }
    }
}

export function* watchSessionUserSaga(){
    yield takeLatest(SESSION_USER.LOAD, sessionUserSaga)
}

//update user
function* updateUser(action) {
    const { payload } = action
    try {
        const result = yield user.updateUser(payload)
        // let updateCover;
        let coverResult;
        // if(payload.profileCoverPhoto){
        //      updateCover = yield user.imageCoverPhoto(payload)
        //      coverResult = updateCover && updateCover.data && updateCover.data.upsertCoverPhoto
        // }
        const data = result && result.data && result.data.userUpdate ? result.data.userUpdate : {}
        yield put(meSuccess({data, coverResult}));
        // if(result === 403 || updateCover === 403){
        //     yield put(meFailed('Something went wrong try again!'))
        // }else{
        //     yield put(meSuccess({data, coverResult}));
        // } 
    }catch (error){
        console.log('error', JSON.stringify(error))
        const errorMessage = 'Validation error'
        yield put(meFailed(errorMessage))
    }
}

export function* watchUpdateUser() {
    yield takeLatest(ME.UPDATE, updateUser)
}

function* verifyEmail(action) {
    try {
        const result = yield auth.verifyEmail(action.payload)
        if(result === 403){
            yield put(emailVerifyFail('Something went wrong try again!'))
        }else{
            const message = result && result.data && result.data.verifyUserEmail && result.data.verifyUserEmail.message ? result.data.verifyUserEmail.message : ''
            yield put(emailVerifySuccess(message));
        } 
    }catch (error){
        console.log('error ', JSON.stringify(error))
        const errorMessage = 'Validation error'
        yield put(emailVerifyFail(errorMessage))
    }
}

export function* watchVerifyEmail(){
    yield takeLatest(EMAIL_VERIFY.LOAD, verifyEmail)
}

//resend email verification
function* resendEmailVerification(action) {
    try {
        const result = yield auth.resendEmailVerification(action.payload)
        if(result === 403){
            yield put(resendEmailVerificationFail('Something went wrong try again!'))
        }else{
            const message = result && result.data && result.data.resendEmailVerification && result.data.resendEmailVerification.message ? result.data.resendEmailVerification.message : ''
            yield put(resendEmailVerificationSuccess(message));
        } 
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(resendEmailVerificationFail(errorMessage))
    }
}

export function* watchResendEmailVerification(){
    yield takeLatest(RESEND_EMAIL_VERIFICATION.LOAD, resendEmailVerification)
}

//changePassword
function* changePassword(action) {
    const { payload } = action
    const id = payload.id
    const currentPassword = payload.currentPassword
    const password = payload.password
    // const rePassword = payload.rePassword
    const email = payload.email
    const type = payload.type

    try{
        const result = yield user.changePassword({id, currentPassword, password, email, type})
        if(result === 403) {
            yield put(cPasswordFailed('Something went wrong try again!'))
        }
        else {
            let data
            if (id) {
                data = result.data.userChangePassword.message ? result.data.userChangePassword.message : null 
            } else {
                data = result.data.publicChangePassword.message ? result.data.publicChangePassword.message : null 
            }

            yield put(cPasswordSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(cPasswordFailed(errorMessage))
    }
}

export function* watchChangePassword() {
    yield takeLatest(CHANGE_PASSWORD.LOAD, changePassword);
}

//send forgot password email
function* sendForgotEmail(action) {
    const email = action.payload.email
    try {
        const result = yield user.forgotPassword(email)

        if(result === 403){
            yield put(forgotPasswordFailed('Something went wrong try again!'))
        }else{
            const message = result && result.data && result.data.userForgotPassword && result.data.userForgotPassword.message ? result.data.userForgotPassword.message : ''
            yield put(forgotPasswordSuccess(message));
        } 
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(forgotPasswordFailed(errorMessage))
    }
}

export function* watchSendForgotEmail(){
    yield takeLatest(SEND_FORGOT_PASSWORD.LOAD, sendForgotEmail)
}

function* logoutUser() {
    try {
        const result = yield auth.userLogout()

        if(result === 403) {
            yield put(logoutFail('Something went wrong try again!!!'))
        }
        else{
            removeLocalStorage('fkv2_session')
            removeLocalStorage('fkv2_search_key')
            window.location.replace(process.env.REACT_APP__BASE_URL); 
        }
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
            error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
              error && error[0] && error[0].message ? error[0].message : 
                theme.text.error.default
        if (errorMessage === 'Unauthenticated.') {
                removeLocalStorage('fkv2_session')
                window.location.replace(process.env.REACT_APP__BASE_URL);  
        } else{
            yield put(logoutFail(errorMessage))
        }
    }
}

export function* watchLogoutUser(){
    yield takeLatest(LOGOUT.LOAD, logoutUser)
}

//login as user
function* loginAsUser(action) {
    
    const { payload } = action 
            try{
            const result = yield auth.getLoginAsUserToken(payload)
            if(result === 403) {
                yield put(loginAsUserFail('Something went wrong try again!'))
            }
            else {
                //save token & type  & user id to localstorage
                const data = result.data.getLoginAsUserToken.oc_t_user ? result.data.getLoginAsUserToken.oc_t_user : null
                const id = result.data.getLoginAsUserToken.oc_t_user.pk_i_id ? result.data.getLoginAsUserToken.oc_t_user.pk_i_id : null
                const access_token = result.data.getLoginAsUserToken.access_token ? result.data.getLoginAsUserToken.access_token : null
                const fkv2_session = {"access_token": access_token, "type": "user", 'id': id, "login_as_admin": true}

                //logout admin
                //delete current fkv2_session
                const logout = adminLogout()
                if (logout) {
                    yield saveLocalStorage("fkv2_session", fkv2_session)
                    yield put(loginAsUserSuccess('Login as User, redirecting...'))
                }
                else {
                    yield put(loginAsUserFail('Error logging out as Admin, try again.'))
                }
            }
        } catch (e) {
            const error = e && e.graphQLErrors ? e.graphQLErrors : e
            const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
                ? error[0].extensions.validation : 
                error && error[0] && error[0].message ? error[0].message : theme.text.error.default
            console.log('error', JSON.stringify(e))
            yield put(loginAsUserFail(errorMessage))
        }

        function* adminLogout() {
            let response = false
            try {
                const result = yield admin.adminLogout()
        
                if(result === 403) {
                    yield put(loginAsUserFail('Something went wrong try again!!!'))
                }
                else{
                    removeLocalStorage('fkv2_session')
                    response = true
                }
            } catch (e){
                const error = e && e.graphQLErrors ? e.graphQLErrors : e
                const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation ? error[0].extensions.validation : 
                    error && error[0] && error[0].debugMessage ? error[0].debugMessage : 
                      error && error[0] && error[0].message ? error[0].message : 
                        theme.text.error.default
                if (errorMessage === 'Unauthenticated.') {
                        removeLocalStorage('fkv2_session')
                        window.location.replace(`${process.env.REACT_APP_BASE_URL}admin`);  
                } else{
                    yield put(loginAsUserFail(errorMessage))
                }
                response = false
            }
            return response;
        }
}

export function* watchLoginAsUser() {
    yield takeLatest(ADMIN.LOGIN_AS_USER.LOAD, loginAsUser);
}

