import React, {useState, memo} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {watchlistLikeDislikeLoad} from '../../../../../redux/action'
import {OtherItemCard} from './OtherItemsCustomComponent'

const OtherItemsFromSeller = (props) => {
    const item = props && props.itemView && props.itemView.data ? props.itemView.data : []
    const userId = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
    const sessionMobile = props && props.sessionMobile ? props.sessionMobile : 0
    const itemLoading = props && props.itemView && props.itemView.isLoading ? props.itemView.isLoading : false
    const itemViewId = item && item.pk_i_id ? item.pk_i_id : 0
    const userdata = item && item.item_from_same_seller ? item.item_from_same_seller : []
    const userItems = userdata && userdata.length !== 0 ? userdata.filter(item => item.pk_i_id !== itemViewId) : []
    const upto = window.innerWidth < 600 ? 6 : 5
    const sliceuserItems = userItems && userItems.length !== 0 ? userItems.slice(0, upto).map((item)=> item) : []
    const likeDislikeLoading = props.watchList && props.watchList.isLoading ? props.watchList.isLoading : false
    const sellerId = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.pk_i_id ? props.itemView.data.oc_t_user.pk_i_id : 0
    const watchlistArr = props.watchlist && props.watchlist.data ? props.watchlist.data : []

    console.log("itemView", item)
    // useEffect(() => {
    //     if(userId){
    //         props.meLoad({id: `${userId}/`})
    //     }
    // },[])

    const likeDislike = (item_id) => {
        props.watchlistLikeDislikeLoad({userId: sessionMobile ? sessionMobile : userId, itemId: item_id})
      }

    return (
        itemLoading ? null : userItems && userItems.length === 0 ? null :
        <div className="item-view-related-sub-container">
            <div className="related-label">
                <p>{`From The Same Seller`}</p>
                <Link to={`/user/${sellerId}/listing`}>
                    <span className="see-more-span">{`View more`}</span>
                </Link>
                {
                    // parseInt(upto) === parseInt(categoryTotal) ? null :
                    // <div onClick={() => handleSetUpto()}>
                    //     <span className="see-more-span">{`View more`}</span>
                    // </div>
                }
            </div>
            <div className="flex flex-justify-center flex-align-center">
                    <div className="related-ads-container">
                    {
                // sliceRelatedData.map((related_item, index) => {
                //     const social_user = related_item && related_item.oc_t_user && related_item.oc_t_user.social && related_item.oc_t_user.social[0] && related_item.oc_t_user.social[0].data && related_item.oc_t_user.social[0].data.picture && related_item.oc_t_user.social[0].data.picture.data && related_item.oc_t_user.social[0].data.picture.data.url ? related_item.oc_t_user.social[0].data.picture.data.url : null
                //     const user_resource = related_item && related_item.oc_t_user && related_item.oc_t_user.oc_t_user_resource ? related_item.oc_t_user.oc_t_user_resource : []                  
                //     const id = related_item && related_item.pk_i_id ? related_item.pk_i_id : 0

                //     return <div key={index}>
                //         <RelatedItemCard related_item={related_item} setForceRender={setForceRender} likeDislike={likeDislike} seller_id={seller_id} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} watchList={watchlistArr} session_id={userId} sessionMobile = {sessionMobile}/>
                //             </div>
                // })
                sliceuserItems.map((data, index) => {
                    return (
                        <div key={index} className='w-100'>
                            <OtherItemCard data={data} sessionMobile={sessionMobile} sessionId={userId} likeDislike={likeDislike} likeDislikeLoading={likeDislikeLoading} watchlistArr={watchlistArr}/>
                        </div>
                    )
                })
                    }
                    </div>
                </div>
        </div>
    )
}
const mapStateToProps = store => {
    return {
    //   me: store.userReducer,
    //   avatar: store.avatarReducer,
      itemView: store.itemViewReducer,
      watchList: store.watchlistReducer
    };
  };
  
  const mapActionToProps = {
    watchlistLikeDislikeLoad
  };

export default connect(mapStateToProps,mapActionToProps)(memo(OtherItemsFromSeller))