import React, {useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { itemViewLoad } from '../../../../../../../redux/action'
import FKImage from '../../../../../../../common/component/image'
import SocialAvatar from '../../../../../../../common/component/socialAvatar'
import { numberWithCommas } from '../../../../../../../common/helper/utility'
import { ItemContext } from '../../../../../../../common/context'
import { ItemDetail } from './component/CustomComponent'
import DefaultPhoto from '../../../../../../../assets/images/defaultAvatar.png'
import LikeButton from '../../../../../../../common/component/like'
import { GAevent } from '../../../../../../../common/googleAnalytics'
import { ReportIcon } from '../../../../../../../assets/svg'

const { REACT_APP_BASE_URL } = process.env;

const Seller = (props) => {
    const id = props.itemId ? props.itemId : 0
    const loading = props.itemView.isLoading
    const itemData = props && props.itemView && props.itemView.data ? props.itemView.data : {}
    const sellerUsername = itemData && itemData.oc_t_user && itemData.oc_t_user.s_username ? itemData.oc_t_user.s_username : null
    const userId  = props.session ? props.session : 0

    const itemUserId = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.pk_i_id ? props.itemView.data.oc_t_user.pk_i_id : 0
    const user_resource = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.oc_t_user_resource ? props.itemView.data.oc_t_user.oc_t_user_resource : null
    const user_social_id = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.social && props.itemView.data.oc_t_user.social[0]
    && props.itemView.data.oc_t_user.social[0].data && props.itemView.data.oc_t_user.social[0].data.id ? props.itemView.data.oc_t_user.social[0].data.id : ''
    const user_social_resource = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.social && props.itemView.data.oc_t_user.social[0]
        && props.itemView.data.oc_t_user.social[0].data && props.itemView.data.oc_t_user.social[0].data.picture && props.itemView.data.oc_t_user.social[0].data.picture.data
        && props.itemView.data.oc_t_user.social[0].data.picture.data.url ? props.itemView.data.oc_t_user.social[0].data.picture.data.url : null
    
    const taExp = itemData && itemData.ta_expiration ? itemData.ta_expiration : 0
    const hlExp = itemData && itemData.hl_expiration ? itemData.hl_expiration : 0
    const item_price = itemData && itemData.get_i_price ? numberWithCommas((itemData.get_i_price).toFixed(2)) : ""
    const item_title = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_title ? itemData.oc_t_item_description.s_title : ''
    const item_desc = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_description ? itemData.oc_t_item_description.s_description : ''
    // const { sendIsLoading } =props.chat
    const [isShowEdit, setIsShowEdit] = useState(false)

    // const raw_price = itemData && itemData.get_i_price ? itemData.get_i_price.toFixed(2) : 0
    // const category = itemData && itemData.oc_t_category ? itemData.oc_t_category : {}
    // const { updateIsLoading, updateMessage, updateError } = props && props.itemView
    // const [currentCoin, setCurrentCoin] = useState(0)
    // const { sessionData } = props.me

    const [isShowDelete, setIsShowDelete] = useState(false)
    const deleteMessage  = props && props.itemView && props.itemView.deleteMessage || false
    const deleteError  = props && props.itemView && props.itemView.deleteError || false
    const session_id = props && props.session ? parseInt(props.session) : null
    const likeDislikeLoading = props.watchlist && props.watchlist.isLoading ? props.watchlist.isLoading : false
    const date_published = props && props.itemView && props.itemView.data && props.itemView.data.dt_pub_date ? props.itemView.data.dt_pub_date : null
    const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null

    const item_custom_field = itemData && itemData.custom_fields ? itemData.custom_fields : {}
    const category_custom_field = itemData && itemData.oc_t_category && itemData.oc_t_category.oc_t_custom_field ? itemData.oc_t_category.oc_t_custom_field : []

    useEffect(() => {
        if(deleteError){
            setTimeout(() => {
                props.itemDeleteReset()
                setIsShowDelete(false)
            }, 2000)
        }
        if(deleteMessage){
            setTimeout(() => {
                props.itemDeleteReset()
                setIsShowDelete(false)
                window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
            }, 2000)
        }
    // eslint-disable-next-line
    }, [deleteError, deleteMessage])

    const toggleLiked = (item_id) => {
        props.watchlistLikeDislikeLoad({userId: userId, itemId: item_id})
    }

    const { watchlist_count, watcher } = itemData
    const [isLiked, setIsLiked] = useState(false)

    useEffect(() => {
        if (watchlist_count > 0) {
            if (watcher.includes(session_id)) {
              setIsLiked(true)
            } else {
              setIsLiked(false)
            }
        }
        else {
          setIsLiked(false)
        }
      // eslint-disable-next-line  
    }, [watcher])

    const messageClick = () => {
        if (session_id) {
          GAevent("user", "message")
        }
    }

    return (
        <div className="contact-seller-container" style={{display: Object.keys(itemData).length === 0 && itemData.constructor === Object ? 'none': 'flex'}} >
            {  
              loading ? null :
                <div>
                     <div> 
                        <div className='user-seller-sub-container'> 
                            <Link to={userId === itemUserId ? `/user/listing`: `/user/${itemUserId}/listing`} style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '50px', display:'flex', alignItems: 'center'}}>
                            {   
                            user_social_resource ?
                            <SocialAvatar id={user_social_id} url={user_social_resource} /> : user_resource ? 
                            <FKImage images={[user_resource]} type="seller_avatar" /> :
                            <img src={DefaultPhoto} width={40} height={40} alt="item owner avatar" />
                            }                                
                            </div>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px'}}> <p style={{fontWeight: 'bold', color: '#222A35', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '160px'}}>{sellerUsername}</p> 
                            </div></Link>   
                           {userId === itemUserId ? 
                            <div className='user-seller-edit-delete-container'>
                                <p style={{textAlign: 'right', fontSize: 20, margin: 0}} onClick={() => setIsShowEdit(true)}><i className="far fa-edit"></i></p>
                                <p style={{textAlign: 'right', fontSize: 20, margin: 0}} onClick={() => setIsShowDelete(true)}><i className="fas fa-trash-alt"></i></p>
                            </div>
                            : 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
                                    {!userId || userId === 0 ? 
                                        <>
                                            <Link to={`/login`}><i className="far fa-heart" /></Link> 
                                            <Link to={`/login`}><ReportIcon /></Link> 
                                        </>
                                        : 
                                        <>
                                        <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id}/>
                                        <div style={{ color: '#373737', cursor: 'pointer'}} id="report-icon" onClick={() => alert(id, userId)} ><ReportIcon /></div>
                                        </>
                                    }
                                    <Link to={{
                                        pathname: !userId ? `/login` : `/user/messages/inbox`,
                                        state: {fromMessage: true, item: itemData}
                                        }} className="message-seller-btn"
                                        onClick = {messageClick}
                                    >Message</Link>
                                </div>
                            } 
                        </div>
                        <ItemContext.Provider value={{
                                item_price, item_title, item_desc, userId, itemUserId, hlExp, taExp, itemData, formatted_date, 
                                item_custom_field, category_custom_field
                        }}>
                                <ItemDetail />
                        </ItemContext.Provider>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = store => {
    return {
        itemView: store.itemViewReducer,
    }
}

const mapActionToProps = {
    itemViewLoad,
}

export default connect(mapStateToProps, mapActionToProps)(Seller)