import React, {useEffect, useState} from 'react';
import { HeartIcon, HeartMobileIcon, DefaultHeartIcon} from '../../../assets/svg'

const LikeButton = (props) => {
    const {toggleLiked, likeDislikeLoading, likeId, isLiked} = props
    const style = props && props.style ? props.style : ''
    const [icon, setIcon] = useState()

    useEffect(() => {
        if(style){
            switch(style){
                case 'black-border-heart': 
                    return setIcon(<DefaultHeartIcon />)
                break;
            }
        }else{
            if(window.innerWidth < 600){
                return setIcon(<HeartMobileIcon />)
            }else{
                return setIcon(<HeartIcon />)
            }
        }
    }, [props])

    return (
        <div className="flex flex-justify-center flex-align-center">
            <div className="favouriteButton">
            <div className={`bg-trans ${isLiked ? `text-red` : ``} flex-align-center cursor-pointer`} onClick={() => likeDislikeLoading ? null : toggleLiked(likeId)}>
                {/* <i className={ ? "fa fa-heart" : "far fa-heart"} /> */}
                <div className={isLiked ? `like-button` : style && style === 'black-border-heart' ? `` : `default-like-button`}>
                    {icon}
                </div>
               
            </div>
            </div>                
        </div>
    );
};

export default LikeButton;