import gql from 'graphql-tag';
import client from '../apollo/apollo-client';
import moment from 'moment';

export const getItems = (page, first, searchKey, categoryId, user_id) => {
  if (page === 1) {
    client.cache.reset()
  }
  const now = new Date()
  const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

  try {
      const query = gql`query publicGetItemsByStatus($session_user_id: Int, $limit: Int, $search: String, $category_id: Int, $b_active: Boolean, $ab_expiration: DateTime, $first: Int, $page: Int, $b_mark_as_sold: Boolean, $dt_sold: Boolean, $orderBy: [OrderByClause!]
        ){
        publicGetItemsByStatus(session_user_id: $session_user_id, limit: $limit, search: $search, category_id: $category_id, b_active: $b_active, first: $first, page: $page, 
          b_mark_as_sold: $b_mark_as_sold, dt_sold: $dt_sold, ab_expiration: $ab_expiration
          orderBy: $orderBy
        ){
          top_searches{
            pk_i_id
            oc_t_item_description {
              s_title
            }
            fk_i_user_id
            dt_pub_date
            status
            get_i_price
            i_price
            is_new
            hl_expiration
            ta_expiration
            ab_expiration
            post_type
            watchlist_count
            watcher
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_extension
              s_content_type
              s_path
            }
            oc_t_user{
              pk_i_id
              s_username
              social{
                id
                fk_i_user_id
                data
              }
              oc_t_user_resource {
                pk_i_id
                fk_i_user_id
                s_name
                s_content_type
                s_path
                s_extension
              }
              items_count
            }
          }
          recommended_for_you {
            pk_i_id
            oc_t_item_description {
              s_title
            }
            fk_i_user_id  
            dt_pub_date
            status
            get_i_price
            i_price
            is_new
            hl_expiration
            ta_expiration
            ab_expiration
            post_type
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_extension
              s_content_type
              s_path
            }
            watchlist{
              id
              fk_i_user_id
              fk_i_item_id
            }
            watchlist_count
            watcher
            oc_t_user{
              pk_i_id
              s_username
              social{
                id
                fk_i_user_id
                data
              }
              oc_t_user_resource {
                pk_i_id
                fk_i_user_id
                s_name
                s_content_type
                s_path
                s_extension
              }
              items_count
            }
          }
          paginatorInfo
          data{
            is_new
            b_reserved
            b_mark_as_sold
            fk_i_user_id
            pk_i_id
            i_price
            get_i_price
            status
            dt_pub_date
            dt_sold
            s_contact_name
            ta_expiration
            hl_expiration
            ab_expiration
            watchlist_count
            post_type
            watchlist{
              id
              fk_i_user_id
              fk_i_item_id
            }
            watcher
            oc_t_item_description {
              fk_i_item_id
              s_title
              s_description
            }
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_path
              s_extension
              s_content_type
            }
            oc_t_user{
              s_name
              s_username
              items_count
              purchases{
                id
                total_purchase
                type
                created_at
                detail
                item_id
                service{
                  id
                  status
                  name
                  description
                  type
                  days
                }
              }
              oc_t_user_resource {
                pk_i_id
                fk_i_user_id
                s_name
                s_content_type
                s_path
                s_extension
              }
              social {
                data
              }
            }
            oc_t_category{
              pk_i_id
              fk_i_parent_id
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
              oc_t_parent_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
           }
         }
      }`;
      const response = client.query({
          query: query,
          variables: {
            session_user_id: user_id,
            limit: 4,
            search: searchKey,
            category_id: categoryId,
            b_active: true, ab_expiration: null, first: first, page: page, b_mark_as_sold: true, dt_sold: null, order_field1: "ta_expiration",
            orderBy: 
            [
              {field: "ta_expiration", order: "DESC"},
              {field: "dt_pub_date", order: "DESC"}
            ]
          }
      });
      const data = response ? response : null;
      // console.log('data', data)
      return data;
  } catch (e) {
      console.log('e', JSON.stringify(e))
      throw e
  }
}

export const getItemCategory = () => {
    try {
      const query = gql`query publicGetItemCategoryByStatus($b_enabled: Boolean, $first: Int, $page: Int, $orderBy: [OrderByClause!]){
        publicGetItemCategoryByStatus(b_enabled: $b_enabled, first: $first, page: $page, orderBy: $orderBy){
          paginatorInfo{
            currentPage
            count
            total
            hasMorePages
          }
          data{
                b_enabled
                i_position
                fk_i_parent_id
                oc_t_parent_category_descriptions{
                  s_name
                  fk_i_category_id
                }
                pk_i_id
                oc_t_category_descriptions{
                  s_name
                  fk_i_category_id
                } 
                oc_t_custom_field {
                  name
                  label
                  placeholder
                  fk_i_category_id
                  type
                  custom_index
                }           
           }
         }
      }`;
      const response = client.query({
          query: query,
          variables: {b_enabled: true, first: 500, page: 1,
          orderBy: 
                      [
                        {"field": "i_position", "order": "DESC"}
                      ] 
                    },
      });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const itemCreate = (data) => {
  try {
      const query = gql`mutation itemCreate($input: ItemCreateInput){
        itemCreate(input: $input){
          message
          response {
            is_new
            pk_i_id
            i_price
            status
            s_item_condition
            s_dm_meetup_add1
            s_dm_meetup_add2
            s_dm_shipped_details
            s_youtube_link 
            oc_t_category{
              pk_i_id
              fk_i_parent_id
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_extension
              s_content_type
              s_path
            }
            oc_t_item_description{
              s_title
              s_description
            }
            custom_fields
            s_dm_meetup_add1_notes
            s_dm_meetup_add2_notes
            youtube_embed_link
            post_type
            live
          }
        }
      }`;
      const response = client.mutate({
          mutation: query,
          variables: {input: 
            { 
            fk_i_user_id: parseInt(data.fk_i_user_id),
            fk_i_category_id: parseInt(data.fk_i_category_id),
            i_price: data.i_price,
            s_contact_name: data.s_contact_name,
            fk_c_currency_code: data.fk_c_currency_code,
            s_contact_email: data.s_contact_email,
            fk_c_locale_code: "en_US",
            s_title: data.s_title,
            s_description: data.s_description,
            imageData: data.finalImageDataArr,
            s_item_condition: data.item_condition,
            s_dm_meetup_add1: data.s_dm_meetup_add1,
            s_dm_meetup_add2: data.s_dm_meetup_add2,
            s_dm_shipped_details: data.s_dm_shipped_details,
            s_youtube_link: data.youtube_link,
            custom_fields: data.custom_fields,
            s_dm_meetup_add1_notes: data.meetupAddnotesAddress1,
            s_dm_meetup_add2_notes: data.meetupAddnotesAddress2,
            post_type: data.post_type,
            s_ip: data.s_ip
            }
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      console.log('gql e', JSON.stringify(e), e)
      throw e
  }
}

export const getItemView = (id) => {
  try{
    const query = gql `query publicGetItemById($id: ID, $b_active: Boolean){
      publicGetItemById(pk_i_id: $id, b_active: $b_active){
        item_from_same_seller {
          pk_i_id
          oc_t_item_description {
            s_title
          }
          get_i_price
          i_price
          fk_c_currency_code
          watcher
          watchlist_count
          status
          pageview_count
          post_type
          b_mark_as_sold
          dt_sold
          is_new
          s_item_condition
          watchlist{
            fk_i_user_id
            fk_i_item_id
          }
          oc_t_item_resource{
            pk_i_id
            fk_i_item_id
            s_name
            s_extension
            s_content_type
            s_path
          }
          oc_t_user{
            pk_i_id
            s_username
            social{
              id
              fk_i_user_id
              data
            }
            oc_t_user_resource {
              pk_i_id
              fk_i_user_id
              s_name
              s_content_type
              s_path
              s_extension
            }
            items_count
          }
          dt_pub_date
        }
        custom_fields
        is_new
        b_active
        b_reserved
        dt_pub_date
        dt_sold
        b_mark_as_sold
        pk_i_id
        fk_i_user_id
        i_price
        get_i_price
        status
        s_contact_name
        s_contact_email
        fk_c_country_code
        ta_expiration
        hl_expiration
        ab_expiration
        s_item_condition
        s_dm_meetup_add1
        s_dm_meetup_add2
        s_dm_shipped_details
        s_youtube_link 
        watchlist_count
        post_type
        watchlist{
          id
          fk_i_user_id
          fk_i_item_id
        }
        watcher
        oc_t_item_description{
          s_title
          s_description
        }
        oc_t_item_resource {
          pk_i_id
          fk_i_item_id
          s_name
          s_extension
          s_content_type
          s_path
        }
        oc_t_category{
          pk_i_id
          fk_i_parent_id
          oc_t_category_descriptions{
            fk_i_category_id
            s_name
          }
          oc_t_custom_field {
            name
            label
            placeholder
            fk_i_category_id
            type
            custom_index
          }
        }
    		oc_t_user{
          pk_i_id
          s_name
          s_username
          items_count
          is_phone_public
          s_phone_mobile
          purchases{
            id
            total_purchase
            type
            status
            created_at
            detail
            item_id
            service{
              id
              status
              name
              description
              type
              days
            }
          }
          oc_t_user_resource{
            pk_i_id
            fk_i_user_id
            s_extension
            s_name
            s_content_type
            s_path
          }
          social{
            data
          }
          wallet
        }
        s_dm_meetup_add1_notes
        s_dm_meetup_add2_notes
        youtube_embed_link
        pageview_count
        item_chat_count
      }
    }`;

    const response = client.query({
      query: query,
      variables: {"id": id, "b_active": true },
      // fetchPolicy: 'no-cache'
    });

    const data = response ? response : null;
    return data;
  } catch(e) {
    console.log('e', e)
    throw e
  }
}

export const itemUpdate = (data) => {
  try {
      const query = gql`mutation itemUpdate($data: ItemCreateInput){
        itemUpdate(data: $data){
          message
          response{
                    is_new
                    fk_i_user_id
                    pk_i_id
                    i_price
                    get_i_price
                    status
                    dt_pub_date
                    dt_sold
                    s_contact_name
                    ta_expiration
                    hl_expiration
                    ab_expiration
                    watchlist_count
                    post_type
                    watchlist{
                      id
                      fk_i_user_id
                      fk_i_item_id
                    }
                    watcher
                    oc_t_item_description {
                      fk_i_item_id
                      s_title
                      s_description
                    }
                    oc_t_item_resource{
                      pk_i_id
                      fk_i_item_id
                      s_name
                      s_path
                      s_extension
                      s_content_type
                    }
                    oc_t_user{
                      pk_i_id
                      s_name
                      s_username
                      oc_t_user_resource {
                        pk_i_id
                        s_extension
                      }
                      social{
                        id
                        fk_i_user_id
                        provider
                        provider_email
                        data
                      }
                    }
                    oc_t_category{
                      pk_i_id
                      fk_i_parent_id
                      oc_t_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                      oc_t_parent_category_descriptions{
                        fk_i_category_id
                        s_name
                      }

                    }
                    custom_fields
                    s_dm_meetup_add1_notes
                    s_dm_meetup_add2_notes
                    youtube_embed_link
              }
          }
        }`;
      const response = client.mutate({
          mutation: query,
          variables: {data: {
            "pk_i_id": parseInt(data.pk_i_id),
            "fk_i_category_id": parseInt(data.categoryId),
            "i_price": data.raw_price,
            "imageData": data.imageData,
            "s_title": data.item_title,
            "s_description": data.item_desc,
            "post_type": data.post_type,
            "custom_fields": data.custom_fields,
            "s_youtube_link": data.s_youtube_link,
            "s_dm_meetup_add1": data.deal_method.add1.location,
            "s_dm_meetup_add2": data.deal_method.add2.location,
            "s_dm_meetup_add1_notes": data.deal_method.add1.notes,
            "s_dm_meetup_add2_notes": data.deal_method.add2.notes,
            "s_dm_shipped_details": data.deal_method.shipped
            } 
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      throw e
  }
}

export const itemImageEdit = (data) => {
  try {
      const query = gql`mutation itemImageUpdate($data: ItemCreateInput){
        itemImageUpdate(data: $data){
          message
          response{ is_new
                    fk_i_user_id
                    pk_i_id
                    i_price
                    get_i_price
                    status
                    dt_pub_date
                    dt_sold
                    s_contact_name
                    ta_expiration
                    hl_expiration
                    ab_expiration
                    watchlist_count
                    watchlist{
                      id
                      fk_i_user_id
                      fk_i_item_id
                    }
                    watcher
                    youtube_embed_link
                    oc_t_item_description {
                      fk_i_item_id
                      s_title
                      s_description
                    }
                    oc_t_item_resource{
                      pk_i_id
                      fk_i_item_id
                      s_name
                      s_path
                      s_extension
                      s_content_type
                    }
                    oc_t_user{
                      pk_i_id
                      s_name
                      s_username
                      oc_t_user_resource {
                        pk_i_id
                        s_extension
                      }
                    }
                    oc_t_category{
                      pk_i_id
                      fk_i_parent_id
                      oc_t_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                      oc_t_parent_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                    }
              }
          }
        }`;
      const response = client.mutate({
          mutation: query,
          variables: { data: {
            "pk_i_id": parseInt(data.id),
            "imageData": data.file
            }
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      console.log('e', JSON.stringify(e))
      throw e
  }
}

//Report
export const reportCreate = (data) => {
  const { reporter_id,reported_id, reported_user_id, report_reason, report_type } = data 
  try {
      const query = gql`mutation reportCreate($data: CreateReportInput){
        reportCreate(data: $data){
          message
          response{
            id
          }
        }
      }`;
      const response = client.mutate({
          mutation: query,
          variables: {data: 
            { 
              reporter_id: parseInt(reporter_id) ,
              reported_id: parseInt(reported_id),
              reported_user_id: parseInt(reported_user_id),
              report_reason: report_reason,
              reportable_id: 1,
              report_type: report_type
            }
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const getTopAds = () => {
  const now = new Date()
  const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')
  try {
      const query = gql`query publicGetTopAdByStatus($b_active: Boolean, $ta_expiration: DateTime, $first: Int, $page: Int, $orderBy: [OrderByClause!]){
        publicGetTopAdByStatus(b_active: $b_active, ta_expiration: $ta_expiration, first: $first, page: $page, orderBy: $orderBy){
          paginatorInfo{
            currentPage
            count
            total
            hasMorePages
          }
          data{
            is_new
            fk_i_user_id
            pk_i_id
            i_price
            status
            dt_pub_date
            dt_sold
            s_contact_name
            ta_expiration
            hl_expiration
            ab_expiration
            watchlist_count
            watchlist{
              id
              fk_i_user_id
              fk_i_item_id
            }
            watcher
            oc_t_item_description {
              fk_i_item_id
              s_title
              s_description
            }
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_path
              s_extension
              s_content_type
            }
            oc_t_user{
              s_name
              s_username
              oc_t_user_resource {
                pk_i_id
                s_extension
              }
              social {
                data
              }
              wallet
            }
            oc_t_category{
              pk_i_id
              fk_i_parent_id
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
              oc_t_parent_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
           }
         }
      }`;
      const response = client.query({
          query: query,
          variables: {b_active: true, ta_expiration: parsedNow, first: 50, page: 1, orderBy: 
            [
              {field: "ta_expiration", order: "DESC"},
            ] 
          }
      });

      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('e', e)
      throw e
  }
}

export const itemDelete = ( payload ) => {
  const {id, userId} = payload
  try {
      const query = gql`mutation itemDelete($pk_i_id: ID!, $fk_i_who_id: Int!, $s_section: String, $s_action: String, $s_who: String){
        itemDelete(pk_i_id: $pk_i_id, fk_i_who_id: $fk_i_who_id, s_section: $s_section,
        s_action: $s_action, s_who: $s_who
        ){
          message
        }
      }`;
      const response = client.mutate({
          mutation: query,
          variables: { 
            pk_i_id: parseInt(id),
            fk_i_who_id: parseInt(userId),
            s_section: 'item',
            s_action: 'delete',
            s_who: 'user'
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      console.log('gql e', JSON.stringify(e))
      throw e
  }
}

export const itemUpdateByField = (data) => {
  const { item_id, field_name, field_value } = data 
  try {
      const query = gql`mutation itemUpdateByField($item_id: ID!, $field_name: String!, $field_value: Mixed){
        itemUpdateByField(item_id: $item_id, field_name: $field_name, field_value: $field_value){
          message
          response{ is_new
                    fk_i_user_id
                    pk_i_id
                    i_price
                    get_i_price
                    status
                    dt_pub_date
                    dt_sold
                    s_contact_name
                    ta_expiration
                    hl_expiration
                    ab_expiration
                    b_mark_as_sold
                    watchlist_count
                    post_type
                    watchlist{
                      id
                      fk_i_user_id
                      fk_i_item_id
                    }
                    watcher
                    oc_t_item_description {
                      fk_i_item_id
                      s_title
                      s_description
                    }
                    oc_t_item_resource{
                      pk_i_id
                      fk_i_item_id
                      s_name
                      s_path
                      s_extension
                      s_content_type
                    }
                    oc_t_user{
                      pk_i_id
                      s_name
                      s_username
                      oc_t_user_resource {
                        pk_i_id
                        s_extension
                      }
                      social{
                        id
                        fk_i_user_id
                        provider
                        provider_email
                        data
                      }
                    }
                    oc_t_category{
                      pk_i_id
                      fk_i_parent_id
                      oc_t_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                      oc_t_parent_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                    }
              }
          }
        }`;
      const response = client.mutate({
          mutation: query,
          variables: {
            item_id: item_id,
            field_name: field_name,
            field_value: field_value
          }
      });
      const result = response ? response : null;
      return result;
  } catch (e) {
      throw e
  }
}

export const pageview = ( $data ) => {
  const {fk_i_item_id, fk_i_user_id, fk_i_viewer_id, pageviewable_type} = $data
  try {
      const query = gql`mutation pageview($input: CreatePageviewInput!){
        pageview(input: $input){
          id
        }
      }`;
      const response = client.mutate({
          mutation: query,
          variables: {
            input: {
                  "fk_i_item_id": parseInt(fk_i_item_id),
                  "fk_i_user_id": fk_i_user_id,
                  "fk_i_viewer_id": parseInt(fk_i_viewer_id),
                  "pageviewable_type": pageviewable_type   
            }
          }
      });
      return false;
  } catch (e) {
      console.log('gql e', JSON.stringify(e))
      // throw e
  }
}

export const getBumps = () => {
  const now = new Date()
  const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')
  try {
      const query = gql`query publicGetBumpByStatus($b_active: Boolean, $ab_expiration: DateTime, $first: Int, $page: Int, $orderBy: [OrderByClause!]){
        publicGetBumpByStatus(b_active: $b_active, ab_expiration: $ab_expiration, first: $first, page: $page, orderBy: $orderBy){
            is_new
            fk_i_user_id
            pk_i_id
            i_price
            status
            dt_pub_date
            dt_sold
            s_contact_name
            ta_expiration
            hl_expiration
            ab_expiration
            watchlist_count
            watchlist{
              id
              fk_i_user_id
              fk_i_item_id
            }
            watcher
            oc_t_item_description {
              fk_i_item_id
              s_title
              s_description
            }
            oc_t_item_resource{
              pk_i_id
              fk_i_item_id
              s_name
              s_path
              s_extension
              s_content_type
            }
            oc_t_user{
              s_name
              s_username
              oc_t_user_resource {
                pk_i_id
                s_extension
              }
              social {
                data
              }
              wallet
            }
            oc_t_category{
              pk_i_id
              fk_i_parent_id
              oc_t_category_descriptions{
                fk_i_category_id
                s_name
              }
              oc_t_parent_category_descriptions{
                fk_i_category_id
                s_name
              }
            }
            i_price
            get_i_price
         }
      }`;
      const response = client.query({
          query: query,
          variables: {b_active: true, ab_expiration: parsedNow, first: 50, page: 1, orderBy: 
            [
              {field: "ab_expiration", order: "DESC"},
            ] 
          }
      });

      const data = response ? response : null;
      // console.log('data', data)
      return data;
  } catch (e) {
      console.log('e', JSON.stringify(e))
      throw e
  }
}

export default {
    getItems,
    getItemCategory,
    itemCreate,
    getItemView,
    itemUpdate,
    itemImageEdit,
    reportCreate,
    getTopAds,
    itemDelete,
    itemUpdateByField,
    pageview,
    getBumps
};
