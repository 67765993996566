import { AVATAR } from '../constant'

export const avatarLoad = (payload) =>{
    return {
        type: AVATAR.LOAD,
        payload
    }
}

export const avatarSuccess  = (payload) => {
    return {
        type: AVATAR.SUCCESS,
        payload
    }
}

export const avatarFail = (payload) => {
    return {
        type: AVATAR.FAIL,
        payload
    }
}

export const avatarUpdate = (payload) => {
    return {
        type: AVATAR.UPDATE,
        payload
    }
}
