import React, { useContext, useEffect, useState, useRef } from 'react';
import { ItemContext } from '../../../../../../../../common/context';
import { isEmpty } from '../../../../../../../../common/helper/utility'

const CustomFieldsList = (props) => {
    const { customFields, categoryCustomFields } = props
    
    const renderCustomFields = () => {
        let mergedData = []
        if (categoryCustomFields.length > 0) {
            mergedData = categoryCustomFields.map((item) => {
                const parsedCustomField = isEmpty(customFields) ? {} : JSON.parse(customFields)
                const value = parsedCustomField && parsedCustomField[item.name] ? parsedCustomField[item.name] : ''
                return {...item, value: value}
            })
        }
        let element = []
        mergedData.map((item, index) => {
        element.push(<p key={index}><span className={'custom-field-label'}>{`${item.label} : `}</span>{item.value}</p>)
        })
        return element
    }
    return (renderCustomFields())
}

export const ItemDetail = () => {
    const value = useContext(ItemContext);
    const [readMore, setReadMore] = useState(false)
    const [descHeight, setDescHeight] = useState()
    const rect = useRef(null)

    let height
    useEffect(() => {
        height = rect.current ? rect.current.offsetHeight : 0;
        setDescHeight({ height: height, current: rect.current })
    }, []);

    const handleReadMoreClick = () => {
        setReadMore(!readMore)
    }

    const { item_price, item_title, item_desc, userId, itemUserId, hlExp, taExp, itemData, formatted_date, 
        item_custom_field, category_custom_field
    } = value
    const sold = itemData && itemData.b_mark_as_sold ? itemData.b_mark_as_sold : itemData.dt_sold ? true : false

    return (
        <div>
            <div style={{ padding: '10px 0px' }}>
                <p style={{ fontSize: '28px', fontWeight: '700', color: '#FB770D' }}>{`${item_price} $`}</p>
            </div>
            <div style={{ padding: '5px 0px' }}>
                <h1 style={{ fontSize: '23px', color: '#373737', fontWeight: 'normal' }}>{item_title}</h1>
                <p style={{fontSize: window.innerWidth < 600 ? '9.5px' : 12, fontStyle: 'normal', letterSpacing: '0.44px', color: '#c4c4c4'}}>{`${formatted_date ? formatted_date : null}`}</p>
            </div>
            <CustomFieldsList customFields={item_custom_field} categoryCustomFields={category_custom_field}/>
            <div style={{ padding: '20px 0px' }} >
                <h2 style={{ fontSize: '18px', color: '#373737' }}>{`Description:`}</h2>
                <p ref={rect} className={`item-info-description ${readMore ? `expand` : `shrink`}`}>{item_desc}</p>
                {descHeight ?
                    descHeight.height >= 300 ?
                        readMore ?
                            null : <span className="readMoreBtn" onClick={handleReadMoreClick}>{`Read More`}</span>
                        : null
                    : null}
            </div>
            {userId === itemUserId ?
                <div>
                    <div>
                        <p>{`HighLight: ${hlExp !== 0 ? sold ? `N/A` : hlExp : `N/A`}`}</p>
                    </div>
                    <div>
                        <p>{`Top Ad: ${taExp !== 0 ? sold ? `N/A` : taExp : `N/A`}`}</p>
                    </div>
                    {
                        sold ? null :
                        <>
                            <div style={{ padding: '10px 0px' }}>
                                <button onClick={() => alert()} style={{ padding: '10px 20px', background: '#FB770D', fontWeight: '500', fontSize: '15px', color: '#ecf0f1', borderRadius: '5px' }} >Promote</button>
                            </div>
                        </>
                    }
                </div> : null
            }
        </div>
    )
}