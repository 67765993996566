import { TOGGLE_LIVE, USE_NOW_PROMOTE, CANCEL_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../constant'

export const toggleLiveLoad = (payload) =>{
    return {
        type: TOGGLE_LIVE.LOAD,
        payload
    }
}    

export const toggleLiveSuccess = (payload) =>{
    return {
        type: TOGGLE_LIVE.SUCCESS,
        payload,
    }
}

export const toggleLiveFailed = (payload) =>{
    return {
        type: TOGGLE_LIVE.FAIL,
        payload,
    }
}

export const toggleLiveReset = () =>{
    return {
        type: TOGGLE_LIVE.RESET,
    }
}

export const servicePromoteLoad = (payload) =>{
    return {
        type: USE_NOW_PROMOTE.LOAD,
        payload
    }
}    

export const servicePromoteSuccess = (payload) =>{
    return {
        type: USE_NOW_PROMOTE.SUCCESS,
        payload,
    }
}

export const servicePromoteFailed = (payload) =>{
    return {
        type: USE_NOW_PROMOTE.FAIL,
        payload,
    }
}

export const servicePromoteReset = () =>{
    return {
        type: USE_NOW_PROMOTE.RESET,
    }
}

export const cancelSubscriptionLoad = (payload) =>{
    return {
        type: CANCEL_SUBSCRIPTION.LOAD,
        payload
    }
}    

export const cancelSubscriptionSuccess = (payload) =>{
    return {
        type: CANCEL_SUBSCRIPTION.SUCCESS,
        payload,
    }
}

export const cancelSubscriptionFailed = (payload) =>{
    return {
        type: CANCEL_SUBSCRIPTION.FAIL,
        payload,
    }
}

export const cancelSubscriptionReset = () =>{
    return {
        type: CANCEL_SUBSCRIPTION.RESET,
    }
}



export const updateSubscriptionLoad = (payload) =>{
    return {
        type: UPDATE_SUBSCRIPTION.LOAD,
        payload
    }
}    

export const updateSubscriptionSuccess = (payload) =>{
    return {
        type: UPDATE_SUBSCRIPTION.SUCCESS,
        payload,
    }
}

export const updateSubscriptionFailed = (payload) =>{
    return {
        type: UPDATE_SUBSCRIPTION.FAIL,
        payload,
    }
}

export const updateSubscriptionReset = () =>{
    return {
        type: UPDATE_SUBSCRIPTION.RESET,
    }
}