import React , {useEffect} from 'react';
import UserProfile from '../../profile'

const Settings = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> :
           <UserProfile userId={userId}/>
  );
}

export default Settings;