import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminSubscriptionSuccess, adminSubscriptionFailed, adminSubscriptionDetailSuccess, adminSubscriptionDetailFailed, adminUpdateSubscriptionSuccess, adminUpdateSubscriptionFailed,
    adminSubscriptionSearchSuccess, adminSubscriptionSearchFailed
} from "../../action";
import admin from '../../../graphql/admin/subscription'
import { theme } from '../../../common/theme'

//fetch subscription
function* adminSubscription(action) {
    const { payload } = action
    try{
        const result = yield admin.getSubscription(payload)
        if(result === 403) {
            yield put(adminSubscriptionFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getSubscriptions ? result.data.getSubscriptions : {}
            yield put(adminSubscriptionSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminSubscriptionFailed(errorMessage))
    }
}

export function* watchAdminSubscription() {
    yield takeLatest(ADMIN.SUBSCRIPTION.LOAD, adminSubscription);
}

//fetch subscription detail
function* adminSubscriptionDetail(action) {
    const { payload } = action
    try{
        const result = yield admin.getSubscriptionDetail(parseInt( payload.id))
        if(result === 403) {
            yield put(adminSubscriptionDetailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getSubscriptionDetail ? result.data.getSubscriptionDetail : {}
            yield put(adminSubscriptionDetailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', e)
        yield put(adminSubscriptionDetailFailed(errorMessage))
    }
}

export function* watchAdminSubscriptionDetail() {
    yield takeLatest(ADMIN.SUBSCRIPTION_DETAIL.LOAD, adminSubscriptionDetail);
}

//update subscription
function* adminUpdateSubscription(action) {
    const { payload } = action
    try{
        const result = yield admin.updateSubscription(payload)
        if(result === 403) {
            yield put(adminUpdateSubscriptionFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.adminSubscriptionUpdate && result.data.adminSubscriptionUpdate.message ? result.data.adminSubscriptionUpdate.message : ''
            yield put(adminUpdateSubscriptionSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpdateSubscriptionFailed(errorMessage))
    }
}

export function* watchAdminUpdateSubscription() {
    yield takeLatest(ADMIN.SUBSCRIPTION_UPDATE.LOAD, adminUpdateSubscription);
}

//subscription search
function* adminSubscriptionSearch(action) {
    const { payload } = action
    try{
        const result = yield admin.subscriptionSearch(payload)
        if(result === 403) {
            yield put(adminSubscriptionSearchFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.subscriptionSearch && result.data.subscriptionSearch.subscription ? result.data.subscriptionSearch.subscription : []
            yield put(adminSubscriptionSearchSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminSubscriptionSearchFailed(errorMessage))
    }
}

export function* watchAdminSubscriptionSearch() {
    yield takeLatest(ADMIN.SUBSCRIPTION_SEARCH.LOAD, adminSubscriptionSearch);
}



