import { CATEGORY} from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case CATEGORY.LOAD:
            return {...state, isLoading: true, error: null}

            case CATEGORY.SUCCESS:
            return {...state, isLoading: false, isLoadingMore: false, data:  action.payload, error: null}

            case CATEGORY.FAILED:
            return {...state, isLoading: false,isLoadingMore: false, error: action.payload}

            default:
            return state;  
        }
    }
    
    