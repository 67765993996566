import { ME, CHANGE_PASSWORD, SEND_FORGOT_PASSWORD, SESSION_USER, CLEAR_UNCOLLECTED_PROMO, REALTIME_USER_WALLET_UPDATE } from '../constant'

//get user
export const meLoad = (payload) =>{
    return {
        type: ME.LOAD,
        payload,
    }
}   

export const meUpdate = (payload) =>{
    return {
        type: ME.UPDATE,
        payload
    }
}

export const meSuccess = (payload) =>{
    return {
        type: ME.SUCCESS,
        payload,
    }
}

export const meFailed = (payload) =>{
    return {
        type: ME.FAILED,
        payload,
    }
}


export const meResetMessage = () =>{
    return {
        type: ME.RESET_MESSAGE,
    }
}

// session user
export const sessionLoad = (payload) =>{
    return {
        type: SESSION_USER.LOAD,
        payload
    }
}

export const sessionSuccess = (payload) => {
    return {
        type: SESSION_USER.SUCCESS,
        payload,
    }
}

export const sessionFail = (payload) => {
    return {
        type: SESSION_USER.FAIL,
        payload,
    }
}

//change password
export const cPasswordLoad = (payload) =>{
    return {
        type: CHANGE_PASSWORD.LOAD,
        payload
    }
}   

export const cPasswordSuccess = (payload) =>{
    return {
        type: CHANGE_PASSWORD.SUCCESS,
        payload,
    }
}

export const cPasswordFailed = (payload) =>{
    return {
        type: CHANGE_PASSWORD.FAIL,
        payload,
    }
}

export const cPasswordClearMessage = () =>{
    return {
        type: CHANGE_PASSWORD.CLEAR_MESSAGE,
    }
}

//send forgot password email
export const forgotPasswordLoad = (payload) =>{
    return {
        type: SEND_FORGOT_PASSWORD.LOAD,
        payload
    }
}   

export const forgotPasswordSuccess = (payload) =>{
    return {
        type: SEND_FORGOT_PASSWORD.SUCCESS,
        payload,
    }
}

export const forgotPasswordFailed = (payload) =>{
    return {
        type: SEND_FORGOT_PASSWORD.FAIL,
        payload,
    }
}

export const forgotPasswordClearMessage = () =>{
    return {
        type: SEND_FORGOT_PASSWORD.CLEAR_MESSAGE,
    }
}

// stripe session
// export const stripeSessionLoad = (payload) =>{
//     console.log('stripeSessionLoad payload', payload)
//     return {
//         type: STRIPE_SESSION.LOAD,
//         payload
//     }
// }

// export const stripeSessionSuccess = (payload) => {
//     return {
//         type: STRIPE_SESSION.SUCCESS,
//         payload,
//     }
// }

// export const stripeSessionFail = (payload) => {
//     return {
//         type: STRIPE_SESSION.FAIL,
//         payload,
//     }
// }

export const clearUncollectedPromo = (payload) => {
    return {
        type: CLEAR_UNCOLLECTED_PROMO,
        payload,
    }
}

export const realtimeUserWalletUpdate = (payload) => {
    return {
        type: REALTIME_USER_WALLET_UPDATE,
        payload,
    }
}


