import React from 'react';
import SideBar from '../../admin/dashboard/components/SideBar'
import NavBar from '../../admin/dashboard/components/NavBar'
import '../../admin/dashboard/style.css'

const DashboardWithSideBar = ( { component: Component, type, session, ...rest } ) => {
    return (
        <React.Fragment>
            <NavBar session={session}/>
            <div className="section-container">
                <SideBar />
                <Component {...rest} session={session}/>
            </div>
            <script src='../../admin/dashboard/script.js'></script>
        </React.Fragment>
    )
};

export default DashboardWithSideBar;
