import { theme } from '../../../common/theme'
    
export const style = {
  colLg: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    flexDirection: 'column',
  },
  errorCont: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 50,
    color: 'red',
    minHeight: 350
  },
  label: {
    padding: '4px 50px 0px 0px',
    display: 'inline-block',
    marginBottom: '1rem',
    fontWeight: '700',
    color: '#333',
    flex: 1
  },
  container:{
    marginTop: '50px',
    border: '1px solid #E1E1E1',
    padding: '40px',
    width: '50%',
    minWidth: 400,
    minHeight: 500,
  },
  div: {
    headTitle: {
      textAlign: 'center',
    },
    formGroup: {
      marginBottom: '1rem',
      display: 'flex', 
      flexDirection: 'column'
    }
  },
  input: {
    formInput: {
      padding: '10px',
      border: '2px solid #bdc3c7',
      borderRadius: '5px',
      flex: 1
    },
   loginBtn: {
      textAlign: 'center',
      padding: '10px 40px',
      margin: '40px 0',
      border: '2px solid #00aaff',
      borderRadius: '5px',
      width: '100%',
      fontSize: '18px',
      color: theme.color.secondaryColor,
      backgroundColor: theme.color.primaryBg,
   }
  },
  forgotPasswordContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10
  },
  forgotPasswordContainer2: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 10
  }
};