import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate'
import { adminBanEmailLoad, adminUpsertBanEmailLoad, adminUpsertBanEmailReset, adminDeleteBanEmailLoad, adminDeleteBanEmailReset } from '../../../../../../redux/action'
import { BanEmailList, BanEmailModal } from './component/CustomComponent'
import {  BanTextContext } from '../../../../../../common/context'
import Loader from '../../../../../../common/component/loader'

const BanEmailSection = (props) => {
    const { 
        adminBanEmailData, adminBanEmailIsLoading, adminBanEmailError, adminBanEmailPage, adminBanEmailTotal,
        adminBanEmailUpsertIsLoading, adminBanEmailUpsertMessage, adminBanEmailUpsertError,
        adminBanEmailDeleteIsLoading, adminBanEmailDeleteMessage, adminBanEmailDeleteError
    } = props.admin

    //variables
    const [isShow, setIsShow] = useState(false)
    const [textInput, setTextInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            pk_i_id: '',
            s_email: '',
            type: 'email'
        }
    );

    useEffect(() => {
        if (adminBanEmailData && adminBanEmailData.length === 0) {
            props.adminBanEmailLoad({first: 20, page: adminBanEmailPage, type: 's_email'})
        }
    // eslint-disable-next-line     
    },[])

    useEffect(()=>{
        if (adminBanEmailUpsertMessage) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertBanEmailReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/email`)
            }, 2000)
        }
        if (adminBanEmailUpsertError) {
            setTimeout(() => {
                setIsShow(false)
                props.adminUpsertBanEmailReset()
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/email`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminBanEmailUpsertMessage, adminBanEmailUpsertError])

    useEffect(()=>{
        if (adminBanEmailDeleteMessage) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/email`)
            }, 2000)
        }
        if (adminBanEmailDeleteError) {
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/ban/email`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminBanEmailDeleteMessage, adminBanEmailDeleteError])

    const handleEdit = (item) => {
        setTextInput({
            pk_i_id: item.pk_i_id,
            s_email: item.s_email,
            type: 'email'
        });
        setIsShow(true)
    }

    const handleAdd = () => {
        setTextInput({
            pk_i_id: '',
            s_email: '',
            type: 'email'
        });
        setIsShow(true)
    }

    const handleDelete = (id) => {
        props.adminDeleteBanEmailLoad({pk_i_id: id})
    }

    const closeModal = () => {
        setIsShow(false)
    }

    const upsertBan = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertBanEmailLoad(data)
    }

    const pageCount = 10
    const handlePageClick = data => {
        props.adminBanEmailLoad({first: 20, page: data.selected + 1, type: 's_email'})
    };

    return (
    <BanTextContext.Provider value = {{
        adminBanEmailData, adminBanEmailIsLoading, adminBanEmailError, isShow, closeModal, upsertBan,
        adminBanEmailUpsertIsLoading, adminBanEmailUpsertMessage, adminBanEmailUpsertError, textInput, setTextInput, handleEdit, handleDelete
    }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Ban Email</h3>
            <section>
            {
                adminBanEmailIsLoading || adminBanEmailDeleteIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminBanEmailError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminBanEmailError}</p></div></article> :
                adminBanEmailDeleteMessage ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'green'}}>{adminBanEmailDeleteMessage}</p></div></article> :
                adminBanEmailDeleteError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminBanEmailDeleteError}</p></div></article> :
                <article style={{flexDirection: 'column'}}>
                    <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={() => handleAdd(true)} className="sellButton pointer" style={{margin: 10, alignSelf: 'flex-start'}}>
                            <span>Add Ban Email</span>
                        </div>
                    </div>
                    <BanEmailList type='load'/>
                </article>
            }
            <div></div> 
            {adminBanEmailError ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminBanEmailTotal}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }           
            </section> 
        </section> 
        {isShow ? <BanEmailModal /> : null }
    </BanTextContext.Provider>    
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminBanEmailLoad,
    adminUpsertBanEmailLoad,
    adminUpsertBanEmailReset,
    adminDeleteBanEmailLoad,
    adminDeleteBanEmailReset
}
  
export default connect(mapStateToProps, mapActionToProps)(BanEmailSection);
