import React, {useState, useEffect} from 'react'
import { useHistory, useLocation, Link} from "react-router-dom";
import {SvgDesktop,  EditUserIconNotActive, EditUserIconActive, LockIconNotActive, LockIconActive} from '../../../../../assets/svg'
import { theme } from '../../../../../common/theme'

const SettingsLink = props => {
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname

    return (
        <>
        <div className="settings-link-group">
            <div onClick={() => history.goBack()} className="flex flex-d-row flex-justify-start flex-align-center ml-13 cursor-pointer">
                <SvgDesktop />
                <p className="edit-profile-p secondary-color">{`Go Back`}</p>
            </div>
            <Link exact to={`/user/settings`} className={`flex flex-d-row flex-justify-start flex-align-center edit-links-container ${pathname.includes('user/settings') ? `bg-gray` : `bg-white`}`}>
                {pathname.includes('user/settings') ? <EditUserIconActive /> : <EditUserIconNotActive />}
                <p className={`ml-7 edit-profile-p ${pathname.includes('user/settings') ? `primary-color` : `secondary-color`}`}>{`Edit Profile`}</p></Link>
            <Link exact to="/user/change-password" className={`flex flex-d-row flex-justify-start flex-align-center edit-links-container ${pathname.includes('user/change-password') ? `bg-gray` : `bg-white`}`}>
                {pathname.includes('user/change-password') ? <LockIconActive /> : <LockIconNotActive />}
                <p className={`ml-7 edit-profile-p ${pathname.includes('user/change-password') ? `primary-color` : `secondary-color`}`}>{`Change Password`}</p></Link>
        </div>
        </>
    );
}


export default SettingsLink