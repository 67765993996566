import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getPreferences = (payload) => {
    const { first, page } = payload
    try {
        const query = gql`query getAllPreference($first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            getAllPreference(first: $first, page: $page, orderField: $orderField, orderType: $orderType
            ){
                count
                preference{
                  s_section
                  s_name
                  s_value
                  e_type
                }
            }
          }`;
        const response = client.query({
            query: query,
            variables: { first: first,
            page: page,
            orderField: "s_name",
            orderType: "ASC"
          }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }
  
  export const upsertPreference = (payload) => {
    const { s_name, s_section, s_value, e_type, type } = payload
    let gql_query
    if (type === 'UPDATE') {
      gql_query = gql`mutation preferenceUpdate($data: PreferenceUpdateInput){
        preferenceUpdate(data: $data){
          message
          preference{
            s_section
            s_value
            e_type
          }
        }
      }`
    } else {
      gql_query = gql`mutation preferenceInsert($data: PreferenceInsertInput){
        preferenceInsert(data: $data){
          message
          preference{
            s_section
            s_name
            s_value
            e_type
          }
        }
      }`;
    }
  
    const variables = {
      data: {
        type: type,
        s_section: s_section,
        s_name: s_name,
        e_type: e_type,
        s_value: s_value
      }
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }
  
  export const deletePreference = (payload) => {
    const { s_name, s_section } = payload
  
    const  gql_query = gql`mutation preferenceDelete($data: PreferenceDeleteInput){
      preferenceDelete(data: $data){
        count
        preference{
          s_section
          s_name
          s_value
          e_type
        }
      }
    }`
   
    const variables = {
      data: {
        type: 'DELETE',
        s_section: s_section,
        s_name: s_name,
      }
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }
  
  export const searchPreferenceForSuggest = (data) => {
    const { first, page, search, orderField, orderType } = data
    try {
        const query = gql`query searchPreference($search: String, $first: Int, $page: Int, $orderField: String,
          $orderType: SortOrder
          ){
            searchPreference(first: $first, page: $page, orderField: $orderField, orderType: $orderType, search: $search
            ){
                count
                preference{
                  s_section
                  s_name
                  s_value
                  e_type
                }
            }
          }`;
  
        const variables = { first: first,
          page: page,
          orderField: orderField,
          orderType: orderType,
          search: search
        }
  
        const response = client.query({
            query: query,
            variables: variables
        });
  
        const data = response ? response : null;
        return data;
    } catch (e) {   
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
  }
  
  export const getPreferenceByField = (payload) => {
    try {
        const query = gql`query getPreference($s_name: String, $s_section: String){
            getPreference(s_name: $s_name, s_section: $s_section
            ){
              count
              preference{
                s_section
                s_name
                s_value
                e_type
              }
            }
          }`;
        const response = client.query({
            query: query,
            variables: { 
              s_section: 'fkv2',
              s_name: 'classified-square',
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }

  export default {
    getPreferences,
    upsertPreference,
    deletePreference,
    searchPreferenceForSuggest
  }