import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { itemViewLoad, sendChatLoad, itemUpdateLoad, itemUpdateReset, servicesLoad, promoteLoad, promoteReset, itemDeleteLoad, itemDeleteReset, watchlistLikeDislikeLoad, itemUpdateByFieldLoad } from '../../../../../redux/action'
import FKImage from '../../../../../common/component/image'
import SocialAvatar from '../../../../../common/component/socialAvatar'
import { numberWithCommas } from '../../../../../common/helper/utility'
import { ItemContext, PromoteContext, ItemViewContext } from '../../../../../common/context'
import { ItemDetail, ItemEditModal, ItemDeleteModal, ItemViewMobile} from './component/CustomComponent'
import DefaultPhoto from '../../../../../assets/images/defaultAvatar.png'
import {ShareIcon, ContactIcon, MessageMobileIcon, SeenIcon} from '../../../../../assets/svg'
import PromoteModal from '../../../home/component/promote'
import LikeButton from '../../../../../common/component/like'
import moment from 'moment';
import { GAevent } from '../../../../../common/googleAnalytics'
// import { ReportModal } from '../../../home/component/CustomComponent'


const { REACT_APP_BASE_URL } = process.env;

const Seller = (props) => {

    const id = props.itemId ? props.itemId : 0
    const loading = props.itemView.isLoading
    const itemData = props && props.itemView && props.itemView.data ? props.itemView.data : {}
    const sellerUsername = itemData && itemData.oc_t_user && itemData.oc_t_user.s_username ? itemData.oc_t_user.s_username : null
    const { pk_i_id } = props.itemView.data
    const userId = props.session ? props.session : 0
    const items_count = itemData && itemData.oc_t_user && itemData.oc_t_user.items_count ? itemData.oc_t_user.items_count : 0
    const itemUserId = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.pk_i_id ? props.itemView.data.oc_t_user.pk_i_id : 0
    const user_resource = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.oc_t_user_resource ? props.itemView.data.oc_t_user.oc_t_user_resource : null
    const user_social_id = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.social && props.itemView.data.oc_t_user.social[0]
    && props.itemView.data.oc_t_user.social[0].data && props.itemView.data.oc_t_user.social[0].data.id ? props.itemView.data.oc_t_user.social[0].data.id : ''
    const user_social_resource = props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.social && props.itemView.data.oc_t_user.social[0]
        && props.itemView.data.oc_t_user.social[0].data && props.itemView.data.oc_t_user.social[0].data.picture && props.itemView.data.oc_t_user.social[0].data.picture.data
        && props.itemView.data.oc_t_user.social[0].data.picture.data.url ? props.itemView.data.oc_t_user.social[0].data.picture.data.url : null
    const is_phone_public = props && props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.is_phone_public ? props.itemView.data.oc_t_user.is_phone_public : false
    const mobile_phone_number = props && props.itemView && props.itemView.data && props.itemView.data.oc_t_user && props.itemView.data.oc_t_user.s_phone_mobile ? props.itemView.data.oc_t_user.s_phone_mobile : null

    const taExp = itemData && itemData.ta_expiration ? itemData.ta_expiration : 0
    const hlExp = itemData && itemData.hl_expiration ? itemData.hl_expiration : 0
    const item_price = itemData && itemData.get_i_price ? numberWithCommas((itemData.get_i_price).toFixed(2)) : ""
    const item_title = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_title ? itemData.oc_t_item_description.s_title : ''
    const item_desc = itemData && itemData.oc_t_item_description && itemData.oc_t_item_description.s_description ? itemData.oc_t_item_description.s_description : ''
    const { sendIsLoading } = props.chat
    const [alertMessage, setAlertMessage] = useState('Sending')
    const [chatArray, setChatArray] = useState({})
    const [isShowEdit, setIsShowEdit] = useState(false)

    const raw_price = itemData && itemData.get_i_price ? itemData.get_i_price.toFixed(2) : 0
    const category = itemData && itemData.oc_t_category ? itemData.oc_t_category : {}
    const { updateIsLoading, updateMessage, updateError } = props && props.itemView
    const [currentCoin, setCurrentCoin] = useState(0)
    const { sessionData } = props.me

    const [isShowDelete, setIsShowDelete] = useState(false)
    const deleteIsloading = props && props.itemView && props.itemView.deleteIsloading || false
    const deleteMessage = props && props.itemView && props.itemView.deleteMessage || false
    const deleteError = props && props.itemView && props.itemView.deleteError || false
    const session_id = props && props.session ? parseInt(props.session) : null
    const likeDislikeLoading = props.watchlist && props.watchlist.isLoading ? props.watchlist.isLoading : false
    const date_published = props && props.itemView && props.itemView.data && props.itemView.data.dt_pub_date ? props.itemView.data.dt_pub_date : null
    const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY') : null

    const item_custom_field = itemData && itemData.custom_fields ? itemData.custom_fields : {}
    const category_custom_field = itemData && itemData.oc_t_category && itemData.oc_t_category.oc_t_custom_field ? itemData.oc_t_category.oc_t_custom_field : []
    const item_chats_count = itemData && itemData.item_chat_count ? itemData.item_chat_count : 0

     //report function
     const value = useContext(ItemViewContext);
     const { setReportShow, setReportInput, setIsEditClick, isEditClick, setEllipsisClick, setIsDeleteClick, isDeleteClick, setIsContactClicked, isShareClick, setIsShareClick, submitItemUpdateByFieldLoad} = value
    
    const chatSorter = (data) => {
        let chatArr = []
        data.map((c, i) => {
            return parseInt(c.item.pk_i_id) === id && c.owner_id === session_id ? chatArr.push({...c, index: i}) : null
        })
        setChatArray(chatArr)
    }

     useEffect(() => {
        if(isEditClick){
            setIsShowEdit(true)
            setEllipsisClick(false)
            setTimeout(() => {
                setIsEditClick(false)
            }, 2000)
        }
     }, [isEditClick])

     useEffect(() => {
        if(props.chat.data.length !== 0){
            chatSorter(props.chat.data)
        }
     },[props.chat.data])

    useEffect(()=>{
        if (updateMessage) {
            setTimeout(() => {
                props.itemUpdateReset()
                setIsShowEdit(false)
                props.reload()
            }, 2000)
        }
        if (updateError) {
            setTimeout(() => {
                props.itemUpdateReset()
                setIsShowEdit(false)
            }, 2000)
        }
        // eslint-disable-next-line
    }, [updateMessage, updateError])

    useEffect(() => {
        if (sessionData.wallet !== 0) {
            setCurrentCoin(sessionData.wallet)
        }
        return () => setCurrentCoin(0)
        // eslint-disable-next-line
    }, [sessionData])

    const handleEditSubmit = (id) => {
        props.itemUpdateLoad(id)
    }

    const handleDeleteSubmit = (id) => {
        props.itemDeleteLoad({id, userId})
    }

    //promote context
    const { servicesData, isLoadingPromote, promoteSuccessMessage, errorPromote } = props.purchase
    const [promoteShow, setPromoteShow] = useState(false)
    const stepLength = 2
    const [step, setStep] = useState(1)
    const [itemForPromote, setItemForPromote] = useState({})
    const [paymentData, setPaymentData] = useState({})

    const togglePromoteShow = () => {
        setItemForPromote(itemData)
        setPromoteShow(!promoteShow)
    }

    const resetModal = () => {
        setStep(1)
        setItemForPromote({})
        setPaymentData({})
        setPromoteShow(false)
    }

    useEffect(() => {
        if (errorPromote) {
            setTimeout(() => {
                props.promoteReset()
            }, 3000)
        }
        if (promoteSuccessMessage) {
            setTimeout(() => {
                props.promoteReset()
                togglePromoteShow()
                setPromoteShow(false)
                resetModal()
            }, 3000)
        }
        // eslint-disable-next-line
    }, [errorPromote, promoteSuccessMessage])

    const handleNext = () => {
        if (step !== stepLength || step < stepLength) {
            setStep(step + 1)
        }
    }

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const getServices = (active) => {
        if (servicesData.length === 0) {
            props.servicesLoad(active)
        }
    }

    const submitPromote = (data) => {
        props.promoteLoad({ fkv2_purchased_data: data, userId: userId })
    }
    //end promote context

    const alert = () => {
        return <div className="flex contact-modal">
            <div className="contact-modal-main-container">
                <div className="contact-form-container">
                    <div style={{ margin: '16px 0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                            <div>
                                <p style={{ color: '#16a085' }}>{alertMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        if(isDeleteClick){
            setIsShowDelete(true)
            setEllipsisClick(false)
            setTimeout(() => {
                setIsDeleteClick(false)
            }, 2000)
        }
     }, [isDeleteClick])

    useEffect(() => {
        if (deleteError) {
            setTimeout(() => {
                props.itemDeleteReset()
                setIsShowDelete(false)
            }, 2000)
        }
        if (deleteMessage) {
            setTimeout(() => {
                props.itemDeleteReset()
                setIsShowDelete(false)
                window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
            }, 2000)
        }
        // eslint-disable-next-line
    }, [deleteError, deleteMessage])

    const toggleLiked = (item_id) => {
        props.watchlistLikeDislikeLoad({ userId: userId, itemId: item_id })
    }

    const { watchlist_count, watcher, is_new} = itemData
    const [isLiked, setIsLiked] = useState(false)

    useEffect(() => {
        if (watchlist_count > 0) {
            if (watcher.includes(session_id)) {
                setIsLiked(true)
            } else {
                setIsLiked(false)
            }
        }
        else {
            setIsLiked(false)
        }
        // eslint-disable-next-line  
    }, [watcher])

    const messageClick = () => {
        if (session_id) {
            GAevent("user", "message")
        }
    }

    //report function
    const handleReportClick = (id, itemUserId) => {
        setReportShow(true)
        setReportInput({
          reported_id: id,
          reported_user_id: itemUserId
        })
    }

    const pageview_count = itemData && itemData.pageview_count ? itemData.pageview_count : 0

    const [isMessageClick, setIsMessageClick] = useState(false)

    const onKeyDownInput = (event) => {
        if (event.key === 'e' || event.key === '+' || event.key === '-') {
          event.preventDefault();
        }
      }

    return (
        <PromoteContext.Provider value={{
            promoteShow, togglePromoteShow, getServices, step, handleNext, handleBack,
            servicesData,
            itemForPromote, setItemForPromote,
            paymentData, setPaymentData,
            isLoadingPromote, promoteSuccessMessage, errorPromote, submitPromote,
            resetModal, currentCoin, isMessageClick, setIsMessageClick, mobile_phone_number, is_phone_public
        }}>

            <div className="contact-seller-container" style={{ display: Object.keys(itemData).length === 0 && itemData.constructor === Object ? 'none' : 'flex' }} >
                {
                    loading ? null :
                        sendIsLoading ? alert() :
                            <div>
                                <div>
                                    {
                                    window.innerWidth > 600 ?
                                    <div className='user-seller-sub-container'>
                                        <Link to={userId === itemUserId ? `/user/listing` : `/user/${itemUserId}/listing`} 
                                        className="flex flex-d-row">
                                            <div style={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                                                {
                                                    user_social_resource ?
                                                    <SocialAvatar id={user_social_id} url={user_social_resource} /> :
                                                    user_resource ?
                                                        <FKImage images={[user_resource]} type="desktop_seller_avatar" /> :
                                                            <img src={DefaultPhoto} width={50} height={50} alt="item owner avatar" />
                                                }
                                            </div>
                                            <div className="ml-7 flex flex-d-column flex-align-start">
                                                <h1 className="contact-seller-p-name">{sellerUsername}</h1>
                                                <p className="user-total-p-listing">{`${items_count} Listings`}</p>
                                            </div></Link>
                                            <div>
                                            <p className="item-posted-date">{`POSTED: ${formatted_date}`}</p>
                                            </div>
                                    </div> : null }
                                    {
                                    window.innerWidth > 600 ?
                                    <div className="w-100 flex flex-d-row flex-align-center flex-justify-between">
                                        <div className="flex flex-d-row">
                                            <div className="flex flex-d-row flex-align-center">
                                                <SeenIcon />
                                                <p className="item-view-p-favorites">{`Seen ${pageview_count}`}</p>
                                            </div>
                                            { 
                                            userId ?
                                            <div className="flex flex-d-row flex-align-center">
                                                <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} />
                                                <p className="item-view-p-favorites">{`Favorites ${watchlist_count}`}</p>
                                            </div> : null
                                            }
                                            <div className="item-view-share-btn" onClick={() => setIsShareClick(!isShareClick)}>
                                                <ShareIcon />
                                                <p className="item-view-p-share ml-7">{`Share`}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-d-row">
                                        {   userId ?
                                            userId === itemUserId ?
                                            <> 
                                            <Link to={{pathname: `/user/messages/inbox`, isFromItemView: true, chatR: chatArray }}  className="item-view-chats-btn">
                                                <p className="item-view-chats-p">{`View ${item_chats_count > 1 ? `${item_chats_count} Chats` : `${item_chats_count} Chat`}`}</p>
                                            </Link>
                                            <div className="item-pr-btn" onClick={() => togglePromoteShow()}>
                                                <p className="item-pr-p">{`Promote`}</p>
                                            </div>
                                            </> : 
                                            <> 
                                            {
                                                is_phone_public ?
                                                <div className="item-view-chats-btn" onClick={() => setIsMessageClick(!isMessageClick)}>
                                                    {/* <ContactIcon /> */}
                                                    <p className="item-view-chats-p">{isMessageClick ? `+65${mobile_phone_number}` : `Contact`}</p>
                                                </div> : null
                                            }
                                             
                                            <Link to={{
                                                    pathname: !session_id ? `/login` : `/user/messages/inbox`,
                                                    state: { fromMessage: true, item: itemData }
                                                }} className="item-pr-btn" onClick={() => messageClick()}>
                                                {/* <MessageMobileIcon /> */}
                                                <p className="item-pr-p ">{`Message`}</p>
                                            </Link>
                                            </> : 
                                            <> 
                                            {
                                                is_phone_public ?
                                                <div className="item-view-chats-btn" onClick={() => setIsMessageClick(!isMessageClick)}>
                                                    {/* <ContactIcon /> */}
                                                    <p className="item-view-chats-p ">{isMessageClick ? `+65 ${mobile_phone_number}` : `Contact`}</p>
                                                </div> : null
                                            }
                                           <Link to={{
                                                    pathname: !session_id ? `/login` : `/user/messages/inbox`,
                                                    state: { fromMessage: true, item: itemData }
                                                }} className="item-pr-btn" onClick={() => messageClick()}>
                                               {/* <MessageMobileIcon /> */}
                                               <p className="item-pr-p">{`Message`}</p>
                                           </Link>
                                           </> 
                                        }
                                           
                                        </div>
                                    </div> : null }
                                    <ItemContext.Provider value={{
                                       handleEditSubmit, pk_i_id, category, item_price, item_title, item_desc, userId, formatted_date, itemUserId, hlExp, taExp, isShowEdit, setIsShowEdit, raw_price, updateIsLoading, updateMessage, updateError, 
                                       isShowDelete, setIsShowDelete, handleDeleteSubmit, deleteIsloading, deleteMessage, deleteError, itemData, submitItemUpdateByFieldLoad, 
                                       item_custom_field, category_custom_field, is_new, isLiked, likeDislikeLoading, toggleLiked, id, watchlist_count, togglePromoteShow, handleReportClick, setIsContactClicked, setIsMessageClick, isMessageClick,
                                       onKeyDownInput, mobile_phone_number, is_phone_public
                                    }}>
                                        {window.innerWidth < 600 ? <ItemViewMobile /> : <ItemDetail /> }
                                        {isShowEdit ? <ItemEditModal /> : null}
                                        {isShowDelete ? <ItemDeleteModal /> : null}
                                    </ItemContext.Provider>
                                </div>
                             </div>
                            
                }
            </div>
            {promoteShow ? <PromoteModal /> : null}
        </PromoteContext.Provider>

    );
}

const mapStateToProps = store => {
    return {
        itemView: store.itemViewReducer,
        chat: store.chatReducer,
        purchase: store.purchaseReducer,
        me: store.userReducer,
        watchList: store.watchlistReducer
    }
}

const mapActionToProps = {
    itemViewLoad,
    sendChatLoad,
    itemUpdateLoad,
    itemUpdateReset,
    servicesLoad,
    promoteLoad,
    promoteReset,
    itemDeleteLoad,
    itemDeleteReset,
    watchlistLikeDislikeLoad,
    itemUpdateByFieldLoad
}

export default connect(mapStateToProps, mapActionToProps)(Seller)