import React from 'react'


const ExtrasSection = () => {
    return (
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Extras</h3>
            <section className="grid">
            <article></article>
            </section> 
        </section> 
    )
}

export default ExtrasSection