import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ProfileListingCard from './ProfileListingCard'
import { NoListingIcon } from '../../../../../../assets/svg'
import {WholePageLoader} from '../../../../../../common/component/customComponents'
import { itemUserLoad, itemUpdateByFieldLoad, itemDeleteReset, itemDeleteLoad, itemUpdateLoad, itemUpdateReset } from '../../../../../../redux/action'

const { REACT_APP_BASE_URL } = process.env;

const Listing = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {isLoading} = props.item
  const location = useLocation()
  const pathname = location.pathname
  const splitPath = pathname.split('/')
  const userId = splitPath && splitPath[4] ? splitPath[4] : null 

  const items_data = props.item && props.item.data  ? props.item.data : []
  const sessionId = props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : false
  const isSessionUser = parseInt(userId) === parseInt(sessionId) ? true : false
  const [upto, setUpto] = useState('10')
  const renderItem = items_data.slice(0, upto).map((item) => <div>{item}</div>)
  const handleLoadMore = () => {
    setUpto(parseInt(upto) + 10)
  }

  useEffect(() => {
      props.itemUserLoad({user_id: userId})
  // eslint-disable-next-line
  }, [userId])

  const submitItemUpdateByFieldLoad = (data) => {
    props.itemUpdateByFieldLoad(data)
  }

  //delete
  const { deleteIsloading, deleteMessage, deleteError } = props.itemView
  const [isShowDelete, setIsShowDelete] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [selectedTitle, setSelectedTitle] = useState(null)

  const setSelected = (id, title) => {
    setSelectedId(id)
    setSelectedTitle(title)
    setIsShowDelete(true)
  }

  const closeDeleteModal = () => {
    setIsShowDelete(false)
    setSelectedId(null)
    setSelectedTitle(null)
  }

  const handleDeleteSubmit = (id) => {
    props.itemDeleteLoad(id)
  }

  useEffect(() => {
    if(deleteError){
        setTimeout(() => {
            props.itemDeleteReset()
            setIsShowDelete(false)
        }, 2000)
    }
    if(deleteMessage){
        setTimeout(() => {
            props.itemDeleteReset()
            setIsShowDelete(false)
            window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
        }, 2000)
    }
// eslint-disable-next-line
}, [deleteError, deleteMessage])

//edit
const { updateIsLoading, updateMessage, updateError } = props && props.itemView
const [isShowEdit, setIsShowEdit] = useState(false)
const [selectedItem, setSelectedItem] = useState(null)

const handleEditSubmit = (item) => {
  props.itemUpdateLoad(item)
}

const closeEditModal = () => {
  setIsShowEdit(false)
  setSelectedItem(null)
}

const handleSetSelectedItem = (item) => {
  setSelectedItem(item)
  setIsShowEdit(true)
}

useEffect(()=>{
  if (updateMessage) {
      setTimeout(() => {
          props.itemUpdateReset()
          setIsShowEdit(false)
          window.location.replace(`${REACT_APP_BASE_URL}user/listing`);
      }, 2000)
  }
  if (updateError) {
      setTimeout(() => {
          props.itemUpdateReset()
          setIsShowEdit(false)
      }, 2000)
  }
// eslint-disable-next-line
}, [updateMessage, updateError])

  return (
      !userId ? <p style={{textAlign: 'center'}}>No User found!</p> : 
       <div style={style.full}>
         
           {
                 items_data.length === 0 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0px', border:'0.5px solid #C4C4C4', borderRadius: '4px'}}><NoListingIcon /></div> : 
                 <div className="profile-listing-item-container">{
                 renderItem.map((items, key) => {
                  const item = items.props && items.props.children ? items.props.children : []
                 return <div key={key} className="home-item-card">       
                          <ProfileListingCard item={item} style={style} isSessionUser={isSessionUser} updateIsLoading={updateIsLoading} submitItemUpdateByFieldLoad={submitItemUpdateByFieldLoad} setSelected={setSelected} handleSetSelectedItem={handleSetSelectedItem}/>
                        </div>
                   })}
                </div>
               }

           { isLoading ? null :
             items_data.length > upto ?
              <div className="w-100 flex flex-center mb-10 p-20" onClick={handleLoadMore}>
                <div className="default-btn">
                  <p className="text-orange">{`Load More`}</p>
                </div>  
              </div> : null
           }
       </div>
  );
}

export const style = {
  full: {
    width: '100%',
    padding: window.innerWidth < 600 ? '0px' : '15px 0px',
    maxWidth: '1240px',
    margin: '20px 0px'
  },
  container: {
    display: 'flex',
    flex: '1 1 0%'
  },
  itembox: {
    display:'flex',
    overflow: 'hidden'
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #E1E1E1',
    borderRadius: '5px',
    background: '#fff',
    padding: '5px'
  },
  cardInfo: {
    padding:  window.innerWidth < 600 ? '0px' : '0px',
    borderRadius: '4px 4px 0px 0px',
    textAlign: 'center',
    overflow: 'hidden',
   
  },
  row: {
  }
};

const mapStateToProps = store => {
  return {
    item: store.itemUserReducer,
    user: store.authReducer,
    itemView: store.itemViewReducer,
  };
};

const mapActionToProps = {
  itemUserLoad,
  itemUpdateByFieldLoad,
  itemDeleteReset, 
  itemDeleteLoad ,
  itemUpdateLoad, 
  itemUpdateReset
}


export default connect(mapStateToProps, mapActionToProps)(Listing);