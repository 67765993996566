import React, {useContext} from 'react';
import {IconContainerClickable, Text, LinkText} from '../../../../../common/component/customComponents'
import {CloseIcon, PaypalIcon, CreditCardsIcon} from '../../../../../assets/svg'
import {ProfileSubscriptionContext} from '../../../../../common/context'
import {currentDate, addDate} from '../../../../../common/helper/utility'
import {PaypalUpgradeButton} from '../paymentMethod/PaypalButton'
import {StripeUpgradeButton} from '../paymentMethod/StripeButton'
import PaypalButton from '../cancelPaymentMethod/PaypalButton'
import StripeButton from '../cancelPaymentMethod/StripeButton'
import {theme} from '../../../../../common/theme'
import {RadioWithText, Divider, PriceTextWithSymbol} from '../../../fkPrime/customComponent'
import {CustomLoader} from '../../../../../common/component/loader'

const Second = () => {
    const value = useContext(ProfileSubscriptionContext)
    const {subscriptionActionModal, subscription, userBoost, isLoadingUpdateSubscription, updateSubscriptionMessage, has_active_subscription, paymentMethod, handlePaymentMethod, 
        isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin} = value
    const {fkPrime} = subscription
    const boostDetails = userBoost && userBoost.boostDetails ? userBoost.boostDetails : [] 
    const payment_method = fkPrime && fkPrime[0] && fkPrime[0].payment_method ? fkPrime[0].payment_method : 'stripe'
    const isCancel = has_active_subscription && has_active_subscription.purchase_micro_service && has_active_subscription.purchase_micro_service.subscription_status ? has_active_subscription.purchase_micro_service.subscription_status : null
    const {fkPrimePurchaseDisclaimer} = theme.text
    return (

        <>
            <div className="border-bottom">
                <div className='flex flex-justify-between  p-20 mt-10 mb-10'>
                    <div />
                    <div className="w-100">
                        <Text 
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text={isCancel && isCancel.toLowerCase().includes('cancel') ? `Select payment method` : isLoadingUpdateSubscription || updateSubscriptionMessage ? `` : `Click the button to upgrade`}
                            textClass="fk-prime-purchase-modal-title-p w-auto"
                        />
                    </div>
                    <div className="">
                        {
                            isLoadingUpdateSubscription || updateSubscriptionMessage ? null :
                            <IconContainerClickable 
                            styleClass="cursor-pointer"
                            clickFunc={() => subscriptionActionModal([], false, 1)}
                            Icon={<CloseIcon/>}
                        />
                        }
                    </div>
                </div>
            </div>
            {
                isCancel && isCancel.toLowerCase().includes('cancel') ?
                <div className="h-100 min-h-600 pl-12 pr-12 mt-30">
                     <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                        <RadioWithText 
                            styleClass="flex flex-d-row flex-align-center"
                            value="paypal"
                            radioClass="item-condition-radios radio custom-radio-ui "
                            onChangeFunc={handlePaymentMethod}
                            text="Paypal"
                            textClass="payment-radio-text-p"
                            purchaseSelected={paymentMethod}
                        />
                        <PaypalIcon />
                    </div>
                    <div className="flex flex-d-row flex-justify-between flex-align-center">
                        <RadioWithText 
                            styleClass="flex flex-d-row flex-align-center"
                            value="stripe"
                            radioClass="item-condition-radios radio custom-radio-ui "
                            onChangeFunc={handlePaymentMethod}
                            text="Credit/Debit Card"
                            textClass="payment-radio-text-p"
                            purchaseSelected={paymentMethod}
                        />
                        <CreditCardsIcon />
                    </div>
                    <div className="mt-30">
                        <Divider />
                    </div>
                    <div className=" mt-30 mb-20">
                        <Text 
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text="Purchase"
                            textClass="fk-prime-purchase-modal-title-p"
                        />
                        <Text
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text={currentDate()}
                            textClass=""
                        />
                    </div>
                    {
                                    boostDetails.length !== 0 ?
                                    <>
                                    <div className="flex flex-d-row w-100 flex-justify-between">
                                        <div className="flex flex-d-column">
                                            <Text
                                                styleClass=""
                                                text="Fk Prime Subscription"
                                                textClass="fk-prime-purchase-title-p"
                                            />
                                            <Text
                                                styleClass="mt-4"
                                                text={`${boostDetails.name} ${boostDetails.subname}`}
                                                textClass="fk-prime-purchase-subtitle-p"
                                            />
                                        </div>
                                        <div className="flex flex-d-column">
                                            <PriceTextWithSymbol
                                                styleClass="flex-justify-end fk-prime-purchase-price-container"
                                                symbol="S$"
                                                symbolClass="fk-prime-purchase-symbol-p"
                                                priceClass="fk-prime-purchase-price-p"
                                                price={boostDetails.price}
                                            />
                                            <Text
                                                styleClass="fk-prime-purchase-due-date-container"
                                                text={`${addDate(boostDetails.name, boostDetails.subname)}`}
                                                textClass="fk-prime-purchase-due-date-p"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-20 mb-20">
                                        {
                                            fkPrimePurchaseDisclaimer.map((disc, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Text
                                                            styleClass=""
                                                            text={`- ${disc.text}`}
                                                            textClass="fk-prime-purchase-disclaimer-p"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <Divider />
                                        
                                    </> : null
                                }
                                 <div className="mt-30 mb-30 w-100 flex flex-justify-center">
                                {
                                isLoadingPurchaseCoin ? <div className="mt-30"><CustomLoader />  </div>:
                                purchaseSuccessMessage ? <p style={{color: 'green'}}>{purchaseSuccessMessage}</p> :
                                errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> :
                                paymentMethod === 'stripe' ? <StripeButton/> : paymentMethod === 'paypal' ? <PaypalButton /> :
                                            <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                                <p className="fk-prime-purchase-p">{`Pay`}</p>
                                            </div>
                                }
                                </div>
                </div> : 
                <div className={`h-462`}>
                    <div className='h-100 flex flex-d-row flex-justify-center cancel-sub-button-container'>
                        {/* {isCancel && isCancel.toLowerCase().includes('cancel') ? <div className="flex flex-d-column flex-justify-evenly"> <StripeButton />  <PaypalButton /> </div> : payment_method === 'stripe' ? <StripeUpgradeButton /> : <PaypalUpgradeButton />} */}
                        {payment_method === 'stripe' ? <StripeUpgradeButton /> : payment_method === 'paypal' ? <PaypalUpgradeButton /> : null}
                    </div>
                </div>
            }
            
            <div className="cancel-subscription-footer-container ">
                <Text
                    styleClass=""
                    text="I accept and agree to the"
                    textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                />
                <LinkText
                    path="/terms-of-use"
                    text="Terms of Services."
                    textClass="fk-prime-purchase-modal-footer-link-p"
                />
            </div>
        </>
    )
}

export default Second