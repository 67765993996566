import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { administratorSuccess, administratorFailed, administratorProfileSuccess, administratorProfileFailed,
    adminUpsertAdministratorSuccess, adminUpsertAdministratorFailed, administratorDeleteSuccess, administratorDeleteFailed,
    adminForgotPasswordSuccess, adminForgotPasswordFailed, adminChangePasswordSuccess, adminChangePasswordFailed,
    adminDashboardSuccess, adminDashboardFailed
} from "../../action";
import admin from '../../../graphql/admin/administrator'
import { theme } from '../../../common/theme'
import { getRandomColor } from '../../../common/helper/utility'

//fetch administrator
function* administratorLoad(action) {
    const { payload } = action
    try{
        const result = yield admin.getAdministrator(payload)
        if(result === 403) {
            yield put(administratorFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.getAdministrator ? result.data.getAdministrator : null
            yield put(administratorSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', e)
        yield put(administratorFailed(errorMessage))
    }
}

export function* watchAdministratorLoad() {
    yield takeLatest(ADMIN.ADMINISTRATOR.LOAD, administratorLoad);
}

//fetch user profile
function* administratorProfile(action) {
    const { payload } = action    
    try{
        const result = yield admin.getAdministratorById(parseInt( payload.pk_i_id))

        if(result === 403) {
            yield put(administratorProfileFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getAdministratorById ? result.data.getAdministratorById : {}
            yield put(administratorProfileSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('saga e', e)
        yield put(administratorProfileFailed(errorMessage))
    }
}

export function* watchAdministratorProfile() {
    yield takeLatest(ADMIN.ADMINISTRATOR_PROFILE.LOAD, administratorProfile);
}

//upsert user
function* adminUpsertAdministrator(action) {
    const { payload } = action
    try{
        const result = yield admin.upsertAdministrator(payload)         
        if(result === 403) {
            yield put(adminUpsertAdministratorFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.administratorAdminRegister && result.data.administratorAdminRegister ? result.data.administratorAdminRegister : 
                result.data && result.data.administratorAdminUpdate ? result.data.administratorAdminUpdate : {}
            yield put(adminUpsertAdministratorSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertAdministratorFailed(errorMessage))
    }
}

export function* watchAdminUpsertAdministrator() {
    yield takeLatest(ADMIN.ADMINISTRATOR_UPSERT.LOAD, adminUpsertAdministrator);
}

//delete user
function* administratorDeleteLoad(action) {
    const { payload } = action
    try{
        const result = yield admin.deleteAdministrator(payload)

        if(result === 403) {
            yield put(administratorDeleteFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.administratorDelete ? result.data.administratorDelete : {}
            yield put(administratorDeleteSuccess(data));

        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('error', JSON.stringify(e))
        yield put(administratorDeleteFailed(errorMessage))
    }
}

export function* watchAdministratorDeleteLoad() {
    yield takeLatest(ADMIN.ADMINISTRATOR_DELETE.LOAD, administratorDeleteLoad);
}

//send forgot password email
function* adminSendForgotEmail(action) {
    try {
        const result = yield admin.adminForgotPassword(action.payload)
        if(result === 403){
            yield put(adminForgotPasswordFailed('Something went wrong try again!'))
        }else{
            const message = result && result.data && result.data.userForgotPassword && result.data.userForgotPassword.message ? result.data.userForgotPassword.message : ''
            yield put(adminForgotPasswordSuccess(message));
        } 
    }catch (e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('adminSendForgotEmail error', JSON.stringify(e))
        yield put(adminForgotPasswordFailed(errorMessage))
    }
}

export function* watchAdminSendForgotEmail(){
    yield takeLatest(ADMIN.SEND_FORGOT_PASSWORD.LOAD, adminSendForgotEmail)
}

//change password
function* adminChangePassword(action) {
    const { payload } = action
    const id = payload.id
    const currentPassword = payload.currentPassword
    const password = payload.password
    const rePassword = payload.rePassword
    const email = payload.email
    const type = payload.type

    try{
        const result = yield admin.adminChangePassword(id, currentPassword, password, rePassword, email, type)
        if(result === 403) {
            yield put(adminChangePasswordFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.adminChangePassword.message ? result.data.adminChangePassword.message : null
            yield put(adminChangePasswordSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('adminChangePassword saga error', JSON.stringify(e))
        yield put(adminChangePasswordFailed(errorMessage))
    }
}

export function* watchAdminChangePassword() {
    yield takeLatest(ADMIN.CHANGE_PASSWORD.LOAD, adminChangePassword);
}

//fetch dashboard data
function* dashboardLoad(action) {
    const { payload } = action
    try{
        const result = yield admin.getDashboardData(payload)
        if(result === 403) {
            yield put(adminDashboardFailed('Something went wrong try again!'))
        }
        else {
            //parse item data
            const todayPostingPerUserData = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.today_posting_per_user ? JSON.parse(result.data.getDashboardDataByCreation.today_posting_per_user) : null
            const finalTodayPostingPerUserData = dataParser2(todayPostingPerUserData)
            const thisWeekPostingPerUser = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.this_week_posting_per_user ? JSON.parse(result.data.getDashboardDataByCreation.this_week_posting_per_user) : null
            const finalWeekPostingPerUser = dataParser2(thisWeekPostingPerUser)
            const this_month_posting_per_user = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.this_month_posting_per_user ? JSON.parse(result.data.getDashboardDataByCreation.this_month_posting_per_user) : null
            const finalMonthPostPerUser = dataParser2(this_month_posting_per_user)
            const this_year_posting_per_user = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.this_year_posting_per_user ? JSON.parse(result.data.getDashboardDataByCreation.this_year_posting_per_user) : null
            const finalYearPostPerUser = dataParser2(this_year_posting_per_user)
            const signUpToday = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.sign_up_today ? JSON.parse(result.data.getDashboardDataByCreation.sign_up_today) : null
            const finalSignUpToday = dataParser2(signUpToday)
            const signUpThisWeek = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.sign_up_this_week ? JSON.parse(result.data.getDashboardDataByCreation.sign_up_this_week) : null
            const finalSignUpThisWeek = dataParser2(signUpThisWeek)
            const signUpThisMonth = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.sign_up_this_month ? JSON.parse(result.data.getDashboardDataByCreation.sign_up_this_month) : null
            const finalSignUpThisMonth = dataParser2(signUpThisMonth)
            const signUpThisYear = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.sign_up_this_year ? JSON.parse(result.data.getDashboardDataByCreation.sign_up_this_year) : null
            const finalSignUpThisYear = dataParser2(signUpThisYear)
            const purchaseToday = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.purchase_today ? JSON.parse(result.data.getDashboardDataByCreation.purchase_today) : null
            const finalPurchaseToday = coinPurchaseParser(purchaseToday)
            const purchaseThisWeek = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.purchase_this_week ? JSON.parse(result.data.getDashboardDataByCreation.purchase_this_week) : null
            const finalPurchaseThisWeek = coinPurchaseParser(purchaseThisWeek)
            const purchaseThisMonth = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.purchase_this_month ? JSON.parse(result.data.getDashboardDataByCreation.purchase_this_month) : null
            const finalPurchaseThisMonth = coinPurchaseParser(purchaseThisMonth)
            const purchaseThisYear = result.data && result.data.getDashboardDataByCreation && result.data.getDashboardDataByCreation.purchase_this_year ? JSON.parse(result.data.getDashboardDataByCreation.purchase_this_year) : null
            const finalPurchaseThisYear = coinPurchaseParser(purchaseThisYear)
            
            yield put(adminDashboardSuccess({finalTodayPostingPerUserData, finalWeekPostingPerUser, finalMonthPostPerUser, finalYearPostPerUser, finalSignUpToday, finalSignUpThisWeek, finalSignUpThisMonth, finalSignUpThisYear, finalPurchaseToday, finalPurchaseThisWeek, finalPurchaseThisMonth, finalPurchaseThisYear }));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e) )
        yield put(adminDashboardFailed(errorMessage))
    }

    function dataParser2(arr){
        let labels = []
        let data = []
        let average = []
        let users = []
        arr && arr.length > 0 &&  arr.forEach(item => {
            labels.push(item.label)
            data.push(item.items)
            users.push(item.users)
            average.push((item.items / item.users).toFixed(2))
        })
        const totalItem = data.reduce((partial_sum, a) => partial_sum + a,0); 
        const totalUser = users.reduce((partial_sum, a) => partial_sum + a,0); 
        const totalAverage = (totalItem / totalUser).toFixed(2)
        const final_data = {labels: labels, data: data, users: users, average: average, totalItem: totalItem, totalUser: totalUser, totalAverage: totalAverage }
        return final_data
    }

    function coinPurchaseParser(arr){
        let labels = []
        let data = []
        let total = []
        let count = []
        arr && arr.length > 0 &&  arr.forEach(item => {
            labels.push(item.label)
            data.push(item.items)
            total.push(item.total_purchase)
            count.push(item.items)
        })
        const totalCount = count.reduce((partial_sum, a) => partial_sum + a,0); 
        const totalPurchase = total.reduce((partial_sum, a) => partial_sum + a,0); 
        const final_data = {labels: labels, data: data, totalPurchase: totalPurchase, totalCount: totalCount }
        return final_data
    }
}

export function* watchDashboardLoad() {
    yield takeLatest(ADMIN.DASHBOARD.LOAD, dashboardLoad);
}