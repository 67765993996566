import React, { useState, useContext, useEffect } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { ItemImageContext, ItemViewContext } from '../../../../../common/context'
import { OverlayLoader } from '../../../../../common/component/loader'
import { theme } from '../../../../../common/theme'
import { ReportingSelect } from './ReportingSelect'

export const EditItem = () => {
    const value = useContext(ItemImageContext);
    const { imageArr, setShowEdit, handleImageEditSubmit, imageEditIsLoading, imageEditError, imageEditMessage, itemImageReset } = value
    const [stateFile, setStateFile] = useState([])

    useEffect(() => {
        if (imageArr.length > 0) {
            async function loadContent(){
                await handleInitialImage()
            }
        loadContent()
        }
    }, [imageArr])

    useEffect(() => {
        if (imageEditError) {
            setTimeout(() => {
                itemImageReset()
                setShowEdit(false)
            }, 2000)
          }
    }, [imageEditError])

    const handleInitialImage = async () => {
        await imageArr.map( item => {
            fetch(item.raw).then(res => {
                res.arrayBuffer().then(buf => {
                const file = new File([buf], `${item.id}.${item.ext}`, { type: 'image/jpeg' })
                setStateFile(file)
                })
            })
        })
    }

    const handleSubmit = (files, allFiles) => {
        // console.log(files.map(f => f.meta))
        // allFiles.forEach(f => f.remove())
        // setStateFile({ files })

        let arr = []

        if (files.length > 0) {
            // const valueArr = files.map(function(item){ return item.file.name.split(".").slice(0, -1).join(".") });
            const valueArr = files.map(function(item){ return item.file.size });

            const isDuplicate = valueArr.some(function(item, index){ 
                return valueArr.indexOf(item) != index 
            });

            if (isDuplicate) {
                window.alert(`You cannot submit duplicate item image`)
            } else {
                files.map(item => {
                    arr.push(item.file)
                })
                handleImageEditSubmit(arr)
            }
        }
    }

    const parsedError = (error) => {
        let errorMessage
        if (error.includes('file')) {
          errorMessage = "Error adding image, due to error processing your picture."
        } else {
          errorMessage = error
        }
        return errorMessage
    }

    return ( 
        <React.Fragment>
        { 
            imageEditMessage ? <p style={{color: 'green'}}>{imageEditMessage}</p> :
                imageEditIsLoading ? null :
                stateFile.length === 0  ? 
                <Dropzone
                    maxFiles={4}
                    onSubmit={handleSubmit}
                    chunking={true}
                />
                :
                <Dropzone
                    maxFiles={4}
                    onSubmit={handleSubmit}
                    initialFiles={[stateFile]}
                    chunking={true}
                />
        }
        {imageEditError ? <p style={{color: 'red'}}>{parsedError(imageEditError)}</p> :
            imageEditMessage ? <p style={{color: 'green'}}>{null}</p> :
                <button onClick={()=> setShowEdit(false)} className="btn secondary">Cancel</button>
        }
        {imageEditIsLoading ? <OverlayLoader/> : null}
        </React.Fragment>
    )

}

export const ReportModal = () => {
    const value = useContext(ItemViewContext)
    const { reportShow, setReportShow, handleReportSubmit, reportInput, isLoadingReport, reportMessage, reportError } = value
    return(
        <div className={reportShow ? `flex modal-index-container` : `hide`}>
            <div className="modal-report-main-container">
                {
                    isLoadingReport ? <div className="modal-index-head"></div> :
                    reportMessage ? <div className="modal-index-head"></div> :
                    reportError ? <div className="modal-index-head"></div> :
                        <div className="modal-index-head">
           
                        <div style={{padding: '0px 15px'}}>
                            <p style={{margin: 0, fontSize: 20, fontWeight: '600'}}>{theme.text.reportListingHeader}</p>
                        </div>
                        <div >
                            <span style={styles.close} onClick={() => setReportShow(false)}>&times;</span>
                        </div>
                    </div>
                }

                <div style={{flex: 1, marginTop: '10px'}}>
                    {   
                        isLoadingReport ? <p style={{textAlign: 'center'}}>Submitting...</p> :
                        reportMessage ? <p style={{color: 'green', textAlign: 'center'}}>{reportMessage}</p> :
                        reportError ? <p style={{color: 'red', textAlign: 'center'}}>{reportError}</p> :
                        <React.Fragment>
                            <ReportingSelect />
                            {
                            reportInput && reportInput.report_reason ? 
                            <div className="modal-report-btn-container">
                                <button type="button" className="modal-report-btn" disabled={reportInput && reportInput.report_reason ? false : true } onClick={() => handleReportSubmit()}>Submit</button>
                            </div>
                                    : null
                            }
                        </React.Fragment>
                    }

                </div>
            </div>
        </div>
    )
}

const styles = {
    modalContent: {
      backgroundColor: '#fefefe',
      margin: 'auto',
      padding: 20,
      border: '1px solid #888',
      width: '90%',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    
    close: {
      color: '#aaaaaa',
      float: 'right',
      fontSize: 28,
      fontWeight: 'bold',
      cursor: 'pointer'
    },
    
    hover: {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }
};