import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminPromoSuccess, adminPromoFailed, adminUpsertPromoSuccess, adminUpsertPromoFailed, adminDeletePromoSuccess, adminDeletePromoFailed } from "../../action";
import admin from '../../../graphql/admin/promo'
import { theme } from '../../../common/theme'

//fetch promo
function* adminPromos(action) {
    const { payload } = action
    try{
        const result = yield admin.getAllPromo(payload)
        if(result === 403) {
            yield put(adminPromoFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getAllPromo ? result.data.getAllPromo : {}
            yield put(adminPromoSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminPromoFailed(errorMessage))
    }
}

export function* watchAdminPromos() {
    yield takeLatest(ADMIN.PROMO.LOAD, adminPromos);
}

//upsert preference
function* adminUpsertPromo(action) {
    const { payload } = action
    const type = payload.type
    try{
        const result = yield admin.upsertPromo(payload)
        if(result === 403) {
            yield put(adminUpsertPromoFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.promoUpsert && result.data.promoUpsert ? result.data.promoUpsert : {}
            yield put(adminUpsertPromoSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertPromoFailed(errorMessage))
    }
}

export function* watchAdminUpsertPromo() {
    yield takeLatest(ADMIN.PROMO_UPSERT.LOAD, adminUpsertPromo);
}

//delete promo
function* adminDeletePromo(action) {
    const { payload } = action
    try{
        const result = yield admin.deletePromo(payload)

        if(result === 403) {
            yield put(adminDeletePromoFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.promoDelete && result.data.promoDelete ? result.data.promoDelete : ''
            yield put(adminDeletePromoSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        yield put(adminDeletePromoFailed(errorMessage))
    }
}

export function* watchAdminDeletePromo() {
    yield takeLatest(ADMIN.PROMO_DELETE.LOAD, adminDeletePromo);
}