import React, {useContext} from 'react'
import {FKOnIphone, GetItOnAppStoreLG, GetItOnAppStore, FKAppOnIphoneHighlighted, FKAppOnIphoneMobileView} from '../../../assets/svg'
import GooglePlayLG from '../../../assets/images/playstoreLG.svg'
import QRCode from '../../../assets/images/qrCode.jpeg'
import {GlobalContext} from '../../../common/context'
import FkApp from '../../../assets/images/FkAppOnIphoneMobileView.png'
import ItemCardWithLabel from '../../../assets/images/itemCardWithLabel.png'
import DownloadOnAppStore from '../../../assets/images/downloadOnTheAppStore.png'
import FKAppWithBoostMobileView from '../../../assets/images/FkAppWithBoostMobileView.png'

const DownloadPage = () => {
    const globalValue = useContext(GlobalContext)
    const {isShowAd} = globalValue
    return (
        <>
        <div className='desktop-icons'>
        <div className='download-page-main-container'>
            <div className='download-f-page-main-container'>
                <div className='download-f-page-container'>
                    <div className='download-f-illustration-container'>
                        <div>
                            <FKOnIphone />
                        </div>
                        <div>
                            <p className='download-f-title-p'>{`TRY FISHINGKAKI ON-APP`}</p>
                            <p className='download-f-description-p'>{`Unlock exclusive features when you download the Fishingkaki app today!`}</p>
                            <div className='download-f-links-container'>
                                <div>
                                    <div>
                                        <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center" target="_blank">
                                            {/* <img src={AppStore} width={150}/> */}
                                            <GetItOnAppStoreLG />
                                        </a>
                                    </div>
                                    <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mt-32 " target="_blank">
                                        <img src={GooglePlayLG} />
                                        {/* <GooglePlayLG /> */}
                                    </a>
                                </div>
                                <div className='download-qr-container'>
                                    <img src={QRCode} width={150} height={150}/>
                                    <p className='download-qr-scan-text-p'>{`Scan Me`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-align-center flex-justify-center w-100 download-s-page-main-container'>
                <div className='flex flex-d-row flex-align-center flex-justify-between w-100 download-s-page-sub-container'>
                    <div className='download-fk-details-container'>
                        <p>{`FISHINGKAKI PRIME`}</p>
                        <p>{`Let your item be seen`}</p>
                        <p>{`Put your item at the top of other listings or highlight to be noticed easily.`}</p>
                    </div>
                    <FKAppOnIphoneHighlighted />
                </div>
            </div>
            <div className='download-t-page-main-container'>
                <p>{`FRESH ITEMS`}</p>
                <p>{`Up to date feed`}</p>
                <p>{`Come shop now and browse from 1000’s of listings.`}</p>
            </div>
            <div className='download-page-fo-main-container'>
                <div className='download-page-fo-sub-container'>
                    {/* <FKItemCardWithLabel /> */}
                    <img src={ItemCardWithLabel} width={308} height={365}/>
                    <div className='download-page-fo-details-container'>
                        <p>{`SELL, BUY or TRADE`}</p>
                        <p>{`Find exactly what you want, when you want it`}</p>
                        <div className='download-page-fo-sub-details-container'>
                            <p>{`No longer using fishing related equipment? SELL it!`}</p>
                            <p>{`Find exactly what you want, BUY it! `}</p>
                            <p>{`or simply want to exchange your item for another? TRADE it!`}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='download-page-fi-container'>
                <p className='download-f-title-p'>{`DOWNLOAD NOW!`}</p>
                <p className='for-better-exp-label-p'>{`For better experience`}</p>
                <div className='flex flex-d-row'>
                    <div>
                        <div>
                            <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center" target="_blank">
                                {/* <img src={AppStore} width={150}/> */}
                                <GetItOnAppStoreLG />
                            </a>
                        </div>
                        <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mt-32 " target="_blank">
                            <img src={GooglePlayLG} />
                            {/* <GooglePlayLG /> */}
                        </a>
                    </div>
                    <div className='download-qr-second-container'>
                        <img src={QRCode} width={130} height={130}/>
                        <p className='download-qr-scan-text-p'>{`Scan Me`}</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className={`mobile-icons w-100 ${isShowAd ? `mt-196` : `mt-87`} pb-95`}>
            <div className={`download-f-page-main-container`}>
                <div className='download-f-page-container'>
                    <div className='w-100 flex flex-d-column flex-align-center flex-justify-center'>
                        <p className='download-f-title-p'>{`TRY FISHINGKAKI ON-APP`}</p>
                        <p className='download-f-description-p'>{`Unlock exclusive features when you download the Fishingkaki app today!`}</p>
                        <img src={FkApp} width={125}/>
                        <div className='mt-29'>
                            <div>
                                <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center" target="_blank">
                                    <img src={DownloadOnAppStore} width={168.8} height={52.43}/>
                                </a>
                            </div>
                            <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mt-29 " target="_blank">
                                <img src={GooglePlayLG} width={168.8} height={52.43}/>
                                {/* <GooglePlayLG /> */}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='download-s-page-main-container w-100 flex flex-justify-center flex-align-center'>
                <div className='download-fk-details-container'>
                    <p>{`FISHINGKAKI PRIME`}</p>
                    <p>{`Let your item be seen`}</p>
                    <img src={FKAppWithBoostMobileView} width={125} />
                    <p className='put-your-item-label-p mt-20'>{`Put your item at the top of other listings`}</p>
                    <p className='put-your-item-label-p'>{`or`}</p>
                    <p className='put-your-item-label-p'>{`highlight to be noticed easily.`}</p>
                </div>
            </div>
            <div className='download-t-page-main-container'>
                <p>{`FRESH ITEMS`}</p>
                <p>{`Up to date feed`}</p>
                <p>{`Come shop now and browse from 1000’s of listings.`}</p>
            </div>
            <div className='download-page-fo-main-container'>
                <p>{`SELL, BUY or TRADE`}</p>
                <p>{`Find exactly what you want, when you want it`}</p>
                <img src={ItemCardWithLabel} width={216.49} height={257}/>
                    <div className='download-page-fo-sub-details-container'>
                        <p>{`No longer using fishing related equipment? SELL it!`}</p>
                        <p>{`Find exactly what you want, BUY it! `}</p>
                        <p>{`or simply want to exchange your item for another? TRADE it!`}</p>
                    </div>
            </div>
            <div className='download-page-fi-container'>
                <p className='download-f-title-p'>{`DOWNLOAD NOW!`}</p>
                <p className='for-better-exp-label-p'>{`For better experience`}</p>

                <div>
                    <div>
                        <a href="https://www.facebook.com/FishingKakiCommunity" className="flex flex-d-row flex-align-center" target="_blank">
                            <img src={DownloadOnAppStore} width={168.8} height={52.43}/>
                        </a>
                    </div>
                    <a href="https://play.google.com/store/apps/details?id=com.fishingkaki" className="flex flex-d-row flex-align-center mt-32 " target="_blank">
                        <img src={GooglePlayLG} width={168.8} height={52.43}/>
                        {/* <GooglePlayLG /> */}
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}

export default DownloadPage