import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import {FkPrimeContext} from '../../../../common/context'
import {getPaypalSubscriptionIdByAmount, resetPaypal} from '../../../../common/helper/utility'

const PaypalButton = () => {
    const value = useContext(FkPrimeContext);
    const {sessionData, userBoost, session, purchaseCoin, resetState, pillSelect} = value
    const userId = session && session.fkv2_session && session.fkv2_session.id ? session.fkv2_session.id  : null
    const s_email = sessionData && sessionData.s_email ? sessionData.s_email : null
    const boostDetails = userBoost && userBoost.boostDetails ? userBoost.boostDetails : [] 
    const sg_price = userBoost && userBoost.boostDetails && userBoost.boostDetails.sg_price ? userBoost.boostDetails.sg_price : 0
    const boostOtpDetails = userBoost && userBoost.boostOtpDetails ? userBoost.boostOtpDetails : [] 
    const boostOtpTotal = userBoost && userBoost.boostOtpTotal ? userBoost.boostOtpTotal : 0

    const [lifetimeItem, setLifetimeItem] = useState([])

    const processPurchaseUnits = () => {
        let arr = []
        boostOtpDetails.map(data => {
            const price = data && data.sg_price ? data.sg_price : 1
            const quantity = data && data.quantity ? data.quantity : 1

            const obj = {
                name: data.name,
                description: data.description,
                unit_amount: {
                    currency_code: "SGD",
                    value: price.toFixed(2)
                },
                quantity: quantity
            }
            arr.push(obj)
        })
        setLifetimeItem(arr)
    }

    const paypalPlanId = getPaypalSubscriptionIdByAmount(sg_price)
    const [loaded, setLoaded] = useState(false)
    let paypalRef = useRef()
    const [paypalError, setPaypalError] = useState(false)
    const service_name = boostDetails.service_name

    const boostDetailsObj = {
        "type": boostDetails.type,
        "user_id": parseInt(userId),
        "coin": boostDetails.credit ? parseInt(boostDetails.credit) : 0,
        "id": boostDetails.id ? parseInt(boostDetails.id) : 0,
        "sg_price": sg_price,
        "description": boostDetails.description,
        "method": "paypal",
        "currency": "sgd",
        "s_email": s_email,
        "name": service_name,
        "customer": "",
    }

    useEffect(() => {
        return () => resetPaypal()
    }, [])

    useEffect(() => {
        // if (pillSelect === 2) {
        //     processPurchaseUnits()
        // }
        processPurchaseUnits()
    }, [pillSelect])

    useEffect(() => {
        processPaypal()
    }, [loaded])

    const parsedLineItems = useCallback((arr, method) => {
        let data = []
        arr.map(item => {
            let obj = {...item, user_id: parseInt(userId), method: method}
            data.push(obj)
        })
        return data
    }, [boostOtpDetails, boostDetailsObj])

    console.log("boostDetailsObj", boostOtpDetails)

    const handleForcePurchase = () => {
        // if (pillSelect === 1) {
        //     const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
        //     const boostData = {payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
        //     purchaseCoin(boostData, { order_id: 'data.subscriptionID' })
        // }
        // else {
            const line_items_data = parsedLineItems(boostOtpDetails, 'paypal')
            const boostData = {payment_method: 'paypal', type: 'otp', total_amount: boostOtpTotal.toFixed(2), line_items_detail: line_items_data }
            purchaseCoin(boostData, { order_id: 'order.id' })
        // }
    }

    const processPaypal = () => {
        const script = document.createElement("script")
        // const paypalSrc =  pillSelect === 1 ? `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=card&intent=subscription` : `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`
        const paypalSrc = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=card&currency=SGD`

        script.src = paypalSrc
        script.addEventListener('load', () => setLoaded(true))
        document.body.appendChild(script)

        if (loaded) {
            setTimeout(() => {
                // if (pillSelect === 1) {
                //     window.paypal.Buttons({
                //         style: {
                //             label: 'paypal',
                //             width: '50%'
                //         },
                //         createSubscription: (data, actions) => {
                //             return actions.subscription.create({
                //               'plan_id': paypalPlanId
                //             });
                //         },
                //         onApprove: async (data, actions) => {
                //             const line_items_data = parsedLineItems([boostDetailsObj], 'paypal')
                //             const boostData = {credit: null, payment_method: 'paypal', type: 'fk_prime', total_amount: sg_price.toFixed(2), line_items_detail: line_items_data }
                //             purchaseCoin(boostData, { order_id: data.subscriptionID })
                //         },
                //         onError: async (e) => {
                //             const error = await e
                //             console.log('error', error)
                //             setPaypalError(JSON.stringify(error))
                //             setTimeout(() => {
                //                 setPaypalError(false)
                //                 resetState()
                //             }, 3000)
                //         }
                //     }).render(paypalRef);
                // }
                // else {
                    window.paypal.Buttons({
                        style: {
                            label: 'paypal',
                            width: '50%'
                        },
                        createOrder: (data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        description: 'Fk Lifetime Plan',
                                        amount: {
                                            currency_code: "SGD",
                                            value: boostOtpTotal.toFixed(2),
                                            breakdown: {
                                                item_total: {
                                                    currency_code: "SGD",
                                                    value: boostOtpTotal.toFixed(2)
                                                }
                                            }
                                        },
                                        items: lifetimeItem
                                    }
                                ]
                            })
                        },
                        onApprove: async (data, actions) => {
                            const order = await actions.order.capture()
                            if (order.status === 'COMPLETED') {
                                const line_items_data = parsedLineItems(boostOtpDetails, 'paypal')
                                const boostData = {credit: null, payment_method: 'paypal', type: 'otp', total_amount: boostOtpTotal.toFixed(2), line_items_detail: line_items_data }
                                purchaseCoin(boostData, { order_id: order.id })

                            } else {
                                setPaypalError("Error processing payment, try again.")
                            }
                        },
                        onError: async (e) => {
                            const error = await e
                            console.log('error', error)
                            setPaypalError(JSON.stringify(error))
                            setTimeout(() => {
                                setPaypalError(false)
                                resetState()
                            }, 3000)
                        }
                    }).render(paypalRef);
                // }
            }, 1000)
        }
    }
 
    return (
        <div>
            <div>
                    { paypalError ? 
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ color: 'red' }}>{paypalError}</p>
                        </div> 
                    : 
                        <div style={{ width: '100%', marginTop: 20, marginBottom: 10 }}>
                            <div ref={v => (paypalRef = v)}></div>
                        </div>
                    }
            </div>
        </div>
        // <div onClick={() => handleForcePurchase()} style={{position: 'absolute', height: 200}}>Force Purchased</div>
    );
}

export default PaypalButton;