import React from 'react'
import ReactGA from 'react-ga'
const { REACT_APP_ENV } = process.env;

export const initGA = (id) => {   
    if (REACT_APP_ENV === 'prod') {
        ReactGA.initialize(id);
    }
} 

export const GApageView = (page) => {   
    if (REACT_APP_ENV === 'prod') {
        ReactGA.pageview(page);  
    }
}

export const GAmodalView = (modal) => {
    if (REACT_APP_ENV === 'prod') {
        ReactGA.modalview(modal);
    }
};

export const GAevent = (categoryName, eventName) => {
    if (REACT_APP_ENV === 'prod') {
        ReactGA.event({       
            category: categoryName,  // Required
            action: eventName,       // Required   
        }); 
    }
}