import React from 'react'
import {Link} from 'react-router-dom'
import {GreaterThanIcon} from '../../../assets/svg'

const Breadcrumb = (props) => {
    const breadcrumb = props.links ? props.links : []
    const loading = props.loading ? props.loading : false
    return (
        <>
        {
            loading ? null : 
            <div className="flex flex-d-row w-100">
                {
                    breadcrumb.map((l, i) => {
                        const label = l.label ? l.label : ''
                        const link = l.link ? l.link.toLowerCase().replace(/[$&+,:;=?@#|'<>.^*()%!]/g, '') : ''
                        return <div className="flex flex-d-row flex-align-center breadcrumb-container" key={i}>
                                    <Link to={link} className="flex flex-align-center pr-8">
                                        <p className="breadcrumb-label">{label}</p>
                                    </Link>
                                {
                                    parseInt(breadcrumb.length) === parseInt(i+1) ? null : <div className="pr-8"><GreaterThanIcon /></div>
                                    
                                } 
                                
                                </div>
                    })
                }
            </div>
        } 
        </>
    )
}

export default Breadcrumb