import { AVATAR } from '../constant'
import { takeLatest, put } from "redux-saga/effects";
import user from '../../graphql/user'
import { avatarFail, avatarSuccess} from '../action/avatarAction'

// avatar
function* avatarSaga(action) {
    const id = action && action.payload && action.payload.user_id ? action.payload.user_id : 0
    try {
        const result = yield user.getAvatarUser(id)
        if(result === 403) {
            yield put(avatarFail('Something went wrong try again!!!'))
        }
        else{
            const data = result && result.data && result.data.getAvatarByUser
            yield put(avatarSuccess(data));
        }
    } catch (error) {
        console.log('error', JSON.stringify(error))
    }
}

export function* watchAvatarSaga() {
    yield takeLatest(AVATAR.LOAD, avatarSaga)
}
