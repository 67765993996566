import React, {useContext} from 'react'
import { FkPrimeContext } from '../../../common/context'
import {GrayCoinsIcon} from '../../../assets/svg'
import {BoostSelectionContainer, ModalComponent, LifetimeMobilePurchaseModal} from './lifetimeCustomComponent'
import {MobileFooter, Divider} from './customComponent'
import CustomFooter from '../../../common/component/customComponents/footer'

const LifetimePlans = () => {
    const {userBoost, boostOffer, setUserBoost, handleBoostSelection, purchaseLifeTimeModal, handleSetPurchaseLifeTimeModal, handleIncrementDecrement, handleBuyCoinsModal} = useContext(FkPrimeContext)
    const {boostOtpTotal} = userBoost
    const {otpBoost} = boostOffer
    const finalArr = otpBoost && otpBoost.filter(item => !item.name.includes('Listing'))

    return (
        <div className="w-100 flex flex-d-column flex-align-center flex-justify-center">
            <div className={`w-100 flex ${window.innerWidth < 600 ? `flex-d-column pl-16 pr-16` : `flex-d-row mt-60 `} flex-align-center flex-justify-center`}>
            {
                finalArr.map((boosts, i) => {
                    const price = boosts && boosts.sg_price ? boosts.sg_price : 0
                    const boost = {...boosts, price: boosts.name.includes('Listing') ? price * parseFloat(userBoost.otpMultiQuantity) : price}
                    return BoostSelectionContainer(boost, i, handleBoostSelection, userBoost, setUserBoost, handleIncrementDecrement)
                })
            }
            </div>
            {
                window.innerWidth < 600 ? null :
                <>
                    <div className="mt-20 individual-ads-main-total-container">
                        <p className="individual-ads-main-total-label-p">{`Total`}</p>
                            <div className="flex flex-d-row flex-align-center">
                                {/* <GrayCoinsIcon /> &nbsp; */}
                                <p className="individual-ads-total-number-p">{`S$ ${
                                boostOtpTotal === 0 ? boostOtpTotal.toFixed(2) : boostOtpTotal.toFixed(2)}`
                                }</p>
                            </div>
                    </div>
                    <div className="w-100 flex flex-align-center flex-justify-center mt-20">
                        <div className={boostOtpTotal !== 0 ? `fk-prime-purchase-btn cursor-pointer` : `opacity-p-6 fk-prime-purchase-btn `} onClick={() => handleSetPurchaseLifeTimeModal(true)}>
                            <p className="fk-prime-purchase-p">{`Purchase`}</p>
                        </div>
                    </div>
                </>
            }
            {purchaseLifeTimeModal ? window.innerWidth < 599 ? <LifetimeMobilePurchaseModal /> : <ModalComponent /> : null}
            {window.innerWidth < 599 ?
            <> 
                <MobileFooter boost={userBoost.boostOtpTotal} buyCoinsFunc={handleBuyCoinsModal} handleFooter={handleSetPurchaseLifeTimeModal}/> 
                <Divider />
                <CustomFooter />
            </>: null
            }
        </div>
        
    )
}

export default LifetimePlans