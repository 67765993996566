import React from 'react'
import { Links } from '../links'

export const Archive = () => {
    return (
        <div className="full">
            <div className="titleHead">
            </div>
            <Links />
            <div style={{display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center', alignItems: 'center', padding: '20px 5px'}}>
                <h1>{`Feature is not available yet`}</h1>
            </div>
        </div>
    )
}

export default Archive