import React from 'react'
import CategoryItemCard from './customComponent'
import GoogleAds from '../../../../common/component/googleAds'

const CategoryCard = (props) => {
    const data = props && props.items ? props.items : null
    const upto = props && props.upto ? props.upto : '0'
    const session_id = props.session !== 0 ? props.session : null

    const insertAds = (index) => {
        const actualIndex = index + 1
        const counter = window.innerWidth < 600 ? 10 : 20
        let element = null
        if (actualIndex % counter === 0) {
            element = 
            <div className={window.innerWidth < 600 ? 'grid-insert-1-3' : 'grid-insert-1-5'}>
              <GoogleAds />
            </div>
        }
        return element
      }
    
    return (
        data.length === 0 ? <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '250px'}}><p style={{fontSize: '20px', fontWeight: '700'}}>No results.</p></div> : 
                <div className="category-item-container">
                  {
                        data.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div key={index}>
                                    <CategoryItemCard item={item} index={index} id={session_id}/>
                                    </div>
                                        {insertAds(index)}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
    );
}

export default CategoryCard