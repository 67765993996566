import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminCategorySuccess, adminCategoryFailed, adminUpsertCategorySuccess, adminUpsertCategoryFailed
} from "../../action";
import admin from '../../../graphql/admin/category'
import { theme } from '../../../common/theme'
import { categoryIndexer } from '../../../common/helper/utility'

//fetch category
function* adminCategory(action) {
    const { payload } = action
    try{
        const result = yield admin.getCategories(payload)
        if(result === 403) {
            yield put(adminCategoryFailed('Something went wrong try again!'))
        }
        else {
            const data = result && result.data && result.data.getAllCategory && result.data.getAllCategory.category ? result.data.getAllCategory.category : []
            const categoryRaw = parseCategory(data)
            const grouped = parseData(data)
            const groupedData = sortByIndex(grouped)
            yield put(adminCategorySuccess({groupedData, categoryRaw}));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminCategoryFailed(errorMessage))
    }

    function parseCategory(res) {
        let arr = []
        res.map((item) => {
            arr.push({pk_i_id: item.pk_i_id, fk_i_category_id: item.oc_t_category_descriptions.fk_i_category_id, s_name: item.oc_t_category_descriptions.s_name})
        })
        return arr
    }

    function parseData(res) {
        let groups = {};
        for (let i = 0; i < res.length; i++) {
          let groupName = res && res[i] && res[i].oc_t_parent_category_descriptions && res[i].oc_t_parent_category_descriptions.s_name ? res[i].oc_t_parent_category_descriptions.s_name : "Parent";
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(res[i]);
        }
        let categoryData = [];
        for (let groupName in groups) {
          const categoryIndex = categoryIndexer(groupName)
          categoryData.push({ parent: groupName, categoryIndex: categoryIndex, data: groups[groupName] });
        }
        return categoryData
    }

    function sortByIndex(arr){
        return arr.sort(function(a, b){return a.categoryIndex - b.categoryIndex});
    }
}

export function* watchAdminCategory() {
    yield takeLatest(ADMIN.CATEGORY.LOAD, adminCategory);
}

//upsert category
function* adminUpsertCategory(action) {
    const { payload } = action
    try{
        const result = yield admin.upsertCategory(payload)
        if(result === 403) {
            yield put(adminUpsertCategoryFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.categoryUpdate ? result.data.categoryUpdate : result.data.categoryInsert ? result.data.categoryInsert : {}
            yield put(adminUpsertCategorySuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertCategoryFailed(errorMessage))
    }
}

export function* watchAdminUpsertCategory() {
    yield takeLatest(ADMIN.CATEGORY_UPSERT.LOAD, adminUpsertCategory);
}
