import { CHAT, SEND_CHAT, ADD_ROOM, SEND_REVIEW, SET_CHAT_ITEM, DELETE_CHAT } from '../constant'

export const chatLoad = (payload) =>{
    return {
        type: CHAT.LOAD,
        payload
    }
}    

export const chatSuccess = (payload) =>{
    return {
        type: CHAT.SUCCESS,
        payload,
    }
}

export const chatAddMessage = (payload) =>{
    return {
        type: CHAT.ADD_MESSAGE,
        payload,
    }
}

export const chatAddRoom = (payload) =>{
    return {
        type: CHAT.ADD_ROOM,
        payload,
    }
}

export const chatUpdateUnread = (payload) =>{
    return {
        type: CHAT.UPDATE_UNREAD,
        payload,
    }
}

export const chatSetActiveIndex = (payload) =>{
    return {
        type: CHAT.SET_ACTIVE_INDEX,
        payload,
    }
}

export const chatFailed = (payload) =>{
    return {
        type: CHAT.FAILED,
        payload,
    }
}

export const sendChatLoad = (payload) =>{
    return {
        type: SEND_CHAT.LOAD,
        payload
    }
}    

export const sendChatSuccess = (payload) =>{
    return {
        type: SEND_CHAT.SUCCESS,
        payload,
    }
}

export const sendChatFailed = (payload) =>{
    return {
        type: SEND_CHAT.FAIL,
        payload,
    }
}

export const sendChatReset = () =>{
    return {
        type: SEND_CHAT.RESET,
    }
}

export const getChatRoomByIdLoad = (payload) =>{
    return {
        type: ADD_ROOM.LOAD,
        payload
    }
}    

export const getChatRoomByIdSuccess = (payload) =>{
    return {
        type: ADD_ROOM.SUCCESS,
        payload,
    }
}

export const getChatRoomByIdFailed = (payload) =>{
    return {
        type: ADD_ROOM.FAILED,
        payload,
    }
}

export const sendReviewLoad = (payload) =>{
    return {
        type: SEND_REVIEW.LOAD,
        payload
    }
}    

export const sendReviewSuccess = (payload) =>{
    return {
        type: SEND_REVIEW.SUCCESS,
        payload,
    }
}

export const sendReviewFailed = (payload) =>{
    return {
        type: SEND_REVIEW.FAIL,
        payload,
    }
}

export const sendReviewReset = () =>{
    return {
        type: SEND_REVIEW.RESET,
    }
}

export const setChatItemReserved = (payload) => {
    return {
        type: SET_CHAT_ITEM.RESERVE,
        payload,
    }
}

export const setChatItemSold = (payload) => {
    return {
        type: SET_CHAT_ITEM.SOLD,
        payload,
    }
}

export const setChatMobileIsThread = (payload) => {
    return {
        type: CHAT.SET_ISTHREAD,
        payload,
    }
}

export const deleteChatLoad = (payload) =>{
    return {
        type: DELETE_CHAT.LOAD,
        payload
    }
}    

export const deleteChatSuccess = (payload) =>{
    return {
        type: DELETE_CHAT.SUCCESS,
        payload,
    }
}

export const deleteChatFailed = (payload) =>{
    return {
        type: DELETE_CHAT.FAIL,
        payload,
    }
}

export const deleteChatReset = () =>{
    return {
        type: DELETE_CHAT.RESET,
    }
}