import gql from 'graphql-tag'
import client from '../apollo/apollo-client'

export const getSearch = (first, page, key, type, orderType, orderField, minPrice, maxPrice) => {
    if(type === 'user'){
            try {
                const query = gql`query publicUserSearch($first: Int, $page: Int, $search: String!,$b_active: Boolean!, $orderField: String!, $orderType: String!){
                    publicUserSearch(
                      first: $first,
                      page: $page,
                      search: $search,
                      b_active: $b_active,
                      orderField: $orderField,
                      orderType: $orderType,
                    ){
                        count 
                        data{
                            pk_i_id
                            b_active
                            s_name
                            s_username
                            oc_t_user_resource{
                                pk_i_id
                                fk_i_user_id
                                s_extension
                                s_name
                                s_content_type
                                s_path
                            }
                            items_count
                            social {
                                data
                                }
                            }
  					}
            }`;

            const response = client.query({
                query: query,
                variables: {first: first, page: page, search: key, b_active: true, orderField: "dt_reg_date", orderType:"DESC"}
            });
            const data = response ? response : null;
            return data

            } catch (e) {
                console.log('e', e)
                throw e
            }
        // eslint-disable-next-line  
    }  else {
        if (page === 1) {
            client.cache.reset()
        }
                try{
                    const query = gql`query publicItemSearch($b_active: Boolean, $first: Int!, $page: Int!, $search: String!, $orderField: String!, $orderType: String!, $price_from: Int, $price_to: Int){
                        publicItemSearch(
                                    b_active: $b_active,
                                    first: $first,
                                    page: $page,
                                    search: $search,
                                    orderField: $orderField,
                                    orderType: $orderType,
                                    i_price: {
                                        price_from: $price_from,
                                        price_to: $price_to
                                    }
                                    
                                    ){
                                        count
                                        data {
                                            ab_expiration
                                    is_new
                                    pk_i_id
                                    fk_i_user_id
                                    b_active
                                    b_mark_as_sold
                                    b_reserved
                                    dt_pub_date
                                    dt_sold
                                    post_type
                                    oc_t_item_description{
                                        s_title
                                        s_description
                                    }
                                    s_contact_name
                                    watcher
                                    i_price
                                    get_i_price
                                    ta_expiration
                                    hl_expiration
                                    watchlist{
                                      id
                                      fk_i_user_id
                                      fk_i_item_id
                                      items{
                                        pk_i_id
                                      }
                                    }
                                    watchlist_count
                                    oc_t_item_resource{
                                    pk_i_id
                                    fk_i_item_id
                                    s_name
                                    s_extension
                                    s_content_type
                                    s_path
                                    }
                                    oc_t_user{
                                        pk_i_id
                                        s_username
                                        oc_t_user_resource{
                                        pk_i_id
                                        s_extension
                                        s_content_type
                                        s_name
                                        s_path
                                        s_content_type
                                        fk_i_user_id
                                        }
                                        social {
                                            data
                                        }
                                    }
                                    oc_t_category{
                                        pk_i_id
                                        oc_t_category_descriptions{
                                            s_name
                                        }
                                    }
                                }
                                }                
                            }`;
                const response = client.query({
                    query: query,
                    variables: {first: first, page: page, b_active: true, search:key, orderField:orderField, orderType:orderType, price_from:minPrice, price_to:maxPrice}
                });

                const data = response ? response : null;
                return data

                }catch (e){
                    throw e
                }
    }
}

export const addTopSearch = (id) => {
    try{
        const query = gql`mutation addTopSearch($item_id: Int!, $limit: Int!){
            addTopSearch(item_id: $item_id, limit: $limit){
                item_id
            }
        }`;

          const response = client.mutate({
            mutation: query,
            variables: {
                item_id: id,
                limit: 4
            }
          });
          const result = response ? response : null;
          return result;
    } catch (e){
        console.log('error', e)
        throw e
    }
}

export default {
    getSearch,
    addTopSearch
}