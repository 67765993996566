import React from 'react'

const NativeHeaderMobile = ({details}) => {
    return (
        <div className="native-header-container">
            <div onClick={details.leftLinkFunc ? details.leftLinkFunc : null}>
                <p className="native-header-links native-active-link">{details.leftLink ? details.leftLink : ''}</p>
            </div>
            <div className="native-header-title-container">
                <h3 className="native-header-title">{details.mainTitle ? details.mainTitle : ''}</h3>
            </div>
            {
                details.isFuncReady ? <div onClick={details.submitFunc ? details.submitFunc : null}>
                <h3 className="native-header-links native-active-link">{details.rightLink ? details.rightLink : ''}</h3>
            </div> : <div>
                <p className="native-header-links native-not-active-link">{details.rightLink ? details.rightLink : ''}</p>
            </div>
            }
            
            
        </div>
    )
}

export default NativeHeaderMobile