import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({session, component, layout: Layout, ...rest}) => (
  <Route
    {...rest}
    render={routeProps => 
    session && session.fkv2_session && session.fkv2_session.type && session.fkv2_session.type === 'admin' ? <Redirect to="/admin/dashboard" />
    : <Layout {...routeProps} component={component} session={session}/>}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
};

export default PublicRoute;
