import gql from 'graphql-tag';
import client from '../apollo/apollo-client';

export const createReview = (data) => {
    try{
        const query = gql`mutation create_user_comment($input: CreateCommentInput!){
            create_user_comment(input: $input){
              id
              fk_i_user_id
              comment_parent_id
              body
              created_at
            }
          }`;

          const response = client.mutate({
            mutation: query,
            variables: {input: 
            {fk_i_user_id: data.reviewer, 
              body: data.review,
              comment_parent_id: data.seller_id,
              commentable_id: 1, 
              commentable_type: "App\\Models\\OcTUser", 
              type: "upsert"
            }
          }
          });
          const result = response ? response : null;
          return result;
    } catch (e){
        console.log('error', e)
        throw e
    }
}

export const reviewView = (data) => {
    const  id = data.id ? data.id : 0
    try{
        const query = gql`query getReviewById($id: ID!, 
                      $filter: ChatFilter!, 
                      $first: Int!,
                      $page: Int!,
                      $orderField: String!, 
                      $orderType: String!){
              getReviewById(
                id: $id,
                filter: $filter,
                first: $first,
                page: $page,
                orderField: $orderField,
                orderType: $orderType
              ){
                review_count
                average_payment_rate
                average_product_rate
                average_communication_rate
                average_meetup_shipping_rate
                average_total_rate
                data {
                  id
                  reviewer_id
                  reviewee_id
                  review_item_id
                  review_ratingable_id
                  payment_rate
                  product_rate
                  communication_rate
                  meetup_shipping_rate
                  total_rate
                  created_at
                  body
                  review_resource {
                    pk_i_id
                    review_id
                    s_name
                    s_extension
                    s_content_type
                    s_path
                  }
                  reviewer{
                    pk_i_id
                    s_username
                    oc_t_user_resource{
                      pk_i_id
                      fk_i_user_id
                      s_extension
                      s_name
                      s_content_type
                      s_path
                    }
                    social{
                      data
                    }
                  }
                  item{
                    pk_i_id
                    fk_i_user_id
                    oc_t_item_resource{
                      pk_i_id
                      fk_i_item_id
                      s_name
                      s_path
                      s_extension
                      s_content_type
                    }
                    oc_t_item_description{
                      s_title
                      s_description
                    }
                    oc_t_category{
                      pk_i_id
                      fk_i_parent_id
                      oc_t_category_descriptions{
                        fk_i_category_id
                        s_name
                      }
                    }
                  }
                }
              }   
            }`;
        const response = client.query({
            query: query,
            variables: {
              id: parseInt(id),
              filter: "ALL",
              first: 20,
              page: 1,
              orderField: "created_at",
              orderType: "DESC"
            }
        })

        const result = response ? response : null;
        return result;
    } catch (e){
        console.log('error', e)
        throw e
    }
}

export default {
    createReview,
    reviewView
}