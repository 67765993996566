import React, {useEffect, useState, useReducer, useCallback, memo} from 'react'
import { connect } from 'react-redux';
import ItemNewWeb from './webIndex2'
import MobileNew from './mobileIndex'
import EditIndex from './edit'
import {meLoad, servicesLoad, purchaseCoinLoad, purchaseCoinReset, createItemLoad, promoteLoad, createItemReset} from '../../../../redux/action'
import {Modal} from './customComponents'
import {ItemNewContext} from '../../../../common/context'
import { slugify, categoryParser} from '../../../../common/helper/utility'
import {FreeSlotSuccessClaimModal, WholePageLoader, ScrollToTop} from '../../../../common/component/customComponents'
import { me } from '../../../../graphql/string_data';

const {REACT_APP_ENV} = process.env

const ItemNew = (props) => {
  const { isLoading, sessionData } = props.me
  const publishLoading = props.create.isLoading
  const publishData = props.create.data
  const publishError = props.create.error
  const publishItemData = props.create.itemData
  const live_items = props.me && props.me.data && props.me.data.live_items ? props.me.data.live_items : []
  const updateItemData = props && props.history && props.history.location && props.history.location.query && props.history.location.query.itemData ? props.history.location.query.itemData : props.history.location.query && props.history.location.query.item ? props.history.location.query.item : false
  const meData = props && props.me && props.me.data ? props.me.data : []
  const session = props && props.session ? props.session : null
  const sessionId = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? props.session.fkv2_session.id : 0
  const wallet = props && props.me && props.me.data && props.me.data.wallet ? props.me.data.wallet : 0
  const isFreeClaimed = props && props.me && props.me.data && props.me.data.is_free_subscription_claimed ? props.me.data.is_free_subscription_claimed : false
  const session_email = props && props.me && props.me.data && props.me.data.s_email ? props.me.data.s_email : ''
  const fkPurchasedArr = props && props.me && props.me.data && props.me.data.purchase_micro_service ? props.me.data.purchase_micro_service : []
  const { servicesData, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin} = props.purchase
  const premiumAccount = props && props.me && props.me.data && props.me.data.database_subscription_status && props.me.data.database_subscription_status.status ? true : false

  const [isFromEdit, setIsFromEdit] = useState(false)
  const [isCoinsClick, setIsCoinsClick] = useState(false)
  const [buyCoinStep, setBuyCoinsStep] = useState(1)
  const [buyCoinsOffer, setBuyCoinsOffer] = useState({})
  const [fkPrimeFree, setFkPrimeFree] = useState({})
  const [imageData, setImageData] = useState([])
  const [initialLoader, setInitialLoader] = useState(false)
  const [highlightClick, setHighlightClick] = useState(false)
  const [premiumClick, setPremiumClick] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [fKPrimeClaim, setFKPrimeClaim] = useState(false)
  const [anotherListing, setAnotherListing] = useState(false)
  const [freeNotClaim, setFreeNotClaim] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [postSubmitted, setPostIsSubmitted] = useState(false)
  const [color, setColor] = useState('red')
  const [modalPops, setModalPops] = useState(false)
  const [desc, setDesc] = useState(false)
  const [meetupClicked, setMeetupClicked] = useState(false)
  const [isMeetupAddNotes, setIsMeetupAddNotes] = useState(false)
  const [isMeetupAddNotes2, setIsMeetupAddNotes2] = useState(false)
  const [locationSuggest, setLocationSuggest] = useState([])
  const [isTyping, setIsTyping] = useState(false)

  const [s_ip, set_s_ip] = useState(null)

  const fetchIp = async () => {
      await fetch('http://ip-api.com/json/')
        .then(results => results.json())
        .then(data => set_s_ip(data.ip));
  }

  useEffect(() => {
    if(sessionId){
      if(!s_ip) {
        fetchIp()
      }
    }
  // eslint-disable-next-line
  }, [])

  let dPremiumId = []
    let dHighlightId = []
    servicesData.map((boost) => { return boost.type === 'top_ad' ? dPremiumId.push(boost) : null })
    servicesData.map((boost) => { return boost.type === 'highlight' ? dHighlightId.push(boost) : null })

    const postTypeArr = [
      {label: 'WTS', value: 'WTS', sublabel: 'Want to Sell'},
      {label: 'WTB', value: 'WTB', sublabel: 'Want to Buy'},
      {label: 'WTT', value: 'WTT', sublabel: 'Want to Trade'}
    ]

    const submitPromote = (data) => {
      props.promoteLoad({ fkv2_purchased_data: data, userId: sessionId })
    }

  const [userBoost, setUserBoost] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      buyCoins: {
        coinsArr: [],
        userId: session.fkv2_session.id,
        payment_method: '',
        total: 0,
        total_coin: 0,
        type: ''
    }
    }
  )

  const [fkPurchased, setFkPurchased] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      free: [],
      paid: [],
      noAvailableSlot: false,
      free_remain: []
    }
    )

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      s_category: '',
      fk_i_category_id: 0,
      i_price: '',
      s_title: '',
      item_condition: '',
      formIsComplete: false,
      deal_method_meetup: '',
      s_dm_shipped_details: '',
      s_dm_meetup_add1: '',
      s_dm_meetup_add2: '',
      totalLocation: 0,
      s_description: '',
      submitItemDetails: [],
      itemLink: '',
      youtube_link: '',
      boost: '',
      taDetails: [],
      hlDetails: [],
      item_id: '',
      subBoost: 0,
      boostTotal: 0,
      boostAppliedTotal: 0,
      highlight: '',
      subHighlight: 0,
      shippingDetails: '',
      highlightTotal: 0,
      converted_YTLink: '',
      meetupAddnotesAddress1: '',
      meetupAddnotesAddress2: '',
      error: {
        name: '',
        price: '',
        description: '',
        image: '',
        item_condition: '',
        category: '',
        meetup: ''
      },
      raw_address: '',
      captcha: '',
      inputError: '',
      custom_fields: {},
      post_type: 'WTS'
    }
  );

  const handleBuyCoins = (arr) => {
    let buyCoinsArr = userBoost.buyCoins.coinsArr
    let buy_total = userBoost.buyCoins.total
    let total_coin = userBoost.buyCoins.total_coin

    const found = userBoost.buyCoins.coinsArr.some(el => el.id === arr.id)
    const newArr = userBoost.buyCoins.coinsArr.filter(item => item.id !== arr.id)
    if(found){
        buyCoinsArr = newArr
        buy_total = parseInt(userBoost.buyCoins.total) - parseInt(arr.sg_price)
        total_coin = parseInt(userBoost.buyCoins.total_coin) - parseInt(arr.credit)
    }else{
        buyCoinsArr.push(arr)
        buy_total = parseInt(userBoost.buyCoins.total) + parseInt(arr.sg_price)
        total_coin = parseInt(userBoost.buyCoins.total_coin) + parseInt(arr.credit)
    }
    setUserBoost({buyCoins: {['coinsArr']: buyCoinsArr, ['userId']: userBoost.buyCoins.userId, ['total']: buy_total, ['type']: arr.type, ['total_coin']: total_coin, ['payment_method']: userBoost.buyCoins.payment_method}})
}
const handleDesc = () => {
  if (!desc) {
    setDesc(true)
  }
}
const handleShippedClick = (type) => {
  let a
  if(meetupClicked){
    if(meetupClicked.includes(type)){
      a = meetupClicked.replace(type, '')
      setUserInput({ ['s_dm_meetup_add1']: '', ['s_dm_meetup_add2']: '', ['deal_method_meetup']: '', ['meetupAddnotesAddress1']: ''})
    }else {
      a = `${meetupClicked} ${type}`
    }
  }else {
    a = type
  }

  setMeetupClicked(a)
}
const convertYoutubeLink = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const embed = '//www.youtube.com/embed/'
  if (match && match[2].length == 11) {
  return embed + match[2];
  } else {
  return null;
  }
}
const handleChange = evt => {
  if (evt.target.type === 'select-one') {
    // eslint-disable-next-line
    setUserInput({ ['fk_i_category_id']: evt.target.options[evt.target.selectedIndex].dataset.id });
  }
  const name = evt.target.name;
  const newValue = evt.target.value;
  const regexp = /^[0-9\b]+$/
  let item_cond;
  if (evt.target.name === 'i_price') {
    if (evt.target.value.includes("-")) {
      setUserInput({ error: { ['name']: userInput.s_title === '' ? 'Enter title' : null, ['price']: 'Price should not be negative', ['category']: userInput.s_category === '' ? 'Enter Category' : null } })
    } 
  }
  if(evt.target.name === 's_category'){
    const customFieldParse = evt.target.options[evt.target.selectedIndex].dataset.cf ? JSON.parse(evt.target.options[evt.target.selectedIndex].dataset.cf) : []
    let cfObject = {}
    if (customFieldParse.length > 0) {
      customFieldParse.map((item) => {
         cfObject[item.name] = {...item, value: ''}
      })
    }
    setUserInput({['custom_fields']: cfObject});
  }
  if(evt.target.name === 'item_condition'){
    setUserInput({['item_condition']: evt.target.value});
  }
  if(evt.target.name === 'item_condition_use' || evt.target.name === 'item_condition_new'){
    if(userInput.item_condition && userInput.item_condition.includes(evt.target.value)){
      item_cond = userInput.item_condition.replaceAll(evt.target.value, '')
      setUserInput({['item_condition']: item_cond})
    }
    if(userInput.item_condition && !userInput.item_condition.includes(evt.target.value)) {
      item_cond = `${userInput.item_condition} ${evt.target.value}`
      setUserInput({['item_condition']: item_cond})
    }
    if(!userInput.item_condition) {
      setUserInput({['item_condition']: evt.target.value})
    }
  }
  if(evt.target.name === 's_category'){
    const customFieldParse = evt.target.options[evt.target.selectedIndex].dataset.cf ? JSON.parse(evt.target.options[evt.target.selectedIndex].dataset.cf) : []
    let cfObject = {}
    if (customFieldParse.length > 0) {
      customFieldParse.map((item) => {
         cfObject[item.name] = {...item, value: ''}
      })
    }
    setUserInput({['custom_fields']: cfObject});
  }
  if (evt.target.name === 's_description') {
    if (newValue.length < 9) {
      setColor('red')
    } else if (newValue.length > 9 && newValue.length < 15) {
      setColor('yellow')
    } else if (newValue.length > 15) {
      setColor('green')
    }
  }
  if(evt.target.name === 'post_type'){
    setUserInput({['post_type']: evt.target.value})
  }
  if(evt.target.name === 'youtube_link'){
    setUserInput({['converted_YTLink']: convertYoutubeLink(newValue), ['youtube_link']: convertYoutubeLink(newValue)})
  }
  if (submitted === true) {
    setUserInput({ error: { ['name']: userInput.s_title === '' ? 'Enter title' : null, ['description']: userInput.s_description === '' ? 'Enter description' : null, ['price']: userInput.i_price === '' ? 'Enter Price' : userInput.i_price.includes("-") ? 'Invalid Price' : userInput.i_price === 0 ? 'Price should not be equal to 0' : null, ['category']: userInput.s_category === '' ? 'Enter Category' : null } })
  }
  setUserInput({ [name]: newValue });
}
const onKeyDownInput = (event) => {
  if (event.key === 'e' || event.key === '+' || event.key === '-') {
    event.preventDefault();
  }
}
const handleChangeStatus = (imageContext, status) => {
  if (status === 'done') {
    const tempData = imageData.concat(imageContext)
    if(imageData.length < 4 ){
    setImageData(tempData)
    }
  }
  if (status === 'removed') {
    const tempData = imageData.filter((obj) => {
      return obj.file.name !== imageContext.file.name
    })
    setImageData(tempData)
  }
}

const handleChangeMap = (evt) => {
  setIsTyping(true)
  const newValue = evt && evt.label ? evt.label : '';
  const name = 'raw_address'
  // const convertToUpperCase = newValue.toUpperCase()
  if(!userInput.s_dm_meetup_add1) {
    setUserInput({['s_dm_meetup_add1']: newValue.toUpperCase()});
  }
  if(userInput.s_dm_meetup_add1) {
    setUserInput({['s_dm_meetup_add2']: newValue.toUpperCase()});
  }
  // setUserInput({ [name]: newValue })
  if(newValue){
    evt.label = null;
  }
  if(newValue){
    // object.map((place) => {
    //   const address = place.ADDRESS
    //   // return address.match(convertToUpperCase) ? locationSuggest.length === 3 ? setLocationSuggest([place]) : setLocationSuggest(locationSuggest.concat(place)) : null
    // })
  }else{
    setLocationSuggest([])
  }
}

const captchaChange = value => {
  if(userInput.inputError){
    setUserInput({ ['inputError']: '' })
  }
  setUserInput({ ['captcha']: value })
}

const userData = {
  fk_i_user_id: sessionData.pk_i_id,
  s_contact_name: sessionData.s_username,
  fk_c_currency_code: 'SGD',
  s_contact_email: sessionData.s_email
}

const handleSubmit = evt => {
  evt.preventDefault();
  setSubmitted(true)
  let finalImageDataArr = []
  imageData.map((item) => {
    return finalImageDataArr.push(item.file)
  })
  const mergeData = { ...userData, ...userInput, finalImageDataArr }
  if (
    REACT_APP_ENV !== 'local' && (userInput.captcha === '' ||
    userInput.s_title === '' || userInput.s_description === '' || userInput.s_description.length < 9 || userInput.i_price === '' || mergeData.finalImageDataArr.length === 0 || userInput.s_category === '')) {
    setUserInput(
      {
        error:
        {
          ['name']: userInput.s_title === '' ? 'Enter title' : null,
          ['description']: userInput.s_description === '' ? 'Enter description' : null,
          ['price']: userInput.i_price === '' ? 'Enter Price' : userInput.i_price.includes("-") ? 'Invalid Price' : null,
          ['image']: mergeData.finalImageDataArr.length === 0 ? 'Add image' : null,
          ['category']: userInput.s_category === '' ? 'Enter Category' : null
        }
      })
    setUserInput({ ['inputError']: 'Recaptcha Error' })
  } else {
  //parse custom field
  let finalObj = {}
  for (var k in mergeData) {
    if (k === 'custom_fields') {
      let finalObj2 = {}
      for(var l in mergeData[k]){
        if (mergeData[k].hasOwnProperty(l)) {
          const value = mergeData[k] && mergeData[k][l] && mergeData[k][l].value ? mergeData[k][l].value : ''
          const name = mergeData[k] && mergeData[k][l] && mergeData[k][l].name ? mergeData[k][l].name : ''
          finalObj2[name] = value
        }
      }
      finalObj[k] = JSON.stringify(finalObj2)
    }
  }
  const finalIp = s_ip ? s_ip : 'unknown'
  const fileForSubmit = {...mergeData, custom_fields : finalObj.custom_fields, s_ip: finalIp }
 props.createItemLoad(fileForSubmit) 
  setSubmitLoading(true)

  //added 060921
  setTimeout(() => {
    setSubmitLoading(false)
    }
  )
    // reset()
  }
}

  const buyCoinsSort = (arr) => {
    let a = []
    let b = []
    let c = []
    arr.map((i) => {
      return i.type === 'coin_bundle' ? a.push(i) : i.type === 'fk_prime' ? i.name.includes('Free') ? b.push(i) : c.push(i) : null
    })
    setBuyCoinsOffer(a)
    setFkPrimeFree(b)
  }

  const fkPurchasedSorter = (arr) => {
    let a = []
    let b = []
    let c
    let d = []
    const found = arr.some(el => el.type === 'live_listing' && el.item_id === null);
    arr.map((j) => {
      return j.type === 'live_listing' ? j.name.includes('Free') ? j.item_id === null ? [d.push(j),a.push(j)] : a.push(j) : b.push(j) : null
    })
    if(found){
      c = false
    }else {
      c = true
    }
    // arr.map((k) => {
    //   return k.type === 'live_listing' ? k.item_id === null ? c = false : c = true : null
    // })

    setFkPurchased({['free']: a, ['paid']: b, ['noAvailableSlot']: c, ['free_remain']: d})
  }

  const purchaseCoin = (data, input) => {
    props.purchaseCoinLoad({ fkv2_purchased_data: { ...data, ...input }, userId: sessionId })
  }

  const coinReset = () => {
      props.purchaseCoinReset()
  }

  const handleBuyCoinsPay = (method) => {
    setUserBoost({buyCoins: {...userBoost.buyCoins, ['payment_method']: method}})
  }

  const getServices = (active) => {
    if (servicesData.length === 0) {
      props.servicesLoad(active)
    }
  }

  const handleBuyCoinsStepsAction = (action) => {
    if(action === '-'){
      setBuyCoinsStep(1);
    }else{
      setBuyCoinsStep(2)
    }
  }

  const parsedLineItems = useCallback((arr, method) => {
    let data = []
    arr.map(item => {
        let obj = {...item, user_id: parseInt(sessionId), method: method}
        data.push(obj)
    })
    return data
}, [servicesData])


  const handleClaim = () => {
    if(isFreeClaimed){
        alert('Free subscription already claimed.')
    }
    else {
      const FKPrimeArr = fkPrimeFree && fkPrimeFree[0] ? fkPrimeFree[0] : []
        const boostDetailsObj = {
            "type": FKPrimeArr.type,
            "user_id": parseInt(sessionId),
            "coin": FKPrimeArr.credit ? parseInt(FKPrimeArr.credit) : 0,
            "id": FKPrimeArr.id ? parseInt(FKPrimeArr.id) : 0,
            "sg_price": 0,
            "description": FKPrimeArr.description,
            "method": "free",
            "currency": "sgd",
            "s_email": session_email,
            "name": FKPrimeArr.name,
            "customer": "",
        }
        const line_items_data = parsedLineItems([boostDetailsObj], 'free')
        const boostData = {payment_method: 'free', type: 'fk_prime', total_amount: 0, line_items_detail: line_items_data }
        purchaseCoin(boostData, { order_id: null })
    }
  }

  const handleMaybeLater = () => {
    setFreeNotClaim(false)
    setTimeout(()=> {
      window.location.reload();
      setFKPrimeClaim(!fKPrimeClaim)
    },900)  
  }
  const clearData = () => {
    setUserInput(
      {
        ['i_price']: '',
        ['s_title']: '',
        ['s_description']: '',
        ['s_category']: '',
        ['fk_i_category_id']: 0,
        ['item_condition']: '',
        ['captcha']: '',
        ['s_dm_shipped_details']: '',
        ['taDetails']: [],
        ['hlDetails']: [],
        ['boost']: '',
        ['boostTotal']: 0,
        ['subBoost']: 0,
        ['s_dm_meetup_add1']: '',
        ['s_dm_meetup_add2']: '',
        ['subHighlight']: 0,
        ['post_type']: ''
      }
    )
  }
  const reset = () => {
    setImageData([])
    clearData()
    setSubmitLoading(false)

  }
  const handlePremiumClick = () => {
    if (!premiumClick) {
      setUserInput({ ['subBoost']: dPremiumId[0].id, ['taDetails']: dPremiumId[0], ['boostTotal']: parseInt(dPremiumId[0].credit)})
      setPremiumClick(true)
    } else {
      setUserInput({ ['subBoost']: 0, ['taDetails']: [] })
      setPremiumClick(false)
    }
  }
  const handleHighlightClick = () => {
    if (!highlightClick) {
      setUserInput({ ['subHighlight']: dHighlightId[0].id, ['hlDetails']: dHighlightId[0], ['highlightTotal']: parseInt(dHighlightId[0].credit)})
      setHighlightClick(true)
    } else {
      setUserInput({ ['subHighlight']: 0, ['hlDetails']: [], ['highlightTotal']: 0})
      setHighlightClick(false)
    }
  }

  const handleTotal = (type, total, boost) => {
    if (type === 'premium') {
      setUserInput({
        ['boostTotal']: total, ['taDetails']: boost, ['subBoost']: boost.id
      })
    }
    if (type === 'highlight') {
      setUserInput({
        ['highlightTotal']: total,
        ['hlDetails']: boost,
        ['subHighlight']: boost.id
      })
    }
  }

  const removeAddress = (address) => {
    if (address === 'address1') {
      setUserInput(
        {
          ['s_dm_meetup_add1']: '',
          ['s_dm_meetup_add2']: userInput.s_dm_meetup_add2,
          ['totalLocation']: userInput.s_dm_meetup_add2 || userInput.s_dm_meetup_add1 ? userInput.totalLocation - 1  : 0,
          ['meetupAddnotesAddress1']: ''
        })
    } else {
      setUserInput(
        {
          ['s_dm_meetup_add1']: userInput.s_dm_meetup_add1,
          ['s_dm_meetup_add2']: '',
          ['totalLocation']: userInput.s_dm_meetup_add2 || userInput.s_dm_meetup_add1 ? userInput.totalLocation - 1  : 0,
          ['meetupAddnotesAddress2']: ''
        })
    }
  }

  const handleMeetupAddNote = (type) => {
    if(type === 'ad0'){
      setUserInput({['meetupAddnotesAddress1']: ''})
    }else{
      setUserInput({['meetupAddnotesAddress2']: ''})
    }
  }

  const handleAnotherListItem = () => {
    setAnotherListing(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.querySelector('body').scrollTop = 0;
    getServices(true)
  }, [])

  useEffect(() => {
    if(servicesData.length !== 0){
      buyCoinsSort(servicesData)
    }
  }, [servicesData])

  useEffect(() => {
    if(updateItemData){
      setIsFromEdit(true)
    }
  })
  useEffect(() => {
    if(meData.length === 0){
      props.meLoad({id: sessionId})
    }
  },[meData])

  useEffect(() => {
    if (publishData) {
      const item_id = publishItemData.pk_i_id ? publishItemData.pk_i_id : 0
      if (userInput.taDetails.length !== 0) {
        const mergeTaPromoteData = { ...userInput.taDetails, item_id: item_id }
        submitPromote(mergeTaPromoteData)
      }
      if (userInput.hlDetails.length !== 0) {
        const mergeHlPromoteData = { ...userInput.hlDetails, item_id: item_id }
        submitPromote(mergeHlPromoteData)
      }

      setSubmitLoading(true)
      setTimeout(() => {
        const category = publishItemData && publishItemData.oc_t_category && publishItemData.oc_t_category.oc_t_category_descriptions && publishItemData.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(publishItemData.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
        const item_title = publishItemData && publishItemData.oc_t_item_description && publishItemData.oc_t_item_description.s_title ? publishItemData.oc_t_item_description.s_title : ''
        const itemSLug = slugify(item_title)
        const finalSLug = `/${category}/${itemSLug}_i${item_id}`
        const postData = {...publishItemData, live_items: live_items, isFreeClaim: isFreeClaimed, fkPurchase: fkPurchased}

        setPostIsSubmitted(true)

        // console.log("postData",postData, publishItemData)

        if (publishData.includes("moderation")) {
          window.location.replace(`${process.env.REACT_APP_BASE_URL}user/listing`);
        } else {
          setUserInput({ ['itemLink']: finalSLug, ['submitItemDetails']: postData, ['captcha']: '' })
          setSubmitLoading(false)
        }
        reset()
        props.createItemReset()
      }, 2000)
    }
    if (publishError) {
      setTimeout(() => {
        // reset()
        // props.createItemReset()
        window.location.replace(`${process.env.REACT_APP_BASE_URL}user/item/new`)
      }, 3000)
    }
    // eslint-disable-next-line
  }, [publishData, publishError])

  useEffect(() => {
    if(imageData.length === 0) {
      window.scrollTo(0, 0);
    }
  })

  useEffect(() => {
    if(purchaseSuccessMessage || errorPurchaseCoin) {
      if(!fKPrimeClaim && parseInt(fkPurchased.free_remain.length) === 5){
        setFKPrimeClaim(true)
      }
      setTimeout(() => {
        setIsCoinsClick(false)
        coinReset();
      },3000)
    }
    if(isLoadingPurchaseCoin) {
      setInitialLoader(false)
    }
  }, [purchaseSuccessMessage, errorPurchaseCoin, isLoadingPurchaseCoin])

  useEffect(() => {
    if(fkPrimeFree.length !== 0) {
        if(!isFreeClaimed && fkPurchased.free.length === 0) {
          setFreeNotClaim(true)
        }
            if(isFreeClaimed) {
              setFreeNotClaim(false)
            }
    }
  },[fkPurchased.free, isFreeClaimed, props.me.data])

  useEffect(() => {
    if(fkPurchasedArr.length !== 0){
      fkPurchasedSorter(fkPurchasedArr)
    }
  },[fkPurchasedArr])

  useEffect(() => {
    // if (cancelClick) {
    //   setModalPops(true)
    // }
    if (anotherListing) {
      setSubmitted(false)
      reset()
      window.location.reload(false)
    }
  }, [anotherListing])

  return (
   
    <ItemNewContext.Provider value={{
      isLoading, sessionData, publishLoading, publishData, publishError, handleChangeStatus, servicesData, premiumClick, handlePremiumClick,
      dPremiumId, dHighlightId, handleTotal, highlightClick, handleHighlightClick,
      updateItemData, purchaseCoin,buyCoinsOffer,  coinReset, isCoinsClick, setIsCoinsClick, userInput, setUserInput,
      userBoost, session, buyCoinStep, handleBuyCoinsStepsAction, offerSelected: userBoost.buyCoins,
      wallet, handleBuyCoinsPay, handleBuyCoins, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, imageData, setImageData,
      initialLoader, setInitialLoader, isFreeClaimed, handleClaim, fKPrimeClaim, freeNotClaim, fkPurchased, premiumAccount,

      postTypeArr, onKeyDownInput, handleChange, handleDesc, submitted, color,desc, handleShippedClick, meetupClicked,
      isMeetupAddNotes2, setIsMeetupAddNotes2, isMeetupAddNotes, removeAddress, handleMeetupAddNote, handleChangeMap, captchaChange,
      handleSubmit, modalPops, setModalPops,

      handleAnotherListItem, postSubmitted, submitLoading
    }}>
      {
          isLoadingPurchaseCoin || me.isLoading ? <WholePageLoader /> :  
          <div className={`flex-1 h-mn-100 ${window.innerWidth < 600 ? `h-100` : ``}`}>
          {
            !isFromEdit && window.innerWidth < 600 ? <MobileNew props={props}/> : !isFromEdit ? 
            <ItemNewWeb props={props}/> : <EditIndex data={updateItemData} indexProps={props}/>
          }
        </div>
        }
    {
      isCoinsClick ? <div className="p-fixed bg-black-op-p3 top-0 left-0 w-100 h-mn-100 z-ndx-5 h-100 flex flex-justify-center flex-align-center">
        <Modal />
      </div> : null
    }
    {
    // fKPrimeClaim ? 
    //   <div className="free-slot-modal-success-main-body-container">
    //     <FreeSlotSuccessClaimModal handleBtn={handleMaybeLater}/>
    //   </div> : null
    }
    </ItemNewContext.Provider>
  )
}

const mapStateToProps = store => {
  return {
    me: store.userReducer,
    create: store.createItemReducer,
    purchase: store.purchaseReducer,
    microService: store.microServiceReducer
  };
};

const mapActionToProps = {
  meLoad,
  servicesLoad,
  purchaseCoinLoad,
  purchaseCoinReset,
  createItemLoad,
  promoteLoad,
  createItemReset
}


export default connect(mapStateToProps, mapActionToProps)(memo(ItemNew))