import React from 'react'


const CommentSection = () => {
    return (
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Comments</h3>
            <section className="grid">
            <article>
                <div style={{minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <p style={{textAlign: 'center'}}>Not yet available.</p>
                </div>
            </article>
            </section> 
        </section> 
    )
}

export default CommentSection