import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminSaleSuccess, adminSaleFailed, adminSaleDetailSuccess, adminSaleDetailFailed, adminSaleSuggestSuccess, adminSaleSuggestFailed } from "../../action";
import admin from '../../../graphql/admin/sale'
import { theme } from '../../../common/theme'

//fetch sale
function* adminSales(action) {
    const { payload } = action
    try{
        const result = yield admin.getSales(payload)
        if(result === 403) {
            yield put(adminSaleFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getAllPayment ? result.data.getAllPayment : {}
            yield put(adminSaleSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminSaleFailed(errorMessage))
    }
}

export function* watchAdminSales() {
    yield takeLatest(ADMIN.SALE.LOAD, adminSales);
}

//fetch sale Detail
function* adminGetSaleDetail(action) {
    const { payload } = action

    try{
        const result = yield admin.getSaleDetail(parseInt( payload.id))
        if(result === 403) {
            yield put(adminSaleDetailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getPayMentDetail && result.data.getPayMentDetail ? result.data.getPayMentDetail : {}
            yield put(adminSaleDetailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminSaleDetailFailed(errorMessage))
    }
}

export function* watchAdminGetSaleDetail() {
    yield takeLatest(ADMIN.SALE_DETAIL.LOAD, adminGetSaleDetail);
}

//suggest sale
function* adminSuggestSale(action) {
    const { payload } = action
    try{
        const result = yield admin.searchSaleForSuggest(payload)
        if(result === 403) {
            yield put(adminSaleSuggestFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.searchSale ? result.data.searchSale : []
            yield put(adminSaleSuggestSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminSaleSuggestFailed(errorMessage))
    }
}

export function* watchAdminSuggestSale() {
    yield takeLatest(ADMIN.SALE_SUGGEST.LOAD, adminSuggestSale);
}