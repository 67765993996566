import React, { useState, useEffect, useCallback } from 'react'
import ImageGallery from 'react-image-gallery';
import ItemTag from '../../../../../../../common/component/tags'

const { REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX, REACT_APP_S3_CLASSIFIED, REACT_APP_S3_CLASSIFIED_FOLDER } = process.env;

const ViewItem = (props) => {
    const item = props.data
    const image = item && item.oc_t_item_resource ? item.oc_t_item_resource : []
    const session = props.session ? props.session : 0
    const seller_id = item && item.oc_t_user && item.oc_t_user.pk_i_id ? item.oc_t_user.pk_i_id : 0
    const [imageArr, setImageArr] = useState([])
    const [showEdit, setShowEdit] = useState(false)
    const defaultItemSrc = `${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/uploads/84612.jpeg`
    const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
    const reserved = item && item.b_reserved ? item.b_reserved : false
    const isNew = item.is_new ? item.is_new : false


    useEffect(() => {
        imageHandler(image)
    }, [image])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const imageHandler = useCallback(
        (image) => {
        let arr = []

        if (image && image.length > 0) {
            image.map((img, index) => {
                const id = img.pk_i_id
                const ext = img.s_extension
                const path = 'uploads'
                const imgSrc = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}` : `${REACT_APP_IMGX}${id}.${ext}?w=640&h=480&fit=fill&fill=solid&fill-color=f5f3f2`
                const thumbImgSrc = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}?w=30&h=30` : `${REACT_APP_IMGX}${id}.${ext}?w=93&h=93&fit=crop&crop=focalpoint&fp-z=1&auto-true`
                const imgRaw = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/uploads/${id}.${ext}` : `${REACT_APP_IMGX}${id}.${ext}`

                arr.push({raw: imgRaw, original: imgSrc, thumbnail: thumbImgSrc, thumbnailClass: 'featured-thumb', id: id, ext: ext})
            })
            setImageArr(arr)
        }
        return null
        }, [image]
    )

    const renderItemTags = useCallback(
        () => {
            return <ItemTag sold={sold} reserved={reserved} />
    },[sold,reserved ])

    return (
        <div className="itemMain">
            <div className="card">
                <div className="cardImage">
                    {
                        image.length === 0 ?  
                            <div> 
                                {session === seller_id ? <div className="edit-btn-container"><button onClick={() => setShowEdit(true)} className="edit-btn"><i className="fas fa-camera" /></button></div> : null }  
                                <img src={defaultItemSrc} alt="item view" width="640" height="480" /> 
                                <div style={{position: 'relative', bottom: 10}}>
                                    {renderItemTags()}
                                </div>
                            </div>
                            : 
                            <div style={{position: 'relative'}}>
                                { isNew ? <div className='item-detail-new-ribbon-contaier'><p className='item-detail-new-label-text'>New</p></div> : null}
                                {session === seller_id ? <div className="edit-btn-container"><button onClick={() => setShowEdit(true)} className="edit-btn"><i className="fas fa-camera" /></button></div> : null }
                                <ImageGallery items={imageArr} autoPlay={false} showThumbnails={true} thumbnailPosition="bottom" showNav={false} showFullscreenButton={false} showPlayButton={false} renderCustomControls={renderItemTags}/>
                            </div>
                    }
                </div>
                <div className="action">
                </div>
            </div>
        </div>
    )
}

export default ViewItem;