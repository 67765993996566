import { SEARCH_WORD, SEARCH_WORD_MORE, ADD_TOP_SEARCH } from '../constant'

export const searchWordLoad = (payload) => {
    return {
        type: SEARCH_WORD.LOAD,
        payload
    }
}

export const searchWordSuccess = (payload) => {
    return {
        type: SEARCH_WORD.SUCCESS,
        payload
    }
}

export const searchWordFail = (payload) => {
    return {
        type: SEARCH_WORD.FAIL,
        payload
    }
}

export const searchWordReset = () => {
    return {
        type: SEARCH_WORD.RESET,
    }
}

export const searchWordMoreLoad = (payload) => {
    return {
        type: SEARCH_WORD_MORE.LOAD,
        payload
    }
}

export const searchWordMoreSuccess = (payload) => {
    return {
        type: SEARCH_WORD_MORE.SUCCESS,
        payload
    }
}

export const addTopSearch = (payload) => {
    return {
        type: ADD_TOP_SEARCH.LOAD,
        payload
    }
}