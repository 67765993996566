import React, { useEffect, useReducer, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { numberWithCommas, slugify, categoryParser } from '../../../../common/helper/utility'
import FkImage from '../../../../common/component/image'
import { PromoteContext } from '../../../../common/context';
import SocialAvatar from '../../../../common/component/socialAvatar'
import DefaultPhoto from '../../../../assets/images/defaultAvatar.png'
import { MobileMessageIcon, HeartIcon, ReportIcon, LightningIcon } from '../../../../assets/svg'
import DefaultItem from '../../../../assets/images/commerce-and-shopping.png'
import HomeItemTag from '../../../../common/component/tags/HomeItemTag'
import LikeButton from '../../../../common/component/like'
import {WTB, WTT} from '../../../../common/component/label'
import { GAevent } from '../../../../common/googleAnalytics'

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'topAd':
      return { ...state, isTopAd: true }
    case 'highlight':
      return { ...state, isHighlight: true }
    case 'auto_bump':
      return { ...state, isAutuBump: true }
    case 'toggleShow':
      return { ...state, isShow: !state.isShow }
    default:
      break;
  }
}

const ItemCard = (props) => {
  const value = useContext(PromoteContext);
  const { togglePromoteShow, setReportShow, handleReportSubmit, setReportInput, toggleSoldPromoteModal } = value
  const item = props && props.item ? props.item : []
  const likeDislike = props &&  props.likeDislike ? props.likeDislike : null
  const likeDislikeLoading = props &&  props.likeDislikeLoading ? props.likeDislikeLoading : null
  const hl_expiration = props &&  props.hl_expiration ? props.hl_expiration : null
  const ta_expiration = props &&  props.ta_expiration ? props.ta_expiration : null 
  const ab_expiration = props &&  props.ab_expiration ? props.ab_expiration : null
  const images = item && item.oc_t_item_resource ? item.oc_t_item_resource : []
  const price = item && item.get_i_price ? numberWithCommas((item.get_i_price).toFixed(2)) : item && item.i_price ? numberWithCommas((item.i_price/1000000).toFixed(2)) : 0
  const id = item && item.pk_i_id ? item.pk_i_id : ''
  const category = item && item.oc_t_category && item.oc_t_category.oc_t_category_descriptions && item.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(item.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
  const contactName = item && item.oc_t_user && item.oc_t_user.s_username ? item.oc_t_user.s_username : ''
  const session_id = props && props.session && props.session.fkv2_session && props.session.fkv2_session.id ? parseInt(props.session.fkv2_session.id) : null
  const user_id = item && item.fk_i_user_id ? item.fk_i_user_id : ''
  const item_title = item.oc_t_item_description && item.oc_t_item_description.s_title ? item.oc_t_item_description.s_title : ''
  const user_resource = props.item && props.item.oc_t_user && props.item.oc_t_user.oc_t_user_resource ? props.item.oc_t_user.oc_t_user_resource : null
  const date_published = item && item.dt_pub_date ? item.dt_pub_date : null
  const formatted_date = date_published ? moment(date_published).format('DD MMM YYYY - hh:mm A') : null
  const user_social_id = props.item && props.item.oc_t_user && props.item.oc_t_user.social && props.item.oc_t_user.social[0]
  && props.item.oc_t_user.social[0].data && props.item.oc_t_user.social[0].data.id ? props.item.oc_t_user.social[0].data.id : ''
  const user_social_resource = props.item && props.item.oc_t_user && props.item.oc_t_user.social && props.item.oc_t_user.social[0]
    && props.item.oc_t_user.social[0].data && props.item.oc_t_user.social[0].data.picture && props.item.oc_t_user.social[0].data.picture.data
    && props.item.oc_t_user.social[0].data.picture.data.url ? props.item.oc_t_user.social[0].data.picture.data.url : null

  const report = useRef(null)
  const reportMobile = useRef(null)
  const [state, dispatch] = useReducer(itemReducer, { isTopAd: false, isHighlight: false, isShow: false })
  const watchlist_count = item && item.watchlist_count ? item.watchlist_count : 0
  const watchlist = item && item.watchlist ? item.watchlist : []
  const [isLiked, setIsLiked] = useState(false)
  const [clicked, setClick] = useState(false)
  const [reportId, setReportId] = useState(0)
  const [reportBtnClicked, setReportBtnClicked] = useState(false)
  const sold = item && item.b_mark_as_sold ? item.b_mark_as_sold : item.dt_sold ? true : false
  const reserved = item && item.b_reserved ? item.b_reserved : false
  
  const item_description = item.oc_t_item_description && item.oc_t_item_description.s_description ? item.oc_t_item_description.s_description.substring(0, 100) : ''
  const cover_photo = item && item.oc_t_item_resource && item.oc_t_item_resource[0] && item.oc_t_item_resource[0].s_extension ? `${item.oc_t_item_resource[0].pk_i_id}.${item.oc_t_item_resource[0].s_extension}` : null

  const itemSLug = slugify(item_title)
  const finalSLug = `/${category}/${itemSLug}_i${id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`

  const post_type = item && item.post_type ? item.post_type : ''
  const isNew = item.is_new ? item.is_new : false

  const handleClickOutside = event => {
    if (report.current && !report.current.contains(event.target)) {

      setReportBtnClicked(reportBtnClicked ? false : true)
    }
  }

  const handleClickMobileOutside = event => {
    if (reportMobile.current) {
      setClick(false)
    }
  }
  // const toggleIsShow = () => {
  //   dispatch({type: 'toggleShow'})
  // }
  const handleClick = (index) => {
    setClick(index)
  }

  const handleReportClick = (id, user_id) => {
    setClick(false)
    setReportShow(true)
    setReportInput({
      reported_id: id,
      reported_user_id: user_id
    })
  }

  const handleReportBtnClicked = (id) => {
    setReportBtnClicked(!reportBtnClicked)
    setReportId(id)
  }
  const reportedWeb = (id) => {
    setReportShow(true)
    setReportInput({
      reported_id: id
    })
  }
  useEffect(() => {
    const now = new Date()
    const parsedNow = moment(now).format('YYYY-MM-DD HH:mm:ss')

    if (ta_expiration) {
      const isTopAd = ta_expiration ? moment(ta_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isTopAd && isTopAd > parsedNow) {
        dispatch({ type: 'topAd' })
      }
    }

    if (hl_expiration) {
      const isHighlight = hl_expiration ? moment(hl_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isHighlight && isHighlight > parsedNow) {
        dispatch({ type: 'highlight' })
      }
    }

    if (ab_expiration) {
      const isAutuBump = ab_expiration ? moment(ab_expiration).format('YYYY-MM-DD HH:mm:ss') : false
      if (isAutuBump && isAutuBump > parsedNow) {
        dispatch({ type: 'auto_bump' })
      }
    }


    
    // eslint-disable-next-line  
  }, [item, hl_expiration])

  useEffect(() => {
    if (watchlist_count > 0) {
      const watcher = item.watcher
      if (watcher.includes(session_id)) {
        setIsLiked(true)
      } else {
        setIsLiked(false)
      }
    }
    else {
      setIsLiked(false)
    }
    // eslint-disable-next-line  
  }, [item.watcher])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  useEffect(() => {
    document.addEventListener("click", handleClickMobileOutside, true);
    return () => {
      document.removeEventListener("click", handleClickMobileOutside, true);
    };
  });

  const toggleLiked = (item_id) => {
    if (session_id) {
      likeDislike(item_id)
      setClick(!clicked)
    }

  }

  const messageClick = () => {
    if (session_id) {
      GAevent("user", "message")
    }
  }

  return (
    <div className={`home-item-card regular`} >
      <div style={{ padding: '6px 8px', paddingTop: 8, width: '100%', position: 'relative', }}>
        {
          state.isTopAd ? sold ? null :
            <div id="ribbon-container">
              <p >Premium</p>
            </div>
            : null
        }
        <Link to={session_id === user_id ? `/user/listing` : `/user/${user_id}/listing`} style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
          { user_social_resource ?
            <SocialAvatar id={user_social_id} url={user_social_resource}/>
            : user_resource ? <FkImage images={[user_resource]} type="seller_avatar" alt="seller avatar" /> : <img src={DefaultPhoto} width={40} height={40} style={{ borderRadius: 20, display: 'flex', alignSelf: 'center'}} />
          }
          <div style={{ margin: `4px `, display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', justifyContent: 'center' }}>
          <p className="item-seller">
            <span>{`${contactName} `}</span>
            <span style={{display: 'inline-block !important'}}>{session_id === user_id && state.isAutuBump ? <LightningIcon/> : null}</span>
          </p>
            {/* <p>{session_id === user_id && state.isAutuBump ? 'bump' : null}</p> */}
            <p style={{ fontSize: window.innerWidth < 600 ? '9.5px' : 12, fontFamily: 'Metropolis-Regular', fontStyle: 'normal', letterSpacing: '0.44px', color: '#c4c4c4' }}>{`${formatted_date ? formatted_date : null}`}</p>
          </div></Link></div>
      <div style={{ border: state.isTopAd ? sold ? 'none' : '0.5px solid #FB770D' : 'none', width: '100%', height: '100%', borderRadius: '6px', background: state.isHighlight ? sold ? '#FFFFFF' : '#FFF1E5' : '#FFFFFF' }}>
        <Link to={finalSLug} style={{ width: '100%' }}>

          <div className="itemcard-image-container">
            {
              images.length !== 0 ?
                <>
                  <FkImage images={images} type="thumbnail" isNew={isNew} />
                  <HomeItemTag sold={sold} reserved={reserved} />
                </> :
                <div style={{ background: '#bdc3c7', background: '-webkit-linear-gradient(to right, #bdc3c7, #2c3e50)', background: 'linear-gradient(to right, #bdc3c7, #2c3e50)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={DefaultItem} width={100} height={100} />
                  <HomeItemTag sold={sold} reserved={reserved} />
                </div>
            }

          </div>
          <div style={{ flex: 1, paddingLeft: 8, paddingRight: 8 }}>
            <div className="flex flex-justify-between w-100 flex-align-centers pt-6">
              <p className="itemcard-item-price">{`${price} $`}</p>
              {
                post_type ? post_type === 'WTS' ? null :
                <div className={`${window.innerWidth < 500 ? `` : ``}`}>{
                post_type === 'WTB' ? <WTB /> : <WTT /> }</div>: null
              }
            </div>
            <p className='itemcard-item-title'>{item_title}</p>
          </div>

        </Link>
        <div className="bottom-item-card-container">

          <div className="mediumToBigScreen" >
            <div style={{ borderBottom: '1px solid #C4C4C4', display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', paddingBottom: '8px', height: '34px' }}>
              {
                !session_id ?
                  <Link to={{ pathname: `/login` }} className="btn-modal-options unsession-like" >
                    <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} /> &nbsp;
                    <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 14, lineHeight: '14px', letterSpacing: '0.0044em', color: '#232A34' }}>{watchlist_count === 0 ? null : watchlist_count}</p>
                  </Link> :
                  session_id && session_id === user_id ?
                    null : <>
                      <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} /> &nbsp;
                    <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 14, lineHeight: '14px', letterSpacing: '0.0044em', color: '#232A34' }}>{watchlist_count === 0 ? null : watchlist_count}</p>
                    </>

              }
              <div className="w-100 flex flex-justify-center flex-align-center"></div>
              {
                session_id !== user_id ? session_id ?
                  <div style={{ color: '#232A34', cursor: 'pointer' }} className="report-icon" onClick={() => handleReportClick(id, user_id)} ><ReportIcon /></div>
                  : null : null
              }
            </div>
            {
              // sold || reserved ? null :
              session_id !== user_id ?
                <div className="flex p-6 flex-justify-center">
                  <Link to={{
                    pathname: !session_id ? `/login` : `/user/messages/inbox`,
                    state: { fromMessage: true, item: item }
                  }}
                    onClick={messageClick}
                    className="btn-modal-options" style={{ color: '#232A34' }}>Message
                </Link>
                </div>
                : <div className="flex p-8 flex-justify-center">
                  <button className='itemcard-item-promote-button' onClick={() => sold ? toggleSoldPromoteModal() : togglePromoteShow(item)}>Promote</button>
                </div>
            }
          </div>
          {/* {
            reportBtnClicked !== false ?
              session_id ?
                reportId === id ?
                  <div className="report-container" onClick={() => handleReportClick(id)} ref={report} >
                    <button style={{ textAlign: 'center' }} >Report Listing</button>
                  </div> : null : null
              : null
          } */}
          <div>
            {
              session_id !== user_id ?
                <div className="likeContainerMobile">
                  <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} /> &nbsp;
                   <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 14, lineHeight: '14px', letterSpacing: '0.0044em', color: '#232A34' }}>{watchlist_count === 0 ? null : watchlist_count}</p>
                </div>
                : null
            }
          </div>
          {
            window.innerWidth < 600 ?
              <div style={{ width: '100% ', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {session_id !== user_id ?
                  <Link to={{
                    pathname: !session_id ? `/login` : `/user/messages/inbox`,
                    state: { fromMessage: true, item: item },
                  }}
                    onClick={messageClick}
                  >
                    <MobileMessageIcon />
                  </Link> : <div style={{ width: '100%', borderTop: '0.5px solid #C4C4C4' }}><div style={{ display: 'flex', padding: '8px', justifyContent: 'center' }}>
                    <button className='itemcard-item-promote-button' onClick={() => sold ? toggleSoldPromoteModal() : togglePromoteShow(item)} style={{ color: '#FB770D' }}>Promote</button>
                  </div></div>
                }
              </div> : null
          }
          <div className="lowScreen">
            {
              session_id !== user_id ? session_id ?
                <div style={{ color: '#232A34', cursor: 'pointer' }} id="report-icon" onClick={() => handleReportClick(id, user_id)} ><ReportIcon /></div>
                : null : null
            }
          </div>
          {/* <div className={`modal-on-item ${clicked === id ? `flex` : `hide`}`}>
            <div className="modal-close-btn" onClick={handleClick}>
              <i className="fas fa-times" />
            </div>
            <div className="modal-item-options">
              {
                session_id === user_id ?
                  <div className="modal-options-flex">
                    <button className="btn-modal-options" onClick={() => sold ? toggleSoldPromoteModal() : togglePromoteShow(item)}>Promote</button>
                  </div>
                  :
                  <div className="modal-options-flex">
                    {
                      !session_id ?
                        <Link to={`/login`} className="btn-modal-options-report">Report</Link> :
                        <button className="btn-modal-options-report" onClick={() => handleReportClick(id)}>Report</button>
                    }
                    {
                      <Link to={{
                        pathname: !session_id ? `/login` : `/user/messages/inbox`,
                        state: { fromMessage: true, item: item }
                      }}
                        className="btn-modal-options">Message
                      </Link>
                    }
                  </div>
              }
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
};

export default ItemCard;