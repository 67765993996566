import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AdminCategoryContext } from '../../../../../common/context'
import { adminCategoryLoad, adminUpsertCategoryLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { CategoryList, CategoryModal } from './component/CustomComponent'


const CategorySection = (props) => {

    const { admincCategoryIsLoading, adminCategoryData, adminCategoryError, adminCategoryPage,
            adminUpsertCategoryIsLoading, adminUpsertCategoryError, adminUpsertCategorySuccess, adminCategorySelection
    } = props.admin

    useEffect(() => {
        if (adminCategoryData && adminCategoryData.length === 0) {
            props.adminCategoryLoad({first: 20, page: adminCategoryPage})
        }
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if (adminUpsertCategorySuccess) {
            setTimeout(() => {
                setIsShow(false)
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/category`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminUpsertCategorySuccess])

    const [indexToShow, setIndexToShow] = useState(0)
    const [showAll, setShowAll] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [categoryDetailData, setCategoryDetail] = useState({})

    const fetchCategoryDetail = (item) => {
        setIsShow(true)
        setCategoryDetail(item)
    }

    const toggleShowAll = () => {
        setShowAll(!showAll)
    }

    const closeItemModal = () => {
        setIsShow(false)
        setCategoryDetail({})
    }

    const updateItem = (evt, data) => {
        evt.preventDefault();
        props.adminUpsertCategoryLoad(data)
    }

    return (
        <AdminCategoryContext.Provider value={{ adminCategoryData, fetchCategoryDetail, indexToShow, setIndexToShow, showAll, categoryDetailData, isShow, closeItemModal,
            updateItem, adminUpsertCategoryIsLoading, adminUpsertCategoryError, adminUpsertCategorySuccess, adminCategorySelection
        }}>
            <section className="page-content">
                <h3 style={{textAlign: 'center'}}>Category</h3>
                <section>
                {admincCategoryIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                    adminCategoryError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminCategoryError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                            <div style={{display:'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div onClick={() => setIsShow(true)} className="sellButton pointer" style={{margin: 10, alignSelf: 'flex-start'}}>
                                    <span>Add Category</span>
                                </div>
                                <div className='margin-right-20 pointer' onClick={() => toggleShowAll()}>
                                    <p>{ showAll ? 'Hide All' : 'View All'}</p>  
                                </div>
                            </div>
                        <CategoryList />
                    </article>
                }
                </section> 
            </section> 
            {isShow ? <CategoryModal /> : null }
        </AdminCategoryContext.Provider>

    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminCategoryLoad,
    adminUpsertCategoryLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(CategorySection);