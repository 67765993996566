import React, {useContext} from 'react';
import { ReportContext } from '../../../../../../common/context'
import { theme } from '../../../../../../common/theme'
import Loader from '../../../../../../common/component/loader'

export const ReportsList = () => {
    const value = useContext(ReportContext)
    const { activeTab, setActiveTab} = value
    const tabs = theme.text.reportTabs

    return (
        <div className={'list-container'}>
            {tabs.map((item, index) => {
                    return <button key={index} className={activeTab === index ? 'list-tab-button-active' : 'list-tab-button'} onClick={() => setActiveTab(index)}></button>
            })}
            { activeTab === 0 ? <ItemList /> : <UserList /> }
        </div>
    )
}

export const ItemList = () => {
    const value = useContext(ReportContext)
    const { adminReportedItemIsLoading, adminReportedItemError, adminReportedItemData, gotoItem, gotoUser, reportedId } = value
    return (
            <div className={'list-sub-container'}>
            {
                adminReportedItemIsLoading ? <Loader /> :
                adminReportedItemError ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><p className='error-message'>{adminReportedItemError}</p></div> : 
                adminReportedItemData && adminReportedItemData.length === 0 ? <p style={{textAlign: 'center'}}>No report found</p> : 
                <div className='purchase-container'>
                    <table style={{fontSize: 13}}>
                        <tbody>
                            <tr>
                                <th>Reporter</th><th>Reported Item</th><th>Item Status</th><th>Reported User</th><th>User Status</th><th>Reason</th><th>Type</th><th>Date</th>
                            </tr>
                            {adminReportedItemData.map((item, index) => {
                                const itemTitle = item && item.reported_item && item.reported_item.oc_t_item_description && item.reported_item.oc_t_item_description.s_title ? item.reported_item.oc_t_item_description.s_title : ''
                                const reporter = item && item.reported_by && item.reported_by.s_username ? item.reported_by.s_username : ''
                                const reason = item && item.report_reason ? item.report_reason : ''
                                const isActive = item && item.reported_item && item.reported_item.b_active ? item.reported_item.b_active : false
                                const itemId = item && item.reported_item && item.reported_item.pk_i_id ? item.reported_item.pk_i_id : 0
                                const type = item && item.report_type ? item.report_type : ''
                                const reportedUser = item && item.reported_item && item.reported_item.oc_t_user && item.reported_item.oc_t_user.s_username ? item.reported_item.oc_t_user.s_username : ''
                                const isUserActive = item && item.reported_item && item.reported_item.oc_t_user && item.reported_item.oc_t_user.b_active ? item.reported_item.oc_t_user.b_active : false
                                const reportedUserId = item && item.reported_item && item.reported_item.oc_t_user && item.reported_item.oc_t_user.pk_i_id ? parseInt(item.reported_item.oc_t_user.pk_i_id) : ''

                                return (
                                    <tr style={{backgroundColor: itemId === reportedId ? 'silver' : null}} className='tr-data-container' key={index} >
                                        <td>{reporter}</td>
                                        <td className='pointer' onClick={()=> gotoItem(itemId)}>{itemTitle}</td>
                                        <td className={isActive ? 'success-message' : 'error-message'}>{isActive ? 'Active' : 'Not Active'}</td>
                                        <td className='pointer' onClick={()=> gotoUser(reportedUserId)}>{reportedUser}</td>
                                        <td className={isUserActive ? 'success-message' : 'error-message'}>{isUserActive ? 'Active' : 'Not Active'}</td>
                                        <td>{reason}</td>
                                        <td>{type}</td>
                                        <td>{item.created_at}</td>
                                    </tr>
                                )                        
                            })}
                        </tbody>    
                    </table>
                </div>
            }
            </div>
    )
}

export const UserList = () => {

    return (
            <div className={'list-sub-container'}>
                <p style={{textAlign: 'center'}}>No data yet.</p>
            </div>
    )
}