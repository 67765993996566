import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/fk_logo.png'
import {FKWhiteBGLogo} from '../../../assets/svg'

const Logo = (props) => {
    const { clickLogoHandler } = props

    return (
        <div className="webBrand">
            <div className="webLogo">
              <Link to="/" onClick={() => clickLogoHandler()}>
                  <FKWhiteBGLogo />
              </Link>
            </div>
        </div>
    );
};


export default Logo;