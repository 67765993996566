import React from 'react'

const WebChatFilter = () => {
    return (
        <div className="chat-filter">
            <span>Inbox</span> <i className="fas fa-chevron-down" style={{visibility: 'hidden'}}/>
        </div>
    )
}

export default WebChatFilter