import React from 'react';
import {NewLabelIcon} from '../../../assets/svg'

const {
    REACT_APP_S3_CLASSIFIED,
    REACT_APP_S3_CLASSIFIED_FOLDER,
    REACT_APP_ENV,
    REACT_APP_IMGX,
    REACT_APP_IMGX_PROFILE,
    REACT_APP_GRAPHQL_SERVER_BASE_URL,
    REACT_APP_IMGX_REVIEW
} = process.env;

const FkImage = (props) => {
    const images = props.images
    const defaultItemSrc = `${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/ui.png`
    // const defaultAvatarSrc = `${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/profile/saitama.jpg`
    const localAvatarSrc = `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/profile/`
    const ImgSrc = REACT_APP_IMGX
    const { isNew, itemStatus, sold } = props

    // eslint-disable-next-line
    return images.map((item, index) => {
        if (index === 0 && images.length !== 0) {
        const id = item && item.pk_i_id ? item.pk_i_id : 0
        const ext = item && item.s_extension ? item.s_extension : 'jpg'
        const path = 'uploads'
        const type = props.type
        // const imgSrc = REACT_APP_ENV === 'local' ? defaultItemSrc : `${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/${path}/${id}.${ext}`
        const imgSrc = REACT_APP_ENV === 'local' ? defaultItemSrc : `${ImgSrc}${id}.${ext}`
        // const avatarSrc = REACT_APP_ENV === 'local' ? defaultAvatarSrc : `${REACT_APP_S3_CLASSIFIED}${REACT_APP_S3_CLASSIFIED_FOLDER}/profile/${id}.${ext}`
        const avatarSrc = REACT_APP_ENV === 'local' ? `${localAvatarSrc}${id}.${ext}` : `${REACT_APP_IMGX_PROFILE}${id}.${ext}`
        const imageSource = REACT_APP_ENV === 'local' ? `${REACT_APP_GRAPHQL_SERVER_BASE_URL}classifieds-local/${path}/${id}.${ext}` : `${ImgSrc}${id}.${ext}`
        const reviewSource = `${REACT_APP_IMGX_REVIEW}${id}.${ext}`

        let imgWidth, imgHeight
        let maxImgWidth, maxImgHeight
        let minHeight = 130
        let imgProperties

        //width switch
        switch (type) {
            case 'thumbnail':
                // imgWidth = 290
                imgWidth = 240
                // imgProperties = '&fit=fill&fill=solid&fill-color=ffffff'
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'success-thumbnail':
                imgWidth = 256
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'listing-thumbnail':
                imgWidth = 214
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'success-thumbnail-mobile':
                imgWidth = 256
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'submit-thumbnail':
                imgWidth = 256
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'like-image':
                imgWidth = 426
                imgProperties = '&fit=crop&fp-debug=false'
            break;

            case 'related-ads-thumbnail':
                imgWidth = 219
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'preview':
                imgWidth = 480
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'original':
                imgWidth = 640
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'avatar':
                imgWidth = 70
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'seller_avatar':
                imgWidth = 70
                maxImgWidth = 40
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'related_seller_avatar':
                imgWidth = 70
                maxImgWidth = 26
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;
            case 'desktop_seller_avatar':
                imgWidth = 60
                maxImgWidth = 50
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'avatar_preview':
                imgWidth = 110
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'avatar_preview_edit':
                imgWidth = 100
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'itemThumbnail':
                imgWidth = 150
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'item-message-inbox':
                imgWidth = 48
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'itemMiniImage':
                imgWidth = 238
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'promote-image':
                imgWidth = 60
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'chat-item-message-inbox':
                imgWidth = 83
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'chat-header-seller_avatar':
                imgWidth = 48
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'mobile-chat-header-seller_avatar':
                imgWidth = 36
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'chat-history-avatar':
                imgWidth = 36
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'review-item':
                imgWidth = 90
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            case 'review-attach-item':
                imgWidth = 90
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;

            default:
                imgWidth = 640
                imgProperties = '&fit=crop&fp-z=1&fp-debug=false&crop=focalpoint&auto=compress'
            break;
        }
        //height switch
        switch (type) {
            case 'thumbnail':
                imgHeight = 200
            break;

            case 'success-thumbnail':
                imgHeight = 279.27
            break;

            case 'listing-thumbnail':
                imgWidth = 214
            break;

            case 'success-thumbnail-mobile':
                imgHeight = 202
            break;

            case 'promote-image':
                imgHeight = 60
            break;

            case 'submit-thumbnail':
                imgHeight = 279.27
            break;

            case 'like-image':
                imgHeight = 320
            break

            case 'related-ads-thumbnail':
                imgHeight = 183
            break;

            case 'preview':
                imgHeight = 340
            break;

            case 'original':
                imgHeight = 480
            break;

            case 'avatar':
                imgHeight = 70
            break;
            case 'seller_avatar':
                imgHeight = 70
                maxImgHeight = 40
            break;

            case 'related_seller_avatar':
                imgHeight = 70
                maxImgHeight = 26
            break;

            case 'desktop_seller_avatar':
                imgHeight = 60
                maxImgHeight = 50
            break;

            case 'avatar_preview':
                imgHeight = 110
                minHeight = 'auto'
            break;

            case 'avatar_preview_edit':
                imgHeight = 100
                minHeight = 100
            break; 

            case 'itemThumbnail':
                imgHeight = 100
                minHeight = 100
            break;

            case 'item-message-inbox':
                imgHeight = 48
            break;

            case 'itemMiniImage':
                imgHeight = 195
                minHeight = 195
            break;

            case 'chat-item-message-inbox':
                imgHeight = 89
                minHeight = 89
            break;

            case 'chat-header-seller_avatar':
                imgHeight = 48
                minHeight = 48
            break;

            case 'mobile-chat-header-seller_avatar':
                imgHeight = 36
                minHeight = 36
            break;

            case 'chat-history-avatar':
                imgHeight = 36
                minHeight = 36
            break;


            case 'review-item':
                imgHeight = 90
                minHeight = 90
            break;

            case 'review-attach-item':
                imgHeight = 90
                minHeight = 90
            break;

            default:
                imgHeight = 480
                imgProperties = '&fit=crop&auto=compress'
            break;
        }

        return (
   
            
            type === 'avatar' || type === 'seller_avatar' || type === 'related_seller_avatar' || type === 'desktop_seller_avatar' || type === 'avatar_preview' || type === 'avatar_preview_edit' || type === 'chat-header-seller_avatar' || type === 'chat-history-avatar' ?
             <img key={index} src={type === 'avatar' || type === 'desktop_seller_avatar' || type === 'seller_avatar' || type === 'related_seller_avatar' || type === 'avatar_preview' || type === 'avatar_preview_edit' || type === 'chat-header-seller_avatar' || type === 'chat-history-avatar' ? 
                id !== undefined || ext !== undefined ? `${avatarSrc}` : `` : id !== undefined || ext !== undefined ? `${imgSrc}` : `${defaultItemSrc}`} style={  type === 'desktop_seller_avatar' || type === 'seller_avatar' || type === 'related_seller_avatar' ? { borderRadius: '50%', maxWidth: maxImgWidth, maxHeight: maxImgHeight, overflow: 'hidden'} : type === 'avatar' ? {} : {paddingTop: 0, minHeight: minHeight}} alt={`fishingkaki avatar`} width={imgWidth} height={imgHeight}/>:
                type === 'review-attach-item' ? 
                <div className='item-image-raw-container' key={index}>
                    <img key={index} src={images === "" || images.length === 0 ? `${defaultItemSrc}` : `${reviewSource}?w=${imgWidth}&min-w=50&h=${imgHeight}&min-h=50${imgProperties}`} />
                </div> : 
                type === 'itemMiniImage' || type === 'item-message-inbox' || type === 'promote-image' ?

                <div key={index}>
                    {/* { isNew ? <div className='new-ribbon-container'><p className='new-label-text'>New</p></div> : null} */}
                    {isNew ? <div className="p-absolute z-ndx-1 w-100 flex flex-justify-end flex-align-center top-0"><NewLabelIcon /></div>  : null}
                    <img key={index} src={images === "" || images.length === 0 ? `${defaultItemSrc}`: `${imageSource}?w=${imgWidth}&min-w=${imgWidth}&h=${imgHeight}&min-h=50${imgProperties}`} alt="fishingkaki item" loading="lazy"/>
                    {sold ? null : itemStatus === 'Active' ? null : <div className='is-pending-container'><p className='color-white'>{itemStatus}</p></div>}

                </div>
                
                :
                <div className='item-image-raw-container' key={index}>
                    {/* { isNew ? <div className='new-ribbon-container'><p className='new-label-text'>New</p></div> : null} */}
                    {isNew ? <div className="p-absolute z-ndx-1 w-100 flex flex-justify-end flex-align-center top-0"><NewLabelIcon /></div>  : null}
                    <img key={index} src={images === "" || images.length === 0 ? `${defaultItemSrc}`: `${imageSource}?w=${imgWidth}&min-w=40&h=${imgHeight}&min-h=50${imgProperties}`} alt="fishingkaki item" loading="lazy"/>
                    {sold ? null : itemStatus === 'Active' ? null : <div className='is-pending-container'><p className='color-white'>{itemStatus}</p></div>}
                </div>
    
            );
         
        }    
    })
};

export default FkImage;