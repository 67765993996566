import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminServiceSuccess, adminServiceFailed, adminUpsertServiceSuccess, adminUpsertServiceFailed, adminDeleteServiceSuccess, adminDeleteServiceFailed } from "../../action";
import admin from '../../../graphql/admin/service'
import { theme } from '../../../common/theme'

//fetch service
function* adminService(action) {
    const { payload } = action
    try{
        const result = yield admin.getServicesByType(payload)
        if(result === 403) {
            yield put(adminServiceFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.getServicesByType ? result.data.getServicesByType : {}
            yield put(adminServiceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminServiceFailed(errorMessage))
    }
}

export function* watchAdminService() {
    yield takeLatest(ADMIN.SERVICE.LOAD, adminService);
}

//upsert service
function* adminUpsertService(action) {
    const { payload } = action
    try{
        const result = yield admin.serviceUpsert(payload)
        if(result === 403) {
            yield put(adminUpsertServiceFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.serviceUpsert && result.data.serviceUpsert ? result.data.serviceUpsert : ''
            yield put(adminUpsertServiceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpsertServiceFailed(errorMessage))
    }
}

export function* watchAdminUpsertService() {
    yield takeLatest(ADMIN.SERVICE_UPSERT.LOAD, adminUpsertService);
}

//delete service
function* adminDeleteService(action) {
    const { payload } = action
    try{
        const result = yield admin.deleteService(payload)
        if(result === 403) {
            yield put(adminDeleteServiceFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.serviceDelete ? result.data.serviceDelete : ''
            yield put(adminDeleteServiceSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeleteServiceFailed(errorMessage))
    }
}

export function* watchAdminDeleteService() {
    yield takeLatest(ADMIN.SERVICE_DELETE.LOAD, adminDeleteService);
}