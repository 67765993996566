import React, {useContext} from 'react'
import {theme} from './theme'
import { ItemContext } from '../../../../../../../common/context'
import { theme as GlobalTheme } from '../../../../../../../common/theme'
import FkImage from '../../../../../../../common/component/image'
import {BackButton, CoinsIcon, InfoFilledIcon} from '../../../../../../../assets/svg'

export const TosModal = ({click}) => {
    const data = theme.TOS
    const lastUpdate = theme.lastUpdate
    const lister = (l) => {
        let listing
        listing = l.map((list) => <li>{list.text}</li>)
        return listing
    }
    const termsBody = (p, s) => {
        let body
        body = <p className="content-sentence"><span className="content-section">{s}</span> {p}</p>
        return body
    }
    return (
        <div style={{width: window.innerWidth < 600 ? '90%' : '50%', background: 'rgba(255, 255, 255,1.0)', borderRadius: 12, height: 'fit-content'}}>
           <div style={{padding: window.innerWidth < 600 ? 10 : 30}}>
             <div className="content-sub-container" style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row'}}>
                <div onClick={click} style={{cursor: 'pointer'}}>
                    <BackButton />
                 </div>
                 <h1 className="content-main-title" style={{width: '100%', textAlign: 'center'}}>{`Terms of Use`}</h1>
                 <div></div>
             </div>
             <div className="content-body">
                 <h1 className="content-last-update">{`Last updated: ${lastUpdate}`}</h1>
                 { data.map((terms, index) => {
                     const bodyContent = terms.body 
                     const bodyCheck = bodyContent.map((data) => data.type === 'sentence' ? termsBody(data.text, data.section) : <div><ul className="content-list">{lister(data.body)}</ul></div>)
                     return <div key={index}>
                         <h1 className="content-title">{terms.title}</h1>
                         <div>
                             {bodyCheck}
                         </div>
                     </div>
                 })}
             </div>
         </div>
        </div>
    )
}

export const BaseAdsContainer = ({servicesData, handleItemSelect, adsTotal}) => {

    const Divider = () => {
        return (
            <div className="flex flex-align-center flex-justify-center">
                <div className="divider-ind-ads-container" />
            </div>
        )
    }

    return (
        <div className="base-ads-option-main-container">
        <div className="ind-ads-option-header-container">
            {
                window.innerWidth < 900 ? <p className="ind-ads-option-header-label-p">{`BASE`}<span>{`ADS`}</span></p> :
                <p className="ind-ads-option-header-label-p">{`BASE ADS`}</p>
            }
            {
                window.innerWidth < 900 ? null :
                <>
                    <div className="flex flex-d-row flex-align-start m-0-auto mt-16">
                        <CoinsIcon />
                        <div className="total-ind-ads-option-container">
                            <p>{adsTotal.base_ads}</p>
                        </div>
                    </div>
                    <p className="pls-select-an-ad-label-p">{`Please select an Ad`}</p>
                </>
            }
        </div>
        <div className={`flex ${window.innerWidth < 900 ? `flex-d-column` : `flex-d-row`}`}>
            <div>
                <p className="ind-ads-option-boost-title-p">{`Premium`}</p>
                <p className="ind-ads-option-boost-sub-p">{`Place item on top.`}</p>
                {
                    servicesData.map((pre, index) => {
                        if(pre.type === 'top_ad'){
                            return (
                                <div key={index} className="flex flex-d-row mt-20">
                                    <div className="flex flex-align-center ml-13">
                                        <div className="ind-ads-option-credits-container">
                                            <CoinsIcon /> &nbsp;
                                            <p>{pre.credit}</p>
                                        </div>
                                    </div>
                                    <div className={`flex flex-d-row flex-justify-between flex-align-center ${window.innerWidth < 900 ? `w-100` : ``}`}>
                                        <div className="flex flex-d-column ind-ads-option-details-container mr-70">
                                            <p>{pre.name.replace("Top Ad", "")}</p>
                                            <p>{`${pre.description.toString().replace(/\D/g,'')} Days`}</p>
                                        </div>
                                        <input type="radio" name="premium" value={pre} onClick={() => handleItemSelect(pre)} checked={adsTotal.base_ads_id === pre.id ? true : false} className="radio custom-radio-ui "/>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            {Divider()}
            {/* {window.innerWidth < 900 ? null : Divider()} */}
            <div className="highlight-ind-ads-main-container">
                <p className="ind-ads-option-boost-title-p">{`Highlighted`}</p>
                <p className="ind-ads-option-boost-sub-p">{`Highlight an item.`}</p>
                {
                    servicesData.map((pre, index) => {
                        if(pre.type === 'highlight'){
                            return (
                                <div key={index} className="flex flex-d-row mt-20">
                                    <div className="flex flex-align-center ml-13">
                                        <div className="ind-ads-option-credits-container">
                                            <CoinsIcon /> &nbsp;
                                            <p>{pre.credit}</p>
                                        </div>
                                    </div>
                                    <div className={`flex flex-d-row flex-justify-between flex-align-center ${window.innerWidth < 900 ? `w-100` : ``}`}>
                                        <div className="flex flex-d-column ind-ads-option-details-container mr-70">
                                            <p>{pre.name.replace("Highlight", "")}</p>
                                            <p>{`${pre.description.toString().replace(/\D/g,'')} Days`}</p>
                                        </div>
                                        <input type="radio" name="highlight" value={pre} onClick={() => handleItemSelect(pre)} checked={adsTotal.base_ads_id === pre.id ? true : false} className=" radio custom-radio-ui "/>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    </div>
    )
}

export const ComboAdsContainer = ({servicesData, handleItemSelect, adsTotal}) => {
    return (
        <div className="ind-ads-combo-ads-main-container">
            <div className="ind-ads-option-header-container">
            {
                window.innerWidth < 900 ? <p className="ind-ads-option-header-label-p">{`COMBO`}<span>{`ADS`}</span></p> :
                <p className="ind-ads-option-header-label-p">{`COMBO ADS`}</p>
            }
            {
                window.innerWidth < 900 ? null :
                <>
                    <div className="flex flex-d-row flex-align-start m-0-auto mt-16">
                        <CoinsIcon />
                        <div className="total-ind-ads-option-container">
                            <p>{adsTotal.combo_ads}</p>
                        </div>
                    </div>
                    <p className="pls-select-an-ad-label-p">{`Please select an Ad`}</p>
                </>
            }
            </div>
            <div>
                <p className="ind-ads-option-boost-title-p">{`Premium & Highlighted Ads`}</p>
                <p className="ind-ads-option-boost-sub-p">{`Item is on top and Higlighted.`}</p>
                {
                    servicesData.map((auto, index) => {
                        if(auto.type === 'combo'){
                            return (
                                <div key={index} className="flex flex-d-row mt-20">
                                    <div className="flex flex-align-center">
                                        <div className="ind-ads-option-combo-credits-container">
                                            <CoinsIcon /> &nbsp;
                                            <p>{auto.credit}</p>
                                        </div>
                                    </div>
                                    <div className={`flex flex-d-row flex-justify-between flex-align-center ${window.innerWidth < 900 ? `w-100` : ``}`}>
                                        <div className="flex flex-d-column combo-ind-ads-container">
                                            <p>{`${auto.name.replace("Combo Ad", "")} + ${auto.name.replace("Combo Ad", "")}`}</p>
                                            <p>{`${auto.description.toString().replace(/\D/g,'')} Days`}</p>
                                        </div>
                                        <input type="radio" name="combo" value={auto} checked={adsTotal.combo_ads_id === auto.id ? true : false} onClick={() => handleItemSelect(auto)} className=" radio custom-radio-ui "/>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const AutoBumpContainer = ({servicesData, handleItemSelect, adsTotal}) => {
    return (
        <div className={`ind-ads-auto-bump-main-container ${window.innerWidth < 900 ? `mt-20` : ``}`}>
            <div className="ind-ads-option-header-container">
            {
                window.innerWidth < 900 ? <p className="ind-ads-option-header-label-p">{`AUTO BUMP`}<span>{`ADS`}</span></p> :
                <p className="ind-ads-option-header-label-p">{`AUTO BUMP ADS`}</p>
            }
            {
                window.innerWidth < 900 ? null :
                <>
                    <div className="flex flex-d-row flex-align-start m-0-auto mt-16">
                        <CoinsIcon />
                        <div className="total-ind-ads-option-container">
                        <p>{adsTotal.auto_bump_ads}</p>
                        </div>
                    </div>
                    <p className="pls-select-an-ad-label-p">{`Please select an Ad`}</p>
                </>
            }
            </div>
            <div>
                <p className="ind-ads-option-boost-title-p">{`Auto Bump Your listing`}</p>
                <p className="ind-ads-option-boost-sub-p">{`Bump when listing reach the 3rd page `}</p>
                {
                    servicesData.map((auto, index) => {
                        if(auto.type === 'auto_bump'){
                            return (
                                <div key={index} className="flex flex-d-row mt-20">
                                    <div className="flex flex-align-center">
                                        <div className="ind-ads-option-combo-credits-container">
                                            <CoinsIcon /> &nbsp;
                                            <p>{auto.credit}</p>
                                        </div>
                                    </div>
                                    <div className={`flex flex-d-row flex-justify-between flex-align-center ${window.innerWidth < 900 ? `w-100` : ``}`}>
                                        <div className="flex flex-d-column combo-ind-ads-container">
                                            <p>{`${auto.description.toString().replace(/\D/g,'')} ${auto.description.toString().replace(/\D/g,'') < 2 ? `Day` : `Days`} Bump`}</p>
                                            <p>{`Bump item for ${auto.description.toString().replace(/\D/g,'')} ${auto.description.toString().replace(/\D/g,'') < 2 ? `Day` : `Days`}`}</p>
                                        </div>
                                        <input type="radio" name="combo" value={auto} onClick={() => handleItemSelect(auto)} checked={adsTotal.auto_bump_ads_id === auto.id ? true : false} className=" radio custom-radio-ui "/>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const SellerToolsProductContainer = () => {
    const value = useContext(ItemContext);
    const {itemForPromote} = value
    const {oc_t_item_description} = itemForPromote
    const {s_title} = oc_t_item_description
    const {get_i_price} = itemForPromote
    const itemImage = itemForPromote && itemForPromote.oc_t_item_resource ? itemForPromote.oc_t_item_resource : []
    const {currencyCode} = GlobalTheme.text

    return (
        <div className='w-100 flex flex-d-row flex-align-center seller-tools-product-container mt-30'>
            <div className='seller-tools-image-main-container'>
                <FkImage images={itemImage} type="promote-image" alt='image'/>
            </div>
            <div className='ml-12'>
                <p className='seller-tools-product-title'>{s_title}</p>
                <p className='seller-tools-product-price'>{`${currencyCode} ${get_i_price}`}</p>
            </div>
        </div>
    )
}

export const PremiumAdsContainer = ({boostKey, customClass}) => {
    const value = useContext(ItemContext);
    const {fkPrimePremiumTitle, fkPrimePremiumDescription, fkPrimeHighlightTitle, fkPrimeHighlightDescription, fkPrimeComboTitle, fkPrimeComboDescription, fkPrimeAutoBumpTitle, 
        fkPrimeAutoBumpDescription, daysLabel} = GlobalTheme.text
    const {servicesData, handleItemSelect} = value
    const title = () => {
        let Title
        if(boostKey === 'top_ad'){
            Title = fkPrimePremiumTitle
        }else if(boostKey === 'highlight'){
            Title = fkPrimeHighlightTitle
        } else if(boostKey === 'combo'){
            Title = fkPrimeComboTitle
        }else if(boostKey === 'auto_bump'){
            Title = fkPrimeAutoBumpTitle
        }else {
            Title = ''
        }

        return Title
    }

    const description = () => {
        let Description 

        if(boostKey === 'top_ad'){
            Description = fkPrimePremiumDescription
        }else if(boostKey === 'highlight'){
            Description = fkPrimeHighlightDescription
        } else if(boostKey === 'combo'){
            Description = fkPrimeComboDescription
        }else if(boostKey === 'auto_bump'){
            Description = fkPrimeAutoBumpDescription
        }else {
            Description = ''
        }

        return Description
    }

    const discountText = (b) => {
        let discount
        let topAdArr
        let highlightArr
        if(boostKey === 'combo'){
            if(b.includes('Basic')){
                topAdArr = servicesData.filter(item => item.type === 'top_ad' && item.name.includes('Basic'))
                highlightArr = servicesData.filter(item => item.type === 'highlight' && item.name.includes('Basic'))
            }else if(b.includes('Standard')){
                topAdArr = servicesData.filter(item => item.type === 'top_ad' && item.name.includes('Standard'))
                highlightArr = servicesData.filter(item => item.type === 'highlight' && item.name.includes('Standard'))
            }else if(b.includes('Ultimate')){
                topAdArr = servicesData.filter(item => item.type === 'top_ad' && item.name.includes('Ultimate'))
                highlightArr = servicesData.filter(item => item.type === 'highlight' && item.name.includes('Ultimate'))
            }

            return discount = parseInt(topAdArr[0].credit) + parseInt(highlightArr[0].credit)
        }else {
            discount = ''
        }
        return discount
    }

    return (
        <div className={`boost-selection-container ${customClass ? customClass : ''}`}>
            <div className='boost-selection-header-container'>
                <div>
                    <p className='boost-selection-header-title-p'>{title()}</p>
                    <p className='boost-selection-header-description-p'>{description()}</p>
                </div>
                {/* <div>
                    <InfoFilledIcon />
                </div> */}
            </div>
            <div className='boost-selection-body-container'>
                {
                    servicesData.map((boost, i) => {
                        if(boost.type === boostKey){
                            return (
                                <div className='boost-selection-sub-body-container' key={i}>
                                    <div>
                                        <p className='boost-selection-body-title-p'>{boost.name.replace(/ .*/,'')}</p>
                                        <p className='boost-selection-body-description-p'>{`${boost.days} ${daysLabel}`}</p>
                                    </div>
                                    <div className='boost-selection-body-btn' onClick={() => handleItemSelect(boost)}>
                                        <CoinsIcon />
                                        <p className='boost-selection-body-coins-p'>{boost.credit}</p>
                                        <p className='boost-selection-discounted-coins'>{discountText(boost.name)}</p>
                                    </div>
                                    
                                </div>
                            )
                        }else {
                            return null
                        }
                    })
                }
            </div>
        </div>
    )
}