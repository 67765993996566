import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { AdminPreferenceContext } from '../../../../../common/context'
import { adminPreferenceLoad, adminUpsertPreferenceLoad, adminDeletePreferenceLoad, adminPreferenceSuggestLoad } from '../../../../../redux/action'
import Loader from '../../../../../common/component/loader'
import { PreferenceList, PreferenceModal, PreferenceSuggestData, PreferenceSearchResult } from './component/CustomComponent'


const SettingSection = (props) => {
    const pageCount = 10
    const result_limit = 20
    // const defaultInput = {s_section: "fkv2", s_name: "", s_value: "", e_type: "STRING" }
    const defaultInput = {s_section: "fkv2", s_name: "", e_type: "", s_value: "",  }


    const { 
        adminPreferenceData, adminPreferenceIsLoading, adminPreferenceError, adminPreferencePage, adminTotalPreference,
        adminPreferenceUpsertIsLoading, adminPreferenceUpsertError, adminPreferenceUpsertMessage,
        adminPreferenceDeleteIsLoading, adminPreferenceDeleteError, adminPreferenceSuggestIsLoading, adminPreferenceSuggestData
    } = props.admin

    useEffect(() => {
        if (adminPreferenceData.length === 0) {
            props.adminPreferenceLoad({first: 20, page: adminPreferencePage})
        }
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if (adminPreferenceUpsertMessage) {
            setTimeout(() => {
                setIsShow(false)
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin/dashboard/setting`)
            }, 2000)
        }
    // eslint-disable-next-line
    }, [adminPreferenceUpsertMessage])

    const fetchPreferenceDetail = (item) => {
        setIsShow(true)
        // setIsInsert(false)
        setIsInsert('Update')
        setPreferenceDetailData(item)
    }

    const fetchPreferenceDefault = () => {
        setIsShow(true)
        setIsInsert('Insert')
        setPreferenceDetailData(defaultInput)
    }

    const closeItemModal = () => {
        setIsShow(false)
    }

    //sale modal
    const [isShow, setIsShow] = useState(false)
    const [isInsert, setIsInsert] = useState('Insert')
    const [preferenceDetailData, setPreferenceDetailData] = useState({})
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)


    //item search
    const [isSearching, setIsSearching] = useState(false)

    const searchOnchange = (event) => {
        const text = event.target.value
        const limit = 2
        if(text.length === 0){
            setIsSearching(false)
        }else if(text.length <= limit){
            setIsSearching(true)
        } else {
            setIsSearching(true)
            props.adminPreferenceSuggestLoad({type: 'all', first: result_limit, search: text, page: 1, orderField: "s_section", orderType: "ASC"})
        }
    }

    const searchSubmit = event => {
        event.preventDefault();
        setIsSearching(false)
        setIsShowSearchResult(true)
    }

    const handlePageClick = data => {
        props.adminPreferenceLoad({first: 20, page: data.selected + 1})
    };

    const upsertItem = (data, type) => {
        props.adminUpsertPreferenceLoad(data = {...data, type: type})
    }

    const deleteItem = (item) => {
        const alert = window.confirm(`Are you sure you want to delete ${item.s_section} - ${item.s_name}`); if(alert === true){ 
            props.adminDeletePreferenceLoad(item)
        }
    }

    return (
        <AdminPreferenceContext.Provider value={{
            adminPreferenceData, fetchPreferenceDetail, adminPreferenceIsLoading, 
            isShow, closeItemModal, preferenceDetailData, upsertItem, adminPreferenceUpsertIsLoading, adminPreferenceUpsertError, adminPreferenceUpsertMessage, isInsert,
            deleteItem, adminPreferenceSuggestIsLoading, adminPreferenceSuggestData, setIsShowSearchResult, isShowSearchResult
        }}>
        <section className="page-content">
            <h3 style={{textAlign: 'center'}}>Preference</h3>
            <section>
            {adminPreferenceIsLoading || adminPreferenceDeleteIsLoading ? <article style={{justifyContent: 'center', alignItems: 'center', minHeight: 572}}><Loader /></article> : 
                adminPreferenceError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminPreferenceError}</p></div></article> :
                    adminPreferenceDeleteError ? <article style={{justifyContent: 'center', alignItems: 'center' }}><div><p style={{color: 'red'}}>{adminPreferenceDeleteError}</p></div></article> :
                    <article style={{flexDirection: 'column'}}>
                        <div style={{display:'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div onClick={() => fetchPreferenceDefault()} className="sellButton pointer" style={{margin: 10, height: 50, alignItems: 'center', alignSelf: 'flex-start'}}>
                                <span>Add Preference</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                <div className="admin-search-container">
                                        <form onSubmit={searchSubmit} style={{display: 'flex',flexDirection: 'row'}}>
                                            <input type="text" placeholder="Search.." autoComplete={'off'} name="search" onChange={(event) => searchOnchange(event)}/>
                                            {false ? null  : <button type="submit"><i className="fa fa-search"></i></button>}
                                        </form>
                                </div>
                                {!isSearching ? null : <div style={{position: 'absolute', top: 60, width: '100%'}}><PreferenceSuggestData /></div>}
                            </div>
                        </div>
                        <PreferenceList />
                    </article>
            }
            <div></div> 
            {adminPreferenceError ? null :
                adminPreferenceData.length < result_limit ? null :
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={adminTotalPreference}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={pageCount}
                    onPageChange={(d) => handlePageClick(d)}
                    containerClassName={'pagination pointer'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            }
            </section> 
        </section> 
        {isShow ? <PreferenceModal /> : null }
        {isShowSearchResult ? <PreferenceSearchResult /> : null}
        </AdminPreferenceContext.Provider>
    )
}

const mapStateToProps = store => {
    return {
        admin: store.adminReducer,
    };
  };
  
const mapActionToProps = {
    adminPreferenceLoad,
    adminUpsertPreferenceLoad,
    adminDeletePreferenceLoad,
    adminPreferenceSuggestLoad
}
  
export default connect(mapStateToProps, mapActionToProps)(SettingSection);