import React, { useReducer, useState, createContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminLoginLoad, adminForgotPasswordLoad, adminForgotPasswordClearMessage } from '../../../redux/action'
import { LoginForm, ForgotPasswordForm } from './component/CustomComponent'

const { REACT_APP_ENV } = process.env;

export const AdminLoginContext = createContext();

const AdminLogin = (props) => {
  const isAuthenticated = props && props.session && props.session.fkv2_session && props.session.fkv2_session ? true : false
  const isAdmin = props && props.session && props.session.fkv2_session && props.session.fkv2_session && props.session.fkv2_session.type && props.session.fkv2_session.type === 'admin' ? true : false

  const { isLoading, error,  } = props.adminData
  const [forgotShow, setForgotShow] = useState(false)
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      userEmail: '',
      userPassword: '',
      captcha: ''
    }
  );

  //LOGIN
  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({[name]: newValue});
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (REACT_APP_ENV === 'local') {
      props.adminLoginLoad(userInput)
    } else {
      if(userInput.captcha !== ''){
        props.adminLoginLoad(userInput)
      }else{
        setUserInput({['inputError']: 'Recaptcha Error'})
      } 
    } 
  }

  const parseError = (error) => {
    let errorArray = []
    if (typeof error === 'string') {
      errorArray.push(<p key={1} style={{color: 'red'}}>{error}</p>)
    } else {
      for (var key in error) {
        if (error.hasOwnProperty(key)) {
            errorArray.push(<p key={key} style={{color: 'red'}}>{error[key].toString().replace('input.', '')}</p>)
        }
      }
    }
    return errorArray
  }

  const toggleForgot = () => {
    setForgotShow(!forgotShow)

  }

  const captchaChange = value => {
    setUserInput({['captcha']: value})
    if(userInput.inputError !== ''){
      setUserInput({['inputError']: ''})
    }
  }

  //FORGOT PASSWORD
  const {forgotPasswordIsLoading, forgotPasswordData, forgotPasswordError } = props.adminData
  const [emailInput, setEmailInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      email: '',
      type: 'admin',
      captcha: ''
    }
  );

  //hooks to clear error / message
  useEffect(() => {
    if (forgotPasswordError) {
      setTimeout(() => {
      props.adminForgotPasswordClearMessage()
      setForgotShow(!forgotShow)
      }, 3000)
    }
    if (forgotPasswordData) {
      setTimeout(() => {
      props.adminForgotPasswordClearMessage()
      setForgotShow(!forgotShow)
    }, 3000)
  }
// eslint-disable-next-line
}, [props.adminData.forgotPasswordError, props.adminData.forgotPasswordData])

  const handleEmailChange = event => {
    const name = event.target.name;
    const newValue = event.target.value;
    setEmailInput({[name]: newValue});
  }

  const handleForgotSubmit = evt => {
    evt.preventDefault();

    if (REACT_APP_ENV === 'local') {
      props.adminForgotPasswordLoad(emailInput)
    } else {
      if(emailInput.captcha !== ''){
        props.adminForgotPasswordLoad(emailInput)
      }else{
        setEmailInput({['inputError']: 'Recaptcha Error'})
      } 
    } 
  }

  const forgotCaptchaChange = value => {
    setEmailInput({['captcha']: value})
    if(emailInput.inputError !== ''){
      setEmailInput({['inputError']: ''})
    }
  }

  return (
    isAuthenticated && isAdmin ? <Redirect to='/admin/dashboard' /> : 
    isAuthenticated && !isAdmin ? <Redirect to='/' /> : 
    <AdminLoginContext.Provider value={{
      isLoading, error, userInput, handleChange, handleSubmit, parseError, toggleForgot,
      forgotPasswordIsLoading, emailInput, forgotPasswordError, handleEmailChange, handleForgotSubmit, forgotPasswordData, captchaChange, forgotCaptchaChange
    }}>
      <div className='colLg'>
        <div className='admin-login-container'>
          {forgotShow ? <ForgotPasswordForm /> : <LoginForm />}
          {forgotShow ? null : <div className='admin-forgot-password-container '>{isLoading ? null : <Link onClick={() => toggleForgot()} to='/admin'>Forgot Password</Link>}</div>}
        </div>
      </div>
    </AdminLoginContext.Provider>
  );
};

const mapStateToProps = store => {
  return {
    adminData: store.adminReducer,
  };
};

const mapActionToProps = {
  adminLoginLoad,
  adminForgotPasswordLoad,
  adminForgotPasswordClearMessage
}

export default connect(mapStateToProps, mapActionToProps)(AdminLogin);
