import Layout from '../layout/layout'
import LayoutWithSideBar from '../layout/layoutWithSideBar'
import LayoutMessage from '../layout/layoutMessage'
import LayoutSettings from '../layout/layoutSettings'
import LayoutWithRightSidebar from '../layout/layoutWithRightSideBar'
import LayoutNoCategory from '../layout/layoutNoCategory'
import LayoutSearch from '../layout/layoutSearch'
import AdminLayout from '../layout/adminLayout/adminLayout'
import DashboardWithSideBar from '../layout/adminLayout/dashboardWithSidebar'

//Public Components
import Home from '../web/home'
import Login from '../web/login'
import Signup from '../web/signup'
import AdminLogin from '../admin/login'
import Email from '../web/email'
import Views from '../web/item/view'
import Forgot from '../web/forgot'
import Search from '../web/search'
import UserSearch from '../web/search/user'
import PrivacyPolicy from '../web/privacypolicy'
import Tou from '../web/TOS'
import About from '../web/about'
import AdminForgot from '../admin/forgot';
import PageNotFound from '../web/notFound'
import ContactUs from '../web/contact-us'
import DownloadPage from '../web/download'

//Private User Components
import ItemNew from '../web/item/new'
import UserProfile from '../web/profile'
import Likes from '../web/likes'
import Messages from '../web/message'
import Archive from '../web/message/archive'
import Listing from '../web/profile/UserProfile'
import ChangePassword from '../web/profile/changePassword'
import WatchList from '../web/profile/watchList'
import Ratings from '../web/profile/ratings'
import BoosterStatus from '../web/profile/boosterStatus'
import PurchaseHistory from '../web/profile/purchaseHistory'
import Settings from '../web/profile/settings'
import Subscription from '../web/profile/UserProfile'
import Wallet from '../web/profile/UserProfile'
import Review from '../web/profile/UserProfile'
import LeaveReview from '../web/profile/review/leave'
import PayNowInit from '../web/paynow/init'
import PayNowRedirect from '../web/paynow/redirect'
import Category from '../web/category'
import FKPrimeComponent from '../web/fkPrime'
// import Trash from '../web/message/trash'

//Protected Admin Components
import AdminDashboard from '../admin/dashboard'
import UserSection from '../admin/dashboard/sections/user';
import AdminSection from '../admin/dashboard/sections/admin';
import ItemSection from '../admin/dashboard/sections/item';
import SalesSection from '../admin/dashboard/sections/sale';
import CommentSection from '../admin/dashboard/sections/comment';
import ReportSection from '../admin/dashboard/sections/report';
import SettingSection from '../admin/dashboard/sections/setting';
import ExtrasSection from '../admin/dashboard/sections/extras';
import CategorySection from '../admin/dashboard/sections/category';
import CustomFieldSection from '../admin/dashboard/sections/customField';
import BanTextSection from '../admin/dashboard/sections/ban/text';
import PublicProfile from '../admin/dashboard/sections/user/component/PublicProfile';
import PublicItem from '../admin/dashboard/sections/item/component/PublicItem';
import BanEmailSection from '../admin/dashboard/sections/ban/email';
import CoinServiceSection from '../admin/dashboard/sections/service/coin';
import ItemServiceSection from '../admin/dashboard/sections/service/item';
import SubscriptionSection from '../admin/dashboard/sections/subscription';
import LogSection from '../admin/dashboard/sections/log';
import PromoSection from '../admin/dashboard/sections/promo';

export const publicRoutes = [
  {
    key: 'root',
    exact: true,
    path: '/',
    component: Home,
    layout: Layout,
  },
  {
    key: 'login',
    exact: true,
    path: '/login',
    component: Login,
    layout: Layout,
  },
  {
    key: 'item',
    exact: true,
    path: '/(reels|rods|lures|line|terminal|accessories|apparel|watercraft|charters|others)/*_i(\\d+)?*',
    component: Views,
    layout: LayoutWithRightSidebar
  },
  {
    key: 'privacyPolicy',
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: Layout,
  },
  {
    key: 'download',
    exact: true,
    path: '/download',
    component: DownloadPage,
    layout: Layout,
  },
  {
    key: 'contact-us',
    exact: 'true',
    path: '/contact-us',
    component: ContactUs,
    layout: Layout
  },
  {
    key: 'tou',
    exact: true,
    path: '/terms-of-use',
    component: Tou,
    layout: Layout
  },
  {
    key: 'about',
    exact: true,
    path: '/about-us',
    component: About,
    layout: Layout
  },
  {
    key: 'user-search-profile-listing',
    exact: true,
    path: '/user/:id(\\d+)/listing',
    component: Listing,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-search-profile-review',
    exact: true,
    path: '/user/:id(\\d+)/reviews',
    component: Review,
    layout: LayoutWithSideBar
  },
  {
    key: 'signup',
    exact: true,
    path: '/signup',
    component: Signup,
    layout: Layout,
  },
  {
    key: 'email',
    exact: true,
    path: '/email/yrFW4AyuI6AUHdby*',
    component: Email,
    layout: Layout,
  },
  {
    key: 'forgot',
    exact: true,
    path: '/forgot/yrFW4AyuI6AUHdby*',
    component: Forgot,
    layout: Layout,
  },
  {
    key: 'category',
    exact: true,
    path: '/(reels|rods|lures|line|terminal|accessories|apparel|watercraft|charters)',
    component: Category,
    layout: LayoutSearch,
  },
  {
    key: 'admin-login',
    exact: true,
    path: '/admin',
    component: AdminLogin,
    layout: AdminLayout,
  },
  {
    key: 'admin-forgot',
    exact: true,
    path: '/admin/forgot/yrFW4AyuI6AUHdby*',
    component: AdminForgot,
    layout: AdminLayout,
  },
  {
    key: 'search',
    exact: true,
    path: '/search/item/*',
    component: Search,
    layout: LayoutSearch
  },
  {
    key: 'search-user',
    exact: true,
    path: '/search/user/*',
    component: UserSearch,
    layout: Layout
  },
  {
    key: 'not-found',
    exact: true,
    path: '/page-not-found',
    component: PageNotFound,
    layout: Layout
  }
];

export const privateRoutes = [
  {
    key: 'user-root',
    exact: true,
    path: '/',
    component: Home,
    layout: Layout,
  },
  {
    key: 'download',
    exact: true,
    path: '/download',
    component: DownloadPage,
    layout: Layout,
  },
  {
    key: 'user',
    exact: true,
    path: '/user',
    component: UserProfile,
    layout: LayoutWithSideBar,
  },
  {
    key: 'user-likes',
    exact: true,
    path: '/user/likes',
    component: Likes,
    layout: Layout
  },
  {
    key: 'fk-prime',
    exact: true,
    path: '/fk-prime',
    component: FKPrimeComponent,
    layout: Layout
  },
  // {
  //   key: 'user-profile',
  //   exact: true,
  //   path: '/user/profile',
  //   component: UserProfile,
  //   layout: LayoutSettings
  // },
  {
    key: 'privacyPolicy',
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: Layout,
  },
  {
    key: 'tou',
    exact: true,
    path: '/terms-of-use',
    component: Tou,
    layout: Layout
  },
  {
    key: 'about',
    exact: true,
    path: '/about-us',
    component: About,
    layout: Layout
  },
  {
    key: 'user-review',
    exact: true,
    path: '/user/reviews',
    component: Review,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-leave-review',
    exact: true,
    path: '/user/:id(\\d+)/reviews/leave',
    component: LeaveReview,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-listing',
    exact: true,
    path: '/user/listing',
    component: Listing,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-wallet',
    exact: true,
    path: '/user/wallet',
    component: Wallet,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-change-password',
    exact: true,
    path: '/user/change-password',
    component: ChangePassword,
    layout: LayoutSettings
  },
  {
    key: 'user-watchlist',
    exact: true,
    path: '/user/watchlist',
    component: WatchList,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-ratings',
    exact: true,
    path: '/user/ratings',
    component: Ratings,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-booster-status',
    exact: true,
    path: '/user/booster-status',
    component: BoosterStatus,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-purchase-history',
    exact: true,
    path: '/user/purchase-history',
    component: PurchaseHistory,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-messages',
    exact: true,
    path: '/user/messages',
    component: Messages,
    layout: LayoutMessage
  },
  {
    key: 'user-messages-inbox',
    exact: true,
    path: '/user/messages/inbox',
    component: Messages,
    layout: LayoutMessage
  },
  {
    key: 'user-messages-archive',
    exact: true,
    path: '/user/messages/archive',
    component: Archive,
    layout: LayoutMessage
  },
  // {
  //   key: 'user-messages-trash',
  //   exact: true,
  //   path: '/user/messages/trash',
  //   component: Trash,
  //   layout: LayoutMessage
  // },
  {
    key: 'user-settings',
    exact: true,
    path: '/user/settings',
    component: Settings,
    layout: LayoutSettings
  },
  {
    key: 'user-subscriptions',
    exact: true,
    path: '/user/subscription',
    component: Subscription,
    layout: LayoutWithSideBar
  },
  {
    key: 'user-item-new',
    exact: true,
    path: '/user/item/new',
    component: ItemNew,
    layout: LayoutNoCategory,
  },
  {
    key: 'paynow-init',
    exact: true,
    path: '/paynow/init',
    component: PayNowInit,
    layout: Layout,
  },
  {
    key: 'paynow-redirect',
    exact: true,
    path: '/paynow/redirect*',
    component: PayNowRedirect,
    layout: Layout,
  },
];

export const protectedRoutes = [
  {
    key: 'admin-dashboard',
    exact: true,
    path: '/admin/dashboard',
    component: AdminDashboard,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-user',
    exact: true,
    path: '/admin/dashboard/user',
    component: UserSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-admin',
    exact: true,
    path: '/admin/dashboard/admin',
    component: AdminSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-item',
    exact: true,
    path: '/admin/dashboard/item',
    component: ItemSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-sale',
    exact: true,
    path: '/admin/dashboard/sale',
    component: SalesSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-comment',
    exact: true,
    path: '/admin/dashboard/comment',
    component: CommentSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-report',
    exact: true,
    path: '/admin/dashboard/report',
    component: ReportSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-report-id',
    exact: true,
    path: '/admin/dashboard/report/:id(\\d+)',
    component: ReportSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-setting',
    exact: true,
    path: '/admin/dashboard/setting',
    component: SettingSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-extra',
    exact: true,
    path: '/admin/dashboard/extra',
    component: ExtrasSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-category',
    exact: true,
    path: '/admin/dashboard/category',
    component: CategorySection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-customField',
    exact: true,
    path: '/admin/dashboard/custom_field',
    component: CustomFieldSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-ban-text',
    exact: true,
    path: '/admin/dashboard/ban/text',
    component: BanTextSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-ban-email',
    exact: true,
    path: '/admin/dashboard/ban/email',
    component: BanEmailSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-user-public-view',
    exact: true,
    path: '/admin/dashboard/user/:id(\\d+)',
    component: PublicProfile,
    layout: AdminLayout,
  },
  {
    key: 'admin-dashboard-item-public-view',
    exact: true,
    path: '/admin/dashboard/item/:id(\\d+)',
    component: PublicItem,
    layout: AdminLayout,
  },
  {
    key: 'admin-dashboard-coin-service',
    exact: true,
    path: '/admin/dashboard/service/coin',
    component: CoinServiceSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-item-service',
    exact: true,
    path: '/admin/dashboard/service/item',
    component: ItemServiceSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-subscription',
    exact: true,
    path: '/admin/dashboard/subscription',
    component: SubscriptionSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-log',
    exact: true,
    path: '/admin/dashboard/log',
    component: LogSection,
    layout: DashboardWithSideBar,
  },
  {
    key: 'admin-dashboard-promo',
    exact: true,
    path: '/admin/dashboard/promo',
    component: PromoSection,
    layout: DashboardWithSideBar,
  },
];