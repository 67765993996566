import React, { useEffect, useState, createContext, useReducer} from 'react'
import { connect } from 'react-redux'
import { meLoad, servicesLoad, promoteReset, purchaseCoinLoad, purchaseCoinReset } from '../../../../redux/action'
import GetCoinsModal from './customComponent/getCoins'
import Purchases from './customComponent/purchase'
import { theme } from '../../../../common/theme'
import { saveLocalStorage } from '../../../../common/helper/utility'
import { MoneyXLIcon, OrangeMinusIcon, OrangePlusIcon } from '../../../../assets/svg'
import {PurchaseTable, PurchaseCard} from './customComponent'
import Purchase from './customComponent/purchase'

export const CoinContext = createContext();

const Wallet = (props) => {
    const userId = props.session.fkv2_session.id ? props.session.fkv2_session.id : null
    const data = props && props.me && props.me.data ? props.me.data : []
    const coins = data && data.wallet ? data.wallet : 0
    const services = props && props.purchase ? props.purchase : {}
    const purchases = props && props.me && props.me.data && props.me.data.purchases ? props.me.data.purchases : []
    const { isLoadingPurchaseCoin, errorPurchaseCoin, purchaseCoinData, purchaseSuccessMessage } = props.purchase
    const session = props.session && props.session.fkv2_session ? props.session.fkv2_session : null
    const isBuyCoinsBtnclick = props && props.data && props.data.history && props.data.history.location && props.data.history.location.isBuyCoinsClick ? props.data.history.location.isBuyCoinsClick : false

    const urlParams = new URLSearchParams(window.location.search);
    const stripeSessionId = urlParams.get('session_id');
    const [stripeError, setStripeError] = useState(null)
    const [stripeLoading, setStripeLoading] = useState(null)
    const [stripeSuccess, setStripeSuccess] = useState(null)
    const [historyView, setHistoryView] = useState(false)


    const [userPurchases, setUserPurchases] = useReducer(
        (state, newState) => ({...state, ...newState}), {
            data: []
        }
    )

    const [boostOffer, setBoostOffer] = useReducer(
        (state, newState) => ({...state, ...newState}), {
            offers: []
        }
    )

    const getServices = (active) => {
        props.servicesLoad(active)
    }

    const boostOfferSort = (arr) => {
        let a = []
        arr.map((h) => {
           return h.type === 'coin_bundle' ? a.push(h) : null
        })

        return a
    }

    //stripe redirect handler
    useEffect(() => {
        if (stripeError) {
            setTimeout(() => {
                resetState()
            }, 3000)
        }
        if (stripeSessionId) {
            purchaseData.payment_method = 'stripe'
            purchaseData.type = 'coin_bundle'
            setIsShow(true)
            setStep(3)
            purchaseCoin(purchaseData, {order_id: stripeSessionId})
            // resetState()
            // window.location.replace(`${process.env.REACT_APP_BASE_URL}user/wallet`);
            window.history.pushState({}, document.title, "/" + "user/wallet" );
        }
        // eslint-disable-next-line
    }, [stripeError, stripeSessionId])

    useEffect(() => {
        let arrData = []
        if(purchases.length !== 0) {
            purchases.map((item) => {
                return item.type === 'coin_bundle' ? arrData.push(item) : null
            })
            setUserPurchases({['data']: arrData})
        }
    }, [purchases])

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            props.meLoad({ id: userId })
        }
        const redirect = props.history && props.history.location && props.history.location.state && props.history.location.state.redirect && props.history.location.state.redirect === 'promote' ? true : false
        if (redirect) {
            setIsShow(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (errorPurchaseCoin) {
            setTimeout(() => {
                resetState()
            }, 3000)
        }
        if (purchaseSuccessMessage) {
            
            setTimeout(() => {
                resetState()
            }, 3000)
        }
        // eslint-disable-next-line
    }, [errorPurchaseCoin, purchaseSuccessMessage])

    useEffect(() => {
        if(props.purchase.servicesData.length === 0){
            getServices(true)
        }
    },[])

    useEffect(() => {
        if(props.purchase.servicesData.length !== 0){
           const arr = boostOfferSort(props.purchase.servicesData)

           setBoostOffer({['offers']: arr})
        }
    },[props.purchase.servicesData])

    useEffect(() => {
        if(isBuyCoinsBtnclick){
            setIsShow(true)
        }
    },[isBuyCoinsBtnclick])

    const [isShow, setIsShow] = useState(false);
    const [step, setStep] = useState(1)
    const [purchaseData, setPurchaseData] = useState({ payment_method: 'paypal' })
    const stepLength = 3

    const handleGotoPayNow = () => {
        saveLocalStorage("purchase_data", purchaseData)
    }

    const gotoSecond = (item) => {
        setPurchaseData({ ...item, payment_method: purchaseData.payment_method })
        if (step !== stepLength || step < stepLength) {
            setStep(step + 1)
        }
    }

    const handleMethodChange = (method) => {
        setPurchaseData({ ...purchaseData, payment_method: method })
    }

    const handleNext = () => {
        if (step !== stepLength || step < stepLength) {
            setStep(step + 1)
        }
    }

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const toggleIsShow = () => {
        if (isShow === true) {
            setIsShow(!isShow)
            setStep(1)
        } else {
            setIsShow(!isShow)
        }
    }

    //purchase coin
    const purchaseCoin = (data, input) => {
        console.log('purchaseCoin data', data)
        console.log('purchaseCoin input', input)
        props.purchaseCoinLoad({ fkv2_purchased_data: { ...data, ...input }, userId: userId })
    }

    const resetState = () => {
        props.purchaseCoinReset()
        setStripeError(null)
        setStripeLoading(null)
        setIsShow(false)
        setPurchaseData({ payment_method: 'paypal' })
        setStep(1)
        window.history.pushState({}, document.title, "/" + "user/wallet" );
    }

    const getMoreCoinsInstantHandler = (item) => {
        setIsShow(true)
        gotoSecond(item)
    }

    return (
        <CoinContext.Provider value={{
            isShow, toggleIsShow, step, handleNext, handleBack, theme, handleGotoPayNow, gotoSecond, purchaseData,
            getServices, services, purchases, handleMethodChange, purchaseCoin, isLoadingPurchaseCoin, errorPurchaseCoin, purchaseCoinData, purchaseSuccessMessage,
            session, data, setStripeError, stripeLoading, setStripeLoading, stripeSuccess, userPurchases,
            getMoreCoinsInstantHandler
        }}>
                <div className="wallet-main-container">
                    <div className="wallet-sub-container">
                        <div className="wallet-header-container">
                            <div className="flex flex-d-column">
                                <div className="flex flex-d-row flex-align-center">
                                    <MoneyXLIcon />
                                    <p className="wallet-current-coins-p">{coins}</p>
                                </div>
                                <div>
                                    <p className="wallet-current-coins-label-p">{`Current Coins`}</p>
                                </div>
                            </div>
                            <div className="default-btn bg-orange" onClick={() => toggleIsShow(true)}>
                                <p className="text-white">{`Add Coins`}</p>
                            </div>
                        </div>
                        <div className="wallet-purchase-history-container">
                        {
                            historyView ?
                            <div className="wallet-purchase-table-container">
                                <PurchaseTable />
                            </div> : null
                        }
                            <div onClick={() => setHistoryView(!historyView)} className={`flex flex-d-row flex-align-center ${window.innerWidth < 600 ? `p-30-0` : ``}`}>
                                {
                                    historyView ? <OrangeMinusIcon /> : <OrangePlusIcon />
                                }
                                <p className="purchase-history-btn-p no-select">{`Purchase history`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="get-more-coins-main-container">
                        <div className="get-more-coins-save-more-container-label">
                            <p className="wallet-get-more-coins-label-p">{`Get more coins`}</p>
                            <p className="wallet-save-more-label-p">{`Save more with bigger bundles`}</p>
                        </div>
                        <div className={`flex ${window.innerWidth < 600 ? `flex-d-column` : `flex-d-row`} w-100`}>
                            {
                                boostOffer.offers.map((b, index) => {
                                    return (
                                        <div key={index} className="purchase-card-main-container">
                                            <PurchaseCard boost={b} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {isShow ? <GetCoinsModal amountCoin={coins} /> : null}
            {/* </div> */}
        </CoinContext.Provider>
    )
}
const mapStateToProps = store => {
    return {
        me: store.userReducer,
        purchase: store.purchaseReducer
    };
};

const mapActionToProps = {
    meLoad,
    servicesLoad,
    promoteReset,
    purchaseCoinLoad,
    purchaseCoinReset,
}

export default connect(mapStateToProps, mapActionToProps)(Wallet)