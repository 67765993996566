import { USER_DATA, NEW_USER, UPDATE_USER, EMAIL_VERIFY, LOGOUT, ADMIN, RESEND_EMAIL_VERIFICATION } from '../constant'

const initialState = {
    isLoading: false,
    verifyIsLoading: true,
    data: {},
    error: false,
    message: null,
    page: 0,

    logoutIsLoading: false,
    logoutSuccess: false,
    logoutError: false,

    loginAsUserIsloading: false,
    loginAsUserMessage: false,
    loginAsUserError: false,

    resendEmailVerificationLoading: false,
    resendEmailVerificationMessage: null,
    resendEmailVerificationError: false

}

export default (state = initialState, action) => {
    switch(action.type) {
        // login action 
        case USER_DATA.LOAD:
        return {...state, isLoading: true, error: null}

        case USER_DATA.SUCCESS:
        return {...state, isLoading: false, data:  action.payload, error: null}

        case USER_DATA.FAIL:
        return {...state, isLoading: false, error: action.payload}

        case USER_DATA.RESET:
        return initialState   

        // register action
        case NEW_USER.LOAD:
        return {...state, isLoading: true, error: null, message: null}

        case NEW_USER.SUCCESS:
        return {...state, isLoading: false, data:  action.payload, error: null, message: action.payload}

        case NEW_USER.FAIL:
        return {...state, isLoading: false, error: action.payload, message: null}

        // update user action
        case UPDATE_USER.LOAD:
        return {...state, isLoading: true, error: null, message: null}

        case UPDATE_USER.SUCCESS:
        return {...state, isLoading: false, data:  action.payload, error: null, message: 'Update success'}

        case UPDATE_USER.FAIL:
        return {...state, isLoading: false, error: action.payload, message: null}

        //email verify
        case EMAIL_VERIFY.LOAD:
        return {...state, verifyIsLoading: true, message: null, error: null}

        case EMAIL_VERIFY.SUCCESS:
        return {...state, verifyIsLoading: false, message:  action.payload, error: null}

        case EMAIL_VERIFY.FAIL:
        return {...state, verifyIsLoading: false, message: null, error: action.payload}

        case EMAIL_VERIFY.CLEAR_MESSAGE:
        return {...state, message: null}

        //resend email verification
        case RESEND_EMAIL_VERIFICATION.LOAD:
        return {...state, resendEmailVerificationLoading: true, resendEmailVerificationMessage: null, resendEmailVerificationError: null}

        case RESEND_EMAIL_VERIFICATION.SUCCESS:
        return {...state, resendEmailVerificationLoading: false, resendEmailVerificationMessage:  action.payload, resendEmailVerificationError: null}

        case RESEND_EMAIL_VERIFICATION.FAIL:
        return {...state, resendEmailVerificationLoading: false, resendEmailVerificationMessage: null, resendEmailVerificationError: action.payload}

        case RESEND_EMAIL_VERIFICATION.RESET:
        return {...state, resendEmailVerificationLoading: false, resendEmailVerificationMessage: null, resendEmailVerificationError: false}

        // logout action 
        case LOGOUT.LOAD:
        return {...state, logoutIsLoading: true, logoutError: false}

        case LOGOUT.SUCCESS:
        return {...state, logoutIsLoading: false, logoutError: false, logoutSuccess:  action.payload }

        case LOGOUT.FAIL:
        return {...state, logoutIsLoading: false, logoutError: action.payload}

        case LOGOUT.RESET:
        return {...state, logoutIsLoading: false, logoutError: false, logoutSuccess: false}

        // admin login as user action 
        case ADMIN.LOGIN_AS_USER.LOAD:
        return {...state, loginAsUserIsloading: true, loginAsUserError: null}

        case ADMIN.LOGIN_AS_USER.SUCCESS:
        return {...state, loginAsUserIsloading: false, loginAsUserMessage:  action.payload, loginAsUserError: null}

        case ADMIN.LOGIN_AS_USER.FAIL:
        return {...state, loginAsUserIsloading: false, loginAsUserError: action.payload}

        case ADMIN.LOGIN_AS_USER.RESET:
        return initialState   


        default:
        return state;  
    }
}