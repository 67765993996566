import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import {useLocation} from 'react-router-dom'
import ProfileBar from '../profileBar'
import ProfileNavLink from '../profileNavLink'
import {meLoad} from '../../../../redux/action'
import Listing from '../listing'
import Wallet from '../wallet'
import Review from '../review'
import Subscription from '../subscription'
import CoverPhoto from '../../../../assets/images/fk-cover.png'
import {WholePageLoader} from '../../../../common/component/customComponents'

const { REACT_APP_ENV, REACT_APP_GRAPHQL_SERVER_BASE_URL, REACT_APP_IMGX_COVER} = process.env

const UserProfile = (props) => {
    const {session, me} = props
    const {fkv2_session} = session
    const sessionId = fkv2_session && fkv2_session.id ? fkv2_session.id : 0

    const use_location = useLocation()
    const pathname = use_location.pathname
    const urlSliced = pathname.slice('6')
    const userId = urlSliced === 'listing' || urlSliced === 'reviews' || urlSliced === 'wallet' ? sessionId : urlSliced.replace(/(listing)|(reviews)|(wallet)/g, '')

    const {data, isLoading} = me

    useEffect(() => {
        if(userId){
            props.meLoad({id: userId})
        }
    },[pathname])

    const componentSwitch = () => {
        let c
        if(urlSliced.includes('listing')){
            c = <Listing session={session} me={me}/>
        }
        if(urlSliced.includes('reviews')){
            c = <Review session={session} me={me}/>
        }
        if(urlSliced.includes('wallet')){
            c = <Wallet session={session} me={me} data={props}/>
        }
        if(urlSliced.includes('subscription')){
            c = <Subscription session={session} me={me}/>
        }
        return c
    }

    const CoverComponent = () => {
        const {cover_photo} = data
        const id = cover_photo && cover_photo.pk_i_id ? cover_photo.pk_i_id : 0
        const ext = cover_photo && cover_photo.s_extension ? cover_photo.s_extension : null
        const coverSrc = id === 0 ? 
        // `https://fkv2-staging-cover.imgix.net/0.null?w=1070&h=306&auto=true` 
        CoverPhoto
        :
        `${REACT_APP_ENV === 'local' ? REACT_APP_GRAPHQL_SERVER_BASE_URL+`classifieds-local/cover/${id}.${ext}?w=${window.innerWidth < 600 ? 355 : 1070}&h=${window.innerWidth < 600 ? 150 : 312}&auto=true` 
        : REACT_APP_IMGX_COVER+id+`.${ext}?w=${window.innerWidth < 600 ? 355 : 1070}&h=${window.innerWidth < 600 ? 150 : 312}&auto=true`}`
        return (
            <div className="p-relative profile-cover-main-container">
                <img src={coverSrc} width={'100%'} height={REACT_APP_ENV === 'local' ? window.innerWidth < 600 ? 150 : 312 : 312} alt={`${id}user_Cover_photo`} />
            </div>
        )
    }
    return (
        <>
         <div className="profile-container">
        {
            isLoading ? <WholePageLoader /> :
             <div className="profile-sub-main-container w-100">
               {CoverComponent()}
                <div className="profile-main-container">
                <div>
                    <ProfileBar data={data} session={session}/>
                </div>
                <div>
                    <div>
                    <ProfileNavLink/>
                    </div>
                    <div style={{marginBottom: 50}}>
                    {componentSwitch()}
                    </div>
                </div>
                </div>
            </div>
            
            
        }
        </div>
        </>
    )
}

const mapStateToProps = store => {
    return {
        me: store.userReducer
    };
  };
  
const mapActionToProps = {
    meLoad
}
  
  
export default connect(mapStateToProps, mapActionToProps)(UserProfile)