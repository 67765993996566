import React, {useContext} from 'react';
import { CoinContext } from "../../../index";
import Stripe from '../method/stripe/index'
import PayPal from '../method/paypal'

const Third = () => {
    const value = useContext(CoinContext);
    const {purchaseData } = value

    const paymentSwitch = () => {
        let paymentHolder = []
        switch (purchaseData.payment_method) {
            case 'stripe':
                paymentHolder = <Stripe />
            break;

            case 'paypal':
                paymentHolder = <PayPal />
            break;

            // case 'paynow':
            //     paymentHolder = <Third />
            // break;

            default:
            break;
        }
        return paymentHolder
    }

    return (
        <React.Fragment>
            {paymentSwitch()}
        </React.Fragment>
    );
}

export default Third;