import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom'
import FKImage from "../../../../../common/component/image";
import DefaultPhoto from '../../../../../assets/images/defaultAvatar.png'
import LikeButton from '../../../../../common/component/like'
import moment from 'moment'
import {numberWithCommas, slugify, categoryParser} from '../../../../../common/helper/utility'
import { ItemFlagIconLarge, PremiumLabelIcon, NewLabelIcon, SeenIcon} from '../../../../../assets/svg'
import {WTT, WTB} from '../../../../../common/component/label'

export const OtherItemCard = (props) => {
    const data = props.data ? props.data : []
    const sessionMobile = props.sessionMobile ? props.sessionMobile : 0
    const session_id = props.sessionId ? props.sessionId : 0
    const seller_id = data && data.fk_i_user_id ? data.fk_i_user_id : 0
    const social_user = data && data.oc_t_user && data.oc_t_user.social && data.oc_t_user.social[0] && data.oc_t_user.social[0].data && data.oc_t_user.social[0].data.id ? `https://graph.facebook.com/${data.oc_t_user.social[0].data.id}/picture?type=large` : null
    const user_resource = data && data.oc_t_user && data.oc_t_user.oc_t_user_resource ? data.oc_t_user.oc_t_user_resource : []
    const username = data && data.oc_t_user && data.oc_t_user.s_username ? data.oc_t_user.s_username : ''
    const items_count = data && data.oc_t_user && data.oc_t_user.items_count ? data.oc_t_user.items_count : 0
    const loggedUserId = session_id === 0 ? sessionMobile ? parseInt(sessionMobile) : 0 : session_id
    const category = data && data.oc_t_category && data.oc_t_category.oc_t_category_descriptions && data.oc_t_category.oc_t_category_descriptions.s_name ? categoryParser(data.oc_t_category.oc_t_category_descriptions.s_name) : 'others'
    const item_title = data && data.oc_t_item_description &&  data.oc_t_item_description.s_title ? data.oc_t_item_description.s_title : ''
    const item_slug = slugify(item_title)
    const id = data && data.pk_i_id ? data.pk_i_id : 0
    const cover_photo = data && data.oc_t_item_resource && data.oc_t_item_resource[0] && data.oc_t_item_resource[0].s_extension ? `${data.oc_t_item_resource[0].pk_i_id}.${data.oc_t_item_resource[0].s_extension}` : null
    const item_description = data.oc_t_item_description && data.oc_t_item_description.s_description ? data.oc_t_item_description.s_description.substring(0, 100) : ''
    const finalSLug = `/${category}/${item_slug}_i${id}?title=${item_title}&cover_photo=${cover_photo}&description=${item_description}`
    const is_new = data && data.is_new ? data.is_new : false
    const post_type = data && data.post_type ? data.post_type : ''
    const [isLiked, setIsLiked] = useState(false)
    const watchlist_count = data && data.watchlist_count ? data.watchlist_count : 0
    const likeDislike = props.likeDislike ? props.likeDislike : null
    const likeDislikeLoading = props.likeDislikeLoading ? props.likeDislikeLoading : null
    const seenCount = data.pageview_count ? data.pageview_count : 0

    const toggleLiked = (item_id) => {
        if (loggedUserId) {
          likeDislike(item_id)
        }
    
      }

    useEffect(() => {
        if (watchlist_count > 0) {
          const watcher = data.watcher && data.watcher.filter(item => parseInt(item) === parseInt(loggedUserId))
          if (watcher.length !==0) {
            setIsLiked(true)
          } else {
            setIsLiked(false)
          }
        }
        else {
          setIsLiked(false)
        }
        // eslint-disable-next-line  
      }, [data.watcher])
    return (
        <div className="related-item-card">
            {/* <Link to={`/user/${seller_id}/listing`}> */}
                {/* <div className="related-seller-avatar"> */}
                    {
                        // social_user ? 
                        // <img src={social_user} width={26} height={26} style={{borderRadius: '50%'}} /> :
                        // user_resource.length !== 0 ?
                        // <FKImage images={[user_resource]} type="related_seller_avatar" alt="avatar" /> :
                        // <img src={DefaultPhoto} width={26} height={26} alt="item owner avatar" />
                    }
                    {/* <div className="flex flex-d-column "> */}
                        {/* <p className="item-related-seller-name">{username}</p>
                        <div className="flex flex-d-row flex-align-center related-seller-more-info-container"> */}
                            {/* <p className="related-seller-followers-p">{`999 Followers`}</p> */}
                            {
                                // window.innerWidth < 600 ? null : <>
                                // {/* <p className="related-seller-followers-p">{`|`}</p> */}
                                // <p className="related-seller-listings-p">{`${items_count} Listing${items_count === 1 ? `` : `s`}`}</p>
                                // </>
                            }
                            
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
            {/* </Link> */}
            <div className={`${data.hl_expiration && moment().isSameOrBefore(data.hl_expiration) ? `bg-highlight border-r-4` : ``} ${data.ta_expiration && moment().isSameOrBefore(data.ta_expiration) ? `border-premium border-r-4` : ``}`}>                
                <Link to={finalSLug} className="w-s-nowrap over-hide text-over-ellipsis">
                <div className="p-relative">
                    <FKImage images={[data.oc_t_item_resource[0]]} type="related-ads-thumbnail" />
                    {
                        data.ta_expiration && moment().isSameOrBefore(data.ta_expiration) ?
                        <div className="p-absolute related-ads-premium-label-container">
                            <PremiumLabelIcon />
                        </div> : null
                    }
                    {
                    is_new ?
                    <div className="p-absolute top-0 right-0">
                        <NewLabelIcon />
                    </div> : null
                    }
                </div>
                <div className="w-100 flex flex-justify-between flex-align-center">
                <p className="related-ads-item-price">{`${parseInt(data.i_price) === 0 ? `Check with seller` : `${data.fk_c_currency_code} ${numberWithCommas((data.i_price / 1000000).toFixed(2))}`}`}</p>
                {
                    post_type ?
                    post_type === 'WTS' ? null 
                    : post_type === 'WTT' ? <WTT /> : <WTB /> : null
                }
                </div>
                <p className="related-ads-item-title">{item_title}</p>
                </Link>
                <p className="related-item-condition-p">{data.s_item_condition ? data.s_item_condition === 'use' ?  data.s_item_condition.charAt(0).toUpperCase() + data.s_item_condition.slice(1) + 'd' : data.s_item_condition.charAt(0).toUpperCase() + data.s_item_condition.slice(1) : ''}</p>
                <div className="flex flex-d-row w-100 flex-align-center flex-justify-between mb-4 ps-6">
                <div className="flex flex-d-row flex-align-center" >
                    <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} style={'black-border-heart'}/>
                    <p className="related-ads-item-watch-count-p ml-4">{watchlist_count}</p>
                </div>
                {
                    // parseInt(seller_id) === parseInt(session_id) ? null :
                    // <div className="flex flex-align-center">
                    //     <ItemFlagIconLarge />
                    // </div>
                }
                <div className="flex flex-d-row flex-align-center">
                    <SeenIcon color={'#232A34'} />
                    <p className="related-ads-item-watch-count-p ml-4">{seenCount}</p>
                </div>
                
            </div>
            {
                // parseInt(seller_id) === parseInt(session_id) ? 
                // <div className="related-ads-message-item-seller-container pt-8 ms-6 pb-8 cursor-pointer">
                //     <p>{`Promote`}</p>
                // </div> :
                // <div className="related-ads-message-item-seller-container pt-8 ms-6 pb-8 cursor-pointer">
                //     <p>{`Message`}</p>
                // </div>
                }
            </div>
        </div>
    )
}

export default OtherItemCard