import React from 'react';

/**
 * This gate component will validate the roles before showing the requested components
 * @return {jsx}
 */
const Gate = ({ component, Layout, pathname, session, ...rest }) => {
    // TODO role related Logic
    return <Layout {...rest} component={component} session={session}/>
};

export default Gate;
