import React from 'react';
import { useLocation } from 'react-router-dom';
import SubscribeButton from './SubscribeButton';
import UpgradeButton from './UpgradeButton';
import OrderButton from './OrderButton';
import LifetimePlanButton from './LifetimePlanButton';

const PaypalScreen = () => {
    const location = useLocation()
    const type = new URLSearchParams(location.search).get("type")

    if(type === 'subscription') return <SubscribeButton location={location}/>
    if(type === 'subscription_upgrade') return <UpgradeButton location={location}/>
    if(type === 'lifetime_plan') return <LifetimePlanButton location={location}/>
    return <OrderButton location={location}/>
}

export default PaypalScreen;