import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { avatarLoad } from '../../../redux/action'
import FKImage from '../../component/image'
import DefaultPhoto from '../../../assets/images/defaultAvatar.png'
import { getLocalStorage } from '../../helper/utility'

const Menubutton = (props) => {
    const id = props.id ? props.id : null
    // const session = getLocalStorage('fkv2_session')
    const unread = props.unread ? props.unread : null
    const {avatarIsLoading} = props.avatar
    const resource_avatar = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.oc_t_user_resource ? props.avatar.avatarData.oc_t_user_resource : null
    const social_avatar = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
    && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.picture && props.avatar.avatarData.social[0].data.picture.data
    && props.avatar.avatarData.social[0].data.picture.data.url
    ? `${props.avatar.avatarData.social[0].data.picture.data.url}` : null

    // let image;
    // if (session && session.social && session.social === 'facebook') {
    //     image = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.social && props.avatar.avatarData.social[0] 
    //     && props.avatar.avatarData.social[0].data && props.avatar.avatarData.social[0].data.picture && props.avatar.avatarData.social[0].data.picture.data
    //     && props.avatar.avatarData.social[0].data.picture.data.url
    //     ? `${props.avatar.avatarData.social[0].data.picture.data.url}` : ''
    // } else {
    //     image = props && props.avatar && props.avatar.avatarData && props.avatar.avatarData.oc_t_user_resource ? props.avatar.avatarData.oc_t_user_resource : []
    // }
    
    useEffect(() => {
        props.avatarLoad({user_id: id})
    // eslint-disable-next-line
    }, [id])
    return (
        <div onClick={()=>props.toggleShow()} id="button" className="p-relative">
            {
                id !== null? avatarIsLoading ? null :
                <>
                <div className="user-avatar mr-auto">
                    {/* {session && session.social && session.social === 'facebook' ? <img src={image} alt="social"></img> : image.length !== 0 ? <FKImage images={[image]} type="avatar" alt="normal"/> : <img src={DefaultPhoto} width={40} height={40} alt="User avatar" />} */}
                    {resource_avatar ? <FKImage images={[resource_avatar]} type="avatar" /> :
                social_avatar ? <img src={social_avatar} alt="social avatar"/> : 
                <img src={DefaultPhoto} width={40} height={40} alt="session user avatar" />
                }
                </div>
                { unread ? <p className="font-size-60 p-absolute top-n-59 right-n-3 text-orange">.</p> : null}
                </>
                :
                <div className="br-50p flex flex-justify-center flex-align-center text-align-center w-px-30 h-px-30 bg-gray">
                    <i className="far fa-user"/>
                </div>
            }
        </div>


    )
}

const mapStateToProps = store => {
    return {
      avatar: store.avatarReducer,
    };
  };
  
const mapActionToProps = {
    avatarLoad
};

export default connect(mapStateToProps, mapActionToProps)(Menubutton);
