import gql from 'graphql-tag';
import client from '../../apollo/apollo-client';

export const getBan = (payload) => {
    const { first, page, type } = payload
    try {
        const query = gql `query getBanRuleByType(
            $type: String, 
            $first: Int!,
            $page: Int!,
            $orderField: String!, 
            $orderType: SortOrder!
        )
{
    getBanRuleByType(
        type: $type,
        first: $first,
        page: $page,
        orderField: $orderField,
        orderType: $orderType
    ){
        count
        keywords {
            pk_i_id
            s_name
            s_email
            s_ip
        }
    }               
}`;
        const response = client.query({
            query: query,
            variables: {type: type, first: first, page: page, orderField: 'pk_i_id', orderType: 'DESC'
            }
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
      const error = e && e.graphQLErrors ? e.graphQLErrors : e
      throw error
    }
  }

  export const upsertBan = (payload) => {
    const { pk_i_id, s_name, s_email, s_ip, type } = payload
    const field_name = type === 'text' ? 's_name' : type === 'email' ? 's_email' : 's_ip'
    const field_value = type === 'text' ? s_name : type === 'email' ? s_email : s_ip

    const variables = {
        pk_i_id: pk_i_id ? parseInt(pk_i_id) : null,
        field_name: field_name,
        field_value: field_value,
    }

    const gql_query = gql`mutation banRuleUpsert($pk_i_id: ID, $field_name: String, $field_value: String)
    {
        banRuleUpsert(pk_i_id: $pk_i_id, field_name: $field_name, field_value: $field_value)
        {
        message
        }
    }`;
    
    try{
          const response = client.mutate({
              mutation: gql_query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
}
  
export const deleteBanText = (payload) => {
    const { pk_i_id  } = payload
  
    const  gql_query = gql`mutation banRuleDelete($pk_i_id: ID!){
        banRuleDelete(pk_i_id: $pk_i_id){
        message
      }
    }`
   
    const variables = {
        pk_i_id: pk_i_id ? parseInt(pk_i_id) : null
    }
  
    try{
          const query = gql_query;
          const response = client.mutate({
              mutation: query,
              variables: variables
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        throw e
    }
  }

  
export default {
    getBan,
    upsertBan,
    deleteBanText
}