import React, { useContext, useReducer, useEffect, useState, useRef } from 'react';
import {Link, useHistory} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import { ItemContext, PromoteContext, ItemViewContext} from '../../../../../../common/context';
import Category from '../../../../../../common/component/category'
import Related from '../../component/related-old'
import OtherItemsFromSeller from '../../component/otherItemsFromSeller'
import Loader, {CustomLoader} from '../../../../../../common/component/loader'
import LikeButton from '../../../../../../common/component/like'
import { isEmpty } from '../../../../../../common/helper/utility'
import { CustomFieldList } from '../../../new/component/CustomComponent'
import Breadcrumb from '../../../../../../common/component/breadcrumb'
import {SuccessMessage} from '../../../../../../common/component/customComponents'
import {EllipsisV, ShippedIcon, BulletIcon, NewBadgeIcon, PinnedLocation, LocationArrow, CycleIcon, InfoSubsIcon, ChevronRight, ShareIcon, BackMobileButton, ContactIcon, MessageMobileIcon, EditIconBtn, TrashIconBtn, ItemFlagIcon, AeroplanceIcon, SeenIcon, WhiteCheckIcon} from '../../../../../../assets/svg'

const CustomFieldsList = (props) => {
    const { customFields, categoryCustomFields } = props
    
    const renderCustomFields = () => {
        let mergedData = []
        if (categoryCustomFields.length > 0) {
            mergedData = categoryCustomFields.map((item) => {
                const parsedCustomField = isEmpty(customFields) ? {} : JSON.parse(customFields)
                const value = parsedCustomField && parsedCustomField[item.name] ? parsedCustomField[item.name] : ''
                return {...item, value: value}
            })
        }
        let element = []
        mergedData.map((item, index) => {
        element.push(<p key={index}><span className={'custom-field-label'}>{`${item.label} : `}</span>{item.value}</p>)
        })
        return element
    }
    return (renderCustomFields())
}

export const ContactMobileModal = () => {
    const value = useContext(ItemViewContext);
    const {setIsContactClicked, message, sendChat, handleMessageChange, sendMessageLoading, sendMessageError, sendMessageSuccess} = value
    return (
        <>
        {
            // sendMessageLoading ? <Loader /> : 
            // sendMessageError ? <p>{sendMessageError}</p> :
            // sendMessageSuccess ? <div></div>
            <div className="h-100 w-100 p-fixed contact-mobile-modal-main-container flex flex-align-end">
            <div className={`w-100 contact-mobile-modal-container flex flex-d-column ${sendMessageLoading || sendMessageError || sendMessageSuccess ? `flex-justify-center flex-align-center` : `flex-align-start`} `}>
                <>
                {
                    sendMessageLoading ? <CustomLoader /> :
                    sendMessageError ? <p>{sendMessageError}</p> :
                    sendMessageSuccess ? <SuccessMessage successText={"Message Sent Succesfully"}/> : 
                    <>
                         <div className="contact-seller-label-container">
                            <p>{`Contact Seller`}</p>
                        </div>
                        <div className="contact-input-message-container">
                            <textarea placeholder="Type your message here..." name="contact_message"  maxLength={200} onChange={(e) => handleMessageChange(e)} />
                        </div>
                        <div className="p-relative contact-number-container">
                            <label className="p-absolute contact-number-label">{`Contact Number`}</label>
                            <div className="flex flex-d-row w-100 flex-align-center">
                                <span>{`+65`}</span> &nbsp;
                                <NumberFormat format="#### ####" name="contact" className="contact-number-input" value={message.contact} onChange={(e) => handleMessageChange(e)}/>
                            </div>
                        </div>
                        {message.error ? <p className="edit-whatsapp-p-label text-red">{message.error}</p> : null}  
                        <span className="contact-number-below-label">{`Give them your number directly.`}</span>
                        <div className="flex flex-d-row w-100 flex-justify-evenly">
                            <div className="contact-cancel-btn" onClick={() => setIsContactClicked(false)}>
                                <p>{`Cancel`}</p>
                            </div>
                            <div className={`contact-send-btn ${message.body.length < 1 ? `opacity-p-6` : ``}`} onClick={() => message.body.length < 1 ? null : sendChat(message)}>
                                <AeroplanceIcon />
                                <p>{`Send`}</p>
                            </div>
                        </div>
                    </>
                }
                </>
            </div>
        </div>
        }
        </>
    )
}

export const ItemDetail = () => {
    const value = useContext(ItemContext);
    const values = useContext(PromoteContext);
    const v = useContext(ItemViewContext)
    const {sendChat, setMessage, message, handleMessageChange, sendMessageLoading, sendMessageErrror, itemId, ownerId, sendMessageSuccess, setIsShareClick, isShareClick, markAsSoldUnsold} = v
    const [readMore, setReadMore] = useState(false)
    const [descHeight, setDescHeight] = useState()
    const rect = useRef(null)

    const history = useHistory()

    let height
    useEffect(() => {
        height = rect.current ? rect.current.offsetHeight : 0;
        setDescHeight({ height: height, current: rect.current })
    }, []);

    const handleReadMoreClick = () => {
        setReadMore(!readMore)
    }

    const { item_price, item_title, item_desc, userId, itemUserId, hlExp, taExp, itemData, formatted_date, submitItemUpdateByFieldLoad, pk_i_id, updateIsLoading, updateMessage, updateError, 
        item_custom_field, category_custom_field, is_new, isShowEdit, setIsShowEdit, isShowDelete, setIsShowDelete, handleReportClick, onKeyDownInput
    } = value
    const { togglePromoteShow, isMessageClick, mobile_phone_number} = values
    const [isEllipsisClick, setIsEllipsisClick] = useState(false)
    const sold = itemData && itemData.b_mark_as_sold ? itemData.b_mark_as_sold : itemData.dt_sold ? true : false
    const {s_dm_meetup_add1, s_dm_meetup_add2, s_dm_meetup_add1_notes, s_dm_meetup_add2_notes, s_dm_shipped_details, s_youtube_link, youtube_embed_link } = itemData
    // const convertYTLink = s_youtube_link ? s_youtube_link.replace('watch?v=', 'embed/') : ''
    const convertYTLink = youtube_embed_link ? youtube_embed_link : ''
    const items_count = itemData && itemData.oc_t_user && itemData.oc_t_user.items_count ? itemData.oc_t_user.items_count : 0
    const item_condition = itemData && itemData.s_item_condition ? itemData.s_item_condition : null
    const item_type = itemData && itemData.post_type ? itemData.post_type : ''

    return (
        <div>
            <div className="w-100 flex flex-d-column item-main-details-container">
                <div className="w-100 flex flex-d-row flex-justify-between">
                    <p className="item-view-price">{item_price === 0 || item_price === '0.00' || !item_price ? `Check with Seller` : `S$${item_price}`}</p>
                    <div className="item-view-ellipsis-desktop" onClick={() => setIsEllipsisClick(!isEllipsisClick)}>
                        <EllipsisV />
                        {
                            isEllipsisClick ?
                            userId === itemUserId ?
                            <div className="p-absolute more-action-container">
                                <div className="flex flex-d-row flex-align-center edit-listing-container">
                                    <EditIconBtn />
                                   <Link to={{pathname: `/user/item/new`, query: {itemData}}}><p className="edit-listing-p">{`Edit Listing`}</p></Link> 
                                </div>
                                <div className="flex flex-d-row flex-align-center edit-listing-container" onClick={() => setIsShowDelete(true)}>
                                    <TrashIconBtn />
                                    <p className="edit-listing-p color-delete-p">{`Delete Listing`}</p>
                                </div>
                                <div className="flex flex-d-row flex-align-center " 
                                onClick={() => markAsSoldUnsold({item_id: parseInt(pk_i_id), field_name: 'b_mark_as_sold', field_value: sold ? false : true }, sold ? false : true)}
                                >
                                    <div className="h-px-12 w-px-11"/>
                                    <p className="edit-listing-p ">{`Mark as ${sold ? `Unsold` : `Sold`}`}</p>
                                </div>
                            </div> :
                            <div className="p-absolute more-action-container hpx-26">
                                <div className="flex flex-d-row flex-align-center" onClick={() => userId ? handleReportClick(pk_i_id, itemUserId) : history.push('/login')}>
                                    <ItemFlagIcon />
                                    <p className="edit-listing-p">{`Report Listing`}</p>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div>
                    <p className="item-title-p">{`${item_type ? item_type === 'WTT' || item_type === 'WTB' ? `${item_type}:` : `` : ``} ${item_title}`}</p>
                </div>
            </div>
            {
                userId !== itemUserId ? 
                <div className="item-view-contact-desktop-container">
                    <div className="w-100 flex flex-align-items">
                        <p className="item-view-contact-seller-label">{`Contact Seller`}</p>
                    </div>
                    <div className="item-view-contact-desktop-textarea-container">
                        <textarea name="contact_message" disabled={userId ? "" : "disabled"} placeholder="Type your message here..." onChange={(e) => handleMessageChange(e)} className="item-view-contact-textarea"/>
                    </div>
                    <div className="item-view-contact-number-container">
                        <label className="p-absolute contact-number-item-view-label">{`Contact Number`}</label>
                        <div className="flex flex-d-row flex-align-center w-100">
                            <span className="contact-number-code">{`+65`}</span> &nbsp;
                            <NumberFormat format="#### ####" name="contact" className="item-view-contact-input" value={message.contact} onChange={(e) => handleMessageChange(e)}/>
                            {/* <input type="number" disabled={userId ? "" : "disabled"} name="contact" className="item-view-contact-input" pattern={`/^[0-9\b]+$/`} onKeyDown={onKeyDownInput} onChange={(e) => handleMessageChange(e)} /> */}
                        </div>
                    </div>
                    <div className="flex flex-d-row mt-6 flex-justify-between">
                            <p className="item-view-contact-sect-below-label">{`Give them your number directly.`}</p>
                            {
                                sendMessageSuccess ? 
                                <div className="flex flex-d-row already-sent-btn">
                                    <WhiteCheckIcon />
                                    <p>{`Already Sent`}</p>
                                </div> :
                                userId ?
                                <div className={`flex flex-d-row flex-justify-center flex-align-center item-view-contact-send-btn cursor-pointer ${message.body.length < 1 ? `opacity-p-6` : ``}`} onClick={() => message.body.length < 1 ? null : sendChat(message)}>
                                    <AeroplanceIcon />
                                    <p>{`Send`}</p>
                                </div> :
                                <Link to={`/login`} className={`flex flex-d-row flex-justify-center flex-align-center item-view-contact-send-btn cursor-pointer ${message.body.length < 1 ? `opacity-p-6` : ``}`}>
                                    <AeroplanceIcon />
                                    <p>{`Send`}</p>
                                </Link>
                            }
                        </div>
                        {message.error ? <p className="edit-whatsapp-p-label text-red">{message.error}</p> : null}
                </div> : null
            }
            { s_dm_meetup_add1 || s_dm_shipped_details ?
            <div className="flex flex-d-row flex-align-center mb-11 mt-6">
                {
                    s_dm_meetup_add1 || s_dm_shipped_details ?
                    <div className="item-view-shipped-container">
                        <ShippedIcon />
                    </div> : null
                }
               
                {
                    s_dm_meetup_add1 || s_dm_meetup_add2 ?
                    <div className="mr-6">
                        <p className="item-view-meetup-label">{`Meetup`}</p>
                    </div> : null
                }
                {
                    s_dm_shipped_details ?
                    <div className="flex flex-d-row flex-align-center">
                      {
                        s_dm_meetup_add1 || s_dm_meetup_add2 ?
                        <BulletIcon /> : null
                      }  
                        <p className="item-view-meetup-label ml-6">{`Shipped`}</p>
                    </div> : null
                }
            </div> : null 
            }
            {
               item_condition ? item_condition.includes('new') ?
                <div className="flex flex-d-row">
                    <div className="item-view-shipped-container">
                        <NewBadgeIcon />
                    </div>
                    <div className={`flex flex-align-center mr-6`}>
                        <p className="item-view-new-p">{`New`}</p>
                    </div>
                    {
                       item_condition ? item_condition.includes('use') ?
                        <div className="flex flex-align-center">
                            {is_new ? <BulletIcon /> : null}
                            <p className="item-view-new-p ml-6">{`Use`}</p>
                        </div> : null : null
                    }
                </div> : null : null
            }
            <div>
                <p className="item-new-desc-p">{`Description:`}</p>
                <CustomFieldsList customFields={item_custom_field} categoryCustomFields={category_custom_field}/>
                <p ref={rect} className={`item-info-description ${readMore ? `expand` : `shrink`}`}>{item_desc}</p>
                {descHeight ?
                    descHeight.height >= 300 ?
                        readMore ?
                            null : <span className="readMoreBtn" onClick={handleReadMoreClick}>{`Read More`}</span>
                        : null
                    : null}
            </div>
            {
                s_dm_meetup_add1 || s_dm_meetup_add2 ?
                <div className="flex flex-d-column">
                    <div className="item-view-meetup-container-title">
                        <p className="item-view-meetup-label-title">{`Meet up`}</p>
                    </div>
                    <div className="flex flex-d-row pl-5">
                        <PinnedLocation />
                        <a href={`https://maps.google.com?q=${s_dm_meetup_add1.replace(/\s+/g, '+')}`} target="_blank"><p className="item-view-location-name">{s_dm_meetup_add1}</p></a>
                        <LocationArrow />
                    </div>
                    {
                        s_dm_meetup_add1_notes ? 
                        <div className="flex flex-d-column mt-12 w-95 align-self-end"> 
                            <p className="item-view-meetup-notes-label">{`Notes:`}</p>
                            <p className="item-view-meetup-notes-p">{s_dm_meetup_add1_notes}</p>
                        </div> : null
                    }
                    {
                        s_dm_meetup_add2 ?
                        <div className="flex flex-d-row pl-5 mt-16">
                            <PinnedLocation />
                            <a href={`https://maps.google.com?q=${s_dm_meetup_add2.replace(/\s+/g, '+')}`} target="_blank"><p className="item-view-location-name">{s_dm_meetup_add2}</p></a>
                            <LocationArrow />
                        </div> : null
                    }
                    {
                        s_dm_meetup_add2_notes ? 
                        <div className="flex flex-d-column mt-12 w-95 align-self-end"> 
                            <p className="item-view-meetup-notes-label">{`Notes:`}</p>
                            <p className="item-view-meetup-notes-p">{s_dm_meetup_add2_notes}</p>
                        </div> : null
                    }
                </div> : null
            }
            {
                s_dm_shipped_details ? 
                <div className="flex flex-d-column mt-30"> 
                    <div>
                        <p className="item-new-desc-p mt-none">{`Shipping`}</p>
                    </div>
                    <div >
                        <p className="item-info-description">{s_dm_shipped_details}</p>
                    </div>
                </div> : null
            }
            {
                youtube_embed_link ?
                <div className="flex flex-d-column mt-30">
                     <div>
                        <p className="item-new-desc-p mt-none">{`Youtube`}</p>
                    </div>
                    <div>
                        <iframe width={560} height={340} src={convertYTLink}></iframe>
                    </div>
                </div> : null
            }
        </div>
    )
}

export const ItemViewMobile = () => {
    const [readMore, setReadMore] = useState(false)
    const [descHeight, setDescHeight] = useState()
    const rect = useRef(null)

    let adsArr = []
    let height
    let history = useHistory();
    useEffect(() => {
        height = rect.current ? rect.current.offsetHeight : 0;
        setDescHeight({ height: height, current: rect.current })
    }, []);

    
    const handleReadMoreClick = () => {
        setReadMore(!readMore)
    }
    const value = useContext(ItemContext);
    const v = useContext(ItemViewContext)
    const { item_price, item_title, item_desc, userId, itemUserId, hlExp, taExp, itemData, formatted_date, submitItemUpdateByFieldLoad, pk_i_id, updateIsLoading, updateMessage, updateError, watchlist_count,
        item_custom_field, category_custom_field, is_new, isLiked, likeDislikeLoading, toggleLiked, id, togglePromoteShow, setIsContactClicked, is_phone_public, setIsMessageClick, isMessageClick, mobile_phone_number
    } = value
    const {setIsShareClick, isShareClick} = v
    const {oc_t_category, s_dm_meetup_add1, s_dm_meetup_add2, s_dm_meetup_add1_notes, s_dm_meetup_add2_notes, s_dm_shipped_details, s_youtube_link, youtube_embed_link} = itemData
    const category_name = oc_t_category && oc_t_category.oc_t_category_descriptions && oc_t_category.oc_t_category_descriptions.s_name ? oc_t_category.oc_t_category_descriptions.s_name : ''
    const convertYTLink = youtube_embed_link ? youtube_embed_link : ''
    const convert_hl_exp_date = hlExp ? moment(hlExp).format("DD MMM - hh:mm a") : null
    const [hasCurrentBoost, setHasCurrentBoost] = useState(false)
    const urlifiedItemName = item_title ? item_title.replace(/ /g, '-') : ''
    let purchasesArr = []
    const getPurchasesArr = itemData && itemData.oc_t_user && itemData.oc_t_user.purchases ? itemData.oc_t_user.purchases : []
    const item_chats_count = itemData && itemData.item_chat_count ? itemData.item_chat_count : 0
    getPurchasesArr.map((p) => {
       return p.item_id === id ? purchasesArr.push(p) : false
    })
    const breadcrumbLinks = [
        {link: '/', label: 'Home'},
        {link: `/${category_name.toLowerCase()}`, label: `${category_name}`},
        {link: `/${category_name.toLowerCase()}/${urlifiedItemName}_i${id}`, label: item_title}
    ]


    return (
        <div >
            {
                userId === itemUserId ?
                <>
                {
                     purchasesArr.map((purchases, index) => {
                        const created_date = purchases.created_at ? moment(purchases.created_at).format('DD MMM - HH:mm a') : null
                        const exp_date = created_date ? moment(purchases.created_at).add(purchases.service.days, 'd') : null
                        const isNotExpired = exp_date ? moment().isSameOrBefore(exp_date) : null
                        const boost_title = purchases.service.name ? purchases.service.name.includes('Top Ad') ? purchases.service.name.replace('Top Ad', '- Premium') : purchases.service.name.replace(' ', ' - ') : null

                        return <div key={index}>
                            {
                                isNotExpired ?
                                <>
                                <div className="flex flex-d-column ps-16">
                                    <p className="your-ads-text-label">{`Current Ads`}</p>
                                    <div className="your-ads-boost-container w-100 mt-6 pb-12">
                                        <div className="your-ads-boost-header">
                                            <p>{boost_title}</p>
                                        </div>
                                        <div className="flex flex-d-column flex-justify-start w-100 mt-6 ml-6">
                                            <p className="your-ads-activate-label">{`Activate:`}</p>
                                            <p className="your-ads-activate-date">{created_date}</p>
                                        </div>
                                        <div className="flex flex-justify-between flex-align-start mt-6 ml-6">
                                            <div className="flex flex-d-column flex-justify-start">
                                                <p className="your-ads-expired-label">{`Expiry:`}</p>
                                                <p className="your-ads-expired-date">{moment(exp_date).format('DD MMM - HH:mm a')}</p>
                                            </div>
                                            {/* <div className="flex flex-d-row flex-align-center flex-justify-center your-ads-renew-btn mr-6">
                                                <CycleIcon /> 
                                                <p className="your-ads-renew-p">{`Extend`}</p>
                                            </div> */}
                                        </div>
                                    
                                    </div>
                                    <div className="flex flex-d-row mt-8 w-100 flex-justify-between">
                                        <p className="your-ads-below-text">{`Only you will be able to see this.`}</p>
                                        <div className="flex flex-d-row ">
                                            <p className="your-ads-cancel-subs-p">{`Cancel Subscription`}</p>
                                            <InfoSubsIcon />
                                        </div>
                                    </div>
                                </div> 
                                </> : null
                            }
                        </div>
                            
                        

                     })
                }
                
                </> : null
            }
            <div className="w-100 ps-16">
                <Breadcrumb links={breadcrumbLinks}/>
            </div>
            <div className="item-main-details-container ms-16">
                <p className="item-view-mobile-price">{item_price === '0' || item_price === '0.00' || !item_price ? `Check with Seller` : `S$${item_price}`}</p>
                <p className="item-view-mobile-title">{item_title}</p>
            </div>
            <div className="flex flex-d-row flex-justify-between flex-align-center w-100 mb-12 ps-16">
                <p className="item-view-mobile-posted">{`POSTED: ${formatted_date}`}</p>
                <div className="flex flex-d-row flex-align-center" > 
                    <div className="cursor-pointer flex flex-d-row flex-align-center" onClick={() => setIsShareClick(!isShareClick)}>
                        <ShareIcon />
                        <p className="item-view-mobile-share-p">{`Share`}</p>
                    </div>
                </div>
            </div>
            {
                s_dm_meetup_add1 || s_dm_shipped_details ?
                <div className="flex flex-d-row flex-align-center mb-12 ps-16">
                    {
                        s_dm_meetup_add1 ? 
                        <>
                            <ShippedIcon />
                            <p className="ml-9 meetup-mobile-text mr-6">{`Meetup`}</p>
                            <BulletIcon />
                        </> : null
                    }
                    {
                        s_dm_shipped_details ?
                        <p className="ml-6 meetup-mobile-text">{`Shipped`}</p> : null
                    }
                </div> : null
            }
            
            {
                is_new ?
                <div className="flex flex-d-row flex-align-center ps-16">
                    <NewBadgeIcon />
                    <p className="ml-9 new-mobile-text">{`New`}</p>
                </div> : null
            }
            <div className="flex flex-d-row flex-align-center mt-12 ps-16">
                <LikeButton isLiked={isLiked} likeDislikeLoading={likeDislikeLoading} toggleLiked={toggleLiked} likeId={id} />
                <p className="ml-9 item-view-mobile-like-p">{`Favorites (${watchlist_count})`}</p>
            </div>
            <div className="flex flex-d-row flex-align-center mt-12 ps-16">
                <SeenIcon />
                <p className="ml-9 new-mobile-text">{`Seen 30 `}</p>
            </div>
            <div className="mt-30 ps-16">
                <p className="item-new-desc-p">{`Description`}</p>
                <CustomFieldsList customFields={item_custom_field} categoryCustomFields={category_custom_field}/>
                <p ref={rect} className={`item-info-description ${readMore ? `expand` : `shrink`}`}>{item_desc}</p>
                {descHeight ?
                    descHeight.height >= 150 ?
                        readMore ?
                        <span className="readMoreBtn" onClick={handleReadMoreClick}>{`Read Less`}</span> : <span className="readMoreBtn" onClick={handleReadMoreClick}>{`Read More`}</span>
                        : null
                    : null}
            </div>
            {
                s_dm_meetup_add1 ?
                <div className="mt-30 ps-16">
                    <p className="item-view-meetup-label-title">{`Meet-up`}</p>
                    <div className="flex flex-d-row"> 
                        <PinnedLocation />
                        <a href={`https://maps.google.com?q=${s_dm_meetup_add1.replace(/\s+/g, '+')}`} target="_blank"><p className="item-view-location-name">{s_dm_meetup_add1}</p></a>
                        <LocationArrow />
                    </div>
                    {
                       s_dm_meetup_add1_notes ? 
                       <div className="w-100 flex flex-justify-end">
                       <div className="flex flex-d-column mt-12 w-93 "> 
                           <p className="item-view-meetup-notes-label">{`Notes:`}</p>
                           <p className="item-view-meetup-notes-p mt-6">{s_dm_meetup_add1_notes}</p>
                       </div> </div> : null
                    }
                    {
                        s_dm_meetup_add2 ?
                        <div className="flex flex-d-row mt-16"> 
                            <PinnedLocation />
                            <a href={`https://maps.google.com?q=${s_dm_meetup_add2.replace(/\s+/g, '+')}`} target="_blank"><p className="item-view-location-name">{s_dm_meetup_add2}</p></a>
                            <LocationArrow />
                        </div> : null
                    }
                    {
                        s_dm_meetup_add2_notes ?
                        <div className="w-100 flex flex-justify-end">
                        <div className="flex flex-d-column mt-12 w-93 "> 
                            <p className="item-view-meetup-notes-label">{`Notes:`}</p>
                            <p className="item-view-meetup-notes-p mt-6">{s_dm_meetup_add2_notes}</p>
                        </div> </div> : null
                    }
                </div> : null
            }
            {
                s_dm_shipped_details ?
                <div className="mt-30 ps-16">
                    <p className="item-new-desc-p">{`Shipping`}</p>
                    <p className="item-info-description">{s_dm_shipped_details}</p>
                </div> : null
            }
            {
                convertYTLink ? 
                <div className="mt-30 mb-30 ps-16"> 
                    <p className="item-new-desc-p">{`Youtube`}</p>
                    <div className="flex flex-justify-center flex-align-center mt-6">
                        <iframe width={300} height={250} src={convertYTLink}></iframe>
                    </div>
                </div> : null
            }
             <div className={`w-100 ${window.innerWidth < 600? convertYTLink ? `ps-4` : `mt-30 ps-4` : `ps-16`}`}>
                <OtherItemsFromSeller sessionMobile={userId}/>
            </div>
            <div className={`w-100 ${window.innerWidth < 600? convertYTLink ? `ps-4 mb-30` : `mt-30 ps-4 mb-30` : `ps-16`}`}>
                <Related sessionMobile={userId}/>
            </div>
            <div className={`p-fixed w-100 bottom-0 bg-white flex flex-d-row flex-justify-evenly flex-align-center item-view-mobile-navbar`}>
                <div className={`flex flex-justify-center flex-align-center ${is_phone_public ? `` : `ml-12`}`} onClick={() => history.goBack()}>
                    <BackMobileButton />
                </div>
                {
                    userId === itemUserId ?
                    <> 
                    <Link to="/user/messages/inbox" className="item-view-mobile-chats">
                        <p>{`View ${item_chats_count > 1 ? `${item_chats_count} Chats` : `${item_chats_count} Chat`} `}</p>
                    </Link>
                    <div className="item-view-mobile-promote-btn" onClick={() => togglePromoteShow()}>
                        <p>{`Promote `}</p>
                    </div>
                    </> : 
                    <>
                    {
                        is_phone_public ? 
                        <div className="item-view-mobile-chats " onClick={() => setIsMessageClick(!isMessageClick)}>
                            {/* <ContactIcon /> */}
                            <p >{isMessageClick ? `+65 ${mobile_phone_number}` : `Contact #`}</p>
                        </div> : null
                    }
                    <div className={`item-view-mobile-promote-btn ${is_phone_public ? `` : `m-0-auto`}`} onClick={() => setIsContactClicked(true)}>
                        {/* <MessageMobileIcon /> */}
                        <p >{`Message`}</p>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}

export const ItemEditModal = () => {
    const value = useContext(ItemContext);
    const { handleEditSubmit, category, raw_price, pk_i_id, item_title, item_desc, isShowEdit, setIsShowEdit, updateIsLoading, updateMessage, updateError, item_custom_field, category_custom_field} = value
    const categoryId = category && category.pk_i_id ? category.pk_i_id : null
    const [itemInput, setItemInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }), {
        }
    );


    const customFieldParser = () => {
        const customFieldParse = category_custom_field ? category_custom_field : []

        let cfObject = {}
        if (customFieldParse.length > 0) {
          customFieldParse.map((item) => {
            const parsedCustomField = isEmpty(item_custom_field) ? {} : JSON.parse(item_custom_field)
            const value = parsedCustomField && parsedCustomField[item.name] ? parsedCustomField[item.name] : ''
            cfObject[item.name] = {...item, value: value}
          })
        }
        return cfObject
    }
    const handleCustomFieldChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        const cfObject = itemInput.custom_fields
        let finalObj = {}
        for (var k in cfObject) {
            if (cfObject[k].name === name) {
            finalObj[name] = {...cfObject[k], value: newValue}
            }
            else {
            finalObj[cfObject[k].name] = {...cfObject[k]}
            }
        }
        setItemInput({['custom_fields']: finalObj});
    }

    useEffect(() => {
        const customFieldParsed = customFieldParser()
        setItemInput({
            pk_i_id: pk_i_id,
            raw_price: raw_price,
            item_title: item_title,
            item_desc: item_desc,
            categoryId: categoryId,
            custom_fields: customFieldParsed,
        })
    }, [])

    const handleChange = (evt) => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : evt.target.value === 'false' ? false : evt.target.value;
        setItemInput({
            [name]: newValue
        });
    }

    const parsedError = (error) => {
        let errorMessage
        if (error.includes('file')) {
            errorMessage = "Item posted with no image, due to error processing your picture."
        } else {
            errorMessage = error
        }
        return errorMessage
    }

    const editSubmitHandler = evt => {
        evt.preventDefault();
          if(
            //comment next line to bypass recaptcha
            itemInput.captcha === '' ||
            itemInput.item_title === '' || itemInput.item_desc === '' || itemInput.item_desc.length < 9 || itemInput.raw_price === '' || itemInput.categoryId === ''){
            setItemInput(
              {
              error: 
              {['name']: itemInput.item_title === '' ? 'Enter title' : null, 
              ['description']: itemInput.item_desc === '' ? 'Enter description' :  null, 
              ['price']: itemInput.raw_price === '' ? 'Enter Price' : itemInput.raw_price.includes("-") ? 'Invalid Price' : itemInput.raw_price === 0 ? 'Price should not be equal to 0' : null, 
              ['category']: itemInput.categoryId === ''  ? 'Enter Category' : null} 
            })
            setItemInput({['inputError']: 'Recaptcha Error'})
          }else{
              //parse custom field
              let finalObj = {}
              for (var k in itemInput) {
                if (k === 'custom_fields') {
                  let finalObj2 = {}
                  for(var l in itemInput[k]){
                    if (itemInput[k].hasOwnProperty(l)) {
                      const value = itemInput[k] && itemInput[k][l] && itemInput[k][l].value ? itemInput[k][l].value : ''
                      const name = itemInput[k] && itemInput[k][l] && itemInput[k][l].name ? itemInput[k][l].name : ''
                      finalObj2[name] = value
                    }
                  }
                  finalObj[k] = JSON.stringify(finalObj2)
                }
              }
              const fileForSubmit = {...itemInput, custom_fields : finalObj.custom_fields }
              handleEditSubmit(fileForSubmit) 
          }
        // setUserInput({[name]: ''})
      }

    return (
        <div className={isShowEdit ? `flex contact-modal` : `hide`}>
            <div className="desktop-edit-item-modal">
                <span style={{ cursor: 'pointer', float: 'right', fontSize: '16px' }} onClick={() => setIsShowEdit(false)}>&times;</span>
                <div>
                    <div style={{ marginBottom: 10 }}>
                        <h4>Edit Item</h4>
                    </div>
                    <div className="contact-form-container">
                        <div className="input-container">
                            <Category name={'categoryId'} selectedId={itemInput.categoryId ? itemInput.categoryId : 0} onChange={event => handleChange(event)} />
                        </div>

                        <div className="input-container">
                            <div className="edit-input-container">
                                <div className="edit-input-sub-container">
                                    <div className="edit-input-main-container">
                                        <label className="edit-input-label">Title </label>
                                        <input type="text" name={'item_title'} value={itemInput.item_title ? itemInput.item_title : ''} onChange={event => handleChange(event)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomFieldList data={itemInput.custom_fields} handleCustomFieldChange={handleCustomFieldChange} />

                        <div className="input-container">
                            <div className="input-textarea-container">
                                <div className="input-textarea-sub-container">
                                    <label className="edit-input-label edit-textarea-label">Description </label>
                                    <textarea className="input-textarea" name={'item_desc'} value={itemInput.item_desc ? itemInput.item_desc : ''} onChange={event => handleChange(event)} />
                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="edit-input-container">
                                <div className="edit-input-sub-container">
                                    <div className="edit-input-main-container">
                                        <label className="edit-input-label">Price </label>
                                        <input type="number" name={'raw_price'} value={itemInput.raw_price ? itemInput.raw_price : 0} onChange={event => handleChange(event)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {updateError ? <p style={{ color: 'red', marginBottom: 10 }}>{parsedError(updateError)}</p> : null}
                        {updateMessage ? <p style={{ color: 'green', marginBottom: 10 }}>{updateMessage}</p> : null}
                        {updateIsLoading ? <p>Processing...</p> : <button className="btn primary" onClick={(e) => editSubmitHandler(e)}>Update</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ItemDeleteModal = () => {
    const value = useContext(ItemContext);
    const { isShowDelete, setIsShowDelete, pk_i_id, item_title, handleDeleteSubmit, deleteIsloading, deleteMessage, deleteError } = value
    return (
        <div className={isShowDelete ? `flex contact-modal` : `hide`}>
            <div className="delete-item-modal">
                {deleteIsloading ? <Loader /> :
                    deleteMessage ? <p>Item successfully deleted.</p> :
                        deleteError ? <p style={{ color: 'red' }}>{deleteError}</p> :
                            <>
                                <p className="delete-close" onClick={() => setIsShowDelete(false)}>&times;</p>
                                <p className="delete-title">Delete listing?</p>
                                <p className="delete-confirm-text">Are you sure you want to delete your listing item?</p>
                                <p className="delete-item-title">{item_title}</p>
                                <div className="delete-button-cont">
                                    <button className="delete-button delete-cancel" onClick={() => setIsShowDelete(false)}>Cancel</button>
                                    <button className="delete-button delete-ok" onClick={() => handleDeleteSubmit(pk_i_id)}>Delete</button>
                                </div>
                            </>
                }
            </div>
        </div>
    )
}

export const MobileNavItemView = (props) => {
    return (
        <></>
    //     <div className="w-100 item-view-topnav-container flex flex-d-row flex-align-center flex-justify-evenly">
    //     <div className="flex flex-d-row flex-align-center" onClick={() => history.goBack()}>
    //         <BackMobileButton/>
    //         <p className="item-view-topnav-text">{`Back`}</p>
    //     </div>
    //     <div className="w-100 flex flex-align-center flex-justify-center">
    //         <p className="item-view-topnav-text ml-none">{`?`}</p>
    //     </div>
    //     <div className="ellipsis-v-container">
    //         <div onClick={() => setEllipsisClick(!ellipsisClick)}>
    //             <EllipsisV />
    //         </div>
    //         {
    //             ellipsisClick ?
    //             session === sellerUserId ?
    //             <div className="p-absolute more-action-container">
    //                 <div className="flex flex-d-row flex-align-center item-view-edit-mobile">
    //                     <EditIconBtn />
    //                  <Link to={{pathname :`/user/item/new`, query: {itemData}}} > <p className="item-view-mobile-edit-actions-p">{`Edit Listing`}</p> </Link> 
    //                 </div>
    //                 <div className="flex flex-d-row flex-align-center" onClick={()=> setIsDeleteClick(true)}>
    //                     <TrashIconBtn />
    //                     <p className="item-view-mobile-delete-actions-p">{`Delete Listing`}</p>
    //                 </div>
    //             </div> : 
    //             <div className="p-absolute more-action-container hpx-26">
    //                <div className="flex flex-d-row flex-align-center item-view-report-mobile" onClick={() => handleReportClick(id, sellerUserId)}>
    //                     <ItemFlagIcon />
    //                     <p className="item-view-mobile-edit-actions-p">{`Report Listing`}</p>
    //                 </div>
    //             </div>
    //             : null
    //         }
    //     </div>
    // </div>
    )
}

export const ItemSoldModal = () => {
    const value = useContext(ItemViewContext);
    const { isShowMarkAsSold, isSold, closeSoldModal, submitSoldUnsold } = value
    const title = isSold ? 'Mark as Sold' : 'Repost?'
    const description = isSold ? 'This will Mark your item as Sold, do you want to continue?' : 'Do you want to continue?'

    return (
        <div className={isShowMarkAsSold ? `flex contact-modal` : `hide`}>
            <div className="delete-item-modal">
                            <>
                                <p className="delete-close" onClick={() => closeSoldModal()}>&times;</p>
                                <p className="delete-title">{title}</p>
                                <p className="delete-confirm-text">{description}</p>
                                <div className="delete-button-cont">
                                    <button className="delete-button delete-cancel" onClick={() => closeSoldModal()}>No</button>
                                    <button className="delete-button delete-ok" onClick={() => submitSoldUnsold()}>Yes</button>
                                </div>
                            </>
            </div>
        </div>
    )
}

