import React from 'react'

export const DividerLine = () => {
    return (
        <div className="divider-line"/>
    )
}

export default {
    DividerLine
}