import gql from 'graphql-tag';
import client from '../apollo/apollo-client';
import { getLocalStorage } from '../common/helper/utility'
import {me, meSession, meWithSubscription, user} from './string_data'

export const getUsers = (first, page) => {
    try {
        const query = gql`query oc_t_admins($first: Int!, $page: Int) {
            oc_t_admins(first: $first, page: $page) {
                data{
                    pk_i_id
                    s_name
                    s_email
                }
            }
        }
        `;
        const response = client.query({
            query: query,
            variables: { first: first, page: page},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

export const getMe = () => {
  try {
      const query = gql`query me{me{${me}}}`;
      const response = client.query({
          query: query
      });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('gql e', JSON.stringify(e))
      throw e
  }
}

export const getMeWithSubscription = () => {
  try {
      const query = gql`query me{me{${meWithSubscription}}}`;
      const response = client.query({
          query: query
      });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('gql e', JSON.stringify(e))
      throw e
  }
}

export const getUser = (id) => {
    try {
        const query = gql`query publicGetUserById($id: ID)
        {publicGetUserById(pk_i_id: $id) {${user}}}`;
        const response = client.query({
            query: query,
            variables: { id: parseInt(id)},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

export const getSession = (id, type) => {
  console.log('id', id)
  console.log('type', type)

  try {
      const query = gql`query getSession($id: Int!, $type: String!){
        getSession(id: $id type: $type){${meSession}}
      }`;
      const response = client.query({
          query: query,
          variables: { id: id, type: type}
      });
      const data = response ? response : null;
      return data;
  } catch (e) {
      console.log('gql e', JSON.stringify(e))
      throw e
  }
}

export const getAvatarUser = (id) => {

    try {
        const query = gql`query getAvatarByUser($id: ID)
        {getAvatarByUser(pk_i_id: $id) {
          pk_i_id
          s_name
          s_username
          s_email
          social {
            data
          }
          oc_t_user_resource {
            pk_i_id
            s_name
            s_content_type
            s_extension
            }
          }
        }`;
        const response = client.query({
            query: query,
            variables: { id: id}
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

export const updateUser = (payload) => {
    const id = payload.profileID
    const name = payload.profileName
    const username = payload.profileUserName
    const city = payload.profileCity
    const website = payload.profileWebsite
    const phone = payload.profilePhone
    const mobile = payload.profileMobile
    const country = payload.profileCountry
    const region = payload.profileRegion
    const resourceId = payload.profileResourceId
    const profileImageData = payload.imageData
    const responsiveness = payload.profileResponsiveness
    const bio = payload.profileBio
    const is_email_public = payload.profileIsEmailPublic
    const is_mobile_public = payload.profileIsMobilePublic
    const coverPhotoImageData = payload && payload.profileCoverPhoto ? payload.profileCoverPhoto : []

    try{
        const query = gql`mutation userUpdate($data: UserUpdateInput){
          userUpdate(data: $data){
              message
              oc_t_user{
                    pk_i_id
                    s_name
                    s_username
                    s_email
                    s_phone_land
                    s_phone_mobile
                    s_country
                    s_region
                    s_city
                    s_website
                    wallet
                		bio
                		responsive
                    dt_deactivated_date
                    oc_t_user_resource {
                        pk_i_id
                        s_extension
                    }
              }
              }
          }`;
          const response = client.mutate({
              mutation: query,
              variables: {data: {
                  "pk_i_id": id, "s_name": name, "s_username": username,
                  "s_phone_land": phone, "s_phone_mobile": mobile, "s_country": country, 
                  "s_region": region, "s_city": city, "s_website": website,
                  "resource_id": resourceId, "imageData": profileImageData,
                  "responsive": responsiveness, "bio": bio, "is_email_public": is_email_public, "is_phone_public": is_mobile_public,
                  "cover_photo": coverPhotoImageData,
                }}
          });
          const data = response ? response : null;
          return data;
    }catch(e){
        console.log('e', e)
        throw e
    }
}

export const imageCoverPhoto = (payload) => {
  console.log("gql", payload)
  const imageCoverFile = payload && payload.profileCoverPhoto ? payload.profileCoverPhoto : []
  const id = payload && payload.profileID ? payload.profileID : 0
  try{
    const query = gql`mutation upsertCoverPhoto($input: UserCoverPhotoInput!){
      upsertCoverPhoto(input: $input){
        message
        cover_photo {
          pk_i_id
          fk_i_user_id
          s_extension
          s_content_type
          s_path
        }
      }
    }`;
      const response = client.mutate({
          mutation: query,
          variables: {input: {
              "pk_i_id": null, "fk_i_user_id": id, "imageData": imageCoverFile
            }}
      });
      const data = response ? response : null;
      return data;
}catch(e){
    console.log('e', e)
    throw e
}
}

export const changePassword = (payload) => {
    let inputData = {}
    let filteredQuery = {}

    const id = payload && payload.id ? payload.id : 0
    const email = payload && payload.email ? payload.email : ''
    const currentPassword = payload && payload.currentPassword ? payload.currentPassword : ''
    const password = payload && payload.password ? payload.password : ''
    const type = payload && payload.type ? payload.type : ''

    if (email) {
        inputData = {
            "email" : email,
            "password": password,
            "type": type
        }
        filteredQuery = gql`mutation publicChangePassword($input: PublicChangePasswordInput){
          publicChangePassword(input: $input){
              message
            }
          }`

    } else {
        inputData = {
            "id" : id,
            "current_password": currentPassword,
            "password": password,
            "type": type
        }
        filteredQuery = gql`mutation userChangePassword($input: ChangePasswordInput){
          userChangePassword(input: $input){
              message
            }
          }`
    }

    try{
        const query = filteredQuery;
          const response = client.mutate({
              mutation: query,
              variables: {input: inputData}

            });
          const data = response ? response : null;
          return data;
    }catch(e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const forgotPassword = (email) => {
    try{
        const query = gql`mutation userForgotPassword($input: FkForgotPasswordInput){
          userForgotPassword(input: $input){
              message
            }
          }`;
          const response = client.mutate({
              mutation: query,
              variables: {input: { "email": email, "type": "user"}}
            });
          const data = response ? response : null;
          return data;
    }catch(e){
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        throw error
    }
}

export const fkPurchase = (payload) => {
    const { fkv2_purchased_data, userId } = payload
    const amount = fkv2_purchased_data  && fkv2_purchased_data.total_amount ? parseFloat(fkv2_purchased_data.total_amount) : fkv2_purchased_data.sg_price ? parseFloat(fkv2_purchased_data.sg_price) : 0
    const coin =  fkv2_purchased_data && fkv2_purchased_data.credit ? parseInt(fkv2_purchased_data.credit) : 0
    const service_id =  fkv2_purchased_data && fkv2_purchased_data.id ? parseInt(fkv2_purchased_data.id) : null
    const type =  fkv2_purchased_data && fkv2_purchased_data.type ? fkv2_purchased_data.type : null
    const item_id = fkv2_purchased_data && fkv2_purchased_data.item_id ? parseInt(fkv2_purchased_data.item_id) : 0
    const days = fkv2_purchased_data && fkv2_purchased_data.days ? parseInt(fkv2_purchased_data.days) : 0
    const description = fkv2_purchased_data  && fkv2_purchased_data.description ? fkv2_purchased_data.description : ''
    const payment_method = fkv2_purchased_data  && fkv2_purchased_data.payment_method ? fkv2_purchased_data.payment_method : 'coin'
    const discount = fkv2_purchased_data  && fkv2_purchased_data.discount ? parseFloat(fkv2_purchased_data.discount) : 0
    const order_id = fkv2_purchased_data  && fkv2_purchased_data.order_id ? fkv2_purchased_data.order_id : ''
    const line_items_detail = fkv2_purchased_data  && fkv2_purchased_data.line_items_detail ? fkv2_purchased_data.line_items_detail : []

    const servicesInput = {
      "user_id": userId,
      "item_id": item_id,
      "coin": coin,
      "services_id": service_id,
      "type": type,
      "days": days,
      "amount": amount,
      "description": description,
      "method": payment_method,
      "discount": discount
    }

    const stripeInput = {
      "user_id": parseInt(userId),
      "type": type,
      "method": payment_method,
      "order_id": order_id,
      "amount": amount
    };

    const paypalInput = {
      "user_id": parseInt(userId),
      "type": type,
      "method": payment_method,
      "order_id": order_id,
      "amount": amount,
      "currency": 'sgd',
      "coin": coin,
      "line_items_detail": line_items_detail
    }

    const coinInput = type.includes('individual_ad') ?
    {
      "user_id": parseInt(userId),
      "type": type,
      "method": payment_method,
      "order_id": order_id,
      "amount": amount,
      "currency": 'coin',
      "line_items_detail": line_items_detail
    } : servicesInput

    const purchaseInput = () => {
        switch (payment_method) {
        case 'stripe':
        return stripeInput
        case 'paypal':
        case 'free':
        return paypalInput
        case 'coin':
        return coinInput
        default:
        return coinInput
      }
    }
    
    console.log('purchaseInput', purchaseInput(), payment_method)
    // return null
    try{
        const query = gql`mutation fkPaymentProcess($input: PurchaseInput){
          fkPaymentProcess(input: $input){
              message
              data {
                pk_i_id
                s_name
                s_username
                s_email
                s_phone_land
                s_phone_mobile
                s_country
                s_region
                s_city
                s_website
                wallet
                dt_deactivated_date
                oc_t_user_resource {
                      pk_i_id
                      s_name
                      s_content_type
                      s_extension
                }
                purchases{
                    id
                    transaction_id
                    total_purchase
                    before_credit
                    current_credit
                    type
                    status
                    created_at
                    detail
                    payment_method
                }  
              }

            response {
              b_active
              pk_i_id
              i_price
              get_i_price
              s_contact_name
              s_contact_email
              fk_c_country_code
              ta_expiration
              hl_expiration
              oc_t_item_description{
                s_title
                s_description
              }
              oc_t_item_resource {
                pk_i_id
                fk_i_item_id
                s_name
                s_extension
                s_content_type
                s_path
              }
              oc_t_category{
                pk_i_id
                fk_i_parent_id
                oc_t_category_descriptions{
                  fk_i_category_id
                  s_name
                }
              }
              oc_t_user{
                pk_i_id
                s_name
                s_username
                oc_t_user_resource{
                  pk_i_id
                  fk_i_user_id
                  s_extension
                  s_name
                  s_content_type
                  s_path
                }
                social{
                  data
                }
                wallet
              }
              }
            }
          }`;
          const response = client.mutate({
              mutation: query,
              variables: {input: purchaseInput()}
          });
          const data = response ? response : null;
          console.log('data', data)
          return data;
    }catch(e){
        console.log('e', JSON.stringify(e))
        throw e
    }
}

export const getServices = () => {
    const fkv2_session = getLocalStorage('fkv2_session')
    const id = fkv2_session && fkv2_session.id ? parseInt(fkv2_session.id) : 0
    try {
        const query = gql`query getActiveServices($id: Int!){
          getActiveServices(id: $id){
              id
              days
              status
              sg_price
              credit
              name
              description
              type
              discount
          }
      }`;
        const response = client.query({
            query: query,
            variables: { id: id},
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}


export const getWatchlistByUser = (data) => {
    try {
        const query = gql`query getWatchlistByUser
        { getWatchlistByUser {
          pk_i_id
          watchlist{
            id
            fk_i_item_id
            items{
              pk_i_id
              dt_pub_date
              dt_sold
              i_price
              get_i_price
              b_reserved
              fk_i_user_id
              b_mark_as_sold
              ta_expiration
              hl_expiration
              watchlist_count
              post_type
              oc_t_user{
                pk_i_id
                s_username
                oc_t_user_resource{
                  pk_i_id
                  s_path
                  s_name
                  s_extension
                  s_content_type
                  fk_i_user_id
                }
                social{
                  data
                }
              }
              oc_t_item_description{
                fk_i_item_id
                s_title
                s_description
              }
              oc_t_item_resource{
                pk_i_id
                s_path
                s_name
                s_extension
                s_content_type
              }
              oc_t_category{
                oc_t_category_descriptions{
                  s_name
                }
              }
            }
          }
        }
    }`;
        const response = client.query({
            query: query,
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

export const likeDislikeByUser = (data) => {
    const { userId, itemId } = data
    try {
        const query = gql`mutation likeDislikeItem($input: LikeDislikeInput!){
        likeDislikeItem(input: $input){
                user_data {
                  pk_i_id
                  watchlist{
                    id
                    fk_i_item_id
                    items{
                      pk_i_id
                      dt_pub_date
                      i_price
                      get_i_price
                      watchlist_count
                      post_type
                      oc_t_user{
                        s_username
                      }
                      oc_t_item_description{
                        s_title
                        s_description
                      }
                      oc_t_item_resource{
                        pk_i_id
                        s_path
                        s_name
                        s_extension
                        s_content_type
                      }
                      oc_t_category{
                        oc_t_category_descriptions{
                          s_name
                        }
                      }
                      
                    }
                  }
                }
                item_data {
                  dt_pub_date
                  b_active
                  b_reserved
                  b_mark_as_sold
                  pk_i_id
                  fk_i_user_id
                  i_price
                  get_i_price
                  status
                  fk_c_currency_code
                  s_contact_name
                  s_contact_email
                  fk_c_country_code
                  ta_expiration
                  hl_expiration    
                  watchlist_count
                  s_dm_meetup_add1
                  s_dm_meetup_add2
                  s_dm_shipped_details
                  s_dm_meetup_add1_notes
                  s_dm_meetup_add2_notes
                  youtube_embed_link
                  post_type
                  watchlist_count
                  watchlist{
                    id
                    fk_i_user_id
                    fk_i_item_id
                  }
                  watcher
                  pageview_count
                  s_item_condition
                  oc_t_item_description{
                    s_title
                    s_description
                  }
                  oc_t_item_resource {
                    pk_i_id
                    fk_i_item_id
                    s_name
                    s_extension
                    s_content_type
                    s_path
                  }
                  oc_t_category{
                    pk_i_id
                    fk_i_parent_id
                    oc_t_category_descriptions{
                      fk_i_category_id
                      s_name
                    }
                  }
                  oc_t_user{
                    pk_i_id
                    s_name
                    s_username 
                    items_count   
                    oc_t_items{
                      pk_i_id
                      fk_i_user_id
                      fk_c_currency_code
                      i_price
                      post_type
                      ta_expiration
                      hl_expiration
                      pageview_count
                      oc_t_category{
                        pk_i_id
                        fk_i_parent_id
                        oc_t_category_descriptions{
                          fk_i_category_id
                          s_name
                        }
                      }
                      watcher
                      pageview_count
                      s_item_condition
                      oc_t_item_description{
                        s_title
                        s_description
                      }
                      oc_t_item_resource {
                        pk_i_id
                        fk_i_item_id
                        s_name
                        s_extension
                        s_content_type
                        s_path
                      }
                      watchlist{
                        fk_i_user_id
                        fk_i_item_id
                      }
                      watchlist_count
                      watcher
                      get_i_price
                      status
                      s_contact_name
                    }
                    purchases{
                      id
                      total_purchase
                      type
                      created_at
                      detail
                      item_id
                      service{
                        id
                        status
                        name
                        description
                        type
                        days
                      }
                    }             
                    oc_t_user_resource{
                      pk_i_id
                      fk_i_user_id
                      s_extension
                      s_name
                      s_content_type
                      s_path
                    }
                    social{
                      data
                    }
                    wallet
                    dt_deactivated_date
                  }
                }
              }
      }`;
        const response = client.mutate({
            mutation: query,
            variables: {input: {fk_i_user_id: parseInt(userId), fk_i_item_id: parseInt(itemId)}}
        });
        const data = response ? response : null;
        return data;
    } catch (e) {
        console.log('e', e)
        throw e
    }
}

export const collectPromo = (payload) => {
  const user_id = payload && payload.id ? parseInt(payload.id) : null
  const promo_id = payload && payload.promo_id ? parseInt(payload.promo_id) : null

  const  gql_query = gql`mutation collectPromo($user_id: Int, $promo_id: Int){
      collectPromo(user_id: $user_id promo_id: $promo_id){
      message
    }
  }`
 
  const variables = {
    user_id: user_id,
    promo_id: promo_id
  }

  try{
    const query = gql_query;
    const response = client.mutate({
        mutation: query,
        variables: variables
    });
    const data = response ? response : null;
    return data;
  }catch(e){
      throw e
  }
}

export default {
    getUsers,
    getUser,
    updateUser,
    imageCoverPhoto,
    changePassword,
    forgotPassword,
    getAvatarUser,
    fkPurchase,
    getServices,
    getWatchlistByUser,
    likeDislikeByUser,
    getMe,
    getSession,
    getMeWithSubscription,
    collectPromo
};
