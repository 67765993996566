import React from 'react'

export const BottomWave = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="303"
            height="54"
            fill="none"
            viewBox="0 0 303 54"
        >
            <path
            fill="#fff"
            d="M0 11.367s59.5 23.854 38 0 62.5 0 62.5 0 64 25.377 64-3.045c0-28.421 53 28.622 78 3.045 25-25.576 60.5 0 60.5 0V54H0V11.367z"
            ></path>
        </svg>
    );
}

export const  GreenCheck = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="25"
        fill="none"
        viewBox="0 0 30 25"
        >
        <path
            fill="#008806"
            d="M25.477 0l-15.27 15.275L4.5 9.568 0 14.074l10.201 10.201 1.319-1.312L29.982 4.5 25.477 0z"
        ></path>
        </svg>
    );
}
