import React from 'react'
import moment from 'moment'
import { Text, LinkText, GotoPageText, LinkTextWithIcon, DashedDivider} from './customComponents'
import { theme } from '../../../../theme'
import {MobileFooterFBLogoIcon, MobileFooterFBLikeIcon, MobileFooterIGIcon} from '../../../../../assets/svg'

const getYearToday = () => {
    return moment().format('YYYY')
}

const MobileFooter = () => {
    const { footerTexts } = theme.text
    const followLinksWithIcon = [
        {title: 'Group', icon: <MobileFooterFBLogoIcon />, path: ''},
        {title: 'Like!', icon: <MobileFooterFBLikeIcon />, path: ''},
        {title: 'Instagram', icon: <MobileFooterIGIcon />, path: ''}
    ]
    return (
        <div className="w-100 flex flex-d-column mb-30">
            <div className="w-100 custom-mobile-footer-container">
               {
                   footerTexts.map((h, i) => {
                       return (
                           <div key={i} className="flex flex-d-column mb-30">
                               <Text
                                    styleClass=""
                                    text={h.title}
                                    textClass="custom-mobile-footer-title-p"
                               />
                               {
                                   h.links.map((j, i) => {
                                       return (
                                           <>
                                            {
                                                j.externalLink ?
                                                <GotoPageText
                                                    key={`${i}0${i}`}
                                                    styleClass=""
                                                    path={j.path}
                                                    text={j.title}
                                                    textClass="custom-mobile-footer-links-p"
                                                /> :
                                                <LinkText
                                                    key={`${i}0${i}`}
                                                    styleClass=""
                                                    path={j.path}
                                                    text={j.title}
                                                    textClass="custom-mobile-footer-links-p"
                                                />
                                            }
                                           </>
                                       )
                                   })
                               }
                            </div>
                       )
                   })
               }
            </div>
            <div className="flex flex-d-column w-100 pl-16 mb-26">
                    <Text
                        styleClass=""
                        text="Follow Us"
                        textClass="custom-mobile-footer-title-p"
                    />
                    <div className="flex flex-d-row ">
                        {
                            followLinksWithIcon.map((x, i) => {
                                return (
                                    <div key={i}>
                                        <LinkTextWithIcon
                                            path={x.path}
                                            icon={x.icon}
                                            styleClass="flex flex-d-row flex-align-center"
                                            textClass="custom-mobile-footer-follow-links-p"
                                            text={x.title}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
               </div>
               <DashedDivider />
                <Text
                    styleClass="flex flex-justify-center pt-20 pb-38"
                    text={`Copyright ${getYearToday()} Fishingkaki.com`}
                    textClass="custom-mobile-footer-title-p"
                />
        </div>
    )
}

export default MobileFooter