import React, {useContext} from 'react'
import {BoostCards, Text, RadioWithText, IconContainerClickable, Divider, PriceTextWithSymbol, Button, LinkText, ActionText, MobileCard, MobileHeader, PaymentSuccess, MobileFooter, PaymentUnsuccessful} from './customComponent'
import { FkPrimeContext } from '../../../common/context'
import { theme } from '../../../common/theme'
import {CustomLoader} from '../../../common/component/loader'
import {CloseIcon, PaypalIcon, CreditCardsIcon, MobileGreaterThanIcon, BackIcon, MinusIcon, PlusIcon, CheckGreenBGIcon  } from '../../../assets/svg'
import PaypalButton from './paymentMethod/PaypalButton'
import StripeButton from './paymentMethod/StripeButton'
import { currentDate, addDate } from '../../../common/helper/utility'

export const BoostSelectionContainer = (b, i, handleBoostSelection, userBoost, setUserBoost, handleIncrementDecrement) => {
    const found = userBoost.boostOtpId.some(el => el.id === b.id);
    const offerBoostName = b && b.name ? b.name : null
    const offerBoostPrice = b && b.price ? b.price : 0
    const offerBoostDesc = b && b.description ? b.description : null
    const offerBoostId = b && b.id ? b.id : 0
    const action = found ? offerBoostName.includes('Listing') ? null : 'remove' : 'add'
    const {fkPrimeCoverDescription, fkPrimeYouTubeDescription} = theme.text


    return (
        <div className={found ? `border-success lifetime-plans-offer-card-container` : `lifetime-plans-offer-card-container`} onClick={() => offerBoostName.includes('Listing') ? null : handleBoostSelection('otp', offerBoostId, b, action)} key={i}>
            <div className="lifetime-header-offer-bost-card-container" >
                <div className="flex flex-d-column flex-justify-center pt-41"  onClick={() => offerBoostName.includes('Listing') ?  handleBoostSelection('otp', offerBoostId, b, action) : null}>
                    <p className="fk-prime-otp-title-p">{offerBoostName}</p>
                    <div className="fk-prime-price-container">
                        <p className="fk-prime-price-symbol-p">{`S$`}</p>
                        <p className="fk-prime-price-text-p fk-prime-price-text-p-not-0">{offerBoostPrice.toFixed(2)}</p>
                    </div>
                </div>
                <div className="pt-10">
                    {
                        offerBoostName.includes('Listing') ? 
                        <div className="w-100 flex flex-d-row boost-incremation-container flex-justify-center flex-align-center">
                            <div className="minusIconBoostIncrementContainer" onClick={() => handleIncrementDecrement('subtract', b.id, b.sg_price, b)}>
                                <MinusIcon />
                            </div>
                            <p className="w-100 flex flex-justify-center flex-align-center boost-increment-quantity-p">{userBoost.otpMultiQuantity}</p>
                            <div className="plusIconBoostIncrementContainer" onClick={() => handleIncrementDecrement('add', b.id, b.sg_price, b)}>
                                <PlusIcon />
                            </div>
                        </div> : null
                    }
                    <p className={`lifetime-plans-offer-boost-desc-p ${offerBoostName.includes('Listing') ? `mt-20`: `mt-10`}`} 
                    onClick={() => offerBoostName.includes('Listing') ? handleBoostSelection('otp', offerBoostId, b, action) : null}>{offerBoostDesc}</p>
                </div>
                {
                    offerBoostName.includes('Listing') ? null :
                    <div className="lifetime-plans-fkprime-discount-label-container">
                        <p className="lifetime-plans-fkprime-discount-label-p">{offerBoostName.includes('Cover') ? fkPrimeCoverDescription : fkPrimeYouTubeDescription}</p>
                        {/* <p className="lifetime-plans-fkprime-discount-int-p">{`50% off`}</p> */}
                    </div>
                }
            </div>
            {
                found ? 
                <div className={`p-absolute fk-prime-selected-check-container-small `}>
                    <CheckGreenBGIcon />
                </div> : null
            }
        </div>
    )
}

export const ModalComponent = () => {
    const {userBoost, handlePurchase, purchaseSelected, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, stripeError, handleSetPurchaseLifeTimeModal} = useContext(FkPrimeContext)
    const {boostOtpTotal} = userBoost

    // boostOtpTotal.map((b) => { return choices.push(b.id === "28" ? b.price * userBoost.otpMultiQuantity : b.price)})
    // const sum = choices && choices.length > 0 && choices.reduce((a, b) => {
    //     return a + b 
    // })

    return (
        <div className="w-100 h-100 p-fixed top-0 left-0 purchase-main-body-modal-container">
            <div className="purchase-main-modal-body-container mt-30 p-20">
                {
                    isLoadingPurchaseCoin ? <CustomLoader /> :
                    purchaseSuccessMessage ? <PaymentSuccess /> :
                    errorPurchaseCoin ? <PaymentUnsuccessful />:
                                stripeError ? <div className="flex flex-d-column flex-justify-center flex-align-center">
                                    <PaymentUnsuccessful />
                                    <p>{stripeError}</p>
                                </div> :
                    <>
                        <div className="w-100 flex flex-justify-end">
                            <IconContainerClickable 
                                styleClass="cursor-pointer"
                                clickFunc={() => handleSetPurchaseLifeTimeModal(false)}
                                Icon={<CloseIcon/>}
                            />
                        </div>
                        <div className="pl-12 pr-12">
                            <Text
                                styleClass="fk-prime-purchase-modal-title-container mt-34 mb-30"
                                text="Select Payment Method"
                                textClass="fk-prime-purchase-modal-title-p"
                            />
                            <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                                <RadioWithText 
                                    styleClass="flex flex-d-row flex-align-center"
                                    value="paypal"
                                    radioClass="item-condition-radios radio custom-radio-ui"
                                    onChangeFunc={handlePurchase}
                                    text="Paypal"
                                    textClass="payment-radio-text-p"
                                    purchaseSelected={purchaseSelected}
                                />
                                <PaypalIcon />
                            </div>
                            <div className="flex flex-d-row flex-justify-between flex-align-center">
                                <RadioWithText 
                                    styleClass="flex flex-d-row flex-align-center"
                                    value="credit"
                                    radioClass="item-condition-radios radio custom-radio-ui"
                                    onChangeFunc={handlePurchase}
                                    text="Credit/Debit Card"
                                    textClass="payment-radio-text-p"
                                    purchaseSelected={purchaseSelected}
                                />
                                <CreditCardsIcon />
                            </div>
                            <div className="mt-30">
                                <Divider />
                            </div>
                            <div className=" mt-30 mb-20">
                                <Text 
                                    styleClass="w-100 flex flex-justify-center flex-align-center"
                                    text="Purchase"
                                    textClass="fk-prime-purchase-modal-title-p"
                                />
                                <Text
                                    styleClass="w-100 flex flex-justify-center flex-align-center"
                                    text={currentDate()}
                                    textClass=""
                                />
                            </div>
                            {
                                userBoost.boostOtpDetails.map((b, i) => {
                                    const price = b.name.includes("Listing") ? parseFloat(b.price) * parseFloat(userBoost.otpMultiQuantity) : b.price
                                    return (
                                        <div key={i} className="flex flex-d-row flex-justify-between mt-20">
                                            <div className="flex flex-d-column">
                                                <Text
                                                    styleClass=""
                                                    text={`${b.name}`}
                                                    textClass="fk-prime-purchase-title-p"
                                                />
                                                <Text
                                                    styleClass=""
                                                    text={`${b.name.includes("Listing") ? `x${userBoost.otpMultiQuantity}` : `x1`}`}
                                                    textClass="fk-prime-purchase-subtitle-p"
                                                />
                                            </div>
                                            <div className="flex flex-d-column">
                                                <PriceTextWithSymbol
                                                    styleClass="flex-justify-end fk-prime-purchase-price-container"
                                                    symbol="S$"
                                                    symbolClass="fk-prime-purchase-symbol-p"
                                                    priceClass="fk-prime-purchase-price-p"
                                                    price={price.toFixed(2)}
                                                />
                                                <Text
                                                    styleClass="flex flex-justify-end"
                                                    text={`Lifetime`}
                                                    textClass="fk-prime-purchase-due-date-p"
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                                <Text
                                    styleClass=""
                                    text="Total"
                                    textClass="fk-prime-purchase-total-label-p"
                                />
                            <PriceTextWithSymbol
                                    styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                                    symbol="S$"
                                    symbolClass="fk-prime-purchase-total-symbol-p"
                                    priceClass="fk-prime-purchase-total-price-p"
                                    price={boostOtpTotal ? boostOtpTotal.toFixed(2) : 0}
                                />
                            </div>
                            <div className="mt-30 mb-30">
                            {
                            // isLoadingPurchaseCoin ? 'Processing...' :
                            //     purchaseSuccessMessage ? <p style={{color: 'green'}}>{purchaseSuccessMessage}</p> :
                            //         errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> :
                            //             stripeError ? <p style={{color: 'red'}}>{stripeError}</p> :
                                        purchaseSelected === '' ? 
                                        <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                            <p className="fk-prime-purchase-p">{`Pay`}</p>
                                        </div> :
                                        purchaseSelected === 'paypal' ? <PaypalButton /> : <StripeButton/>
                            }
                            </div>
                            <div className=" fk-prime-purchase-modal-footer-container">
                                <Text
                                    styleClass=""
                                    text="By purchasing any of the above, you accept our"
                                    textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                                />
                                <LinkText
                                    path="/terms-of-use"
                                    text="Terms of Services."
                                    textClass="fk-prime-purchase-modal-footer-link-p"
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export const LifetimeMobilePurchaseModal = () => {
    const {userBoost, handlePurchase, purchaseSelected, isLoadingPurchaseCoin, purchaseSuccessMessage, errorPurchaseCoin, stripeError, handleSetPurchaseLifeTimeModal, handleBuyCoinsModal} = useContext(FkPrimeContext)
    const {boostOtpTotal} = userBoost

    // const {fkPrimePurchaseDisclaimer} = theme.text
    // let choices = []
    return (
        <div className="purchase-main-body-mobile-modal-container">
             <MobileHeader 
                styleClass="w-100 fk-prime-mobile-header-container"
                leftStyleClass="flex flex-d-row flex-align-center"
                leftTextClass="fk-prime-left-header-text-p"
                leftIcon={ <BackIcon />}
                leftText={`Back`}
                centerStyleClass="fk-prime-center-header-container"
                centerTextClass="fk-prime-center-header-text-p"
                centerText="Subscriptions"
                rightStyleClass="fk-prime-right-header-container"
                />
                <Divider />
                {
                    isLoadingPurchaseCoin ? <div className="w-100 h-100 flex flex-justify-center flex-align-center text-align-center"> <CustomLoader /> </div> : 
                    purchaseSuccessMessage ? <div className="w-100 h-100 flex flex-justify-center flex-align-center text-align-center"><PaymentSuccess /> </div> :
                    <>
                        <div>
                    <div className="mobile-purchase-fk-prime-container">
                        <Text
                        styleClass="fk-prime-purchase-modal-title-container mt-34 mb-30"
                        text="Select Payment Method"
                        textClass="fk-prime-purchase-modal-title-p"
                        />
                         <div className="flex flex-d-row flex-justify-between flex-align-center mb-20">
                            <RadioWithText 
                                styleClass="flex flex-d-row flex-align-center"
                                value="paypal"
                                radioClass="item-condition-radios radio custom-radio-ui"
                                onChangeFunc={handlePurchase}
                                text="Paypal"
                                textClass="payment-radio-text-p"
                                purchaseSelected={purchaseSelected}
                            />
                            <PaypalIcon />
                        </div>
                        <div className="flex flex-d-row flex-justify-between flex-align-center">
                            <RadioWithText 
                                styleClass="flex flex-d-row flex-align-center"
                                value="stripe"
                                radioClass="item-condition-radios radio custom-radio-ui"
                                onChangeFunc={handlePurchase}
                                text="Credit/Debit Card"
                                textClass="payment-radio-text-p"
                                purchaseSelected={purchaseSelected}
                            />
                            <CreditCardsIcon />
                        </div>
                    </div>
                <div className="mt-30">
                    <Divider />
                </div>
                <div className="mobile-purchase-fk-prime-container">
                    <div className=" mt-30 mb-20">
                        <Text 
                            styleClass="w-100 flex flex-justify-center flex-align-center"
                            text="Purchase"
                            textClass="fk-prime-purchase-modal-title-p"
                        />
                    </div>
                    {
                        userBoost.boostOtpDetails.map((b, i) => {
                            const price = b.name.includes("Listing") ? parseFloat(b.price) * userBoost.otpMultiQuantity : b.price
                            return (
                                <div key={i} className="flex flex-d-row flex-justify-between mt-20">
                                    <div className="flex flex-d-column">
                                        <Text
                                            styleClass=""
                                            text={`${b.name}`}
                                            textClass="fk-prime-purchase-title-p"
                                        />
                                        <Text
                                            styleClass=""
                                            text={`${b.name.includes("Listing") ? `x${userBoost.otpMultiQuantity}` : `x1`}`}
                                            textClass="fk-prime-purchase-subtitle-p"
                                        />
                                    </div>
                                    <div className="flex flex-d-column">
                                        <PriceTextWithSymbol
                                            styleClass="flex-justify-end fk-prime-purchase-price-container"
                                            symbol="S$"
                                            symbolClass="fk-prime-purchase-symbol-p"
                                            priceClass="fk-prime-purchase-price-p"
                                            price={price.toFixed(2)}
                                        />
                                        <Text
                                            styleClass="flex flex-justify-end"
                                            text={`Lifetime`}
                                            textClass="fk-prime-purchase-due-date-p"
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                     <div className="flex flex-d-row flex-justify-between fk-prime-purchase-total-price-container">
                        <Text
                            styleClass=""
                            text="Total"
                            textClass="fk-prime-purchase-total-label-p"
                        />
                       <PriceTextWithSymbol
                            styleClass="flex flex-justify-end flex-align-center fk-prime-purchase-price-container"
                            symbol="S$"
                            symbolClass="fk-prime-purchase-total-symbol-p"
                            priceClass="fk-prime-purchase-total-price-p"
                            price={boostOtpTotal ? boostOtpTotal.toFixed(2) : 0}
                        />
                    </div>
                </div>
                <Divider />
                    {/* <div className="mt-30 mb-30">
                    {isLoadingPurchaseCoin ? 'Processing...' :
                        purchaseSuccessMessage ? <p style={{color: 'green'}}>{purchaseSuccessMessage}</p> :
                            errorPurchaseCoin ?  <p style={{color: 'red'}}>{errorPurchaseCoin}</p> :
                                stripeError ? <p style={{color: 'red'}}>{stripeError}</p> :
                                purchaseSelected === 'paypal' ? <PaypalButton /> : purchaseSelected === 'stripe' ? <StripeButton/> : 
                                <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                    <p className="fk-prime-purchase-p">{`Pay`}</p>
                                </div>
                    }
                    </div> */}
                    {/* <div className=" fk-prime-purchase-modal-footer-container"> */}
                    <div className="mt-20 w-100 flex flex-d-column flex-justify-center flex-align-center text-align-center">
                        <Text
                                styleClass=""
                                text="By purchasing any of the above, you accept our"
                                textClass="fk-prime-purchase-modal-footer-disclaimer-p"
                            />
                            <LinkText
                                path="/terms-of-use"
                                text="Terms of Services."
                                textClass="fk-prime-purchase-modal-footer-link-p"
                            />
                    </div>
                    {/* </div> */}
                </div>
                <MobileFooter boost={userBoost.boostOtpTotal} buyCoinsFunc={handleBuyCoinsModal} customButton={purchaseSelected === 'paypal' ? <div className="mobile-fk-prime-purchase-paypal-container-btn"><PaypalButton /> </div> : purchaseSelected === 'stripe' ?  <div className="mobile-fk-prime-purchase-paypal-container-btn"><StripeButton/> </div> : 
                                <div className={`opacity-p-6 fk-prime-purchase-btn`} >
                                    <p className="fk-prime-purchase-p">{`Pay`}</p>
                                </div>}/>
                    </>
                }
                
        </div>
    )
}