import React, { useContext, useEffect, useState } from 'react';
import { CoinContext } from "../../index";
import { TosModal } from './customComponent'
import { theme } from '../../../../../../common/theme'
import First from './steps/first'
import Second from './steps/second'
import Third from './steps/third'
import { MoneyIcon } from '../../../../../../assets/svg'


const GetCoinsModal = (props) => {
    const [tosModal, setTosModal] = useState(false)
    const value = useContext(CoinContext);
    const amountCoin = props && props.amountCoin ? props.amountCoin : ''

    console.log("coins value", value)

    const handleModalClick = () => {
        if (tosModal) {
            setTosModal(false)
        }
        else {
            setTosModal(true)
        }
    }

    // useEffect(() => {
    //     value.getServices(true)
    //     // eslint-disable-next-line
    // }, [])

    const stepSwitch = () => {
        let stepHolder = []
        switch (value.step) {
            case 1:
                stepHolder = <First />
                break;

            case 2:
                stepHolder = <Second />
                break;

            case 3:
                stepHolder = <Third />
                break;

            default:
                break;
        }
        return stepHolder
    }

    return (
        <>
            <div style={{
                display: value.isShow ? 'block' : 'none',
                position: 'fixed',
                zIndex: 3,
                paddingTop: window.innerWidth < 600 ? '40px' : '100px',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'rgba(0,0,0,0.4)',
            }}>
                <div style={styles.modalContent}>
                    <div style={{ flex: 1, marginBottom: 30 }}>
                        <span style={styles.close} onClick={() => value.toggleIsShow()}>&times;</span>
                    </div>

                    <div>
                        <div style={{ textAlign: 'center', marginBottom: `${value.step === 3 ? `0px` : `30px`}` }}>
                            <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '20px', lineHeight: '24px', letterSpacing: '0.44px', marginBottom: 6 }} className="text-black">{value.step === 1 ? `Get more coins` : value.step === 2 || value.purchaseData.payment_method === 'paypal' ? `Select payment method` : `Enter Payment Details`}</p>
                            {value.step === 1 ? <small>{`Save more with bigger bundles`}</small> : null}
                        </div>
                        {
                            value.step === 1 ?
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 16, borderBottom: '0.5px solid #C4C4C4', marginBottom: '30.5px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontStyle: 'normal', fontWeight: '500', fontSize: 16, lineHeight: '24px', letterSpacing: '0.44px', color: '#232A34' }}>{`Current Coins`}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <MoneyIcon />
                                        <p style={{ marginLeft: '4.6px' }}>{amountCoin ? amountCoin : 0}</p>
                                    </div>
                                </div> :
                                null

                        }
                    </div>
                    <div style={{ flex: 1 }}>
                        {stepSwitch()}
                    </div>
                    {/* <div style={styles.purchaseTOS}>
                <p>{`${theme.text.purchaseTOS}`}</p>
                <p><a href={`${theme.text.forumLinks}tos.php`} style={{textDecoration: 'underline'}}>{`Terms of Service`}</a></p>
                </div> */}
                    <div style={{ margin: '6px auto' }, styles.purchaseTOS} onClick={handleModalClick}>
                        <p className='promote-text promote-text-4' style={{ width: '90%' }}>{theme.text.purchaseTOS}<span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{`Terms of Use`}</span> </p>
                    </div>
                </div>
            </div>
            {tosModal ?
                <div style={{ width: '100%', height: '100%', position: 'fixed', zIndex: '4', top: '0', left: '0', background: 'rgba(44, 62, 80,0.3)', display: 'flex', justifyContent: 'center', paddingTop: '10px', overflow: 'auto', paddingBottom: '100px' }}>
                    <TosModal click={handleModalClick} />
                </div> : null}
        </>
    );
}

const styles = {
    modalContent: {
        backgroundColor: '#fefefe',
        margin: 'auto',
        padding: 16,
        border: '1px solid #888',
        width: window.innerWidth < 600 ? '90%' : '50%',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 350,
    },
    purchaseTOS: {
        borderTop: '0.5px solid #C4C4C4',
        paddingTop: '10.5px',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '12px',
        lineHeight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.44px',
        color: '#232A34'
    },

    close: {
        color: '#aaaaaa',
        float: 'right',
        fontSize: 28,
        fontWeight: 'bold',
        cursor: 'pointer'
    },

    hover: {
        color: '#000',
        textDecoration: 'none',
        cursor: 'pointer',
    }
};

export default GetCoinsModal;