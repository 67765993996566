import React, { useContext } from 'react'
import { theme } from '../../../../common/theme'
import { ReportingSelect } from '../../../../common/component/reporting/CustomComponent'
import { PromoteContext } from '../../../../common/context'

export const TopAd = (props) => {
    const label = props.label ? props.label : ''
    return(
        <div className="topAd">
            <p style={{margin: 0, padding: 0, color: 'rgb(241, 242, 246)', fontWeight: '700', fontSize: '15px'}}>{label}</p>
        </div>
    )
}  

export const ReportModal = () => {
    const value = useContext(PromoteContext)
    const { reportShow, setReportShow, handleReportSubmit, reportInput, isLoadingReport, reportMessage, reportError } = value
    return(
        <div className={reportShow ? `flex modal-index-container` : `hide`}>
            <div className="modal-report-main-container">
                {
                    isLoadingReport ? <div className="modal-index-head"></div> :
                    reportMessage ? <div className="modal-index-head"></div> :
                    reportError ? <div className="modal-index-head"></div> :
                        <div className="modal-index-head">
           
                        <div style={{padding: '0px 15px'}}>
                            <p style={{margin: 0, fontSize: 20, fontWeight: '600'}}>{theme.text.reportListingHeader}</p>
                        </div>
                        <div >
                            <span style={styles.close} onClick={() => setReportShow(false)}>&times;</span>
                        </div>
                    </div>
                }

                <div style={{flex: 1, marginTop: '10px'}}>
                    {   
                        isLoadingReport ? <p style={{textAlign: 'center'}}>Submitting...</p> :
                        reportMessage ? <p style={{color: 'green', textAlign: 'center'}}>{reportMessage}</p> :
                        reportError ? <p style={{color: 'red', textAlign: 'center'}}>{reportError}</p> :
                        <React.Fragment>
                            <ReportingSelect />
                            {
                            reportInput && reportInput.report_reason ? 
                            <div className="modal-report-btn-container">
                                <button type="button" className="modal-report-btn" disabled={reportInput && reportInput.report_reason ? false : true } onClick={() => handleReportSubmit()}>Submit</button>
                            </div>
                                    : null
                            }
                        </React.Fragment>
                    }

                </div>
            </div>
        </div>
    )
}

export const AdsItemCard = () => {
    return (
        <div className="regular-ad">
          <img src={`https://ntknowledge.com/wp-content/uploads/2020/02/Google-Ads-1-1.jpg`} width="175px" height="255px" alt="google ads"/>
        </div>
    )
}

const styles = {
    modalContent: {
      backgroundColor: '#fefefe',
      margin: 'auto',
      padding: 20,
      border: '1px solid #888',
      width: '90%',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    
    close: {
      color: '#aaaaaa',
      float: 'right',
      fontSize: 28,
      fontWeight: 'bold',
      cursor: 'pointer'
    },
    
    hover: {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }
};


