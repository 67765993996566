import { ADMIN } from '../../constant';
import { takeLatest, put } from "redux-saga/effects";
import { adminItemSuccess, adminItemFailed, adminItemDetailSuccess, adminItemDetailFailed,
    adminUpdateItemSuccess, adminUpdateItemFailed, adminItemSuggestSuccess, adminItemSuggestFailed,
    adminItemByUserSuccess, adminItemByUserFailed, adminReportedItemSuccess, adminReportedItemFailed,
    adminDeleteItemSuccess, adminDeleteItemFailed
} from "../../action";
import admin from '../../../graphql/admin/item'
import { theme } from '../../../common/theme'

//fetch item
function* adminItems(action) {
    const { payload } = action
    try{
        const result = yield admin.getItems(payload)
        if(result === 403) {
            yield put(adminItemFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data.getAllItems ? result.data.getAllItems : null
            yield put(adminItemSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminItemFailed(errorMessage))
    }
}

export function* watchAdminItems() {
    yield takeLatest(ADMIN.ITEM.LOAD, adminItems);
}

//fetch item Detail
function* adminGetItemDetail(action) {
    const { payload } = action

    try{
        const result = yield admin.getItemDetail(parseInt( payload.pk_i_id))
        if(result === 403) {
            yield put(adminItemDetailFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.adminGetItemById ? result.data.adminGetItemById : {}
            yield put(adminItemDetailSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('saga e', e)
        yield put(adminItemDetailFailed(errorMessage))
    }
}

export function* watchAdminGetItemDetail() {
    yield takeLatest(ADMIN.ITEM_DETAIL.LOAD, adminGetItemDetail);
}

//update item
function* adminUpdateItem(action) {
    const { payload } = action
    try{
        const result = yield admin.updateItem(payload)
        if(result === 403) {
            yield put(adminUpdateItemFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.itemAdminUpdate ? result.data.itemAdminUpdate : {}
            yield put(adminUpdateItemSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminUpdateItemFailed(errorMessage))
    }
}

export function* watchAdminUpdateItem() {
    yield takeLatest(ADMIN.ITEM_UPDATE.LOAD, adminUpdateItem);
}

//suggest item
function* adminSuggestItem(action) {
    const { payload } = action
    try{
        const result = yield admin.searchItemsForSuggest(payload)
        if(result === 403) {
            yield put(adminItemSuggestFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.itemAllSearchSuggest ? result.data.itemAllSearchSuggest : []
            yield put(adminItemSuggestSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminItemSuggestFailed(errorMessage))
    }
}

export function* watchAdminSuggestItem() {
    yield takeLatest(ADMIN.ITEM_SUGGEST.LOAD, adminSuggestItem);
}

//items by user
function* adminItemsByUser(action) {
    const { payload } = action
    try{
        const result = yield admin.getItemsByUser(payload)
        if(result === 403) {
            yield put(adminItemByUserFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data  && result.data.getItemsByUser && result.data.getItemsByUser.data ? result.data.getItemsByUser.data : []
            yield put(adminItemByUserSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminItemByUserFailed(errorMessage))
    }
}

export function* watchAdminItemsByUser() {
    yield takeLatest(ADMIN.ITEM_BY_USER.LOAD, adminItemsByUser);
}

//fetch reported items
function* adminReportedItems(action) {
    const { payload } = action    
    try{
        const result = yield admin.getReportedItems(payload)
        if(result === 403) {
            yield put(adminReportedItemFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data  && result.data.getReportedByType ? result.data.getReportedByType : {}
            yield put(adminReportedItemSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminReportedItemFailed(errorMessage))
    }
}

export function* watchAdminReportedItems() {
    yield takeLatest(ADMIN.REPORT.ITEM.LOAD, adminReportedItems);
}

//delete item
function* adminDeleteItem(action) {
    const { payload } = action

    try{
        const result = yield admin.deleteItem(payload)
        if(result === 403) {
            yield put(adminDeleteItemFailed('Something went wrong try again!'))
        }
        else {
            const data = result.data && result.data.adminItemDelete ? result.data.adminItemDelete : {}
            yield put(adminDeleteItemSuccess(data));
        }
    } catch (e) {
        const error = e && e.graphQLErrors ? e.graphQLErrors : e
        const errorMessage = error && error[0] && error[0].extensions && error[0].extensions.validation 
            ? error[0].extensions.validation : 
              error && error[0] && error[0].message ? error[0].message : theme.text.error.default
        console.log('e', JSON.stringify(e))
        yield put(adminDeleteItemFailed(errorMessage))
    }
}

export function* watchAdminDeleteItem() {
    yield takeLatest(ADMIN.ITEM_DELETE.LOAD, adminDeleteItem);
}