import { CHAT, SEND_CHAT, ADD_ROOM, SEND_REVIEW, SET_CHAT_ITEM, DELETE_CHAT } from '../constant'    
    const initialState = {
        isLoading: false,
        data: [],
        error: false,

        sendIsLoading: false,
        sendData: [],
        sendMessage: false,
        sendError: false,

        addRoomIsLoading: false,
        addRoomError: false,

        activeIndex: 0,

        sendReviewIsLoading: false,
        reviewData: null,
        sendReviewMessage: false,
        sendReviewError: false,

        mobileIsThread: false,

        deleteChatIsLoading: false,
        deleteChatData: [],
        deleteChatMessage: false,
        deleteChatError: false,
    }
    
    export default (state = initialState, action) => {
        switch(action.type) {
            case CHAT.LOAD:
            return {...state, isLoading: true, error: null}

            case CHAT.SUCCESS:
            return {...state, isLoading: false, data:  state.data.concat(action.payload), error: null, page: state.page +1}

            case CHAT.ADD_MESSAGE:
            let updatedData = []
            state.data.map((item) => {
                if (item.id === action.payload.chat_room_id.toString()) {
                    updatedData.push({...item, last_message: action.payload.body, chat_messages : item.chat_messages.concat(action.payload)})
                } else {
                    updatedData.push(item)
                }
                return []
            })
            return {...state, data:  updatedData}

            //add temporary chat room
            case CHAT.ADD_ROOM:
                const newElement = [action.payload]
            return {...state, data:  newElement.concat(state.data)}

            case CHAT.UPDATE_UNREAD:
                let updatedData2 = []
                let updatedChat = []
                const userId = action.payload && action.payload.userId ? parseInt(action.payload.userId) : 0
                const chatRoomId = action.payload && action.payload.chat_room_id ? action.payload.chat_room_id : 0
                // eslint-disable-next-line 
                state.data.map((item) => {
                    if (item.id === chatRoomId) {
                        // eslint-disable-next-line 
                        item.chat_messages.map(chat => {
                            if (!chat.read_at && (chat.sender_id !== userId)) {
                                updatedChat.push({...chat, read_at: Date.now()})
                            }
                            else {
                                updatedChat.push(chat)
                            }
                        })
                        updatedData2.push({...item, chat_messages: updatedChat})
                    }
                    else {
                        updatedData2.push(item)
                    }
                })
            return {...state, data:  updatedData2}

            case CHAT.FAIL:
            return {...state, isLoading: false, error: action.payload}

            case SEND_CHAT.LOAD:
            return {...state, sendIsLoading: true, sendError: null, sendMessage: false}

            case SEND_CHAT.SUCCESS:
            return {...state, sendIsLoading: false, sendData:  state.sendData.concat(action.payload), error: null, sendMessage: 'Done' }

            case SEND_CHAT.FAIL:
            return {...state, sendIsLoading: false, sendError: action.payload, sendMessage: false }

            case SEND_CHAT.RESET:
            return {...state, sendIsLoading: false, sendError: false, sendMessage: false }

            case ADD_ROOM.LOAD:
            return {...state, addRoomIsLoading: true, addRoomError: null}

            case ADD_ROOM.SUCCESS:
                const chat_room_id2 = action.payload && action.payload.id ? parseInt(action.payload.id) : null
                let updatedChatRoom = []
                let newItem = true
                state.data.map((item) => {

                    const item_id = item.item && item.item.pk_i_id || 0
                    const inner_chat_room_id = item.id ? parseInt(item.id) : null
                    if(!inner_chat_room_id){
                        console.log('no id')
                    }
                    else if (inner_chat_room_id && inner_chat_room_id === chat_room_id2) {
                        newItem = false
                        updatedChatRoom.push(action.payload)
                    } 
                    else {
                        updatedChatRoom.push(item)
                    }
                })

                if (newItem) {
                    updatedChatRoom.unshift(action.payload)
                }

            return {...state, addRoomIsLoading: false, addRoomError: null, data:  updatedChatRoom}

            case ADD_ROOM.FAIL:
            return {...state, addRoomIsLoading: false, addRoomError: action.payload}

            case CHAT.SET_ACTIVE_INDEX:
            return {...state, activeIndex: action.payload}   

            case SEND_REVIEW.LOAD:
            return {...state, sendReviewIsLoading: true, sendReviewError: null, sendMessage: false}

            case SEND_REVIEW.SUCCESS:
                const review = action.payload && action.payload.review ? action.payload.review : null
                const reviewMessage = action.payload && action.payload.review ? action.payload.message : null
            return {...state, sendReviewIsLoading: false, reviewData:  review, sendReviewError: null, sendReviewMessage: reviewMessage }

            case SEND_REVIEW.FAIL:
            return {...state, sendReviewIsLoading: false, sendReviewError: action.payload, sendReviewMessage: false }

            case SEND_REVIEW.RESET:
            return {...state, sendReviewIsLoading: false, sendReviewError: false, sendReviewMessage: false }

            case SET_CHAT_ITEM.RESERVE: 
            const {b_reserved, id} = action.payload
            const dataArr = state.data.map(chatItem => {
                if (chatItem && chatItem.item && chatItem.item.pk_i_id && parseInt(chatItem.item.pk_i_id) === id) {
                    return {...chatItem,  item: {...chatItem.item, b_reserved: b_reserved}}
                } else {
                    return chatItem
                }
            })
            return {...state, data:  dataArr}

            case SET_CHAT_ITEM.SOLD: 
            const {b_mark_as_sold} = action.payload
            const id2 = action.payload && action.payload.id ? action.payload.id : null
            const dataArr2 = state.data.map(chatItem2 => {
                if (id2 && chatItem2 && chatItem2.item && chatItem2.item.pk_i_id && parseInt(chatItem2.item.pk_i_id) === parseInt(id2)) {
                    return {...chatItem2,  item: {...chatItem2.item, b_mark_as_sold: b_mark_as_sold}}
                } else {
                    return chatItem2
                }
            })
            return {...state, data:  dataArr2}

            case CHAT.SET_ISTHREAD:
            return {...state, mobileIsThread:  action.payload}

            case DELETE_CHAT.LOAD:
            return {...state, deleteChatIsLoading: true, deleteChatError: null, deleteChatMessage: false}

            case DELETE_CHAT.SUCCESS:
            return {...state, deleteChatIsLoading: false, deleteChatError: null, deleteChatMessage: action.payload }

            case DELETE_CHAT.FAIL:
            return {...state, deleteChatIsLoading: false, deleteChatError: action.payload, deleteChatMessage: false }

            case DELETE_CHAT.RESET:
            return {...state, deleteChatIsLoading: false, deleteChatError: false, deleteChatMessage: false }

            default:
            return state;  
        }
    }

    
    