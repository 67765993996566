import React, { useEffect } from 'react'
import useWindowDimensions from '../../../common/helper/useWindowDimension'

const { REACT_APP_ENV } = process.env;

const GoogleAds = (props) => {
    const { position } = props
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (REACT_APP_ENV === 'prod') {
            setTimeout(() => {
                window.adsbygoogle = window.adsbygoogle || []
                window.adsbygoogle.push({})
            }, 1000)
        }
    }, [])

    let element = null

    if (width > 600) {
        element =   <>
                        <p className='fk-ads-title'>Advertisement</p>
                        <ins 
                            className="adsbygoogle inline-block w-px-728 h-px-90 bg-gray m-10-0"
                            data-adtest={REACT_APP_ENV === 'prod' ? "off" : "on"}
                            data-ad-client="ca-pub-0860515033076106"
                            data-ad-slot="8939227657"
                            >
                        </ins> 
                    </>
    } 
    else {
        if (position && position === 'top') {
            element =  <>
                            <p className='fk-ads-title'>Advertisement</p>
                            <ins className="adsbygoogle inline-block w-px-300 h-px-100 bg-gray mb-10"
                                data-adtest={REACT_APP_ENV === 'prod' ? "off" : "on"}
                                data-ad-client="ca-pub-0860515033076106"
                                data-ad-slot="9160140384">
                            </ins>
                        </>
        } else {
            element =   <>
                            <p className='fk-ads-title'>Advertisement</p>
                            <ins 
                                className="adsbygoogle inline-block w-px-300 h-px-100 bg-gray m-5"
                                data-adtest={REACT_APP_ENV === 'prod' ? "off" : "on"}
                                data-ad-client="ca-pub-0860515033076106"
                                data-ad-slot="2927804337"
                                >
                            </ins> 
                        </>
        }


    }

    return element
}   

export default GoogleAds